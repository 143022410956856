import React from 'react';

import { Option } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { MaskField, normalizeIban } from 'components/forms/inputs/MaskField';
import { RadioField } from 'components/forms/inputs/RadioField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextField } from 'components/forms/inputs/TextField';
import config from 'config';
import { useDisabledCustomer } from 'hooks/useDisabledCustomer';
import { translate } from 'i18n/translator';
import {
  isRadioFieldChecked,
  SWIFT_ALLOWED_CHARACTERS_REGEXP,
  swiftValidation,
} from 'pages/Demands/DepositDemand/utils';
import { validateIban } from 'pages/Demands/utils';
import { usePrevious } from 'utils/hooks';
import { isExist } from 'utils/isData';

import { ChangingDepositRadioMainAccFieldsNames, DepositDetailNames } from '../enums';

const { ibanMask } = config.page.domesticPayment;

interface ProlongationDepositFields {
  deposit?: string;
  doChangeAmount?: boolean;
  doChangeMainAcc?: boolean;
  doChangeTerm?: boolean;
  radioMainAcc?: string;
}

export const ProlongationDepositDetail = () => {
  const {
    order,
    onChangeCustomer,
    payload: { deposit: deposits, radioMainAcc: radioMainAccounts, ownMainAccount },
    form: { updateData, getFormData },
  } = useOrderDetails();

  const {
    doChangeMainAcc,
    doChangeAmount,
    doChangeTerm,
    radioMainAcc,
    deposit,
  }: ProlongationDepositFields = getFormData();

  const isDisabledCustomer = useDisabledCustomer(order);

  const [isCheckedDoExternalMainAcc, isCheckedDoExternalSwiftMainAcc, isCheckedDoOwnMainAcc] =
    React.useMemo(
      () =>
        Object.values(ChangingDepositRadioMainAccFieldsNames).map(field =>
          isRadioFieldChecked(radioMainAccounts, radioMainAcc, field),
        ),
      [radioMainAcc],
    );

  const filteredOwnMainAccounts = React.useMemo(() => {
    const depositCurrency = deposit
      ? deposits.find((option: Option) => option.value === deposit).content.info
      : null;

    const hasCurrency = isExist(depositCurrency);

    return hasCurrency
      ? ownMainAccount.filter((option: Option) => option.content.info === depositCurrency)
      : ownMainAccount;
  }, [deposit]);

  const prevDepositValue = usePrevious(deposit);

  React.useEffect(() => {
    if (prevDepositValue) {
      updateData({
        ownMainAccount: null,
      });
    }
  }, [deposit]);

  return (
    <DefaultForm>
      <h5>{translate('front.prolongation-deposit-demand.titleLabel.label')}</h5>
      <CustomerSelect
        name={DepositDetailNames.Customer}
        label="front.deposit-demand.organization.label"
        onSelectOption={onChangeCustomer}
        selectDefaultCustomer
        disabled={isDisabledCustomer}
        required
      />
      <SelectField
        name={DepositDetailNames.Deposit}
        label="front.deposit-demand.deposit.label"
        options={deposits}
        required
      />
      <CheckboxField
        name={DepositDetailNames.DoChangeTerm}
        label="front.prolongation-deposit-demand.doChangeTerm.label"
        defaultValue={true}
        required
      />
      <AmountField
        name={DepositDetailNames.NewTerm}
        label="front.prolongation-deposit-demand.newTerm.label"
        maxLength={doChangeTerm ? 3 : null}
        minAmount={doChangeTerm ? 1 : null}
      />
      <CheckboxField
        name={DepositDetailNames.DoChangeAmount}
        label="front.prolongation-deposit-demand.doChangeAmount.label"
      />
      {doChangeAmount && (
        <AmountField
          name={DepositDetailNames.NewAmout}
          label="front.prolongation-deposit-demand.newAmout.label"
          maxLengthWithoutZero={12}
          minAmount={0.01}
          hasMoneyFormat
          required
        />
      )}
      <CheckboxField
        name={DepositDetailNames.DoChangeMainAcc}
        label="front.prolongation-deposit-demand.doChangeMainAcc.label"
        required
      />
      {doChangeMainAcc && (
        <RadioField
          name={DepositDetailNames.RadioMainAcc}
          options={radioMainAccounts}
          cssType="block"
          required
        />
      )}
      {isCheckedDoExternalMainAcc && (
        <MaskField
          name={DepositDetailNames.ExternalMainAccNumber}
          label="front.changing-deposit-demand.externalMainNationalAccount.label"
          normalize={normalizeIban}
          validate={validateIban}
          mask={ibanMask}
          required
        />
      )}
      {isCheckedDoExternalSwiftMainAcc && (
        <>
          <TextField
            name={DepositDetailNames.ExternalSwiftMainAccCorrBank}
            label="front.changing-deposit-demand.externalMainAccCorrBank.label"
            required
          />
          <TextField
            name={DepositDetailNames.ExternalSwiftMainAccCorrSwiftCode}
            label="front.changing-deposit-demand.externalMainAccCorrSwiftCode.label"
            maxLength={34}
            validate={swiftValidation}
            allowedCharacters={SWIFT_ALLOWED_CHARACTERS_REGEXP}
            required
          />
          <TextField
            name={DepositDetailNames.ExternalSwiftMainAccBenefBank}
            label="front.changing-deposit-demand.externalMainAccBenefBank.label"
            required
          />
          <TextField
            name={DepositDetailNames.ExternalSwiftMainAccCode}
            label="front.changing-deposit-demand.externalMainAccCode.label"
            maxLength={34}
            validate={swiftValidation}
            allowedCharacters={SWIFT_ALLOWED_CHARACTERS_REGEXP}
            required
          />
          <TextField
            name={DepositDetailNames.ExternalSwiftMainAccNumber}
            label="front.changing-deposit-demand.externalMainAccNumber.label"
            maxLength={34}
          />
          <TextField
            name={DepositDetailNames.ExternalSwiftMainAccName}
            label="front.changing-deposit-demand.externalMainAccName.label"
            maxLength={34}
            required
          />
          <TextField
            name={DepositDetailNames.ExternalSwiftMainAccAddress}
            label="front.changing-deposit-demand.externalMainAccAddress.label"
            required
          />
        </>
      )}
      {isCheckedDoOwnMainAcc && (
        <SelectField
          name={DepositDetailNames.OwnMainAccount}
          label="front.changing-deposit-demand.ownMainAccount.label"
          options={filteredOwnMainAccounts}
          required
        />
      )}
      <CheckboxField
        name={DepositDetailNames.AcceptConditions}
        label="front.prolongation-deposit-demand.acceptConditions.label"
        required
      />
    </DefaultForm>
  );
};
