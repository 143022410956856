import * as React from 'react';
import reactHtmlParser, { Transform } from 'react-html-parser';

import { FieldInput } from 'components/forms/inputs/FieldInput';
import { PreloadField } from 'components/forms/inputs/PreoadField';
import { useField } from 'components/forms/ValidatingForm/components/useField';

export interface HtmlParserField {
  label: string;
  name: string;
  transform?: Transform;
}

export const HtmlParserField = (props: HtmlParserField) => {
  const { name, label, transform } = props;
  const { field, form } = useField({ name });

  if (!field && !form) {
    return null;
  }

  const isDisabled = form.disabled || form.progress;

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const htmlText = field.value?.replace(/\n/g, '<br>');

  return (
    <PreloadField height={88} isLoading={form.initializing}>
      <FieldInput
        name={name}
        label={label}
        error={field.error}
        warning={field.warning}
        value={field.value}
        isDisabled={isDisabled}
        isFocus
      >
        <div
          id={name}
          className="form-input form-input-textarea form-input-textarea-parse"
          onClick={handleClick}
        >
          {reactHtmlParser(htmlText, { transform })}
        </div>
      </FieldInput>
    </PreloadField>
  );
};
