import { api } from 'api';
import Encrypt from 'utils/Encrypt';

export const EncryptCredentials = async (login: string, password: string, newPassword?: string) => {
  const encryptedLogin = Encrypt.encryptLogin(login);

  const { salt } = await api.auth.getUserSalt(encryptedLogin);
  const hashedPassword: string = Encrypt.hashPassword(password, salt);

  const data = {
    login: encryptedLogin,
    password: hashedPassword,
  };
  if (newPassword) {
    const hashedNewPassword = Encrypt.encryptNewPassword(newPassword);
    return { ...data, newpassword: hashedNewPassword };
  }
  return data;
};
