import { SERVER_ERROR } from 'store/actions/types';
import { ActionInterface } from 'store/reducers/interfaces';

const initialState: boolean = null;

export function serverErrorReducer(state = initialState, action: ActionInterface) {
  if (action.type === SERVER_ERROR.SET_ERROR) {
    return action.payload;
  }
  return state;
}
