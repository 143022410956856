import { post } from 'api/backend';

type Error = {
  data: string;
  level: string;
  message: string;
  id?: string;
};

export class LogService {
  async log(error: Error) {
    post('/v1/front-log', error);
  }
}
