import { DepositView } from 'api/ProductsService';

export const getCurrency = (row: DepositView) => row.product.currency;

export const createCurrencyOption = (row: DepositView) => ({
  label: row.product.currency,
  value: row.product.currency,
});

export const getType = (row: DepositView) => row.product.subType;

export const createTypeOption = (row: DepositView) => ({
  label: row.product.subTypeName,
  value: row.product.subType,
});
