import React from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment';

import { DemandType } from 'api/DemandsService/enums';
import { ConstantEnum } from 'api/enums';
import { getMaxDate } from 'components/Document/utils';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { toDDMMYYY } from 'components/utils/format';
import { selectConstants } from 'store/selectors';
import { useEffectIfValueChange } from 'utils/hooks';
import { isExist } from 'utils/isData';

const getConstantName = (orderType: string) => {
  switch (orderType) {
    case DemandType.CashWithdrawalOrder:
      return ConstantEnum.AllowedDaysForCashOrderWithdraw;
    case DemandType.CashTransferOrder:
      return ConstantEnum.AllowedDaysForCashOrderTransfer;
    case DemandType.CashPreparationOrder:
      return ConstantEnum.AllowedDaysForCashOrderDemands;
  }
};

export const useDateOperation = (orderType?: string) => {
  const [disabledDays, setDisabledDays] = React.useState<any>();
  const constants = useSelector(selectConstants());

  const validDays = constants[getConstantName(orderType)];

  const { getFieldValue, updateData } = useForm();

  const orderDate = getFieldValue('orderDate');

  React.useEffect(() => {
    if (orderDate) {
      const dateOperationAfterDay = {
        before: orderDate,
        after: moment(orderDate as Date)
          .add(validDays, 'days')
          .toDate(),
      };
      setDisabledDays(dateOperationAfterDay);
    }
  }, [orderDate, validDays]);

  const resetOrderData = React.useCallback(() => updateData({ dateOperation: null }), []);

  const maxDate = getMaxDate(orderDate as Date, validDays);
  const formatDate = isExist(orderDate) ? toDDMMYYY(orderDate as Date) : null;

  useEffectIfValueChange(formatDate, resetOrderData);

  return { maxDate, disabledDays };
};
