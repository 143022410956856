import * as React from 'react';
import { Col, Container, Row } from 'react-grid';
import { useRouteMatch } from 'react-router';

import { api } from 'api';
import { PersonPrivileges, PersonPrivilegyGroup, Privilegy } from 'api/AuthService';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { FormContextValue } from 'components/forms/ValidatingForm/FormContext';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { Tab, Tabs, TabsRef } from 'components/tabs/Tabs';
import { translate } from 'i18n/translator';
import { goBack, goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';

interface GroupsProps {
  groups: PersonPrivilegyGroup<ExpandedPrivilege>[];
  topLevelRole?: boolean;
}

interface PrivilegesProps {
  privileges: ExpandedPrivilege[];
  topLevelRole?: boolean;
}

interface FormFields {
  fullName: string;
  login: string;
  organizationRole: number;
  privileges: Obj<boolean>;
  roleId: number;
  signLevel: string;
  status: string;
}

interface ExpandedPrivilege extends Privilegy {
  neighbors?: number[];
  subPrivilege?: number[];
}

const toActivePrivilegies = (p: Obj<boolean>) =>
  Object.entries(p)
    .reduce((acc, [key, value]) => {
      if (!value) return acc;

      const id = parseKeyToId(key);
      return [...acc, id];
    }, [])
    .sort((a, b) => a - b);

const privelegeDataFromActive = (newP: number[], prevP: number[] = []) => {
  const newData = newP.reduce((acc, id) => {
    return { [`#${id}`]: true, ...acc };
  }, {});
  const prevData = prevP.reduce((acc, id) => {
    return { [`#${id}`]: false, ...acc };
  }, {});

  return { ...prevData, ...newData };
};

const createPrefix = (val: number): string => `privileges.#${val}`;

const parseKeyToId = (key: string): number => Number(key.substring(1));

const filterByMasterId = (privileges: Privilegy[], masterId: number, excludeId?: number) =>
  privileges?.filter(group => group.masterId === masterId && group.id !== excludeId).map(g => g.id);

const Groups = ({ groups, topLevelRole }: GroupsProps) => {
  const prevGroups = React.useRef(null);
  const tabsRef = React.useRef<TabsRef>(null);

  React.useEffect(() => {
    if (prevGroups.current !== groups) {
      tabsRef.current?.goToFirst();
    }
    prevGroups.current = groups;
  });

  return (
    <Tabs ref={tabsRef}>
      {groups.map(group => {
        const privileges = group.subGroups?.reduce((acc, subGroup) => {
          acc.push(...subGroup.privileges);
          return acc;
        }, []);

        const newSubGroups: PersonPrivilegyGroup<ExpandedPrivilege>[] = group.subGroups?.map(
          (group: PersonPrivilegyGroup<ExpandedPrivilege>) => ({
            ...group,
            privileges: group.privileges.map((subGroup: ExpandedPrivilege) =>
              subGroup.masterId
                ? {
                    ...subGroup,
                    neighbors: filterByMasterId(privileges, subGroup.masterId, subGroup.id),
                  }
                : group,
            ),
          }),
        );

        if (group.privileges.length === 0 && !group.subGroups) return null;

        const newPrivileges = group.privileges.map(p => ({
          ...p,
          subPrivilege: filterByMasterId(privileges, p.id),
        }));
        // TODO delete
        if (group.name === 'Є-підпис') {
          return;
        }

        return (
          <Tab key={group.name} label={group.name} value={group.name}>
            <Privileges privileges={newPrivileges} topLevelRole={topLevelRole} />
            {group.subGroups && (
              <>
                <hr />
                <SubGroups groups={newSubGroups} topLevelRole={topLevelRole} />
              </>
            )}
          </Tab>
        );
      })}
    </Tabs>
  );
};

const SubGroups = ({ groups, topLevelRole }: GroupsProps) => {
  if (!groups) return null;
  return (
    <>
      {groups.map(group => (
        <div key={group.name} className="mb-4">
          <h4 className="mb-0">{group.name}</h4>
          <Privileges privileges={group.privileges} topLevelRole={topLevelRole} />
          {group.subGroups && <SubGroups groups={group.subGroups} topLevelRole={topLevelRole} />}
        </div>
      ))}
    </>
  );
};

const createOnChange = (
  privilege: ExpandedPrivilege,
  { updateData, getFormData }: Partial<FormContextValue<FormFields, any>>,
) => {
  if (privilege.subPrivilege) {
    return (value: boolean) => {
      const privilegesFormData = privilege.subPrivilege.reduce((acc: Obj<boolean>, privilegeId) => {
        acc[createPrefix(privilegeId)] = value;
        return acc;
      }, {});
      updateData({ ...privilegesFormData });
    };
  }
  if (privilege.neighbors) {
    return (value: boolean) => {
      const allPrivileges = Object.entries(getFormData().privileges);
      const needCheckMaster = allPrivileges
        .filter(([key]) => privilege.neighbors.includes(parseKeyToId(key)))
        .every(([key, value]) => {
          const id = parseKeyToId(key);
          return privilege.neighbors.includes(id) && value;
        });
      const { masterId } = privilege;
      masterId && updateData({ [createPrefix(masterId)]: value && needCheckMaster });
    };
  }
};

const Privileges = ({ privileges, topLevelRole }: PrivilegesProps) => {
  const { updateData, getFormData } = useForm<FormFields>();

  return (
    <Container>
      <Row>
        {privileges.map(privilege => {
          const onChange = createOnChange(privilege, {
            getFormData,
            updateData,
          });
          return (
            <Col key={privilege.id} md={4}>
              <CheckboxField
                label={privilege.name}
                name={createPrefix(privilege.id)}
                disabled={topLevelRole || privilege.code?.endsWith('.sign')}
                onChange={onChange}
              />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

const RolePermissionsForm: React.FC = () => {
  const {
    params: { id },
  } = useRouteMatch<{
    id: string;
  }>();

  const [role, setRole] = React.useState<PersonPrivileges>();

  const { progress, error, handleSubmit, setDisabled, updateData } = useForm<FormFields>();

  const fetchData = async () => {
    const role = await api.auth.getCustomerRoleWithFiltering(id);
    if (!role.roleMasterId) {
      setDisabled(true);
    }
    setRole(role);
    updateData({ privileges: privelegeDataFromActive(role.activePrivileges) });
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = async (data: FormFields) => {
    const privilegeIds = toActivePrivilegies(data.privileges);
    await api.auth.saveRole(+id, { privilegeIds });
    goto(pages.roles);
  };

  return role?.groups ? (
    <>
      <FormError>{error}</FormError>
      <Groups groups={role.groups[0].subGroups} topLevelRole={role.roleMasterId === null} />
      <Container>
        <Row>
          <Col md={6}>
            <Button
              color="primary"
              onClick={e => handleSubmit(onSubmit, e)}
              progress={progress}
              type="submit"
              size="sm"
            >
              {translate('front.role-page.edit-role.apply-button.label')}
            </Button>
            <Button
              color="secondary"
              onClick={() => goBack()}
              progress={progress}
              type="submit"
              size="sm"
            >
              {translate('front.role-page.edit-role.cancel-button.label')}
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  ) : null;
};

export const RolePermissions = withForm(RolePermissionsForm);
