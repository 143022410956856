import * as React from 'react';

import { useDataFetcher } from 'components/DataFetcher/useDataFetcher';
import { Pagination } from 'components/Pagination';

export const PagePagination = () => {
  const { count, updateGridRequest, gridRequest, breakpoint, isHidePagination } = usePagination();

  if (isHidePagination) {
    return null;
  }

  return (
    <Pagination
      breakpoint={breakpoint}
      totalCount={count}
      pagination={gridRequest}
      updatePagination={updateGridRequest}
    />
  );
};

const usePagination = () => {
  const {
    count,
    breakpoint,
    gridRequest: { updateGridRequest, ...gridRequest },
    isHidePagination,
  } = useDataFetcher();

  return { gridRequest, updateGridRequest, count, isHidePagination, breakpoint };
};
