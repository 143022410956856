import * as React from 'react';

import moment from 'moment';

import { Customer } from 'api/UserService';
import { Button } from 'components/buttons/Button';
import { Attention } from 'components/icons';
import { confirmModal, PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import { translate } from 'i18n/translator';

import './styles.scss';

interface Props {
  customers: Array<Customer>;
  description: string;
  onClickNotShowAgain: () => void;
}

export const showExpiredCustomersModal = async (
  customers: Array<Customer>,
  description: string,
  onClickNotShowAgain: () => void,
): Promise<any> => {
  await confirmModal(TermExpirationModal, {
    customers,
    description,
    onClickNotShowAgain,
  });
};

export const TermExpirationModal: React.FC<PropsWithConfirmModal<Props>> = ({
  resolve,
  onClickNotShowAgain,
  customers,
  description,
}) => {
  const expiringDateCustomers = React.useMemo(
    () =>
      customers.map((customer, key) => (
        <li key={key}>
          {translate('front.modal-auth-person-term-expiring-data-modal.label')
            .replace('{date}', `${moment(customer.endDate).format('DD.MM.YYYY')}`)
            .replace('{organization}', `${customer.name}`)}
        </li>
      )),
    [customers],
  );

  const handleClickNotShowAgain = () => {
    onClickNotShowAgain();
    resolve();
  };

  return (
    <div className="term-expiration-modal-wrapper">
      <div className="expiration-modal-icon">
        <Attention />
      </div>
      <ul className="customers-list">{expiringDateCustomers}</ul>
      <div className="expiration-description">{description}</div>
      <div className="expiration-modal-footer">
        <Button onClick={handleClickNotShowAgain} color="secondary" size="sm">
          <div className="hide-modal-button-label">
            {translate('front.term-expiration-modal.acquainted.label')}
          </div>
          <div>{translate('front.term-expiration-modal.not-show-again.label')}</div>
        </Button>
        <Button onClick={() => resolve()} color="secondary" size="sm">
          <div className="close-button-label">
            {translate('front.required-mail-modal.close-button.label')}
          </div>
        </Button>
      </div>
    </div>
  );
};
