import React from 'react';

import { Option } from 'api/Service';
import { MaskField, normalizeIban } from 'components/forms/inputs/MaskField';
import { RadioField } from 'components/forms/inputs/RadioField';
import { SelectField } from 'components/forms/inputs/SelectField';
import config from 'config';
import {
  DepositDetailNames,
  RadioPlacementAccTrancheFieldsNames,
} from 'pages/Demands/DepositDemand/enums';
import { isRadioFieldChecked } from 'pages/Demands/DepositDemand/utils';
import { validateIban } from 'pages/Demands/utils';

const { ibanMask } = config.page.domesticPayment;

interface SelectDetailsProps {
  filteredAccounts: Option[];
  radioPlacementAccTranche: string;
  radioPlacementAccTrancheOptions: Option[];
}

export const SelectDetails = ({
  radioPlacementAccTranche,
  radioPlacementAccTrancheOptions,
  filteredAccounts,
}: SelectDetailsProps) => {
  const [isExternalPlacementAcc, isInternalPlacementAcc] = React.useMemo(
    () =>
      Object.values(RadioPlacementAccTrancheFieldsNames).map(field =>
        isRadioFieldChecked(radioPlacementAccTrancheOptions, radioPlacementAccTranche, field),
      ),
    [radioPlacementAccTranche],
  );

  return (
    <>
      <RadioField
        name={DepositDetailNames.RadioPlacementAccTranche}
        options={radioPlacementAccTrancheOptions}
        cssType="block"
      />
      {isExternalPlacementAcc && (
        <MaskField
          name={DepositDetailNames.PlacementOwnIbanTranche}
          label="front.changing-deposit-demand.externalMainNationalAccount.label"
          normalize={normalizeIban}
          validate={validateIban}
          mask={ibanMask}
          required
        />
      )}
      {isInternalPlacementAcc && (
        <SelectField
          name={DepositDetailNames.PlacementOwnAccountTranche}
          label="front.changing-deposit-demand.ownMainAccount.label"
          options={filteredAccounts}
          required
        />
      )}
    </>
  );
};
