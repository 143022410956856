import * as React from 'react';

import './counter.scss';

interface CounterProps {
  count: number;
}

export const Counter: React.FC<CounterProps> = ({ count }) => (
  <div className="counter">
    <span className="counter__content">{count}</span>
  </div>
);
