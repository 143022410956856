import client from 'api/CertificateService/CipherModule/client';
import {
  CertificateInfo,
  CertificateInfoResult,
  ConnectedToken,
  ConnectedTokenOptions,
  CreateCertificateResponse,
  GenerationProfiles,
  getKeySessionContainerParams,
} from 'api/CertificateService/CipherModule/interfaces';
import {
  cipherError,
  translationsCustomError,
} from 'api/CertificateService/CipherModule/translations';
import { KeyType } from 'api/CertificateService/enums';
import { handlePasswordError } from 'api/CertificateService/handlePasswordError';
import { ActionEnum } from 'api/enums';
import { ConfirmedProfile } from 'api/interfaces';
import store from 'store';
import { CustomError } from 'utils/customError';

export const isCipherAvailable = async (): Promise<boolean> => {
  try {
    await client.get('api/v1/status', {});

    return true;
  } catch (e:any) {
    return false;
  }
};

export const createSession = async (): Promise<string> => {
  const { data } = await client.post('api/v1/ticket');
  return data.ticketUuid;
};

export const setSessionParams = async (uuid: string, hasFirstSign = false): Promise<void> => {
  const cipherTokenCaId = store.getState().constantState.CIPHER_TOKEN_CA_ID;

  const options = {
    caId: cipherTokenCaId,
    signatureType: 'attached',
    cadesType: 'CAdESXLong',
    dataToSignQualifier: hasFirstSign ? 'alreadySigned' : 'notSignedBefore',
  };

  return await client.put(`api/v1/ticket/${uuid}/option`, options);
};

export const getKeyProfiles = async (id: string): Promise<GenerationProfiles['oid']> => {
  const { data } = await client.get('api/v1/generator/keysProfiles');

  return data.find((item: GenerationProfiles) => item.id === +id)?.oid;
};

export const createCertificate = async (
  request: ConfirmedProfile,
): Promise<CreateCertificateResponse> => {
  try {
    const { data } = await client.post('api/v1/generator/creator', JSON.stringify(request), {
      headers: { 'Content-Type': 'application/json' },
    });

    return data;
  } catch (e: any) {
    throw new CustomError(await handlePasswordError(e.message, ActionEnum.CreateCertificate));
  }
};

export const getConnectedTokenOptions = async (
  mode?: KeyType,
): Promise<ConnectedTokenOptions[]> => {
  const { data } = await client.get<ConnectedToken>('api/v1/token/connected');

  return data.tokenInfo.map(item => ({
    label: `${item.name} ${item.model ? `(${item.model})` : ''}`,
    value: mode === KeyType.TOKEN ? item.serial : item.name,
    content: item,
  }));
};

export const createSessionContainer = async ({
  ticketUuid,
  keyStorePath,
  base64Data,
}: getKeySessionContainerParams) =>
  await client.put(`api/v1/ticket/${ticketUuid}/keyStore`, {
    keyStorePath,
    base64Data,
  });

export const importCertificate = async (
  uuid: string,
  keyStorePassword: string,
  certificateData: string,
): Promise<void> =>
  await client.put(`api/v1/ticket/${uuid}/keyStore/certificate/importer`, {
    keyStorePassword,
    certificateData,
  });

export const getCertificateInfo = async (
  uuid: string,
  password: string,
  action: ActionEnum,
  keyType = 'signature',
): Promise<CertificateInfoResult> => {
  try {
    const { data: certificateInfo } = await client.put<CertificateInfo>(
      `api/v1/ticket/${uuid}/keyStore/certificate/info/${keyType}`,
      {
        keyStorePassword: password,
      },
    );

    const personalData = certificateInfo.extensionsCertificateInfo?.value.personalData.value;

    return { certificateInfo, personalData };
  } catch (e: any) {
    throw new CustomError(await handlePasswordError(e.message, action));
  }
};

export const getCertificateBase64 = async (
  uuid: string,
  password: string,
  action: ActionEnum,
  keyType = 'signature',
): Promise<string> => {
  try {
    const { data } = await client.put(
      `/api/v1/ticket/${uuid}/keyStore/certificate/base64/${keyType}`,
      {
        keyStorePassword: password,
      },
    );

    return data.base64Data;
  } catch (e: any) {
    if (e.message.includes(cipherError.useCertificateIsProhibited)) {
      throw new CustomError(translationsCustomError(e.message).useOtherTokenOrFormatThis);
    }

    throw new CustomError(await handlePasswordError(e.message, action));
  }
};

export const sign = async (
  uuid: string,
  password: string,
  signatureList: string[],
): Promise<string[]> => {
  const { data } = await client.post(`/api/v1/ticket/${uuid}/ds/creator/data.bulk`, {
    keyStorePassword: password,
    dataToSign: signatureList,
  });

  return data;
};

export const closeSession = async (ticketUuid: string) =>
  await client.delete(`/api/v1/ticket/${ticketUuid}`);
