export enum CashPreparationDetailNames {
  CustomerId = 'customerId',
  Branch = 'branch',
  PayerAccountId = 'payerAccountId',
  Currency = 'currency',
  Amount = 'amount',
  Purpose = 'purpose',
}

export enum CashTransferDetailNames {
  CustomerId = 'customerId',
  Branch = 'branch',
  PayerAccountId = 'payerAccountId',
  Amount = 'amount',
  CashSymbol = 'cashSymbol',
  Purpose = 'purpose',
  SecondPurpose = 'secondPurpose',
  AdditionalDetails = 'additionalDetails',
  Attach = 'attach',
}

export enum CashTransferRepresentativeNames {
  FullName = 'fullName',
  TaxCode = 'taxCode',
  DocumentType = 'documentType',
  DocumentSerial = 'documentSerial',
  DocumentNumber = 'documentNumber',
  EnrollmentNumber = 'enrollmentNumber',
  DateBirth = 'dateBirth',
  DateIssue = 'dateIssue',
  IssuedBy = 'issuedBy',
  PlaceOfResidence = 'placeOfResidence',
}

export enum DocumentTypes {
  Passport = 'PASSPORT',
  IdCard = 'IDCARD',
  Other = 'OTHER',
}
