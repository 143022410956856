export const SESSION_STORAGE_KEYS = {
  AUTH_DATA: 'authData',
  TOKEN: 'tokenUser',
  LOCALES: 'locales',
  ACTIVE_LOCALE: 'activeLocale',
  SESSION_EXPIRE: 'sessionExpire',
  NOTIFICATIONS: 'notifications',
  SESSION_INFO: 'sessionInfo',
  IS_AUTHORIZED_BY_EDS: 'isAuthorizedByEds',
  IS_EXPIRATION_SIGN_MODAL_HIDDEN: 'usersHidSignExpiredModal',
  IS_EXPIRATION_AUTH_MODAL_HIDDEN: 'usersHidAuthExpiredModal',
};

export const LOCAL_STORAGE_KEY = {
  STORAGE_VERSION: 'storageVersion',
  TABLE_SETTINGS: 'dataTableSettings',
  PAGINATION_SIZES: 'paginationSizes',
  PRESERVED_VALUES: 'preservedValues',
  ACTIVE_LOCALE: SESSION_STORAGE_KEYS.ACTIVE_LOCALE,
  LOCALES: SESSION_STORAGE_KEYS.LOCALES,
  SESSION_INFO: SESSION_STORAGE_KEYS.SESSION_INFO,
  IS_AUTHORIZED_BY_EDS: SESSION_STORAGE_KEYS.IS_AUTHORIZED_BY_EDS,
  IS_EXPIRATION_SIGN_MODAL_HIDDEN: SESSION_STORAGE_KEYS.IS_EXPIRATION_SIGN_MODAL_HIDDEN,
  IS_EXPIRATION_AUTH_MODAL_HIDDEN: SESSION_STORAGE_KEYS.IS_EXPIRATION_AUTH_MODAL_HIDDEN,
};

export const USER = {
  SET_TOKEN: 'SET_TOKEN',
  SET_USER: 'SET_USER',
  SET_COLLECT_USER: 'SET_COLLECT_USER', // Using for PasswordLogin
  SET_CUSTOMERS: 'SET_CUSTOMERS',
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  SET_DEFAULT_CUSTOMER: 'SET_DEFAULT_CUSTOMER',
};

export const LOCALE = {
  SET_LOCALES: 'SET_LOCALES',
  SET_ACTIVE_LOCALE: 'SET_ACTIVE_LOCALE',
};

export const MODAL = {
  SET_MODAL: 'SET_MODAL',
};

export const MODAL_COLLECTION = {
  ADD_MODAL_COLLECTION: 'ADD_MODAL_COLLECTION',
  REMOVE_MODAL_COLLECTION: 'REMOVE_MODAL_COLLECTION',
};

export const CONSTANT = {
  SET_CONSTANTS: 'SET_CONSTANTS',
};

export const DEVICE = {
  RESOLVE_DEVICE: 'RESOLVE_DEVICE',
};

export const NOTIFICATIONS = {
  UPDATE_NOTIFICATIONS: 'UPDATE_NOTIFICATIONS',
};

export const SERVER_ERROR = {
  SET_ERROR: 'SERVER_ERROR/SET_ERROR',
};

export const APPLICATION_INIT = {
  SET_SERVER_TIME_INIT: 'SET_SERVER_TIME_INIT',
};
