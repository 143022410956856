import * as React from 'react';

import { GridRequest, Option } from 'api/Service';
import { Autocomplete } from 'components/forms/select/Autocomplete';
import { ArrowLeft, ArrowRight } from 'components/icons';
import { bem } from 'components/utils/bem';
import { cls } from 'components/utils/utils';
import config from 'config';
import { translate } from 'i18n/translator';

import './pagination.scss';

const sizeOption: Array<Option> = config.components.table.paginationSizeOptions;

const paginationDots = (c: number, m: number): (number | string)[] => {
  const current = c;
  const last = m;
  const delta = 2;
  const left = current - delta;
  const right = current + delta + 1;
  const range = [];
  const rangeWithDots = [];
  let l;

  for (let i = 1; i <= last; i += 1) {
    if (i === 1 || i === last || (i >= left && i < right)) {
      range.push(i);
    }
  }

  for (const i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push('...');
      }
    }
    rangeWithDots.push(i);
    l = i;
  }

  return rangeWithDots;
};

interface PaginationProps {
  breakpoint: string;
  pagination: GridRequest;
  totalCount: number;
  updatePagination: (GridRequest: GridRequest) => void;
}

export const Pagination = ({
  totalCount,
  pagination,
  updatePagination,
  breakpoint,
}: PaginationProps) => {
  if (!totalCount) {
    return null;
  }

  const totalPages = Math.ceil(totalCount / pagination.size);

  const pageLink = (
    page: number,
    label: JSX.Element | string | number,
    arrow?: string | boolean,
    key?: string | number,
    isDots?: boolean,
  ) => {
    const disabled = page === pagination.page || isDots;

    const className = cls(
      {
        disabled,
        active: !arrow && disabled && !isDots,
        dots: isDots,
      },
      arrow ? 'page-arrow' : 'page',
    );

    return (
      <li
        className={className}
        key={key || (label as string)}
        onClick={disabled ? null : () => updatePagination({ page })}
      >
        <a>{label}</a>
      </li>
    );
  };

  const pageLinks = paginationDots(pagination.page + 1, totalPages).map((item, key) =>
    pageLink(Number(item) - 1, item, false, key + 1, item === '...'),
  );

  return (
    <div className={bem('paginator').modificator('breakpoint', breakpoint).toClassName()}>
      <div className="paginator__summary">
        <span className="paginator__summary-label">
          {translate('front.table.paging-size.label')}
        </span>
        <span className="paginator__summary-select">
          <Autocomplete
            name="size"
            value={pagination.size.toString()}
            multi={false}
            options={sizeOption}
            onChange={value => updatePagination({ page: 0, size: +value })}
            isSearchable={false}
          />
        </span>
      </div>

      <ul className="paginator__pages">
        {pageLink(
          Math.max(0, pagination.page - 1),
          (<ArrowLeft />) as JSX.Element,
          'page-arrow',
          'arrow-left-key',
        )}
        {pageLinks}
        {pageLink(
          Math.min(totalPages - 1, pagination.page + 1),
          <ArrowRight />,
          'page-arrow',
          'arrow-right-key',
        )}
      </ul>
    </div>
  );
};
