import React from 'react';

import { Option } from 'api/Service';
import { MaskField, normalizeIban } from 'components/forms/inputs/MaskField';
import { RadioField } from 'components/forms/inputs/RadioField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextField } from 'components/forms/inputs/TextField';
import config from 'config';
import {
  DepositDetailNames,
  RadioInterestAccTrancheFieldsName,
} from 'pages/Demands/DepositDemand/enums';
import {
  isRadioFieldChecked,
  SWIFT_ALLOWED_CHARACTERS_REGEXP,
  swiftValidation,
} from 'pages/Demands/DepositDemand/utils';
import { validateIban } from 'pages/Demands/utils';

const { ibanMask } = config.page.domesticPayment;

interface InterestPaymentDetailsProps {
  filteredAccounts: Option[];
  radioInterestAccTranche: string;
  radioInterestAccTrancheOptions: Option[];
}

export const InterestPaymentDetails = ({
  radioInterestAccTranche,
  radioInterestAccTrancheOptions,
  filteredAccounts,
}: InterestPaymentDetailsProps) => {
  const [isExternalInterestAcc, isExternalSwiftAcc, isInternalInterestAcc] = React.useMemo(
    () =>
      Object.values(RadioInterestAccTrancheFieldsName).map(field =>
        isRadioFieldChecked(radioInterestAccTrancheOptions, radioInterestAccTranche, field),
      ),
    [radioInterestAccTranche],
  );

  return (
    <>
      <RadioField
        name={DepositDetailNames.RadioInterestAccTranche}
        options={radioInterestAccTrancheOptions}
        cssType="block"
        required
      />
      {isExternalInterestAcc && (
        <MaskField
          name={DepositDetailNames.AmountOwnIbanTranche}
          label="front.changing-deposit-demand.externalMainNationalAccount.label"
          normalize={normalizeIban}
          validate={validateIban}
          mask={ibanMask}
          required
        />
      )}
      {isExternalSwiftAcc && (
        <>
          <TextField
            name={DepositDetailNames.ExternalAmountSwiftAccCorrBank}
            label="front.changing-deposit-demand.externalMainAccCorrBank.label"
            required
          />
          <TextField
            name={DepositDetailNames.ExternalAmountSwiftAccCorrSwiftCode}
            label="front.changing-deposit-demand.externalMainAccCorrSwiftCode.label"
            maxLength={34}
            validate={swiftValidation}
            allowedCharacters={SWIFT_ALLOWED_CHARACTERS_REGEXP}
            required
          />
          <TextField
            name={DepositDetailNames.ExternalAmountSwiftAccBenefBank}
            label="front.changing-deposit-demand.externalMainAccBenefBank.label"
            required
          />
          <TextField
            name={DepositDetailNames.ExternalAmountSwiftAccCode}
            label="front.changing-deposit-demand.externalMainAccCode.label"
            maxLength={34}
            validate={swiftValidation}
            allowedCharacters={SWIFT_ALLOWED_CHARACTERS_REGEXP}
            required
          />
          <TextField
            name={DepositDetailNames.ExternalAmountSwiftAccNumber}
            label="front.changing-deposit-demand.externalMainAccNumber.label"
            maxLength={34}
            required
          />
          <TextField
            name={DepositDetailNames.ExternalAmountSwiftAccName}
            label="front.changing-deposit-demand.externalMainAccName.label"
            maxLength={34}
            required
          />
          <TextField
            name={DepositDetailNames.ExternalAmountSwiftAccAddress}
            label="front.changing-deposit-demand.externalMainAccAddress.label"
            required
          />
        </>
      )}
      {isInternalInterestAcc && (
        <SelectField
          name={DepositDetailNames.InterestOwnAccountTranche}
          label="front.changing-deposit-demand.ownMainAccount.label"
          options={filteredAccounts}
          required
        />
      )}
    </>
  );
};
