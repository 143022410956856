import * as React from 'react';

import { ExternalApiSession, SessionStatus } from 'api/ProfileService';
import { ChosenCustomerModel, CustomerStatus } from 'api/UserService';
import { Checkbox } from 'components/forms/checkbox/Checkbox';
import { time } from 'components/utils/format';
import { translate } from 'i18n/translator';

interface ExternalApiDetailsProps {
  row: ExternalApiSession;
}

const renderErrorMessage = (customer: ChosenCustomerModel) => {
  if (!customer || !customer.disabled) {
    return null;
  }

  const errorMessages = [];

  if (customer.status !== CustomerStatus.ACTIVE) {
    errorMessages.push(
      translate('front.profile.route-external-api.error-customer-not-active.label'),
    );
  }

  if (customer.personStatus !== CustomerStatus.ACTIVE) {
    errorMessages.push(translate('front.profile.route-external-api.error-person-not-active.label'));
  }

  if (customer.restrictedByTime) {
    const startTime = time(customer.validFrom);
    const endTime = time(customer.validTo);
    errorMessages.push(
      `${translate(
        'front.profile.route-external-api.restriction-time-text.label',
      )} ${startTime} - ${endTime}.`,
    );
  }

  if (customer.restrictedByIp) {
    errorMessages.push(
      `${translate('front.profile.route-external-api.restriction-ip-text.label')} ${
        customer.ipRestriction
      }.`,
    );
  }

  return errorMessages.length > 0 ? (
    <div className="error-block">{errorMessages.join('\n')}</div>
  ) : null;
};

export const ExternalApiDetails: React.FC<ExternalApiDetailsProps> = ({ row }) => {
  const isActiveToken = row.status === SessionStatus.OPEN;
  return (
    <div>
      <p>{translate('front.profile.route-external-api.chosen-customers')}</p>
      {row.customers.map(customer => (
        <div key={customer.id}>
          <Checkbox
            label={`${customer.name} (${customer.taxCode})`}
            checked={isActiveToken && !customer.disabled}
            name={String(customer.id)}
            disabled={!isActiveToken || customer.disabled}
          />
          {isActiveToken && renderErrorMessage(customer)}
        </div>
      ))}
    </div>
  );
};
