import * as React from 'react';

import { Field } from 'components/forms/ValidatingForm/components/useField';

const PX = 'px';

export const useFieldAutoGrowing = (field: Field, hasAutoGrowing: boolean) => {
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  React.useEffect(() => {
    if (!hasAutoGrowing || !field?.value || !textareaRef.current) {
      return;
    }

    textareaRef.current.style.height = '0px';
    const { scrollHeight, rows } = textareaRef.current;
    const { padding, lineHeight } = window.getComputedStyle(textareaRef.current);

    const defaultAllRowsHeight = rows * +lineHeight.replaceAll(PX, '');
    const defaultPaddingHeight = 2 * +padding.replaceAll(PX, '');

    const defaultHeight = defaultAllRowsHeight + defaultPaddingHeight;

    textareaRef.current.style.height =
      scrollHeight > defaultHeight ? `${scrollHeight}px` : `${defaultHeight}px`;
  }, [field?.value]);

  return { textareaRef };
};
