import * as React from 'react';

import classnames from 'classnames';

import { ArrowDown, ArrowUp } from 'components/icons';
import { IconRound } from 'pages/Instructions/components/IconRound';

import './instructions.scss';

interface Props {
  idx: number;
  isOpen: boolean;
  label: string;
  onToggleShow: () => void;
}

export const InstructionHeader: React.FC<Props> = ({ idx, label, isOpen, onToggleShow }) => {
  return (
    <div className="collapse-main">
      <div
        onClick={onToggleShow}
        className={classnames('collapse-main__row', {
          'collapse-main__row--without-shadow': isOpen,
        })}
      >
        <IconRound label={idx} isOpen={isOpen} />
        <span>{label}</span>
        <div className="collapse-main__row__arrow">{isOpen ? <ArrowUp /> : <ArrowDown />}</div>
      </div>
    </div>
  );
};
