import React from 'react';

import { api } from 'api';
import { Order, OrderHistory } from 'api/OrderService';
import { GridRequest, GridResponse } from 'api/Service';

export const useOrderHistory = (
  order: Order,
  setHistory: React.Dispatch<React.SetStateAction<GridResponse<OrderHistory>>>,
) => {
  const fetchHistory = React.useCallback(
    async (request: GridRequest) => {
      const orderHistory = await api.order.getOrderHistory(order.id, request);
      setHistory(orderHistory);

      return orderHistory;
    },
    [order],
  );

  // TODO: IMPLEMENT PAGINATION!!!!!!!!

  return { fetchHistory, history };
};
