import { ExportType } from 'api/ExportService';
import { HasId } from 'api/Service';

export type PropsType = {
  data: Array<HasId>;
  objectCode: ExportType;
};

export enum PrintPdfEnum {
  OneDocOneFile = 'OneDocOneFile',
  OneDocAnotherFile = 'OneDocAnotherFile',
  TwoDocOneFile = 'TwoDocOneFile',
}

export enum ItemLayoutEnum {
  OnePageOneItem = '1:1',
  OnePageTwoItems = '1:2',
}

export type PrintOption = {
  icon: any;
  isExportItemSeparatelyFile: boolean;
  label: string;
  layout: ItemLayoutEnum;
  value: PrintPdfEnum;
};
