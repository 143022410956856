import { MODAL } from 'store/actions/types';
import { ActionInterface } from 'store/reducers/interfaces';

export interface ModalState<T> {
  modal: T;
}

const initialState: ModalState<null> = {
  modal: null,
};

export const modalReducer = <T>(state: ModalState<T> = initialState, action: ActionInterface) => {
  switch (action.type) {
    case MODAL.SET_MODAL: {
      return { ...state, modal: action.payload };
    }
    default:
      return state;
  }
};
