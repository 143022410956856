import * as React from 'react';
import { Col, Container, Row } from 'react-grid';

import { Button } from 'components/buttons/Button';
import { REGEX_TEMPLATE_NAME } from 'components/forms/embeddedEditField/EmbeddedEditField';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { TextField } from 'components/forms/inputs/TextField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { validateMessages } from 'components/validateMessages';
import { translate } from 'i18n/translator';

import './styles.scss';

interface Fields {
  name: string;
}

interface Props {
  resolve(val: any): void;
}

const regexValidationName = (value: string) => {
  if (!REGEX_TEMPLATE_NAME.test(value)) {
    return translate(validateMessages.invalidDocNumber);
  }

  return;
};
export const TemplateForm: React.FC<Props> = props => {
  const { error, progress, handleSubmit } = useForm<Fields>();

  const onSave = async (formData: Fields) => {
    props.resolve(formData.name);
  };

  return (
    <div className="template-modal">
      <DefaultForm disabled={progress}>
        <FormError>{error}</FormError>
        <TextField
          label="front.template-modal.template-name.label"
          maxLength={80}
          name="name"
          validate={regexValidationName}
          required
        />
        <Container>
          <Row>
            <Col sm={6}>
              <Button
                onClick={e => handleSubmit(onSave, e)}
                size="sm"
                progress={progress}
                type="submit"
                block
              >
                {translate('front.template-modal.template-save-button.label')}
              </Button>
            </Col>
            <Col sm={6}>
              <Button onClick={() => props.resolve(null)} color="secondary" size="sm" block>
                {translate('front.template-modal.template-cancel-button.label')}
              </Button>
            </Col>
          </Row>
        </Container>
      </DefaultForm>
    </div>
  );
};

export const TemplateModal = withForm(TemplateForm);
