import React from 'react';

import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { DateField } from 'components/forms/inputs/DateField';
import { PhoneField } from 'components/forms/inputs/PhoneField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { PassportType, TextField } from 'components/forms/inputs/TextField';
import { useDisabledCustomer } from 'hooks/useDisabledCustomer';
import { translate } from 'i18n/translator';
import { disabledDaysDate, eighteenYearsBack } from 'pages/AuthorizedPersons/AuthorizedPersonPage';
import { CardFieldNames } from 'pages/Demands/CardDemand/enums';
import { CardPayload } from 'pages/Demands/CardDemand/interfaces';
import { normalizeName, ONLY_NUMBER, validateInn } from 'pages/Demands/CardDemand/utils';
import { OrderBaseFields } from 'pages/Demands/interfaces';
import { disableDaysAfterToday, disabledDaysBeforeToday, getOption } from 'pages/Demands/utils';
import { isExist } from 'utils/isData';

interface CardEditDetailFields extends OrderBaseFields {
  [CardFieldNames.Customer]: string;
  [CardFieldNames.CardAccount]: string;
  [CardFieldNames.TypePacket]: string;
  [CardFieldNames.NewCardType]: string;
  [CardFieldNames.SecretWord]: string;
  [CardFieldNames.LastName]: string;
  [CardFieldNames.FirstName]: string;
  [CardFieldNames.MiddleName]: string;
  [CardFieldNames.Inn]: number;
  [CardFieldNames.Sex]: string;
  [CardFieldNames.BirthDay]: Date;
  [CardFieldNames.BirthCountry]: string;
  [CardFieldNames.Resident]: boolean;
  [CardFieldNames.DocumentType]: string;
  [CardFieldNames.PassportSerial]: string;
  [CardFieldNames.PassportIssueDate]: Date;
  [CardFieldNames.PassportIssuer]: string;
  [CardFieldNames.PassportTerm]: Date;
  [CardFieldNames.RegCountry]: string;
  [CardFieldNames.RegRegion]: string;
  [CardFieldNames.RegDistrict]: string;
  [CardFieldNames.RegPostCode]: number;
  [CardFieldNames.RegCity]: string;
  [CardFieldNames.RegStreet]: string;
  [CardFieldNames.RegHouse]: string;
  [CardFieldNames.RegFlat]: string;
  [CardFieldNames.CoincidesResidenceAddress]: boolean;
  [CardFieldNames.LiveCountry]: string;
  [CardFieldNames.LiveRegion]: string;
  [CardFieldNames.LiveDistrict]: string;
  [CardFieldNames.LivePostCode]: number;
  [CardFieldNames.LiveCity]: string;
  [CardFieldNames.LiveStreet]: string;
  [CardFieldNames.LiveHouse]: string;
  [CardFieldNames.LiveFlat]: string;
  [CardFieldNames.Branch]: string;
  [CardFieldNames.BranchAdr]: string;
  [CardFieldNames.AddSMS]: boolean;
  [CardFieldNames.Phone]: string;
  [CardFieldNames.AcceptConditions]: boolean;
}

export const NewCardDetails = () => {
  const {
    order,
    payload: {
      cardAccount,
      typePacket,
      newCardType,
      sex,
      birthСountry,
      documentType,
      regCountry,
      liveCountry,
      branch,
    },
    onChangeCustomer,
    form: { updateData, getFieldValue },
  } = useOrderDetails<CardEditDetailFields, CardPayload>();
  const isDisabledCustomer = useDisabledCustomer(order);

  const branchFieldId = getFieldValue(CardFieldNames.Branch);
  const documentTypeId = getFieldValue(CardFieldNames.DocumentType);

  const getPassportType = getOption(documentTypeId, documentType)?.content.value;
  const isIdCard = getPassportType === PassportType.IDCARD;

  const isCheckedCoincidesResidenceAddress = getFieldValue(
    CardFieldNames.CoincidesResidenceAddress,
  );

  React.useEffect(() => {
    if (isExist(branchFieldId)) {
      const branchAddress = getOption(branchFieldId, branch)?.content.info;

      updateData({ [CardFieldNames.BranchAdr]: branchAddress });
    }
  }, [branchFieldId]);

  return (
    <DefaultForm>
      <CustomerSelect
        name={CardFieldNames.Customer}
        label="front.card-edit.organization.label"
        onSelectOption={onChangeCustomer}
        selectDefaultCustomer
        disabled={isDisabledCustomer}
        required
      />
      <SelectField
        name={CardFieldNames.CardAccount}
        label="front.card-edit.cardAccount.label"
        options={cardAccount}
        required
      />
      <SelectField
        name={CardFieldNames.TypePacket}
        label="front.card-edit.typePacket.label"
        options={typePacket}
        required
      />
      <SelectField
        name={CardFieldNames.NewCardType}
        label="front.card-edit.newCardType.label"
        options={newCardType}
        required
      />
      <TextField
        name={CardFieldNames.SecretWord}
        label="front.card-edit.secretWord.label"
        required
      />
      <h5>{translate('front.card-edit.questionnaire.label')}</h5>
      <TextField
        name={CardFieldNames.LastName}
        label="front.card-edit.lastName.label"
        normalize={normalizeName}
        maxLength={35}
        required
      />
      <TextField
        name={CardFieldNames.FirstName}
        label="front.card-edit.firstName.label"
        normalize={normalizeName}
        maxLength={35}
        required
      />
      <TextField
        name={CardFieldNames.MiddleName}
        label="front.card-edit.middleName.label"
        normalize={normalizeName}
        maxLength={35}
        required
      />
      <TextField
        name={CardFieldNames.Inn}
        label="front.card-edit.inn.label"
        allowedCharacters={ONLY_NUMBER}
        validate={validateInn}
        required
      />
      <SelectField
        name={CardFieldNames.Sex}
        label="front.card-edit.sex.label"
        options={sex}
        required
      />
      <DateField
        name={CardFieldNames.BirthDay}
        label="front.card-edit.birthDay.label"
        disabledDays={disabledDaysDate}
        maxDate={eighteenYearsBack}
        required
      />
      <SelectField
        name={CardFieldNames.BirthCountry}
        label="front.card-edit.birthCountry.label"
        options={birthСountry}
        required
      />
      <CheckboxField
        name={CardFieldNames.Resident}
        label="front.card-edit.resident.label"
        required
      />
      <SelectField
        name={CardFieldNames.DocumentType}
        label="front.card-edit.documentType.label"
        options={documentType}
        required
      />
      <TextField
        name={CardFieldNames.PassportSerial}
        label={
          isIdCard
            ? 'front.card-edit.passport-number.label'
            : 'front.card-edit.passportSerial.label'
        }
        passportType={getPassportType}
        required
      />
      <DateField
        name={CardFieldNames.PassportIssueDate}
        label="front.card-edit.passportIssueDate.label"
        disabledDays={disableDaysAfterToday}
        required
      />
      <TextField
        name={CardFieldNames.PassportIssuer}
        label="front.card-edit.passportIssuer.label"
        maxLength={300}
        required
      />
      <DateField
        name={CardFieldNames.PassportTerm}
        label="front.card-edit.passportTerm.label"
        disabledDays={disabledDaysBeforeToday}
      />
      <h5>{translate('front.card-edit.registration-address.label')}</h5>
      <SelectField
        name={CardFieldNames.RegCountry}
        label="front.card-edit.regCountry.label"
        options={regCountry}
        required
      />
      <TextField
        name={CardFieldNames.RegRegion}
        label="front.card-edit.regRegion.label"
        maxLength={300}
        required
      />
      <TextField
        name={CardFieldNames.RegDistrict}
        label="front.card-edit.regDistrict.label"
        maxLength={300}
        required
      />
      <TextField
        name={CardFieldNames.RegPostCode}
        label="front.card-edit.regPostCode.label"
        allowedCharacters={ONLY_NUMBER}
        length={5}
        required
      />
      <TextField
        name={CardFieldNames.RegCity}
        label="front.card-edit.regCity.label"
        maxLength={300}
        required
      />
      <TextField
        name={CardFieldNames.RegStreet}
        label="front.card-edit.regStreet.label"
        maxLength={300}
        required
      />
      <TextField
        name={CardFieldNames.RegHouse}
        label="front.card-edit.regHouse.label"
        maxLength={300}
        required
      />
      <TextField
        name={CardFieldNames.RegFlat}
        label="front.card-edit.regFlat.label"
        maxLength={300}
        required
      />
      <h5>{translate('front.card-edit.residence-address.label')}</h5>
      <CheckboxField
        name={CardFieldNames.CoincidesResidenceAddress}
        label="front.card-edit.coincides-residence-address.label"
      />
      {!isCheckedCoincidesResidenceAddress && (
        <>
          <SelectField
            name={CardFieldNames.LiveCountry}
            label="front.card-edit.regCountry.label"
            options={liveCountry}
            required
          />
          <TextField
            name={CardFieldNames.LiveRegion}
            label="front.card-edit.regRegion.label"
            maxLength={300}
            required
          />
          <TextField
            name={CardFieldNames.LiveDistrict}
            label="front.card-edit.regDistrict.label"
            maxLength={300}
            required
          />
          <TextField
            name={CardFieldNames.LivePostCode}
            label="front.card-edit.regPostCode.label"
            allowedCharacters={ONLY_NUMBER}
            length={5}
            required
          />
          <TextField
            name={CardFieldNames.LiveCity}
            label="front.card-edit.regCity.label"
            maxLength={300}
            required
          />
          <TextField
            name={CardFieldNames.LiveStreet}
            label="front.card-edit.regStreet.label"
            maxLength={300}
            required
          />
          <TextField
            name={CardFieldNames.LiveHouse}
            label="front.card-edit.regHouse.label"
            maxLength={300}
            required
          />
          <TextField
            name={CardFieldNames.LiveFlat}
            label="front.card-edit.regFlat.label"
            maxLength={300}
            required
          />
        </>
      )}
      <SelectField
        name={CardFieldNames.Branch}
        label="front.card-edit.branch.label"
        options={branch}
        required
      />
      <TextField
        name={CardFieldNames.BranchAdr}
        label="front.card-edit.branch-adr.label"
        disabled
      />
      <CheckboxField name={CardFieldNames.AddSMS} label="front.card-edit.addSMS.label" />
      <PhoneField name={CardFieldNames.Phone} label="front.card-edit.phone.label" />
      <CheckboxField
        name={CardFieldNames.AcceptConditions}
        label="front.card-edit.acceptConditions.label"
        required
      />
    </DefaultForm>
  );
};
