import * as React from 'react';

import classnames from 'classnames';

import './instructions.scss';

interface Props {
  isOpen: boolean;
  label: number;
}

export const IconRound: React.FC<Props> = props => {
  return (
    <div
      className={classnames(
        'icon-round',
        { 'icon-round--darkblue': props.isOpen },
        { 'icon-round--grey': !props.isOpen },
      )}
    >
      <span
        className={classnames('icon-round__content', {
          'icon-round__content--opened': props.isOpen,
        })}
      >
        {props.label}
      </span>
    </div>
  );
};
