import React from 'react';

import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextAreaField } from 'components/forms/inputs/TextAreaField';
import { useDisabledCustomer } from 'hooks/useDisabledCustomer';
import { CardFieldNames } from 'pages/Demands/CardDemand/enums';
import { CardPayload } from 'pages/Demands/CardDemand/interfaces';

interface CardBlockFields {
  [CardFieldNames.Customer]: string;
  [CardFieldNames.Card]: string;
  [CardFieldNames.BlockReason]: string;
  [CardFieldNames.Description]: string;
}

export const CardBlockDetail = () => {
  const {
    order,
    payload: { card, blockReason },
    onChangeCustomer,
  } = useOrderDetails<CardBlockFields, CardPayload>();

  const isDisabledCustomer = useDisabledCustomer(order);

  return (
    <DefaultForm>
      <CustomerSelect
        name={CardFieldNames.Customer}
        label="front.card-edit.organization.label"
        onSelectOption={onChangeCustomer}
        selectDefaultCustomer
        disabled={isDisabledCustomer}
        required
      />
      <SelectField
        name={CardFieldNames.Card}
        label="front.card-edit.card.label"
        options={card}
        required
      />
      <SelectField
        name={CardFieldNames.BlockReason}
        label="front.card-edit.blockReason.label"
        options={blockReason}
        required
      />
      <TextAreaField
        name={CardFieldNames.Description}
        label="front.card-edit.description.label"
        maxLength={240}
        rows={3}
        hasCounter
        required
      />
    </DefaultForm>
  );
};
