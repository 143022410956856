import React from 'react';
import { Action, Menu, TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { OrderType } from 'api/DemandsService/enums';
import { DemandResponse } from 'api/DemandsService/interfaces';
import { OrderActionType, PaymentTypes } from 'api/enums';
import { ExportType } from 'api/ExportService';
import { Salary, SalaryRequestInterface } from 'api/SalaryService';
import { getTotal, GridResponse, SortOrder } from 'api/Service';
import { getStatusColor } from 'api/utils';
import { Page } from 'components/layout/Page/Page';
import { Status } from 'components/layout/Status';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { DEFAULT_WIDTH, PrintingDocuments } from 'components/PrintingDocuments/PrintingDocuments';
import { ActionComponentType } from 'components/react-data-table/types';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { TotalCurrency } from 'components/TotalCurrency/TotalCurrency';
import * as format from 'components/utils/format';
import config from 'config';
import { translate } from 'i18n/translator';
import { allowPrivilegy, isActionAllowed } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { getTranslateAction } from 'pages/Payments/general';
import { SalaryPageFilter } from 'pages/Salary/SalaryFilter';
import { signCertificateOrders, signOrders } from 'pages/utils/SignOrders/signOrders';

interface SalaryFilterRequest extends Omit<SalaryRequestInterface, 'withSync'> {
  shouldUseIntegration: boolean;
}

const fetchSalaries = async (filter: SalaryFilterRequest): Promise<GridResponse<Salary>> => {
  const { shouldUseIntegration, ...otherFilter } = filter;

  const types = filter.types || [PaymentTypes.SALARY_SHEET];
  const orderStates = api.payments.getPaymentStatusesByKey((filter.orderStates as string) || null);

  const { rows, total } = await api.salary.getSalaries({
    ...otherFilter,
    types,
    orderStates,
    orderType: OrderType.SalarySheet,
    isPaymentOrder: true,
    withCurrencyTotals: true,
    withSync: shouldUseIntegration,
  });

  return { rows, total: getTotal(total) };
};

const filterByAction = (doc: DemandResponse, action: OrderActionType) => {
  return doc.actions.some(item => item.actionName === action);
};

const columns: TableColumnProps<DemandResponse>[] = [
  {
    label: 'front.menu.work-protocol.date.label',
    render: row => <DataColumn title={format.date(row.base.orderDate)} />,
    sort: 'orderDate',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.payroll-page.col-number.label',
    render: row => <DataColumn title={row.base.orderNumber} />,
    sort: 'orderNumberNumeric',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.payroll-page.col-sender.label',
    render: row => <DataColumn title={row.base.customerName} />,
    sort: 'name',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.payroll-page.col-status.label',
    render: row => (
      <DataColumn>
        <Status color={getStatusColor(row?.base.orderState)}>{row.base.orderStateLabel}</Status>
      </DataColumn>
    ),
    sort: 'orderState',
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.payroll-page.col-sum.label',
    render: row => {
      const field = row.dynamicFields.find(df => df.field.code === 'salaryTotal');
      const title =
        field && field.data && field.data.length
          ? format.getAmount(field.data[0].numericValue)
          : format.ZERO_MONEY;
      return <DataColumn title={title} subTitle="UAH" />;
    },
    sort: 'dynamicNumeric.salaryTotal',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    renderMenu: (row: DemandResponse): Menu<DemandResponse>[] => [
      {
        label: getTranslateAction(OrderActionType.EDIT),
        onClick: () => goto(pages.salary.tabs.details(row.id)),
        isVisible:
          filterByAction(row, OrderActionType.EDIT) &&
          isActionAllowed([Privileges.salarySheetEdit], row.base.customerId),
      },
      {
        label: getTranslateAction(OrderActionType.SIGN),
        onClick: async () => {
          const action = row.actions.find(item => item.actionName === OrderActionType.SIGN);
          if (
            action &&
            (action.confirmation.includes('Signature') || action.confirmation.includes('EDS'))
          ) {
            await signCertificateOrders([{ id: row.id, customerId: row.base.customerId }]);
          } else {
            await signOrders([row.id]);
          }
        },
        isReloadable: true,
        isVisible:
          filterByAction(row, OrderActionType.SIGN) &&
          isActionAllowed([Privileges.salarySheetSign], row.base.customerId),
      },
      {
        label: getTranslateAction(OrderActionType.PRINT),
        onClick: async () => await api.demands.getOrderPdf(row.id, true),
        isVisible:
          filterByAction(row, OrderActionType.PRINT) &&
          isActionAllowed([Privileges.salarySheetView], row.base.customerId),
      },
      {
        label: getTranslateAction(OrderActionType.COPY),
        onClick: () => goto(pages.salary.tabs.details('new', { copyFrom: row.id.toString() })),
        isReloadable: true,
        isVisible:
          filterByAction(row, OrderActionType.COPY) &&
          isActionAllowed([Privileges.salarySheetEdit], row.base.customerId),
      },
      {
        label: getTranslateAction(OrderActionType.DELETE),
        onClick: async () => await api.demands.deleteOrder(row.id),
        isReloadable: true,
        confirmMessage: 'front.working-documents-table.actions-delete-confirmation.label',
        isVisible:
          filterByAction(row, OrderActionType.DELETE) &&
          isActionAllowed([Privileges.salarySheetEdit], row.base.customerId),
      },
    ],
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

const actions: Action<DemandResponse>[] = [
  {
    label: 'front.misc.sign-subscribe-sms.label',
    action: async (data: DemandResponse[]): Promise<void> => {
      const orders = data.filter(item =>
        item.actions.some(
          action =>
            action.actionName === OrderActionType.SIGN &&
            action.confirmation.includes(config.signTypes.SMS),
        ),
      );

      if (!orders.length) {
        return;
      }

      await signOrders(orders.map(item => item.id));
    },
    isVisible: selected =>
      selected.some(item =>
        item.actions.some(
          action =>
            action.actionName === OrderActionType.SIGN &&
            action.confirmation.includes(config.signTypes.SMS),
        ),
      ) && allowPrivilegy([Privileges.salarySheetSign]),
    isReloadable: true,
  },
  {
    label: 'front.misc.sign-electronic-sign.label',
    action: async data => {
      const orders = data.filter(item =>
        item.actions.some(
          action =>
            action.actionName === OrderActionType.SIGN &&
            (action.confirmation.includes(config.signTypes.SIGNATURE) ||
              action.confirmation.includes(config.signTypes.EDS)),
        ),
      );
      if (!orders.length) {
        return;
      }
      await signCertificateOrders(
        orders.map(item => ({ id: item.id, customerId: item.base.customerId })),
      );
    },
    isVisible: selected =>
      selected.some(item =>
        item.actions.some(
          action =>
            action.actionName === OrderActionType.SIGN &&
            (action.confirmation.includes(config.signTypes.SIGNATURE) ||
              action.confirmation.includes(config.signTypes.EDS)),
        ),
      ) && allowPrivilegy([Privileges.salarySheetSign]),
    isReloadable: true,
  },
  {
    label: getTranslateAction(OrderActionType.PRINT),
    action: async selected =>
      await confirmModal(
        PrintingDocuments,
        {
          data: selected.filter(
            item =>
              filterByAction(item, OrderActionType.PRINT) &&
              isActionAllowed([Privileges.salarySheetView], item.base.customerId),
          ),
          objectCode: ExportType.SALARY_SHEET_ORDERS,
        },
        DEFAULT_WIDTH,
      ),
    color: 'secondary',
    isVisible: selected =>
      selected.some(
        item =>
          filterByAction(item, OrderActionType.PRINT) &&
          isActionAllowed([Privileges.salarySheetView], item.base.customerId),
      ),
  },
  {
    label: getTranslateAction(OrderActionType.DELETE),
    action: async data => {
      const orders = data.filter(
        item =>
          item.actions.some(action => action.actionName === OrderActionType.DELETE) &&
          isActionAllowed([Privileges.salarySheetEdit], item.base.customerId),
      );
      await Promise.all(
        orders.map(async (order: Obj) => {
          await api.demands.deleteOrder(order.id);
        }),
      );
    },
    color: 'secondary',
    confirmMessage: 'front.working-documents-table.actions-delete-confirmation.label',
    isVisible: selected =>
      selected.some(item =>
        item.actions.some(action => action.actionName === OrderActionType.DELETE),
      ) && allowPrivilegy([Privileges.salarySheetEdit]),
    isReloadable: true,
  },
];

const getCurrencyAmount = (row: DemandResponse): [string, number] => [
  row.financial.isoCode,
  row.financial.amount,
];

const ActionsComponent: ActionComponentType<DemandResponse> = ({
  total,
  selectedItems,
  children,
}) => (
  <TotalCurrency<DemandResponse>
    total={total}
    selectedItems={selectedItems}
    getCurrencyAmount={getCurrencyAmount}
  >
    {children}
  </TotalCurrency>
);

export const SalariesPage: React.FC = () => (
  <Page title={translate('front.cash-info.title.label')}>
    <DataTable
      name={TableNamesEnum.SalaryPage}
      columns={columns}
      fetchRows={fetchSalaries}
      onRowClick={row => goto(pages.salary.tabs.details(row.id))}
      actions={actions}
      defaultSort="id"
      defaultOrder={SortOrder.Desc}
      filterComponent={SalaryPageFilter}
      actionsComponent={ActionsComponent}
    />
  </Page>
);
