import { get } from './backend';
import { GridResponse } from './Service';

export interface Announcement {
  deleted?: boolean;
  externalId?: number;
  head?: string;
  id?: number;
  link?: string;
  newsDate?: Date;
  newsDateCreate?: Date;
  newsDatePublication?: Date;
  newsDesc?: string;
  status?: boolean;
}

export class AnnouncementsService {
  async getAnnouncements(isActual: boolean): Promise<GridResponse<Announcement>> {
    return await get('/news/customer-news', {
      isActual,
      order: 'desc',
      sort: 'publication_time',
    });
  }
}
