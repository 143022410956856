import * as React from 'react';

import {
  CertificateContext,
  ICertificateContext,
} from 'pages/Profile/Certificates/KepCertificate/KepCertificateContext';

export const useExpiredError = () => {
  const { expiredError } = React.useContext<ICertificateContext>(CertificateContext);

  return { expiredError };
};
