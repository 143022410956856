import * as React from 'react';
import { Col, Row } from 'react-grid';
import { useSelector } from 'react-redux';

import { api } from 'api';
import { PaymentTypes } from 'api/enums';
import { ExportType } from 'api/ExportService';
import { IMPORT_TYPES, Option } from 'api/Service';
import { Button } from 'components/buttons/Button';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { ExportFormatEnum } from 'components/ExportDropdown/enum';
import {
  ExportDropdown,
  getExportActions,
  TExportTypes,
} from 'components/ExportDropdown/ExportDropdown';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { DateRangeField } from 'components/forms/inputs/DateRangeField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextField } from 'components/forms/inputs/TextField';
import { withForm } from 'components/forms/withForm';
import * as Icon from 'components/icons';
import { PageHeader } from 'components/layout/Page/PageHeader';
import { SearchInput } from 'components/searchInput/SearchInput';
import { CreateButton } from 'components/Table/CreateButton';
import { useFilter } from 'components/Table/DataTable';
import { ToggleFilterButton } from 'components/Table/ToggleFilterButton/ToggleFilterButton';
import { FilterTabBar } from 'components/tabs/FilterTabBar';
import { useStoragePeriod } from 'hooks/useStoragePeriod';
import { translate } from 'i18n/translator';
import { DisabledBlock } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages, PAYMENTS } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { NEW } from 'navigations/routes';
import { InternalPaymentFilterFormNames } from 'pages/Payments/InternalPayment/enums';
import { useStorageFilterParams } from 'pages/Payments/InternalPayment/hooks/useStorageFilterParams';
import { InternalPaymentFilterFields } from 'pages/Payments/InternalPayment/interface';
import { DEFAULT_PAYMENT_TYPES } from 'pages/Payments/InternalPayment/utils';
import { selectChosenCustomersId } from 'store/selectors';

interface ExtraFields {
  orderStates: string;
  payerIds: string[];
  searchText: string;
}

const EXPORT_TYPES_PAYMENT: TExportTypes = {
  XLS: ExportFormatEnum.EXCEL,
  CSV: ExportFormatEnum.CSV,
  DBF: ExportFormatEnum.DBF,
  XML: ExportFormatEnum.XML,
};

export const PERIOD_KEY = 'period';

const typesOptions: Option[] = [
  {
    value: PaymentTypes.INTERNAL_TRANSFER,
    label: 'front.internal-payment-page.order-internal-type.title.label',
  },
  {
    value: PaymentTypes.DOMESTIC_TRANSFER,
    label: 'front.internal-payment-page.order-external-type.title.label',
  },
];

const InternalPaymentFilterForm: React.FC = () => {
  const defaultSelectedCustomerIds = useSelector(selectChosenCustomersId()).map(id => `${id}`);

  const { defaultAccountIds, defaultCustomerIds, clearStorageParams } = useStorageFilterParams();

  const { datePeriodFrom, datePeriodTo, onApplyPeriodChanges, clearPeriod } =
    useStoragePeriod(PAYMENTS);

  const onMount = () => fetchData();

  const {
    updateFilter,
    extraFields,
    setPayload,
    payload,
    setProgress,
    updateData,
    setData,
    progress,
    getFormData,
    setPagination,
  } = useFilter<InternalPaymentFilterFields, ExtraFields>({
    extraFields: {
      searchText: '',
      orderStates: 'all',
      payerIds: defaultAccountIds,
    },
    fetchInitial: () => {
      const hasDefaultCustomerIds =
        defaultCustomerIds.length > 0 || defaultSelectedCustomerIds.length === 1;

      if (hasDefaultCustomerIds) {
        return onMount();
      }
    },
  });

  const fetchData = async (customerIds?: string[]) => {
    const accountOptions = await api.payments.getAccountsForFilterPayment(
      customerIds ?? defaultCustomerIds,
    );

    const hasSomeNoExistAccountId = defaultAccountIds.some(
      id => !accountOptions.some(account => account.value === id),
    );

    setData({ payerIds: hasSomeNoExistAccountId ? null : defaultAccountIds });
    setPayload({ accountOptions });
  };

  const onCustomerSelect = async (value: string[]) => {
    setProgress(true);
    updateData({ [InternalPaymentFilterFormNames.PayerIds]: null });

    value.length && (await fetchData(value));
    setProgress(false);
  };

  const exportActions = React.useMemo(() => {
    const filter = {
      ...extraFields,
      types: extraFields.types ? [extraFields.types] : DEFAULT_PAYMENT_TYPES,
      orderStates: api.payments.getPaymentStatusesByKey(extraFields.orderStates),
    };

    const exportOrderAction = (exportType: string) => () => {
      if (exportType === ExportFormatEnum.XML) {
        const params = {
          ...filter,
          objectCode: ExportType.DOMESTIC_PAYMENT_ORDERS,
          format: ExportFormatEnum.XML,
        };

        return api.export.exportOrders(params);
      }

      return api.payments.exportOrder({
        ...filter,
        exportType,
      });
    };

    return getExportActions({
      action: exportOrderAction,
      exportTypes: EXPORT_TYPES_PAYMENT,
    });
  }, [extraFields]);

  const handleClearFilter = () => {
    clearPeriod();
    clearStorageParams();
  };

  const { customerIds = [] } = getFormData() as InternalPaymentFilterFields;

  const validAccountOptions = React.useMemo(
    () => (customerIds.length ? payload.accountOptions : []),
    [customerIds, payload.accountOptions],
  );

  return (
    <div>
      <PageHeader.Right>
        <ExportDropdown actions={exportActions} />
      </PageHeader.Right>
      <FilterTabBar
        onChange={orderStates => updateFilter({ orderStates })}
        value={extraFields.orderStates}
        options={api.payments.getPaymentOptionStatuses()}
        setPagination={setPagination}
      />
      <Row>
        <Col lg={7} md={12}>
          <DisabledBlock privileges={[Privileges.paymentDomesticEdit]}>
            <CreateButton
              title="front.working-documents.create-payment.label"
              onClick={() => {
                goto(pages.internalPayments.tabs.details(NEW));
              }}
            />
            <Button
              label={translate('front.internal-payment-page.import-button.label')}
              color="secondary"
              onClick={() => {
                goto(pages.import, { type: IMPORT_TYPES.DOMESTIC_PAYMENTS });
              }}
            />
          </DisabledBlock>
          <Button
            label={translate('front.internal-payments.refresh')}
            icon={<Icon.Update />}
            color="secondary"
            onClick={() => updateFilter({})}
          />
        </Col>
        <Col lg={3} md={8}>
          <SearchInput
            placeholder={translate('front.working-documents-table.filter-search.label')}
            value={extraFields.searchText}
            onChange={searchText => updateFilter({ searchText })}
          />
        </Col>
        <Col lg={2} md={4}>
          <ToggleFilterButton
            smallMode
            onApplyFilter={onApplyPeriodChanges}
            onCloseFilter={onApplyPeriodChanges}
            onResetFilter={handleClearFilter}
            disabled={progress}
          >
            <Row>
              <Col md={12}>
                <CustomerSelect
                  label="front.internal-payment-page.order-payer-name.label"
                  name={InternalPaymentFilterFormNames.CustomerIds}
                  onSelectOption={onCustomerSelect}
                  defaultValue={defaultCustomerIds}
                  multi
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <SelectField
                  label="front.i18n-payments.filter-sender-score.label"
                  name={InternalPaymentFilterFormNames.PayerIds}
                  options={validAccountOptions}
                  clearable
                  multi
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <TextField
                  label="front.working-documents-filter-form.number-document.label"
                  name={InternalPaymentFilterFormNames.OrderNumber}
                />
              </Col>
              <Col md={4}>
                <TextField
                  label="front.working-documents-filter-form.tax-code.label"
                  name={InternalPaymentFilterFormNames.ReceiverTaxCode}
                />
              </Col>
              <Col md={4}>
                <SelectField
                  label="front.working-documents-filter-form.type.label"
                  name={InternalPaymentFilterFormNames.Types}
                  options={typesOptions}
                  clearable
                />
              </Col>
              <Col md={4}>
                <TextField
                  label="front.working-documents-filter-form.receiver-account-name.label"
                  name={InternalPaymentFilterFormNames.ReceiverName}
                />
              </Col>
              <Col md={4}>
                <TextField
                  label="front.working-documents-filter-form.receiver-account.label"
                  name={InternalPaymentFilterFormNames.ReceiverAccount}
                />
              </Col>
              <Col md={4}>
                <TextField
                  label="front.working-documents-filter-form.receiver-bic.label"
                  name={InternalPaymentFilterFormNames.ReceiverBic}
                />
              </Col>
              <Col md={4}>
                <AmountField
                  label="front.working-documents-filter-form.amount-from.label"
                  name={InternalPaymentFilterFormNames.AmountFrom}
                />
              </Col>
              <Col md={4}>
                <AmountField
                  label="front.working-documents-filter-form.amount-to.label"
                  name={InternalPaymentFilterFormNames.AmountTo}
                />
              </Col>
              <Col md={4}>
                <DateRangeField
                  label="front.working-documents-table.date-range.label"
                  nameFrom={InternalPaymentFilterFormNames.DateFrom}
                  nameTo={InternalPaymentFilterFormNames.DateTo}
                  defaultFrom={datePeriodFrom}
                  defaultTo={datePeriodTo}
                  clearable
                />
              </Col>
            </Row>
          </ToggleFilterButton>
        </Col>
      </Row>
      <hr />
    </div>
  );
};

export const InternalPaymentFilter = withForm(InternalPaymentFilterForm);
