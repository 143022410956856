import React from 'react';

import { ErrorType } from 'api/PaymentService/enums';
import { TableSortType } from 'components/react-data-table/types';
import { OS } from 'config';
import { isExist, isString } from 'utils/isData';

export enum IMPORT_TYPES {
  SALARY = 'SalarySheet',
  SALARY_EMPLOYEES = 'SalaryEmployeeNew',
  DOMESTIC_PAYMENTS = 'DomesticTransfer',
  FOREIGN_PAYMENTS = 'ForeignTransfer',
  COUNTERPARTIES = 'Counterparties',
  CURRENCY_BUY = 'CurrencyBuy',
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export enum FileType {
  FILE = 'File',
  BASE_64 = 'Base64',
  BLOB = 'Blob',
}

export enum FILETYPE_PRESETS {
  'DOCUMENTS',
  'IMAGES',
  'PDF',
  'DOC',
  'CSV',
  'XLS',
  'DBF',
  'ARCHIVES',
  'XML',
}

export enum NullFields {
  LAST = 'last',
  NATIVE = 'native',
}

export interface HasId {
  [key: string]: any;

  id: number;

  date?: string;
}

export interface GridRequest {
  order?: SortOrder;
  page?: number;
  shouldUseIntegration?: boolean;
  size?: number;
  sort?: TableSortType;
}

export interface Turnover {
  credit: number;
  creditNc: number;
  debit: number;
  debitNc: number;
}

export interface TotalInterface {
  count: number;
  balances?: Obj<number>;
  byCurrency?: Obj<number>;
  quantity?: Obj<number>;
  status?: Obj<number>;
  turnover?: Record<string, Turnover>;
}

export interface GridResponse<R> {
  rows: R[];
  total: TotalInterface;
  showAll?: boolean;
}

export interface Meta {
  deprecated: boolean;
  messages: MetaMessages[];
}

export interface MetaMessages {
  code: string;
  level: string;
  message: string;
  params: MetaParams;
  target: string;
}

export interface MetaParams {
  ERROR_TYPE?: ErrorType;
  EVENT_LEVEL?: string;
  ROW_NUMBER?: string;
  VALUE?: string;
}

export interface GridResponseWithMeta<R> extends GridResponse<R> {
  meta: Meta;
}

export interface Option<C = any, V = string> {
  content?: C;
  label?: any;
  value?: V;
}

export function searchText(where: any, text: string): boolean {
  return (where?.toString() || '').toLowerCase().includes((text || '').toLocaleLowerCase());
}

export const compareString = (a: string, b: string) => a.localeCompare(b);

export const compareNumber = (a: number, b: number) => a - b;

export type Predicate<T> = (arg: T) => boolean;

export function getPage<T>(
  raw: T[] = [],
  request: GridRequest,
  filter?: Predicate<T>,
): GridResponse<T> {
  const { page = 0, size = raw.length, sort, order = SortOrder.Asc } = request;

  let data: T[] = raw ?? [];

  if (filter) {
    data = data.filter(filter);
  }

  if (typeof sort === 'string') {
    const isStringValue = data.some((item: Obj) => isExist(item[sort]) && isString(item[sort]));

    const compare: (a: any, b: any) => number = isStringValue ? compareString : compareNumber;

    data = data.sort((a: Obj, b: Obj) =>
      order === SortOrder.Desc
        ? compare(b[sort] ?? '', a[sort] ?? '')
        : compare(a[sort] ?? '', b[sort] ?? ''),
    );
  }

  const rows = data.slice(page * size, page * size + size);

  return {
    rows,
    total: {
      count: data.length,
    },
  };
}

const cacheContainer = new Map();

type MergeStateAction<T> = Partial<T> | ((prevState: T) => T);

export const useMergeState = <T>(initialState: T): [T, React.Dispatch<MergeStateAction<T>>] => {
  const [state, setBaseState] = React.useState<T>(initialState);
  const setState: React.Dispatch<MergeStateAction<T>> = arg => {
    if (typeof arg === 'function') {
      setBaseState(arg);
    } else {
      setBaseState(prevState => ({ ...prevState, ...(arg as Partial<T>) }));
    }
  };

  return [state, setState];
};

export const cache = (
  target: any,
  propertyName: string,
  descriptor: TypedPropertyDescriptor<any>,
) => {
  const impl = descriptor.value;

  descriptor.value = function (...args: any[]) {
    const name = `${target.constructor.name}:${propertyName}:${args.join('-')}`;
    const cacheValue = cacheContainer.get(name);

    if (cacheValue) return cacheValue;

    const result = impl.apply(this, args);
    cacheContainer.set(name, result);

    return result;
  };
};

export class BooleanBuilder {
  constructor(private value: boolean = false) {}

  or(value: boolean) {
    this.value = this.value || value;
  }

  and(value: boolean) {
    this.value = this.value && value;
  }

  apply() {
    return this.value;
  }
}

export const checkOS = (): OS => {
  if (navigator.appVersion.indexOf('Win') !== -1) return OS.Windows;
  if (navigator.appVersion.indexOf('Mac') !== -1) return OS.MacOS;
  if (navigator.appVersion.indexOf('Linux') !== -1) return OS.Linux;

  return OS.Windows;
};

export const getTotal = ({ count, byCurrency, quantity }: TotalInterface) => {
  const currencies = Object.entries(byCurrency).map(item => {
    const [currency, amount] = item;

    return {
      currency,
      amount,
      count: quantity?.[currency],
    };
  });

  return {
    currencies,
    count,
  };
};
