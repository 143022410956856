import * as React from 'react';

import { Instruction } from 'api/HandbooksService';
import { CollapseNew } from 'components/utils/CollapseNew';
import { InstructionHeader } from 'pages/Instructions/components/InstructionHeader';
import { InstructionInfo } from 'pages/Instructions/components/InstructionInfo';

import './instructions.scss';

interface Props {
  instructionsList: Instruction[];
}

const createCollapseTitle =
  (instruction: Instruction, idx: number) => (isOpen: boolean, onToggleShow: () => void) =>
    (
      <InstructionHeader
        label={instruction.name}
        idx={idx}
        isOpen={isOpen}
        onToggleShow={onToggleShow}
      />
    );

const renderInstructionItem = (instruction: Instruction, idx: number) => {
  return (
    <CollapseNew
      key={instruction.id}
      renderHeader={createCollapseTitle(instruction, idx + 1)}
      content={
        <div className="instructions-wrapped">
          <InstructionInfo description={instruction.description} />
          {instruction.children.length > 0 && (
            <InstructionList instructionsList={instruction.children} />
          )}
        </div>
      }
    />
  );
};

export const InstructionList: React.FC<Props> = ({ instructionsList }) => {
  return <>{instructionsList.map(renderInstructionItem)}</>;
};
