export enum MailActions {
  Edit = 'edit',
  View = 'view',
}

export enum MailFieldsEnum {
  Id = 'id',
  Subject = 'subject',
  Desc = 'desc',
  CompanyId = 'companyId',
  Files = 'files',
  IsFromCustomer = 'isFromCustomer',
  MessageAttachmentIds = 'messageAttachmentIds',
}
