import * as React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';

import { api } from 'api';
import {
  AddNotification,
  Channel,
  ChannelTypes,
  NotificationType,
  NotificationTypeCode,
  NotificationTypeObject,
} from 'api/NotificationService';
import { Option } from 'api/Service';
import { Button } from 'components/buttons/Button';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { Page } from 'components/layout/Page/Page';
import { confirmationModal } from 'components/modals/ConfirmModal';
import { translate } from 'i18n/translator';
import { isDeniedAction } from 'navigations/access';
import { goBack, goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { NEW } from 'navigations/routes';
import { NotificationDetail } from 'pages/Notifications/enum';
import { getAccountOptions } from 'pages/Notifications/utils';
import { selectDefaultCustomerId } from 'store/selectors';
import { isExist } from 'utils/isData';

import './notifications.scss';

interface Fields {
  [NotificationDetail.NotificationTypeId]: number;
  email: boolean;
  internal: boolean;
  sms: boolean;
  [NotificationDetail.Account]: number[];
  [NotificationDetail.CustomerId]: number;
  id: number;
  [NotificationDetail.WithRevaluation]?: boolean;
}

interface Payload {
  accountOptions: Option[];
  channels: Channel[];
  isAccount: boolean;
  targetParams: Channel[];
  typeOptions: Option<NotificationType, string>[];
  [NotificationDetail.WithRevaluation]?: boolean;
}

type CheckedChannels = {
  [key in ChannelTypes]?: boolean;
};

const STATEMENT_NOTIFICATION_TYPE = 'STATEMENT';

const deleteNotification = async (id: string) => {
  const shouldDel = await confirmationModal('front.notification-modal.confirm-delete.label');
  if (!shouldDel) return;
  await api.notification.deleteNotificationTarget(id);
  goto(pages.notifications);
};

const NotificationPageForm = () => {
  const editId = useRouteMatch<{ id: string }>().params.id;
  const isNew = editId === NEW;

  const defaultCustomerId = useSelector(selectDefaultCustomerId());

  const [checkedChannels, setCheckedChannels] = React.useState<CheckedChannels>({});

  const [shouldRevaluationRender, setRevaluationRender] = React.useState<boolean>(false);

  const onRevaluationShow = (type: string) =>
    setRevaluationRender(type.startsWith(STATEMENT_NOTIFICATION_TYPE));

  const {
    handleSubmit,
    progress,
    payload,
    error,
    setError,
    getFormData,
    updateData,
    setPayload,
    setInitialingCb,
  } = useForm<Fields, Payload>(async ({ setFields }) => {
    const typeOptions = await api.notification.getNotificationTypeOptions();

    if (!isNew) {
      const notification = await api.notification.getSingleNotification(+editId);

      const {
        id: notificationId,
        notificationTypeId,
        customerId,
        objectId,
        targetParams,
        objectParam,
      } = notification;

      const sms = targetParams.some(tp => tp.channelCode === ChannelTypes.SMS);
      const email = targetParams.some(tp => tp.channelCode === ChannelTypes.EMAIL);
      const internal = targetParams.some(tp => tp.channelCode === ChannelTypes.INTERNAL);
      const notificationType = (typeOptions.find(o => o.content.id === notificationTypeId) || {})
        .content;
      const channels = notificationType ? notificationType.params.filter(p => p.isActive) : [];
      const isAccount = !!objectId;
      const accountOptions = isAccount ? await getAccountOptions(customerId) : [];

      const parsedObjectParam = JSON.parse(objectParam);
      const withRevaluation = parsedObjectParam?.withRevaluation ?? false;

      setPayload({
        channels,
        typeOptions,
        targetParams,
        accountOptions,
        isAccount,
        withRevaluation,
      });

      setFields({
        sms,
        email,
        internal,
        notificationTypeId,
        customerId,
        id: notificationId,
        account: [+objectId],
      });

      setCheckedChannels({
        [ChannelTypes.SMS]: sms,
        [ChannelTypes.EMAIL]: email,
        [ChannelTypes.INTERNAL]: internal,
      });

      onRevaluationShow(notificationType.code);
    } else {
      setPayload({ typeOptions });
    }
  });

  const { withRevaluation: revaluationCheckboxValue } = getFormData();

  const { id, customerId, notificationTypeId, account, email } = getFormData();

  const setAccounts = async (customerId: number) => {
    const accountOptions = await getAccountOptions(+customerId);
    setPayload({ accountOptions });
  };

  React.useEffect(() => {
    if (isNew && customerId) {
      setAccounts(customerId);
    }
  }, [customerId]);

  const { accountOptions, typeOptions, isAccount, channels, targetParams, withRevaluation } =
    payload;

  const notificationTypeCode =
    isExist(notificationTypeId) &&
    typeOptions.find(({ value }) => +value === +notificationTypeId)?.content?.code;

  const isDebitFromAccount = notificationTypeCode === NotificationTypeCode.DEBIT_ACCOUNT;
  const isNotificationWithOutCustomer =
    notificationTypeCode === NotificationTypeCode.CERTIFICATE_EXPIRING ||
    notificationTypeCode === NotificationTypeCode.SUCCESS_SIGN_IN;

  const allowAccountOptions = React.useMemo(() => {
    return isDebitFromAccount
      ? accountOptions?.filter(({ content }) => content.allowDebit)
      : accountOptions;
  }, [notificationTypeId, accountOptions]);

  const isNoChannelSelected =
    Object.keys(checkedChannels).filter(channelName => checkedChannels[channelName as ChannelTypes])
      .length === 0;

  React.useEffect(() => {
    if (!isNotificationWithOutCustomer && !customerId) {
      updateData({ customerId: defaultCustomerId });
    }
  }, [isNotificationWithOutCustomer]);

  const selectedNotifyType = typeOptions?.find(t => t.content.id === +notificationTypeId)?.content;

  const createOnChannelChange = ({ channelCode }: Channel, checked: boolean) =>
    setCheckedChannels({ ...checkedChannels, [channelCode]: checked });

  const createNotification = async () => {
    if (isNoChannelSelected) {
      return setError(translate('front.notification-modal.channels-error.label'));
    }

    const targetParams = Object.entries(checkedChannels).map(([channelCode]) => ({
      channelCode: channelCode as ChannelTypes,
    }));

    const newNotifyTargets: AddNotification[] = [];

    if (!isAccount) {
      newNotifyTargets.push({
        notificationTypeId,
        targetParams,
        customerId: +customerId || null,
        objectId: '',
      });
    } else {
      account.forEach(accountId =>
        newNotifyTargets.push({
          notificationTypeId,
          targetParams,
          customerId: +customerId || null,
          objectId: `${accountId || ''}`,
          objectParam: JSON.stringify({
            [NotificationDetail.WithRevaluation]: revaluationCheckboxValue,
          }),
        }),
      );
    }

    const addedTargets = await Promise.all(
      newNotifyTargets.map(api.notification.addNotificationTarget),
    );

    // adds each channel as target param for each notification target
    await Promise.all(
      addedTargets.map(({ id }) =>
        targetParams.map(param => api.notification.setNotificationParam(id, param.channelCode)),
      ),
    );

    goBack();
  };

  const editNotification = async () => {
    if (isNoChannelSelected) {
      return setError(translate('front.notification-modal.channels-error.label'));
    }

    const promises: Promise<void>[] = Object.entries(checkedChannels).map(([code, checked]) => {
      const channelCode = code as ChannelTypes;
      const paramId = targetParams.find(tp => tp.channelCode === channelCode)?.id;

      if (checked && !isExist(paramId)) {
        return api.notification.setNotificationParam(id, channelCode);
      }
      if (!checked && isExist(paramId)) {
        return paramId && api.notification.unsetNotificationParam(paramId);
      }
    });
    await Promise.all(promises);

    if (email) {
      await api.notification.editNotificationTarget(
        id,
        notificationTypeId,
        JSON.stringify({
          [NotificationDetail.WithRevaluation]: revaluationCheckboxValue,
        }),
      );
    }

    goBack();
  };

  const onSave = async () => (isNew ? await createNotification() : await editNotification());

  const onChangeCustomer = async () => {
    updateData({ account: null });
  };

  const onSelectType = async (value: string) => {
    const typeId = +value;
    const notificationType = typeOptions.find(o => o.content.id === typeId)?.content;
    const isAccount = notificationType.notificationTypeObject === NotificationTypeObject.PERSON;
    const channels = notificationType ? notificationType.params.filter(p => p.isActive) : [];

    onRevaluationShow(notificationType.code);

    setPayload({ isAccount, channels });
  };

  const title = !isNew
    ? translate('front.notification-modal.change-notif.label')
    : translate('front.notification-modal.add-notif.label');

  const allowCustomer = selectedNotifyType?.notificationTypeObject !== NotificationTypeObject.USER;

  const disableButton = isDeniedAction([Privileges.contactNotificationEdit], customerId);

  return (
    <Page title={title}>
      <DefaultForm disabled={progress}>
        <FormError>{error}</FormError>
        {allowCustomer && (
          <CustomerSelect
            name={NotificationDetail.CustomerId}
            label="front.organization.col-organization.label"
            disabled={!isNew}
            onSelectOption={setInitialingCb(onChangeCustomer)}
            selectDefaultCustomer
            required
          />
        )}
        <SelectField
          name={NotificationDetail.NotificationTypeId}
          label="front.notification-modal.notification-type.label"
          required
          disabled={!isNew}
          options={typeOptions}
          onSelectOption={onSelectType}
        />

        {isAccount && (
          <SelectField
            name={NotificationDetail.Account}
            label="front.notification-modal.account.label"
            options={allowAccountOptions}
            required
            multi
            disabled={!isNew}
          />
        )}
        <div className="checkbox-wrapper">
          <div>
            {channels?.map(item => (
              <CheckboxField
                key={item.id}
                name={item.channelCode.toLowerCase()}
                onChange={value => createOnChannelChange(item, value)}
                label={item.channelCode[0].toUpperCase() + item.channelCode.slice(1).toLowerCase()}
              />
            ))}
          </div>
          {shouldRevaluationRender && (
            <div>
              <CheckboxField
                label="front.account-statements-filter-form.show-revaluation.label"
                name={NotificationDetail.WithRevaluation}
                defaultValue={withRevaluation}
              />
            </div>
          )}
        </div>
        <>
          <Button
            onClick={e => handleSubmit(onSave, e)}
            progress={progress}
            type="submit"
            disabled={disableButton}
          >
            {translate('front.сounterparty-form.save-button.label')}
          </Button>

          {!isNew ? (
            <Button
              onClick={() => deleteNotification(editId)}
              color="secondary"
              disabled={disableButton}
            >
              {translate('front.working-documents-table.actions-delete.label')}
            </Button>
          ) : (
            <Button onClick={goBack} color="secondary">
              {translate('front.card-block.cancel.label')}
            </Button>
          )}
        </>
      </DefaultForm>
    </Page>
  );
};

export const NotificationPage = withForm(NotificationPageForm);
