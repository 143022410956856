import * as React from 'react';

import { Page } from 'components/layout/Page/Page';

import { RolePermissions } from './RolePermissions';

export const EditRolePage: React.FC = () => (
  <Page title="front.role-page.edit-title.label">
    <RolePermissions />
  </Page>
);
