import React from 'react';
import { flushSync } from 'react-dom';

import moment from 'moment';

import { OrderState, VatType } from 'api/enums';
import { Order } from 'api/OrderService';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { PaymentOrderFields } from 'pages/Payments/InternalPayment/interface';
import { createNdsPurpose, createPurpose } from 'pages/Payments/InternalPayment/utils';
import { usePrevious } from 'utils/hooks';

export interface UseUpdatePurposePropsType {
  order: Order;
  vatPercent: number | string;
}

export const useUpdatePurpose = ({ order, vatPercent }: UseUpdatePurposePropsType) => {
  const { getFormData, setPayload, updateData } = useForm<PaymentOrderFields>();
  const formFields = getFormData();

  const {
    vatType,
    budgetCode,
    payerTaxCode,
    explanatoryInfo,
    budgetDate,
    budgetNumber,
    reserve,
    amount,
    purpose,
    isCustomBudgetPurpose,
  } = formFields;

  const prevVatPercent = usePrevious(vatPercent);

  const updatePurpose = () => {
    const vatTypeEmptyPurpose = `*;${[
      budgetCode || '',
      payerTaxCode || '',
      explanatoryInfo || '',
      budgetDate ? moment(budgetDate).format('DD.MM.YYYY') : '',
      budgetNumber || '',
      reserve || '',
    ].join(';')}`;

    const correctPurpose = {
      [VatType.EMPTY]: vatTypeEmptyPurpose,
      [VatType.WITHOUT_VAT]: purpose?.replace(`${vatTypeEmptyPurpose}`, ''),
      [VatType.INCLUDE_VAT20]: purpose?.replace(`${vatTypeEmptyPurpose}`, ''),
      [VatType.TAXES_PAYMENT]: '',
    };

    const newPurpose = createPurpose({
      amount,
      vatType,
      vatPercent,
      prevVatPercent,
      purpose: correctPurpose[vatType],
    });

    queueMicrotask(() => {
      flushSync(() => {
        setPayload({ templatePurpose: createNdsPurpose(vatType, amount, vatPercent) });
        updateData({
          purpose: newPurpose,
        });
      });
    });
  };

  React.useEffect(() => {
    const isDraft = order && (order.state === OrderState.Draft || !order.state);

    if (isDraft && vatType && !isCustomBudgetPurpose) {
      updatePurpose();
    }
  }, [
    order,
    vatType,
    budgetCode,
    payerTaxCode,
    explanatoryInfo,
    budgetDate,
    budgetNumber,
    reserve,
    amount,
    vatPercent,
    prevVatPercent,
    isCustomBudgetPurpose,
  ]);
};
