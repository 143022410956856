import React from 'react';

import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { DateField } from 'components/forms/inputs/DateField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextAreaField } from 'components/forms/inputs/TextAreaField';
import { useDisabledCustomer } from 'hooks/useDisabledCustomer';
import { translate } from 'i18n/translator';
import { DepositDetailNames } from 'pages/Demands/DepositDemand/enums';
import { disabledDaysBeforeToday } from 'pages/Demands/utils';

interface DepositClosingFields {
  [DepositDetailNames.Customer]: string;
  [DepositDetailNames.Deposit]: string;
  [DepositDetailNames.CloseDate]: Date;
  [DepositDetailNames.CloseReason]: string;
  [DepositDetailNames.AcceptConditions]: boolean;
}

export const DepositClosingDetail = () => {
  const {
    order,
    onChangeCustomer,
    payload: { deposit },
  } = useOrderDetails<DepositClosingFields>();

  const isDisabledCustomer = useDisabledCustomer(order);

  return (
    <DefaultForm>
      <h5>{translate('front.deposit-demand.titleLabel.label')}</h5>
      <CustomerSelect
        name={DepositDetailNames.Customer}
        label="front.deposit-demand.organization.label"
        onSelectOption={onChangeCustomer}
        selectDefaultCustomer
        disabled={isDisabledCustomer}
        required
      />
      <SelectField
        name={DepositDetailNames.Deposit}
        label="front.deposit-demand.deposit.label"
        options={deposit}
        required
      />
      <DateField
        name={DepositDetailNames.CloseDate}
        label="front.deposit-demand.closeDate.label"
        disabledDays={disabledDaysBeforeToday}
        required
      />
      <TextAreaField
        name={DepositDetailNames.CloseReason}
        rows={3}
        label="front.deposit-demand.closeReason.label"
        maxLength={350}
        hasCounter
        required
      />
      <h5>{translate('front.deposit-demand.return-funds.label')}</h5>
      <CheckboxField
        name={DepositDetailNames.AcceptConditions}
        label="front.deposit-demand.acceptConditions.label"
        required
      />
    </DefaultForm>
  );
};
