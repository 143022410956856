import * as React from 'react';
import { Action, TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { Terminal } from 'api/AcquiringService';
import { getPage, GridRequest, SortOrder } from 'api/Service';
import { Page } from 'components/layout/Page/Page';
import { PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { translate } from 'i18n/translator';

const translateStatus = (doc: Terminal) => {
  return translate(`front.acquiring.terminal.status.${doc.productStatus.toLowerCase()}.label`);
};

const terminalModalColumns: TableColumnProps<Terminal>[] = [
  {
    label: 'front.acquiring.trade-point.label',
    render: doc => <DataColumn title={doc.baseName} subTitle={''} />,
    sort: 'baseName',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.acquiring.terminal.address',
    render: doc => <DataColumn title={doc.baseName} />,
    sort: 'baseName',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.acquiring.terminal.label',
    render: doc => <DataColumn title={doc.externalId} />,
    sort: 'externalId',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.acquiring.terminal.status',
    render: doc => <DataColumn title={translateStatus(doc)} />,
    sort: 'productStatus',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

type TerminalsModalProps = PropsWithConfirmModal<{ id: number }>;

export const TerminalsModal = ({ id, resolve }: TerminalsModalProps) => {
  const fetchTerminals = async (request: GridRequest) => {
    const fetchTerminals = await api.acquiring.getTerminals(id as number, request);
    return getPage(fetchTerminals, request, null);
  };

  const actions: Action<Terminal>[] = [
    {
      label: 'front.acquiring.terminal.add',
      action: async data => {
        resolve(data.map(item => item.id));
      },
    },
  ];

  return (
    <Page title={translate('front.acquiring.terminal.list')} headerStyle={{ padding: '24px' }}>
      <DataTable
        name={TableNamesEnum.TerminalList}
        columns={terminalModalColumns}
        fetchRows={fetchTerminals}
        defaultSort="id"
        defaultOrder={SortOrder.Asc}
        actions={actions}
      />
    </Page>
  );
};
