import * as React from 'react';
import { Col, Row } from 'react-grid';
import { useLocation } from 'react-router';

import moment from 'moment';

import { api } from 'api';
import { ExportStatementRequest } from 'api/AccountService';
import { parseUrlParams } from 'api/backend';
import { GridRequest } from 'api/Service';
import { Button } from 'components/buttons/Button';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { Actions, ExportDropdown } from 'components/ExportDropdown/ExportDropdown';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { DateRangeField } from 'components/forms/inputs/DateRangeField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { withForm } from 'components/forms/withForm';
import { PageHeader } from 'components/layout/Page/PageHeader';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { CreateButton } from 'components/Table/CreateButton';
import { useFilter } from 'components/Table/DataTable';
import { ToggleFilterButton } from 'components/Table/ToggleFilterButton/ToggleFilterButton';
import { timeDateFull } from 'components/utils/format';
import { translate } from 'i18n/translator';
import { DisabledBlock } from 'navigations/access';
import { STATEMENTS } from 'navigations/pages';
import { EmailProps } from 'pages/AccountStatements/AccountStatementsPage';
import { EmailModal } from 'pages/AccountStatements/EmailModal';
import { AccountStatementFilterEnum } from 'pages/AccountStatements/enums';
import { SuccessModal } from 'pages/AccountStatements/SuccessModal';
import { useStatementsStorageFilter } from 'pages/AccountStatements/useStatementsStorageFilter';
import { end, start } from 'pages/Products/utils/productUtils';
import { TransactionsHistoryFields } from 'pages/TransactionsHistory/interfaces';
import { CLICK_COUNT } from 'pages/TransactionsHistory/utils';

export interface AccountStatementsFilterProps {
  getActions?: (
    formData: ExportStatementRequest,
    pagination: GridRequest,
    shapedStatementTime?: string,
  ) => Actions[];
  hasRevaluation?: boolean;
  hasSendButton?: boolean;
}

interface AccountStatementsFilterExtraFields {
  shapedStatementTime: string;
}

//TODO: add types
const sendStatement = async (
  filter: ExportStatementRequest,
  gridRequest: GridRequest,
  shapedStatementTime: string,
) => {
  const { page, size, ...sortParams } = gridRequest;

  const onSend = async (params: ExportStatementRequest) =>
    await api.accounts.sendStatements({ ...params, ...sortParams, time: shapedStatementTime });

  const isLetterSent = await confirmModal<boolean, EmailProps>(EmailModal, {
    onSend,
    params: filter,
    fieldName: 'recipientEmails',
  });
  isLetterSent && (await confirmModal(SuccessModal));
};

const today = moment().toDate();

const AccountStatementFilterForm: React.FC<AccountStatementsFilterProps> = ({
  hasSendButton = true,
  hasRevaluation = false,
  getActions,
}) => {
  const onMount = () => fetchData();
  const {
    applyFilter,
    updateData,
    setData,
    getFormData,
    rows,
    gridRequest,
    setPagination,
    payload,
    setPayload,
    setProgress,
    progress,
    extraFields,
  } = useFilter<TransactionsHistoryFields, AccountStatementsFilterExtraFields>({
    fetchOnDemand: true,
    fetchInitial: onMount,
  });

  const formData = getFormData();

  const { storageRevaluationValue, storageShowAdditionalDetails, onUpdateStorage } =
    useStatementsStorageFilter(STATEMENTS);

  const fetchData = async (customerId?: string) => {
    const accountOptions = await api.payments.getAccountsForStatementOptions([customerId]);
    const isExistAccount = accountId && accountOptions.some(item => accountId === item.value);

    !isExistAccount &&
      setData({
        accountIds: accountOptions.map(account => account.value),
      });

    setPayload({ accountOptions });
  };

  const onResetData = (customerId?: string) => {
    setData({
      [AccountStatementFilterEnum.WithRevaluation]: storageRevaluationValue,
      [AccountStatementFilterEnum.ShowAdditionalDetails]: storageShowAdditionalDetails,
    });

    fetchData(customerId);
  };

  const onUpdateRevaluation = () => {
    const { withRevaluation, showAdditionalDetails } = getFormData();

    return onUpdateStorage({
      withRevaluation,
      showAdditionalDetails,
    });
  };

  const onCustomerSelect = async (value: string) => {
    setProgress(true);
    updateData({ accountIds: null });
    await fetchData(value);
    setProgress(false);
  };

  const exportData: ExportStatementRequest = {
    ...formData,
    // TODO destrasturuzation formData.customerIds
    // @ts-ignore
    customerIds: formData.customerIds ? [formData.customerIds] : undefined,
  };

  const { accountId } = parseUrlParams(useLocation().search);

  const exportActions = React.useMemo(
    () => getActions(exportData, gridRequest, extraFields.shapedStatementTime),
    [JSON.stringify(exportData), gridRequest],
  );

  const handleApplyFilter = async (e: any) => {
    //transfer changes to CreateButton component
    if (e.detail > CLICK_COUNT) {
      return;
    }

    updateData({
      time: moment().format(),
    });

    setPagination(0);
    await applyFilter();
  };

  return (
    <div>
      <Row>
        <Col md={6}>
          {formData.time ? (
            <p>{`${translate('front.current-date.label')} ${timeDateFull(formData.time)}`}</p>
          ) : null}
        </Col>
        <Col md={6}>
          <PageHeader.Right>
            <DisabledBlock disabled={rows.length === 0 && !formData.showZeroBalances}>
              <ExportDropdown actions={exportActions} />
            </DisabledBlock>
          </PageHeader.Right>
        </Col>
      </Row>
      <Row>
        <Col xl={2} lg={3} md={4}>
          <CreateButton
            title={'front.account-statements-filter-form.apply-button.label'}
            onClick={handleApplyFilter}
            disabled={progress}
          />
        </Col>
        <Col xl={10} lg={9} md={8}>
          <Row>
            <Col xl={6} lg={5}>
              <SelectField
                name={AccountStatementFilterEnum.AccountIds}
                label="front.account-statements-filter-form.account-number.label"
                options={payload.accountOptions}
                defaultValue={accountId ? [accountId] : []}
                multi
                clearable
                required
              />
            </Col>
            <Col xl={4} lg={4}>
              <DateRangeField
                label="front.account-statements-filter-form.date-range.label"
                nameFrom={AccountStatementFilterEnum.DateFrom}
                nameTo={AccountStatementFilterEnum.DateTo}
                defaultFrom={start}
                defaultTo={end}
                maxDate={today}
                disabledDaysFrom={{
                  before: null,
                  after: today,
                }}
                clearable
                required
              />
            </Col>
            <Col xl={2} lg={3}>
              <ToggleFilterButton
                fetchOnApply={false}
                onResetFilter={onResetData}
                onApplyFilter={onUpdateRevaluation}
                onCloseFilter={onUpdateRevaluation}
              >
                <Row>
                  <Col md={6}>
                    <CustomerSelect
                      label="front.internal-payment-page.order-payer-name.label"
                      name={AccountStatementFilterEnum.CustomerIds}
                      onSelectOption={onCustomerSelect}
                    />
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col>
                        <CheckboxField
                          label="front.account-statements-filter-form.show-zero-balances.label"
                          name={AccountStatementFilterEnum.ShowZeroBalances}
                        />
                      </Col>
                      {hasRevaluation && (
                        <Col md={12}>
                          <CheckboxField
                            label="front.account-statements-filter-form.show-revaluation.label"
                            name={AccountStatementFilterEnum.WithRevaluation}
                            defaultValue={storageRevaluationValue}
                          />
                        </Col>
                      )}
                      <Col md={12}>
                        <CheckboxField
                          label="front.account-statements-filter-form.show-additional-details.label"
                          name={AccountStatementFilterEnum.ShowAdditionalDetails}
                          defaultValue={storageShowAdditionalDetails}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </ToggleFilterButton>
            </Col>
          </Row>
        </Col>
      </Row>
      {/*Show export buttons only if data available */}
      {hasSendButton && rows.length ? (
        <div>
          <Button
            onClick={() => sendStatement(exportData, gridRequest, extraFields.shapedStatementTime)}
          >
            {translate('front.account-statements-table.action-send.label')}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export const AccountStatementFilter = withForm(AccountStatementFilterForm);
