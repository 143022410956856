import { api } from 'api';
import { OrderActionType } from 'api/enums';
import { Order, OrderAction } from 'api/OrderService';
import { Privileges } from 'navigations/privileges';
import { NEW } from 'navigations/routes';
import { getTranslateAction } from 'pages/Payments/general';

import { getSubmitPage } from './utils';

import './styles.scss';

export const actions: OrderAction<Order>[] = [
  {
    label: getTranslateAction(OrderActionType.SIGN),
    name: OrderActionType.SIGN,
    privileges: [Privileges.demandSign],
    type: 'signButton',
  },
  {
    label: getTranslateAction(OrderActionType.PRINT),
    name: OrderActionType.PRINT,
    type: 'dropdown',
    onClick: async order => await api.demands.getOrderPdf(order.id, true),
  },
  {
    label: getTranslateAction(OrderActionType.DELETE),
    name: OrderActionType.DELETE,
    onClick: async order => await api.demands.deleteOrder(order.id),
    type: 'dropdown',
    closable: true,
    privileges: [Privileges.demandCurrencyExchangeEdit, Privileges.demandEdit],
    confirmMessage: 'front.demand-filter.actions-delete.label',
  },
  {
    label: getTranslateAction(OrderActionType.REJECT),
    name: OrderActionType.REJECT,
    type: 'dropdown',
    onClick: async order => await api.demands.executeAction(order.id, OrderActionType.REJECT),
  },
  {
    label: getTranslateAction(OrderActionType.REVOKE),
    name: OrderActionType.REVOKE,
    type: 'dropdown',
    onClick: async order => void (await api.demands.withdrawOrders([order.id])),
    confirmMessage: 'front.working-documents-table.actions-revoke-confirmation.demand.label',
  },
  {
    label: getTranslateAction(OrderActionType.COPY),
    name: OrderActionType.COPY,
    type: 'dropdown',
    privileges: [Privileges.demandCurrencyExchangeEdit, Privileges.demandEdit],
    onClick: async order => getSubmitPage({ id: NEW, type: order.type, copyFrom: `${order.id}` }),
  },
];
