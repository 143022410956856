import React from 'react';
import { useLocation } from 'react-router';

import moment from 'moment';

import { api } from 'api';
import { parseUrlParams } from 'api/backend';
import { DemandType } from 'api/DemandsService/enums';
import { OrderActionType } from 'api/enums';
import { Order } from 'api/OrderService';
import { useOrder } from 'components/Document/useOrder';
import { FetchOrderCb, withOrder } from 'components/Document/withOrder';
import { FormState } from 'components/forms/ValidatingForm/FormContext';
import { NavTab, NavTabs } from 'components/tabs/NavTabs';
import * as format from 'components/utils/format';
import { translate } from 'i18n/translator';
import { allowDemandAction } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { NEW } from 'navigations/routes';
import { CashTransferPayload } from 'pages/Demands/CashTransferPage/interfaces';
import { DemandHistoryPage } from 'pages/Demands/DemandHistoryPage';
import { getActions } from 'pages/Demands/utils';
import { PaymentHistoryPage } from 'pages/Payments/History/PaymentHistoryPage';
import { CustomError } from 'utils/customError';
import { isExist } from 'utils/isData';

import { CashPreparationDetails } from './CashPreparationDetails';
import { CashTransferDetails } from './CashTransferDetails';
import { CashTransferRepresentative } from './CashTransferRepresentative';
import {
  getAccounts,
  getBranches,
  getClearFormData,
  getCorrectFinancial,
  getDetailCashFields,
  getDynamicFields,
  getFinancial,
  getOrderFieldSchema,
  getSymbols,
} from './utils';

const orderTitleMap: Partial<Record<DemandType, string>> = {
  [DemandType.CashTransferOrder]: 'front.demands.cash-transfer.label',
  [DemandType.CashWithdrawalOrder]: 'front.demands.cash-withdrawal.label',
  [DemandType.CashPreparationOrder]: 'front.demands.cash-preparation.label',
};

const CASH_ACCOUNT_PARTS = 3;

// TODO change type
const fetchOrder: FetchOrderCb<any> = async (
  { customerId: defaultCustomerId, routeParams, queryParams },
  { setPayload },
) => {
  const { type } = queryParams;
  const label = translate(orderTitleMap[type as DemandType]);

  let cashSymbols = {};
  let orderFieldSchema;
  let demand;

  if (type === DemandType.CashTransferOrder || type === DemandType.CashWithdrawalOrder) {
    cashSymbols = await getSymbols(type);
  }

  let accounts;
  let branches;
  const currencies = await api.currency.getAllCurrencies();

  if (routeParams.id !== NEW) {
    demand = await api.demands.getDemand(routeParams.id);
    const {
      base: { orderNumber, customerId, orderDate, orderType, orderState, orderStateLabel, id },
      dynamicFields,
      financial,
    } = demand;

    branches = await getBranches(customerId);
    accounts = await getAccounts(customerId, type as DemandType);
    orderFieldSchema = getOrderFieldSchema(dynamicFields);

    setPayload({
      ...cashSymbols,
      accounts,
      branches,
      currencies,
      orderFieldSchema,
    });

    return {
      id,
      label,
      customerId,
      date: moment(orderDate).toDate(),
      state: orderState,
      stateTranslate: orderStateLabel,
      number: orderNumber,
      type: orderType as DemandType,
      actions: getActions(demand),
      detailFields: {
        ...getDetailCashFields(dynamicFields),
        ...getCorrectFinancial(financial),
        customerId,
      },
    };
  }

  let number;
  const today = moment().toDate();

  if (queryParams.copyFrom) {
    const {
      base: { customerId, orderType, translatedOrderType },
      dynamicFields,
      financial,
    } = await api.demands.getDemand(queryParams.copyFrom);

    branches = await getBranches(customerId);

    number = await api.payments.getDocumentNumber({
      customerId: customerId,
      orderType: orderType,
      orderDate: moment().format('YYYY-MM-DD'),
    });

    if (
      orderType === DemandType.CashTransferOrder ||
      orderType === DemandType.CashWithdrawalOrder
    ) {
      cashSymbols = await getSymbols(orderType);
    }

    orderFieldSchema = getOrderFieldSchema(dynamicFields);
    accounts = await getAccounts(customerId, orderType as DemandType);

    setPayload({
      ...cashSymbols,
      orderFieldSchema,
      accounts,
      branches,
      currencies,
    });

    return {
      number,
      label: translatedOrderType,
      stateTranslate: null,
      date: today,
      id: null,
      state: null,
      actions: [],
      type: orderType,
      customerId,
      detailFields: {
        ...getDetailCashFields(dynamicFields),
        ...getCorrectFinancial(financial as any),
        customerId,
        dateOperation: today,
      },
    };
  }

  branches = await getBranches(defaultCustomerId);

  number = await api.payments.getDocumentNumber({
    orderType: type,
    customerId: defaultCustomerId,
    orderDate: moment().format('YYYY-MM-DD'),
  });

  const { orderFieldModel } = await api.demands.getDemandTemplate(
    queryParams.type,
    defaultCustomerId,
  );

  orderFieldSchema = getOrderFieldSchema(orderFieldModel);

  accounts = await getAccounts(defaultCustomerId, type as DemandType);

  setPayload({
    ...cashSymbols,
    orderFieldSchema,
    accounts,
    currencies,
    branches,
  });

  return {
    label,
    customerId: defaultCustomerId,
    id: null,
    state: null,
    stateTranslate: null,
    number,
    type,
    date: today,
    actions: [],
    detailFields: {
      dateOperation: today,
      customerId: defaultCustomerId,
    },
  };
};

const onSave = (order: Order, payload: CashTransferPayload) => async (formData: FormState) => {
  const clearFormData = getClearFormData(formData);
  const { orderNumber, orderDate, customerId, ...otherFields } = clearFormData;
  const { id, type } = order;

  let financial = {};

  if (!isExist(otherFields.documentType) && type !== DemandType.CashPreparationOrder) {
    goto(pages.cashTransfer.tabs.representative(NEW, { type }));
    throw new CustomError(translate('front.cash-transfer-page.representative-errors.label'));
  }

  const branch = payload.branches.find(
    branch => Number(otherFields.branch) === +branch.value,
  ).content;

  const isoCode = payload.accounts.find(
    account => account.value === `${otherFields.payerAccountId}`,
  ).content.currency;

  const currency = payload.currencies.find(currency => currency.isoCode === isoCode);
  const [accountNumberBase, , branchNumber] =
    branch.cashAccount?.split('.', CASH_ACCOUNT_PARTS) ?? [];

  const cashAccountNumber = `${accountNumberBase}.${currency.digitalCode}.${branchNumber}`;

  financial = getFinancial(type, cashAccountNumber, branch.cashIban, isoCode, otherFields);

  const dynamicFields = getDynamicFields(otherFields, payload);

  const orderRequest = {
    dynamicFields,
    financial,
    base: {
      orderNumber: orderNumber as any,
      orderDate: format.localDate(orderDate as Date),
      customerId: Number(customerId),
      orderType: type,
    },
  };

  if (!id) {
    return await api.payments.createPayment(orderRequest);
  }

  await api.payments.updatePayment(id, orderRequest);
  return id;
};

const renderDetails = (orderType: DemandType): React.ReactNode => {
  if (isExist(orderType)) {
    switch (orderType) {
      case DemandType.CashTransferOrder:
      case DemandType.CashWithdrawalOrder:
        return <CashTransferDetails />;
      default:
        return <CashPreparationDetails />;
    }
  }
  return null;
};

const renderRepresentative = (orderType: DemandType): React.ReactNode => {
  if (isExist(orderType) && orderType !== DemandType.CashPreparationOrder) {
    return (
      <NavTab
        title={translate('front.cash-transfer-page.representative-tab.label')}
        path={pages.cashTransfer.tabs.representative(':id')}
      >
        <CashTransferRepresentative />
      </NavTab>
    );
  }
  return null;
};

const renderHistory = (orderType: DemandType): React.ReactNode => {
  switch (orderType) {
    case DemandType.CashTransferOrder:
    case DemandType.CashWithdrawalOrder:
      return <PaymentHistoryPage />;
    default:
      return <DemandHistoryPage />;
  }
};

export const CashTransferPageComponent = () => {
  const { order } = useOrder();

  const { type } = parseUrlParams(useLocation().search);
  const orderType = type ?? order?.type;

  const Details = React.useMemo(() => renderDetails(orderType), [orderType]);
  const Representative = React.useMemo(() => renderRepresentative(orderType), [orderType]);
  const History = React.useMemo(() => renderHistory(orderType), [orderType]);

  return (
    <NavTabs>
      <NavTab
        title={translate('front.cash-transfer-page.requisites-tab.label')}
        path={pages.cashTransfer.tabs.details(':id')}
      >
        {Details}
      </NavTab>
      {Representative}
      {!!order?.state && (
        <NavTab
          title={translate('front.demand-detail.history.label')}
          path={pages.cashTransfer.tabs.history(':id')}
        >
          {History}
        </NavTab>
      )}
    </NavTabs>
  );
};

const goBack = () => goto(pages.demands);

export const CashTransferPage = withOrder(({ order, error }) => {
  const isRepresentativeError =
    error === translate('front.cash-transfer-page.representative-errors.label');

  return {
    fetchOrder,
    createOnSave: onSave,
    allowState: true,
    allowDetails: true,
    allowSave:
      order && allowDemandAction(order.type as DemandType, OrderActionType.EDIT, +order.customerId),
    allowSign:
      order &&
      allowDemandAction(order.type as DemandType, OrderActionType.SIGN, +order.customerId) &&
      !isRepresentativeError,
    disableButtons: true,
    afterSubmit: goBack,
    allowOperationDate: true,
    isDisabledSave: isRepresentativeError,
  };
})(CashTransferPageComponent);
