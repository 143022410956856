export enum TransactionHistoryFilterEnum {
  AccountIds = 'accountIds',
  DateFrom = 'dateFrom',
  DateTo = 'dateTo',
  AmountFrom = 'amountFrom',
  AmountTo = 'amountTo',
  DocNumber = 'docNumber',
  ContragentTax = 'contragentTax',
  ContragentName = 'contragentName',
  Details = 'details',
  CustomerIds = 'customerIds',
  WithRevaluation = 'withRevaluation',
  ShowAdditionalDetails = 'showAdditionalDetails',
}

export enum TabOptions {
  all = 'all',
  in = 'in',
  out = 'out',
  info = 'info',
}

export enum TransactionHistory {
  PayerName = 'payerName',
  PayerIban = 'payerIban',
  PayerBin = 'payerBin',
  PayerBankBik = 'payerBankBik',
  ReceiverName = 'receiverName',
  ReceiverIban = 'receiverIban',
  ReceiverBin = 'receiverBin',
  ReceiverBankBik = 'receiverBankBik',
  Amount = 'amount',
  Purpose = 'purpose',
}
