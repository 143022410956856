import React from 'react';

import { logout } from 'api/backend';
import { Popover } from 'components/popover/Popover';
import config from 'config';
import { AllowDisplayBlock, isActionAllowed } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { useNotificationsSelector } from 'pages/Dashboard/Header/useNotificationsSelector';
import { useReIdentificationMessage } from 'pages/Dashboard/Header/useReIdentificationMessage';
import { LogoutButton } from 'pages/Dashboard/LogoutButton/LogoutButton';
import { NotificationsHeader, NotificationsList } from 'pages/Dashboard/Notifications';
import { UserInfo } from 'pages/Dashboard/UserInfo/UserInfo';
import { isString } from 'utils/isData';
import './header-small.scss';

const {
  burgerIcon,
  settingsIconWhite,
  mobileLogoPath: smallSiteLogo,
  notificationsIconWhite,
  instructionsIconWhite,
} = config.page.dashboard;

const mobileHeaderIcons = () => [
  {
    icon: settingsIconWhite,
    key: 'settings',
    link: pages.profile.tabs.settings,
    isVisible: isActionAllowed([Privileges.settingsView]),
  },
  {
    icon: instructionsIconWhite,
    key: 'instructions',
    link: pages.instructions,
    isVisible: isActionAllowed([Privileges.instructionDictionaryView]),
  },
];

export const HeaderSmall = () => {
  const {
    notifications,
    readNotification,
    getNotificationsSize,
    readAllNotifications,
    gotoNotification,
  } = useNotificationsSelector();

  const reIdentificationMessage = useReIdentificationMessage();

  const getNotificationsStylePosition: React.CSSProperties = {
    position: getNotificationsSize() > 0 ? 'absolute' : 'static',
  };

  const hasNotifications = notifications.length > 0;

  return (
    <header className="small-header">
      <div className="control-wrapper">
        <div
          className="header-burger"
          onClick={() => document.body.classList.toggle('mobile-sidebar')}
        >
          <img src={burgerIcon} alt="burger" />
        </div>
        <div className="small-logo">
          <img src={smallSiteLogo} className="small-logo-img" alt="logo" />
        </div>
        <div className="icons-wrapper">
          <ul className="icons-list list-reset">
            <AllowDisplayBlock privileges={[Privileges.contactLetterView]}>
              <li className="icons-list_item">
                <span className="notifications" style={getNotificationsStylePosition} />
                <Popover
                  isClickAutoClose={false}
                  placement="bottom"
                  fade={false}
                  className="notifications-popover"
                  id="notifications"
                  header={<NotificationsHeader onReadAll={readAllNotifications} />}
                  label={
                    <a>
                      <img className="notifications-icon" src={notificationsIconWhite} alt="logo" />
                      {hasNotifications && <div className="unread-notifications-mark" />}
                    </a>
                  }
                >
                  <NotificationsList
                    onClose={readNotification}
                    onClick={gotoNotification}
                    data={notifications}
                  />
                </Popover>
              </li>
            </AllowDisplayBlock>
            {mobileHeaderIcons().map(
              ({ icon, key, link, isVisible }) =>
                isVisible && (
                  <li
                    className="icons-list_item"
                    key={key}
                    onClick={() => {
                      link && goto(link);
                    }}
                  >
                    <img className="item-icon" src={icon} alt="logo" />
                  </li>
                ),
            )}
            <li className="icons-list_item">
              <UserInfo
                popoverChildren={
                  <div className="logout-button-wrapper">
                    <LogoutButton onLogout={logout} />
                  </div>
                }
                isSmall
              />
            </li>
          </ul>
        </div>
      </div>
      {isString(reIdentificationMessage) && (
        <div className="header-info-message">{reIdentificationMessage}</div>
      )}
    </header>
  );
};
