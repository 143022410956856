import * as React from 'react';
import { SortOrder, TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { Financial } from 'api/DemandsService/interfaces';
import { getPage, HasId } from 'api/Service';
import { CounterDescription } from 'components/CounterDescription/CounterDescription';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { getAmountNumberFormatWithComma } from 'components/utils/format';
import { translate } from 'i18n/translator';

import 'pages/Import/styles.scss';

interface PaymentError extends HasId {
  message: string;
  messages: string[];
  order: Financial;
}

interface Props {
  errors: PaymentError[];
}

const columns: TableColumnProps<PaymentError>[] = [
  {
    label: '#',
    render: row => <DataColumn title={row.id} />,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.payroll-page.col-receiver.label',
    render: ({ order }) => (
      <DataColumn title={order?.receiverName} subTitle={order?.receiverIban} />
    ),
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.payroll-page.col-sum.label',
    render: ({ order }) => <DataColumn title={getAmountNumberFormatWithComma(order?.amount)} />,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.import-page.errors.label',
    render: row => (
      <DataColumn>
        <ul className="error-message-container">
          {row.messages?.map((msg, i) => <li key={i}>{msg}</li>)}
        </ul>
      </DataColumn>
    ),
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

export const ErrorPaymentsList = ({ errors }: Props) => (
  <>
    <CounterDescription
      count={errors?.length}
      description={translate('front.import-page.tab-errors.label')}
    />
    <DataTable
      name={TableNamesEnum.ErrorPaymentsImport}
      columns={columns}
      defaultOrder={SortOrder.Asc}
      fetchRows={async request => getPage(errors, request)}
    />
  </>
);
