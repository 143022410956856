import * as React from 'react';
import { Col, Container, Row } from 'react-grid';
import reactHtmlParser from 'react-html-parser';

import { api } from 'api';
import { Token } from 'api/CertificateService/interfaces';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { PasswordField } from 'components/forms/inputs/PasswordField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { validateMessages } from 'components/validateMessages';
import { translate } from 'i18n/translator';
import { validatePasswordWithException } from 'pages/Profile/Certificates/PasswordValidator';

interface Fields {
  confirmPassword: string;
  newPassword: string;
  password: string;
}

interface Payload {
  certificate: Token;
}

interface Props {
  certificate: string | Token;
  resolve: any;
}

const ChangePasswordForm: React.FC<Props> = ({ certificate, resolve }) => {
  const fetchCertificate = async () => {
    if (typeof certificate === 'string') {
      const payloadCertificate = await api.certificate.getCertificate(certificate);
      setPayload({ certificate: payloadCertificate });
    } else {
      setPayload({ certificate });
    }
  };

  const { progress, error, handleSubmit, getFieldValue, payload, setPayload } = useForm<
    Fields,
    Payload
  >(fetchCertificate);

  const onSave = async (formData: Fields) => {
    const { password, newPassword } = formData;

    validatePasswordWithException(newPassword);

    await api.certificate.changePin(password, newPassword, payload?.certificate);

    resolve(true);
  };

  const newPassword = getFieldValue('newPassword');

  const validatePasswords = React.useCallback(
    (value: string) => {
      if (value !== newPassword) {
        return validateMessages.passwordsEqual;
      }
      return '';
    },
    [newPassword],
  );

  return (
    <div>
      <div className="title-wrap">
        <h3>
          {translate(
            payload.certificate?.Hw
              ? 'front.cert-page.change-password-modal-token.title.label'
              : 'front.cert-page.change-password-modal-file.title.label',
          )}
        </h3>
        <Tooltip
          className={'popover-position'}
          tooltip={translate('front.cert-page.change-password-modal-tooltip.label')}
        />
      </div>
      <DefaultForm disabled={progress}>
        {error && <FormError>{reactHtmlParser(error)}</FormError>}
        <Container>
          <PasswordField
            label="front.cert-page.change-password-modal.input-password.label"
            name="password"
            minLength={6}
            maxLength={8}
            required
          />
          <PasswordField
            label="front.cert-page.change-password-modal.input-new-password.label"
            name="newPassword"
            minLength={6}
            maxLength={8}
            required
          />
          <PasswordField
            label="front.cert-page.change-password-modal.input-confirm-password.label"
            name="confirmPassword"
            validate={validatePasswords}
            minLength={6}
            maxLength={8}
            required
          />
          <Row>
            <Col sm={6}>
              <Button
                onClick={e => handleSubmit(onSave, e)}
                size="md"
                progress={progress}
                type="submit"
                block
              >
                {translate('front.cert-page.change-password-modal.save-button.label')}
              </Button>
            </Col>
            <Col sm={6}>
              <Button onClick={() => resolve(null)} color="secondary" size="md" block>
                {translate('front.cert-page.change-password-modal.cancel-button.label')}
              </Button>
            </Col>
          </Row>
        </Container>
      </DefaultForm>
    </div>
  );
};

export const ChangePasswordModal = withForm(ChangePasswordForm);
