import React from 'react';

import { FileType } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { FileField } from 'components/forms/inputs/FileField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { useDisabledCustomer } from 'hooks/useDisabledCustomer';
import { useFileExtensions } from 'hooks/useFileExtensions';
import { EmployeeReissueNames } from 'pages/Demands/EmployeeManagment/enums';
import {
  EmployeeReissueFields,
  EmployeeReissuePayload,
} from 'pages/Demands/EmployeeManagment/interfaces';

export const EmployeeReissueDetails = () => {
  const {
    order,
    payload: { salaryDeal, reissueReason, employeeList },
    onChangeCustomer,
    form: { getFieldValue },
  } = useOrderDetails<EmployeeReissueFields, EmployeeReissuePayload>();

  const fileExtensions = useFileExtensions();
  const isDisabledCustomer = useDisabledCustomer(order);
  const hasSalaryDeal = !!getFieldValue(EmployeeReissueNames.SalaryDeal);

  return (
    <DefaultForm>
      <CustomerSelect
        name={EmployeeReissueNames.Customer}
        label="front.employee-management.organization.label"
        onSelectOption={onChangeCustomer}
        selectDefaultCustomer
        disabled={isDisabledCustomer}
        required
      />
      <SelectField
        name={EmployeeReissueNames.SalaryDeal}
        label="front.employee-management.salary-deal.label"
        options={salaryDeal}
        required
      />
      <SelectField
        name={EmployeeReissueNames.ReissueReason}
        label="front.employee-new.reason-reissue.label"
        options={reissueReason}
        required
      />
      <SelectField
        name={EmployeeReissueNames.EmployeeList}
        label="front.employee-new.employee.label"
        options={employeeList}
        disabled={!hasSalaryDeal}
        onlySearch
        required={hasSalaryDeal}
      />
      <FileField
        name={EmployeeReissueNames.Attach}
        label="front.employee-management.attachments.label"
        outputFormat={FileType.BASE_64}
        accept={fileExtensions}
        multiple
      />
    </DefaultForm>
  );
};
