import * as React from 'react';
import { SortOrder, TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { Employee } from 'api/SalaryService';
import { getPage, HasId } from 'api/Service';
import { CounterDescription } from 'components/CounterDescription/CounterDescription';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { translate } from 'i18n/translator';
import { ImportResult } from 'pages/Import/interfaces';

import 'pages/Import/styles.scss';

interface EmployeeError extends HasId {
  employee: Employee;
  message: string;
  messages: string[];
}

interface Props {
  importResult: ImportResult;
}

const columns: TableColumnProps<EmployeeError>[] = [
  {
    label: '#',
    render: row => {
      const employee = row.employee || row.employeeSalaryModel;
      return <DataColumn title={row?.id ?? employee?.id ?? '-'} />;
    },
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.menu.employees.label',
    render: row => {
      const employee = row.employee || row.employeeSalaryModel || {};
      return (
        <DataColumn
          title={[employee.lastName, employee.firstName, employee.middleName].join(' ')}
          subTitle={employee.iban}
        />
      );
    },
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.сounterparty-form.bin.label',
    render: row => {
      const employee = row.employee || row.employeeSalaryModel || {};
      return <DataColumn title={employee.taxCode} />;
    },
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.import-page.errors.label',
    render: row => (
      <DataColumn>
        <ul className="error-message-container">
          {row.messages?.map((msg, i) => <li key={i}>{msg}</li>)}
        </ul>
      </DataColumn>
    ),
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

export const ErrorEmployeesList = ({ importResult }: Props) => {
  // true if file has row errors, or file structure errors
  const hasFileImportError =
    importResult.hasErrors &&
    importResult.meta?.messages?.length !== 0 &&
    importResult?.errors?.length === 0;

  //use proxy array to avoid rerender issues
  const allErrors: any[] = [];

  if (hasFileImportError) {
    importResult.meta?.messages?.map(value => {
      allErrors.push({
        employeeSalaryModel: {
          id: value.target,
        },
        messages: [value.message],
      });
    });
  }
  allErrors.push(...importResult.errors);

  return (
    <>
      <CounterDescription
        count={allErrors.length}
        description={translate('front.import-page.tab-errors.label')}
      />
      <DataTable
        name={TableNamesEnum.ErrorEmployeesImport}
        columns={columns}
        defaultOrder={SortOrder.Asc}
        fetchRows={async request => getPage(allErrors, request)}
      />
    </>
  );
};
