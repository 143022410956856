import * as React from 'react';
import { Col, Row } from 'react-grid';

import { PersonStatus } from 'api/enums';
import { Option } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { SelectField } from 'components/forms/inputs/SelectField';
import { withForm } from 'components/forms/withForm';
import { SearchInput } from 'components/searchInput/SearchInput';
import { CreateButton } from 'components/Table/CreateButton';
import { useFilter } from 'components/Table/DataTable';
import { ToggleFilterButton } from 'components/Table/ToggleFilterButton/ToggleFilterButton';
import { FilterTabBar } from 'components/tabs/FilterTabBar';
import { translate } from 'i18n/translator';
import { DisabledBlock } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { ActionTypes } from 'pages/AuthorizedPersons/enums';

const ALL_STATUSES = `${PersonStatus.ACTIVE},${PersonStatus.BLOCKED}`;

const authPersonTypes = [
  {
    value: ALL_STATUSES,
    label: 'front.authorized-persons.table.all.label',
  },
  {
    value: PersonStatus.ACTIVE,
    label: 'front.authorized-persons.table.active.label',
  },
  {
    value: PersonStatus.BLOCKED,
    label: 'front.authorized-persons.table.blocked.label',
  },
];

const statusOptions: Option[] = [
  {
    value: ALL_STATUSES,
    label: translate('front.authorized-persons.table.all.label'),
  },
  {
    value: PersonStatus.ACTIVE,
    label: translate('front.authorized-persons.table.active.label'),
  },
  {
    value: PersonStatus.BLOCKED,
    label: translate('front.authorized-persons.table.blocked.label'),
  },
];

const AuthorizedPersonsFilterForm = () => {
  const { extraFields, updateFilter, updateData, setPagination } = useFilter({
    extraFields: { searchText: undefined, status: ALL_STATUSES },
  });

  return (
    <div>
      <FilterTabBar
        onChange={status => {
          updateFilter({ status });
          updateData({ status });
        }}
        value={extraFields.status}
        options={authPersonTypes}
        setPagination={setPagination}
      />
      <Row>
        <Col lg={6} md={3}>
          <DisabledBlock privileges={[Privileges.manageUsersCreate]}>
            <CreateButton
              title="front.working-documents.create.label"
              onClick={() => goto(pages.authorizedPersonPage(ActionTypes.CREATE))}
            />
          </DisabledBlock>
        </Col>
        <Col lg={5} md={6}>
          <SearchInput
            placeholder={translate('front.working-documents-table.filter-search.label')}
            value={extraFields.searchText}
            onChange={searchText => updateFilter({ searchText })}
          />
        </Col>
        <Col lg={1} md={3}>
          <ToggleFilterButton smallMode>
            <Row>
              <Col md={6}>
                <CustomerSelect
                  label="front.authorized-person.filter-organization.label"
                  name="customerIds"
                />
              </Col>
              <Col md={6}>
                <SelectField
                  label="front.working-documents-table.status.label"
                  name="status"
                  options={statusOptions}
                  clearable
                />
              </Col>
            </Row>
          </ToggleFilterButton>
        </Col>
      </Row>
    </div>
  );
};

export const AuthorizedPersonsFilter = withForm(AuthorizedPersonsFilterForm);
