import React from 'react';

import { api } from 'api';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { PasswordField } from 'components/forms/inputs/PasswordField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { PasswordHint } from 'components/PasswordHint/PasswordHint';
import { validateMessages } from 'components/validateMessages';
import { useUserCredentialsValidate } from 'hooks/useUserCredentialsValidate';
import { translate } from 'i18n/translator';
import { goBack } from 'navigations/navigate';

interface Fields {
  confirmPassword: string;
  newPass: string;
  oldPass: string;
}

export const ChangePasswordForm: React.FC = () => {
  const { error, progress, handleSubmit, getFormData } = useForm<Fields>();
  const { newPass, confirmPassword } = getFormData();

  const { validatePassword, minLengthPassword, maxLengthPassword } = useUserCredentialsValidate();

  const onChangePassword = async (formData: Fields) => {
    const { oldPass, newPass } = formData;
    await api.profile.changePassword(oldPass, newPass);
    goBack();
  };

  const validatePwdConfirm = React.useCallback(
    (confirmPassword: string) => {
      if (confirmPassword !== newPass) {
        return validateMessages.passwordsEqual;
      }
      return '';
    },
    [newPass, confirmPassword],
  );

  return (
    <div>
      <h3>{translate('front.profile.information.change-password.label')}</h3>
      <DefaultForm disabled={progress}>
        <FormError>{error}</FormError>
        <PasswordField
          label="front.profile.information.change-password.current.label"
          name="oldPass"
          required
        />
        <PasswordField
          label="front.profile.information.change-password.new.label"
          name="newPass"
          minLength={minLengthPassword}
          maxLength={maxLengthPassword}
          validate={validatePassword}
          absoluteTooltip={<PasswordHint />}
          required
        />
        <PasswordField
          label="front.profile.information.change-password.repeat.label"
          name="confirmPassword"
          validate={validatePwdConfirm}
          required
        />
        <Button
          color="primary"
          onClick={e => handleSubmit(onChangePassword, e)}
          progress={progress}
          type="submit"
          size="lg"
          label="front.login-form.set-password-button.label"
        />
      </DefaultForm>
    </div>
  );
};

export const ChangePasswordPage = withForm(ChangePasswordForm);
