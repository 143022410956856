import { useState } from 'react';

import { LocalStorage } from 'utils/LocalStorage';

export const useLocalStorage = <T = any>(path: string, key: string): [T, (value: T) => void] => {
  const [storedValue, setStoredValue] = useState(LocalStorage.getItem(path, {}));

  const setStorageValue = (value: T) => {
    const storageValue = LocalStorage.getItem(path, {});
    const newStorageValue = createNewValue(storageValue, value, key);

    LocalStorage.setItem(path, newStorageValue);
    setStoredValue(newStorageValue);
  };

  return [storedValue[key], setStorageValue];
};

const createNewValue = <T>(prevValue: T, currentValue: T, key: keyof T) => {
  const isObjectStorage = typeof currentValue === 'object' && !Array.isArray(currentValue);

  return {
    ...prevValue,
    [key]: isObjectStorage ? { ...prevValue?.[key], ...currentValue } : currentValue,
  };
};
