import { get } from './backend';
import { GridRequest, GridResponse } from './Service';

export interface Department {
  id: number;
  addInfo?: string;
  address?: string;
  city?: string;
  cityId?: number;
  deleted?: boolean;
  externalId?: number;
  geoposition?: string;
  isInoperability?: boolean;
  lat?: number;
  lng?: number;
  localBankId?: number;
  name?: string;
  phoneNumbers?: string[];
  region?: string;
  timezone?: string;
  type?: string;
  warning?: string;
}

export interface DepartmentsFilter {
  searchText: string;
}

export type DepartmentsGridRequest = GridRequest & DepartmentsFilter;

export class DepartmentsService {
  async getDepartments(request?: DepartmentsGridRequest): Promise<GridResponse<Department>> {
    return await get('/v1/service-point', request);
  }
}
