import { api } from 'api';
import { ImportSalaryResponse } from 'api/DemandsService/interfaces';
import { ImportPaymentsResponse } from 'api/interfaces';
import { IMPORT_TYPES } from 'api/Service';
import { FormFields } from 'pages/Import/ImportDetail/interfaces';
import { ImportResult } from 'pages/Import/interfaces';

type DownloadFileResult = ImportSalaryResponse | ImportPaymentsResponse | ImportResult;

export const onDownloadFile = async (formData: FormFields): Promise<DownloadFileResult> => {
  const getDownloadFunction = {
    [IMPORT_TYPES.SALARY]: downLoadSalary,
    [IMPORT_TYPES.SALARY_EMPLOYEES]: downLoadSalary,
    [IMPORT_TYPES.DOMESTIC_PAYMENTS]: downloadPayments,
    [IMPORT_TYPES.FOREIGN_PAYMENTS]: downloadPayments,
    [IMPORT_TYPES.CURRENCY_BUY]: downloadCurrencyBuy,
    [IMPORT_TYPES.COUNTERPARTIES]: downloadCounterparties,
  };

  return getDownloadFunction[formData.importType](formData);
};

const downLoadSalary = async (formData: FormFields): Promise<ImportSalaryResponse> => {
  const { orders, importType, customerId, productId, fileFormat } = formData;

  return await api.demands.importSalaryV2(orders, importType, customerId, productId, fileFormat);
};

const downloadPayments = async (formData: FormFields): Promise<ImportPaymentsResponse> => {
  const { orders, importType, customerId, isMultiImport, fileFormat } = formData;

  return await api.payments.importPaymentsV2(
    orders,
    importType,
    isMultiImport ? undefined : customerId,
    isMultiImport,
    fileFormat,
  );
};

const downloadCounterparties = async (formData: FormFields): Promise<ImportResult> => {
  const { orders, importType, customerId, productId, fileFormat } = formData;

  return await api.handbooks.importCounterpartiesV2(
    orders,
    importType,
    customerId,
    productId,
    fileFormat,
  );
};

const downloadCurrencyBuy = async (formData: FormFields): Promise<ImportPaymentsResponse> => {
  const { orders, importType, customerId, fileFormat } = formData;

  return await api.payments.importPaymentsV2(orders, importType, customerId, false, fileFormat);
};
