import * as React from 'react';

import { checkOS } from 'api/Service';
import { Button } from 'components/buttons/Button';
import { RenderLabelWithLink } from 'components/RenderLabelWithLink/RenderLabelWithLink';
import config, { OS } from 'config';
import { translate } from 'i18n/translator';

import './certificates.scss';

const ErrorSvg = () => (
  <svg
    className="no-connection__error_svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* tslint:disable-next-line: max-line-length */}
    <path
      d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM9 13H11V15H9V13ZM9 5H11V11H9V5Z"
      fill="#E95F5A"
    />
  </svg>
);

interface Props {
  description: string;
  renderButtons(resolve: any): React.FC;
  resolve: (args: any) => any;
  title: string;

  useCipherApp: boolean;
}

const defaultRenderButtons = (resolve: (args: any) => any, useCipherApp: boolean) => (
  <>
    <a
      target="_blank"
      href={
        useCipherApp
          ? config.page.certificate.downloadCipherLinks[checkOS()]
          : config.page.certificate.downloadUmcaLinks[checkOS()]
      }
      className="bs-button btn btn-primary btn-md"
    >
      {translate('front.certificates.error-modal-solve.label')}
    </a>
    <Button onClick={() => resolve(null)} color="secondary">
      {translate('front.table.filter-header-close.label')}
    </Button>
  </>
);

export const NoConnectionModal: React.FC<Props> = ({
  resolve,
  title = 'front.certificates.error-modal-error.label',
  description = 'front.certificates.error-modal-cipher-text.label',
  renderButtons = defaultRenderButtons,
  useCipherApp = true,
}) => (
  <div className="no-connection">
    <div className="no-connection__error">
      <ErrorSvg />
      <h1 className="no-connection__error_title">{translate(title)}</h1>
      <div className="no-connection__error_desc">
        <span>
          {useCipherApp
            ? RenderLabelWithLink(
                description,
                [
                  config.page.certificate.downloadCipherLinks[OS.Windows],
                  config.page.certificate.downloadCipherLinks[OS.MacOS],
                ],
                [OS.Windows, `${OS.MacOS}/${OS.Linux}`],
              )
            : translate(description)}
        </span>
      </div>
    </div>
    <div className="no-connection__error_footer">{renderButtons(resolve, useCipherApp)}</div>
  </div>
);
