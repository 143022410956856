import * as React from 'react';
import { Col, Row, Container } from 'react-grid';
import { RouteComponentProps } from 'react-router';

import { api } from 'api';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { TextField } from 'components/forms/inputs/TextField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { translate } from 'i18n/translator';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';

import './styles.scss';

interface Fields {
  alias: string;
}

export interface ProductEdit {
  alias: string;
  id: string;
}

type Props = RouteComponentProps<{ id: string }>;

const AccountEditForm: React.FC<Props> = props => {
  const { progress, error, handleSubmit } = useForm<Fields>(async ({ setFields }) => {
    const account = await api.products.getAccount(+props.match.params.id);
    setFields({ alias: account?.accountDetails?.alias || '' });
  });

  const onSave = async ({ alias }: Fields) => {
    const { id } = props.match.params;
    const accountData: ProductEdit = {
      id,
      alias: alias?.trim() || '',
    };
    await api.products.updateAccount(accountData);

    goto(pages.accounts);
  };

  return (
    <>
      <h4 className="text-center">{translate('front.account-edit-page.title.label')}</h4>

      <DefaultForm>
        <FormError>{error}</FormError>
        <Container>
          <Row>
            <Col>
              <TextField label="front.account-edit-page.input-alias.label" name="alias" required />
            </Col>
            <Col sm={6}>
              <Button
                color="primary"
                onClick={e => handleSubmit(onSave, e)}
                progress={progress}
                type="submit"
                size="sm"
                block
              >
                {translate('front.account-edit-page.apply-button.label')}
              </Button>
            </Col>
            <Col sm={6}>
              <Button
                color="secondary"
                onClick={() => goto(pages.accounts)}
                progress={progress}
                type="submit"
                size="sm"
                block
              >
                {translate('front.account-edit-page.cancel-button.label')}
              </Button>
            </Col>
          </Row>
        </Container>
      </DefaultForm>
    </>
  );
};

export const AccountEditPage = withForm(AccountEditForm);
