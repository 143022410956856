export enum WIDGET_LAYOUT {
  DOUBLE = 'double',
  SINGLE = 'single',
}

export enum WIDGET {
  INTERNAL_PAYMENTS = 'INTERNAL_PAYMENTS',
  ANALYTICS = 'ANALYTICS',
  TEST = 'TEST',
}

export enum OS {
  Windows = 'Windows',
  MacOS = 'MacOS',
  Linux = 'Linux',
}

const assets = process?.env?.PROFILE === 'demo' ? 'assetsDemo' : 'assets';

export default {
  bank: {
    nationalCurrency: 'UAH',
  },
  app: {
    screenSize: {
      tablet: 992,
      mobile: 767,
    },
    containerSizes: {
      mobile: 560,
      tablet: 960,
    },
  },
  logOut: {
    showWarningBeforeLogoutFor: 1000 * 60,
  },
  components: {
    table: {
      tableConfigGear: require('../www/assets/images/table-settings.svg'),
      tableMore: require('../www/assets/images/three-dots.svg'),
      paginationSizeOptions: [
        { value: '10', label: '10' },
        { value: '20', label: '20' },
        { value: '50', label: '50' },
        { value: '100', label: '100' },
        { value: '1000', label: '1000' },
      ],
      defaultPaginationSize: 20,
      minWidthDesktop: 851,
      minWidthTablet: 528,
    },
    datePicker: {
      MIN_YEAR: 1900,
      MAX_YEAR: 2050,
    },
  },
  i18n: {
    defaultLanguageCode: 'uk',
  },
  page: {
    accounts: {
      ESCROU: 'ESCROU',
    },
    cards: {
      masterCardLogo: require('../www/assets/images/mastercard.svg'),
      visaLogo: require('../www/assets/images/visa.svg'),
    },
    dashboard: {
      desktopLogoPath: require(`../www/${assets}/images/logo.svg`),
      mobileLogoPath: require('../www/assets/images/small-logo.svg'),
      applicationLoader: require('../www/assets/images/applicationLoader.gif'),
      notificationsIcon: require('../www/assets/images/notifications.svg'),
      notificationsIconWhite: require('../www/assets/images/notifications-white.svg'),
      settingsIcon: require('../www/assets/images/header-settings.svg'),
      settingsIconWhite: require('../www/assets/images/header-settings-white.svg'),
      instructionsIcon: require('../www/assets/images/instructions-icon.svg'),
      instructionsIconWhite: require('../www/assets/images/instructions-icon-white.svg'),
      callIcon: require('../www/assets/images/call.svg'),
      locationIcon: require('../www/assets/images/location.svg'),
      logoutIcon: require('../www/assets/images/logout.svg'),
      avatarIcon: require('../www/assets/images/avatar.png'),
      inputSearchIcon: require('../www/assets/images/input-search.svg'),
      burgerIcon: require('../www/assets/images/burger.svg'),
      notificationPollInterval: 31,
    },
    announcements: {
      lengthThreshold: 500,
      truncateThreshold: 300,
    },
    login: {
      desktopLogoPath: require(`../www/${assets}/images/app-logo.svg`),
      mobileLogoPath: require('../www/assets/images/logo.svg'),
      earth: require('../www/assets/images/login/earth.svg'),
      suggestion: require('../www/assets/images/login/suggestion.svg'),
      suggestionGray: require('../www/assets/images/login/suggestion-gray.svg'),
      resetFilterActiveCustomerTime: 5000,
      accessPermissionCheckInterval: 180 * 1000,
    },
    main: {
      widgets: {
        LS_KEY: 'dashboard:state',
        activeColor: '#4CBC5C',
        layouts: {
          [WIDGET_LAYOUT.DOUBLE]: [
            {
              sm: 12,
              md: 12,
              lg: 7,
            },
            {
              sm: 12,
              md: 12,
              lg: 5,
            },
          ],
          [WIDGET_LAYOUT.SINGLE]: [
            {
              sm: 12,
              md: 12,
              lg: 12,
            },
          ],
        },
      },
    },
    certificate: {
      downloadUmcaLinks: {
        [OS.Linux]: '/files/UmCAService.deb',
        [OS.MacOS]: '/files/UmCAService.pkg',
        [OS.Windows]: '/files/UmCAService.msi',
      },
      downloadCipherLinks: {
        [OS.Windows]: '/filesCipher/setup_CaaS_PCS.exe',
        [OS.MacOS]: '/filesCipher/setup_CaaS_PCS.jnlp',
        [OS.Linux]: '/filesCipher/setup_CaaS_PCS.jnlp',
      },
    },
    department: {
      googleApiUrl:
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyB9y0onlmdJuV2eY_XHk1H1GhJd3vFNZ20',
      defaultLatitude: 50.4501,
      defaultLongitude: 30.5234,
      defaultZoom: 14,
      markerUrl: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
    },
    mail: {
      DRAFT: 'DRAFT',
      SENT: 'SENT',
    },
    product: {
      currencyUAHColor: '#4CBC5C',
      currencyUSDColor: '#3171B6',
      currencyEURColor: '#32465C',
      currencyDefaultColor: '#A8AEB9',
    },
    foreignPayment: {
      forbiddenSwiftCharactersRegex: /[^A-Za-z0-9\/\-?:().,'+ ]/g,
      ibanRegexps: /^[a-zA-Z0-9]{0,34}$/,
      ibanRegexpsAllowSymbols: /^[ a-zA-Z0-9а-яА-ЯіїєёґІЇЁЄҐ!#$%&'()№*+,;.\\\/<+_=>:\[\]]{0,34}$/,
      defaultPayerBic: 'UGASUAUKXXX',
    },
    paymentRequest: {
      defaultBic: '320478',
      codeUah: 980,
      attributeTransactionAccount: '2909',
    },
    internalPayment: {
      defaultBic: '320478',
      defaultBankName: 'АБ «УКРГАЗБАНК»',
    },
    domesticPayment: {
      receiverTaxCodeEmpty: '0000000000',
      ibanMask: [
        /[A-Za-z]/,
        /[A-Za-z]/,
        '.',
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ],
    },
    autorizedPerson: {
      maskIpV4: [
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '/',
        /\d/,
        /\d/,
      ],
    },
  },
  utils: {
    ecp: {
      API_URL: 'https://localhost:25989/',
      CIPHER_API_URL: 'https://local.cipher.kiev.ua:9090/',
      ALGORITM: 'dstu4145',
      NETWORK_MESSAGE: 'Network Error',
    },
    socket: {
      webSocketUrl: 'wss://dev-ugb.finik.pro/socket',
    },
  },
  signTypes: {
    SMS: 'SMS',
    EDS: 'EDS',
    SIGNATURE: 'Signature',
  },
  sms: {
    defaultSmsTtl: 600,
  },
  ignoringErrors: [
    'ResizeObserver loop completed with undelivered notifications.',
    'ResizeObserver loop limit exceeded',
  ],
  links: {
    instructionsUGB: 'https://www.ukrgasbank.com/inbank/eco_boom_24_7/',
    structuredFormat: 'https://zakon.rada.gov.ua/laws/show/z0528-23#Text',
    formattingUtility: 'https://api.avtor.ua/cdn/files/Yq90_InitUM.zip',
  },
};
