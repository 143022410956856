import * as React from 'react';
import { connect } from 'react-redux';

import { Dispatch } from 'redux';

import { Modal } from 'components/modals/Modal';
import store from 'store';
import { ModalActions } from 'store/actions/modal';
import { ModalState } from 'store/reducers/modal';

type ResolveType = <P>(value?: P) => Promise<P>;
// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsWithConfirmModal<T = {}> = {
  resolve: ResolveType;
} & T;

const mapStateToProps = <P,>(state: { modalState: ModalState<ModalInterface<P>> }) => ({
  modal: state.modalState.modal,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setModal: (modal: typeof React.Component) => dispatch(ModalActions.setModal(modal)),
});

interface ModalInterface<P> {
  component: typeof React.Component;
  props: P;
  resolve: (e?: boolean) => void;
  width: number;
  hideCloseButton?: boolean;
  styles?: React.CSSProperties;
}

class GlobalModalComponent<P> extends React.PureComponent<
  { modal?: ModalInterface<P>; setModal?: (modal: typeof React.Component) => void },
  any
> {
  render() {
    const { modal } = this.props;

    if (!modal) {
      return null;
    }

    const Component = modal.component;

    return (
      <Modal
        onClose={modal.resolve}
        width={modal.width}
        styles={modal.styles}
        hideCloseButton={modal.hideCloseButton}
      >
        <Component resolve={modal.resolve} {...modal.props} />
      </Modal>
    );
  }
}

export const confirmModal = <T, P = any>(
  component: React.ReactNode,
  props?: P,
  width = 500,
  styles?: React.CSSProperties,
  hideCloseButton?: boolean,
): Promise<T> => {
  return new Promise(resolve => {
    const resolveAndClose = (cancelled: PromiseLike<T> | T) => {
      resolve(cancelled);
      store.dispatch(ModalActions.setModal(null));
    };

    store.dispatch(
      ModalActions.setModal({
        component,
        props,
        width,
        styles,
        hideCloseButton,
        resolve: resolveAndClose,
      }),
    );
  });
};

export const GlobalModal = connect(mapStateToProps, mapDispatchToProps)(GlobalModalComponent);
