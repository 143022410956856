import Decimal from 'decimal.js-light';

import { CurrencyAmountInterface } from 'components/react-data-table/types';
import { getAmount } from 'components/utils/format';
import config from 'config';

const { nationalCurrency } = config.bank;

export const getSum = (amounts: number[]): number =>
  amounts.reduce((acc, amount) => new Decimal(acc).add(Math.abs(amount)).toNumber(), 0);

const getCurrencyOption = (
  currency: string,
  amounts: number[],
): CurrencyAmountInterface<string> => ({
  currency,
  count: amounts.length,
  amount: getAmount(new Decimal(Math.abs(getSum(amounts))).toFixed(2)),
});

export const getCurrencyMap = <R>(
  selectedItems: R[],
  getCurrencyAmount: (row: R) => [string, number],
): Obj<number[]> =>
  selectedItems.reduce((acc: Obj<number[]>, row: R) => {
    const [currency, amount] = getCurrencyAmount(row);

    acc.hasOwnProperty(currency)
      ? (acc[currency] = [...acc[currency], amount])
      : (acc[currency] = [amount]);

    return acc;
  }, {});

export const getCurrencyOptions = (currencyMap: Obj<number[]>) =>
  Object.entries(currencyMap).reduce(
    (
      acc: [CurrencyAmountInterface<string> | null, CurrencyAmountInterface<string>[]],
      currencyAmount: [string, number[]],
    ) => {
      const [nationalAmount, foreignAmounts] = acc;
      const [currency, amounts] = currencyAmount;

      if (currency === nationalCurrency) {
        return [getCurrencyOption(currency, amounts), foreignAmounts];
      }

      return [nationalAmount, [...foreignAmounts, getCurrencyOption(currency, amounts)]];
    },
    [null, []],
  );

const getParsedAmount = (amount: number) => getAmount(Math.abs(amount));

export const getCurrencyOptionsFromTotal = (
  currencies: CurrencyAmountInterface[] = [],
): [CurrencyAmountInterface<string> | null, CurrencyAmountInterface<string>[]] =>
  currencies.reduce(
    (acc, item) => {
      const [nationalAmount, foreignAmounts] = acc;

      if (item.currency === nationalCurrency) {
        return [{ ...item, amount: getParsedAmount(item.amount) }, foreignAmounts];
      }

      return [
        nationalAmount,
        [...foreignAmounts, { ...item, amount: getParsedAmount(item.amount) }],
      ];
    },
    [null, []],
  );
