import * as React from 'react';
import { GridRequest, TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { ExportStatementRequest, Statement } from 'api/AccountService';
import { HasId, SortOrder } from 'api/Service';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import * as format from 'components/utils/format';
import { EmailModal, EmailModalProps } from 'pages/AccountStatements/EmailModal';
import { SuccessModal } from 'pages/AccountStatements/SuccessModal';
import { LOCAL_STORAGE_KEY } from 'store/actions/types';
import { LocalStorage } from 'utils/LocalStorage';

export interface EmailProps extends EmailModalProps {
  params: {
    accountIds: number[];
    dateFrom?: string;
    dateTo?: string;
    ids?: number[];
  };
}

interface Dates {
  dateFrom: string;
  dateTo: string;
}

interface AnalyticsTablePageProps {
  filterComponent: React.FC;
}

const REVALUATION_LOCAL_KEY = 'statements.withRevaluation';

const columns: TableColumnProps<Statement>[] = [
  {
    label: 'front.account-statements-table.account-number.label',
    render: row => <DataColumn title={`${row.iban || row.accountNumber} ${row.currency}`} />,
    sort: 'iban',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.account-statements-table.owner.label',
    render: row => <DataColumn title={row.ownerName} />,
    sort: 'name',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.account-statements-table.date.label',
    render: row => <DataColumn title={format.date(row.date)} />,
    sort: 'balanceDate',
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.account-statements-table.opening-balance.label',
    render: row => <DataColumn title={format.getAmount(row.openingBalance)} />,
    sort: 'openingBalance',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.account-statements-table.credit.label',
    render: row => <DataColumn title={format.getAmount(row.credit)} />,
    sort: 'creditTurnOver',
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.account-statements-table.debet.label',
    render: row => <DataColumn title={format.getAmount(row.debet)} />,
    sort: 'debetTurnOver',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.account-statements-table.closing-balance.label',
    render: row => <DataColumn title={format.getAmount(row.closingBalance)} />,
    sort: 'closingBalance',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    renderMenu: (row, _, filter) => [
      {
        label: 'front.account-statements-table.menu-view.label',
        onClick: () => onExport([row], 'PDF', filter.shapedStatementTime),
      },
      {
        label: 'front.account-statements-table.menu-send.label',
        onClick: async () => {
          const params = {
            dateFrom: row.date,
            dateTo: row.date,
            ids: [row.id],
            accountIds: [row.accountId],
            time: filter.shapedStatementTime,
          };
          const onSend = async (params: ExportStatementRequest) =>
            await api.accounts.sendStatements(params);

          const isLetterSent = await confirmModal<boolean, EmailProps>(EmailModal, {
            params,
            onSend,
            fieldName: 'recipientEmails',
          });
          isLetterSent && (await confirmModal(SuccessModal));
        },
      },
    ],
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

const getBorderDates = (rows: HasId[]): Partial<Dates> => {
  const dateArr: Dates = {} as Dates;
  if (rows.length === 1) {
    dateArr['dateFrom'] = rows[0].date;
    dateArr['dateTo'] = rows[0].date;
  }

  if (rows.length > 1) {
    const dates = rows.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    const length = dates.length;
    dateArr['dateFrom'] = dates[0].date;
    dateArr['dateTo'] = dates[length - 1].date;
  }
  return dateArr;
};

const onExport = (data: HasId[], format: string, shapedStatementTime: string): void => {
  const ids = data.map(item => item.id);
  const accountIds = data.map(item => item.accountId);
  const withRevaluation =
    LocalStorage.getItem(LOCAL_STORAGE_KEY.PRESERVED_VALUES, {})[REVALUATION_LOCAL_KEY] ??
    undefined;
  api.accounts.exportStatements({
    ids,
    format,
    accountIds,
    formatType: format,
    withRevaluation,
    time: shapedStatementTime,
    ...getBorderDates(data),
  });
};

const fetchStatements = async (request: GridRequest) => {
  const { shouldUseIntegration, ...otherRequest } = request;

  return await api.accounts.getStatements({
    ...otherRequest,
    withSync: shouldUseIntegration,
  });
};

export const AnalyticsTablePage = ({ filterComponent }: AnalyticsTablePageProps) => (
  <DataTable
    name={TableNamesEnum.Analytics}
    columns={columns}
    fetchRows={fetchStatements}
    defaultSort="id"
    defaultOrder={SortOrder.Desc}
    filterComponent={filterComponent}
  />
);
