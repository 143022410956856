import React from 'react';

import { OrderState, VatType } from 'api/enums';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { DomesticTransferDetailNames } from 'pages/Payments/InternalPayment/enums';
import { UseUpdatePurposePropsType } from 'pages/Payments/InternalPayment/hooks/useUpdatePurpose';
import { PaymentOrderFields } from 'pages/Payments/InternalPayment/interface';
import { hasVatTypeInPurpose } from 'pages/Payments/InternalPayment/utils';

export const useChangingPurpose = ({ order, vatPercent }: UseUpdatePurposePropsType) => {
  const { getFormData, setPayload, updateFieldInData } = useForm<PaymentOrderFields>();

  const { purpose, vatType, amount, isCustomBudgetPurpose } = getFormData();
  const prevVatType = React.useRef(null);

  React.useEffect(() => {
    const isDraft = order && (order.state === OrderState.Draft || !order.state);
    const isNotTaxesVatType = vatType !== VatType.EMPTY && vatType !== VatType.TAXES_PAYMENT;

    if (isDraft && isNotTaxesVatType && !isCustomBudgetPurpose) {
      if (prevVatType.current && !hasVatTypeInPurpose({ purpose, vatType, amount, vatPercent })) {
        setPayload({ templatePurpose: null });
        updateFieldInData(DomesticTransferDetailNames.VatType, 'value', null);
        return;
      }

      updateFieldInData(DomesticTransferDetailNames.VatType, 'value', vatType);
    }

    prevVatType.current = vatType;
  }, [purpose]);
};
