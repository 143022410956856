import moment from 'moment';

import { api } from 'api';
import { OrderType } from 'api/DemandsService/enums';
import { DemandAction, DemandBase, DemandResponse } from 'api/DemandsService/interfaces';
import { DocumentManagementStateEnum } from 'api/DocumentManagementService/enum';
import { OrderActionType, OrderKind, OrderState } from 'api/enums';
import { Order } from 'api/OrderService';
import { SignType } from 'components/Document/OrderControls';
import { confirmationModal } from 'components/modals/ConfirmModal';
import { CHECK_ORDER_TYPES, isSignAuthLetterFiles } from 'pages/Demands/utils';
import { signActions } from 'pages/Payments/general';
import { signCertificateOrders, signOrders } from 'pages/utils/SignOrders/signOrders';

// TODO add test
export const getMaxDate = (date: Date, daysCount: number | string): Date =>
  moment(date).add(daysCount, 'days').toDate();

// TODO add test
export const getOrderDate = (order: Partial<DemandBase>, isCopyForm = false): Date => {
  if (order.orderState !== OrderState.Draft && !isCopyForm) {
    return moment(order.orderDate).toDate();
  }

  if (moment(order.orderDate).valueOf() > moment().toDate().valueOf()) {
    return moment(order.orderDate).toDate();
  }

  return moment().toDate();
};

// TODO add test
export const orderControlsSign = async (orderId: number) => {
  const orderDetail = await api.order.getNewOrderModel(orderId);

  const action = (orderDetail.mobileActions || orderDetail.actions).find(
    (item: DemandAction) => item.actionName === OrderActionType.SIGN,
  );

  const customerId = orderDetail.custId || orderDetail.base.customerId;

  action
    ? await sign(
        orderId,
        customerId,
        action.confirmation,
        isSignAuthLetterFiles(orderDetail.dynamicFields),
      )
    : await signActions(orderDetail as DemandResponse);
};

// TODO add test
const sign = async (
  id: number,
  customerId: number,
  signTypes: SignType[],
  isSignFiles: boolean,
) => {
  let isSuccess = false;
  loop: for (const type of signTypes) {
    switch (type) {
      case 'Signature':
      case 'EDS': {
        isSuccess = await signCertificateOrders([{ id, customerId, isSignFiles }]);
        if (!isSuccess) break loop;
        break;
      }
      case 'SMS': {
        isSuccess = await signOrders([id]);
        if (!isSuccess) break loop;
        break;
      }
    }
  }

  if (!isSuccess) {
    await confirmationModal(
      'front.components.order-controls.save-request-in-status-entered.label',
      true,
      false,
    );
  }
};

export const isNotDraft = (state: OrderState | DocumentManagementStateEnum): boolean =>
  state && state !== OrderState.Draft;

export const hasLinkedOrder = (
  order: Order<any>,
  linkedOrderType?: Extract<Order, 'linkedOrderType'>,
): boolean =>
  !!order?.linkedOrderId && (linkedOrderType || order?.linkedOrderType === linkedOrderType);

export const isDisabled = (
  order: Order<any>,
  linkedOrderType?: Extract<Order, 'linkedOrderType'>,
): boolean => (order && isNotDraft(order.state)) || hasLinkedOrder(order, linkedOrderType);

// if order type is in CHECK_ORDER_TYPES and does not have COPY action or does not have an id
export const generatedByInvoice = (order: Order<any>) => {
  return (
    order &&
    order.orderKind !== OrderKind.TEMPLATE &&
    CHECK_ORDER_TYPES.indexOf(order.type as OrderType) !== -1 &&
    (!order.actions.find(action => action.name === OrderActionType.COPY) || !order.id)
  );
};
