import React from 'react';

import classNames from 'classnames';
import { Devices } from 'enums/DevicesEnum';
import lodashThrottle from 'lodash.throttle';
import ResizeObserver from 'resize-observer-polyfill';

import config from 'config';

type CalculateBreakpointFn = (width: number) => string;

type ResizeObserverConfig = {
  calculate: CalculateBreakpointFn;
  htmlElement: React.ElementType;
};

export const withResizeObserver =
  <P,>(config: ResizeObserverConfig) =>
  (Component: React.ElementType): React.FC<P> => {
    const { htmlElement: Element = 'div', calculate = calculateDevice } = config;

    const WrapperComponent: React.FC<P> = props => {
      const ref = React.useRef<HTMLDivElement>();
      const [breakpoint, setBreakpoint] = React.useState<string>();

      React.useLayoutEffect(() => {
        const resizeObserver = new ResizeObserver(onResize);
        resizeObserver.observe(ref.current);

        return () => resizeObserver.unobserve(ref.current);
      }, []);

      const onResize = lodashThrottle(([entry]) => {
        if (ref.current) {
          setBreakpoint(calculate(entry.contentRect.width));
        }
      }, 300);

      return (
        <Element
          ref={ref}
          className={classNames('resize-observer', `resize-observer_breakpoint_${breakpoint}`)}
        >
          <Component breakpoint={breakpoint} {...props} />
        </Element>
      );
    };

    return WrapperComponent;
  };

const calculateDevice: CalculateBreakpointFn = width => {
  if (width <= config.app.containerSizes.mobile) {
    return Devices.Mobile;
  }

  if (width <= config.app.containerSizes.tablet) {
    return Devices.Tablet;
  }

  return Devices.Desktop;
};

export const withDeviceResolver = <P,>(Component: React.ElementType) =>
  withResizeObserver<P>({ htmlElement: 'div', calculate: calculateDevice })(Component);
