import { CertificateService } from 'api/CertificateService/CertificateService';

import { AccountService } from './AccountService';
import { AcquiringService } from './AcquiringService';
import { AnnouncementsService } from './AnnouncementsService';
import { AuthService } from './AuthService';
import { CommonService } from './CommonService';
import { ConstantService } from './ConstantService';
import { CountryService } from './CountryService';
import { CurrencyService } from './CurrencyService';
import { DemandsService } from './DemandsService/DemandsService';
import { DepartmentsService } from './DepartmentsService';
import { DocumentManagementService } from './DocumentManagementService/DocumentManagementService';
import { EventService } from './EventService';
import { ExportService } from './ExportService';
import { HandbooksService } from './HandbooksService';
import { LocaleService } from './LocaleService';
import { LogService } from './LogService';
import { MailService } from './MailService';
import { NotificationService } from './NotificationService';
import { OrderService } from './OrderService';
import { PaymentsService } from './PaymentService/PaymentsService';
import { ProductsService } from './ProductsService';
import { ProfileService } from './ProfileService';
import { RedirectUri } from './RedirectUri/RedirectUri';
import { RegistrationService } from './RegistrationService';
import { RegularPaymentsService } from './RegularPaymentsService';
import { SalaryService } from './SalaryService';
import { SmsService } from './SmsService';
import { UserService } from './UserService';

export const api = {
  auth: new AuthService(),
  sms: new SmsService(),
  locale: new LocaleService(),
  payments: new PaymentsService(),
  currency: new CurrencyService(),
  common: new CommonService(),
  user: new UserService(),
  mail: new MailService(),
  accounts: new AccountService(),
  handbooks: new HandbooksService(),
  demands: new DemandsService(),
  products: new ProductsService(),
  announcements: new AnnouncementsService(),
  departments: new DepartmentsService(),
  notification: new NotificationService(),
  logService: new LogService(),
  constant: new ConstantService(),
  events: new EventService(),
  regularPayments: new RegularPaymentsService(),
  acquiring: new AcquiringService(),
  salary: new SalaryService(),
  profile: new ProfileService(),
  order: new OrderService(),
  certificate: new CertificateService(),
  registration: new RegistrationService(),
  export: new ExportService(),
  country: new CountryService(),
  documentManagement: new DocumentManagementService(),
  redirectUri: new RedirectUri(),
};
