import * as React from 'react';

import { KeyType } from 'api/CertificateService/enums';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import {
  CertificateContext,
  ICertificateContext,
} from 'pages/Profile/Certificates/CertificateEngine/CertificateContext';

export const useCertificateDetails = () => {
  const form = useForm();
  const { customer } = React.useContext<ICertificateContext>(CertificateContext);

  const mode = form.getFieldValue('mode') as KeyType;

  return {
    form,
    mode,
    customer,
  };
};
