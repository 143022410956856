import React from 'react';
import { useSelector } from 'react-redux';

import { Checkbox } from 'components/forms/checkbox/Checkbox';
import { translate } from 'i18n/translator';
import { selectCustomers } from 'store/selectors';

export const CertificateDetail: React.FC = () => {
  const customers = useSelector(selectCustomers());
  return (
    <div>
      <p>{translate('front.certificates.expanded-row.label')}</p>
      {customers.map(customer => (
        <div key={customer.taxCode}>
          <Checkbox
            label={`${customer.name} (${customer.taxCode})`}
            checked={customer.allowedForUserCert}
            name={String(customer.id)}
            disabled
          />
        </div>
      ))}
    </div>
  );
};
