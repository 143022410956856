import React from 'react';
import { useLocation } from 'react-router';

import { parseUrlParams } from 'api/backend';
import { DemandType } from 'api/DemandsService/enums';
import { OrderActionType } from 'api/enums';
import { useOrder } from 'components/Document/useOrder';
import { withOrder } from 'components/Document/withOrder';
import { NavTab, NavTabs } from 'components/tabs/NavTabs';
import { translate } from 'i18n/translator';
import { allowDemandAction } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { CreditFundsDetail } from 'pages/Demands/CreditDemand/CreditFundsDetail';
import { CreditProlongationDetails } from 'pages/Demands/CreditDemand/CreditProlongationDetails';
import { NewCreditTrancheOrderDetail } from 'pages/Demands/CreditDemand/NewCreditTranchOrderDetail';
import { DemandHistoryPage } from 'pages/Demands/DemandHistoryPage';
import { createOnSave, fetchOrder } from 'pages/Demands/utils';
import { isExist } from 'utils/isData';

import '../styles.scss';

const goBack = () => goto(pages.demands);

const renderDetails = (orderType: DemandType): React.ReactNode => {
  const typeComponents: Obj<React.ReactNode> = {
    [DemandType.CreditProlongationOrder]: <CreditProlongationDetails />,
    [DemandType.CreditFundsOrder]: <CreditFundsDetail />,
    default: <NewCreditTrancheOrderDetail />,
  };

  if (isExist(orderType)) {
    return typeComponents[orderType] || typeComponents.default;
  }

  return null;
};

const CreditDemandComponent: React.FC = () => {
  const { order } = useOrder();

  const { type } = parseUrlParams(useLocation().search);
  const orderType = type ?? order?.type;

  const Details = React.useMemo(() => renderDetails(orderType), [orderType]);

  return (
    <NavTabs>
      <NavTab
        title={translate('front.demand-detail.details.label')}
        path={pages.creditDemand.tabs.details(':id')}
      >
        {Details}
      </NavTab>
      {!!order?.state && (
        <NavTab
          title={translate('front.demand-detail.history.label')}
          path={pages.creditDemand.tabs.history(':id')}
        >
          <DemandHistoryPage />
        </NavTab>
      )}
    </NavTabs>
  );
};

export const CreditDemandPage = withOrder(({ order }) => ({
  createOnSave,
  fetchOrder: fetchOrder,
  allowState: true,
  allowDetails: true,
  allowSave:
    order && allowDemandAction(order.type as DemandType, OrderActionType.EDIT, +order.customerId),
  allowSign:
    order && allowDemandAction(order.type as DemandType, OrderActionType.SIGN, +order.customerId),
  disableButtons: true,
  afterSubmit: goBack,
}))(CreditDemandComponent);
