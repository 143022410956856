import React from 'react';
import { Col, Row, Container } from 'react-grid';
import { useRouteMatch } from 'react-router';

import moment from 'moment';

import { api } from 'api';
import { RegularPeriodicity } from 'api/RegularPaymentsService';
import { Button } from 'components/buttons/Button';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DateField } from 'components/forms/inputs/DateField';
import { SelectField } from 'components/forms/inputs/SelectField';
import * as format from 'components/utils/format';
import { translate } from 'i18n/translator';
import { isActionAllowed } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { NEW } from 'navigations/routes';
import { SchedulePageNames } from 'pages/Payments/RegularPayments/enums';

export interface Fields {
  period: string;
  startDate: Date;
  stopDate: Date;
  type: RegularPeriodicity;
}

const DEFAULT_PERIOD = '0';
const MAX_DAYS_IN_MONTH = 31;
export const today: Date = moment().toDate();
const minDateRequired = today;
minDateRequired.setDate(today.getDate() + 1);

const getMinDayCount = (dateFrom: Date, dateTo: Date) => {
  if (!dateFrom || !dateTo) {
    return MAX_DAYS_IN_MONTH;
  }

  let startDate: Date = new Date(dateFrom.getFullYear(), dateFrom.getMonth(), 1);
  let endDate: Date = new Date(dateTo.getFullYear(), dateTo.getMonth(), 1);

  if (endDate < startDate) {
    const temp = startDate;
    startDate = endDate;
    endDate = temp;
  }

  let minDaysCount = MAX_DAYS_IN_MONTH;

  while (startDate <= endDate) {
    const daysInMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0).getDate();
    minDaysCount = daysInMonth < minDaysCount ? daysInMonth : minDaysCount;
    startDate.setMonth(startDate.getMonth() + 1);
  }

  return minDaysCount;
};

export const getScheduleDetailFields = async (id: string, scheduleId: string) => {
  if (scheduleId !== NEW) {
    const fields = await api.regularPayments.getSchedule(id, scheduleId);

    const { type, period, startDate, stopDate } = fields;
    const startPeriod = today > format.parseDate(startDate) ? today : format.parseDate(startDate);
    const endPeriod = stopDate
      ? format.parseDate(stopDate) > startPeriod
        ? format.parseDate(stopDate)
        : startPeriod
      : startPeriod;
    return {
      startDate: startPeriod,
      stopDate: endPeriod,
      type: type as RegularPeriodicity,
      period: `${period}`,
    };
  }
};

export const SchedulePage = () => {
  const {
    params: { id: orderId, scheduleId },
  } = useRouteMatch<{ id: string; scheduleId: string }>();

  const {
    form: { getFormData, updateData, progress, initializing, handleSubmit, removeField },
  } = useOrderDetails<Fields>();

  const onScheduleSave = async (formData: Fields) => {
    const { type, startDate, stopDate, period } = formData;
    const scheduleParams = {
      period: period ?? DEFAULT_PERIOD,
      startDate,
      stopDate: stopDate ?? startDate,
      type,
    };

    if (scheduleId && scheduleId !== NEW) {
      await api.regularPayments.updateSchedule({
        orderId: +orderId,
        id: +scheduleId,
        ...scheduleParams,
      });
    } else {
      await api.regularPayments.createSchedule({
        orderId,
        ...scheduleParams,
      });
    }
    goto(pages.regularPayments);
  };

  const { type, startDate, stopDate, period } = getFormData();
  const { order } = useOrderDetails();

  const maxAvailableDate = React.useMemo(
    () => getMinDayCount(startDate, stopDate),
    [startDate, stopDate],
  );

  const dateOptions = React.useMemo(
    () => api.regularPayments.getDateOptions(maxAvailableDate),
    [maxAvailableDate],
  );

  React.useEffect(() => {
    if (type === RegularPeriodicity.SINGLE) {
      removeField('stopDate');
    }

    if (period && Number(period) > maxAvailableDate) {
      updateData({ period: null });
    }
  }, [maxAvailableDate, type]);

  return (
    <div>
      <Container>
        <Row>
          <Col md={6}>
            <SelectField
              label="front.regular-payments-page-table.frequency.label"
              name={SchedulePageNames.Type}
              options={api.regularPayments.getRegularPaymentTypes()}
              onSelectOption={() => updateData({ period: null })}
              required
            />
          </Col>
          <Col md={6}>
            {type === RegularPeriodicity.WEEKLY && (
              <SelectField
                label="front.regular-payments-page-table.day-of-week.label"
                name={SchedulePageNames.Period}
                options={api.regularPayments.getDayOptions()}
                required
              />
            )}
            {type === RegularPeriodicity.MONTHLY && (
              <SelectField
                label="front.regular-payments-page-table.day-of-month.label"
                name={SchedulePageNames.Period}
                options={dateOptions}
                required
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <DateField
              label="front.regular-payments-page-table.validFrom.label"
              name={SchedulePageNames.StartDate}
              minDate={today}
              defaultValue={today}
              disabledDays={{
                before: today,
                after: stopDate,
              }}
              required
            />
          </Col>
          <Col md={6}>
            {type !== RegularPeriodicity.SINGLE && (
              <DateField
                label="front.regular-payments-page-table.validTo.label"
                name={SchedulePageNames.StopDate}
                initialDate={stopDate || startDate}
                minDate={startDate}
                disabledDays={{ before: minDateRequired }}
                required
              />
            )}
          </Col>
        </Row>
      </Container>
      {isActionAllowed([Privileges.paymentRegularEdit], order?.customerId) && !initializing && (
        <Button
          color="primary"
          onClick={e => handleSubmit(onScheduleSave, e)}
          disabled={progress}
          size="md"
        >
          {translate('front.regular-payments-page-table.save-button.label')}
        </Button>
      )}
    </div>
  );
};
