import React from 'react';
import { Col, Row } from 'react-grid';
import { useLocation } from 'react-router';

import { parseUrlParams } from 'api/backend';
import { Account } from 'api/interfaces';
import { Option } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { HintField } from 'components/forms/inputs/HintField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextField } from 'components/forms/inputs/TextField';
import { translate } from 'i18n/translator';
import { InternationalTransferDetailNames } from 'pages/Payments/InternalPayment/enums';
import {
  handleBlurSubAccount,
  handleFocusSubAccount,
  hasAttributeTransactionAccount,
  lengthPurpose,
  normalizeSubAccount,
} from 'pages/Payments/InternalPayment/utils';
import { isExistOrder } from 'utils/isData';

import { DetailProps, Payload as InternalPaymentPagePayload } from './interface';

export const InternalTransferDetails: React.FC<DetailProps> = ({
  allowSavePurpose,
  isDisabled = false,
}) => {
  const { order, payload, form, onChangeCustomer } = useOrderDetails<
    any,
    InternalPaymentPagePayload
  >();

  const hasOrder = isExistOrder(order);

  const { copyFrom, customerId } = parseUrlParams(useLocation().search);
  const { payerAccount, receiverAccount } = form.getFormData();
  const { receiverAccountOptions, payerAccountOptions } = payload;

  React.useEffect(() => order && form.updateData({ ...order.detailFields }), []);

  React.useEffect(() => void handlePayerAccountChange(), [payerAccount]);

  React.useEffect(() => {
    handleReceiverAccountChange();
    const isCopy = order?.id || order?.detailFields?.subAccount;

    if (!hasOrder || !isCopy) {
      form.updateFieldInData(InternationalTransferDetailNames.SubAccount, 'value', '');
      form.updateFieldInData(InternationalTransferDetailNames.SubAccount, 'error', '');
    }
  }, [receiverAccount]);

  const handlePayerAccountChange = () =>
    form.setPayload((state: InternalPaymentPagePayload) => {
      const { allPayerAccountOptions, allReceiverAccountOptions } = state;

      const payerAccountOption = allPayerAccountOptions?.find(
        account => +account.value === +payerAccount,
      );

      const [accounts, isoCode] = filterAccounts(allReceiverAccountOptions, payerAccountOption);
      return { ...state, isoCode, receiverAccountOptions: accounts };
    });

  const handleReceiverAccountChange = () =>
    form.setPayload((state: InternalPaymentPagePayload) => {
      const { allReceiverAccountOptions, allPayerAccountOptions } = state;

      const receiverAccountOption = allReceiverAccountOptions?.find(
        account => +account.value === +receiverAccount,
      );

      const [accounts, isoCode] = filterAccounts(allPayerAccountOptions, receiverAccountOption);
      return { ...state, isoCode, payerAccountOptions: accounts };
    });

  const filterAccounts = (options: Option<Account>[], option: Option<Account>) => {
    let currency = payload.isoCode;
    if (option?.content) {
      const { content, value } = option;
      let accounts = options.filter(item => item.value !== value);
      if (content.currency) {
        accounts = accounts.filter(item => item.content.currency === content.currency);
        currency = content.currency;
      }
      return [accounts, currency];
    }
    return [options, currency];
  };

  const receiverNumber = React.useMemo(() => {
    if (!receiverAccountOptions) {
      return form.getFieldValue(InternationalTransferDetailNames.SubAccount) as string;
    }

    if (receiverAccount) {
      const receiverAccountOption = receiverAccountOptions?.find(
        account => +account.value === +receiverAccount || account.content.iban === receiverAccount,
      );
      return receiverAccountOption?.content.number;
    }

    return null;
  }, [receiverAccount, receiverAccountOptions]);

  return (
    <DefaultForm>
      <h3>{translate('front.internal-payment-page.order-details.label')}</h3>
      <CustomerSelect
        label="front.internal-payment-page.order-payer-name.label"
        name={InternationalTransferDetailNames.PayerName}
        onSelectOption={onChangeCustomer}
        disabled={!!order?.state || !!copyFrom || !!customerId}
        required
      />
      <SelectField
        label="front.internal-payment-page.order-payer-account.label"
        name={InternationalTransferDetailNames.PayerAccount}
        options={payerAccountOptions}
        required
        clearable
        disabled={!hasOrder}
      />
      <SelectField
        label="front.internal-payment-page.order-receiver-account.label"
        name={InternationalTransferDetailNames.ReceiverAccount}
        options={receiverAccountOptions}
        required
        clearable
      />
      <h3>{translate('front.internal-payment-page.order-amount-and-purpose.label')}</h3>
      <Row>
        <Col sm={6}>
          <AmountField
            label={`${translate('front.internal-payment-page.order-amount.label')} ${
              form.payload.isoCode || ''
            }`}
            name={InternationalTransferDetailNames.Amount}
            minAmount={0.01}
            maxLengthWithoutZero={12}
            isoCode={form.payload.isoCode}
            disabled={isDisabled}
            hasMoneyFormat
            thousandSeparator={' '}
            required
          />
        </Col>
        {hasAttributeTransactionAccount(receiverNumber) && (
          <Col sm={6}>
            <TextField
              label="front.internal-payment-page.order-subaccount.label"
              name={InternationalTransferDetailNames.SubAccount}
              disabled={!receiverAccount || isDisabled}
              onFocus={handleFocusSubAccount(receiverNumber, form.updateData)}
              onBlur={handleBlurSubAccount(receiverNumber, form.updateFieldInData)}
              normalize={normalizeSubAccount(receiverNumber)}
              autoComplete="off"
            />
          </Col>
        )}
      </Row>
      <HintField
        label="front.internal-payment-page.order-purpose.label"
        name={InternationalTransferDetailNames.Purpose}
        rows={3}
        minLength={7}
        maxLength={lengthPurpose}
        hints={payload.purposes}
        disabled={isDisabled}
        hasCounter
        required
        forbiddenSpecialCharacters
        denyCaretReturn
      />
      {allowSavePurpose && (
        <CheckboxField
          label="front.internal-payment-page.order-save-payment-purpose.label"
          name={InternationalTransferDetailNames.SavePaymentPurpose}
        />
      )}
    </DefaultForm>
  );
};
