import { Option } from 'api/Service';

import { get } from './backend';

export interface Currency {
  digitalCode: string;
  fullName: string;
  isoCode: string;
  name: string;
}

export interface CurrencyExchangeRate {
  baseCurrencyIsoCode: string;
  coursePurchase: string;
  courseSale: string;
  currencyIsoCode: string;
  id: number;
  previousCoursePurchase: string;
  previousCourseSale: string;
  scale: number;
  type: {
    id: number;
    label: string;
    value: string;
  };
  typeId: number;
  validFrom: Date;
  validTo: Date;
}

export class CurrencyService {
  async getAllCurrencies(): Promise<Array<Currency>> {
    const { rows } = await get('/currency');
    return rows;
  }

  async getCurrencyList(): Promise<Option[]> {
    const { rows } = await get('/v1/products');
    const currencies = [...new Set(rows.map((option: any) => option.product.currency))];

    return currencies.map(currency => ({
      label: currency as string,
      value: currency as string,
    }));
  }

  async getEchangeRates(baseCurrencyIsoCode: string): Promise<Array<CurrencyExchangeRate>> {
    return await get('/exchange-rate/by-currency/', { baseCurrencyIsoCode });
  }
}
