import * as React from 'react';
import { Col, Row } from 'react-grid';

import { api } from 'api';
import { Button } from 'components/buttons/Button';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { ExportFormatEnum } from 'components/ExportDropdown/enum';
import { ExportDropdown, getExportActions } from 'components/ExportDropdown/ExportDropdown';
import { getCurrentMonth } from 'components/forms/datePicker/utils';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { DateRangeField } from 'components/forms/inputs/DateRangeField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { withForm } from 'components/forms/withForm';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { CreateButton } from 'components/Table/CreateButton';
import { useFilter } from 'components/Table/DataTable';
import { ToggleFilterButton } from 'components/Table/ToggleFilterButton/ToggleFilterButton';
import { translate } from 'i18n/translator';
import { DisabledBlock } from 'navigations/access';

import { TerminalsModal } from './TerminalModal';

interface Fields {
  amountFrom: number;
  amountTo: number;
  refundDateFrom: Date;
  refundDateTo: Date;
  customerIds?: string[];
  tradePointId?: string;
}

interface Payload {
  terminalIds: (number | string)[];
}

const EXPORT_TYPES: Record<string, ExportFormatEnum> = {
  XLS: ExportFormatEnum.EXCEL,
  CSV: ExportFormatEnum.CSV,
  DBF: ExportFormatEnum.DBF,
};

export const AcquiringStatementsFilterForm: React.FC = () => {
  const [tradePointOptions, setTradePointOptions] = React.useState(null);

  React.useEffect(() => {
    fetchTradePoints();
  }, []);

  const fetchTradePoints = async (customerIds?: string[]) => {
    const tradePointOptions = await api.acquiring.getTradePointOptions({
      customerIds,
    });
    const tradePointId = customerIds ? tradePointOptions[0]?.value : null;
    setTradePointOptions(tradePointOptions);
    updateData({ tradePointId });
  };

  const {
    rows,
    applyFilter,
    updateData,
    updateExtraFields,
    setProgress,
    extraFields,
    getFormData,
    gridRequest,
  } = useFilter<Fields, Payload>({
    fetchOnDemand: true,
    extraFields: { terminalIds: null },
  });

  const { terminalIds } = extraFields;
  const formData = getFormData();
  const { tradePointId } = formData;
  const [start, end] = getCurrentMonth();

  const onChangeCustomer = async () => {
    setProgress(true);

    updateData({ tradePointId: null });
    await fetchTradePoints(formData.customerIds);

    setProgress(false);
  };

  const exportActions = React.useMemo(() => {
    const aqFilter = {
      ...extraFields,
      ...formData,
      tradePointIds: [tradePointId],
      sort: gridRequest.sort,
      order: gridRequest.order,
    };
    const action = (exportType: string) => () =>
      api.acquiring.exportAcquiringStatements({ exportType, ...aqFilter });

    return getExportActions({
      action,
      exportTypes: EXPORT_TYPES,
    });
  }, [JSON.stringify(formData), gridRequest.sort, gridRequest.order]);

  return (
    <>
      <Row>
        <Col md={6} sm={5}>
          <CreateButton
            title={'front.account-statements-filter-form.apply-button.label'}
            onClick={applyFilter}
          />
        </Col>
        <Col md={6} hAlign="end">
          <DisabledBlock disabled={rows.length === 0}>
            <ExportDropdown actions={exportActions} />
          </DisabledBlock>
        </Col>
        <Col xl={3} md={6}>
          {tradePointId && (
            <Button
              color="secondary"
              onClick={async () => {
                const ids: number[] = await confirmModal(
                  TerminalsModal,
                  { id: tradePointId },
                  900,
                  {
                    padding: 0,
                  },
                );

                ids && updateExtraFields({ terminalIds: ids });
              }}
            >
              {`${translate('front.acquiring.terminal.list')}${
                terminalIds ? ` - ${terminalIds.length}` : ''
              }`}
            </Button>
          )}
        </Col>
        <Col xl={9}>
          <Row>
            <Col xl={4} lg={6} md={6}>
              <CustomerSelect
                label="front.internal-payment-page.order-payer-name.label"
                name="customerIds"
                onBlur={onChangeCustomer}
                selectAll
                multi
              />
            </Col>
            <Col xl={4} lg={6} md={6}>
              <SelectField
                label="front.acquiring.trade-point.label"
                name="tradePointId"
                options={tradePointOptions}
                defaultValue={tradePointOptions && tradePointOptions[0]?.value}
                clearable
                required
              />
            </Col>
            <Col xl={3} lg={6} md={6}>
              <DateRangeField
                label="front.acquiring.date-of-transaction"
                nameFrom="refundDateFrom"
                nameTo="refundDateTo"
                defaultFrom={start}
                defaultTo={end}
                clearable
                required
              />
            </Col>
            <Col md={1}>
              <ToggleFilterButton smallMode>
                <Row>
                  <Col md={4}>
                    <AmountField
                      label="front.working-documents-filter-form.amount-from.label"
                      name="amountFrom"
                    />
                  </Col>
                  <Col md={4}>
                    <AmountField
                      label="front.working-documents-filter-form.amount-to.label"
                      name="amountTo"
                    />
                  </Col>
                </Row>
              </ToggleFilterButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export const AcquiringStatementsFilter = withForm<Fields>(AcquiringStatementsFilterForm);
