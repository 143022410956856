import * as React from 'react';
import { useDataTable } from 'react-data-table';

import { FormError } from 'components/forms/FormError';

export const DataTableError = () => {
  const error = useDataTableError();

  return <FormError>{error}</FormError>;
};

const useDataTableError = () => {
  const { error } = useDataTable();

  return error;
};
