import { checkMultiCustomer } from 'api/ConstantService';
import { DemandBase } from 'api/DemandsService/interfaces';
import { StatusColor } from 'components/layout/Status';

import { del, downloadFile, get, HttpMethod, post, put } from './backend';
import { GridRequest, GridResponse } from './Service';

export enum AuthLetterActionType {
  EDIT = 'EDIT',
  SIGN = 'SIGN',
  DELETE = 'DELETE',
  COPY = 'COPY',
  PRINT = 'PRINT',
}

export enum LetterStatus {
  DRAFT = 'DRAFT',
  SIGNED = 'SIGNED',
  WAITING = 'WAITING',
  SENT = 'SENT',
  SENTFAIL = 'SENTFAIL',
  SUBMITED = 'SUBMITED',
  DELIVERED = 'DELIVERED',
  SUBMITFAIL = 'SUBMITFAIL',
  DELIVERFAIL = 'DELIVERFAIL',
  DELETED = 'DELETED',
}

export type LettersGrid = Omit<RequiredLetter, 'displayKind' | 'isMassMailing'> & {
  sendStatus: boolean;
};

export interface LettersGridFilter extends GridRequest {
  selectedPeriod?: string;
  type?: string;
}

export interface SendLetterRequest {
  desc: string;
  documentStatus: string;
  subject: string;
}

export interface LettersResponse {
  massMailingIds: Array<number>;
  selectedLetterIds: Array<number>;
  successCount: number;
}

interface MessageFile {
  contentType: string;
  id: string;
  isMassMailing: boolean;
  name: string;
}

export interface Letter {
  companyId: number;
  desc: string;
  documentStatus: string;
  id: number;
  isFromCustomer: boolean;
  letterDistributionGroupId: number;
  massMailingMessageId: number;
  messageAttachmentIds: Array<number>;
  messageAttachments: Array<File>;
  messageFiles: Array<MessageFile>;
  messageTime: Date;
  person: string;
  personIds: Array<number>;
  reply: string;
  shortDesc: string;
  subject: string;
}

export interface RequiredLetter {
  companyPersonName: string;
  customerName: string;
  date: Date;
  desc: string;
  displayKind: string;
  documentStatus: string;
  id: number;
  isFromCustomer: boolean;
  isMassMailing: boolean;
  isRead: boolean;
  managerName: string;
  statusLabel: string;
  subject: string;
}

interface MailDynamicFields {
  data: {
    textValue: string;
  }[];
  field: {
    code: string;
  };
}

export interface AuthLetter {
  actions: AuthLetterAction[];
  base: DemandBase['id' | 'orderType' | 'orderDate' | 'orderStateLabel'];
  dynamicFields: MailDynamicFields[];
  id: number;
}

export interface AuthLetterAction {
  actionName: AuthLetterActionType;
  confirmation?: string;
}

export const getStatusColor = (status: LetterStatus): StatusColor => {
  switch (status) {
    case LetterStatus.SENT:
    case LetterStatus.SUBMITED:
    case LetterStatus.DELIVERED:
      return 'green';
    case LetterStatus.DELETED:
    case LetterStatus.DELIVERFAIL:
    case LetterStatus.SENTFAIL:
      return 'red';
    default:
      return 'grey';
  }
};

export class MailService {
  async getLetters(request: LettersGridFilter): Promise<GridResponse<LettersGrid>> {
    return await get('/v2/letter/customer', checkMultiCustomer(request));
  }

  async getLetter(id: string): Promise<Letter> {
    return await get(`/letter/customer/${id}`);
  }

  async getRequiredLetters(): Promise<Array<RequiredLetter>> {
    return await get('/v1/letter/popup');
  }

  async readRequiredLetter(id: number): Promise<void> {
    return await put(`/v1/ugb/letter/read/${id}`);
  }

  async createLetter(letterData: FormData): Promise<number> {
    return await post('/letter', letterData);
  }

  async updateLetter(letterData: FormData) {
    return await put('/v1/letter', letterData);
  }

  async sendLetters(selectedLetterIds: Array<number>): Promise<LettersResponse> {
    return await post('/letter/customer/list', { selectedLetterIds });
  }

  async deleteLetters(selectedLetterIds: Array<number>): Promise<LettersResponse> {
    return await del('/letter/customer/list', { selectedLetterIds });
  }

  async sendLetter(id: number): Promise<void> {
    return await put(`/letter/send/${id}`);
  }

  async deleteLetter(id: number): Promise<void> {
    return await del(`/letter/customer/${id}`);
  }

  async downloadLetterFile(id: string, fileId: MessageFile['id']): Promise<void> {
    return await downloadFile(
      `/v3/letters/${id}/attachments/${fileId}`,
      undefined,
      undefined,
      HttpMethod.GET,
    );
  }

  async deleteOrder(id: number): Promise<number> {
    return await put(`/v1/orders/${id}/DELETE`);
  }
}
