import { useEffect, useState } from 'react';

import { api } from 'api';

export const useReIdentificationMessage = () => {
  const [reIdentificationMessage, setReIdentificationMessage] = useState('');

  const getIdentificationMessage = async () => {
    const reIdentificationMessage = await api.user.getReIdentificationMessage();
    setReIdentificationMessage(reIdentificationMessage);
  };

  useEffect(() => void getIdentificationMessage(), []);

  return reIdentificationMessage;
};
