import React from 'react';
import { Col, Row } from 'react-grid';

import { FileType } from 'api/Service';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { FileField } from 'components/forms/inputs/FileField';
import { TextField } from 'components/forms/inputs/TextField';
import { translate } from 'i18n/translator';

import { Managers } from './components/Managers';
import { OrganizationComponent } from './components/OrganizationComponent';
import { DocumentManagementDetailsNames, DocumentManagementStateFilter } from './enums';
import { DocumentManagementDetailsFields } from './interfaces';

export const DocumentManagementDetails = () => {
  const { order } = useOrderDetails<DocumentManagementDetailsFields, any>();
  const showRejectComment = order?.state.includes(DocumentManagementStateFilter.Rejected);

  return (
    <DefaultForm>
      <h3>{translate('front.document-management.document.label')}</h3>
      <Row>
        <Col sm={4}>
          <TextField
            name={DocumentManagementDetailsNames.Category}
            label="front.document-management.document-category.label"
          />
        </Col>
        <Col sm={8}>
          <TextField
            name={DocumentManagementDetailsNames.Type}
            label="front.document-management.document-type.label"
          />
        </Col>
      </Row>
      <TextField
        name={DocumentManagementDetailsNames.Body}
        label={'front.document-management.topic.label'}
      />
      <h3>{translate('front.document-management.author.label')}</h3>
      <Row>
        <Col sm={6}>
          <TextField
            name={DocumentManagementDetailsNames.AuthorFullName}
            label="front.employees.col-pib.label"
          />
        </Col>
        <Col sm={6}>
          <TextField
            name={DocumentManagementDetailsNames.AuthorRole}
            label="front.organization.col-role.label"
          />
        </Col>
      </Row>
      <TextField
        name={DocumentManagementDetailsNames.AuthorBranch}
        label={'front.departments.title.label'}
        disabled
      />
      <Managers />
      <OrganizationComponent />
      {showRejectComment && (
        <TextField
          name={DocumentManagementDetailsNames.RejectComment}
          label={'front.document-management.comment.label'}
          disabled
        />
      )}
      <h3>{translate('front.document-management.attached_documents.label')}</h3>
      <FileField
        name={DocumentManagementDetailsNames.Attach}
        label="front.mail-modal.attach-files.label"
        accept={{ extensions: [] }}
        outputFormat={FileType.FILE}
        multiple
      />
    </DefaultForm>
  );
};
