import React from 'react';

import { TabBar } from './TabBar';

interface TabsProps {
  children: React.ReactElement<TabProps> | React.ReactElement<TabProps>[];
  initial?: string;
}

export type TabsRef<T = string> = {
  goToFirst: () => void;
  setActive: (value: T) => void;
};

const TabsWithRef = ({ initial, children }: TabsProps, ref: React.Ref<TabsRef>) => {
  const [tabs, options] = React.useMemo(() => {
    const tabs = React.Children.toArray(children);
    const options = tabs.reduce(
      (acc, { props: { children, isHidden, ...option } }) => (isHidden ? acc : [...acc, option]),
      [],
    );
    return [tabs, options];
  }, [children]);

  const [active, setActive] = React.useState(initial || tabs[0].props.value);

  React.useImperativeHandle(ref, () => ({
    setActive,
    goToFirst: () => setActive(tabs[0].props.value),
  }));

  return (
    <>
      <TabBar options={options} value={active} onChange={setActive} />
      {tabs.map(tab => (
        <div
          key={tab.props.value}
          style={{ display: tab.props.value === active ? 'initial' : 'none' }}
        >
          {tab}
        </div>
      ))}
    </>
  );
};

export const Tabs = React.forwardRef(TabsWithRef);

interface TabProps {
  children: React.ReactNode;
  label: string;
  value: string;
  isHidden?: boolean;
}

export const Tab = ({ children }: TabProps) => <>{children}</>;
