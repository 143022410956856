import React from 'react';
import { GridRequest, SortOrder } from 'react-data-table';

import { Devices } from 'enums/DevicesEnum';

import { DataFetcherContext } from 'components/DataFetcher/DataFetcherContext';
import { handleError } from 'utils/handleError';

export interface Props {
  breakpoint: string;
  fetch: any;
  defaultOrder?: SortOrder;
  defaultPageSize?: number;
  defaultSort?: string;
  hidePagination?: boolean;
}

export const DataFetcher: React.FC<Props> = ({
  fetch,
  defaultSort,
  defaultOrder = SortOrder.Desc,
  defaultPageSize = 20,
  hidePagination = false,
  breakpoint = Devices.Desktop,
  children,
}) => {
  const [rows, setRows] = React.useState<any[]>([]);
  const [count, setCount] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>(null);
  const [filter, setFilter] = React.useState(null);
  const [gridRequest, setGridRequest] = React.useState<GridRequest>({
    page: 0,
    size: hidePagination ? undefined : defaultPageSize,
    order: defaultOrder,
    sort: defaultSort,
  });

  React.useEffect(() => {
    fetchData();
  }, [fetch, gridRequest]);

  React.useEffect(() => {
    if (filter) {
      fetchData();
    }
  }, [filter]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const {
        rows,
        total: { count },
      } = await fetch({ ...filter, ...gridRequest });

      setRows(rows);
      setCount(count);
    } catch (error: any) {
      handleError(error, setError);
    } finally {
      setIsLoading(false);
    }
  };

  const updateGridRequest = (gridRequest: GridRequest) => {
    setError(null);
    setGridRequest(oldGridRequest => {
      return { ...oldGridRequest, ...gridRequest };
    });
  };

  const updateFilter = (newFilter: any) => {
    setFilter((oldFilter: any) => ({
      ...oldFilter,
      ...newFilter,
    }));
  };

  if (!children) return null;

  return (
    <DataFetcherContext.Provider
      value={{
        rows,
        count,
        error,
        isLoading,
        filter,
        breakpoint,
        updateFilter,
        gridRequest: { ...gridRequest, updateGridRequest },
        isHidePagination: hidePagination,
      }}
    >
      {children}
    </DataFetcherContext.Provider>
  );
};
