import * as React from 'react';
import { TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { OrderType } from 'api/DemandsService/enums';
import {
  CreditActions,
  CreditProduct,
  CreditView,
  getStatusColor,
  isActionAvailable,
  ProductStatus,
} from 'api/ProductsService';
import { GridRequest, Option, SortOrder, TotalInterface } from 'api/Service';
import * as Icon from 'components/icons';
import { Page } from 'components/layout/Page/Page';
import { Status } from 'components/layout/Status';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import * as format from 'components/utils/format';
import { translate } from 'i18n/translator';
import { isActionAllowed } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { NEW } from 'navigations/routes';
import { ProductLogo } from 'pages/Products/components/ProductLogo';

import { CreditDetails } from './CreditDetails';
import { CreditFilterFields, CreditsFilter } from './CreditsFilter';

import './styles.scss';

export const CreditTypes = {
  GENERAL_CREDIT: 'GENERAL_CREDIT',
  CREDIT_LINE: 'CREDIT_LINE',
  NON_REVOLVING_LINE: 'NON_REVOLVING_LINE',
  CREDIT_TRANCHE: 'CREDIT_TRANCHE',
};

export const generalProductTypes = [
  CreditTypes.GENERAL_CREDIT,
  CreditTypes.CREDIT_LINE,
  CreditTypes.NON_REVOLVING_LINE,
  CreditTypes.CREDIT_TRANCHE,
];

const tabLabel = {
  all: 'front.product-accounts-filter.statuses-all.label',
  [ProductStatus.Active]: 'front.product-accounts-filter.statuses-opened.label',
  [ProductStatus.Closed]: 'front.product-accounts-filter.statuses-closed.label',
  // [AccountStatus.Blocked]: translate('front.product-accounts-filter.statuses-blocked.label'),
};

const actionPrivileges: { [key in CreditActions]: Privileges[] } = {
  [CreditActions.STATEMENT]: [Privileges.financeStatementView],
  [CreditActions.HISTORY]: [Privileges.financeTransactionHistoryView],
  [CreditActions.TRANSFER]: [Privileges.paymentInternalEdit],
};

const isActionVisible = ({ customerId, actions }: CreditProduct, action: CreditActions) =>
  isActionAvailable(actions, action) && isActionAllowed(actionPrivileges[action], customerId);

const getStatusesOptions = (total: TotalInterface): Array<Option> =>
  Object.entries(tabLabel).map(([value, label]) => ({
    value,
    label: (
      <span className="credits-filter__tab">
        {translate(label)}
        <span className="credits-filter__tab__badge">
          {value === 'all'
            ? Object.values(total.status).reduce((a, b) => a + b, 0)
            : total.status[value.toUpperCase()] || 0}
        </span>
      </span>
    ),
  }));

const columns: TableColumnProps<CreditView>[] = [
  {
    label: 'credit-logo',
    render: ({ product }) => (
      <DataColumn>
        <ProductLogo type={product.subTypeName} currency={product.currency} />
      </DataColumn>
    ),
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'credit-name-edit',
    render: ({ product }) => (
      <DataColumn>
        {isActionAllowed([Privileges.productCreditEdit], product.customerId) && (
          <span
            onClick={e => {
              e.stopPropagation();
              goto(pages.credit(product.id));
            }}
            className="credit-name-edit"
          >
            <Icon.Edit />
          </span>
        )}
        <span className="credit-name">{product.alias || product.subTypeName}</span>
      </DataColumn>
    ),
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'credit-organization',
    render: ({ product }) => <DataColumn title={product.organization} />,
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'credit-code',
    render: ({ product }) => <DataColumn title={product.code} />,
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'credit-close-date',
    render: ({ product }) => (
      <DataColumn
        title={translate('front.deposits-page.close-date.label')}
        subTitle={format.date(product?.closed)}
      />
    ),
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'credit-status',
    render: ({ product }) => (
      <DataColumn>
        <Status color={getStatusColor(product.status)}>{product.statusName}</Status>
      </DataColumn>
    ),
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'credit-amount',
    render: ({ product, credit }) => {
      const isGeneral = generalProductTypes.includes(product?.subType);
      const title = isGeneral
        ? translate('front.deposits-page.available-limit.label')
        : translate('front.credits-page.current-debt.label');
      const balance = isGeneral ? credit.limit : credit.amountInterest;

      return (
        <DataColumn
          title={title}
          subTitle={`${format.getAmount(Number(balance))} ${product.currency}`}
        />
      );
    },
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    renderMenu: ({ product }) => {
      const mainAccountId = getAccountId(product, 'MAIN');
      const transitAccountId = getAccountId(product, 'TRANSIT');
      return [
        {
          label: translate('front.menu.statements.label'),
          onClick: () => goto(pages.statements({ accountId: mainAccountId })),
          isVisible: !!mainAccountId && isActionVisible(product, CreditActions.STATEMENT),
        },
        {
          label: translate('front.product-accounts-page.menu-history.label'),
          onClick: () => goto(pages.transactionsHistory({ accountId: mainAccountId })),
          isVisible: !!mainAccountId && isActionVisible(product, CreditActions.HISTORY),
        },
        {
          label: translate('front.credits-page.credits-payment.label'),
          onClick: () =>
            goto(
              pages.internalPayments.tabs.details(NEW, {
                type: OrderType.InternalTransfer,
                customerId: product.customerId,
                receiverAccount: `${transitAccountId}`,
              }),
            ),
          isVisible: !!transitAccountId && isActionVisible(product, CreditActions.TRANSFER),
        },
      ];
    },
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

const getAccountId = ({ params }: CreditProduct, type: 'MAIN' | 'TRANSIT') =>
  params.find(param => param.type === type)?.linkedProductId;

const fetchCredits = async (request: GridRequest & CreditFilterFields) => {
  const { subType, ...otherRequest } = request;
  const status = request?.status !== 'all' ? request.status.toUpperCase() : undefined;
  const { rows, total } = await api.products.getCredits({
    ...otherRequest,
    status,
    subTypes: [subType],
    sort: 'displayOrder',
    size: 0,
  });
  return {
    rows,
    total,
    tabOptions: getStatusesOptions(total),
  };
};

export const CreditsPage = () => (
  <div className="credits-page">
    <Page title={translate('front.credits-page.title.label')}>
      <DataTable
        name={TableNamesEnum.CreditsTable}
        columns={columns}
        fetchRows={fetchCredits}
        defaultSort="subType"
        defaultOrder={SortOrder.Desc}
        filterComponent={CreditsFilter}
        expandRowComponent={CreditDetails}
        hideHeader
        hidePagination
      />
    </Page>
  </div>
);
