import { DemandType, OrderType } from 'api/DemandsService/enums';
import { DocumentManagementStateEnum } from 'api/DocumentManagementService/enum';
import { OrderActionType, OrderState } from 'api/enums';
import { OrderTemplate } from 'api/HandbooksService';
import { translate } from 'i18n/translator';
import { Privileges } from 'navigations/privileges';
import { addEscrouTypeToOrderType } from 'pages/Payments/utils';

import { get } from './backend';
import { checkMultiCustomer } from './ConstantService';
import { GridRequest, GridResponse, HasId, Option } from './Service';

export interface OrderAction<T = any> {
  label: string;
  name: OrderActionType;
  type: 'button' | 'signButton' | 'dropdown';
  closable?: boolean;
  confirmMessage?: string;
  onClick?: (order: Order<T>) => void | Promise<void>;
  payload?: { [key: string]: string[] };
  privileges?: Privileges[];
  reloadable?: boolean;
}

export interface OrderHistory {
  actionTime: string;
  actionType: string;
  id: number;
  orderId: number;
  orderNumber: string;
  orderState: string;
  orderStateCode: string;
  userName: string;
  description?: string;
}

// TODO refactoring the interface (fields relate DemandBase)
export interface Order<T = any> {
  actions: OrderAction<T>[];
  date: Date;
  id: number;
  label: string;
  // orderType in DemandBase
  number: string;
  state: OrderState | DocumentManagementStateEnum;
  stateTranslate: string;
  type: OrderType | DemandType;
  checkIsAvailableSign?: boolean;
  customerId?: number;
  detailFields?: T;
  executionTime?: string | Date;
  linkedOrderId?: number;
  linkedOrderType?: string;
  orderKind?: string;
  templateName?: string;
  valueDate?: string;
}

export class OrderService {
  async getNewOrderModel<T = HasId>(id: number | string): Promise<T> {
    return await get(`/v3/orders/${id}`);
  }

  async getOrderHistory(id: number, req: GridRequest): Promise<GridResponse<OrderHistory>> {
    return await get(`/v1/orders/history/${id}`, checkMultiCustomer(req));
  }

  async getOrderTemplates(orderType: string, customerIds: number[]): Promise<Option[]> {
    const newOrderType = addEscrouTypeToOrderType(orderType);

    const orders = await get(
      '/v1/orders',
      checkMultiCustomer({
        customerIds,
        types: newOrderType,
        isTemplate: true,
      }),
    );
    return this.formatTemplates(orders.rows, newOrderType);
  }

  private formatTemplates(rows: OrderTemplate[], orderType: string[]): Option[] {
    const templates = rows
      .filter(
        item =>
          item.base.orderKind === 'TEMPLATE' &&
          orderType.some(type => type === item.base.orderType),
      )
      .map(row => ({
        value: `${row.id}`,
        label: row.base.orderAlias,
        content: {
          ...row.base,
          updated: new Date(row.base.updated),
        },
      }));

    return [
      {
        value: '',
        label: translate('front.form.select-without-template.label'),
        content: {
          orderAlias: translate('front.form.select-without-template.label'),
        },
      },
      ...templates,
    ];
  }
}
