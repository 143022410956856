import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import store from 'store';
import { shouldClearLocalStorage } from 'utils/shouldClearLocalStorage';

import { App } from './App';

const preloader = document.querySelector('.preloader');

function init() {
  shouldClearLocalStorage();

  const root = createRoot(document.getElementById('app'));
  root.render(
    <Provider store={store}>
      <App />
    </Provider>,
  );

  preloader.parentNode.removeChild(preloader);
}

init();
