import * as React from 'react';
import { Action, GridResponse, Menu, TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';
import moment from 'moment/moment';

import { api } from 'api';
import { OrderType } from 'api/DemandsService/enums';
import {
  DemandBase,
  DynamicFieldBase,
  DynamicFieldData,
  Financial,
  PartialDynamicField,
} from 'api/DemandsService/interfaces';
import { CheckSmsActionEnum } from 'api/enums';
import {
  OrderTemplate,
  OrderTemplateBase,
  OrderTemplatesFilterProps,
  OrderTypes,
} from 'api/HandbooksService';
import { SortOrder } from 'api/Service';
import { confirmationModal } from 'components/modals/ConfirmModal';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { SmsModal } from 'components/modals/SmsModal';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { translate } from 'i18n/translator';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { addEscrouTypeToOrderType } from 'pages/Payments/utils';

import { OrderTemplatesFilter } from './OrderTemplatesFilter';

const filterDataFields = (
  data: DynamicFieldData = [] as unknown as DynamicFieldData,
  field: DynamicFieldBase,
): Partial<DynamicFieldData> => {
  const convertors: Record<
    string,
    (data: DynamicFieldData, field: DynamicFieldBase) => Partial<DynamicFieldData>
  > = {
    BOOLEAN: ({ booleanValue = false, isFieldApproved = false, orderFieldId }, { id }) => ({
      booleanValue,
      orderFieldId: orderFieldId ?? id,
      isFieldApproved,
    }),

    default: ({ fieldValue, textValue, numericValue, orderFieldId, isFieldApproved }, { id }) => ({
      fieldValue,
      textValue,
      orderFieldId: orderFieldId ?? id,
      numericValue,
      isFieldApproved,
    }),
  };

  return convertors[field.fieldType]?.(data, field) ?? convertors.default(data, field);
};

const correctTranslate = (orderBase: OrderTemplateBase): string => {
  const translates: Obj<string> = {
    [OrderTypes.DomesticTransfer]: translate(
      'front.internal-payment-page.order-external-type.title.label',
    ),
    default: orderBase.translatedOrderType,
  };

  return translates[orderBase.orderType] ?? translates.default;
};

const createOrderByTemplate = async ({ base, dynamicFields, financial, id }: OrderTemplate) => {
  const documentNumber = await api.payments.getDocumentNumber({
    customerId: base.customerId,
    orderType: base.orderType,
    orderDate: moment().format('YYYY-MM-DD'),
  });

  switch (base.orderType) {
    case OrderType.DomesticTransfer:
    case OrderType.DomesticTransferESCROU:
    case OrderType.InternalTransfer:
    case OrderType.InternalTransferESCROU:
    case OrderType.ForeignTransfer:
    case OrderType.ForeignTransferESCROU: {
      const newDynamicFields = dynamicFields.map(({ data, field }) => {
        return { data: [filterDataFields(data[0], field)] };
      });

      const newBase: Partial<DemandBase> = {
        orderNumber: documentNumber,
        customerId: base.customerId,
        orderDate: moment().format('YYYY-MM-DD'),
        orderType: base.orderType,
        linkedOrderType: base.linkedOrderType,
        extraData: base.extraData,
        commission: `${base.commission}`,
        orderKind: null,
        priority: 0,
      };

      return await api.payments.createPayment({
        base: newBase,
        dynamicFields: newDynamicFields as PartialDynamicField[],
        financial: financial as Partial<Financial>,
      });
    }

    case OrderType.SalarySheet: {
      const { rows } = await api.salary.getEmployeesInDemand(id);
      const newDynamicFields = {} as Record<string, Pick<PartialDynamicField, 'data'>>;

      dynamicFields.forEach(({ data, field }) => {
        return (newDynamicFields[field.code] = {
          data: [filterDataFields(data[0], field)],
        });
      });

      return await api.salary.createSalary({
        checkAllConstraints: false,
        generateOrders: newDynamicFields.makePayments.data[0].booleanValue,
        orderType: OrderType.SalarySheet,
        template: false,
        dynamicFields: newDynamicFields,
        employees: rows.map(({ amount, employeeId }) => ({ amount, employeeId })),
        templateName: null,

        fields: {
          orderNumber: documentNumber,
          customerId: base.customerId,
          orderDate: moment().format('YYYY-MM-DD'),
          currency: financial.isoCode,
          amount: financial.amount,
        },
      });
    }
  }
};

const renderMenu = (row: OrderTemplate): Menu<OrderTemplate>[] => [
  {
    label: 'front.working-documents-table.actions-edit.label',
    onClick: () => goto(pages.handbooks.orderTemplate(row.id)),
    isReloadable: true,
    isVisible: row.actions.some(act => act.actionName === 'EDIT'),
  },
  {
    label: 'front.working-documents.create-document.label',
    onClick: async () => {
      try {
        await createOrderByTemplate(row);
      } catch (error: any) {
        await confirmationModal(JSON.parse(error.message).message, false, false);
      }
    },
    isLoading: true,
  },
  {
    label: 'front.working-documents-table.actions-delete.label',
    onClick: async () => {
      const otpCode = await confirmModal<string>(SmsModal, {
        action: CheckSmsActionEnum.CONFIRM_TEMPLATE_DELETE,
      });

      if (!otpCode) return;

      await api.handbooks.deleteOrderTemplate(row.id);
    },
    isReloadable: true,
    confirmMessage: 'front.document-templates-table.confirm-delete.label',
    isVisible: row.actions.some(act => act.actionName === 'DELETE'),
  },
];

const columns: TableColumnProps<OrderTemplate>[] = [
  {
    label: 'front.document-templates.templ-name.label',
    render: row => <DataColumn title={row.base.orderAlias} subTitle={correctTranslate(row.base)} />,
    sort: 'orderAlias',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.working-documents-table.sender.label',
    render: row => (
      <DataColumn title={row?.financial?.payerName ?? '-'} subTitle={row?.financial?.payerIban} />
    ),
    sort: 'payerName',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.working-documents-table.receiver.label',
    render: row => (
      <DataColumn
        title={row?.financial?.receiverName ?? '-'}
        subTitle={row?.financial?.receiverIban}
      />
    ),
    sort: 'receiverName',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.working-documents-table.purpose.label',
    render: row => <DataColumn title={row?.financial?.purpose ?? '-'} />,
    sort: 'purpose',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    renderMenu,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

export const actions: Action[] = [
  {
    label: 'front.mails-table-actions.delete.label',
    action: async selected => {
      const otpCode = await confirmModal<string>(SmsModal, {
        action: CheckSmsActionEnum.CONFIRM_TEMPLATE_DELETE,
      });

      if (!otpCode) return;

      for (let i = 0; selected.length > i; i += 1) {
        await api.handbooks.deleteOrderTemplate(selected[i].id);
      }
    },
    isReloadable: true,
    confirmMessage: 'front.document-templates-table.confirm-delete.label',
  },
];

const fetchDocs = async (
  request: OrderTemplatesFilterProps,
): Promise<GridResponse<OrderTemplate>> => {
  const types = addEscrouTypeToOrderType(request.types) as OrderType[];

  const { rows, total } = await api.handbooks.getOrderTemplates({ ...request, types });

  return { rows, total };
};

export const OrderTemplatesPage = () => (
  <DataTable
    name={TableNamesEnum.OrderTemplatesTable}
    columns={columns}
    fetchRows={fetchDocs}
    onRowClick={row => {
      if (row.base.orderType === OrderTypes.ExposedOrder) return;
      goto(pages.handbooks.orderTemplate(row.id));
    }}
    actions={actions}
    defaultOrder={SortOrder.Desc}
    filterComponent={OrderTemplatesFilter}
  />
);
