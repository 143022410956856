import * as React from 'react';

import classNames from 'classnames';

import * as Icon from 'components/icons';

import './forms.scss';

export const FormError = ({ children, className = '' }: React.PropsWithChildren<any>) => {
  if (!React.Children.count(children)) {
    return null;
  }

  return (
    <div className={classNames('server-error', className)}>
      <div className="server-error__icon">
        <Icon.FormErrorIcon />
      </div>
      <div className="server-error__text">{children}</div>
    </div>
  );
};
