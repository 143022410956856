import * as React from 'react';

import classNames from 'classnames';
// tslint:disable-next-line: import-name

import { Question } from 'components/icons';
import { Popover, TPlacement } from 'components/popover/Popover';

import './styles.scss';

export type TTooltip = string | React.ReactNode;

export type Trigger = 'focus' | 'hover' | 'click' | 'legacy';

interface Props {
  tooltip: TTooltip;
  className?: string;
  fade?: boolean;
  id?: string;
  label?: any;
  placement?: TPlacement;
  trigger?: Trigger;
}

export const Tooltip: React.FC<Props> = props => {
  const {
    tooltip,
    placement,
    className,
    id,
    fade = false,
    trigger = 'hover',
    label = Question(),
  } = props;
  const cx = classNames({ [className]: className });
  return (
    <div className={cx}>
      <Popover
        trigger={trigger}
        fade={fade}
        placement={placement || 'top'}
        label={<span className="tooltip-wrap">{label}</span>}
        id={id || 'tooltip'}
      >
        <div className="tooltip-message">{tooltip}</div>
      </Popover>
    </div>
  );
};
