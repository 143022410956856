import React from 'react';
import { useLocation } from 'react-router';

import moment from 'moment';

import { parseUrlParams } from 'api/backend';
import { DemandType } from 'api/DemandsService/enums';
import { OrderState } from 'api/enums';
import { Option } from 'api/Service';
import { CustomerTypeEnum } from 'api/UserService';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { DateRangeField } from 'components/forms/inputs/DateRangeField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextField } from 'components/forms/inputs/TextField';
import { translate } from 'i18n/translator';
import { DepositDetailNames } from 'pages/Demands/DepositDemand/enums';
import {
  labelProlongationAllowed,
  labelProlongationNotAllowed,
} from 'pages/Demands/DepositDemand/NewContractTermDepositDetail/NewContractTermDepositDetail';
import { useCheckRelevanceDeposit } from 'pages/Demands/hooks/useCheckRelevanceDeposit';
import { disabledDaysBeforeToday, getOption, today } from 'pages/Demands/utils';
import { usePrevious } from 'utils/hooks';
import { isExist } from 'utils/isData';

import 'pages/Demands/DepositDemand/styles.scss';

interface NewDepositGeneralOrderFields {
  channelTypeSend?: string;
  depositCurrency?: string;
  depositType?: string;
  doSend?: boolean;
  retentionPeriodFrom?: Date;
}

export const NewDepositGeneralOrder = ({ type }: { type: DemandType | CustomerTypeEnum }) => {
  const {
    order,
    onChangeCustomer,
    payload: { depositType: depositTypes, depositCurrency, channelTypeSend, dynamicFields },
    form: { updateData, getFormData },
  } = useOrderDetails();

  const {
    depositType,
    retentionPeriodFrom,
    doSend,
    channelTypeSend: channelTypeSendValue,
    depositCurrency: depositCurrencyValue,
  }: NewDepositGeneralOrderFields = getFormData();

  const { copyFrom } = parseUrlParams(useLocation().search);

  const isDisabledCustomer = !!order?.state || !!copyFrom;

  const hasDepositType = isExist(depositType);
  const currentDepositType = getOption(depositType, depositTypes)?.content;
  const prevDepositTypeFieldId = usePrevious(depositType);
  const hasPrevDepositType = isExist(prevDepositTypeFieldId);
  const isProlongationAllowed = hasDepositType && currentDepositType?.params.isExtensionAllowed;
  const isFop = type === CustomerTypeEnum.IndividualEmployer;

  const filteredDepositCurrencies = React.useMemo(
    () =>
      hasDepositType
        ? depositCurrency.filter(
            (deposit: Option) => deposit.content.info === currentDepositType.info,
          )
        : [],
    [currentDepositType, depositCurrency],
  );

  const currentCurrency = React.useMemo(
    () =>
      hasDepositType
        ? depositCurrency.find(
            (currency: Option) => currency.content.value === depositCurrencyValue,
          )?.content.params
        : [],
    [depositCurrency, depositCurrencyValue],
  );

  const isSendEmail =
    getOption(channelTypeSendValue, channelTypeSend)?.content.value ===
    DepositDetailNames.ChannelEmail;

  const disabledDaysTo = React.useMemo(
    () =>
      isExist(retentionPeriodFrom)
        ? {
            before: retentionPeriodFrom,
            after: moment(retentionPeriodFrom)
              .add('days', +currentDepositType?.params.dateMax)
              .startOf('date')
              .toDate(),
          }
        : null,
    [retentionPeriodFrom],
  );

  React.useEffect(() => {
    if (!order?.state && hasPrevDepositType) {
      return updateData({
        retentionPeriodFrom: today,
        retentionPeriodTo: moment()
          .add('days', +currentDepositType.params.dateMax)
          .startOf('date')
          .toDate(),
      });
    }

    if (order?.state === OrderState.Draft && hasPrevDepositType) {
      updateData({
        retentionPeriodFrom: null,
        retentionPeriodTo: null,
      });
    }
  }, [depositType]);

  React.useEffect(() => {
    if (prevDepositTypeFieldId) {
      updateData({
        depositCurrency: null,
        periodInterestPayment: null,
      });
    }
  }, [depositType]);

  useCheckRelevanceDeposit(DepositDetailNames.DepositType, depositTypes, dynamicFields);

  return (
    <>
      <DefaultForm>
        <CustomerSelect
          name={DepositDetailNames.Customer}
          label="front.deposit-demand.organization.label"
          onSelectOption={onChangeCustomer}
          selectDefaultCustomer
          disabled={isDisabledCustomer}
          required
        />
        <SelectField
          name={DepositDetailNames.DepositType}
          label="front.new-contract-term-deposit-demand.depositType.label"
          options={depositTypes}
          required
        />
        <SelectField
          name={DepositDetailNames.DepositCurrency}
          label="front.new-contract-term-deposit-demand.depositCurrency.label"
          options={filteredDepositCurrencies}
          disabled={!hasDepositType}
          required
        />
        <DateRangeField
          nameFrom={DepositDetailNames.RetentionPeriodFrom}
          nameTo={DepositDetailNames.RetentionPeriodTo}
          label="front.new-placement-tranche-deposit-demand.retention-period.label"
          disabledDaysFrom={disabledDaysBeforeToday}
          disabledDaysTo={disabledDaysTo}
          isOnlyRangePicker
          disabled={!hasDepositType}
          required={hasDepositType}
          clearable
        />
        <AmountField
          name={DepositDetailNames.Amount}
          label="front.new-contract-term-deposit-demand.amount.label"
          maxLengthWithoutZero={12}
          minAmount={currentCurrency?.min}
          maxAmount={currentCurrency?.max}
          disabled={!hasDepositType}
          required={hasDepositType}
        />
        <h6 className="dynamic-input__title">
          {translate(
            'front.new-contract-term-deposit-demand.label-possibility-replenishment.label',
          )}
        </h6>
        {hasDepositType && (
          <h6 className="dynamic-input__title">
            {isProlongationAllowed ? labelProlongationAllowed : labelProlongationNotAllowed}
          </h6>
        )}
        <CheckboxField
          name={DepositDetailNames.DoSelectedContribution}
          label="front.new-contract-term-deposit-demand.label-placement-selected-deposit.label"
          required
        />
        <CheckboxField
          name={DepositDetailNames.DoAcceptance}
          label="front.new-contract-term-deposit-demand.label-public-proposal-deposit.label"
          required
        />
        <CheckboxField
          name={DepositDetailNames.DoUnderstanding}
          label="front.new-contract-term-deposit-demand.label-deposit-agreement-deposit.label"
          required
        />
        <CheckboxField
          name={DepositDetailNames.DoInformation}
          label="front.new-contract-term-deposit-demand.label-information-provided-deposit.label"
          required
        />
        <CheckboxField
          name={DepositDetailNames.DoInformationLaw}
          label="front.new-contract-term-deposit-demand.label-bank-information-deposit.label"
          required
        />
        {isFop && (
          <CheckboxField
            name={DepositDetailNames.DoConditions}
            label="front.new-contract-term-deposit-demand.label-confirm-acquaintance-deposit.label"
            required
          />
        )}
        <CheckboxField
          name={DepositDetailNames.DoAccommodationContribution}
          label="front.new-contract-term-deposit-demand.label-public-offer.label"
          required
        />
        <CheckboxField
          name={DepositDetailNames.DoSend}
          label="front.new-contract-term-deposit-demand.label-do-send.label"
          required
        />
        {doSend && (
          <SelectField
            name={DepositDetailNames.ChannelTypeSend}
            label="front.new-contract-term-deposit-demand.label-channel-typeSend.label"
            options={channelTypeSend}
            required
          />
        )}
        {isSendEmail && (
          <TextField
            name={DepositDetailNames.ChannelEmail}
            label="front.new-contract-term-deposit-demand.label-channel-email.label"
            email
            required
          />
        )}
      </DefaultForm>
    </>
  );
};
