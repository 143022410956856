import React from 'react';

import { GreenArrowDown, GreenArrowUp } from 'components/icons';
import { translate } from 'i18n/translator';

interface DetailsButtonProps {
  count: number;
  isOpen: boolean;
  onClick: () => void;
}

export const DetailButton = ({ isOpen, onClick, count }: DetailsButtonProps) => (
  <div className="detail-button" onClick={onClick}>
    <div className="detail-button__description">
      {`${translate('front.foreign-payments-details.label')} (${count})`}
    </div>
    <div className="detail-button__icon">{isOpen ? <GreenArrowUp /> : <GreenArrowDown />}</div>
  </div>
);
