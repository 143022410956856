import * as React from 'react';
import { Col, Container, Row } from 'react-grid';
import reactHtmlParser from 'react-html-parser';

import { api } from 'api';
import { OrderSignSmsRows, SignOrder } from 'api/interfaces';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { SmsField } from 'components/forms/SmsField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { confirmModal, PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import { translate } from 'i18n/translator';
import { SignInformation } from 'pages/utils/SignOrders/SignInformation/SignInformation';
import { SmsModalCounter } from 'pages/utils/SignOrders/SmsSign/SmsModalCounter';
import { SmsModalTable } from 'pages/utils/SignOrders/SmsSign/SmsModalTable';
import { isExist } from 'utils/isData';

import './styles.scss';

interface Fields {
  code: string;
}

export interface PayloadSmsSign {
  smsLength: number;
  tableRows: OrderSignSmsRows;
}

interface Props extends PropsWithConfirmModal {
  ids: number[];
  ordersCount: number;
}

export const SmsModalForm = ({ ids, ordersCount, resolve }: Props) => {
  const sendSms = async () => {
    const response = await api.sms.ordersSignSmsRequest({ orderIdList: ids });

    setPayload({
      smsLength: response.smsLength,
      tableRows: response.params,
    });
  };

  const { progress, error, handleSubmit, setPayload, payload, initializing } = useForm<
    Fields,
    PayloadSmsSign
  >(sendSms);

  const onSign = async (data: Fields) => {
    const signData: SignOrder[] = ids.map(id => ({
      signFactor: 'SMS',
      certificateSn: null,
      certificateCaSn: null,
      signatures: { [id]: data.code },
    }));
    const { data: signResult } = await api.payments.signOrder(signData);
    const hasError = isExist(signResult.find(item => item.hasError));

    if (ordersCount > 1 || hasError) {
      const errors = signResult.filter(item => item.hasError);
      const successCount = signResult.length - errors.length;

      await confirmModal<string>(SignInformation, { errors, successCount, ordersCount }, 650);
    }

    await resolve(true);
  };

  const { smsLength } = payload;

  return (
    <div className="sign-sms-modal">
      <div className="sign-sms-modal__title">
        {translate('front.sms-modal.sms-sign-password.label')}
      </div>
      {payload.tableRows && SmsModalTable(payload.tableRows)}
      <div className="sign-sms-modal__desc">
        {translate('front.sms-modal.sms-sent-to-phone.label')}
      </div>
      <DefaultForm disabled={progress} className="sign-sms-modal__form">
        <Container>
          <Row>
            <Col>{error && <FormError>{reactHtmlParser(error)}</FormError>}</Col>
            <Col>
              <SmsField
                label="front.login-form.sms.label"
                name="code"
                length={smsLength}
                autoFocus
              />
            </Col>
            <SmsModalCounter initializing={initializing} sendSms={sendSms} payload={payload} />
          </Row>
          <Row className="sign-sms-modal__footer">
            <Col sm={6}>
              <Button
                onClick={e => handleSubmit(onSign, e)}
                size="md"
                progress={progress}
                type="submit"
                className="sign-sms-modal__footer__btn"
              >
                {translate('front.sms-modal.sms-sign-button.label')}
              </Button>
            </Col>
            <Col sm={6}>
              <Button
                onClick={() => resolve()}
                color="secondary"
                size="md"
                className="sign-sms-modal__footer__btn"
              >
                {translate('front.sms-modal.sms-cancel-button.label')}
              </Button>
            </Col>
          </Row>
        </Container>
      </DefaultForm>
    </div>
  );
};

export const SmsModal = withForm(SmsModalForm);
