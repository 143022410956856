import * as React from 'react';
import { TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { Department } from 'api/DepartmentsService';
import { SortOrder } from 'api/Service';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';

import { DepartmentsGridFilter } from './DepartmentsGridFilter';

const columns: TableColumnProps<Department>[] = [
  {
    label: 'front.branches.list-department.label',
    render: row => <DataColumn title={row.name} />,
    sort: 'name_ml',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.branches.list-address.label',
    render: row => <DataColumn title={row.address} />,
    sort: 'address_ml',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.branches.list-phone.label',
    render: row => <DataColumn title={row.phoneNumbers[0]} />,
    sort: 'phones',
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.branches.list-information.label',
    render: row => <DataColumn title={row.addInfo} />,
    sort: 'add_info_ml',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
];

export const DepartmentsListPage = () => (
  <DataTable
    name={TableNamesEnum.DepartmentsList}
    columns={columns}
    fetchRows={api.departments.getDepartments}
    filterComponent={DepartmentsGridFilter}
    defaultSort="id"
    defaultOrder={SortOrder.Asc}
    onRowClick={row => goto(pages.departments.tabs.map({ id: row.id }))}
  />
);
