import * as React from 'react';
import { SortOrder, TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { Counterparties, Counterparty, CounterpartyAccount } from 'api/HandbooksService';
import { getPage } from 'api/Service';
import { Button } from 'components/buttons/Button';
import { CounterDescription } from 'components/CounterDescription/CounterDescription';
import { FormError } from 'components/forms/FormError';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { translate } from 'i18n/translator';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { useError } from 'pages/Import/SuccessImportList/useError';
import { useLoading } from 'pages/Import/SuccessImportList/useLoading';

export type SuccessCounterparties = {
  accounts: CounterpartyAccount[];
  counterparty: Counterparty;
  contracts?: null;
};

interface Props {
  counterparties: Counterparties[];
}

const columns: TableColumnProps<Counterparties>[] = [
  {
    label: 'front.import-modal.counterparties.counterparty.label',
    render: row => <DataColumn title={row.counterparty.name} />,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.import-modal.counterparties.bin.label',
    render: row => <DataColumn title={row.counterparty.bin} />,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.import-modal.counterparties.accounts.label',
    render: row => (
      <DataColumn>
        <ul className="accounts-container">
          {row.accounts.map(account => (
            <li key={account.id}>{account.iban}</li>
          ))}
        </ul>
      </DataColumn>
    ),
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

export const SuccessCounterpartyList = ({ counterparties }: Props) => {
  const [error, setError] = useError();
  const [isLoading, setLoading] = useLoading();

  const isSaveDisabled = !counterparties?.length || isLoading;

  const onClickAccept = async () => {
    setLoading(true);
    try {
      await api.handbooks.acceptCounterparties(
        counterparties.map(({ counterparty }) => counterparty.id),
      );
      goto(pages.handbooks.tabs.counterparties);
    } catch (error: any) {
      setError(JSON.parse(error.message)?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <FormError>{error}</FormError>
      <Button color="primary" onClick={onClickAccept} disabled={isSaveDisabled} size="md">
        {translate('front.import-page.save.label')}
      </Button>
      <Button color="secondary" onClick={() => goto(pages.handbooks.tabs.counterparties)} size="md">
        {translate('front.import-page.cancel.label')}
      </Button>
      <CounterDescription
        count={counterparties?.length}
        description={translate('front.import-page.tab-success.label')}
      />
      <DataTable
        name={TableNamesEnum.SuccessCounterpartiesImport}
        columns={columns}
        defaultOrder={SortOrder.Asc}
        fetchRows={async request => getPage(counterparties, request)}
      />
    </>
  );
};
