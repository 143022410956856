export enum TableNamesEnum {
  WorkingDocs = 'working-docs',
  AccountsTable = 'accounts-table',
  DepositsTable = 'deposits-table',
  CreditsTable = 'credits-table',
  CardsTable = 'cards-table',
  CardsLimit = 'card-limits',
  AccountStatements = 'account-statements',
  TransactionsHistoryTable = 'transactions-history-table',
  Analytics = 'analytics',
  ExposedPayments = 'exposed-payments',
  InvoicePayments = 'invoice-payments',
  RegularPaymentsTable = 'regularPayments-table',
  InternationalPayments = 'international-payments',
  ForeignDemandsTable = 'foreign-demands-table',
  AuthLetterPage = 'auth-letter-demands-table',
  AcquiringNewTerminalPage = 'acquiring-new-terminal-demands-table',
  FEAMessagePage = 'fea-message-demands-table',
  SalaryPage = 'salary-page',
  Employees = 'employees',
  EmployeesManagement = 'employees-management',
  AcquiringStatements = 'acquiring-statements',
  AuthorizedPersonsPage = 'authorized-persons-page',
  CertificatesTable = 'certificates-table',
  CounterpartiesTable = 'counterparties-table',
  DepartmentsList = 'departments-list',
  ExternalApiList = 'external-api-list',
  InformationList = 'information-list',
  InternalDemandsTable = 'internal-demands-table',
  LettersTable = 'letters-table',
  NotificationsPage = 'notifications-page',
  PersonalOrganizationsList = 'personal-organizations-list',
  ProtocolsPage = 'protocols-page',
  RolesPage = 'roles-page',
  DebtUsersPage = 'debt-users-page',
  DocumentManagement = 'document-management-page',
  DemandHistory = 'demand-history',
  Sms = 'sms',
  TerminalList = 'terminalList',
  CurrencyHistoryTable = 'currency-history-table',
  OrderTemplatesTable = 'order-templates-table',
  SignErrors = 'sign-errors',
  SalaryRelated = 'salary-related',
  Registry = 'registry',
  RegistryModal = 'registry-modal',
  DepositOrder = 'depositOrder',
  SuccessPaymentsImport = 'success-payments-import',
  SuccessEmployeesImport = 'success-employees-import',
  SuccessCounterpartiesImport = 'success-counterparties-import',
  ErrorPaymentsImport = 'error-payments-import',
  ErrorEmployeesImport = 'error-employees-import',
  ErrorCounterpartiesImport = 'error-counterparties-import',
  InternalPaymentHistory = 'internal-payment-history',
  UkraineBanksTable = 'ukraine-banks-table',
  PaymentPurposeTable = 'payment-purpose-table',
}
