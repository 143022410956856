import * as React from 'react';
import { Col, Row, Container } from 'react-grid';

import { PaymentTypes } from 'api/enums';
import { Option } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { SelectField } from 'components/forms/inputs/SelectField';
import { withForm } from 'components/forms/withForm';
import { SearchInput } from 'components/searchInput/SearchInput';
import { useFilter } from 'components/Table/DataTable';
import { ToggleFilterButton } from 'components/Table/ToggleFilterButton/ToggleFilterButton';
import { translate } from 'i18n/translator';

const OrderTemplatesFilterForm: React.FC = () => {
  const {
    updateFilter,
    extraFields: { searchText },
  } = useFilter({ extraFields: { searchText: '' } });

  const templateOptions: Option[] = [
    {
      value: PaymentTypes.INTERNAL_TRANSFER,
      label: 'front.internal-payment-page.order-internal-type.title.label',
    },
    {
      value: PaymentTypes.DOMESTIC_TRANSFER,
      label: 'front.internal-payment-page.order-external-type.title.label',
    },
    {
      value: PaymentTypes.FOREIGN_TRANSFER,
      label: 'front.internal-payment-page.options-types.foreign-transfer.label',
    },
    {
      value: PaymentTypes.SALARY_SHEET,
      label: 'front.menu.salary-sheet.label',
    },
  ];

  return (
    <Container>
      <Row justifyContent="flex-end">
        <Col lg={5} md={6}>
          <SearchInput
            placeholder={translate('front.working-documents-table.filter-search.label')}
            value={searchText}
            onChange={searchText => updateFilter({ searchText })}
          />
        </Col>
        <Col lg={2} md={6}>
          <ToggleFilterButton>
            <Row>
              <Col sm={6}>
                <CustomerSelect
                  label="front.internal-payment-page.order-payer-name.label"
                  name="customerIds"
                  multi
                />
              </Col>
              <Col sm={6}>
                <SelectField
                  label="front.document-templates.template-type.label"
                  name="types"
                  options={templateOptions}
                />
              </Col>
            </Row>
          </ToggleFilterButton>
        </Col>
      </Row>
    </Container>
  );
};

export const OrderTemplatesFilter = withForm(OrderTemplatesFilterForm);
