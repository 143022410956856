import * as React from 'react';
import { useDataTable } from 'react-data-table';

import { LinearProgress } from 'components/LinearProgress/LinearProgress';
import { TableActions } from 'components/Table/TableActions/TableActions';

import { DataTableError } from './DataTableError/DataTableError';
import { TableFilter } from './TableFilter/TableFilter';

import './data-table-header.scss';

export const DataTableHeader: React.FC = () => {
  const { isLoading } = useDataTable();

  return (
    <div className="data-table-header">
      <DataTableError />
      <LinearProgress isLoading={isLoading} />
      <TableFilter />
      <TableActions />
    </div>
  );
};
