import * as React from 'react';

import { Placeholder, PlaceholderProps } from 'components/placeholder/Placeholder';

interface Props extends PlaceholderProps {
  isLoading: boolean;
  display?: string;
}

export const PreloadField: React.FC<React.PropsWithChildren<Props>> = props => {
  const { isLoading, children, ...placeholderProps } = props;

  if (isLoading) {
    return <Placeholder height={30} marginTop={12} marginBottom={9} {...placeholderProps} />;
  }

  return <>{children}</>;
};
