import * as React from 'react';
import { Action, SortOrder, TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { Employee } from 'api/SalaryService';
import { getPage, GridRequest } from 'api/Service';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { Indexes } from 'components/react-data-table/types';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { capitalize } from 'components/utils/utils';
import { isExist } from 'utils/isData';

import { SalaryAmountField } from './SalaryAmountField';
import { AmountModal, AmountModalProps } from './SalaryAmountModal';
import { SalaryRegistryFilter } from './SalaryRegistryFilter';

interface Props {
  disabled: boolean;
  productIds: number[];
  selectedEmployees: Employee[];
  setSelectedEmployees: (data: Employee[], merge?: boolean) => void;
}

export const SalaryRegistry: React.FC<Props> = ({
  disabled,
  selectedEmployees,
  setSelectedEmployees,
  productIds,
}) => {
  const columns: TableColumnProps<Employee>[] = React.useMemo(
    () => [
      {
        label: ['front.menu.employees.label', 'front.menu.iban.account.label'],
        render: row => (
          <DataColumn
            title={[row.lastName, row.firstName, row.middleName].map(capitalize).join(' ')}
            subTitle={row.iban}
          />
        ),
        sort: ['lastName', 'iban'],
        showDesktop: true,
        showTablet: true,
        showMobile: true,
      },
      {
        label: 'front.сounterparties-table.bin.label',
        render: row => <DataColumn title={row.taxCode} />,
        sort: 'taxCode',
        showDesktop: true,
        showTablet: true,
        showMobile: true,
      },
      {
        label: 'front.internal-payment-page.order-amount.label',
        render: (row, { tableIndex }) => (
          <SalaryAmountField
            key={`amount-${row.id}-${tableIndex}`}
            rowAmount={row.amount as number}
            setSalary={setSalary}
            tableIndex={tableIndex}
            disabled={disabled}
          />
        ),
        sort: 'amount',
        showDesktop: true,
        showTablet: true,
        showMobile: true,
      },
      {
        renderMenu: (row, { tableIndex }) => [
          {
            label: 'front.employees-actions.delete.label',
            onClick: () => deleteEmployee(row, { tableIndex }),
            isVisible: !disabled,
          },
        ],
      },
    ],
    [selectedEmployees, disabled],
  );

  const actions: Action[] = React.useMemo(
    () => [
      {
        label: 'front.regular-payments-page-table.actions-delete.label',
        action: (rows: Employee[], { selectedByTable }) => {
          const newEmpl = selectedEmployees.filter((e, i) => !selectedByTable.includes(i));
          setSelectedEmployees(newEmpl);
        },
      },
      {
        label: 'front.employees-actions.set-salary.label',
        action: async (rows: Employee[], { selectedByTable }) => {
          const amount = await confirmModal<string | boolean, AmountModalProps>(AmountModal);
          if (amount === false) return;

          const newEmpl = selectedEmployees.map((e, i) => {
            if (selectedByTable.includes(i)) {
              return { ...e, amount: +amount };
            }
            return e;
          });
          setSelectedEmployees(newEmpl);
        },
      },
    ],
    [selectedEmployees],
  );

  const setSalary = async ({ tableIndex }: Partial<Indexes>, amount: string | number) => {
    const newEmployees = selectedEmployees.map((employee, i) => {
      if (i === tableIndex) {
        return isExist(amount) ? { ...employee, amount: +amount } : { ...employee, amount: amount };
      }
      return employee;
    });

    setSelectedEmployees([...newEmployees]);
  };

  const deleteEmployee = async (row: Employee, { tableIndex }: Partial<Indexes>) => {
    setSelectedEmployees(selectedEmployees.filter((e, i) => tableIndex !== i));
  };

  const fetchRows = React.useCallback(
    async (request: GridRequest) => {
      return getPage(selectedEmployees, request);
    },
    [selectedEmployees],
  );

  const Filter = React.useCallback(
    () => (
      <SalaryRegistryFilter
        disabled={disabled}
        setSelectedEmployees={setSelectedEmployees}
        productIds={productIds}
        selectedEmployees={selectedEmployees}
      />
    ),
    [disabled, productIds, selectedEmployees, setSelectedEmployees],
  );

  return (
    <DataTable
      name={TableNamesEnum.Registry}
      fetchRows={fetchRows}
      columns={columns}
      defaultSort="lastName"
      defaultOrder={SortOrder.Asc}
      actions={disabled ? undefined : actions}
      filterComponent={Filter}
    />
  );
};
