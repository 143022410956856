import React from 'react';
import { PatternFormatProps, PatternFormat } from 'react-number-format';

import classNames from 'classnames';
import './styles.scss';

export interface Props {
  onBlur(): void;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  placeholder: string;
  type: string;
  autoComplete?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  hasGrayBackground?: boolean;
  id?: string;
  isLargeInput?: boolean;
  maskConfig?: PatternFormatProps;
  onFocus?(): void;
  onPaste?(event: React.ClipboardEvent<HTMLInputElement>): void;
  value?: string;
}

export const PatternFormatField: React.FC<Props> = ({
  id,
  value,
  disabled,
  onChange,
  onBlur,
  autoFocus,
  onPaste,
  placeholder,
  onFocus,
  autoComplete,
  isLargeInput,
  hasGrayBackground,
  maskConfig: { allowEmptyFormatting, format, mask } = {},
}) => {
  return (
    <PatternFormat
      id={id}
      onPaste={onPaste}
      autoFocus={autoFocus}
      allowEmptyFormatting={allowEmptyFormatting}
      className={classNames('form-input', {
        'form-input-large': isLargeInput,
        'form-input-grey-background': hasGrayBackground,
      })}
      autoComplete={autoComplete}
      disabled={disabled}
      value={value}
      mask={mask}
      placeholder={placeholder}
      onBlur={onBlur}
      onFocus={onFocus}
      format={format}
      onChange={onChange}
    />
  );
};
