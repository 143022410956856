import * as React from 'react';

import { Button } from 'components/buttons/Button';
import { confirmModal, PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import { translate } from 'i18n/translator';
import { AgreementModalResult } from 'pages/utils/SignOrders/enums';

interface AgreementModalProps {
  message: string;
}

const AgreementModalForm = ({ message, resolve }: PropsWithConfirmModal<AgreementModalProps>) => (
  <div className="confirm-modal">
    <h3 style={{ textAlign: 'center' }}>{translate(message)}</h3>
    <div className="confirm-modal__footer">
      <div>
        <Button onClick={() => resolve(AgreementModalResult.Yes)} size="lg">
          {translate('front.confirm-modal.yes.button.label')}
        </Button>
      </div>
      <div>
        <Button onClick={() => resolve(AgreementModalResult.No)} color="secondary" size="lg">
          {translate('front.confirm-modal.no.button.label')}
        </Button>
      </div>
    </div>
  </div>
);

export const AgreementModal = async (message: string): Promise<AgreementModalResult | boolean> =>
  await confirmModal(AgreementModalForm, { message });
