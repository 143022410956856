import React from 'react';

import { Option } from 'api/Service';
import { MaskField, normalizeIban } from 'components/forms/inputs/MaskField';
import { RadioField } from 'components/forms/inputs/RadioField';
import { SelectField } from 'components/forms/inputs/SelectField';
import config from 'config';
import {
  DepositDetailNames,
  RadioPlacementAccTermFieldNames,
} from 'pages/Demands/DepositDemand/enums';
import { isRadioFieldChecked } from 'pages/Demands/DepositDemand/utils';
import { validateIban } from 'pages/Demands/utils';

const { ibanMask } = config.page.domesticPayment;

interface PlaceFundsDepositProps {
  filteredAccounts: Option[];
  radioPlacementAccTerm: string;
  radioPlacementAccTermOptions: Option[];
}

export const PlaceFundsDeposit = ({
  radioPlacementAccTerm,
  radioPlacementAccTermOptions,
  filteredAccounts,
}: PlaceFundsDepositProps) => {
  const [isInternalPlacementAcc, isPlacementOwnAccount] = React.useMemo(
    () =>
      Object.values(RadioPlacementAccTermFieldNames).map(field =>
        isRadioFieldChecked(radioPlacementAccTermOptions, radioPlacementAccTerm, field),
      ),
    [radioPlacementAccTerm],
  );

  return (
    <>
      <RadioField
        name={DepositDetailNames.RadioPlacementAccTerm}
        options={radioPlacementAccTermOptions}
        cssType="block"
      />
      {isInternalPlacementAcc && (
        <MaskField
          name={DepositDetailNames.DoInternalPlacementAccTerm}
          label="front.changing-deposit-demand.externalMainNationalAccount.label"
          normalize={normalizeIban}
          validate={validateIban}
          mask={ibanMask}
          required
        />
      )}
      {isPlacementOwnAccount && (
        <SelectField
          name={DepositDetailNames.PlacementOwnAccountTerm}
          label="front.changing-deposit-demand.ownMainAccount.label"
          options={filteredAccounts}
          required
        />
      )}
    </>
  );
};
