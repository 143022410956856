const DEFAULT_MASK = '/32';

export const prepareIpForRequest = (ip: string): string => {
  if (ip) {
    return ip
      .split(';')
      .reduce((acc, ip) => {
        if (!ip) {
          return acc;
        }

        if (!ip.includes('/')) {
          return [...acc, `${ip}${DEFAULT_MASK}`];
        }

        return [...acc, ip];
      }, [])
      .toString()
      .replace(/,/g, ';');
  }
};

export const prepareIpFromRequest = (ip: string): string => {
  if (ip) {
    return ip
      .split(';')
      .reduce((acc, ip) => {
        if (ip.includes(DEFAULT_MASK)) {
          return [...acc, ip.replace(`${DEFAULT_MASK}`, '')];
        }

        return [...acc, ip];
      }, [])
      .toString()
      .replace(/,/g, ';');
  }
};
