import * as React from 'react';
import { useSelector } from 'react-redux';

import { ConstantEnum } from 'api/enums';
import { FileType, FILETYPE_PRESETS } from 'api/Service';
import { useField } from 'components/forms/ValidatingForm/components/useField';
import { validateMessages } from 'components/validateMessages';
import { translate } from 'i18n/translator';
import { selectConstants } from 'store/selectors';

import { FILE_TYPES, FileInput, useCashed, Value } from '../fileInput/FileInput';

interface FileFieldProps {
  accept: AcceptConfig;
  label: string;
  name: string;
  outputFormat: FileType;
  defaultValue?: Blob | File | string | Value[];
  disabled?: boolean;
  hideFileList?: boolean;
  isEDSSignedDocuments?: boolean; // in Mb
  multiple?: boolean;
  onChange?: (value: Value[]) => void;
  required?: boolean;
  size?: number;
}

interface AcceptConfig {
  extensions?: string[];
  presets?: FILETYPE_PRESETS | FILETYPE_PRESETS[];
}

export const FILE_ACCEPTS = {
  [FILETYPE_PRESETS.DOCUMENTS]: [
    'text/csv',
    'application/dbf',
    'application/x-dbf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/wps-office.xls',
    'application/wps-office.xlsx',
    'application/msword',
    'application/wps-office.doc',
    'application/wps-office.docx',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'application/wps-office.pdf',
    '.csv',
    '.dbf',
    '.xls',
    '.xlsx',
    '.doc',
    '.pdf',
  ],
  [FILETYPE_PRESETS.IMAGES]: ['image/jpeg', 'image/jpg', 'image/png', '.png', '.jpg', '.jpeg'],
  [FILETYPE_PRESETS.PDF]: ['application/pdf', 'application/wps-office.pdf', '.pdf'],
  [FILETYPE_PRESETS.DOC]: [
    'application/msword',
    'application/wps-office.doc',
    'application/wps-office.docx',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    '.doc',
    '.docx',
  ],
  [FILETYPE_PRESETS.CSV]: ['text/csv', '.csv'],
  [FILETYPE_PRESETS.XLS]: [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/wps-office.xls',
    'application/wps-office.xlsx',
    '.xls',
    '.xlsx',
  ],
  [FILETYPE_PRESETS.DBF]: ['application/dbf', 'application/x-dbf', '.dbf'],
  [FILETYPE_PRESETS.ARCHIVES]: [
    'application/x-rar-compressed',
    'application/vnd.rar',
    'application/zip',
    '.rar',
    '.zip',
  ],
  [FILETYPE_PRESETS.XML]: ['text/xml', '.xml'],
};

const calculateFromPresets = (acceptPresetName: FILETYPE_PRESETS | FILETYPE_PRESETS[]) => {
  return (Array.isArray(acceptPresetName) ? acceptPresetName : [acceptPresetName]).reduce(
    ([accepts, types], item) => {
      return [
        [...accepts, ...FILE_TYPES[item]],
        [...types, ...FILE_TYPES[item]],
      ];
    },
    [[], []],
  );
};

const calculate = ({ presets, extensions }: AcceptConfig): string[] => {
  let ext = [];
  if (presets) {
    [ext] = calculateFromPresets(presets);
  }
  if (extensions) {
    ext.push(...extensions);
  }
  return ext;
};

export const FileField = (props: FileFieldProps) => {
  const {
    label,
    name,
    disabled,
    defaultValue,
    required,
    outputFormat,
    hideFileList,
    size,
    multiple,
    accept: acceptConfig,
    isEDSSignedDocuments,
    onChange,
  } = props;

  const maxFileSize = +useSelector(selectConstants())[ConstantEnum.FileSize];
  const extensions = useCashed(calculate(acceptConfig));

  const validate = React.useCallback(
    (values: any[]) => {
      if (required) {
        if (!values || !values.length) {
          return translate(validateMessages.fileRequired);
        }
      }

      if (values) {
        const isEmptyFile = values.some(file => file?.size === 0);

        if (isEmptyFile) {
          return translate(validateMessages.fileEmpty);
        }

        const totalSize = values.reduce(
          // tslint:disable-next-line:no-parameter-reassignment
          (acc, file) => (acc += file?.size || 0),
          0,
        );

        const isValidSize = !maxFileSize || totalSize / 1024 ** 2 <= maxFileSize;

        if (!isValidSize) {
          return validateMessages.maxSizeFile(maxFileSize);
        }

        if (extensions?.length) {
          const invalidFile = values.find(value => value?.isValid === false);
          if (invalidFile) {
            return validateMessages.invalidFileType(invalidFile.value.name || invalidFile.field);
          }
        }
      }
    },
    [extensions, required],
  );

  const { field, form } = useField({ name, defaultValue, validate });

  if (!field && !form) {
    return null;
  }

  const handleChange = (value: Value[]) => {
    onChange?.(value);
    field.onChange(value);
  };

  return (
    <>
      <FileInput
        id={name}
        type="file"
        label={translate(label)}
        value={field.value as any[]}
        onChange={handleChange}
        onBlur={field.onBlur}
        disabled={disabled || form.disabled || form.progress}
        fileConfig={{
          multiple,
          size,
          extensions,
          outputFormat,
          showFileList: !hideFileList,
        }}
        isEDSSignedDocuments={isEDSSignedDocuments}
      />
      {field.error && (
        <div className="form-input-error" style={{ margin: 0 }}>
          {field.error}
        </div>
      )}
    </>
  );
};
