import React from 'react';
import { useLocation } from 'react-router';

import classnames from 'classnames';
import { Devices } from 'enums/DevicesEnum';

import { api } from 'api';
import { useMergeState } from 'api/Service';
import { UncontrolledPopover } from 'components/popover/UncontrolledPopover';
import { Switch } from 'components/Switch';
import { cls } from 'components/utils/utils';
import { translate } from 'i18n/translator';
import { getBackgroundLocation, goto, history } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { RouteConfig } from 'navigations/routes';
import { UserInfo } from 'pages/Dashboard/UserInfo/UserInfo';

interface Props {
  currentDevice: Devices;
  menu: RouteConfig[];
}

type Banner = { link: string; src: string };

interface State {
  banner: Banner;
  isFullWidth: boolean;
  openedItemIdx: number;
}

const getInitialState = (currentDevice: Devices): State =>
  ({
    isFullWidth: [Devices.Desktop, Devices.Mobile].includes(currentDevice),
    openedItemIdx: null,
    banner: null,
  } as State);

const isEqualsRoutes = (path: string, link: string) => {
  const partsPathname = path.split('/');
  const partsLink = link.split('/');

  const isPageSame = partsPathname[1] === partsLink[1];
  const hasParams = partsLink[2] && partsLink[2][0] === ':';
  return hasParams ? isPageSame : link === path;
};

export const SideBar: React.FC<Props> = ({ menu, currentDevice }) => {
  const [state, setState] = useMergeState<State>(getInitialState(currentDevice));
  const location = useLocation();

  const onMount = async () => {
    history.listen(location => {
      const prevLocation = getBackgroundLocation();
      if (
        prevLocation.pathname !== location.pathname &&
        document.body.classList.contains('mobile-sidebar')
      ) {
        document.body.classList.remove('mobile-sidebar');
      }
    });

    const bannerData = await api.common.getBanner();
    const banner: Banner = bannerData.reduce(
      (acc, item) => {
        if (item.code === 'image-main') {
          acc.src = item.descriptionTranslation;
        }
        if (item.code === 'link-main') {
          acc.link = item.descriptionTranslation;
        }
        return acc;
      },
      { src: null, link: null },
    );
    setState({ banner });
  };

  React.useEffect(() => {
    onMount();
  }, []);

  React.useEffect(() => {
    const isFullWidth = [Devices.Desktop, Devices.Mobile].includes(currentDevice);
    if (isFullWidth && document.body.classList.contains('mini-sidebar')) {
      setState({ isFullWidth: true });
      document.body.classList.remove('mini-sidebar');
    } else if (!isFullWidth) {
      setState({ isFullWidth: false });
      document.body.classList.add('mini-sidebar');
    }
  }, [currentDevice]);

  const handleChangeSidebar = () => {
    setState({ isFullWidth: !state.isFullWidth });
    document.body.classList.toggle('mini-sidebar');
  };

  const selectItem = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    item: RouteConfig,
    idx: number,
    parentIdx: number,
  ) => {
    const { openedItemIdx } = state;

    e.preventDefault();

    let nextOpenedItemIdx = null;

    if (item.items && openedItemIdx !== idx) {
      nextOpenedItemIdx = idx;
    }
    if (parentIdx) {
      nextOpenedItemIdx = parentIdx;
    }

    setState({ openedItemIdx: nextOpenedItemIdx });

    if (item.path) {
      const hasDynamicParams = item.path.includes(':');
      const pathWithoutDynamicParams = item.path.split(':')[0];

      const isDifferentPaths = hasDynamicParams
        ? !location.pathname.includes(pathWithoutDynamicParams)
        : item.path !== location.pathname;

      isDifferentPaths && goto(item.menuPath || item.path);
    }
  };

  const getIsItemActive = (menuLink: RouteConfig) => {
    const { pathname } = location;

    if (menuLink.items) {
      return menuLink.items.some(nestedLink => {
        return isEqualsRoutes(pathname, nestedLink.path);
      });
    }

    if (menuLink.path === pages.dashboard) {
      return pathname === pages.dashboard;
    }

    return isEqualsRoutes(pathname, menuLink.path);
  };

  const renderItems = (item: RouteConfig, parendIdx: number) =>
    item.items
      .filter(subMenu => subMenu.showSideBar)
      .map((childItem, childIdx) => (
        <li
          key={childIdx}
          id={childItem.id}
          className={cls({
            'active-item': getIsItemActive(childItem),
          })}
        >
          <a
            href={childItem.menuPath || childItem.path}
            onClick={e => selectItem(e, childItem, childIdx, parendIdx)}
          >
            <span>{translate(childItem.label)}</span>
          </a>
        </li>
      ));

  const { openedItemIdx, banner, isFullWidth } = state;
  const visibleMenu = menu.filter(item => !!item.showSideBar);

  const isDesktopOrTabletDevice =
    Devices.Desktop === currentDevice || Devices.Tablet === currentDevice;

  return (
    <aside className={classnames('left-sidebar', { 'mobile-sidebar': !isDesktopOrTabletDevice })}>
      {isDesktopOrTabletDevice && (
        <div className="user-info-wrapper">
          <UserInfo isSmall={!isFullWidth} />
        </div>
      )}
      <div className="left-sidebar__wrapper">
        <div className="scroll-sidebar">
          <nav className="sidebar-nav">
            <ul id="sidebarnav">
              {visibleMenu.map((item, idx) => {
                const isItemActive = getIsItemActive(item);
                return (
                  <li
                    key={idx}
                    id={`sidebarnav-${idx}`}
                    style={{ position: 'relative' }}
                    className={cls({ 'active-item': isItemActive })}
                  >
                    <a
                      href={item.menuPath || item.path}
                      onClick={e => selectItem(e, item, idx, null)}
                      id={`sidebarnav-${idx}`}
                    >
                      <item.icon />
                      <span>{translate(item.label)}</span>
                      {isFullWidth && (
                        <div
                          className={cls({ 'has-arrow': !!item.items })}
                          aria-expanded={openedItemIdx === idx || isItemActive}
                        />
                      )}
                    </a>
                    {item.items && (
                      <>
                        {!isFullWidth && (
                          <UncontrolledPopover
                            trigger="legacy"
                            placement="right"
                            target={`sidebarnav-${idx}`}
                            className="sidebar-nav-popover"
                            tooltip={translate(item.label)}
                          >
                            <ul>{renderItems(item, idx)}</ul>
                          </UncontrolledPopover>
                        )}
                        <ul
                          className={cls('collapse', {
                            in: openedItemIdx === idx || isItemActive,
                          })}
                        >
                          {renderItems(item, idx)}
                        </ul>
                      </>
                    )}
                  </li>
                );
              })}
            </ul>
          </nav>
          {isFullWidth && banner && (
            <div>
              <a href={banner.link} target="_blank">
                <img className="sidebar-banner" src={banner.src} alt="banner" />
              </a>
            </div>
          )}
        </div>
        {isDesktopOrTabletDevice && (
          <div className="left-sidebar__footer">
            <Switch
              onChange={handleChangeSidebar}
              checked={isFullWidth}
              className="react-switch"
              labelText={
                isFullWidth
                  ? translate('front.sidebar.collapse.label')
                  : translate('front.sidebar.show.label')
              }
            />
          </div>
        )}
      </div>
    </aside>
  );
};
