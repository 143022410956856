import * as React from 'react';
import { Action, TableColumnProps } from 'react-data-table';
import { useRouteMatch } from 'react-router';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { DemandResponse } from 'api/DemandsService/interfaces';
import { OrderActionType } from 'api/enums';
import { ExportType } from 'api/ExportService';
import { SortOrder } from 'api/Service';
import { getStatusColor } from 'api/utils';
import { Page } from 'components/layout/Page/Page';
import { Status } from 'components/layout/Status';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { DEFAULT_WIDTH, PrintingDocuments } from 'components/PrintingDocuments/PrintingDocuments';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import * as format from 'components/utils/format';
import config from 'config';
import { translate } from 'i18n/translator';
import { isPaymentActionAllowed } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { getTranslateAction } from 'pages/Payments/general';
import { PaymentRequestLogo } from 'pages/Payments/PaymentRequest/PaymentRequestLogo';
import { isVisibleAction } from 'pages/Payments/utils';
import { signCertificateOrders, signOrders } from 'pages/utils/SignOrders/signOrders';

import { PaymentRequestsFilter } from './PaymentRequestFilter';

const exposedMenu = (order: DemandResponse) => [
  {
    label: getTranslateAction(OrderActionType.REJECT),
    onClick: async () => await api.payments.executeAction(order.id, OrderActionType.REJECT),
    isReloadable: true,
    isVisible:
      order.actions.some(item => item.actionName === OrderActionType.REJECT) &&
      isPaymentActionAllowed(
        order?.base?.orderType,
        OrderActionType.REJECT,
        order?.base?.customerId,
      ),
  },
  {
    label: getTranslateAction(OrderActionType.REVOKE),
    onClick: async () => await api.payments.withdrawOrders([order.id]),
    isVisible:
      order.actions.some(item => item.actionName === OrderActionType.REVOKE) &&
      isPaymentActionAllowed(
        order?.base?.orderType,
        OrderActionType.REVOKE,
        order?.base?.customerId,
      ),
    confirmMessage: 'front.working-documents-table.actions-revoke-confirmation.label',
    isReloadable: true,
  },
  {
    label: getTranslateAction(OrderActionType.DELETE),
    onClick: async () => await api.payments.deleteOrder(order.id),
    isVisible:
      order.actions.some(item => item.actionName === OrderActionType.DELETE) &&
      isPaymentActionAllowed(
        order?.base?.orderType,
        OrderActionType.DELETE,
        order?.base?.customerId,
      ),
    confirmMessage: 'front.working-documents-table.actions-delete-confirmation.label',
    isReloadable: true,
  },
  {
    label: getTranslateAction(OrderActionType.SIGN),
    onClick: async () => {
      const action = order.actions.find(item => item.actionName === OrderActionType.SIGN);
      if (
        action &&
        (action.confirmation.includes(config.signTypes.SIGNATURE) ||
          action.confirmation.includes(config.signTypes.EDS))
      ) {
        await signCertificateOrders([{ id: order.id, customerId: order.base.customerId }]);
      } else {
        await signOrders([order.id]);
      }
    },
    isVisible:
      order.actions.some(item => item.actionName === OrderActionType.SIGN) &&
      isPaymentActionAllowed(order?.base?.orderType, OrderActionType.SIGN, order?.base?.customerId),
    isReloadable: true,
  },
  {
    label: getTranslateAction(OrderActionType.VIEW),
    onClick: () => {
      goto(pages.paymentRequest.tabs.details(order.id));
    },
    isVisible:
      order.actions.some(item => item.actionName === OrderActionType.VIEW) &&
      isPaymentActionAllowed(order?.base?.orderType, OrderActionType.VIEW, order?.base?.customerId),
  },
  {
    label: getTranslateAction(OrderActionType.EDIT),
    onClick: () => {
      goto(pages.paymentRequest.tabs.details(order.id));
    },
    isVisible:
      order.actions.some(item => item.actionName === OrderActionType.EDIT) &&
      isPaymentActionAllowed(order?.base?.orderType, OrderActionType.EDIT, order?.base?.customerId),
  },
  {
    label: getTranslateAction(OrderActionType.COPY),
    onClick: () => {
      goto(pages.paymentRequest.tabs.details('new', { copyFrom: order.id }));
    },
    isVisible:
      order.actions.some(item => item.actionName === OrderActionType.COPY) &&
      isPaymentActionAllowed(order?.base?.orderType, OrderActionType.COPY, order?.base?.customerId),
  },
  {
    label: getTranslateAction(OrderActionType.PRINT),
    onClick: () => api.payments.getOrderPdf(order.id),
    isVisible:
      order.actions.some(item => item.actionName === OrderActionType.PRINT) &&
      isPaymentActionAllowed(
        order?.base?.orderType,
        OrderActionType.PRINT,
        order?.base?.customerId,
      ),
  },
];

const invoiceMenu = (order: DemandResponse) => [
  {
    label: getTranslateAction(OrderActionType.DELETE),
    onClick: async () => await api.payments.deleteOrder(order.id),
    isVisible:
      order.actions.some(item => item.actionName === OrderActionType.DELETE) &&
      isPaymentActionAllowed(
        order?.base?.orderType,
        OrderActionType.DELETE,
        order?.base?.customerId,
      ),
    confirmMessage: 'front.working-documents-table.actions-delete-confirmation.label',
    isReloadable: true,
  },
  {
    label: getTranslateAction(OrderActionType.SIGN),
    onClick: async () => {
      const action = order.actions.find(item => item.actionName === OrderActionType.SIGN);
      if (
        action &&
        (action.confirmation.includes(config.signTypes.SIGNATURE) ||
          action.confirmation.includes(config.signTypes.EDS))
      ) {
        await signCertificateOrders([{ id: order.id, customerId: order.base.customerId }]);
      } else {
        await signOrders([order.id]);
      }
    },
    isVisible:
      order.actions.some(item => item.actionName === OrderActionType.SIGN) &&
      isPaymentActionAllowed(order?.base?.orderType, OrderActionType.SIGN, order?.base?.customerId),
    isReloadable: true,
  },
  {
    label: getTranslateAction(OrderActionType.VIEW),
    onClick: () => {
      goto(pages.paymentRequest.tabs.details(order.id));
    },
    isVisible:
      order.actions.some(item => item.actionName === OrderActionType.VIEW) &&
      isPaymentActionAllowed(order?.base?.orderType, OrderActionType.VIEW, order?.base?.customerId),
  },
  {
    label: getTranslateAction(OrderActionType.PRINT),
    onClick: () => api.payments.getOrderPdf(order.id),
    isVisible:
      order.actions.some(item => item.actionName === OrderActionType.PRINT) &&
      isPaymentActionAllowed(
        order?.base?.orderType,
        OrderActionType.PRINT,
        order?.base?.customerId,
      ),
  },
  {
    label: getTranslateAction(OrderActionType.EXECUTE),
    onClick: async () => await api.payments.executeAction(order.id, OrderActionType.EXECUTE),
    isReloadable: true,
    isVisible:
      order.actions.some(item => item.actionName === OrderActionType.EXECUTE) &&
      isPaymentActionAllowed(
        order?.base?.orderType,
        OrderActionType.EXECUTE,
        order?.base?.customerId,
      ),
  },
  {
    label: getTranslateAction(OrderActionType.REJECT),
    onClick: async () => await api.payments.executeAction(order.id, OrderActionType.REJECT),
    isReloadable: true,
    isVisible:
      order.actions.some(item => item.actionName === OrderActionType.REJECT) &&
      isPaymentActionAllowed(
        order?.base?.orderType,
        OrderActionType.REJECT,
        order?.base?.customerId,
      ),
  },
];

const getColumns = (isExposed: boolean): TableColumnProps<DemandResponse>[] => [
  {
    label: 'front.demands-table.type.label',
    render: row => (
      <DataColumn>
        <PaymentRequestLogo exposedType={row.base?.extraData?.exposedType} />
      </DataColumn>
    ),
    sort: 'orderBase.extraData#exposedType',
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.working-documents-table.number.label',
    render: row => {
      const orderNumber = row.base?.orderNumber;
      const orderDate = format.date(row.base?.orderDate);
      return <DataColumn title={orderNumber} subTitle={orderDate} />;
    },
    sort: 'orderNumberNumeric',
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.working-documents-table.sender.label',
    render: row => {
      const payerName = row.financial?.payerName;
      const payerAccount = row.financial?.payerIban || row.financial?.payerAccount;
      const receiverName = row.financial?.receiverName;
      const receiverAccount = row.financial?.receiverIban || row.financial?.receiverAccount;
      return (
        <DataColumn
          title={isExposed ? receiverName : payerName}
          subTitle={isExposed ? receiverAccount : payerAccount}
        />
      );
    },
    sort: isExposed ? 'receiverName' : 'payerName',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.working-documents-table.receiver.label',
    render: row => {
      const receiverName = row.financial?.receiverName;
      const receiverAccount = row.financial?.receiverIban || row.financial?.receiverAccount;
      const payerName = row.financial?.payerName;
      const payerAccount = row.financial?.payerIban || row.financial?.payerAccount;
      return (
        <DataColumn
          title={isExposed ? payerName : receiverName}
          subTitle={isExposed ? payerAccount : receiverAccount}
        />
      );
    },
    sort: isExposed ? 'payerName' : 'receiverName',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.working-documents-table.purpose.label',
    render: row => <DataColumn title={row.financial?.purpose} />,
    sort: 'purpose',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.working-documents-table.status.label',
    render: row => (
      <DataColumn>
        <Status color={getStatusColor(row?.base.orderState)}>{row.base.orderStateLabel}</Status>
      </DataColumn>
    ),
    sort: 'orderState',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.working-documents-table.amount.label',
    render: row => {
      const amount = format.getAmount(row.financial?.amount);
      const isoCode = row.financial?.isoCode;
      return <DataColumn title={amount} subTitle={isoCode} />;
    },
    sort: 'amount',
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
];

const actions: Action<DemandResponse>[] = [
  {
    label: 'front.misc.sign-subscribe-sms.label',
    action: async selected => {
      const orders = selected.filter(item =>
        item.actions.some(
          action =>
            action.actionName === OrderActionType.SIGN &&
            action.confirmation.includes(config.signTypes.SMS) &&
            isPaymentActionAllowed(
              item?.base?.orderType,
              OrderActionType.SIGN,
              item?.base?.customerId,
            ),
        ),
      );
      await signOrders(orders.map(item => item.id));
    },
    isReloadable: true,
    isVisible: selected =>
      selected.some(item =>
        item.actions.some(
          action =>
            action.actionName === OrderActionType.SIGN &&
            action.confirmation.includes(config.signTypes.SMS) &&
            isPaymentActionAllowed(
              item?.base?.orderType,
              OrderActionType.SIGN,
              item?.base?.customerId,
            ),
        ),
      ),
  },
  {
    label: 'front.misc.sign-electronic-sign.label',
    action: async selected => {
      const orders = selected.filter(item =>
        item.actions.some(
          action =>
            action.actionName === OrderActionType.SIGN &&
            (action.confirmation.includes(config.signTypes.SIGNATURE) ||
              action.confirmation.includes(config.signTypes.EDS)) &&
            isPaymentActionAllowed(
              item?.base?.orderType,
              OrderActionType.SIGN,
              item?.base?.customerId,
            ),
        ),
      );
      await signCertificateOrders(
        orders.map(item => ({ id: item.id, customerId: item.base.customerId })),
      );
    },
    isReloadable: true,
    isVisible: selected =>
      selected.some(item =>
        item.actions.some(
          action =>
            action.actionName === OrderActionType.SIGN &&
            (action.confirmation.includes(config.signTypes.SIGNATURE) ||
              action.confirmation.includes(config.signTypes.EDS)) &&
            isPaymentActionAllowed(
              item?.base?.orderType,
              OrderActionType.SIGN,
              item?.base?.customerId,
            ),
        ),
      ),
  },
  {
    label: getTranslateAction(OrderActionType.PRINT),
    action: async selected =>
      await confirmModal(
        PrintingDocuments,
        {
          data: selected.filter(item => isVisibleAction(item, OrderActionType.PRINT)),
          objectCode: ExportType.EXPOSED_ORDERS,
        },
        DEFAULT_WIDTH,
      ),
    color: 'secondary',
    isVisible: selected => selected.some(item => isVisibleAction(item, OrderActionType.PRINT)),
    isReloadable: true,
  },
  {
    label: getTranslateAction(OrderActionType.DELETE),
    action: async selected => {
      const orders = selected.filter(
        item =>
          item.actions.some(action => action.actionName === OrderActionType.DELETE) &&
          isPaymentActionAllowed(
            item?.base?.orderType,
            OrderActionType.DELETE,
            item?.base?.customerId,
          ),
      );
      for (let i = 0; i < orders.length; i += 1) {
        await api.payments.deleteOrder(orders[i].id);
      }
    },
    color: 'secondary',
    confirmMessage: 'front.working-documents-table.actions-delete-confirmation.label',
    isVisible: selected =>
      selected.some(
        item =>
          item.actions.some(action => action.actionName === OrderActionType.DELETE) &&
          isPaymentActionAllowed(
            item?.base?.orderType,
            OrderActionType.SIGN,
            item?.base?.customerId,
          ),
      ),
    isReloadable: true,
  },
];

export const PaymentRequestsPage = () => {
  const { params } = useRouteMatch<{ type: 'exposed' | 'invoice' }>();
  const isExposed = params.type === 'exposed';

  const menu = {
    renderMenu: isExposed ? exposedMenu : invoiceMenu,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  };

  const pageTitle = isExposed
    ? 'front.exposedpayments.title-accounts-payable.label'
    : 'front.invoicepayments.title-accounts-payable.label';

  const onRowClick = (order: DemandResponse) => goto(pages.paymentRequest.tabs.details(order.id));

  return (
    <Page title={translate(pageTitle)}>
      <DataTable
        name={isExposed ? TableNamesEnum.ExposedPayments : TableNamesEnum.InvoicePayments}
        columns={[...getColumns(isExposed), menu]}
        actions={actions}
        fetchRows={api.payments.getWorkingDocs}
        defaultSort="id"
        defaultOrder={SortOrder.Desc}
        onRowClick={onRowClick}
        filterComponent={PaymentRequestsFilter}
      />
    </Page>
  );
};
