import * as React from 'react';
import { useState } from 'react';

import { api } from 'api';
import { ClaimTokenRequestInterface } from 'api/RedirectUri/interfaces';
import { Button } from 'components/buttons/Button';
import { confirmationModal } from 'components/modals/ConfirmModal';
import { translate } from 'i18n/translator';

import { RedirectButtonProps } from './interfaces';

const handleRedirect = async ({
  customerId,
  claimTokenCode,
  questionnaireId,
}: ClaimTokenRequestInterface) => {
  try {
    const { uri } = await api.redirectUri.getRedirectUri({
      customerId,
      claimTokenCode,
      questionnaireId,
    });

    window.open(uri, '_blank');
  } catch (e) {
    await confirmationModal('front.redirect-button.error-message.label', false, true);
  }
};

export const RedirectButton = ({
  customerId,
  questionnaireId,
  claimTokenCode,
  label = 'front.redirect-button.label',
  size,
  color,
}: RedirectButtonProps) => {
  const [progress, setProgress] = useState(false);

  const handleClick = async () => {
    setProgress(true);
    await handleRedirect({ customerId, questionnaireId, claimTokenCode });
    setProgress(false);
  };

  return (
    <Button
      label={translate(label)}
      size={size}
      color={color}
      disabled={progress}
      onClick={handleClick}
    />
  );
};

export const exportedForTesting = {
  handleRedirect,
};
