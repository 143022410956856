import * as React from 'react';

import { useForm } from 'components/forms/ValidatingForm/useForm';
import {
  calculateMaxLengthPurpose,
  createNdsPurpose,
  lengthPurpose,
} from 'pages/Payments/InternalPayment/utils';
import { PaymentRequestDetailsFields } from 'pages/Payments/PaymentRequest/interfaces';

export const useMaxLengthPurpose = () => {
  const { getFormData } = useForm<PaymentRequestDetailsFields>();
  const { purpose, vatType, amount, vatPercent } = getFormData();

  return React.useMemo(() => {
    if (!vatType || !purpose) {
      return lengthPurpose;
    }

    const ndsPurpose = createNdsPurpose(vatType, +amount, vatPercent);
    return calculateMaxLengthPurpose(ndsPurpose, purpose);
  }, [vatType, amount, purpose, vatPercent]);
};
