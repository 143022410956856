import React from 'react';
import { useLocation } from 'react-router';

import moment from 'moment';

import { api } from 'api';
import { parseUrlParams } from 'api/backend';
import { DemandType } from 'api/DemandsService/enums';
import { DynamicFieldEssentials } from 'api/DemandsService/interfaces';
import { OrderActionType, OrderState } from 'api/enums';
import { Order } from 'api/OrderService';
import { CustomerTypeEnum } from 'api/UserService';
import { useOrder } from 'components/Document/useOrder';
import { FetchOrderCb, withOrder } from 'components/Document/withOrder';
import { NavTab, NavTabs } from 'components/tabs/NavTabs';
import { translate } from 'i18n/translator';
import { allowDemandAction } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { NEW } from 'navigations/routes';
import { DemandHistoryPage } from 'pages/Demands/DemandHistoryPage';

import { createOnSave, getDetailFields, getFieldNames, getPayload, makeFields } from '../utils';
import { ChangingDepositDetail } from './ChangindDepositDetail/ChangingDepositDetail';
import { DepositClosingDetail } from './DepositClosingDetail/DepositClosingDetail';
import { NewContractTermDepositDetail } from './NewContractTermDepositDetail/NewContractTermDepositDetail';
import { NewDepositGeneralOrder } from './NewDepositGeneralOrderDetail/NewDepositGeneralOrderDetail';
import { NewPlacementTrancheOrderDetail } from './NewTrancheOrderDetail/NewPlacementTrancheOrderDetail';
import { ProlongationDepositDetail } from './ProlongationDepositDetail/ProlongationDepositDetail';
import { getDepositFields } from './utils';

import 'pages/Demands/styles.scss';

const fetchOrder: FetchOrderCb<DynamicFieldEssentials> = async (
  { customerId, routeParams, queryParams },
  { setPayload },
): Promise<Order> => {
  const { type } = queryParams;
  let demand;
  let number;
  let fields;
  let payload;

  if (routeParams.id !== NEW) {
    demand = await api.demands.getDemand(routeParams.id);
    payload = getPayload(demand.dynamicFields, getFieldNames(type as DemandType));

    setPayload({
      ...payload,
      dynamicFields: demand.dynamicFields,
    });

    const detailFields = getDetailFields(demand.dynamicFields, demand.base.orderState);

    return {
      ...makeFields(demand, Number(routeParams.id)),
      detailFields: getDepositFields(detailFields),
    };
  }

  if (queryParams.copyFrom) {
    demand = await api.demands.getDemand(queryParams.copyFrom);
    number = await api.payments.getDocumentNumber({
      customerId: demand.base.customerId,
      orderType: demand.base.orderType,
      orderDate: moment().format('YYYY-MM-DD'),
    });

    payload = getPayload(demand.dynamicFields, getFieldNames(type as DemandType));

    setPayload({
      ...payload,
      dynamicFields: demand.dynamicFields,
    });

    const detailFields: any = getDetailFields(demand?.dynamicFields, OrderState.Draft);

    fields = {
      ...makeFields(demand, null),
      number,
      date: moment().toDate(),
      state: null,
      stateTranslate: null,
      actions: [],
      detailFields: getDepositFields(detailFields),
    };
  } else {
    number = await api.payments.getDocumentNumber({
      customerId,
      orderType: type,
      orderDate: moment().format('YYYY-MM-DD'),
    });
    demand = await api.demands.getDemandTemplate(type, customerId);

    fields = {
      number,
      customerId,
      id: null,
      label: demand.orderTypeModel.name.text,
      type: type,
      state: null,
      stateTranslate: null,
      date: moment().toDate(),
      actions: [],
    };

    payload = getPayload(demand.orderFieldModel, getFieldNames(type as DemandType));

    setPayload({
      ...payload,
      dynamicFields: demand.orderFieldModel,
    });
  }

  return fields;
};

const renderDetails = (orderType: DemandType | CustomerTypeEnum): React.ReactNode => {
  const componentsDetail: Obj<React.ReactNode> = {
    [DemandType.DepositClosingOrder]: <DepositClosingDetail />,
    [DemandType.DepositChangingOrder]: <ChangingDepositDetail />,
    [DemandType.DepositProlongationOrder]: <ProlongationDepositDetail />,
    [DemandType.NewPlacementTrancheOrder]: <NewPlacementTrancheOrderDetail />,
    [DemandType.NewContractTermDeposit]: <NewContractTermDepositDetail type={orderType} />,
    [DemandType.NewDepositGeneralOrder]: <NewDepositGeneralOrder type={orderType} />,
  };
  return componentsDetail[orderType] ?? null;
};

const DepositDemandComponent: React.FC = () => {
  const { order } = useOrder();

  const { type } = parseUrlParams(useLocation().search);
  const orderType = type ?? order?.type;

  const Details = React.useMemo(() => renderDetails(orderType), [orderType]);

  return (
    <NavTabs>
      <NavTab
        title={translate('front.demand-detail.details.label')}
        path={pages.depositDemand.tabs.details(':id')}
      >
        {Details}
      </NavTab>
      {!!order.state && (
        <NavTab
          title={translate('front.demand-detail.history.label')}
          path={pages.depositDemand.tabs.history(':id')}
        >
          <DemandHistoryPage />
        </NavTab>
      )}
    </NavTabs>
  );
};

const goBack = () => goto(pages.demands);

export const DepositDemand = withOrder(({ order }) => ({
  createOnSave,
  fetchOrder,
  allowState: true,
  allowDetails: true,
  allowSave:
    order && allowDemandAction(order.type as DemandType, OrderActionType.EDIT, +order.customerId),
  allowSign:
    order && allowDemandAction(order.type as DemandType, OrderActionType.SIGN, +order.customerId),
  waitForOrder: true,
  disableButtons: true,
  afterSubmit: goBack,
}))(DepositDemandComponent);
