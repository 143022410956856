import * as React from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Col, Container, Row } from 'react-grid';

import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { useModal } from 'components/modals/useModal';
import { translate } from 'i18n/translator';

import './avatar-modal.scss';

interface Props {
  avatar: string;

  resolve(val: any): void;
}

export const AvatarModal: React.FC<Props> = ({ resolve, avatar }) => {
  const { error, progress, handleSubmit } = useModal();

  const onSave = async () => {
    if (editorRef) {
      const avatar = await editorRef.current.getImageScaledToCanvas().toDataURL();
      resolve(avatar);
    }
  };

  const editorRef = React.useRef<AvatarEditor>(null);
  const setEditorRef = (editor: AvatarEditor) => (editorRef.current = editor);

  return (
    <div className="avatar-modal">
      <DefaultForm disabled={progress}>
        <Container>
          <FormError>{error}</FormError>
          <AvatarEditor
            ref={setEditorRef}
            image={avatar}
            width={250}
            height={250}
            border={50}
            color={[255, 255, 255, 0.6]}
            scale={1.2}
            rotate={0}
            borderRadius={200}
            disableBoundaryChecks={false}
            disableHiDPIScaling={false}
            style={{ width: '400px', height: '400px' }}
          />
          <Row>
            <Col sm={6}>
              <Button
                onClick={e => handleSubmit(onSave, e)}
                size="sm"
                progress={progress}
                type="submit"
                block
              >
                {translate('front.avatar-modal.button-save.label')}
              </Button>
            </Col>
            <Col sm={6}>
              <Button onClick={() => resolve(null)} color="secondary" size="sm" block>
                {translate('front.avatar-modal.button-cancel.label')}
              </Button>
            </Col>
          </Row>
        </Container>
      </DefaultForm>
    </div>
  );
};
