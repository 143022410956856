import * as React from 'react';
import { Col, Row } from 'react-grid';

import { VatType } from 'api/enums';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { HintField } from 'components/forms/inputs/HintField';
import { RadioField } from 'components/forms/inputs/RadioField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextAreaField } from 'components/forms/inputs/TextAreaField';
import { TextField } from 'components/forms/inputs/TextField';
import { translate } from 'i18n/translator';
import { validateIban } from 'pages/Demands/utils';
import { InvoiceOrderDetailsNames } from 'pages/Payments/PaymentRequest/enums';
import { vatTypes } from 'pages/Payments/PaymentRequest/utils';
import { MAX_VAT_PERCENT } from 'pages/Payments/utils';

import { PaymentRequestDetailsFields, PaymentRequestPayload } from './interfaces';

const codeRegexps = [/^[\d]{8}$/, /^[\d]{10}$/, /^[0]{9}$/, /^[9]{5}$/];

const validatePayerTaxCode = (value: string) => {
  if (!codeRegexps.some(item => item.test(value))) {
    return translate('front.internal-payment-page.invalid-code.label');
  }
  return '';
};

export const InvoiceOrderDetails: React.FC = () => {
  const { form, payload, order } = useOrderDetails<
    PaymentRequestDetailsFields,
    PaymentRequestPayload
  >();

  const { vatType, isoCode = '' } = form.getFormData();

  React.useEffect(() => {
    order && form.updateData({ ...order.detailFields });
  }, []);

  return (
    <div className="exposed-transfer">
      <DefaultForm className="exposed-transfer__form" disabled={form.progress}>
        <h3>{translate('front.internal-payment-page.payer.title.label')}</h3>
        <HintField
          label="front.internal-payment-page.organization-name.label"
          name={InvoiceOrderDetailsNames.PayerName}
          hints={payload.payerOptions}
          required
          disabled
        />
        <TextField
          label="front.internal-payment-page.payer-account.label"
          name={InvoiceOrderDetailsNames.PayerAccount}
          validate={validateIban}
          required
          disabled
        />
        <h3>{translate('front.internal-payment-page.receiver.title.label')}</h3>
        <CustomerSelect
          label="front.internal-payment-page.organization-name.label"
          name={InvoiceOrderDetailsNames.ReceiverName}
          disabled
          required
        />
        <SelectField
          label="front.internal-payment-page.receiver-account.label"
          name={InvoiceOrderDetailsNames.ReceiverIban}
          options={payload.receiverAccountOptions}
          required
          clearable
        />
        <Row>
          <Col sm={4}>
            <TextField
              label="front.internal-payment-page.receiver-code.label"
              name={InvoiceOrderDetailsNames.ReceiverTaxCode}
              validate={validatePayerTaxCode}
              required
              disabled
            />
          </Col>
          <Col sm={8}>
            <TextField
              label="front.internal-payment-page.order-receiver-bic.label"
              name={InvoiceOrderDetailsNames.ReceiverBic}
              disabled
              required
            />
          </Col>
        </Row>
        <h3>{translate('front.internal-payment-page.amount-and-purpose.title.label')}</h3>
        <Row>
          <Col sm={4}>
            <AmountField
              label={`${translate('front.internal-payment-page.order-amount.label')} ${isoCode}`}
              name={InvoiceOrderDetailsNames.Amount}
              minAmount={1}
              hasMoneyFormat
              disabled
              required
            />
          </Col>
          <Col sm={8}>
            <RadioField
              name={InvoiceOrderDetailsNames.VatType}
              options={vatTypes}
              disabled
              required
            />
          </Col>
        </Row>
        {vatType === VatType.INCLUDE_VAT20 && (
          <Row>
            <Col sm={6} />
            <Col sm={4}>
              <AmountField
                name={InvoiceOrderDetailsNames.VatPercent}
                label="front.domestic-transfer.vat-rate.label"
                maxAmount={MAX_VAT_PERCENT}
                disabled
                required
              />
            </Col>
          </Row>
        )}
        <TextAreaField
          label="front.internal-payment-page.order-purpose.label"
          name={InvoiceOrderDetailsNames.Purpose}
          rows={3}
          minLength={7}
          maxLength={160}
          hasCounter
          disabled
          required
          denyCaretReturn
          forbiddenSpecialCharacters
        />
        {!form.disabled && (
          <CheckboxField
            label="front.internal-payment-page.order-save-payment-purpose.label"
            name={InvoiceOrderDetailsNames.SavePaymentPurpose}
            disabled
          />
        )}
      </DefaultForm>
    </div>
  );
};
