import * as React from 'react';

import { api } from 'api';
import { ConstantEnum } from 'api/enums';
import { translate } from 'i18n/translator';

import './styles.scss';

const Suggestion: React.FC<{ link: string }> = ({ link }) => (
  <div className="login-page__body__info__suggestion">
    <span className="login-page__body__info__suggestion_desc">
      <a target="_blank" href={link}>
        {translate('front.login.i-tiny.label')}
      </a>
    </span>
  </div>
);

export const LoginLayout: React.FC = ({ children }) => {
  const [instructionLink, setInstructionLink] = React.useState('');

  const fetchInstructionLink = async () => {
    const { value } = await api.constant.getConstantByName(ConstantEnum.LinkToUserManual);
    setInstructionLink(value.trim());
  };

  React.useEffect(() => {
    fetchInstructionLink();
  }, []);

  return (
    <div className="login-page__body">
      <div className="login-page__body__box card">
        <div className="card-body">{children}</div>
        <div className="site-link">{translate('front.login.site-link.label')}</div>
      </div>
      <div className="login-page__body__info">
        <div className="login-page__body__info__title">
          {translate('front.login.title-first.label')}
          <div className="strong">{` ${translate('front.login.title-second.label')}`}</div>
        </div>
        {instructionLink && <Suggestion link={instructionLink} />}
      </div>
    </div>
  );
};
