import * as React from 'react';
import { TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { DemandResponse } from 'api/DemandsService/interfaces';
import { getPage, GridRequest } from 'api/Service';
import { toMoneyAmount } from 'components/forms/inputs/amountFields/AmountField';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import * as format from 'components/utils/format';

interface Props {
  data: DemandResponse[];
}

const columns: TableColumnProps<DemandResponse>[] = [
  {
    label: 'front.salary.related-number.label',
    render: row => (
      <DataColumn title={row.base.orderNumber} subTitle={format.toDDMMYYY(row.base.created)} />
    ),
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.salary.receiver-account-number.label',
    render: row => (
      <DataColumn title={row.financial.receiverIban || row.financial.receiverAccount} />
    ),
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.salary.related-status.label',
    render: row => (
      <span className="order-status">
        <DataColumn title={row.base.orderStateLabel} />
      </span>
    ),
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.salary.related-total.label',
    render: row => <DataColumn title={toMoneyAmount(row.financial.amount || 0)} subTitle={'UAH'} />,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

export const SalaryRelatedPayments: React.FC<Props> = ({ data }) => {
  const fetchRows = React.useCallback(
    async (request: GridRequest) => getPage(data, request),
    [data],
  );

  return (
    <DataTable
      name={TableNamesEnum.SalaryRelated}
      fetchRows={fetchRows}
      columns={columns}
      hidePagination
    />
  );
};
