import React from 'react';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { OrderType } from 'api/DemandsService/enums';
import { Button } from 'components/buttons/Button';
import { CounterDescription } from 'components/CounterDescription/CounterDescription';
import { InformationIcon } from 'components/icons';
import { Page } from 'components/layout/Page/Page';
import { PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { translate } from 'i18n/translator';

import './styles.scss';

interface ErrorParams {
  customerId: number;
  errorMessage: string;
  factorsLeft: any;
  fullySigned: boolean;
  hasError: boolean;
  orderId: number;
  orderNumber: string;
  orderType: OrderType;
}

interface Props {
  errors: ErrorParams[];
  ordersCount: number;
  successCount: number;
}

const columns = [
  {
    label: 'front.sign-information.column-number.label',
    render: (row: ErrorParams) => <DataColumn title={row.orderNumber} />,
    sort: 'orderNumber',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.sign-information.column-error.label',
    render: (row: ErrorParams) => <DataColumn title={row.errorMessage} titleWrap />,
    sort: 'orderNumber',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

export const SignInformation = ({
  errors,
  successCount,
  ordersCount,
  resolve,
}: PropsWithConfirmModal<Props>) => {
  const fetchRows = React.useCallback(
    async () => ({ rows: errors, total: { count: errors.length } }),
    [errors],
  );

  const handleClose = () => resolve(true);

  return (
    <Page title="front.sign-information.results.label">
      <div className="counter-wrapper">
        <CounterDescription
          count={successCount}
          description={translate('front.sign-information.signed.label')}
        />
        <CounterDescription
          count={ordersCount ? ordersCount - successCount : errors.length}
          description={translate('front.sign-information.not-signed.label')}
        />
        <Tooltip
          className="tooltip-wrapper"
          placement="bottom"
          tooltip={translate('front.message-not-displayed.label')}
          label={<InformationIcon />}
        />
      </div>
      {errors.length > 0 && (
        <DataTable
          name={TableNamesEnum.SignErrors}
          fetchRows={fetchRows}
          columns={columns}
          hidePagination
        />
      )}
      <Button onClick={handleClose} label="front.table.filter-header-close.label" />
    </Page>
  );
};
