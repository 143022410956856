import * as React from 'react';
import {useSelector} from 'react-redux';
import {Route, Router, Switch} from 'react-router';

import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import {connectedRouterRedirect} from 'redux-auth-wrapper/history4/redirect';
import {createSelector} from 'reselect';

import {api} from 'api';
import {AuthLayout} from 'components/layout/AuthLayout';
import {DeviceResolver} from 'components/layout/deviceResolver/DeviceResolver';
import {GlobalModal} from 'components/modals/globalModal/GlobalModal';
import {ModalCollectionView} from 'components/modals/globalModalCollection/GlobalModalCollection';
import {history} from 'navigations/navigate';
import {pages} from 'navigations/pages';
import {Dashboard} from 'pages/Dashboard/Dashboard';
import {LoginPage} from 'pages/Login/LoginPage';
import ServerUnavailable from 'pages/Login/Steps/ServerUnavailable';
import {RegistrationFilesPage} from 'pages/Registration/RegistrationFilesPage';
import {RegistrationPage} from 'pages/Registration/RegistrationPage';
import {selectServerError, selectUser} from 'store/selectors';

import {ServerTimeInstance} from './utils/ServerTime';

import '../www/plugins/bootstrap/css/bootstrap.css';
import '../www/theme/scss/style.scss';
import '../www/theme/scss/colors/blue.scss';


const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: pages.login,
  authenticatedSelector: createSelector(selectUser(), user =>
    Boolean(user.value && user.privileges?.length),
  ),
  wrapperDisplayName: 'UserIsAuthenticated',
});

const locationHelper = locationHelperBuilder({});

const userIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
  allowRedirectBack: false,
  authenticatedSelector: createSelector(
    selectUser(),
    user => !user.value || !user.privileges?.length,
  ),
  wrapperDisplayName: 'UserIsNotAuthenticated',
});

export const App = () => {
  React.useEffect(() => void ServerTimeInstance.initTime(api.common.getServerTime), []);

  const isServerError = useSelector(selectServerError());
  if (isServerError) {
    return (
      <AuthLayout>
        <div className="login-page__body">
          <div className="login-page__body__box card">
            <div className="card-body">
              <ServerUnavailable />
            </div>
          </div>
        </div>
      </AuthLayout>
    );
  }
  return (
    <DeviceResolver>
      <Router history={history}>
        <Switch>
          <Route path={pages.registration} component={userIsNotAuthenticated(RegistrationPage)} />
          <Route path={pages.login} component={userIsNotAuthenticated(LoginPage)} />
          <Route
            path={pages.registrationFiles(':orderId', ':uuid')}
            component={RegistrationFilesPage}
          />
          <Route path={pages.dashboard} component={userIsAuthenticated(Dashboard)} />
        </Switch>
        <GlobalModal /> <ModalCollectionView />
      </Router>
    </DeviceResolver>
  );
};
