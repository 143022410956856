import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Base64 } from 'js-base64';
import { createSelector } from 'reselect';

import { api } from 'api';
import * as CipherService from 'api/CertificateService/CipherModule/CipherService';
import { getCertificateInfo } from 'api/CertificateService/CipherModule/CipherService';
import { UserKeystoreType } from 'api/CertificateService/enums';
import { ActionEnum } from 'api/enums';
import { Effects, useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { CustomerSelectModal } from 'pages/Login/CustomerSelectModal/CustomerSelectModal';
import {
  CustomerSelectModalProps,
  CustomerSelectMode,
} from 'pages/Login/CustomerSelectModal/types';
import { GetButtonsComponent } from 'pages/Login/Steps/SignatureLogin/GetButtonsComponent';
import { KepSignatureLoginPayload } from 'pages/Login/Steps/SignatureLogin/interface';
import { SignatureComponent } from 'pages/Login/Steps/SignatureLogin/SignatureComponent';
import { useLoginContext } from 'pages/Login/useLoginContext';
import { NoConnectionModal } from 'pages/Profile/Certificates/Modals/NoConnectionModal';
import { validatePasswordWithException } from 'pages/Profile/Certificates/PasswordValidator';
import { isAvtorCertificate } from 'pages/Profile/Certificates/utils';
import { UserActions } from 'store/actions/user';
import { UserState } from 'store/reducers/user';
import { selectUser } from 'store/selectors';

export interface KepSignatureLoginFields {
  password: string;
  token: string;
}

const KepSignatureLogin = () => {
  const [isSearchTokens, setIsSearchTokens] = React.useState(false);

  const dispatch = useDispatch();

  const dispatchUser = (user: UserState) => dispatch(UserActions.setUser(user));

  const signingString = useSelector(createSelector(selectUser(), user => user.value));
  const { goToFirstStep, setCurrentFactor, certificates } = useLoginContext();

  const filteredCertificates = certificates.filter(
    cert => !isAvtorCertificate(cert) && cert.userKeystoreType === UserKeystoreType.HardWired,
  );

  const fetchToken = async ({
    setPayload,
    setFields,
  }: Partial<Effects<KepSignatureLoginFields, KepSignatureLoginPayload>>) => {
    const tokenOptions = await CipherService.getConnectedTokenOptions();
    const token = tokenOptions[0]?.value;

    setFields({ token });
    setPayload({ tokenOptions });
  };

  const { progress, error, payload, setPayload, updateData, handleSubmit } = useForm<
    KepSignatureLoginFields,
    KepSignatureLoginPayload
  >(async ({ setPayload, setFields }) => await fetchToken({ setPayload, setFields }));

  const onSearchToken = async () => {
    setIsSearchTokens(true);
    await fetchToken({ setPayload, setFields: updateData });
    setIsSearchTokens(false);
  };

  const onSave = async (formData: KepSignatureLoginFields) => {
    const { token: tokenSerial, password } = formData;

    validatePasswordWithException(password, '', 'front.form.pin-code.error');

    const token = payload.tokenOptions.find(item => item.value === tokenSerial).content;

    const ticketUuid = await CipherService.createSession();
    await CipherService.setSessionParams(ticketUuid);

    await CipherService.createSessionContainer({
      ticketUuid,
      keyStorePath: token.activeModePath,
    });

    const tokenCertificate = await getCertificateInfo(
      ticketUuid,
      password,
      ActionEnum.LoginWithCipher,
    );

    const certificatesSN = tokenCertificate.certificateInfo.certificateSerialNumber?.value ?? '';
    const activeCertificate = filteredCertificates.filter(cert =>
      certificatesSN.includes(cert.certificateSN),
    );

    if (activeCertificate.length === 0) {
      await CipherService.closeSession(ticketUuid);

      return await confirmModal(NoConnectionModal, {
        renderButtons,
        description: 'front.login-form.certificate.token-no-valid-keys.label',
      });
    }

    const signedString = await CipherService.sign(ticketUuid, password, [
      Base64.toBase64(signingString),
    ]);

    await CipherService.closeSession(ticketUuid);
    await api.auth.signatureLogin({ sign: signedString[0] });

    const user = await confirmModal<UserState, CustomerSelectModalProps>(
      CustomerSelectModal,
      { mode: CustomerSelectMode.auth },
      undefined,
      {},
      true,
    );

    dispatchUser({ ...user });
  };

  const renderButtons = GetButtonsComponent({
    goToFirstStep,
    setCurrentFactor,
    fetchToken,
    setPayload,
    updateData,
  });

  return (
    <SignatureComponent
      progress={progress}
      error={error}
      payload={payload}
      onSearchToken={onSearchToken}
      isSearchTokens={isSearchTokens}
      goToFirstStep={goToFirstStep}
      handleSubmit={e => handleSubmit(onSave, e)}
      isCipher
    />
  );
};

export default withForm(KepSignatureLogin);
