import * as React from 'react';
import { Col } from 'react-grid';

import { Button } from 'components/buttons/Button';
import { translate } from 'i18n/translator';
import { useControls } from 'pages/Profile/Certificates/CertificateEngine/useControls';

export const CertificateControls: React.FC<any> = props => {
  const { allowChangePassword, allowCancel, onSave, resolve, progress, labelButtonOnSave } = props;

  const { changePassword } = useControls();

  return (
    <>
      <Col sm={6.5}>
        <Button
          onClick={onSave}
          size="md"
          progress={progress}
          type="submit"
          block
          label={translate(labelButtonOnSave)}
        />
      </Col>
      {allowChangePassword && (
        <Col sm={5.5}>
          <Button
            onClick={changePassword}
            color="secondary"
            size="md"
            block
            label="front.cert-page-add.change-pin-button.label"
          />
        </Col>
      )}
      {allowCancel && resolve && (
        <Col sm={5.5}>
          <Button
            onClick={() => {
              resolve(false);
            }}
            color="secondary"
            size="md"
            block
            label="front.certificates.сancel-button.label"
          />
        </Col>
      )}
    </>
  );
};
