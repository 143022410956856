import { Decimal } from 'decimal.js-light';

import { api } from 'api';
import { OrderType } from 'api/DemandsService/enums';
import { DemandResponse, DynamicField } from 'api/DemandsService/interfaces';
import { OrderState, PaymentTypes, VatType } from 'api/enums';
import { Account } from 'api/interfaces';
import { Option } from 'api/Service';
import {
  DatePeriodEnum,
  getCurrentMonth,
  getCurrentQuarter,
  getCurrentWeek,
  getPreviousDay,
  getPreviousMonth,
  getPreviousQuarter,
  getPreviousWeek,
  getToday,
} from 'components/forms/datePicker/utils';
import { defaultMaskConfig } from 'components/forms/inputs/amountFields/useAmountField';
import { UpdateData } from 'components/forms/ValidatingForm/FormContext';
import * as format from 'components/utils/format';
import {
  getAmountNumberFormatWithComma,
  toAmountFormat,
  toAmountFormatWithComma,
  toAmountFormatWithDot,
} from 'components/utils/format';
import config from 'config';
import { translate } from 'i18n/translator';
import { DomesticTransferDetailNames } from 'pages/Payments/InternalPayment/enums';
import {
  CreatePurposeParams,
  ParsedBudgetPurpose,
  PurposeProps,
} from 'pages/Payments/InternalPayment/interface';
import { LOCAL_STORAGE_KEY } from 'store/actions/types';
import { isExist, isString } from 'utils/isData';
import { LocalStorage } from 'utils/LocalStorage';

export const lengthPurpose = 420;
const maxLengthSubAccount = 10;
const startIndexBankBic = 4;
const endIndexBankBic = 10;

const purposeSpace = /;\s*/g;
export const codeRegexps = [/^[\d]{8,10}$/, /^[0]{9}$/, /^[9]{5}$/];

export const DEFAULT_PAYMENT_TYPES = [
  PaymentTypes.INTERNAL_TRANSFER,
  PaymentTypes.DOMESTIC_TRANSFER,
  PaymentTypes.INTERNAL_TRANSFER_ESCROU,
  PaymentTypes.DOMESTIC_TRANSFER_ESCROU,
];

export const validateReceiverTaxCode = (value: string): string => {
  if (!codeRegexps.some(item => item.test(value))) {
    return translate('front.internal-payment-page.invalid-receiver-tax-code.label');
  }

  return null;
};

const removeSpace = (purpose: string) => purpose.replaceAll(purposeSpace, ';');

export const normalizePurposeVatTypeEmpty = (purpose: string, taxCode: string): string => {
  let newPurpose = removeSpace(purpose);

  // if purpose have 7 symbols(";") , then we cut last symbol ";".
  // Because for budget info need six symbols ";"
  if (newPurpose.split(';').length === 8) {
    newPurpose = newPurpose.replace(/;$/, '');
  }

  const [, , payerTaxCode] = newPurpose.split(';');

  if (payerTaxCode !== taxCode) {
    return `${newPurpose.replace(`${payerTaxCode};`, '')}`;
  }

  return newPurpose;
};

export const getCorrectVatPercent = (vatPercent: string | number): string =>
  isAmountFormat(+vatPercent) ? toAmountFormatWithComma(vatPercent) : `${vatPercent}`;

export const checkIsCustomerBudgetPurpose = (
  dynamicFields: Array<Partial<DynamicField>>,
): boolean => {
  const field = dynamicFields.find(
    d => d.field.code === DomesticTransferDetailNames.IsCustomBudgetPurpose,
  );

  return !!field?.data[0]?.booleanValue;
};

export const getUpdatedVatTypeAndVatPercent = (
  vatType: VatType,
  vatPercent: string | number,
  isNew: boolean,
): { vatPercent: string | number; vatType: VatType } => {
  if (isNew && vatType === VatType.EMPTY) {
    return {
      vatType: VatType.INCLUDE_VAT20,
      vatPercent: DEFAULT_VAT_PERCENT,
    };
  }

  return {
    vatType,
    vatPercent,
  };
};

export const getPreComputedFields = (doc: DemandResponse): ParsedBudgetPurpose => {
  if (checkIsCustomerBudgetPurpose(doc.dynamicFields)) {
    return;
  }

  let preComputed: ParsedBudgetPurpose;

  if (doc.financial.vatType === VatType.EMPTY) {
    preComputed = parseBudgetPurpose(doc.financial.purpose, doc.financial.payerTaxCode);
    if (preComputed.budgetDate) {
      preComputed.budgetDate = format.parseDate(preComputed.budgetDate);
    }
    if (preComputed.budgetNumber || preComputed.budgetDate || preComputed.reserve) {
      preComputed.otherDetails = true;
    }
  }

  return preComputed;
};

export const getAccountValue = (
  accountId: number | string,
  options: Option<Account>[] = [],
): string =>
  accountId ? options.find(acc => Number(acc.value) === Number(accountId))?.value : null;

export const parseBudgetPurpose = (purpose: string, taxCode: string): ParsedBudgetPurpose => {
  const correctPurpose = normalizePurposeVatTypeEmpty(purpose, taxCode);

  const [, budgetCode, payerTaxCode, explanatoryInfo, budgetDate, budgetNumber, reserve] =
    correctPurpose.split(';');

  return {
    budgetCode,
    payerTaxCode,
    explanatoryInfo,
    budgetDate,
    budgetNumber,
    reserve,
  };
};

const locales: string[] = LocalStorage.getItem(LOCAL_STORAGE_KEY.LOCALES, [
  config.i18n.defaultLanguageCode,
]).map(({ value }: { value: string }) => value);

enum VAT_KEY {
  VAT_TYPE = 0,
  CURRENCY = 1,
  WITHOUT_VAT = 2,
}

export const DEFAULT_VAT_PERCENT = '20';
export const VAT_PERCENT_0 = '0';
export const isAmountFormat = (value: string | number): boolean =>
  new RegExp(/[.]/).test(`${value}`);

export const normalizeVatPercent = (value: string | number): string => {
  if (!isExist(value) || value === defaultMaskConfig.decimalSeparator) {
    return null;
  }

  const correctValue = toAmountFormatWithDot(value);

  return isAmountFormat(+correctValue)
    ? getAmountNumberFormatWithComma(+correctValue)
    : new Decimal(correctValue).toFixed(0);
};

const currency = translate('front.vat-types.uan-postfix.label');
const transWithoutVat = translate('front.vat-types.without-vat.label');
const transWithVat = (vatPercent: string | number) =>
  `${translate('front.vat-types.include-vat.label')} ${vatPercent}%`;

const withVatAndCurrencyAllTrans = (vatPercent: string | number): string[][] => {
  const vatPercent0 = VAT_PERCENT_0.includes(`${vatPercent}`);

  return locales.reduce(
    (acc: string[][], lang: string) => [
      ...acc,
      [
        vatPercent0
          ? `${translate('front.vat-types.include-vat.label', lang)} ${vatPercent}%`
          : `${translate('front.vat-types.include-vat.label', lang)} ${vatPercent}%`,
        translate('front.vat-types.uan-postfix.label', lang),
        translate('front.vat-types.without-vat.label', lang),
      ],
    ],
    [],
  );
};

export const calculateVat = (
  amount: number | string,
  vatType: VatType,
  vatPercent: string | number,
): number | string => {
  if (!amount) {
    return 0;
  }

  switch (vatType) {
    case VatType.WITHOUT_VAT: {
      return 0;
    }
    case VatType.INCLUDE_VAT20: {
      const correctVatPercent = toAmountFormatWithDot(vatPercent);
      const correctAmount = toAmountFormatWithDot(amount);
      const totalAmount = (+correctAmount * +correctVatPercent) / (100 + +correctVatPercent);

      return toAmountFormatWithComma(toAmountFormat(totalAmount));
    }
    default:
      return null;
  }
};

export const cutUserPurpose = (purpose: string, vatPercent: string | number): string =>
  withVatAndCurrencyAllTrans(vatPercent ? normalizeVatPercent(vatPercent) : vatPercent).reduce(
    (purpose, translation) => {
      const vatPercent0 = VAT_PERCENT_0.includes(`${vatPercent}`);

      return purpose
        .replace(/^\*;\d*;\d*;[^;]*;(\d{2}\.\d{2}\.\d{4})?;\d*;[^;]*;/, '')
        .replace(
          new RegExp(
            vatPercent0
              ? `${translation[VAT_KEY.VAT_TYPE]}\u0020?`
              : `${translation[VAT_KEY.VAT_TYPE]} - \\d+\\,?\\.?(\\d+)? ${
                  translation[VAT_KEY.CURRENCY]
                }\u0020?`,
            'g',
          ),
          '',
        )
        .replace(new RegExp(`(${translation[VAT_KEY.WITHOUT_VAT]})`, 'g'), '')
        .trim();
    },
    purpose || '',
  );

export const createNdsPurpose = (
  vatType: VatType,
  amount: number | string,
  vatPercent: string | number,
): string => {
  if (vatType === VatType.WITHOUT_VAT) {
    return `${transWithoutVat}`;
  }

  if (vatType === VatType.INCLUDE_VAT20) {
    const correctVatPercent = normalizeVatPercent(vatPercent);
    const vatPercent0 = VAT_PERCENT_0.includes(`${correctVatPercent}`);

    return vatPercent0
      ? `${transWithVat(correctVatPercent)}`
      : `${transWithVat(correctVatPercent)} - ${
          calculateVat(amount, vatType, vatPercent) || VAT_PERCENT_0
        } ${currency}`;
  }

  return '';
};

const withVatRegexp = (vatPercent: number | string): RegExp => {
  const vatPercent0 = VAT_PERCENT_0.includes(`${vatPercent}`);

  return new RegExp(
    vatPercent0
      ? `${transWithVat(vatPercent).replace(/[^\D,?]+[%]/g, '')}\\d+\\,?(\\d+?)?%`
      : `${transWithVat(vatPercent).replace(
          /[^\D,?]+[%]/g,
          '',
        )}\\d+\\,?(\\d+?)?% - \\d+\\,?(\\d+)? ${currency}`,
    'g',
  );
};

export const hasVatTypeInPurpose = ({
  purpose,
  vatType,
  amount,
  vatPercent,
}: PurposeProps): boolean => {
  if (isString(purpose)) {
    if (vatType === VatType.WITHOUT_VAT) {
      return new RegExp(`${transWithoutVat}`).test(purpose);
    }

    if (vatType === VatType.INCLUDE_VAT20) {
      const currentNdsPurpose = createNdsPurpose(vatType, amount, vatPercent);

      return currentNdsPurpose === purpose.match(withVatRegexp(vatPercent))?.[0];
    }
  }
};

export const createPurpose = ({
  vatType,
  purpose,
  amount,
  vatPercent,
  prevVatPercent,
}: CreatePurposeParams): string => {
  if (vatType === VatType.EMPTY || vatType === VatType.TAXES_PAYMENT) {
    return purpose;
  }

  const newPurpose = `${cutUserPurpose(purpose, prevVatPercent)} ${createNdsPurpose(
    vatType,
    amount,
    vatPercent,
  )}`;

  return newPurpose.trim();
};

export const calculateMaxLengthPurpose = (ndsPurpose: string, purpose: string): number => {
  if (purpose.includes(ndsPurpose) || ndsPurpose.includes(purpose)) {
    return lengthPurpose;
  }

  return lengthPurpose - ndsPurpose.length - 1; // '-1' it's adding space
};

export const getCurrentPeriod = (selectedStoragePeriod: DatePeriodEnum): Array<string> => {
  switch (selectedStoragePeriod) {
    case DatePeriodEnum.TODAY:
      return getToday();
    case DatePeriodEnum.PREVIOUS_DAY:
      return getPreviousDay();
    case DatePeriodEnum.CURRENT_WEEK:
      return getCurrentWeek();
    case DatePeriodEnum.PREVIOUS_WEEK:
      return getPreviousWeek();
    case DatePeriodEnum.CURRENT_MONTH:
      return getCurrentMonth();
    case DatePeriodEnum.PREVIOUS_MONTH:
      return getPreviousMonth();
    case DatePeriodEnum.CURRENT_QUARTER:
      return getCurrentQuarter();
    case DatePeriodEnum.PREVIOUS_QUARTER:
      return getPreviousQuarter();
    case DatePeriodEnum.SELECT_RANGE:
      return;
  }
};

const dot = 1;
export const normalizeSubAccount =
  (analyticAccount: string) => (value: string, prevValue: string) => {
    if (analyticAccount && value.length > analyticAccount.length) {
      return `${analyticAccount}.${value
        .substring(analyticAccount.length, analyticAccount.length + maxLengthSubAccount + dot)
        .replace(/\D/g, '')}`;
    }

    if (value > prevValue) {
      return analyticAccount;
    }

    return '';
  };

export const hasAttributeTransactionAccount = (analyticAccount: string) =>
  analyticAccount?.substring(0, 4) === config.page.paymentRequest.attributeTransactionAccount;

export const handleBlurSubAccount =
  (
    analyticAccount: string,
    updateFieldInData: (path: string, fieldName: string, value: string) => void,
  ) =>
  (value: string) => {
    if (value.length <= analyticAccount.length) {
      updateFieldInData(DomesticTransferDetailNames.SubAccount, 'value', '');
      updateFieldInData(DomesticTransferDetailNames.SubAccount, 'error', '');
    }
  };
export const handleFocusSubAccount =
  (analyticAccount: string, updateData: UpdateData<any>) => (value: string) => {
    if (analyticAccount && !value) {
      updateData({ subAccount: analyticAccount });
    }
  };
export const isVisibleSubAccount = (receiverAccount: string) => {
  const { defaultBic } = config.page.paymentRequest;

  if (receiverAccount) {
    const analyticAccount = getAnalyticAccount(receiverAccount);
    return (
      receiverAccount.substring(startIndexBankBic, endIndexBankBic).trim() === defaultBic &&
      hasAttributeTransactionAccount(analyticAccount)
    );
  }

  return false;
};

export const getOnlyAnalyticAccount = (account: string) => account.substring(10).replace(/^0+/, '');

export const getAnalyticAccount = (receiverAccount: string) => {
  const { codeUah } = config.page.paymentRequest;
  return receiverAccount ? `${getOnlyAnalyticAccount(receiverAccount)}.${codeUah}` : null;
};

export const hasSigningRules = async (
  payerAccountOptions: Option[],
  receiverAccountOptions: Option[],
  orderType: OrderType,
  customerId: number,
  orderState: OrderState,
): Promise<boolean> => {
  if (orderState === OrderState.Draft) {
    const isInternal =
      orderType === OrderType.InternalTransfer || orderType === OrderType.InternalTransferESCROU;

    const hasEscrouAccount =
      payerAccountOptions.some(item => item.content.type === config.page.accounts.ESCROU) ||
      !!receiverAccountOptions?.some(item => item.content.type === config.page.accounts.ESCROU);

    const checkIsAvailableSign = await api.payments.checkIsAvailableSignForCustomers(
      customerId,
      isInternal ? OrderType.InternalTransfer : OrderType.DomesticTransfer,
    );

    const checkIsAvailableSignForEscrow = hasEscrouAccount
      ? await api.payments.checkIsAvailableSignForCustomers(
          customerId,
          isInternal ? OrderType.InternalTransferESCROU : OrderType.DomesticTransferESCROU,
        )
      : null;

    return checkIsAvailableSign || checkIsAvailableSignForEscrow;
  }

  return false;
};

export const normalizeIban = (value = ''): string => {
  switch (value.length) {
    case 0:
      return '';
    case 1:
      return 'U' === value.toUpperCase() ? 'U' : '';
    case 2:
      return 'UA' === value.toUpperCase() ? 'UA' : '';
  }

  return `UA${value.substring(2).replace(/\D/g, '').substring(0, 27)}`;
};

export const maskIban = (val: string): string => {
  if (typeof val !== 'string' || !val) {
    return val;
  }
  const plainIban = normalizeIban(val);
  const parts: string[] = [
    plainIban.substring(0, 2),
    plainIban.substring(2, 4),
    plainIban.substring(4, 10),
    plainIban.substring(10, 29),
  ];
  return parts.filter(v => v).join('.');
};

export const getBankBic = (iban: string | number): string => `${iban}`.substring(4, 10);
