import * as React from 'react';

import { useField } from 'components/forms/ValidatingForm/components/useField';
import { bem } from 'components/utils/bem';
import { validateMessages } from 'components/validateMessages';
import { translate } from 'i18n/translator';
import { isExist, isString } from 'utils/isData';

import { HintInput, HintInputProps } from '../hintInput/HintInput';
import { FieldInput } from './FieldInput';
import { PreloadField } from './PreoadField';
import { TextFieldProps } from './types';

interface HintFieldProps extends TextFieldProps, HintInputProps {
  label: string;
  defaultValue?: string;
  hasCounter?: boolean;
  normalize?: (value: string, prevValue: string, content?: any) => string;
  required?: boolean;
  validate?: (value: string) => string | null;
}

export const HintField: React.FC<HintFieldProps> = ({
  name,
  label,
  required,
  minLength,
  maxLength,
  length,
  disabled,
  defaultValue,
  placeholder,
  requiredSelectInDropDown,
  hints,
  rows,
  validate: externalValidate,
  onChange: externalOnChange,
  showHintsOnFocus,
  transform = value => value,
  normalize = value => value,
  hasCounter = false,
  denyCaretReturn = false,
  templateValue,
  onFocus,
}) => {
  const [isFocus, setFocus] = React.useState<boolean>(false);

  const toggleFocus = (isFocus: boolean) => () => setFocus(isFocus);

  const validate = React.useCallback(
    value => {
      const hasValue = isString(value);

      if (required && !hasValue) {
        return translate(validateMessages.fieldRequired);
      }

      if (!hasValue) {
        return null;
      }

      if (maxLength) {
        if (value.length > maxLength) {
          return translate(validateMessages.moreThan(maxLength));
        }
      }

      if (minLength) {
        if (value.length < minLength) {
          return translate(validateMessages.lessThan(minLength));
        }
      }

      if (length) {
        if (value.length !== length) {
          return translate(validateMessages.shouldBe(length));
        }
      }

      return externalValidate && hasValue ? externalValidate(value) : null;
    },
    [required, maxLength, minLength, length, externalValidate],
  );

  const onTextFieldChange = React.useCallback(
    (value, prevValue, content, onChange) => {
      const val = denyCaretReturn ? value.replace(/\n$/, '').replace(/\n+/g, ' ') : value;
      onChange(normalize(val, prevValue));
      if (externalOnChange) externalOnChange(val, content);
    },
    [externalOnChange],
  );

  const { field, form } = useField({ name, defaultValue, validate });

  if (!field && !form) {
    return null;
  }

  let count;

  if (hasCounter && field.value) {
    count = maxLength - (field.value as string).length;
  }

  const isDisabled = disabled || form.disabled || form.progress;

  return (
    <PreloadField isLoading={form.initializing}>
      <FieldInput
        name={name}
        label={label}
        error={field.error}
        warning={field.warning}
        value={field.value || ''}
        isFocus={isFocus}
        isDisabled={isDisabled}
      >
        <HintInput
          name={name}
          disabled={isDisabled}
          value={(field.value as string) || ''}
          templateValue={templateValue}
          hints={hints}
          placeholder={placeholder}
          onChange={(nextValue: string, content: any) =>
            onTextFieldChange(nextValue, field.value, content, field.onChange)
          }
          transform={transform}
          onFocus={e => {
            onFocus?.(e);
            toggleFocus(true);
          }}
          onBlur={() => {
            field.onBlur();
            toggleFocus(false)();
          }}
          showHintsOnFocus={showHintsOnFocus}
          rows={rows}
          requiredSelectInDropDown={requiredSelectInDropDown}
        />
      </FieldInput>
      {isExist(count) && !form.disabled && (
        <div
          className={bem('form-counter')
            .modificator('negative', count < 0)
            .toClassName()}
        >
          {`${translate('front.form.counter-first-field.label')}
                ${count}
                ${translate('front.form.counter-second-field.label')}`}
        </div>
      )}
    </PreloadField>
  );
};
