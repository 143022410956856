import React from 'react';
import { useLocation } from 'react-router';

import { parseUrlParams } from 'api/backend';
import { DynamicField } from 'api/DemandsService/interfaces';
import { OrderState } from 'api/enums';
import { Option } from 'api/Service';
import { useOrder } from 'components/Document/useOrder';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { validateMessages } from 'components/validateMessages';
import { translate } from 'i18n/translator';
import { getDynamicField, getValidatorConstraint, toSafetyFieldValue } from 'pages/Demands/utils';
import { isExist } from 'utils/isData';

export const useCheckRelevanceDeposit = (
  fieldName: string,
  options: Option[],
  dynamicFields: DynamicField[],
) => {
  const { setError, getFieldValue } = useForm();
  const { order } = useOrder();
  const { copyFrom } = parseUrlParams(useLocation().search);

  const fieldValue = getFieldValue(fieldName);
  const isExistFieldValue = isExist(fieldValue);

  React.useEffect(() => {
    const dynamicField = getDynamicField(fieldName, dynamicFields);

    const checkValueConstraint = getValidatorConstraint(dynamicField, 'checkValueInOptions');
    const defaultValue = toSafetyFieldValue(dynamicField.data);

    if (order?.state === OrderState.Draft || !!copyFrom) {
      const isNotRelevantDeposit =
        !isExistFieldValue || options.every(field => `${field.content.id}` !== `${fieldValue}`);

      if (isNotRelevantDeposit) {
        const defaultTextValue = defaultValue.textValue || '';

        const errorText =
          checkValueConstraint.errorText?.replace('${VALUE}', defaultTextValue) ||
          validateMessages.valueIsNotInOptions(defaultTextValue);

        setError(errorText);
      }
    }

    return () => {
      setError(null);
    };
  }, [fieldValue]);

  React.useEffect(() => {
    if (!isExist(options) || options.length === 0) {
      setError(translate('front.new-contract-term-deposit-demand.error-deposit-products.label'));
    }
  }, [options, fieldValue]);
};
