import React from 'react';
import { useSelector } from 'react-redux';

import { selectConstants } from 'store/selectors';

export const useFileExtensions = () => {
  const acceptedFiles = useSelector(selectConstants()).FILE_TYPE;

  return React.useMemo(
    () => ({
      extensions: acceptedFiles?.split(','),
    }),
    [acceptedFiles],
  );
};
