import * as React from 'react';
import { TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { History } from 'api/interfaces';
import { SortOrder } from 'api/Service';
import { OrderContext } from 'components/Document/OrderContext';
import { useOrderHistory } from 'components/Document/useOrderHistory';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import * as format from 'components/utils/format';

const columns: TableColumnProps<History>[] = [
  {
    label: 'front.payment-history-page.table.action-time.label',
    render: row => (
      <DataColumn title={format.date(row.actionTime)} subTitle={format.fullTime(row.actionTime)} />
    ),
    sort: 'actionTime',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.payment-history-page.table.user.label',
    render: row => <DataColumn title={row.userName} />,
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.payment-history-page.table.status.label',
    render: row => (
      <DataColumn>
        <span className="order-status">{row.orderState}</span>
      </DataColumn>
    ),
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.payment-history-page.table.message.label',
    render: row => (
      <DataColumn>
        <span className="history-page__description">{row.description}</span>
      </DataColumn>
    ),
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
];

export const PaymentHistoryPage: React.FC = () => {
  const { order, setHistory } = React.useContext(OrderContext);
  const { fetchHistory } = useOrderHistory(order, setHistory);

  return (
    <DataTable
      name={TableNamesEnum.InternalPaymentHistory}
      columns={columns}
      fetchRows={fetchHistory}
      defaultSort="actionTime"
      defaultOrder={SortOrder.Desc}
    />
  );
};
