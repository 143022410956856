import React from 'react';
import { GridResponse, TableColumnProps } from 'react-data-table';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { OrderType } from 'api/DemandsService/enums';
import { DebtUserInterface } from 'api/UserService';
import { Button } from 'components/buttons/Button';
import { ModalEnum } from 'components/modals/globalModalCollection/modalEnum';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { bem } from 'components/utils/bem';
import { getAmountNumberFormatWithComma } from 'components/utils/format';
import { translate } from 'i18n/translator';
import { goto } from 'navigations/navigate';
import { pages, PAYMENTS } from 'navigations/pages';
import { NEW } from 'navigations/routes';
import { selectCollectionModal } from 'store/selectors';

import { DebtQueryInterface } from './interface';

import './styles.scss';

const columns: TableColumnProps<DebtUserInterface>[] = [
  {
    label: 'front.debt-modal.organization-and-contract-number.label',
    render: (row: DebtUserInterface) => {
      return (
        <DataColumn
          title={row.customerName}
          subTitle={`${translate('front.debt-modal.debt-contract.label')}${
            row.contractNumber
          } ${translate('front.debt-modal.debt-from.label')} ${row.openedContractDate}`}
          subTitleClassName="sub-title"
        />
      );
    },
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.debt-modal.debt-accounting-account.label',
    render: (row: DebtUserInterface) => <DataColumn title={row.accountNumber} />,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.debt-modal.debt-amount.label',
    render: (row: DebtUserInterface) => (
      <DataColumn
        title={`${getAmountNumberFormatWithComma(row.amount)} ${row.currency}`}
        titleClassName={bem('status').modificator('color', 'red').toClassName()}
      />
    ),
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.menu.work-protocol.action.label',
    render: (row: DebtUserInterface) => (
      <Button
        onClick={async () =>
          goto(
            pages.internalPayments.tabs.details<DebtQueryInterface>(NEW, {
              receiverAccount: `${row.accountId}`,
              customerId: row.customerId,
              type: OrderType.InternalTransfer,
              amount: row.amount,
              isDebt: true,
            }),
          )
        }
      >
        {translate('front.debt-modal.debt-to-pay.label')}
      </Button>
    ),
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

export const DebtPage: React.FC<{ debtUser: GridResponse<DebtUserInterface> }> = React.memo(
  ({ debtUser }) => {
    const { modal } = useSelector(selectCollectionModal(ModalEnum.DebtModal));
    const { pathname } = useLocation();

    const prevDebt = React.useRef(null);

    const fetchRows = async () => {
      if (pathname.includes(PAYMENTS)) {
        if (prevDebt.current) {
          const data = await api.user.getDebtUser();

          if (data.rows.length === 0) {
            modal.close();
          }

          return data;
        }

        prevDebt.current = debtUser;
        return debtUser;
      }

      return debtUser;
    };

    return (
      <>
        <h2 className="header-message">{translate('front.dear-customer.label')}</h2>
        <h2 className="header-message">{translate('front.debt-modal.debt-overdue.label')}</h2>
        <DataTable
          name={TableNamesEnum.DebtUsersPage}
          columns={columns}
          fetchRows={fetchRows}
          hidePagination
        />
        <h6 className="footer-message">{translate('front.debt-modal.warning.label')}</h6>
      </>
    );
  },
);
