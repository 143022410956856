import * as React from 'react';
import { Col, Row } from 'react-grid';

import { api } from 'api';
import { IMPORT_TYPES } from 'api/Service';
import { Button } from 'components/buttons/Button';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { DateRangeField } from 'components/forms/inputs/DateRangeField';
import { TextField } from 'components/forms/inputs/TextField';
import { withForm } from 'components/forms/withForm';
import { CreateButton } from 'components/Table/CreateButton';
import { useFilter } from 'components/Table/DataTable';
import { ToggleFilterButton } from 'components/Table/ToggleFilterButton/ToggleFilterButton';
import { FilterTabBar } from 'components/tabs/FilterTabBar';
import { useStoragePeriod } from 'hooks/useStoragePeriod';
import { translate } from 'i18n/translator';
import { deniedPrivilegy, DisabledBlock } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages, SALARIES } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';

const SalaryFilterForm: React.FC = () => {
  const { extraFields, updateFilter, setPagination } = useFilter({
    extraFields: { orderStates: 'all' },
  });

  const { datePeriodFrom, datePeriodTo, onApplyPeriodChanges, clearPeriod } =
    useStoragePeriod(SALARIES);

  return (
    <div>
      <FilterTabBar
        onChange={orderStates => updateFilter({ orderStates })}
        value={extraFields.orderStates}
        options={api.payments.getPaymentOptionStatuses()}
        setPagination={setPagination}
      />
      <Row>
        <Col lg={9} md={8}>
          <DisabledBlock privileges={[Privileges.salarySheetEdit]}>
            <CreateButton
              title="front.handbooks-table.create.label"
              onClick={() => {
                goto(pages.salary.tabs.details('new'));
              }}
              disabled={deniedPrivilegy([Privileges.salarySheetEdit])}
            />
            <Button
              color="secondary"
              onClick={() => {
                goto(pages.import, { type: IMPORT_TYPES.SALARY });
              }}
            >
              {translate('front.demands.salary.import-button.label')}
            </Button>
          </DisabledBlock>
        </Col>
        <Col lg={3} md={4}>
          <ToggleFilterButton
            onApplyFilter={onApplyPeriodChanges}
            onCloseFilter={onApplyPeriodChanges}
            onResetFilter={clearPeriod}
          >
            <Row>
              <Col md={4}>
                <TextField label="front.demands-table.number.label" name="orderNumber" />
              </Col>
              <Col md={4}>
                <AmountField
                  label="front.working-documents-filter-form.amount-from.label"
                  name="amountFrom"
                />
              </Col>
              <Col md={4}>
                <AmountField
                  label="front.working-documents-filter-form.amount-to.label"
                  name="amountTo"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <DateRangeField
                  label="front.working-documents-table.date-range.label"
                  nameFrom="dateFrom"
                  nameTo="dateTo"
                  defaultFrom={datePeriodFrom}
                  defaultTo={datePeriodTo}
                  clearable
                />
              </Col>
              <Col md={6}>
                <CustomerSelect
                  label="front.internal-payment-page.order-payer-name.label"
                  name="customerIds"
                  multi
                />
              </Col>
            </Row>
          </ToggleFilterButton>
        </Col>
      </Row>
      <hr />
    </div>
  );
};

export const SalaryPageFilter = withForm(SalaryFilterForm);
