import { createCol } from './createCol';
import { createContainer } from './createContainer';
import { createRow } from './createRow';
import { MakeFnArg, SizeConfig } from './types';

export const make = <S extends SizeConfig>(arg: MakeFnArg<S>) => {
  return {
    Container: createContainer(arg),
    Col: createCol<S>(),
    Row: createRow(arg),
  };
};
