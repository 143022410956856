import * as React from 'react';
import { Col, Row } from 'react-grid';

import { CustomerSelect } from 'components/Document/CustomerSelect';
import { withForm } from 'components/forms/withForm';
import { CreateButton } from 'components/Table/CreateButton';
import { useFilter } from 'components/Table/DataTable';
import { ToggleFilterButton } from 'components/Table/ToggleFilterButton/ToggleFilterButton';
import { deniedPrivilegy } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';

const NotificationsForm: React.FC = () => {
  useFilter();

  return (
    <Row>
      <Col lg={3} sm={6}>
        <CreateButton
          onClick={() => goto(pages.editNotification('new'))}
          title="front.notifications.create.label"
          size="lg"
          disabled={deniedPrivilegy([Privileges.contactNotificationEdit])}
        />
      </Col>
      <Col lg={9} sm={6}>
        <ToggleFilterButton>
          <Row>
            <Col md={6}>
              <CustomerSelect
                name="customerId"
                label="front.notifications.filter.customer-id.label"
              />
            </Col>
          </Row>
        </ToggleFilterButton>
      </Col>
    </Row>
  );
};

export const NotificationsFilter = withForm(NotificationsForm);
