import React from 'react';
import { useDataTable } from 'react-data-table';
import { Col, Container, Row } from 'react-grid';

import classNames from 'classnames';

import { Button } from 'components/buttons/Button';
import { DefaultForm } from 'components/forms/formParts';
import { FormContext } from 'components/forms/ValidatingForm/FormContext';
import { Close, Filter as FilterIcon } from 'components/icons';
import { CreatePortal } from 'components/portal/CreatePortal';
import { translate } from 'i18n/translator';
import { noop } from 'utils/noop';

import './toggle-filter-button.scss';

interface Props {
  children?: React.ReactNode;
  className?: string;
  count?: number;
  disabled?: boolean;
  fetchOnApply?: boolean;
  maxWidth?: number;

  onApplyFilter?(event?: any): void;

  onCloseFilter?(event?: any): void;

  onResetFilter?: () => void;

  smallMode?: boolean;
}

interface Dimensions {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
  x: number;
  y: number;
}

export const ToggleFilterButton = (props: Props) => {
  const {
    children,
    className,
    smallMode,
    onResetFilter = () => null,
    onApplyFilter = noop,
    onCloseFilter = noop,
    maxWidth = 730,
    fetchOnApply = true,
    disabled = false,
  } = props;

  const [dimensions, setDimensions] = React.useState<Dimensions>();
  const [style, setStyle] = React.useState<Obj>();
  const [isOpen, setIsOpen] = React.useState<boolean>();

  const el = React.useRef<HTMLSpanElement>();

  const { applyFilter, resetFilter } = useToggleFilterButton();

  const onClickResetFilter = () => {
    onResetFilter();
    return resetFilter();
  };
  const onToggle = (e?: Event) => {
    e && e.preventDefault();
    calculateModalPosition();
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  const calculateModalPosition = () => {
    const newStyle = { maxWidth } as any;
    let newDimensions = {} as Dimensions;
    if (el.current) {
      newDimensions = el.current.getBoundingClientRect() as Dimensions;
      newStyle.left = newDimensions.left - maxWidth + newDimensions.width;
      if (newStyle.left < 0) {
        newStyle.left = 24;
        newStyle.right = 24;
      }
      newStyle.top = window.pageYOffset + newDimensions.top + newDimensions.height - 4;
    }
    setStyle(newStyle);
    setDimensions(newDimensions);
  };

  const handleApply = async (e: Event) => {
    e.preventDefault();
    if (fetchOnApply) {
      try {
        await applyFilter(e);
        onApplyFilter();
        onToggle();
        // eslint-disable-next-line no-empty
      } catch (error) {}
    } else {
      onToggle();
      onApplyFilter();
    }
  };

  const handleClose = async (e: Event) => {
    e.preventDefault();
    onCloseFilter();
    onToggle();
  };

  return (
    <div className={classNames('toggle-block', className)}>
      <div className="toggle-block__btn">
        <span ref={el} style={{ display: 'inline-block' }}>
          <Button
            onClick={onToggle}
            color="secondary"
            className={classNames('toggle-block__btn_filter', {
              hidden: isOpen,
            })}
          >
            <span className="btn-icon">
              <FilterIcon />
            </span>
            {!smallMode && (
              <span className="btn-title">{translate('front.table.filter-button.label')}</span>
            )}
          </Button>
        </span>
      </div>

      <CreatePortal>
        <div className={classNames('toggle-block__popup', { hidden: !isOpen })} style={style}>
          <Button
            onClick={handleClose}
            color="primary"
            className="toggle-block__btn_close"
            style={{ minWidth: dimensions ? dimensions.width : 0 }}
          >
            <span className="btn-title">{translate('front.table.filter-header-close.label')}</span>
            <span className="btn-icon">{<Close />}</span>
          </Button>
          <Row>
            <DefaultForm className="filter-form">
              <Col md={12}>
                <Row>
                  <Col className="toggle-block__popup__header">
                    <span className="toggle-block__popup__header_title">
                      {translate('front.table.filter-header.label')}
                    </span>
                  </Col>
                </Row>
                <Container>{children}</Container>
              </Col>
              <Col>
                <div className="toggle-block__popup__footer">
                  <Button
                    onClick={handleApply}
                    color="primary"
                    size="sm"
                    type="submit"
                    className="toggle-block__popup__footer_btn"
                    disabled={disabled}
                  >
                    {translate('front.table.filter-footer-apply.label')}
                  </Button>
                  <Button
                    onClick={onClickResetFilter}
                    color="secondary"
                    size="sm"
                    type="button"
                    className="toggle-block__popup__footer_btn"
                    disabled={disabled}
                  >
                    {translate('front.table.filter-footer-clear.label')}
                  </Button>
                </div>
              </Col>
            </DefaultForm>
          </Row>
        </div>
      </CreatePortal>
      <div className={classNames({ 'fake-background': isOpen })} />
    </div>
  );
};

const useToggleFilterButton = () => {
  const { handleSubmit, resetData } = React.useContext(FormContext);
  const {
    gridRequest: { updateGridRequest },
    filter: { updateFilter },
  } = useDataTable();

  const applyFilter = (e: Event) => {
    updateGridRequest({ page: 0 });
    handleSubmit(updateFilter, e);
  };

  return { applyFilter, resetFilter: resetData };
};
