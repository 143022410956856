import React from 'react';

import cls from 'classnames';

import './styles.scss';

type Value = number | string;

export interface PlaceholderProps {
  disableAnimation?: boolean;
  display?: string;
  height?: Value;
  marginBottom?: Value;
  marginLeft?: Value;
  marginRight?: Value;
  marginTop?: Value;
  width?: Value;
}

export const Placeholder = (props: PlaceholderProps) => {
  const { disableAnimation, ...styles } = props;

  return (
    <div
      className={cls('placeholder', {
        ['placeholder_animated']: !disableAnimation,
      })}
      style={styles}
    />
  );
};
