import * as React from 'react';
import { TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { CardView, ProductStatus } from 'api/ProductsService';
import { GridRequest, Option, SortOrder, TotalInterface } from 'api/Service';
import * as Icon from 'components/icons';
import { Page } from 'components/layout/Page/Page';
import { Status, StatusColor } from 'components/layout/Status';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import * as format from 'components/utils/format';
import config from 'config';
import { translate } from 'i18n/translator';
import { isActionAllowed } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { NEW } from 'navigations/routes';

import { CardDetails } from './CardDetails';
import { CardFilterFields, CardsFilter } from './CardsFilter';

const { masterCardLogo, visaLogo } = config.page.cards;

enum CardActions {
  STATEMENT = 'statement',
  HISTORY = 'history',
  LIMITS = 'cardLimits',
  TRANSFER = 'transfer',
  BLOCK = 'block',
  DOMESTIC = 'domesticTransferOrder',
}

export enum CardStatusTranslation {
  Active = 'front.cards-page.card-active.label',
  Blocked = 'front.cards-page.card-blocked.label',
  New = 'front.cards-page.card-new.label',
  Closed = 'front.cards-page.card-closed.label',
}

//TODO delete when will be implemented UK-4053
const undefinedValue = 'Undefined';

const getStatusColor = (status: ProductStatus): StatusColor => {
  switch (status) {
    case 'Active':
      return 'green';
    case 'Blocked':
      return 'red';
    default:
      return 'grey';
  }
};

const tabLabel = {
  all: 'front.product-accounts-filter.statuses-all.label',
  [ProductStatus.Active]: 'front.product-accounts-filter.statuses-opened.label',
  [ProductStatus.Closed]: 'front.product-accounts-filter.statuses-closed.label',
  // [AccountStatus.Blocked]: translate('front.product-accounts-filter.statuses-blocked.label'),
};

const getStatusesOptions = (total: TotalInterface): Array<Option> =>
  Object.entries(tabLabel).map(([value, label]) => ({
    value,
    label: (
      <span className="cards-filter__tab">
        {translate(label)}
        <span className="cards-filter__tab__badge">
          {value === 'all'
            ? Object.values(total.status).reduce((a, b) => a + b, 0)
            : total.status[value] || 0}
        </span>
      </span>
    ),
  }));

const columns: TableColumnProps<CardView>[] = [
  {
    label: 'card-logo',
    render: row => (
      <DataColumn>
        <img src={row.cardNumber.startsWith('5') ? masterCardLogo : visaLogo} alt="card" />
      </DataColumn>
    ),
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'card-name-edit',
    render: row => (
      <DataColumn>
        {isActionAllowed([Privileges.productCardEdit], row.customerId) && (
          <span
            onClick={e => {
              e.stopPropagation();
              goto(pages.card(row.id));
            }}
            className="card-name-edit"
          >
            <Icon.Edit />
          </span>
        )}
        <span className="card-name">{row.cardName}</span>
      </DataColumn>
    ),
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'card-organization',
    render: row => <DataColumn title={row.organization} />,
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'card-number',
    render: row => <DataColumn title={row.cardNumber} />,
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'card-close-date',
    render: row => <DataColumn title={row.finishDate} />,
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'card-status',
    render: row =>
      row.status !== undefinedValue && (
        <DataColumn>
          <Status color={getStatusColor(row.status as ProductStatus)}>
            {row.statusTranslation}
          </Status>
        </DataColumn>
      ),
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'card-balance',
    render: row => {
      const balance = format.getAmount(Number(row.balance));
      return <DataColumn titleClassName="card-amount" title={`${balance} ${row.currency}`} />;
    },
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    renderMenu: item =>
      [
        {
          label: 'front.menu.statements.label',
          onClick: () => goto(pages.statements({ accountId: item.accountId })),
          isVisible:
            filterByAction(item, CardActions.STATEMENT) &&
            isActionAllowed([Privileges.financeStatementView], item.customerId),
        },
        {
          label: 'front.product-accounts-page.menu-history.label',
          onClick: () => goto(pages.transactionsHistory({ accountId: item.accountId })),
          isVisible:
            filterByAction(item, CardActions.HISTORY) &&
            isActionAllowed([Privileges.financeTransactionHistoryView], item.customerId),
        },
        {
          label: 'front.cards-page.limits.label',
          onClick: () => goto(pages.limits({ cardId: item.id, customerId: item.customerId })),
          isVisible:
            filterByAction(item, CardActions.LIMITS) &&
            isActionAllowed([Privileges.productCardLimitView], item.customerId),
        },
        {
          label: 'front.product-accounts-page.menu-payment.label',
          onClick: () =>
            goto(
              pages.internalPayments.tabs.details(NEW, {
                customerId: item.customerId,
                payerAccount: `${item.accountId}`,
              }),
            ),
          isVisible:
            filterByAction(item, CardActions.TRANSFER) &&
            isActionAllowed(
              [Privileges.paymentInternalEdit, Privileges.paymentDomesticEdit],
              item.customerId,
            ),
        },
        {
          label: 'front.cards-page.block.label',
          onClick: null,
          isVisible: filterByAction(item, CardActions.BLOCK),
        },
      ].map(row => ((row.label = translate(row.label)), row)),
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

const filterByAction = (row: CardView, action: string) =>
  row.actions.some(item => item.action === action);

const fetchCards = async (request: GridRequest & CardFilterFields) => {
  const status = request?.status !== 'all' ? request.status : undefined;
  const { cardType, ...otherRequest } = request;
  const { rows, total } = await api.products.getCards({
    ...otherRequest,
    status,
    cardTypes: [cardType],
    sort: 'displayOrder',
    size: 0,
  });
  return {
    rows,
    total,
    tabOptions: getStatusesOptions(total),
  };
};

export const CardsPage: React.FC = () => (
  <div className="cards-page">
    <Page title={translate('front.cards-page.title.label')}>
      <DataTable
        name={TableNamesEnum.CardsTable}
        columns={columns}
        fetchRows={fetchCards}
        defaultSort="cardNumber"
        defaultOrder={SortOrder.Desc}
        filterComponent={CardsFilter}
        expandRowComponent={CardDetails}
        hidePagination
        hideHeader
      />
    </Page>
  </div>
);
