import React from 'react';
import { Col, Container, Row } from 'react-grid';

import { ConfirmedProfile, DataFromWebBank } from 'api/interfaces';
import { Option } from 'api/Service';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { RadioField } from 'components/forms/inputs/RadioField';
import { TextAreaField } from 'components/forms/inputs/TextAreaField';
import { TextField } from 'components/forms/inputs/TextField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { PageHeader } from 'components/layout/Page/PageHeader';
import config from 'config';
import { translate } from 'i18n/translator';
import { IdentificationProps } from 'pages/Registration/ConfirmationIdentificationPage/interfaces';

import { ConfirmationIdentificationFormFields, CorrectType, ProfileIdKeys } from '../enums';

interface Fields {
  companyAddress: string;
  companyFullName: string;
  companyTaxCode: string;
  fullName: string;
  passport: string;
  userInfo: string;
  userTaxCode: string;
}

interface Payload {
  confirmedProfile: ConfirmedProfile;
  dataHash: string;
  isIdCard: boolean;
  isLegalEntity: boolean;
  profileId: string;
}

const correctDateOptions: Option[] = [
  {
    value: CorrectType.YES,
    label: 'front.conformation-identification-data-correct-yes.label',
  },
  {
    value: CorrectType.NO,
    label: 'front.conformation-identification-data-correct-no.label',
  },
];

const ConfirmationIdentificationForm = ({
  resolve,
  dataProfile,
  saveAfterConfirmation,
  certificateType,
}: IdentificationProps) => {
  const {
    progress,
    error,
    handleSubmit,
    getFieldValue,
    payload: { isIdCard, isLegalEntity },
  } = useForm<Fields, Payload>(async ({ setPayload, setFields }) => {
    const {
      profileId,
      dataHash,
      confirmedProfile,
      countryName,
      cyrillicOldPassportNumber,
      customerTaxCode,
    } = dataProfile as DataFromWebBank;

    const isLegalEntity =
      profileId === ProfileIdKeys.ClientLegalPerson ||
      profileId === ProfileIdKeys.FopOrSelfEmployedPerson ||
      profileId === ProfileIdKeys.ClientLegalPersonSeal;

    const isClientLegalPerson = profileId === ProfileIdKeys.ClientLegalPerson;

    const addressLegalPerson = isClientLegalPerson
      ? `, ${confirmedProfile.POSTAL_CODE} ${confirmedProfile.STREET_ADDRESS}`
      : '';

    const stateOfProvinceName = confirmedProfile.STATE_OR_PROVINCE_NAME
      ? `, ${confirmedProfile.STATE_OR_PROVINCE_NAME}`
      : '';

    const fields = {
      ...confirmedProfile,
      [ConfirmationIdentificationFormFields.Edrpou]: customerTaxCode,
      [ConfirmationIdentificationFormFields.Passport]: cyrillicOldPassportNumber,
      [ConfirmationIdentificationFormFields.CompanyAddress]: isLegalEntity
        ? `${countryName}, ${confirmedProfile.LOCALITY_NAME}${stateOfProvinceName}${addressLegalPerson}`
        : null,
      [ConfirmationIdentificationFormFields.RegistrationAddress]: isLegalEntity
        ? null
        : `${countryName}, ${confirmedProfile.LOCALITY_NAME}, ${confirmedProfile.STATE_OR_PROVINCE_NAME}`,
    };

    setFields(fields);
    setPayload({
      isIdCard: !confirmedProfile.PASSPORT,
      isLegalEntity,
      dataHash,
      profileId,
      confirmedProfile,
    });
  });

  const correctDateValue = getFieldValue(ConfirmationIdentificationFormFields.CorrectDate);
  const confirmValue = getFieldValue(ConfirmationIdentificationFormFields.Confirm);

  const isDisable = correctDateValue === CorrectType.NO || !confirmValue || !!error;

  const handleConfirm = async () =>
    saveAfterConfirmation
      ? await saveAfterConfirmation({ dataHash: dataProfile.dataHash, certificateType })
      : resolve(true);

  return (
    <>
      <PageHeader.Title>{translate('front.conformation-identification.label')}</PageHeader.Title>
      <DefaultForm disabled={progress}>
        <FormError>{error}</FormError>
        <Container>
          {isLegalEntity && (
            <>
              <Row>
                <Col sm={12}>
                  <TextField
                    name={ConfirmationIdentificationFormFields.OrganizationName}
                    label="front.conformation-identification-name.label"
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <TextField
                    name={ConfirmationIdentificationFormFields.Edrpou}
                    label="front.conformation-identification-code-edrpou.label"
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <TextField
                    name={ConfirmationIdentificationFormFields.CompanyAddress}
                    label="front.conformation-identification-actually-address-location.label"
                    disabled
                  />
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col sm={12}>
              <p></p>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <h3>{translate('front.conformation-identification-signatory.label')}</h3>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <TextField
                name={ConfirmationIdentificationFormFields.CommonName}
                label="front.conformation-identification-customer-full-name.label"
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <TextField
                name={ConfirmationIdentificationFormFields.Drfou}
                label="front.conformation-identification-tax-code.label"
                disabled
              />
            </Col>
            <Col sm={6}>
              {isIdCard ? (
                <TextField
                  name={ConfirmationIdentificationFormFields.Unzr}
                  label="front.conformation-identification-unzre-code.label"
                  disabled
                />
              ) : (
                <TextField
                  name={ConfirmationIdentificationFormFields.Passport}
                  label="front.conformation-identification-passport.label"
                  disabled
                />
              )}
            </Col>
          </Row>
          {isLegalEntity ? (
            <Row>
              <Col sm={12}>
                <TextAreaField
                  name={ConfirmationIdentificationFormFields.Title}
                  label="front.conformation-identification-position.label"
                  hasAutoGrowing
                  rows={1}
                  disabled
                />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col sm={12}>
                <TextField
                  name={ConfirmationIdentificationFormFields.RegistrationAddress}
                  label="front.conformation-identification-address.label"
                  disabled
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col sm={12}>
              <p></p>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <h4>{translate('front.conformation-identification-data-correct.label')}</h4>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <RadioField
                name={ConfirmationIdentificationFormFields.CorrectDate}
                options={correctDateOptions}
                defaultValue={CorrectType.NO}
                disabled={!!error}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <CheckboxField
                name={ConfirmationIdentificationFormFields.Confirm}
                label="front.conformation-identification-checkbox.label"
                linkLabel="front.conformation-identification-checkbox-link.label"
                link={config.links.instructionsUGB}
                disabled={!!error}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <p></p>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <p>{translate('front.conformation-identification-warning.label')}</p>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Button
                onClick={e => handleSubmit(handleConfirm, e)}
                progress={progress}
                disabled={isDisable}
                size="md"
                type="submit"
                block
              >
                {translate('front.conformation-identification-confirm.label')}
              </Button>
            </Col>
            <Col sm={4}>
              <Button
                onClick={() => resolve(false)}
                color="secondary"
                size="md"
                type="submit"
                block
              >
                {translate('front.conformation-identification-no-confirm.label')}
              </Button>
            </Col>
          </Row>
        </Container>
      </DefaultForm>
    </>
  );
};

export const ConfirmationIdentificationPage = withForm(ConfirmationIdentificationForm);
