import * as React from 'react';

import { ExportFormatEnum } from 'components/ExportDropdown/enum';

import {
  ExportOneAccountInOneFilePDF,
  ExportOneAccountInOneFileXLS_PDF,
  ExportOneAccountOneDateInOneFilePDF,
  ExportOneAccountOneDateInOneFileXLS_PDF,
  ExportOneFilePDF,
  ExportOneFileXLS_PDF,
} from '../icons';
import { ExportWayOption, ExportWays, IconOptionType } from './types';

const IconTypeOptions: Partial<Record<ExportFormatEnum, IconOptionType>> = {
  [ExportFormatEnum.PDF]: {
    [ExportWays.OneFile]: <ExportOneFilePDF />,
    [ExportWays.OneAccountInOneFile]: <ExportOneAccountInOneFilePDF />,
    [ExportWays.OneAccountOneDateInOneFile]: <ExportOneAccountOneDateInOneFilePDF />,
  },
  [ExportFormatEnum.XLS_PDF]: {
    [ExportWays.OneFile]: <ExportOneFileXLS_PDF />,
    [ExportWays.OneAccountInOneFile]: <ExportOneAccountInOneFileXLS_PDF />,
    [ExportWays.OneAccountOneDateInOneFile]: <ExportOneAccountOneDateInOneFileXLS_PDF />,
  },
};

export const getExportWayOptions = (
  exportType: ExportFormatEnum,
): Partial<Record<ExportWays, ExportWayOption>> => ({
  [ExportWays.OneFile]: {
    type: ExportWays.OneFile,
    label: 'front.account-statements-export-modal.one-file.label',
    icon: IconTypeOptions[exportType][ExportWays.OneFile],
  },
  [ExportWays.OneAccountInOneFile]: {
    type: ExportWays.OneAccountInOneFile,
    label: 'front.account-statements-export-modal.one-account-in-one-file.label',
    icon: IconTypeOptions[exportType][ExportWays.OneAccountInOneFile],
  },
  [ExportWays.OneAccountOneDateInOneFile]: {
    type: ExportWays.OneAccountOneDateInOneFile,
    label: 'front.account-statements-export-modal.one-account-one-date-in-one-file.label',
    icon: IconTypeOptions[exportType][ExportWays.OneAccountOneDateInOneFile],
  },
});
