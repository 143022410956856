import * as React from 'react';
import { Col, Row } from 'react-grid';

import { api } from 'api';
import { OrderType } from 'api/DemandsService/enums';
import { TransactionPayment } from 'api/interfaces';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { FetchOrderCb, withOrder } from 'components/Document/withOrder';
import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { HintField } from 'components/forms/inputs/HintField';
import { TextField } from 'components/forms/inputs/TextField';
import { translate } from 'i18n/translator';
import { TransactionHistory } from 'pages/TransactionsHistory/enums';

interface Fields {
  [TransactionHistory.PayerName]: string;
  [TransactionHistory.PayerIban]: string;
  [TransactionHistory.PayerBin]: string;
  [TransactionHistory.PayerBankBik]: string;
  [TransactionHistory.ReceiverName]: string;
  [TransactionHistory.ReceiverIban]: string;
  [TransactionHistory.ReceiverBin]: string;
  [TransactionHistory.ReceiverBankBik]: string;
  [TransactionHistory.Amount]: number;
  amountNational: number;
  [TransactionHistory.Purpose]: string;
}

interface Payload {
  currency: string;
}

const makeFields = (
  doc: Omit<
    TransactionPayment,
    | 'date'
    | 'executionTime'
    | 'documentNumber'
    | 'customerId'
    | 'id'
    | 'valueDate'
    | 'type'
    | 'actions'
  >,
): Fields => {
  const {
    payer: payerName,
    payerIban,
    payerBankBik,
    payerBin,
    receiver: receiverName,
    receiverIban,
    receiverBankBik,
    receiverBin,
    amount,
    amountNational,
    paymentPurpose: purpose,
  } = doc;

  return {
    payerName,
    payerIban,
    payerBankBik,
    payerBin,
    receiverName,
    receiverIban,
    receiverBankBik,
    receiverBin,
    purpose,
    amount: amount ? Math.abs(amount) : Math.abs(amountNational),
    amountNational,
  };
};

const fetchOrder: FetchOrderCb<Fields, Payload> = async ({ routeParams }, { setPayload }) => {
  const {
    id,
    date,
    customerId,
    type,
    actions,
    documentNumber,
    valueDate,
    executionTime,
    ...otherDoc
  } = await api.payments.getTransactionHistoryById(routeParams.id);

  setPayload({ currency: otherDoc.currency });

  return {
    customerId,
    id,
    date,
    valueDate,
    executionTime,
    label: translate('front.transaction-history-page.title.label'),
    actions: [],
    type: type as OrderType,
    number: documentNumber,
    state: null,
    stateTranslate: null,
    detailFields: makeFields(otherDoc),
  };
};

const TransactionHistoryOrder: React.FC = () => {
  const { payload, form } = useOrderDetails();
  form.setDisabled(true);

  return (
    <DefaultForm>
      <h3>{translate('front.internal-payment-page.payer.label')}</h3>
      <CustomerSelect
        name={TransactionHistory.PayerName}
        label="front.internal-payment-page.order-payer-name.label"
        disabled
        required
      />
      <TextField
        name={TransactionHistory.PayerIban}
        label="front.internal-payment-page.payer-account.label"
        disabled
      />
      <Row>
        <Col md={4}>
          <TextField
            name={TransactionHistory.PayerBin}
            label="front.transaction-history-page.payer-bin.label"
            disabled
          />
        </Col>
        <Col md={8}>
          <TextField
            name={TransactionHistory.PayerBankBik}
            label="front.transaction-history-page.payer-bank-bik.label"
            disabled
          />
        </Col>
      </Row>

      <h3 className="m-t-24">{translate('front.internal-payment-page.receiver.label')}</h3>
      <TextField
        name={TransactionHistory.ReceiverName}
        label="front.internal-payment-page.organization-name.label"
        disabled
      />
      <TextField
        name={TransactionHistory.ReceiverIban}
        label="front.internal-payment-page.receiver-account.label"
        disabled
      />
      <Row>
        <Col md={4}>
          <TextField
            name={TransactionHistory.ReceiverBin}
            label="front.internal-payment-page.receiver-code.label"
            disabled
          />
        </Col>
        <Col md={8}>
          <TextField
            name={TransactionHistory.ReceiverBankBik}
            label="front.transaction-history-page.payer-bank-bik.label"
            disabled
          />
        </Col>
      </Row>
      <AmountField
        name={TransactionHistory.Amount}
        label={`${translate('front.internal-payment-page.order-amount.label')} ${
          payload.currency || ''
        }`}
        hasMoneyFormat
        disabled
      />
      <HintField
        name={TransactionHistory.Purpose}
        label="front.internal-payment-page.order-purpose.label"
        minLength={7}
        maxLength={160}
        rows={3}
        disabled
        forbiddenSpecialCharacters
        denyCaretReturn
      />
    </DefaultForm>
  );
};

export const TransactionHistoryPage = withOrder({
  fetchOrder,
  allowDetails: true,
  allowExecutionDate: true,
})(TransactionHistoryOrder);
