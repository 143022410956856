import * as React from 'react';

import { Order, OrderAction } from 'api/OrderService';
import { Button, Props } from 'components/buttons/Button';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { Arrow, ArrowDirection } from 'components/icons';
import { confirmationModal } from 'components/modals/ConfirmModal';
import { Popover, TPlacement } from 'components/popover/Popover';
import { translate } from 'i18n/translator';
import { goBack } from 'navigations/navigate';
import { handleError } from 'utils/handleError';
import { isExistOrder } from 'utils/isData';

import 'components/buttons/action-button.scss';

interface OrderActionButtonProps extends Props {
  actions: OrderAction[];
  onSign: (e: any) => void;
  order: Order;
  id?: string;
  placement?: TPlacement;
  title?: string;
}

export const OrderActionButton = (props: OrderActionButtonProps) => {
  const {
    order,
    actions,
    onSign,
    id = 'action-button-popover',
    title = 'front.from.action-button.label',
    placement = 'top',
    ...rest
  } = props;
  const { setProgress, setError } = useForm();

  if (!isExistOrder(order) || !order.id || !actions.length) {
    return null;
  }

  const onClickAction = async (
    action: OrderAction,
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) => {
    const { onClick, confirmMessage, closable } = action;
    if (action.type === 'signButton') {
      return onSign(e);
    }
    if (confirmMessage && !(await confirmationModal(confirmMessage))) {
      return;
    }
    try {
      setProgress(true);
      await onClick(order);
      if (closable) {
        goBack(); // TODO fix goback
      }
    } catch (e: any) {
      handleError(e, setError);
    } finally {
      setProgress(false);
    }
  };

  return (
    <>
      <Popover
        className="action-button-popover"
        placement={placement}
        label={isOpen => (
          <Button {...rest} className="action-button-popover__button">
            <span className="action-button-popover__button__label">{translate(title)}</span>
            <span className="action-button-popover__button__icon">
              <Arrow direction={isOpen ? ArrowDirection.top : ArrowDirection.bottom} />
            </span>
          </Button>
        )}
        id={id || 'action-button-popover'}
      >
        <ul className="action-button-popover__menu">
          {actions?.map((action, key) => (
            <li
              className="action-button-popover__menu__item"
              key={`action-button-${key}`}
              onClick={async e => {
                await onClickAction(action, e);
              }}
            >
              {translate(action.label)}
            </li>
          ))}
        </ul>
      </Popover>
    </>
  );
};
