import cryptoJs from 'crypto-js';

/**
 * EncryptUtils for provide login encrypt and password hashing.
 *
 * crypto-js library with version 3.1.9-1 is required:
 * Installation(if not exist):
 *
 * npm install crypto-js --save
 *
 * Usage:
 * Including all libraries, for access to extra methods:
 *
 * const CRYPTO_JS = require('crypto-js');
 *
 * Use CRYPTO_JS.neededMethod() after that.
 *
 * @Author Jack <e.kobets>
 */
export default class Encrypt {
  /**
   * Encrypts login by following algorithm.
   * @param login login for encrypt
   */
  public static encryptLogin(login: string): string {
    if (!login) {
      console.error('Login cannot be empty, null or undefined!');
    }

    const key = cryptoJs.lib.WordArray.random(32);

    const encryptedData = cryptoJs.AES.encrypt(login, key, {
      mode: cryptoJs.mode.ECB,
      padding: cryptoJs.pad.Pkcs7,
    });

    const hashedDataHex = cryptoJs.SHA256(encryptedData.ciphertext);

    const hashedData: string = cryptoJs.enc.Latin1.stringify(hashedDataHex);

    const keyStr: string = cryptoJs.enc.Latin1.stringify(key);

    const xorKey: string = new Encrypt().getXorKey(hashedData, keyStr);

    return `encrypted:${encryptedData.toString()}:${btoa(xorKey)}`;
  }

  /**
   * Gets XOR between hashedData and keyStr
   * @param hashedData login hashed and encrypted data
   * @param keyStr
   */
  private getXorKey(hashedData: string, keyStr: string): string {
    if (!hashedData || !keyStr) {
      console.error('Hashed data and key cannot be empty, null or undefined!');
    }

    let xorKey = '';
    for (let i = 0; i < hashedData.length; i += 1) {
      const value = hashedData.charCodeAt(i) ^ keyStr.charCodeAt(i);
      xorKey += String.fromCharCode(value);
    }

    return xorKey;
  }

  /**
   * Hash password with salt.
   * @param password password for hash
   * @param salt user salt
   */
  public static hashPassword(password: string, salt: string): string {
    if (!password || !salt) {
      console.error('Password and Salt cannot be empty, null or undefined!');
    }

    const hashPassword = cryptoJs.PBKDF2(password, salt, {
      keySize: 512 / 32,
      hasher: cryptoJs.algo.SHA512,
      iterations: 10000,
    });

    return hashPassword.toString(cryptoJs.enc.Base64);
  }

  /**
   * Encrypts new password  by following algorithm.
   * Temporary solution for encrypt password during password change process.
   * Algorithm will be changed soon.
   * @param newPassword for encrypt
   */
  public static encryptNewPassword(newPassword: string): string {
    if (!newPassword) {
      console.error('New password cannot be empty, null or undefined!');
    }

    return this.encryptLogin(newPassword);
  }
}
