import { OrderState } from 'api/enums';
import { Account, OrderSignSmsRows, OrderSmsSignParams } from 'api/interfaces';
import { IMPORT_TYPES, Option } from 'api/Service';
import { ExportFormatEnum } from 'components/ExportDropdown/enum';
import { StatusColor } from 'components/layout/Status';
import { getAmountNumberFormatWithComma } from 'components/utils/format';
import { translate } from 'i18n/translator';
import { isExist } from 'utils/isData';

export const HEADER_ID = 1;

const needHeaderByType: Obj<Obj<boolean>> = {
  [IMPORT_TYPES.SALARY_EMPLOYEES]: {
    [ExportFormatEnum.DBF]: true,
    [ExportFormatEnum.CSV]: false,
    [ExportFormatEnum.XLS]: false,
  },

  [IMPORT_TYPES.COUNTERPARTIES]: {
    [ExportFormatEnum.CSV]: false,
    [ExportFormatEnum.XLS]: false,
  },

  default: {
    [ExportFormatEnum.DBF]: true,
    [ExportFormatEnum.CSV]: false,
    [ExportFormatEnum.XLS]: true,
    [ExportFormatEnum.XML]: true,
  },
};

//TODO delete after bringing the consideration of titles on the back to one format
export const needHeaders = (fileFormat: ExportFormatEnum, orderType: IMPORT_TYPES): boolean =>
  needHeaderByType[orderType]
    ? needHeaderByType[orderType][fileFormat.toUpperCase()]
    : needHeaderByType.default[fileFormat.toUpperCase()];

export const createAccountLabel = (account: Account): string => {
  const { alias, iban, currency, plannedBalance } = account;
  const balance = getAmountNumberFormatWithComma(plannedBalance);
  return alias ? `${iban} / ${currency} ${balance} / ${alias}` : `${iban} / ${currency} ${balance}`;
};

export const createAccountOptions = (accounts: Account[]): Option<Account>[] =>
  accounts.map(account => ({
    value: String(account.id),
    label: createAccountLabel(account) || account.iban || account.number,
    content: account,
  }));

export const getStatusColor = (status: OrderState): StatusColor => {
  switch (status) {
    case OrderState.Signed:
    case OrderState.Ready:
    case OrderState.SentToBank:
    case OrderState.Executed:
    case OrderState.Sent:
    case OrderState.InWork:
    case OrderState.InProcess:
    case OrderState.Accepted:
    case OrderState.ReceivedByBank:
    case OrderState.RetainedByBank:
    case OrderState.OnExecution:
    case OrderState.OnPartialExecution:
    case OrderState.PartiallyExecuted:
      return 'green';
    case OrderState.Rejected:
    case OrderState.RejectedByClient:
    case OrderState.Revoked:
    case OrderState.Deleted:
      return 'red';
    default:
      return 'grey';
  }
};

export const getOrdersSignSmsRows = (params: OrderSmsSignParams): OrderSignSmsRows['rows'] => {
  const hasMultiSign = isExist(params['HASH']);

  return [
    {
      name: translate(
        hasMultiSign
          ? 'front.sms-modal.transaction-identifier.label'
          : 'front.sms-modal.recipient.label',
      ),
      data: hasMultiSign ? params['HASH'] : params['RECEIVER'],
    },
    hasMultiSign && {
      name: translate('front.sms-modal.number-of-documents.label'),
      data: params['SIZE'],
    },
    {
      name: translate('front.sms-modal.total.label'),
      data: hasMultiSign ? params['PAYMENT_SUMMARY'] : `${params['AMOUNT']} ${params['CUR']}`,
    },
  ].filter(Boolean);
};
