import React from 'react';
import { useSelector } from 'react-redux';

import {
  ModalViewComponentProps,
  ModalViewConfiguration,
} from 'components/modals/globalModalCollection/interfaces';
import { Modal } from 'components/modals/Modal';
import store from 'store';
import { ModalCollectionActions } from 'store/actions/modalCollection';
import { selectCollectionModals } from 'store/selectors';

export class ModalView<P = any> {
  configuration: ModalViewConfiguration<P>;

  constructor(configuration: ModalViewConfiguration<P>) {
    this.configuration = configuration;
  }

  open = async () =>
    new Promise<P>((resolve, reject) => {
      store.dispatch(
        ModalCollectionActions.addToModalCollection({ modal: this, props: { resolve, reject } }),
      );
    });

  close = () =>
    store.dispatch(ModalCollectionActions.removeFromModalCollection({ id: this.configuration.id }));
}

const ModalViewComponent = <P,>({
  ModalContentComponent,
  contentProps,
}: ModalViewComponentProps<P>) => (
  <Modal onClose={contentProps.onClose} width={contentProps.width || 500}>
    <ModalContentComponent {...contentProps} />
  </Modal>
);

export const ModalCollectionView = () => {
  const modals = useSelector(selectCollectionModals());

  return (
    <div>
      {modals.map((item, i) => {
        const { id, Component, data, width } = item.modal.configuration;

        return (
          <div style={{ display: i < modals.length - 1 ? 'none' : 'initial' }} key={id}>
            <ModalViewComponent
              ModalContentComponent={Component}
              contentProps={{
                ...item.props,
                ...data,
                width,
                onClose: () => {
                  store.dispatch(
                    ModalCollectionActions.removeFromModalCollection({
                      id,
                    }),
                  );
                  item.props.resolve(false);
                },
              }}
            />
          </div>
        );
      })}
    </div>
  );
};
