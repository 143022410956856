import * as React from 'react';
import { Col, Row, Container } from 'react-grid';
import reactHtmlParser from 'react-html-parser';

import { api } from 'api';
import { Certificate } from 'api/CertificateService/interfaces';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { PasswordField } from 'components/forms/inputs/PasswordField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { translate } from 'i18n/translator';

import './certificates.scss';

interface Props {
  certificate: Certificate;
  resolve: any;
}

interface Fields {
  password: string;
}

const ActivateModalForm: React.FC<Props> = ({ resolve, certificate }) => {
  const { progress, error, handleSubmit } = useForm<Fields>();

  const onSave = async (formData: Fields) => {
    const { password } = formData;
    await api.certificate.updateCertificate(password, certificate.certificate);
    await api.certificate.confirmCertificate(certificate.id);
    resolve(true);
  };

  return (
    <>
      <h3>{translate('front.cert-page.activate-modal.title.label')}</h3>
      <DefaultForm disabled={progress}>
        {error && <FormError>{reactHtmlParser(error)}</FormError>}
        <Container>
          <PasswordField
            label="front.cert-page.activate-modal.pin.label"
            name="password"
            required
          />
          <Row>
            <Col sm={6}>
              <Button
                onClick={e => handleSubmit(onSave, e)}
                size="md"
                progress={progress}
                type="submit"
              >
                {translate('front.cert-page.activate-modal.activate-button.label')}
              </Button>
            </Col>
            <Col sm={6}>
              <Button onClick={() => resolve(null)} color="secondary" size="md">
                {translate('front.cert-page.activate-modal.cancel-button.label')}
              </Button>
            </Col>
          </Row>
        </Container>
      </DefaultForm>
    </>
  );
};

export const ActivateModal = withForm(ActivateModalForm);
