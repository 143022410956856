import { checkMultiCustomer } from 'api/ConstantService';
import { OrderType } from 'api/DemandsService/enums';
import { DemandResponse } from 'api/DemandsService/interfaces';
import { EmployeeStatus, PaymentTypes } from 'api/enums';
import { ProductsService } from 'api/ProductsService';
import { ExportFormatEnum } from 'components/ExportDropdown/enum';
import { StatusColor } from 'components/layout/Status';
import { getFieldNulls } from 'pages/Employees/utils';
import { SalaryRequest } from 'pages/Salary/SalaryPage';

import { del, downloadFile, get, post, put } from './backend';
import { GridRequest, GridResponse, HasId, IMPORT_TYPES } from './Service';

export type Salary = HasId;

export interface SalaryRequestInterface extends GridRequest {
  amountFrom: number;
  amountTo: number;
  customerIds: string[];
  dateFrom: Date;
  dateTo: Date;
  orderNumber: number;
  orderStates: string | string[];
  orderType: OrderType;
  types: PaymentTypes[];
  isPaymentOrder?: boolean;
  withCurrencyTotals?: boolean;
  withSync?: boolean;
}

export interface Employee {
  accountNumber: string;
  amount: number;
  birthDate: string;
  customerId: number;
  employeeId: number;
  externalId: string;
  firstName: string;
  firstNameTrans: string;
  iban: string;
  id: number;
  isAccountInAnotherBank: boolean;
  lastName: string;
  lastNameTrans: string;
  middleName: string;
  phone: string;
  productCode: string;
  productId: number;
  sex: '1' | '2';
  status: EmployeeStatus;
  taxCode: string;
  birthPlace?: string;
  tabularNumber?: string;
}

export interface EmployeeFilter extends GridRequest {
  statuses: EmployeeStatus | Array<EmployeeStatus>;
  accountNumbers?: string;
  customerIds?: number[];
  name?: string;
  productCode?: string;
  productIds?: Array<number>;
  searchText?: string;
  taxCodes?: string;
}

export const getStatusColor = (status: EmployeeStatus): StatusColor => {
  switch (status) {
    case EmployeeStatus.ACTIVE:
      return 'green';
    case EmployeeStatus.FIRED:
      return 'red';
    default:
      return 'grey';
  }
};

export class SalaryService {
  async importEmployees(employees: Employee[]): Promise<any> {
    return Promise.all(employees.map(employee => post('/v1/employees', { ...employee })));
  }

  async acceptEmployees(employees: Employee[]): Promise<void> {
    const ids = employees.map(employee => employee.id);
    await post('/v1/import/accept', ids, { objectCode: IMPORT_TYPES.SALARY_EMPLOYEES });
  }

  async exportEmployees(exportType: ExportFormatEnum, filter: EmployeeFilter): Promise<void> {
    return downloadFile('/v1/employees/export', {}, { exportType, ...filter });
  }

  async createSalary(request: SalaryRequest): Promise<number> {
    const response = await post('/v1/orders/salary', { ...request });
    return response.value;
  }

  updateSalary(id: number, request: SalaryRequest) {
    return put(`/v1/orders/salary/${id}`, request);
  }

  async getSalaries(request: SalaryRequestInterface): Promise<GridResponse<Salary>> {
    return get('/v1/orders', checkMultiCustomer(request));
  }

  getEmployees(request: EmployeeFilter): Promise<GridResponse<Employee>> {
    const newRequest = {
      ...request,
      nulls: getFieldNulls(request.sort),
    };
    return get('/v1/employees', checkMultiCustomer(newRequest));
  }

  getEmployee(id: string): Promise<Employee> {
    return get(`/v1/employees/${id}`);
  }

  updateEmployee(id: string, request: Employee): Promise<Employee> {
    return put(`/v1/employees/${id}`, request);
  }

  deleteEmployee(id: number): Promise<any> {
    return del(`/v1/employees/${id}`);
  }

  getEmployeesInDemand(id: number): Promise<GridResponse<Employee>> {
    return get(`/v1/orders/salary/${id}/employees`);
  }

  async getCommission(customerId: number, productId: string, amount: string): Promise<number> {
    const response = await get('/v1/orders/salary/commission', {
      customerId,
      productId,
      amount,
    });
    return response.commissionAmount;
  }

  async getSalaryDealOptions(customerId: number) {
    const response = await new ProductsService().getProductsForFilter({
      customerIds: [customerId],
      subTypes: ['SALARY_DEAL'],
      productType: 'SALARY',
      statuses: ['ACTIVE'],
    });

    return response.rows.map(r => ({
      label: `${r.subTypeName.text} ${r.product.productCode}`,
      value: r.product.id,
    }));
  }

  getRelated(id: number): Promise<DemandResponse[]> {
    return get(`/v1/orders/salary/${id}/payments`).catch(() => []);
  }
}
