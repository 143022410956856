import React from 'react';
import { Action, TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { DemandResponse } from 'api/DemandsService/interfaces';
import { OrderActionType, OrderState, PaymentTypes } from 'api/enums';
import { ExportType } from 'api/ExportService';
import { ForeignPaymentFilterInterface } from 'api/interfaces';
import { SortOrder } from 'api/Service';
import { getStatusColor } from 'api/utils';
import { Page } from 'components/layout/Page/Page';
import { Status } from 'components/layout/Status';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { DEFAULT_WIDTH, PrintingDocuments } from 'components/PrintingDocuments/PrintingDocuments';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import * as format from 'components/utils/format';
import { translate } from 'i18n/translator';
import { allowPrivilegy } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import {
  electronicSignAction,
  getTranslateAction,
  signActions,
  smsSignAction,
} from 'pages/Payments/general';
import { isAllowedActionSign, isVisibleAction } from 'pages/Payments/utils';

import { ForeignPaymentPageFilter } from './ForeignPaymentsFilter';

const columns: TableColumnProps<DemandResponse>[] = [
  {
    label: 'front.i18n-payments.col-number.label',
    render: row => (
      <DataColumn title={row.base.orderNumber} subTitle={format.date(row.base.orderDate)} />
    ),
    sort: 'orderNumberNumeric',
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.i18n-payments.col-sender.label',
    render: row => (
      <DataColumn
        title={row?.financial?.payerName}
        subTitle={row?.financial?.payerIban || row?.financial?.payerAccount}
      />
    ),
    sort: 'payerName',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.i18n-payments.col-receiver.label',
    render: row => {
      const title = row.financial.receiverName;
      const subTitle = row.financial.receiverIban
        ? row.financial.receiverIban
        : row.financial.receiverAccount;
      return <DataColumn title={title} subTitle={subTitle} />;
    },
    sort: 'receiverName',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.i18n-payments.col-appointment.label',
    render: row => <DataColumn title={row.financial.purpose} />,
    sort: 'purpose',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.i18n-payments.col-status.label',
    render: row => (
      <DataColumn>
        <Status color={getStatusColor(row?.base.orderState)}>{row.base.orderStateLabel}</Status>
      </DataColumn>
    ),
    sort: 'orderState',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.i18n-payments.col-sum.label',
    render: row => (
      <DataColumn title={format.getAmount(row.financial.amount)} subTitle={row.financial.isoCode} />
    ),
    sort: 'amount',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    renderMenu: order => [
      {
        label: getTranslateAction(OrderActionType.REJECT),
        onClick: async () => await api.payments.executeAction(order.id, OrderActionType.REJECT),
        isReloadable: true,
        isVisible: isVisibleAction(order, OrderActionType.REJECT),
      },
      {
        label: getTranslateAction(OrderActionType.REVOKE),
        onClick: async () => await api.payments.withdrawOrders([order.id]),
        isVisible: isVisibleAction(order, OrderActionType.REVOKE),
        confirmMessage: 'front.working-documents-table.actions-revoke-confirmation.label',
      },
      {
        label: getTranslateAction(OrderActionType.DELETE),
        onClick: async () => await api.payments.deleteOrder(order.id),
        isVisible: isVisibleAction(order, OrderActionType.DELETE),
        confirmMessage: 'front.working-documents-table.actions-delete-confirmation.label',
        isReloadable: true,
      },
      {
        label: getTranslateAction(OrderActionType.SIGN),
        onClick: async () => signActions(order),
        isVisible: isAllowedActionSign(order, OrderActionType.SIGN),
        isReloadable: true,
      },
      {
        label: getTranslateAction(OrderActionType.VIEW),
        onClick: () => {
          goto(pages.foreignTransfer(order.id));
        },
        isVisible: isVisibleAction(order, OrderActionType.VIEW),
      },
      {
        label: getTranslateAction(OrderActionType.EDIT),
        onClick: () => {
          goto(pages.foreignPayment.tabs.details(order.id));
        },
        isVisible: isVisibleAction(order, OrderActionType.EDIT),
      },
      {
        label: getTranslateAction(OrderActionType.COPY),
        onClick: () => {
          goto(
            pages.foreignPayment.tabs.details('new', {
              copyFrom: order.id.toString(),
              customerId: order.base.customerId,
            }),
          );
        },
        isVisible: isVisibleAction(order, OrderActionType.COPY),
      },
      {
        label: getTranslateAction(OrderActionType.PRINT),
        onClick: () => api.payments.getOrderPdf(order.id),
        isVisible: isVisibleAction(order, OrderActionType.PRINT),
      },
    ],
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

const actions: Action<DemandResponse>[] = [
  {
    label: 'front.misc.sign-electronic-sign.label',
    action: async data => electronicSignAction(data),
    isReloadable: true,
    isVisible: selected =>
      selected.some(
        item =>
          item.base.orderState !== OrderState.NeedSms &&
          isAllowedActionSign(item, OrderActionType.SIGN),
      ),
  },
  {
    label: 'front.misc.sign-subscribe-sms.label',
    action: async data => smsSignAction(data),
    isReloadable: true,
    color: 'secondary',
    isVisible: selected => selected.some(item => isAllowedActionSign(item, OrderActionType.SIGN)),
  },
  {
    label: 'front.working-documents-table.actions-revoke.label',
    action: async data => {
      const orders = data.filter(item => isVisibleAction(item, OrderActionType.REVOKE));
      if (!orders.length) {
        return;
      }
      await api.payments.withdrawOrders(orders.map(item => item.id));
    },
    color: 'secondary',
    isVisible: selected =>
      selected.some(item =>
        item.actions.some(action => action.actionName === OrderActionType.REVOKE),
      ) && allowPrivilegy([Privileges.paymentForeignEdit]),
  },
  {
    label: getTranslateAction(OrderActionType.PRINT),
    action: async data =>
      await confirmModal(
        PrintingDocuments,
        {
          data: data.filter(item => isVisibleAction(item, OrderActionType.PRINT)),
          objectCode: ExportType.FOREIGN_TRANSFER_ORDERS,
        },
        DEFAULT_WIDTH,
      ),
    color: 'secondary',
    isVisible: selected => selected.some(item => isVisibleAction(item, OrderActionType.PRINT)),
    isReloadable: true,
  },
  {
    label: getTranslateAction(OrderActionType.DELETE),
    action: async data => {
      const orders = data.filter(item => isVisibleAction(item, OrderActionType.DELETE));
      if (!orders.length) {
        return;
      }
      for (let i = 0; i < orders.length; i += 1) {
        await api.payments.deleteOrder(orders[i].id);
      }
    },
    color: 'secondary',
    confirmMessage: 'front.working-documents-table.actions-delete-confirmation.label',
    isVisible: selected =>
      selected.some(item =>
        item.actions.some(action => action.actionName === OrderActionType.DELETE),
      ) && allowPrivilegy([Privileges.paymentForeignEdit]),
    isReloadable: true,
  },
];

const fetchDocs = (filter: ForeignPaymentFilterInterface) => {
  const types = [PaymentTypes.FOREIGN_TRANSFER, PaymentTypes.FOREIGN_TRANSFER_ESCROU];
  const orderStates = api.payments.getPaymentStatusesByKey((filter.orderStates as string) || null);
  return api.payments.getWorkingDocs({ ...filter, types, orderStates });
};

export const ForeignPaymentsPage = () => (
  <Page title={translate('front.i18n-payments.title.label')}>
    <DataTable
      name={TableNamesEnum.InternationalPayments}
      columns={columns}
      actions={actions}
      fetchRows={fetchDocs}
      defaultSort="id"
      defaultOrder={SortOrder.Desc}
      filterComponent={ForeignPaymentPageFilter}
      onRowClick={row => goto(pages.foreignPayment.tabs.details(row.id))}
    />
  </Page>
);
