import * as React from 'react';

import { FormContext, FormContextValue, SetFormDataFunc, FormPayload } from './FormContext';

export type Effects<T, P> = {
  setArrayFields: SetFormDataFunc<T>;
  setFields: SetFormDataFunc<T>;
  setPayload: React.Dispatch<React.SetStateAction<Partial<P>>>;
};

export type FormFetchCb<T, P = FormPayload> = (effects: Effects<T, P>) => Promise<void>;

export const useForm = <T, P = FormPayload>(initialFetch?: FormFetchCb<T, P>) => {
  const {
    updateField,
    fieldsData,
    registerField,
    registerFieldsArray,
    unregisterField,
    updateInput,
    ...form
  } = React.useContext<FormContextValue<T, P>>(FormContext);

  const setFields: SetFormDataFunc<T> = fields => form.setData(fields, undefined);
  const setArrayFields: SetFormDataFunc<T> = fields => form.setData(undefined, fields);

  const fetchData = async (effect: FormFetchCb<T, P>) => {
    form.setProgress(true);
    await effect({ setFields, setArrayFields, setPayload: form.setPayload });
    form.setProgress(false);
  };

  const onMount = async () => {
    if (initialFetch) {
      form.setProgress(true);

      try {
        form.setInitializing(true);
        await initialFetch({
          setFields,
          setArrayFields,
          setPayload: form.setPayload,
        });
        form.setInitializing(false);
      } catch (e) {
        form.handleError(e);
      }

      form.setProgress(false);
    }
  };

  React.useEffect(() => {
    onMount();
  }, []);

  return { ...form, fetchData };
};
