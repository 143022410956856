import * as React from 'react';

import classNames from 'classnames';

import { Option } from 'api/Service';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { translate } from 'i18n/translator';

import './styles.scss';

export type CssType = 'inline' | 'block' | 'flex';

interface Props {
  name: string;
  options: Array<Option>;
  value: string;
  clearable?: boolean;
  cssType?: CssType;
  disabled?: boolean;

  onChange?(value: any): void;
}

export const Radio: React.FC<Props> = props => {
  const { value, disabled, onChange, clearable, options, name, cssType = 'inline' } = props;

  const handleChange = (event: any) => {
    if (clearable && event.target.value === value) {
      event.target.value = null;
      return onChange(event);
    }

    onChange(event);
  };

  return (
    <div className={classNames('inp-radio-wrap', cssType)}>
      {options.map(item => (
        <div key={`${item.value}`} className="inp-radio">
          <input
            name={name}
            className="inp-radio__input"
            id={`radio-${item.value}`}
            type="radio"
            value={`${item.value}`}
            onClick={handleChange}
            disabled={disabled}
            checked={`${item.value}` === `${value}` || `${item.content?.value}` === `${value}`}
          />
          <label className="inp-radio__label" htmlFor={`radio-${item.value}`}>
            {translate(item.label)}
            {item.content?.tooltipValue && (
              <Tooltip tooltip={item.content.tooltipValue} label={item.content?.tooltipIcon} />
            )}
          </label>
        </div>
      ))}
    </div>
  );
};
