import { NOTIFICATIONS } from 'store/actions/types';
import { ActionInterface } from 'store/reducers/interfaces';

export type Notification = {
  attemptCount: number;
  channelCode: 'INTERNAL';
  customerUserId: number;
  dirtyFields: any[];
  displayKind: 'NONE';
  generateTime: Date;
  id: number;
  messageText: string;
  notificationType: 'PASSWORD_EXPIRING';
  notificationTypeId: number;
  objectId: string;
  recipientId: 'INTERNAL';
  status: 'WAITING';
  subjectText: string;
};

export type NotificationsState = {
  notifications?: Notification[];
};

const initialState: NotificationsState = {
  notifications: [],
};

export const notificationsReducer = (
  state = initialState,
  action: ActionInterface,
): NotificationsState => {
  switch (action.type) {
    case NOTIFICATIONS.UPDATE_NOTIFICATIONS: {
      return { notifications: action.payload };
    }
    default:
      return state;
  }
};
