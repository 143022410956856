import React from 'react';

import classNames from 'classnames';

import {
  LINK_REGEXP,
  RenderLabelWithLink,
} from 'components/RenderLabelWithLink/RenderLabelWithLink';
import { translate } from 'i18n/translator';
import { noop } from 'utils/noop';

import './checkbox.scss';

interface CheckboxProps {
  checked: boolean;
  name: string;
  disabled?: boolean;
  hasEnlargedArea?: boolean;
  isAnimated?: boolean;
  isHover?: boolean;
  label?: string;
  link?: string;
  linkLabel?: string;
  onBlur?(): void;

  onChange?(): void;

  style?: React.CSSProperties;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  disabled,
  name,
  label,
  link,
  linkLabel,
  isAnimated = true,
  isHover = false,
  onChange,
  onBlur = noop,
  hasEnlargedArea = false,
}) => {
  const onChangeMove = (e: any) => {
    const isLinkPressed = e.target.tagName === 'A';
    !isLinkPressed && e.preventDefault();

    if (!disabled && onChange && !isLinkPressed) {
      onChange();
      e.stopPropagation();
      onBlur();
    }
  };

  const id = `checkbox-${name}`;
  const translatedLabel = translate(label);

  return (
    <div className="inline-wrap">
      <input
        className="inp-cbx"
        id={id}
        type="checkbox"
        style={{ display: 'none' }}
        checked={checked}
        readOnly
        disabled={disabled}
      />
      <label
        htmlFor={id}
        onClick={onChangeMove}
        className={classNames('cbx', { enlargedClickableArea: hasEnlargedArea })}
      >
        <span
          className={classNames({
            'checkbox-animated': isAnimated,
          })}
        >
          <svg width="12px" height="10px" viewBox="0 0 12 10">
            <polyline points="1.5 6 4.5 9 10.5 1" />
          </svg>
        </span>
        {LINK_REGEXP.test(translatedLabel) || link ? (
          RenderLabelWithLink(label, [link], [linkLabel])
        ) : (
          <span
            className={classNames({
              'cbx_label-hover': isHover,
            })}
          >
            {translatedLabel}
          </span>
        )}
      </label>
    </div>
  );
};
