import * as React from 'react';
import { Col, Container, Row } from 'react-grid';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';

import { api } from 'api';
import { CheckSmsActionEnum } from 'api/enums';
import { CounterpartyAccount, CounterpartyFilteredModel } from 'api/HandbooksService';
import { Button } from 'components/buttons/Button';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { TextField } from 'components/forms/inputs/TextField';
import { ArrayField } from 'components/forms/ValidatingForm/components/ArrayField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import * as Icons from 'components/icons';
import { Page } from 'components/layout/Page/Page';
import { confirmationModal } from 'components/modals/ConfirmModal';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { SmsModal } from 'components/modals/SmsModal';
import { translate } from 'i18n/translator';
import { isDeniedAction } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { normalize, validateIban } from 'pages/Demands/utils';
import { selectDefaultCustomerId } from 'store/selectors';

interface FormData {
  accounts: Partial<CounterpartyAccount>[];
  bin: string;
  customerId: string | number;
  name: string;
}

function getLabelKey(isNew: boolean) {
  return isNew
    ? 'front.сounterparty-form.create-title.label'
    : 'front.сounterparty-form.edit-title.label';
}

const validateBIN = (value: string): string => {
  if (value.length > 10 || value.length < 8) {
    return 'front.validation.bin-length.label';
  }
  if (!/^\d+$/.test(value)) {
    return 'front.validation.bin-numbers.label';
  }
  return '';
};

const sortById = (a: CounterpartyFilteredModel, b: CounterpartyFilteredModel) =>
  b.id > a.id ? 1 : -1;

const isNonResidentCounterParty = (value: string): boolean => {
  const bin: Record<string, boolean> = {
    '000000000': true,
    '0000000000': true,
  };

  return bin[value];
};

const CounterpartyForm = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();
  const isNew = !Number(id);
  const defaultCustomerId = useSelector(selectDefaultCustomerId());
  const { error, progress, handleSubmit, updateData, getFormData, setError } = useForm<FormData>(
    async ({ setFields, setArrayFields }) => {
      let currentCustomerId = defaultCustomerId;
      if (!isNew) {
        const {
          accounts: rawAccounts,
          counterparty: { name, bin, customerId },
        } = await api.handbooks.getCounterparty(Number(id));
        currentCustomerId = customerId;

        const accounts: Partial<CounterpartyAccount>[] = rawAccounts.map(({ iban, bankCode }) => ({
          iban,
          bankCode,
        }));
        setFields({ name, bin, customerId: currentCustomerId });
        setArrayFields({ accounts });
      } else {
        setArrayFields({ accounts: [{ iban: '', bankCode: '' }] });
      }
    },
  );

  React.useEffect(() => {
    error && setError(null);
  }, [JSON.stringify(getFormData().accounts)]);

  const onSave = async ({ bin, name, accounts, customerId }: FormData) => {
    const otpCode = await confirmModal<string>(SmsModal, {
      action: CheckSmsActionEnum.CONFIRM_COUNTERPARTY_CHANGE,
    });

    if (!otpCode) return;

    const accountsJson = accounts.map(account => JSON.stringify(account));
    const accountsUniqueSize = new Set(accountsJson).size;
    const messageErrorAboutDuplicate = translate('front.form.counterparty-duplicate-iban.error');
    if (accounts.length !== accountsUniqueSize) {
      return setError(messageErrorAboutDuplicate);
    }
    const counterparty = {
      bin,
      name,
      id: Number(id),
      customerId: +customerId,
    };

    const {
      rows: existingCounterparties,
      total: { count },
    } = await api.handbooks.getFilteredCounterparty({
      bin,
      customerIds: [counterparty.customerId],
    });

    const existingCounterparty =
      count > 0
        ? // if there are duplicate inn for counterparty take the newest one
          existingCounterparties.sort(sortById)[0]
        : null;

    const message = translate(
      'front.counterparty.confirm-save.move-request-to-exist-bin.label',
    ).replace('${name}', existingCounterparty?.name);
    if (isNew) {
      if (existingCounterparty && !isNonResidentCounterParty(bin)) {
        const needUpdate = !!(await confirmationModal(message, true, true));

        return await updateExistingCounterparty(
          needUpdate,
          existingCounterparty.id,
          name,
          accounts,
        );
      }

      await api.handbooks.createCounterparty(
        { counterparty, accounts, otpCode },
        counterparty.customerId,
      );
    } else {
      if (
        existingCounterparty &&
        !isNonResidentCounterParty(bin) &&
        existingCounterparty.id !== Number(id)
      ) {
        const needUpdate = !!(await confirmationModal(message, true, true));

        return await updateExistingCounterparty(
          needUpdate,
          existingCounterparty.id,
          name,
          accounts,
        );
      }

      await api.handbooks.updateCounterparty(
        { counterparty, accounts, otpCode },
        counterparty.customerId,
      );
    }

    goto(pages.handbooks.tabs.counterparties);
  };

  // Update state and redirect to edit existing counterparties
  const updateExistingCounterparty = async (
    needUpdate: boolean,
    existingId: number,
    name: string,
    newAccounts: Partial<CounterpartyAccount>[],
  ) => {
    if (needUpdate) {
      const {
        accounts: rawAccounts,
        counterparty: { bin },
      } = await api.handbooks.getCounterparty(existingId);

      const oldAccounts: Partial<CounterpartyAccount>[] = rawAccounts.map(({ iban, bankCode }) => ({
        iban,
        bankCode,
      }));

      updateData({ name, bin }, { accounts: [...oldAccounts, ...newAccounts] });
      return goto(pages.handbooks.counterparty(existingId));
    }

    return goto(pages.handbooks.tabs.counterparties);
  };

  const onIBANChange = (fieldName: string) => (value: string) => {
    const bic = value.slice(4, 10);
    updateData({ [`${fieldName}bankCode`]: bic });
  };

  const { customerId } = getFormData();
  const disableButton = isDeniedAction([Privileges.infoCounterpartiesEdit], customerId);

  return (
    <Page title={translate(getLabelKey(isNew))}>
      <DefaultForm>
        <FormError>{error}</FormError>
        <CustomerSelect
          name="customerId"
          label="front.сounterparty-form.customer-id.label"
          disabled={!isNew}
          selectDefaultCustomer
          required
        />
        <TextField
          name="name"
          label="front.сounterparty-form.name.label"
          normalize={normalize}
          required
        />
        <TextField
          name="bin"
          label="front.сounterparty-form.bin.label"
          required
          validate={validateBIN}
        />
        <ArrayField
          name="accounts"
          renderContainer={(items, push) => (
            <>
              <Button color="primary" onClick={push} size="sm" disabled={disableButton}>
                {translate('front.сounterparty-form.add-button.label')}
              </Button>
              <Container>{items}</Container>
            </>
          )}
          renderItem={(prefix, index, remove) => (
            <Row key={`account-${index}`}>
              <Col md={7}>
                <TextField
                  name={`${prefix}[${index}].iban`}
                  label="front.сounterparty-form.iban.label"
                  required
                  onChange={onIBANChange(`${prefix}[${index}].`)}
                  validate={validateIban}
                  length={29}
                />
              </Col>
              <Col md={4}>
                <TextField
                  name={`${prefix}[${index}].bankCode`}
                  label="front.сounterparty-form.bank-code.label"
                  required
                  disabled
                />
              </Col>
              {!!index && (
                <Col md={1}>
                  <Button close onClick={() => remove()}>
                    <Icons.Delete />
                  </Button>
                </Col>
              )}
            </Row>
          )}
        />
        <div>
          <Button
            color="secondary"
            onClick={() => goto(pages.handbooks.tabs.counterparties)}
            progress={progress}
            disabled={disableButton}
            type="submit"
            size="sm"
          >
            {translate('front.сounterparty-form.cancel-button.label')}
          </Button>
          {/* // TODO: create form submit button */}
          <Button
            color="primary"
            onClick={e => handleSubmit(onSave, e)}
            progress={progress}
            disabled={disableButton}
            type="submit"
            size="sm"
          >
            {translate('front.сounterparty-form.save-button.label')}
          </Button>
        </div>
      </DefaultForm>
    </Page>
  );
};

export const CounterpartyPage = withForm(CounterpartyForm);
