import * as React from 'react';

import './instructions.scss';

interface Props {
  description: string;
}

export const InstructionInfo: React.FC<Props> = ({ description }) => {
  return <div className="additional-info" dangerouslySetInnerHTML={{ __html: description }} />;
};
