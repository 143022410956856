import { ModalView } from 'components/modals/globalModalCollection/GlobalModalCollection';
import { AsyncProps } from 'components/modals/globalModalCollection/interfaces';
import { ModalEnum } from 'components/modals/globalModalCollection/modalEnum';
import actionCreator from 'store/actionCreator';
import { MODAL_COLLECTION } from 'store/actions/types';

export const ModalCollectionActions = {
  addToModalCollection: actionCreator<{
    modal: ModalView;
    props: AsyncProps;
  }>(MODAL_COLLECTION.ADD_MODAL_COLLECTION),
  removeFromModalCollection: actionCreator<{ id: ModalEnum }>(
    MODAL_COLLECTION.REMOVE_MODAL_COLLECTION,
  ),
};
