import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

import classNames from 'classnames';
import './styles.scss';

export interface Props {
  onBlur(): void;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  placeholder: string;
  type: string;
  autoComplete?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  hasGrayBackground?: boolean;
  id?: string;
  isLargeInput?: boolean;
  maskConfig?: NumericFormatProps;
  maxLength?: number;
  onFocus?(): void;
  onPaste?(event: React.ClipboardEvent<HTMLInputElement>): void;
  value?: string;
}

export const NumericFormatField: React.FC<Props> = ({
  id,
  value,
  disabled,
  onChange,
  onBlur,
  autoFocus,
  onPaste,
  placeholder,
  onFocus,
  autoComplete,
  isLargeInput,
  hasGrayBackground,
  maxLength,
  maskConfig: {
    decimalScale = null,
    fixedDecimalScale = false,
    allowedDecimalSeparators,
    decimalSeparator,
    thousandSeparator,
    allowLeadingZeros = false,
  } = {},
}) => {
  return (
    <NumericFormat
      id={id}
      onPaste={onPaste}
      autoFocus={autoFocus}
      className={classNames('form-input', {
        'form-input-large': isLargeInput,
        'form-input-grey-background': hasGrayBackground,
      })}
      autoComplete={autoComplete}
      disabled={disabled}
      value={value}
      placeholder={placeholder}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={onChange}
      decimalScale={decimalScale}
      fixedDecimalScale={fixedDecimalScale}
      allowedDecimalSeparators={allowedDecimalSeparators}
      allowNegative={false}
      decimalSeparator={decimalSeparator}
      thousandSeparator={thousandSeparator}
      maxLength={maxLength}
      allowLeadingZeros={allowLeadingZeros}
    />
  );
};
