import * as React from 'react';

import { Button } from 'components/buttons/Button';
import { PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import { translate } from 'i18n/translator';
import { goBack } from 'navigations/navigate';

export const NotAllowed: React.FC<PropsWithConfirmModal> = ({ resolve = goBack }) => (
  <div>
    <h3 style={{ lineHeight: '27px', textAlign: 'center', marginBottom: '20px' }}>
      {translate('front.certificates.action-not-allowed.label')}
    </h3>
    <div className="text-center">
      <Button onClick={() => resolve()} color="secondary" size="md">
        {translate('front.cert-page-new.cancel-button.label')}
      </Button>
    </div>
  </div>
);
