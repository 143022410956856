import * as React from 'react';
import { Menu, TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { EmployeeStatus } from 'api/enums';
import { Employee, getStatusColor } from 'api/SalaryService';
import { SortOrder } from 'api/Service';
import { Page } from 'components/layout/Page/Page';
import { Status } from 'components/layout/Status';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { translate } from 'i18n/translator';
import { isActionAllowed } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';

import { EmployeesFilter } from './EmployeesFilter';

const employeeStatus: Obj<string> = {
  [EmployeeStatus.ACTIVE]: 'front.employees-filter.status.active.label',
  [EmployeeStatus.FIRED]: 'front.employees-filter.status.fired.label',
  [EmployeeStatus.NEW]: 'front.employees-filter.status.new.label',
};

export const NAME_EMPLOYEE_SORTS = ['lastName', 'firstName', 'middleName'];

const columns: TableColumnProps<Employee>[] = [
  {
    label: 'front.employees.col-contract.label',
    render: row => <DataColumn title={row.productCode} />,
    sort: 'productCode',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.employees.col-pib.label',
    render: row => {
      const { firstName, middleName, lastName } = row;
      return <DataColumn title={[lastName, firstName, middleName].join(' ')} />;
    },
    sort: NAME_EMPLOYEE_SORTS,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.employees.col-code.label',
    render: row => <DataColumn title={row.taxCode} />,
    sort: 'taxCode',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.employees.col-account.label',
    render: row => <DataColumn title={row.iban} />,
    sort: 'iban',
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.employees.col-status.label',
    render: row => {
      const title = employeeStatus[row.status] || row.status;
      return (
        <DataColumn>
          <Status color={getStatusColor(row.status as EmployeeStatus)}>{translate(title)}</Status>
        </DataColumn>
      );
    },
    sort: 'status',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    renderMenu: (row): Menu<Employee>[] => [
      {
        label: 'front.employees.render-menu.edit.label',
        onClick: () => goto(pages.employee(row.id), { employee: row }),
        isVisible: isActionAllowed([Privileges.salaryEmployeeEdit], row.customerId),
      },
      {
        label: 'front.employees.render-menu.delete.label',
        onClick: async () => {
          await api.salary.deleteEmployee(row.id);
        },
        isVisible:
          row.status !== EmployeeStatus.NEW &&
          isActionAllowed([Privileges.salaryEmployeeEdit], row.customerId),
        confirmMessage: 'front.employees-documents-table.actions-delete-confirmation.label',
        isReloadable: true,
      },
    ],
  },
];

export const EmployeesPage = () => {
  return (
    <Page title={translate('front.menu.employees.label')}>
      <DataTable
        name={TableNamesEnum.Employees}
        columns={columns}
        fetchRows={api.salary.getEmployees}
        defaultSort="id"
        defaultOrder={SortOrder.Desc}
        filterComponent={EmployeesFilter}
      />
    </Page>
  );
};
