import * as React from 'react';
import { Col, Row } from 'react-grid';

import { Decimal } from 'decimal.js-light';

import { api } from 'api';
import { Employee } from 'api/SalaryService';
import { Option } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { DateRangeField } from 'components/forms/inputs/DateRangeField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { useDisabledCustomer } from 'hooks/useDisabledCustomer';
import { Privileges } from 'navigations/privileges';
import { SalaryNames } from 'pages/Salary/enums';
import { DynamicFieldWithCondition } from 'pages/Salary/utils';
import { useEffectIfValueChange } from 'utils/hooks';

export interface SalaryDetailFields {
  accountFee: string;
  accountTotal: string;
  customer: string;
  makePayments: boolean;
  salaryDeal: string;
  salaryFee: string | number;
  salaryPeriod: Date;
  salaryPeriodEnd: Date;
  salaryTotal: string | number;
  salaryType: string;
}

interface Payload {
  accountFee: Option[];
  accountTotal: Option[];
  isImported: boolean;
  salaryDeal: Option[];
  salaryType: Option[];
  selectedEmployees: Employee[];
}

export const findField = (fieldName: string, dynamicFields: DynamicFieldWithCondition[]) =>
  dynamicFields.find(field => field.field.code === fieldName);

const PAYMENT_VALUE = `37`;
const PREPAYMENT_VALUE = `18`;

const sortingSalaryType = (_: Option, type2: Option) => {
  if ([PAYMENT_VALUE, PREPAYMENT_VALUE].includes(`${type2.content.value}`)) {
    return 1;
  }
  return -1;
};

const getSalaryTotal = (selectedEmployees: Employee[]): string =>
  selectedEmployees?.reduce((total, e) => total.add(e.amount || 0), new Decimal(0)).toFixed(2);

export const SalaryDetails = () => {
  const {
    order,
    payload: {
      salaryDeal: savedSalaryDeal,
      isImported,
      selectedEmployees,
      salaryType,
      accountTotal,
      accountFee,
    },
    onChangeCustomer,
    form,
  } = useOrderDetails<SalaryDetailFields, Payload>();

  const { customer, salaryDeal, makePayments, salaryFee } = form.getFormData();
  const salaryTotal = getSalaryTotal(selectedEmployees);

  const isDisabledCustomer = useDisabledCustomer(order) || isImported;
  const isRequiredAccountFee = salaryFee ? parseFloat(salaryFee as string) > 0 : false;
  useEffectIfValueChange(salaryDeal, () => form.updateData({ salaryTotal: '', salaryFee: '' }));

  const onGetCommission = async () => {
    const isAllowedGetCommission = customer && salaryDeal && salaryTotal;

    if (isAllowedGetCommission) {
      const salaryFee = await api.salary.getCommission(+customer, salaryDeal, salaryTotal);
      form.updateData({ salaryTotal, salaryFee });
    }
  };

  React.useEffect(() => {
    onGetCommission();
  }, [salaryTotal, customer, salaryDeal]);

  React.useEffect(() => {
    if (!makePayments) {
      form.updateData({ accountTotal: null, accountFee: null });
    }
  }, [makePayments]);

  return (
    <DefaultForm>
      <Row>
        <Col md={12}>
          <CustomerSelect
            key={SalaryNames.Customer}
            name={SalaryNames.Customer}
            label="front.salary-statements.customer.label"
            disabled={isDisabledCustomer}
            onSelectOption={onChangeCustomer}
            privilege={form.disabled ? Privileges.salarySheetView : Privileges.salarySheetEdit}
            required
            selectDefaultCustomer
          />
        </Col>
        <Col md={12}>
          <SelectField
            name={SalaryNames.SalaryDeal}
            label="front.salary-statements.salary-deal.label"
            options={savedSalaryDeal}
            disabled={!!order?.state || isImported || !savedSalaryDeal}
            required
          />
        </Col>
        <Col md={7}>
          <SelectField
            name={SalaryNames.SalaryType}
            label="front.salary-statements.salary-type.label"
            options={salaryType?.sort(sortingSalaryType)}
            required
          />
        </Col>
        <Col md={5}>
          <DateRangeField
            nameFrom={SalaryNames.SalaryPeriod}
            nameTo={SalaryNames.SalaryPeriodEnd}
            label="front.salary-statements.salary-period.label"
            required
            clearable
          />
        </Col>

        <Col md={7}>
          <AmountField
            name={SalaryNames.SalaryTotal}
            label="front.salary-statements.amount-enrollment.label"
            thousandSeparator={' '}
            hasMoneyFormat
            disabled
          />
        </Col>
        <Col md={5}>
          <AmountField
            name={SalaryNames.SalaryFee}
            label="front.salary-statements.salary-fee.label"
            thousandSeparator={' '}
            hasMoneyFormat
            disabled
          />
        </Col>
        <Col md={12}>
          <CheckboxField
            name={SalaryNames.MakePayments}
            label="front.salary-statements.make-payments.label"
            defaultValue={true}
          />
        </Col>
        <Col md={12}>
          <SelectField
            name={SalaryNames.AccountTotal}
            label="front.salary-statements.account-total.label"
            options={accountTotal}
            disabled={!makePayments}
            required={!!makePayments}
          />
        </Col>
        <Col md={12}>
          <SelectField
            name={SalaryNames.AccountFee}
            label="front.salary-statements.account-fee.label"
            options={accountFee}
            disabled={!makePayments}
            required={makePayments && isRequiredAccountFee}
          />
        </Col>
      </Row>
    </DefaultForm>
  );
};
