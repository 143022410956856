import { toAmountFormat } from 'components/utils/format';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { PAYMENTS } from 'navigations/pages';
import { PARAMS_KEY } from 'pages/Payments/InternalPayment/hooks/useStorageFilterParams';
import { isAmountFormat } from 'pages/Payments/InternalPayment/utils';
import { LOCAL_STORAGE_KEY } from 'store/actions/types';

export const useStorageVatPercent = () => {
  const [vatPercent, setDefaultStorageVatPercent] = useLocalStorage(
    LOCAL_STORAGE_KEY.PRESERVED_VALUES,
    `${PAYMENTS}.${PARAMS_KEY}`,
  );

  const { defaultVatPercent } = vatPercent ?? [];

  const setStorageVatPercent = (vatPercent: string) => {
    setDefaultStorageVatPercent({
      defaultVatPercent: isAmountFormat(vatPercent) ? toAmountFormat(vatPercent) : vatPercent,
    });
  };

  return [defaultVatPercent, setStorageVatPercent];
};
