import * as React from 'react';
import { TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { AuthorizedPerson, getStatusColor, PersonStatus } from 'api/AuthService';
import { SortOrder } from 'api/Service';
import { Page } from 'components/layout/Page/Page';
import { Status } from 'components/layout/Status';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { translate } from 'i18n/translator';
import { isActionAllowed } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { ActionTypes } from 'pages/AuthorizedPersons/enums';
import { AddRoleFormProps, AddRoleModal } from 'pages/AuthorizedPersons/Roles/AddRoleModal';

import { AuthorizedPersonsFilter } from './AuthorizedPersonsFilter';

const translationStatus: Record<PersonStatus, string> = {
  [PersonStatus.ACTIVE]: translate('front.authorized-persons.status.active.label'),
  [PersonStatus.BLOCKED]: translate('front.authorized-persons.status.blocked.label'),
  [PersonStatus.DELETED]: translate('front.authorized-persons.status.deleted.label'),
};

const shouldVisibleAddRoleAction = (row: AuthorizedPerson) => {
  const hasRoles = row.currentRoles.length > 0;
  return !hasRoles && isActionAllowed([Privileges.adminPermissionEdit], row.companyId);
};

const shouldVisibleEditRoleAction = (row: AuthorizedPerson) => {
  const hasRoles = row.currentRoles.length > 0;
  return hasRoles && isActionAllowed([Privileges.adminCompanyPersonEdit], row.companyId);
};

const columns: TableColumnProps<AuthorizedPerson>[] = [
  {
    label: 'front.authorized-persons.table.customer-full-name.label',
    render: row => <DataColumn title={row.fullName} />,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.authorized-persons.table.role.label',
    render: row => <DataColumn title={row.currentRoles.map(role => role.name).join(',')} />,
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.authorized-persons.table.organization.label',
    render: row => <DataColumn title={row.customerName} />,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.authorized-persons.table.tax-code.label',
    render: row => <DataColumn title={row.taxCode} />,
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.authorized-persons.table.status.label',
    render: row => (
      <DataColumn>
        <Status color={getStatusColor(row.status)}>{translationStatus[row.status]}</Status>
      </DataColumn>
    ),
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.authorized-persons.table.login.label',
    render: row => <DataColumn title={row.login} />,
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    renderMenu: row => [
      {
        label:
          row.status === 'ACTIVE'
            ? 'front.authorized-persons.table.block.label'
            : 'front.authorized-persons.table.unblock.label',
        onClick: async () => {
          await api.auth.changeAccess(row.id, row.status === 'ACTIVE');
        },
        isReloadable: true,
        isPreservePage: true,
        isLoading: true,
        isVisible: isActionAllowed([Privileges.adminCompanyPersonEdit], row.companyId),
      },
      {
        label: 'front.authorized-persons.table.edit.label',
        onClick: () => goto(pages.authPersonPermission(row.id)),
        isVisible: shouldVisibleEditRoleAction(row),
      },
      {
        label: 'front.authorized-persons.table.view.label',
        onClick: () => goto(pages.authorizedPersonPage(row.id, ActionTypes.VIEW)),
        isVisible:
          row.creatorType === 'CUSTOMER' &&
          isActionAllowed([Privileges.adminCompanyPersonView], row.companyId),
      },
      {
        label: 'front.authorized-persons.table.edit-user-data.label',
        onClick: () => goto(pages.authorizedPersonPage(row.id, ActionTypes.EDIT)),
        isVisible:
          row.creatorType === 'CUSTOMER' &&
          isActionAllowed([Privileges.adminCompanyPersonEdit], row.companyId),
      },
      {
        label: 'front.authorized-persons.table.add-role.label',
        onClick: async () => {
          await confirmModal<any, AddRoleFormProps>(AddRoleModal, {
            personId: row.id,
            customerId: row.companyId,
          });
        },
        isVisible: shouldVisibleAddRoleAction(row),
        isReloadable: true,
      },
    ],
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
];

export const AuthorizedPersonsPage = () => (
  <Page title={translate('front.auth-persons.title.label')}>
    <DataTable
      name={TableNamesEnum.AuthorizedPersonsPage}
      columns={columns}
      fetchRows={api.auth.getPersons}
      defaultSort="id"
      defaultOrder={SortOrder.Desc}
      filterComponent={AuthorizedPersonsFilter}
    />
  </Page>
);
