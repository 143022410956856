import * as React from 'react';

import 'rc-tabs/assets/index.css';

import classNames from 'classnames';
//TODO update rc-tabs
// @ts-ignore
import Tabs, { TabPane } from 'rc-tabs';
// @ts-ignore
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
// @ts-ignore
import TabContent from 'rc-tabs/lib/TabContent';

import { TabsProps } from 'components/tabs/interfaces';
import { translate } from 'i18n/translator';

import './styles.scss';

export type TabsPosition = 'top' | 'right' | 'bottom' | 'left';

const DEFAULT_PROPS: TabsProps = {
  animated: true,
  tabPosition: 'top',
  onChange: () => {},
  options: [],
};

export const TabBar: React.FC<TabsProps> = ({
  onChange,
  value,
  options,
  className,
  animated,
  tabPosition,
} = DEFAULT_PROPS) => {
  const getLabel = React.useCallback((label: string) => {
    if (typeof label === 'string') {
      return translate(label);
    }
    return label;
  }, []);

  return (
    <Tabs
      activeKey={value}
      onChange={onChange}
      renderTabBar={() => <ScrollableInkTabBar />}
      renderTabContent={() => <TabContent animated={animated} />}
      className={classNames('select-tab', className)}
      tabBarPosition={tabPosition}
    >
      {options.map(option => (
        <TabPane tab={getLabel(option.label)} key={option.value}>
          {option.content}
        </TabPane>
      ))}
    </Tabs>
  );
};
