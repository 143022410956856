import * as React from 'react';
import { TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { AcquiringStatement } from 'api/AcquiringService';
import { SortOrder } from 'api/Service';
import { Page } from 'components/layout/Page/Page';
import { CurrencyColumn } from 'components/Table/CurrencyColumn';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import * as format from 'components/utils/format';
import { getAmountNumberFormatWithComma } from 'components/utils/format';
import { translate } from 'i18n/translator';

import { AcquiringStatementsFilter } from './AcquiringStatementsFilter';

const columns: TableColumnProps<AcquiringStatement>[] = [
  {
    label: 'front.acquiring.bin.label',
    render: row => <DataColumn title={row.customerTaxCode} />,
    sort: 'taxCode',
    showDesktop: false,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.acquiring.organization.label',
    render: row => <DataColumn title={row.customerName} />,
    sort: 'name',
    showDesktop: false,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.acquiring.merchant-id.label',
    render: row => <DataColumn title={row.tradePointCode} />,
    sort: 'masterId',
    showDesktop: false,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.acquiring.trade-point.label',
    render: row => <DataColumn title={row.tradePointName} />,
    sort: 'productName',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.acquiring.terminal.label',
    render: row => <DataColumn title={row.terminalCode} />,
    sort: 'productCode',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.acquiring.date-of-transaction',
    render: row => <DataColumn title={format.dateTime(new Date(row.executeTime))} />,
    sort: 'executed',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.acquiring.card.label',
    render: row => <DataColumn title={row.cardNumber} />,
    sort: 'cardNumber',
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.acquiring.the-deal.label',
    render: row => <CurrencyColumn amount={row.amount} currency={row.currency} />,
    sort: 'currency, amount',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.acquiring.the-amount-of-commission.label',
    render: row => <DataColumn title={getAmountNumberFormatWithComma(row.comissionAmount)} />,
    sort: 'comissionAmount',
    showDesktop: false,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.acquiring.the-amount-of-compensation.label',
    render: row => (
      <DataColumn
        title={getAmountNumberFormatWithComma(row.refundAmount)}
        subTitle={getAmountNumberFormatWithComma(row.comissionAmount)}
      />
    ),
    // render: row => <CurrencyColumn amount={row.refundAmount} currency={''}/>,
    sort: 'refundAmount',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.acquiring.refund-date.label',
    render: row => <DataColumn title={row.refundDate && format.date(row.refundDate)} />,
    sort: 'refundDate',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.acquiring.code-of-transaction',
    render: row => <DataColumn title={row.rrn} subTitle={row.authCode} />,
    sort: 'authCode',
    showDesktop: false,
    showTablet: false,
    showMobile: false,
  },
  {
    renderMenu: () => [],
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

export const AcquiringStatementsPage = () => (
  <Page title={translate('front.acquiring.title.label')}>
    <DataTable
      name={TableNamesEnum.AcquiringStatements}
      columns={columns}
      fetchRows={api.acquiring.getAcquiringStatements}
      defaultSort="refundDate"
      defaultOrder={SortOrder.Desc}
      filterComponent={AcquiringStatementsFilter}
    />
  </Page>
);
