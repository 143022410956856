import * as React from 'react';
import { Col, Row } from 'react-grid';
import { useRouteMatch } from 'react-router';

import { api } from 'api';
import { Option } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { DateRangeField } from 'components/forms/inputs/DateRangeField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextField } from 'components/forms/inputs/TextField';
import { withForm } from 'components/forms/withForm';
import { SearchInput } from 'components/searchInput/SearchInput';
import { CreateButton } from 'components/Table/CreateButton';
import { useFilter } from 'components/Table/DataTable';
import { ToggleFilterButton } from 'components/Table/ToggleFilterButton/ToggleFilterButton';
import { FilterTabBar } from 'components/tabs/FilterTabBar';
import config from 'config';
import { translate } from 'i18n/translator';
import { AllowDisplayBlock } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages, PAYMENT_REQUEST_TYPE } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { PaymentRequestsFilterFormNames } from 'pages/Payments/PaymentRequest/enums';

const exposedTypesOptions = [
  {
    value: 'ExposedOrder',
    label: 'front.exposed-types.obtained.label',
  },
  {
    value: 'InvoiceOrder',
    label: 'front.exposed-types.exhibited.label',
  },
];

const PaymentRequestsFilterForm = () => {
  const [accountOptions, setAccountOptions] = React.useState<Option[]>();

  const fetchAccounts = async () => {
    const accounts = await api.accounts.getAccountsForPayment();
    const accountsOptions = accounts
      .filter(item => item.currency === config.bank.nationalCurrency)
      .map(item => ({
        value: `${item.iban || item.number}`,
        label: item.iban || item.number,
        content: item,
      }));

    setAccountOptions(accountsOptions);
  };

  React.useEffect(() => {
    fetchAccounts();
  }, []);

  const {
    params: { type },
  } = useRouteMatch<{ type: PAYMENT_REQUEST_TYPE.EXPOSED | PAYMENT_REQUEST_TYPE.INVOICE }>();
  const isExposed = type === PAYMENT_REQUEST_TYPE.EXPOSED;
  const [exposed, invoice] = exposedTypesOptions;

  const paymentType = isExposed ? exposed.value : invoice.value;
  const { updateFilter, extraFields, setPagination } = useFilter({
    extraFields: {
      searchText: undefined,
      types: paymentType,
    },
  });

  return (
    <div>
      <FilterTabBar
        onChange={types => {
          updateFilter({ types });
          goto(
            pages.paymentRequests(
              isExposed ? PAYMENT_REQUEST_TYPE.INVOICE : PAYMENT_REQUEST_TYPE.EXPOSED,
            ),
          );
        }}
        value={extraFields.types}
        options={exposedTypesOptions}
        setPagination={setPagination}
      />
      <Row>
        <Col lg={6}>
          {isExposed && (
            <AllowDisplayBlock privileges={[Privileges.paymentExposedEdit]}>
              <CreateButton
                title="front.working-documents.create.label"
                onClick={() => {
                  goto(pages.paymentRequest.tabs.details());
                }}
              />
            </AllowDisplayBlock>
          )}
        </Col>
        <Col lg={6}>
          <Row>
            <Col lg={7} md={8}>
              <SearchInput
                placeholder={translate('front.working-documents-table.filter-search.label')}
                value={extraFields.searchText}
                onChange={searchText => updateFilter({ searchText })}
              />
            </Col>
            <Col lg={5} md={4}>
              <ToggleFilterButton>
                <Row>
                  <Col md={6}>
                    <TextField
                      label="front.working-documents-filter-form.number.label"
                      name={PaymentRequestsFilterFormNames.ExactOrderNumber}
                    />
                  </Col>
                  <Col md={6}>
                    <SelectField
                      label={
                        isExposed
                          ? 'front.working-documents-filter-form.receiver-account.label'
                          : 'front.working-documents-filter-form.sender-account.label'
                      }
                      name={PaymentRequestsFilterFormNames.PayerAccount}
                      options={accountOptions}
                      clearable
                    />
                  </Col>
                  <Col md={4}>
                    <AmountField
                      label="front.working-documents-filter-form.amount-from.label"
                      name={PaymentRequestsFilterFormNames.AmountFrom}
                    />
                  </Col>
                  <Col md={4}>
                    <AmountField
                      label="front.working-documents-filter-form.amount-to.label"
                      name={PaymentRequestsFilterFormNames.AmountTo}
                    />
                  </Col>
                  <Col md={4}>
                    <DateRangeField
                      label="front.working-documents-table.date-range.label"
                      nameFrom={PaymentRequestsFilterFormNames.DateFrom}
                      nameTo={PaymentRequestsFilterFormNames.DateTo}
                      clearable
                    />
                  </Col>
                  <Col md={6}>
                    <CustomerSelect
                      label="front.internal-payment-page.order-payer-name.label"
                      name={PaymentRequestsFilterFormNames.CustomerIds}
                      multi
                    />
                  </Col>
                </Row>
              </ToggleFilterButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export const PaymentRequestsFilter = withForm(PaymentRequestsFilterForm);
