import * as React from 'react';
import { TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { DepositOffer } from 'api/ProductsService';
import { getPage, GridRequest } from 'api/Service';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { getAmountNumberFormatWithComma } from 'components/utils/format';
import { translate } from 'i18n/translator';
import { ProductLogo } from 'pages/Products/components/ProductLogo';

const columns: TableColumnProps<DepositOffer>[] = [
  {
    label: '',
    render: row => {
      const logo = <ProductLogo type={row.codeName} currency={row.currency} />;
      return <DataColumn>{logo}</DataColumn>;
    },
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: '',
    render: row => <DataColumn title={row.name} />,
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: '',
    render: row => <DataColumn title={`${getAmountNumberFormatWithComma(row.interest)} %`} />,
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: '',
    render: row => (
      <DataColumn
        topSubTitle={translate('front.deposit-calculator.table-row.term.label')}
        title={`${row.period} ${translate('front.deposit-calculator.table-row.days.label')}`}
      />
    ),
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: '',
    render: row => (
      <DataColumn
        topSubTitle={translate('front.deposit-calculator.table-row.sum-of-percent.label')}
        title={
          <>
            <span>{`${getAmountNumberFormatWithComma(row.params[0].amountMonth)} ${
              row.currency
            } /${translate('front.deposit-calculator.table-row.mounts.label')}`}</span>
            <br />
            <span>{`${getAmountNumberFormatWithComma(row.params[0].amountPeriod)} ${
              row.currency
            } /${translate('front.deposit-calculator.table-row.period.label')}`}</span>
          </>
        }
      />
    ),
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  // {
  //   label: '',
  //   render: row => (
  //     <DataColumn>
  //       <div style={{ textAlign: 'right' }}>
  //         <Button
  //           size="sm"
  //           color="primary"
  //           onClick={async () => {
  //             const importData = await api.products.calculateDeposit(row);
  //             goto(
  //               pages.demand.tabs.details('new', { type: 'NewDepositCalculateOrder' }),
  //               { importData });
  //           }}
  //         >
  //           {translate('front.deposit-calculator.button-select.label')}
  //         </Button>
  //       </div>
  //     </DataColumn>
  //   ),
  //   showDesktop: true,
  //   showTablet: true,
  //   showMobile: true,
  // },
];

export const DepositOffers: React.FC<{ offers: DepositOffer[] }> = ({ offers }) => {
  const fetchDepositOffers = async (request: GridRequest) => {
    return getPage(offers, request);
  };

  return (
    <div className="deposits-page">
      <DataTable
        name={TableNamesEnum.DepositOrder}
        columns={columns}
        fetchRows={fetchDepositOffers}
        defaultSort="id"
        hidePagination
        hideHeader
      />
    </div>
  );
};
