import React from 'react';

import { api } from 'api';
import { Instruction } from 'api/HandbooksService';
import { Option } from 'api/Service';
import { getCategoryAndTypeOptions } from 'pages/DocumentManagement/utils';

export const useCategoryAndType = () => {
  const [[categories, types], setCategoriesAndTypes] = React.useState<
    [Option<Instruction>[], Option<Instruction>[]]
  >([[], []]);

  const fetchCategory = async () => {
    const categoriesTree = await api.documentManagement.getCategories();

    setCategoriesAndTypes(getCategoryAndTypeOptions(categoriesTree));
  };

  React.useEffect(() => void fetchCategory(), []);

  return [categories, types];
};
