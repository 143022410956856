import React from 'react';
import reactHtmlParser from 'react-html-parser';
import { useRouteMatch } from 'react-router';

import { api } from 'api';
import { Announcement } from 'api/AnnouncementsService';
import { Button } from 'components/buttons/Button';
import * as format from 'components/utils/format';
import config from 'config';
import { translate } from 'i18n/translator';

import './styles.scss';

export const AnnouncementsList = () => {
  const { path } = useRouteMatch();
  const isActual = path.includes('actual');

  const [announcements, setAnnouncements] = React.useState<Announcement[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const fetchAnnouncements = async (): Promise<void> => {
    setIsLoading(true);
    const { rows } = await api.announcements.getAnnouncements(isActual);
    setAnnouncements(rows);
    setIsLoading(false);
  };

  React.useEffect(() => {
    fetchAnnouncements();
  }, [isActual]);

  if (!isLoading && !announcements.length) {
    return <h4 className="announcements-page__no-data">{translate('front.ads.no-ads.label')}</h4>;
  }

  return (
    <div className="announcements-page">
      {announcements.map(item => (
        <AnnouncementItem key={item.id} item={item} />
      ))}
    </div>
  );
};

const truncate = (text = '') => `${text.substr(0, config.page.announcements.truncateThreshold)}...`;

const AnnouncementItem = ({ item }: { item: Announcement }) => {
  const isLong = item.newsDesc.length > config.page.announcements.lengthThreshold;
  const [isOpened, setIsOpened] = React.useState(!isLong);

  return (
    <div className="announcements-page__item" key={item.id}>
      <div className="announcements-page__item__title">
        {item.link ? (
          <a href={item.link} target="_blank">
            {item.head}
          </a>
        ) : (
          <p> {item.head} </p>
        )}
      </div>
      <div className="announcements-page__item__date">{format.date(item.newsDate)}</div>
      <p className="announcements-page__item__desc">
        {!isOpened ? reactHtmlParser(truncate(item.newsDesc)) : reactHtmlParser(item.newsDesc)}
      </p>
      {isLong && (
        <Button
          color="primary"
          onClick={() => setIsOpened(!isOpened)}
          className="announcements-page__item__btn"
          size="sm"
        >
          {translate(
            isOpened ? 'front.ads.show-show-less.label' : 'front.ads.show-show-more.label',
          )}
        </Button>
      )}
    </div>
  );
};
