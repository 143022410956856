import * as React from 'react';
import { TableColumnProps, Action } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import {
  RegularPayment,
  RegularPeriodicity,
  regularPeriodicityTranslations,
} from 'api/RegularPaymentsService';
import { SortOrder } from 'api/Service';
import { Page } from 'components/layout/Page/Page';
import { CurrencyColumn } from 'components/Table/CurrencyColumn';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import * as format from 'components/utils/format';
import { translate } from 'i18n/translator';
import { isActionAllowed } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';

const columns: TableColumnProps<RegularPayment>[] = [
  {
    label: 'front.regular-payments-page-table.frequency.label',
    render: row => (
      <DataColumn
        title={translate(regularPeriodicityTranslations[row.type as RegularPeriodicity])}
      />
    ),
    sort: 'scheduleType',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.regular-payments-page-table.validity.label',
    render: row => (
      <DataColumn title={format.date(row.startDate)} subTitle={format.date(row.stopDate)} />
    ),
    sort: 'startDate',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.regular-payments-page-table.recipient.label',
    render: row => <DataColumn title={row.receiverName} subTitle={row.receiverAccount} />,
    sort: 'name',
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.regular-payments-page-table.total.label',
    render: row => <CurrencyColumn amount={row.orderAmount} currency={row.currency} />,
    sort: 'amount',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    renderMenu: order => [
      {
        label: 'front.regular-payments-page-table.actions-delete.label',
        onClick: async () => await api.regularPayments.deleteSchedule(order.id),
        isReloadable: true,
        isVisible: isActionAllowed([Privileges.paymentRegularEdit], order.customerId),
      },
    ],
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

const actions: Action[] = [
  {
    action: async selected => {
      const selectedOrdersAllowedToEdit = selected.filter(({ customerId }) =>
        isActionAllowed([Privileges.paymentRegularEdit], customerId),
      );

      if (!selectedOrdersAllowedToEdit.length) {
        return;
      }

      for (let index = 0; index < selectedOrdersAllowedToEdit.length; index = index + 1) {
        await api.regularPayments.deleteSchedule(selectedOrdersAllowedToEdit[index].id);
      }
    },
    label: 'front.regular-payments-page-table.actions-delete.label',
    isReloadable: true,
    // @ts-ignore
    isVisible: ({ customerId }) => isActionAllowed([Privileges.paymentRegularEdit], customerId),
  },
];

const onRowClick = async (row: RegularPayment): Promise<void> => {
  const {
    base: { orderType },
  } = await api.payments.getDocument(row.orderId);
  goto(
    pages.regularPayment.tabs.schedule(row.orderId, row.id, {
      type: orderType,
    }),
  );
};

export const RegularPaymentsPage = () => (
  <Page title={translate('front.regular-payments-page-table.table-name.label')}>
    <DataTable
      name={TableNamesEnum.RegularPaymentsTable}
      columns={columns}
      actions={actions}
      fetchRows={api.regularPayments.getRegularPayments}
      defaultSort="id"
      defaultOrder={SortOrder.Desc}
      onRowClick={onRowClick}
    />
  </Page>
);
