import React from 'react';

import { TableTotalInterface } from 'components/react-data-table/types';
import { isExist } from 'utils/isData';

import { AmountCard } from './AmountCard';
import { AmountCards } from './AmountCards';
import { DetailButton } from './DetailButton';
import { TableCounter } from './TableCounter';
import { getCurrencyMap, getCurrencyOptions, getCurrencyOptionsFromTotal } from './utils';

import './styles.scss';

interface TotalCurrencyProps<R = Obj> {
  getCurrencyAmount: (row: R) => [string, number];
  selectedItems: R[];
  isShowAmount?: boolean;
  total?: TableTotalInterface;
}

export const TotalCurrency = <R,>({
  children,
  selectedItems,
  total,
  getCurrencyAmount,
  isShowAmount = true,
}: React.PropsWithChildren<TotalCurrencyProps<R>>) => {
  const [isOpen, setOpen] = React.useState<boolean>(false);

  const hasSelected = selectedItems.length > 0;

  if (!isExist(total.currencies) && !hasSelected) {
    return null;
  }

  const handleClick = () => setOpen(!isOpen);

  const [nationalAmount, foreignAmounts] = hasSelected
    ? getCurrencyOptions(getCurrencyMap(selectedItems, getCurrencyAmount))
    : getCurrencyOptionsFromTotal(total.currencies);

  const hasNationalAmount = isExist(nationalAmount);

  const foreignAmountsLength = foreignAmounts.length;
  const hasMultipleCurrency =
    foreignAmountsLength > 1 || (nationalAmount && foreignAmountsLength !== 0);

  return (
    <div className="payment-amount-wrapper">
      <div className="payment-amount-wrapper__counter-wrapper">
        <TableCounter selectedCount={selectedItems.length} total={total.count} />
        <div className="wrapper-card">
          {hasNationalAmount && <AmountCard isShowAmount={isShowAmount} item={nationalAmount} />}

          {hasMultipleCurrency && (
            <div className="button-wrapper">
              <DetailButton isOpen={isOpen} count={foreignAmounts.length} onClick={handleClick} />
            </div>
          )}
        </div>
      </div>
      <div className="payment-amount-wrapper__actions-wrapper">{children}</div>
      {(isOpen || !hasNationalAmount) && (
        <AmountCards isShowAmount={isShowAmount} options={foreignAmounts} />
      )}
    </div>
  );
};
