import { getLabelLink } from 'components/RenderLabelWithLink/RenderLabelWithLink';
import config from 'config';

export const translationsCustomError = (errorMessage?: string) => ({
  useOtherTokenOrFormatThis: getLabelLink(
    `${errorMessage}  \nВикористайте інший токен або відформатуйте існуючий. Утиліта для форматування тут`,
    [config.links.formattingUtility],
    ['тут'],
  ),
});

export const cipherError = {
  useCertificateIsProhibited: 'Використання сертифіката заборонено',
};
