import { get } from 'api/backend';
import { Option } from 'api/Service';

type CountriesResponse = {
  meta: any;
  rows: Country[];
  total: any;
};

export type Country = {
  countryCode: string;
  countryName: string;
  deleted: boolean;
  fullCountryName: string;
  id: number;
  threeLetterCode: string;
  twoLetterCode: string;
  externalId?: string | number;
};

export class CountryService {
  async getCounty(): Promise<CountriesResponse> {
    return await get('/country', { sort: 'country_Name' });
  }

  async getCountryOptions(): Promise<Option<Country, string | number>[]> {
    const { rows } = await this.getCounty();

    return rows.map(country => ({
      value: country.id,
      label: `${country.countryName} / ${country.countryCode}`,
      content: country,
    }));
  }
}
