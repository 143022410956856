import * as React from 'react';

import './styles.scss';
import { translate } from 'i18n/translator';

export const InProgressPage = () => (
  <div className="page-in-progress">
    {translate('front.this-page-will-be-implemented-later.label')}
  </div>
);
