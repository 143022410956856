import React, { useEffect } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { useForm } from 'components/forms/ValidatingForm/useForm';
import { CashTransferDetailNames } from 'pages/Demands/CashTransferPage/enums';
import { MAX_LENGTH_PURPOSE } from 'pages/Demands/CashTransferPage/utils';

export const DEBOUNCED_DELAY = 500;

export const useMaxLengthPurpose = () => {
  const { getFieldValue } = useForm();

  const [maxLengthPurpose, setMaxLengthPurpose] = React.useState(MAX_LENGTH_PURPOSE);

  const secondPurpose = (getFieldValue(CashTransferDetailNames.SecondPurpose) as string) ?? '';

  const additionalDetails =
    (getFieldValue(CashTransferDetailNames.AdditionalDetails) as string) ?? '';

  const handleUpdateMaxLength = () =>
    setMaxLengthPurpose(MAX_LENGTH_PURPOSE - secondPurpose.length - additionalDetails.length);

  const debounced = useDebouncedCallback(handleUpdateMaxLength, DEBOUNCED_DELAY);

  useEffect(() => {
    debounced();
  }, [secondPurpose, additionalDetails]);

  return maxLengthPurpose;
};
