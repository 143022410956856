import { Certificate } from 'api/CertificateService/interfaces';
import { ConstantEnum } from 'api/enums';
import { confirmationModal } from 'components/modals/ConfirmModal';
import { showExpiredCustomersModal } from 'components/TermExpirationModal/TermExpirationModal';
import { getEndDateCustomers } from 'components/TermExpirationModal/utils';
import { translate } from 'i18n/translator';
import { getUpdatedCustomers, handleClickNotShowAgain } from 'pages/Dashboard/utils';
import { isAvtorCertificate } from 'pages/Profile/Certificates/utils';
import { AgreementModal } from 'pages/utils/SignOrders/AgreementModal';
import { AgreementModalResult } from 'pages/utils/SignOrders/enums';
import { ShortDocument, ShortOrder } from 'pages/utils/SignOrders/signOrders';
import { toUniqueArray } from 'pages/utils/utils';
import store from 'store';
import { LOCAL_STORAGE_KEY } from 'store/actions/types';
import { isExist } from 'utils/isData';
import { LocalStorage } from 'utils/LocalStorage';

interface switchSignApplicationsProps {
  certificates: Array<Certificate>;
  warningMessage: string;
}

export const switchSignApplications = async ({
  warningMessage,
  certificates,
}: switchSignApplicationsProps): Promise<AgreementModalResult | boolean> => {
  if (!certificates.length) {
    await confirmationModal(
      'front.confirm-modal.switch-sign.action-is-prohibited.label',
      false,
      false,
    );

    return;
  }

  const hasAvtorCsk = certificates.some(cert => isAvtorCertificate(cert));
  const hasOtherCsk = certificates.some(cert => !isAvtorCertificate(cert));

  if (hasOtherCsk && hasAvtorCsk) {
    return await AgreementModal(warningMessage);
  }

  return !hasAvtorCsk ? AgreementModalResult.Yes : AgreementModalResult.No;
};

export const checkExpirationSign = async () => {
  const {
    userState: { customers, currentAuthPerson },
    constantState,
  } = store.getState();

  const [endSignCustomers] = getEndDateCustomers(
    customers,
    +constantState[ConstantEnum.CompanyPersonDaysToExpire],
  );

  const userSignExpired = LocalStorage.getItem(LOCAL_STORAGE_KEY.IS_EXPIRATION_SIGN_MODAL_HIDDEN)?.[
    currentAuthPerson
  ];

  const newEndSignCustomers = isExist(userSignExpired)
    ? getUpdatedCustomers(endSignCustomers, userSignExpired)
    : endSignCustomers;

  const shouldRenderSignExpirationModal = newEndSignCustomers.length > 0;

  shouldRenderSignExpirationModal &&
    (await showExpiredCustomersModal(
      newEndSignCustomers,
      translate('front.modal-auth-person-term-expiring-signing-forbidden-modal.label'),
      () =>
        handleClickNotShowAgain(
          LOCAL_STORAGE_KEY.IS_EXPIRATION_SIGN_MODAL_HIDDEN,
          currentAuthPerson,
          newEndSignCustomers,
        ),
    ));
};

export const prepareDataForSing = (
  orders: ShortDocument[],
  certificates: Certificate[],
): [ShortOrder[], number[]] => {
  const shortOrders = orders.reduce<ShortOrder[]>(
    (ordersWithOneCustomer, order) => [
      ...ordersWithOneCustomer,
      ...mapShortDocumentToShortOrders(order),
    ],
    [],
  );

  const customerIdsWithKepCertificate = toUniqueArray(
    certificates.map(certificate => certificate.customerId),
  );

  const filteredOrdersByCertificate = shortOrders.filter(({ customerId }) =>
    customerIdsWithKepCertificate.includes(customerId),
  );

  return [filteredOrdersByCertificate, customerIdsWithKepCertificate];
};

const mapShortDocumentToShortOrders = (order: ShortDocument): ShortOrder[] =>
  order.customerIds.map(customerId => ({
    id: order.id,
    customerId,
    isSignFiles: order.isSignFiles,
  }));
