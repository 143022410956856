import * as React from 'react';
import { TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';
import moment from 'moment';

import { api } from 'api';
import { SendRequisites } from 'api/AccountService';
import {
  AccountActions,
  AccountView,
  getStatusColor,
  isActionAvailable,
  ProductStatus,
} from 'api/ProductsService';
import { getTotal, GridRequest, Option, SortOrder, TotalInterface } from 'api/Service';
import * as Icon from 'components/icons';
import { Page } from 'components/layout/Page/Page';
import { Status } from 'components/layout/Status';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { ActionComponentType } from 'components/react-data-table/types';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { TotalCurrency } from 'components/TotalCurrency/TotalCurrency';
import * as format from 'components/utils/format';
import { translate } from 'i18n/translator';
import { isActionAllowed } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { NEW } from 'navigations/routes';
import { EmailModal, EmailModalProps } from 'pages/AccountStatements/EmailModal';
import { SuccessModal } from 'pages/AccountStatements/SuccessModal';
import { getCurrencyAmount } from 'pages/Products/Accounts/utils';
import { ProductLogo } from 'pages/Products/components/ProductLogo';

import { AccountDetails } from './AccountDetails';
import { AccountIban } from './AccountIban';
import { AccountFilterFields, AccountsFilter } from './AccountsFilter';

interface EmailProps extends EmailModalProps {
  params: {
    accountId: number;
  };
}

const tabLabel = {
  all: 'front.product-accounts-filter.statuses-all.label',
  [ProductStatus.Active]: 'front.product-accounts-filter.statuses-opened.label',
  [ProductStatus.Closed]: 'front.product-accounts-filter.statuses-closed.label',
  // [AccountStatus.Blocked]: translate('front.product-accounts-filter.statuses-blocked.label'),
};

const getStatusesOptions = (total: TotalInterface): Array<Option> =>
  Object.entries(tabLabel).map(([value, label]) => ({
    value,
    label: (
      <span className="accounts-filter__tab">
        {translate(label)}
        <span className="accounts-filter__tab__badge">
          {value === 'all'
            ? Object.values(total.status).reduce((a, b) => a + b, 0)
            : total.status[value] || 0}
        </span>
      </span>
    ),
  }));

const columns: TableColumnProps<AccountView>[] = [
  {
    label: 'front.working-accounts-table.pictogram.label',
    labelIcon: () => (
      <DataColumn>
        <Icon.Pictogram />
      </DataColumn>
    ),
    render: row => {
      const logo = <ProductLogo type={row.typeTranslation} currency={row.currency} />;
      return <DataColumn>{logo}</DataColumn>;
    },
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.working-accounts-table.edit.label',
    labelIcon: () => (
      <DataColumn>
        <Icon.Edit />
      </DataColumn>
    ),
    render: row => {
      const editIcon = <Icon.Edit />;
      const title = (
        <div className="account-name">
          {isActionAllowed([Privileges.productAccountEdit], row.customerId) && (
            <span
              onClick={e => {
                e.stopPropagation();
                goto(pages.account(row.id));
              }}
              className="account-name__edit"
            >
              {editIcon}
            </span>
          )}
        </div>
      );
      return <DataColumn>{title}</DataColumn>;
    },
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.working-accounts-table.account-name.label',
    render: row => {
      const name = row.alias || row.typeTranslation;
      const title = <span className="account-name__title">{name}</span>;
      return <DataColumn>{title}</DataColumn>;
    },
    showDesktop: true,
    showTablet: true,
    showMobile: false,
    showOnlySelectedDevices: true,
  },
  {
    label: 'front.working-accounts-table.organization-name.label',
    render: row => {
      return <DataColumn titleClassName="account-name__title" title={row.customerName} />;
    },
    showDesktop: true,
    showTablet: false,
    showMobile: false,
    showOnlySelectedDevices: true,
  },
  {
    label: 'front.working-accounts-table.iban-name.label',
    render: row => {
      const iban = <AccountIban iban={row.iban} />;
      return <DataColumn>{iban}</DataColumn>;
    },
    showDesktop: true,
    showTablet: false,
    showMobile: false,
    showOnlySelectedDevices: true,
  },
  {
    label: 'front.working-account-details-tablet.label',
    render: acc => (
      <DataColumn title={<AccountIban iban={acc.iban} />} topSubTitle={acc.customerName} />
    ),
    showDesktop: false,
    showTablet: true,
    showMobile: false,
    showOnlySelectedDevices: true,
  },
  {
    label: 'front.working-accounts-table.label.label',
    render: row => (
      <DataColumn>
        <Status color={getStatusColor(row.status as ProductStatus)}>{row.statusTranslation}</Status>
      </DataColumn>
    ),
    showDesktop: true,
    showTablet: false,
    showMobile: false,
    showOnlySelectedDevices: true,
  },
  {
    label: 'front.product-accounts-togglerow.last-write-off.label',
    render: row =>
      row.lastExpenseTime && (
        <div className="account-date">
          <span className="account-date__icon-debit">D</span>
          <span>
            <DataColumn
              title={moment(row.lastExpenseTime).format('DD.MM.YYYY')}
              subTitle={moment(row.lastExpenseTime).format('HH:mm:ss')}
            />
          </span>
        </div>
      ),
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.product-accounts-togglerow.last-enrollment.label',
    render: row =>
      row.lastIncomeTime && (
        <div className="account-date">
          <span className="account-date__icon-credit">C</span>
          <span>
            <DataColumn
              title={moment(row.lastIncomeTime).format('DD.MM.YYYY')}
              subTitle={moment(row.lastIncomeTime).format('HH:mm:ss')}
            />
          </span>
        </div>
      ),
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.working-accounts-table.amount.label',
    render: acc => {
      const balance = format.getAmount(Number(acc.balance));
      const title = `${balance} ${acc.currency}`;
      return <DataColumn titleClassName="account-amount" title={title} />;
    },
    showDesktop: true,
    showTablet: true,
    showMobile: false,
    showOnlySelectedDevices: true,
  },
  {
    label: 'front.working-account-details-mobile.label',
    render: acc => (
      <DataColumn
        title={<AccountIban iban={acc.iban} />}
        topSubTitle={acc.customerName}
        subTitle={`${format.getAmount(Number(acc.balance))} ${acc.currency}`}
      />
    ),
    showDesktop: false,
    showTablet: false,
    showMobile: true,
    showOnlySelectedDevices: true,
  },
  {
    renderMenu: item => [
      {
        label: 'front.product-accounts-page.menu-statements.label',
        onClick: () => goto(pages.statements({ accountId: item.id })),
        isVisible:
          isActionAvailable<AccountActions>(item.actions, AccountActions.STATEMENT) &&
          isActionAllowed([Privileges.financeStatementView], item.customerId),
      },
      {
        label: 'front.product-accounts-page.menu-history.label',
        onClick: () => goto(pages.transactionsHistory({ accountId: item.id })),
        isVisible:
          isActionAvailable(item.actions, AccountActions.HISTORY) &&
          isActionAllowed([Privileges.financeTransactionHistoryView], item.customerId),
      },
      {
        label: 'front.product-accounts-page.menu-payment.label',
        onClick: () =>
          goto(
            pages.internalPayments.tabs.details(NEW, {
              payerAccount: `${item.id}`,
              customerId: item.customerId,
            }),
          ),
        isVisible:
          isActionAvailable(item.actions, AccountActions.TRANSFER) &&
          isActionAllowed(
            [Privileges.paymentInternalEdit, Privileges.paymentDomesticEdit],
            item.customerId,
          ),
      },
      {
        label: 'front.product-accounts-page.menu-int-transfer.label',
        onClick: () =>
          goto(
            pages.foreignPayment.tabs.details(NEW, {
              payerAccount: `${item.id}`,
              customerId: item.customerId,
            }),
          ),
        isVisible:
          isActionAvailable(item.actions, AccountActions.INT_TRANSFER) &&
          isActionAllowed([Privileges.paymentForeignEdit], item.customerId),
      },
      {
        label: 'front.product-accounts-page.menu-download-requisites.label',
        onClick: () => goto(pages.accountRequisites({ accountId: item.id })),
        isVisible:
          isActionAvailable(item.actions, AccountActions.REQUISITES) &&
          isActionAllowed([Privileges.productAccountView], item.customerId),
      },
      {
        label: 'front.product-accounts-page.menu-send-requisites.label',
        onClick: async () => {
          const onSend = async (params: SendRequisites) =>
            await api.accounts.sendRequisties(params);

          const sendRequisites = await confirmModal<boolean, EmailProps>(EmailModal, {
            onSend,
            fieldName: 'to',
            params: { accountId: item.id },
          });
          sendRequisites && (await confirmModal(SuccessModal));
        },
        isVisible:
          isActionAvailable(item.actions, AccountActions.REQUISITES) &&
          isActionAllowed([Privileges.productAccountView], item.customerId),
      },
    ],
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

const Actions: ActionComponentType<AccountView> = ({ total, selectedItems, children }) => (
  <TotalCurrency<AccountView>
    total={total}
    selectedItems={selectedItems}
    getCurrencyAmount={getCurrencyAmount}
  >
    {children}
  </TotalCurrency>
);

const fetchAccounts = async (request: GridRequest & AccountFilterFields) => {
  const { subType, status, ...restRequest } = request;
  const { rows, total } = await api.products.getAccounts({
    ...restRequest,
    status: status !== 'all' ? status : undefined,
    subTypes: [subType],
    type: subType ? null : 'all', // workaround for API hardcode
    forAll: true,
    size: 0,
  });

  return {
    rows,
    total: getTotal({ count: total.count, byCurrency: total.balances }),
    tabOptions: getStatusesOptions(total),
  };
};

export const AccountsPage: React.FC = () => (
  <div className="accounts-page">
    <Page title={translate('front.product-accounts-page.title.label')}>
      <DataTable
        name={TableNamesEnum.AccountsTable}
        columns={columns}
        fetchRows={fetchAccounts}
        defaultSort="product_sub_type.displayOrder|asc,product.opened|desc"
        defaultOrder={SortOrder.Asc}
        filterComponent={AccountsFilter}
        actionsComponent={Actions}
        expandRowComponent={AccountDetails}
        hidePagination
      />
    </Page>
  </div>
);
