import axios, { AxiosResponse } from 'axios';
import qs from 'qs';

import { post } from 'api/backend';
import { ResponseDataException, Translation } from 'api/CertificateService/UmcaModule/interfaces';
import { getLocalizeErrorMessage } from 'api/CertificateService/UmcaModule/translation';
import config from 'config';
import { LOCAL_STORAGE_KEY } from 'store/actions/types';
import { LocalStorage } from 'utils/LocalStorage';

const parseDetailErrorMessages = (failureCause: string) => failureCause?.split(`.\n`)[0];

const handleError = (responseError: AxiosResponse) => {
  const { config, data, status } = responseError;

  const message = {
    configRequest: {
      url: config.url,
      method: config.method,
      params: config.params,
      payload: config.data,
    },
    response: {
      status,
      errorObject: data,
    },
  };

  const stringifyError = JSON.stringify(message);

  post('/v1/front-log', { message: stringifyError, level: 'ERROR' });
};

const client = axios.create({
  baseURL: config.utils.ecp.CIPHER_API_URL,
});

client.interceptors.request.use(config => {
  config.paramsSerializer = params => qs.stringify(params, { arrayFormat: 'brackets' });

  return config;
});

client.interceptors.response.use(
  response => response,
  error => {
    const activeLocale: keyof Translation = LocalStorage.getItem(
      LOCAL_STORAGE_KEY.ACTIVE_LOCALE,
      config.i18n.defaultLanguageCode,
    );

    const { response } = error;
    let responseData = {} as ResponseDataException;

    if (response) {
      handleError(response);

      const { status, statusText, data } = response;
      responseData = {
        status,
        statusText,
        code: status ?? 499,
        message: parseDetailErrorMessages((data || {}).failureCause) ?? (data || {}).message,
        type: 'UserError',
      };
    } else {
      responseData = {
        code: 499,
        type: 'NetworkError',
        message: config.utils.ecp.NETWORK_MESSAGE,
      };
    }

    //TODO It is possible to transfer cipher errors from api and components here
    return Promise.reject(getLocalizeErrorMessage(responseData, activeLocale));
  },
);

export default client;
