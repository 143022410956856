import React from 'react';

import { RenderLabelWithLink } from 'components/RenderLabelWithLink/RenderLabelWithLink';
import config from 'config';

export const CertificatesError: React.FC = () => (
  <h3 className="confirm-modal__message">
    {RenderLabelWithLink(
      'front.profile.certificates-kep.error.label',
      [config.links.formattingUtility],
      ['front.here.label'],
    )}
  </h3>
);
