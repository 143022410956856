import * as React from 'react';
import { Col, Row, Container } from 'react-grid';

import { NotificationInterface } from 'api/UserService';
import * as Icon from 'components/icons';
import * as format from 'components/utils/format';
import { translate } from 'i18n/translator';

interface Props {
  data: NotificationInterface[];
  onClick?: (notification: NotificationInterface) => any;
  onClose?: (notification: NotificationInterface) => any;
}

interface NotificationProps {
  data: NotificationInterface;
  onClick?: (e: React.MouseEvent) => any;
  onClose?: (e: React.MouseEvent) => any;
}

interface HeaderProps {
  onReadAll: () => void;
}

const Notification: React.FC<NotificationProps> = ({ data, onClose, onClick }) => (
  <li key={data.id} onClick={onClick}>
    <Row>
      <Col sm={10}>{format.dateTime(data.generateTime)}</Col>
      <Col sm={2} hAlign="end">
        <span className="close-icon" onClick={onClose}>
          <Icon.CloseDocument />
        </span>
      </Col>
      <Col>{data.messageText}</Col>
    </Row>
  </li>
);

export const NotificationsHeader: React.FC<HeaderProps> = ({ onReadAll }) => (
  <div className="notifications-header">
    <Container>
      <Row>
        <Col sm={6}>{translate('front.header.notifications.label')}</Col>
        <Col sm={6}>
          <span className="notifications-header__read-all" onClick={onReadAll}>
            {translate('front.header.notifications.clear-all.label')}
          </span>
        </Col>
      </Row>
    </Container>
  </div>
);

export const NotificationsList: React.FC<Props> = React.memo(({ data, onClose, onClick }) => {
  const closeHandler = React.useCallback(
    item => (e: React.MouseEvent) => {
      e.stopPropagation();
      onClose(item);
    },
    [],
  );

  const clickHandler = React.useCallback(item => () => onClick(item), []);

  if (!data?.length) {
    return <span>{translate('front.header.no-notifications.label')}</span>;
  }

  return (
    <Container>
      <ul className="notifications-list">
        {data.map(item => (
          <Notification
            onClose={closeHandler(item)}
            onClick={clickHandler(item)}
            key={item.id}
            data={item}
          />
        ))}
      </ul>
    </Container>
  );
});
