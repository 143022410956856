import * as React from 'react';

import { api } from 'api';
import { RegistrationSteps, RegistrationResponse } from 'api/RegistrationService';
import { ActivationErrorPage } from 'pages/Registration/activation/ActivationErrorPage';
import { ActivateExecutedPage } from 'pages/Registration/activation/ActivationExecutedPage';
import { ActivationFilesPage } from 'pages/Registration/activation/ActivationFilesPage';
import { ActivationRejectPage } from 'pages/Registration/activation/ActivationRejectPage';
import { ActivationResultPage } from 'pages/Registration/activation/ActivationResultPage';
import { ActivationStatusPage } from 'pages/Registration/activation/ActivationStatusPage';
import { CourierRegistrationPage } from 'pages/Registration/activation/CourierRegistrationPage';

import { ActivationContext } from './ActivationContext';

const initialStep: RegistrationResponse = {
  orderState: null,
};

export const ActivationPage = () => {
  const [step, setStep] = React.useState<RegistrationResponse>(initialStep);

  const getRegistrationOrder = async () => {
    setStep(await api.registration.getRegistrationOrder());
  };

  React.useEffect(() => {
    getRegistrationOrder();
  }, []);

  const renderSteps = () => {
    switch (step.orderState) {
      case RegistrationSteps.DoShowRegistrationResult: {
        return <ActivationResultPage />;
      }
      case RegistrationSteps.Rejected:
      case RegistrationSteps.Stopped: {
        return <ActivationRejectPage />;
      }
      case RegistrationSteps.Error: {
        return <ActivationErrorPage />;
      }
      case RegistrationSteps.Executed: {
        return <ActivateExecutedPage />;
      }
      case RegistrationSteps.DoShowPassportScan: {
        return <ActivationFilesPage />;
      }
      case RegistrationSteps.DoShowCourierRequest: {
        return <CourierRegistrationPage />;
      }
      case RegistrationSteps.DoShowActivationStatus: {
        return <ActivationStatusPage />;
      }
      default: {
        return null;
      }
    }
  };

  const contextValue = {
    step,
    setStep,
  };

  return (
    <>
      <ActivationContext.Provider value={contextValue}>{renderSteps()}</ActivationContext.Provider>
    </>
  );
};
