import * as React from 'react';
import { Col, Row, Container } from 'react-grid';

import { SearchInput } from 'components/searchInput/SearchInput';
import { useFilter } from 'components/Table/DataTable';
import { translate } from 'i18n/translator';

export const PersonalOrganizationsFilter = () => {
  const { updateFilter, extraFields } = useFilter({
    extraFields: { searchText: '' },
  });

  return (
    <Container>
      <Row justifyContent="flex-end">
        <Col md={7}>
          <SearchInput
            placeholder={translate('front.working-documents-table.filter-search.label')}
            value={extraFields.searchText}
            onChange={searchText => updateFilter({ searchText })}
          />
        </Col>
      </Row>
    </Container>
  );
};
