import { APPLICATION_INIT } from 'store/actions/types';
import { ActionInterface } from 'store/reducers/interfaces';

export interface ApplicationInitState {
  isServerTimeInit: boolean;
}

const initialState = {
  isServerTimeInit: false,
};

export const applicationInitReducer = (
  state = initialState,
  action: ActionInterface,
): ApplicationInitState => {
  switch (action.type) {
    case APPLICATION_INIT.SET_SERVER_TIME_INIT: {
      return { ...state, isServerTimeInit: action.payload };
    }
    default:
      return state;
  }
};
