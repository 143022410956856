import React from 'react';

import { api } from 'api';
import { CertificateStatus, CertificateType } from 'api/CertificateService/enums';
import { isUmcaVersionAvailable } from 'api/CertificateService/UmcaModule/UmcaService';
import { OrderFields } from 'api/interfaces';
import { checkOS } from 'api/Service';
import { Button } from 'components/buttons/Button';
import { confirmationModal } from 'components/modals/ConfirmModal';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import config from 'config';
import { translate } from 'i18n/translator';
import { NoConnectionModal } from 'pages/Profile/Certificates/Modals/NoConnectionModal';
import { CertificateSign } from 'pages/utils/SignOrders/CertificateSign/CertificateSign';
import { KepCertificateSign } from 'pages/utils/SignOrders/CertificateSign/KepCertificateSign';
import { AgreementModalResult } from 'pages/utils/SignOrders/enums';
import {
  checkExpirationSign,
  prepareDataForSing,
  switchSignApplications,
} from 'pages/utils/SignOrders/utils';
import { toUniqueArray } from 'pages/utils/utils';

import { SmsModal } from './SmsSign/SmsModal';

export interface ShortOrder {
  customerId: number;
  id: number;
  isSignFiles?: boolean;
}

export interface ShortDocument {
  customerIds: number[];
  id: number;
  isSignFiles?: boolean;
}

export interface PropsSign {
  customerIds: Array<number>;
  orders: Pick<OrderFields, 'id' | 'customerId' | 'isSignFiles'>[];
  canUseMultiKey?: boolean;
  certificateTypes?: string[];
  ordersCount?: number;
}

export const signOrders = async (ids: Array<number>, ordersCount?: number) =>
  await confirmModal<boolean>(SmsModal, { ids, ordersCount }, 426);

const renderButtons = (resolve: (resolve: boolean) => void) => (
  <>
    <a
      target="_blank"
      href={config.page.certificate.downloadUmcaLinks[checkOS()]}
      className="bs-button btn btn-primary btn-md"
    >
      {translate('front.certificates.error-modal-solve.label')}
    </a>
    <Button onClick={() => resolve(null)} color="secondary">
      {translate('front.confirm-modal.continue.label')}
    </Button>
  </>
);

export const signCertificateOrders = async (orders: Array<ShortOrder>, ordersCount?: number) => {
  const customerIds = toUniqueArray(orders.map(item => item.customerId));
  const certificates = await api.certificate.getCertificates(customerIds, true, {
    statuses: CertificateStatus.Active,
  });

  const result = await switchSignApplications({
    certificates,
    warningMessage: 'front.confirm-modal.switch-sign.label',
  });

  if (!result) {
    return;
  }

  const useAvtorCsk = result === AgreementModalResult.No;

  if ((useAvtorCsk && (await api.certificate.isUmcaAvailable())) || !useAvtorCsk) {
    if (useAvtorCsk && !(await isUmcaVersionAvailable())) {
      await confirmModal<boolean>(NoConnectionModal, {
        renderButtons,
        title: 'front.certificates.warning-modal-error.label',
        description: 'front.certificates.update-umca-warning-modal.label',
      });
    }

    await checkExpirationSign();

    return await confirmModal<boolean, PropsSign>(
      useAvtorCsk ? CertificateSign : KepCertificateSign,
      { orders, customerIds, ordersCount, canUseMultiKey: true },
      426,
    );
  }

  return await confirmModal<boolean>(NoConnectionModal, {
    description: 'front.certificates.error-modal-umca-text.label',
    useCipherApp: false,
  });
};

export const signKepCertificateOrders = async (
  orders: Array<ShortDocument>,
  ordersCount?: number,
) => {
  const customerIds = toUniqueArray(orders.map(item => item.customerIds).flat());
  const certificateTypes = [CertificateType.Kep, CertificateType.Seal];
  const certificates = await api.certificate.getCertificates(customerIds, false, {
    statuses: CertificateStatus.Active,
    certificateTypes,
  });

  if (certificates.length === 0) {
    await confirmationModal('front.document-management.sign-error-message.label', false);
    return;
  }

  await checkExpirationSign();

  const [ordersFilterByCertificate, customerIdsWithKepCertificate] = prepareDataForSing(
    orders,
    certificates,
  );

  return await confirmModal<boolean, PropsSign>(
    KepCertificateSign,
    {
      orders: ordersFilterByCertificate,
      customerIds: customerIdsWithKepCertificate,
      ordersCount,
      canUseMultiKey: false,
      certificateTypes,
    },
    426,
  );
};
