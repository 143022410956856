import * as React from 'react';

import { Option } from 'api/Service';
import { useField } from 'components/forms/ValidatingForm/components/useField';
import { validateMessages } from 'components/validateMessages';
import { translate } from 'i18n/translator';

import { Autocomplete } from '../select/Autocomplete';
import { FieldInput } from './FieldInput';
import { PreloadField } from './PreoadField';

export interface SelectProps {
  name: string;
  options: Option<any, string | string[]>[];
  clearable?: boolean;
  defaultValue?: string | string[];
  disabled?: boolean;
  isCreatableSelect?: boolean;
  isMenuMaxContentWidth?: boolean;
  isSearchable?: boolean;
  label?: string;
  multi?: boolean;
  onBlur?: () => void;
  onlySearch?: boolean;

  onSelectOption?(value: any, content?: any): void;

  placeholder?: string;
  required?: boolean;

  validate?: (value: string, values?: string[]) => string | null;
}

export const SelectField = ({
  name,
  label,
  placeholder,
  required,
  disabled,
  defaultValue,
  options,
  multi,
  onBlur,
  isSearchable,
  clearable,
  onSelectOption,
  isMenuMaxContentWidth,
  onlySearch,
  validate: externalValidate,
  isCreatableSelect,
}: SelectProps) => {
  const validate = React.useCallback(
    (value, values) => {
      const isEmptyField = Array.isArray(value) ? !value?.length : !value;

      if (required) {
        if (isEmptyField) {
          return translate(validateMessages.fieldRequired);
        }
      }

      return externalValidate ? externalValidate(value, values) : null;
    },
    [required, externalValidate],
  );

  const onChangeSelect = (
    e: React.ChangeEvent<HTMLInputElement> | string,
    onChange: (value: any) => void,
  ) => {
    const value =
      (e as React.ChangeEvent<HTMLInputElement>)?.target?.value || (e as string) || null;
    onChange(value);
    onSelectOption && onSelectOption(value, options.find(o => o.value === value)?.content);
  };

  const translatedOptions = React.useMemo(
    () => options?.map(o => ({ ...o, label: translate(o.label) })) ?? [],
    [options],
  );

  const { field, form } = useField({
    name,
    defaultValue,
    validate,
    required,
    shouldNormalize: false,
  });

  if (!field && !form) {
    return null;
  }

  const handleBlur = () => {
    onBlur?.();
    field.onBlur();
  };

  return (
    <PreloadField isLoading={form.initializing}>
      <FieldInput
        isFocus
        isDisabled={disabled}
        name={name}
        label={label}
        error={field.error}
        warning={field.warning}
        value={field.value}
      >
        <Autocomplete
          name={name}
          value={(field.value as string) || ''}
          onChange={e => onChangeSelect(e, field.onChange)}
          placeholder={placeholder}
          onBlur={handleBlur}
          disabled={disabled || !options || form.disabled || form.progress}
          multi={multi}
          isSearchable={isSearchable}
          options={translatedOptions}
          clearable={clearable}
          isMenuMaxContentWidth={!!isMenuMaxContentWidth}
          onlySearch={onlySearch}
          isCreatableSelect={isCreatableSelect}
        />
      </FieldInput>
    </PreloadField>
  );
};
