import { Align, SizeConfig } from './types';

export const calculateSize = <S extends SizeConfig>(currentWidth: number, sizeConfig: S) => {
  let size = Object.keys(sizeConfig)[0];
  for (const [sizeName, breakpoint] of Object.entries(sizeConfig)) {
    size = sizeName;
    if (currentWidth <= breakpoint) {
      break;
    }
  }
  return size;
};

export const toFlexAligns = (align: Align) => {
  if (!align) return undefined;
  switch (align) {
    case 'start':
      return 'flex-start';
    case 'middle':
      return 'center';
    case 'end':
      return 'flex-end';
  }
};
