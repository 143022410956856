import * as React from 'react';

import { OrderState } from 'api/enums';
import { Order } from 'api/OrderService';
import { Status } from 'components/layout/Status';
import { translate } from 'i18n/translator';
import { isExist } from 'utils/isData';

const getOrderStatus = (status: OrderState): string =>
  translate(`front.order-state.${status.toLowerCase()}.label`);

interface RenderStatusComponentProps<T> {
  order: Order<T>;
  StatusComponent?: React.FunctionComponent<{ order: Order }>;
}

export const RenderStatusComponent = <T,>({
  order,
  StatusComponent,
}: RenderStatusComponentProps<T>) =>
  isExist(StatusComponent) ? (
    <StatusComponent order={order} />
  ) : (
    <Status>{getOrderStatus(order?.state as OrderState)}</Status>
  );
