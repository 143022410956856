import moment from 'moment';

import { api } from 'api';
import { ExportStatementRequest } from 'api/AccountService';
import { GridRequest } from 'api/Service';
import { ExportFormatEnum } from 'components/ExportDropdown/enum';
import { Actions, getExportActions, MTFormat } from 'components/ExportDropdown/ExportDropdown';
import { ExportingDocuments } from 'components/ExportingDocuments/ExportingDocuments';
import { ModalExportProps } from 'components/ExportingDocuments/types';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';

const DEFAULT_WIDTH_MODAL_EXPORT = 864;
export const MAX_VISIBLE_TABLE_ROWS = 7;

const EXPORT_TYPES: Record<string, ExportFormatEnum> = {
  XLS: ExportFormatEnum.EXCEL,
  ['xls (PDF)']: ExportFormatEnum.XLS_PDF,
  CSV: ExportFormatEnum.CSV,
  DBF: ExportFormatEnum.DBF,
  PDF: ExportFormatEnum.PDF,
  MT940: ExportFormatEnum.MT940,
  MT940f: ExportFormatEnum.MT940f,
  MT942: ExportFormatEnum.MT942,
};

const ENCODINGS = {
  CP1251: 'CP1251',
  UTF8: 'UTF-8',
};

const today = moment().format('YYYY-MM-DD');

const getConfigExport = (exportType: string) => {
  switch (exportType) {
    case EXPORT_TYPES.MT940:
    case EXPORT_TYPES.MT940f:
    case EXPORT_TYPES.MT942:
      return { formatType: MTFormat.MT, encoding: ENCODINGS.UTF8 };
    case EXPORT_TYPES['xls (PDF)']:
      return { formatType: EXPORT_TYPES.XLS, encoding: ENCODINGS.CP1251, exportType: exportType };
    default:
      return { formatType: exportType, encoding: ENCODINGS.CP1251 };
  }
};

export const getActions = (
  formData: ExportStatementRequest,
  pagination: GridRequest,
): Actions[] => {
  const action = (exportType: string) => {
    return async () => {
      const configExport = getConfigExport(exportType);

      if (exportType === MTFormat.MT940) {
        return await api.accounts.exportMt940Statement({
          ...formData,
          format: exportType,
          encoding: configExport.encoding,
        });
      }

      if (exportType === MTFormat.MT942) {
        return await api.accounts.exportMt940Statement({
          ...formData,
          dateFrom: today,
          dateTo: today,
          showZeroBalances: true,
          format: exportType,
          encoding: configExport.encoding,
        });
      }

      if (exportType === ExportFormatEnum.XLS_PDF || exportType === ExportFormatEnum.PDF) {
        const exportData = {
          sort: pagination.sort,
          order: pagination.order,
          ...formData,
          ...configExport,
          time: moment(formData.time).toISOString(),
        };

        return await confirmModal<void, ModalExportProps>(
          ExportingDocuments,
          { exportData, exportType },
          DEFAULT_WIDTH_MODAL_EXPORT,
        );
      }

      return await api.accounts.exportStatements({
        sort: pagination.sort,
        order: pagination.order,
        ...formData,
        ...configExport,
        time: moment(formData.time).toISOString(),
      });
    };
  };

  return getExportActions({
    action,
    exportTypes: EXPORT_TYPES,
  });
};
