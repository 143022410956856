import * as React from 'react';
import { Col, Row } from 'react-grid';

import { api } from 'api';
import { PaymentTypes } from 'api/enums';
import { IMPORT_TYPES } from 'api/Service';
import { createAccountLabel } from 'api/utils';
import { Button } from 'components/buttons/Button';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { ExportFormatEnum } from 'components/ExportDropdown/enum';
import {
  ExportDropdown,
  getExportActions,
  TExportTypes,
} from 'components/ExportDropdown/ExportDropdown';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { DateRangeField } from 'components/forms/inputs/DateRangeField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextField } from 'components/forms/inputs/TextField';
import { withForm } from 'components/forms/withForm';
import { PageHeader } from 'components/layout/Page/PageHeader';
import { SearchInput } from 'components/searchInput/SearchInput';
import { CreateButton } from 'components/Table/CreateButton';
import { useFilter } from 'components/Table/DataTable';
import { ToggleFilterButton } from 'components/Table/ToggleFilterButton/ToggleFilterButton';
import { FilterTabBar } from 'components/tabs/FilterTabBar';
import { translate } from 'i18n/translator';
import { DisabledBlock } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';

interface ExtraFields {
  orderStates: string;
  searchText: string;
  types: PaymentTypes[];
}

const EXPORT_TYPES_PAYMENT: TExportTypes = {
  XLS: ExportFormatEnum.EXCEL,
  CSV: ExportFormatEnum.CSV,
  DBF: ExportFormatEnum.DBF,
};

const ForeignPaymentFilterForm = () => {
  const { updateFilter, extraFields, setPagination } = useFilter<unknown, ExtraFields>({
    extraFields: {
      searchText: '',
      orderStates: '',
      types: [PaymentTypes.FOREIGN_TRANSFER, PaymentTypes.FOREIGN_TRANSFER_ESCROU],
    },
  });
  const [accountsOptions, setAccountsOptions] = React.useState([]);

  const fetchAccounts = async () => {
    const accounts = await api.payments.getAccountsForPayment({
      subTypes: 'Current, ESCROU',
      withDocumentBalance: false,
    });

    setAccountsOptions(
      accounts.rows
        .filter(item => item.currency !== 'UAH' && item.allowDebit)
        .map(account => ({
          label: createAccountLabel(account),
          value: `${account.iban || account.number}`,
        })),
    );
  };

  const exportActions = React.useMemo(() => {
    const filter = {
      ...extraFields,
      orderStates: api.payments.getPaymentStatusesByKey(extraFields.orderStates),
    };
    const exportOrderAction = (exportType: any) => () =>
      api.payments.exportOrder({
        ...filter,
        exportType,
      });

    return getExportActions({
      action: exportOrderAction,
      exportTypes: EXPORT_TYPES_PAYMENT,
    });
  }, [extraFields]);

  React.useEffect(() => {
    fetchAccounts();
  }, []);

  return (
    <div>
      <PageHeader.Right>
        <ExportDropdown actions={exportActions} />
      </PageHeader.Right>
      <FilterTabBar
        onChange={orderStates => updateFilter({ orderStates })}
        value={extraFields.orderStates}
        options={api.payments.getInternationalPaymentOptionStatuses()}
        setPagination={setPagination}
      />
      <Row>
        <Col lg={6}>
          <DisabledBlock privileges={[Privileges.paymentForeignEdit]}>
            <CreateButton
              title="front.working-documents.create-payment.label"
              onClick={() => {
                goto(pages.foreignPayment.tabs.details('new'));
              }}
            />
          </DisabledBlock>
          <DisabledBlock privileges={[Privileges.paymentForeignEdit]}>
            <Button
              color="secondary"
              onClick={() => {
                goto(pages.import, { type: IMPORT_TYPES.FOREIGN_PAYMENTS });
              }}
            >
              {translate('front.internal-payment-page.import-button.label')}
            </Button>
          </DisabledBlock>
        </Col>
        <Col lg={6}>
          <Row>
            <Col lg={7} md={8}>
              <SearchInput
                placeholder={translate('front.working-documents-table.filter-search.label')}
                value={extraFields.searchText}
                onChange={searchText => updateFilter({ searchText })}
              />
            </Col>
            <Col lg={5} md={4}>
              <ToggleFilterButton>
                <Row>
                  <Col md={6}>
                    <TextField
                      label="front.i18n-payments.filter-by-room.label"
                      name="orderNumber"
                    />
                  </Col>
                  <Col md={6}>
                    <DateRangeField
                      label="front.i18n-payments.filter-period.label"
                      nameFrom="dateFrom"
                      nameTo="dateTo"
                      clearable
                    />
                  </Col>
                  <Col md={6} className="">
                    <SelectField
                      label="front.i18n-payments.filter-sender-score.label"
                      name="payerAccount"
                      options={accountsOptions}
                      clearable
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      label="front.i18n-payments.filter-receiver.label"
                      name="receiverName"
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      label="front.i18n-payments.filter-the-receiver.label"
                      name="receiverAccount"
                    />
                  </Col>
                  <Col md={4}>
                    <AmountField
                      label="front.i18n-payments.filter-the-minimum-amount.label"
                      name="amountFrom"
                    />
                  </Col>
                  <Col md={6}>
                    <CustomerSelect
                      label="front.i18n-payments.filter-payer-name.label"
                      name="customerIds"
                      multi
                    />
                  </Col>
                  <Col md={4}>
                    <AmountField
                      label="front.i18n-payments.filter-maximum-amount.label"
                      name="amountTo"
                    />
                  </Col>
                </Row>
              </ToggleFilterButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export const ForeignPaymentPageFilter = withForm(ForeignPaymentFilterForm);
