import * as React from 'react';

import { toMoneyAmount } from 'components/forms/inputs/amountFields/AmountField';
import { AmountFieldState } from 'components/forms/inputs/amountFields/AmountFieldState';
import { FinalValue } from 'components/forms/ValidatingForm/FormContext';

type ColumnAmountFiledProps = {
  disabled: boolean;
  rowAmount: number;
  setSalary: ({ tableIndex }: { tableIndex: number }, amount: string) => void;
  tableIndex: number;
};

export const SalaryAmountField: React.FC<ColumnAmountFiledProps> = props => {
  const { rowAmount, setSalary, tableIndex, disabled } = props;
  const [value, setValue] = React.useState<FinalValue>();

  React.useEffect(() => {
    if (rowAmount) {
      setValue(rowAmount);
    }
  }, [rowAmount]);

  const handleBlur = (value: string) => {
    setSalary({ tableIndex }, value);
    setValue(value);
  };

  const handleChange = (value: string) => setValue(value);

  return (
    <AmountFieldState
      name="amount"
      label="front.menu.employees-amount.label"
      value={toMoneyAmount(value as string)}
      minAmount={0.01}
      maxLengthWithoutZero={12}
      hasMoneyFormat
      onBlur={handleBlur}
      onChange={handleChange}
      thousandSeparator={' '}
      disabled={disabled}
      autoComplete="off"
      required
    />
  );
};
