import React from 'react';
import { useLocation } from 'react-router';

import moment from 'moment';

import { api } from 'api';
import { parseUrlParams } from 'api/backend';
import { DemandType } from 'api/DemandsService/enums';
import { OrderActionType, OrderState } from 'api/enums';
import { Order } from 'api/OrderService';
import { useOrder } from 'components/Document/useOrder';
import { getOrderDate } from 'components/Document/utils';
import { FetchOrderCb, withOrder } from 'components/Document/withOrder';
import { FormState } from 'components/forms/ValidatingForm/FormContext';
import { NavTab, NavTabs } from 'components/tabs/NavTabs';
import * as format from 'components/utils/format';
import { translate } from 'i18n/translator';
import { allowDemandAction } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { NEW } from 'navigations/routes';
import { CurrencyBuyDetails } from 'pages/Demands/CurrencyExchange/CurrencyBuyDetails';
import { CurrencyConversionDetails } from 'pages/Demands/CurrencyExchange/CurrencyConversionDetails';
import { CurrencySaleDetail } from 'pages/Demands/CurrencyExchange/CurrencySaleDetail';
import { CurrencyExchangeDetailsName } from 'pages/Demands/CurrencyExchange/enums';
import { CurrencyPayload } from 'pages/Demands/CurrencyExchange/interfaces';
import {
  getAccountType,
  getDynamicFields,
  getFinancialDetailsByOrder,
  prepareFieldsCurrencyExchange,
} from 'pages/Demands/CurrencyExchange/utils';
import { getActions, getDetailFields, getPayload } from 'pages/Demands/utils';
import { PaymentHistoryPage } from 'pages/Payments/History/PaymentHistoryPage';
import { getAccountValue } from 'pages/Payments/InternalPayment/utils';
import { isExist } from 'utils/isData';

// TODO change type
const fetchOrder: FetchOrderCb<any> = async (
  { customerId: defaultCustomerId, routeParams, queryParams, locationState },
  { setPayload },
) => {
  const { type } = queryParams;

  let demand;
  let payload;
  let isBuyAmount: boolean;
  let detailFields;

  const cashSymbolOptions = await api.demands.getCurrencyPair();

  if (routeParams.id !== NEW) {
    demand = await api.demands.getDemand(routeParams.id);

    const {
      base: {
        orderNumber,
        customerId,
        orderType,
        orderState,
        orderStateLabel,
        id,
        translatedOrderType,
      },
      dynamicFields,
      financial,
    } = demand;

    const { uahAccountOptions, currencyAccountOptions, payerOptions } =
      await api.payments.getCurrencyExchangeSourceField(customerId);

    detailFields = getDetailFields(dynamicFields, orderState);

    isBuyAmount = detailFields.isBuyAmount ?? false;
    payload = getPayload(dynamicFields, getAccountType(orderType));

    const isDraft = orderState === OrderState.Draft;

    const { receiverCurrency, isoCode } = financial;

    const saleCurrencyAccount = isDraft ? null : isoCode;
    const buyCurrencyAccount = isDraft ? null : receiverCurrency;

    setPayload({
      ...payload,
      [CurrencyExchangeDetailsName.AccountUAH]: uahAccountOptions,
      [CurrencyExchangeDetailsName.AccountNonUAH]: currencyAccountOptions,

      [CurrencyExchangeDetailsName.AccountBuy]: currencyAccountOptions,
      [CurrencyExchangeDetailsName.AccountSale]: currencyAccountOptions,
      [CurrencyExchangeDetailsName.AccountFee]: uahAccountOptions,

      payerOptions,
      cashSymbolOptions,
      isBuyAmountValue: isBuyAmount,
      orderType,
      saleCurrencyAccount,
      buyCurrencyAccount,
      dynamicFields: demand.dynamicFields,
    });

    return {
      id,
      label: translatedOrderType,
      customerId,
      date: getOrderDate(demand.base),
      state: orderState,
      stateTranslate: orderStateLabel,
      number: orderNumber,
      type: orderType as DemandType,
      actions: getActions(demand),

      detailFields: {
        ...detailFields,
        ...prepareFieldsCurrencyExchange(
          demand,
          detailFields,
          routeParams,
          uahAccountOptions,
          currencyAccountOptions,
          isBuyAmount,
        ),
      },
    };
  }

  let number;
  const today = moment().toDate();

  if (queryParams.copyFrom) {
    demand = await api.demands.getDemand(queryParams.copyFrom);

    const {
      base: { customerId, orderType, translatedOrderType, orderState },
      dynamicFields,
    } = demand;

    number = await api.payments.getDocumentNumber({
      customerId: customerId,
      orderType: orderType,
      orderDate: moment().format('YYYY-MM-DD'),
    });

    const { uahAccountOptions, currencyAccountOptions, payerOptions } =
      await api.payments.getCurrencyExchangeSourceField(customerId);

    detailFields = getDetailFields(dynamicFields, OrderState.Draft);
    isBuyAmount = detailFields[CurrencyExchangeDetailsName.IsBuyAmount] ?? false;
    payload = getPayload(dynamicFields, getAccountType(orderType));

    setPayload({
      ...payload,
      payerOptions,
      [CurrencyExchangeDetailsName.AccountUAH]: uahAccountOptions,
      [CurrencyExchangeDetailsName.AccountNonUAH]: currencyAccountOptions,
      [CurrencyExchangeDetailsName.AccountBuy]: currencyAccountOptions,
      [CurrencyExchangeDetailsName.AccountSale]: currencyAccountOptions,
      [CurrencyExchangeDetailsName.AccountFee]: uahAccountOptions,
      cashSymbolOptions,
      orderType,
      dynamicFields: dynamicFields,
    });

    return {
      number,
      label: translatedOrderType,
      stateTranslate: null,
      date: getOrderDate({ customerId, orderType, translatedOrderType, orderState }, true),
      id: null,
      state: null,
      actions: [],
      type: orderType,
      customerId,

      detailFields: {
        ...detailFields,
        ...prepareFieldsCurrencyExchange(
          demand,
          detailFields,
          routeParams,
          uahAccountOptions,
          currencyAccountOptions,
          isBuyAmount,
          true,
        ),
      },
    };
  }

  demand =
    locationState?.importData ||
    (await api.demands.getDemandTemplate(queryParams.type, defaultCustomerId));

  number = await api.payments.getDocumentNumber({
    orderType: type,
    customerId: defaultCustomerId,
    orderDate: moment().format('YYYY-MM-DD'),
  });

  payload = getPayload(demand.orderFieldModel, getAccountType(type));

  const { uahAccountOptions, currencyAccountOptions, payerOptions } =
    await api.payments.getCurrencyExchangeSourceField(defaultCustomerId);

  setPayload({
    ...payload,
    cashSymbolOptions,
    dynamicFields: demand.orderFieldModel,
    payerOptions,
    [CurrencyExchangeDetailsName.AccountUAH]: uahAccountOptions,
    [CurrencyExchangeDetailsName.AccountNonUAH]: currencyAccountOptions,
    [CurrencyExchangeDetailsName.AccountBuy]: currencyAccountOptions,
    [CurrencyExchangeDetailsName.AccountSale]: currencyAccountOptions,
    [CurrencyExchangeDetailsName.AccountFee]: uahAccountOptions,
    orderType: demand.orderTypeModel.orderType,
  });

  return {
    label: demand.orderTypeModel.name.text,
    customerId: defaultCustomerId,
    id: null,
    state: null,
    stateTranslate: null,
    number,
    type: queryParams.type,
    date: today,
    actions: [],
    detailFields: {
      dateOperation: today,
      isBuyAmount: true,
    },
  };
};

const onSave = (order: Order, payload: CurrencyPayload) => async (formData: FormState) => {
  const filteredFormData = { ...formData };

  Object.keys(formData).forEach(key => {
    const value = filteredFormData[key];
    if (value === null) {
      delete filteredFormData[key];
    }
  });

  const {
    orderNumber,
    orderDate,
    valueDate,
    isBuyAmount,
    accountUAH,
    isCustomIban,
    info,
    ...demandFields
  } = filteredFormData;

  const { id, type } = order;

  const accountUahIban = getAccountValue(+accountUAH, payload.accountUAH);

  const isAccountUahCustomIban = !accountUahIban;

  const validDemandFields = {
    isBuyAmount: isBuyAmount,
    isCustomIban: isAccountUahCustomIban,
    ...demandFields,
  };

  const dynamicFields = getDynamicFields(validDemandFields, payload.dynamicFields);

  const orderData: any = {
    dynamicFields,
    financial: getFinancialDetailsByOrder(order, filteredFormData, payload),
    base: {
      orderNumber,
      orderDate: format.localDate(orderDate as Date),
      customerId: order.customerId,
      orderType: type,
    },
  };

  if (!id) {
    return await api.demands.saveOrderV3(orderData);
  }

  await api.demands.updateOrderV4(id, orderData);
  return id;
};

const renderDetails = (orderType: DemandType): React.ReactNode => {
  const componentByTypes: Partial<Record<DemandType | 'default', JSX.Element>> = {
    [DemandType.CurrencyBuy]: <CurrencyBuyDetails />,
    [DemandType.CurrencySale]: <CurrencySaleDetail />,
    default: <CurrencyConversionDetails />,
  };

  if (isExist(orderType)) {
    return componentByTypes[orderType] ? componentByTypes[orderType] : componentByTypes.default;
  }

  return null;
};

const CurrencyExchangePageComponent = () => {
  const { order } = useOrder();

  const { type } = parseUrlParams(useLocation().search);
  const orderType = type ?? order?.type;

  const Details = React.useMemo(() => renderDetails(orderType), [orderType]);

  return (
    <NavTabs>
      <NavTab
        title={translate('front.demand-detail.details.label')}
        path={pages.currencyExchanges.tabs.details(':id')}
      >
        {Details}
      </NavTab>
      {!!order?.state && (
        <NavTab
          title={translate('front.demand-detail.history.label')}
          path={pages.currencyExchanges.tabs.history(':id')}
        >
          <PaymentHistoryPage />
        </NavTab>
      )}
    </NavTabs>
  );
};

const goBack = () => goto(pages.currencyExchange);

export const CurrencyExchangePage = withOrder(({ order, error }) => {
  const isRepresentativeError =
    error === translate('front.cash-transfer-page.representative-errors.label');

  return {
    fetchOrder,
    createOnSave: onSave,
    allowState: true,
    allowSave:
      order && allowDemandAction(order.type as DemandType, OrderActionType.EDIT, +order.customerId),
    allowSign:
      order &&
      allowDemandAction(order.type as DemandType, OrderActionType.SIGN, +order.customerId) &&
      !isRepresentativeError,
    allowDetails: true,
    afterSubmit: goBack,
    disableButtons: true,
    isDisabledSave: isRepresentativeError,
  };
})(CurrencyExchangePageComponent);
