import React from 'react';
import { components } from 'react-select';

import classNames from 'classnames';

import { Checkbox } from 'components/forms/checkbox/Checkbox';
import { AutocompleteSortColumn } from 'components/forms/select/AutocopleteSortColumn';
import { SortParams } from 'components/forms/select/interfaces';
import { Clear } from 'components/icons';
import { translate } from 'i18n/translator';
import { isExist } from 'utils/isData';

export const selectStyles = (
  isMenuMaxContentWidth: boolean,
  maxWidth: string,
  hiddenTextLongOption: boolean,
) => ({
  container: (provided: any) => ({
    ...provided,
  }),
  control: (provided: any) => ({
    ...provided,
    minHeight: 'initial',
    boxShadow: 'none',
    border: 'none',
    backgroundColor: 'initial',
  }),
  menuPortal: (provided: any) => ({
    ...provided,
    zIndex: 1550,
    width: maxWidth ? maxWidth : isMenuMaxContentWidth ? '100%' : provided.width,
  }),
  menu: (provided: { width: any }) => ({
    ...provided,
    width: maxWidth ? maxWidth : isMenuMaxContentWidth ? 'initial' : provided.width,
  }),
  option: (styles: Obj, { data, isDisabled, isFocused, isSelected }: any) => {
    const hiddenTextLongOptionStyles = hiddenTextLongOption
      ? {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'noWrap',
        }
      : {};
    return {
      ...styles,
      wordWrap: 'break-word',
      backgroundColor: isDisabled ? null : isSelected ? '#4CA852' : isFocused ? '#cdf6d3' : null,
      color: isDisabled ? '#ccc' : isSelected ? '#fff' : data.color,
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      ...hiddenTextLongOptionStyles,
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : '#4CA852'),
      },
    };
  },
  valueContainer: (provided: any) => ({
    ...provided,
    alignItems: 'initial',
    padding: 0,
    flexWrap: 'nowrap',
  }),
  multiValue: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  menuList: (provided: any) => ({
    ...provided,
    paddingTop: 0,
  }),
  dropdownIndicator: (provided: any, { isDisabled, selectProps }: any) => ({
    ...provided,
    display: isDisabled ? 'none' : 'flex',
    padding: 0,
    minHeight: '26px',
    alignItems: 'center',
    transition: 'all .1s ease',
    transform: selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    transformOrigin: 'center center',
    cursor: 'pointer',
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    padding: 0,
    marginRight: '5px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  input: (provided: any) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  singleValue: () => ({
    display: 'none',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    position: 'absolute',
  }),
});

export const HighlightingOption = (props: any) => {
  const { getValue, value, options, ...other } = props;
  const {
    selectProps: { isClearable },
  } = props;

  if (isClearable && !value) {
    return null;
  }

  return (
    <components.Option {...other}>
      <div className="select-option">
        <span className="wrp-checkbox">
          {props.isMulti && (
            <Checkbox
              checked={!!getValue().find((item: any) => item.value === value)}
              name={props.innerProps.id}
            />
          )}
        </span>
        <span className={classNames({ 'wrp-children': props.isMulti })}>{props.children}</span>
      </div>
    </components.Option>
  );
};

export const Input = (props: any) => {
  const newProps = { ...props, value: props.value.trim() };

  return (
    <div className={classNames('input-autocomplete', { 'no-value': !newProps.value })}>
      <components.Input {...newProps} />
    </div>
  );
};

export const Options = (props: any) => {
  const {
    value,
    selectProps: { isClearable, inputValue, combo },
  } = props;

  if (isClearable && !value) {
    return null;
  }

  return <HighlightingOption {...props} inputValue={inputValue} isCreateble={combo} />;
};

export const ValueContainer = (props: any) => {
  const {
    children,
    selectProps: { inputValue, isAllSelected },
  } = props;

  return (
    <components.ValueContainer {...props}>
      <div className="selected-value-children-container">{children}</div>
      <span className="selected-value">
        {props.hasValue &&
          (props.getValue().length === 1
            ? inputValue || props.getValue()[0].label
            : inputValue ||
              `${translate('front.form.selected-count.label')}
            ${
              isAllSelected
                ? translate('front.form.selected-all.label')
                : `- ${props.getValue().length}`
            }`)}
      </span>
    </components.ValueContainer>
  );
};

export const ClearIndicator = (props: any) => {
  const {
    children = <Clear />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props)}>
      <div className="autocomplete__clear-indicator">{children}</div>
    </div>
  );
};

export const MenuLils = (props: any) => {
  const { value, isCreateble, ...rest } = props;

  if (isCreateble && !value && !rest.options.filter((item: any) => !item.__isNew__).length) {
    return null;
  }

  return <components.Menu {...rest}>{rest.children}</components.Menu>;
};

export const Menu = (props: any) => {
  const {
    selectProps: { inputValue, combo },
  } = props;

  return <MenuLils {...props} value={inputValue} isCreateble={combo} />;
};

export const MenuList = (props: any) => {
  const {
    selectProps: { isDisabled, onChange, isAllSelected, sortParams },
  } = props;

  const hasOptions = props.selectProps.options.length > 0;
  const shouldRenderCheckbox = hasOptions && props.isMulti;
  const hasSorting = isExist(sortParams);

  return (
    <components.MenuList {...props}>
      <div
        style={{
          pointerEvents: isDisabled ? 'none' : 'auto',
          opacity: isDisabled ? 0.65 : 1,
        }}
      >
        {hasSorting && (
          <div className="sort-wrap">
            {(sortParams as SortParams<true>).columns.map(({ name, label, order }) => (
              <AutocompleteSortColumn
                sort={sortParams.currentSort}
                setSort={sortParams.handleSort(props.options)}
                order={order}
                colSort={name}
                colLabel={label}
                hasDefaultSort={sortParams.currentSort === name}
              />
            ))}
          </div>
        )}
        {shouldRenderCheckbox && (
          <div
            style={{
              paddingLeft: '12px',
              paddingBottom: '5px',
              paddingTop: '5px',
              cursor: 'pointer',
            }}
            onClick={() => onChange(isAllSelected ? [] : props.options)}
          >
            <Checkbox
              checked={isAllSelected}
              name={'gfgfg '}
              label={translate('front.form.select-all.label')}
            />
          </div>
        )}
        {props.children}
      </div>
    </components.MenuList>
  );
};
