import * as React from 'react';

import { Card } from '../Card';

export const withoutModal = (Component: React.FC) => {
  class WithoutModalComponent extends React.PureComponent {
    render() {
      return (
        <Card className="wrap-no-modal">
          <Component {...this.props} />
        </Card>
      );
    }
  }

  return WithoutModalComponent;
};

export default withoutModal;
