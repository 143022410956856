import * as React from 'react';
import { Col, Container, Row } from 'react-grid';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';

import moment from 'moment';

import { api } from 'api';
import { PersonStatus } from 'api/enums';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextAreaField } from 'components/forms/inputs/TextAreaField';
import { TextField } from 'components/forms/inputs/TextField';
import { TimeField } from 'components/forms/inputs/TimeField';
import { Effects, useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { Page } from 'components/layout/Page/Page';
import { translate } from 'i18n/translator';
import { goBack, goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { prepareIpForRequest, prepareIpFromRequest } from 'pages/AuthorizedPersons/utils';
import { OrganizationTimers } from 'pages/Login/CustomerTimers';
import { selectChosenCustomers } from 'store/selectors';
import { isNumber } from 'utils/isData';

const statuses = {
  [PersonStatus.ACTIVE]: translate('front.auth-person.status-active.label'),
  [PersonStatus.BLOCKED]: translate('front.auth-person.status-blocked.label'),
  [PersonStatus.DELETED]: translate('front.auth-person.status-deleted.label'),
};

type PrivilegiesData = {
  [key: string]: boolean;
};

interface FormFields {
  fullName: string;
  ipRestriction: string;
  login: string;
  organizationRole: number;
  privilegies: PrivilegiesData;
  roleId: number | string;
  signLevel: string;
  status: string;
  validFrom: string;
  validTo: string;
}

const IP_REGEX_WITH_MASK =
  /^((25[0-5]|2[0-4][0-9]|[1]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[1]?[0-9][0-9]?)(\/([1-9]|[1-2][0-9]|3[0-2]))?([;])?$/;

const MAX_IPS = 25;

const validateOneIp = (ip: string): string => {
  if (!ip) return '';

  const translateErrorWithSemicolon = translate(
    'front.authorized-person.ip-validation-base-error',
  ).replace('./', './;');

  if (!IP_REGEX_WITH_MASK.test(ip)) {
    return translateErrorWithSemicolon;
  }

  return '';
};

const validateIp = (ip: string): string => {
  if (!ip) {
    return '';
  }

  if (ip.includes(';')) {
    const ips = ip.split(';');

    if (ips.length > MAX_IPS) {
      return 'front.authorized-person.max-ip-validation-error';
    }

    return ips.reduce((acc, ip) => {
      const error = validateOneIp(ip);

      if (error) {
        return error;
      }
      return acc;
    }, '');
  }

  return validateOneIp(ip);
};

const normalizeIp = (ip: string): string => {
  if (!ip) return '';

  return ip.replace(/[^\+\d.\/;]/g, '');
};

const validTranslateTooltip = translate('front.auth-person.ip-tooltip.label').replaceAll(',', ';');

const AuthorizedPersonPermissionForm = () => {
  const {
    params: { id },
  } = useRouteMatch<{
    id: string;
  }>();
  const chosenCustomerIds = useSelector(selectChosenCustomers()).map(c => c.id);

  const fetchRoleOptions = async (customerId: number) => {
    const roleOptions = await api.auth.getPersonRoleOptions({ customerId });
    return roleOptions.filter(role => role?.content.isInternal === false);
  };

  const fetchData = async ({ setFields, setPayload }: Effects<FormFields, any>) => {
    const [person, role] = await Promise.all([
      api.auth.getPerson(id),
      api.auth.getPersonPrivileges(id),
    ]);

    const roleOptions = await fetchRoleOptions(person.company.id);

    const {
      status,
      userAccount,
      organizationRole,
      sign_level: signLevel,
      valid_from,
      valid_to,
    } = person;

    const validFrom = valid_from ? moment(valid_from).utc(true).format('HH:mm') : null;
    const validTo = valid_to ? moment(valid_to).utc(true).format('HH:mm') : null;
    const hasRole = roleOptions.some(item => +item.value === role.roleId);

    const newIpRestriction = prepareIpFromRequest(userAccount.ipRestriction);

    setFields({
      signLevel,
      organizationRole,
      roleId: hasRole ? role.roleId : role.roleName,
      status: statuses[status],
      login: userAccount?.login,
      fullName: userAccount?.fullName,
      validFrom,
      validTo,
      ipRestriction: newIpRestriction,
    });
    setPayload({
      roleOptions,
      ipRestriction: newIpRestriction,
      defaultRoleId: role.roleId,
    });
  };

  const { error, progress, payload, handleSubmit, setDisabled } = useForm<FormFields>(fetchData);

  const onSubmit = async (formData: FormFields) => {
    setDisabled(true);

    const { validFrom, validTo } = formData;
    const newRoleId = isNumber(+formData.roleId) ? formData.roleId : null;

    try {
      await Promise.all([
        newRoleId && api.auth.savePersonRole(id, newRoleId),
        api.auth.setWorktime(id, { validFrom, validTo }),
        api.auth.setIp(id, prepareIpForRequest(formData.ipRestriction)),
      ]);

      const { customers } = await api.user.getCustomersInfo();
      OrganizationTimers.reloadTimers(customers, chosenCustomerIds);
      goto(pages.authorizedPersons);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <Page title="front.auth-person.title.label">
      <DefaultForm>
        <FormError>{error}</FormError>
        <Container>
          <Row>
            <Col md={6}>
              <TextField label="front.auth-person.input-full-name.label" name="fullName" disabled />
            </Col>
            <Col md={6}>
              <TextField label="front.auth-person.input-login.label" name="login" disabled />
            </Col>
            <Col md={6}>
              <TextField label="front.auth-person.input-status.label" name="status" disabled />
            </Col>
            <Col md={6}>
              <SelectField
                label="front.auth-person.input-role-id.label"
                name="roleId"
                options={payload?.roleOptions}
                required
              />
            </Col>
          </Row>
          <h3>{translate('front.auth-person.second-title.label')}</h3>
          <Row>
            <Col md={6}>
              <TimeField label="front.auth-person.time-field-valid-from.label" name="validFrom" />
            </Col>
            <Col md={6}>
              <TimeField label="front.auth-person.time-field-valid-to.label" name="validTo" />
            </Col>
            <Col md={12}>
              <TextAreaField
                label="front.auth-person.input-ip.label"
                name="ipRestriction"
                validate={validateIp}
                tooltip={validTranslateTooltip}
                maxLength={474}
                rows={1}
                normalize={normalizeIp}
                hasAutoGrowing
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Button
                color="primary"
                onClick={e => handleSubmit(onSubmit, e)}
                progress={progress}
                type="submit"
                size="sm"
              >
                {translate('front.auth-person.button-apply.label')}
              </Button>
              <Button
                color="secondary"
                onClick={goBack}
                progress={progress}
                type="submit"
                size="sm"
              >
                {translate('front.auth-person.button-cancel.label')}
              </Button>
            </Col>
          </Row>
        </Container>
      </DefaultForm>
    </Page>
  );
};

export const AuthorizedPersonPermissionPage = withForm(AuthorizedPersonPermissionForm);
