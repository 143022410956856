import * as React from 'react';

import { Button } from 'components/buttons/Button';
import { SetPayload, UpdateData } from 'components/forms/ValidatingForm/FormContext';
import { Effects } from 'components/forms/ValidatingForm/useForm';
import { translate } from 'i18n/translator';
import { CurrentFactor } from 'pages/Login/LoginContextType';
import { FactorType } from 'pages/Login/LoginPage';
import { KepSignatureLoginPayload } from 'pages/Login/Steps/SignatureLogin/interface';
import { KepSignatureLoginFields } from 'pages/Login/Steps/SignatureLogin/KepSignatureLogin';

interface getButtonsFields {
  fetchToken: (
    props: Partial<Effects<KepSignatureLoginFields, KepSignatureLoginPayload>>,
  ) => Promise<void>;
  goToFirstStep: () => void;
  setCurrentFactor: (factor: CurrentFactor) => void;
  setPayload: SetPayload<KepSignatureLoginPayload>;
  updateData: UpdateData<KepSignatureLoginFields>;
}

export const GetButtonsComponent = ({
  goToFirstStep,
  setCurrentFactor,
  fetchToken,
  setPayload,
  updateData,
}: getButtonsFields) =>
  React.useMemo(
    () => (resolve: any) =>
      (
        <div>
          <Button
            onClick={() => {
              goToFirstStep();
              resolve(null);
            }}
            color="secondary"
          >
            {translate('front.login.modal.go-main.label')}
          </Button>
          <Button
            onClick={() => {
              setCurrentFactor({ type: FactorType.SMS });
              resolve(null);
            }}
            color="secondary"
          >
            {translate('front.sms-modal.sms-enter-with-sms.label')}
          </Button>
          <Button
            onClick={() => {
              fetchToken({ setPayload, setFields: updateData });
              resolve(null);
            }}
            color="secondary"
          >
            {translate('front.activation.files-page.button-update.label')}
          </Button>
        </div>
      ),
    [goToFirstStep, setPayload, updateData],
  );
