import React from 'react';

import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { useDisabledCustomer } from 'hooks/useDisabledCustomer';
import { translate } from 'i18n/translator';
import {
  ChangeFeeAccFields,
  ChangeMainAccFields,
} from 'pages/Demands/DepositDemand/ChangindDepositDetail/interfaces';

import { DepositDetailNames } from '../enums';
import { ChangeFeeAccDetail } from './ChangeFeeAccDetail';
import { ChangeMainAccDetail } from './ChangeMainAccDetail';

interface ChangingDepositFields extends ChangeMainAccFields, ChangeFeeAccFields {
  [DepositDetailNames.Customer]: string;
  [DepositDetailNames.Deposit]: string;
  [DepositDetailNames.DoChangeMainAcc]?: boolean;
  [DepositDetailNames.DoChangeFeeAcc]?: boolean;
  [DepositDetailNames.RadioMainAcc]?: string;
  [DepositDetailNames.RadioFeeAcc]?: string;
}

export const ChangingDepositDetail = () => {
  const {
    order,
    onChangeCustomer,
    payload: {
      deposit,
      radioMainAcc: radioMainAccounts,
      ownMainAccount,
      radioFeeAcc: radioFeeAccounts,
      ownFeeAccount,
    },
    form: { getFormData },
  } = useOrderDetails<ChangingDepositFields>();

  const { doChangeMainAcc, doChangeFeeAcc, radioMainAcc, radioFeeAcc }: ChangingDepositFields =
    getFormData();

  const isDisabledCustomer = useDisabledCustomer(order);

  return (
    <DefaultForm>
      <h5>{translate('front.changing-deposit-demand.titleLabel.label')}</h5>
      <CustomerSelect
        name={DepositDetailNames.Customer}
        label="front.deposit-demand.organization.label"
        onSelectOption={onChangeCustomer}
        selectDefaultCustomer
        disabled={isDisabledCustomer}
        required
      />
      <SelectField
        name={DepositDetailNames.Deposit}
        label="front.deposit-demand.deposit.label"
        options={deposit}
        required
      />
      <CheckboxField
        name={DepositDetailNames.DoChangeMainAcc}
        label="front.changing-deposit-demand.doChangeMainAcc.label"
        required={!doChangeFeeAcc}
      />
      {doChangeMainAcc && (
        <ChangeMainAccDetail
          radioMainAccounts={radioMainAccounts}
          ownMainAccounts={ownMainAccount}
          radioMainAcc={radioMainAcc}
        />
      )}
      <CheckboxField
        name={DepositDetailNames.DoChangeFeeAcc}
        label="front.changing-deposit-demand.doChangeFeeAcc.label"
        required={!doChangeMainAcc}
      />
      {doChangeFeeAcc && (
        <ChangeFeeAccDetail
          radioFeeAccounts={radioFeeAccounts}
          ownFeeAccounts={ownFeeAccount}
          radioFeeAcc={radioFeeAcc}
        />
      )}
    </DefaultForm>
  );
};
