import { Devices } from 'enums/DevicesEnum';

import config from 'config';
import { DEVICE } from 'store/actions/types';
import { ActionInterface } from 'store/reducers/interfaces';

const getDevice = (width: number): Devices => {
  switch (true) {
    case width >= config.app.screenSize.tablet: {
      return Devices.Desktop;
    }
    case width >= config.app.screenSize.mobile: {
      return Devices.Tablet;
    }
    default:
      return Devices.Mobile;
  }
};

const initialState = getDevice(document.getElementById('app')?.getBoundingClientRect()?.width);

export const deviceReducer = (state = initialState, action: ActionInterface) => {
  switch (action.type) {
    case DEVICE.RESOLVE_DEVICE: {
      const device = getDevice(action.payload);

      return device !== state ? device : state;
    }
    default:
      return state;
  }
};
