import * as React from 'react';
import { Col, Row } from 'react-grid';
import { useSelector } from 'react-redux';

import { createSelector } from 'reselect';

import { api } from 'api';
import { DemandType } from 'api/DemandsService/enums';
import { OrderActionType } from 'api/enums';
import { Option } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { DateRangeField } from 'components/forms/inputs/DateRangeField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { CreateButton } from 'components/Table/CreateButton';
import { useFilter } from 'components/Table/DataTable';
import { ToggleFilterButton } from 'components/Table/ToggleFilterButton/ToggleFilterButton';
import { FilterTabBar } from 'components/tabs/FilterTabBar';
import { translate } from 'i18n/translator';
import { AllowDisplayBlock, allowSomeDemandAction } from 'navigations/access';
import { Privileges } from 'navigations/privileges';
import { NEW } from 'navigations/routes';
import { demandsStates, getSubmitPage } from 'pages/Demands/utils';
import { selectUser } from 'store/selectors';

import { typeGroups } from './DemandsPage';

const branches = () => [
  {
    label: 'front.demand.card-group',
    privileges: [Privileges.demandCardEdit],
    children: [
      {
        type: DemandType.CardBlockingOrder,
        label: 'front.demands.card-blocking.label',
      },
      {
        type: DemandType.CardReissueOrder,
        label: 'front.demands.card-reissue.label',
      },
      {
        type: DemandType.CardClosingOrder,
        label: 'front.demands.card-closing.label',
      },
      { type: DemandType.NewCardOrder, label: 'front.demands.card-new.label' },
      {
        type: DemandType.ServiceDescOrder,
        label: 'front.demands.card-transaction-claim.label',
      },
    ],
  },
  {
    label: 'front.demand.sms-notification',
    privileges: [Privileges.demandSMSEdit],
    children: [
      {
        type: DemandType.SMSNotificationOrder,
        label: 'front.demands.sms-notification.label',
      },
      {
        type: DemandType.ChangeSMSNotificationOrder,
        label: 'front.demands.change-sms-notification.label',
      },
    ],
  },
  {
    label: 'front.demand.deposit-group',
    privileges: [Privileges.demandDepositEdit],
    children: [
      {
        type: DemandType.NewDepositGeneralOrder,
        label: 'front.demands.deposit-general.label',
      },
      {
        type: DemandType.NewContractTermDeposit,
        label: 'front.demands.deposit-contract-term.label',
      },
      {
        type: DemandType.NewPlacementTrancheOrder,
        label: 'front.demands.deposit-placement-tranche.label',
      },
      {
        type: DemandType.DepositProlongationOrder,
        label: 'front.demands.deposit-prolongation.label',
      },
      {
        type: DemandType.DepositChangingOrder,
        label: 'front.demands.deposit-changing.label',
      },
      {
        type: DemandType.DepositClosingOrder,
        label: 'front.demands.deposit-closing.label',
      },
    ],
  },
  {
    label: 'front.demand.credit-group',
    privileges: [Privileges.demandCreditEdit],
    children: [
      {
        type: DemandType.CreditProlongationOrder,
        label: 'front.demands.credit-prolongation.label',
      },
      {
        type: DemandType.NewCreditTranchOrder,
        label: 'front.demands.new-credit-tranch.label',
      },
      {
        type: DemandType.CreditFundsOrder,
        label: 'front.demands.credit-funds.label',
      },
    ],
  },
  {
    label: 'front.demand.salary-group',
    privileges: [Privileges.demandSalaryEdit],
    children: [
      {
        type: DemandType.NewSalaryContract,
        label: 'front.demands.new-salary-contract.label',
      },
    ],
  },
  {
    label: 'front.demand.cash-group',
    privileges: [Privileges.demandCashEdit],
    children: [
      {
        type: DemandType.CashPreparationOrder,
        label: 'front.demands.cash-preparation.label',
      },
      {
        type: DemandType.CashTransferOrder,
        label: 'front.demands.cash-transfer.label',
      },
      {
        type: DemandType.CashWithdrawalOrder,
        label: 'front.demands.cash-withdrawal.label',
      },
    ],
  },
];

const INITIAL_FILTER = { extraFields: { orderStates: '' } };

export const depositOrderTypes = [
  DemandType.NewDepositGeneralOrder,
  DemandType.NewContractTermDeposit,
  DemandType.NewPlacementTrancheOrder,
  DemandType.DepositProlongationOrder,
  DemandType.DepositChangingOrder,
  DemandType.DepositClosingOrder,
];

const DemandFilterForm = () => {
  const [orderTypeOptions, setOrderTypeOptions] = React.useState(null);

  React.useEffect(() => {
    getTypeOptions();
  }, []);

  const getTypeOptions = async () =>
    setOrderTypeOptions(await api.demands.getDemandTypes(typeGroups));

  const { extraFields, updateFilter, setPagination } = useFilter(INITIAL_FILTER);

  return (
    <div>
      <FilterTabBar
        value={extraFields.orderStates}
        options={demandsStates}
        onChange={orderStates => updateFilter({ orderStates })}
        setPagination={setPagination}
      />
      <Row>
        <AllowDisplayBlock
          privileges={[
            Privileges.demandEdit,
            Privileges.demandReferenceEdit,
            Privileges.demandDepositEdit,
            Privileges.demandCreditEdit,
            Privileges.demandCardEdit,
            Privileges.demandSalaryEdit,
            Privileges.demandSMSEdit,
            Privileges.demandCurrencyExchangeEdit,
            Privileges.demandCashEdit,
          ]}
        >
          <Col lg={7}>
            <DemandSelectForm />
          </Col>
          <Col lg={5}>
            <ToggleFilterButton>
              <Row>
                <Col md={6}>
                  <DateRangeField
                    label="front.account-statements-filter-form.date-range.label"
                    nameFrom="dateFrom"
                    nameTo="dateTo"
                    clearable
                  />
                </Col>
                <Col md={6}>
                  <SelectField
                    label="front.demands-page.filter-type.label"
                    name="types"
                    options={orderTypeOptions}
                    multi
                  />
                </Col>
                <Col md={6}>
                  <CustomerSelect
                    label="front.internal-payment-page.order-payer-name.label"
                    name="customerIds"
                    multi
                  />
                </Col>
              </Row>
            </ToggleFilterButton>
          </Col>
        </AllowDisplayBlock>
      </Row>
    </div>
  );
};

interface DemandSelectFormState {
  category: string;
  demand: DemandType;
}

const DemandSelectForm = withForm(() => {
  const [demandOptions, setDemandOptions] = React.useState(null);
  const { updateData, getFormData, handleSubmit } = useForm<DemandSelectFormState>();
  const privileges = useSelector(createSelector(selectUser(), user => user.privileges));

  const categories = React.useMemo(
    () =>
      branches()
        .filter(
          item => item.privileges === null || privileges.some(p => item.privileges.includes(p)),
        )
        .map(item => ({ value: item.label, label: translate(item.label) })),
    [privileges],
  );

  const onCategoryChange = (categoryOption: Option) => {
    let demandOptions: Option<DemandType, DemandType>[] = [];
    if (categoryOption) {
      demandOptions = branches()
        .find(b => b.label === categoryOption)
        .children.map(({ type, label }) => ({
          label: translate(label),
          value: type,
        }));
    }
    updateData({ demand: null });
    setDemandOptions(demandOptions);
  };

  const onSubmit = ({ demand: type }: DemandSelectFormState) => getSubmitPage({ id: NEW, type });

  return (
    <>
      <Row>
        <Col lg={5}>
          <SelectField
            label="front.demand-filter.select-category.label"
            name="category"
            onSelectOption={onCategoryChange}
            options={categories}
            required
            clearable
          />
        </Col>
        <Col lg={5}>
          <SelectField
            label="front.demand-filter.select-order.label"
            name="demand"
            options={demandOptions}
            disabled={!getFormData().category}
            required
            clearable
          />
        </Col>
        <Col lg={2}>
          <CreateButton
            title="front.demand-filter.create-order.label"
            disabled={!allowSomeDemandAction(OrderActionType.EDIT)}
            onClick={e => handleSubmit(onSubmit, e)}
          />
        </Col>
      </Row>
    </>
  );
});

export const DemandPageFilter = withForm(DemandFilterForm);
