import * as React from 'react';

import classNames from 'classnames';

interface Props {
  children: React.ReactNode;
  autoComplete?: 'on' | 'off' | string;
  className?: string;
  disabled?: boolean;
  name?: string;
}

export const DefaultForm: React.FC<Props> = props => {
  const { name, className = '', disabled = false, autoComplete, children } = props;
  return (
    <div className={classNames('form-wrapper', className)}>
      <form name={name} className={classNames({ disabled })} autoComplete={autoComplete}>
        {children}
      </form>
    </div>
  );
};
