import React from 'react';
import { useLocation } from 'react-router';

import { parseUrlParams } from 'api/backend';
import { DemandType } from 'api/DemandsService/enums';
import { OrderActionType } from 'api/enums';
import { useOrder } from 'components/Document/useOrder';
import { withOrder } from 'components/Document/withOrder';
import { NavTab, NavTabs } from 'components/tabs/NavTabs';
import { translate } from 'i18n/translator';
import { allowDemandAction } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { CardBlockDetail } from 'pages/Demands/CardDemand/CardBlockDetail';
import { CardClosingDetail } from 'pages/Demands/CardDemand/CardClosingDetail';
import { CardReissueDetail } from 'pages/Demands/CardDemand/CardReissueDetail';
import { NewCardDetails } from 'pages/Demands/CardDemand/NewCardDetails';
import { ServiceDescDetail } from 'pages/Demands/CardDemand/ServiceDescDetail';
import { DemandHistoryPage } from 'pages/Demands/DemandHistoryPage';
import { createOnSave, fetchOrder } from 'pages/Demands/utils';
import { isExist } from 'utils/isData';

import '../styles.scss';

const goBack = () => goto(pages.demands);

const renderDetails = (orderType: DemandType): React.ReactNode => {
  const cardDetails: Partial<Record<DemandType | 'default', React.ReactNode>> = {
    [DemandType.CardBlockingOrder]: <CardBlockDetail />,
    [DemandType.CardReissueOrder]: <CardReissueDetail />,
    [DemandType.CardClosingOrder]: <CardClosingDetail />,
    [DemandType.ServiceDescOrder]: <ServiceDescDetail />,
    default: <NewCardDetails />,
  };

  if (isExist(orderType)) {
    return cardDetails[orderType] || cardDetails.default;
  }

  return null;
};

const CardDemandComponent: React.FC = () => {
  const { order } = useOrder();

  const { type } = parseUrlParams(useLocation().search);
  const orderType = type ?? order?.type;

  const Details = React.useMemo(() => renderDetails(orderType), [orderType]);

  return (
    <NavTabs>
      <NavTab
        title={translate('front.demand-detail.details.label')}
        path={pages.cardDemand.tabs.details(':id')}
      >
        {Details}
      </NavTab>
      {!!order?.state && (
        <NavTab
          title={translate('front.demand-detail.history.label')}
          path={pages.cardDemand.tabs.history(':id')}
        >
          <DemandHistoryPage />
        </NavTab>
      )}
    </NavTabs>
  );
};

export const CardDemandPage = withOrder(({ order }) => ({
  createOnSave,
  fetchOrder,
  allowState: true,
  allowDetails: true,
  allowSave:
    order && allowDemandAction(order.type as DemandType, OrderActionType.EDIT, +order.customerId),
  allowSign:
    order && allowDemandAction(order.type as DemandType, OrderActionType.SIGN, +order.customerId),
  disableButtons: true,
  afterSubmit: goBack,
}))(CardDemandComponent);
