import * as React from 'react';
// eslint-disable-next-line node/no-unpublished-import
import { createUseStyles } from 'react-jss';

import classnames from 'classnames';

import { RowContext } from './RowContext';
import { RowProps, ContainerConfig, SizeConfig } from './types';

const useStyles = createUseStyles({
  row: ({ scale, gap, alignItems, justifyContent }: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const cellWidth = `${100 / scale}%`;
    return {
      alignItems,
      justifyContent,
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: `-${gap}px`,
      marginRight: `-${gap}px`,
      // '@supports (display: flex)': {
      // },
      // '@supports (display: grid)': {
      //   display: 'grid',
      //   gridGap: 10,
      //   gridTemplateColumns: `repeat(${cellWidth}, calc(${cellWidth} - ${gap - 1}px))`,
      //   '&:after': {
      //     content: 'none',
      //   },
      // },
    };
  },
});

export const createRow = <S extends SizeConfig>(config: ContainerConfig<S>) => {
  const Row: React.FC<RowProps> = props => {
    const { children, scale, alignItems, justifyContent, className } = props;
    const classes = useStyles({ scale, justifyContent, alignItems, gap: 10 });

    return (
      <RowContext.Provider value={{ scale: scale || config.defaultScale }}>
        <div className={classnames(classes.row, { [className]: className })}>{children}</div>
      </RowContext.Provider>
    );
  };

  return Row;
};
