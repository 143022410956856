import React from 'react';

import { Option } from 'api/Service';
import { MaskField, normalizeIban } from 'components/forms/inputs/MaskField';
import { RadioField } from 'components/forms/inputs/RadioField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextField } from 'components/forms/inputs/TextField';
import config from 'config';
import {
  DepositDetailNames,
  RadioAmountAccTrancheFieldsName,
} from 'pages/Demands/DepositDemand/enums';
import {
  isRadioFieldChecked,
  SWIFT_ALLOWED_CHARACTERS_REGEXP,
  swiftValidation,
} from 'pages/Demands/DepositDemand/utils';
import { validateIban } from 'pages/Demands/utils';

const { ibanMask } = config.page.domesticPayment;

interface SelectDepositAmountDetailsProps {
  filteredAccounts: Option[];
  radioAmountAccTranche: string;
  radioAmountAccTrancheOptions: Option[];
}

export const SelectDepositAmountDetails = ({
  radioAmountAccTranche,
  radioAmountAccTrancheOptions,
  filteredAccounts,
}: SelectDepositAmountDetailsProps) => {
  const [isExternalAmountAcc, isExternalAmountSwiftAcc, isInternalAmountAcc] = React.useMemo(
    () =>
      Object.values(RadioAmountAccTrancheFieldsName).map(field =>
        isRadioFieldChecked(radioAmountAccTrancheOptions, radioAmountAccTranche, field),
      ),
    [radioAmountAccTranche],
  );

  return (
    <>
      <RadioField
        name={DepositDetailNames.RadioAmountAccTranche}
        options={radioAmountAccTrancheOptions}
        cssType="block"
      />
      {isExternalAmountAcc && (
        <MaskField
          name={DepositDetailNames.InterestOwnIbanTranche}
          label="front.changing-deposit-demand.externalMainNationalAccount.label"
          normalize={normalizeIban}
          validate={validateIban}
          mask={ibanMask}
          required
        />
      )}
      {isExternalAmountSwiftAcc && (
        <>
          <TextField
            name={DepositDetailNames.ExternalInterestSwiftAccCorrBank}
            label="front.changing-deposit-demand.externalMainAccCorrBank.label"
            required
          />
          <TextField
            name={DepositDetailNames.ExternalInterestSwiftAccCorrSwiftCode}
            label="front.changing-deposit-demand.externalMainAccCorrSwiftCode.label"
            maxLength={34}
            validate={swiftValidation}
            allowedCharacters={SWIFT_ALLOWED_CHARACTERS_REGEXP}
            required
          />
          <TextField
            name={DepositDetailNames.ExternalInterestSwiftAccBenefBank}
            label="front.changing-deposit-demand.externalMainAccBenefBank.label"
            required
          />
          <TextField
            name={DepositDetailNames.ExternalInterestSwiftAccCode}
            label="front.changing-deposit-demand.externalMainAccCode.label"
            maxLength={34}
            validate={swiftValidation}
            allowedCharacters={SWIFT_ALLOWED_CHARACTERS_REGEXP}
            required
          />
          <TextField
            name={DepositDetailNames.ExternalInterestSwiftAccNumber}
            label="front.changing-deposit-demand.externalMainAccNumber.label"
            maxLength={34}
            required
          />
          <TextField
            name={DepositDetailNames.ExternalInterestSwiftAccName}
            label="front.changing-deposit-demand.externalMainAccName.label"
            maxLength={34}
            required
          />
          <TextField
            name={DepositDetailNames.ExternalInterestSwiftAccAddress}
            label="front.changing-deposit-demand.externalMainAccAddress.label"
            required
          />
        </>
      )}
      {isInternalAmountAcc && (
        <SelectField
          name={DepositDetailNames.AmountOwnAccountTranche}
          label="front.changing-deposit-demand.ownMainAccount.label"
          options={filteredAccounts}
          required
        />
      )}
    </>
  );
};
