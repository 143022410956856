import * as React from 'react';

export interface ICertificateContext {
  expiredError: string;
  isSearchTokens: boolean;
  onSearchToken: () => void;
}

export const CertificateContext = React.createContext<ICertificateContext>({
  isSearchTokens: false,
  expiredError: null,
  onSearchToken: () => {},
});
