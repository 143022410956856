import * as React from 'react';

interface IconProps {
  className?: string;
  color?: string;
}

export const Star = ({ className, color = '#000' }: IconProps) => (
  <svg
    className={className}
    width="15"
    height="15"
    viewBox="0 0 15 15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="none" d="M-1-1h17v17H-1z" />
    <g>
      <path
        fill={color}
        stroke="null"
        d="M7.531 12.243l-4.615 2.712 1.03-5.445L.064 5.74l5.253-.653L7.53.045l2.216 5.042 5.252.653-3.883 3.77 1.03 5.445-4.615-2.712h0zm0-1.574l2.78 1.633-.622-3.279 2.338-2.27-3.162-.393L7.53 3.325 6.198 6.36l-3.163.392 2.338 2.27-.62 3.279 2.778-1.633z"
      />
    </g>
  </svg>
);
export const Draggable = ({ className, color = '#000' }: IconProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <path fill="none" d="M-1-1h24v22H-1z" />
    <g>
      <path
        fill={color}
        stroke="null"
        d="M6.207.615a1.124 1.124 0 011.571 0l2.358 2.32-1.048 1.032-2.095-2.063-2.095 2.063-1.049-1.031L6.207.616zM.37 7.907a1.082 1.082 0 010-1.547l2.358-2.32L3.775 5.07 1.68 7.133l2.095 2.062-1.047 1.032L.37 7.907zM13.615 6.36c.434.427.434 1.12 0 1.547l-2.358 2.32-1.047-1.032 2.096-2.062-2.096-2.062 1.047-1.031 2.358 2.32zM6.993 8.592c-.393 0-.77-.154-1.048-.428a1.447 1.447 0 01-.434-1.03c0-.388.156-.758.434-1.032a1.494 1.494 0 011.048-.427c.393 0 .77.154 1.047.427.278.274.434.644.434 1.031 0 .387-.156.758-.434 1.031a1.494 1.494 0 01-1.047.428zm.785 5.06a1.124 1.124 0 01-1.571 0L3.849 11.33 4.898 10.3l2.095 2.063L9.088 10.3l1.048 1.03-2.358 2.321z"
      />
    </g>
  </svg>
);

export const SingleColumn = ({ className, color = '#000' }: IconProps) => (
  <svg
    transform="translate(-0.25014805,-0.250148)"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <path
      fill={color}
      d="M3 5h8V3.6667H3V5zM11 7.6667H3V6.3333h8v1.3334zM3 10.3333h8V9H3v1.3333z"
    />
    <path
      fill={color}
      fillRule="evenodd"
      d="M.3334 1A.6667.6667 0 011 .3333h12A.6667.6667 0 0113.6667 1v12a.6667.6667 0 01-.6667.6667H1A.6667.6667 0 01.3334 13V1zm1.3333 11.3333V1.6667h10.6667v10.6666H1.6667z"
      clipRule="evenodd"
    />
  </svg>
);

export const DoubleColumn = ({ className, color = '#000' }: IconProps) => (
  <svg
    className={className}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill={color} d="M-1-1h3v2.937h-3z" />
    <g>
      <path
        fill={color}
        d="M7 5H3V3.667h4V5zM9 5h2V3.667H9V5zM7 7.667H3V6.333h4v1.334zM9 7.667h2V6.333H9v1.334zM7 10.333H3V9h4v1.333zM9 10.333h2V9H9v1.333z"
      />
      <path
        fill={color}
        fillRule="evenodd"
        stroke="null"
        d="M.804.052a.69.69 0 00-.69.693v12.464a.69.69 0 00.69.692h12.408c.38 0 .69-.31.69-.692V.745a.69.69 0 00-.69-.693H.804zm.69 1.385v11.08h11.028V1.436H1.493z"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

export const Layers = ({ className, color = '#000' }: IconProps) => (
  <svg
    className={className}
    width="14"
    height="15"
    viewBox="0 0 14 15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="none" d="M-1-1h24v22H-1z" />
    <g>
      <path
        fill={color}
        stroke="null"
        d="M12.813 9.915l.864.507a.355.355 0 01.175.301.345.345 0 01-.175.301L7.37 14.72a.732.732 0 01-.74 0L.323 11.024a.355.355 0 01-.175-.301.345.345 0 01.175-.301l.864-.507L7 13.322l5.813-3.407zm0-3.3l.864.506a.355.355 0 01.175.3.345.345 0 01-.175.302L7 11.636.323 7.723a.355.355 0 01-.175-.301.345.345 0 01.175-.301l.864-.507L7 10.021l5.813-3.407zM7.37.158l6.307 3.696a.355.355 0 01.175.301.345.345 0 01-.175.301L7 8.37.323 4.457a.355.355 0 01-.175-.301.345.345 0 01.175-.301L6.63.159a.732.732 0 01.74 0h0zM7 1.579L2.604 4.157 7 6.732l4.396-2.576L7 1.58z"
      />
    </g>
  </svg>
);

export const Success = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#4CBC5C"
      d="M40 80C17.908 80 0 62.092 0 40S17.908 0 40 0s40 17.908 40 40-17.908 40-40 40zm0-8a31.9998 31.9998 0 0022.6274-9.3726 31.9998 31.9998 0 000-45.2548 31.9998 31.9998 0 00-45.2548 0 31.9998 31.9998 0 000 45.2548A31.9998 31.9998 0 0040 72zm-3.988-16L19.04 39.028l5.656-5.656 11.316 11.316L58.636 22.06l5.66 5.656L36.012 56z"
    />
  </svg>
);
