import React from 'react';
import { TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { OrderSignSmsRow, OrderSignSmsRows } from 'api/interfaces';
import { DataTable } from 'components/Table/DataTable';

const columns: TableColumnProps<OrderSignSmsRow>[] = [
  {
    label: 'name',
    render: row => row.name,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'data',
    render: row => row.data,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

export const SmsModalTable = (rows: OrderSignSmsRows) => (
  <DataTable
    name={TableNamesEnum.Sms}
    columns={columns}
    fetchRows={() => Promise.resolve(rows)}
    hideHeader
    hidePagination
  />
);
