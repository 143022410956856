import { Reducer } from 'react';

import { ModalView } from 'components/modals/globalModalCollection/GlobalModalCollection';
import { PropsWithCollectionModal } from 'components/modals/globalModalCollection/interfaces';
import { MODAL_COLLECTION } from 'store/actions/types';
import { Action } from 'store/interfaces';

interface ModalCollectionState {
  modal: ModalView;
  props: PropsWithCollectionModal;
}

export const modalCollectionReducer: Reducer<ModalCollectionState[], Action> = (
  state = [],
  action,
) => {
  switch (action.type) {
    case MODAL_COLLECTION.ADD_MODAL_COLLECTION: {
      const modal = state.find(
        item => item.modal.configuration.id === action.payload.modal.configuration.id,
      );

      if (modal) {
        modal.modal = action.payload.modal;
        modal.props = action.payload.props;
        return [...state];
      }

      return [...state, { modal: action.payload.modal, props: action.payload.props }];
    }

    case MODAL_COLLECTION.REMOVE_MODAL_COLLECTION: {
      const modalIndex = state.findIndex(item => item.modal.configuration.id === action.payload.id);
      const currState = [...state];

      if (currState[modalIndex]) currState.splice(modalIndex, 1);

      return currState;
    }

    default:
      return state;
  }
};
