import React from 'react';

import { ChevronBottom } from 'components/icons';
import { Popover } from 'components/popover/Popover';
import { translate } from 'i18n/translator';
import { useUserInfo } from 'pages/Dashboard/UserInfo/useUserInfo';
import { ExpandedCustomer } from 'pages/Login/CustomerSelectModal/types';

import './styles.scss';

interface UserInfoProps {
  isSmall?: boolean;
  popoverChildren?: JSX.Element;
}

interface UserNameProps {
  chosenCustomers: ExpandedCustomer[];
  onChooseCustomers: any;
  userName: string;
}

const UserName: React.FC<UserNameProps> = ({ onChooseCustomers, chosenCustomers, userName }) => (
  <div className="user-info__name-inner-wrapper cursor-pointer" onClick={onChooseCustomers}>
    <div>{userName.length > 30 ? userName.substring(0, 30) + '...' : userName}</div>
    <div className="user-info__count-text">
      {chosenCustomers.length > 1
        ? `${translate('front.header.select-customers.label')} - ${chosenCustomers.length}`
        : chosenCustomers[0].name}
      <div className="arrow-wrapper">
        <ChevronBottom />
      </div>
    </div>
  </div>
);

export const UserInfo: React.FC<UserInfoProps> = ({ isSmall = false, popoverChildren }) => {
  const { chosenCustomers, avatar, onChooseCustomers, userFullName } = useUserInfo();

  const renderUserInfo = () => {
    if (isSmall) {
      return (
        <Popover
          trigger="click"
          placement="bottom"
          id="avatar"
          className="user-info-popover"
          label={<img className="avatar cursor-pointer" src={avatar} id="avatar" alt="avatar" />}
        >
          <div className="cursor-pointer">
            <UserName
              chosenCustomers={chosenCustomers}
              onChooseCustomers={onChooseCustomers}
              userName={userFullName}
            />
            {popoverChildren}
          </div>
        </Popover>
      );
    }

    return (
      <>
        <img className="avatar" src={avatar} id="avatar" alt="avatar" />
        <div className="user-info__name-wrapper">
          <UserName
            chosenCustomers={chosenCustomers}
            onChooseCustomers={onChooseCustomers}
            userName={userFullName}
          />
        </div>
      </>
    );
  };

  const InfoComponent = React.useMemo(renderUserInfo, [
    isSmall,
    chosenCustomers,
    onChooseCustomers,
    userFullName,
    avatar,
  ]);

  return <div className="user-info">{InfoComponent}</div>;
};
