import { ChosenCustomerModel } from 'api/UserService';
import Encrypt from 'utils/Encrypt';

import { get, post, put, del } from './backend';
import { getPage, GridRequest, GridResponse, HasId } from './Service';

export enum SessionStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

interface PersonalRole {
  code: string;
  deleted: boolean;
  id: number;
  name: string;
}

interface Contact {
  contact: string;
  contactType: string;
  id: number;
}

export interface PersonalInformation {
  contacts: Array<Contact>;
  currentOrgIntlAddress: string;
  currentOrgIntlCity: string;
  currentOrgIntlCountry: string;
  currentOrgIntlName: string;
  currentOrgName: string;
  defaultOrgIntlName: string;
  defaultOrgName: string;
  email: string;
  fullName: string;
  id: number;
  language: string;
  login: string;
  loginChanged: string;
  passwordChanged: string;
  passwordExpiry: string;
  phone: string;
  privatePersonId: number;
  require_sms: boolean;
  roles: PersonalRole[];
  status: string;
  userTaxCode: string;
}

export interface PersonalOrganization {
  bin: string;
  branch: string;
  id: number;
  isBlocked: boolean;
  isDefault: boolean;
  name: string;
  ownership: string;
  personId: number;
}

export interface ExternalApiSession extends HasId {
  active: boolean;
  customers: Array<ChosenCustomerModel>;
  minRequestGap: number;
  status: SessionStatus;
  token: string;
  userLogin: string;
  valid_from: string;
  valid_to: string;
}

export class ProfileService {
  async getInformation(): Promise<any> {
    const result = await get('/personal');
    return getPage([result], {}, null);
  }

  async changePassword(oldPass: string, newPass: string): Promise<void> {
    return put('/personal/pass', { oldPass, newPass });
  }

  async changeEmail(email: string, password: string): Promise<PersonalInformation> {
    const encryptedPassword = Encrypt.encryptNewPassword(password);

    return put('/personal/update', { email, password: encryptedPassword });
  }

  async getOrganizations(request: GridRequest): Promise<GridResponse<PersonalOrganization>> {
    return get('/personal/by-auth-person', request);
  }

  async setDefaultEnterprise(id: number): Promise<void> {
    return put('/personal/change-default', { id });
  }

  async getExternalApiSessions(request: GridRequest): Promise<GridResponse<ExternalApiSession>> {
    return get('/sessions', { ...request, channel: 'EXTERNAL_API' });
  }

  async createExternalApiSession(): Promise<void> {
    return post('/v1/user/external-session');
  }

  async revokeExternalApiSession(): Promise<void> {
    return del('/v1/user/external-session');
  }
}
