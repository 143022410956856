import { CardView } from 'api/ProductsService';

export const getCurrency = (row: CardView) => row.currency;

export const createCurrencyOption = (row: CardView) => ({
  label: row.currency,
  value: row.currency,
});

export const getType = (row: CardView) => row.cardType;

export const createTypeOption = (row: CardView) => ({ label: row.cardType, value: row.cardType });
