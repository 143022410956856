import * as React from 'react';

import { Button } from 'components/buttons/Button';
import { translate } from 'i18n/translator';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const errorSVG = require('./error.svg');

export default () => (
  <div className="login-page__sms-step">
    <img alt="error" src={errorSVG} />
    <div className="login-page__body__box__title">
      {translate('front.login-unavailable-title.label')}
    </div>
    <div className="login-page__bPody__box__sms-desc">
      {translate('front.login-unavailable-decs.label')}
    </div>
    <Button onClick={() => location.reload()} size="lg">
      {translate('front.login-form.unavailable-try-again.label')}
    </Button>
  </div>
);
