export enum CreditProlongationDetailsName {
  Customer = 'customer',
  Credit = 'credit',
  NewTerm = 'newTerm',
  FromDate = 'fromDate',
  Reason = 'reason',
  Amount = 'amount',
  Account = 'account',
  PeriodDays = 'periodDays',
  OwnFeeAccount = 'ownFeeAccount',
  CreditReason = 'creditReason',
  LinkedPayment = 'linkedPayment',
  DocumentNumber = 'documentNumber',
  DocumentDate = 'documentDate',
  DocumentReceiver = 'documentReceiver',
  DocumentAccount = 'documentAccount',
  DocumentAmount = 'documentAmount',
  Attach = 'attach',
}
