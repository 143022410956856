import React from 'react';

import { FileType } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { FileField } from 'components/forms/inputs/FileField';
import { PhoneField } from 'components/forms/inputs/PhoneField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextAreaField } from 'components/forms/inputs/TextAreaField';
import { TextField } from 'components/forms/inputs/TextField';
import { ByAmountIcon, SaleAmountIcon } from 'components/icons';
import { Col, Row } from 'components/react-grid';
import { toAmountFormatWithDot } from 'components/utils/format';
import { useDisabledCustomer } from 'hooks/useDisabledCustomer';
import { useFileExtensions } from 'hooks/useFileExtensions';
import { translate } from 'i18n/translator';
import { getClearValue, MAX_LENGTH_PURPOSE } from 'pages/Demands/CashTransferPage/utils';
import { CurrencyExchangeDetailsName } from 'pages/Demands/CurrencyExchange/enums';
import {
  CurrencyConversionFields,
  CurrencyPayload,
} from 'pages/Demands/CurrencyExchange/interfaces';
import { useEffectIfValueChange } from 'utils/hooks';
import { isExist, isString } from 'utils/isData';

import {
  BANK_RATE,
  divide,
  getCurrencyConversion,
  getFilteredAccounts,
  getUpdatedData,
  maskDecimalTenConfig,
  MIN_AMOUNT,
  multiply,
} from './utils';

import './styles.scss';

export const CurrencyConversionDetails = () => {
  const {
    order,
    payload: {
      [CurrencyExchangeDetailsName.AccountSale]: saleAccountOptions,
      [CurrencyExchangeDetailsName.AccountBuy]: buyAccountOptions,
      [CurrencyExchangeDetailsName.AccountFee]: feeAccountOptions,
      [CurrencyExchangeDetailsName.RateType]: rateTypeOptions,
      cashSymbolOptions,
      saleCurrencyAccount,
      buyCurrencyAccount,
    },
    onChangeCustomer,
    form: { updateData, getFormData, progress, disabled },
  } = useOrderDetails<CurrencyConversionFields, CurrencyPayload>();

  const fileExtensions = useFileExtensions();

  const {
    [CurrencyExchangeDetailsName.AccountSale]: saleAccountId,
    [CurrencyExchangeDetailsName.AccountBuy]: buyAccountId,
    amountBuy,
    amountSale,
    rateType,
    currencyRate,
    isBuyAmount = true,
  } = getFormData();

  const hasAmountBuy = isString(amountBuy);
  const hasAmountSale = isString(amountSale);
  const hasSaleAccount = isExist(saleAccountId);
  const hasBuyAccount = isExist(buyAccountId);
  const hasRateType = isExist(rateType);

  const saleCurrency = hasSaleAccount
    ? getCurrencyConversion(saleAccountOptions, saleAccountId, saleCurrencyAccount)
    : null;
  const buyCurrency = hasBuyAccount
    ? getCurrencyConversion(buyAccountOptions, buyAccountId, buyCurrencyAccount)
    : null;

  const hasSaleCurrency = isExist(saleCurrency);
  const hasBuyCurrency = isExist(buyCurrency);

  const isBankRate =
    `${rateType}` === rateTypeOptions?.find(item => item.content.value === BANK_RATE).value;

  const isDisabledCustomer = useDisabledCustomer(order);
  const isDisableRateType = !hasSaleAccount || !hasBuyAccount;
  const isDisableAmountBuy = !hasBuyAccount || !isBuyAmount;
  const isDisableAmountSale = !hasSaleAccount || isBuyAmount;
  const isDisableRate = !rateType || isBankRate || isDisableRateType;

  const isExistCurrencyPairs = React.useMemo(
    (): boolean =>
      hasSaleCurrency &&
      hasBuyCurrency &&
      !!cashSymbolOptions.find(option => option.code === `${saleCurrency}/${buyCurrency}`),
    [saleCurrency, buyCurrency],
  );

  React.useEffect(() => {
    (hasBuyCurrency || hasSaleCurrency) &&
      updateData({ isoCode: saleCurrency, receiverCurrency: buyCurrency });
  }, [isBuyAmount, saleCurrency, buyCurrency]);

  const filteredSaleAccountOptions = hasBuyCurrency
    ? getFilteredAccounts(saleAccountOptions, buyCurrency)
    : saleAccountOptions;

  const filteredBuyAccountOptions = hasSaleCurrency
    ? getFilteredAccounts(buyAccountOptions, saleCurrency)
    : buyAccountOptions;

  const onSelectRatyType = (id: number, { value }: { value: string }) =>
    updateData(getUpdatedData(value, isBuyAmount));
  const updateAmountSale = (amountSale: string) => updateData({ amountSale });
  const updateAmountBuy = (amountBuy: string) => updateData({ amountBuy });

  React.useEffect(() => {
    const numberRate = parseFloat(toAmountFormatWithDot(currencyRate));

    if (!hasAmountSale || (isBuyAmount && hasAmountBuy)) {
      updateAmountSale(null);
    }
    if (!hasAmountBuy || (!isBuyAmount && hasAmountSale)) {
      updateAmountBuy(null);
    }

    if (numberRate) {
      const numberAmountBuy = parseFloat(amountBuy);
      const numberAmountSale = parseFloat(amountSale);

      if (isBuyAmount && numberAmountBuy) {
        updateAmountSale(
          isExistCurrencyPairs
            ? divide(numberAmountBuy, numberRate)
            : multiply(numberAmountBuy, numberRate),
        );
      }

      if (!isBuyAmount && numberAmountSale) {
        updateAmountBuy(
          isExistCurrencyPairs
            ? multiply(numberAmountSale, numberRate)
            : divide(numberAmountSale, numberRate),
        );
      }
    }
  }, [currencyRate, isBuyAmount, amountBuy, amountSale, isExistCurrencyPairs]);

  const clearAmounts = () =>
    updateData({ [CurrencyExchangeDetailsName.AmountBuy]: null, amountSale: null });

  useEffectIfValueChange(saleCurrency, clearAmounts);
  useEffectIfValueChange(buyCurrency, clearAmounts);

  const handleClick = () => {
    if (progress || disabled) {
      return;
    }

    updateData({ [CurrencyExchangeDetailsName.IsBuyAmount]: !isBuyAmount });
  };

  const handleFocus = () => (isBuyAmount ? updateAmountBuy(null) : updateAmountSale(null));

  return (
    <DefaultForm>
      <h5 className="m-t-24">{translate('front.currency-exchange.titleLabel.conversion.label')}</h5>
      <CustomerSelect
        name={CurrencyExchangeDetailsName.Customer}
        selectDefaultCustomer
        label="front.currency-exchange.organization.label"
        onSelectOption={onChangeCustomer}
        disabled={isDisabledCustomer}
        required
      />
      <SelectField
        name={CurrencyExchangeDetailsName.AccountSale}
        label="front.currency-exchange.accountSale.conversion.label"
        options={filteredSaleAccountOptions}
        clearable
        required
      />
      <SelectField
        name={CurrencyExchangeDetailsName.AccountBuy}
        label="front.currency-exchange.accountBuy.conversion.label"
        options={filteredBuyAccountOptions}
        clearable
        required
      />
      <SelectField
        name={CurrencyExchangeDetailsName.RateType}
        label="front.currency-exchange.rateType.conversion.label"
        options={rateTypeOptions}
        onSelectOption={onSelectRatyType}
        disabled={isDisableRateType}
        required
      />
      <AmountField
        label="front.currency-exchange.rate.conversion.label"
        name={CurrencyExchangeDetailsName.CurrencyRate}
        maskConfig={maskDecimalTenConfig}
        disabled={isDisableRate}
        required={!isBankRate}
      />
      <Row>
        <Col sm={4}>
          <AmountField
            name={CurrencyExchangeDetailsName.AmountBuy}
            label="front.currency-exchange.amount.conversion.label"
            minAmount={MIN_AMOUNT}
            maxLengthWithoutZero={12}
            isoCode={buyCurrency}
            hasMoneyFormat
            thousandSeparator={' '}
            onFocus={handleFocus}
            disabled={isDisableAmountBuy}
            required={isBuyAmount}
            hasGrayBackground={!isBuyAmount && hasRateType}
          />
        </Col>
        <Col sm={1}>
          <div className="row-currency">{buyCurrency}</div>
        </Col>
        <Col sm={2}>
          <div className={progress ? 'amount-icon_disabled' : 'amount-icon'} onClick={handleClick}>
            {isBuyAmount ? <ByAmountIcon /> : <SaleAmountIcon />}
          </div>
        </Col>
        <Col sm={4}>
          <AmountField
            name={CurrencyExchangeDetailsName.AmountSale}
            label="front.currency-exchange.currency.conversion.label"
            minAmount={MIN_AMOUNT}
            maxLengthWithoutZero={12}
            isoCode={saleCurrency}
            hasMoneyFormat
            thousandSeparator={' '}
            onFocus={handleFocus}
            disabled={isDisableAmountSale}
            required={!isBuyAmount}
            hasGrayBackground={isBuyAmount && hasRateType}
          />
        </Col>
        <Col sm={1}>
          <div className="row-currency">{saleCurrency}</div>
        </Col>
      </Row>
      <TextAreaField
        name={CurrencyExchangeDetailsName.Purpose}
        rows={3}
        label="front.currency-exchange.purpose.conversion.label"
        minLength={5}
        maxLength={MAX_LENGTH_PURPOSE}
        normalize={getClearValue}
        hasCounter
        required
      />
      <FileField
        name={CurrencyExchangeDetailsName.Attach}
        label="front.currency-exchange.document.conversion.label"
        outputFormat={FileType.BASE_64}
        accept={fileExtensions}
        multiple
      />
      <SelectField
        name={CurrencyExchangeDetailsName.AccountFee}
        label="front.currency-exchange.accountFee.conversion.label"
        options={feeAccountOptions}
        required
      />
      <h5 className="m-t-24">{translate('front.currency-exchange.agentText.label')}</h5>
      <TextField
        label="front.currency-exchange.agent.label"
        name={CurrencyExchangeDetailsName.Agent}
      />
      <PhoneField
        label="front.create-authorized-person.phone.label"
        name={CurrencyExchangeDetailsName.Phone}
        required
      />
    </DefaultForm>
  );
};
