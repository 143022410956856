import { FieldData, FieldsData, FinalValue, FlatFormState, FormState } from './FormContext';

export function normalizeStringValue(value: string) {
  return value.trim().replace(/\s{2,}/g, ' ');
}

export function createFieldData(
  name: string,
  fieldName: string,
  value: FinalValue,
  fieldsData: FieldsData,
): FieldsData {
  const updatedField: FieldData = { ...fieldsData[name] };
  // @ts-ignore
  updatedField[fieldName] = value;
  return { ...fieldsData, [name]: { ...updatedField } };
}

export function setFieldData(
  name: string,
  fieldData: FieldData,
  fieldsData: FieldsData,
): FieldsData {
  return { ...fieldsData, [name]: { ...fieldsData[name], ...fieldData } };
}

export function toFormState(flat: FieldsData, trimStrings = false): FormState {
  return fromPlain(
    Object.entries(flat).reduce((acc, [fieldName, { isFieldArray, value }]) => {
      if (isFieldArray) return acc;
      // trim all string values
      const nextValue = trimStrings && typeof value === 'string' ? value.trim() : value;
      return { ...acc, [fieldName]: nextValue };
    }, {}),
  );
}

export const toPlain = (obj: FormState, isArrayToPlain?: boolean): FormState => {
  return flatten(obj, isArrayToPlain);
};

export function fromPlain(flat: FlatFormState): FormState {
  const state: FormState = {};

  Object.entries(flat).forEach(([path, value]) => {
    set(state, toArrayPath(path), value);
  });

  function set(substate: FormState, subpath: string[], value: FinalValue) {
    const isArray = (entry: string) => /^\d+$/.test(entry);
    const [current, next, ...tail] = subpath;

    if (!next) {
      substate[current] = value;
      return;
    }
    if (!substate[current]) {
      substate[current] = isArray(next) ? [] : {};
    }
    set(substate[current], [next, ...tail], value);
  }

  return state;
}

function flatten(obj: FormState, isArrayToPlain: boolean): FormState {
  const result: FormState = {};
  flattenObj(obj, result, '', isArrayToPlain);
  return result;
}

export function flattenObj(
  obj: FormState,
  result: FormState,
  prevKey = '',
  isArrayToPlain = false,
) {
  Object.entries(obj).forEach(([key, value]) => {
    let nextKey;
    if (typeof value === 'object' && value !== null && !(value instanceof Date)) {
      if (Array.isArray(value)) {
        if (isArrayToPlain) {
          for (let index = 0; index < value.length; index = index + 1) {
            nextKey = prevKey ? `${prevKey}.${key}[${index}]` : `${key}[${index}]`;
            flattenObj(value[index], result, nextKey);
          }
        } else {
          nextKey = prevKey ? `${prevKey}.${key}` : key;
          result[nextKey] = value;
        }
      } else {
        nextKey = prevKey ? `${prevKey}.${key}` : key;
        flattenObj(value, result, nextKey);
      }
    } else {
      nextKey = prevKey ? `${prevKey}.${key}` : key;
      result[nextKey] = value;
    }
  });
}

function toArrayPath(name: string): string[] {
  const path: string[] = [];
  let sub = '';

  for (let i = 0; i < name.length; i = i + 1) {
    const char = name[i];

    if (['.', '[', ']'].includes(char)) {
      if (sub.length) {
        path.push(sub);
        sub = '';
      }
      continue;
    } else {
      sub += char;
    }
  }

  if (sub) {
    path.push(sub);
  }

  return path;
}
