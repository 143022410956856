import React from 'react';

import { DemandType } from 'api/DemandsService/enums';
import { OrderActionType } from 'api/enums';
import { useOrder } from 'components/Document/useOrder';
import { withOrder } from 'components/Document/withOrder';
import { NavTab, NavTabs } from 'components/tabs/NavTabs';
import { translate } from 'i18n/translator';
import { allowDemandAction } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { DemandHistoryPage } from 'pages/Demands/DemandHistoryPage';
import { SMSNotificationDetail } from 'pages/Demands/SmsDemandPage/SMSNotificationDetail';
import { createOnSave, fetchOrder } from 'pages/Demands/utils';

import '../styles.scss';

const goBack = () => goto(pages.demands);

const SmsDemandComponent: React.FC = () => {
  const { order } = useOrder();

  return (
    <NavTabs>
      <NavTab
        title={translate('front.demand-detail.details.label')}
        path={pages.smsDemand.tabs.details(':id')}
      >
        <SMSNotificationDetail />
      </NavTab>
      {!!order?.state && (
        <NavTab
          title={translate('front.demand-detail.history.label')}
          path={pages.smsDemand.tabs.history(':id')}
        >
          <DemandHistoryPage />
        </NavTab>
      )}
    </NavTabs>
  );
};

export const SmsDemandPage = withOrder(({ order }) => ({
  createOnSave,
  fetchOrder,
  allowState: true,
  allowDetails: true,
  allowSave:
    order && allowDemandAction(order.type as DemandType, OrderActionType.EDIT, +order.customerId),
  allowSign:
    order && allowDemandAction(order.type as DemandType, OrderActionType.SIGN, +order.customerId),
  disableButtons: true,
  afterSubmit: goBack,
}))(SmsDemandComponent);
