import * as React from 'react';

import { bem } from 'components/utils/bem';
import { translate } from 'i18n/translator';

import './status.scss';

export type StatusColor = 'red' | 'green' | 'grey' | 'blue';

interface Props {
  children?: React.ReactNode;
  color?: StatusColor;
  status?: string;
}

export const Status: React.FC<Props> = props => {
  const { color = 'grey', children } = props;

  const className = bem('status').modificator('color', color).toClassName();
  return (
    <div className={className}>
      <span>{translate(status)}</span>
      <span>{children}</span>
    </div>
  );
};
