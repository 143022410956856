import * as React from 'react';

import { Page } from 'components/layout/Page/Page';
import { NavTabs, NavTab } from 'components/tabs/NavTabs';
import { translate } from 'i18n/translator';
import { pages } from 'navigations/pages';

import { DepartmentsListPage } from './DepartmentsListPage';
import { DepartmentsMapPage } from './DepartmentsMapPage';

import './styles.scss';

export const DepartmentsPage = () => (
  <Page title={translate('front.departments.title.label')}>
    <NavTabs className="tabs-padding">
      <NavTab
        title={translate('front.branches.tab-watch-list.label')}
        path={pages.departments.tabs.list}
      >
        <DepartmentsListPage />
      </NavTab>
      <NavTab
        title={translate('front.branches.tab-on-the-map.label')}
        path={pages.departments.tabs.map()}
      >
        <DepartmentsMapPage />
      </NavTab>
    </NavTabs>
  </Page>
);
