import * as React from 'react';
import { Popover as RsPopover, PopoverBody, PopoverHeader } from 'reactstrap';

import lodashDebounce from 'lodash.debounce';

import { Option } from 'api/Service';
import { TestSelectorProps } from 'test/TestSelectors';

import './styles.scss';

export type TPlacement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end';

interface Props extends TestSelectorProps {
  children: React.ReactNode;
  id: string;
  label: React.ReactNode | string | ((isOpen: boolean) => React.ReactNode);
  className?: string;
  disabled?: boolean;
  fade?: boolean;
  header?: React.ReactNode | string;
  isClickAutoClose?: boolean;
  placement?: TPlacement;
  trigger?: 'focus' | 'hover' | 'click' | 'legacy';
}

interface State {
  addedListener: boolean;
  popoverOpen: boolean;
}

const updateOnBodyScroll = {
  order: 9000,
  name: 'updateOnBodyScroll',
  enabled: true,
  fn: (data: any) => data,
  onLoad: (
    reference: string,
    popper: any,
    options: Option,
    modifierOptions: any,
    state: { isCreated: any; isDestroyed: any; updateBound: any },
  ) => {
    const updateOnScroll = () => {
      const { isCreated, isDestroyed, updateBound } = state;
      if (!isCreated || !updateBound) {
        return;
      }
      if (isDestroyed) {
        document.body.removeEventListener('scroll', updateOnScroll);
        return;
      }

      updateBound();
    };

    document.body.addEventListener('scroll', updateOnScroll);
  },
};

const modifiers = { updateOnBodyScroll };

export class Popover extends React.PureComponent<Props, State> {
  static defaultProps = {
    trigger: 'legacy',
    placement: 'left-start' as TPlacement,
    className: '',
    isClickAutoClose: true,
    fade: true,
    disabled: false,
  };
  state = {
    popoverOpen: false,
    addedListener: false,
  };
  closeOnScroll = lodashDebounce(
    () => {
      this.state.popoverOpen && this.setState({ popoverOpen: false });
    },
    500,
    { leading: true },
  );
  private placement: TPlacement = this.props.placement || Popover.defaultProps.placement;

  /** Close poper if scrolling page **/
  componentDidUpdate() {
    const { popoverOpen, addedListener } = this.state;
    if (popoverOpen && !addedListener) {
      document.body.addEventListener('scroll', this.closeOnScroll);
    } else {
      document.body.removeEventListener('scroll', this.closeOnScroll);
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener('scroll', this.closeOnScroll);
  }

  onToggle = (e: any) => {
    const isNeedChangePlacement = document.body.clientHeight - e.pageY < 250;
    if (isNeedChangePlacement) {
      this.placement = 'top' as TPlacement;
    } else {
      this.placement = this.props.placement;
    }
    this.setState(prevState => ({
      popoverOpen: !prevState.popoverOpen,
    }));
  };

  onClickPopover = () => {
    const { isClickAutoClose } = this.props;
    if (isClickAutoClose) {
      this.setState({ popoverOpen: false });
    }
  };

  render() {
    const {
      dataTestId = '',
      children,
      label,
      id,
      isClickAutoClose,
      header,
      placement,
      ...rest
    } = this.props;
    const { popoverOpen } = this.state;
    const newLabel = typeof label === 'function' ? label(popoverOpen) : label;

    return (
      <>
        <span className="wrap-popover" id={id} data-test-id={dataTestId}>
          {newLabel}
        </span>
        <RsPopover
          {...rest}
          placement={this.placement}
          modifiers={modifiers}
          isOpen={popoverOpen}
          target={id}
          toggle={this.onToggle}
          onClick={this.onClickPopover}
        >
          {header && <PopoverHeader>{header}</PopoverHeader>}
          <PopoverBody>{children}</PopoverBody>
        </RsPopover>
      </>
    );
  }
}
