export enum Privileges {
  // Common
  customer = 'customer',
  personal = 'personal',
  welcomePageView = 'welcomePage.view',
  signFolderView = 'sign_folder.view',
  contactNews = 'contact.news',
  settingsView = 'settings.view',
  settingsInformationEdit = 'settings.information.edit',
  // Products
  productView = 'product.view',
  productEdit = 'product.edit',
  productAccountView = 'product.account.view',
  productAccountEdit = 'product.account.edit',
  productDepositView = 'product.deposit.view',
  productDepositEdit = 'product.deposit.edit',
  productCreditView = 'product.credit.view',
  productCreditEdit = 'product.credit.edit',
  productCardView = 'product.card.view',
  productCardEdit = 'product.card.edit',
  productCardLimitView = 'product.card.limit.view',
  productCardLimitEdit = 'product.card.limit.edit',
  // Payments
  paymentView = 'payment.view',
  paymentEdit = 'payment.edit',
  paymentSign = 'payment.sign',
  paymentInternalView = 'payment.internal.view',
  paymentInternalEdit = 'payment.internal.edit',
  paymentInternalSign = 'payment.internal.sign',
  paymentDomesticView = 'payment.domestic.view',
  paymentDomesticEdit = 'payment.domestic.edit',
  paymentDomesticSign = 'payment.domestic.sign',
  paymentForeignView = 'payment.foreign.view',
  paymentForeignEdit = 'payment.foreign.edit',
  paymentForeignSign = 'payment.foreign.sign',
  paymentExposedView = 'payment.exposed.view',
  paymentExposedEdit = 'payment.exposed.edit',
  paymentExposedSign = 'payment.exposed.sign',
  paymentRegularView = 'payment.regular.view',
  paymentRegularEdit = 'payment.regular.edit',
  paymentRegularSign = 'payment.regular.sign',
  // Demands
  demandView = 'demand.view',
  demandEdit = 'demand.edit',
  demandSign = 'demand.sign',
  demandReferenceView = 'demand.reference.view',
  demandReferenceEdit = 'demand.reference.edit',
  demandReferenceSign = 'demand.reference.sign',
  demandDepositView = 'demand.deposit.view',
  demandDepositEdit = 'demand.deposit.edit',
  demandDepositSign = 'demand.deposit.sign',
  demandCreditView = 'demand.credit.view',
  demandCreditEdit = 'demand.credit.edit',
  demandCreditSign = 'demand.credit.sign',
  demandCardView = 'demand.card.view',
  demandCardEdit = 'demand.card.edit',
  demandCardSign = 'demand.card.sign',
  demandSalaryView = 'demand.salary.view',
  demandSalaryEdit = 'demand.salary.edit',
  demandSalarySign = 'demand.salary.sign',
  demandSMSView = 'demand.sms.view',
  demandSMSEdit = 'demand.sms.edit',
  demandSMSSign = 'demand.sms.sign',
  demandCurrencyExchangeView = 'demand.currency_exchange.view',
  demandCurrencyExchangeEdit = 'demand.currency_exchange.edit',
  demandCurrencyExchangeSign = 'demand.currency_exchange.sign',
  demandCashView = 'demand.cash.view',
  demandCashEdit = 'demand.cash.edit',
  demandCashSign = 'demand.cash.sign',
  //FeaMessage
  feamessageView = 'feamessage.view',
  feamessageEdit = 'feamessage.edit',
  feamessageSign = 'feamessage.sign',
  // Salary project
  salaryView = 'salary.view',
  salaryEdit = 'salary.edit',
  salarySign = 'salary.sign',
  salarySheetView = 'salary.sheet.view',
  salarySheetEdit = 'salary.sheet.edit',
  salarySheetSign = 'salary.sheet.sign',
  salaryEmployeeView = 'salary.employee.view',
  salaryEmployeeEdit = 'salary.employee.edit',
  salaryEmployeeSign = 'salary.employee.sign',
  // Administration
  adminOrganisationView = 'admin.organization.view',
  adminCompanyPersonView = 'admin.user.view',
  adminCompanyPersonEdit = 'admin.user.edit',
  adminPermissionView = 'admin.user.permission.view',
  adminPermissionEdit = 'admin.user.permission.edit',
  // Finance
  financeStatementView = 'finance.statement.view',
  financeTransactionHistoryView = 'finance.transaction_history.view',
  financeAnalyticsView = 'finance.analytics.view',
  // Acquiring
  acquiringStatementView = 'acquiring.statement.view',
  acquiringShopsView = 'acquiring.shops.view',
  acquiringShopsEdit = 'acquiring.shops.edit',
  acquiringShopsSign = 'acquiring.shops.sign',
  // EDS keys
  keysAddNew = 'keys.add_new',
  keysAddExisted = 'keys.add_existed',
  keysReissue = 'keys.reissue',
  keysDelete = 'keys.delete',
  keysChangePin = 'keys.change_pin',
  keysActivate = 'keys.activate',
  //SEAL keys
  sealKeysAddNew = 'seal.add_new',
  sealReissue = 'seal.reissue',
  sealDelete = 'seal.delete',
  sealChangePin = 'seal.change_pin',
  // Communication
  contactView = 'contact.view',
  contactLetterView = 'contact.letter.view',
  contactNotificationView = 'contact.notification.view',
  contactNotificationEdit = 'contact.notification.edit',
  contactSignedLetterView = 'contact.signed_letter.view',
  contactSignedLetterEdit = 'contact.signed_letter.edit',
  contactSignedLetterSign = 'contact.signed_letter.sign',
  // Information
  infoView = 'info.view',
  infoBranchView = 'info.branch.view',
  infoDictionaryView = 'info.dictionary.view',
  infoCurrencyRateView = 'info.currency_rate.view',
  infoCounterpartiesEdit = 'info.counterparties.edit',
  infoCounterpartiesView = 'info.counterparties.view',
  infoLogView = 'info.log.view',
  instructionDictionaryView = 'instruction.dictionary.view',
  // Registration
  registrationFlpView = 'registration.flp.view',
  externalApiEnabled = 'external_api.enabled',
  // Managers
  manageUsersCreate = 'manage_users.create',
  manageUsersManageRoles = 'manage_users.manage_roles',
  //Import
  importCurrencyBuy = 'c_data_import_currency_buy',
  // DocumentManagement
  protocolView = 'protocol.view',
  protocolSign = 'protocol.sign',
}
