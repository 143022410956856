import * as React from 'react';

import { useField } from 'components/forms/ValidatingForm/components/useField';
import { validateMessages } from 'components/validateMessages';
import { translate } from 'i18n/translator';

import { Checkbox } from '../checkbox/Checkbox';
import { FieldInput } from './FieldInput';

interface CheckboxProps {
  label: string;
  name: string;
  defaultValue?: boolean;
  disabled?: boolean;
  isHover?: boolean;
  link?: string;
  linkLabel?: string;
  onChange?(value: boolean): void;
  required?: boolean;
}

export const CheckboxField = ({
  name,
  label,
  required,
  onChange: externalOnChange,
  disabled,
  isHover,
  defaultValue = false,
  link,
  linkLabel,
}: CheckboxProps) => {
  const validate = React.useCallback(
    value => {
      if (required) {
        if (!value) {
          return translate(validateMessages.fieldRequired);
        }
      }

      return null;
    },
    [required],
  );

  const onCheckboxFieldChange = React.useCallback(
    (value, onChange) => {
      onChange(value);
      if (externalOnChange) externalOnChange(value);
    },
    [externalOnChange],
  );

  const { field, form } = useField({ name, defaultValue, validate });

  if (!field && !form) {
    return null;
  }

  return (
    <FieldInput
      label=""
      isLargeInput={false}
      isDisabled={disabled}
      hasOutline={false}
      name={name}
      error={field.error}
      warning={field.warning}
      value={field.value}
      isFocus
    >
      <Checkbox
        name={name}
        checked={!!field.value}
        label={label}
        link={link}
        disabled={disabled || form.disabled || form.progress}
        onChange={() => onCheckboxFieldChange(!field.value, field.onChange)}
        isHover={isHover}
        linkLabel={linkLabel}
      />
    </FieldInput>
  );
};
