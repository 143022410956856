import * as React from 'react';

import lodashThrottle from 'lodash.throttle';
import ResizeObserver from 'resize-observer-polyfill';

import { ContainerContext } from './ContainerContext';
import { ContainerConfig, SizeConfig } from './types';
import { calculateSize } from './utils';

export const createContainer = <S extends SizeConfig>(config: ContainerConfig<S>) => {
  const Container: React.FC<Partial<ContainerConfig<S>>> = props => {
    const {
      sizeConfig,
      containerElement: Element = 'div',
      children,
    } = {
      ...config,
      ...props,
    };

    const ref = React.useRef<HTMLElement>();
    const [size, setSize] = React.useState<keyof S>(Object.keys(sizeConfig)[0] as keyof S);

    React.useLayoutEffect(() => {
      const resizeObserver = new ResizeObserver(onResize);
      resizeObserver.observe(ref.current);

      return () => resizeObserver.unobserve(ref.current);
    }, []);

    const onResize = lodashThrottle(([entry]) => {
      if (ref.current) {
        const newSize = calculateSize(entry.contentRect.width, sizeConfig);
        setSize(newSize);
      }
    }, 300);

    return (
      <ContainerContext.Provider value={{ size, sizeConfig }}>
        <Element ref={ref} className="rcg-container">
          {children}
        </Element>
      </ContainerContext.Provider>
    );
  };

  return Container;
};
