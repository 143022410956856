import React from 'react';

import config from 'config';
import { getSafeTranslation } from 'i18n/translator';

import './styles.scss';

const currencyProductColors: Obj<string> = {
  UAH: config.page.product.currencyUAHColor,
  USD: config.page.product.currencyUSDColor,
  EUR: config.page.product.currencyEURColor,
};

interface ProductLogoProps {
  currency: string;
  type: string;
}

export const ProductLogo = ({ type, currency }: ProductLogoProps) => {
  const translationKey = type
    .split(' ')
    .map(letter => letter[0])
    .join('')
    .toUpperCase();
  const logoBackground =
    currencyProductColors[currency] || config.page.product.currencyDefaultColor;

  return (
    <div style={{ background: logoBackground }} className="product-logo">
      {getSafeTranslation(translationKey)}
    </div>
  );
};
