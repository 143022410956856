import * as React from 'react';
import { Action, Menu, TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { DemandType } from 'api/DemandsService/enums';
import { DemandResponse } from 'api/DemandsService/interfaces';
import { OrderActionType } from 'api/enums';
import { SortOrder } from 'api/Service';
import { getStatusColor } from 'api/utils';
import { Page } from 'components/layout/Page/Page';
import { Status } from 'components/layout/Status';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import * as format from 'components/utils/format';
import { translate } from 'i18n/translator';
import { allowDemandAction, allowPrivilegy } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { NEW } from 'navigations/routes';
import { getTranslateAction } from 'pages/Payments/general';
import { signCertificateOrders, signOrders } from 'pages/utils/SignOrders/signOrders';

import { EmployeesManagementFilter } from './EmployeesManagementFilter';

const filterByAction = (doc: DemandResponse, action: OrderActionType) => {
  return doc.actions.some(item => item.actionName === action);
};

const columns: TableColumnProps<DemandResponse>[] = [
  {
    label: 'front.demands-table.date.label',
    render: row => <DataColumn title={format.date(row.base.orderDate)} />,
    sort: 'orderDate',
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.demands-table.number.label',
    render: row => <DataColumn title={row.base.orderNumber} />,
    sort: 'orderNumber',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.demands-table.type.label',
    render: row => <DataColumn title={translate(row.base.translatedOrderType)} />,
    sort: 'orderType',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.demands-table.status.label',
    render: row => (
      <DataColumn>
        <Status color={getStatusColor(row?.base.orderState)}>{row.base.orderStateLabel}</Status>
      </DataColumn>
    ),
    sort: 'orderState',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    renderMenu: (row): Menu<DemandResponse>[] => [
      {
        label: getTranslateAction(OrderActionType.EDIT),
        onClick: () =>
          goto(pages.employeeManagement.tabs.details(row.id, { type: row.base.orderType })),
        isVisible:
          filterByAction(row, OrderActionType.EDIT) &&
          allowDemandAction(
            row.base.orderType as DemandType,
            OrderActionType.EDIT,
            row.base.customerId,
          ),
      },
      {
        label: getTranslateAction(OrderActionType.SIGN),
        onClick: async () => {
          const action = row.actions.find(item => item.actionName === OrderActionType.SIGN);
          if (
            action &&
            (action.confirmation.includes('Signature') || action.confirmation.includes('EDS'))
          ) {
            await signCertificateOrders([{ id: row.id, customerId: row.base.customerId }]);
          } else {
            await signOrders([row.id]);
          }
        },
        isReloadable: true,
        isVisible:
          filterByAction(row, OrderActionType.SIGN) &&
          allowDemandAction(
            row.base.orderType as DemandType,
            OrderActionType.SIGN,
            row.base.customerId,
          ),
      },
      {
        label: getTranslateAction(OrderActionType.PRINT),
        onClick: async () => await api.demands.getOrderPdf(row.id, true),
        isVisible:
          filterByAction(row, OrderActionType.PRINT) &&
          allowDemandAction(
            row.base.orderType as DemandType,
            OrderActionType.PRINT,
            row.base.customerId,
          ),
      },
      {
        label: getTranslateAction(OrderActionType.COPY),
        onClick: () => goto(pages.employeeManagement.tabs.details(NEW, { copyFrom: `${row.id}` })),
        isVisible:
          filterByAction(row, OrderActionType.COPY) &&
          allowDemandAction(
            row.base.orderType as DemandType,
            OrderActionType.COPY,
            row.base.customerId,
          ),
      },
      {
        label: getTranslateAction(OrderActionType.DELETE),
        onClick: async () => await api.demands.deleteOrder(row.id),
        isReloadable: true,
        confirmMessage: 'front.working-documents-table.actions-delete-confirmation.demand.label',
        isVisible:
          filterByAction(row, OrderActionType.DELETE) &&
          allowDemandAction(
            row.base.orderType as DemandType,
            OrderActionType.DELETE,
            row.base.customerId,
          ),
      },
    ],
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

const actions: Action<DemandResponse>[] = [
  {
    label: 'front.misc.sign-subscribe-sms.label',
    action: async data => {
      const orders = data.filter(item =>
        item.actions.some(
          action =>
            action.actionName === OrderActionType.SIGN && action.confirmation.includes('SMS'),
        ),
      );
      await signOrders(orders.map(item => item.id));
    },
    isReloadable: true,
    isVisible: selected =>
      selected.some(item =>
        item.actions.some(
          action =>
            action.actionName === OrderActionType.SIGN && action.confirmation.includes('SMS'),
        ),
      ),
  },
  {
    label: 'front.misc.sign-electronic-sign.label',
    action: async data => {
      const orders = data.filter(item =>
        item.actions.some(
          action =>
            action.actionName === OrderActionType.SIGN &&
            (action.confirmation.includes('Signature') || action.confirmation.includes('EDS')),
        ),
      );
      await signCertificateOrders(
        orders.map(item => ({ id: item.id, customerId: item.base.customerId })),
      );
    },
    isReloadable: true,
    isVisible: selected =>
      selected.some(item =>
        item.actions.some(
          action =>
            action.actionName === OrderActionType.SIGN &&
            (action.confirmation.includes('Signature') || action.confirmation.includes('EDS')),
        ),
      ),
  },
  {
    label: 'front.working-documents-table.actions-revoke.label',
    action: async (data: DemandResponse[]) => {
      const orders = data.filter(
        item =>
          item.actions.some(action => action.actionName === OrderActionType.REVOKE) &&
          allowPrivilegy([Privileges.demandSalaryEdit]),
      );
      await api.demands.withdrawOrders(orders.map(item => item.id));
    },
    color: 'secondary',
    isVisible: selected =>
      selected.some(item =>
        item.actions.some(action => action.actionName === OrderActionType.REVOKE),
      ) && allowPrivilegy([Privileges.demandSalaryEdit]),
  },
  {
    label: getTranslateAction(OrderActionType.DELETE),
    action: async data => {
      const orders = data.filter(item =>
        item.actions.some(action => action.actionName === OrderActionType.DELETE),
      );
      for (let i = 0; i < orders.length; i += 1) {
        await api.demands.deleteOrder(orders[i].id);
      }
    },
    color: 'secondary',
    confirmMessage: 'front.working-documents-table.actions-delete-confirmation.label',
    isVisible: selected =>
      selected.some(item =>
        item.actions.some(action => action.actionName === OrderActionType.DELETE),
      ) && allowPrivilegy([Privileges.demandSalaryEdit]),
    isReloadable: true,
  },
];

export const EmployeesManagementPage = () => (
  <Page title={translate('front.staff-management.title.label')}>
    <DataTable
      name={TableNamesEnum.EmployeesManagement}
      columns={columns}
      fetchRows={api.demands.getDemands}
      actions={actions}
      onRowClick={row =>
        goto(pages.employeeManagement.tabs.details(row.id, { type: row.base.orderType }))
      }
      defaultSort="id"
      defaultOrder={SortOrder.Desc}
      filterComponent={EmployeesManagementFilter}
    />
  </Page>
);
