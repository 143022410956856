import { GridRequest, SortOrder } from 'api/Service';
import { TableColumnProps, TableSortType } from 'components/react-data-table/types';
import { LOCAL_STORAGE_KEY } from 'store/actions/types';
import { isExist } from 'utils/isData';
import { LocalStorage } from 'utils/LocalStorage';

export interface TableConfig {
  [key: string]: string | string[];
}

const getDefaultTableConfig = <R>(columns: TableColumnProps<R>[]): TableConfig =>
  columns.reduce(
    (acc, col) => {
      if (!col || col.renderMenu) return acc;
      if (col.showMobile) acc.mobile.push(col.label);
      if (col.showTablet) acc.tablet.push(col.label);
      if (col.showDesktop) acc.desktop.push(col.label);

      return acc;
    },
    { mobile: [], tablet: [], desktop: [] },
  );

export const getInitialTableConfig = <R>(
  tableName: string,
  columns: TableColumnProps<R>[],
): TableConfig => {
  const tableSettings = LocalStorage.getItem(LOCAL_STORAGE_KEY.TABLE_SETTINGS, {});

  return tableSettings[tableName]?.columns || getDefaultTableConfig<R>(columns);
};

export const getInitialGridRequest = (
  tableName: string,
  defaultOrder: SortOrder,
  defaultSort: TableSortType | TableSortType[],
  defaultPageSize: number,
  shouldUseStorageSort: boolean,
  shouldUseStorageSize: boolean,
  hidePagination: boolean,
): GridRequest => {
  const tableSettings = LocalStorage.getItem(LOCAL_STORAGE_KEY.TABLE_SETTINGS, {});
  const gridRequest = tableSettings[tableName]?.gridRequest;

  const hasGridRequest = isExist(gridRequest);
  const hasSort = hasGridRequest && shouldUseStorageSort;
  const hasSize = hasGridRequest && shouldUseStorageSize && !hidePagination;

  return {
    order: hasSort ? gridRequest.order : defaultOrder,
    sort: hasSort ? gridRequest.sort : defaultSort,
    size: hasSize ? gridRequest.size : defaultPageSize,
    page: 0,
  };
};

export const getToggleColumn = (
  columnKey: string | string[],
  tableConfig: TableConfig,
  breakpoint: string,
) => {
  const checkedColumns = new Set(tableConfig[breakpoint]);
  const foundColumnKey = (tableConfig[breakpoint] as string[]).find(
    item => JSON.stringify(item) === JSON.stringify(columnKey),
  );

  if (checkedColumns.has(foundColumnKey)) {
    checkedColumns.delete(foundColumnKey);
  } else {
    checkedColumns.add(columnKey as string);
  }
  return { [breakpoint]: [...checkedColumns] };
};
