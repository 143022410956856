import { CertificateStatus, CertificateType, UserKeystoreType } from 'api/CertificateService/enums';
import { Certificate } from 'api/CertificateService/interfaces';
import { ConstantEnum } from 'api/enums';
import { diffWithCurrentDate } from 'components/utils/format';
import { translate } from 'i18n/translator';
import store from 'store';

export const parseErrorMessage = (e: { message: string }) => {
  try {
    return JSON.parse(e.message).message;
  } catch (err) {
    return e.message;
  }
};

export const isAvtorCertificate = (certificate: Certificate): boolean =>
  !certificate.isCertificateCaReissueAllowed && certificate.isCertificateCaRevokeAllowed;

export const isCipherCskCertificate = (certificate: Certificate): boolean =>
  certificate.isCertificateCaReissueAllowed && certificate.isCertificateCaRevokeAllowed;

export const isAvtorCipherCskCertificate = (certificate: Certificate): boolean =>
  isAvtorCertificate(certificate) || isCipherCskCertificate(certificate);

const statuses = {
  [CertificateStatus.Active]: 'front.cert-page.status-active.label',
  [CertificateStatus.New]: 'front.cert-page.status-new.label',
  [CertificateStatus.NotValid]: 'front.cert-page.status-not-valid.label',
  [CertificateStatus.Certified]: 'front.cert-page.status-certified.label',
  [CertificateStatus.Processing]: 'front.cert-page.status-processing.label',
  [CertificateStatus.Unknown]: 'front.cert-page.status-processing.label',
  [CertificateStatus.Rejected]: 'front.cert-page.status-rejected.label',
  [CertificateStatus.Blocked]: 'front.cert-page.status-blocked.label',
};

export const parseStatusToLocalization = (status: CertificateStatus): string =>
  translate(statuses[status]);

export const allowReissue = (days: number): boolean => {
  const storeState = store.getState();
  const allowedDaysForReissue = +storeState.constantState[ConstantEnum.AllowedDaysForReissue];
  return days < allowedDaysForReissue;
};

const TODAY = 0;

export const isCertificateNotExpired = (date: Date | string): boolean =>
  diffWithCurrentDate(date) >= TODAY;

export const allowCertificate = (certificate: Certificate): boolean =>
  isAvtorCertificate(certificate) || certificate.userKeystoreType === UserKeystoreType.CloudStorage;

export const isESeal = (certificateType: CertificateType): boolean =>
  certificateType === CertificateType.Seal;
