import * as React from 'react';

import { api } from 'api';
import { ClientAddInfo } from 'api/RegistrationService';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { TextField } from 'components/forms/inputs/TextField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { translate } from 'i18n/translator';
import { RegistrationContext } from 'pages/Registration/RegistrationContext';

interface Fields extends ClientAddInfo {
  isAddressFact: boolean;
}

const ClientAddInfoPageForm: React.FC = () => {
  const { getClientAddInfo, onConfirmClientAddInfo } = useClientAddInfo();

  const onRegister = async (formData: Fields) => {
    const { isAddressFact, ...fields } = formData;
    await onConfirmClientAddInfo(fields);
  };

  const { progress, error, handleSubmit, getFieldValue } = useForm<Fields>(
    async ({ setFields }) => {
      const { email } = await getClientAddInfo();
      setFields({ email });
    },
  );

  const isAddressFact = getFieldValue('isAddressFact') as boolean;

  return (
    <div className="" style={{ position: 'initial' }}>
      <div className="login-page__body__box card" style={{}}>
        <div className="card-body">
          <div className="login-page__body__box__title">
            {translate('front.registration.client-add-info-page.title.label')}
          </div>
          <DefaultForm>
            <FormError>{error}</FormError>
            <TextField
              label="front.registration.client-add-info-page.field-login.label"
              name="login"
              required
            />
            <TextField
              label="front.registration.client-add-info-page.field-email.label"
              name="email"
              email
              required
            />
            <CheckboxField
              label="front.registration.client-add-info-page.field-is-address-fact.label"
              name="isAddressFact"
              defaultValue={false}
            />
            <TextField
              label="front.registration.client-add-info-page.field-address-fact.label"
              name="addressFact"
              defaultValue={''}
              required={!isAddressFact}
              disabled={isAddressFact}
            />
            <Button
              color="primary"
              onClick={e => handleSubmit(onRegister, e)}
              progress={progress}
              className="login-page__form__btn"
              type="submit"
              size="lg"
            >
              {translate('front.registration.client-add-info-page.button-continue.label')}
            </Button>
          </DefaultForm>
        </div>
      </div>
    </div>
  );
};

export const ClientAddInfoPage = withForm(ClientAddInfoPageForm);

const useClientAddInfo = () => {
  const { orderId, uuid, setStep } = React.useContext(RegistrationContext);

  const getClientAddInfo = async (): Promise<{ email: string }> => {
    return await api.registration.getClientAddInfo(orderId, uuid);
  };

  const onConfirmClientAddInfo = async (formData: ClientAddInfo) => {
    setStep(await api.registration.confirmClientAddInfo(orderId, uuid, formData));
  };

  return { onConfirmClientAddInfo, getClientAddInfo };
};
