import * as React from 'react';
import { Button as BsButton } from 'reactstrap';

import { ELabelTypes, ExportFormatEnum } from 'components/ExportDropdown/enum';
import { useExport } from 'components/ExportDropdown/useExport';
import * as Icon from 'components/icons';
import { BeatLoader } from 'components/Loader/Loader';
import { Popover } from 'components/popover/Popover';
import { translate } from 'i18n/translator';

import './styles.scss';

export type TExportTypes = Record<string, ExportFormatEnum>;

export const MTFormat = {
  MT: ExportFormatEnum.MT,
  MT940: ExportFormatEnum.MT940,
  MT940f: ExportFormatEnum.MT940f,
  MT942: ExportFormatEnum.MT942,
};

type Action = (exportType: ExportFormatEnum) => () => Promise<void>;

interface CreateActionsParams {
  action: Action;
  exportTypes: Record<string, ExportFormatEnum>;
}

export interface Actions {
  action: () => void;
  label: string;
}

interface Props {
  actions: Actions[];
  disabled?: boolean;
  labelType?: ELabelTypes;
  marginBottom?: string;
}

export const getExportActions = ({ action, exportTypes }: CreateActionsParams): Array<Actions> =>
  Object.entries(exportTypes).map(([label, exportType]) => ({
    label,
    action: action(exportType),
  }));

const TextLabel: React.FC<any> = props => {
  const { marginBottom = '15px' } = props;
  return (
    <div className="export-button" style={{ marginBottom }}>
      <Icon.Export />
      <span className="export-button__name">
        {translate('front.working-documents-table.actions-export.label')}
      </span>
    </div>
  );
};

const ButtonLabel: React.FC<any> = props => {
  const { color = 'primary', ...rest } = props;
  return (
    <BsButton className="bs-button bs-button-dropdown" color={color} size="md" {...rest}>
      {translate('front.working-documents-table.actions-export.label')}
    </BsButton>
  );
};

const createLabel = (type: ELabelTypes, props: Props) => {
  const labels = {
    [ELabelTypes.button]: <ButtonLabel {...props} />,
    [ELabelTypes.text]: <TextLabel {...props} />,
  };
  return labels[type];
};

export const ExportDropdown: React.FC<Props> = props => {
  const { actions, disabled, labelType = ELabelTypes.text } = props;
  const { loaders, createOnClick } = useExport();
  const Label = createLabel(labelType, props);

  return (
    <Popover fade={false} placement="bottom" id="export-popover" disabled={disabled} label={Label}>
      <ul className="list-reset export-list">
        {actions.map(({ label, action }) => (
          <DropDownItem
            key={label}
            label={label}
            onClick={createOnClick(label, action)}
            loading={loaders[label]}
          />
        ))}
      </ul>
    </Popover>
  );
};

const DropDownItem: React.FC<any> = ({ label, onClick, loading }) =>
  loading ? <BeatLoader size={5} loading={loading} /> : <li onClick={onClick}>{label}</li>;
