import * as React from 'react';
import { useDispatch } from 'react-redux';

import { api } from 'api';
import { FactorsChain } from 'api/AuthService';
import { ConstantList } from 'api/ConstantService';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { PasswordField } from 'components/forms/inputs/PasswordField';
import { TextField } from 'components/forms/inputs/TextField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { Switch } from 'components/Switch';
import { validateMessages } from 'components/validateMessages';
import { translate } from 'i18n/translator';
import { FactorType } from 'pages/Login/LoginPage';
import { useLoginContext } from 'pages/Login/useLoginContext';
import { ConstantActions } from 'store/actions/constant';
import { LOCAL_STORAGE_KEY } from 'store/actions/types';
import { UserActions } from 'store/actions/user';
import { LoginType, UserState } from 'store/reducers/user';
import { LocalStorage } from 'utils/LocalStorage';

import { EncryptCredentials } from '../EncryptCredentials';

interface Fields {
  login: string;
  password: string;
}

const CYRILLIC_REGEX = /[а-яА-ЯіїєёґІЇЁЄҐ]/;

const warn = (value: string) => {
  if (CYRILLIC_REGEX.test(value)) {
    return translate(validateMessages.passwordNoCyrillicSymbols);
  }
  return '';
};

enum PriorityFactor {
  FirstStep = 1,
  SecondStep,
  AnotherStep,
  OtherStep,
}

const sortFactorTypes = (factorTypes: FactorsChain, isSignatureAuth: boolean) => {
  const sort: Record<string, PriorityFactor> = {
    Password: PriorityFactor.FirstStep,
    Signature: isSignatureAuth ? PriorityFactor.SecondStep : PriorityFactor.AnotherStep,
    SMS: isSignatureAuth ? PriorityFactor.AnotherStep : PriorityFactor.SecondStep,
  };

  const factors = factorTypes.map(item => ({
    ...item,
    order: sort[item.factorType] || PriorityFactor.OtherStep,
  }));
  return factors.sort((a, b) => (a.order > b.order ? 1 : -1));
};

const PasswordLogin = () => {
  const [isSignatureAuth, setIsSignatureAuth] = React.useState<boolean>(
    !!LocalStorage.getItem(LOCAL_STORAGE_KEY.IS_AUTHORIZED_BY_EDS),
  );

  const { setCurrentFactor, setFactorsChain, setCertificates } = useLoginContext();

  const dispatch = useDispatch();

  const dispatchCollectUser = (user: Partial<UserState>) =>
    dispatch(UserActions.setCollectUser(user));

  const dispatchConstants = (constants: Partial<ConstantList>) =>
    dispatch(ConstantActions.setConstants(constants));

  const toggleIsAuthorizedByEds = (e: any) => {
    setIsSignatureAuth(!isSignatureAuth);
    localStorage.setItem(LOCAL_STORAGE_KEY.IS_AUTHORIZED_BY_EDS, String(e.target.checked));
  };

  const onLogin = async (formData: Fields) => {
    const { login, password } = formData;
    const encryptedData = await EncryptCredentials(login, password);

    const { value, action, factorsChain, phoneNumber, certificates } =
      await api.auth.login(encryptedData);

    if (action === 'setPassword') {
      setCurrentFactor({
        type: FactorType.ChangePassword,
        payload: { login, password },
      });
      return;
    }

    dispatchCollectUser({ value, phoneNumber, loginType: LoginType.AUTHORIZATION });

    const constants = await api.constant.getConstantsList();
    dispatchConstants(constants);

    setCertificates(certificates);
    setFactorsChain(sortFactorTypes(factorsChain, isSignatureAuth));
  };
  const { progress, error, handleSubmit } = useForm<Fields>();

  return (
    <>
      <div className="login-page__body__box__title">
        <span>{translate('front.login-form.title.label')}</span>
      </div>
      <DefaultForm className="login-page__form" disabled={progress}>
        <FormError className="login-page__form__server-error">{error}</FormError>
        <div className="login-page__form__field">
          <TextField label="front.login-form.login.label" name="login" required isLargeInput />
        </div>
        <div className="login-page__form__field last">
          <PasswordField
            label="front.login-form.password.label"
            name="password"
            warn={warn}
            isLargeInput
            required
          />
        </div>
        <Button
          color="primary"
          onClick={e => handleSubmit(onLogin, e)}
          progress={progress}
          className="login-page__form__btn"
          type="submit"
          size="lg"
          label="front.login-form.login-button.label"
        />
        <Switch
          checked={isSignatureAuth}
          className="password-login-switch"
          labelText={translate(
            isSignatureAuth
              ? 'front.login-form.enter-with-pin.label'
              : 'front.login-form.enter-with-sms.label',
          )}
          onChange={(e: any) => toggleIsAuthorizedByEds(e)}
          isGrey
        />
      </DefaultForm>
    </>
  );
};

export default withForm(PasswordLogin);
