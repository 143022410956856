import * as React from 'react';
import { Col, Row, Container } from 'react-grid';
import { useRouteMatch } from 'react-router';

import { api } from 'api';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { TextField } from 'components/forms/inputs/TextField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { translate } from 'i18n/translator';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';

interface Fields {
  cardName: string;
  customerId: number;
  id: number;
}

const CardEditForm = () => {
  const {
    params: { id: cardId },
  } = useRouteMatch<{ id: string }>();

  const { handleSubmit, error, progress } = useForm<Fields>(async ({ setFields }) => {
    const {
      productView: { cardName, customerId, id },
    } = await api.products.getCard(cardId);
    setFields({ cardName, customerId, id });
  });

  const onSave = async ({ cardName, ...data }: Fields) => {
    await api.products.updateCard({ cardName: cardName.trim(), ...data });

    goto(pages.cards);
  };

  return (
    <>
      <h4 className="text-center">{translate('front.card-block.product-personalization.label')}</h4>

      <DefaultForm>
        <FormError>{error}</FormError>
        <div className="card-block__form__sub-title">
          {translate('front.card-block.edit-card-name.label')}
        </div>
        <Container>
          <Row>
            <Col md={12}>
              <TextField label="front.card-block.enter-name.label" name="cardName" required />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Button
                color="primary"
                onClick={e => handleSubmit(onSave, e)}
                progress={progress}
                type="submit"
                size="sm"
                block
              >
                {translate('front.card-block.apply.label')}
              </Button>
            </Col>
            <Col sm={6}>
              <Button
                color="secondary"
                onClick={() => goto(pages.cards)}
                progress={progress}
                type="submit"
                size="sm"
                block
              >
                {translate('front.card-block.cancel.label')}
              </Button>
            </Col>
          </Row>
        </Container>
      </DefaultForm>
    </>
  );
};

export const CardEditPage = withForm(CardEditForm);
