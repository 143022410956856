import React from 'react';

import { PatternFormatField } from 'components/forms/amountInput/PatternFormat';
import { useField } from 'components/forms/ValidatingForm/components/useField';
import { validateMessages } from 'components/validateMessages';
import { useUserCredentialsValidate } from 'hooks/useUserCredentialsValidate';
import { translate } from 'i18n/translator';

import { Props } from '../amountInput/NumericFormat';
import { FieldInput } from './FieldInput';
import { PreloadField } from './PreoadField';

interface AmountProps extends Partial<Omit<Props, 'onChange'>> {
  label: string;
  name: string;
  defaultValue?: number | string;
  normalize?: (value: string) => string;
  onChange?: (value: string) => void;
  outline?: boolean;
  required?: boolean;
}

export const phoneMaskConfig = {
  mask: '_',
  format: '+38 (###) #######',
  allowEmptyFormatting: true,
};

const emptyPhoneMask = '+38 (___) _______';

const EVENT_TYPE_COPY = 'paste';

export const PhoneField = (props: AmountProps) => {
  const {
    name,
    label,
    required,
    disabled,
    placeholder,
    onChange,
    onBlur,
    normalize = value => value,
    autoFocus = false,
  } = props;

  const onFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    onBaseChange: (value: string) => void,
  ) => {
    const value = e.target.value !== emptyPhoneMask ? e.target.value : '';
    const normalizedValue = normalize(value);
    onBaseChange(normalizedValue);
    onChange && onChange(normalizedValue);
  };

  const { validatePhone } = useUserCredentialsValidate();

  const handleCopy = (event: React.ClipboardEvent<HTMLInputElement>) => {
    if (event.type === EVENT_TYPE_COPY) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  const onFieldBlur = (baseOnBlur: () => void) => {
    baseOnBlur();
    onBlur && onBlur();
  };

  const validate = React.useCallback(
    (value: string): string => {
      if (required) {
        if (!value) {
          return translate(validateMessages.fieldRequired);
        }
      }

      return value && translate(validatePhone(value));
    },
    [required],
  );

  const { field, form } = useField({ name, validate, required });

  if (!field && !form) {
    return null;
  }

  const isDisabled = disabled || form.disabled || form.progress;

  return (
    <PreloadField isLoading={form.initializing}>
      <FieldInput
        isFocus
        name={name}
        label={label}
        value={field.value}
        error={field.error}
        warning={field.warning}
        isDisabled={isDisabled}
      >
        <PatternFormatField
          id={name}
          type="text"
          placeholder={placeholder}
          disabled={isDisabled}
          value={(field.value as string) || ''}
          onChange={e => onFieldChange(e, field.onChange)}
          onBlur={() => onFieldBlur(field.onBlur)}
          autoFocus={autoFocus}
          autoComplete={`new-${name}`}
          maskConfig={phoneMaskConfig}
          onPaste={handleCopy}
        />
      </FieldInput>
    </PreloadField>
  );
};
