import { VisualComponentEnum } from 'api/DemandsService/enums';
import { DynamicField, DynamicFieldData } from 'api/DemandsService/interfaces';
import { SalaryFields } from 'pages/Salary/SalaryPage';

type DynamicFieldCondition = {
  checkField: string;
  checkParam: string;
  checkType: string;
  isRequired: boolean;
  isBoolean?: boolean;
};

export interface DynamicFieldWithCondition extends DynamicField {
  condition?: DynamicFieldCondition;
}

export const toDynamicFieldsSalary = (demandFields: SalaryFields, dynamicFields: DynamicField[]) =>
  Object.entries(demandFields).reduce(
    (acc: Record<string, { data: Partial<DynamicFieldData>[] }>, [field, value]) => {
      const { field: fieldBase, values } =
        dynamicFields.find(({ field: fieldBase }) => fieldBase.code === field) || {};

      if (!fieldBase) {
        return acc;
      }

      const data: Partial<DynamicFieldData> = {
        orderFieldId: fieldBase.id,
        isFieldApproved: true,
        managerComment: null,
      };

      switch (fieldBase.visualComponent) {
        case VisualComponentEnum.SELECT: {
          const selected = values?.find(({ id, value: val }) =>
            [id?.toString(), val].includes(value?.toString()),
          );

          acc[fieldBase.code] = {
            data: [
              {
                numericValue: isNaN(Number(selected?.id)) ? null : Number(selected.id),
                textValue: selected?.label,
                fieldValue: selected?.value,
                ...data,
              },
            ],
          };
          break;
        }

        case VisualComponentEnum.FILE: {
          acc[fieldBase.code] = {
            data: value?.map((item: { field: any }) => ({
              textValue: item.field,
              ...data,
            })),
          };
          break;
        }

        case VisualComponentEnum.AMOUNT:
        case VisualComponentEnum.NUMBER: {
          acc[fieldBase.code] = {
            data: [
              {
                numericValue: Number(value),
                ...data,
              },
            ],
          };
          break;
        }

        case VisualComponentEnum.CHECKBOX: {
          acc[fieldBase.code] = {
            data: [
              {
                booleanValue: Boolean(value),
                ...data,
              },
            ],
          };
          break;
        }

        default: {
          acc[fieldBase.code] = {
            data: [
              {
                fieldValue: value as string,
                textValue: value as string,
                ...data,
              },
            ],
          };
        }
      }
      return acc;
    },
    {},
  );

export const hasAccount = (dynamicField: DynamicField) =>
  dynamicField.values?.some(item => `${item.id}` === `${dynamicField.data[0].numericValue}`);

// TODO delete
export const getNewDynamicField = (dynamicField: DynamicField, isCopyFrom?: boolean) => {
  if (hasAccount(dynamicField)) {
    return dynamicField;
  }

  const isAccountField =
    dynamicField.field?.code === 'accountTotal' || dynamicField.field?.code === 'accountFee';

  if (isAccountField && !hasAccount(dynamicField)) {
    const data = dynamicField.data[0];

    return {
      ...dynamicField,
      data: isCopyFrom ? [] : dynamicField.data,
      values: isCopyFrom
        ? dynamicField.values
        : [
            {
              id: data.numericValue,
              label: data.textValue,
            },
          ],
    };
  }

  return dynamicField;
};

export const findAccount = (
  dynamicFields: DynamicField[],
  accountId: number,
  fieldName: string,
) => {
  const isAccount = dynamicFields.find(
    item =>
      item.field?.code === fieldName && item.values.some(value => `${value.id}` === `${accountId}`),
  );
  return isAccount ? accountId : null;
};
