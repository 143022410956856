import * as React from 'react';

import { api } from 'api';
import { RegistrationSteps } from 'api/RegistrationService';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { Question } from 'components/icons';
import { translate } from 'i18n/translator';
import { RegistrationContext } from 'pages/Registration/RegistrationContext';

interface Fields {
  smsCode: string;
}

interface Payload {
  allowResendSMS: boolean;
  smsLength: number;
}

const SmsRegistrationPageForm: React.FC = () => {
  const { goBack, onSmsRequest, onSmsRegister, phoneNumber } = useSmsRegistration();

  const { setPayload, progress, error, payload, handleSubmit } = useForm<Fields, Payload>(
    async ({ setPayload }) => {
      const { smsLength } = await onSmsRequest();
      setPayload({ smsLength });
    },
  );

  const onSendSMS = async (formData: Fields) => {
    await onSmsRegister(formData.smsCode);
  };

  const onSMSRequest = async () => {
    setPayload({ allowResendSMS: false });

    const { smsLength } = await onSmsRequest();
    setPayload({ smsLength });
  };

  return (
    <div className="">
      <div className="login-page__body__box card">
        <div className="card-body">
          <div className="login-page__sms-step">
            <div className="login-page__body__box__title">
              {translate('front.registration.sms-reg-page.title.label')}
            </div>
            <div className="login-page__body__box__sms-desc">
              {translate('front.login-form.sms-description.label')}
            </div>
            <div className="login-page__body__box__sms-phone">
              <span className="login-page__body__box__sms-phone_number">{phoneNumber}</span>
              <span className="login-page__body__box__sms-phone_icon">{Question()}</span>
            </div>
            <DefaultForm className="login-page__form" disabled={progress}>
              <FormError className="login-page__form__server-error">{error}</FormError>
              <div className="login-page__form__field">
                <AmountField
                  label="front.login-form.sms.label"
                  name="smsCode"
                  length={payload.smsLength}
                  maxLength={6}
                  maskConfig={{
                    allowLeadingZeros: true,
                    prefix: '',
                  }}
                />
              </div>

              {payload.allowResendSMS ? (
                <div onClick={onSMSRequest} className="resend-sms">
                  {translate('front.login-form.resend-sms.label')}
                </div>
              ) : (
                <div className="login-page__form__timer-block">
                  <div className="text">{translate('front.login-form.timer-sms.label')}</div>
                  <div className="time" />
                </div>
              )}

              <Button
                color="primary"
                onClick={e => handleSubmit(onSendSMS, e)}
                progress={progress}
                className="login-page__form__btn save-btn"
                type="submit"
                size="lg"
              >
                {translate('front.login-form.login-button.label')}
              </Button>
              <Button
                color="secondary"
                onClick={goBack}
                disabled={progress}
                className="login-page__form__btn cancel-btn"
                size="lg"
              >
                {translate('front.login-form.cancel-button.label')}
              </Button>
            </DefaultForm>
          </div>
        </div>
      </div>
    </div>
  );
};

export const SmsRegistrationPage = withForm(SmsRegistrationPageForm);

const useSmsRegistration = () => {
  const { orderId, uuid, setStep, phoneNumber } = React.useContext(RegistrationContext);

  const onSmsRequest = async () => {
    return await api.registration.smsRequest(orderId, uuid);
  };

  const onSmsRegister = async (smsCode: string) => {
    const isValid = await api.registration.confirmSms(orderId, uuid, smsCode);

    if (isValid) {
      setStep(await api.registration.signRegistration(orderId, uuid));
    }
  };

  const goBack = async () => {
    setStep({ orderState: RegistrationSteps.InitRegistration });
  };

  return { onSmsRequest, onSmsRegister, goBack, phoneNumber };
};
