import * as React from 'react';
import { Col, Row } from 'react-grid';

import { DemandType } from 'api/DemandsService/enums';
import { Option } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { SelectField } from 'components/forms/inputs/SelectField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { CreateButton } from 'components/Table/CreateButton';
import { useFilter } from 'components/Table/DataTable';
import { ToggleFilterButton } from 'components/Table/ToggleFilterButton/ToggleFilterButton';
import { deniedPrivilegy } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';

interface EmployeesManagementFilterState {
  customerIds: number[];
  demand: DemandType;
}

const onSubmit = (data: EmployeesManagementFilterState) => {
  goto(pages.employeeManagement.tabs.details('new', { type: data.demand }));
};

const typeOptions: Option<undefined>[] = [
  {
    value: DemandType.SalaryEmployeeDismissal,
    label: 'front.demands.salary-employee-dismissal.label',
  },
  {
    value: DemandType.SalaryEmployeeNew,
    label: 'front.demands.salary-employee-new.label',
  },
  {
    value: DemandType.SalaryEmployeeReissue,
    label: 'front.demands.salary-employee-reissue.label',
  },
];

const EmployeesManagementFilterForm: React.FC = () => {
  const { handleSubmit } = useForm<EmployeesManagementFilterState>();

  useFilter({
    extraFields: {
      typeGroups: 'ManagementEmployees',
      isPaymentOrder: false,
    },
  });

  return (
    <div>
      <Row>
        <Col lg={5} md={7}>
          <SelectField
            label="front.demand-filter.select-order.label"
            name="demand"
            options={typeOptions}
            clearable
            required
          />
        </Col>
        <Col md={3} sm={8}>
          <CreateButton
            title="front.handbooks-table.create.label"
            onClick={e => handleSubmit(onSubmit, e)}
            disabled={deniedPrivilegy([Privileges.demandSalaryEdit])}
          />
        </Col>
        <Col lg={4} md={2} sm={4} hAlign="end">
          <ToggleFilterButton smallMode>
            <Row>
              <Col md={5}>
                <CustomerSelect
                  label="front.internal-payment-page.order-payer-name.label"
                  name="customerIds"
                  multi
                />
              </Col>
            </Row>
          </ToggleFilterButton>
        </Col>
      </Row>
    </div>
  );
};

export const EmployeesManagementFilter = withForm(EmployeesManagementFilterForm);
