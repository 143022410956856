import { AccountView } from 'api/ProductsService';

export const getCurrency = (row: AccountView) => row.currency;

export const createCurrencyOption = (row: AccountView) => ({
  label: row.currency,
  value: row.currency,
});

export const getType = (row: AccountView) => row.type;

export const createTypeOption = (row: AccountView) => ({
  label: row.typeTranslation,
  value: row.type,
});
