import * as React from 'react';
import { Action, SortOrder, TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { EmployeeStatus } from 'api/enums';
import { Employee, EmployeeFilter } from 'api/SalaryService';
import { Page } from 'components/layout/Page/Page';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { translate } from 'i18n/translator';

import { SalaryEmployeesFilter } from './SalaryEmployeesFilter';

interface Props {
  productIds: number[];
  resolve: (data: Employee[]) => void;
  selectedEmployees: Employee[];
}

const columns: TableColumnProps<Employee>[] = [
  {
    label: ['front.menu.employees.label', 'front.menu.iban.account.label'],
    render: row => (
      <DataColumn
        title={[row.lastName, row.firstName, row.middleName].join(' ')}
        subTitle={row.iban}
      />
    ),
    sort: ['lastName', 'iban'],
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.сounterparty-form.bin.label',
    render: row => <DataColumn title={row.taxCode} />,
    sort: 'taxCode',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

export const SalaryEmployeesModal: React.FC<Props> = request => {
  const { resolve, productIds } = request;

  const createActions = (): Action[] => [
    {
      label: 'front.employees-filter.add.label',
      action: rows => resolve(rows),
    },
  ];

  const fetchRows = React.useCallback(
    async (req: EmployeeFilter) => {
      const { rows, total } = await api.salary.getEmployees({
        ...req,
        statuses: EmployeeStatus.ACTIVE,
        productIds,
      });

      const newRows = rows.map(row => ({ ...row, amount: 0 }));

      return { rows: newRows, total };
    },
    [productIds],
  );

  return (
    <Page title={translate('front.demands.salary-employee-modal.title.label')}>
      <DataTable
        name={TableNamesEnum.RegistryModal}
        fetchRows={fetchRows}
        defaultSort="lastName"
        // @ts-ignore
        defaultOrder={SortOrder.Asc}
        columns={columns}
        actions={createActions()}
        filterComponent={SalaryEmployeesFilter}
      />
    </Page>
  );
};
