import * as React from 'react';
import { Col, Container, Row } from 'react-grid';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import { api } from 'api';
import { parseUrlParams } from 'api/backend';
import { OrderType } from 'api/DemandsService/enums';
import { CheckSmsActionEnum } from 'api/enums';
import { Order, OrderHistory } from 'api/OrderService';
import { GridResponse, Option } from 'api/Service';
import { AccessError } from 'components/Document/AccessError';
import { RenderStatusComponent } from 'components/Document/RenderStatusComponent';
import { EmbeddedEditField } from 'components/forms/embeddedEditField/EmbeddedEditField';
import { FormError } from 'components/forms/FormError';
import { FinalValue, FormState } from 'components/forms/ValidatingForm/FormContext';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { FormInitialParams } from 'components/forms/ValidatingForm/validatingForm';
import { withForm } from 'components/forms/withForm';
import { PageHeader } from 'components/layout/Page/PageHeader';
import { confirmationModal } from 'components/modals/ConfirmModal';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { SmsModal } from 'components/modals/SmsModal';
import { OrderParams } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { NEW } from 'navigations/routes';
import { TemplateModal } from 'pages/utils/ConfirmTemplate/TemplateModal';
import { selectDefaultCustomerId } from 'store/selectors';
import { isExistOrder } from 'utils/isData';

import { DocumentDetails } from './DocumentDetails';
import { OrderContext, RefetchOrderCb } from './OrderContext';
import { OrderControls } from './OrderControls';
import { TemplateCreate, TemplateSelect } from './TemplateControls';
import { generatedByInvoice, isDisabled } from './utils';

export type OrderRouteParams = { id: string; scheduleId?: string };

export type FetchOrderCbParams<Q = Obj> = {
  customerId: number;
  queryParams: OrderParams<Q>;
  routeParams: OrderRouteParams;
  isFromTemplate?: boolean;
  locationState?: any;
};

export type OrderEffects<T> = {
  setPayload: React.Dispatch<React.SetStateAction<T>>;
  setTemplateOptions: React.Dispatch<React.SetStateAction<Option[]>>;
};

export type OnSaveOptions = {
  templateName?: string;
};

export type OnSaveCreator<T, P> = (
  order: Order<T>,
  payload: P,
) => (formData: T & FormState, options?: OnSaveOptions) => Promise<number | never>;

export type FetchOrderCb<T, P = any, Q = Obj> = (
  params: FetchOrderCbParams<Q>,
  effects?: OrderEffects<P>,
) => Promise<Order<T>>;

export type GetCustomerFilteringPrivilege = (type: OrderType) => Privileges;

interface Config<T, P> {
  fetchOrder: FetchOrderCb<T, P>;
  afterSubmit?: (order: Order, options: OnSaveOptions) => void;
  allowDetails?: boolean; // determines would an orderDetails be shown
  allowExecutionDate?: boolean; // determines would an executed date be shown
  allowOperationDate?: boolean; // determines would an value date be shown
  allowSave?: boolean; // determines would user save the order
  allowSign?: boolean; // determines would user sign the order
  allowState?: boolean; // determines would an orderState be shown
  allowTemplateName?: boolean; // display template name order
  allowTemplates?: boolean; // determines would a template be shown
  allowValueDate?: boolean; // determines would a template be shown
  allowViewOrder?: boolean; // determines would Order be shown according to privileges
  createOnSave?: OnSaveCreator<T, P>;
  disableButtons?: boolean; // disable buttons sign and save if not permission
  formInitialParams?: FormInitialParams;
  getCustomerFilteringPrivilege?: GetCustomerFilteringPrivilege;
  isDisabledSave?: boolean;
  showSaveButton?: boolean; // determines would a component be waiting until the order will come
  showSignOnForm?: boolean; // determines would sign button display like separate button
  StateComponent?: React.FunctionComponent<{ order: Order }>; // render custom component for state
  waitForOrder?: boolean;
}

type ConfigFnArg = {
  getFieldValue: (path: string) => FinalValue;
  order: Order;
  queryParams: OrderParams;
  routeParams: OrderRouteParams;
  error?: string;
  locationState?: any;
};

type ConfigFn<T, P> = (arg: ConfigFnArg) => Config<T, P>;

const saveAsTemplateCreator =
  <T,>(onSave: ReturnType<OnSaveCreator<T, OnSaveOptions>>) =>
  async (formData: T & FormState): Promise<void> => {
    const templateName = (await confirmModal<string>(TemplateModal, {}, 350)) || null;
    if (!templateName) return null;

    const otpCode = await confirmModal<string>(SmsModal, {
      action: CheckSmsActionEnum.CONFIRM_TEMPLATE_CREATE,
    });
    if (!otpCode) return;

    const id = await onSave(formData, { templateName });

    if (id) {
      await confirmationModal(
        'front.internal-payment-page.confirmation-template-saved-text.label',
        true,
        false,
      );
    }
  };

export function withOrder<T = any, P = any, U = Obj>(
  config: Config<T, P> | ConfigFn<T, P>,
  formInitialParams?: Partial<FormInitialParams>,
  props?: U,
) {
  return (WrappedComponent: React.ElementType): React.FC => {
    const OrderComponent = () => {
      const [order, setOrder] = React.useState<Order>();
      const [history, setHistory] = React.useState<GridResponse<OrderHistory>>();
      const [templateOptions, setTemplateOptions] = React.useState<Option[]>();
      const [currentTemplate, setCurrentTemplate] = React.useState<string>();
      const [isDetails, setIsDetails] = React.useState(false);

      const routeParams = useParams<OrderRouteParams>();
      const { state: locationState, key } = useLocation<Location>();
      const queryParams = parseUrlParams(useLocation().search);

      const hasOrder = isExistOrder(order);

      /* create initial fetch function for useForm */
      const fetchFormData = React.useCallback(async ({ setFields }) => {
        const fetchedOrder = await fetchOrder(defaultChosenPersonId);

        if (fetchedOrder) {
          setOrder(fetchedOrder);
          setFields({
            orderNumber: fetchedOrder.number,
            orderDate: fetchedOrder.date,
          });
          await fetchTemplates(fetchedOrder.type, fetchedOrder.customerId);
        }
      }, []);

      const form = useForm<any, P>(fetchFormData);

      const {
        fetchOrder: createFetchOrder,
        createOnSave,
        allowTemplates,
        allowValueDate,
        allowOperationDate,
        allowExecutionDate,
        waitForOrder,
        allowDetails,
        allowState,
        allowSave,
        allowSign,
        showSaveButton,
        afterSubmit,
        allowTemplateName,
        disableButtons,
        isDisabledSave,
        allowViewOrder = true,
        StateComponent,
        getCustomerFilteringPrivilege = () => undefined,
        showSignOnForm,
      } = typeof config === 'function'
        ? config({
            order,
            queryParams,
            routeParams,
            locationState,
            getFieldValue: form.getFieldValue,
            error: form.error,
          })
        : config;

      const privilege = getCustomerFilteringPrivilege(queryParams.type as OrderType);
      const defaultChosenPersonId = useSelector(selectDefaultCustomerId(privilege));

      /* create fetchOrder function for further reusing */
      const fetchOrder: RefetchOrderCb<T> = React.useCallback(
        (customerId, opts) =>
          createFetchOrder(
            {
              customerId,
              locationState,
              routeParams: opts?.routeParams || routeParams,
              queryParams: opts?.queryParams || queryParams,
              isFromTemplate: opts?.isFromTemplate,
            },
            // @ts-ignore
            { setTemplateOptions, setPayload: form.setPayload },
          ),
        [key],
      );

      // disabled form if not privilege EDIT,
      // or order is not draft or has been generated and cannot be edited
      React.useEffect(() => {
        // at first checks the EDIT privilege
        if (typeof allowSave !== 'undefined' && !allowSave && order.state) {
          return form.setDisabled(true);
        }

        const disableForm = order?.id && (isDisabled(order) || generatedByInvoice(order));
        disableForm && form.setDisabled(true);
      }, [allowSave, order, queryParams]);

      /* set new fieldData if order was changed */
      React.useEffect(() => {
        if (hasOrder) {
          form.resetData();
          form.updateData({
            orderNumber: order.number,
            orderDate: order.date,
            valueDate: order.valueDate || null,
            ...order.detailFields,
          });
        }
      }, [order]);

      const [copied, setCopied] = React.useState(false);

      const refetchOrderOnCopy = async () => {
        form.setInitializing(true);
        form.setDisabled(true);
        form.clearData(true);
        resetTemplate();
        const copiedOrder = await fetchOrder(order?.customerId, {
          queryParams,
        });
        setOrder(copiedOrder);
        setCopied(true);
        await fetchTemplates(copiedOrder.type, copiedOrder.customerId);
        form.setDisabled(false);
        form.setInitializing(false);
      };

      React.useEffect(() => {
        queryParams?.copyFrom && order && !copied && refetchOrderOnCopy();
      }, [queryParams?.copyFrom]);

      const resetTemplate = () => setCurrentTemplate('');

      const onChangeTemplate = async (orderId: string) => {
        form.setProgress(true);
        form.setInitializing(true);
        form.setDisabled(true);
        form.clearData(true);

        const fetchedOrder = await fetchOrder(order.customerId, {
          isFromTemplate: true,
          routeParams: { id: orderId || NEW },
        });

        if (fetchedOrder) {
          setCurrentTemplate(orderId);
          const { state, id, date, number, label, ...restOrder } = fetchedOrder;
          setOrder(prevState => ({ ...prevState, ...restOrder }));
        }

        form.setDisabled(false);
        form.setInitializing(false);
        form.setProgress(false);
      };

      const fetchTemplates = async (orderType: string, customerId: number) => {
        if (!allowTemplates) return;

        const templates = await api.order.getOrderTemplates(orderType, [customerId]);
        setTemplateOptions(templates);
      };

      const onSave = createOnSave && allowSave ? createOnSave(order, form.payload) : null;

      const renderRight = () => {
        if (order?.state && allowState) {
          return <RenderStatusComponent<U> order={order} StatusComponent={StateComponent} />;
        }
      };

      if (!hasOrder && form.error) {
        return <FormError>{form.error}</FormError>;
      }

      if (waitForOrder && !hasOrder) {
        return null; // TODO: Add placeholder
      }

      if (hasOrder && !allowViewOrder) {
        return <AccessError />;
      }

      return (
        <OrderContext.Provider
          value={{
            order,
            history,
            setHistory,
            setTemplateOptions,
            setIsDetails,
            setOrder,
            fetchOrder,
            resetTemplate,
            fetchTemplates,
            getCustomerFilteringPrivilege,
            setPayload: form.setPayload,
            payload: form.payload,
          }}
        >
          <PageHeader>
            <PageHeader.Title>{order?.label}</PageHeader.Title>
            <PageHeader.Right>{renderRight()}</PageHeader.Right>
          </PageHeader>
          {!order?.state && allowTemplates && (
            <TemplateSelect
              onChange={onChangeTemplate}
              value={currentTemplate}
              options={templateOptions}
              disabled={form.progress || form.initializing}
            />
          )}
          {allowTemplateName && order?.detailFields?.orderAlias && (
            <EmbeddedEditField
              label="front.internal-payment-page.order-template-name.label"
              name="orderAlias"
              maxLength={80}
              required
            />
          )}
          {allowDetails && (
            <DocumentDetails
              order={order}
              allowValueDate={allowValueDate}
              allowOperationDate={allowOperationDate}
              allowExecutionDate={allowExecutionDate}
            />
          )}
          <FormError>{form.error}</FormError>
          <Container>
            <WrappedComponent {...props} />
            {isDetails && (
              <Row>
                <Col md={8}>
                  <OrderControls
                    onSave={onSave}
                    order={order}
                    handleSubmit={form.handleSubmit}
                    progress={form.progress}
                    afterSubmit={afterSubmit}
                    allowSave={allowSave}
                    showSaveButton={showSaveButton}
                    allowSign={allowSign}
                    disableButtons={disableButtons}
                    isDisabledSave={isDisabledSave}
                    showSignOnForm={showSignOnForm}
                  />
                </Col>
                {allowTemplates && (
                  <Col md={4} hAlign="end">
                    <TemplateCreate
                      onClick={e => form.handleSubmit(saveAsTemplateCreator<T>(onSave), e)}
                      disabled={!allowSave}
                    />
                  </Col>
                )}
              </Row>
            )}
          </Container>
        </OrderContext.Provider>
      );
    };

    // @ts-ignore
    return withForm<U>(OrderComponent, {
      preserveReset: ['customer'],
      ...formInitialParams,
    });
  };
}
