import React from 'react';
import ReactModal from 'react-modal';

import * as Icon from 'components/icons';
import { goBack } from 'navigations/navigate';
import './styles.scss';

interface Props {
  hideCloseButton?: boolean;
  onClose?: (e?: boolean) => void;
  styles?: React.CSSProperties;
  width?: number;
}

export class Modal extends React.PureComponent<Props, any> {
  static defaultProps = {
    width: 500,
    styles: {},
    hideCloseButton: false,
    onClose: () => {
      goBack();
    },
  };

  componentWillUnmount() {
    const root = document.getElementsByTagName('html')[0];
    root.classList.remove('ReactModal__html--open');

    document.removeEventListener('keydown', this.handleOnEsc, false);
  }

  onAfterOpen = () => {
    const root = document.getElementsByTagName('html')[0];
    root.classList.add('ReactModal__html--open');
  };

  handleOnEsc = (e: { keyCode: number }) => {
    if (e.keyCode === 27) {
      this.props.onClose(false);
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleOnEsc, false);
  }

  render() {
    const { children, width, onClose, styles, hideCloseButton } = this.props;

    return (
      <ReactModal
        isOpen
        contentLabel="onRequestClose Example"
        className="rmodal__content"
        overlayClassName="rmodal__overlay"
        portalClassName="rmodal"
        style={{
          content: {
            maxWidth: width,
            ...styles,
          },
        }}
        ariaHideApp={false}
        shouldFocusAfterRender={false}
        onAfterOpen={this.onAfterOpen}
      >
        {!hideCloseButton && (
          <>
            <div className="rmodal__close" onClick={() => onClose(false)}>
              <Icon.CloseModal />
            </div>
            <div className="rmodal__close mobile" onClick={() => onClose(false)}>
              <Icon.CloseMobileModal />
            </div>
          </>
        )}
        <div>{children}</div>
      </ReactModal>
    );
  }
}
