import React from 'react';

import { TextField } from 'components/forms/inputs/TextField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { translate } from 'i18n/translator';
import { DocumentManagementDetailsNames } from 'pages/DocumentManagement/enums';
import { DocumentManagementDetailsFields } from 'pages/DocumentManagement/interfaces';

import { SignatoryRow } from './SignatoryRow/SignatoryRow';
import { getSignFlag } from './utils';

export const OrganizationComponent = () => {
  const { getFormData } = useForm<DocumentManagementDetailsFields>();
  const formData = getFormData();

  if (!formData[DocumentManagementDetailsNames.Customers]) {
    return;
  }

  return (
    <>
      {formData[DocumentManagementDetailsNames.Customers].map(customer => (
        <div key={customer.customerId}>
          <h3>{translate('front.cards-page.filter-customers.label')}</h3>

          <TextField
            name={customer.customerName}
            label="front.cert-page-new.input-customer-id.label"
            defaultValue={customer.customerName}
          />

          <h3>{translate('front.document-management.signatories_organization.label')}</h3>
          {customer.extraData.persons?.map(
            ({ id, signed, rejected, firstName, lastName, middleName }) => (
              <SignatoryRow
                key={id}
                signFlag={getSignFlag(signed, rejected)}
                name={`${lastName} ${firstName} ${middleName ? middleName : ''}`}
              />
            ),
          )}
        </div>
      ))}
    </>
  );
};
