import React from 'react';

import classNames from 'classnames';

import { SortOrder } from 'api/Service';
import { DefaultSortIcon, SortDown, SortUp } from 'components/icons';
import { translate } from 'i18n/translator';
import { isExist } from 'utils/isData';

import './styles.scss';

interface Props {
  colLabel: string;
  colSort: string;
  hasDefaultSort: boolean;
  order: string;

  setSort(sort: string, order: SortOrder): void;

  sort: string;
}

export const AutocompleteSortColumn = ({
  colLabel,
  colSort,
  sort,
  order,
  setSort,
  hasDefaultSort,
}: Props) => {
  const hasColSort = isExist(colSort);
  const hasSelectedSort = JSON.stringify(colSort) === JSON.stringify(sort);
  const shouldRenderDefaultIcon = !hasSelectedSort && hasColSort;

  const updateSort = (e: any) => {
    e.preventDefault();
    if (!hasColSort) {
      return;
    }
    if (hasColSort) {
      setSort(colSort, hasSelectedSort && order === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc);
    }
  };

  const Icon = React.useMemo(() => {
    if (shouldRenderDefaultIcon && !hasDefaultSort) {
      return <DefaultSortIcon />;
    }

    if (hasDefaultSort || hasColSort) {
      return order === SortOrder.Asc ? <SortUp /> : <SortDown />;
    }
  }, [order, colSort, sort, hasDefaultSort]);

  return (
    <div
      className={classNames({
        'sort-column': colSort,
        isActive: hasDefaultSort || hasSelectedSort,
      })}
      onClick={updateSort}
    >
      <span
        className={classNames({
          isActive: hasDefaultSort || hasSelectedSort,
        })}
      >
        {translate(colLabel)}
      </span>
      <div className="sort-column__icon">{Icon}</div>
    </div>
  );
};
