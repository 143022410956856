import moment from 'moment';

import { translate } from 'i18n/translator';

export const maxDate = moment().add(1, 'years').toDate();
export const minDate = moment().add(-1, 'years').toDate();

const NAME_REGULAR = /^[а-яА-ЯіїєёґІЇЁЄҐ]+([-`' ][а-яА-ЯіїєёґІЇЁЄҐ]+){0,}?$/;
const NAME_EN_REGULAR = /^[a-zA-Z]+([-`' ][a-zA-Z]+){0,}?$/;
const ONLY_NUMBER = /^[0-9]*$/;
const HOUSE_REGULAR = /^[0-9a-zA-Zа-яА-ЯіїєёґІЇЁЄҐ\\/-]*$/;
const JOB_REGULAR = /^[a-zA-Zа-яА-ЯіїєёґІЇЁЄҐ0-9]+([-`' ][a-zA-Zа-яА-ЯіїєёґІЇЁЄҐ0-9]+){0,}?$/;
export const INN_ALLOWED_CHARACTERS = '^[0-9]{0,12}$';
export const POST_CODE_ALLOWED_CHARACTERS = '^[0-9]{0,10}$';

export const validateOnlyNumber = (value: string) => {
  if (!ONLY_NUMBER.test(value)) {
    return translate('front.employee-new.error-contains-incorrect-characters.label');
  }
};

export const validateName = (value: string) => {
  if (!NAME_REGULAR.test(value)) {
    return translate('front.employee-new.name-error-text.label');
  }
};

export const validateEnName = (value: string) => {
  if (!NAME_EN_REGULAR.test(value)) {
    return translate('front.employee-new.en-name-error-text.label');
  }
};

export const validateJob = (value: string) => {
  if (!JOB_REGULAR.test(value)) {
    return translate('front.employee-new.error-contains-incorrect-characters.label');
  }
};

export const validateHouse = (value: string) => {
  if (!HOUSE_REGULAR.test(value)) {
    return translate('front.employee-new.error-contains-incorrect-characters.label');
  }
};
