import React from 'react';
import { Col, Row } from 'react-grid';

import { api } from 'api';
import { withForm } from 'components/forms/withForm';
import { SearchInput } from 'components/searchInput/SearchInput';
import { useFilter } from 'components/Table/DataTable';
import { translate } from 'i18n/translator';

import { DocumentsManagementFilterEnum, StateTab } from './enums';
import { ExtraFields, FilterFields, FilterPayload } from './interfaces';
import { documentManagementTabs } from './options';
import { getCategoryAndTypeOptions, getStatesOptions } from './utils';
// TODO uncomment when bank payed
// const EXPORT_TYPES_DOCUMENTS_MANAGEMENT = {
//   XLS: ExportFormatEnum.EXCEL,
// };

// const isEmptyField = (value: unknown): boolean => value === null;

const DocumentsManagementsFilterForm = () => {
  const fetchData = async () => {
    const categories = await api.documentManagement.getCategories();
    const [categoryOptions, typeOptions] = getCategoryAndTypeOptions(categories);
    const [stateBankOptions, stateCustomerOptions] = getStatesOptions(StateTab.Working);

    setPayload({ categoryOptions, typeOptions, stateBankOptions, stateCustomerOptions });
  };

  const {
    updateFilter,
    extraFields,
    setPayload,
    // TODO uncomment when bank payed
    // payload,
    // progress,
    // getFormData,
    // updateFieldInData,
    // setPagination,
  } = useFilter<FilterFields, ExtraFields, FilterPayload>({
    extraFields: {
      [DocumentsManagementFilterEnum.SearchText]: '',
      [DocumentsManagementFilterEnum.StateTab]: StateTab.Working,
      [DocumentsManagementFilterEnum.OrderStates]:
        documentManagementTabs[StateTab.Working][DocumentsManagementFilterEnum.OrderStates],
      [DocumentsManagementFilterEnum.ExcludedStates]:
        documentManagementTabs[StateTab.Working][DocumentsManagementFilterEnum.ExcludedStates],
    },
    fetchInitial: fetchData,
  });
  // TODO delete
  React.useEffect(() => updateFilter({}), []);
  // TODO uncomment when bank payed
  // const formData = getFormData();

  // const { defaultForm, onApplyChangeParams } = useStorageFilter<
  //   Exclude<
  //     FilterFields,
  //     DocumentsManagementFilterEnum.StateBank | DocumentsManagementFilterEnum.StateCustomer
  //   >
  // >(DOCUMENTS_MANAGEMENT, 'filter', formData);

  // const { defaultStateBank, defaultStateCustomer, onApplyChangeStates, getDefaultValuesByTab } =
  //   useStoreStatesByTab(DOCUMENTS_MANAGEMENT, extraFields.stateTab, {
  //     stateBank: formData.stateBank,
  //     stateCustomer: formData.stateCustomer,
  //   });

  // const handleChangeTab = useChangingTab(getDefaultValuesByTab, updateFilter);
  // const defaultSelectedCustomerIds = useSelector(selectChosenCustomersId()).map(id => `${id}`);
  // const isMultiCustomer = defaultSelectedCustomerIds.length > 1;

  // const handleClearFilter = () => {
  //   const newForm: FilterFields = {} as FilterFields;
  //
  //   Object.keys(formData).forEach(key => {
  //     if (key === DocumentsManagementFilterEnum.CustomerIds && !isMultiCustomer) {
  //       return;
  //     }
  //
  //     updateFieldInData(key, 'defaultValue', null);
  //     newForm[key as keyof FilterFields] = null;
  //   });
  //
  //   const { stateBank, stateCustomer, ...filter } = newForm;
  //   onApplyChangeParams(filter);
  //   onApplyChangeStates({ stateBank, stateCustomer });
  //   updateFilter(filter);
  // };

  // const handleApplyFilter = () => {
  //   const { stateBank, stateCustomer, ...filter } = formData;
  //   onApplyChangeParams(filter);
  //   onApplyChangeStates({ stateBank, stateCustomer });
  //   updateFilter(formData);
  // };
  //
  // const [defaultDateFrom, defaultDateTo] = getCurrentPeriod(DatePeriodEnum.CURRENT_WEEK);
  // useUpdateSignatories(formData.customerIds, setPayload);
  //
  // const exportActions = React.useMemo(() => {
  //   const preparedFilter = prepareFilterForFetch(extraFields);
  //
  //   const exportOrderAction = (exportType: string) => () =>
  //     api.payments.exportOrder({
  //       ...preparedFilter,
  //       orderType: DemandType.DocumentManagement,
  //       exportType,
  //     });
  //
  //   return getExportActions({
  //     action: exportOrderAction,
  //     exportTypes: EXPORT_TYPES_DOCUMENTS_MANAGEMENT,
  //   });
  // }, [extraFields]);

  return (
    <div>
      {/*// TODO uncomment when bank payed*/}
      {/*<PageHeader.Right>{<ExportDropdown actions={exportActions} />}</PageHeader.Right>*/}
      <Row>
        {/*<Col lg={7} md={12}>*/}
        {/*  <FilterTabBar*/}
        {/*    onChange={stateTab => handleChangeTab(stateTab as unknown as StateTab)}*/}
        {/*    value={extraFields.stateTab}*/}
        {/*    options={DocumentManagementOptionStatuses}*/}
        {/*    setPagination={setPagination}*/}
        {/*  />*/}
        {/*</Col>*/}
        <Col lg={3} md={8}>
          <SearchInput
            placeholder={translate('front.working-documents-table.filter-search.label')}
            value={extraFields[DocumentsManagementFilterEnum.SearchText]}
            onChange={searchText => updateFilter({ searchText })}
          />
        </Col>
        {/*// TODO uncomment when bank payed*/}
        {/*<Col lg={2} md={4}>*/}
        {/*  <ToggleFilterButton*/}
        {/*    smallMode*/}
        {/*    onApplyFilter={handleApplyFilter}*/}
        {/*    onResetFilter={handleClearFilter}*/}
        {/*    fetchOnApply={false}*/}
        {/*    disabled={progress}*/}
        {/*  >*/}
        {/*    <Row>*/}
        {/*      <Col md={12}>*/}
        {/*        <CustomerSelect*/}
        {/*          label="front.internal-payment-page.order-payer-name.label"*/}
        {/*          name={DocumentsManagementFilterEnum.CustomerIds}*/}
        {/*          defaultValue={defaultForm?.[DocumentsManagementFilterEnum.CustomerIds]}*/}
        {/*          multi*/}
        {/*        />*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*    <Row>*/}
        {/*      <Col md={6}>*/}
        {/*        <TextField*/}
        {/*          label="front.working-documents-filter-form.number-document.label"*/}
        {/*          name={DocumentsManagementFilterEnum.OrderNumber}*/}
        {/*          defaultValue={defaultForm?.[DocumentsManagementFilterEnum.OrderNumber]}*/}
        {/*        />*/}
        {/*      </Col>*/}
        {/*      <Col md={6}>*/}
        {/*        <DateRangeField*/}
        {/*          label="front.working-documents-table.date-range.label"*/}
        {/*          nameFrom={DocumentsManagementFilterEnum.DateFrom}*/}
        {/*          nameTo={DocumentsManagementFilterEnum.DateTo}*/}
        {/*          defaultFrom={*/}
        {/*            isEmptyField(defaultForm?.[DocumentsManagementFilterEnum.DateFrom])*/}
        {/*              ? defaultForm?.[DocumentsManagementFilterEnum.DateFrom]*/}
        {/*              : defaultDateFrom*/}
        {/*          }*/}
        {/*          defaultTo={*/}
        {/*            isEmptyField(defaultForm?.[DocumentsManagementFilterEnum.DateTo])*/}
        {/*              ? defaultForm?.[DocumentsManagementFilterEnum.DateTo]*/}
        {/*              : defaultDateTo*/}
        {/*          }*/}
        {/*          clearable*/}
        {/*        />*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*    <Row>*/}
        {/*      <Col md={12}>*/}
        {/*        <SelectField*/}
        {/*          label="front.demands-page.filter-type.label"*/}
        {/*          name={DocumentsManagementFilterEnum.TypeIds}*/}
        {/*          defaultValue={defaultForm?.[DocumentsManagementFilterEnum.TypeIds]}*/}
        {/*          options={payload?.typeOptions}*/}
        {/*          multi*/}
        {/*        />*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*    <Row>*/}
        {/*      <Col md={6}>*/}
        {/*        <SelectField*/}
        {/*          label={`${translate('front.menu.work-protocol.status.label')}: ${translate(*/}
        {/*            'front.employees-filter.bank.label',*/}
        {/*          )}`}*/}
        {/*          name={DocumentsManagementFilterEnum.StateBank}*/}
        {/*          defaultValue={defaultStateBank}*/}
        {/*          options={payload.stateBankOptions}*/}
        {/*          multi*/}
        {/*        />*/}
        {/*      </Col>*/}
        {/*      <Col md={6}>*/}
        {/*        <SelectField*/}
        {/*          label={`${translate('front.menu.work-protocol.status.label')}: ${translate(*/}
        {/*            'front.demands-table.organization.label',*/}
        {/*          )}`}*/}
        {/*          name={DocumentsManagementFilterEnum.StateCustomer}*/}
        {/*          defaultValue={defaultStateCustomer}*/}
        {/*          options={payload.stateCustomerOptions}*/}
        {/*          multi*/}
        {/*        />*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*    <Row>*/}
        {/*      <Col md={12}>*/}
        {/*        <SelectField*/}
        {/*          label="front.document-management.signatory.label"*/}
        {/*          name={DocumentsManagementFilterEnum.SignatoryBank}*/}
        {/*          defaultValue={defaultForm?.[DocumentsManagementFilterEnum.SignatoryBank]}*/}
        {/*          options={payload?.signatoryOptions}*/}
        {/*          multi*/}
        {/*        />*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*  </ToggleFilterButton>*/}
        {/*</Col>*/}
      </Row>
    </div>
  );
};

export const DocumentsManagementFilter = withForm(DocumentsManagementsFilterForm);
