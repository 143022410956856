export enum CertificateFieldNames {
  Mode = 'mode',
  CustomerId = 'customerId',
  Store = 'store',
  EdsKey = 'edsKey',
  Password = 'password',
  PukCode = 'puk',
}

export enum KeyKepType {
  CLOUD_STORAGE = 'cloud_storage',
}

export enum StoreTypeID {
  CLOUD_STORAGE = 0,
  TOKEN = 1,
}
