import { checkMultiCustomer } from 'api/ConstantService';
import { ExportWays } from 'components/ExportingDocuments/types';
import { TableSortType } from 'components/react-data-table/types';
import { Fields } from 'pages/Analytics/AnalyticsFilter';

import { download, downloadFile, downloadRequisites, get, post } from './backend';
import { Account } from './interfaces';
import { GridRequest, GridResponse } from './Service';

export type GraphPoint = {
  credit: number;
  date: Date;
  debet: number;
  value: number;
  creditFut?: number;
  debetFut?: number;
  valueFut?: number;
};

export interface Statement {
  accountId: number;
  accountNumber: string;
  closingBalance: number;
  closingBalanceNational: number;
  credit: number;
  creditNational: number;
  currency: string;
  date: string;
  debet: number;
  debetNational: number;
  id: number;
  openingBalance: number;
  openingBalanceNational: number;
  ownerName: string;
  iban?: string;
  time?: string;
}

export interface StatementsFilter extends GridRequest {
  accountIds?: Array<string>;
  dateFrom?: Date;
  dateTo?: Date;
  withSync?: boolean;
}

export interface ExportStatementRequest {
  ids: Array<string | number>;
  accountIds?: Array<string | number>;
  customerIds?: string[];
  dateFrom?: string;
  dateTo?: string;
  encoding?: string;
  exportType?: string;
  format?: string;
  formatType?: string;
  layout?: ExportWays;
  objectCode?: string;
  order?: string;
  recipientEmails?: string;
  showZeroBalances?: boolean;
  sort?: TableSortType;
  time?: string;
  translateHeaders?: boolean;
  withRevaluation?: boolean;
  withSync?: boolean;
}

export type GraphDataFilters = Fields;

export interface SendRequisites {
  accountIds: Array<string>;
  to: string;
}

export interface PreparedDataToMultiExport {
  accountIds: number[];
  accountIdsWithDate: string[];
}

export class AccountService {
  async getStatements(filter: StatementsFilter): Promise<GridResponse<Statement>> {
    return await get('/v1/account/balance-history', checkMultiCustomer(filter));
  }

  async getAccountsForPayment(): Promise<Account[]> {
    return await get('/account/get-accounts-for-payment', checkMultiCustomer({}));
  }

  async isExistingAccountOurBank(iban: string, currency: string): Promise<boolean> {
    const response = await get(`/v1/accounts/exists/${iban}`, { currency });
    return response.value;
  }

  async exportStatements(data: ExportStatementRequest): Promise<void> {
    return await downloadFile('/v1/account-balance-history/export', data);
  }

  async exportMt940Statement(data: ExportStatementRequest): Promise<void> {
    const resp = await get('/v1/export', {
      ...data,
      withSync: false,
      objectCode: 'ACCOUNT_BALANCE_HISTORY',
    });
    const files = resp
      .toString()
      .split('\n\n')
      .map((lines: string | string[]) => {
        const eon = lines.indexOf('\n');
        return { name: lines.slice(0, eon), data: lines.slice(eon + 1) };
      });

    for (const file of files) {
      await download(new Blob([file.data]), file.name, true);
    }
    return Promise.resolve();
  }

  async exportStatementsByAnalytic(data: ExportStatementRequest): Promise<void> {
    return await downloadRequisites('/v1/export', data);
  }

  async getPreparedExportData(data: ExportStatementRequest): Promise<PreparedDataToMultiExport> {
    const { idAndDate, uniqueIdAccounts } = await get(
      '/v1/ugb/accounts/transactions/export-information',
      data,
    );
    return { accountIdsWithDate: idAndDate, accountIds: uniqueIdAccounts };
  }

  async sendStatements(data: ExportStatementRequest): Promise<void> {
    return await post('/v1/account-balance-history/export', data);
  }

  async getCashFlowData(request: GraphDataFilters): Promise<Array<GraphPoint>> {
    return await get('/v1/account/graph/', checkMultiCustomer(request));
  }

  async sendRequisties(request: SendRequisites): Promise<void> {
    return await get('/account/send-requisites/email', request);
  }
}
