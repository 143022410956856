import { useDispatch, useSelector } from 'react-redux';

import { api } from 'api';
import { NotificationInterface } from 'api/UserService';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { NotificationsActions } from 'store/actions/notifications';
import { selectNotifications } from 'store/selectors';

export const useNotificationsSelector = () => {
  const dispatch = useDispatch();
  const notifications: NotificationInterface[] = useSelector(selectNotifications());

  const setNotifications = (n: NotificationInterface[]) =>
    dispatch(NotificationsActions.setNotifications(n));

  const getNotificationsSize = () => {
    return notifications?.length || 0;
  };

  const readAllNotifications = async () => {
    await api.user.readNotifications(notifications);
    setNotifications([]);
  };

  const readNotification = async (notification: NotificationInterface) => {
    await api.user.readNotifications([notification]);
    setNotifications(await api.user.getNotifications());
  };

  const gotoNotification = async (notification: NotificationInterface) => {
    await readNotification(notification);
    if (notification.notificationType === 'LETTER') {
      goto(pages.message(notification.id, 'view'));
    }
  };

  return {
    notifications,
    setNotifications,
    getNotificationsSize,
    readAllNotifications,
    readNotification,
    gotoNotification,
  };
};
