import { createSelector } from 'reselect';

import { BooleanBuilder, Option } from 'api/Service';
import { Customer } from 'api/UserService';
import { ModalEnum } from 'components/modals/globalModalCollection/modalEnum';
import { Privileges } from 'navigations/privileges';
import { ExpandedCustomer } from 'pages/Login/CustomerSelectModal/types';
import store, { AppStore } from 'store';
import { ConstantsState } from 'store/reducers/constant';
import { LocaleState } from 'store/reducers/locale';
import { UserState } from 'store/reducers/user';

export const selectLocaleState =
  () =>
  (state: AppStore): LocaleState =>
    state.localeState;

export const selectUser =
  () =>
  (state: AppStore): UserState =>
    state.userState;

export const selectNotifications =
  () =>
  (state: AppStore): any[] =>
    state.notifications.notifications;

export const selectCustomers =
  () =>
  (state: AppStore): Array<ExpandedCustomer> =>
    state.userState.customers;

const filterForSelected = ({ isSelected }: ExpandedCustomer) => isSelected;

const filterForPrivilegy = (person: ExpandedCustomer, privilege: Privileges) =>
  privilege ? person.privileges.includes(privilege) : true;

export const selectChosenCustomers = () => (state: AppStore) =>
  state.userState.customers.filter(filterForSelected);

const selectChosenCustomersByPriv = (privilege: Privileges) =>
  createSelector(selectChosenCustomers(), chosenPersons =>
    chosenPersons.filter(person => filterForPrivilegy(person, privilege)),
  );

export const selectChosenCustomersId =
  () =>
  (state: AppStore): Array<number> =>
    state.userState.customers.filter(({ isSelected }) => isSelected).map(({ id }) => id);

export const selectCustomer =
  (id: number) =>
  (state: AppStore): Customer =>
    state.userState.customers.find(customer => `${customer.id}` === `${id}`);

const personToOptions = (person: Customer): Option<ExpandedCustomer> => ({
  label: person.name,
  value: `${person.id}`,
  content: person,
});

export const selectPersonOptions =
  () =>
  (state: AppStore): Option<ExpandedCustomer>[] =>
    state.userState.customers.map(personToOptions);

export const selectChosenPersonOptions =
  (privilege?: Privileges) =>
  (state: AppStore): Option<ExpandedCustomer>[] =>
    createSelector(selectChosenCustomersByPriv(privilege), chosenPersons =>
      chosenPersons.map(personToOptions),
    )(state);

const findDefaultCustomer = (p: ExpandedCustomer, privilege: Privileges) => {
  const b = new BooleanBuilder(true);
  b.and(p.default);
  b.and(p.isSelected);
  privilege && b.and(p.privileges.includes(privilege));

  return b.apply();
};

export const selectDefaultCustomerId =
  (privilege?: Privileges) =>
  (state: AppStore): number =>
    state.userState.customers.find(p => findDefaultCustomer(p, privilege))?.id ??
    selectChosenCustomersByPriv(privilege)(state)[0]?.id;

export const selectConstants =
  () =>
  (state: AppStore): ConstantsState =>
    state.constantState;

// const parseConstantType = (constant: Constant, value: string) => {
//   const type = TypesConstant[constant];
//   switch (type) {
//     case ETypes.boolean:
//       return value === 'true';
//     case ETypes.number:
//       return +value;
//     case ETypes.string:
//       return value;
//     default:
//       return value;
//   }
// };
//
// export const selectConstant = (constant: Constant) => (state: AppStore) =>
//     parseConstantType(constant, state.constantState[constant]);

// tslint:disable-next-line: max-line-length
export const selectIsMulticustomer =
  () =>
  (state: AppStore): boolean =>
    state.constantState.IS_MULTI_CUSTOMER === 'true';

export const selectServerError =
  () =>
  (state: AppStore): boolean =>
    Boolean(state.serverError);

export const selectIsServerTimeInit =
  () =>
  (state: AppStore): boolean =>
    state.applicationInit.isServerTimeInit;

export const getDefaultCustomerId = (privilege?: Privileges) =>
  selectDefaultCustomerId(privilege)(store.getState());

export const selectCollectionModals = () => (store: AppStore) => store.modalCollectionState;

export const selectCollectionModal = (id: ModalEnum) => (store: AppStore) =>
  store.modalCollectionState.find(value => value.modal.configuration.id === id);
