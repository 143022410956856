import * as React from 'react';

import { Page } from 'components/layout/Page/Page';
import { NavTabs, NavTab } from 'components/tabs/NavTabs';
import { translate } from 'i18n/translator';
import { pages } from 'navigations/pages';

import { CounterpartiesPage } from './Counterparties/CounterpartiesPage';
import { CurrencyHistoryPage } from './CurrencyHistory/CurrencyHistoryPage';
import { OrderTemplatesPage } from './OrderTemplates/OrderTemplatesPage';
import { PaymentPurposesPage } from './PaymentPurpose/PaymentPurposesPage';
import { UkraineBanksPage } from './UkraineBanks/UkraineBanksPage';

import './style.scss';

export const HandbooksPage: React.FC = () => {
  return (
    <Page title={translate('front.menu.references.label')}>
      <NavTabs className="tabs-padding">
        <NavTab
          title={translate('front.сounterparties-table.label')}
          path={pages.handbooks.tabs.counterparties}
        >
          <CounterpartiesPage />
        </NavTab>
        <NavTab
          title={translate('front.payment-purpose-table.label')}
          path={pages.handbooks.tabs.paymentPurposes}
        >
          <PaymentPurposesPage />
        </NavTab>
        <NavTab
          path={pages.handbooks.tabs.orderTemplates}
          title={translate('front.document-templates-table.label')}
        >
          <OrderTemplatesPage />
        </NavTab>
        <NavTab
          title={translate('front.ukraine-banks-table.label')}
          path={pages.handbooks.tabs.ukraineBanks}
        >
          <UkraineBanksPage />
        </NavTab>
        <NavTab
          title={translate('front.currency-history-switch.name.label')}
          path={pages.handbooks.tabs.currencyHistory}
        >
          <CurrencyHistoryPage />
        </NavTab>
      </NavTabs>
    </Page>
  );
};
