import { UserKeystoreType } from 'api/CertificateService/enums';
import { Certificate } from 'api/CertificateService/interfaces';
import { Option } from 'api/Service';
import { translate } from 'i18n/translator';

export const getStoreOptions = (
  filteredCertificates: Certificate[],
  tokenOption: Option[],
): Option<Certificate>[] =>
  filteredCertificates.reduce((acc, cert) => {
    const token = tokenOption.find(item => item.content.serial === cert.userKeystoreName);
    const hasCloudCert = cert.userKeystoreType === UserKeystoreType.CloudStorage;

    const equalTokenOption = acc.some(item => item.content.serial === cert.userKeystoreName);
    const equalCloudOption = acc.some(
      item => item.content.userKeystoreType === UserKeystoreType.CloudStorage,
    );

    if (token && !equalTokenOption) {
      return [{ label: token.label, value: token.content.serial, content: token.content }, ...acc];
    }

    if (hasCloudCert && !equalCloudOption) {
      return [
        ...acc,
        {
          value: UserKeystoreType.CloudStorage,
          label: translate('front.certificate-confirmation.cloud-storage.label'),
          content: cert,
        },
      ];
    }

    return acc;
  }, []);

export const getEdsKeyOptions = (
  certificates: Certificate[],
  storeValue: string,
): Option<Certificate>[] =>
  certificates.reduce((acc, cert) => {
    const hasCloudCert = cert.userKeystoreType === UserKeystoreType.CloudStorage;

    if (hasCloudCert && storeValue === UserKeystoreType.CloudStorage) {
      return [
        ...acc,
        {
          label: getCloudLabel(cert.isUserCert),
          value: cert.userKeystoreName,
          content: cert,
        },
      ];
    }

    if (cert.userKeystoreName === storeValue) {
      return [
        {
          label: `${cert.certificateSN} - ${cert.userFullName} ${
            cert.customerName ? `- ${cert.customerName}` : ''
          }`,
          value: cert.userKeystoreName,
          content: cert,
        },
        ...acc,
      ];
    }

    return acc;
  }, []);

const getCloudLabel = (isUserCert: boolean) =>
  isUserCert
    ? translate('front.certificate-confirmation.cloud-key-multiKey.label')
    : translate('front.certificate-confirmation.cloud-key.label');
