import * as React from 'react';

import './buttons.scss';

interface Props {
  onClick: () => void;
  disabled?: boolean;
}

export const LinkButton: React.FC<React.PropsWithChildren<Props>> & {
  Icon: React.ReactNode;
} = props => {
  const { children, onClick, disabled } = props;
  return (
    <button className="link-button" onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

const Icon = ({ children }: React.PropsWithChildren<any>) => (
  <div className="link-button__icon">{children}</div>
);

LinkButton.Icon = Icon;
