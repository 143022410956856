import { useContext } from 'react';

import { OrderType } from 'api/DemandsService/enums';
import { Order } from 'api/OrderService';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { getDefaultCustomerId } from 'store/selectors';

import { OrderContext, OrderContextValue } from './OrderContext';

export const useOrder = <T, P = any>() => {
  const form = useForm<T>();
  const {
    order,
    fetchOrder,
    setPayload,
    history,
    payload,
    setTemplateOptions,
    setOrder,
    resetTemplate,
    fetchTemplates,
    getCustomerFilteringPrivilege,
  } = useContext<OrderContextValue<T, P>>(OrderContext);

  const onChangeNewOrderType = async (type: OrderType) => {
    form.setInitializing(true);
    resetTemplate();
    const privilege = getCustomerFilteringPrivilege(type);
    const defaultChosenPersonId = getDefaultCustomerId(privilege);
    const order = await fetchOrder(defaultChosenPersonId, {
      queryParams: { type },
    });
    setOrder(order);
    fetchTemplates(order.type, order.customerId);
    form.setInitializing(false);
  };

  // tslint:disable-next-line: max-line-length
  return {
    setPayload,
    onChangeNewOrderType,
    setTemplateOptions,
    form,
    order,
    history,
    payload,
    clearOrder: () => setOrder({} as Order),
  };
};
