import { Order } from 'api/OrderService';
import { isEmptyObject } from 'components/utils/utils';

export const isExist = <T>(value: T | null | undefined): value is NonNullable<T> =>
  value !== undefined && value !== null;

export const isString = (value?: any): value is string =>
  typeof value === 'string' && value.length > 0;

export const isNumber = (value?: any): value is number =>
  typeof value === 'number' && !Number.isNaN(value);

export const isExistOrder = (order: Order): boolean => !isEmptyObject(order);

export const isFunction = (value?: any): value is NonNullable<typeof Function> =>
  typeof value === 'function';
