import React from 'react';

import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { DateField } from 'components/forms/inputs/DateField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextField } from 'components/forms/inputs/TextField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { Col, Row } from 'components/react-grid';
import { translate } from 'i18n/translator';
import {
  CashTransferRepresentativeNames,
  DocumentTypes,
} from 'pages/Demands/CashTransferPage/enums';
import { CashTransferRepresentativeFields } from 'pages/Demands/CashTransferPage/interfaces';
import { isObjectNotEmpty, today } from 'pages/Demands/utils';

import {
  checkFieldError,
  checkFieldsFilled,
  dateOfBirth,
  getClearUppercaseValue,
  getClearValue,
  getLengthsDocumentNumber,
  MAX_LENGTH_SERIAL_OTHER_DOCUMENT,
  MAX_LENGTH_SERIAL_PASSPORT,
  PASSPORT_SERIAL_REGEX,
  passportOptions,
  validateFullName,
} from './utils';

export const CashTransferRepresentative = () => {
  const { getFieldValue, removeField, setError, getFieldErrors, getFormData, updateData } =
    useForm<CashTransferRepresentativeFields>();
  const documentType = getFieldValue(CashTransferRepresentativeNames.DocumentType) as DocumentTypes;

  const isDocumentIdCard = documentType === DocumentTypes.IdCard;
  const isDocumentPassport = documentType === DocumentTypes.Passport;

  const [minLengthDocumentNumber, maxLengthDocumentNumber] = getLengthsDocumentNumber(documentType);

  const maxLengthSerial = isDocumentPassport
    ? MAX_LENGTH_SERIAL_PASSPORT
    : MAX_LENGTH_SERIAL_OTHER_DOCUMENT;

  const validateDocumentSerial = React.useCallback(
    (value: string) => {
      const isPasswordSerialWrongFormat = isDocumentPassport && !PASSPORT_SERIAL_REGEX.test(value);

      if (isPasswordSerialWrongFormat) {
        return translate('front.form.invalid-document-serial-format.error');
      }

      return '';
    },
    [isDocumentPassport],
  );

  React.useEffect(() => {
    if (isDocumentIdCard) {
      removeField(CashTransferRepresentativeNames.DocumentSerial);
    }

    if (isDocumentPassport || documentType === DocumentTypes.Other) {
      removeField(CashTransferRepresentativeNames.EnrollmentNumber);
    }
  }, [documentType]);

  const formData = getFormData();

  React.useEffect(() => {
    if (isObjectNotEmpty(formData)) {
      const errors = getFieldErrors();
      const isFieldsFilled = checkFieldsFilled(formData);
      const hasFieldError = checkFieldError(errors);

      if (isFieldsFilled && !hasFieldError) {
        setError(null);
      }
    }
  }, [formData]);

  const resetDocumentNumber = () =>
    updateData({ [CashTransferRepresentativeNames.DocumentNumber]: null });

  return (
    <DefaultForm>
      <TextField
        name={CashTransferRepresentativeNames.FullName}
        label="front.demands.cash-transfer.full-name.field.label"
        minLength={3}
        maxLength={50}
        validate={validateFullName}
        normalize={getClearValue}
        required
      />
      <AmountField
        name={CashTransferRepresentativeNames.TaxCode}
        label="front.demands.cash-transfer.tax-number.field.label"
        minLength={9}
        maxLength={10}
        required
      />
      <SelectField
        name={CashTransferRepresentativeNames.DocumentType}
        options={passportOptions}
        onSelectOption={resetDocumentNumber}
        label="front.demands.cash-transfer.document-type.field.label"
        required
      />

      {!isDocumentIdCard && (
        <Row>
          <Col sm={4}>
            <TextField
              name={CashTransferRepresentativeNames.DocumentSerial}
              label="front.demands.cash-transfer.document-serial.field.label"
              maxLength={maxLengthSerial}
              validate={validateDocumentSerial}
              normalize={getClearUppercaseValue}
              disabled={!documentType}
              required
            />
          </Col>
          <Col sm={8}>
            <AmountField
              name={CashTransferRepresentativeNames.DocumentNumber}
              label="front.demands.cash-transfer.document-number.field.label"
              minLength={minLengthDocumentNumber}
              maxLength={maxLengthDocumentNumber}
              required={isDocumentPassport}
            />
          </Col>
        </Row>
      )}

      {isDocumentIdCard && (
        <Row>
          <Col sm={6}>
            <AmountField
              name={CashTransferRepresentativeNames.DocumentNumber}
              label="front.demands.cash-transfer.document-number.field.label"
              minLength={minLengthDocumentNumber}
              maxLength={maxLengthDocumentNumber}
              required={isDocumentPassport}
            />
          </Col>

          <Col sm={6}>
            <TextField
              name={CashTransferRepresentativeNames.EnrollmentNumber}
              label="front.demands.cash-transfer.document-enrollment-number.field.label"
              normalize={getClearValue}
              minLength={10}
              maxLength={14}
              required
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col sm={6}>
          <DateField
            name={CashTransferRepresentativeNames.DateBirth}
            label="front.demands.cash-transfer.date-of-birth.field.label"
            maxDate={dateOfBirth}
            disabledDays={{ after: dateOfBirth }}
            required
          />
        </Col>
        <Col sm={6}>
          <DateField
            name={CashTransferRepresentativeNames.DateIssue}
            label="front.demands.cash-transfer.date-of-issue.field.label"
            disabledDays={{ after: today }}
            maxDate={today}
            required
          />
        </Col>
      </Row>

      <TextField
        name={CashTransferRepresentativeNames.IssuedBy}
        label="front.demands.cash-transfer.issued-by.field.label"
        normalize={getClearValue}
        maxLength={50}
        required
      />
      <TextField
        name={CashTransferRepresentativeNames.PlaceOfResidence}
        label="front.demands.cash-transfer.place-of-residence.field.label"
        normalize={getClearValue}
        minLength={5}
        maxLength={60}
        required
      />
    </DefaultForm>
  );
};
