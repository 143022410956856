import * as React from 'react';
import { Col, Container, Row } from 'react-grid';
import { useRouteMatch } from 'react-router';

import { api } from 'api';
import { registrationPages } from 'api/RegistrationService';
import { FILETYPE_PRESETS, Option } from 'api/Service';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { FILE_ACCEPTS } from 'components/forms/inputs/FileField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { Checked } from 'components/icons';
import { AuthLayout } from 'components/layout/AuthLayout';
import { translate } from 'i18n/translator';

export interface ScanType {
  file?: Blob;
  photo?: Blob;
  type?: string;
  url?: string;
}

interface Payload {
  devices: Option[];
}

const fileButton = React.createRef<HTMLInputElement>();

const RegistrationFilesPageForm: React.FC = () => {
  const { getDevices, onSelectDevice, toBlob, addFile, getScanTypes, removeFile } =
    useRegistrationFiles();

  const [scan, setScan] = React.useState<ScanType>({});
  const [scans, setScans] = React.useState<ScanType[]>();

  const { progress, error, handleSubmit, payload, getFieldValue } = useForm<any, Payload>(
    async ({ setFields, setPayload }) => {
      const devices = await getDevices();
      await onSelectDevice(devices[0]?.value);
      await updateScans();
      setPayload({ devices });
      setFields({ a: devices[0]?.value });
    },
  );

  const updateScans = async () => {
    const scans = await getScanTypes();
    setScans(scans);
    setScan(scans.find(item => !item.url));
  };

  const addScan = async () => {
    await addFile(scan);
    await updateScans();
  };

  const clearPhoto = () => {
    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    const context = canvas.getContext('2d');
    context.fillStyle = '#AAA';
    context.fillRect(0, 0, canvas.width, canvas.height);
    setScan(scan => ({ ...scan, file: undefined, photo: null }));
  };

  const takePicture = () => {
    const video = document.getElementById('video') as HTMLVideoElement;
    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    const context = canvas.getContext('2d');

    const width = video.clientWidth;
    const height = video.clientHeight;

    canvas.width = width;
    canvas.height = height;

    context.drawImage(video, 0, 0, width, height);
    setScan(scan => ({ ...scan, photo: toBlob(canvas.toDataURL()) }));
  };

  if (scans && !scan) {
    return (
      <div>
        {translate('front.registration.files-page.description-photograph-to-success.label')}
      </div>
    );
  }

  const deviceId = getFieldValue('deviceId');

  return (
    <AuthLayout>
      <div className="">
        <div className="login-page__body__box card">
          <div className="card-body">
            <div className="login-page__body__box__title">
              {translate('front.registration.files-page.title.label')}
            </div>
            <DefaultForm>
              <FormError>{error}</FormError>
              {scans?.map(item => (
                <div>
                  <span
                    style={{
                      display: 'flex',
                      color: item.type === scan.type ? 'initial' : item.url ? 'green' : 'grey',
                    }}
                    onClick={() => setScan(item)}
                  >
                    {!!item.url && <Checked />}
                    {registrationPages[item.type]}
                  </span>
                </div>
              ))}
              {payload.devices?.length ? (
                <SelectField
                  label="front.registration.files-page.field-device-id.label"
                  name="deviceId"
                  onSelectOption={onSelectDevice}
                  options={payload.devices}
                  required
                />
              ) : (
                <div>
                  {translate(
                    'front.registration.files-page.description-no-photo-devices-found.label',
                  )}
                </div>
              )}
            </DefaultForm>
            <div style={{ paddingTop: '15px' }}>
              <div style={{ display: `${scan.url ? 'none' : 'initial'}` }}>
                <video
                  id="video"
                  autoPlay={true}
                  playsinline
                  style={{
                    width: '100%',
                    display: `${scan.file || scan.photo ? 'none' : 'initial'}`,
                  }}
                />
                <canvas
                  id="canvas"
                  style={{
                    width: '100%',
                    display: `${!scan.photo ? 'none' : 'initial'}`,
                  }}
                />
              </div>
              {(scan.file || scan.url) && (
                <img style={{ width: '100%' }} src={scan.url || URL.createObjectURL(scan.file)} />
              )}
            </div>
            <Container>
              <Row>
                {!scan.url && !scan.photo && scan.type && !scan.file && deviceId && (
                  <Col sm={6}>
                    <Button color="primary" onClick={() => takePicture()}>
                      {translate('front.registration.files-page.button-to-photograph.label')}
                    </Button>
                  </Col>
                )}
                {scan.type && (
                  <Col sm={6}>
                    <Button progress={progress} onClick={() => fileButton.current.click()}>
                      {translate('front.registration.files-page.button-select-photo.label')}
                      <input
                        ref={fileButton}
                        type="file"
                        onChange={e => {
                          const file = e.target.files[0];
                          setScan(item => ({
                            ...item,
                            file,
                            url: null,
                            photo: null,
                          }));
                          fileButton.current.value = null;
                        }}
                        className="upload-file__label_file"
                        accept={FILE_ACCEPTS[FILETYPE_PRESETS.IMAGES].join(',')}
                      />
                    </Button>
                  </Col>
                )}
                {(scan.photo || scan.file) && (
                  <Col sm={5}>
                    <Button color="primary" onClick={() => clearPhoto()} progress={progress}>
                      {translate('front.registration.files-page.button-cancel.label')}
                    </Button>
                  </Col>
                )}
                {(scan.file || scan.photo) && (
                  <Col sm={6}>
                    <Button
                      color="primary"
                      onClick={e => handleSubmit(addScan, e)}
                      progress={progress}
                    >
                      {translate('front.registration.files-page.button-send.label')}
                    </Button>
                  </Col>
                )}
                {scan.url && (
                  <Col sm={6}>
                    <Button
                      color="primary"
                      onClick={async () => {
                        await removeFile(scan.type);
                        await updateScans();
                      }}
                      progress={progress}
                    >
                      {translate('front.registration.files-page.button-delete.label')}
                    </Button>
                  </Col>
                )}
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export const RegistrationFilesPage = withForm(RegistrationFilesPageForm);

const useRegistrationFiles = () => {
  const {
    params: { orderId, uuid },
  } = useRouteMatch<{ orderId: string; uuid: string }>();

  const getScanTypes = async (): Promise<ScanType[]> => {
    const data = await api.registration.getScanStatus(orderId, uuid);
    return Object.entries(data.pages).map(([key, value]) => ({
      type: key,
      url: value === 'null' ? null : value,
      file: undefined,
    }));
  };

  const addFile = async (scan: ScanType) => {
    return await api.registration.addScan(orderId, scan.file || scan.photo, scan.type);
  };

  const toBlob = (dataURI: string) => {
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i = i + 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  };

  const onSelectDevice = async (deviceId: string) => {
    const constraints = {
      video: { deviceId },
      audio: false,
    };

    const userMedia = await navigator.mediaDevices.getUserMedia(constraints);
    const video = document.getElementById('video') as HTMLMediaElement;
    if (!deviceId) {
      video.srcObject = null;
    } else {
      video.srcObject = userMedia;
    }
  };

  const getDevices = async (): Promise<Option[]> => {
    const enumerateDevices = await navigator.mediaDevices.enumerateDevices();

    return enumerateDevices
      .filter(item => item.kind === 'videoinput')
      .map(({ deviceId: value, label }, i) => {
        return { value, label: label || `Device-${i}` };
      });
  };

  const removeFile = async (attachmentPurpose: string) => {
    await api.registration.removeScan(orderId, attachmentPurpose);
  };

  return {
    getDevices,
    onSelectDevice,
    toBlob,
    addFile,
    getScanTypes,
    removeFile,
  };
};
