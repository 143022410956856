import React from 'react';

import { OrderType } from 'api/DemandsService/enums';
import { TabsProps } from 'components/tabs/interfaces';
import { TabBar } from 'components/tabs/TabBar';

interface FilterTabBarProps extends TabsProps {
  setPagination: (page: number) => void;
}

export const FilterTabBar = ({ setPagination, ...tabProps }: FilterTabBarProps) => {
  const handleChange = (key: OrderType) => {
    setPagination(0);
    tabProps.onChange(key);
  };

  return <TabBar {...tabProps} onChange={handleChange} />;
};
