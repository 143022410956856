import * as React from 'react';

import { AmountField } from './inputs/amountFields/AmountField';

interface Props {
  label: string;
  length: number;
  name: string;
  autoFocus?: boolean;
}

export const SmsField: React.FC<Props> = ({ length, label, name, autoFocus }) => {
  const maskConfig = React.useMemo(
    () => ({
      allowLeadingZeros: true,
      prefix: '',
    }),
    [length],
  );

  return (
    <div className="sms-field">
      <AmountField
        label={label}
        name={name}
        maxLength={length}
        maskConfig={maskConfig}
        autoFocus={autoFocus}
        isLargeInput
        required
      />
    </div>
  );
};
