import * as React from 'react';
import { SortOrder, TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { getPage, HasId } from 'api/Service';
import { CounterDescription } from 'components/CounterDescription/CounterDescription';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { translate } from 'i18n/translator';
import { SuccessCounterparties } from 'pages/Import/SuccessImportList/SuccessCounterpartyList';

import 'pages/Import/styles.scss';

interface CounterpartyError extends HasId {
  data: SuccessCounterparties;
  errors: string[];
  id: number;
}

interface Props {
  errors: CounterpartyError[];
}

const columns: TableColumnProps<CounterpartyError>[] = [
  {
    label: '#',
    render: row => <DataColumn title={row.id} />,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.import-page.errors.label',
    render: row => (
      <DataColumn>
        <ul className="error-message-container">
          {row.errors?.map(massage => <li key={row.id}>{massage}</li>)}
        </ul>
      </DataColumn>
    ),
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

export const ErrorCounterpartiesList = ({ errors }: Props) => (
  <>
    <CounterDescription
      count={errors.length}
      description={translate('front.import-page.tab-errors.label')}
    />
    <DataTable
      name={TableNamesEnum.ErrorCounterpartiesImport}
      columns={columns}
      defaultOrder={SortOrder.Asc}
      fetchRows={async request => getPage(errors, request)}
    />
  </>
);
