import * as React from 'react';
import { Action, TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { getStatusColor, LettersGrid, LetterStatus } from 'api/MailService';
import { SortOrder } from 'api/Service';
import { Page } from 'components/layout/Page/Page';
import { Status } from 'components/layout/Status';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import * as format from 'components/utils/format';
import config from 'config';
import { translate } from 'i18n/translator';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';

import { MailPageFilter } from './MailsPageFilter';

const { DRAFT } = config.page.mail;

const columns: TableColumnProps<LettersGrid>[] = [
  {
    label: 'front.mails-table.date.label',
    render: row => <DataColumn title={format.dateTime(row.date)} />,
    sort: 'generateTime',
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.mails-table.owner.label',
    render: row => <DataColumn title={row.customerName} />,
    sort: 'name',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.mails-table.subject.label',
    render: row => <DataColumn title={row.subject} />,
    sort: 'subjectText',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.mails-table.direction.label',
    render: row => {
      const title = row.isFromCustomer
        ? 'front.mails-table-filters.outgoing.label'
        : 'front.mails-table-filters.incoming.label';
      return <DataColumn title={translate(title)} />;
    },
    sort: 'notificationMessage.companyPersonId',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.mails-table.status.label',
    render: row => (
      <DataColumn>
        <Status color={getStatusColor(row.documentStatus as LetterStatus)}>
          {row.statusLabel}
        </Status>
      </DataColumn>
    ),
    sort: 'status',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    renderMenu: letter => [
      {
        label: 'front.mails-table-actions.send.label',
        onClick: async () => await api.mail.sendLetter(letter.id),
        isVisible: letter.documentStatus === DRAFT && letter.sendStatus,
        isReloadable: true,
        confirmMessage: 'front.mails-table-actions.confirm-send.label',
      },
      {
        label: 'front.mails-table-actions.delete.label',
        onClick: async () => await api.mail.deleteLetter(letter.id),
        isVisible: (letter.documentStatus === DRAFT && letter.sendStatus) || !letter.sendStatus,
        isReloadable: true,
        confirmMessage: 'front.mails-table-actions.confirm-delete.label',
      },
      {
        label: 'front.mails-table-actions.view.label',
        onClick: () => {
          const action = letter.documentStatus === DRAFT ? 'edit' : 'view';
          goto(pages.message(letter.id, action));
        },
        isReloadable: true,
      },
    ],
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

const actions: Action[] = [
  {
    label: 'front.mails-table-actions.send.label',
    action: async letters => {
      const draftLetters = letters.filter(
        letter => letter.documentStatus === DRAFT && !letter.sendStatus,
      );
      const selectedLetterIds: number[] = draftLetters.map(letter => letter.id);

      if (!selectedLetterIds.length) {
        return;
      }

      await api.mail.sendLetters(selectedLetterIds);
    },
    color: 'secondary',
    isReloadable: true,
    confirmMessage: 'front.mails-table-actions.confirm-send.label',
    isVisible: selected => selected.some(item => item.documentStatus === DRAFT && !item.sendStatus),
  },
  {
    label: 'front.mails-table-actions.delete.label',
    action: async letters => {
      const draftLetters = letters.filter(
        letter => (letter.documentStatus === DRAFT && letter.sendStatus) || !letter.sendStatus,
      );
      const selectedLetterIds: number[] = draftLetters.map(letter => letter.id);

      if (!selectedLetterIds.length) {
        return;
      }

      await api.mail.deleteLetters(selectedLetterIds);
    },
    isReloadable: true,
    confirmMessage: 'front.mails-table-actions.confirm-delete.label',
    isVisible: selected => selected.some(item => item.documentStatus === DRAFT),
  },
];

export const MailsPage = () => (
  <Page title={translate('front.mails-table.label')}>
    <DataTable
      name={TableNamesEnum.LettersTable}
      columns={columns}
      actions={actions}
      fetchRows={api.mail.getLetters}
      onRowClick={row => {
        goto(pages.message(row.id, row.documentStatus === DRAFT ? 'edit' : 'view'));
      }}
      defaultSort="generateTime, sentTime|desc|nulls_first"
      defaultOrder={SortOrder.Desc}
      filterComponent={MailPageFilter}
    />
  </Page>
);
