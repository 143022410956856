import * as React from 'react';
import { useLocation } from 'react-router';

import { ErrorFromBack } from 'components/forms/withForm';
import { isExist } from 'utils/isData';
import { noop } from 'utils/noop';

import {
  Errors,
  FetchOnBlur,
  FieldData,
  FieldsData,
  FieldValidations,
  FinalValue,
  FormContext,
  FormContextValue,
  FormPayload,
  FormState,
  SubscriptionCb,
  Subscriptions,
  Validate,
} from './FormContext';
import { createFieldData, normalizeStringValue, setFieldData, toFormState, toPlain } from './utils';

export interface FormInitialParams {
  handleError: (
    e: ErrorFromBack,
    setError: (errorMessage: string | null) => void,
    setErrors: (errors: Errors) => void,
  ) => void;
  preserveReset?: string[];
}

export const validatingForm =
  (params: FormInitialParams) => (Component: React.ComponentType) => (props: any) => {
    const subscriptions = React.useRef<Subscriptions>({});

    const formId = React.useMemo(() => makeid(), []);
    const [disabled, setDisabled] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [fieldsData, setFieldsData] = React.useState<FieldsData>({} as any);
    const [payload, setPayloadState] = React.useState<FormPayload>({});
    // const [subscriptions, setSubscriptions] = React.useState<Subscriptions>({});
    const [progress, setProgress] = React.useState(false);
    const [initializing, setInitializing] = React.useState(false);
    //TODO:Check how and whether it works
    const [fetchOnBlur, setFetchOnBlur] = React.useState<FetchOnBlur>({
      func: null,
      needToCall: false,
      updatedField: false,
    });

    const { pathname } = useLocation();

    //TODO:Check how and whether it works and add tests
    const updateFetchOnBlur = (state: Partial<FetchOnBlur>) => {
      setFetchOnBlur(prevState => ({
        ...prevState,
        ...state,
      }));
    };

    function subscribe(path: string, cb: SubscriptionCb) {
      subscriptions.current = { ...subscriptions.current, [path]: cb };
    }

    function unsubscribe(path: string) {
      delete subscriptions.current[path];
    }

    function getFormData(): FormState {
      return toFormState(fieldsData, true);
    }

    function getFieldErrors() {
      return Object.entries(fieldsData).reduce((acc, [fieldName]) => {
        if (isExist(fieldsData[fieldName]?.error)) {
          return { ...acc, [fieldName]: fieldsData[fieldName]?.error };
        }
        return acc;
      }, null);
    }

    function getFieldValue(path: string): FinalValue {
      return fieldsData[path]?.value;
    }

    function validateFieldsData(formData: FormState): Errors {
      return Object.entries(fieldsData).reduce<Errors | null>((errors, [fieldName, fieldData]) => {
        const error =
          fieldData.validate && fieldData.validate(fieldData.value, formData, fieldsData);

        if (error) {
          return { ...(errors || {}), [fieldName]: error };
        }

        return errors;
      }, null);
    }

    function validateField(path: string, value: FinalValue): string[] {
      const formData = getFormData();
      const fieldData = fieldsData[path];

      return [fieldData?.validate, fieldData?.warn].map(action => {
        if (action) {
          return action(value, formData, fieldsData);
        }
      });
    }

    function updateField(path: string, fieldData: FieldData) {
      setFieldsData(state => setFieldData(path, fieldData, state));
    }

    function updateFieldInData(path: string, fieldName: string, value: any) {
      setFieldsData(state => createFieldData(path, fieldName, value, state));
    }

    const updateInput = (path: string, value: any) => {
      if (subscriptions.current[path]) {
        subscriptions.current[path](
          { path, value, payload, formData: getFormData() },
          { setPayload, updateData },
        );
      }
      setFieldsData(oldFieldsData =>
        setFieldData(path, { value, error: null, warning: null }, oldFieldsData),
      );
      fetchOnBlur.func && updateFetchOnBlur({ updatedField: true });
    };

    const registerFieldsArray = (name: string, validate: Validate) => {
      const fieldData = { validate, isFieldArray: true };
      setFieldsData(oldFieldsData => ({ ...oldFieldsData, [name]: fieldData }));
    };

    const registerField = (
      name: string,
      incDefaultValue: any = null,
      { validate, warn }: FieldValidations,
    ) => {
      setFieldsData(oldState => {
        const defaultValue = !oldState[name] ? incDefaultValue : oldState[name].defaultValue;
        const value = !oldState[name] ? defaultValue : oldState[name].value;
        const fieldData = {
          defaultValue,
          validate,
          warn,
          value,
          ...oldState[name],
        };
        return { ...oldState, [name]: fieldData };
      });
    };

    const unregisterField = (name: string, isRemovable = false, prevPathName: string) => {
      const shouldKeepFieldValues = prevPathName !== pathname;

      if (shouldKeepFieldValues) return;
      setFieldsData(oldFieldsData => {
        const newFieldsData = { ...oldFieldsData };
        if (isRemovable) {
          delete newFieldsData[name];
        } else {
          newFieldsData[name] = { value: null };
        }
        return newFieldsData;
      });
    };

    const removeField = (name: string) =>
      setFieldsData(oldFieldsData => {
        const newFieldsData = { ...oldFieldsData };
        delete newFieldsData[name];

        return newFieldsData;
      });

    const setErrors = (errors: Errors = {}, isMerge?: boolean) => {
      setFieldsData(oldFieldsData => {
        const newFieldsData = { ...oldFieldsData };
        Object.entries(newFieldsData).forEach(([fieldName, fieldData]) => {
          const newError = isMerge ? newFieldsData[fieldName].error : null;
          const error = fieldName in errors ? errors[fieldName] : newError;
          newFieldsData[fieldName] = { ...fieldData, error };
        });

        return newFieldsData;
      });
    };

    const setInitialingCb =
      (callback: any) =>
      async (...args: any) => {
        setInitializing(true);
        await callback(...args);
        setInitializing(false);
      };

    const handleError = (error: ErrorFromBack) => {
      params.handleError(error, setError, setErrors);
    };

    const handleSubmit = async (
      onSubmit: (formData: FormState) => void,
      e?: Event,
    ): Promise<boolean> => {
      e && e.preventDefault();

      setProgress(true);
      setDisabled(true);
      setErrors({});
      setError(null);
      const normalizedFormData = Object.entries(getFormData()).reduce((acc, [key, value]) => {
        if (typeof value === 'string') {
          return { ...acc, [key]: normalizeStringValue(value) };
        }
        return { ...acc, [key]: value };
      }, {});

      try {
        const errors = validateFieldsData(normalizedFormData);
        if (errors) {
          setErrors(errors);
          return false;
        }
        await onSubmit(normalizedFormData);
      } catch (error: any) {
        handleError(error);
        console.log(error);
        return false;
      } finally {
        setProgress(false);
        setDisabled(false);
      }
      return true;
    };

    const setData = (formData: FormState = {}, arrayFormData: FormState = {}) => {
      setFieldsData(oldState => {
        const plainFormData = toPlain(formData);
        const plainArrayFormData = toPlain(arrayFormData, true);

        const newState: FieldsData = {};
        Object.entries({ ...plainFormData, ...plainArrayFormData }).forEach(
          ([fieldName, fieldValue]) => {
            newState[fieldName] = {
              ...oldState[fieldName],
              value: fieldValue,
              defaultValue: fieldValue,
            };
          },
        );

        return { ...oldState, ...newState };
      });
    };

    const updateData = (formData: FormState = {}, arrayFormData: FormState = {}) => {
      setFieldsData(oldState => {
        const plainFormData = toPlain(formData);
        const plainArrayFormData = toPlain(arrayFormData, true);

        const newState: FieldsData = {};
        Object.entries({ ...plainFormData, ...plainArrayFormData }).forEach(
          ([fieldName, fieldValue]) => {
            newState[fieldName] = {
              ...oldState[fieldName],
              value: fieldValue,
              error: null,
            };
          },
        );

        return { ...oldState, ...newState };
      });
    };

    const resetData = () => {
      setFieldsData(state => {
        const newFieldsData = { ...state };
        Object.entries(newFieldsData).forEach(([fieldName, fieldData]) => {
          if ((params.preserveReset || []).includes(fieldName)) return;
          newFieldsData[fieldName] = {
            ...fieldData,
            error: null,
            value: fieldData.defaultValue,
          };
        });

        return newFieldsData;
      });

      setError(null);
    };

    const clearData = (isPreserveValidate = false) => {
      setFieldsData(state =>
        Object.entries(state).reduce((acc, [path, fieldData]) => {
          if ((params.preserveReset || []).includes(path)) {
            return { ...acc, [path]: fieldData };
          }
          return {
            ...acc,
            [path]: {
              error: null,
              value: null,
              validate: isPreserveValidate ? fieldData.validate : noop,
            },
          };
        }, {}),
      );

      setError(null);
    };

    const setPayload: (payload: React.SetStateAction<any>) => void = arg => {
      //TODO:Check how and whether it works
      if (typeof arg === 'function') {
        setPayloadState(arg);
      } else {
        setPayloadState(prevState => ({ ...prevState, ...arg }));
      }
    };

    const providerProps: FormContextValue<FormState, FormPayload> = {
      formId,
      updateInput,
      registerField,
      fieldsData,
      handleSubmit,
      handleError,
      resetData,
      setProgress,
      setData,
      updateFieldInData,
      setError,
      setErrors,
      updateData,
      unregisterField,
      validateField,
      getFieldErrors,
      registerFieldsArray,
      updateField,
      setFieldsData,
      getFormData,
      getFieldValue,
      error,
      setDisabled,
      disabled,
      setInitializing,
      setInitialingCb,
      payload,
      setPayload,
      clearData,
      initializing,
      progress,
      subscribe,
      unsubscribe,
      fetchOnBlur,
      removeField,
      updateFetchOnBlur,
      ready: Object.keys(fieldsData).length > 0,
    };

    return (
      <FormContext.Provider value={providerProps}>
        <Component {...props} />
      </FormContext.Provider>
    );
  };

function makeid(length = 10) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  // tslint:disable-next-line: no-increment-decrement
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
