import { Decimal } from 'decimal.js-light';
import moment from 'moment';

import { api } from 'api';
import { CashTransferAccounts, DemandType, VisualComponentEnum } from 'api/DemandsService/enums';
import {
  BankBranchInterface,
  CashSymbolInterface,
  DynamicField,
  DynamicFieldBase,
  DynamicFieldData,
  Financial,
} from 'api/DemandsService/interfaces';
import { Account } from 'api/interfaces';
import { Option } from 'api/Service';
import { FormState } from 'components/forms/ValidatingForm/FormContext';
import { getAmountCurrency } from 'components/utils/format';
import { validateMessages } from 'components/validateMessages';
import config from 'config';
import { translate } from 'i18n/translator';
import {
  CashPreparationDetailNames,
  CashTransferDetailNames,
  CashTransferRepresentativeNames,
  DocumentTypes,
} from 'pages/Demands/CashTransferPage/enums';
import {
  CashTransferPayload,
  CashTransferRepresentativeFields,
} from 'pages/Demands/CashTransferPage/interfaces';
import { FILE_SEPARATOR, getFileValue, TYPE_SEPARATOR } from 'pages/Demands/utils';
import { isExist } from 'utils/isData';

export const MAX_LENGTH_SERIAL_PASSPORT = 2;
export const MAX_LENGTH_SERIAL_OTHER_DOCUMENT = 4;
export const MAX_LENGTH_PURPOSE = 200;
export const FULL_NAME_REGEX = /^(\S+\s){2}\S+$/;
export const PASSPORT_SERIAL_REGEX = new RegExp('^[а-я]{2}$', 'i');
export const dateOfBirth = moment().subtract(18, 'years').toDate();

const representativeNames = Object.values(CashTransferRepresentativeNames);

export const passportOptions: Option<undefined>[] = [
  {
    label: 'front.demands.cash-transfer.document.passport.option.label',
    value: DocumentTypes.Passport,
  },
  {
    label: 'front.demands.cash-transfer.document.id-card.option.label',
    value: DocumentTypes.IdCard,
  },
  {
    label: 'front.demands.cash-transfer.document.other.option.label',
    value: DocumentTypes.Other,
  },
];

export const getRoundingPenni = (value: string | number, currency: string): string => {
  if (!isExist(value)) {
    return null;
  }

  const scale = currency === config.bank.nationalCurrency ? 1 : 0;
  return new Decimal(+value).toFixed(scale, Decimal.ROUND_HALF_UP);
};

const DENIED_SYMBOL = ';';
const hasSemiColon = (value: string) => value.indexOf(DENIED_SYMBOL) !== -1;

export const validateFullName = (value: string) => {
  if (!FULL_NAME_REGEX.test(value)) {
    return translate('front.validation-fullName.cash-orders.label');
  }

  return '';
};

export const getClearValue = (value: string) =>
  hasSemiColon(value) ? value.replaceAll(DENIED_SYMBOL, '') : value;

export const getClearUppercaseValue = (value: string) => getClearValue(value).toUpperCase();

export const getLengthsDocumentNumber = (documentType: DocumentTypes) => {
  const lengthsDocumentNumber = {
    [DocumentTypes.Passport]: [6, 6],
    [DocumentTypes.IdCard]: [9, 9],
    [DocumentTypes.Other]: [5, 10],
    default: [1, 10],
  };

  return lengthsDocumentNumber[documentType] || lengthsDocumentNumber.default;
};

const getAccountType = (orderType: DemandType): CashTransferAccounts => {
  const accountTypes: Partial<Record<DemandType, CashTransferAccounts>> = {
    [DemandType.CashTransferOrder]: CashTransferAccounts.cashTransferAccounts,
    [DemandType.CashPreparationOrder]: CashTransferAccounts.cashPreparationAccounts,
    [DemandType.CashWithdrawalOrder]: CashTransferAccounts.cashWithdrawalAccounts,
  };

  return accountTypes[orderType];
};

const getBranchOptions = (branches: BankBranchInterface[]) =>
  branches.map(branch => ({
    value: branch.id,
    label: `${branch.fullName} / ${branch.mailAddress}`,
    content: branch,
  }));

export const getBranches = async (id: number) =>
  getBranchOptions(await api.demands.getBranches(id));

const getAccountOption = ({ account }: { account: Account }) => ({
  label: `${account.iban} / ${getAmountCurrency(account.plannedBalance, account.currency)}`,
  value: account.id.toString(),
  content: account,
});

export const getAccounts = async (customerId: string | number, orderType: DemandType) => {
  const accountType = getAccountType(orderType);
  const result = await api.demands.getAccountsCashTransfer(customerId, [accountType]);

  return result[accountType].map(getAccountOption);
};

const getUpdatedAcc = (acc: DynamicField[], data: Partial<DynamicFieldData>[], code: string) => [
  ...acc,
  { data, field: { code } },
];

export const getDynamicFields = (fields: FormState, payload: CashTransferPayload) =>
  [...payload.orderFieldSchema.entries()].reduce(
    (acc: DynamicField[], [fieldName, { visualComponent, orderFieldId }]) => {
      const value = fields[fieldName];

      if (!isExist(value)) {
        return acc;
      }

      switch (visualComponent) {
        case VisualComponentEnum.SELECT:
          return getUpdatedAcc(
            acc,
            [getDynamicSelectFields(fieldName, orderFieldId, value as string, payload)],
            fieldName,
          );
        case VisualComponentEnum.AMOUNT: {
          return getUpdatedAcc(
            acc,
            [
              {
                orderFieldId,
                numericValue: Number(value),
              },
            ],
            fieldName,
          );
        }
        case VisualComponentEnum.FILE: {
          if (!value.length) {
            return acc;
          }

          return getUpdatedAcc(
            acc,
            (value as any[])?.map(({ value, field, attachment }) => {
              const [type, content] = value.split(FILE_SEPARATOR);
              const [, typeValue] = type.split(TYPE_SEPARATOR);

              return {
                orderFieldId,
                isFieldApproved: true,
                textValue: field,
                attachment: {
                  name: field,
                  type: attachment?.type ?? typeValue,
                  content,
                },
              };
            }),
            fieldName,
          );
          return acc;
        }
        default: {
          return getUpdatedAcc(
            acc,
            [
              {
                orderFieldId,
                fieldValue: value,
                textValue: value,
              },
            ],
            fieldName,
          );
        }
      }
    },
    [],
  );

const getDynamicSelectFields = (
  fieldName: string,
  orderFieldId: number,
  value: string,
  payload: CashTransferPayload,
): Partial<DynamicFieldData> => {
  if (fieldName === CashTransferDetailNames.CashSymbol) {
    return {
      orderFieldId,
      fieldValue: value,
      textValue: value,
    };
  }

  if (fieldName === CashPreparationDetailNames.PayerAccountId) {
    const account = payload.accounts.find(account => value === account.value).content;

    return {
      orderFieldId,
      numericValue: Number(value),
      fieldValue: value,
      textValue: account.iban,
    };
  }

  if (fieldName === CashTransferDetailNames.Branch) {
    const branchId = Number(value);
    const branchName = payload.branches.find(branch => branchId === Number(branch.value)).label;

    return {
      orderFieldId,
      numericValue: branchId,
      fieldValue: value,
      textValue: branchName,
    };
  }

  if (fieldName === CashTransferRepresentativeNames.DocumentType) {
    const passport = passportOptions.find(passport => passport.value === value);

    return {
      orderFieldId,
      fieldValue: value,
      textValue: translate(passport?.label),
    };
  }
};

export const getOrderFieldSchema = (orderFieldModel: DynamicField[]) =>
  orderFieldModel.reduce(
    (acc, { field: { code, visualComponent, id } }) =>
      acc.set(code, { orderFieldId: id, visualComponent }),
    new Map(),
  );

export const getDetailCashFields = (dynamicFields: DynamicField[]) =>
  dynamicFields.reduce((acc, { field, data }) => {
    return { ...acc, [field.code]: getDetailField(field, data) };
  }, {});

const getDetailField = (field: DynamicFieldBase, data: DynamicFieldData[]) => {
  const visualComponents: Record<string, (data: DynamicFieldData[]) => any> = {
    [VisualComponentEnum.SELECT]: data => data[0]?.fieldValue,
    [VisualComponentEnum.AMOUNT]: data => data[0]?.numericValue,
    [VisualComponentEnum.FILE]: data => getFileValue(data),
    default: data => data[0]?.textValue,
  };

  return visualComponents[field.visualComponent]?.(data) ?? visualComponents.default(data);
};

export const getFinancial = (
  orderType: string,
  receiverAccount: string,
  receiverIban: string,
  isoCode: string,
  { payerAccountId, amount, purpose }: Partial<Financial>,
) => ({
  isoCode,
  purpose,
  receiverIban,
  receiverAccount,
  currencyRate: 1,
  amount: Number(amount),
  payerAccountId: Number(payerAccountId),
});

export const getClearFormData = (formData: FormState): FormState =>
  Object.entries(formData).reduce((acc, [name, value]) => {
    if (isExist(value)) {
      return { ...acc, [name]: value };
    }
    return acc;
  }, {});

export const getCorrectFinancial = ({
  amount,
  purpose,
  payerAccountId,
  receiverIban,
  receiverAccount,
}: Financial): Partial<Financial> => ({
  amount,
  purpose,
  payerAccountId,
  receiverIban,
  receiverAccount,
});

const getPurposes = (
  descriptionMap: Map<string, Option[]>,
  { dictionaryValue, description, descriptionTranslation }: CashSymbolInterface,
) => {
  const newPurposeOption = {
    value: descriptionTranslation,
    label: descriptionTranslation,
    content: description,
  };

  if (descriptionMap.has(dictionaryValue)) {
    const currentPurposes = descriptionMap.get(dictionaryValue);

    descriptionMap.set(dictionaryValue, [...currentPurposes, newPurposeOption]);
  } else {
    descriptionMap.set(dictionaryValue, [newPurposeOption]);
  }

  return descriptionMap;
};

const getCashSymbols = (
  cashSymbols: Array<Option>,
  { dictionaryValue, label }: CashSymbolInterface,
): Array<Option> => {
  const hasSymbol =
    cashSymbols.find(cashSymbol => cashSymbol.value === dictionaryValue) !== undefined;

  if (hasSymbol) {
    return cashSymbols;
  }

  return [...cashSymbols, { label: `${dictionaryValue} - ${label}`, value: dictionaryValue }];
};

export const getSymbols = async (orderType: DemandType) => {
  const { rows: allCashSymbols } = await api.demands.getCashTransferSymbols(orderType);

  const [purposeMapOptions, cashSymbolOptions] = allCashSymbols.reduce(
    (acc, symbol) => {
      const [purposes, cashSymbols] = acc;
      const newPurposes = getPurposes(purposes as Map<string, Option[]>, symbol);
      const newCashSymbols = getCashSymbols(cashSymbols as Array<Option>, symbol);

      return [newPurposes, newCashSymbols];
    },
    [new Map(), []],
  );

  return { allCashSymbols, purposeMapOptions, cashSymbolOptions };
};

export const checkFieldsFilled = (formData: CashTransferRepresentativeFields) =>
  representativeNames.every((field: CashTransferRepresentativeNames) =>
    formData.hasOwnProperty(field) ? isExist(formData[field]) : true,
  );

export const checkFieldError = (errors: Record<CashTransferRepresentativeNames, string>) =>
  representativeNames.find(field => isExist(errors?.[field]));

export const validateBranch = (branchId: number, branches: Option<BankBranchInterface>[]) => {
  const { cashAccount, cashIban } = branches.find(
    branch => `${branch.value}` === `${branchId}`,
  ).content;

  return !cashAccount || !cashIban
    ? translate('front.cash-transfer-page.branch.miss-cash-account.error')
    : '';
};

export const DEFAULT_PURPOSE = 0;

const TEMPLATE_SYMBOLS = /\_+/g;
const ALLOW_SYMBOLS = "[ a-zA-Z0-9а-яА-ЯіїєёґІЇЁЄҐ!#$%&'()№*+,;.\\\\\\/<+_=>:\\[\\]]*";
const PATTERNS: [RegExp, string][] = [
  [/\.+/g, '\\.'],
  [/\)+/g, '\\)'],
  [/\(+/g, '\\('],
  [TEMPLATE_SYMBOLS, ALLOW_SYMBOLS],
];

export const getTemplate = (template: string) =>
  `^${PATTERNS.reduce((acc, pattern) => {
    const [regExp, replacementString] = pattern;
    return acc.replace(regExp, replacementString);
  }, template)}`;

export const validatePurpose = (maxLength: number) => {
  if (maxLength < 0) {
    return validateMessages.moreThan(MAX_LENGTH_PURPOSE);
  }

  return '';
};
