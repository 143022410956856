import * as React from 'react';
import { Col, Row } from 'react-grid';
import { useLocation } from 'react-router';

import { api } from 'api';
import { parseUrlParams } from 'api/backend';
import { CardView } from 'api/ProductsService';
import { Option } from 'api/Service';
import { Button } from 'components/buttons/Button';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { HiddenField } from 'components/forms/inputs/HiddenField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { withForm } from 'components/forms/withForm';
import { useFilter } from 'components/Table/DataTable';
import { translate } from 'i18n/translator';
import { getLimitOptions } from 'pages/Products/Cards/Limits/utils';

const LimitFilterForm = () => {
  const fetchData = async () => {
    const [cards, limits] = await Promise.all([
      api.products.getCards({ sort: 'displayOrder' }),
      api.products.getCardLimitPeriods(),
    ]);
    const cardOptions = cards.rows.map(item => ({
      value: `${item.id}`,
      label: `${item.cardNumber}/${item.ownerName}/${item.currency}`,
      content: item,
    }));

    setPayload({ allCardOptions: cardOptions });
    setPayload({ filteredCardOptions: cardOptions });

    setPayload({
      limitOptions: getLimitOptions(limits),
    });
  };
  const { applyFilter, updateData, payload, setPayload } = useFilter({
    fetchOnDemand: true,
    fetchInitial: fetchData,
  });

  const { cardId, customerId } = parseUrlParams(useLocation().search);
  const { allCardOptions, filteredCardOptions, limitOptions } = payload;

  const onChangeCustomer = (value: string) => {
    const filteredCardOptions = allCardOptions.filter((card: Option<CardView>) =>
      value.includes(`${card.content.customerId}`),
    );
    updateData({ cardId: null });
    setPayload({ filteredCardOptions });
  };

  const defaultCustomerId = customerId ? [customerId] : [];

  return (
    <Row>
      <Col xl={4}>
        <Button onClick={applyFilter} color="primary" type="submit">
          {translate('front.cards-page.filter-show-limit.label')}
        </Button>
      </Col>
      <Col xl={8}>
        <Row>
          <Col md={4}>
            <CustomerSelect
              label="front.cards-page.filter-customers.label"
              name="customerIds"
              defaultValue={defaultCustomerId}
              onSelectOption={onChangeCustomer}
              required
              multi
            />
            <HiddenField name="forAll" defaultValue={true} />
          </Col>
          <Col md={4}>
            <SelectField
              label="front.cards-page.filter-cards.label"
              name="cardId"
              defaultValue={cardId}
              options={filteredCardOptions}
              required
            />
          </Col>
          <Col md={4}>
            <SelectField
              label="front.cards-page.filter-period.label"
              name="period"
              options={limitOptions}
              required
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export const LimitFilter = withForm(LimitFilterForm);
