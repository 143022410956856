import * as React from 'react';
import { Col, Container, Row } from 'react-grid';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { api } from 'api';
import { FileType, IMPORT_TYPES } from 'api/Service';
import { Button } from 'components/buttons/Button';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { FileField } from 'components/forms/inputs/FileField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { translate } from 'i18n/translator';
import { isActionAllowed } from 'navigations/access';
import { Privileges } from 'navigations/privileges';
import { ImportDetailsFormFieldNames } from 'pages/Import/ImportDetail/enums';
import {
  allowedImportPrivilege,
  allowedImportPrivilegeForSelect,
  fileAccepts,
  fileEncodingOptions,
  fileTypeOptions,
  getFormatOptions,
} from 'pages/Import/ImportDetail/ImportDetailOptions';
import { FormFields } from 'pages/Import/ImportDetail/interfaces';
import { onDownloadFile } from 'pages/Import/ImportDetail/utils';
import { SetResultProps } from 'pages/Import/ImportPage/interfaces';
import { selectChosenCustomers, selectDefaultCustomerId } from 'store/selectors';

interface Props {
  setResult: ({ result, importType }: SetResultProps) => void;
}

const deniedPrivilegeImportType = (
  customerId: number,
  importType: IMPORT_TYPES,
  allowedPrivileges: (value: IMPORT_TYPES) => Privileges,
): boolean => {
  const privilege = allowedPrivileges(importType);

  return privilege && customerId ? !isActionAllowed([privilege], customerId) : false;
};

const SALARY_TYPES = [IMPORT_TYPES.SALARY];

const checkSalaryType = (salaryType: IMPORT_TYPES) => SALARY_TYPES.includes(salaryType);

const ImportDetailsForm = ({ setResult }: Props) => {
  const currentCustomer = React.useRef<number>(null);
  const chosenCustomers = useSelector(selectChosenCustomers());
  const defaultCustomerId = useSelector(selectDefaultCustomerId());
  const { location } = useHistory<{ type: IMPORT_TYPES }>();

  const defaultImportType = location?.state?.type;

  const {
    getFormData,
    handleSubmit,
    resetData,
    progress,
    error,
    payload,
    setPayload,
    updateData,
    setInitialingCb,
  } = useForm<FormFields>(async () => {
    if (checkSalaryType(defaultFileType)) {
      await fetchSalaryDeal(defaultCustomerId);
    }
  });

  const { customerId, importType, productId, isMultiImport, fileFormat } = getFormData();

  const filteredFileTypeOptions = React.useMemo(
    () =>
      fileTypeOptions.filter(
        option =>
          !deniedPrivilegeImportType(customerId, option.value, allowedImportPrivilegeForSelect),
      ),
    [customerId],
  );

  const defaultFileType = React.useMemo(
    () =>
      deniedPrivilegeImportType(
        customerId ?? defaultCustomerId,
        importType ?? defaultImportType,
        allowedImportPrivilegeForSelect,
      )
        ? filteredFileTypeOptions[0].value
        : defaultImportType,
    [customerId],
  );

  const fetchSalaryDeal = async (customerId: number) => {
    const salaryOptions = await api.salary.getSalaryDealOptions(customerId);

    setPayload({ salaryOptions });
    salaryOptions.length === 1 && updateData({ productId: salaryOptions[0].value });
  };

  const onDownload = async (formData: FormFields) => {
    const result = await onDownloadFile(formData);

    if (result) {
      setResult({ result, importType });
    }
  };

  const onChangeCustomer = setInitialingCb(async (customerId: number) => {
    updateData({ productId: null });
    await fetchSalaryDeal(customerId);
  });

  const onChangeImportType = setInitialingCb(async (importType: IMPORT_TYPES) => {
    if (checkSalaryType(importType)) {
      await fetchSalaryDeal(customerId);
    }
  });

  const onChangeIsMultiImport = (value: boolean) => {
    if (value) {
      currentCustomer.current = customerId;
      updateData({ [ImportDetailsFormFieldNames.CustomerId]: null });
    } else if (currentCustomer.current) {
      updateData({ [ImportDetailsFormFieldNames.CustomerId]: currentCustomer.current });
      currentCustomer.current = null;
    }
  };

  const isSalaryType = checkSalaryType(importType);

  const isDisableSave =
    deniedPrivilegeImportType(customerId, importType, allowedImportPrivilege) ||
    (isSalaryType && !productId) ||
    progress;

  const isAllowedMultiImport =
    IMPORT_TYPES.DOMESTIC_PAYMENTS === importType && chosenCustomers.length > 1;

  const handleResetData = () => {
    resetData();
    setResult({ result: null, importType: null });
  };

  const fileFormatOptions = getFormatOptions(importType ?? defaultImportType);

  React.useEffect(() => {
    updateData({ [ImportDetailsFormFieldNames.FileFormat]: fileFormatOptions[0].value });
  }, [importType]);

  React.useEffect(() => {
    if (customerId) {
      updateData({ [ImportDetailsFormFieldNames.ImportType]: defaultFileType });
    }
  }, [customerId]);

  return (
    <DefaultForm>
      <FormError>{error}</FormError>
      <CustomerSelect
        label="front.account-statements-filter-form.organization-number.label"
        name={ImportDetailsFormFieldNames.CustomerId}
        onSelectOption={onChangeCustomer}
        disabled={isMultiImport}
        required={!isMultiImport}
        placeholder={isMultiImport ? 'front.import.customer-select.multi-import.placeholder' : ''}
        privilege={allowedImportPrivilegeForSelect(defaultImportType)}
        selectDefaultCustomer
      />
      {isSalaryType && (
        <SelectField
          label="front.import.salary-deal.label"
          name={ImportDetailsFormFieldNames.ProductId}
          options={payload.salaryOptions}
          required
        />
      )}
      {isAllowedMultiImport && (
        <CheckboxField
          label="front.import.multi-import.label"
          name={ImportDetailsFormFieldNames.IsMultiImport}
          onChange={onChangeIsMultiImport}
        />
      )}
      <SelectField
        label="front.demand-import.fileType.label"
        name={ImportDetailsFormFieldNames.ImportType}
        defaultValue={defaultFileType}
        options={filteredFileTypeOptions}
        onSelectOption={onChangeImportType}
        disabled={isMultiImport}
        required
      />
      <Container>
        <Row className="payment-page__row">
          <Col md={6}>
            <SelectField
              label="front.demand-import.fileEncoding.label"
              name={ImportDetailsFormFieldNames.FileEncoding}
              defaultValue={fileEncodingOptions[0].value}
              options={fileEncodingOptions}
              required
            />
          </Col>
          <Col md={6}>
            <SelectField
              label="front.demand-import.fileFormat.label"
              name={ImportDetailsFormFieldNames.FileFormat}
              defaultValue={fileFormatOptions[0].value}
              options={fileFormatOptions}
              required
            />
          </Col>
        </Row>
      </Container>
      <FileField
        label="front.mail-modal.attach-files.label"
        name={ImportDetailsFormFieldNames.Orders}
        outputFormat={FileType.FILE}
        accept={{ presets: fileAccepts[fileFormat] || [] }}
        multiple={false}
        required
      />
      <Button
        color="primary"
        onClick={e => handleSubmit(onDownload, e)}
        size="md"
        disabled={isDisableSave}
      >
        {translate('front.demand-import.download.label')}
      </Button>
      <Button color="secondary" onClick={handleResetData} size="md">
        {translate('front.demand-import.clear.label')}
      </Button>
    </DefaultForm>
  );
};

export const ImportDetails = withForm<Props>(ImportDetailsForm);
