import moment from 'moment';

import { api } from 'api';
import { OrderType } from 'api/DemandsService/enums';
import {
  DemandResponse,
  DynamicField,
  Financial,
  PartialDemandRequest,
} from 'api/DemandsService/interfaces';
import { OrderKind, VatType } from 'api/enums';
import { Account, ExtraData } from 'api/interfaces';
import { Option } from 'api/Service';
import {
  getAmountNumberFormatWithComma,
  toAmountFormat,
  toAmountFormatWithDot,
} from 'components/utils/format';
import config from 'config';
import {
  PaymentOrderFields,
  PrepareRequestDataInternalPaymentInterface,
} from 'pages/Payments/InternalPayment/interface';
import {
  checkIsCustomerBudgetPurpose,
  createPurpose,
  getAccountValue,
  getCorrectVatPercent,
  getPreComputedFields,
  getUpdatedVatTypeAndVatPercent,
  hasAttributeTransactionAccount,
  isVisibleSubAccount,
  normalizePurposeVatTypeEmpty,
} from 'pages/Payments/InternalPayment/utils';
import { isExist } from 'utils/isData';

export const prepareRequestDataInternalPayment = async ({
  formData,
  pld,
  order,
  options,
}: PrepareRequestDataInternalPaymentInterface): Promise<PartialDemandRequest> => {
  const { receiverAccountOptions, payerAccountOptions, isoCode } = pld;
  const {
    orderDate,
    orderNumber,
    savePaymentPurpose,
    receiverAccount,
    payerAccount,
    vatType,
    isCustomBudgetPurpose,
    subAccount,
    vatPercent,
    payerNameF,
    payerTaxCodeF,
    receiverNameF,
    receiverTaxCodeF,
    budgetClassificationCode,
    additionalRecordInfo,
    taxIban,
    taxAmount,
    taxCurrency,
    taxNoticeInfo,
    taxNoticeType,
    additionalPaymentInfo,
    ...otherFormData
  } = formData;

  const paymentAccounts: Pick<
    Financial,
    'payerAccountId' | 'receiverIban' | 'receiverAccountId' | 'receiverAccount'
  > = {
    payerAccountId: +payerAccount,
    receiverIban: receiverAccount as string,
    receiverAccountId: undefined,
    receiverAccount: undefined,
  };

  const payerAccountOption = payerAccountOptions.find(i => +i.value === +payerAccount);

  if (payerAccountOption) {
    paymentAccounts.payerAccountId = +payerAccountOption.content.id;
  } else {
    paymentAccounts.payerAccountId = null;
  }

  let dynamicFields;
  let extraData = {} as ExtraData;

  if (
    order.type === OrderType.DomesticTransfer ||
    order.type === OrderType.DomesticTransferESCROU
  ) {
    if (formData.vatType) {
      if (formData.vatType !== VatType.EMPTY) {
        const params = {
          purpose: formData.purpose,
          vatType: formData.vatType,
          amount: formData.amount,
          vatPercent: formData.vatPercent,
          prevVatPercent: formData.vatPercent,
        };

        otherFormData.purpose = createPurpose(params);
      }

      if (formData.vatType === VatType.TAXES_PAYMENT) {
        extraData = {
          ...extraData,
          budgetClassificationCode,
          additionalRecordInfo,
          taxIban,
          taxAmount,
          taxCurrency,
          taxNoticeInfo,
          taxNoticeType,
        };
      }
    }

    paymentAccounts.receiverAccount = subAccount;

    const orderField = await api.payments.createOrderField(order.type);

    dynamicFields = [
      {
        data: [
          {
            orderFieldId: orderField.orderFieldModel[0].field.id,
            booleanValue: !!isCustomBudgetPurpose,
            isFieldApproved: true,
          },
        ],
      } as DynamicField,
    ];

    extraData = {
      ...extraData,
      payerNameF,
      payerTaxCodeF,
      receiverNameF,
      receiverTaxCodeF,
      additionalPaymentInfo,
    };
  }

  if (
    order.type === OrderType.InternalTransfer ||
    order.type === OrderType.InternalTransferESCROU
  ) {
    const receiverAccountOption = receiverAccountOptions.find(i => +i.value === +receiverAccount);
    if (receiverAccountOption) {
      const { number, id } = receiverAccountOption.content;
      paymentAccounts.receiverAccountId = +id;
      paymentAccounts.receiverAccount =
        `${subAccount}`.length > number.length ? subAccount : number;
    }
  }

  return {
    dynamicFields,
    financial: {
      ...otherFormData,
      ...paymentAccounts,
      vatPercent: toAmountFormatWithDot(vatPercent),
      isoCode,
      receiverAddCode:
        formData.receiverTaxCode === config.page.domesticPayment.receiverTaxCodeEmpty
          ? formData.receiverAddCode
          : undefined,
      currencyRate: 1,
      vatType: vatType || null,
    },
    base: {
      orderNumber,
      customerId: order.customerId,
      orderDate: moment(orderDate).format('YYYY-MM-DD'),
      orderType: order.type as OrderType,
      orderKind: options?.templateName ? OrderKind.TEMPLATE : undefined,
      orderAlias: options?.templateName,
      linkedOrderType: order.linkedOrderType,
      extraData,
    },
  };
};

interface PrepareFieldsInternalPaymentInterface {
  doc: DemandResponse;
  isDraft: boolean;
  isNew?: boolean;
  payerAccounts?: Option<Account>[];
  receiverAccounts?: Option<Account>[];
}

// If account options are passed then need to check order draft
// whether the payer and/or receiver account ids from doc are contained in these options
export const prepareFieldsInternalPayment = ({
  doc,
  isDraft,
  payerAccounts,
  receiverAccounts,
  isNew = false,
}: PrepareFieldsInternalPaymentInterface): Partial<PaymentOrderFields> => {
  const {
    dynamicFields,
    base: { orderType, extraData },
    financial: {
      vatType,
      vatPercent,
      amount,
      receiverAddCode,
      purpose,
      payerAccountId,
      payerIban,
      payerAccount: payerAccountNumber,
      isoCode,
      receiverIban,
      receiverAccountId,
      receiverAccount: receiverAccountNumber,
      payerName,
      payerTaxCode,
      payerBic,
      payerBankName,
      receiverName,
      receiverTaxCode,
      receiverBic,
      receiverBankName,
      receiverCountryId,
    },
  } = doc;

  const isDomestic =
    orderType === OrderType.DomesticTransfer || orderType === OrderType.DomesticTransferESCROU;
  const isInternal =
    orderType === OrderType.InternalTransfer || orderType === OrderType.InternalTransferESCROU;

  const isCustomBudgetPurpose = checkIsCustomerBudgetPurpose(dynamicFields);

  const { vatType: correctVatType, vatPercent: newVatPercent } = getUpdatedVatTypeAndVatPercent(
    vatType,
    vatPercent,
    isNew,
  );

  let correctPurpose = purpose;
  let correctVatPercent = newVatPercent;
  let isActualRecipient = false;
  let isActualPayer = false;

  if (isDomestic) {
    if (correctVatType === VatType.EMPTY && !isCustomBudgetPurpose) {
      correctPurpose = normalizePurposeVatTypeEmpty(purpose, payerTaxCode);
    }

    if (correctVatType === VatType.INCLUDE_VAT20) {
      correctVatPercent = getCorrectVatPercent(newVatPercent);

      if (isDraft) {
        correctPurpose = createPurpose({
          vatPercent: getAmountNumberFormatWithComma(newVatPercent),
          prevVatPercent: vatPercent,
          purpose,
          amount,
          vatType: correctVatType,
        });
      }
    }

    isActualPayer = isExist(extraData?.payerNameF) || isExist(extraData?.payerTaxCodeF);
    isActualRecipient = isExist(extraData?.receiverNameF) || isExist(extraData?.receiverTaxCodeF);
  }

  const payerBicValue = payerBic || config.page.internalPayment.defaultBic;
  const payerBankNameValue = payerBankName || config.page.internalPayment.defaultBankName;

  let payerAccount;
  if (isDraft) {
    payerAccount = payerAccounts ? getAccountValue(payerAccountId, payerAccounts) : payerAccountId;
  } else {
    payerAccount = payerIban || payerAccountNumber;
  }

  const subAccount =
    hasAttributeTransactionAccount(`${receiverAccountNumber}`) && isVisibleSubAccount(receiverIban)
      ? receiverAccountNumber
      : '';

  let receiverAccount: number | string;
  if (isDraft && isInternal) {
    receiverAccount = receiverAccounts
      ? getAccountValue(receiverAccountId, receiverAccounts)
      : receiverAccountId;
  } else {
    receiverAccount = receiverIban || receiverAccountNumber;
  }

  const precomputedFields = isDomestic ? getPreComputedFields(doc) : null;

  return {
    amount,
    receiverAddCode,
    payerName,
    payerTaxCode,
    receiverName,
    receiverTaxCode,
    receiverBic,
    receiverBankName,
    receiverCountryId,
    isoCode,
    isCustomBudgetPurpose, // for Domestic transfer only
    vatType: correctVatType, // for Domestic transfer only
    payerBankName: payerBankNameValue,
    payerBic: payerBicValue,
    purpose: correctPurpose,
    payerAccount,
    receiverAccount,
    subAccount,
    vatPercent: correctVatPercent,
    isActualPayer,
    isActualRecipient,
    ...extraData,
    taxAmount: extraData?.taxAmount && toAmountFormat(extraData.taxAmount),
    // budgetCode: doc.budgetCode, // for Domestic transfer only
    ...precomputedFields,
  };
};
