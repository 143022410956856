import * as React from 'react';
import { Col, Row } from 'react-grid';

import { DepositProduct, DepositView } from 'api/ProductsService';
import * as format from 'components/utils/format';
import { translate } from 'i18n/translator';

export enum ParamType {
  MAIN = 'MAIN',
  MAIN_BASE = 'MAIN_BASE',
  RETURN = 'RETURN',
  PERCENT = 'PERCENT',
}

const DEPOSIT_TRANCHE = 'DEPOSIT_TRANCHE';
const GENERAL_DEPOSIT = 'GENERAL_DEPOSIT';

const getParamValue = ({ params }: DepositProduct, types: ParamType | ParamType[]) =>
  params.find(({ type }) =>
    Array.isArray(types) ? types.includes(type as ParamType) : type === types,
  )?.value || '';

const DetailColumn = ({ label = '', value = '' }) => (
  <Col md={6} className="deposit-details__col">
    <Row>
      <Col sm={4} className="deposit-details__col__label">
        {translate(label)}
      </Col>
      <Col sm={8} className="deposit-details__col__value">
        {value}
      </Col>
    </Row>
  </Col>
);

export const DepositDetails = ({ row: { product, deposit } }: { row: DepositView }) => (
  <Row>
    <DetailColumn label={'front.deposits-page.togglerow-product-code.label'} value={product.code} />
    <DetailColumn
      label={'front.product-accounts-togglerow.currency.label'}
      value={product.currency}
    />
    <DetailColumn
      label={'front.product-accounts-togglerow.customer-name.label'}
      value={product.organization}
    />
    <DetailColumn label={'front.deposits-page.togglerow-name.label'} value={product.name} />
    <DetailColumn
      label={'front.deposits-page.togglerow-deposit-type.label'}
      value={product.subTypeName}
    />
    <DetailColumn
      label={'front.deposits-page.togglerow-contract-status.label'}
      value={product.statusName}
    />
    <DetailColumn
      label={'front.working-documents-table.account-number.label'}
      value={getParamValue(product, [ParamType.MAIN, ParamType.MAIN_BASE])}
    />
    <DetailColumn
      label={'front.deposits-page.togglerow-deposit-sum.label'}
      value={format.getAmount(deposit.contractAmount)}
    />
    <DetailColumn
      label={'front.deposits-page.togglerow-contract-date.label'}
      value={format.date(product.opened)}
    />
    <DetailColumn
      label={'front.deposits-page.togglerow-interest-rate.label'}
      value={product.subType !== GENERAL_DEPOSIT && `${format.getAmount(deposit.percent)} %`}
    />
    <DetailColumn
      label={'front.deposits-page.togglerow-end-date.label'}
      value={format.date(product.closed)}
    />
    <DetailColumn
      label={'front.deposits-page.togglerow-bank-branch.label'}
      value={product.bankBranchName}
    />
    {product.subType === DEPOSIT_TRANCHE && (
      <DetailColumn
        label={'front.product-accounts-togglerow.balance.label'}
        value={format.getAmount(product.amount)}
      />
    )}
    {product.subType === DEPOSIT_TRANCHE && (
      <DetailColumn
        label={'front.deposits-page.togglerow-refund-payment.label'}
        value={getParamValue(product, ParamType.RETURN)}
      />
    )}
    {product.subType === DEPOSIT_TRANCHE && (
      <DetailColumn
        label={'front.deposits-page.togglerow-payment-check.label'}
        value={getParamValue(product, ParamType.PERCENT)}
      />
    )}
  </Row>
);
