import React from 'react';

import { DynamicFieldValue } from 'api/DemandsService/interfaces';
import { Option } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { DateField } from 'components/forms/inputs/DateField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextAreaField } from 'components/forms/inputs/TextAreaField';
import { useDisabledCustomer } from 'hooks/useDisabledCustomer';
import { translate } from 'i18n/translator';
import { CreditProlongationDetailsName } from 'pages/Demands/CreditDemand/enums';
import { today } from 'pages/Demands/utils';

interface CreditFundsFields {
  [CreditProlongationDetailsName.Customer]: string;
  [CreditProlongationDetailsName.Credit]: string;
  [CreditProlongationDetailsName.Amount]: number;
  [CreditProlongationDetailsName.FromDate]: Date;
  [CreditProlongationDetailsName.Account]: string;
  [CreditProlongationDetailsName.Reason]: string;
}

interface CreditFundsPayload {
  [CreditProlongationDetailsName.Credit]: Option<DynamicFieldValue>[];
  [CreditProlongationDetailsName.Account]: Option<DynamicFieldValue>[];
}

export const CreditFundsDetail = () => {
  const {
    order,
    payload: { credit, account },
    onChangeCustomer,
  } = useOrderDetails<CreditFundsFields, CreditFundsPayload>();

  const isDisabledCustomer = useDisabledCustomer(order);

  return (
    <DefaultForm>
      <h5>{translate('front.credit-demand.titleLabel.funds.label')}</h5>
      <CustomerSelect
        name={CreditProlongationDetailsName.Customer}
        label="front.credit-funds.organization.label"
        onSelectOption={onChangeCustomer}
        selectDefaultCustomer
        disabled={isDisabledCustomer}
        required
      />
      <SelectField
        name={CreditProlongationDetailsName.Credit}
        label="front.credit-prolongation.credit.label"
        options={credit}
        required
      />
      <AmountField
        name={CreditProlongationDetailsName.Amount}
        label="front.credit-funds.amount.label"
        maxLengthWithoutZero={12}
        minAmount={0.01}
        hasMoneyFormat
        thousandSeparator={' '}
        required
      />
      <DateField
        name={CreditProlongationDetailsName.FromDate}
        label="front.credit-funds.date-of-issue.field.label"
        initialDate={today}
        required
      />
      <SelectField
        name={CreditProlongationDetailsName.Account}
        label="front.credit-funds.account.label"
        options={account}
        required
      />
      <TextAreaField
        name={CreditProlongationDetailsName.Reason}
        label="front.credit-funds.reason.label"
        rows={3}
      />
    </DefaultForm>
  );
};
