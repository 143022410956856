import React from 'react';
import { GridResponse, TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { DemandType } from 'api/DemandsService/enums';
import { OrderResponseV5 } from 'api/DocumentManagementService/interfaces';
import { OrderActionType } from 'api/enums';
import { Instruction } from 'api/HandbooksService';
import { Option, SortOrder } from 'api/Service';
import { Page } from 'components/layout/Page/Page';
import { Status } from 'components/layout/Status';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import * as format from 'components/utils/format';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { ExtraFields, FilterFields } from 'pages/DocumentManagement/interfaces';
import { getTranslateAction } from 'pages/Payments/general';
import { signKepCertificateOrders } from 'pages/utils/SignOrders/signOrders';

import RejectDocumentModal from './components/RejectDocumentModal/RejectDocumentModal';
import { DocumentsManagementFilter } from './DocumentsManagementFilter';
import { useCategoryAndType } from './hooks/useCategoryAndType';
import {
  getCustomerIdsForSign,
  getDocumentManagementStatusColor,
  getName,
  getStatus,
  isVisibleActionForV5,
  labelBankStatus,
  labelCustomerStatus,
  prepareFilterForFetch,
} from './utils';

import './styles.scss';

const fetchRows = async (
  filter: FilterFields & ExtraFields,
): Promise<GridResponse<OrderResponseV5>> =>
  await api.documentManagement.getOrdersV5({
    ...prepareFilterForFetch(filter),
    orderType: DemandType.DocumentManagement,
  });

const getColumns = (
  categories: Option<Instruction>[] = [],
  types: Option<Instruction>[] = [],
): TableColumnProps<OrderResponseV5>[] => [
  {
    label: 'front.demands-table.date.label',
    render: row => (
      <DataColumn
        title={format.date(row.base.updated)}
        subTitle={format.fullTime(row.base.updated)}
      />
    ),
    sort: 'changed',
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.demands-table.number.label',
    render: row => <DataColumn title={row.base.number} />,
    sort: 'orderNumberNumeric',
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.demands-table.organization.label',
    render: row => (
      <div className="column-wrapper">
        {row.customers.map(({ customerName }) => (
          <DataColumn title={customerName} key={customerName} />
        ))}
      </div>
    ),
    sort: 'customer_sort.name',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.document-management.category.label',
    render: row => <DataColumn title={getName(`${row.base.extraData.categoryId}`, categories)} />,
    sort: 'dict_category.nameMl',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.document-management.document-type.label',
    render: row => <DataColumn titleWrap title={getName(`${row.base.extraData.typeId}`, types)} />,
    sort: 'dict_type.nameMl',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: labelBankStatus,
    render: row => {
      const { state, translate } = getStatus(row.base);

      return (
        <DataColumn>
          <Status color={getDocumentManagementStatusColor(state)}>{translate}</Status>
        </DataColumn>
      );
    },
    sort: 'orderState.orderStateName',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: labelCustomerStatus,
    render: row => {
      const { state, translate } = getStatus(row.base, true);

      return (
        <DataColumn>
          <Status color={getDocumentManagementStatusColor(state)}>{translate}</Status>
        </DataColumn>
      );
    },
    sort: 'stateCustomer',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },

  {
    renderMenu: order => [
      {
        label: getTranslateAction(OrderActionType.SIGN),
        onClick: async () =>
          await signKepCertificateOrders([
            {
              id: order.id,
              customerIds: getCustomerIdsForSign(order.customers, order.actions),
            },
          ]),
        isVisible: isVisibleActionForV5(order, OrderActionType.SIGN),
        isReloadable: true,
      },
      {
        label: getTranslateAction(OrderActionType.REJECT),
        onClick: async order => {
          await confirmModal<boolean>(RejectDocumentModal, { orderId: order.id }, 420);
        },
        isVisible: isVisibleActionForV5(order, OrderActionType.REJECT),
        isReloadable: true,
      },
      // TODO uncomment when bank payed
      // {
      //   label: getTranslateAction(OrderActionType.PRINT),
      //   onClick: () => api.payments.getOrderPdf(order.id),
      //   isVisible: isVisibleActionForV5(order, OrderActionType.PRINT),
      // },
      {
        label: getTranslateAction(OrderActionType.EXPORT_ATTACHMENTS),
        onClick: async () => {
          for (const { dataId, signed } of order.dynamic.attach) {
            await api.demands.downloadDemandFile(dataId);
            signed && (await api.demands.downloadDemandFile(dataId, true));
          }
        },
      },
    ],
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];
// TODO uncomment when bank payed
// const actions: Action<OrderResponseV5>[] = [
//   {
//     label: 'front.working-documents-table.actions-sign.label',
//     action: async (selected: OrderResponseV5[]) => {
//       const orders = selected
//         .filter(order => isVisibleActionForV5(order, OrderActionType.SIGN))
//         .map(order => ({
//           id: order.id,
//           customerIds: getCustomerIdsForSign(order.customers, order.actions),
//         }));
//
//       await signKepCertificateOrders(orders, selected.length);
//     },
//     isReloadable: true,
//     isVisible: (selected: OrderResponseV5[]) => {
//       const orders = selected.filter(order => isVisibleActionForV5(order, OrderActionType.SIGN));
//       return !!orders.length;
//     },
//   },
// ];

export const DocumentsManagementPage = () => {
  const [categories, types] = useCategoryAndType();

  return (
    <Page title="front.document-management.signing-protocols.label">
      <DataTable
        name={TableNamesEnum.DocumentManagement}
        columns={getColumns(categories, types)}
        // TODO uncomment when bank payed
        // actions={actions}
        fetchRows={fetchRows}
        defaultSort="changed"
        defaultOrder={SortOrder.Desc}
        filterComponent={DocumentsManagementFilter}
        onRowClick={row => goto(pages.documentManagement.tabs.details(row.id))}
      />
    </Page>
  );
};
