import React from 'react';
import { useLocation } from 'react-router';

import moment from 'moment';

import { parseUrlParams } from 'api/backend';
import { DemandType } from 'api/DemandsService/enums';
import { OrderState } from 'api/enums';
import { Option } from 'api/Service';
import { CustomerTypeEnum } from 'api/UserService';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { DateRangeField } from 'components/forms/inputs/DateRangeField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextField } from 'components/forms/inputs/TextField';
import { translate } from 'i18n/translator';
import { DepositDetailNames } from 'pages/Demands/DepositDemand/enums';
import { useCheckRelevanceDeposit } from 'pages/Demands/hooks/useCheckRelevanceDeposit';
import { disabledDaysBeforeToday, getOption, today } from 'pages/Demands/utils';
import { usePrevious } from 'utils/hooks';
import { isExist } from 'utils/isData';

import { AccountPaymentInterest } from './AccountPaymentInterest';
import { PaymentDepositAmount } from './PaymentDepositAmount';
import { PlaceFundsDeposit } from './PlaceFundsDeposit';

import 'pages/Demands/DepositDemand/styles.scss';

interface NewContractTermDepositFields {
  channelTypeSend?: string;
  depositCurrency?: string;
  depositType?: string;
  doSend?: boolean;
  label3?: boolean;
  label4?: boolean;
  label5?: boolean;
  radioAmountAccTerm?: string;
  radioInterestAccTerm?: string;
  radioPlacementAccTerm?: string;
  retentionPeriodFrom?: Date;
}

export const [
  labelProlongationAllowed,
  labelProlongationNotAllowed,
  labelReplenishmentAllowed,
  labelReplenishmentNotAllowed,
] = [
  'front.new-contract-term-deposit-demand.label-prolongation-allowed.label',
  'front.new-contract-term-deposit-demand.label-prolongation-not-allowed.label',
  'front.new-contract-term-deposit-demand.label-replenishment-allowed.label',
  'front.new-contract-term-deposit-demand.label-replenishment-not-allowed.label',
].map(item => translate(item));

export const NewContractTermDepositDetail = ({ type }: { type: DemandType | CustomerTypeEnum }) => {
  const {
    order,
    onChangeCustomer,
    payload: {
      depositType: depositTypes,
      depositCurrency: depositCurrencies,
      periodInterestPayment,
      radioPlacementAccTerm: radioPlacementAccTermOptions,
      placementOwnAccountTerm,
      radioInterestAccTerm: radioInterestAccTermOptions,
      radioAmountAccTerm: radioAmountAccTermOptions,
      channelTypeSend,
      dynamicFields,
    },
    form: { updateData, getFormData },
  } = useOrderDetails();

  const {
    depositType,
    retentionPeriodFrom,
    label3: isCheckPlaceFundsDeposit,
    radioPlacementAccTerm,
    label4: isCheckAccountPaymentInterest,
    radioInterestAccTerm,
    label5: isCheckedPaymentDepositAmount,
    radioAmountAccTerm,
    doSend,
    channelTypeSend: channelTypeSendValue,
    depositCurrency,
  }: NewContractTermDepositFields = getFormData();

  const { copyFrom } = parseUrlParams(useLocation().search);

  const isDisabledCustomer = !!order?.state || !!copyFrom;

  const hasDepositType = isExist(depositType);
  const isFop = type === CustomerTypeEnum.IndividualEmployer;
  const currentDepositType = getOption(depositType, depositTypes)?.content;

  const prevDepositTypeId = usePrevious(depositType);
  const hasPrevDepositType = isExist(prevDepositTypeId);

  const filteredDepositCurrencies = React.useMemo(
    () =>
      hasDepositType
        ? depositCurrencies.filter(
            (deposit: Option) => deposit.content.info === currentDepositType.info,
          )
        : [],
    [currentDepositType, depositCurrencies],
  );

  const filteredPeriodOptions = React.useMemo(
    () =>
      hasDepositType
        ? periodInterestPayment.filter(
            (option: Option) => option.content.info === currentDepositType.info,
          )
        : [],
    [periodInterestPayment, currentDepositType],
  );

  const currentCurrency = React.useMemo(
    () =>
      hasDepositType
        ? depositCurrencies.find((currency: Option) => currency.content.value === depositCurrency)
            ?.content.params
        : [],
    [depositCurrencies, depositCurrency],
  );

  const isProlongationAllowed = hasDepositType && currentDepositType?.params.isExtensionAllowed;
  const isReplenishmentAllowed =
    hasDepositType && currentDepositType?.params.isReplenishmentAllowed;

  const isSendEmail =
    getOption(channelTypeSendValue, channelTypeSend)?.content.value ===
    DepositDetailNames.ChannelEmail;

  const disabledDaysTo = React.useMemo(
    () =>
      isExist(retentionPeriodFrom)
        ? {
            before: retentionPeriodFrom,
            after: moment(retentionPeriodFrom)
              .add('days', +currentDepositType?.params.dateMax)
              .startOf('date')
              .toDate(),
          }
        : null,
    [retentionPeriodFrom],
  );

  React.useEffect(() => {
    if (!order?.state && hasDepositType) {
      return updateData({
        retentionPeriodFrom: today,
        retentionPeriodTo: moment()
          .add('days', +currentDepositType.params.dateMax)
          .startOf('date')
          .toDate(),
      });
    }

    if (order?.state === OrderState.Draft && hasPrevDepositType) {
      updateData({
        retentionPeriodFrom: null,
        retentionPeriodTo: null,
      });
    }
  }, [depositType]);

  React.useEffect(() => {
    if (hasPrevDepositType) {
      updateData({
        depositCurrency: null,
        periodInterestPayment: null,
        placementOwnAccountTerm: null,
        amountOwnAccountTerm: null,
        interestOwnAccountTerm: null,
      });
    }
  }, [depositType]);

  useCheckRelevanceDeposit(DepositDetailNames.DepositType, depositTypes, dynamicFields);

  const filteredAccounts = React.useMemo(() => {
    const hasCurrency = isExist(depositCurrency);

    return hasCurrency
      ? placementOwnAccountTerm.filter((option: Option) => option.content.info === depositCurrency)
      : placementOwnAccountTerm;
  }, [depositCurrency]);

  return (
    <DefaultForm>
      <CustomerSelect
        name={DepositDetailNames.Customer}
        label="front.deposit-demand.organization.label"
        onSelectOption={onChangeCustomer}
        selectDefaultCustomer
        disabled={isDisabledCustomer}
        required
      />
      <SelectField
        name={DepositDetailNames.DepositType}
        label="front.new-contract-term-deposit-demand.depositType.label"
        options={depositTypes}
        required
      />
      <SelectField
        name={DepositDetailNames.DepositCurrency}
        label="front.new-contract-term-deposit-demand.depositCurrency.label"
        options={filteredDepositCurrencies}
        disabled={!hasDepositType}
        required
      />
      <DateRangeField
        nameFrom={DepositDetailNames.RetentionPeriodFrom}
        nameTo={DepositDetailNames.RetentionPeriodTo}
        label="front.new-placement-tranche-deposit-demand.retention-period.label"
        disabledDaysFrom={disabledDaysBeforeToday}
        disabledDaysTo={disabledDaysTo}
        isOnlyRangePicker
        disabled={!hasDepositType}
        required={hasDepositType}
        clearable
      />
      <AmountField
        name={DepositDetailNames.Amount}
        label="front.new-contract-term-deposit-demand.amount.label"
        maxLengthWithoutZero={12}
        minAmount={currentCurrency?.min}
        maxAmount={currentCurrency?.max}
        disabled={!hasDepositType}
        required={hasDepositType}
      />
      <h6 className="dynamic-input__title">
        {translate('front.new-contract-term-deposit-demand.toggle-row-interest-rate.label')}
      </h6>
      <SelectField
        name={DepositDetailNames.PeriodInterestPayment}
        label="front.new-placement-tranche-deposit-demand.period-interest-payment.label"
        options={filteredPeriodOptions}
        disabled={!hasDepositType}
        required={hasDepositType}
      />
      <CheckboxField
        name={DepositDetailNames.CheckPlaceFundsDeposit}
        label="front.new-contract-term-deposit-demand.place-funds-deposit.label"
        required
      />
      {isCheckPlaceFundsDeposit && (
        <PlaceFundsDeposit
          radioPlacementAccTerm={radioPlacementAccTerm}
          radioPlacementAccTermOptions={radioPlacementAccTermOptions}
          filteredAccounts={filteredAccounts}
        />
      )}
      <CheckboxField
        name={DepositDetailNames.CheckAccountPaymentInterest}
        label="front.new-contract-term-deposit-demand.account-payment-interest.label"
        required
      />
      {isCheckAccountPaymentInterest && (
        <AccountPaymentInterest
          radioInterestAccTerm={radioInterestAccTerm}
          radioInterestAccTermOptions={radioInterestAccTermOptions}
          filteredAccounts={filteredAccounts}
        />
      )}
      <CheckboxField
        name={DepositDetailNames.CheckedPaymentDepositAmount}
        label="front.new-contract-term-deposit-demand.payment-deposit-amount.label"
        required
      />
      {isCheckedPaymentDepositAmount && (
        <PaymentDepositAmount
          radioAmountAccTerm={radioAmountAccTerm}
          radioAmountAccTermOptions={radioAmountAccTermOptions}
          filteredAccounts={filteredAccounts}
        />
      )}
      {hasDepositType && (
        <>
          <h6 className="dynamic-input__title">
            {isProlongationAllowed ? labelProlongationAllowed : labelProlongationNotAllowed}
          </h6>
          <h6 className="dynamic-input__title">
            {isReplenishmentAllowed ? labelReplenishmentAllowed : labelReplenishmentNotAllowed}
          </h6>
        </>
      )}
      <h6 className="dynamic-input__title">
        {translate(
          'front.new-contract-term-deposit-demand.label-return-deposit-not-provided.label',
        )}
      </h6>
      <CheckboxField
        name={DepositDetailNames.DoSelectedContribution}
        label="front.new-contract-term-deposit-demand.label-do-selected-contribution.label"
        required
      />
      {isFop && (
        <CheckboxField
          name={DepositDetailNames.DoConditions}
          label="front.new-contract-term-deposit-demand.label-do-conditions.label"
          required
        />
      )}
      <CheckboxField
        name={DepositDetailNames.DoUnderstanding}
        label="front.new-contract-term-deposit-demand.label-do-understanding.label"
        required
      />
      <CheckboxField
        name={DepositDetailNames.DoAcceptance}
        label="front.new-contract-term-deposit-demand.label-do-acceptance.label"
        required
      />
      <CheckboxField
        name={DepositDetailNames.DoReliableInfo}
        label="front.new-contract-term-deposit-demand.label-do-reliableInfo.label"
        required
      />
      <CheckboxField
        name={DepositDetailNames.DoInformationLaw}
        label="front.new-contract-term-deposit-demand.label-do-informationLaw.label"
        required
      />
      <CheckboxField
        name={DepositDetailNames.DoSend}
        label="front.new-contract-term-deposit-demand.label-do-send.label"
        required
      />
      {doSend && (
        <SelectField
          name={DepositDetailNames.ChannelTypeSend}
          label="front.new-contract-term-deposit-demand.label-channel-typeSend.label"
          options={channelTypeSend}
          required
        />
      )}
      {isSendEmail && (
        <TextField
          name={DepositDetailNames.ChannelEmail}
          label="front.new-contract-term-deposit-demand.label-channel-email.label"
          email
          required
        />
      )}
    </DefaultForm>
  );
};
