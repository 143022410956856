import * as React from 'react';
import { Action, TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';
import moment from 'moment';

import { api } from 'api';
import { DemandResponse } from 'api/DemandsService/interfaces';
import { OrderActionType, TransactionHistoryEnum, TransactionHistoryStatus } from 'api/enums';
import { ExportType } from 'api/ExportService';
import { Transaction, TransactionsFilters } from 'api/interfaces';
import { getTotal, SortOrder } from 'api/Service';
import { ExportFormatEnum } from 'components/ExportDropdown/enum';
import { withForm } from 'components/forms/withForm';
import { Page } from 'components/layout/Page/Page';
import { confirmationModal } from 'components/modals/ConfirmModal';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { DEFAULT_WIDTH, PrintingDocuments } from 'components/PrintingDocuments/PrintingDocuments';
import { ActionComponentType } from 'components/react-data-table/types';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable, useFilter } from 'components/Table/DataTable';
import { TotalCurrency } from 'components/TotalCurrency/TotalCurrency';
import * as format from 'components/utils/format';
import config from 'config';
import { translate } from 'i18n/translator';
import { isActionAllowed } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { MAX_VISIBLE_TABLE_ROWS } from 'pages/AccountStatements/utils';
import { getTranslateAction } from 'pages/Payments/general';
import { getCurrencyAmount } from 'pages/Products/Accounts/utils';
import { ExtraFields } from 'pages/TransactionsHistory/interfaces';
import { isExist } from 'utils/isData';

import { TransactionsHistoryFilter } from './TransactionsHistoryFilter';

const DOCUMENT_NUMBER = 'documentNumber';
const EXECUTED = 'executed';
const { nationalCurrency: UAH } = config.bank;
const yesterday = moment().add(-1, 'days').toDate();
const REVELATION_AMOUNT = 0;

export const isVisibleCopy = (row: Transaction) =>
  isActionAllowed(
    [Privileges.paymentInternalEdit, Privileges.paymentDomesticEdit],
    row.customerId,
  ) &&
  row.currency === UAH &&
  row.type === TransactionHistoryEnum.OUT &&
  row.amount !== REVELATION_AMOUNT;

export const isVisibleReply = (row: Transaction): boolean => {
  const isNationalCurrency = row.currency === UAH;
  const isTypeIn = row.type === TransactionHistoryEnum.IN;
  const isBinEqual = row.payerBin === row.receiverBin;
  const isWithoutRevelation = row.amount !== REVELATION_AMOUNT;

  const hasPrivilege = isActionAllowed(
    [Privileges.paymentInternalEdit, Privileges.paymentDomesticEdit],
    row.customerId,
  );

  return isTypeIn && hasPrivilege && isWithoutRevelation && (isNationalCurrency || isBinEqual);
};

const columns: TableColumnProps<Transaction>[] = [
  {
    label: 'front.transactions-history-page.cols-number.label',
    render: row => <DataColumn title={row.documentNumber} />,
    sort: `${DOCUMENT_NUMBER}, ${EXECUTED}`,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.transactions-history-page.date.label',
    render: row => (
      <DataColumn
        title={format.date(row.executionTime)}
        subTitle={format.fullTime(row.executionTime)}
      />
    ),
    sort: EXECUTED,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.transactions-history-page.cols-type.label',
    render: row => {
      const type =
        row.type === TransactionHistoryEnum.IN
          ? 'front.transactions-history-page.render-type-earnings.label'
          : 'front.transactions-history-page.render-type-write-off.label';
      return (
        <DataColumn
          title={translate(type)}
          subTitle={
            row.status === TransactionHistoryStatus.INFO
              ? `(${translate('front.transactions-history-page.render-type-info.label')})`
              : null
          }
        />
      );
    },
    sort: 'amountNc',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.transactions-history-page.cols-counterparty.label',
    render: row => {
      const isIncoming = row.type.toLowerCase() === TransactionHistoryEnum.IN;
      return (
        <DataColumn
          title={isIncoming ? row.payer : row.receiver}
          subTitle={isIncoming ? row.payerIban : row.receiverIban}
        />
      );
    },
    sort: 'correspondentName',
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.transactions-history-page.cols-sum.label',
    render: row => (
      <DataColumn
        title={`${format.getAmount(Math.abs(row.amount !== 0 ? row.amount : row.amountNational))} ${
          row.currency
        }`}
      />
    ),
    sort: 'amountThAbs',
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.transactions-history-page.cols-purpose-of-payment.label',
    render: row => <DataColumn title={row.paymentPurpose} />,
    sort: 'details',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.internal-payment-page.actual-payer.label',
    render: row => <DataColumn title={row.actualPayerName} />,
    sort: ['productTransaction.extraData#payer#name'],
    showDesktop: false,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.internal-payment-page.actual-payer-code.label',
    render: row => <DataColumn title={row.actualPayerTaxCode} />,
    sort: ['productTransaction.extraData#payer#taxCode'],
    showDesktop: false,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.internal-payment-page.actual-recipient.label',
    render: row => <DataColumn title={row.actualReceiverName} />,
    sort: ['productTransaction.extraData#receiver#name'],
    showDesktop: false,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.internal-payment-page.actual-recipient-code.label',
    render: row => <DataColumn title={row.actualReceiverTaxCode} />,
    sort: ['productTransaction.extraData#receiver#taxCode'],
    showDesktop: false,
    showTablet: false,
    showMobile: false,
  },
  {
    renderMenu: row => [
      {
        label: 'front.transaction-history-page.menu-view.label',
        onClick: () => goto(pages.transactionHistory.details(row.id)),
      },
      {
        label: 'front.account-statements-table.reply-by-transfer.label',
        onClick: async () => {
          try {
            const orderId = await api.payments.createPaymentFromTransaction(row.id);
            goto(pages.internalPayments.tabs.details(orderId));
          } catch (error: any) {
            await confirmationModal(JSON.parse(error.message).message, false, false);
          }
        },
        isVisible: isVisibleReply(row),
      },
      {
        label: 'front.transaction-history-page.menu-print.label',
        onClick: () =>
          api.export.exportByCode(ExportType.ACCOUNT_TRANSACTIONS, {
            format: ExportFormatEnum.PDF,
            ids: [row.id],
          }),
      },
      {
        label: 'front.transaction-history-page.menu-copy.label',
        onClick: async () => {
          const orderId = await api.payments.createPaymentFromTransaction(row.id);
          goto(pages.internalPayments.tabs.details(orderId));
        },
        isVisible: isVisibleCopy(row),
      },
    ],
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

const actions: Action[] = [
  {
    label: getTranslateAction(OrderActionType.PRINT),
    action: async data =>
      await confirmModal(
        PrintingDocuments,
        {
          objectCode: ExportType.ACCOUNT_TRANSACTIONS,
          data,
        },
        DEFAULT_WIDTH,
      ),
    color: 'secondary',
  },
];

const ActionsComponent: ActionComponentType<DemandResponse> = withForm(
  ({ total, selectedItems, children }) => {
    const { extraFields } = useFilter<unknown, ExtraFields>();

    const isAllOrNotExistType =
      extraFields.transactionType === 'all' || !isExist(extraFields.transactionType);

    return (
      <TotalCurrency
        total={total}
        selectedItems={selectedItems}
        getCurrencyAmount={getCurrencyAmount}
        isShowAmount={!isAllOrNotExistType}
      >
        {children}
      </TotalCurrency>
    );
  },
);

const fetchTransactions = async (request: TransactionsFilters) => {
  const { shouldUseIntegration, ...otherRequest } = request;

  const { rows, total } = await api.payments.getTransactionHistory({
    ...otherRequest,
    sort: request.sort,
    dateFrom: request.dateFrom ? request.dateFrom : yesterday,
    withCurrencyTotals: true,
    withSync: shouldUseIntegration,
  });

  return {
    rows,
    total: getTotal(total),
  };
};

export const TransactionsHistoryPage = () => (
  <Page title={translate('front.transactions-history-page.title.label')}>
    <DataTable
      name={TableNamesEnum.TransactionsHistoryTable}
      columns={columns}
      actions={actions}
      fetchRows={fetchTransactions}
      defaultSort={EXECUTED}
      defaultOrder={SortOrder.Desc}
      filterComponent={TransactionsHistoryFilter}
      actionsComponent={ActionsComponent}
      onRowClick={order => goto(pages.transactionHistory.details(order.id))}
      maxVisibleTableRows={MAX_VISIBLE_TABLE_ROWS}
    />
  </Page>
);
