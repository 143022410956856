import React from 'react';
import { Col, Row } from 'react-grid';

import moment from 'moment';

import { Option } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { DateRangeField } from 'components/forms/inputs/DateRangeField';
import { withForm } from 'components/forms/withForm';
import { SearchInput } from 'components/searchInput/SearchInput';
import { CreateButton } from 'components/Table/CreateButton';
import { useFilter } from 'components/Table/DataTable';
import { ToggleFilterButton } from 'components/Table/ToggleFilterButton/ToggleFilterButton';
import { FilterTabBar } from 'components/tabs/FilterTabBar';
import { translate } from 'i18n/translator';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';

const getLetterOptionStatuses = (): Array<Option> => {
  return [
    {
      value: 'all',
      label: 'front.mails-table-filters.all.label',
    },
    {
      value: 'in',
      label: 'front.mails-table-filters.incoming.label',
    },
    {
      value: 'out',
      label: 'front.mails-table-filters.outgoing.label',
    },
  ];
};

const MailPageFilterForm = () => {
  const { updateFilter, extraFields, setPagination } = useFilter({
    extraFields: { type: 'all', text: undefined },
  });

  return (
    <div>
      <FilterTabBar
        onChange={type => updateFilter({ type })}
        value={extraFields.type}
        options={getLetterOptionStatuses()}
        setPagination={setPagination}
      />
      <Row>
        <Col lg={5} md={3}>
          <CreateButton
            onClick={() => {
              goto(pages.message('new'));
            }}
            title="front.mails-table.create-letter.label"
          />
        </Col>
        <Col lg={7}>
          <Row>
            <Col lg={8} md={8}>
              <SearchInput
                placeholder={translate('front.working-documents-table.filter-search.label')}
                value={extraFields.text}
                onChange={text => updateFilter({ text })}
              />
            </Col>
            <Col lg={4} md={4}>
              <ToggleFilterButton>
                <Row>
                  <Col md={6}>
                    <DateRangeField
                      label="front.working-documents-table.date-range.label"
                      nameFrom="dateFrom"
                      nameTo="dateTo"
                      maxDate={moment().toDate()}
                      clearable
                    />
                  </Col>
                  <Col md={6}>
                    <CustomerSelect
                      label="front.internal-payment-page.order-payer-name.label"
                      name="customerIds"
                      multi
                    />
                  </Col>
                </Row>
              </ToggleFilterButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export const MailPageFilter = withForm(MailPageFilterForm);
