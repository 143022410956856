import * as React from 'react';
import { Action } from 'react-data-table';
import { Button as BsButton, ButtonProps as BsProps } from 'reactstrap';

import { Props as ButtonProps } from 'components/buttons/Button';
import { Popover } from 'components/popover/Popover';
import { translate } from 'i18n/translator';

export type BtnColor = 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'link';

interface Props extends ButtonProps {
  actions: Array<Action>;
}

export const ButtonDropdown: React.FC<Props> = ({
  actions,
  children,
  color = 'primary',
  disabled = false,
  ...rest
}) => (
  <Popover
    fade={false}
    placement="bottom"
    id="table-popover"
    disabled={disabled}
    label={
      <BsButton
        className="bs-button bs-button-dropdown"
        color={color}
        disabled={disabled}
        {...rest as BsProps}
      >
        {children || translate('front.working-documents-table.actions-export.label')}
      </BsButton>
    }
  >
    <ul className="list-reset export-list">
      {actions.map((item, index) => (
        // @ts-ignore
        <li key={index} onClick={item.action}>
          {translate(item.label)}
        </li>
      ))}
    </ul>
  </Popover>
);
