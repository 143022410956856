import { Option } from 'api/Service';
import { isExist } from 'utils/isData';

const MAX_VISIBLE = 300;
const DEFAULT_VALUE = 0;

export const isOptionsEquals = (selectedOptions: Option[], options: Option[]): boolean => {
  if (selectedOptions.length !== options.length) {
    return false;
  }

  const selectedValues = new Set(selectedOptions.map(({ value }) => value));
  const uniqueOptions = [
    ...selectedOptions,
    ...options.filter(({ value }) => !selectedValues.has(value)),
  ];

  return uniqueOptions.length === options.length;
};

export const getDisplayedOptions = (
  options: Option<any, string | string[]>[],
  onlySearch: boolean,
  inputValue: string,
  filteredOptions: Option[],
) => {
  if (onlySearch) {
    return filteredOptions?.length > MAX_VISIBLE ? options.slice(0, MAX_VISIBLE) : filteredOptions;
  }

  return filteredOptions.length ? filteredOptions : options;
};

export const getSelectedOptions = (
  values: string | string[],
  options: Option<any, string | string[]>[] = [],
) => {
  const defaultValues = [
    {
      label: values[DEFAULT_VALUE],
      value: values[DEFAULT_VALUE],
    },
  ];

  if (!options.length) {
    return defaultValues;
  }

  const selectedValues = (values as string[]).map(value =>
    options.find(item => `${item.value}` === `${value}` || `${item.label}` === `${value}`),
  );

  const hasSelectedValue = isExist(selectedValues[DEFAULT_VALUE]);

  return hasSelectedValue ? selectedValues : defaultValues;
};

export const getFilteredOptions = (
  options: Option<any, string | string[]>[],
  inputValue: string,
): Option<any, string | string[]>[] =>
  options?.filter(item => item.label?.toLowerCase().includes(inputValue.toLowerCase()));

export const getValue = (multi: boolean, selectedOptions: any) =>
  multi ? selectedOptions : selectedOptions.length ? selectedOptions[0] : null;
