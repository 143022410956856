import fetch from 'isomorphic-fetch';
import moment from 'moment';

import { api } from 'api/index';
import { ExpiredTimeModal } from 'components/ExpiredTimeModal/ExpiredTimeModal';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import config from 'config';
import { translate } from 'i18n/translator';
import store from 'store';
import { LOCAL_STORAGE_KEY, SERVER_ERROR, SESSION_STORAGE_KEYS } from 'store/actions/types';
import { CustomError } from 'utils/customError';
import { LocalStorage } from 'utils/LocalStorage';

const DEFAULT_FILE_NAME = 'default';
const FILENAME_INDEX = 2;

const DATE_FORMAT = 'YYYY-MM-DD';
const DATE_TIME_FORMAT = 'YYYY-MM-DD:HH:mm:ss:SSS';

const ieVersion = detectIEVersion();

Date.prototype.toJSON = function () {
  return moment(this).format((this as never)['withTime'] ? DATE_TIME_FORMAT : DATE_FORMAT);
};

enum ResponseStatus {
  OK = 200,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  SESSION_EXPIRED = 420,
  BAD_GATEWAY = 502,
  GATEWAY_TIMEOUT = 504,
}

class Timer {
  isModalActive = false;
  isStartTimer = false;
  timerLogoutId: ReturnType<typeof setTimeout>;
  timerModalId: ReturnType<typeof setTimeout>;

  startTimers = () => {
    const sessionTimeModal =
      Number(sessionStorage.getItem(SESSION_STORAGE_KEYS.SESSION_EXPIRE)) * 1000 -
      config.logOut.showWarningBeforeLogoutFor;

    this.showModalTimerStart(sessionTimeModal);
    this.isStartTimer = true;
  };

  reloadTimers = () => {
    if (this.isModalActive || !this.isStartTimer) return;

    this.resetTimerModal();
    this.startTimers();
  };

  logoutTimerStart = (time: number) => {
    this.isModalActive = true;
    this.timerLogoutId = setTimeout(() => {
      logout();
    }, time);
  };

  showModalTimerStart = (time: number) => {
    this.timerModalId = setTimeout(async () => {
      await confirmModal<boolean, ExpiredTimeModal>(
        ExpiredTimeModal,
        {
          title: 'front.modal-expired-time.title.label',
          label: 'front.modal-expired-time.button.label',
          mountEffect: () => this.logoutTimerStart(config.logOut.showWarningBeforeLogoutFor),
        },
        500,
        {},
        true,
      );
      this.resetTimerLogout();
    }, time);
  };

  resetTimerLogout = () => {
    this.isModalActive = false;
    clearTimeout(this.timerLogoutId);
    api.constant.getConstantsList();
  };

  resetTimerModal = () => {
    clearTimeout(this.timerModalId);
  };
}

export const LogoutTimer = new Timer();

async function parseResponse(response: Response) {
  const contentType = response.headers.get('content-type');

  const text = await response.text();

  if (!contentType || contentType.indexOf('application/json') === -1) {
    return text;
  }

  return JSON.parse(text);
}

function pad2(n: number) {
  if (n < 10) {
    return `0${n}`;
  }

  return `${n}`;
}

export function formatParam(param: any) {
  if (param instanceof Date) {
    // 2007-12-03 is backend accepted format, see JacksonConfiguration
    return `${param.getFullYear()}-${pad2(1 + param.getMonth())}-${pad2(param.getDate())}`;
  }

  return `${param}`;
}

export function renderQueryString(qs: Obj) {
  if (!qs) return '';

  let r = '';

  Object.keys(qs).forEach(key => {
    if ([null, undefined, NaN].includes(qs[key])) {
      return;
    }

    if (r !== '') {
      r += '&';
    }

    r += `${key}=${encodeURIComponent(formatParam(qs[key]))}`;
  });

  return `?${r}`;
}

export const parseUrlParams = (search: string): Obj => {
  if (!search) {
    return {};
  }
  const hashes = search.slice(search.indexOf('?') + 1).split('&');
  return hashes.reduce((params, hash) => {
    const [key, val] = hash.split('=');
    return { ...params, [key]: decodeURIComponent(val) };
  }, {});
};

export function get(resource: string, params: Obj = undefined) {
  return rest(HttpMethod.GET, resource, params, undefined, false);
}

export function post(resource: string, body: unknown = undefined, qs: Obj = undefined) {
  return rest(HttpMethod.POST, resource, qs, body);
}

export function put(resource: string, body = {}, qs: Obj = undefined) {
  return rest(HttpMethod.PUT, resource, qs, body);
}

export function patch(resource: string, body = {}, qs: Obj = undefined) {
  return rest(HttpMethod.PATCH, resource, qs, body);
}

export function del(resource: string, body = {}, qs: Obj = undefined) {
  return rest(HttpMethod.DELETE, resource, qs, body);
}

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export function downloadFile(
  resource: string,
  body: Obj = undefined,
  qs: Obj = undefined,
  method: HttpMethod = HttpMethod.POST,
) {
  return rest(method, resource, qs, body, true);
}

export function downloadRequisites(resource: string, params: Obj = undefined) {
  return rest(HttpMethod.GET, resource, params, undefined, true);
}

async function confirmSuccessResponse(response: Response) {
  const sessionExpire = response.headers.get('session_expire');

  if (sessionExpire) {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.SESSION_EXPIRE, sessionExpire);
    LogoutTimer.reloadTimers();
  }

  if (response.status >= ResponseStatus.OK && response.status < 300) {
    return response;
  }

  if (
    response.status === ResponseStatus.UNAUTHORIZED ||
    response.status === ResponseStatus.SESSION_EXPIRED
  ) {
    const isLoginPage = response.url.includes('/login');
    !isLoginPage && logout();
  }

  const errorParamCode = response.headers.get('X-ibankApp-params');
  const text = await response.text();

  let errorMessage;

  switch (response.status) {
    case ResponseStatus.FORBIDDEN:
      errorMessage = text || translate('front.backend.error.forbidden.label');
      break;
    case ResponseStatus.SESSION_EXPIRED:
      errorMessage = text || translate('front.cert-page.sms-modal.session-expired.label');
      break;
    default:
      errorMessage = text || response.statusText;
  }

  const error: any = new Error(errorMessage);
  error.code = errorParamCode || response.status;
  if (error.code === ResponseStatus.BAD_GATEWAY || error.code === ResponseStatus.GATEWAY_TIMEOUT) {
    store.dispatch({ type: SERVER_ERROR.SET_ERROR, payload: true });
  }
  throw error;
}

export function getResource(url: string, method = 'GET', body = {}) {
  const headers: Obj = {};

  headers['Content-Type'] = 'application/json';

  return new Promise((resolve, reject) => {
    fetch(url, {
      method,
      // @ts-ignore
      body,
      headers,
      cache: 'no-cache',
    })
      .then((response: Response) => confirmSuccessResponse(response))
      .then((response: Response) => parseResponse(response))
      .then((response: Response) => resolve(response))
      .catch((error: Response) => reject(error));
  });
}

// tslint:disable-next-line:max-line-length
export function rest(
  method: HttpMethod,
  url: string,
  queryString: Obj,
  body: Obj,
  isFile?: boolean,
): Promise<any> {
  const urli = `/api${url}${renderQueryString(queryString)}`;

  const headers: Obj = {};
  let data: any = body;

  if (typeof data === 'object' || typeof data === 'number' || typeof data === 'boolean') {
    // JSON request
    if (!(data instanceof FormData)) {
      headers['Content-Type'] = 'application/json';
      data = JSON.stringify(data);
    }
  }
  const token = sessionStorage.getItem(SESSION_STORAGE_KEYS.TOKEN);
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const activeLocale = LocalStorage.getItem(LOCAL_STORAGE_KEY.ACTIVE_LOCALE);
  if (activeLocale) {
    headers['accept-language'] = activeLocale;
  }

  //disable cache for all versions of MSIE
  if (ieVersion) {
    headers['pragma'] = 'no-cache';
    headers['cache-control'] = 'no-cache';
  }

  return new Promise((resolve, reject) => {
    if (!navigator.onLine) {
      throw new CustomError(translate('front.no-internet-connection.error'));
    }

    fetch(urli, {
      method,
      headers,
      body: data,
      cache: 'no-cache',
      credentials: 'same-origin',
    })
      .then((response: Response) => confirmSuccessResponse(response))
      .then((response: Response) => {
        if (isFile) {
          return downloadAsFile(response);
        }
        return parseResponse(response);
      })
      .then((response: Response) => resolve(response))
      .catch((error: Response) => reject(error));
  });
}

export const logout = async (reloadPage = true) => {
  !sessionStorage.getItem('isLogout') && (await api.auth.logout());

  sessionStorage.clear();
  reloadPage && window.location.reload();
};

const getFileName = (headers: Response['headers']) => {
  const disposition = headers.get('content-disposition');
  if (!disposition) {
    return DEFAULT_FILE_NAME;
  }

  const fileNameMatch = /filename\*?="(UTF-8'')?([^"';]+)/i.exec(disposition);
  if (!fileNameMatch) {
    return DEFAULT_FILE_NAME;
  }
  return decodeURI(fileNameMatch[FILENAME_INDEX]);
};

const downloadAsFile = async (response: Response, fileName?: string) => {
  const fileNameDecoded = fileName ?? getFileName(response.headers);
  const blob = await response.blob();

  download(blob, fileNameDecoded);
};

const DELAY_TIME = 100;

export const download = async (blob: Blob, filename: string, hasDelay?: boolean) => {
  if (typeof (window.navigator as Obj).msSaveBlob !== 'undefined') {
    (window.navigator as Obj).msSaveBlob(blob, filename);
    return;
  }
  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', filename);
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  setTimeout(() => {
    window.URL.revokeObjectURL(blobURL);
  }, 100);

  if (hasDelay) {
    await new Promise(resolve => setTimeout(resolve, DELAY_TIME));
  } else {
    return '';
  }
};

/**
 * detect IEEdge
 * returns version of IE/Edge or false, if browser is not a Microsoft browser
 */
function detectIEVersion() {
  const ua = window.navigator.userAgent;
  return ua.indexOf('MSIE ') > 0 || ua.indexOf('Trident/') > 0;
}
