import * as React from 'react';

import moment from 'moment';

import { api } from 'api';
import { useDisableAndError } from 'components/ExportingDocuments/useDisableAndError';
import { translate } from 'i18n/translator';
import { handleError } from 'utils/handleError';

import { ExportButton } from '../buttons/ExportButton';
import { DisablingWays, ExportWayOption, ExportWays, ModalExportProps } from './types';
import { getExportWayOptions } from './utils';

import './styles.scss';

const INCLUDED_LAST_DAY = 1;

export const ExportingDocuments = ({ exportData, exportType }: ModalExportProps) => {
  const { error, disabled, setDisableAndError } = useDisableAndError<DisablingWays>();

  const { dateTo, dateFrom, accountIds } = exportData;

  const countDaysPeriod = moment(dateTo).diff(dateFrom, 'days') + INCLUDED_LAST_DAY;
  const isPeriodSeveralDays = countDaysPeriod > 1;
  const isMultipleAccounts = accountIds.length > 1;

  const handleClick = (option: ExportWayOption) => async () => {
    setDisableAndError('');

    if (option.type === ExportWays.OneFile) {
      try {
        await api.accounts.exportStatements({ ...exportData });
      } catch (e: any) {
        handleError(e, setDisableAndError);
      }

      return;
    }

    const { accountIdsWithDate, accountIds } = await api.accounts.getPreparedExportData({
      ...exportData,
    });

    if (option.type === ExportWays.OneAccountInOneFile) {
      if (accountIds.length > 100) {
        setDisableAndError(
          'front.account-statements-export-modal.error-message.exceeds-100-files',
          ExportWays.OneAccountInOneFile,
        );
        return;
      }

      for (const accountId of accountIds) {
        try {
          await api.accounts.exportStatements({
            ...exportData,
            accountIds: [accountId],
            layout: option.type,
          });
        } catch (e: any) {
          handleError(e, setDisableAndError);
        }
      }

      return;
    }

    if (option.type === ExportWays.OneAccountOneDateInOneFile) {
      if (accountIdsWithDate.length > 100) {
        setDisableAndError(
          'front.account-statements-export-modal.error-message.exceeds-100-files',
          ExportWays.OneAccountOneDateInOneFile,
        );
        return;
      }

      for (const accountIdAndDate of accountIdsWithDate) {
        const [id, date] = accountIdAndDate.split('/');

        try {
          await api.accounts.exportStatements({
            ...exportData,
            accountIds: [id],
            dateFrom: date,
            dateTo: date,
            layout: option.type,
          });
        } catch (e: any) {
          handleError(e, setDisableAndError);
        }
      }

      return;
    }
  };

  return (
    <div className="exporting-document">
      <h2 className="header">{translate('front.print-document.export-type.label')}</h2>
      <div className="wrapper">
        <ExportButton
          option={getExportWayOptions(exportType)[ExportWays.OneFile]}
          onClick={handleClick}
        />
        {isMultipleAccounts && (
          <ExportButton
            option={getExportWayOptions(exportType)[ExportWays.OneAccountInOneFile]}
            onClick={handleClick}
            disabled={disabled[ExportWays.OneAccountInOneFile]}
          />
        )}
        {isPeriodSeveralDays && (
          <ExportButton
            option={getExportWayOptions(exportType)[ExportWays.OneAccountOneDateInOneFile]}
            onClick={handleClick}
            disabled={disabled[ExportWays.OneAccountOneDateInOneFile]}
          />
        )}

        {error && <div className="error-import-modal">{error}</div>}
      </div>
    </div>
  );
};
