import React from 'react';
import DayPicker, { DateUtils, DayModifiers } from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import { Input as BsInput } from 'reactstrap';

import classNames from 'classnames';
import moment from 'moment';

import { Clear } from 'components/icons';
import * as format from 'components/utils/format';
import config from 'config';
import { translate } from 'i18n/translator';
import { LOCAL_STORAGE_KEY } from 'store/actions/types';
import { emptyObject } from 'utils/emptyObject';
import { LocalStorage } from 'utils/LocalStorage';
import { noop } from 'utils/noop';

import { MonthYearSelect } from './MonthYearSelect';
import { NavBarNavigation } from './NavBarNavigation';
import { getDateArray, getSelectedValueKey, getSelectOptions } from './utils';
import './styles.scss';
import 'react-day-picker/lib/style.css';

interface DisableDays {
  after: Date;
  before: Date;
}

export interface DateRangePickerProps {
  nameFrom: string;
  nameTo: string;
  clearable?: boolean;
  dateFrom?: Date;
  dateTo?: Date;
  disabled?: boolean;
  disabledDaysFrom?: DisableDays | null;
  disabledDaysTo?: DisableDays | null;
  isOnlyRangePicker?: boolean;
  maxDate?: Date;
  minDate?: Date;

  onBlur?(event?: any): void;

  onChange?(value: { [key: string]: any }): void;

  placeholder?: string;

  renderHelpedFormFields?: boolean;
}

interface DateOption {
  key: string;
  className?: string;
  label?: string;
  value?: string[] | string;
}

const { MIN_YEAR, MAX_YEAR } = config.components.datePicker;

const disabledDays = [
  new Date(2000, 0, 0), // Jan, 2nd
  new Date(2000, 7, 7), // Aug, 7th
];

function isDaysDisabled(day: Date) {
  return !disabledDays.some(disabledDay => DateUtils.isSameDay(day, disabledDay));
}

interface ListProps {
  onSelectRange: (key: string, e: any) => void;
  selectedOption: DateOption;
}

const SelectList: React.FC<ListProps> = ({ selectedOption, onSelectRange }) => {
  return (
    <div className="date-input__wrap-picker">
      <div className="date-input__select-range">
        {getSelectOptions().map(item => (
          <div
            key={item.key}
            className={classNames('date-input__select-range__item', item.className, {
              selected: item.key === selectedOption.key,
            })}
            onClick={e => {
              onSelectRange(item.key, e);
            }}
          >
            {translate(item.label)}
          </div>
        ))}
      </div>
    </div>
  );
};

let scrollSize: string;

export const DateRangePicker: React.FC<DateRangePickerProps> = props => {
  const {
    clearable = false,
    disabled = false,
    isOnlyRangePicker = false,
    onBlur = noop,
    placeholder = '',
    minDate = new Date(MIN_YEAR, 0),
    maxDate = new Date(MAX_YEAR, 11),
    disabledDaysTo = emptyObject,
    disabledDaysFrom = emptyObject,
    dateFrom,
    dateTo,
    nameFrom,
    nameTo,
    onChange,
  } = props;

  const [isOpen, setIsOpen] = React.useState(false);
  const [isShownSelectRange, setIsShownSelectRange] = React.useState(false);
  const [monthFrom, setMonthFrom] = React.useState(null);
  const [monthTo, setMonthTo] = React.useState(null);
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [highlightedDates, setHighlightedDates] = React.useState([]);

  const handleUpdateDate = (from: Date, to: Date) => {
    setFrom(from);
    setTo(to);
  };

  React.useEffect(() => {
    scrollSize = `${document.body.offsetWidth - document.body.clientWidth}px`;
  }, []);

  React.useEffect(() => {
    if (isShownSelectRange || isOpen) {
      handleUpdateDate(null, null);

      document.body.style.paddingRight = scrollSize;
      document.body.classList.add('scroll-hidden');
    } else {
      document.body.classList.remove('scroll-hidden');
      document.body.style.paddingRight = '0px';
    }
  }, [isShownSelectRange, isOpen]);

  const onFocus = () => {
    if (!isOpen && !isShownSelectRange) {
      handleUpdateDate(dateFrom, dateTo);

      if (isOnlyRangePicker) {
        return setIsOpen(true);
      }

      setIsShownSelectRange(true);
      setIsOpen(false);
    }
  };

  const onClose = (e?: any) => {
    e && e.preventDefault();
    setIsOpen(false);
    setIsShownSelectRange(false);
    handleUpdateDate(dateFrom, dateTo);
    setHighlightedDates([]);
    onBlur();
  };

  const onYearMonthChangeFrom = (monthFrom: Date) => {
    const getMonthTo = moment(monthFrom).isAfter(monthTo || dateTo) ? monthFrom : monthTo || dateTo;
    setMonthFrom(monthFrom);
    setMonthTo(getMonthTo);
  };

  const onYearMonthChangeTo = (monthTo: Date) => {
    setMonthTo(monthTo);
  };

  const onClear = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    onChange({ [nameFrom]: null, [nameTo]: null });
    onClose();
  };

  const onSelectRange = (key: string, e: any) => {
    e.preventDefault();
    if (key === 'select_range') {
      setIsOpen(true);
      setIsShownSelectRange(false);
    } else {
      const range = getSelectOptions().find(item => item.key === key).value;
      const [from, to] = range;
      onChange({ [nameFrom]: from, [nameTo]: to });
      onClose();
    }
  };

  const onFromChange = (
    value: Date,
    modifires: DayModifiers,
    e: React.MouseEvent<HTMLDivElement>,
  ) => {
    e.preventDefault();
    if (modifires.disabled) {
      return;
    }
    onChange({
      [nameFrom]: format.date(value),
      [nameTo]: disabledDaysTo ? null : format.date(to),
    });
    setFrom(value);
    setHighlightedDates([]);
  };

  const onToChange = (
    value: Date,
    modifires: DayModifiers,
    e: React.MouseEvent<HTMLDivElement>,
  ) => {
    e.preventDefault();
    if (modifires.disabled) {
      return;
    }
    let fromValue = from;
    let toValue = value;

    if (!from && !to) {
      fromValue = value;
      toValue = null;
      handleUpdateDate(fromValue, toValue);
      setHighlightedDates([]);
    } else {
      onChange({
        [nameFrom]: format.date(fromValue),
        [nameTo]: format.date(toValue),
      });
      onClose();
    }
  };

  const onDayMouseEnter = (day: Date, modifiers: DayModifiers) => {
    if (from && moment(day).isAfter(from) && !modifiers.disabled) {
      setHighlightedDates(getDateArray(from, day));
    } else {
      setHighlightedDates([]);
    }
  };

  const locale = LocalStorage.getItem(
    LOCAL_STORAGE_KEY.ACTIVE_LOCALE,
    config.i18n.defaultLanguageCode,
  );

  const value = format.dateRange(dateFrom, dateTo);

  const selectedOption = getSelectedValueKey(value);

  const modifiers = { highlighted: highlightedDates };

  const isClearButtonVisible = value && clearable && !disabled;

  return (
    <div className="date-field date-input">
      <div
        className="click-outside"
        onClick={onClose}
        style={{ display: isOpen || isShownSelectRange ? 'block' : 'none' }}
      />
      <BsInput
        className={classNames('form-control', 'input-date', 'date-input__input', {
          'date-input__with-clear-button': isClearButtonVisible,
        })}
        value={translate(selectedOption.value)}
        disabled={disabled}
        placeholder={translate(placeholder)}
        onFocus={onFocus}
        readOnly={true}
      />
      {isClearButtonVisible ? (
        <div className="date-input__clear" onClick={onClear}>
          <Clear />
        </div>
      ) : null}
      {isShownSelectRange && !isOnlyRangePicker && !isOpen ? (
        <SelectList selectedOption={selectedOption} onSelectRange={onSelectRange} />
      ) : null}
      {isOpen && (!isShownSelectRange || isOnlyRangePicker) ? (
        <div className="date-input__wrap-picker">
          <div className="date-input__picker range-picker">
            <div className="range-picker__item">
              <div className="range-picker__item__title">
                {translate('front.date-range.date-from.label')}
              </div>
              <DayPicker
                locale={locale}
                localeUtils={MomentLocaleUtils}
                onDayClick={onFromChange}
                selectedDays={[from, { from, to }]}
                disabledDays={{
                  before: minDate,
                  after: to,
                  ...disabledDaysFrom,
                }}
                modifiers={modifiers}
                month={monthFrom || dateFrom}
                fromMonth={minDate}
                toMonth={maxDate}
                navbarElement={<NavBarNavigation />}
                captionElement={props => (
                  <MonthYearSelect
                    {...props}
                    onChange={onYearMonthChangeFrom}
                    fromMonth={minDate}
                    toMonth={maxDate}
                  />
                )}
                onDayMouseEnter={onDayMouseEnter}
              />
            </div>
            <div className="range-picker__item">
              <div className="range-picker__item__title">
                {translate('front.date-range.date-to.label')}
              </div>
              <DayPicker
                locale={locale}
                localeUtils={MomentLocaleUtils}
                onDayClick={onToChange}
                selectedDays={[to, { from, to }]}
                disabledDays={
                  !from
                    ? isDaysDisabled
                    : {
                        before: from,
                        after: maxDate,
                        ...disabledDaysTo,
                      }
                }
                modifiers={modifiers}
                month={monthTo || dateTo || (disabledDaysTo as DisableDays)?.before}
                fromMonth={minDate}
                toMonth={maxDate}
                navbarElement={<NavBarNavigation />}
                captionElement={props => (
                  <MonthYearSelect
                    {...props}
                    onChange={onYearMonthChangeTo}
                    fromMonth={minDate}
                    toMonth={maxDate}
                  />
                )}
                onDayMouseEnter={onDayMouseEnter}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
