import { api } from 'api';
import { translate } from 'i18n/translator';

export const handleError = (
  e: { code: any; message: string; stack: any },
  setError: (errorMessage: string) => void,
) => {
  let error;

  try {
    error = JSON.parse(e.message);
  } catch (err) {
    error = e;
  }

  if (e.code) {
    setError(error.message);
  } else {
    const storages = {
      stack: e?.stack,
      ...getStoragesB64(),
    };
    const storagesBase64 = btoa(unescape(encodeURIComponent(JSON.stringify(storages))));

    const fullError = { level: 'ERROR', message: e.message, data: storagesBase64 };

    api.logService.log(fullError);
    setError(translate('front.system-error.reload-page.label'));
  }
};

export const getStoragesB64 = () => {
  const newSessionStorage = { ...sessionStorage };

  if (newSessionStorage.token) {
    delete newSessionStorage.token;
  }
  if (newSessionStorage.user) {
    const { privileges, value, ...otherUser } = newSessionStorage.user;
    newSessionStorage.user = otherUser;
  }

  return {
    localStorage: { ...localStorage },
    sessionStorage: newSessionStorage,
  };
};
