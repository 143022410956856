import { CheckSmsActionEnum } from 'api/enums';
import { OrderSignSmsRows, OrderSmsSignParams } from 'api/interfaces';
import { getOrdersSignSmsRows } from 'api/utils';
import { isExist } from 'utils/isData';

import { get, post, put } from './backend';

interface SmsResponse {
  addFactors?: Array<any>;
  privileges?: Array<string>;
}

interface OrdersSignSmsResponse {
  params: OrderSmsSignParams;
  smsLength: number;
  ttl: number;
}

interface PrepareOrderSignSmsResponse extends Omit<OrdersSignSmsResponse, 'params'> {
  params: OrderSignSmsRows;
}

interface CheckSmsCodeRequest {
  action: CheckSmsActionEnum;
  code: string;
}

export class SmsService {
  async smsRequest(): Promise<{
    smsLength: number;
    ttl: number;
  }> {
    return await post('/customer/sms-request');
  }

  async smsCodeSend(code: string): Promise<SmsResponse> {
    return await post('/customer/sms', { code });
  }

  async getSmsValidPeriod(): Promise<{ value: number }> {
    return await get('/constants/name/SMS_VALID_PERIOD');
  }

  async ordersSignSmsRequest(data: {
    orderIdList: Array<number>;
  }): Promise<PrepareOrderSignSmsResponse> {
    const res: OrdersSignSmsResponse = await post('/v1/order/signing/sms-request', data);
    const hasParams = isExist(res.params);

    return {
      ...res,
      params: hasParams
        ? {
            rows: getOrdersSignSmsRows(res.params),
            total: {
              count: 1,
            },
          }
        : null,
    };
  }

  async certificateSmsRequest(): Promise<any> {
    return await get('/v1/certificates/sms-request');
  }

  async checkSmsCode(req: CheckSmsCodeRequest): Promise<unknown> {
    return await put('/v1/confirmation', req);
  }

  async confirmationRequest(action: CheckSmsActionEnum): Promise<string> {
    return await post('/v1/confirmation/request', { action });
  }
}
