import * as React from 'react';
import { Col, Container, Row } from 'react-grid';

import { api } from 'api';
import { CalcPeriodOption, CalculatedDeposit, DepositOffer } from 'api/ProductsService';
import { Option } from 'api/Service';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { FormFetchCb, useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { Page } from 'components/layout/Page/Page';
import { translate } from 'i18n/translator';

import { DepositOffers } from './DepositOffers';
import { DepositText } from './DepositText';

type PeriodTypeValue = 'Days' | 'Month' | 'Year';

const periodMountsOptions: Option[] = [
  {
    label: translate('front.deposit-calculator.options-period-days.label'),
    value: 'Days',
  },
  {
    label: translate('front.deposit-calculator.options-period-month.label'),
    value: 'Month',
  },
  {
    label: translate('front.deposit-calculator.options-period-year.label'),
    value: 'Year',
  },
];

interface Fields {
  amount: number;
  calcPeriod: string;
  currency: string;
  customerBusinessSize: string;
  earlyReturnAllowed: boolean;
  extensionAllowed: boolean;
  interestCapitalizeAllowed: boolean;
  period: number;
  periodType: PeriodTypeValue;
  publicSector: boolean;
  replenishmentAllowed: boolean;
}

interface Payload {
  amountMax: number;
  amountMin: number;
  calcPeriodsDetails: Option[];
  currencies: Option[];
  customerBusinessSizes: Option[];
  periodMax: number;
  periodMin: number;
}

const periodMax = 3700;

const convertPeriod = (from: PeriodTypeValue, to: PeriodTypeValue, value: number): number => {
  let newPeriod;
  switch (to) {
    case 'Days': {
      if (from === 'Month') {
        newPeriod = (730 / 24) * value;
      } else if (from === 'Year') {
        newPeriod = value * 365;
      } else {
        newPeriod = value;
      }
      break;
    }
    case 'Month': {
      if (from === 'Days') {
        newPeriod = value / 30;
      } else if (from === 'Year') {
        newPeriod = value * 12;
      } else {
        newPeriod = value;
      }
      break;
    }
    case 'Year': {
      if (from === 'Days') {
        newPeriod = value / 365;
      } else if (from === 'Month') {
        newPeriod = value / 12;
      } else {
        newPeriod = value;
      }
      break;
    }
  }

  return Math.trunc(newPeriod);
};

const DepositCalculatorForm: React.FC = () => {
  const [depositOffers, setDepositOffers] = React.useState<DepositOffer[]>();
  const fetchDepositInfo: FormFetchCb<Fields, Payload> = async ({ setFields, setPayload }) => {
    const {
      customerBusinessSizes,
      currencies,
      calcPeriodsDetails,
      periodMin,
      periodMax,
      amountMin,
      amountMax,
    } = await api.products.getCalculateDepositInfo();

    setPayload({
      customerBusinessSizes,
      currencies,
      calcPeriodsDetails,
      periodMin,
      periodMax,
      amountMin,
      amountMax,
    });
    const periodType = periodMountsOptions[1].value as PeriodTypeValue;
    setFields({
      periodType,
    });
  };

  const { error, payload, handleSubmit, progress, getFormData, updateData, resetData } =
    useForm<Fields>(fetchDepositInfo);

  const { period, periodType } = getFormData();

  const validateMaxPeriod = React.useCallback(
    value => {
      const newValue = convertPeriod(periodType, 'Days', value);
      if (newValue > periodMax) {
        return translate('front.form.term-max.error').replace('${value}', `${periodMax}`);
      }
      return '';
    },
    [periodType],
  );

  const onSave = async (formData: Fields) => {
    const { period, periodType, ...other } = formData;

    const newPeriod = convertPeriod(periodType, 'Days', period);

    const depositRequest: CalculatedDeposit = {
      periodMin: newPeriod,
      periodMax: newPeriod,
      ...other,
    };

    const { rows } = await api.products.getCalculateDeposits(depositRequest);

    setDepositOffers(rows);
  };

  const resetFields = (e: React.CompositionEvent) => {
    e.preventDefault();
    resetData();
    setDepositOffers(null);
  };

  if (error && depositOffers) {
    setDepositOffers(null);
  }

  const onCalcPeriodChange = (calcPeriodsDetails: CalcPeriodOption[]) => (value: string) => {
    const interestCapitalizeAllowed = calcPeriodsDetails.find(
      item => item.value === value,
    ).isCapitalize;
    updateData({ ['interestCapitalizeAllowed']: interestCapitalizeAllowed });
  };

  return (
    <>
      <Page title={translate('front.deposit-calculator.title.label')}>
        <div style={{ padding: '24px' }}>
          <DefaultForm>
            <FormError>{error}</FormError>
            <Container>
              <Row>
                <Col lg={3} md={6}>
                  <SelectField
                    label={translate('front.deposit-calculator.select-currency.label')}
                    name="currency"
                    options={payload.currencies}
                    required
                  />
                </Col>
                <Col lg={3} md={6}>
                  <SelectField
                    label={translate('front.deposit-calculator.select-business.label')}
                    name="customerBusinessSize"
                    options={payload.customerBusinessSizes}
                    required
                  />
                </Col>
                <Col lg={3} md={6}>
                  <SelectField
                    label={translate('front.deposit-calculator.select-interest-payment.label')}
                    name="calcPeriod"
                    options={payload.calcPeriodsDetails}
                    onSelectOption={onCalcPeriodChange(payload.calcPeriodsDetails)}
                    required
                  />
                </Col>
                <Col lg={3} md={6}>
                  <SelectField
                    label={translate('front.deposit-calculator.select-term.label')}
                    name="periodType"
                    // defaultValue={periodMountsOptions[1].value}
                    options={periodMountsOptions}
                    required
                    onSelectOption={value => {
                      const newPeriod = convertPeriod(periodType, value as PeriodTypeValue, period);
                      updateData({ period: newPeriod });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={6}>
                  <AmountField
                    label="front.deposit-calculator.input-amount.label"
                    name="amount"
                    maxAmount={payload.amountMax}
                    thousandSeparator={' '}
                    hasMoneyFormat
                    required
                  />
                </Col>
              </Row>
              {/*<Row>*/}
              {/*  <Col md="12">*/}
              {/*    <div className="slider-wrap" style={{ margin: '15px 0', padding: '0 5px' }}>*/}
              {/*      <Slider*/}
              {/*        value={Number(amount)}*/}
              {/*        min={payload.amountMin}*/}
              {/*        max={payload.amountMax}*/}
              {/*        marks={{*/}
              {/*          [payload.amountMin]: payload.amountMin,*/}
              {/*          [payload.amountMax]: payload.amountMax,*/}
              {/*        } as any}*/}
              {/*        onChange={amount => updateData({ amount })}*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*  </Col>*/}
              {/*</Row>*/}
              {periodType && payload.periodMax && (
                <Row>
                  <Col lg={3} md={6}>
                    <AmountField
                      label="front.deposit-calculator.select-term.label"
                      name="period"
                      validate={validateMaxPeriod}
                      required
                    />
                  </Col>
                </Row>
              )}
              {/*{periodType && <Row>*/}
              {/*  <Col md="12">*/}
              {/*    <div className="slider-wrap" style={{ margin: '15px 0', padding: '0 5px' }}>*/}
              {/*      <Slider*/}
              {/*        value={Number(period)}*/}
              {/* tslint:disable-next-line:max-line-length */}
              {/*        min={periodType === 'Days' ? payload.periodMin : periodType === 'Month' ? 1 : 1}*/}
              {/*        max={getValuePeriod(periodType, payload.periodMax)}*/}
              {/*        marks={{*/}
              {/*          1: 1,*/}
              {/* tslint:disable-next-line:max-line-length */}
              {/*          [getValuePeriod(periodType, payload.periodMax)]: getValuePeriod(periodType, payload.periodMax),*/}
              {/*        }  as any}*/}
              {/*        onChange={period => updateData({ period })}*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*  </Col>*/}
              {/*</Row>}*/}
              <Row>
                <Col lg={3} md={6}>
                  <CheckboxField
                    label={translate('front.deposit-calculator.checkbox-public-sector.label')}
                    name="publicSector"
                  />
                </Col>
                <Col lg={3} md={6}>
                  <CheckboxField
                    label={translate(
                      'front.deposit-calculator.checkbox-replenishment-allowed.label',
                    )}
                    name="replenishmentAllowed"
                  />
                </Col>
                <Col lg={3} md={6}>
                  <CheckboxField
                    label={translate(
                      'front.deposit-calculator.checkbox-early-return-allowed.label',
                    )}
                    name="earlyReturnAllowed"
                  />
                </Col>
                <Col lg={3} md={6}>
                  <CheckboxField
                    label={translate('front.deposit-calculator.checkbox-extension-allowed.label')}
                    name="extensionAllowed"
                    defaultValue={false}
                  />
                </Col>
              </Row>
              <Button
                color="primary"
                onClick={e => handleSubmit(onSave, e)}
                progress={progress}
                type="submit"
                size="md"
              >
                {translate('front.deposit-calculator.button-pick.label')}
              </Button>
              <Button
                color="secondary"
                onClick={resetFields}
                progress={progress}
                type="submit"
                size="md"
              >
                {translate('front.deposit-calculator.button-cancel.label')}
              </Button>
            </Container>
          </DefaultForm>
          {depositOffers && (
            <>
              <DepositText text={translate('front.deposit-calculator.deposit-text.label')} />
              <DepositOffers offers={depositOffers} />
            </>
          )}
        </div>
      </Page>
    </>
  );
};

export const DepositCalculatorPage = withForm(DepositCalculatorForm);
