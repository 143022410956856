import * as React from 'react';
import { Col, Container, Row } from 'react-grid';
import { useParams } from 'react-router';

import { api } from 'api';
import { Employee } from 'api/SalaryService';
import { Option } from 'api/Service';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { DateField } from 'components/forms/inputs/DateField';
import { MaskField, normalizeIban } from 'components/forms/inputs/MaskField';
import { TextField } from 'components/forms/inputs/TextField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { Page } from 'components/layout/Page/Page';
import config from 'config';
import { translate } from 'i18n/translator';
import { goBack, goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { validateIban } from 'pages/Demands/utils';

export const regExpNativeBank = /^[A-Z]{2}\d{2}(320478)/;

const EmployeeForm: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { error, setData, updateData, handleSubmit, subscribe, unsubscribe } = useForm<Employee>(
    async () => {
      const employee = await api.salary.getEmployee(id);

      setData(employee);
    },
  );

  React.useEffect(() => {
    subscribe('iban', onChangeIban);
    return () => unsubscribe('iban');
  }, []);

  const onSave = async (formData: Employee) => {
    await api.salary.updateEmployee(`${formData.id}`, formData);
    goto(pages.employees);
  };

  const onChangeIban = ({ value }: Option) => {
    const isUgbMfo = regExpNativeBank.test(value);

    if (isUgbMfo) {
      updateData({ isAccountInAnotherBank: false });
    } else {
      updateData({ isAccountInAnotherBank: true });
    }
  };

  return (
    <Page title={'front.employee-page.title.label'}>
      <DefaultForm>
        <FormError>{error}</FormError>
        <Container>
          <Row>
            <Col md={12}>
              <DateField
                label="front.employee-page.field.birth-date.label"
                name="birthDate"
                disabled
              />
            </Col>
            <Col md={12}>
              <TextField
                label="front.employee-page.field.birth-place.label"
                name="birthPlace"
                disabled
              />
            </Col>
            <Col md={12}>
              <TextField
                label="front.employee-page.field.last-name.label"
                name="lastName"
                disabled
              />
            </Col>
            <Col md={12}>
              <TextField
                label="front.employee-page.field.first-name.label"
                name="firstName"
                disabled
              />
            </Col>
            <Col md={12}>
              <TextField
                label="front.employee-page.field.middle-name.label"
                name="middleName"
                disabled
              />
            </Col>
            <Col md={12}>
              <TextField label="front.employee-page.field.phone.label" name="phone" disabled />
            </Col>
            <Col md={12}>
              <TextField label="front.employee-page.field.tax-code.label" name="taxCode" disabled />
            </Col>
            <Col md={12}>
              <MaskField
                name="iban"
                label="front.employee-page.field.iban.label"
                validate={validateIban}
                mask={config.page.domesticPayment.ibanMask}
                normalize={normalizeIban}
                length={29}
                required
              />
            </Col>
            <Col md={12}>
              <Button color="primary" onClick={e => handleSubmit(onSave, e)}>
                {translate('front.employee-page.button.save.label')}
              </Button>
              <Button color="secondary" onClick={() => goBack()}>
                {translate('front.employee-page.button.cancel.label')}
              </Button>
            </Col>
          </Row>
        </Container>
      </DefaultForm>
    </Page>
  );
};

export const EmployeePage = withForm(EmployeeForm);
