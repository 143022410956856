import * as React from 'react';

import { Button } from 'components/buttons/Button';
import { PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import { translate } from 'i18n/translator';

import './styles.scss';

export interface ExpiredTimeModal {
  label: string;
  title: string;
  listItem?: { [key: string]: string };

  mountEffect?(): void;

  onClick?(resolve: any): void;
}

export const ExpiredTimeModal: React.FC<PropsWithConfirmModal<ExpiredTimeModal>> = ({
  resolve,
  title,
  listItem,
  label,
  mountEffect = () => null,
  onClick = resolve => resolve(true),
}: PropsWithConfirmModal<ExpiredTimeModal>) => {
  const [objectList, setObjectList] = React.useState<{
    [key: string]: string | string[];
  }>({});

  let list: string[] = [];

  if (listItem) {
    const [key] = Object.keys(listItem);
    list = (objectList[key] || []) as string[];
  }

  React.useEffect(() => {
    if (!listItem) return;
    const [key] = Object.keys(listItem);
    const value = listItem[key];

    setObjectList(prevState => {
      if (!objectList[key]) {
        const newArr = [value];
        return {
          ...prevState,
          [key]: newArr,
        };
      }
      return {
        ...prevState,
        [key]: [...prevState[key], value],
      };
    });
  }, [listItem]);

  React.useEffect(() => {
    mountEffect();
  }, [mountEffect]);

  return (
    <div className="expired-modal">
      {title && <h3 className="title">{translate(title)}</h3>}
      {list.length > 0 && listItem && (
        <ul className="list">
          {list.map(item => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      )}
      <div>
        <Button onClick={() => onClick(resolve)} size="md" type="submit">
          {translate(label)}
        </Button>
      </div>
    </div>
  );
};
