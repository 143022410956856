import * as React from 'react';
import { Col, Row } from 'react-grid';
import { useSelector } from 'react-redux';

import { ProductType, useExportActions } from 'api/ProductsService';
import { Option } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { ExportDropdown } from 'components/ExportDropdown/ExportDropdown';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { withForm } from 'components/forms/withForm';
import { PageHeader } from 'components/layout/Page/PageHeader';
import { SearchInput } from 'components/searchInput/SearchInput';
import { useFilter } from 'components/Table/DataTable';
import { ToggleFilterButton } from 'components/Table/ToggleFilterButton/ToggleFilterButton';
import { FilterTabBar } from 'components/tabs/FilterTabBar';
import { translate } from 'i18n/translator';
import { DisabledBlock, isDeniedActionForCustomers } from 'navigations/access';
import { Privileges } from 'navigations/privileges';
import {
  createCurrencyOption,
  createTypeOption,
  getCurrency,
  getType,
} from 'pages/Products/Credits/creditsUtils';
import { ExtraFields } from 'pages/Products/Credits/interfaces';
import { getOptionsFromRows } from 'pages/Products/utils/productUtils';
import { selectChosenCustomersId } from 'store/selectors';

import './styles.scss';

interface Fields {
  currency: string;
  customerIds: number[];
  maxAmountLimit: number;
  minAmountLimit: number;
  organizationName: string;
  subType: string;
}

export type CreditFilterFields = Fields & ExtraFields;

const CreditsFilterForm = () => {
  const [currencyOptions, setCurrencyOptions] = React.useState<Option[]>();
  const [typeOptions, setTypeOptions] = React.useState<Option[]>();
  const customerIds = useSelector(selectChosenCustomersId());

  const handleChangeSearchText = (searchText: string) => updateFilter({ searchText });
  const { rows, updateFilter, extraFields, tabOptions, setPagination } = useFilter<
    Fields,
    ExtraFields
  >({
    extraFields: { customerIds, status: 'all', searchText: undefined },
  });

  React.useEffect(() => {
    if (!currencyOptions && rows.length) {
      const currencies = getOptionsFromRows(rows, getCurrency, createCurrencyOption);

      setCurrencyOptions(currencies);
    }

    if (!typeOptions && rows.length) {
      const types = getOptionsFromRows(rows, getType, createTypeOption);

      setTypeOptions(types);
    }
  }, [rows]);

  const exportActions = useExportActions(extraFields, ProductType.CREDIT);
  const chosenCustomerIds =
    extraFields?.customerIds?.length > 0 ? extraFields.customerIds : customerIds;

  return (
    <div className="credits-filter">
      <PageHeader.Right>
        <DisabledBlock
          disabled={isDeniedActionForCustomers([Privileges.productCreditView], chosenCustomerIds)}
        >
          <ExportDropdown actions={exportActions} />
        </DisabledBlock>
      </PageHeader.Right>
      <Row>
        <Col lg={7} md={12}>
          <FilterTabBar
            onChange={status => updateFilter({ status })}
            value={extraFields.status}
            options={tabOptions}
            setPagination={setPagination}
          />
        </Col>
        <Col lg={4} sm={9}>
          <SearchInput
            placeholder={translate('front.working-documents-table.filter-search.label')}
            value={extraFields.searchText}
            onChange={handleChangeSearchText}
          />
        </Col>
        <Col lg={1} sm={3}>
          <ToggleFilterButton smallMode>
            <Row>
              <Col md={6}>
                <SelectField
                  label="front.credits-page.credit-type.label"
                  name="subType"
                  options={typeOptions}
                  clearable
                />
              </Col>
              <Col md={6}>
                <SelectField
                  label="front.working-documents-filter-form.currency.label"
                  name="currency"
                  options={currencyOptions}
                  clearable
                />
              </Col>
              <Col md={4}>
                <AmountField
                  label="front.working-documents-filter-form.amount-from.label"
                  name="minAmountLimit"
                />
              </Col>
              <Col md={4}>
                <AmountField
                  label="front.working-documents-filter-form.amount-to.label"
                  name="maxAmountLimit"
                />
              </Col>
              <Col md={4}>
                <CustomerSelect
                  label="front.internal-payment-page.order-payer-name.label"
                  name="customerIds"
                  multi
                />
              </Col>
            </Row>
          </ToggleFilterButton>
        </Col>
      </Row>
    </div>
  );
};

export const CreditsFilter = withForm(CreditsFilterForm);
