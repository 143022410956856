import * as React from 'react';

import { api } from 'api';
import { ExportType } from 'api/ExportService';
import { ExportButton } from 'components/buttons/ExportButton';
import { ExportFormatEnum } from 'components/ExportDropdown/enum';
import {
  PrintOnePdfInAnotherFile,
  PrintOnePdfInOneFile,
  PrintTwoPdfInOneFile,
} from 'components/icons';
import {
  ItemLayoutEnum,
  PrintOption,
  PrintPdfEnum,
  PropsType,
} from 'components/PrintingDocuments/types';
import { translate } from 'i18n/translator';
import { handleError } from 'utils/handleError';
import { noop } from 'utils/noop';

import './styles.scss';

export const DEFAULT_WIDTH = 864;

const PrintOptions: { [key in keyof typeof PrintPdfEnum]: PrintOption } = {
  [PrintPdfEnum.OneDocOneFile]: {
    value: PrintPdfEnum.OneDocOneFile,
    label: 'front.print-document.one-doc-one-file.label',
    layout: ItemLayoutEnum.OnePageOneItem,
    isExportItemSeparatelyFile: false,
    icon: <PrintOnePdfInOneFile />,
  },
  [PrintPdfEnum.OneDocAnotherFile]: {
    value: PrintPdfEnum.OneDocAnotherFile,
    label: 'front.print-document.one-doc-another-file.label',
    layout: ItemLayoutEnum.OnePageOneItem,
    isExportItemSeparatelyFile: true,
    icon: <PrintOnePdfInAnotherFile />,
  },
  [PrintPdfEnum.TwoDocOneFile]: {
    value: PrintPdfEnum.TwoDocOneFile,
    label: 'front.print-document.2-doc-one-file.label',
    layout: ItemLayoutEnum.OnePageTwoItems,
    isExportItemSeparatelyFile: false,
    icon: <PrintTwoPdfInOneFile />,
  },
};

const isVisibleTwoItemsOption = (code: ExportType) =>
  code === ExportType.DOMESTIC_PAYMENT_ORDERS ||
  code === ExportType.EXPOSED_ORDERS ||
  code === ExportType.ACCOUNT_TRANSACTIONS;

export const PrintingDocuments = ({ data, objectCode }: PropsType) => {
  const handleClick =
    ({ isExportItemSeparatelyFile, layout }: PrintOption) =>
    async () => {
      if (objectCode === ExportType.ACCOUNT_TRANSACTIONS) {
        const ids = data.map(o => o.id);
        if (isExportItemSeparatelyFile) {
          for (const id of ids) {
            await api.export.exportAccountTransactions({
              ids: [id],
              objectCode,
              layout,
              format: ExportFormatEnum.PDF,
            });
          }
          return;
        }
        await api.export.exportAccountTransactions({
          ids,
          objectCode,
          layout,
          format: ExportFormatEnum.PDF,
        });
        return;
      }

      const orderIds = data.map(o => o.id);
      if (isExportItemSeparatelyFile) {
        for (const id of orderIds) {
          try {
            await api.export.exportOrders({
              orderIds: [id],
              objectCode,
              layout,
              format: ExportFormatEnum.PDF,
            });
          } catch (e: any) {
            handleError(e, noop);
          }
        }
        return;
      }

      try {
        await api.export.exportOrders({
          orderIds,
          objectCode,
          layout,
          format: ExportFormatEnum.PDF,
        });
      } catch (e: any) {
        handleError(e, noop);
      }
    };

  return (
    <div className="printing-document">
      <h2 className="name">{translate('front.print-document.print-type.label')}</h2>
      <div className="wrapper">
        <ExportButton option={PrintOptions[PrintPdfEnum.OneDocOneFile]} onClick={handleClick} />
        {data?.length > 1 && [
          <ExportButton
            option={PrintOptions[PrintPdfEnum.OneDocAnotherFile]}
            onClick={handleClick}
          />,
          isVisibleTwoItemsOption(objectCode) && (
            <ExportButton option={PrintOptions[PrintPdfEnum.TwoDocOneFile]} onClick={handleClick} />
          ),
        ]}
      </div>
    </div>
  );
};
