import * as React from 'react';
import { Marker, InfoWindow } from 'react-google-maps';
import { useLocation } from 'react-router';

import { api } from 'api';
import { parseUrlParams } from 'api/backend';
import { Department } from 'api/DepartmentsService';
import { Map, Position } from 'components/Map/Map';
import config from 'config';

import { getNearestPoint, Point, toPoints } from './utils';

import './styles.scss';

const initialPosition: Position = {
  lat: config.page.department.defaultLatitude,
  lng: config.page.department.defaultLongitude,
};

export const DepartmentsMapPage = () => {
  const [activePoint, setActivePoint] = React.useState<Point>();
  const [points, setPoints] = React.useState([] as Point[]);

  React.useEffect(() => {
    initializeMap();
  }, []);

  const location = useLocation();

  const initializeMap = async (): Promise<void> => {
    const departments = await api.departments.getDepartments();
    setInitPosition(departments.rows);
  };

  const setInitPosition = async (departments: Department[] = []): Promise<void> => {
    const { id } = parseUrlParams(location.search);
    const points = toPoints(departments);

    const activePoint = Number(id)
      ? points.find(item => item.id === Number(id))
      : await getNearestPoint(points);

    setActivePoint(activePoint);
    setPoints(points);
  };

  const handlePointClick = (activePoint: Point): void => setActivePoint(activePoint);

  const renderMarkers = (): React.ReactNode =>
    points.map(point => (
      <Marker
        icon={config.page.department.markerUrl}
        key={`point-${point.id}`}
        position={point.position}
        onClick={() => handlePointClick(point)}
      >
        {activePoint && point.id === activePoint.id && (
          <InfoWindow>
            <div className="departments-map__info-window">
              {point.content}
              <div>
                <br />
                {point.addInfo}
              </div>
            </div>
          </InfoWindow>
        )}
      </Marker>
    ));

  return (
    <div className="departments-map">
      <Map center={activePoint ? activePoint.position : initialPosition}>{renderMarkers()}</Map>
    </div>
  );
};
