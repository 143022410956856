import * as React from 'react';
import { Col, Row } from 'react-grid';
import { useLocation } from 'react-router';

import moment from 'moment';

import { api } from 'api';
import { parseUrlParams } from 'api/backend';
import { TransactionHistoryStatus } from 'api/enums';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { ExportFormatEnum } from 'components/ExportDropdown/enum';
import { ExportDropdown, getExportActions } from 'components/ExportDropdown/ExportDropdown';
import { getPreviousDay } from 'components/forms/datePicker/utils';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { DateRangeField } from 'components/forms/inputs/DateRangeField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextField } from 'components/forms/inputs/TextField';
import { withForm } from 'components/forms/withForm';
import { PageHeader } from 'components/layout/Page/PageHeader';
import { SearchInput } from 'components/searchInput/SearchInput';
import { CreateButton } from 'components/Table/CreateButton';
import { useFilter } from 'components/Table/DataTable';
import { ToggleFilterButton } from 'components/Table/ToggleFilterButton/ToggleFilterButton';
import { FilterTabBar } from 'components/tabs/FilterTabBar';
import { timeDateFull } from 'components/utils/format';
import { translate } from 'i18n/translator';
import { DisabledBlock } from 'navigations/access';
import { TRANSACTION_HISTORY } from 'navigations/pages';
import { AccountStatementFilterEnum } from 'pages/AccountStatements/enums';
import { useStatementsStorageFilter } from 'pages/AccountStatements/useStatementsStorageFilter';
import { TabOptions, TransactionHistoryFilterEnum } from 'pages/TransactionsHistory/enums';
import { ExtraFields, TransactionsHistoryFields } from 'pages/TransactionsHistory/interfaces';
import { CLICK_COUNT, getAccountIds } from 'pages/TransactionsHistory/utils';

const EXECUTED = 'executed';

const transactionFilterOptions = [
  {
    value: TabOptions.all,
    label: 'front.transactions-history-filter.statuses-all.label',
  },
  {
    value: TabOptions.in,
    label: 'front.transactions-history-filter.statuses-earnings.label',
  },
  {
    value: TabOptions.out,
    label: 'front.transactions-history-filter.statuses-write-off.label',
  },
  {
    value: TabOptions.info,
    label: 'front.transactions-history-page.tab-info-status.label',
  },
];

const [start, end] = getPreviousDay();

const EXPORT_TYPES: Record<string, ExportFormatEnum> = {
  XLS: ExportFormatEnum.EXCEL,
  CSV: ExportFormatEnum.CSV,
  DBF: ExportFormatEnum.DBF,
  ['DBF OB']: ExportFormatEnum.DBF_OB,
  ['DBF_420']: ExportFormatEnum.DBF_MOD_OB,
};

const today = moment().toDate();

const TransactionsHistoryFilterForm = () => {
  const onMount = () => fetchData();

  const {
    applyFilter,
    updateFilter,
    extraFields,
    updateExtraFields,
    getFormData,
    updateData,
    setData,
    setProgress,
    rows,
    gridRequest,
    setPagination,
    payload,
    setPayload,
  } = useFilter<TransactionsHistoryFields, ExtraFields>({
    fetchOnDemand: true,
    extraFields: {
      searchText: '',
      transactionType: TabOptions.all,
      statuses: [TransactionHistoryStatus.EXECUTED],
    },
    fetchInitial: onMount,
  });

  const { storageRevaluationValue, storageShowAdditionalDetails, onUpdateStorage } =
    useStatementsStorageFilter(TRANSACTION_HISTORY);

  const { accountId } = parseUrlParams(useLocation().search);

  const fetchData = async (customerIds?: string[]) => {
    const accountOptions = await api.payments.getAccountsForStatementOptions(customerIds);

    setData({
      accountIds: getAccountIds(accountOptions, accountId),
    });
    setPayload({ accountOptions });
  };

  const onCustomerSelect = async (value: string[]) => {
    setProgress(true);
    updateData({ accountIds: null });
    await fetchData(value);
    setProgress(false);
  };

  const formData = getFormData();

  const onResetData = (customerId?: string) => {
    setData({
      [AccountStatementFilterEnum.WithRevaluation]: storageRevaluationValue,
      [AccountStatementFilterEnum.ShowAdditionalDetails]: storageShowAdditionalDetails,
    });

    fetchData([customerId]);
  };

  const onUpdateFilter = () => {
    const { withRevaluation, showAdditionalDetails } = formData;

    return onUpdateStorage({ withRevaluation, showAdditionalDetails });
  };

  const exportedFData = {
    ...formData,
    searchText: extraFields.searchText,
    transactionType: extraFields.transactionType,
    statuses: extraFields.statuses,
    ids: rows.map(r => r.id),
  };

  const exportActions = React.useMemo(() => {
    const action = (exportType: string) => async () =>
      await api.payments.exportTransactionHistory({
        ...exportedFData,
        exportType,
        sort:
          gridRequest.sort === EXECUTED
            ? `${EXECUTED}|${gridRequest.order}, produced`
            : (gridRequest.sort as string),
        order: gridRequest.order,
      });

    return getExportActions({
      action,
      exportTypes: EXPORT_TYPES,
    });
  }, [JSON.stringify(exportedFData), gridRequest]);

  const { customerIds, dateFrom, dateTo, accountIds } = formData;
  const { accountOptions } = payload;

  const onChange = (value: string) => {
    const filter = {
      accountIds,
      customerIds,
      dateFrom,
      dateTo,
    };

    let typeAndStatus: Partial<ExtraFields> = {};

    if (value === TabOptions.info) {
      typeAndStatus = {
        statuses: [TransactionHistoryStatus.INFO, TransactionHistoryStatus.INTERNAL],
        transactionType: null,
      };
    } else {
      typeAndStatus = {
        statuses: [TransactionHistoryStatus.EXECUTED],
        transactionType: value as TabOptions,
      };
    }
    updateFilter({ ...filter, ...typeAndStatus });
    updateExtraFields(typeAndStatus);
  };

  const handleApplyFilter = async (e?: any) => {
    //transfer changes to CreateButton component
    if (e.detail > CLICK_COUNT) {
      return;
    }

    updateData({
      time: moment().toISOString(),
    });
    setPagination(0);
    await applyFilter();
  };

  const handleSearch = (searchText: { searchText: string }) => {
    updateFilter({ ...searchText });
    updateExtraFields({ ...searchText });
  };

  return (
    <div>
      <FilterTabBar
        onChange={onChange}
        value={extraFields.transactionType || TabOptions.info}
        options={transactionFilterOptions}
        setPagination={setPagination}
      />
      {!extraFields.statuses?.includes(TransactionHistoryStatus.INFO) && (
        <Row>
          <Col md={6}>
            <p>{`${translate('front.current-date.label')} ${
              formData.time ? timeDateFull(formData.time) : timeDateFull(moment().toDate())
            }`}</p>
          </Col>
          <Col md={6}>
            <PageHeader.Right>
              <DisabledBlock disabled={rows.length === 0} isInline>
                <ExportDropdown actions={exportActions} />
              </DisabledBlock>
            </PageHeader.Right>
          </Col>
        </Row>
      )}
      <Row>
        <Col xl={2} md={4}>
          <CreateButton
            title="front.account-statements-filter-form.apply-button.label"
            onClick={handleApplyFilter}
          />
        </Col>
        <Col xl={10} md={8}>
          <Row>
            <Col lg={3} md={8}>
              <SearchInput
                placeholder={translate('front.working-documents-table.filter-search.label')}
                value={extraFields.searchText}
                onChange={searchText => handleSearch({ searchText })}
              />
            </Col>
            <Col lg={0.5} md={2} />
            <Col lg={4}>
              <SelectField
                name={TransactionHistoryFilterEnum.AccountIds}
                label="front.account-statements-filter-form.account-number.label"
                options={accountOptions}
                multi
                clearable
                required
              />
            </Col>
            <Col lg={3}>
              <DateRangeField
                label="front.account-statements-filter-form.date-range.label"
                nameFrom={TransactionHistoryFilterEnum.DateFrom}
                nameTo={TransactionHistoryFilterEnum.DateTo}
                defaultFrom={start}
                defaultTo={end}
                disabledDaysFrom={{
                  before: null,
                  after: today,
                }}
                clearable
                required
              />
            </Col>
            <Col lg={1.5}>
              <ToggleFilterButton
                smallMode
                fetchOnApply={false}
                onResetFilter={onResetData}
                onApplyFilter={onUpdateFilter}
                onCloseFilter={onUpdateFilter}
              >
                <Row>
                  <Col md={4}>
                    <AmountField
                      label="front.working-documents-filter-form.amount-from.label"
                      name={TransactionHistoryFilterEnum.AmountFrom}
                    />
                  </Col>
                  <Col md={4}>
                    <AmountField
                      label="front.working-documents-filter-form.amount-to.label"
                      name={TransactionHistoryFilterEnum.AmountTo}
                    />
                  </Col>
                  <Col md={4}>
                    <TextField
                      label="front.transactions-history-page.cols-number.label"
                      name={TransactionHistoryFilterEnum.DocNumber}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      label="front.transactions-history-page.cols-counterparty-edrpou.label"
                      name={TransactionHistoryFilterEnum.ContragentTax}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      label="front.transactions-history-page.cols-counterparty-name.label"
                      name={TransactionHistoryFilterEnum.ContragentName}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      label="front.transactions-history-page.cols-purpose-of-payment.label"
                      name={TransactionHistoryFilterEnum.Details}
                    />
                  </Col>
                  <Col md={6}>
                    <CustomerSelect
                      label="front.internal-payment-page.order-payer-name.label"
                      name={TransactionHistoryFilterEnum.CustomerIds}
                      onSelectOption={onCustomerSelect}
                      multi
                    />
                  </Col>
                  <Col md={6} vAlign="middle">
                    <CheckboxField
                      label="front.account-statements-filter-form.show-revaluation.label"
                      name={TransactionHistoryFilterEnum.WithRevaluation}
                      defaultValue={storageRevaluationValue ?? true}
                    />
                  </Col>
                  <Col md={6}>
                    <CheckboxField
                      label="front.account-statements-filter-form.show-additional-details.label"
                      name={TransactionHistoryFilterEnum.ShowAdditionalDetails}
                      defaultValue={storageShowAdditionalDetails}
                    />
                  </Col>
                </Row>
              </ToggleFilterButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
    </div>
  );
};

export const TransactionsHistoryFilter = withForm(TransactionsHistoryFilterForm);
