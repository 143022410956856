import React from 'react';

import { FileType } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { DateField } from 'components/forms/inputs/DateField';
import { FileField } from 'components/forms/inputs/FileField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { useDisabledCustomer } from 'hooks/useDisabledCustomer';
import { useFileExtensions } from 'hooks/useFileExtensions';
import { EmployeeManagementNames } from 'pages/Demands/EmployeeManagment/enums';
import {
  EmployeeReissueFields,
  EmployeeReissuePayload,
} from 'pages/Demands/EmployeeManagment/interfaces';
import { isSignedDocuments } from 'pages/Demands/utils';
import { today } from 'pages/Demands/utils';

type DismissalPayload = Omit<EmployeeReissuePayload, 'reissueReason'>;

export const EmployeeDismissalDetails = () => {
  const {
    order,
    payload: { salaryDeal, employeeList },
    onChangeCustomer,
    form: { getFieldValue },
  } = useOrderDetails<EmployeeReissueFields, DismissalPayload>();

  const isDisabledCustomer = useDisabledCustomer(order);
  const fileExtensions = useFileExtensions();

  const hasSalaryDeal = !!getFieldValue(EmployeeManagementNames.SalaryDeal);

  return (
    <DefaultForm>
      <CustomerSelect
        name={EmployeeManagementNames.Customer}
        label="front.employee-management.organization.label"
        onSelectOption={onChangeCustomer}
        selectDefaultCustomer
        disabled={isDisabledCustomer}
        required
      />
      <SelectField
        name={EmployeeManagementNames.SalaryDeal}
        label="front.employee-management.salary-deal.label"
        options={salaryDeal}
        required
      />
      <DateField
        name={EmployeeManagementNames.DismissalDate}
        label="front.employee-management.dismissal-date.label"
        initialDate={today}
        minDate={today}
        disabledDays={{ before: today }}
        required
      />
      <SelectField
        name={EmployeeManagementNames.EmployeeList}
        label="front.employee-management.employee-list.label"
        options={employeeList}
        onlySearch
        disabled={!hasSalaryDeal}
        required={hasSalaryDeal}
      />
      <FileField
        name={EmployeeManagementNames.Attach}
        label="front.employee-management.attachments.label"
        outputFormat={FileType.BASE_64}
        accept={fileExtensions}
        isEDSSignedDocuments={isSignedDocuments(order?.state)}
        multiple
      />
    </DefaultForm>
  );
};
