import * as React from 'react';
import { Col, Row } from 'react-grid';

import { api } from 'api';
import { IMPORT_TYPES } from 'api/Service';
import { Button } from 'components/buttons/Button';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { DateRangeField } from 'components/forms/inputs/DateRangeField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextField } from 'components/forms/inputs/TextField';
import { withForm } from 'components/forms/withForm';
import { SearchInput } from 'components/searchInput/SearchInput';
import { CreateButton } from 'components/Table/CreateButton';
import { useFilter } from 'components/Table/DataTable';
import { ToggleFilterButton } from 'components/Table/ToggleFilterButton/ToggleFilterButton';
import { FilterTabBar } from 'components/tabs/FilterTabBar';
import { useStoragePeriod } from 'hooks/useStoragePeriod';
import { translate } from 'i18n/translator';
import { deniedPrivilegy } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { CURRENCY_EXCHANGE, pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { NEW } from 'navigations/routes';
import { CurrencyExchangeFilterEnum } from 'pages/Demands/CurrencyExchange/enums';

const foreignDemandsTypes = [
  {
    value: 'CurrencyBuy',
    label: 'front.foreign-demands.filter-applications-to-purchase-foreign-currency.label',
  },
  {
    value: 'CurrencySale',
    label: 'front.foreign-demands.filter-applications-for-currency-sale.label',
  },
  {
    value: 'CurrencyConversion',
    label: 'front.foreign-demands.filter-requests-for-currency-conversion.label',
  },
];

const CurrencyExchangeFilterForm = () => {
  const onMount = async ({ setPayload }: { setPayload: (payload: any) => void }) =>
    setPayload({ currencies: await api.currency.getCurrencyList() });

  const { updateFilter, extraFields, payload, setPagination } = useFilter({
    extraFields: { searchText: '', orderType: foreignDemandsTypes[0].value },
    fetchInitial: onMount,
  });

  const { datePeriodFrom, datePeriodTo, onApplyPeriodChanges, clearPeriod } =
    useStoragePeriod(CURRENCY_EXCHANGE);

  const hasViewImport =
    extraFields.orderType === IMPORT_TYPES.CURRENCY_BUY &&
    !deniedPrivilegy([Privileges.importCurrencyBuy]);

  return (
    <div>
      <FilterTabBar
        onChange={orderType => updateFilter({ orderType })}
        value={extraFields.orderType}
        options={foreignDemandsTypes}
        setPagination={setPagination}
      />
      <Row>
        <Col lg={6}>
          <CreateButton
            onClick={() =>
              goto(
                pages.currencyExchanges.tabs.details(NEW, {
                  type: extraFields.orderType as any,
                }),
              )
            }
            title="front.demand-filter.create-order.label"
            disabled={deniedPrivilegy([Privileges.demandCurrencyExchangeEdit])}
          />
          {hasViewImport && (
            <Button
              label={translate('front.internal-payment-page.import-button.label')}
              color="secondary"
              onClick={() => {
                goto(pages.import, { type: IMPORT_TYPES.CURRENCY_BUY });
              }}
            />
          )}
        </Col>
        <Col lg={6}>
          <Row>
            <Col lg={7} md={8}>
              <SearchInput
                placeholder={translate('front.working-documents-table.filter-search.label')}
                value={extraFields.searchText}
                onChange={searchText => updateFilter({ searchText })}
              />
            </Col>
            <Col lg={5} md={4}>
              <ToggleFilterButton
                onApplyFilter={onApplyPeriodChanges}
                onCloseFilter={onApplyPeriodChanges}
                onResetFilter={clearPeriod}
              >
                <Row>
                  <Col md={6}>
                    <TextField
                      label="front.working-documents-filter-form.number.label"
                      name={CurrencyExchangeFilterEnum.OrderNumber}
                    />
                  </Col>
                  <Col md={6}>
                    <DateRangeField
                      label="front.working-documents-table.date-range.label"
                      nameFrom={CurrencyExchangeFilterEnum.DateFrom}
                      nameTo={CurrencyExchangeFilterEnum.DateTo}
                      defaultFrom={datePeriodFrom}
                      defaultTo={datePeriodTo}
                      clearable
                    />
                  </Col>
                  <Col md={4}>
                    <AmountField
                      label="front.working-documents-filter-form.amount-from.label"
                      name={CurrencyExchangeFilterEnum.AmountFrom}
                    />
                  </Col>
                  <Col md={4}>
                    <AmountField
                      label="front.working-documents-filter-form.amount-to.label"
                      name={CurrencyExchangeFilterEnum.AmountTo}
                    />
                  </Col>
                  <Col md={4}>
                    <SelectField
                      label="front.working-documents-filter-form.currency.label"
                      name={CurrencyExchangeFilterEnum.Currency}
                      options={payload.currencies}
                    />
                  </Col>
                </Row>
              </ToggleFilterButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export const CurrencyExchangeFilter = withForm(CurrencyExchangeFilterForm);
