import React from 'react';
import { Button as BsButton } from 'reactstrap';

import classNames from 'classnames';

import { translate } from 'i18n/translator';
import './styles.scss';

export type Size = 'sm' | 'md' | 'lg';

export interface Props {
  block?: boolean;
  children?: React.ReactNode;
  className?: string;
  close?: boolean;
  color?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  label?: string;
  onClick?: (e: any) => void;
  progress?: boolean;
  size?: Size;
  style?: React.CSSProperties;
  type?: string;
}

export const Button: React.FC<Props> = props => {
  const {
    progress,
    children,
    disabled,
    onClick,
    color,
    type,
    className,
    size,
    style,
    block,
    label,
    icon,
    ...rest
  } = props;

  return (
    <BsButton
      disabled={disabled || progress}
      onClick={onClick}
      color={color || 'primary'}
      type={(type as 'submit' | 'reset' | 'button') || 'button'}
      className={classNames('bs-button', className)}
      size={size || 'md'}
      style={style || {}}
      block={block}
      {...rest}
    >
      {progress ? (
        <span>
          <span className="spinner" />
          <span>{translate('front.form.await-button.label')}</span>
        </span>
      ) : (
        <span className="bs-button__label">
          {icon && <span>{icon}</span>}
          {label && <span>{translate(label)}</span>}
          {children}
        </span>
      )}
    </BsButton>
  );
};
