import { get } from 'api/backend';
import config from 'config';
import { LOCAL_STORAGE_KEY } from 'store/actions/types';
import { LocalStorage } from 'utils/LocalStorage';

interface TranslateObj {
  [key: string]: string;

  default: string;
}

function convertToDictionary() {
  const translations = require('./translations.csv');
  const dictionary: Obj<string> = {};

  for (let i = 0; i < translations.length; i += 1) {
    const row = translations[i];
    const { translation_key, output_channel, ...locales } = row;
    dictionary[translation_key] = locales;
  }

  return dictionary;
}

const dictionary = convertToDictionary();

export const getSafeTranslation = (key: string, defaultTranslate = '_'): string => {
  const translated = translate(key);
  return translated.startsWith('front') ? defaultTranslate : translated;
};

export function translate(key: string, lang?: string): string {
  return (
    /* sessionStorage[key] || */ (dictionary[key] &&
      dictionary[key][
        lang ??
          LocalStorage.getItem(LOCAL_STORAGE_KEY.ACTIVE_LOCALE, config.i18n.defaultLanguageCode)
      ]) ||
    key
  );
}

// Example TranslateObj;
// {
//   2: 'single translate',
//   3-11: 'range translate',
//   default: 'all other cases',
// }

export const withdrawTranslateByRange = (number: number, opt: TranslateObj, replace = false) => {
  let translation;
  for (const o in opt) {
    const arrayKeys = o.split('-');
    const [key] = arrayKeys;
    const value = opt[o];

    //  single number
    if (+key === number) {
      translation = value;
      break;
    }

    // count in range
    if (arrayKeys.length === 2) {
      const [min, max] = arrayKeys;

      if (number >= +min && number <= +max) {
        translation = value;
        break;
      }
    }
  }

  if (!translation) {
    translation = opt['default'];
  }

  return replace ? translate(translation).replace('{value}', `${number}`) : translate(translation);
};

export async function getDictionary(callback = () => {}) {
  sessionStorage.removeItem('dictionary_saved');
  return await get('/translate', { outputChannel: 'W' }).then(res => {
    if (Object.keys(res).length > 2) {
      Object.keys(res).map(key => (sessionStorage[key] = res[key]));
      sessionStorage['dictionary_saved'] = '1';
      callback();
    }
  });
}
