import React from 'react';

import Decimal from 'decimal.js-light';

import { NumericFormatField } from 'components/forms/amountInput/NumericFormat';
import { FieldInput } from 'components/forms/inputs/FieldInput';
import { PreloadField } from 'components/forms/inputs/PreoadField';
import { useField } from 'components/forms/ValidatingForm/components/useField';
import { toAmountFormatWithComma, toAmountFormatWithDot } from 'components/utils/format';
import { isExist } from 'utils/isData';

import { AmountProps } from './types';
import { useAmountField } from './useAmountField';

export const toMoneyAmount = (value: string | number): string => {
  if (!isExist(value) || value == '') {
    return null;
  }
  return new Decimal(value).toFixed(2);
};

export const AmountField = ({
  name,
  label,
  required,
  disabled,
  placeholder,
  defaultValue,
  autoFocus = false,
  isLargeInput,
  hasGrayBackground,
  autoComplete,
  onBlur,
  onChange,
  normalize = value => value,
  hasMoneyFormat = false,
  maxLength,
  ...otherProps
}: AmountProps) => {
  const { validate, handleFocus, handleBlur, isFocus, maskConfig } = useAmountField({
    ...otherProps,
    label,
    required,
    autoFocus,
    hasMoneyFormat,
    maxLength,
  });

  const onAmountFieldBlur = (baseOnBlur: () => void) => {
    baseOnBlur();
    handleBlur();
    onBlur && onBlur();
  };

  const onAmountFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    onBaseChange: (value: any) => void,
  ) => {
    const value = e.target.value || null;

    const normalizedValue = normalize(value);

    const correctValue = toAmountFormatWithDot(normalizedValue);

    onBaseChange(correctValue);
    onChange?.(correctValue);
  };

  const { field, form } = useField({ name, defaultValue, validate, required });

  if (!field && !form) {
    return null;
  }

  const isDisabled = disabled || form.disabled;
  const formattedValue = toAmountFormatWithComma(field.value as string);

  return (
    <PreloadField isLoading={form.initializing}>
      <FieldInput
        isFocus={isFocus}
        name={name}
        label={label}
        value={formattedValue}
        error={field.error}
        warning={field.warning}
        isDisabled={isDisabled}
        isLargeInput={isLargeInput}
      >
        <NumericFormatField
          id={name}
          type="text"
          placeholder={placeholder}
          disabled={isDisabled}
          value={formattedValue || ''}
          onChange={e => onAmountFieldChange(e, field.onChange)}
          onBlur={() => onAmountFieldBlur(field.onBlur)}
          onFocus={handleFocus}
          autoFocus={autoFocus}
          autoComplete={autoComplete || `new-${name}`}
          maskConfig={maskConfig}
          isLargeInput={isLargeInput}
          hasGrayBackground={hasGrayBackground}
          maxLength={maxLength}
        />
      </FieldInput>
    </PreloadField>
  );
};
