import * as React from 'react';

import { ExportWayOption } from 'components/ExportingDocuments/types';
import { PrintOption } from 'components/PrintingDocuments/types';
import { translate } from 'i18n/translator';

import { useExport } from '../ExportDropdown/useExport';
import { BeatLoader } from '../Loader/Loader';

import './export-button.scss';

interface ExportButtonProps {
  onClick: (option?: any) => () => Promise<void>;
  option: ExportWayOption | PrintOption;
  disabled?: boolean;
}

export const ExportButton = ({ option, onClick, disabled = false }: ExportButtonProps) => {
  const { loaders, createOnClick } = useExport();
  const isLoading = loaders[option.label];

  return isLoading ? (
    <div className="loading-container">
      <BeatLoader size={15} loading={isLoading} />
    </div>
  ) : (
    <div
      key={(option as ExportWayOption).type}
      className={disabled ? 'option-container-disabled' : 'option-container'}
      onClick={createOnClick(option.label, onClick(option))}
    >
      {option.icon}
      <p className="text">{translate(option.label)}</p>
    </div>
  );
};
