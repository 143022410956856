import { translate } from 'i18n/translator';
import { fillStringParams } from 'i18n/utils';

export const validateMessages = {
  invalidChars: 'front.form.invalid-chars.error',
  fieldRequired: 'front.form.required.error',
  onlyNum: 'front.form.number.error',
  invalidEmail: 'front.form.invalid-email.error',
  invalidPassport: 'front.demand-validation-page.passport-error.label',
  invalidIdCard: 'front.demand-validation-page.iscard-error.label',
  invalidPhone: 'front.form.account-phone.error',
  invalidFileType: (file: string) => fillStringParams('front.form.input-file-type.error', file),
  maxSizeFile: (val: number) => fillStringParams('front.form.max-size-file.error', val),
  incorrectSumFormat: 'front.form.amount.error',
  futureDate: 'front.form.date-future.error',
  latinLetterNumberAndSymbol: 'front.form.only-latin-and-num.error',
  latinLetterAndSymbol: 'front.form.only-latin.error',
  invalidBic: 'front.form.invalid-bic-swift.error',
  passwordValidation: 'front.form.password-validation.error',
  passwordValidationPolicyAdd: translate('front.form.password-validation-policy-add.error'),
  passwordValidationPolicyCreate: translate('front.form.password-validation-policy-create.error'),
  passwordValidationSignCertificate: translate('front.certificates.error-modal-password.label'),
  passwordNoCyrillicSymbols: 'front.password.no-cyrillic-symbols.error.label',
  hasSpacesError: translate('front.foreign-transfer.validate-error-has-spaces'),
  passwordsEqual: 'front.form.password-equal.error',
  passwordsEqualKep: 'front.form.password-equal-kep.error',
  min: (amount: string, isoCode: string, label: string) =>
    fillStringParams(
      translate('front.form.amount-min.error').replace(
        '${fieldName}',
        `${translate(label).toLowerCase()}`,
      ),
      amount,
      isoCode,
    ),
  max: (amount: string, isoCode: string) =>
    fillStringParams('front.form.amount-max.error', amount, isoCode),
  mustBeNoEarlier: (value: string, period = '') =>
    translate('front.demand.dynamic-input.date-field.min-value.error.label')
      .replace('${value}', `${value}`)
      .replace('${period}', `${translate(period)}`),
  mustBeNoLater: (value: string, period = '') =>
    translate('front.demand.dynamic-input.date-field.max-value.error.label')
      .replace('${value}', `${value}`)
      .replace('${period}', `${translate(period)}`),

  maxLengthWithoutZero: (maxLength: number) =>
    translate('front.demand.validation-max-length-without-zero.label').replace(
      '${maxLength}',
      `${maxLength}`,
    ),
  notNull: translate('front.demand-form.field-not-null.error'),
  moreThan: (val: number) => fillStringParams('front.form.field-max-length.error', val),
  lessThan: (val: number) => fillStringParams('front.form.field-min-length.error', val),
  // TODO: replace is temporary solution
  shouldBe: (val: number) => fillStringParams('front.form.field-length.error', val),
  invalidAge: (val: number) => `You must be at least ${val} years old`,
  incorrectIban: 'front.form.invalid-iban.error',
  uploadFileError: 'front.form.upload-file.error',
  fileRequired: 'front.form.file-required.error',
  fileEmpty: 'front.form.attached-file-no-empty.error',
  allowedSymbols: 'front.form.allowed-symbols.error',
  invalidDocNumber: 'front.form.invalid-doc-number.error',
  valueIsNotInOptions: (value: string) =>
    fillStringParams('front.form.value-is-not-in-options.error', value),
  byBicAllowedVatType: (bankBic: string) =>
    fillStringParams('front.domestic-transfer.by-bic-allowed-vat-type-validation.label', bankBic),
};

export type ValidateMessages = typeof validateMessages;
