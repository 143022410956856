import * as React from 'react';

import { RegistrationResponse } from 'api/RegistrationService';

export interface RegistrationContextValue {
  errorMessage: string;
  orderId: number;
  phoneNumber: string;
  setOrderId(orderId: number): void;
  setPhoneNumber(uuid: string): void;
  setStep: React.Dispatch<React.SetStateAction<RegistrationResponse>>;

  setUuid(uuid: string): void;

  step: RegistrationResponse;

  uuid: string;
}

export const RegistrationContext = React.createContext<RegistrationContextValue>({
  orderId: null,
  setOrderId: () => {},
  uuid: null,
  setUuid: () => {},
  phoneNumber: null,
  setPhoneNumber: () => {},
  step: null,
  setStep: () => {},
  errorMessage: null,
});
