import * as React from 'react';

interface AccountIbanProps {
  iban: string;
}

export const AccountIban = ({ iban }: AccountIbanProps) => {
  if (!iban) {
    return null;
  }

  const code = iban.substr(0, 4);
  const mfo = iban.substr(4, 6);
  const zeroes = iban.substr(10, 5);
  const cardNumber = iban.substr(15);

  return (
    <span className="account-iban">
      <span className="account-iban__code">{code}</span>
      <span>{mfo}</span>
      <span className="account-iban__zeroes">{zeroes}</span>
      <span>{cardNumber}</span>
    </span>
  );
};
