import React from 'react';

import moment from 'moment';

import { api } from 'api';
import { CourierRegistration } from 'api/RegistrationService';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { DateField } from 'components/forms/inputs/DateField';
import { HiddenField } from 'components/forms/inputs/HiddenField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextField } from 'components/forms/inputs/TextField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { Page } from 'components/layout/Page/Page';
import { translate } from 'i18n/translator';
import { ActivationContext } from 'pages/Registration/activation/ActivationContext';

const CourierRegistrationPageForm: React.FC = () => {
  const { onCourierRegister, getCourierInfo } = useCourierRegistration();

  const onRegister = async (formData: CourierRegistration) => {
    await onCourierRegister(formData);
  };

  const { progress, error, handleSubmit } = useForm<CourierRegistration>(async ({ setFields }) => {
    setFields(await getCourierInfo());
  });

  return (
    <Page title="front.activation.courier-reg-page.title.label">
      <DefaultForm>
        <FormError>{error}</FormError>
        <DateField
          label="front.activation.courier-reg-page.field-courier-date.label"
          name="courierDate"
          minDate={moment().add(2, 'd').toDate()}
          maxDate={moment().add(10, 'd').toDate()}
          required
        />
        <SelectField
          label="front.activation.courier-reg-page.field-courier-time.label"
          name="courierTime"
          options={[
            { value: '9:00-12:00', label: '9-12' },
            { value: '12:00-15:00', label: '12-15' },
            { value: '15:00-18:00', label: '15-18' },
          ]}
          required
        />
        <TextField
          label="front.activation.courier-reg-page.field-courier-address.label"
          name="courierAddress"
          required
        />
        <CheckboxField
          label="front.activation.courier-reg-page.field-courier-check-by-phone.label"
          name="courierCheckByPhone"
        />
        <HiddenField name="courierCoordinate" defaultValue={''} />
        <Button
          color="primary"
          onClick={e => handleSubmit(onRegister, e)}
          progress={progress}
          className="login-page__form__btn"
          type="submit"
          size="lg"
        >
          {translate('front.activation.courier-reg-page.button-continue.label')}
        </Button>
      </DefaultForm>
    </Page>
  );
};

export const CourierRegistrationPage = withForm(CourierRegistrationPageForm);

const useCourierRegistration = () => {
  const { step, setStep } = React.useContext(ActivationContext);

  const getCourierInfo = async () => {
    return await api.registration.getCourierInfo(step.orderId);
  };

  const onCourierRegister = async (formData: CourierRegistration) => {
    setStep(await api.registration.confirmCourierInfo(formData));
  };

  return { getCourierInfo, onCourierRegister };
};
