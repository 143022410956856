export enum AcquiringFieldNames {
  Customer = 'customer',
  AcquiringMerchant = 'acquiringMerchant',
  AdressMerchant = 'adressMerchant',
  NewAcquiringMerchant = 'newAcquiringMerchant',
  NewAdressMerchant = 'newAdressMerchant',
  ModeTerm = 'modeTerm',
  NumberTerm = 'numberTerm',
  ExtTerm = 'extTerm',
  ConnectionType = 'connectionType',
  ParamTerm = 'paramTerm',
  DateInst = 'dateInst',
  RadioAcquiringNew = 'radioAcquiringNew',
  ContactData = 'contactData',
  Comment = 'comment',
  Attach = 'attach',
}
