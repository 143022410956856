import React from 'react';
import { useSelector } from 'react-redux';

import { useForm } from 'components/forms/ValidatingForm/useForm';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { PAYMENTS } from 'navigations/pages';
import { InternalPaymentFilterFormNames } from 'pages/Payments/InternalPayment/enums';
import { LOCAL_STORAGE_KEY } from 'store/actions/types';
import { selectChosenCustomersId } from 'store/selectors';

type StorageParams = {
  clearStorageParams: () => void;
  defaultAccountIds: string[];
  defaultCustomerIds: string[];
};

export const PARAMS_KEY = 'params';

export const useStorageFilterParams = (): StorageParams => {
  const { getFieldValue, updateFieldInData } = useForm();
  const defaultSelectedCustomerIds = useSelector(selectChosenCustomersId()).map(id => `${id}`);
  const isMultiCustomer = defaultSelectedCustomerIds.length > 1;

  const payerIds = getFieldValue(InternalPaymentFilterFormNames.PayerIds) ?? [];
  const customerIds = getFieldValue(InternalPaymentFilterFormNames.CustomerIds) ?? [];

  const [storageParams, setStorageParams] = useLocalStorage(
    LOCAL_STORAGE_KEY.PRESERVED_VALUES,
    `${PAYMENTS}.${PARAMS_KEY}`,
  );

  const {
    payerIds: defaultAccountIds = [],
    customerIds: defaultCustomerIds = [],
  }: Record<string, string[]> = storageParams ?? [];

  React.useEffect(() => {
    setStorageParams({
      ...storageParams,
      payerIds,
      customerIds,
    });
  }, [JSON.stringify(payerIds), JSON.stringify(customerIds)]);

  const clearStorageParams = () => {
    updateFieldInData(InternalPaymentFilterFormNames.PayerIds, 'defaultValue', []);
    isMultiCustomer &&
      updateFieldInData(InternalPaymentFilterFormNames.CustomerIds, 'defaultValue', []);

    setStorageParams({
      ...storageParams,
      payerIds: [],
      customerIds: [],
    });
  };

  const isAnyCustomerAbsentInSelectedCustomers = React.useMemo(
    () => defaultCustomerIds.some(customer => !defaultSelectedCustomerIds.includes(customer)),
    [JSON.stringify(defaultSelectedCustomerIds)],
  );

  return {
    defaultAccountIds,
    defaultCustomerIds: isAnyCustomerAbsentInSelectedCustomers ? [] : defaultCustomerIds,
    clearStorageParams,
  };
};
