import React from 'react';

import { translate } from 'i18n/translator';

interface ErrorDisableAndError<D> {
  disabled: D;
  error: string;
  setDisableAndError: (errorText: string, nameDisablingOption?: keyof D) => void;
}

export const useDisableAndError = <D = Record<string, boolean>>(): ErrorDisableAndError<D> => {
  const [error, setError] = React.useState<string>();
  const [disabled, setDisablingWays] = React.useState<D>({} as D);

  const setErrorAndDisable = (errorText: string, nameDisablingOption?: keyof D): void => {
    setError(translate(errorText));
    nameDisablingOption &&
      setDisablingWays({
        ...disabled,
        [nameDisablingOption]: true,
      });
  };
  return { error, disabled, setDisableAndError: setErrorAndDisable };
};
