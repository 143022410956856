import { Option } from 'api/Service';

export const getBicAndBankNameFromAccount = (
  accountNumber: string,
  localBanksOptions: Option[],
): { receiverBankName: string; receiverBic: string } => {
  const [, bic] = accountNumber.match(/^[A-Z]{2}\d{2}(\d{6})/);
  const bank = localBanksOptions?.find(bank => bank.value === bic);
  return { receiverBic: bic, receiverBankName: bank?.content?.bankName || '' };
};
