import * as React from 'react';
import { Col, Container, Row } from 'react-grid';
import reactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';

import { api } from 'api';
import { CheckSmsActionEnum, ConstantEnum } from 'api/enums';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { SmsField } from 'components/forms/SmsField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import { useCountdown } from 'hooks/useCountDown';
import { translate } from 'i18n/translator';
import { selectConstants } from 'store/selectors';

interface Fields {
  code: string;
}

interface Payload {
  smsLength: number;
}

const SMS_LENGTH = 6;

const SmsModalForm = ({
  resolve,
  action,
}: PropsWithConfirmModal<{ action: CheckSmsActionEnum }>) => {
  const smsValidPeriod = +useSelector(selectConstants())[ConstantEnum.SmsValidPeriod];

  const { progress, error, payload, handleSubmit, handleError, initializing } = useForm<
    Fields,
    Payload
  >(async ({ setPayload }) => {
    CheckSmsActionEnum.ADD_CERTIFICATE === action
      ? await api.sms.certificateSmsRequest()
      : await api.sms.confirmationRequest(action);

    setPayload({ smsLength: SMS_LENGTH });
  });

  const resendSms = async (e: React.MouseEvent<HTMLDivElement>) => {
    try {
      e.preventDefault();
      CheckSmsActionEnum.ADD_CERTIFICATE === action
        ? await api.sms.certificateSmsRequest()
        : await api.sms.confirmationRequest(action);

      startResendCounter();
    } catch (e: any) {
      handleError(e);
    }
  };

  const sendSms = async (formData: Fields): Promise<void> => {
    await api.sms.checkSmsCode({
      code: formData.code,
      action,
    });
    resolve(formData.code);
  };

  const { smsLength } = payload;

  const { time, start: startResendCounter, started } = useCountdown({ seconds: smsValidPeriod });

  React.useEffect(() => {
    if (!initializing && smsValidPeriod) {
      startResendCounter();
    }
  }, [initializing]);

  return (
    <div className="confirm-sms-modal">
      <div className="confirm-sms-modal__title">
        {translate('front.cert-page.sms-modal.sms-title.label')}
      </div>
      <div className="confirm-sms-modal__desc">
        {translate('front.cert-page.sms-modal.sms-sent-to-phone.label')}
      </div>
      <DefaultForm className="confirm-sms-modal__form">
        {error && <FormError>{reactHtmlParser(error)}</FormError>}
        <Container>
          <Row>
            <Col>
              <SmsField
                label="front.cert-page.sms-modal.input-code.label"
                name="code"
                length={smsLength}
                autoFocus
              />
            </Col>
            <Col>
              <div className="expired-sms">
                {!initializing && !started ? (
                  <span className="expired-sms__expired">
                    {translate('front.cert-page.sms-modal.session-expired.label')}
                  </span>
                ) : (
                  !initializing &&
                  started && (
                    <>
                      <span className="expired-sms__title">
                        {translate('front.cert-page.sms-modal.password-is-valid.label')}
                      </span>
                      <span className="expired-sms__timer">{time}</span>
                    </>
                  )
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="resend-sms" onClick={!started ? resendSms : () => {}}>
                <span className="resend-sms__title">
                  {translate('front.cert-page.sms-modal.sms-resend-button.label')}
                </span>
                {!initializing && started ? (
                  <span className="resend-sms__timer">
                    {translate('front.cert-page.sms-modal.sms-resend-timer.label')}
                    {` ${time}`}
                  </span>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row className="confirm-sms-modal__footer">
            <Col sm={6}>
              <Button
                onClick={e => handleSubmit(sendSms, e)}
                size="md"
                progress={progress}
                type="submit"
                className="confirm-sms-modal__footer__btn"
              >
                {translate('front.cert-page.sms-modal.sms-continue-button.label')}
              </Button>
            </Col>
            <Col sm={6}>
              <Button
                onClick={() => resolve(null)}
                color="secondary"
                size="md"
                className="confirm-sms-modal__footer__btn"
              >
                {translate('front.cert-page.sms-modal.sms-cancel-button.label')}
              </Button>
            </Col>
          </Row>
        </Container>
      </DefaultForm>
    </div>
  );
};

export const SmsModal = withForm(SmsModalForm);
