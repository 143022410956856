export enum ExportFormatEnum {
  EXCEL = 'EXCEL',
  CSV = 'CSV',
  DBF = 'DBF',
  DBF_MOD = 'DBF_MOD',
  PDF = 'PDF',
  MT = 'MT',
  MT940 = 'MT940',
  MT940f = 'MT940f',
  MT942 = 'MT942',
  XLS = 'XLS',
  XML = 'XML',
  XLS_PDF = 'XLS_PDF',
  DBF_OB = 'DBF_OB',
  DBF_MOD_OB = 'DBF_MOD_OB',
}

export enum ELabelTypes {
  text = 'text',
  button = 'button',
}
