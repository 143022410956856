import moment from 'moment';

import { api } from 'api';
import { logout } from 'api/backend';
import { Customer } from 'api/UserService';
import { ExpiredTimeModal } from 'components/ExpiredTimeModal/ExpiredTimeModal';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import config from 'config';
import { withdrawTranslateByRange } from 'i18n/translator';
import { ExpandedCustomer } from 'pages/Login/CustomerSelectModal/types';
import store from 'store';
import { UserActions } from 'store/actions/user';

const MINUTES_TO_WARNING = 3;

const translationObj = {
  1: 'front.modal-customer-warning-minute.text.label',
  default: 'front.modal-customer-warning-minutes.text.label',
};

class CustomerTimers {
  isStartTimers = false;
  actualCustomers: ExpandedCustomer[] = [];
  verificationPermissionsTimerId: number;

  setCustomers = (customers: Customer[]) => store.dispatch(UserActions.setCustomers(customers));

  getDifferenceTimeInMs = (validTo: Date) => {
    const date = moment().toDate();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();
    const currentDate = date.getDate();

    const a = moment(validTo);
    a.set('year', currentYear);
    a.set('month', currentMonth);
    a.set('date', currentDate);

    return a.diff(date, 'second') * 1000;
  };

  startTimers = (customers: ExpandedCustomer[], selectedCustomers: number[]) => {
    if (this.isStartTimers) return;

    this.isStartTimers = true;

    this.actualCustomers = customers.map(customer => {
      const isSelected = selectedCustomers.includes(customer.id);
      if (selectedCustomers.includes(customer.id) && !customer.disabled && !!customer.validTo) {
        const timerWarning = this.startTimerWarningCustomer(
          this.getDifferenceTimeInMs(customer.validTo),
          customer.name,
        );
        const timerExit = this.startTimerExitCustomer(
          this.getDifferenceTimeInMs(customer.validTo),
          customer,
        );

        return {
          ...customer,
          isSelected,
          timers: {
            timerExit,
            timerWarning,
          },
        };
      }

      return { ...customer, isSelected, timers: null };
    });

    // this.verificationPermissionsTimerId = window.setInterval(
    //   this.verificationAccessPermission,
    //   config.page.login.accessPermissionCheckInterval,
    // );
  };

  verificationAccessPermission = async () => {
    const { customers } = await api.user.getCustomersInfo();
    let needReloadTimers = false;
    const newChosenPersonIds: number[] = [];

    customers.forEach(person => {
      const customer = this.actualCustomers.find(customer => customer.id === person.id);
      if (!customer) return;

      if (person.disabled && customer.isSelected) {
        this.startTimerExitCustomer(0, customer);
        needReloadTimers = true;
        return;
      }

      if (customer.validFrom !== person.validFrom && customer.isSelected) {
        needReloadTimers = true;
      }

      if (customer.isSelected) {
        newChosenPersonIds.push(customer.id);
      }
    });

    if (needReloadTimers) {
      this.reloadTimers(customers, newChosenPersonIds);
    }
  };

  resetFilter = async (id: number) => {
    const actualCustomers = this.actualCustomers.map(c =>
      c.id === id ? { ...c, isSelected: false } : c,
    );
    const chosenCustomers = actualCustomers.filter(({ isSelected }) => isSelected);

    this.actualCustomers = actualCustomers;
    if (!chosenCustomers.length) return logout();
    this.setCustomers(actualCustomers);
    await api.user.chooseCustomers(
      chosenCustomers.map(c => c.id),
      false,
    );
  };

  resetTimers = () => {
    this.actualCustomers.map(customer => {
      if (!customer.timers) return customer;

      const { timerWarning, timerExit } = customer.timers;
      clearTimeout(timerWarning);
      clearTimeout(timerExit);

      return customer;
    });
    clearInterval(this.verificationPermissionsTimerId);
    this.isStartTimers = false;
  };

  reloadTimers = (customers: Customer[], chosenPersonsIds: number[]) => {
    this.resetTimers();
    this.startTimers(customers, chosenPersonsIds);
  };

  startTimerExitCustomer = (time: number, customer: ExpandedCustomer) => {
    return window.setTimeout(async () => {
      await confirmModal(ExpiredTimeModal, {
        title: 'front.modal-customer-expired-time.text.label',
        label: 'front.modal-customer-expired-time.button.label',
        listItem: {
          exitOrganizationName: customer.name,
        },
        mountEffect: () =>
          setTimeout(
            () => this.resetFilter(customer.id),
            config.page.login.resetFilterActiveCustomerTime,
          ),
      });
      await this.resetFilter(customer.id);
      location.reload();
    }, time);
  };

  startTimerWarningCustomer = (time: number, name: string) => {
    const timeLeft = Math.ceil(time / 1000 / 60);
    let title: string;

    if (timeLeft <= MINUTES_TO_WARNING) {
      title = withdrawTranslateByRange(timeLeft, translationObj, true);

      return window.setTimeout(
        async () =>
          await confirmModal(ExpiredTimeModal, {
            title,
            label: 'front.modal-customer-warning.button.label',
            listItem: {
              warningOrganizationName: name,
            },
          }),
        0,
      );
    }

    title = withdrawTranslateByRange(MINUTES_TO_WARNING, translationObj, true);

    return window.setTimeout(
      async () =>
        await confirmModal(ExpiredTimeModal, {
          title,
          label: 'front.modal-customer-warning.button.label',
          listItem: {
            warningOrganizationName: name,
          },
        }),
      time - MINUTES_TO_WARNING * 60 * 1000,
    );
  };
}

export const OrganizationTimers = new CustomerTimers();
