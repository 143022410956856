import React from 'react';

import { NumericFormatField } from 'components/forms/amountInput/NumericFormat';
import { FieldInput } from 'components/forms/inputs/FieldInput';
import { normalizeStringValue } from 'components/forms/ValidatingForm/utils';
import { toAmountFormatWithComma, toAmountFormatWithDot } from 'components/utils/format';
import { isString } from 'utils/isData';

import { AmountProps } from './types';
import { useAmountField } from './useAmountField';

interface AmountFieldStateInterface extends AmountProps {
  onBlur(value?: string): void;

  value?: string;
}

export const AmountFieldState = ({
  name,
  label,
  value,
  required,
  disabled,
  placeholder,
  defaultValue,
  autoFocus = false,
  isLargeInput,
  hasGrayBackground,
  autoComplete,
  onBlur: externalBlur,
  onChange,
  hasMoneyFormat = false,
  ...otherProps
}: AmountFieldStateInterface) => {
  const { validate, handleFocus, handleBlur, isFocus, maskConfig } = useAmountField({
    ...otherProps,
    label,
    required,
    autoFocus,
    hasMoneyFormat,
  });

  const [error, setError] = React.useState<string>(null);

  React.useEffect(() => {
    if (value) {
      setError(null);
    }
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target?.value || null;

    const correctValue = toAmountFormatWithDot(value);

    setError(null);
    onChange?.(correctValue);
  };

  const onBlur = () => {
    let normalizedValue = value || null;
    handleBlur();

    if (isString(normalizedValue)) {
      normalizedValue = normalizeStringValue(normalizedValue);
    }

    const error = validate(normalizedValue);
    error && setError(error);

    externalBlur(normalizedValue);
  };

  const formattedValue = toAmountFormatWithComma(value);

  return (
    <FieldInput
      isFocus={isFocus}
      name={name}
      label={label}
      value={formattedValue}
      error={error}
      isDisabled={disabled}
      isLargeInput={isLargeInput}
    >
      <NumericFormatField
        id={name}
        type="text"
        placeholder={placeholder}
        disabled={disabled}
        value={formattedValue}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={handleFocus}
        autoFocus={autoFocus}
        autoComplete={autoComplete || `new-${name}`}
        maskConfig={maskConfig}
        isLargeInput={isLargeInput}
        hasGrayBackground={hasGrayBackground}
      />
    </FieldInput>
  );
};
