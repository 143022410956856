import React from 'react';

import { api } from 'api';
import { DemandAction } from 'api/DemandsService/interfaces';
import { OrderActionType } from 'api/enums';
import { OrderAction } from 'api/OrderService';
import { FetchOrderCb, withOrder } from 'components/Document/withOrder';
import { confirmationModal } from 'components/modals/ConfirmModal';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { NavTab, NavTabs } from 'components/tabs/NavTabs';
import * as format from 'components/utils/format';
import { translate } from 'i18n/translator';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { getTranslateAction } from 'pages/Payments/general';
import { signKepCertificateOrders } from 'pages/utils/SignOrders/signOrders';

import { DoubleStatusForm } from './components/DoubleStatusFrom/DoubleStatusForm';
import RejectDocumentModal from './components/RejectDocumentModal/RejectDocumentModal';
import { DocumentManagementDetails } from './DocumentManagementDetails';
import { DocumentManagementDetailsNames } from './enums';
import { DocumentManagementDetailsFields } from './interfaces';
import { getCustomerIdsForSign, getDetailFields, isVisibleActionForV5 } from './utils';

const goBack = () => goto(pages.documentsManagement);

const fetchOrder: FetchOrderCb<DocumentManagementDetailsFields> = async ({ routeParams }) => {
  const { id } = routeParams;
  const order = await api.documentManagement.getOrderV5(id);

  return {
    id: +id,
    label: translate('front.document-management.document_signing_protocol.label'),
    number: order.base.number,
    type: order.base.type,
    state: order.base.state,
    stateTranslate: order.base.stateName,
    date: format.parseDate(order.base.date),
    actions: [
      ...actions(order.actions).filter(action => isVisibleActionForV5(order, action.name)),
      exportAttachmentButton,
    ],
    detailFields: await getDetailFields(order),
  };
};

const actions = (actions: DemandAction[]): OrderAction<DocumentManagementDetailsFields>[] => [
  {
    label: getTranslateAction(OrderActionType.SIGN),
    name: OrderActionType.SIGN,
    type: 'signButton',
    onClick: async order => {
      const savedOrder = await api.documentManagement.getOrderV5(order.id);
      const canSign = isVisibleActionForV5(savedOrder, OrderActionType.SIGN);

      if (!canSign) {
        return;
      }

      const isSuccess = await signKepCertificateOrders([
        {
          id: order.id,
          customerIds: getCustomerIdsForSign(
            order.detailFields[DocumentManagementDetailsNames.Customers],
            actions,
          ),
        },
      ]);

      if (!isSuccess) {
        await confirmationModal(
          'front.components.order-controls.save-request-in-status-entered.label',
          true,
          false,
        );
      }
    },
    privileges: [Privileges.protocolSign],
    reloadable: true,
  },
  {
    label: getTranslateAction(OrderActionType.REJECT),
    name: OrderActionType.REJECT,
    type: 'dropdown',
    onClick: async order =>
      void (await confirmModal<boolean>(RejectDocumentModal, { orderId: order.id }, 420)),
    closable: true,
  },
  {
    label: getTranslateAction(OrderActionType.DELETE),
    name: OrderActionType.DELETE,
    type: 'dropdown',
    onClick: async order => await api.payments.deleteOrder(order.id),
    confirmMessage: 'front.working-documents-table.actions-delete-confirmation.label',
    closable: true,
  },
  {
    label: getTranslateAction(OrderActionType.PRINT),
    name: OrderActionType.PRINT,
    type: 'dropdown',
    onClick: order => api.payments.getOrderPdf(order.id),
  },
];

const exportAttachmentButton: OrderAction<DocumentManagementDetailsFields> = {
  label: getTranslateAction(OrderActionType.EXPORT_ATTACHMENTS),
  name: OrderActionType.EXPORT_ATTACHMENTS,
  type: 'dropdown',
  onClick: async order => {
    for (const { downloadAction } of order.detailFields.attach) {
      await downloadAction();
    }
  },
};

const DocumentManagementComponent: React.FC = () => {
  // TODO uncomment when bank payed
  // const { order } = useOrder<any>();

  return (
    <NavTabs>
      <NavTab
        title={translate('front.demand-detail.details.label')}
        path={pages.documentManagement.tabs.details(':id')}
      >
        <DocumentManagementDetails />
      </NavTab>
      {/*// TODO uncomment when bank payed*/}
      {/*{!!order?.state && (*/}
      {/*  <NavTab*/}
      {/*    title={translate('front.demand-detail.history.label')}*/}
      {/*    path={pages.documentManagement.tabs.history(':id')}*/}
      {/*  >*/}
      {/*    <DocumentManagementHistoryPage />*/}
      {/*  </NavTab>*/}
      {/*)}*/}
    </NavTabs>
  );
};

export const DocumentManagementPage = withOrder<DocumentManagementDetailsFields, undefined>(() => ({
  fetchOrder,
  allowState: true,
  allowDetails: true,
  allowSign: true,
  showSignOnForm: true,
  afterSubmit: goBack,
  StateComponent: DoubleStatusForm,
}))(DocumentManagementComponent);
