import * as React from 'react';

import { api } from 'api';
import { Button } from 'components/buttons/Button';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextField } from 'components/forms/inputs/TextField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import { translate } from 'i18n/translator';
import { AllowForMultiuser } from 'navigations/access';
import { Privileges } from 'navigations/privileges';
import { normalize } from 'pages/Demands/utils';

type FormData = {
  customerId: string;
  roleId: string;
  roleName: string;
};

export const CreateRoleForm: React.FC<PropsWithConfirmModal> = ({ resolve }) => {
  const {
    progress,
    error,
    payload,
    setPayload,
    setInitializing,
    handleSubmit,
    getFieldValue,
    updateData,
  } = useForm<FormData>();

  const onSave = async ({ roleName, roleId, customerId }: FormData) => {
    const { activePrivileges } = await api.auth.getCustomerRole(roleId);

    await api.auth.createRole({
      customerId: +customerId,
      masterId: +roleId,
      privilegeIds: activePrivileges,
      name: {
        data: {
          en: roleName,
          ru: roleName,
          uk: roleName,
        },
      },
    });
    resolve();
  };

  const customerId = getFieldValue('customerId');

  const fetchRoles = async () => {
    if (!customerId) return;
    setInitializing(true);
    updateData({ roleId: null, roleName: null });
    const personRoleOptions = await api.auth.getPersonRoleOptions({
      customerId: `${customerId}`,
      isInternal: false,
      withEmptyCustomer: true,
    });
    setPayload({ personRoleOptions });
    setInitializing(false);
  };

  React.useEffect(() => {
    fetchRoles();
  }, [customerId]);

  return (
    <>
      <h3>{translate('front.role-page.create-title.label')}</h3>
      <DefaultForm>
        <FormError>{error}</FormError>
        <AllowForMultiuser>
          <CustomerSelect
            label="front.roles-page.filter-organizations.label"
            name="customerId"
            privilege={Privileges.manageUsersManageRoles}
            selectDefaultCustomer
            required
          />
        </AllowForMultiuser>
        <SelectField
          label="front.role-page.create-role.select-role-id.label"
          name="roleId"
          options={payload?.personRoleOptions}
          required
        />
        <TextField
          required
          label="front.role-page.create-role.input-role-name.label"
          name="roleName"
          normalize={normalize}
        />
        <Button disabled={progress} onClick={e => handleSubmit(onSave, e)}>
          {translate('front.role-page.create-role.create-button.label')}
        </Button>
        <Button color="secondary" onClick={() => resolve()}>
          {translate('front.role-page.create-role.cancel-button.label')}
        </Button>
      </DefaultForm>
    </>
  );
};

export const CreateRoleModal = withForm(CreateRoleForm);
