import * as React from 'react';
import { Col } from 'react-grid';

import * as Icon from 'components/icons';

interface Props {
  onSearchToken: () => void;
}

export const SearchTokenButton: React.FC<Props> = ({ onSearchToken }) => (
  <Col sm={2} hAlign="middle">
    <div className="update-token" onClick={onSearchToken}>
      <Icon.Update />
    </div>
  </Col>
);
