import * as React from 'react';
import { Col, Container, Row } from 'react-grid';
import reactHtmlParser from 'react-html-parser';

import { api } from 'api';
import { CertificateType, UserKeystoreType } from 'api/CertificateService/enums';
import { Certificate } from 'api/CertificateService/interfaces';
import { DataFromWebBank } from 'api/interfaces';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { PasswordField } from 'components/forms/inputs/PasswordField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { confirmationModal } from 'components/modals/ConfirmModal';
import { confirmModal, PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import { translate } from 'i18n/translator';
import { ReissueModalEnum } from 'pages/Profile/Certificates/enum';
import { confirmIssueCertificateUEP } from 'pages/Profile/Certificates/KepCertificate/Modals/IssueUepKeyModal';
import { ERROR_CODES } from 'pages/Profile/Certificates/KepCertificate/utils';
import { SaveAfterConfirmationParams } from 'pages/Profile/Certificates/Modals/ReissueModal/interfaces';
import { validatePasswordWithException } from 'pages/Profile/Certificates/PasswordValidator';
import { isESeal, parseErrorMessage } from 'pages/Profile/Certificates/utils';
import { ConfirmationIdentificationPage } from 'pages/Registration/ConfirmationIdentificationPage/ConfirmationIdentificationPage';
import { IdentificationProps } from 'pages/Registration/ConfirmationIdentificationPage/interfaces';

interface Fields {
  [ReissueModalEnum.Password]: string;
  [ReissueModalEnum.OldPin]: string;
  [ReissueModalEnum.NewPin]: string;
}

interface Props {
  certificate: Certificate;
}

const ReissueModalForm: React.FC<PropsWithConfirmModal<Props>> = ({ certificate, resolve }) => {
  const { error, progress, handleSubmit } = useForm<Fields>(async ({ setPayload }) => {
    const { customers } = await api.user.getCustomersInfo();

    setPayload({ customers });
  });

  const onSave = async (formData: Fields) => {
    const { newPin, oldPin } = formData;

    validatePasswordWithException(
      newPin,
      '',
      'front.cert-page.reissue-modal.input-new-password.label',
    );

    let dataProfile: DataFromWebBank;

    const saveAfterConfirmation = async ({
      dataHash,
      certificateType,
    }: SaveAfterConfirmationParams): Promise<boolean | unknown> => {
      try {
        await api.certificate.reissueCloudCertificate(
          certificate.id,
          oldPin,
          newPin,
          dataHash,
          certificateType,
        );

        return resolve(true);
      } catch (e: any) {
        return await confirmationModal(reactHtmlParser(parseErrorMessage(e)), false, false);
      }
    };

    const confirmPayload = {
      customerId: +certificate.customerId,
      keystoreType: UserKeystoreType.CloudStorage,
      certificateType: isESeal(certificate.certificateType)
        ? certificate.certificateType
        : CertificateType.Kep,
      isReissue: true,
    };

    try {
      dataProfile = await api.payments.getCurrentDataFromWebBank(confirmPayload);
    } catch (event: any) {
      const parsedError = JSON.parse(event.message);

      if (ERROR_CODES.includes(parsedError.code)) {
        try {
          const isConfirmedData = await confirmIssueCertificateUEP({
            confirmPayload: {
              ...confirmPayload,
              certificateType: CertificateType.Uep,
            },
            message: parseErrorMessage(event),
            saveAfterConfirmation,
          });

          if (!isConfirmedData) {
            return resolve(false);
          }
        } catch (event: any) {
          return await confirmationModal(parseErrorMessage(event), false, false);
        }
      } else {
        return await confirmationModal(parseErrorMessage(event), false, false);
      }
    }

    const isConfirmedData = await confirmModal<IdentificationProps>(
      ConfirmationIdentificationPage,
      { dataProfile, saveAfterConfirmation, certificateType: certificate.certificateType },
      640,
    );

    if (!isConfirmedData) {
      return resolve(false);
    }

    return resolve(true);
  };

  return (
    <div>
      <h3>{translate('front.cert-page.reissue-modal.title.label')}</h3>
      <DefaultForm disabled={progress}>
        {error && <FormError>{reactHtmlParser(error)}</FormError>}
        <PasswordField
          label="front.cert-page.reissue-modal.input-current-password.label"
          name={ReissueModalEnum.OldPin}
          minLength={6}
          maxLength={8}
          required
        />
        <PasswordField
          label="front.cert-page.reissue-modal.input-new-password.label"
          name={ReissueModalEnum.NewPin}
          minLength={6}
          maxLength={8}
          required
        />
        <Container>
          <Row>
            <Col sm={6}>
              <Button
                onClick={e => handleSubmit(onSave, e)}
                size="md"
                progress={progress}
                type="submit"
                block
              >
                {translate('front.cert-page.reissue-modal.reissue-button.label')}
              </Button>
            </Col>
            <Col sm={6}>
              <Button onClick={() => resolve(null)} color="secondary" size="md" block>
                {translate('front.cert-page.reissue-modal.cancel-button.label')}
              </Button>
            </Col>
          </Row>
        </Container>
      </DefaultForm>
    </div>
  );
};

export const ReissueModal = withForm(ReissueModalForm);
