import moment from 'moment';

import { UserKeystoreType } from 'api/CertificateService/enums';
import { Certificate } from 'api/CertificateService/interfaces';
import { ActionEnum } from 'api/enums';
import { StatusColor } from 'components/layout/Status';
import { translate } from 'i18n/translator';
import { BooleanString } from 'pages/Login/CustomerSelectModal/types';
import { FactorType } from 'pages/Login/LoginPage';

import { del, get, post, put } from './backend';
import { Lang } from './NotificationService';
import { getPage, GridRequest, GridResponse, Option } from './Service';

interface LoginRequest {
  login?: string;
  newpassword?: string;
  password?: string;
}

interface Factor {
  factorType: FactorType;
  isOptional: boolean;
}

export type FactorsChain = Factor[];

export interface LoginResponse {
  action?: string;
  certificates?: Array<Certificate>;
  factorsChain?: FactorsChain;
  fio?: string;
  isChangePass?: boolean;
  lastLogin?: string;
  phoneNumber?: string;
  privileges?: Array<string>;
  value?: string;
}

export interface GetUserSaltResponse {
  salt: string;
}

export interface Organization {
  blocked: boolean;
  name: string;
  region: string;
  taxCode: string;
}

export interface Company {
  id: number;
  organization: Organization;
}

export interface Role {
  code: string;
  deleted: boolean;
  id: number;
  name: string;
}

export interface UserAccount {
  firstName: string;
  fullName: string;
  id: number;
  iin: string;
  ipRestriction: string;
  lastName: string;
  login: string;
  middleName: string;
  status: string;
}

export enum PersonStatus {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  DELETED = 'DELETED',
}

export interface CustomerPerson {
  company: Company;
  dn: string;
  id: number;
  isDefaultForUser: boolean;
  isRegisteredOnVC: boolean;
  limits: Array<any>;
  quickLinks: string;
  resultPrivileges: Array<number>;
  roles: Array<Role>;
  status: PersonStatus;
  userAccount: UserAccount;
  organizationRole?: any;
  otp?: any;
  productsViewState?: string;
  sign_level?: string;
  valid_from?: Date;
  valid_to?: Date;
}

export interface PersonRole {
  customerId: number;
  id: number;
  isInternal: boolean;
  masterId: number;
  name: {
    data: {
      en: string;
      ru: string;
      uk: string;
    };
    id: number;
    text: string;
  };
  nameTranslation: string;
  privilegeIds: number[];
}

export interface SignatureRequest {
  sign: string;
}

export interface AuthorizedPerson {
  companyId: number;
  created: string;
  creatorType: string;
  currentRoles: Role[];
  customerName: string;
  firstName: string;
  fullName: string;
  id: number;
  lastName: string;
  login: string;
  middleName: string;
  organizationRole: string;
  signLevel: string;
  status: PersonStatus;
  taxCode: string;
  userAccountId: number;
}

export interface Privilegy {
  code: string;
  id: number;
  isActive: boolean;
  masterId: number;
  name: string;
}

export interface PersonPrivilegyGroup<P = Privilegy> extends Privilegy {
  privileges: P[];
  subGroups: PersonPrivilegyGroup<P>[];
}

export interface PersonPrivileges {
  activePrivileges: number[];
  groups: PersonPrivilegyGroup[];
  roleId: number;
  roleMasterId: number;
  roleName: string;
}

export interface PersonPrivilegesSave {
  privilegeIds: number[];
  customerId?: number;
  masterId?: number;
  name?: {
    data: { [key: string]: string };
  };
}

export interface CustomerRole {
  id: number;
  name: {
    data: Record<Lang, string>;
    id: number;
    text: string;
  };
  nameTranslation: string;
  customerId?: number;
  customerName?: string;
  masterId?: number;
  privilegeIds?: number[];
}

export interface DN {
  C: string;
  CN: string;
  edrpou: string;
  email: string;
  G: string;
  L: string;
  O: string;
  SN: string;
  ST: string;
  uid: string;
}

interface Config {
  avatar?: string;
  CHOSEN_CUSTOMERS?: number[];
  REMEMBER_CHOSEN_CUSTOMER?: BooleanString;
}

interface PersonRoleFilter {
  customerId: number | string;
  isInternal?: boolean;
  withEmptyCustomer?: boolean;
}

export const getStatusColor = (status: PersonStatus): StatusColor => {
  switch (status) {
    case PersonStatus.ACTIVE:
      return 'green';
    case PersonStatus.BLOCKED:
    case PersonStatus.DELETED:
      return 'red';
    default:
      return 'grey';
  }
};

const prepareTimeToApi = (time: string) => {
  return time.length === 5 ? moment(time, 'HH:mm').format('YYYY-MM-DDTHH:mm:ss') : time;
};

interface RolesRequest extends GridRequest {
  customerId?: string;
  forAll?: boolean;
}

export class AuthService {
  async login(loginRequest: LoginRequest): Promise<LoginResponse> {
    const { value, action, factorsChain, phoneNumber, certificates } = await post(
      '/customer/login',
      loginRequest,
    );

    const filteredFactors = factorsChain?.reduce((acc: FactorsChain, factor: Factor) => {
      if (factor.factorType === FactorType.Signature) {
        const filteredOnlyTokenCertificates = certificates?.filter(
          (cert: Certificate) => cert.userKeystoreType === UserKeystoreType.HardWired,
        );

        return filteredOnlyTokenCertificates.length ? [...acc, factor] : acc;
      }

      return [...acc, factor];
    }, [] as FactorsChain);

    return {
      value,
      action,
      factorsChain: filteredFactors,
      phoneNumber,
      certificates,
    };
  }

  async logout() {
    sessionStorage.setItem('isLogout', 'TRUE');
    return post('/customer/logout');
  }

  async signatureLogin(request: SignatureRequest): Promise<any> {
    return await post('/customer/signature-token', request);
  }

  async getUserSalt(login: string): Promise<GetUserSaltResponse> {
    return await get('/v1/customer/salt', { login });
  }

  async saveConfig(data: Config): Promise<any> {
    return await put('/v1/config/user', data);
  }

  async getPersons(req: GridRequest): Promise<GridResponse<AuthorizedPerson>> {
    return await get('/customer/users', req);
  }

  async getPerson(id: string): Promise<CustomerPerson> {
    return await get(`/customer/persons/${id}`);
  }

  async getPersonRoleOptions(params: PersonRoleFilter): Promise<Option<PersonRole>[]> {
    const personRoles: PersonRole[] = await get('/v1/permission/customer/roles', params);

    return personRoles.map(role => ({
      value: `${role.id}`,
      label: role.nameTranslation,
      content: role,
    }));
  }

  async getAllRoles({ customerId, ...request }: RolesRequest): Promise<GridResponse<CustomerRole>> {
    const showAllRequest: RolesRequest = { ...request, forAll: true, size: undefined };

    const roles = await get('/v1/permission/customer/roles', {
      customerIds: customerId,
      ...showAllRequest,
    });

    return getPage(roles, showAllRequest, null);
  }

  async deleteRole(id: number): Promise<any> {
    return await del(`/v1/permission/customer/roles/${id}`);
  }

  async getCustomerRole(id: string): Promise<PersonPrivileges> {
    return await get(`/v1/permission/customer/roles/${id}`);
  }

  async getCustomerRoleWithFiltering(id: string): Promise<PersonPrivileges> {
    const role: PersonPrivileges = await this.getCustomerRole(id);

    //TODO: use code instead of name and add tests
    const namesForFiltering = [
      translate('c.root.data.import'),
      translate('c.root.data.management.accounts'),
    ];

    const filteredGroupsByName = (groups: PersonPrivilegyGroup[]): PersonPrivilegyGroup[] =>
      groups.filter(group => {
        const hasFilteredName = namesForFiltering.some(name => group.name === name);

        if (hasFilteredName) {
          return false;
        }

        if (group.subGroups?.length) {
          group.subGroups = filteredGroupsByName(group.subGroups);
        }

        return true;
      });

    return { ...role, groups: filteredGroupsByName(role.groups) };
  }

  async createRole(role: PersonPrivilegesSave) {
    return await post('/v1/permission/customer/roles', role);
  }

  async saveRole(roleId: number, role: PersonPrivilegesSave) {
    return await put(`/v1/permission/customer/roles/${roleId}`, role);
  }

  async savePersonRole(personId: string, roleId: number | string) {
    return await put(`/v1/permission/person/${personId}/role/${roleId}`);
  }

  addRolesToPerson(personId: number, roleIds: number[]) {
    return post('/v1/customer/persons/set-roles', { personId, roleIds });
  }

  async getPersonPrivileges(id: string): Promise<PersonPrivileges> {
    return await get(`/v1/permission/person/${id}`);
  }

  async deleteWorkTime(id: string): Promise<void> {
    return await del(`/v1/customer/persons/${id}/worktime`);
  }

  async changeAccess(id: number, block: boolean): Promise<void> {
    return await put(`/v1/customer/persons/${id}/access`, {}, { block });
  }

  async saveWorkTime(
    id: string,
    { validFrom, validTo }: { validFrom: string; validTo: string },
  ): Promise<void> {
    return await put(
      `/v1/customer/persons/${id}/worktime`,
      {},
      {
        validFrom: prepareTimeToApi(validFrom),
        validTo: prepareTimeToApi(validTo),
      },
    );
  }

  setWorktime = async (
    id: string,
    { validFrom, validTo }: { validFrom: string; validTo: string },
  ): Promise<void> => {
    if (!validFrom && !validTo) {
      return await this.deleteWorkTime(id);
    }
    return await this.saveWorkTime(id, { validFrom, validTo });
  };

  async deleteIp(personId: string): Promise<void> {
    return await del('/v1/config/company-person', {}, { personId, code: 'IP_RESTRICTION' });
  }

  async saveIp(personId: string, ip: string): Promise<void> {
    return await post('/v1/config/person/ip', {}, { personId, ip });
  }

  setIp = async (personId: string, ip: string) => {
    if (!ip) {
      return await this.deleteIp(personId);
    }
    return await this.saveIp(personId, ip);
  };

  checkPassword = async (message: string, action: ActionEnum): Promise<string> => {
    return await post('/v1/ugb/confirmation-failed', { message, action });
  };
}
