import { ResponseDataException, Translation } from 'api/CertificateService/UmcaModule/interfaces';

const translations: Record<string, Translation> = {
  default: {
    uk: 'Неочікувана помилка!',
    ru: 'Неожиданная ошибка!',
    en: 'Unexpected error!',
  },
  ERR_INVALID_FUNCTION: {
    uk: 'Невірна функція.',
    ru: 'Неверная функция.',
    en: 'Invalid function.',
  },
  ERR_INVALID_OPERATION: {
    uk: 'Невірна операція.',
    ru: 'Неверная операция.',
    en: 'Invalid operation.',
  },
  ERR_INVALID_PARAM: {
    uk: 'Невірний параметр.',
    ru: 'Неверный параметр.',
    en: 'Invalid parameter.',
  },
  ERR_DATA_NOT_FOUND: {
    uk: 'Дані не знайдені.',
    ru: 'Данные не найдены.',
    en: 'Data not found.',
  },
  ERR_DATA_CORRUPTED: {
    uk: 'Дані пошкоджені.',
    ru: 'Данные повреждены.',
    en: 'Data is corrupted.',
  },
  ERR_ALG_NOT_FOUND: {
    uk: 'Алгоритм не підтримується.',
    ru: 'Алгоритм не поддерживается.',
    en: 'Algorithm is not supported.',
  },
  ERR_TRANSPORT_STATUS: {
    uk: 'Мережева помилка.',
    ru: 'Сетевая ошибка.',
    en: 'Network error.',
  },
  ERR_TSP_STATUS: {
    uk: 'Помилки сервісу TSP.',
    ru: 'Ошибка сервиса TSP.',
    en: 'TSP service error.',
  },
  ERR_IN_OUT: {
    uk: 'Помилка вводу-виводу.',
    ru: 'Ошибка ввода-вывода.',
    en: 'Input-output error.',
  },
  ERR_CORRUPTED: {
    uk: 'Дані пошкоджені.',
    ru: 'Данные повреждены.',
    en: 'Data is corrupted.',
  },
  KSE_NOT_IMPLEMENTED: {
    uk: 'Функція не реалізована на пристрої.',
    ru: 'Функция не реализована на устройстве.',
    en: 'Function is not implemented on the device.',
  },
  KSE_DATA_NOT_FOUND: {
    uk: 'Пристрій не знайдено.',
    ru: 'Устройство не найдено.',
    en: 'Device not found.',
  },
  KSE_STORE_GENERAL: {
    uk: 'Загальна помилка пристрою.',
    ru: 'Общая ошибка устройства.',
    en: 'General device error.',
  },
  KSE_INVALID_PASSWORD: {
    uk: 'Невірний ПІН-код.',
    ru: 'Неверный ПИН-код.',
    en: 'Invalid PIN.',
  },
  KSE_LOGIN_REQUIRED: {
    uk: 'Для роботи потрібен логін.',
    ru: 'Для работы необходим логин.',
    en: 'Login needs to work.',
  },
  KSE_STORE_LOCKED: {
    uk: 'Пристрій заблоковоно.',
    ru: 'Устройство заблокировано.',
    en: 'Device locked.',
  },
  KSE_STORE_NOMEMORY: {
    uk: "Недостатньо пам'яті на пристрої. Спробуйте видалити неактивні, зайві ключі",
    ru: 'Недостаточно памяти на устройстве. Попробуйте удалить неактивные, лишние ключи',
    en: 'Not enough memory on device. Try deleting inactive keys',
  },
  'umcaservice is unreachable': {
    uk: 'Пристрої або служба UmCAService недоступні.',
    ru: 'Устройства или служба UmCAService недоступны.',
    en: 'Devices or UmCAService are not available.',
  },
  'bad request': {
    uk: 'Невірний запит.',
    ru: 'Неверный запрос.',
    en: 'Bad request',
  },
  'Network Error': {
    uk: 'Помилка мережі',
    ru: 'Ошибка сети',
    en: 'Network Error',
  },
  'Access is denied': {
    uk: 'Неочікувана помилка!\nКлюч (файл), має атрибут "Тільки читання", у властивостях файлу зніміть галочку "Тільки читання" і продовжите операцію.',
    ru: 'Неожиданная ошибка!\nКлюч (файл), имеет атрибут "Только чтение", в свойствах файла снимите галочку "Только чтение" и продолжите операцию.',
    en: 'Unexpected error!\nThe key (file) has the attribute "Read Only", uncheck the box "Read Only" in the properties of the file and continue the operation.',
  },
};

export const getLocalizeErrorMessage = (
  exception: ResponseDataException,
  language: keyof Translation,
) => {
  const errors = Object.keys(translations);
  let error = exception.message ?? translations.default[language];

  for (let i = 0; i < errors.length; i += 1) {
    if (
      (exception.message || exception.statusText).toLowerCase().indexOf(errors[i].toLowerCase()) >
      -1
    ) {
      error = translations[errors[i]][language];
      break;
    }
  }

  return { ...exception, message: error };
};
