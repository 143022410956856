import { api } from 'api';
import { DemandAction } from 'api/DemandsService/interfaces';
import {
  AttachV5,
  Customer,
  OrderBaseV5,
  OrderResponseV5,
} from 'api/DocumentManagementService/interfaces';
import { OrderActionType } from 'api/enums';
import { Instruction } from 'api/HandbooksService';
import { Order } from 'api/OrderService';
import { Option } from 'api/Service';
import { StatusColor } from 'components/layout/Status';
import { translate } from 'i18n/translator';
import { isExist } from 'utils/isData';

import {
  DocumentManagementDetailsNames,
  DocumentManagementStateFilter,
  DocumentsManagementFilterEnum,
  StateTab,
} from './enums';
import {
  DocumentManagementDetailsFields,
  ExtraFields,
  FileInterface,
  FilterFields,
} from './interfaces';
import { DocumentManagementStateOptions, documentManagementTabs } from './options';

const STATUS_SEPARATOR = ':';

export const labelBankStatus = `${translate('front.menu.work-protocol.status.label')}: ${translate(
  'front.employees-filter.bank.label',
)}`;

export const labelCustomerStatus = `${translate(
  'front.menu.work-protocol.status.label',
)}: ${translate('front.demands-table.organization.label')}`;

export const isVisibleActionForV5 = (order: OrderResponseV5, actionName: OrderActionType) =>
  order.actions.some(item => item.actionName === actionName);

export const getDocumentManagementStatusColor = (status: string): StatusColor => {
  switch (status) {
    // TODO uncomment when bank payed
    // case DocumentManagementStateFilter.Signed:
    //   return 'green';
    // case DocumentManagementStateFilter.OnSigning:
    // case DocumentManagementStateFilter.PartiallySigned:
    // case DocumentManagementStateFilter.NeedSigning:
    // case DocumentManagementStateFilter.NotRequiredSign:
    //   return 'blue';
    // case DocumentManagementStateFilter.Rejected:
    //   return 'red';
    default:
      return 'grey';
  }
};

export const getSplitByStatusSeparator = (state: string): string[] => state.split(STATUS_SEPARATOR);

export const getStatus = (
  base: OrderBaseV5 | Order,
  isCustomerStatus = false,
): { state: string; translate: string } => {
  const [bankStatus, customerStatus] = getSplitByStatusSeparator(base.state);
  const [bankTranslate, customerTranslate] = getSplitByStatusSeparator(
    (base as OrderBaseV5).stateName ?? (base as Order).stateTranslate,
  );

  if (isCustomerStatus) {
    return { state: customerStatus, translate: customerTranslate };
  }

  return { state: bankStatus, translate: bankTranslate };
};

export const getOptionFromInstruction = (instruction: Instruction): Option<Instruction> => ({
  content: instruction,
  label: instruction.translatedName.text,
  value: `${instruction.id}`,
});

export const getCategoryAndTypeOptions = (
  protocolCategories: Instruction[],
): [Option<Instruction>[], Option<Instruction>[]] => {
  const types: Option<Instruction>[] = [];

  const categories = protocolCategories.map(category => {
    const { children } = category;
    types.push(...children.map(child => getOptionFromInstruction(child)));

    return getOptionFromInstruction(category);
  });

  return [categories, types];
};

export const getName = (categoryOrTypeId: string, options: Option<Instruction>[]): string =>
  options.find(({ value }) => value === categoryOrTypeId)?.label;

export const mapDoubleStateToSingle = (state: string): string =>
  state && getSplitByStatusSeparator(state)[0];

export const mapSingleStateToDouble = (
  option: Option<undefined, DocumentManagementStateFilter>,
  isBankState = true,
): Option<undefined> => ({
  ...option,
  value: isBankState ? `${option.value}:` : `:${option.value}`,
});

export const getStateOptions = (
  stateTab: StateTab,
): Option<undefined, DocumentManagementStateFilter>[] => {
  const filterParams = documentManagementTabs[stateTab];

  if (!stateTab && !filterParams) {
    return [];
  }

  const orderStates = filterParams?.[DocumentsManagementFilterEnum.OrderStates];
  const excludedStates = filterParams?.[DocumentsManagementFilterEnum.ExcludedStates];

  const usedStates = orderStates?.map(mapDoubleStateToSingle);
  const excluded = excludedStates?.map(mapDoubleStateToSingle);

  return DocumentManagementStateOptions.filter(
    ({ value }) =>
      (!usedStates?.length || usedStates?.includes(value)) && !excluded?.includes(value),
  );
};

export const getStatesOptions = (
  stateTab: StateTab,
): [Option<undefined>[], Option<undefined>[]] => {
  const stateOptions = getStateOptions(stateTab);
  const stateBankOptions = stateOptions.map(option => mapSingleStateToDouble(option));
  const stateCustomerOptions = stateOptions.map(option => mapSingleStateToDouble(option, false));
  return [stateBankOptions, stateCustomerOptions];
};

export const prepareFile = ({ dataId, name, signed }: AttachV5): FileInterface => ({
  id: dataId,
  field: name,
  signed,
  downloadAction: async () => {
    await api.demands.downloadDemandFile(dataId, false);
  },
});

export const prepareFakeFile = ({ dataId, name }: AttachV5): FileInterface => ({
  id: dataId,
  field: `${name}.p7s`,
  downloadAction: async () => {
    await api.demands.downloadDemandFile(dataId, true);
  },
});

export const getDetailFields = async (
  order: OrderResponseV5,
): Promise<DocumentManagementDetailsFields> => {
  const { base, customers, dynamic, lastAction } = order;
  const categoriesTree = await api.documentManagement.getCategories();
  const [categories, types] = getCategoryAndTypeOptions(categoriesTree);
  const type = types.find(type => type.value === base.extraData.typeId.toString());
  const category = categories.find(
    category => category.value === base.extraData.categoryId.toString(),
  );

  const attaches = dynamic.attach.reduce<FileInterface[]>((attaches, attach: AttachV5) => {
    const file = prepareFile(attach);
    const fakeFile = prepareFakeFile(attach);

    return [...attaches, file, ...(attach.signed ? [fakeFile] : [])];
  }, []);

  return {
    [DocumentManagementDetailsNames.Body]: dynamic.body,
    [DocumentManagementDetailsNames.Customers]: customers,
    [DocumentManagementDetailsNames.ManagersSignatory]: dynamic.signatory,
    [DocumentManagementDetailsNames.AuthorFullName]: dynamic.author.name,
    [DocumentManagementDetailsNames.AuthorRole]: dynamic.author.roleName,
    [DocumentManagementDetailsNames.AuthorBranch]: dynamic.author.bankBranchName,
    [DocumentManagementDetailsNames.Type]: type.label,
    [DocumentManagementDetailsNames.Category]: category.label,
    [DocumentManagementDetailsNames.Attach]: attaches,
    [DocumentManagementDetailsNames.RejectComment]: lastAction.description,
  };
};

export const getCustomerIdsForSign = (customers: Customer[], actions: DemandAction[]) =>
  customers
    .map(({ customerId }) => customerId)
    .filter(customerId => {
      const signAction = actions.find(action => action.actionName === OrderActionType.SIGN);
      return signAction.params.customerIds.includes(customerId);
    });

export const getExtraFieldsString = <E extends object>(extraFields: E): string => {
  if (!isExist(extraFields)) {
    return '';
  }

  return Object.entries(extraFields).reduce((strExtraFields: string, [key, value]) => {
    if (!isExist(value)) {
      return strExtraFields;
    }

    return `${strExtraFields}${key}:${value?.join(';')},`;
  }, '');
};

export const prepareFilterForFetch = (filter: FilterFields & ExtraFields) => {
  const { stateBank, stateCustomer, stateTab, typeIds, ...notChangedFields } = filter;

  const extraFields = getExtraFieldsString<
    Pick<FilterFields, DocumentsManagementFilterEnum.TypeIds>
  >({ typeIds });

  const orderStates =
    stateTab === StateTab.Signed
      ? filter[DocumentsManagementFilterEnum.OrderStates]
      : [...(stateBank ?? []), ...(stateCustomer ?? [])];

  return {
    ...notChangedFields,
    orderStates,
    extraFields,
  };
};
