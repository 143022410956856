import { renderQueryString } from 'api/backend';
import { DemandType, OrderType } from 'api/DemandsService/enums';

export enum PAYMENT_REQUEST_TYPE {
  EXPOSED = 'exposed',
  INVOICE = 'invoice',
}

type ID = string | number;

export type OrderParams<Q = Obj> = {
  amount?: number;
  copyFrom?: string;
  customerId?: number;
  payerAccount?: string;
  receiverAccount?: string;
  type?: OrderType | DemandType;
} & Q;

interface StatementParams {
  accountId?: number;
}

interface TransactionParams {
  accountId?: number;
}

interface AccountRequisitesParams {
  accountId?: number;
}

interface LimitsParams {
  cardId?: number;
  customerId?: number;
}

export const STATEMENTS = 'statements';
export const TRANSACTION_HISTORY = 'transactions-history';
export const PAYMENTS = 'payments';
export const SALARIES = 'salaries';
export const CURRENCY_EXCHANGE = 'currency-exchange';
export const Notifications = 'notifications';
export const DOCUMENTS_MANAGEMENT = 'documents-management';

export const pages = {
  // Accounts
  accounts: '/accounts',
  account: (id: string | number = '') => `/account/${id}`,
  statements: (params?: StatementParams) => `/${STATEMENTS}${renderQueryString(params)}`,
  transactionsHistory: (params?: TransactionParams) =>
    `/${TRANSACTION_HISTORY}${renderQueryString(params)}`,
  transactionHistory: {
    fullPath: `/${TRANSACTION_HISTORY}/:id`,
    details: (id: number) => `/${TRANSACTION_HISTORY}/${id}`,
  },
  accountRequisites: (params?: AccountRequisitesParams) =>
    `/account-requisites${renderQueryString(params)}`,

  // Acquiring
  acquiring: {
    statements: (params?: StatementParams) => `/acquiring/statements${renderQueryString(params)}`,
  },

  // Analytics
  analytics: '/analytics',

  // Announcements
  announcements: {
    fullPath: '/announcements/:subroute',
    tabs: {
      current: '/announcements/actual',
      history: '/announcements/history',
    },
  },

  // AuthorizedPersons
  authorizedPersons: '/authorized-persons',
  authPersonPermission: (id: string | number) => `/authorized-person-permission/${id}`,
  authorizedPersonPage: (id: string | number, action = '') => `/authorized-person/${id}/${action}`,
  roles: '/organization-roles',
  role: (id: string | number) => `/organization-role/${id}`,

  // Cards
  cards: '/cards',
  card: (id: string | number = '') => `/card/${id}`,

  // Cards Limits
  limits: (params?: LimitsParams) => `/limits/card${renderQueryString(params)}`,

  // Credit
  credits: '/credits',
  credit: (id: string | number = '') => `/credit/${id}`,

  // Demands
  demands: '/demands',
  demand: {
    fullPath: '/demand/:id/(.*)?',
    tabs: {
      details: (id: string | number, params?: OrderParams) =>
        `/demand/${id}/${renderQueryString(params)}`,
      history: (id: string | number) => `/demand/${id}/history`,
    },
  },
  creditDemand: {
    fullPath: '/credit-demand/:id/(.*)?',
    tabs: {
      details: (id: string | number, params?: OrderParams) =>
        `/credit-demand/${id}/${renderQueryString(params)}`,
      history: (id: string | number) => `/credit-demand/${id}/history`,
    },
  },
  depositDemand: {
    fullPath: '/deposit-demand/:id/(.*)?',
    tabs: {
      details: (id: string | number, params?: OrderParams) =>
        `/deposit-demand/${id}/${renderQueryString(params)}`,
      history: (id: string | number) => `/deposit-demand/${id}/history`,
    },
  },
  smsDemand: {
    fullPath: '/sms-demand/:id/(.*)?',
    tabs: {
      details: (id: string | number, params?: OrderParams) =>
        `/sms-demand/${id}/${renderQueryString(params)}`,
      history: (id: string | number) => `/sms-demand/${id}/history`,
    },
  },
  newSalaryDemand: {
    fullPath: '/newSalary-demand/:id/(.*)?',
    tabs: {
      details: (id: string | number, params?: OrderParams) =>
        `/newSalary-demand/${id}/${renderQueryString(params)}`,
      history: (id: string | number) => `/newSalary-demand/${id}/history`,
    },
  },
  cardDemand: {
    fullPath: '/card-demand/:id/(.*)?',
    tabs: {
      details: (id: string | number, params?: OrderParams) =>
        `/card-demand/${id}/${renderQueryString(params)}`,
      history: (id: string | number) => `/card-demand/${id}/history`,
    },
  },
  cashTransfer: {
    fullPath: '/cash-transfer/:id/(.*)?',
    tabs: {
      details: (id: string | number, params?: OrderParams) =>
        `/cash-transfer/${id}/${renderQueryString(params)}`,
      representative: (id: string | number, params?: OrderParams) =>
        `/cash-transfer/${id}/representative/${renderQueryString(params)}`,
      history: (id: string | number) => `/cash-transfer/${id}/history`,
    },
  },

  //route for grid and orders type of AcquiringNewTerminalOrder, FEAMessage, AuthLetter
  partialDemands: {
    fullPath: (type: string) => `/partial-demands/${type}/:id/(.*)`,
    tabs: {
      details: (type: string, id: string | number, params?: OrderParams) =>
        `/partial-demands/${type}/${id}/${renderQueryString(params)}`,
      history: (type: string, id: string | number) => `/partial-demands/${type}/${id}/history`,
    },
    type: (type: string) => `/partial-demands/${type}`,
  },

  // Departments
  departments: {
    fullPath: '/departments/:action',
    tabs: {
      map: (params?: { id: ID }) => `/departments/map${renderQueryString(params)}`,
      list: '/departments/list',
    },
  },

  // Currency Exchange
  currencyExchange: `/${CURRENCY_EXCHANGE}`,
  currencyExchanges: {
    fullPath: `/${CURRENCY_EXCHANGE}/:id/(.*)?`,
    tabs: {
      details: (id: number | string, params?: OrderParams) =>
        `/${CURRENCY_EXCHANGE}/${id}/${renderQueryString(params)}`,
      history: (id: number | string) => `/${CURRENCY_EXCHANGE}/${id}/history`,
    },
  },

  // Deposits
  deposits: '/deposits',
  deposit: (id: string | number = '') => `/deposit/${id}`,
  depositCalculator: '/deposit-calculator',

  // Employees
  employees: '/employees',
  employee: (id: string | number) => `/employee/${id}`,

  // EmployeesManagement
  employeesManagement: '/employees-management',
  employeeManagement: {
    fullPath: '/employee-management/:id/(.*)?',
    tabs: {
      details: (id: string | number, params?: OrderParams) =>
        `/employee-management/${id}/${renderQueryString(params)}`,
      history: (id: string | number) => `/employee-management/${id}/history`,
    },
    type: (type: string) => `/employees-management/${type}`,
  },

  // Handbooks
  handbooks: {
    counterparty: (id: string | number) => `/handbooks/counterparty/${id}`,
    paymentPurpose: (id: string | number) => `/handbooks/payment-purpose/${id}`,
    orderTemplate: (id: string | number) => `/handbooks/order-template/${id}`,
    orderTemplateFullPath: (id: string | number) => `/handbooks/order-template/${id}/(.*)?`,
    orderTemplateSalaryRegistry: (id: string | number) =>
      `/handbooks/order-template/${id}/salary-registry`,
    fullPath: '/handbooks/:subroute',
    tabs: {
      counterparties: '/handbooks/counterparties',
      paymentPurposes: '/handbooks/payment-purposes',
      orderTemplates: '/handbooks/order-templates',
      ukraineBanks: '/handbooks/ukraine-banks',
      currencyHistory: '/handbooks/currency-history',
    },
  },

  // Instructions
  instructions: '/instructions',

  // Home
  dashboard: '/',

  // Import
  import: '/import',

  // InProgress
  signatureFolder: '/signature-folder',
  currencyRates: '/currency-rates',
  outlets: '/outlets',
  news: '/news',

  // Login
  login: '/login',

  // Mail
  messages: '/messages',
  message: (id: string | number, action = '') => `/message/${id}/${action}`,

  // NotFound
  notFound: '/not-found',

  // Notifications
  notifications: `/${Notifications}`,
  editNotification: (id: string) => `/notification/${id}`,

  // Organization
  organizationData: '/organization-data',

  // Payments
  payments: `/${PAYMENTS}`,

  // Payments InternalPayments
  paymentRequests: (type: ':type' | PAYMENT_REQUEST_TYPE) => `/payment-requests/${type}`,
  paymentRequest: {
    fullPath: '/payment-request/:id/(.*)?',
    tabs: {
      history: (id: string) => `/payment-request/${id}/history`,
      details: (id?: string | number, params?: { copyFrom: number | string }) =>
        `/payment-request/${id || 'new'}${renderQueryString(params)}`,

      // exposed: (id?: string | number, params?: OrderParams) =>
      //   `/payment-request/${DIRECTIONS.EXPOSED}/${id || 'new'}${renderQueryString(params)}`,
      // invoice: (id: string | number) => `/payment-request/${DIRECTIONS.INVOICE}/${id}`,
    },
  },
  internalPayments: {
    fullPath: '/internal-payment/:id/(.*)?',
    tabs: {
      history: (id: string | number) => `/internal-payment/${id}/history`,
      details: <Q>(id: string | number, params?: OrderParams<Q>) =>
        `/internal-payment/${id}${renderQueryString(params)}`,
    },
  },

  // Payments RegularPayments
  regularPayments: '/regular-payments',
  regularPayment: {
    fullPath: '/regular-payment/:id/:action/:scheduleId',
    tabs: {
      schedule: (orderId: ID, scheduleId: ID, params?: OrderParams) =>
        `/regular-payment/${orderId}/schedule/${scheduleId}${renderQueryString(params)}`,
      paymentRequisites: (id: ID, scheduleId: ID) =>
        `/regular-payment/${id}/requisites/${scheduleId}`,
      history: (id: ID, schedule: ID) => `/regular-payment/${id}/history/${schedule}`,
    },
  },

  // DEPRECATED
  // Payments InternationalPayment ForeignTransfer
  foreignTransfer: (id?: string | number, params?: OrderParams) =>
    `/international-payment/foreign-transfer/${id || 'new'}${renderQueryString(params)}`,
  foreignTransferHistory: (id?: string | number) =>
    `/international-payment/foreign-transfer/${id}/history`,

  // Payments ForeignPayments
  foreignPayments: '/foreign-payments',

  // Payments ForeignPayment
  foreignPayment: {
    fullPath: '/foreign-payment/:id/(.*)?',
    tabs: {
      details: (id: string | number, params?: OrderParams) =>
        `/foreign-payment/${id}${renderQueryString(params)}`,
      history: (id: string | number) => `/foreign-payment/${id}/history`,
    },
  },

  // Profile
  profile: {
    fullPath: '/profile/:subroute/:action?',
    createCertificate: '/profile/certificates/new',
    CreateESeal: '/profile/e-stamp/new',
    addCertificate: '/profile/certificates/add',
    changePassword: '/profile/information/change-password',
    changeEmail: '/profile/information/change-email',
    tabs: {
      information: '/profile/information',
      certificates: '/profile/certificates',
      organizations: '/profile/organizations',
      accounts: '/profile/accounts',
      settings: '/profile/settings',
      externalApi: '/profile/external-api',
    },
  },

  // Protocol
  workProtocol: '/work-protocol',

  // Registration
  registration: '/registration',
  registrationFiles: (orderId: number | string, uuid: string) =>
    `/registration-files/${orderId}/${uuid}`,
  authRegistration: '/user-registration',

  // Salary
  salaries: `/${SALARIES}`,
  salary: {
    fullPath: '/salary/:id/(.*)?',
    tabs: {
      details: (id: string | number, params?: { copyFrom: string }) =>
        `/salary/${id}${renderQueryString(params)}`,
      registry: (id: string | number) => `/salary/${id}/registry`,
      history: (id: string | number) => `/salary/${id}/history`,
      relatedPayments: (id: string | number) => `/salary/${id}/related-payments`,
    },
  },

  //Document Management
  documentsManagement: `/${DOCUMENTS_MANAGEMENT}`,
  documentManagement: {
    fullPath: '/document-management/:id/(.*)?',
    tabs: {
      details: (id: string | number) => `/document-management/${id}`,
      history: (id: string | number) => `/document-management/${id}/history`,
    },
  },
};
