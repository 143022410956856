import * as React from 'react';

import { api } from 'api';
import { Button } from 'components/buttons/Button';
import { translate } from 'i18n/translator';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { RegistrationContext } from 'pages/Registration/RegistrationContext';

export const RegistrationResultPage: React.FC = () => {
  const { fetchRegistrationResult, resendSms } = useRegistrationResult();

  const [ABSAccountIban, setABSAccountIban] = React.useState<string>();
  const [isSent, setIsSent] = React.useState<boolean>();

  const fetchABSAccountIban = async () => {
    const { ABSAccountIban, isSent } = await fetchRegistrationResult();
    setABSAccountIban(ABSAccountIban);
    setIsSent(isSent);
  };

  React.useEffect(() => {
    fetchABSAccountIban();
  }, []);

  return (
    <div className="" style={{ position: 'initial' }}>
      <div className="login-page__body__box card" style={{}}>
        <div className="card-body">
          <div>
            <h5>{translate('front.registration.result-page.title.label')}</h5>
            {translate('front.registration.result-page.first-description.label')} {ABSAccountIban}
            <br />
            {translate('front.registration.result-page.second-description.label')}
            <br />
            {translate('front.registration.result-page.third-description.label')}
          </div>
          {isSent != null && (
            <>
              {isSent ? (
                <div>
                  {/* tslint:disable-next-line:max-line-length */}
                  {translate(
                    'front.registration.result-page.send-message-to-email-and-phone.label',
                  )}
                </div>
              ) : (
                <>
                  <div>
                    {translate('front.registration.result-page.send-message-to-email.label')}
                  </div>
                  <div
                    onClick={async () => {
                      const { isSent } = await resendSms();
                      setIsSent(isSent);
                    }}
                    className="resend-sms"
                  >
                    {translate('front.registration.result-page.button-resend-sms.label')}
                  </div>
                </>
              )}
            </>
          )}
          <Button
            color="primary"
            onClick={() => goto(pages.login)}
            className="login-page__form__btn"
            size="lg"
          >
            {translate('front.registration.result-page.button-logging-in.label')}
          </Button>
        </div>
      </div>
    </div>
  );
};

const useRegistrationResult = () => {
  const { orderId, uuid } = React.useContext(RegistrationContext);

  const fetchRegistrationResult = async () => {
    const { ABSAccountIban } = await api.registration.getRegistrationResult(orderId, uuid);
    const { isSent } = await api.registration.isSmsSent(orderId, uuid);
    return { ABSAccountIban, isSent };
  };

  const resendSms = async () => {
    const { isSent } = await api.registration.resendSms(orderId, uuid);
    return { isSent };
  };

  return { fetchRegistrationResult, resendSms };
};
