import * as React from 'react';

import moment from 'moment';

import { useField } from 'components/forms/ValidatingForm/components/useField';
import * as format from 'components/utils/format';
import { validateMessages } from 'components/validateMessages';
import { translate } from 'i18n/translator';

import { DateRangePicker, DateRangePickerProps } from '../datePicker/DateRangePicker';
import { FieldInput } from './FieldInput';
import { PreloadField } from './PreoadField';

export interface DataRangeProps extends DateRangePickerProps {
  label: string;
  defaultFrom?: Date | string;
  defaultTo?: Date | string;
  maxDate?: Date;
  minDate?: Date;
  outline?: boolean;
  required?: boolean;
}

const stubValidate = (): null => null;

export const DateRangeField = (props: DataRangeProps) => {
  const {
    label,
    disabled,
    isOnlyRangePicker,
    required,
    placeholder,
    renderHelpedFormFields,
    clearable,
    minDate,
    maxDate,
    nameFrom,
    nameTo,
    disabledDaysTo,
    disabledDaysFrom,
  } = props;

  const { defaultFrom, defaultTo } = props;

  const validate = React.useCallback(
    (v, f, fieldsData) => {
      const { [nameFrom]: fromValue, [nameTo]: toValue } = fieldsData;
      return ((valueFrom, valueTo) => {
        if (required) {
          if (!valueFrom || !valueTo) {
            return translate(validateMessages.fieldRequired);
          }
        }

        if (valueTo && disabledDaysTo) {
          if (valueFrom < disabledDaysTo.before) {
            return validateMessages.mustBeNoEarlier(
              moment(disabledDaysTo.before).format('DD.MM.YYYY'),
              'front.demand.dynamic-input.date-range-field.from.label',
            );
          }

          if (valueTo > disabledDaysTo.after) {
            return validateMessages.mustBeNoLater(
              moment(disabledDaysTo.after).format('DD.MM.YYYY'),
              'front.demand.dynamic-input.date-range-field.to.label',
            );
          }
        }

        return null;
      })(fromValue?.value, toValue?.value);
    },
    [required, disabledDaysTo],
  );
  const { field: fieldDateFrom } = useField({
    name: nameFrom,
    validate: stubValidate,
    defaultValue: format.parseDate(defaultFrom),
  });

  const { field: fieldDateTo, form } = useField({
    name: nameTo,
    validate: validate,
    defaultValue: format.parseDate(defaultTo),
  });

  if (!fieldDateFrom && !fieldDateTo && !form) {
    return null;
  }

  return (
    <PreloadField isLoading={form.initializing}>
      <FieldInput
        isDisabled={disabled}
        isFocus={false}
        isLargeInput={false}
        name={`${nameFrom}-${nameTo}`}
        label={label}
        error={fieldDateTo.error}
        warning={fieldDateTo.warning}
        value={fieldDateTo.value ?? fieldDateFrom.value}
      >
        <DateRangePicker
          dateFrom={format.parseDate(fieldDateFrom.value)}
          dateTo={format.parseDate(fieldDateTo.value)}
          nameFrom={nameFrom}
          nameTo={nameTo}
          onChange={data => {
            const { [nameFrom]: fromValue, [nameTo]: toValue } = data;
            const dataFrom = format.parseDate(fromValue);
            const dataTo = format.parseDate(toValue);
            fieldDateFrom.onChange(dataFrom);
            fieldDateTo.onChange(dataTo);
          }}
          onBlur={fieldDateFrom.onBlur}
          disabled={disabled || form.disabled || form.progress}
          clearable={clearable}
          placeholder={placeholder}
          minDate={minDate}
          maxDate={maxDate} // TODO: disabling after max and before min didn't work
          renderHelpedFormFields={renderHelpedFormFields}
          isOnlyRangePicker={isOnlyRangePicker}
          disabledDaysTo={disabledDaysTo}
          disabledDaysFrom={disabledDaysFrom}
        />
      </FieldInput>
    </PreloadField>
  );
};
