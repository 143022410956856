import React from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router';

import classnames from 'classnames';
import { compile, pathToRegexp } from 'path-to-regexp';

import { history } from 'navigations/navigate';

import { TabBar } from './TabBar';

interface Props extends React.PropsWithChildren<any> {
  className?: string;
  initialTabPath?: string;
}

export interface TabProps {
  children: any;
  path: string;
  title: string;
  className?: string;
  route?: string; // DEPRECATED
}

const compilePath = (path: string, params: Obj) => compile(path)(params);

const getTabPath = (tab: React.ReactElement<TabProps>) => tab.props.path;

const getTabRoute = (tab: React.ReactElement<TabProps>, params: Obj) => {
  return params ? compilePath(tab.props.path, params) : getTabPath(tab);
};

const getActiveTab = (tabs: ReturnType<typeof childrenToTabs>, pathname: string) =>
  tabs.find(tab => pathToRegexp(getTabPath(tab)).test(pathname));

const childrenToTabs = (children: React.ReactElement<TabProps>): React.ReactElement<TabProps>[] => {
  return React.Children.toArray(children).filter(tab => !!tab);
};
0;
const tabsToTabOptions = (tabs: ReturnType<typeof childrenToTabs>) =>
  tabs.map(tab => ({
    label: tab.props.title,
    value: getTabPath(tab),
  }));

export const NavTabs = (props: Props) => {
  const { path, params } = useRouteMatch();
  const { pathname, search } = useLocation();

  const tabs = childrenToTabs(props.children);

  const tabOptions = React.useMemo(() => tabsToTabOptions(tabs), [tabs]);
  const activeTabPath = React.useMemo(
    () => getTabPath(getActiveTab(tabs, pathname) || tabs[0]),
    [tabs, pathname],
  );

  const initialTabPath = props.initialTabPath || getTabRoute(tabs[0], params);

  if (!tabs.length) {
    return null;
  }

  const onChange = (path: string) => {
    const tab = tabs.find(tab => tab.props.path === path);

    history.push(`${getTabRoute(tab, params)}${search}`);
  };

  return (
    <>
      <TabBar
        onChange={onChange}
        options={tabOptions}
        value={activeTabPath}
        className={classnames(props.className)}
      />
      <Switch>
        {tabs.map(tab => (
          <Route key={getTabPath(tab)} path={getTabPath(tab)} render={() => tab} exact />
        ))}
        <Redirect exact from={path} to={initialTabPath} />
      </Switch>
    </>
  );
};

export const NavTab = (props: TabProps) => props.children;
