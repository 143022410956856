import * as React from 'react';
import { Col } from 'react-grid';

import { translate } from 'i18n/translator';

interface Props {
  isSearchTokens: boolean;
  values: any[];
}

export const SearchTokenLabel: React.FC<Props> = ({ values, isSearchTokens }) => {
  if (values && values.length && !isSearchTokens) return null;

  return (
    <Col>
      {isSearchTokens
        ? translate('front.certificates.search-in-progress-text.label')
        : translate('front.certificates.search-in-finish-text.label')}
    </Col>
  );
};
