import React from 'react';

import { CurrencyAmountInterface } from 'components/react-data-table/types';
import { AmountCard } from 'components/TotalCurrency/AmountCard';

interface AmountCardsProps {
  options: CurrencyAmountInterface<string>[];
  isShowAmount?: boolean;
}

export const AmountCards = ({ options, isShowAmount }: AmountCardsProps) => (
  <div className="amount-cards">
    {options.map(option => {
      return <AmountCard isShowAmount={isShowAmount} key={option.currency} item={option} />;
    })}
  </div>
);
