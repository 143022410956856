import * as React from 'react';
import { TableColumnProps } from 'react-data-table';

import { api } from 'api';
import { TransactionHistoryStatus } from 'api/enums';
import { ExportType } from 'api/ExportService';
import { Transaction } from 'api/interfaces';
import { Turnover } from 'api/Service';
import { ExportFormatEnum } from 'components/ExportDropdown/enum';
import { confirmationModal } from 'components/modals/ConfirmModal';
import { DataColumn } from 'components/Table/DataColumn';
import * as format from 'components/utils/format';
import { translate } from 'i18n/translator';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { isVisibleCopy, isVisibleReply } from 'pages/TransactionsHistory/TransactionsHistoryPage';

export interface AccountStatement extends Transaction, Turnover {
  isEntrancePayment: boolean;
}

export const columns: TableColumnProps<AccountStatement>[] = [
  {
    label: 'front.account-statements-table.cols-number.label',
    render: row => (
      <DataColumn title={row.documentNumber} subTitle={format.date(row.executionTime)} />
    ),
    sort: [['balanceDate', 'documentNumber']],
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.account-statements-table.correspondent-name.label',
    render: row => (
      <DataColumn
        title={row.isEntrancePayment ? row.payer : row.receiver}
        subTitle={row.isEntrancePayment ? row.payerIban : row.receiverIban}
      />
    ),
    sort: [['correspondentName', 'correspondentIban']],
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.account-statements-table.cols-type.label',
    render: row => {
      const type = row.isEntrancePayment
        ? 'front.transactions-history-page.render-type-earnings.label'
        : 'front.transactions-history-page.render-type-write-off.label';
      return (
        <DataColumn
          title={translate(type)}
          subTitle={
            row.status === TransactionHistoryStatus.INFO
              ? `(${translate('front.transactions-history-page.render-type-info.label')})`
              : null
          }
        />
      );
    },
    sort: [['transactionType', 'balanceDate', 'documentNumber']],
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.account-statements-table.amount-document.label',
    render: row => <DataColumn title={format.getAmount(Math.abs(row.amount))} />,
    sort: [['amountAbs', 'balanceDate']],
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.account-statements-table.amount-document-equivalent.label',
    render: row => <DataColumn title={format.getAmount(Math.abs(row.amountNational))} />,
    sort: [['amountNcAbs', 'balanceDate']],
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.account-statements-table.transaction-currency.label',
    render: row => <DataColumn title={row.currency} />,
    sort: [['currency', 'balanceDate']],
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.account-statements-table.purpose.label',
    render: row => <DataColumn title={row.paymentPurpose} />,
    sort: [['details', 'balanceDate']],
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.account-statements-table.correspondent-bank-name.label',
    render: row => <DataColumn title={row.isEntrancePayment ? row.payerBank : row.receiverBank} />,
    sort: [['correspondentBankName', 'balanceDate']],
    showDesktop: false,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.account-statements-table.amount-debit-equivalent.label',
    render: row => <DataColumn title={format.getAmount(row.debitNc)} />,
    showDesktop: false,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.account-statements-table.loan-amount-equivalent.label',
    render: row => <DataColumn title={format.getAmount(row.creditNc)} />,
    showDesktop: false,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.account-statements-table.bin.label',
    render: row => <DataColumn title={row.isEntrancePayment ? row.payerBin : row.receiverBin} />,
    sort: [['correspondentBin', 'balanceDate']],
    showDesktop: false,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.account-statements-table.amount-debit.label',
    render: row => <DataColumn title={format.getAmount(row.debit)} />,
    showDesktop: false,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.account-statements-table.loan-amount.label',
    render: row => <DataColumn title={format.getAmount(row.credit)} />,
    showDesktop: false,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.account-statements-table.order-receiver-bic.label',
    render: row => (
      <DataColumn title={row.isEntrancePayment ? row.payerBankBik : row.receiverBankBik} />
    ),
    sort: [['correspondentBankBic', 'balanceDate']],
    showDesktop: false,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.account-statements-table.organization.label',
    render: row => <DataColumn title={row.customerName} />,
    sort: [['senderName', 'senderIban']],
    showDesktop: false,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.account-statements-table.code-EDRPOU.label',
    render: row => <DataColumn title={row.isEntrancePayment ? row.receiverBin : row.payerBin} />,
    sort: [['senderBin', 'balanceDate']],
    showDesktop: false,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.account-statements-table.organization-account.label',
    render: row => (
      <DataColumn title={row.isEntrancePayment ? row.receiverAccount : row.payerAccount} />
    ),
    sort: [['senderBankAccount', 'balanceDate']],
    showDesktop: false,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.account-statements-table.organization-iban.label',
    render: row => <DataColumn title={row.isEntrancePayment ? row.receiverIban : row.payerIban} />,
    sort: [['senderIban', 'balanceDate']],
    showDesktop: false,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.account-statements-table.correspondent-iban.label',
    render: row => <DataColumn title={row.isEntrancePayment ? row.payerIban : row.receiverIban} />,
    sort: [['correspondentIban', 'balanceDate']],
    showDesktop: false,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.account-statements-table.organization-receiver-bic.label',
    render: row => (
      <DataColumn title={row.isEntrancePayment ? row.receiverBankBik : row.payerBankBik} />
    ),
    sort: [['senderBankBic', 'balanceDate']],
    showDesktop: false,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.account-statements-table.name-organization-bank.label',
    render: row => <DataColumn title={row.isEntrancePayment ? row.receiverBank : row.payerBank} />,
    sort: [['senderBankName', 'balanceDate']],
    showDesktop: false,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.internal-payment-page.actual-payer.label',
    render: row => <DataColumn title={row.actualPayerName} />,
    sort: [['productTransaction.extraData#payer#name', 'balanceDate']],
    showDesktop: false,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.internal-payment-page.actual-payer-code.label',
    render: row => <DataColumn title={row.actualPayerTaxCode} />,
    sort: [['productTransaction.extraData#payer#taxCode', 'balanceDate']],
    showDesktop: false,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.internal-payment-page.actual-recipient.label',
    render: row => <DataColumn title={row.actualReceiverName} />,
    sort: [['productTransaction.extraData#receiver#name', 'balanceDate']],
    showDesktop: false,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.internal-payment-page.actual-recipient-code.label',
    render: row => <DataColumn title={row.actualReceiverTaxCode} />,
    sort: [['productTransaction.extraData#receiver#taxCode', 'balanceDate']],
    showDesktop: false,
    showTablet: false,
    showMobile: false,
  },
  {
    renderMenu: row => [
      {
        label: 'front.account-statements-table.menu-view.label',
        onClick: () => goto(pages.transactionHistory.details(row.id)),
      },
      {
        label: 'front.account-statements-table.reply-by-transfer.label',
        onClick: async () => {
          try {
            const orderId = await api.payments.createPaymentFromTransaction(row.id);
            goto(pages.internalPayments.tabs.details(orderId));
          } catch (error: any) {
            await confirmationModal(JSON.parse(error.message).message, false, false);
          }
        },
        isVisible: isVisibleReply(row),
      },
      {
        label: 'front.transaction-history-page.menu-print.label',
        onClick: () =>
          api.export.exportByCode(ExportType.ACCOUNT_TRANSACTIONS, {
            format: ExportFormatEnum.PDF,
            ids: [row.id],
          }),
      },
      {
        label: 'front.transaction-history-page.menu-copy.label',
        onClick: async () => {
          const orderId = await api.payments.createPaymentFromTransaction(row.id);
          goto(pages.internalPayments.tabs.details(orderId));
        },
        isVisible: isVisibleCopy(row),
      },
    ],
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];
