import * as React from 'react';

import { ClaimTokenCodeEnum } from 'api/RedirectUri/enums';
import { RedirectButton } from 'components/buttons/components/RedirectButton/RedirectButton';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { DefaultForm } from 'components/forms/formParts';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { translate } from 'i18n/translator';
import { Privileges } from 'navigations/privileges';
import { ImportDetailsFormFieldNames } from 'pages/Import/ImportDetail/enums';
import { FormFields } from 'pages/Import/ImportDetail/interfaces';

import './LoadEmployeeRegisterModal.scss';

const LoadEmployeeRegisterDetails = () => {
  const { getFieldValue } = useForm<FormFields>();
  const customerId = getFieldValue(ImportDetailsFormFieldNames.CustomerId);

  return (
    <DefaultForm>
      <div className="title-modal">
        {translate('front.demands.salary.select_organization.label')}
      </div>
      <CustomerSelect
        label="front.account-statements-filter-form.organization-number.label"
        name={ImportDetailsFormFieldNames.CustomerId}
        placeholder={'front.import.customer-select.multi-import.placeholder'}
        privilege={Privileges.salaryEmployeeEdit}
        selectDefaultCustomer
        required
      />
      <RedirectButton
        claimTokenCode={ClaimTokenCodeEnum.SalaryRegistry}
        customerId={customerId}
        label="front.demands.salary.download_register.label"
      />
    </DefaultForm>
  );
};

export const LoadEmployeeRegisterModal = withForm(LoadEmployeeRegisterDetails);
