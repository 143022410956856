import * as React from 'react';

import { CertificateInfoResult } from 'api/CertificateService/CipherModule/interfaces';
import { Button } from 'components/buttons/Button';
import { confirmModal, PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import { translate } from 'i18n/translator';

import './connectionCertificateModal.scss';

interface Props {
  cipherCertificate: CertificateInfoResult;
}

const ViewConnectionCertificateComponent = ({
  cipherCertificate,
  resolve,
}: PropsWithConfirmModal<Props>) => {
  const { certificateInfo } = cipherCertificate;

  const defaultValue = `${certificateInfo.certificateSerialNumber.value} - ${certificateInfo.ownerCertificateInfo.value.ownerFullName.value} - ${certificateInfo.ownerCertificateInfo.value.ownerOrganization.value}`;

  return (
    <>
      <h3>{translate('front.login-form.certificate.title.label')}</h3>
      <div className="key-wrapper">{defaultValue}</div>
      <div>
        <Button onClick={() => resolve(true)} size="sm">
          {translate('front.confirm-modal.continue.label')}
        </Button>
        <Button onClick={() => resolve(false)} color="secondary" size="sm">
          {translate('front.confirm-modal.cancel.label')}
        </Button>
      </div>
    </>
  );
};

export const ConnectionCertificateModal = async (cipherCertificate: CertificateInfoResult) =>
  await confirmModal(ViewConnectionCertificateComponent, {
    cipherCertificate,
  });
