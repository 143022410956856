import React from 'react';
import { Input } from 'reactstrap';

import classNames from 'classnames';

import { Search } from 'components/icons';

import './styles.scss';

interface Props {
  onChange: (value: string) => void;
  className?: string;
  isClickable?: boolean;
  placeholder?: string;
  value?: string;
}

const WAIT_INTERVAL = 4000;
const ENTER_KEY = 13;

export const SearchInput: React.FC<Props> = ({
  value = '',
  onChange,
  className,
  placeholder,
  isClickable = true,
}) => {
  const [searchText, setSearchText] = React.useState(value);
  const [isDoubleEnter, setDoubleEnter] = React.useState(false);

  const timer = React.useRef(null);

  const handleChange = (e: any) => {
    const { value } = e.target;
    clearTimeout(timer.current);

    setSearchText(value);
    timer.current = setTimeout(() => triggerChange(value), WAIT_INTERVAL);
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode === ENTER_KEY) {
      if (isDoubleEnter) {
        return;
      }

      setDoubleEnter(true);
      triggerChange(searchText);

      setTimeout(() => {
        setDoubleEnter(false);
      }, 1000);
    }
  };

  const triggerChange = (value: string) => {
    clearTimeout(timer.current);

    onChange(value);
  };

  const handleClickSearch = () => triggerChange(searchText);

  return (
    <div className={classNames('search-input', className)}>
      <Input
        type="text"
        className="search-input__input"
        placeholder={placeholder}
        onChange={handleChange}
        value={searchText}
        onKeyDown={handleKeyDown}
      />
      <span className="search-input__icon" onClick={isClickable ? handleClickSearch : null}>
        <Search />
      </span>
    </div>
  );
};
