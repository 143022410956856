import * as React from 'react';
import { Col, Container, Row } from 'react-grid';
import { useSelector } from 'react-redux';

import moment from 'moment';

import { api } from 'api';
import { EXPORT_TYPES_PRODUCT } from 'api/ProductsService';
import { Option } from 'api/Service';
import { createAccountOptions } from 'api/utils';
import { Button } from 'components/buttons/Button';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { getMaxDate } from 'components/Document/utils';
import { ExportFormatEnum } from 'components/ExportDropdown/enum';
import { ExportDropdown, getExportActions } from 'components/ExportDropdown/ExportDropdown';
import { getCurrentMonth } from 'components/forms/datePicker/utils';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { DateRangeField } from 'components/forms/inputs/DateRangeField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { FinalValue, SubscriptionCbEffects } from 'components/forms/ValidatingForm/FormContext';
import { FormFetchCb, useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { ToggleFilterButton } from 'components/Table/ToggleFilterButton/ToggleFilterButton';
import * as format from 'components/utils/format';
import { translate } from 'i18n/translator';
import { selectChosenCustomersId } from 'store/selectors';

import './styles.scss';

export interface Fields {
  accountIds: string[];
  customerIds: string[];
  dateFrom: Date;
  dateTo: Date;
  prediction?: boolean;
  turnover?: boolean;
}

interface Payload {
  accountsOptions: Option[];
  chosenPersonsOptions: Option[];
  predictionCheckboxVisible: boolean;
}

interface Props {
  onSubmit: (formData: Fields) => void;
}

const today = moment().toDate();
const maxDate = getMaxDate(today, 30);

const AnalyticsFilterForm: React.FC<Props> = ({ onSubmit }) => {
  const allSelectedCustomers = useSelector(selectChosenCustomersId());

  const onMount: FormFetchCb<Fields, Payload> = async ({ setFields, setPayload }) => {
    const accountsOptions = await api.payments.getAccountsForStatementOptions(allSelectedCustomers);
    const accountIds = accountsOptions.map(item => item.value);

    const [dateFrom, dateTo] = getCurrentMonth();
    const initFilters = {
      accountIds,
      customerIds: allSelectedCustomers.map(id => `${id}`),
      dateFrom: format.parseDate(dateFrom),
      dateTo: format.parseDate(dateTo),
    };

    setFields(initFilters);
    setPayload({ accountsOptions });
    onSubmit(initFilters);
  };

  const {
    payload,
    getFormData,
    getFieldValue,
    updateData,
    subscribe,
    setPayload,
    handleSubmit,
    setProgress,
  } = useForm<Fields, Payload>(onMount);

  const dateFrom = getFieldValue('dateFrom');
  const dateTo = getFieldValue('dateTo');

  React.useEffect(() => {
    const { predictionCheckboxVisible } = payload;
    const diff = Number(dateTo) - Number(dateFrom);
    if (diff === 0) {
      if (!predictionCheckboxVisible) {
        setPayload({ predictionCheckboxVisible: true });
      }
    } else if (predictionCheckboxVisible) {
      setPayload({ predictionCheckboxVisible: false });
      updateData({ turnover: false });
    }

    subscribe('customerIds', onChangeCustomer);
  }, [dateFrom, dateTo]);

  const onChangeCustomer = async (
    { value: customerIds }: { value: FinalValue },
    { setPayload, updateData }: SubscriptionCbEffects<Fields, Payload>,
  ) => {
    setProgress(true);
    if (customerIds.length > 0) {
      const accounts = await api.payments.getAccountsForPayment({
        customerIds,
        type: 'all',
        withDocumentBalance: false,
      });

      const accountsOptions = createAccountOptions(accounts.rows);

      setPayload({ accountsOptions });
      accountsOptions.length > 0
        ? updateData({ accountIds: accountsOptions.map(a => a.value) })
        : updateData({ accountIds: null });
    } else {
      updateData({ accountIds: null });
    }
    setProgress(false);
  };

  const { accountsOptions, predictionCheckboxVisible } = payload;

  const exportActions = React.useMemo(() => {
    const exportProductAction = (formatType: ExportFormatEnum) => () =>
      api.products.exportGraph(getFormData(), formatType);

    return getExportActions({
      action: exportProductAction,
      exportTypes: EXPORT_TYPES_PRODUCT,
    });
  }, [JSON.stringify(getFormData())]);

  return (
    <Container>
      <Row className="analytics-filter__row analytics-filter__tabs">
        <Col md={8} />
        <Col md={4} hAlign="end" className="analytics-filter__tabs_col">
          <ExportDropdown actions={exportActions} marginBottom="0" />
        </Col>
      </Row>
      <Row className="analytics-filter__row">
        <Col md={4}>
          <CustomerSelect
            label="front.account-statements-filter-form.organization-number.label"
            name="customerIds"
            multi
            clearable
            required
          />
        </Col>
        <Col md={4}>
          <SelectField
            label="front.account-statements-filter-form.account-number.label"
            name="accountIds"
            options={accountsOptions}
            multi
            required
            clearable
          />
        </Col>
        <Col md={4}>
          <DateRangeField
            label="front.account-statements-filter-form.date-range.label"
            nameFrom="dateFrom"
            nameTo="dateTo"
            maxDate={maxDate}
            disabledDaysFrom={{
              before: null,
              after: today,
            }}
            clearable
          />
        </Col>
      </Row>
      <Row className="analytics-filter__buttons">
        <Button
          label={translate('front.table.filter-footer-apply.label')}
          onClick={e => handleSubmit(onSubmit, e)}
          color="primary"
          type="submit"
        />
        <ToggleFilterButton smallMode>
          <CheckboxField
            label="front.analytics.filter-prediction.label"
            disabled={!predictionCheckboxVisible}
            name="prediction"
          />
        </ToggleFilterButton>
      </Row>
    </Container>
  );
};

export const AnalyticsFilter = withForm(AnalyticsFilterForm);
