import * as React from 'react';

import { Devices } from 'enums/DevicesEnum';

import { DemandType } from 'api/DemandsService/enums';
import * as Icon from 'components/icons';
import withoutModal from 'components/modals/withoutModal';
import { AccountStatementsPage } from 'pages/AccountStatements/AccountStatementsPage';
import { AcquiringStatementsPage } from 'pages/Acquiring/Statements/AcquiringStatementsPage';
import { AnalyticsPage } from 'pages/Analytics/AnalyticsPage';
import { AnnouncementsPage } from 'pages/Announcements/AnnouncementsPage';
import { AuthorizedPersonPage } from 'pages/AuthorizedPersons/AuthorizedPersonPage';
import { AuthorizedPersonPermissionPage } from 'pages/AuthorizedPersons/AuthorizedPersonPermissionPage';
import { AuthorizedPersonsPage } from 'pages/AuthorizedPersons/AuthorizedPersonsPage';
import { EditRolePage } from 'pages/AuthorizedPersons/Roles/EditRolePage';
import { RolesPage } from 'pages/AuthorizedPersons/Roles/RolesPage';
import { AcquiringDemandPage } from 'pages/Demands/AcuiringDemand/AcquiringDemandPage';
import { AuthLetterPage } from 'pages/Demands/AuthLetterDemand/AuthLetterPage';
import { CardDemandPage } from 'pages/Demands/CardDemand/CardDemandPage';
import { CashTransferPage } from 'pages/Demands/CashTransferPage/CashTransferPage';
import { CreditDemandPage } from 'pages/Demands/CreditDemand/CreditDemandPage';
import { CurrencyDocumentPage } from 'pages/Demands/CurrencyDocument/CurrencyDocumentPage';
import { CurrencyExchangePage } from 'pages/Demands/CurrencyExchange/CurrencyExchangePage';
import { CurrencyExchangesPage } from 'pages/Demands/CurrencyExchange/CurrencyExchangesPage';
import { DemandsPage } from 'pages/Demands/DemandsPage';
import { DepositDemand } from 'pages/Demands/DepositDemand/DepositDemandPage';
import { EmployeeManagementDemandPage } from 'pages/Demands/EmployeeManagment/EmployeeManagementDemandPage';
import { NewSalaryDemand } from 'pages/Demands/NewSalaryDemand/NewSalaryDemandPage';
import { PartialDemandsPage } from 'pages/Demands/PartialDemandsPage';
import { SmsDemandPage } from 'pages/Demands/SmsDemandPage/SmsDemandPage';
import { DepartmentsPage } from 'pages/Departments/DepartmentsPage';
import { DocumentManagementPage } from 'pages/DocumentManagement/DocumentManagementPage';
import { DocumentsManagementPage } from 'pages/DocumentManagement/DocumentsManagementPage';
import { EmployeePage } from 'pages/Employees/EmployeePage';
import { EmployeesPage } from 'pages/Employees/EmployeesPage';
import { EmployeesManagementPage } from 'pages/EmployeesManagement/EmployeesManagementPage';
import { CounterpartyPage } from 'pages/Handbooks/Counterparties/CounterpartyPage';
import { HandbooksPage } from 'pages/Handbooks/HandbooksPage';
import { OrderTemplate } from 'pages/Handbooks/OrderTemplates/OrderTemplate';
import { PaymentPurposePage } from 'pages/Handbooks/PaymentPurpose/PaymentPurposePage';
import { HomePage } from 'pages/Home';
import { ImportPage } from 'pages/Import/ImportPage/ImportPage';
import { InProgressPage } from 'pages/InProgress/InProgressPage';
import { InstructionsPage } from 'pages/Instructions/InstructionsPage';
import { MailPage } from 'pages/Mail/MailPage';
import { MailsPage } from 'pages/Mail/MailsPage';
import { NoAccessPage } from 'pages/NoAccess/NoAccessPage';
import { NotificationPage } from 'pages/Notifications/NotificationPage';
import { NotificationsPage } from 'pages/Notifications/NotificationsPage';
import { ForeignPaymentPage } from 'pages/Payments/ForeignPayment/ForeignPaymentPage';
import { ForeignPaymentsPage } from 'pages/Payments/ForeignPayment/ForeignPaymentsPage';
import { InternalPaymentPage } from 'pages/Payments/InternalPayment/InternalPaymentPage';
import { InternalPaymentsPage } from 'pages/Payments/InternalPayment/InternalPaymentsPage';
import { PaymentRequestPage } from 'pages/Payments/PaymentRequest/PaymentRequestPage';
import { PaymentRequestsPage } from 'pages/Payments/PaymentRequest/PaymentRequestsPage';
import { RegularPaymentPage } from 'pages/Payments/RegularPayments/RegularPaymentPage';
import { RegularPaymentsPage } from 'pages/Payments/RegularPayments/RegularPaymentsPage';
import { AccountEditPage } from 'pages/Products/Accounts/AccountEditPage';
import { AccountRequisitesPage } from 'pages/Products/Accounts/AccountRequisitesPage';
import { AccountsPage } from 'pages/Products/Accounts/AccountsPage';
import { CardEditPage } from 'pages/Products/Cards/CardEditPage';
import { CardsPage } from 'pages/Products/Cards/CardsPage';
import { LimitsPage } from 'pages/Products/Cards/Limits/LimitsPage';
import { CreditEditPage } from 'pages/Products/Credits/CreditEditPage';
import { CreditsPage } from 'pages/Products/Credits/CreditsPage';
import { DepositCalculatorPage } from 'pages/Products/Deposits/DepositCalculator/DepositCalculatorPage';
import { DepositEditPage } from 'pages/Products/Deposits/DepositEditPage';
import { DepositsPage } from 'pages/Products/Deposits/DepositsPage';
import { AddKepCertificatePage } from 'pages/Profile/Certificates/KepCertificate/AddKepCertificatePage';
import { CreateESeal } from 'pages/Profile/Certificates/KepCertificate/CreateESeal';
import { CreateKepCertificatePage } from 'pages/Profile/Certificates/KepCertificate/CreateKepCertificatePage';
import { ChangeEmailPage } from 'pages/Profile/Information/ChangeEmailPage';
import { ChangePasswordPage } from 'pages/Profile/Information/ChangePasswordPage';
import { ProfilePage } from 'pages/Profile/ProfilePage';
import { ProtocolsPage } from 'pages/Protocols/ProtocolsPage';
import { ActivationPage } from 'pages/Registration/activation/ActivationPage';
import { SalariesPage } from 'pages/Salary/SalariesPage';
import { SalaryPage } from 'pages/Salary/SalaryPage';
import { TransactionHistoryPage } from 'pages/TransactionsHistory/TransactionHistoryPage';
import { TransactionsHistoryPage } from 'pages/TransactionsHistory/TransactionsHistoryPage';

import { pages, PAYMENT_REQUEST_TYPE } from './pages';
import { Privileges } from './privileges';

const isPrivilegedRoute = (route: RouteConfig, privileges: Privileges[]) => {
  if (!route.privileges) {
    return true;
  }
  return route.privileges.some(privilege => privileges.includes(privilege));
};

export interface RouteConfig {
  privileges: Array<Privileges>;
  component?: any;
  exact?: boolean;
  icon?: React.SFC<any>;
  id?: string;
  isModal?: boolean;
  items?: Array<RouteConfig>;
  label?: string;
  menuPath?: string;
  path?: string;
  showSideBar?: boolean;
  width?: number;
}

export const NEW = 'new';

export const routes: RouteConfig[] = [
  {
    path: pages.dashboard,
    label: 'front.menu.main.label',
    icon: Icon.Home,
    showSideBar: true,
    exact: true,
    component: HomePage,
    privileges: [Privileges.welcomePageView],
  },
  {
    path: '',
    label: 'front.menu.products.label',
    icon: Icon.Accounts,
    showSideBar: true,
    privileges: [],
    items: [
      {
        path: pages.accounts,
        label: 'front.menu.accounts.label',
        component: AccountsPage,
        showSideBar: true,
        privileges: [Privileges.productAccountView],
      },
      {
        path: pages.deposits,
        label: 'front.menu.deposits.label',
        component: DepositsPage,
        showSideBar: true,
        privileges: [Privileges.productDepositView],
      },
      {
        path: pages.credits,
        label: 'front.menu.credits.label',
        component: CreditsPage,
        showSideBar: true,
        privileges: [Privileges.productCreditView],
      },
      {
        path: pages.cards,
        label: 'front.menu.cards.label',
        component: CardsPage,
        showSideBar: true,
        privileges: [Privileges.productCardView],
      },
      {
        path: pages.limits(),
        label: 'front.menu.limits.label',
        component: LimitsPage,
        exact: true,
        showSideBar: true,
        privileges: [Privileges.productCardLimitView],
      },
    ],
  },
  {
    path: '',
    label: 'front.menu.financial-statements.label',
    icon: Icon.PieChart,
    showSideBar: true,
    privileges: [],
    items: [
      {
        path: pages.statements(),
        label: 'front.menu.statements.label',
        component: AccountStatementsPage,
        showSideBar: true,
        privileges: [Privileges.financeStatementView],
      },
      {
        path: pages.transactionsHistory(),
        label: 'front.menu.transactions-history.label',
        showSideBar: true,
        component: TransactionsHistoryPage,
        privileges: [Privileges.financeTransactionHistoryView],
      },
      {
        path: pages.analytics,
        label: 'front.menu.analytics.label',
        showSideBar: true,
        component: AnalyticsPage,
        privileges: [Privileges.financeAnalyticsView],
      },
    ],
  },
  {
    path: '',
    label: 'front.menu.payments.label',
    icon: Icon.Payments,
    showSideBar: true,
    privileges: [],
    items: [
      {
        path: pages.payments,
        label: 'front.menu.payment-documents.label',
        component: InternalPaymentsPage,
        showSideBar: true,
        privileges: [Privileges.paymentDomesticView, Privileges.paymentInternalView],
      },
      {
        path: pages.paymentRequests(':type'),
        menuPath: pages.paymentRequests(PAYMENT_REQUEST_TYPE.EXPOSED),
        label: 'front.menu.payment-demands.label',
        component: PaymentRequestsPage,
        showSideBar: true,
        privileges: [Privileges.paymentExposedView],
      },
      {
        path: pages.regularPayments,
        label: 'front.menu.regular-payments.label',
        component: RegularPaymentsPage,
        showSideBar: true,
        privileges: [Privileges.paymentRegularView],
      },
    ],
  },
  {
    path: '',
    label: 'front.menu.currencies.label',
    icon: Icon.ExchangeDollar,
    showSideBar: true,
    privileges: [],
    items: [
      {
        path: pages.foreignPayments,
        label: 'front.menu.international-payments.label',
        component: ForeignPaymentsPage,
        showSideBar: true,
        privileges: [Privileges.paymentForeignView],
      },
      {
        path: pages.currencyExchange,
        label: 'front.menu.currency-exchange.label',
        component: CurrencyExchangesPage,
        showSideBar: true,
        privileges: [Privileges.demandCurrencyExchangeView],
        exact: true,
      },
      {
        path: pages.partialDemands.type(DemandType.FEAMessage),
        label: 'front.menu.сurrency-documents.label',
        showSideBar: true,
        privileges: [Privileges.feamessageView],
      },
    ],
  },
  {
    path: '',
    label: 'front.menu.salary-project.label',
    icon: Icon.Briefcase,
    showSideBar: true,
    privileges: [],
    items: [
      {
        path: pages.salaries,
        label: 'front.menu.salary-sheet.label',
        component: SalariesPage,
        showSideBar: true,
        privileges: [Privileges.salarySheetView],
      },
      {
        path: pages.employees,
        label: 'front.menu.employees.label',
        component: EmployeesPage,
        showSideBar: true,
        privileges: [Privileges.salaryEmployeeView],
      },
      {
        path: pages.employeesManagement,
        label: 'front.menu.employees-management.label',
        component: EmployeesManagementPage,
        showSideBar: true,
        privileges: [Privileges.demandSalaryView],
      },
    ],
  },
  {
    path: '',
    label: 'front.menu.acquiring.label',
    icon: Icon.Acquiring,
    showSideBar: true,
    privileges: [],
    items: [
      {
        path: pages.acquiring.statements(),
        label: 'front.menu.statements.label',
        component: AcquiringStatementsPage,
        showSideBar: true,
        privileges: [Privileges.acquiringStatementView],
      },
      {
        path: pages.partialDemands.type(DemandType.AcquiringNewTerminalOrder),
        label: 'front.menu.services.label',
        showSideBar: true,
        privileges: [Privileges.acquiringShopsView],
      },
    ],
  },
  {
    path: pages.demands,
    label: 'front.menu.services.label',
    icon: Icon.Draft,
    showSideBar: true,
    component: DemandsPage,
    privileges: [
      Privileges.demandReferenceView,
      Privileges.demandDepositView,
      Privileges.demandCreditView,
      Privileges.demandCardView,
      Privileges.demandSalaryView,
      Privileges.demandSMSView,
    ],
  },
  {
    path: pages.signatureFolder,
    label: 'front.menu.signature-folder.label',
    icon: Icon.Services,
    showSideBar: true,
    component: InProgressPage,
    privileges: [Privileges.signFolderView],
  },
  {
    path: pages.documentsManagement,
    label: 'front.menu.document-management.label',
    icon: Icon.DocumentCloud,
    showSideBar: true,
    component: DocumentsManagementPage,
    privileges: [Privileges.protocolView],
  },
  {
    path: pages.documentManagement.fullPath,
    showSideBar: false,
    component: DocumentManagementPage,
    isModal: true,
    width: 864,
    privileges: [Privileges.protocolView],
    exact: true,
  },
  {
    path: '',
    label: 'front.menu.communication.label',
    icon: Icon.MailUnread,
    showSideBar: true,
    privileges: [],
    items: [
      {
        path: pages.messages,
        label: 'front.menu.mail.label',
        component: MailsPage,
        showSideBar: true,
        privileges: [Privileges.contactLetterView],
      },
      {
        path: pages.partialDemands.type(DemandType.AuthLetter),
        label: 'front.menu.authorized-messages.label',
        showSideBar: true,
        privileges: [Privileges.contactSignedLetterView],
      },
      {
        path: pages.notifications,
        label: 'front.menu.notification.label',
        component: NotificationsPage,
        showSideBar: true,
        privileges: [Privileges.contactNotificationView],
      },
      // {
      //   path: pages.news,
      //   label: 'front.menu.news.label',
      //   component: InProgressPage,
      //   showSideBar: true,
      //   privileges: [Privileges.communicationNews],
      // },
    ],
  },
  {
    path: '',
    label: 'front.menu.info.label',
    icon: Icon.Info,
    showSideBar: true,
    privileges: [],
    items: [
      {
        path: pages.depositCalculator,
        label: 'front.deposit-calculator.title.label',
        component: DepositCalculatorPage,
        showSideBar: true,
        privileges: [Privileges.productDepositView],
      },
      {
        path: pages.departments.fullPath,
        label: 'front.menu.branches.label',
        component: DepartmentsPage,
        showSideBar: true,
        privileges: [Privileges.infoBranchView],
      },
      {
        path: pages.handbooks.fullPath,
        label: 'front.menu.references.label',
        component: HandbooksPage,
        showSideBar: true,
        privileges: [Privileges.infoDictionaryView],
      },
      // {
      //   path: pages.currencyRates,
      //   label: 'front.menu.currency-rates.label',
      //   component: InProgressPage,
      //   showSideBar: true,
      //   privileges: [Privileges.infoCurrencyRateView],
      // },
      {
        path: pages.workProtocol,
        label: 'front.menu.work-protocol.label',
        component: ProtocolsPage,
        showSideBar: true,
        privileges: [Privileges.infoLogView],
      },
      {
        path: pages.announcements.fullPath,
        label: 'front.menu.announcements.label',
        component: AnnouncementsPage,
        showSideBar: true,
        privileges: [Privileges.contactNews],
      },
      {
        path: pages.instructions,
        label: 'front.menu.instructions.label',
        component: InstructionsPage,
        showSideBar: true,
        privileges: [Privileges.instructionDictionaryView],
      },
    ],
  },
  {
    path: '',
    label: 'front.menu.administration.label',
    icon: Icon.Team,
    showSideBar: true,
    privileges: [],
    items: [
      // {
      //   path: pages.organizationData,
      //   label: 'front.menu.organization-data.label',
      //   component: OrganizationsPage,
      //   showSideBar: true,
      //   privileges: [Privileges.adminOrganisationView],
      // },
      {
        path: pages.authorizedPersons,
        label: 'front.menu.authorized-persons.label',
        component: AuthorizedPersonsPage,
        showSideBar: true,
        privileges: [Privileges.adminCompanyPersonView],
      },
      {
        path: pages.roles,
        label: 'front.roles-page.title.label',
        component: RolesPage,
        showSideBar: true,
        privileges: [Privileges.adminPermissionView],
      },
    ],
  },
  {
    path: pages.authRegistration,
    label: 'front.activation-page.title.label',
    component: ActivationPage,
    icon: Icon.Info,
    showSideBar: true,
    isModal: true,
    privileges: [Privileges.registrationFlpView],
  },
  {
    path: pages.paymentRequest.fullPath,
    showSideBar: false,
    component: PaymentRequestPage,
    isModal: true,
    width: 640,
    privileges: [Privileges.paymentDomesticView, Privileges.paymentInternalView],
    exact: true,
  },
  {
    path: pages.internalPayments.fullPath,
    showSideBar: false,
    component: InternalPaymentPage,
    isModal: true,
    width: 640,
    privileges: [Privileges.paymentDomesticView, Privileges.paymentInternalView],
    exact: true,
  },
  {
    path: pages.foreignPayment.fullPath,
    showSideBar: false,
    component: ForeignPaymentPage,
    isModal: true,
    width: 640,
    privileges: [Privileges.paymentForeignView, Privileges.paymentForeignEdit],
    exact: true,
  },
  {
    path: pages.transactionHistory.fullPath,
    showSideBar: false,
    component: TransactionHistoryPage,
    isModal: true,
    width: 640,
    privileges: null,
    exact: true,
  },
  {
    path: pages.message(':id', ':action?'),
    showSideBar: false,
    component: MailPage,
    isModal: true,
    width: 708,
    privileges: [Privileges.contactLetterView],
  },
  {
    path: pages.creditDemand.fullPath,
    showSideBar: false,
    component: CreditDemandPage,
    isModal: true,
    width: 640,
    privileges: null,
    exact: true,
  },
  {
    path: pages.depositDemand.fullPath,
    showSideBar: false,
    component: DepositDemand,
    isModal: true,
    width: 640,
    privileges: null,
    exact: true,
  },
  {
    path: pages.smsDemand.fullPath,
    showSideBar: false,
    component: SmsDemandPage,
    isModal: true,
    width: 640,
    privileges: null,
    exact: true,
  },
  {
    path: pages.newSalaryDemand.fullPath,
    showSideBar: false,
    component: NewSalaryDemand,
    isModal: true,
    width: 640,
    privileges: null,
    exact: true,
  },
  {
    path: pages.cardDemand.fullPath,
    showSideBar: false,
    component: CardDemandPage,
    isModal: true,
    width: 640,
    privileges: null,
    exact: true,
  },
  {
    path: pages.handbooks.paymentPurpose(':id'),
    showSideBar: false,
    component: PaymentPurposePage,
    isModal: true,
    width: 708,
    privileges: [Privileges.paymentDomesticView, Privileges.paymentInternalView],
  },
  {
    path: pages.handbooks.counterparty(':id'),
    showSideBar: false,
    component: CounterpartyPage,
    isModal: true,
    width: 708,
    privileges: [Privileges.paymentDomesticView, Privileges.paymentInternalView],
  },
  {
    path: pages.handbooks.orderTemplateFullPath(':id'),
    showSideBar: false,
    component: OrderTemplate,
    isModal: true,
    width: 708,
    privileges: [Privileges.paymentDomesticView, Privileges.paymentInternalView],
  },
  {
    path: pages.account(':id'),
    showSideBar: false,
    component: AccountEditPage,
    isModal: true,
    width: 332,
    privileges: [Privileges.productAccountEdit],
  },
  {
    path: pages.card(':id'),
    showSideBar: false,
    component: CardEditPage,
    isModal: true,
    width: 332,
    privileges: [Privileges.productCardView],
  },
  {
    path: pages.credit(':id'),
    showSideBar: false,
    component: CreditEditPage,
    isModal: true,
    width: 332,
    privileges: [Privileges.productCreditView],
  },
  {
    path: pages.deposit(':id'),
    showSideBar: false,
    component: DepositEditPage,
    isModal: true,
    width: 332,
    privileges: [Privileges.productDepositView],
  },
  {
    path: pages.profile.createCertificate,
    showSideBar: false,
    component: CreateKepCertificatePage,
    isModal: true,
    width: 446,
    privileges: [Privileges.keysAddNew],
    exact: true,
  },
  {
    path: pages.profile.CreateESeal,
    showSideBar: false,
    component: CreateESeal,
    isModal: true,
    width: 446,
    privileges: [Privileges.sealKeysAddNew],
    exact: true,
  },
  {
    path: pages.profile.addCertificate,
    showSideBar: false,
    component: AddKepCertificatePage,
    isModal: true,
    width: 426,
    privileges: [Privileges.keysAddExisted],
    exact: true,
  },
  {
    path: pages.profile.changePassword,
    showSideBar: false,
    component: ChangePasswordPage,
    isModal: true,
    width: 500,
    privileges: null,
    exact: true,
  },
  {
    path: pages.profile.changeEmail,
    showSideBar: false,
    component: ChangeEmailPage,
    isModal: true,
    width: 500,
    privileges: null,
    exact: true,
  },
  {
    path: pages.accountRequisites(),
    showSideBar: false,
    component: AccountRequisitesPage,
    isModal: true,
    width: 600,
    privileges: [Privileges.productAccountView],
    exact: true,
  },
  {
    path: pages.profile.fullPath,
    showSideBar: false,
    component: ProfilePage,
    privileges: [Privileges.settingsView],
  },
  {
    path: pages.regularPayment.fullPath,
    showSideBar: false,
    isModal: true,
    width: 750,
    component: RegularPaymentPage,
    privileges: [Privileges.paymentRegularView],
  },
  {
    path: pages.salary.fullPath,
    showSideBar: false,
    isModal: true,
    width: 700,
    component: SalaryPage,
    privileges: [Privileges.salarySheetEdit, Privileges.salarySheetView],
  },
  {
    path: pages.import,
    showSideBar: false,
    isModal: true,
    width: 700,
    component: ImportPage,
    privileges: [
      Privileges.salarySheetEdit,
      Privileges.salarySheetView,
      Privileges.paymentInternalView,
    ],
  },
  //partial demands: AcquiringNewTerminalOrder, FEAMessage, AuthLetter
  {
    path: pages.partialDemands.type(':orderType'),
    component: PartialDemandsPage,
    showSideBar: false,
    isModal: false,
    privileges: null,
    exact: true,
  },
  {
    path: pages.partialDemands.fullPath(DemandType.FEAMessage),
    showSideBar: false,
    component: CurrencyDocumentPage,
    isModal: true,
    width: 640,
    privileges: [Privileges.feamessageEdit, Privileges.feamessageView],
    exact: true,
  },
  {
    path: pages.partialDemands.fullPath(DemandType.AcquiringNewTerminalOrder),
    showSideBar: false,
    component: AcquiringDemandPage,
    isModal: true,
    width: 640,
    privileges: [Privileges.demandView],
    exact: true,
  },
  {
    path: pages.partialDemands.fullPath(DemandType.AuthLetter),
    showSideBar: false,
    component: AuthLetterPage,
    isModal: true,
    width: 640,
    privileges: [Privileges.contactSignedLetterView, Privileges.contactSignedLetterEdit],
    exact: true,
  },

  {
    path: pages.editNotification(':id'),
    component: NotificationPage,
    showSideBar: false,
    isModal: true,
    privileges: [Privileges.contactNotificationView, Privileges.contactNotificationEdit],
  },
  {
    path: pages.authPersonPermission(':id'),
    component: AuthorizedPersonPermissionPage,
    isModal: true,
    width: 900,
    privileges: [Privileges.adminCompanyPersonView],
  },
  {
    path: pages.authorizedPersonPage(':id', ':action?'),
    component: AuthorizedPersonPage,
    isModal: true,
    privileges: [Privileges.adminCompanyPersonView], // TODO CHANGE privilegies
  },
  {
    path: pages.role(':id'),
    component: EditRolePage,
    width: 1000,
    isModal: true,
    privileges: [Privileges.adminCompanyPersonView],
  },
  {
    path: pages.employee(':id'),
    label: 'front.employee-page.title.label',
    component: EmployeePage,
    isModal: true,
    privileges: [Privileges.salaryEmployeeEdit],
  },
  {
    path: pages.employeeManagement.fullPath,
    label: 'front.employee-page.title.label',
    component: EmployeeManagementDemandPage,
    isModal: true,
    width: 640,
    privileges: [Privileges.demandSalaryEdit],
  },
  {
    path: pages.cashTransfer.fullPath,
    component: CashTransferPage,
    isModal: true,
    showSideBar: false,
    width: 640,
    privileges: [Privileges.demandCashView, Privileges.demandCashEdit],
  },
  {
    path: pages.currencyExchanges.fullPath,
    component: CurrencyExchangePage,
    isModal: true,
    showSideBar: false,
    width: 640,
    privileges: [Privileges.demandCurrencyExchangeView, Privileges.demandCurrencyExchangeEdit],
  },
];

export const allRoutes = (): RouteConfig[] => {
  const result: RouteConfig[] = [];
  routes.forEach(route => {
    if (route.path) {
      result.push(route);
    }
    if (route.items && route.items.length) {
      route.items.forEach(item => {
        result.push(item);
      });
    }
  });
  return result;
};

export const splitRoutes = (privileges: Privileges[], device: Devices) => {
  const allRoute = allRoutes()
    .filter(route => !!route.component)
    .map(route => ({
      ...route,
      component: isPrivilegedRoute(route, privileges)
        ? route.isModal && device === Devices.Mobile
          ? withoutModal(route.component)
          : route.component
        : NoAccessPage,
    }));

  return {
    modalRoutes: allRoute.filter(route => route.isModal && device !== Devices.Mobile),
    noModalRoutes: allRoute.filter(
      route => !route.isModal || (route.isModal && device === Devices.Mobile),
    ),
  };
};

export default (privileges: Privileges[]) =>
  routes.map(route => {
    if (route.items && route.items.length) {
      route.privileges = [
        ...route.privileges,
        ...route.items.reduce((acc, i) => {
          acc.push(...i.privileges);
          return acc;
        }, []),
      ];
    }
    return {
      ...route,
      showSideBar: route.showSideBar && isPrivilegedRoute(route, privileges),
      items:
        route.items &&
        route.items.map(item => ({
          ...item,
          showSideBar: item.showSideBar && isPrivilegedRoute(item, privileges),
        })),
    };
  });
