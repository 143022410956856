import React from 'react';

import classNames from 'classnames';

import { Button } from 'components/buttons/Button';
import * as Icon from 'components/icons';
import { translate } from 'i18n/translator';

interface Props {
  onClick(e?: any): void;

  title: string;
  block?: boolean;

  className?: string;

  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

export const CreateButton: React.FC<Props> = props => {
  const { onClick, title, className = '', block = false, disabled = false, size = 'md' } = props;

  return (
    <Button
      color="primary"
      onClick={onClick}
      className={classNames('create-btn', className)}
      size={size}
      block={block}
      disabled={disabled}
    >
      <Icon.Create />
      <span className="create-btn_title">{translate(title)}</span>
    </Button>
  );
};
