import { checkMultiCustomer } from 'api/ConstantService';
import { OrderType } from 'api/DemandsService/enums';
import { DemandAction, DynamicField, Financial } from 'api/DemandsService/interfaces';
import { DictionaryKeyCode, OrderState } from 'api/enums';
import { HEADER_ID, needHeaders } from 'api/utils';
import { ExportFormatEnum } from 'components/ExportDropdown/enum';
import { ImportResult } from 'pages/Import/interfaces';
import { FileFieldInterface } from 'pages/Profile/Certificates/CertificateEngine/types';

import { del, downloadFile, get, post, put } from './backend';
import { GridRequest, GridResponse, HasId, IMPORT_TYPES, Option } from './Service';

export type CurrencyOption = {
  id: number;
  label: string;
  value: string;
};

export type Counterparty = {
  bin: string;
  customerId: number;
  id: number;
  name: string;
  addInfo?: string;
  address?: string;
  beneficiaryCode?: string;
  chosen?: boolean;
  city?: string;
  country?: string;
  countryId?: number;

  countryName?: string;
  countryTwoLetterCode?: string;
  internationalName?: string;
  isChosen?: boolean;
  isDeleted?: boolean;
  isNational?: boolean;
  kpp?: string;
  mail?: string;
  national?: boolean;
  notes?: string;
  phone?: string;
};

export interface CounterpartyAccount {
  bankCode: string;
  bankId: number;
  bankName: string;
  iban: string;
  id: number;
  agentAddress?: string;
  agentBankCode?: string;
  agentBankName?: string;
  agentCity?: string;
  agentCorrBankAcc?: string;

  agentCountryId?: string | number;
  agentCountryName?: string;
  agentCountryTwoLetterCode?: string;
  agentId?: string | number;
  bank?: AccountBank;
  bankAddress?: string;
  bankCity?: string;
  bankCountryId?: number;
  bankCountryName?: string;
  bankCountryTwoLetterCode?: string;
  corrBankAccount?: string;
  counterpartyId?: number;
  currency?: string;
  isDeleted?: false;
  iso?: string;
  number?: string;
}

export type AccountBank = {
  address?: string;
  bankCode?: string;
  bankName?: string;
  city?: string;
  corrBankAccount?: string;
  country?: string;
  deleted?: boolean;
  externalId?: number;
  id?: number;
  internationalBankBik?: string;
  internationalBankName?: string;
  nationalBankBik?: string;
};

interface Account {
  bankId: number;
  counterpartyId: number;
  currency: string;
  iban: string;
  id: number;
}

export interface Counterparties {
  accounts: Array<Account>;
  addInfo: string;
  address: string;
  beneficiaryCode: string;
  bin: string;
  chosen: boolean;
  city: string;
  counterparty: Counterparty;
  countryId: number;
  customerId: number;
  customerName: string;
  id: number;
  internationalName: string;
  mail: string;
  name: string;
  national: boolean;
  notes: string;
  phone: number;
  tax: string;
}

export interface PaymentPurposesGrid {
  id: number;
  isDeleted: boolean;
  purpose: string;
}

export interface UkraineBanksGrid {
  address: string;
  bankCode: string;
  bankName: string;
  city: string;
  corrBankAccount: string;
  countryId: number;
  id: number;
  nationalBankBik: string;
  twoLetterCode: string;
}

export interface CurrencyHistoryGrid {
  baseCurrencyIsoCode: string;
  coursePurchase: string;
  courseSale: string;
  currencyIsoCode: string;
  deleted: boolean;
  id: number;
  scale: string;
  type: CurrencyOption;
  validFrom: Date;
  validTo: Date;
}

export interface PaymentPurpose {
  purpose: string;
  id?: number;
  isDeleted?: boolean;
}

export interface CounterpartyInfo {
  accounts: Partial<CounterpartyAccount>[];
  counterparty: Counterparty;
  otpCode: string;
}

export interface HandbooksFilter {
  searchText: string;
}

export interface OrderTemplateBase extends HasId {
  created: string;
  customerId: number;
  customerName: string;
  orderAlias: string;
  orderDate: Date;
  orderKind: string;
  orderNumber: string;
  orderState: OrderState;
  orderStateLabel: string;
  orderType: OrderType;
  updated: string;
  bankResponse?: string;
  commission?: number;
  managerId?: number;
  priority?: number;
  translatedOrderType?: string;
}

export interface OrderTemplate extends HasId {
  actions: DemandAction[];
  base: OrderTemplateBase;
  dynamicFields: DynamicField[];
  financial: Financial;
}

export interface PaymentPurposeFilter {
  searchText: string;
  customerIds?: number[];
}

interface CounterpartyFilter extends GridRequest {
  bin: string;
  customerIds: number[];
  isNational?: boolean;
}

export interface CounterpartyFilteredModel {
  beneficiaryCode: string;
  bin: string;
  customerId: number;
  id: number;
  name: string;
}

export type HandbooksRequest = GridRequest & HandbooksFilter;

export enum OrderTypes {
  InternalTransfer = 'InternalTransfer',
  AccountTransfer = 'AccountTransfer',
  DomesticTransfer = 'DomesticTransfer',
  ForeignTransfer = 'ForeignTransfer',
  ExposedOrder = 'ExposedOrder',
}

interface TranslatedName {
  en: string;
  ru: string;
  uk: string;
}

export interface Instruction {
  code: string;
  description: string;
  dictionaryValue: string;
  externalId: number;
  id: number;
  name: string;
  translatedName: {
    data: TranslatedName;
    text: string;
  };
  upLevelCode: string;
  children?: Array<Instruction>;
}

export interface OrderTemplatesFilterProps extends GridRequest {
  customerIds: number[];
  searchText: string;
  types?: OrderType[];
}

interface CounterpartyExcelModel {
  searchText: string;
}

export class HandbooksService {
  async getInstructions(request: Instruction & GridRequest): Promise<Instruction[]> {
    return await get('/v1/instructions/Instruction', request);
  }

  async getNationalCounterparties(req: CounterpartyFilter): Promise<GridResponse<Counterparties>> {
    req.isNational = true;
    return await get('/v2/counterparty', req);
  }

  async getCounterparty(id: number): Promise<CounterpartyInfo> {
    return await get(`/counterparty/find-one-national/${id}`);
  }

  async getBanks(): Promise<Array<UkraineBanksGrid>> {
    return await get('/local-banks/get-dto-list');
  }

  async getCounterpartyExcel(request: CounterpartyExcelModel): Promise<void> {
    return await downloadFile('/counterparty/excel/true', {}, request);
  }

  async createCounterparty(request: CounterpartyInfo, customerId: number): Promise<number> {
    return await post('/counterparty/save-national', request, { customerId });
  }

  async updateCounterparty(request: CounterpartyInfo, customerId: number): Promise<number> {
    return await put('/counterparty/save-national', request, { customerId });
  }

  async getFilteredCounterparty(
    params: CounterpartyFilter,
  ): Promise<GridResponse<CounterpartyFilteredModel>> {
    return await get('/v2/counterparty', params);
  }

  async deleteCounterparties(ids: Array<number>, otpCode: string): Promise<void> {
    return await put('/counterparty/group-delete', { ids, otpCode });
  }

  async getPaymentPurposes(request: HandbooksRequest): Promise<GridResponse<PaymentPurpose>> {
    return await get('/v2/payment-purpose', checkMultiCustomer(request));
  }

  async getPaymentPurpose(id: number): Promise<PaymentPurpose> {
    return await get(`/payment-purpose/view/${id}`);
  }

  async getPaymentPurposeExcel(filter: PaymentPurposeFilter): Promise<void> {
    return await downloadFile('/payment-purpose/get-excel/', {}, filter);
  }

  async createPaymentPurpose(request: PaymentPurpose): Promise<{ value: number }> {
    return await post('/v1/payment-purpose/create', request);
  }

  async updatePaymentPurpose(request: PaymentPurpose): Promise<void> {
    return await put('/payment-purpose', request);
  }

  async deletePaymentPurpose(id: number): Promise<void> {
    return await del(`/payment-purpose/${id}`);
  }

  async deletePaymentPurposes(ids: Array<number>): Promise<void> {
    return await put('/payment-purpose/group-delete', { ids });
  }

  async deleteCounterParty(id: number, code: string): Promise<void> {
    return await del(`/counterparty/${id}`, code);
  }

  async getUkraineBanks(request: HandbooksRequest): Promise<GridResponse<UkraineBanksGrid>> {
    return await get('/local-banks', request);
  }

  async getUkraineBanksExcel(filter: HandbooksRequest): Promise<void> {
    return await downloadFile('/local-banks/get-excel/', {}, filter);
  }

  async getCurrencyHistory(request: HandbooksRequest): Promise<GridResponse<CurrencyHistoryGrid>> {
    return await get('/exchange-rate', request);
  }

  async getCurrencyHistoryExcel(filter: HandbooksRequest): Promise<void> {
    return await downloadFile('/exchange-rate/get-excel/', {}, filter);
  }

  async getOrderTemplates(filter: OrderTemplatesFilterProps): Promise<GridResponse<OrderTemplate>> {
    return await get('/v2/orders', checkMultiCustomer({ ...filter, isTemplate: true }));
  }

  async deleteOrderTemplate(id: number): Promise<void> {
    return await put(`/v1/orders/${id}/DELETE`);
  }

  async getDictionaryOptions(upLevelCode: DictionaryKeyCode): Promise<Option[]> {
    return await get(`/codes/options/${upLevelCode}`);
  }

  async importCounterpartiesV2(
    files: FileFieldInterface[] = [],
    orderType: IMPORT_TYPES,
    customerId: number,
    productId: number,
    fileFormat: ExportFormatEnum,
  ): Promise<ImportResult> {
    const formData = files.reduce((data, file) => {
      data.append('data', file.value);
      return data;
    }, new FormData());

    const result = await post('/v2/import', formData, {
      orderType,
      customerId,
      productId,
      objectCode: orderType,
    });

    const hasHeaders = needHeaders(fileFormat, orderType);

    const newErrors = result.errors.map(
      (error: { data: { counterparty: { id: string | number } } }) => ({
        ...error,
        id: hasHeaders ? +error.data.counterparty.id + HEADER_ID : +error.data.counterparty.id,
      }),
    );

    return { ...result, errors: newErrors };
  }

  async acceptCounterparties(ids: number[]): Promise<void> {
    await post('/v1/import/accept', ids, { objectCode: IMPORT_TYPES.COUNTERPARTIES });
  }
}
