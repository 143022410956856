import * as React from 'react';

import { DatePeriodEnum, getSelectedValueKey } from 'components/forms/datePicker/utils';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import * as format from 'components/utils/format';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { PERIOD_KEY } from 'pages/Payments/InternalPayment/InternalPaymentFilter';
import { getCurrentPeriod } from 'pages/Payments/InternalPayment/utils';
import { end, start } from 'pages/Products/utils/productUtils';
import { LOCAL_STORAGE_KEY } from 'store/actions/types';
import { isString } from 'utils/isData';

type StoragePeriod = {
  clearPeriod: () => void;
  datePeriodFrom: string | Date;
  datePeriodTo: string | Date;
  onApplyPeriodChanges: () => void;
};

export const useStoragePeriod = (pageLocation: string): StoragePeriod => {
  const { getFieldValue, updateFieldInData } = useForm();
  const dateFrom = getFieldValue('dateFrom') as Date;
  const dateTo = getFieldValue('dateTo') as Date;

  const [storagePeriod, setStoragePeriod] = useLocalStorage(
    LOCAL_STORAGE_KEY.PRESERVED_VALUES,
    `${pageLocation}.${PERIOD_KEY}`,
  );
  const [storageDateFrom, storageDateTo, selectedStoragePeriod] = storagePeriod ?? [];

  const [datePeriodFrom, datePeriodTo] = React.useMemo(() => {
    let datePeriodFrom;
    let datePeriodTo;

    const hasStoragePeriod = isString(selectedStoragePeriod);

    if (!hasStoragePeriod) {
      datePeriodFrom = format.parseDate(start);
      datePeriodTo = format.parseDate(end);
    }

    if (hasStoragePeriod) {
      if (selectedStoragePeriod === DatePeriodEnum.SELECT_RANGE) {
        datePeriodFrom = storageDateFrom;
        datePeriodTo = storageDateTo;
      } else {
        const [defaultDateFrom, defaultDateTo] = getCurrentPeriod(
          selectedStoragePeriod as DatePeriodEnum,
        );

        datePeriodFrom = defaultDateFrom;
        datePeriodTo = defaultDateTo;
      }
    }

    return [datePeriodFrom, datePeriodTo];
  }, []);

  const currentSelectedPeriod = React.useMemo(() => {
    const value = format.dateRange(dateFrom, dateTo);

    return getSelectedValueKey(value).key;
  }, [dateFrom, dateTo]);

  const onApplyPeriodChanges = () =>
    setStoragePeriod([format.date(dateFrom), format.date(dateTo), currentSelectedPeriod]);

  const clearPeriod = () => {
    updateFieldInData('dateFrom', 'defaultValue', null);
    updateFieldInData('dateTo', 'defaultValue', null);
  };

  return { datePeriodFrom, datePeriodTo, onApplyPeriodChanges, clearPeriod };
};
