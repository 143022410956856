import * as React from 'react';
import { Transform } from 'react-html-parser';

import { RedirectButtonProps } from 'components/buttons/components/RedirectButton/interfaces';
import { RedirectButton } from 'components/buttons/components/RedirectButton/RedirectButton';

const TRANSFORM_TAG_ID = 'questionnaire';

const getQueryParam = <T extends Obj>(queryString: string): T => {
  const pairsArray = queryString.split('&').map(pair => pair.split('='));
  return Object.fromEntries(pairsArray) as T;
};

export const transformForMail: Transform = (node): React.ReactElement => {
  if (node.type === 'tag' && node.name === 'a' && node.attribs.id === TRANSFORM_TAG_ID) {
    const { customerId, questionnaireId, claimTokenCode } = getQueryParam<RedirectButtonProps>(
      node.attribs['data-query'],
    );

    return (
      <RedirectButton
        claimTokenCode={claimTokenCode}
        questionnaireId={questionnaireId}
        customerId={customerId}
        size="sm"
      />
    );
  }
};
