import React from 'react';

import { DynamicFieldValue } from 'api/DemandsService/interfaces';
import { Option } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { PhoneField } from 'components/forms/inputs/PhoneField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { useDisabledCustomer } from 'hooks/useDisabledCustomer';
import { SMSNotificationDetailNames } from 'pages/Demands/SmsDemandPage/enums';

interface Fields {
  [SMSNotificationDetailNames.Customer]: string;
  [SMSNotificationDetailNames.Card]: string;
  [SMSNotificationDetailNames.Phone]: string;
}

interface Payload {
  [SMSNotificationDetailNames.Card]: Option<DynamicFieldValue>[];
}

export const SMSNotificationDetail = () => {
  const {
    order,
    payload: { card },
    onChangeCustomer,
  } = useOrderDetails<Fields, Payload>();
  const isDisabledCustomer = useDisabledCustomer(order);

  return (
    <DefaultForm>
      <CustomerSelect
        name={SMSNotificationDetailNames.Customer}
        label="front.sms-notification.organization.label"
        onSelectOption={onChangeCustomer}
        selectDefaultCustomer
        disabled={isDisabledCustomer}
        required
      />
      <SelectField
        name={SMSNotificationDetailNames.Card}
        label="front.sms-notification.card.label"
        options={card}
        required
      />
      <PhoneField
        name={SMSNotificationDetailNames.Phone}
        label="front.sms-notification.phone.label"
        required
      />
    </DefaultForm>
  );
};
