import React from 'react';

import { DataFetcherContext } from 'components/DataFetcher/DataFetcherContext';

export const useDataFetcherFilter = () => {
  const { filter, updateFilter } = React.useContext(DataFetcherContext);

  return {
    filter,
    updateFilter,
  };
};
