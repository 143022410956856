import moment from 'moment';

import { api } from 'api';
import { checkMultiCustomer } from 'api/ConstantService';
import { Country } from 'api/CountryService';
import { OrderType } from 'api/DemandsService/enums';
import { DemandResponse, PartialDemandRequest } from 'api/DemandsService/interfaces';
import { OrderActionType, OrderState, PaymentStatuses, PaymentTypes, VatType } from 'api/enums';
import { CounterpartyInfo } from 'api/HandbooksService';
import {
  Account,
  AccountPaymentParam,
  AccountTransferSourceFields,
  Bank,
  BankSwift,
  DataFromWebBank,
  DocumentNumberRequest,
  DomesticSourceFields,
  ExposedOrderSourceFields,
  ImportError,
  ImportPaymentsResponse,
  InternationalSourceFields,
  OrderFieldResponse,
  OrderFields,
  OrderRequest,
  ParamsForDomesticSourceFields,
  SignCertificateOrders,
  SigningOrders,
  SignOrder,
  SignOrderResponse,
  Transaction,
  TransactionHistoryFilterInterface,
  TransactionPayment,
  TransactionsFilters,
  TransferSourceFieldsReturn,
} from 'api/interfaces';
import { GetCurrentDataFromWebBankParams } from 'api/PaymentService/interfaces';
import { isTypeErrorSkipped, preparePurpose } from 'api/PaymentService/utils';
import { createAccountLabel, createAccountOptions, needHeaders } from 'api/utils';
import { OnSaveOptions } from 'components/Document/withOrder';
import { ExportFormatEnum } from 'components/ExportDropdown/enum';
import { createFormDataFromObj } from 'components/utils/createFormDataFromObj';
import config from 'config';
import { translate } from 'i18n/translator';
import { OrderParams } from 'navigations/pages';
import { DebtQueryInterface } from 'pages/Login/CustomerSelectModal/DebtModal/interface';
import {
  DomesticSourceFieldsEnum,
  InternalSourceFieldsEnum,
} from 'pages/Payments/InternalPayment/enums';
import { PaymentRequestsSourceFieldsEnum } from 'pages/Payments/PaymentRequest/enums';
import { FileFieldInterface } from 'pages/Profile/Certificates/CertificateEngine/types';
import { AgreementModal } from 'pages/utils/SignOrders/AgreementModal';
import { AgreementModalResult } from 'pages/utils/SignOrders/enums';

import { downloadFile, get, post, put } from '../backend';
import { cache, compareNumber, GridRequest, GridResponse, IMPORT_TYPES, Option } from '../Service';

const ACCRUED_INCOME_RKO_MASK_ACCOUNT = '3570';
const ACCRUED_COMMISSION_RKO_MASK_ACCOUNT = '3739';

const ACCOUNT_MASKS = [ACCRUED_INCOME_RKO_MASK_ACCOUNT, ACCRUED_COMMISSION_RKO_MASK_ACCOUNT];

const ERROR_CODE_RENAMING = 'dataImport.common.error.orderRenamedNumber';

const createLabelReceiver = ({ counterparty, accounts }: CounterpartyInfo): string => {
  const ibanLabel = accounts[0] ? `/ ${accounts[0].iban || ''}` : '';
  return `${counterparty.internationalName} ${ibanLabel}`;
};

const getCountriesOptions = (countries: Country[]) =>
  countries.map(item => ({
    value: String(item.id),
    label: `${item.countryName} ${item.countryCode}`,
    content: item,
  }));

export class PaymentsService {
  async getWorkingDocs(request: OrderRequest): Promise<GridResponse<DemandResponse>> {
    return await get('/v2/orders', checkMultiCustomer(request));
  }

  async getDocument(id: number): Promise<DemandResponse> {
    return await get(`/v2/orders/${id}`);
  }

  async createPayment(request: PartialDemandRequest): Promise<number> {
    return await post('/v2/orders', request);
  }

  async createPaymentFromTransaction(transactionId: number): Promise<number> {
    return await post('/v2/orders/from-transaction', { value: transactionId });
  }

  async acceptImportPayments(ids: number[], objectCode: string): Promise<void> {
    return await post('/v1/import/accept', ids, { objectCode });
  }

  async updatePayment(id: number, request: PartialDemandRequest): Promise<number> {
    return await put(`/v2/orders/${id}`, request);
  }

  async signOrder(data: SignOrder[]): Promise<SignOrderResponse> {
    return await post('/v2/order/signing', data);
  }

  async canSigningOrders(orderIds: number[]): Promise<SigningOrders[]> {
    return await get('/v1/orders/signing/factors', { orderIds });
  }

  async signCertificateOrder(orderIds: Array<number>): Promise<SignCertificateOrders> {
    return await get('/v2/orders/signing/data-for-sign', { orderIds });
  }

  async getCurrentDataFromWebBank({
    customerId,
    keystoreType,
    isReissue,
    certificateType,
    keystoreName,
  }: GetCurrentDataFromWebBankParams): Promise<DataFromWebBank> {
    return await get(`/v1/ugb/certificates/${customerId}/require_data_for_cert`, {
      keystoreType,
      isReissue,
      keystoreName,
      certificateType,
    });
  }

  async checkIsAvailableSignForCustomers(
    customerId: number,
    orderType: OrderType,
  ): Promise<boolean> {
    return await get('/v1/orders/signing/has-rules', { customerId, orderType });
  }

  async deleteOrder(id: number): Promise<any> {
    return await put(`/v1/orders/${id}/DELETE`);
  }

  async withdrawOrders(ids: Array<number>): Promise<Array<number>> {
    return await put('/v1/orders/withdraw', ids);
  }

  async getOrderPdf(id: number): Promise<any> {
    return await downloadFile(`/v1/orders/get-pdf/${id}`);
  }

  async exportOrder(filter: OrderRequest): Promise<any> {
    return await downloadFile('/v1/orders/export', {}, filter);
  }

  async createPaymentPurpose(request: { purpose: string }): Promise<{ value: number }> {
    return await post('/v1/payment-purpose/create', request);
  }

  async createOrderTemplate(id: number, name: OnSaveOptions['templateName']): Promise<number> {
    return await post(`/v1/orders/${id}/template`, {}, { name });
  }

  async getDocumentNumber(request: DocumentNumberRequest): Promise<string> {
    return await get('/v1/orders/generate-number', request);
  }

  async getTransactionHistory(request: TransactionsFilters): Promise<GridResponse<Transaction>> {
    return await get('/account/transaction-history', checkMultiCustomer(request));
  }

  async getTransactionHistoryById(id: string): Promise<TransactionPayment> {
    return await get(`/account/transaction-history/${id}`);
  }

  exportTransactionHistory(filter: TransactionHistoryFilterInterface): Promise<any> {
    return downloadFile('/account/transaction-history/export', {}, filter);
  }

  async getAccountsForPayment(params: AccountPaymentParam = {}): Promise<GridResponse<Account>> {
    return await get('/v1/account/get-accounts-for-payment', params);
  }

  async getAccountsForStatementOptions(
    customerId?: Array<string | number>,
  ): Promise<Option<Account>[]> {
    const request = {
      customerId,
      objectCode: 'AccountStatement',
      fieldCodes: ['ACCOUNTS'],
    };

    const { ACCOUNTS } = await get('/v1/source-fields', request);

    return createAccountOptions(ACCOUNTS);
  }

  async getAccountsForFilterPayment(customerId?: Array<string | number>): Promise<Option[]> {
    const request = {
      customerId,
      objectCode: 'DomesticTransfer',
      fieldCodes: ['NATIONAL_ACCOUNTS'],
    };

    const { NATIONAL_ACCOUNTS }: DomesticSourceFields = await get('/v1/source-fields', request);
    const accounts = NATIONAL_ACCOUNTS.filter(item => item.allowDebit);

    return createAccountOptions(accounts);
  }

  async getAccountForPaymentOptions(params: AccountPaymentParam = {}): Promise<Option[]> {
    const accounts = await this.getAccountsForPayment(params);
    const filteredAccounts = accounts.rows.filter(item => item.allowStatement);

    return createAccountOptions(filteredAccounts);
  }

  async getInternalTransferSourceFields(
    fieldList: Array<InternalSourceFieldsEnum>,
    generateNumber: boolean,
    customerId: number,
    params?: OrderParams<DebtQueryInterface>,
  ): Promise<TransferSourceFieldsReturn> {
    const filteredFieldList = fieldList.filter(
      params?.isDebt ? Boolean : item => item !== InternalSourceFieldsEnum.REPORTS_FOR_PURPOSE_DEBT,
    );

    const fields: AccountTransferSourceFields = await get(
      '/payment/account-transfer/source-field',
      {
        customerId,
        fieldList: filteredFieldList,
      },
    );

    const {
      CUSTOMER: customer,
      ALLOWED_CURRENCY_FOR_INTERNAL_TRANSFER: currencies,
      ACCOUNTS: activeAccounts,
      ACCOUNT_TRANSFER_PAYMENT_PURPOSE: purpose,
      PURPOSES: purposes,
      REPORTS_FOR_PURPOSE_DEBT,
    } = fields;

    let documentNumber = null;
    if (generateNumber) {
      documentNumber = await this.getDocumentNumber({
        customerId,
        orderType: PaymentTypes.INTERNAL_TRANSFER,
        orderDate: moment().format('YYYY-MM-DD'),
      });
    }

    const payerOptions = [{ value: customer.name, label: customer.name }];
    const allowedCurrencies = currencies.toUpperCase().split(',');

    const accounts = activeAccounts.filter(
      item => item.allowDebit && allowedCurrencies.includes(item.currency),
    );
    const creditAccounts = activeAccounts.filter(
      item => item.allowCredit && allowedCurrencies.includes(item.currency),
    );

    const accountsOptions = createAccountOptions(accounts);
    const creditAccountsOptions = createAccountOptions(creditAccounts);

    return {
      budgetsOptions: [],
      countriesOptions: [],
      localBanksOptions: [],
      payerAccountOptions: [],
      receiverOptions: [],
      purposes,
      payerOptions,
      accountsOptions,
      creditAccountsOptions,
      formFields: {
        purpose: preparePurpose({ purpose, activeAccounts, REPORTS_FOR_PURPOSE_DEBT, params }),
        payerName: customer.name,
        payerTaxCode: customer.taxCode,
        payerBic: customer.bankMFO,
        payerBankName: customer.bankName,
        receiverName: customer.name,
        receiverTaxCode: customer.taxCode,
        receiverBic: customer.bankMFO,
        receiverBankName: customer.bankName,
        orderDate: moment().toDate(),
        orderNumber: documentNumber,
      },
    };
  }

  async getDomesticSourceFields(
    params: ParamsForDomesticSourceFields,
  ): Promise<DomesticSourceFields> {
    return await get('/payment/domestic-transfer/source-field', params);
  }

  async getInternationalSourceFields(
    params: ParamsForDomesticSourceFields,
  ): Promise<InternationalSourceFields> {
    return await get('/payment/international-transfer/source-field', params);
  }

  async getCurrencyExchangeSourceField(customerId: number) {
    const { NATIONAL_ACCOUNTS: nationalAccounts }: DomesticSourceFields =
      await this.getDomesticSourceFields({
        customerId: customerId,
        fieldList: ['NATIONAL_ACCOUNTS'],
        domesticTransferType: 'PaymentOrder',
      });

    const { ACCOUNTS: currencyAccounts, CUSTOMER: customer } =
      await api.payments.getInternationalSourceFields({
        customerId: customerId,
        fieldList: ['ACCOUNTS', 'CUSTOMER'],
      });

    const nationals = nationalAccounts.filter(item => item.allowDebit);
    const currencies = currencyAccounts.filter(item => item.allowDebit);

    const uahAccountOptions: Option<Account>[] = createAccountOptions(nationals);
    const currencyAccountOptions: Option<Account>[] = createAccountOptions(currencies);

    const payerOptions = { taxCode: customer.taxCode };

    return { uahAccountOptions, currencyAccountOptions, payerOptions };
  }

  async getDomesticTransferSourceFields(
    fieldList: Array<DomesticSourceFieldsEnum>,
    generateNumber?: boolean,
    customerId?: number,
  ): Promise<TransferSourceFieldsReturn> {
    const fetchSourceField = this.getDomesticSourceFields({
      customerId,
      fieldList,
      domesticTransferType: 'PaymentOrder',
    });

    const generateDocumentNumber: string =
      generateNumber &&
      (await this.getDocumentNumber({
        customerId,
        orderType: PaymentTypes.DOMESTIC_TRANSFER,
        orderDate: moment().format('YYYY-MM-DD'),
      }));

    const [sourceFields, documentNumber] = await Promise.all([
      fetchSourceField,
      generateDocumentNumber,
    ]);

    const {
      CUSTOMER: customer,
      NATIONAL_ACCOUNTS: nationalAccounts,
      COUNTERPARTIES: counterparties,
      LOCAL_BANKS: localBanks,
      COUNTRIES: countries,
      KBK: budgets,
      PURPOSES: purposes,
      ASP_PROVIDERS: aspProviders,
    } = sourceFields;

    const payerOptions = [{ value: customer.name, label: customer.name }];
    const accounts = nationalAccounts.filter(item => item.allowDebit);

    const accountsOptions: Option<Account>[] = createAccountOptions(accounts);

    const receiverOptions = counterparties.map(item => ({
      value: item.counterparty.name,
      label: item.counterparty.name,
      content: item,
    }));

    const localBanksOptions = localBanks.map(item => ({
      value: `${item.nationalBankBik}`,
      label: `${item.nationalBankBik || ''} ${item.bankName}`,
      content: item,
    }));

    aspProviders.map(item =>
      localBanksOptions.push({
        value: `${item.bank.nationalBankBik}`,
        label: `${item.bank.nationalBankBik || ''} ${item.bank.bankName}`,
        content: item.bank,
      }),
    );

    const countriesOptions = getCountriesOptions(countries);

    const budgetsOptions = budgets
      .sort((a, b) => compareNumber(a.displayOrder, b.displayOrder))
      .map(item => ({
        value: `${item.code}`,
        label: `${item.code} ${item.label}`,
        content: item,
      }));

    return {
      payerOptions,
      accountsOptions,
      receiverOptions,
      localBanksOptions,
      countriesOptions,
      budgetsOptions,
      purposes,
      formFields: {
        orderNumber: documentNumber,
        orderDate: moment().toDate(),
        payerName: customer.name,
        payerTaxCode: customer.taxCode,
        payerBic: customer.bankMFO,
        payerBankName: customer.bankName,
      },
    };
  }

  async getInternationalTransferSourceFields(
    request: Array<string>,
    generateNumber?: boolean,
    customerId?: number,
  ): Promise<TransferSourceFieldsReturn> {
    const fields = await this.getInternationalSourceFields({
      customerId,
      fieldList: request,
    });

    const countryOptions = await api.country.getCountryOptions();

    const {
      CUSTOMER: customer,
      ACCOUNTS: activeAccounts,
      COUNTERPARTIES: counterparties,
      FEE_ACCOUNTS: feeAccounts,
      FEE_TYPES: feeTypes,
      PURPOSES: purposes,
    } = fields;

    let documentNumber = null;
    if (generateNumber) {
      documentNumber = await this.getDocumentNumber({
        customerId,
        orderType: PaymentTypes.FOREIGN_TRANSFER,
        orderDate: moment().format('YYYY-MM-DD'),
      });
    }

    const payerOptions = [{ value: customer.name, label: customer.name }];

    const accounts = activeAccounts.filter(item => item.allowDebit);
    const accountsOptions = createAccountOptions(accounts);

    const receiverOptions = counterparties
      .reduce((acc, item) => {
        if (item.accounts.length > 1) {
          const newCounterparties = item.accounts.map(account => ({
            ...item,
            accounts: [account],
          }));

          return [...acc, ...newCounterparties];
        }

        return [...acc, item];
      }, [])
      .map(item => ({
        value: item.counterparty.internationalName,
        label: createLabelReceiver(item),
        content: item,
      }));

    const feeAccountsOptions = feeAccounts
      .filter(
        item =>
          item.currency === config.bank.nationalCurrency &&
          (ACCOUNT_MASKS.some(mask => item.number.startsWith(mask)) || item.allowDebit),
      )
      .map(item => ({
        value: item.iban || item.number,
        label: createAccountLabel(item),
        content: item,
      }));

    const feeTypesOptions = feeTypes.map(item => ({
      value: item.code,
      label: `${item.code} - ${item.label}`,
      content: item,
    }));

    return {
      payerOptions,
      accountsOptions,
      receiverOptions,
      feeAccountsOptions,
      feeTypesOptions,
      countryOptions,
      purposes,
      formFields: {
        orderNumber: documentNumber,
        orderDate: moment().toDate(),
        payerName: customer.name,
        payerTaxCode: customer.taxCode,
        payerBic: config.page.foreignPayment.defaultPayerBic, // customer.bankMFO,
        payerBankName: customer.bankName,
        commission: 'OUR',
        priority: 0,
        receiverCountryId: null,
        receiverCity: null,
        receiverId: null,
        intermediaryCity: null,
        intermediaryCountryId: null,
        intermediaryId: null,
      },
    };
  }

  async getExposedTransferSourceFields(
    request: Array<PaymentRequestsSourceFieldsEnum>,
    generateNumber?: boolean,
    customerId?: number,
  ): Promise<TransferSourceFieldsReturn> {
    const fields: ExposedOrderSourceFields = await get('/exposedorder/source-field', {
      customerId,
      fieldList: request,
    });

    const {
      CUSTOMER: customer,
      COUNTERPARTIES: counterparties,
      NATIONAL_ACCOUNTS: accounts,
      LOCAL_BANKS: localBanks,
      COUNTRIES: countries,
      ASP_PROVIDERS: aspProviders = [],
    } = fields;

    let documentNumber: string = null;

    if (generateNumber) {
      documentNumber = await this.getDocumentNumber({
        customerId,
        orderType: PaymentTypes.EXPOSED_ORDER,
        orderDate: moment().format('YYYY-MM-DD'),
      });
    }

    const payerOptions = [{ value: customer.name, label: customer.name }];
    const receiverOptions = counterparties
      .filter(item => item.accounts.length)
      .map(item => ({
        value: item.counterparty.name,
        label: `${item.counterparty.name} ${item.counterparty.bin}`,
        content: item,
      }));

    const localBanksOptions = localBanks.map(item => ({
      value: `${item.nationalBankBik}`,
      label: `${item.nationalBankBik || ''} ${item.bankName}`,
      content: item,
    }));

    aspProviders.map(item =>
      localBanksOptions.push({
        value: `${item.bank.nationalBankBik}`,
        label: `${item.bank.nationalBankBik || ''} ${item.bank.bankName}`,
        content: item.bank,
      }),
    );

    const countriesOptions = getCountriesOptions(countries);

    const payerAccountOptions = createAccountOptions(accounts);

    return {
      receiverOptions,
      payerOptions,
      payerAccountOptions,
      localBanksOptions,
      countriesOptions,
      formFields: {
        orderNumber: documentNumber,
        orderDate: moment().toDate(),
        payerBic: `${customer.bankMFO} ${customer.bankName}`,
        payerName: customer.name,
        payerBankName: customer.bankName,
        payerTaxCode: customer.taxCode,
        vatType: VatType.WITHOUT_VAT,
      },
    };
  }

  async getInternationalBanks(
    params: GridRequest & { search: string },
  ): Promise<GridResponse<BankSwift>> {
    return await get('/banks/get-international-banks-swifts', params);
  }

  getInternationalBanksOption = async (
    params: GridRequest & { search: string },
  ): Promise<Array<Option<BankSwift>>> => {
    const banks: GridResponse<BankSwift> = await this.getInternationalBanks(params);

    return banks.rows.map(item => ({
      value: item.internationalBic,
      label: item.internationalBic,
      content: item,
    }));
  };

  /** correct bic expected to get a 1 specific bank */
  async getInternationBankByBic(bic: string): Promise<BankSwift> {
    const banks: GridResponse<BankSwift> = await this.getInternationalBanks({
      search: bic,
    });
    return banks.rows[0];
  }

  @cache
  async getInternationalBank(id: number): Promise<Bank> {
    return await get(`/banks/view/${id}`);
  }

  async createOrderField(type: PaymentTypes | OrderType): Promise<OrderFieldResponse> {
    return await post(`/v1/orders/fields/${type}`);
  }

  async createCounterparty(data: Obj): Promise<number> {
    return await post('/counterparty/save-international', data);
  }

  getPaymentOptionStatuses(): Array<Option<undefined, PaymentStatuses>> {
    return [
      {
        value: PaymentStatuses.All,
        label: 'front.working-documents-table.filter-status-all.label',
      },
      {
        value: PaymentStatuses.Sign,
        label: 'front.working-documents-table.filter-status-sign.label',
      },
      {
        value: PaymentStatuses.SentToBank,
        label: 'front.working-documents-table.filter-status-in-progress.label',
      },
      {
        value: PaymentStatuses.Rejected,
        label: 'front.working-documents-table.filter-status-rejected.label',
      },
      {
        value: PaymentStatuses.Executed,
        label: 'front.working-documents-table.filter-status-processed.label',
      },
    ];
  }

  getInternationalPaymentOptionStatuses(): Array<Option<undefined, PaymentStatuses | string>> {
    return [
      {
        value: '',
        label: 'front.i18n-payment.status-all-applications.label',
      },
      {
        value: PaymentStatuses.Sign,
        label: 'front.i18n-payment.status-in-signing.label',
      },
      {
        value: PaymentStatuses.SentToBank,
        label: 'front.i18n-payment.status-pursuant.label',
      },
      {
        value: PaymentStatuses.Rejected,
        label: 'front.working-documents-table.filter-status-rejected.label',
      },
      {
        value: PaymentStatuses.Executed,
        label: 'front.i18n-payment.status-completed.label',
      },
    ].map(r => {
      r.label = translate(r.label);
      return r;
    });
  }

  getPaymentStatusesByKey(key: string | string[]): string[] {
    if (!key) {
      return [];
    }
    switch (key) {
      case PaymentStatuses.All:
        return [];
      case PaymentStatuses.Sign:
        return [
          OrderState.Draft,
          OrderState.Signed,
          OrderState.PartiallySigned,
          OrderState.NeedSms,
          OrderState.Ready,
        ];
      case PaymentStatuses.SentToBank:
        return [
          OrderState.SentToBank,
          OrderState.RetainedByBank,
          OrderState.OnExecution,
          OrderState.OnPartialExecution,
        ];
      case PaymentStatuses.Executed:
        return [OrderState.Executed, OrderState.PartiallyExecuted];
      case PaymentStatuses.Rejected:
        return [OrderState.Rejected];
      default:
        return [];
    }
  }

  async executeAction(orderId: number, action: OrderActionType, data?: Obj): Promise<any> {
    return await put(`/v1/orders/${orderId}/${action}`, data);
  }

  async importPayments(
    files: any[] = [],
    orderType: string,
    customerId: number,
  ): Promise<ImportPaymentsResponse> {
    const formData = createFormDataFromObj({});

    for (let i = 0; i < files.length; i += 1) {
      formData.append('uploadFile', files[i].value);
    }

    return post('/v1/orders/import', formData, { orderType, customerId });
  }

  async importPaymentsV2(
    files: FileFieldInterface[] = [],
    orderType: IMPORT_TYPES,
    customerId: number,
    isMultiImport: boolean,
    fileFormat: ExportFormatEnum,
  ): Promise<ImportPaymentsResponse> {
    const formData = createFormDataFromObj({});
    files.forEach(file => formData.append('data', file.value));

    const { success, orders, errors, meta, hasErrors }: ImportPaymentsResponse = await post(
      '/v2/import',
      formData,
      {
        orderType,
        customerId,
        isMultiImport,
        objectCode: orderType,
      },
    );

    const hasOrderErrors = errors.length > 0 || meta?.messages?.length > 0;
    const showModalRenaming =
      errors?.filter(({ message }) => message.includes(ERROR_CODE_RENAMING)).length > 0;

    const newErrors: ImportError[] = [];
    let hasUserAgreedToRename: AgreementModalResult = AgreementModalResult.No;

    if (showModalRenaming) {
      hasUserAgreedToRename = (await AgreementModal(
        'front.import.renaming-message.label',
      )) as AgreementModalResult;
    }

    // if user answer to use auto renaming then add order to success
    if (hasUserAgreedToRename === AgreementModalResult.Yes) {
      errors.forEach(
        ({ message, order }) =>
          message === ERROR_CODE_RENAMING && orders.push(order as OrderFields),
      );
    }

    if (hasOrderErrors) {
      const messageMap = new Map<number, string[]>();

      meta.messages
        .filter(message => isTypeErrorSkipped(hasUserAgreedToRename, message.params))
        //map messages to orderIds
        .forEach(({ target, message }) => {
          const orderId = +target;

          if (messageMap.has(orderId)) {
            return messageMap.get(orderId).push(message);
          }

          messageMap.set(orderId, [message]);
        });

      const HEADER_ID = 1;
      const hasHeader = needHeaders(fileFormat, orderType);

      //add messages to grid
      messageMap.forEach((messages, orderId) => {
        const rowError = errors.find(error => error.rowNumber === orderId);
        const currentOrderId = rowError?.rowNumber ?? orderId;

        newErrors.push({
          //add order object or empty order for correct error sorting on grid
          order: rowError?.order ?? { id: orderId },
          messages,
          id: hasHeader ? currentOrderId : currentOrderId + HEADER_ID,
        });
      });
    }

    return {
      success,
      orders,
      hasErrors,
      meta,
      errors: newErrors,
    };
  }
}
