import { make } from 'components/react-container-grid';

export const { Container, Row, Col } = make({
  sizeConfig: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  defaultScale: 12,
});
