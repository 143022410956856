import React from 'react';

import { DynamicFieldValue } from 'api/DemandsService/interfaces';
import { FileType, Option } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { FileField } from 'components/forms/inputs/FileField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextAreaField } from 'components/forms/inputs/TextAreaField';
import { TextField } from 'components/forms/inputs/TextField';
import { useDisabledCustomer } from 'hooks/useDisabledCustomer';
import { useFileExtensions } from 'hooks/useFileExtensions';
import { CurrencyDocumentNames } from 'pages/Demands/CurrencyDocument/enums';
import { OrderBaseFields } from 'pages/Demands/interfaces';
import { isSignedDocuments } from 'pages/Demands/utils';

interface CurrencyDocumentFields extends OrderBaseFields {
  [CurrencyDocumentNames.Customer]: string;
  [CurrencyDocumentNames.Topic]: string;
  [CurrencyDocumentNames.Subject]: string;
  [CurrencyDocumentNames.Body]: string;
}

interface CurrencyDocumentPayload {
  [CurrencyDocumentNames.Topic]: Option<DynamicFieldValue>[];
}

export const CurrencyDocumentDetails = () => {
  const {
    order,
    payload: { topic },
    onChangeCustomer,
  } = useOrderDetails<CurrencyDocumentFields, CurrencyDocumentPayload>();

  const isDisabledCustomer = useDisabledCustomer(order);

  const fileExtensions = useFileExtensions();

  return (
    <DefaultForm>
      <CustomerSelect
        name={CurrencyDocumentNames.Customer}
        label="front.currency-document.customer.label"
        onSelectOption={onChangeCustomer}
        selectDefaultCustomer
        disabled={isDisabledCustomer}
        required
      />
      <SelectField
        name={CurrencyDocumentNames.Topic}
        label="front.currency-document.topic.label"
        options={topic}
        required
      />
      <TextField
        name={CurrencyDocumentNames.Subject}
        label="front.currency-document.subject.label"
        maxLength={120}
        minLength={0}
      />
      <TextAreaField
        name={CurrencyDocumentNames.Body}
        label="front.currency-document.message.label"
        rows={3}
        maxLength={500}
        minLength={0}
        hasAutoGrowing
        hasCounter
      />
      <FileField
        name={CurrencyDocumentNames.Attach}
        label="front.currency-document.attachment.label"
        outputFormat={FileType.BASE_64}
        accept={fileExtensions}
        isEDSSignedDocuments={isSignedDocuments(order?.state)}
        multiple
      />
    </DefaultForm>
  );
};
