import React from 'react';

import { SortOrder } from 'api/Service';

import { DataTableContextValue } from './types';

export const initialTotal = {
  count: 0,
};

export const DataTableContext = React.createContext<DataTableContextValue>({
  gridRequest: {
    page: 0,
    sort: 'id',
    size: 0,
    order: SortOrder.Desc,
    updateGridRequest: () => {},
  },
  selection: {
    selected: [],
    isAllSelected: false,
    isChecked: () => false,
    toggleAllSelected: () => {},
    toggleSelected: () => {},
    setSelected: () => {},
  },
  filter: {
    filter: null,
    updateFilter: () => {},
  },
  breakpoint: null,
  hideHeader: false,
  filterComponent: null,
  actionsComponent: null,
  expandRowComponent: null,
  total: initialTotal,
  rows: [],
  tabOptions: [],
  name: '',
  columns: [],
  actions: [],
  error: null,
  isLoading: false,
  onRowClick: () => {},
  setError: () => {},
  setIsLoading: () => {},
  reFetchData: async () => {},
  isRowDisabled: () => false,
  isHidePagination: false,
});
