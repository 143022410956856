import * as React from 'react';

import { Devices } from 'enums/DevicesEnum';

import { api } from 'api';
import config from 'config';
import { isActionAllowed } from 'navigations/access';
import { Privileges } from 'navigations/privileges';
import { HeaderLarge } from 'pages/Dashboard/Header/HeaderLarge/HeaderLarge';
import { HeaderSmall } from 'pages/Dashboard/Header/HeaderSmall/HeaderSmall';

import { useNotificationsSelector } from './useNotificationsSelector';

interface HeaderProps {
  currentDevice: Devices;
}

export const Header: React.FC<HeaderProps> = props => {
  const { currentDevice } = props;

  const { setNotifications } = useNotificationsSelector();

  const updateNotifications = async () => {
    setNotifications(await api.user.getNotifications());
  };

  React.useEffect(() => {
    if (isActionAllowed([Privileges.contactLetterView])) {
      updateNotifications();
      const interval = setInterval(
        updateNotifications,
        1000 * 60 * config.page.dashboard.notificationPollInterval,
      );
      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  return (
    <>
      {[Devices.Tablet, Devices.Desktop].includes(currentDevice) ? (
        <HeaderLarge />
      ) : (
        <HeaderSmall />
      )}
    </>
  );
};
