import { Option } from 'api/Service';
import { FormState } from 'components/forms/ValidatingForm/FormContext';
import { translate } from 'i18n/translator';
import { isExist } from 'utils/isData';

export const isRadioFieldChecked = (accounts: Option[], id: string, fieldName: string) =>
  accounts?.find(account => account.value === id || account.content.value === id)?.content.value ===
  fieldName;

export const SWIFT_ALLOWED_CHARACTERS_REGEXP = '^[a-zA-Z0-9]{0,34}$';
export const SWIFT_VALIDATION_REGEXP =
  /^([a-zA-Z]){4}([a-zA-Z]){2}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$/;

export const swiftValidation = (value: string) => {
  if (SWIFT_VALIDATION_REGEXP.test(value)) {
    return '';
  }

  return translate('front.changing-deposit-demand.swift-validation.label');
};

export const getDefaultOptions = (isDraft: boolean, periodInterestPayments: any) =>
  isDraft ? null : periodInterestPayments;

export const getDepositFields = ({
  toContract,
  depositType,
  amountOwnAccountTranche,
  placementOwnAccountTranche,
  interestOwnAccountTranche,
  periodInterestPayment,
  ...otherFields
}: FormState) => {
  const hasContract = isExist(toContract);
  const hasDepositType = isExist(depositType);

  return {
    toContract,
    depositType,
    periodInterestPayment: hasContract || hasDepositType ? periodInterestPayment : null,
    interestOwnAccountTranche: hasContract ? interestOwnAccountTranche : null,
    placementOwnAccountTranche: hasContract ? placementOwnAccountTranche : null,
    amountOwnAccountTranche: hasContract ? amountOwnAccountTranche : null,
    ...otherFields,
  };
};
