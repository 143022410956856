import * as React from 'react';
import { Col, Row } from 'react-grid';

import { api } from 'api';
import { IMPORT_TYPES } from 'api/Service';
import { Button } from 'components/buttons/Button';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { ExportDropdown, getExportActions } from 'components/ExportDropdown/ExportDropdown';
import { withForm } from 'components/forms/withForm';
import { PageHeader } from 'components/layout/Page/PageHeader';
import { SearchInput } from 'components/searchInput/SearchInput';
import { CreateButton } from 'components/Table/CreateButton';
import { useFilter } from 'components/Table/DataTable';
import { ToggleFilterButton } from 'components/Table/ToggleFilterButton/ToggleFilterButton';
import { translate } from 'i18n/translator';
import { deniedPrivilegy, DisabledBlock } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { EXPORT_TYPE } from 'pages/Handbooks/CurrencyHistory/CurrencyHistoryFilter';

const CounterpartiesPageFilterForm = () => {
  const { updateFilter, extraFields } = useFilter({
    extraFields: { searchText: '' },
  });

  const exportActions = React.useMemo(() => {
    const action = () => () =>
      api.handbooks.getCounterpartyExcel({
        ...extraFields,
      });

    return getExportActions({
      action,
      exportTypes: EXPORT_TYPE,
    });
  }, [extraFields]);

  return (
    <div>
      <PageHeader.Right>
        <ExportDropdown
          actions={exportActions}
          disabled={deniedPrivilegy([Privileges.infoCounterpartiesView])}
        >
          {translate('front.handbooks-table.excel-export.label')}
        </ExportDropdown>
      </PageHeader.Right>
      <Row>
        <Col xl={5} md={3}>
          <CreateButton
            onClick={() => goto(pages.handbooks.counterparty('new'))}
            title="front.handbooks-table.create.label"
            disabled={deniedPrivilegy([Privileges.infoCounterpartiesEdit])}
          />
          <DisabledBlock privileges={[Privileges.infoCounterpartiesEdit]}>
            <Button
              color="secondary"
              onClick={() => {
                goto(pages.import, { type: IMPORT_TYPES.COUNTERPARTIES });
              }}
            >
              {translate('front.demands.salary.import-button.label')}
            </Button>
          </DisabledBlock>
        </Col>
        <Col xl={7} md={9}>
          <Row>
            <Col lg={9} md={8}>
              <DisabledBlock privileges={[Privileges.infoCounterpartiesView]}>
                <SearchInput
                  placeholder={translate('front.working-documents-table.filter-search.label')}
                  value={extraFields.searchText}
                  onChange={searchText => updateFilter({ searchText })}
                />
              </DisabledBlock>
            </Col>
            <Col lg={3} md={4}>
              <DisabledBlock privileges={[Privileges.infoCounterpartiesView]}>
                <ToggleFilterButton>
                  <Row>
                    <Col md={6}>
                      <CustomerSelect
                        label="front.internal-payment-page.order-payer-name.label"
                        name="customerIds"
                        privilege={Privileges.infoCounterpartiesView}
                        multi
                      />
                    </Col>
                  </Row>
                </ToggleFilterButton>
              </DisabledBlock>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export const CounterpartiesPageFilter = withForm(CounterpartiesPageFilterForm);
