import React from 'react';

import { DemandType } from 'api/DemandsService/enums';
import { OrderState } from 'api/enums';
import { OrderAction } from 'api/OrderService';
import { FileType, Option } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { DateField } from 'components/forms/inputs/DateField';
import { FileField } from 'components/forms/inputs/FileField';
import { PhoneField } from 'components/forms/inputs/PhoneField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextField } from 'components/forms/inputs/TextField';
import { useDisabledCustomer } from 'hooks/useDisabledCustomer';
import { useFileExtensions } from 'hooks/useFileExtensions';
import { translate } from 'i18n/translator';
import { disabledDaysDate, eighteenYearsBack } from 'pages/AuthorizedPersons/AuthorizedPersonPage';
import { EmployeeNewNames } from 'pages/Demands/EmployeeManagment/enums';
import {
  INN_ALLOWED_CHARACTERS,
  maxDate,
  minDate,
  POST_CODE_ALLOWED_CHARACTERS,
  validateEnName,
  validateHouse,
  validateJob,
  validateName,
  validateOnlyNumber,
} from 'pages/Demands/EmployeeManagment/utils';
import {
  disableDaysAfterToday,
  disabledDaysBeforeToday,
  isSignedDocuments,
  today,
} from 'pages/Demands/utils';

interface DismissalPayload {
  birthСountry: Option[];
  branch: Option[];
  branch_adr: Option[];
  cardType: Option[];
  documentType: Option[];
  gender: Option[];
  liveCountry: Option[];
  regCountry: Option[];
  salaryDeal: Option[];
}

interface EmployeeNewFields {
  actions: OrderAction<EmployeeNewFields>[];
  customerId: number;
  id: number;
  label: string;
  number: string;
  branch_adr?: string;
  date?: Date;
  state?: OrderState;
  stateTranslate?: string;
  type?: DemandType;
}

export const EmployeeNewDetails = () => {
  const {
    order,
    payload: {
      salaryDeal,
      cardType,
      branch,
      branch_adr,
      gender,
      birthСountry,
      documentType,
      regCountry,
      liveCountry,
    },
    onChangeCustomer,
    form: { updateData, getFieldValue },
  } = useOrderDetails<EmployeeNewFields, DismissalPayload>();

  const fileExtensions = useFileExtensions();
  const isDisabledCustomer = useDisabledCustomer(order);

  const onSelectBranch = (branchId: string) => {
    const selectedBranchAddress = branch_adr.find(
      branchAddress => branchAddress.value === branchId,
    );

    updateData({ branch_adr: selectedBranchAddress.content.info });
  };

  const isLiveEqualRegistration = getFieldValue(EmployeeNewNames.LiveEqualsReg);
  const isIdCardDocumentType = getFieldValue(EmployeeNewNames.DocumentType) === '1071';

  return (
    <DefaultForm>
      <CustomerSelect
        name={EmployeeNewNames.Customer}
        label="front.employee-new.organization.label"
        onSelectOption={onChangeCustomer}
        selectDefaultCustomer
        disabled={isDisabledCustomer}
        required
      />
      <SelectField
        name={EmployeeNewNames.SalaryDeal}
        label="front.employee-new.salary-deal.label"
        options={salaryDeal}
        required
      />
      <DateField
        name={EmployeeNewNames.RecruitDate}
        label="front.employee-new.date-of-employment.label"
        initialDate={today}
        minDate={minDate}
        maxDate={maxDate}
        disabledDays={{ after: maxDate }}
        required
      />
      <SelectField
        name={EmployeeNewNames.CardType}
        label="front.employee-new.card-type.label"
        options={cardType}
        required
      />
      <SelectField
        name={EmployeeNewNames.Branch}
        label="front.employee-new.branch.label"
        options={branch}
        onSelectOption={onSelectBranch}
        required
      />
      <TextField
        name={EmployeeNewNames.BranchAddress}
        label="front.employee-new.branch-address.label"
        disabled={!getFieldValue(EmployeeNewNames.Branch)}
      />
      <h5>{translate('front.employee-new.employee-data.label')}</h5>
      <TextField
        name={EmployeeNewNames.LastName}
        label="front.employee-new.last-name.label"
        minLength={1}
        maxLength={300}
        validate={validateName}
        required
      />
      <TextField
        name={EmployeeNewNames.FirstName}
        label="front.employee-new.first-name.label"
        maxLength={300}
        minLength={1}
        validate={validateName}
        required
      />
      <TextField
        name={EmployeeNewNames.MiddleName}
        label="front.employee-new.middle-name.label"
        maxLength={300}
        minLength={1}
        validate={validateName}
        required
      />
      <TextField
        name={EmployeeNewNames.Inn}
        label="front.employee-new.inn.label"
        maxLength={300}
        minLength={1}
        allowedCharacters={INN_ALLOWED_CHARACTERS}
        required
      />
      <TextField
        name={EmployeeNewNames.NameEn}
        label="front.employee-new.name-en.label"
        maxLength={300}
        minLength={1}
        validate={validateEnName}
        required
      />
      <SelectField
        name={EmployeeNewNames.Gender}
        label="front.employee-new.gender.label"
        options={gender}
        required
      />
      <DateField
        name={EmployeeNewNames.BirthDay}
        label="front.employee-new.birth-day.label"
        maxDate={eighteenYearsBack}
        initialDate={eighteenYearsBack}
        disabledDays={disabledDaysDate}
        required
      />
      <SelectField
        name={EmployeeNewNames.BirthCountry}
        label="front.employee-new.country-birth.label"
        options={birthСountry}
        required
      />
      <CheckboxField name={EmployeeNewNames.Resident} label="front.employee-new.resident.label" />
      <TextField
        name={EmployeeNewNames.SecretWord}
        label="front.employee-new.secret-word.label"
        maxLength={300}
        minLength={1}
        required
      />
      <PhoneField name={EmployeeNewNames.Phone} label="front.employee-new.phone.label" required />
      <SelectField
        name={EmployeeNewNames.DocumentType}
        label="front.employee-new.document-type.label"
        options={documentType}
        required
      />
      <TextField
        name={EmployeeNewNames.PassportSerial}
        label="front.employee-new.series-number.label"
        maxLength={300}
        minLength={1}
        required
      />
      <DateField
        name={EmployeeNewNames.PassportIssueDate}
        label="front.employee-new.passport-issue-date.label"
        initialDate={today}
        maxDate={today}
        disabledDays={disableDaysAfterToday}
        required
      />
      <TextField
        name={EmployeeNewNames.PassportIssuer}
        label="front.employee-new.passport-issuer.label"
        maxLength={300}
        minLength={1}
        required
      />
      <DateField
        name={EmployeeNewNames.PassportTerm}
        label="front.employee-new.validity.label"
        initialDate={today}
        minDate={today}
        disabledDays={disabledDaysBeforeToday}
        required={isIdCardDocumentType}
      />
      <h5>{translate('front.employee-new.block-registration-address.label')}</h5>
      <SelectField
        name={EmployeeNewNames.RegCountry}
        label="front.employee-new.country.label"
        options={regCountry}
        required
      />
      <TextField
        name={EmployeeNewNames.RegRegion}
        label="front.employee-new.region.label"
        maxLength={300}
        minLength={1}
        required
      />
      <TextField
        name={EmployeeNewNames.RegDistrict}
        label="front.employee-new.district.label"
        maxLength={300}
        minLength={1}
        required
      />
      <TextField
        name={EmployeeNewNames.RegPostCode}
        label="front.employee-new.post-code.label"
        maxLength={300}
        minLength={1}
        allowedCharacters={POST_CODE_ALLOWED_CHARACTERS}
        required
      />
      <TextField
        name={EmployeeNewNames.RegCity}
        label="front.employee-new.city.label"
        maxLength={300}
        minLength={1}
        required
      />
      <TextField
        name={EmployeeNewNames.RegStreet}
        label="front.employee-new.street.label"
        maxLength={300}
        minLength={1}
        required
      />
      <TextField
        name={EmployeeNewNames.RegHouse}
        label="front.employee-new.house-building.label"
        maxLength={300}
        minLength={1}
        validate={validateHouse}
        required
      />
      <TextField
        name={EmployeeNewNames.RegFlat}
        label="front.employee-new.flat.label"
        maxLength={300}
        minLength={1}
        validate={validateOnlyNumber}
        required
      />
      <CheckboxField
        name={EmployeeNewNames.LiveEqualsReg}
        label="front.employee-new.live-equal-registration.label"
      />
      {!isLiveEqualRegistration && (
        <>
          <h5>{translate('front.employee-new.block-address-residence.label')}</h5>
          <SelectField
            name={EmployeeNewNames.LiveCountry}
            label="front.employee-new.country.label"
            options={liveCountry}
            required
          />
          <TextField
            name={EmployeeNewNames.LiveRegion}
            label="front.employee-new.region.label"
            maxLength={300}
            minLength={1}
            required
          />
          <TextField
            name={EmployeeNewNames.LiveDistrict}
            label="front.employee-new.district.label"
            maxLength={300}
            minLength={1}
            required
          />
          <TextField
            name={EmployeeNewNames.LivePostCode}
            label="front.employee-new.post-code.label"
            maxLength={300}
            minLength={1}
            required
          />
          <TextField
            name={EmployeeNewNames.LiveCity}
            label="front.employee-new.city.label"
            maxLength={300}
            minLength={1}
            required
          />
          <TextField
            name={EmployeeNewNames.LiveStreet}
            label="front.employee-new.street.label"
            maxLength={300}
            minLength={1}
            required
          />
          <TextField
            name={EmployeeNewNames.LiveHouse}
            label="front.employee-new.house-building.label"
            maxLength={300}
            minLength={1}
            required
          />
          <TextField
            name={EmployeeNewNames.LiveFlat}
            label="front.employee-new.flat.label"
            maxLength={300}
            minLength={1}
            required
          />
        </>
      )}
      <h5>{translate('front.employee-new.block-organization.label')}</h5>
      <TextField
        name={EmployeeNewNames.JobTitle}
        label="front.employee-new.position.label"
        maxLength={300}
        minLength={1}
        validate={validateJob}
        required
      />
      <TextField
        name={EmployeeNewNames.JobUnit}
        label="front.employee-new.unit.label"
        maxLength={300}
        minLength={1}
        validate={validateJob}
        required
      />
      <PhoneField name={EmployeeNewNames.JobPhone} label="front.employee-new.work-phone.label" />
      <FileField
        name={EmployeeNewNames.Attach}
        label="front.employee-new.attachments.label"
        outputFormat={FileType.BASE_64}
        isEDSSignedDocuments={isSignedDocuments(order?.state)}
        accept={fileExtensions}
        multiple
      />
    </DefaultForm>
  );
};
