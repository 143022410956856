import React from 'react';
import { Action } from 'react-data-table';

import { OrderActionType } from 'api/enums';
import { Button } from 'components/buttons/Button';
import { ButtonDropdown } from 'components/buttons/ButtonDropdown';
import { ActionButton } from 'components/Table/ActionButton';
import { translate } from 'i18n/translator';
import { getTranslateAction } from 'pages/Payments/general';
import { isExist } from 'utils/isData';

interface TableActionProps<T = Action> {
  isDisabled: boolean;
  item: T;
  onClick: (item: T) => () => Promise<void>;
}

export const TableAction = ({ item, isDisabled, onClick }: TableActionProps) => {
  if (!item) {
    return null;
  }

  const { label, className, actions, color = 'primary' } = item;

  const isDropdownActions = isExist(actions);

  return (
    <React.Fragment>
      {isDropdownActions ? (
        <ButtonDropdown
          actions={actions.map(action => ({
            ...action,
            action: onClick(action),
          }))}
        >
          {translate(label)}
        </ButtonDropdown>
      ) : item.label === getTranslateAction(OrderActionType.PRINT) ? (
        <ActionButton onClick={onClick(item)} item={item} size="sm" />
      ) : (
        <Button
          className={className}
          disabled={isDisabled}
          onClick={onClick(item)}
          size="sm"
          color={color}
        >
          {translate(label)}
        </Button>
      )}
    </React.Fragment>
  );
};
