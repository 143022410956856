import { DemandResponse } from 'api/DemandsService/interfaces';
import { OrderActionType, OrderState, PaymentTypes } from 'api/enums';
import { isPaymentActionAllowed } from 'navigations/access';

export const MAX_VAT_PERCENT = 99.99;

export const isVisibleAction = (order: DemandResponse, action: OrderActionType) =>
  order.actions.some(item => item.actionName === action) &&
  isPaymentActionAllowed(order.base.orderType, action, order.base.customerId);

const signingStates = [OrderState.Draft, OrderState.PartiallySigned, OrderState.NeedSms];

export const isAllowedActionSign = (order: DemandResponse, action: OrderActionType) =>
  signingStates.includes(order.base.orderState as OrderState) &&
  isPaymentActionAllowed(order.base.orderType, action, order.base.customerId);

export const addEscrouTypeToOrderType = (type: string | string[]): string[] => {
  const newType = Array.isArray(type) ? type : [type];

  if (newType.some(item => item === PaymentTypes.DOMESTIC_TRANSFER)) {
    return [...newType, PaymentTypes.DOMESTIC_TRANSFER_ESCROU];
  }

  if (newType.some(item => item === PaymentTypes.INTERNAL_TRANSFER)) {
    return [...newType, PaymentTypes.INTERNAL_TRANSFER_ESCROU];
  }

  return newType;
};
