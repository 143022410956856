import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface Props {
  children?: React.ReactNode;
}

export const CreatePortal: React.FC<Props> = ({ children }) => {
  const el = React.useRef<HTMLDivElement>(document.createElement('div'));
  React.useEffect(() => {
    document.body.appendChild(el.current);
    return () => {
      document.body.removeChild(el.current);
    };
  }, []);

  return ReactDOM.createPortal(children, el.current);
};
