import * as React from 'react';
import reactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';

import { createSelector } from 'reselect';

import { api } from 'api';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { SmsField } from 'components/forms/SmsField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { Question } from 'components/icons';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { useCountdown } from 'hooks/useCountDown';
import { translate } from 'i18n/translator';
import { CustomerSelectModal } from 'pages/Login/CustomerSelectModal/CustomerSelectModal';
import {
  CustomerSelectModalProps,
  CustomerSelectMode,
} from 'pages/Login/CustomerSelectModal/types';
import { FactorType } from 'pages/Login/LoginPage';
import { useLoginContext } from 'pages/Login/useLoginContext';
import { UserActions } from 'store/actions/user';
import { UserState } from 'store/reducers/user';
import { selectUser } from 'store/selectors';

interface Fields {
  code: string;
}

interface Payload {
  smsLength: number;
  smsValidPeriod: number;
}

const SmsLogin = () => {
  const { setPayload, progress, error, payload, updateData, handleSubmit, initializing } = useForm<
    Fields,
    Payload
  >(async ({ setPayload }) => {
    const { smsLength } = await api.sms.smsRequest();
    const { value } = await api.sms.getSmsValidPeriod();
    setPayload({ smsLength, smsValidPeriod: value });
  });

  const dispatch = useDispatch();

  const phoneNumber = useSelector(createSelector(selectUser(), user => user.phoneNumber));

  const { goToPrevFactor } = useLoginContext();

  const onSendSMS = async ({ code }: Fields) => {
    await api.sms.smsCodeSend(code);
    const user = await confirmModal<UserState, CustomerSelectModalProps>(
      CustomerSelectModal,
      { mode: CustomerSelectMode.auth },
      undefined,
      {},
      true,
    );
    dispatch(UserActions.setUser({ ...user, currentAuthFactor: FactorType.SMS }));
  };

  const {
    time,
    start: startResendCounter,
    started,
  } = useCountdown({
    seconds: payload.smsValidPeriod,
  });

  React.useEffect(() => {
    if (!initializing && payload.smsValidPeriod) {
      startResendCounter();
    }
  }, [initializing]);

  const onSMSRequest = async () => {
    const { smsLength } = await api.sms.smsRequest();
    setPayload({ smsLength });
    updateData({ code: '' });
    startResendCounter();
  };

  return (
    <div className="login-page__sms-step">
      <div className="login-page__body__box__title">
        <span>{translate('front.login-form.title.label')}</span>
      </div>
      <div className="login-page__body__box__sms-desc">
        <span>{translate('front.login-form.sms-description.label')}</span>
      </div>
      <div className="login-page__body__box__sms-phone">
        <span className="login-page__body__box__sms-phone_number">{phoneNumber}</span>
        <span className="login-page__body__box__sms-phone_icon">{Question()}</span>
      </div>
      <DefaultForm className="login-page__form" disabled={progress}>
        <FormError className="login-page__form__server-error">
          {error && reactHtmlParser(error)}
        </FormError>
        <SmsField label="front.login-form.sms.label" name="code" length={6} autoFocus />
        {!initializing && !started && (
          <div onClick={onSMSRequest} className="resend-sms">
            <span>{translate('front.login-form.resend-sms.label')}</span>
          </div>
        )}
        {!initializing && started && (
          <div className="login-page__form__timer-block">
            <div className="text">{translate('front.login-form.timer-sms.label')}</div>
            <div className="time">{time}</div>
          </div>
        )}
        <Button
          color="primary"
          onClick={e => handleSubmit(onSendSMS, e)}
          progress={progress}
          className="login-page__form__btn save-btn"
          type="submit"
          size="lg"
          label="front.login-form.login-button.label"
        />
        <Button
          color="secondary"
          onClick={goToPrevFactor}
          disabled={progress}
          className="login-page__form__btn cancel-btn"
          size="lg"
          label="front.login-form.cancel-button.label"
        />
      </DefaultForm>
    </div>
  );
};

export default withForm(SmsLogin);
