import * as React from 'react';
import * as Loders from 'react-spinners';

interface Props {
  loading: boolean;
  size: number;
  color?: string;
}

export const ClockLoader: React.FC<Props> = ({ size, color = '#4CBC5C', loading }) => {
  return <Loders.ClockLoader size={size} color={color} loading={loading} />;
};

export const BeatLoader: React.FC<Props> = ({ size, color = '#4CBC5C', loading }) => {
  return <Loders.BeatLoader size={size} color={color} loading={loading} />;
};
