import React from 'react';

import moment from 'moment';
import {
  CartesianGrid,
  Legend,
  LegendPayload,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { api } from 'api';
import { ExportStatementRequest } from 'api/AccountService';
import { GridRequest } from 'api/Service';
import { Card } from 'components/Card';
import { ExportFormatEnum } from 'components/ExportDropdown/enum';
import { getExportActions } from 'components/ExportDropdown/ExportDropdown';
import { TabBar } from 'components/tabs/TabBar';
import * as format from 'components/utils/format';
import { translate } from 'i18n/translator';
import { AccountStatementFilter } from 'pages/AccountStatements/AccountStatementFilter';
import { AnalyticsTablePage } from 'pages/Analytics/AnalyticsTablePage';

import { AnalyticsFilter, Fields as FilterFields } from './AnalyticsFilter';

import './styles.scss';

type CashFlowPoint = {
  date: Date | string;
  value: number | string;
};

enum StatusesOption {
  TurnoverBalanceSheet = 'turnoverBalanceSheet',
  Balance = 'balance',
  Turnover = 'turnover',
}

const statusesOptions = [
  {
    value: StatusesOption.Balance,
    label: 'front.working-documents-table.balance.label',
  },
  {
    value: StatusesOption.Turnover,
    label: 'front.analytics.filter-turnover.label',
  },
  {
    value: StatusesOption.TurnoverBalanceSheet,
    label: 'front.analytics.filter-turnover-balance-sheet.label',
  },
];

const SUFFIX = [
  [1, ''],
  [1e3, 'K'],
  [1e6, 'M'],
  [1e9, 'B'],
  [1e12, 'T'],
].reverse() as [number, string][];

function formatThousands(num: number): string {
  const isPositive = num >= 0;
  const newNum = isPositive ? num : Math.abs(num);
  const idx = SUFFIX.findIndex(v => newNum >= v[0]);
  if (idx === -1) return num.toString();
  const format = SUFFIX[idx];
  const value = isPositive ? num / format[0] : -Math.abs(num / format[0]);

  return Math.trunc(value) + format[1];
}

const EXPORT_TYPES: Record<string, ExportFormatEnum> = { XLS: ExportFormatEnum.EXCEL };

const LINE_STROKES = {
  main: '#FF4C4C',
  debet: '#FF0000',
  credit: '#008800',
};
const DASHARRAY = '6 4';

const LEGEND_TRANSLATIONS: Record<string, string> = {
  value: 'front.home.cash-flow.chart-tooltip.value',
  valueFut: 'front.home.cash-flow.chart-tooltip.valueFut',
  debet: 'front.home.cash-flow.chart-tooltip.debet',
  debetFut: 'front.home.cash-flow.chart-tooltip.debetFut',
  credit: 'front.home.cash-flow.chart-tooltip.credit',
  creditFut: 'front.home.cash-flow.chart-tooltip.creditFut',
};

const getActions = (
  formData: ExportStatementRequest,
  pagination: GridRequest,
  shapedStatementTime: string,
) => {
  const action = (formatType: string) => async () =>
    await api.accounts.exportStatementsByAnalytic({
      ...formData,
      time: shapedStatementTime,
      format: formatType,
      translateHeaders: true,
      withSync: false,
      sort: pagination.sort,
      order: pagination.order,
      objectCode: 'ACCOUNT_BALANCE_HISTORY',
    });

  return getExportActions({
    action,
    exportTypes: EXPORT_TYPES,
  });
};

const StatementsFilter = () => (
  <AccountStatementFilter hasSendButton={false} getActions={getActions} />
);

export const AnalyticsPage: React.FC = () => {
  const [cashFlowData, setCashFlowData] = React.useState<CashFlowPoint[]>([]);
  const [tab, setTab] = React.useState<string>(StatusesOption.TurnoverBalanceSheet);

  const updateGraphData = async (filters: FilterFields) => {
    const points = await api.accounts.getCashFlowData(filters);
    const today = moment().startOf('day');

    let parsedPoints = points.map(point => ({
      ...point,
      displayedDate: format.date(point.date),
    }));

    if (filters.prediction) {
      parsedPoints = parsedPoints.map(item => {
        const balanceDate = moment(item.date).startOf('day');

        if (balanceDate.isSameOrAfter(today)) {
          const withFuture = {
            ...item,
            valueFut: item.value,
            creditFut: item.credit,
            debetFut: item.debet,
          };
          if (balanceDate.isAfter(today)) {
            delete withFuture.value;
            delete withFuture.credit;
            delete withFuture.debet;
          }
          return withFuture;
        }
        return item;
      });
    }
    setCashFlowData(parsedPoints);
  };

  const legendPayload = () => {
    return [
      {
        id: 'credit',
        type: 'line',
        value: translate('front.analytics.legend-credit.label'),
        color: LINE_STROKES.credit,
      },
      {
        id: 'debet',
        type: 'line',
        value: translate('front.analytics.legend-debet.label'),
        color: LINE_STROKES.debet,
      },
    ] as LegendPayload[];
  };

  const [balanceHidden, turnoverHidden] = React.useMemo(
    () => [tab !== StatusesOption.Balance, tab !== StatusesOption.Turnover],
    [tab],
  );

  return (
    <Card className="analytics-page">
      <h3>{translate('front.home.cash-flow.label')}</h3>
      <TabBar value={tab} options={statusesOptions} onChange={setTab} />
      {StatusesOption.TurnoverBalanceSheet === tab ? (
        <AnalyticsTablePage filterComponent={StatementsFilter} />
      ) : (
        <>
          <AnalyticsFilter onSubmit={updateGraphData} />
          <ResponsiveContainer height={500}>
            <LineChart
              data={cashFlowData}
              margin={{ top: 10, left: 20, bottom: 5 }}
              stackOffset="sign"
            >
              <CartesianGrid strokeDasharray="10 10" />
              <XAxis dataKey="displayedDate" />
              <YAxis
                allowDataOverflow
                interval={'preserveStartEnd'}
                tickFormatter={formatThousands}
                domain={['auto', 'auto']}
              />
              <Tooltip
                formatter={(value, name) => [
                  format.getAmount(+value),
                  translate(LEGEND_TRANSLATIONS[name]),
                ]}
                isAnimationActive={false}
              />
              {tab === 'turnover' && <Legend payload={legendPayload()} />}
              <Line
                hide={balanceHidden}
                type="monotone"
                dataKey="value"
                stroke={LINE_STROKES.main}
              />
              <Line
                hide={balanceHidden}
                type="monotone"
                dataKey="valueFut"
                stroke={LINE_STROKES.main}
                strokeDasharray={DASHARRAY}
              />
              <Line
                hide={turnoverHidden}
                type="monotone"
                dataKey="credit"
                stroke={LINE_STROKES.credit}
              />
              <Line
                hide={turnoverHidden}
                type="monotone"
                dataKey="creditFut"
                stroke={LINE_STROKES.credit}
                strokeDasharray={DASHARRAY}
              />
              <Line
                hide={turnoverHidden}
                type="monotone"
                dataKey="debet"
                stroke={LINE_STROKES.debet}
              />
              <Line
                hide={turnoverHidden}
                type="monotone"
                dataKey="debetFut"
                stroke={LINE_STROKES.debet}
                strokeDasharray={DASHARRAY}
              />
            </LineChart>
          </ResponsiveContainer>
        </>
      )}
    </Card>
  );
};
