import * as React from 'react';

import { translate } from 'i18n/translator';

export const ActivationStatusPage: React.FC = () => {
  return (
    <div>
      <h5>{translate('front.activation.status-page.title.label')}</h5>
      <p>{translate('front.activation.status-page.sub-title.label')}</p>
      {translate('front.activation.status-page.description.label')}
    </div>
  );
};
