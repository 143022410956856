import * as React from 'react';

import { api } from 'api';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { PasswordField } from 'components/forms/inputs/PasswordField';
import { TextField } from 'components/forms/inputs/TextField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { translate } from 'i18n/translator';
import { goBack } from 'navigations/navigate';

interface Fields {
  email: string;
  password: string;
}

const ChangeEmailForm: React.FC = () => {
  const { error, progress, handleSubmit } = useForm<Fields>();

  const onChangeEmail = async (formData: Fields) => {
    const { email, password } = formData;
    await api.profile.changeEmail(email, password);
    goBack();
  };

  return (
    <div>
      <h3>{translate('front.profile.information.change-email.label')}</h3>
      <DefaultForm name="changeEmail" disabled={progress} autoComplete="off">
        <FormError>{error}</FormError>
        <TextField
          label="front.profile.information.change-email.email.label"
          name="email"
          autoComplete="off"
          required
          email
        />
        <PasswordField
          label="front.profile.information.change-email.password.label"
          name="password"
          required
        />
        <Button
          color="primary"
          onClick={e => handleSubmit(onChangeEmail, e)}
          progress={progress}
          type="submit"
          size="lg"
          label="front.profile.information.change-email.button.change.label"
        />
      </DefaultForm>
    </div>
  );
};

export const ChangeEmailPage = withForm(ChangeEmailForm);
