import * as React from 'react';
import { LocaleUtils } from 'react-day-picker';

import { Option } from 'api/Service';
import { Selector } from 'components/forms/datePicker/DateSelector';

interface Props {
  date: Date;
  fromMonth: Date;
  locale: string;
  localeUtils: LocaleUtils;
  onChange: (value: Date) => void;
  toMonth: Date;
}

const getYears = (fromMonth: Date, toMonth: Date): Option<undefined, number>[] => {
  const years: Option<undefined, number>[] = [];

  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push({ label: i, value: i });
  }

  return years.sort((a, b) => (b.value > a.value ? 1 : -1));
};

const getMonths = (months: string[]) =>
  months.map((item, index) => ({
    label: item,
    value: index + 1,
  }));

export const MonthYearSelect: React.FC<Props> = ({
  date,
  localeUtils,
  onChange,
  locale,
  fromMonth,
  toMonth,
}) => {
  const months = React.useMemo(() => getMonths(localeUtils.getMonths(locale)), [locale]);
  const years = React.useMemo(() => getYears(fromMonth, toMonth), [fromMonth, toMonth]);

  const onChangeMonth = (value: number) => {
    onChange(new Date(date.getFullYear(), value - 1));
  };

  const onChangeYear = (value: number) => {
    onChange(new Date(value, date.getMonth()));
  };

  return (
    <div className="DayPicker-Caption">
      <div className="wrapper-selectors">
        <Selector
          className="date-month"
          onChange={onChangeMonth}
          options={months}
          value={date.getMonth() + 1}
        />
        <Selector
          className="date-year"
          options={years}
          onChange={onChangeYear}
          value={date.getFullYear()}
        />
      </div>
    </div>
  );
};
