import { api } from 'api';
import { DemandResponse } from 'api/DemandsService/interfaces';
import { OrderActionType } from 'api/enums';
import { SignType } from 'components/Document/OrderControls';
import { notificationModal } from 'components/modals/NotificationsModal';
import config from 'config';
import { isSignAuthLetterFiles } from 'pages/Demands/utils';
import { isAllowedActionSign } from 'pages/Payments/utils';
import { signCertificateOrders, signOrders } from 'pages/utils/SignOrders/signOrders';

export const getTranslateAction = (actionName: OrderActionType) =>
  `front.working-documents-table.actions-${actionName.toLowerCase()}.label`;

const hasElectronicSign = (factors: SignType[]) =>
  factors.length &&
  (factors[0].includes(config.signTypes.EDS) || factors[0].includes(config.signTypes.SIGNATURE));

const hasSmsSign = (factors: SignType[]) =>
  factors.length && factors[0].includes(config.signTypes.SMS);

const getFilteredOrders = (data: DemandResponse[]) =>
  data.filter(order => isAllowedActionSign(order, OrderActionType.SIGN));

export const signActions = async (order: DemandResponse) => {
  const checkSignOrder = await api.payments.canSigningOrders([order.id]);

  if (!checkSignOrder.length) {
    return notificationModal();
  }

  const [{ factors }] = checkSignOrder;

  if (hasElectronicSign(factors)) {
    return signCertificateOrders([
      {
        id: order.id,
        customerId: order.base.customerId,
        isSignFiles: isSignAuthLetterFiles(order.dynamicFields),
      },
    ]);
  }

  return await signOrders([order.id]);
};

const singleSmsSign = async (data: DemandResponse[]) => {
  const checkSignOrder = await api.payments.canSigningOrders([data[0].id]);

  if (checkSignOrder.length && hasSmsSign(checkSignOrder[0].factors)) {
    return signOrders([data[0].id]);
  }

  return notificationModal();
};

const multiplySmsSign = async (data: DemandResponse[]) => {
  const orders = getFilteredOrders(data);
  const checkSignOrders = await api.payments.canSigningOrders(orders.map(item => item.id));

  const filteredOrders = checkSignOrders.filter(({ factors }) =>
    factors[0].includes(config.signTypes.SMS),
  );

  if (filteredOrders.length) {
    return signOrders(
      filteredOrders.map(item => item.orderId),
      data.length,
    );
  }

  return notificationModal();
};

export const smsSignAction = async (data: DemandResponse[]) =>
  data.length === 1 ? await singleSmsSign(data) : await multiplySmsSign(data);

const singleElectronicSign = async (data: DemandResponse[]) => {
  const checkSignOrder = await api.payments.canSigningOrders([data[0].id]);

  if (checkSignOrder.length && hasElectronicSign(checkSignOrder[0].factors)) {
    return signCertificateOrders([{ id: data[0].id, customerId: data[0].base.customerId }]);
  }

  return notificationModal();
};

const multipleElectronicSign = async (data: DemandResponse[]) => {
  const orders = getFilteredOrders(data);

  const checkSignOrders = await api.payments.canSigningOrders(orders.map(item => item.id));

  const filteredOrders = checkSignOrders.filter(({ factors }) => hasElectronicSign(factors));

  if (filteredOrders.length) {
    return signCertificateOrders(
      filteredOrders.map(item => ({ id: item.orderId, customerId: item.customerId })),
      data.length,
    );
  }

  return notificationModal();
};

export const electronicSignAction = async (data: DemandResponse[]) =>
  data.length === 1 ? await singleElectronicSign(data) : await multipleElectronicSign(data);
