import * as React from 'react';
import { Button as BsButton } from 'reactstrap';

import classnames from 'classnames';

import './styles.scss';
import { translate } from 'i18n/translator';

import { activeColor, primaryColor } from './config';

export type Option = {
  icon: React.ComponentType<IconProps>;
  label: string;
  action?: (data?: any) => void;
  color?: string;
  render?: (button: React.ReactNode) => React.ReactNode;
};

interface IconProps {
  color?: string;
}

interface Props {
  icon: React.ComponentType<IconProps>;
  options: Option[];
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  title?: string;
}

const createIcon = (
  Icon: string | React.ComponentType<IconProps>,
  title: string,
): React.ComponentType<IconProps> =>
  typeof Icon === 'string' ? () => <img src={Icon} alt={title} /> : Icon;

const createButton = (option: Option) => {
  const label = translate(option.label);
  const ActionIcon = createIcon(option.icon, label || 'Action');
  return (
    <BsButton
      onClick={option.action}
      className="bs-button expand-options__btn expand-options__btn_expanded"
      title={label}
    >
      <ActionIcon color={option.color || primaryColor} />
    </BsButton>
  );
};

export const ExpandOptions = ({
  disabled,
  className,
  options,
  title = 'Options',
  icon,
  children,
}: Props) => {
  const [expanded, setExpanded] = React.useState(false);
  const element = React.useRef<HTMLDivElement>();

  const onDocumentClick = React.useCallback((e: MouseEvent) => {
    if (e.target === document.body) {
      setExpanded(false);
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener('click', onDocumentClick);
    return () => document.removeEventListener('click', onDocumentClick);
  }, []);

  const IconComponent = React.useMemo(() => createIcon(icon, title), [icon, title]);
  const actions = React.useMemo(
    () =>
      options.map((option, i) => {
        const key = `${i}-${option.label}`;
        return (
          <React.Fragment key={key}>
            {option.render ? option.render(createButton(option)) : createButton(option)}
          </React.Fragment>
        );
      }),
    [options],
  );

  return (
    <div ref={element} className="expand-options__wrapper">
      <div
        className={classnames('expand-options__options', {
          'expand-options__options_expanded': expanded,
        })}
      >
        {actions}
      </div>
      <BsButton
        disabled={disabled}
        onClick={() => setExpanded(e => !e)}
        color="secondary"
        title={title}
        className={classnames(
          'bs-button',
          'expand-options__btn',
          { 'expand-options__btn_expanded': expanded },
          className,
        )}
      >
        <IconComponent color={expanded ? activeColor : primaryColor} />
        {children && <span className="expand-options__content">{children}</span>}
      </BsButton>
    </div>
  );
};
