import * as React from 'react';
import { Col, Row, Container } from 'react-grid';

import { api } from 'api';
import { ExportDropdown, getExportActions } from 'components/ExportDropdown/ExportDropdown';
import { PageHeader } from 'components/layout/Page/PageHeader';
import { SearchInput } from 'components/searchInput/SearchInput';
import { useFilter } from 'components/Table/DataTable';
import { translate } from 'i18n/translator';
import { DisabledBlock } from 'navigations/access';
import { Privileges } from 'navigations/privileges';
import { EXPORT_TYPE } from 'pages/Handbooks/CurrencyHistory/CurrencyHistoryFilter';

export const UkraineBanksFilter = () => {
  const { updateFilter, extraFields } = useFilter({
    extraFields: { searchText: '' },
  });

  const exportActions = React.useMemo(() => {
    const action = () => async () =>
      await api.handbooks.getUkraineBanksExcel({
        ...extraFields,
      });

    return getExportActions({
      action,
      exportTypes: EXPORT_TYPE,
    });
  }, [extraFields]);

  return (
    <div>
      <Container>
        <PageHeader.Right>
          <DisabledBlock privileges={[Privileges.infoCurrencyRateView]}>
            <ExportDropdown actions={exportActions}>
              {translate('front.handbooks-table.excel-export.label')}
            </ExportDropdown>
          </DisabledBlock>
        </PageHeader.Right>
        <Row>
          <Col md={7} />
          <Col md={5}>
            <SearchInput
              placeholder={translate('front.working-documents-table.filter-search.label')}
              value={extraFields.searchText}
              onChange={searchText => updateFilter({ searchText })}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
