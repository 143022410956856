import React from 'react';

import { Button } from 'components/buttons/Button';
import { translate } from 'i18n/translator';

import { confirmModal } from './globalModal/GlobalModal';

interface ConfirmModalProps {
  message: string | React.ReactElement[];
  resolve: any;
  showCancelButton: boolean;
  showContinueButton: boolean;
}

const ConfirmModalComponent = ({
  message,
  resolve,
  showContinueButton,
  showCancelButton,
}: ConfirmModalProps) => (
  <div className="confirm-modal">
    <h3 style={{ textAlign: 'center' }}>
      <span>{typeof message === 'string' ? translate(message) : message}</span>
    </h3>
    <div className="confirm-modal__footer">
      {showContinueButton ? (
        <div>
          <Button onClick={() => resolve(true)} size="sm">
            {translate('front.confirm-modal.continue.label')}
          </Button>
        </div>
      ) : null}
      {showCancelButton ? (
        <div>
          <Button onClick={() => resolve(false)} color="secondary" size="sm">
            {translate('front.confirm-modal.cancel.label')}
          </Button>
        </div>
      ) : null}
    </div>
  </div>
);

export const confirmationModal = async (
  message: string | React.ReactElement[],
  showContinueButton = true,
  showCancelButton = true,
) => {
  return await confirmModal(ConfirmModalComponent, {
    message,
    showContinueButton,
    showCancelButton,
  });
};
