import * as React from 'react';

import { Employee } from 'api/SalaryService';
import { Button } from 'components/buttons/Button';
import * as Icon from 'components/icons';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { useFilter } from 'components/Table/DataTable';
import { translate } from 'i18n/translator';

import { SalaryEmployeesModal } from './SalaryEmployeesModal';

interface Props {
  disabled: boolean;
  productIds: number[];
  selectedEmployees: Employee[];
  setSelectedEmployees: (data: Employee[], merge: boolean) => void;
}

export const SalaryRegistryFilter: React.FC<Props> = props => {
  const { totalCount } = useFilter();
  const { setSelectedEmployees, selectedEmployees, productIds, disabled } = props;

  const onAddEmployees = async () => {
    const request = { selectedEmployees, productIds };
    const newEmpl: Employee[] = await confirmModal(SalaryEmployeesModal, request, 920);
    if (!newEmpl) return;
    setSelectedEmployees(newEmpl, true);
  };

  return (
    <>
      <h4>
        {translate('front.menu.employees.label')}
        {` (${totalCount})`}
      </h4>
      {!disabled && (
        <Button size="sm" onClick={onAddEmployees}>
          <Icon.Plus />
          {translate('front.employees-filter.add.label')}
        </Button>
      )}
    </>
  );
};
