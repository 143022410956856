import * as React from 'react';
import { Col, Row } from 'react-grid';

import { SearchInput } from 'components/searchInput/SearchInput';
import { useFilter } from 'components/Table/DataTable';

import './styles.scss';

export const SalaryEmployeesFilter: React.FC = () => {
  const {
    updateFilter,
    extraFields: { searchText },
  } = useFilter({ extraFields: { searchText: undefined } });

  return (
    <Row justifyContent="flex-end">
      <Col md={6}>
        <SearchInput value={searchText} onChange={searchText => updateFilter({ searchText })} />
      </Col>
    </Row>
  );
};
