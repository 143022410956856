import React from 'react';

import moment from 'moment';

import { OrderState } from 'api/enums';
import { Option } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { DateRangeField } from 'components/forms/inputs/DateRangeField';
import { RadioField } from 'components/forms/inputs/RadioField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { useDisabledCustomer } from 'hooks/useDisabledCustomer';
import { translate } from 'i18n/translator';
import { DepositDetailNames } from 'pages/Demands/DepositDemand/enums';
import { InterestPaymentDetails } from 'pages/Demands/DepositDemand/NewTrancheOrderDetail/InterestPaymentDetails';
import { SelectDepositAmountDetails } from 'pages/Demands/DepositDemand/NewTrancheOrderDetail/SelectDepositAmountDetails';
import { getDefaultOptions } from 'pages/Demands/DepositDemand/utils';
import { useCheckRelevanceDeposit } from 'pages/Demands/hooks/useCheckRelevanceDeposit';
import { disabledDaysBeforeToday, getOption, today } from 'pages/Demands/utils';
import { usePrevious } from 'utils/hooks';
import { isExist } from 'utils/isData';

import { SelectDetails } from './SelectDetails';

import 'pages/Demands/DepositDemand/styles.scss';

export interface NewPlacementTrancheOrderField {
  currency?: string;
  label4?: boolean;
  label5?: boolean;
  label6?: boolean;
  label10?: boolean;
  radioAmountAccTranche?: string;
  radioInterestAccTranche?: string;
  radioPlacementAccTranche?: string;
  retentionPeriodFrom?: Date;
  toContract?: string;
}

const [
  labelReplenishmentAllowed,
  labelReplenishmentNotAllowed,
  labelExtensionAllowed,
  labelExtensionNotAllowed,
] = [
  'front.new-placement-tranche-deposit-demand.replenish-tranche.label',
  'front.new-placement-tranche-deposit-demand.no-replenish-tranche.label',
  'front.new-placement-tranche-deposit-demand.possibility-tranche.label',
  'front.new-placement-tranche-deposit-demand.no-possibility-tranche.label',
].map(item => translate(item));

export const NewPlacementTrancheOrderDetail = () => {
  const {
    order,
    onChangeCustomer,
    payload: {
      toContract: toContracts,
      currency: currencies,
      periodInterestPayment,
      radioPlacementAccTranche: radioPlacementAccTrancheOptions,
      radioInterestAccTranche: radioInterestAccTrancheOptions,
      depositAgreements,
      formContractRadio,
      radioAmountAccTranche: radioAmountAccTrancheOptions,
      amountOwnAccountTranche,
      dynamicFields,
    },
    form: { updateData, getFormData },
  } = useOrderDetails();

  const {
    toContract,
    label4: isCheckedSelect,
    radioPlacementAccTranche,
    label5: isCheckedInterestPayment,
    label6: isCheckedSelectDepositAmount,
    label10: isCheckedFormContract,
    radioInterestAccTranche,
    radioAmountAccTranche,
    retentionPeriodFrom,
    currency,
  }: NewPlacementTrancheOrderField = getFormData();

  const isDraft = order?.state === OrderState.Draft;

  const isDisabledCustomer = useDisabledCustomer(order);

  const hasContract = isExist(toContract);
  const prevContactFieldId = usePrevious(toContract);
  const isPrevContactFieldId = isExist(prevContactFieldId);
  const contactCurrency = getOption(toContract, toContracts)?.content;
  const hasContactCurrency = isExist(contactCurrency);

  const isExtensionAllowed = hasContactCurrency && contactCurrency?.params.isExtensionAllowed;
  const isReplenishmentAllowed =
    hasContactCurrency && contactCurrency?.params.isReplenishmentAllowed;

  const currentCurrency = React.useMemo(
    () =>
      hasContactCurrency
        ? currencies.find((currency: Option) => currency.content.value === currency)?.content.params
        : [],
    [currency, currencies],
  );

  React.useEffect(() => {
    if (!order?.state && hasContract) {
      updateData({
        currency: contactCurrency.params.currency,
        retentionPeriodFrom: today,
        retentionPeriodTo: moment()
          .add('days', +contactCurrency.params.dateMax)
          .startOf('date')
          .toDate(),
        depositAgreements: toContract,
      });
    }

    if (order?.state === OrderState.Draft && hasContract) {
      updateData({
        depositAgreements: toContract,
        currency: contactCurrency.params.currency,
      });

      if (isPrevContactFieldId) {
        updateData({
          retentionPeriodFrom: null,
          retentionPeriodTo: null,
        });
      }
    }
  }, [toContract]);

  React.useEffect(() => {
    if (isPrevContactFieldId) {
      updateData({
        periodInterestPayment: null,
        interestOwnAccountTranche: null,
        placementOwnAccountTranche: null,
        amountOwnAccountTranche: null,
      });
    }
  }, [toContract]);

  const periodInterestPaymentOptions = React.useMemo(
    () =>
      hasContactCurrency
        ? periodInterestPayment.filter(
            (option: Option) => option.content.info === contactCurrency.info,
          )
        : getDefaultOptions(isDraft, periodInterestPayment),
    [toContract],
  );

  const disabledDaysTo = React.useMemo(
    () =>
      isExist(retentionPeriodFrom)
        ? {
            before: retentionPeriodFrom,
            after: moment(retentionPeriodFrom)
              .add('days', +contactCurrency?.params.dateMax)
              .startOf('date')
              .toDate(),
          }
        : null,
    [retentionPeriodFrom],
  );

  useCheckRelevanceDeposit(DepositDetailNames.ToContract, toContracts, dynamicFields);

  const filteredAccounts = React.useMemo(() => {
    const hasCurrency = isExist(currency);

    return hasCurrency
      ? amountOwnAccountTranche.filter((option: Option) => option.content.info === currency)
      : amountOwnAccountTranche;
  }, [currency]);

  return (
    <DefaultForm>
      <CustomerSelect
        name={DepositDetailNames.Customer}
        label="front.deposit-demand.organization.label"
        onSelectOption={onChangeCustomer}
        selectDefaultCustomer
        disabled={isDisabledCustomer}
        required
      />
      <SelectField
        name={DepositDetailNames.ToContract}
        label="front.new-placement-tranche-deposit-demand.toContract.label"
        options={toContracts}
        required
      />
      <h6 className="dynamic-input__title">
        {translate('front.new-placement-tranche-deposit-demand.place-tranche.label')}
      </h6>
      <AmountField
        name={DepositDetailNames.AmountTranche}
        label="front.new-placement-tranche-deposit-demand.amountTranche.label"
        maxLengthWithoutZero={12}
        minAmount={currentCurrency?.min}
        maxAmount={currentCurrency?.max}
        hasMoneyFormat
        disabled={!hasContract}
        required={hasContract}
      />
      <SelectField
        name={DepositDetailNames.Currency}
        label="front.new-placement-tranche-deposit-demand.currency.label"
        options={currencies}
        disabled
      />
      <DateRangeField
        nameFrom={DepositDetailNames.RetentionPeriodFrom}
        nameTo={DepositDetailNames.RetentionPeriodTo}
        label="front.new-placement-tranche-deposit-demand.retention-period.label"
        disabledDaysFrom={disabledDaysBeforeToday}
        disabledDaysTo={disabledDaysTo}
        isOnlyRangePicker
        disabled={!hasContract}
        required={hasContract}
        defaultFrom={retentionPeriodFrom}
        clearable
      />
      <h6 className="dynamic-input__title">
        {translate('front.new-placement-tranche-deposit-demand.interest-rate.label')}
      </h6>
      <SelectField
        name={DepositDetailNames.PeriodInterestPayment}
        label="front.new-placement-tranche-deposit-demand.period-interest-payment.label"
        options={periodInterestPaymentOptions}
        disabled={!hasContract}
        required={hasContract}
      />
      <CheckboxField
        name={DepositDetailNames.SelectDetails}
        label="front.new-placement-tranche-deposit-demand.details.label"
        required
      />
      {isCheckedSelect && (
        <SelectDetails
          radioPlacementAccTranche={radioPlacementAccTranche}
          radioPlacementAccTrancheOptions={radioPlacementAccTrancheOptions}
          filteredAccounts={filteredAccounts}
        />
      )}
      <CheckboxField
        name={DepositDetailNames.DetailsForInterestPayment}
        label="front.new-placement-tranche-deposit-demand.details-payment.label"
        required
      />
      {isCheckedInterestPayment && (
        <InterestPaymentDetails
          radioInterestAccTranche={radioInterestAccTranche}
          radioInterestAccTrancheOptions={radioInterestAccTrancheOptions}
          filteredAccounts={filteredAccounts}
        />
      )}
      <CheckboxField
        name={DepositDetailNames.SelectDepositAmount}
        label="front.new-placement-tranche-deposit-demand.details-deposit-amount.label"
        required
      />
      {isCheckedSelectDepositAmount && (
        <SelectDepositAmountDetails
          radioAmountAccTranche={radioAmountAccTranche}
          radioAmountAccTrancheOptions={radioAmountAccTrancheOptions}
          filteredAccounts={filteredAccounts}
        />
      )}
      {hasContactCurrency && (
        <>
          <h6 className="dynamic-input__title">
            {isReplenishmentAllowed ? labelReplenishmentAllowed : labelReplenishmentNotAllowed}
          </h6>
          <h6 className="dynamic-input__title">
            {isExtensionAllowed ? labelExtensionAllowed : labelExtensionNotAllowed}
          </h6>
        </>
      )}
      <h6 className="dynamic-input__title">
        {translate('front.new-placement-tranche-deposit-demand.placement-tranche.label')}
      </h6>
      <SelectField
        name={DepositDetailNames.DepositAgreements}
        label="front.new-placement-tranche-deposit-demand.deposit-agreements.label"
        options={depositAgreements}
        disabled
        required={hasContract}
      />
      <CheckboxField
        name={DepositDetailNames.DoCommission}
        label="front.new-placement-tranche-deposit-demand.doCommission.label"
      />
      <CheckboxField
        name={DepositDetailNames.FormOfContract}
        label="front.new-placement-tranche-deposit-demand.form-of-contract.label"
      />
      {isCheckedFormContract && (
        <RadioField
          name={DepositDetailNames.FormContractRadio}
          options={formContractRadio}
          cssType="block"
        />
      )}
    </DefaultForm>
  );
};
