import React from 'react';
import { Col, Row } from 'react-grid';

import moment from 'moment';

import { api } from 'api';
import { CreditProduct, CreditView } from 'api/ProductsService';
import { Button } from 'components/buttons/Button';
import * as format from 'components/utils/format';
import { getAmount } from 'components/utils/format';
import { translate } from 'i18n/translator';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { isExist } from 'utils/isData';

import { generalProductTypes } from './CreditsPage';

export enum ParamType {
  MAIN = 'MAIN',
}

interface FieldInterface {
  key: keyof CreditProduct;
  label: string;
  getValue?: (row: CreditView) => string;
}

const getParamValue = ({ params }: CreditProduct, paramType: ParamType) =>
  params.find(({ type }) => type === paramType)?.value;

const commonFields: FieldInterface[] = [
  { key: 'code' as keyof CreditProduct, label: 'front.deposits-page.togglerow-product-code.label' },
  {
    key: 'statusName' as keyof CreditProduct,
    label: 'front.deposits-page.togglerow-contract-status.label',
  },
  { key: 'subTypeName' as keyof CreditProduct, label: 'front.credits-page.credit-type.label' },
  {
    key: 'currency' as keyof CreditProduct,
    label: 'front.working-documents-filter-form.currency.label',
  },
  {
    key: 'iban' as keyof CreditProduct,
    label: 'front.working-documents-table.account-number.label',
    getValue: ({ product }: CreditView) => getParamValue(product, ParamType.MAIN),
  },
  {
    key: 'organization' as keyof CreditProduct,
    label: 'front.account-statements-table.owner.label',
  },
  {
    key: 'bankBranchName' as keyof CreditProduct,
    label: 'front.product-accounts-togglerow.bank-branch.label',
  },
  {
    key: 'opened' as keyof CreditProduct,
    label: 'front.deposits-page.togglerow-contract-date.label',
    getValue: ({ product }: CreditView) => format.date(product.opened),
  },
  {
    key: 'closed' as keyof CreditProduct,
    label: 'front.deposits-page.togglerow-end-date.label',
    getValue: ({ product }: CreditView) => format.date(product.closed),
  },
].map(field => {
  field.label = translate(field.label);
  return field;
});

const generalFields: FieldInterface[] = [
  {
    key: 'amountInterest' as keyof CreditProduct,
    label: 'front.credits-page.credit-duty.label',
    getValue: ({ credit }: CreditView) => getAmount(credit.amountInterest),
  },
  { key: 'limit' as keyof CreditProduct, label: 'front.credits-page.credit-available-limit.label' },
].map(field => {
  field.label = translate(field.label);
  return field;
});

const additionalFields: FieldInterface[] = [
  {
    key: 'amountInterest' as keyof CreditProduct,
    label: 'front.credits-page.current-debt.label',
    getValue: ({ credit }: CreditView) => getAmount(credit.amountInterest),
  },
  {
    key: 'amountOverdue' as keyof CreditProduct,
    label: 'front.credits-page.arrears.label',
    getValue: ({ credit }: CreditView) => getAmount(credit.amountOverdue),
  },
  {
    key: 'percent' as keyof CreditProduct,
    label: 'front.credits-page.interest-rate.label',
    getValue: ({ credit }: CreditView) => `${credit.percent} %`,
  },
  {
    key: 'contractAmount' as keyof CreditProduct,
    label: 'front.credits-page.loan-amount.label',
    getValue: ({ credit }: CreditView) => getAmount(credit.contractAmount),
  },
].map(field => {
  field.label = translate(field.label);
  return field;
});

const debtFields = [
  { key: 'MAIN', label: 'front.credits-page.loan-arrears.label' },
  { key: 'PERCENT', label: 'front.credits-page.debt-percent-on-time.label' },
  { key: 'COMISSION', label: 'front.credits-page.monthly-fee-arrears.label' },
  {
    key: 'EXPIRATION_BODY',
    label: 'front.credits-page.debts-on-credit-body.label',
  },
  {
    key: 'EXPIRATION_PERCENT',
    label: 'front.credits-page.arrears-overdue-commisions.label',
  },
].map(field => {
  field.label = translate(field.label);
  return field;
});

const renderRowFields = (row: CreditView) => {
  const { credit, product } = row;

  const resultSet = { ...credit, ...product };

  const rowFields = generalProductTypes.includes(product.subType)
    ? commonFields.concat(generalFields)
    : commonFields.concat(additionalFields);

  return rowFields.map(({ key, label, getValue }) => {
    const value = typeof getValue === 'function' ? getValue(row) : resultSet[key];

    return (
      isExist(value) && (
        <Col md={6} className="credit-details__col" key={key}>
          <Row>
            <Col sm={6} className="credit-details__col__label">
              {label}
            </Col>
            <Col sm={6} className="credit-details__col__value">
              {value}
            </Col>
          </Row>
        </Col>
      )
    );
  });
};

export const CreditDetails = ({ row }: { row: CreditView }) => {
  const [creditDebt, setCreditDebt] = React.useState(null);
  const [showDebt, setShowDebt] = React.useState(false);
  const { id, code, organization } = row.product;

  const getCreditDebt = async () => {
    if (showDebt) {
      setShowDebt(state => !state);
    } else {
      try {
        const creditDebt = await api.products.getCreditDebt(+id);
        setCreditDebt(creditDebt);
        setShowDebt(true);
      } catch (e) {
        goto(pages.credits);
      }
    }
  };

  const exportDebt = async () => {
    const XLSX = await import('xlsx');
    const rows = [
      [translate('front.credits-page.document-debt.contract-number.label'), code],
      [translate('front.credits-page.document-debt.owner.label'), organization],
      ['', ''], // empty row
      [translate('front.credits-page.document-debt.date.label'), moment().format('DD.MM.YYYY')],
    ];

    Object.keys(creditDebt).forEach(key =>
      rows.push([
        debtFields.find(o => o.key === key).label,
        format.getAmount(Number(creditDebt[key])),
      ]),
    );
    /* convert AOA back to worksheet */
    const ws = XLSX.utils.aoa_to_sheet(rows);
    /* build new workbook */
    const wb = XLSX.utils.book_new();
    const headerText = 'debt_report';
    const sheetName = headerText.substring(0, 31);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    const fileName = `${headerText}.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  const renderCreditDebt = () => {
    const { product } = row;

    return debtFields.map(({ key, label }) => {
      let value;
      if (isExist(creditDebt[key])) {
        const balance = format.getAmount(Number(creditDebt[key]));
        value = `${balance} ${product.currency}`;
      } else {
        value = '';
      }

      return (
        <Col md={6} className="credit-details__col" key={key}>
          <Row>
            <Col sm={6} className="credit-details__col__label">
              {label}
            </Col>
            <Col sm={6} className="credit-details__col__value">
              {value}
            </Col>
          </Row>
        </Col>
      );
    });
  };

  const neededType = !generalProductTypes.includes(row.product.subType);
  return (
    <>
      <Row>{renderRowFields(row)}</Row>
      {neededType && ( // for non-general credits
        <Row>
          <Button onClick={getCreditDebt} size="sm">
            {translate('front.credits-page.row-detail.debt-button.label')}
          </Button>
          {creditDebt && ( // if credit debt present -> render export button
            <Button size="sm" onClick={exportDebt}>
              {translate('front.credits-page.row-detail.export-button.label')}
            </Button>
          )}
        </Row>
      )}
      {showDebt && <Row>{renderCreditDebt()}</Row>}
    </>
  );
};
