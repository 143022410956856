export enum DocumentManagementStateFilter {
  Draft = 'Draft',
  OnSigning = 'OnSigning',
  NeedSigning = 'NeedSigning',
  PartiallySigned = 'PartiallySigned',
  NotRequiredSign = 'NotRequiredSign',
  Signed = 'Signed',
  Rejected = 'Rejected',
}

export enum DocumentManagementDetailsNames {
  Attach = 'attach',
  Body = 'body',
  AuthorFullName = 'authorFullName',
  AuthorRole = 'authorRole',
  AuthorBranch = 'authorBranch',
  Customers = 'customers',
  Category = 'category',
  Signatory = 'signatory',
  Type = 'type',
  ManagersSignatory = 'managersSignatory',
  RejectComment = 'rejectComment',
}

export enum DocumentsManagementFilterEnum {
  SearchText = 'searchText',
  OrderStates = 'orderStates',
  ExcludedStates = 'excludedStates',
  CustomerIds = 'customerIds',
  OrderNumber = 'orderNumber',
  DateFrom = 'dateFrom',
  DateTo = 'dateTo',
  TypeIds = 'typeIds',
  StateBank = 'stateBank',
  StateTab = 'stateTab',
  StateCustomer = 'stateCustomer',
  SignatoryBank = 'personIds',
}

export enum StateTab {
  Working = 'Working',
  Rejected = 'Rejected',
  Signed = 'Signed',
  All = 'All',
}
