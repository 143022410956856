import * as React from 'react';

import { api } from 'api';
import { Button } from 'components/buttons/Button';
import { useFilter } from 'components/Table/DataTable';

export const ExternalApiFilter: React.FC = () => {
  const [isTokenLoading, setIsTokenLoading] = React.useState<boolean>(false);

  const { isLoading, rows, reFetchData } = useFilter();

  const activeSessionIds: Array<number> = React.useMemo(
    () => rows.filter(it => it.active).map(it => it.id),
    [rows],
  );

  const createHandleSession = (callbackApi: () => Promise<void>) => async () => {
    setIsTokenLoading(true);
    await callbackApi();
    reFetchData();
    setIsTokenLoading(false);
  };
  const hasActiveSession = activeSessionIds.length === 0;
  const propsButton = React.useMemo(
    () => ({
      onClick: createHandleSession(
        hasActiveSession
          ? api.profile.createExternalApiSession
          : api.profile.revokeExternalApiSession,
      ),
      label: hasActiveSession
        ? 'front.profile.route-external-api.create-token'
        : 'front.profile.route-external-api.deactivate-token',
      disabled: isLoading || isTokenLoading,
      progress: isTokenLoading,
    }),
    [hasActiveSession, isLoading, isTokenLoading],
  );

  return <Button {...propsButton} />;
};
