import * as React from 'react';

import { default as ProgressBar } from '@material/react-linear-progress';

import '@material/react-linear-progress/dist/linear-progress.css';
import './styles.css';

export const LinearProgress = ({ isLoading }: { isLoading: boolean }) => {
  return isLoading ? <ProgressBar indeterminate /> : <div className="empty-loader" />;
};
