import * as React from 'react';
import { TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { ImportPaymentsResponse, OrderFields } from 'api/interfaces';
import { getPage } from 'api/Service';
import { Button } from 'components/buttons/Button';
import { CounterDescription } from 'components/CounterDescription/CounterDescription';
import { FormError } from 'components/forms/FormError';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { getAmountNumberFormatWithComma } from 'components/utils/format';
import { translate } from 'i18n/translator';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { CurrencyExchangeImportResultModal } from 'pages/Import/ImportModal/CurrencyExchangeImportResultModal';
import { useError } from 'pages/Import/SuccessImportList/useError';
import { useLoading } from 'pages/Import/SuccessImportList/useLoading';

interface Props {
  importResult: ImportPaymentsResponse;
  importType: string;
}

const columns: TableColumnProps<OrderFields>[] = [
  {
    label: 'front.payroll-page.col-receiver.label',
    render: row => <DataColumn title={row.receiverName} subTitle={row.receiverIban} />,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.payroll-page.col-sum.label',
    render: row => <DataColumn title={getAmountNumberFormatWithComma(row.receiverAmount)} />,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

export const SuccessCurrencyExchangeList: React.FC<Props> = ({ importResult, importType }) => {
  const [error, setError] = useError();
  const [isLoading, setLoading] = useLoading();

  const handleClick = async () => {
    setLoading(true);

    try {
      await api.payments.acceptImportPayments(
        importResult.orders.map(order => order.id),
        importType,
      );

      goto(pages.currencyExchange);

      await confirmModal(CurrencyExchangeImportResultModal, {
        orders: importResult.orders,
      });
    } catch (error: any) {
      setError(JSON.parse(error.message)?.message);
    } finally {
      setLoading(false);
    }
  };

  const isDisableSave = !importResult?.orders?.length || isLoading;

  return (
    <>
      <FormError>{error}</FormError>
      <Button color="primary" onClick={handleClick} size="md" disabled={isDisableSave}>
        {translate('front.import-page.save.label')}
      </Button>
      <Button
        color="secondary"
        onClick={() => {
          goto(pages.currencyExchange);
        }}
        size="md"
      >
        {translate('front.import-page.cancel.label')}
      </Button>
      <CounterDescription
        count={importResult.orders?.length}
        description={translate('front.import-page.tab-success.label')}
      />
      <DataTable
        name={TableNamesEnum.SuccessPaymentsImport}
        columns={columns}
        fetchRows={async request => getPage(importResult.orders, request)}
      />
    </>
  );
};
