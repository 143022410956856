import { UserState } from 'store/reducers/user';

import actionCreator from '../actionCreator';
import { USER } from './types';

export const UserActions = {
  setToken: actionCreator(USER.SET_TOKEN),
  setUser: actionCreator<UserState>(USER.SET_USER),
  setCollectUser: actionCreator<Partial<UserState>>(USER.SET_COLLECT_USER),
  setCustomers: actionCreator(USER.SET_CUSTOMERS),
  setDefaultCustomer: actionCreator(USER.SET_DEFAULT_CUSTOMER),
};
