import { checkMultiCustomer } from 'api/ConstantService';
import { translate } from 'i18n/translator';
import { Fields } from 'pages/Payments/RegularPayments/SchedulePage';

import { del, get, post, put } from './backend';
import { cache, GridRequest, GridResponse, Option } from './Service';

export enum RegularPeriodicity {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  LAST_DAY_OF_MONTH = 'LAST_DAY_OF_MONTH',
  SINGLE = 'SINGLE',
}

export const regularPeriodicityTranslations = {
  [RegularPeriodicity.DAILY]: 'front.regular-payments-page-table.daily.label',
  [RegularPeriodicity.WEEKLY]: 'front.regular-payments-page-table.weekly.label',
  [RegularPeriodicity.MONTHLY]: 'front.regular-payments-page-table.monthly.label',
  [RegularPeriodicity.LAST_DAY_OF_MONTH]: 'front.regular-payments-page-table.end_of_month.label',
  [RegularPeriodicity.SINGLE]: 'front.regular-payments-page-table.one_time.label',
};

export interface RegularPayment {
  currency: string;
  customerId: number;
  id: number;
  orderAmount: number;
  orderId: number;
  period: number;
  receiverAccount: string;
  receiverName: string;
  startDate: string;
  stopDate: string;
  type: string;
}

export interface RegularPaymentHistory {
  bankResponse: string;
  commission: string;
  created: string;
  customerId: number;
  customerName: string;
  id: number;
  managerId: number;
  orderAlias: string;
  orderDate: string;
  orderKind: string;
  orderNumber: string;
  orderState: string;
  orderStateLabel: string;
  orderType: string;
  priority: number;
  translatedOrderType: string;
  updated: string;
}

export class RegularPaymentsService {
  async getRegularPayments(request: GridRequest): Promise<GridResponse<RegularPayment>> {
    return await get('/v1/orders/schedule', checkMultiCustomer(request));
  }

  @cache
  getRegularPaymentTypes(): Option[] {
    return Object.keys(regularPeriodicityTranslations).map(key => ({
      value: key,
      label: translate(regularPeriodicityTranslations[key as RegularPeriodicity]),
    }));
  }

  getDayOptions(): Option[] {
    return [
      {
        value: '1',
        label: translate('front.regular-payments-page-table.days.monday.label'),
      },
      {
        value: '2',
        label: translate('front.regular-payments-page-table.days.tuesday.label'),
      },
      {
        value: '3',
        label: translate('front.regular-payments-page-table.days.wednesday.label'),
      },
      {
        value: '4',
        label: translate('front.regular-payments-page-table.days.thursday.label'),
      },
      {
        value: '5',
        label: translate('front.regular-payments-page-table.days.friday.label'),
      },
      {
        value: '6',
        label: translate('front.regular-payments-page-table.days.saturday.label'),
      },
      {
        value: '7',
        label: translate('front.regular-payments-page-table.days.sunday.label'),
      },
    ];
  }

  getDateOptions(daysCount = 31): Option[] {
    return Array(daysCount)
      .fill(0)
      .map((item, index) => ({ value: `${index + 1}`, label: `${index + 1}` }));
  }

  async createSchedule(schedule: Fields & { orderId: string }): Promise<void> {
    await post('/v1/orders/schedule/', schedule);
  }

  async getSchedule(orderId: string, scheduleId: string): Promise<RegularPayment> {
    const all: RegularPayment[] = await get(`/v1/orders/schedule/order-schedules/${orderId}`);
    return all.find(order => order.id === Number(scheduleId));
  }

  async getHistory(orderId: string, rq: GridRequest): Promise<GridResponse<RegularPaymentHistory>> {
    return await get(`/v1/orders/schedule/by-master/${orderId}`, rq);
  }

  async updateSchedule(schedule: Fields & { id: number; orderId: number }): Promise<void> {
    await put('/v1/orders/schedule/', schedule);
  }

  async deleteSchedule(id: number): Promise<void> {
    await del(`/v1/orders/schedule/${id}`);
  }
}
