import * as React from 'react';
import { Col, Container, Row } from 'react-grid';
import reactHtmlParser from 'react-html-parser';

import { api } from 'api';
import { Certificate } from 'api/CertificateService/interfaces';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { PasswordField } from 'components/forms/inputs/PasswordField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import { translate } from 'i18n/translator';

interface Fields {
  password: string;
}

interface Props {
  certificate: Certificate;
}

const ReissueAuthorModalForm = ({ certificate, resolve }: PropsWithConfirmModal<Props>) => {
  const { certificate: Base64, id } = certificate;
  const { error, progress, handleSubmit } = useForm<Fields>();

  const onSave = async (formData: Fields) => {
    const { password } = formData;

    await api.certificate.deleteKey(password, Base64);
    await api.certificate.revokeCertificate(certificate.id);
    await api.certificate.deleteCertificate(id);
    resolve(true);
  };

  return (
    <div>
      <h3>{translate('front.cert-page.reissue-umca-modal.title.label')}</h3>
      <DefaultForm disabled={progress}>
        {error && <FormError>{reactHtmlParser(error)}</FormError>}
        <PasswordField
          label="front.cert-page.reissue-modal.input-password.label"
          name="password"
          required
        />
        <Container>
          <Row>
            <Col sm={6}>
              <Button
                onClick={e => handleSubmit(onSave, e)}
                size="md"
                progress={progress}
                type="submit"
                block
              >
                {translate('front.cert-page.reissue-modal.reissue-button.label')}
              </Button>
            </Col>
            <Col sm={6}>
              <Button onClick={() => resolve(null)} color="secondary" size="md" block>
                {translate('front.cert-page.reissue-modal.cancel-button.label')}
              </Button>
            </Col>
          </Row>
        </Container>
      </DefaultForm>
    </div>
  );
};

export const ReissueAuthorModal = withForm(ReissueAuthorModalForm);
