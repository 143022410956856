import { useLocalStorage } from 'hooks/useLocalStorage';
import { AccountStatementFilterEnum } from 'pages/AccountStatements/enums';
import { LOCAL_STORAGE_KEY } from 'store/actions/types';

interface IUseStatementsStorageFilter {
  onUpdateStorage: (params: OnUpdateStorage) => void;
  storageRevaluationValue: boolean;
  storageShowAdditionalDetails: boolean;
}

interface OnUpdateStorage {
  showAdditionalDetails: boolean;
  withRevaluation: boolean;
}

export const useStatementsStorageFilter = (key: string): IUseStatementsStorageFilter => {
  const [storageShowAdditionalDetails = false, setStorageShowAdditionalDetails] = useLocalStorage(
    LOCAL_STORAGE_KEY.PRESERVED_VALUES,
    `${key}.${AccountStatementFilterEnum.ShowAdditionalDetails}`,
  );

  const [storageRevaluationValue, setStorageWithRevaluation] = useLocalStorage(
    LOCAL_STORAGE_KEY.PRESERVED_VALUES,
    `${key}.${AccountStatementFilterEnum.WithRevaluation}`,
  );

  const onUpdateStorage = ({
    withRevaluation = false,
    showAdditionalDetails = false,
  }: OnUpdateStorage) => {
    setStorageWithRevaluation(withRevaluation);
    setStorageShowAdditionalDetails(showAdditionalDetails);
  };

  return {
    storageRevaluationValue,
    storageShowAdditionalDetails,
    onUpdateStorage,
  };
};
