import React from 'react';
import { Col, Row } from 'react-grid';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { parseUrlParams } from 'api/backend';
import { ConstantEnum, VatType } from 'api/enums';
import { Account } from 'api/interfaces';
import { Option } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { HiddenField } from 'components/forms/inputs/HiddenField';
import { HintField } from 'components/forms/inputs/HintField';
import { RadioField } from 'components/forms/inputs/RadioField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextField } from 'components/forms/inputs/TextField';
import { FormPayload } from 'components/forms/ValidatingForm/FormContext';
import { InformationIcon } from 'components/icons';
import { RenderLabelWithLink } from 'components/RenderLabelWithLink/RenderLabelWithLink';
import { TTooltip } from 'components/Tooltip/Tooltip';
import { validateMessages } from 'components/validateMessages';
import config from 'config';
import { translate } from 'i18n/translator';
import { INN_ALLOWED_CHARACTERS } from 'pages/Demands/EmployeeManagment/utils';
import { validateIban } from 'pages/Demands/utils';
import { DomesticDetailFields } from 'pages/Payments/InternalPayment/DomesticTransferDetailFields';
import { DomesticTransferDetailNames } from 'pages/Payments/InternalPayment/enums';
import { useChangingPurpose } from 'pages/Payments/InternalPayment/hooks/useChangingPurpose';
import { useDomesticTransferSubscribe } from 'pages/Payments/InternalPayment/hooks/useDomesticTransferSubscribe';
import { useMaxLengthPurpose } from 'pages/Payments/InternalPayment/hooks/useMaxLengthPurpose';
import { useStorageVatPercent } from 'pages/Payments/InternalPayment/hooks/useStorageVatPercent';
import { useUpdatePurpose } from 'pages/Payments/InternalPayment/hooks/useUpdatePurpose';
import {
  cutUserPurpose,
  DEFAULT_VAT_PERCENT,
  getAnalyticAccount,
  getBankBic,
  handleBlurSubAccount,
  handleFocusSubAccount,
  isVisibleSubAccount,
  maskIban,
  normalizeIban,
  normalizeSubAccount,
  validateReceiverTaxCode,
} from 'pages/Payments/InternalPayment/utils';
import { selectConstants } from 'store/selectors';
import { isString } from 'utils/isData';

import {
  DetailProps,
  Payload as InternalPaymentPagePayload,
  PaymentOrderFields,
} from './interface';

const receiverNonResidentTaxCodeRegex = /^0{8,9}$/;

const { receiverTaxCodeEmpty } = config.page.domesticPayment;

interface TooltipInterface {
  tooltipIcon: React.ReactNode;
  tooltipValue: TTooltip;
}

const vatTypeOptions: Option<TooltipInterface>[] = [
  {
    value: VatType.WITHOUT_VAT,
    label: 'front.vat-types.without-vat.label',
  },
  {
    // TODO need to rename value after backend fix
    value: VatType.INCLUDE_VAT20,
    label: 'front.vat-types.include-vat.label',
  },
  {
    value: VatType.EMPTY,
    label: 'front.vat-types.empty-vat.label',
  },
  {
    value: VatType.TAXES_PAYMENT,
    label: 'front.vat-types.taxes-payment.label',
    content: {
      tooltipValue: RenderLabelWithLink(
        'front.vat-types.taxes-payment.tooltip',
        [config.links.structuredFormat],
        ['front.vat-types.taxes-payment.tooltip-link'],
      ),
      tooltipIcon: InformationIcon(),
    },
  },
];

export const isVisibleResidentCountryFiled = (
  taxCode: PaymentOrderFields[DomesticTransferDetailNames.ReceiverTaxCode],
): boolean => receiverNonResidentTaxCodeRegex.test(`${taxCode}`);

export const isVisiblePassportField = (
  taxCode: PaymentOrderFields[DomesticTransferDetailNames.ReceiverTaxCode],
): boolean => taxCode === receiverTaxCodeEmpty;

export const validatePassport = (value: string): string => {
  if (/^([0-9]{9}|[A-ZА-ГҐДЕЁЄЖЗИІЇЙК-Я]{2}:[0-9]{6})$/.test(value)) {
    return null;
  }

  return 'front.internal-payment-page.passport-error.label';
};

export const nonResidentCountryPlaceholder = (form: FormPayload): string => {
  if (form.disabled && !form.getFieldValue(DomesticTransferDetailNames.ReceiverAccountId)) {
    return '  ';
  }

  return undefined;
};

export const DomesticTransferDetails: React.FC<DetailProps> = ({
  allowSavePurpose,
  isDisabled = false,
}) => {
  const { form, onChangeCustomer, order } = useOrderDetails<
    PaymentOrderFields,
    InternalPaymentPagePayload
  >();

  const constants = useSelector(selectConstants());
  const budgetCodes = constants?.[ConstantEnum.BankCodesForBudgetPayments].split(',');

  const { copyFrom, customerId } = parseUrlParams(useLocation().search);

  const [defaultStorageVatPercent, setDefaultStorageVatPercent] = useStorageVatPercent();

  const defaultVatPercent = isString(defaultStorageVatPercent)
    ? defaultStorageVatPercent
    : DEFAULT_VAT_PERCENT;

  const {
    vatType,
    purpose,
    isCustomBudgetPurpose,
    receiverAccount,
    vatPercent = defaultVatPercent,
    isActualPayer,
    isActualRecipient,
  } = form.getFormData();

  const {
    receiverOptions = [],
    receiverAccountOptions = [],
    localBanksOptions = [],
    countriesOptions = [],
  } = form.payload;

  const correctVatTypeOptions = React.useMemo(
    () =>
      vatTypeOptions.filter(item => {
        if (item.value === VatType.EMPTY) {
          return order?.detailFields.vatType === VatType.EMPTY;
        }

        return true;
      }),
    [order],
  );

  React.useEffect(() => {
    if (order) {
      form.updateData({ ...order.detailFields });
      !defaultStorageVatPercent && setDefaultStorageVatPercent(DEFAULT_VAT_PERCENT);
    }
  }, []);

  // Needed to update the payerTaxCode value when vatType changes
  React.useEffect(() => {
    order &&
      form.updateData({
        [DomesticTransferDetailNames.PayerTaxCode]: order.detailFields.payerTaxCode,
      });

    if (vatType === VatType.INCLUDE_VAT20) {
      form.updateData({
        [DomesticTransferDetailNames.VatPercent]: vatPercent ? vatPercent : DEFAULT_VAT_PERCENT,
      });
    }
  }, [vatType]);

  useChangingPurpose({ order, vatPercent: vatPercent || defaultVatPercent });
  useUpdatePurpose({ order, vatPercent: vatPercent || defaultVatPercent });
  useDomesticTransferSubscribe(budgetCodes);
  const maxLengthPurpose = useMaxLengthPurpose();

  const onChangeVatType = (value: string): void => {
    if (value !== VatType.INCLUDE_VAT20) {
      form.updateData({
        vatPercent: null,
      });
    }

    if (value === VatType.INCLUDE_VAT20) {
      form.updateData({
        vatPercent: defaultStorageVatPercent ?? DEFAULT_VAT_PERCENT,
      });
    }

    if (!value) {
      form.updateData({ purpose: cutUserPurpose(purpose, vatPercent) });
    }

    const [error] = form.validateField(DomesticTransferDetailNames.VatType, value);
    error && form.updateFieldInData(DomesticTransferDetailNames.VatType, 'error', error);
  };

  const notManualPurpose = vatType === VatType.EMPTY && !isCustomBudgetPurpose;

  const taxCode = form.getFieldValue(
    DomesticTransferDetailNames.ReceiverTaxCode,
  ) as PaymentOrderFields[DomesticTransferDetailNames.ReceiverTaxCode];

  const onChange = (_: string, option: Option): void => {
    if (option) {
      const isAccounts = option.content.accounts.length > 1;

      form.updateData({
        receiverTaxCode: option.content?.counterparty.bin,
      });

      if (isAccounts) {
        form.updateData({
          receiverTaxCode: option.content.counterparty.bin,
          receiverBankName: '',
          receiverBic: '',
          receiverAccount: '',
        });
        form.setPayload({
          receiverAccountOptions: option.content.accounts.map((item: Account) => ({
            value: item.iban || item.number,
            label: item.iban,
            content: item,
          })),
        });
      }
    }
  };

  const analyticAccount = React.useMemo(
    () => getAnalyticAccount(`${receiverAccount}`),
    [receiverAccount],
  );

  const receiverBankBic = React.useMemo(() => getBankBic(receiverAccount), [receiverAccount]);

  const validationVatType = React.useCallback(
    (value: string) => {
      if (budgetCodes.includes(receiverBankBic) && value !== VatType.TAXES_PAYMENT) {
        return validateMessages.byBicAllowedVatType(receiverBankBic);
      }
    },
    [receiverBankBic],
  );

  return (
    <DefaultForm>
      <h3>{translate('front.internal-payment-page.payer.label')}</h3>
      <CustomerSelect
        name={DomesticTransferDetailNames.PayerName}
        label="front.internal-payment-page.order-payer-name.label"
        onSelectOption={onChangeCustomer}
        disabled={!!order?.state || !!copyFrom || !!customerId}
        required
      />
      <SelectField
        name={DomesticTransferDetailNames.PayerAccount}
        label="front.internal-payment-page.payer-account.label"
        options={form.payload.payerAccountOptions}
        required
        clearable
      />
      <Row>
        <Col sm={4}>
          <TextField
            name={DomesticTransferDetailNames.PayerTaxCode}
            label="front.internal-payment-page.payer-code.label"
            required={notManualPurpose}
            disabled
          />
        </Col>
      </Row>
      <CheckboxField
        label="front.internal-payment-page.actual-payer.label"
        name={DomesticTransferDetailNames.IsActualPayer}
        defaultValue={false}
        disabled={isDisabled}
      />
      {isActualPayer && (
        <>
          <HintField
            label="front.internal-payment-page.actual-payer.label"
            name={DomesticTransferDetailNames.PayerNameF}
            minLength={1}
            maxLength={140}
            disabled={isDisabled}
          />
          <TextField
            label="front.internal-payment-page.actual-payer-code.label"
            name={DomesticTransferDetailNames.PayerTaxCodeF}
            allowedCharacters={INN_ALLOWED_CHARACTERS}
            minLength={8}
            maxLength={10}
            disabled={isDisabled}
          />
        </>
      )}
      {vatType !== VatType.EMPTY && <HiddenField name="payerTaxCode" />}
      <h3 className="m-t-24">{translate('front.internal-payment-page.receiver.label')}</h3>
      <HintField
        label="front.internal-payment-page.organization-name.label"
        name={DomesticTransferDetailNames.ReceiverName}
        hints={receiverOptions}
        onChange={onChange}
        forbiddenSpecialCharacters
        maxLength={140}
        showHintsOnFocus
        disabled={isDisabled}
        required
      />
      <HintField
        label="front.internal-payment-page.receiver-account.label"
        name={DomesticTransferDetailNames.ReceiverAccount}
        hints={receiverAccountOptions}
        transform={maskIban}
        validate={validateIban}
        normalize={normalizeIban}
        length={29}
        required
        disabled={isDisabled}
        forbiddenSpecialCharacters
        showHintsOnFocus
      />
      <SelectField
        name={DomesticTransferDetailNames.ReceiverBic}
        label="front.internal-payment-page.receiver-bic.label"
        options={localBanksOptions}
        required
        disabled
      />
      <Row>
        <Col sm={4}>
          <TextField
            label="front.internal-payment-page.receiver-code.label"
            name={DomesticTransferDetailNames.ReceiverTaxCode}
            validate={validateReceiverTaxCode}
            disabled={isDisabled}
            required
          />
        </Col>
        {isVisibleResidentCountryFiled(taxCode) && (
          <Col sm={8}>
            <SelectField
              label="front.internal-payment-page.non-resident-country.label"
              name={DomesticTransferDetailNames.ReceiverCountryId}
              options={countriesOptions}
              placeholder={nonResidentCountryPlaceholder(form)}
              required
              clearable
              isSearchable
            />
          </Col>
        )}
        {isVisibleSubAccount(`${receiverAccount}`) && (
          <Col sm={8}>
            <TextField
              label="front.internal-payment-page.order-subaccount.label"
              name={DomesticTransferDetailNames.SubAccount}
              disabled={isDisabled}
              onFocus={handleFocusSubAccount(analyticAccount, form.updateData)}
              onBlur={handleBlurSubAccount(analyticAccount, form.updateFieldInData)}
              normalize={normalizeSubAccount(analyticAccount)}
              autoComplete="off"
            />
          </Col>
        )}
        <Col sm={8}>
          {isVisiblePassportField(taxCode) && (
            <TextField
              label="front.internal-payment-page.passport.label"
              name={DomesticTransferDetailNames.ReceiverAddCode}
              validate={validatePassport}
              tooltip={translate('front.internal-payment-page.passport-tooltip.label')}
              required
            />
          )}
        </Col>
      </Row>
      <CheckboxField
        label="front.internal-payment-page.actual-recipient.label"
        name={DomesticTransferDetailNames.IsActualRecipient}
        defaultValue={false}
        disabled={isDisabled}
      />
      {isActualRecipient && (
        <>
          <HintField
            label="front.internal-payment-page.actual-recipient.label"
            name={DomesticTransferDetailNames.ReceiverNameF}
            minLength={1}
            maxLength={140}
            disabled={isDisabled}
          />
          <TextField
            label="front.internal-payment-page.actual-recipient-code.label"
            name={DomesticTransferDetailNames.ReceiverTaxCodeF}
            allowedCharacters={INN_ALLOWED_CHARACTERS}
            minLength={8}
            maxLength={10}
            disabled={isDisabled}
          />
        </>
      )}
      <h3 className="m-t-24">
        {translate('front.internal-payment-page.amount-and-purpose.title.label')}
      </h3>
      <Row>
        <Col sm={4}>
          <AmountField
            name={DomesticTransferDetailNames.Amount}
            label={`${translate('front.internal-payment-page.order-amount.label')} ${
              form.payload.isoCode || ''
            }`}
            minAmount={0.01}
            maxLengthWithoutZero={12}
            isoCode={form.payload.isoCode}
            disabled={isDisabled}
            hasMoneyFormat
            thousandSeparator={' '}
            required
          />
        </Col>
        <Col sm={8}>
          <RadioField
            name={DomesticTransferDetailNames.VatType}
            options={correctVatTypeOptions}
            cssType="inline"
            onChange={onChangeVatType}
            externalValidation={validationVatType}
            disabled={isDisabled}
            clearable
          />
        </Col>
      </Row>
      <DomesticDetailFields
        vatType={vatType}
        isDisabled={isDisabled}
        notManualPurpose={notManualPurpose}
        setStorageVatPercent={setDefaultStorageVatPercent}
      />
      {vatType !== VatType.TAXES_PAYMENT && (
        <HintField
          label="front.internal-payment-page.order-purpose.label"
          name={DomesticTransferDetailNames.Purpose}
          rows={3}
          minLength={6}
          maxLength={maxLengthPurpose}
          disabled={notManualPurpose || isDisabled}
          hints={form.payload?.purposes}
          hasCounter
          required
          denyCaretReturn
          templateValue={form.payload.templatePurpose}
          forbiddenSpecialCharacters
        />
      )}
      {/*<TextAreaField*/}
      {/*  label="front.internal-payment-page.additional-information-about-transfer.label"*/}
      {/*  name={DomesticTransferDetailNames.AdditionalPaymentInfo}*/}
      {/*  placeholder={translate(*/}
      {/*    'front.internal-payment-page.additional-information-transfer-details.label',*/}
      {/*  )}*/}
      {/*  maxLength={420}*/}
      {/*  hasCounter*/}
      {/*  rows={3}*/}
      {/*  disabled={isDisabled}*/}
      {/*/>*/}
      {allowSavePurpose && (
        <CheckboxField
          label="front.internal-payment-page.order-save-payment-purpose.label"
          name="savePaymentPurpose"
        />
      )}
    </DefaultForm>
  );
};
