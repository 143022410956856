import * as React from 'react';
import { Col, Row, Container } from 'react-grid';

import { api } from 'api';
import { CardLimitView } from 'api/ProductsService';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { TextField } from 'components/forms/inputs/TextField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { Page } from 'components/layout/Page/Page';
import { translate } from 'i18n/translator';

interface Props {
  limit: CardLimitView;
  resolve: (resolve: boolean) => void;
}

interface Fields {
  code: number;
  limit: {
    label: string;
  };
  maxAmount: number;
  maxCount: number;
  period: {
    label: string;
  };
}

export const LimitPageForm: React.FC<Props> = ({ limit: fields, resolve }) => {
  const { handleSubmit, progress, error } = useForm<Fields>(async ({ setFields }) =>
    setFields(fields),
  );

  const onSave = async ({ limit, period, ...data }: Fields) => {
    await api.products.saveCardLimit({
      limit: fields.limit,
      period: fields.period,
      ...data,
    });
    resolve(true);
  };

  return (
    <Page title={translate('front.cards.limits-page.modal-title.label')}>
      <DefaultForm disabled={progress}>
        <FormError>{error}</FormError>
        <TextField label="front.cards.limits-page.input-code.label" name="code" required disabled />
        <TextField
          label="front.cards.limits-page.input-limit.label"
          name="limit.label"
          required
          disabled
        />
        <TextField
          label="front.cards.limits-page.input-period.label"
          name="period.label"
          required
          disabled
        />
        <AmountField
          label="front.cards.limits-page.input-max-amount.label"
          name="maxAmount"
          hasMoneyFormat
          required
        />
        <AmountField
          label="front.cards.limits-page.input-max-count.label"
          name="maxCount"
          maxLengthWithoutZero={9}
          maskConfig={{ decimalSeparator: ' ' }}
          required
        />
        <Container>
          <Row>
            <Col md={6}>
              <Button
                onClick={e => handleSubmit(onSave, e)}
                size="md"
                progress={progress}
                type="submit"
                block
              >
                {translate('front.cards.limits-page.save-button.label')}
              </Button>
            </Col>
            <Col md={6}>
              <Button onClick={() => resolve(null)} color="secondary" size="md" block>
                {translate('front.cards.limits-page.cancel-button.label')}
              </Button>
            </Col>
          </Row>
        </Container>
      </DefaultForm>
    </Page>
  );
};

export const LimitModal = withForm(LimitPageForm);
