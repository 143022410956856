import { KeyType } from 'api/CertificateService/enums';
import { PersonConfig } from 'api/UserService';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { parseConstants } from 'pages/Profile/Certificates/CertificateEngine/utils';

import { getKeyTypeOptions } from './utils';

export const useMode = () => {
  const form = useForm();

  const updateMode = (config: PersonConfig): KeyType => {
    const actualConstants = parseConstants(config);
    const keyTypeOptions = getKeyTypeOptions(actualConstants);
    const mode = keyTypeOptions[0]?.value as KeyType;
    form.setPayload({ keyTypeOptions });
    form.setData({ mode });
    return mode;
  };

  const currentMode = form.getFieldValue('mode') as KeyType;

  return {
    currentMode,
    updateMode,
  };
};
