import * as React from 'react';
import { Col } from 'react-grid';

import { api } from 'api';
import * as CipherService from 'api/CertificateService/CipherModule/CipherService';
import {
  CertificateStatus,
  CertificateType,
  KeyType,
  UserKeystoreType,
} from 'api/CertificateService/enums';
import { ActionEnum, CheckSmsActionEnum } from 'api/enums';
import { FileType } from 'api/Service';
import { FileField } from 'components/forms/inputs/FileField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { confirmationModal } from 'components/modals/ConfirmModal';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { SmsModal } from 'components/modals/SmsModal';
import { isEmptyObject } from 'components/utils/utils';
import { validateMessages } from 'components/validateMessages';
import { translate } from 'i18n/translator';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { FILE_SEPARATOR } from 'pages/Demands/utils';
import { SearchTokenButton } from 'pages/Profile/Certificates/CertificateEngine/SearchTokenButton';
import { SearchTokenLabel } from 'pages/Profile/Certificates/CertificateEngine/SearchTokenLabel';
import { FileFieldInterface } from 'pages/Profile/Certificates/CertificateEngine/types';
import { CertificateFieldNames } from 'pages/Profile/Certificates/KepCertificate/enums';
import {
  CreateFetchCerts,
  CreateOnSave,
  FetchOnMount,
} from 'pages/Profile/Certificates/KepCertificate/types';
import { useCertificateDetails } from 'pages/Profile/Certificates/KepCertificate/useCertificateDetails';
import { useSearchToken } from 'pages/Profile/Certificates/KepCertificate/useSearchToken';
import {
  hasSomeActiveCertificate,
  isConnectedToken,
} from 'pages/Profile/Certificates/KepCertificate/utils';
import { withKepCertificate } from 'pages/Profile/Certificates/KepCertificate/withKepCertificate';
import { ConnectionCertificateModal } from 'pages/Profile/Certificates/Modals/ConnectionCertificateModal';
import { CustomError } from 'utils/customError';
import './addKepCertificatePage.scss';

const filterParams = {
  statuses: `${CertificateStatus.Active}, ${CertificateStatus.Certified}`,
};

const gotoCertificatesPage = () => goto(pages.profile.tabs.certificates);

const AddCertToken = () => {
  const {
    form: { payload },
  } = useCertificateDetails();

  const { onSearchToken, isSearchTokens } = useSearchToken();

  return (
    <>
      <Col sm={10}>
        <SelectField
          label="front.cert-page-add.input-token.select-token.label"
          name={CertificateFieldNames.Store}
          options={payload.storeOptions}
          isSearchable={false}
          required
        />
      </Col>
      <SearchTokenButton onSearchToken={onSearchToken} />
      <SearchTokenLabel values={payload.storeOptions} isSearchTokens={isSearchTokens} />
    </>
  );
};

const AddCertFile = () => (
  <>
    <Col sm={12}>
      <div className="title-wrapper">
        <span>{translate('front.cert-page-add.input-token.way-to-key.label')}</span>
      </div>
    </Col>
    <Col sm={12}>
      <FileField
        label="front.cert-page-add.input-token.take-key.label"
        name={CertificateFieldNames.EdsKey}
        outputFormat={FileType.BASE_64}
        accept={{}}
        required
      />
    </Col>
  </>
);

const AddCertificateDetails: React.FC<any> = () => {
  const { mode } = useCertificateDetails();

  return mode === KeyType.TOKEN ? <AddCertToken /> : <AddCertFile />;
};

const fetchOnMount: FetchOnMount = async (
  { customer },
  { setFields, setPayload, updateMode, fetchData },
) => {
  const mode = updateMode(customer.config);

  const storeOptions = await CipherService.getConnectedTokenOptions();

  const filter = {
    customerIds: [customer.id],
    edrpou: customer.taxCode,
  };

  await fetchData(mode, filter);

  setPayload({ storeOptions });
  setFields({ customerId: `${customer.id}`, store: storeOptions[0]?.value });
};

const createFetchCerts: CreateFetchCerts =
  ({ updateData, setPayload }) =>
  async mode => {
    if (mode === KeyType.TOKEN) {
      const storeOptions = await CipherService.getConnectedTokenOptions();

      updateData({ store: storeOptions[0]?.value });
      setPayload({ storeOptions: storeOptions });
    }
  };

const createOnSave: CreateOnSave =
  (validatePassword, { payload, customer }) =>
  async ({ password, store, mode, customerId, edsKey }) => {
    const { storeOptions } = payload;
    const isTokenMode = mode === KeyType.TOKEN;

    validatePassword();

    const storeOption = storeOptions.find(item => item.value === store)?.content;

    const currentCertificates = await api.certificate.getCertificates(
      [customerId],
      false,
      filterParams,
    );

    if (hasSomeActiveCertificate(currentCertificates)) {
      await confirmationModal('front-profile.prohibited-certificate.label', false, false);
      return gotoCertificatesPage();
    }

    const ticketUuid = await CipherService.createSession();
    await CipherService.setSessionParams(ticketUuid);

    if (!isTokenMode) {
      const edsKeyFieldValue = (edsKey[0] as FileFieldInterface).value;
      const [, content] = edsKeyFieldValue.split(FILE_SEPARATOR);

      await CipherService.createSessionContainer({ ticketUuid, base64Data: content });
    }

    isTokenMode &&
      (await CipherService.createSessionContainer({
        ticketUuid,
        keyStorePath: storeOption.activeModePath,
      }));

    const cipherCertificate = await CipherService.getCertificateInfo(
      ticketUuid,
      password,
      ActionEnum.AddCertificate,
    );

    if (isEmptyObject(cipherCertificate.certificateInfo)) {
      await CipherService.closeSession(ticketUuid);

      throw new CustomError(
        translate(
          isTokenMode
            ? 'front.login-form.certificate.token-no-valid-keys-kep.label'
            : 'front.login-form.certificate.token-no-valid-file-kep.label',
        ),
      );
    }

    const cipherCertificateBase64 = await CipherService.getCertificateBase64(
      ticketUuid,
      password,
      ActionEnum.AddCertificate,
    );

    const isConnect = isConnectedToken({
      edrpou: customer.taxCode,
      currentCertificates,
      cipherCertificate,
      cipherCertificateBase64,
      connectedToSystem: false,
    });

    if (!isConnect) {
      await CipherService.closeSession(ticketUuid);

      return await confirmationModal(
        translate('front.certificates.modal-error-belongs.label'),
        false,
        false,
      );
    }

    if (isTokenMode && !(await ConnectionCertificateModal(cipherCertificate))) {
      await CipherService.closeSession(ticketUuid);

      return gotoCertificatesPage();
    }

    const signedString = await CipherService.sign(ticketUuid, password, [cipherCertificateBase64]);
    await CipherService.closeSession(ticketUuid);

    // TODO: Added to reset the counter of the number of passwords to the selected key. DO NOT DELETE!!!
    await api.payments.getCurrentDataFromWebBank({
      customerId: +customerId,
      keystoreType: isTokenMode ? UserKeystoreType.HardWired : UserKeystoreType.FileStorage,
      isReissue: false,
      certificateType: CertificateType.Uep,
    });

    const smsCode = await confirmModal<string>(SmsModal, {
      action: CheckSmsActionEnum.ADD_CERTIFICATE,
    });
    if (!smsCode) return;

    const isNewCertificateAdded = await api.certificate.addNewCertificate(
      {
        smsCode,
        certificate: signedString[0],
        userKeystoreType: isTokenMode ? UserKeystoreType.HardWired : UserKeystoreType.FileStorage,
        userKeystoreName: isTokenMode
          ? storeOption?.serial
          : (edsKey[0] as FileFieldInterface).field,
      },
      +customerId,
    );

    !isNewCertificateAdded &&
      (await confirmationModal(
        translate('front.certificates.error-modal-unknown-key-certification-center.label'),
        true,
        false,
      ));

    gotoCertificatesPage();
  };

export const AddKepCertificatePage = withKepCertificate({
  fetchOnMount,
  createOnSave,
  createFetchCerts,
  title: 'front.cert-page-add.title.label',
  labelButtonOnSave: 'front.cert-page-add.connect-button.label',
  validatePasswordError: validateMessages.passwordValidationPolicyAdd,
  customerPrivilege: Privileges.keysAddExisted,
  filteringParams: {
    existingInSystem: false,
    edrpou: true,
  },
})(AddCertificateDetails);
