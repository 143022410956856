import React from 'react';

import classNames from 'classnames';

import { Checkbox } from 'components/forms/checkbox/Checkbox';
import { TableSortType } from 'components/react-data-table/types';
import { checkSelected, getColArray } from 'components/Table/helpers';
import { translate } from 'i18n/translator';

import './data-table.scss';

interface DataTableCheckbox {
  checked: boolean;
  colSort: TableSortType | Array<TableSortType>;
  disabled: boolean;
  label: string | string[];
  name: string;
  onChange(): void;
  sort: TableSortType | Array<TableSortType>;
}

const getTranslatedLabel = (labels: string[]) =>
  labels.reduce((acc, item, index) => {
    const isLastLabel = index + 1 === labels.length;
    const separator = isLastLabel ? '' : '/';
    return `${acc}${translate(item)}${separator}`;
  }, '');

export const DataTableCheckbox: React.FC<DataTableCheckbox> = ({
  name,
  onChange,
  checked,
  disabled,
  label,
  colSort,
  sort,
}: DataTableCheckbox) => {
  const multiLabel = React.useMemo(
    () => (Array.isArray(label) ? getTranslatedLabel(label) : translate(label)),
    [label],
  );

  const newColSort = getColArray(colSort);
  const hasSelectedSort = checkSelected(newColSort, sort);

  return (
    <div className={classNames({ isActive: hasSelectedSort })}>
      <Checkbox
        name={name}
        label={multiLabel}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
    </div>
  );
};
