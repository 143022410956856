import * as React from 'react';

import { Customer } from 'api/UserService';

export interface ICertificateContext {
  customer: Customer;
  expiredError: string;
  isSearchTokens: boolean;
  onSearchToken: () => void;
}

export const CertificateContext = React.createContext<ICertificateContext>({
  customer: null,
  isSearchTokens: false,
  expiredError: null,
  onSearchToken: () => {},
});
