import { api } from 'api';
import { ActionEnum } from 'api/enums';

const ErrorCodes = {
  WRONG_PIN: 'Невірний ПІН-код',
  WRONG_PASSWORD: 'Невірний пароль',
  KSE_INVALID_PASSWORD: 'KSE_INVALID_PASSWORD',
};

export const handlePasswordError = async (message: string, action: ActionEnum): Promise<string> => {
  const hasCodeInMessage = Object.values(ErrorCodes).some(code => message.includes(code));

  if (hasCodeInMessage) {
    return await api.auth.checkPassword(message, action);
  }

  return message;
};
