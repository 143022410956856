import * as React from 'react';

import { Modal } from './Modal';

export const withModal = (Component: React.FC, width = 500) => {
  class WithModalComponent extends React.PureComponent {
    render() {
      return (
        <Modal width={width}>
          <Component {...this.props} />
        </Modal>
      );
    }
  }

  return WithModalComponent;
};

export default withModal;
