import * as React from 'react';
import { Draggable as DndDraggable, Droppable as DndDroppable } from 'react-beautiful-dnd';

import classnames from 'classnames';

import { ItemType } from 'pages/Home/DragDrop';

import { getItemStyle, getWidgetComponent } from './utils';

interface DroppableProps {
  droppableId: string;
  items: ItemType[];
  isDraggable?: boolean;
}

export const Droppable = ({ items, isDraggable, ...props }: DroppableProps) => (
  <DndDroppable {...props}>
    {(provided: any, snapshot: any) => (
      <div
        ref={provided.innerRef}
        {...provided.droppableProps}
        className={classnames('home-page__droppable', {
          'home-page__droppable_active': isDraggable,
          'home-page__droppable_over': snapshot.isDraggingOver,
        })}
      >
        {items.map((item, index) => (
          <DndDraggable
            key={item.id}
            isDragDisabled={!isDraggable}
            draggableId={item.id}
            index={index}
          >
            {(provided: any, snapshot: any) => {
              const Component = getWidgetComponent(item.widget);
              return (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                >
                  <Component />
                </div>
              );
            }}
          </DndDraggable>
        ))}
        {provided.placeholder}
      </div>
    )}
  </DndDroppable>
);
