import { VatType } from 'api/enums';
import { CounterpartyAccount } from 'api/HandbooksService';
import { Option } from 'api/Service';
import { ExposedTypes } from 'pages/Payments/PaymentRequest/enums';

export const vatTypes: Option[] = [
  {
    value: VatType.WITHOUT_VAT,
    label: 'front.vat-types.without-vat.label',
  },
  {
    value: VatType.INCLUDE_VAT20,
    label: 'front.vat-types.include-vat.label',
  },
];

export const ExposedTypeOptions: Option<undefined>[] = [
  {
    value: ExposedTypes.FD,
    label: 'front.confirm-modal.yes.button.label',
  },
  {
    value: ExposedTypes.TRF,
    label: 'front.confirm-modal.no.button.label',
  },
];

export const toReceiverAccountOptions = (
  accounts: CounterpartyAccount[],
): Option<CounterpartyAccount>[] =>
  (accounts || []).map(item => ({
    value: item.iban,
    label: item.iban,
    content: item,
  }));
