import * as React from 'react';
import { useRouteMatch } from 'react-router';

import { api } from 'api';
import { FileType } from 'api/Service';
import { Button } from 'components/buttons/Button';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { FileField } from 'components/forms/inputs/FileField';
import { HtmlParserField } from 'components/forms/inputs/HtmlParserField';
import { TextAreaField } from 'components/forms/inputs/TextAreaField';
import { TextField } from 'components/forms/inputs/TextField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { Page } from 'components/layout/Page/Page';
import { createFormDataFromObj } from 'components/utils/createFormDataFromObj';
import config from 'config';
import { useFileExtensions } from 'hooks/useFileExtensions';
import { translate } from 'i18n/translator';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { NEW } from 'navigations/routes';
import { normalize } from 'pages/Demands/utils';
import { MailActions, MailFieldsEnum } from 'pages/Mail/enums';
import { transformForMail } from 'pages/Mail/utils';
import { isExist } from 'utils/isData';

interface LetterFields {
  [MailFieldsEnum.Subject]: string;
  [MailFieldsEnum.Desc]: string;
  [MailFieldsEnum.CompanyId]: number;
  [MailFieldsEnum.Files]: FileInterface[];
  [MailFieldsEnum.IsFromCustomer]: boolean;
}

interface FileInterface {
  downloadAction: () => void;
  // TODO check type of value
  value: any;
  field?: string;
  id?: string;
}

const { SENT, DRAFT } = config.page.mail;

const getLabel = (id: string, action: string): string => {
  if (id === NEW) {
    return 'front.mails-table.create-letter.label';
  }

  if (Number(id) && action === MailActions.Edit) {
    return 'front.mails-table.edit-letter.label';
  }

  if (Number(id) && action === MailActions.View) {
    return 'front.mails-table.letter.label';
  }
};

const MAX_LENGTH_DESC = 500;

const MailForm = () => {
  const {
    params: { id, action },
  } = useRouteMatch<{ action: string; id: string }>();

  const setAttachmentToLetterData = (file: FileInterface, data: FormData): void => {
    const fileId = file.id;

    if (fileId) {
      data.append(MailFieldsEnum.MessageAttachmentIds, fileId);
    } else {
      data.append(MailFieldsEnum.Files, file.value);
    }
  };

  const { setDisabled, progress, error, disabled, handleSubmit, getFieldValue } =
    useForm<LetterFields>(async ({ setFields }) => {
      setDisabled(action === MailActions.View);
      if (Number(id)) {
        const { subject, desc, messageFiles, companyId, isFromCustomer, documentStatus } =
          await api.mail.getLetter(id);

        setFields({
          subject,
          companyId,
          desc,
          isFromCustomer,
          files: (messageFiles || []).map(item => ({
            id: item.id,
            field: item.name,
            value: {
              name: item.name,
              type: item.contentType,
            },
            downloadAction: () => {
              api.mail.downloadLetterFile(id, item.id);
            },
          })),
        });
        readLetter(documentStatus, isFromCustomer);
      }
    });

  const readLetter = (documentStatus: string, isFromCustomer: boolean): void => {
    documentStatus === SENT && !isFromCustomer ? api.mail.readRequiredLetter(+id) : null;
  };

  const isFromCustomer = getFieldValue(MailFieldsEnum.IsFromCustomer);
  const defaultFileExtensions = useFileExtensions();
  const isIncomingLetters = isExist(isFromCustomer) && !isFromCustomer;
  const fileExtensions = isIncomingLetters ? { extensions: [] } : defaultFileExtensions;

  const onSave = async (data: LetterFields, isSave?: boolean) => {
    const isNew = id === NEW;
    const { files, ...rest } = data;

    const fields = {
      ...rest,
      documentStatus: isSave ? DRAFT : SENT,
    };

    const formData = createFormDataFromObj(fields);

    if (!isNew) {
      formData.append(MailFieldsEnum.Id, id);
    }

    if (files) {
      files.forEach(file => setAttachmentToLetterData(file, formData));
    }

    if (isNew) {
      await api.mail.createLetter(formData);
    } else {
      await api.mail.updateLetter(formData);
    }

    goto(pages.messages);
  };

  const isView = action === MailActions.View;

  return (
    <Page title={translate(getLabel(id, action))}>
      <DefaultForm>
        <FormError>{error}</FormError>
        <CustomerSelect
          name={MailFieldsEnum.CompanyId}
          label="front.mail-modal.organization.label"
          disabled={id !== NEW}
          selectDefaultCustomer
          required
        />
        <TextField
          name={MailFieldsEnum.Subject}
          label="front.mail-modal.subject.label"
          normalize={normalize}
          maxLength={255}
          required
        />
        {isView ? (
          <HtmlParserField
            name={MailFieldsEnum.Desc}
            label="front.mail-modal.description.label"
            transform={transformForMail}
          />
        ) : (
          <TextAreaField
            name={MailFieldsEnum.Desc}
            label="front.mail-modal.description.label"
            rows={3}
            minLength={7}
            maxLength={MAX_LENGTH_DESC}
            hasAutoGrowing
            hasCounter
            required
          />
        )}
        <FileField
          name={MailFieldsEnum.Files}
          label="front.mail-modal.attach-files.label"
          accept={fileExtensions}
          outputFormat={FileType.FILE}
          multiple
        />
        {!disabled && (
          <>
            <Button
              color="secondary"
              onClick={e => handleSubmit(formData => onSave(formData, true), e)}
              progress={progress}
              type="submit"
              size="sm"
            >
              {translate('front.mail-modal.save-button.label')}
            </Button>
            <Button
              color="primary"
              onClick={e => handleSubmit(formData => onSave(formData, false), e)}
              progress={progress}
              type="submit"
              size="sm"
            >
              {translate('front.mail-modal.send-button.label')}
            </Button>
          </>
        )}
      </DefaultForm>
    </Page>
  );
};

export const MailPage = withForm(MailForm);
