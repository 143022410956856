import React from 'react';

import config from 'config';
import { translate } from 'i18n/translator';

const { logoutIcon } = config.page.dashboard;

interface LogoutButtonProps {
  onLogout: () => void;
}

export const LogoutButton: React.FC<LogoutButtonProps> = ({ onLogout }) => (
  <div onClick={onLogout}>
    <img src={logoutIcon} alt="logo" />
    <span style={{ marginLeft: '10px' }}>{translate('front.header.logout.label')}</span>
  </div>
);
