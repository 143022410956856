export enum DocumentManagementStateEnum {
  DraftDraft = 'Draft:Draft',
  OnSigningOnSigning = 'OnSigning:OnSigning',
  NeedSigningPartiallySigned = 'NeedSigning:PartiallySigned',
  NeedSigningSigned = 'NeedSigning:Signed',
  NeedSigningNotRequiredSign = 'NeedSigning:NotRequiredSign',
  PartiallySignedNeedSigning = 'PartiallySigned:NeedSigning',
  PartiallySignedPartiallySigned = 'PartiallySigned:PartiallySigned',
  PartiallySignedSigned = 'PartiallySigned:Signed',
  SignedSigned = 'Signed:Signed',
  RejectedRejected = 'Rejected:Rejected',
}
