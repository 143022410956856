import React from 'react';

import { DemandType } from 'api/DemandsService/enums';
import { OrderActionType } from 'api/enums';
import { useOrder } from 'components/Document/useOrder';
import { withOrder } from 'components/Document/withOrder';
import { NavTab, NavTabs } from 'components/tabs/NavTabs';
import { translate } from 'i18n/translator';
import { allowDemandAction } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { AuthLetterDetails } from 'pages/Demands/AuthLetterDemand/AuthLetterDetails';
import { DemandHistoryPage } from 'pages/Demands/DemandHistoryPage';
import { createOnSave, fetchOrder } from 'pages/Demands/utils';
import { PaymentOrderFields as Fields } from 'pages/Payments/InternalPayment/interface';

const goBack = () => goto(pages.partialDemands.type(DemandType.AuthLetter));

const AuthLetterComponent: React.FC = () => {
  const { order } = useOrder<Fields>();

  return (
    <NavTabs>
      <NavTab
        title={translate('front.demand-detail.details.label')}
        path={pages.partialDemands.tabs.details(DemandType.AuthLetter, ':id')}
      >
        <AuthLetterDetails />
      </NavTab>
      {!!order?.state && (
        <NavTab
          title={translate('front.demand-detail.history.label')}
          path={pages.partialDemands.tabs.history(DemandType.AuthLetter, ':id')}
        >
          <DemandHistoryPage />
        </NavTab>
      )}
    </NavTabs>
  );
};
export const AuthLetterPage = withOrder(({ order }) => ({
  createOnSave,
  fetchOrder,
  allowState: true,
  allowDetails: true,
  allowSave:
    order && allowDemandAction(order.type as DemandType, OrderActionType.EDIT, +order.customerId),
  allowSign:
    order && allowDemandAction(order.type as DemandType, OrderActionType.SIGN, +order.customerId),
  disableButtons: true,
  afterSubmit: goBack,
}))(AuthLetterComponent);
