import React from 'react';

import { Devices } from 'enums/DevicesEnum';

import { DataFetcher, Props as DataFetcherProps } from 'components/DataFetcher/DataFetcher';
import { useDataFetcher } from 'components/DataFetcher/useDataFetcher';
import { FormError } from 'components/forms/FormError';
import { Page, Props as PageProps } from 'components/layout/Page/Page';
import { LinearProgress } from 'components/LinearProgress/LinearProgress';
import { bem } from 'components/utils/bem';
import { withDeviceResolver } from 'components/utils/withResizeObserver';

import { PagePagination } from './PagePagination';

import './styles.scss';

interface PageFetcher extends PageProps, DataFetcherProps {}

type Props = Omit<PageFetcher, 'breakpoint'>;

const Content: React.FC<any> = ({ children, pageProps }) => {
  const { isLoading, error } = useDataFetcher();

  return (
    <Page {...pageProps}>
      <div className={bem('content-wrapper').modificator('isLoading', isLoading).toClassName()}>
        <FormError>{error}</FormError>
        <LinearProgress isLoading={isLoading} />
        {children}
        <PagePagination />
      </div>
    </Page>
  );
};

export const PageFetcher: React.FC<Props> = withDeviceResolver(
  ({ fetch, hidePagination, children, breakpoint = Devices.Desktop, ...pageProps }) => {
    return (
      <DataFetcher fetch={fetch} hidePagination={hidePagination} breakpoint={breakpoint}>
        <Content children={children} pageProps={pageProps} />
      </DataFetcher>
    );
  },
);
