export enum UserKeystoreType {
  FileStorage = 'fs',
  HardWired = 'hw',
  CloudStorage = 'cs',
}

export enum CertificateType {
  Kep = 'KEP',
  Uep = 'UEP',
  Seal = 'SEAL',
}

export enum KeyType {
  TOKEN = 'token',
  FILE = 'file',
}

export enum CertificateStatus {
  Active = 'ACTIVE',
  Certified = 'CERTIFIED',
  NotValid = 'NOT_VALID',
  New = 'NEW',
  Processing = 'PROCESSING',
  Rejected = 'REJECTED',
  Unknown = 'UNKNOWN',
  Blocked = 'BLOCKED',
}
