import React from 'react';

import { api } from 'api';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { PasswordField } from 'components/forms/inputs/PasswordField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { PasswordHint } from 'components/PasswordHint/PasswordHint';
import { validateMessages } from 'components/validateMessages';
import { useUserCredentialsValidate } from 'hooks/useUserCredentialsValidate';
import { translate } from 'i18n/translator';
import { useLoginContext } from 'pages/Login/useLoginContext';

import { EncryptCredentials } from '../EncryptCredentials';

interface Fields {
  confirmPassword: string;
  password: string;
}

const ChangePassword = () => {
  const { progress, error, getFormData, handleSubmit } = useForm<Fields>();

  const { validatePassword, minLengthPassword, maxLengthPassword } = useUserCredentialsValidate();

  const { password: newPassword, confirmPassword } = getFormData();

  const { currentFactor, goToFirstStep } = useLoginContext();

  const onChangePassword = async (data: Fields) => {
    const { login, password } = currentFactor.payload;
    const encryptedData = await EncryptCredentials(login, password, data.password);
    await api.auth.login(encryptedData);
    goToFirstStep();
  };

  const validateConfirmPassword = React.useCallback(
    confirmPasswordField => {
      if (newPassword !== confirmPasswordField) {
        return validateMessages.passwordsEqual;
      }
      return '';
    },
    [newPassword, confirmPassword],
  );

  return (
    <>
      <div className="login-page__body__box__title">
        <span>{translate('front.set-password-form.title.label')}</span>
      </div>

      <DefaultForm className="login-page__form" disabled={progress}>
        <FormError className="login-page__form__server-error">{error}</FormError>
        <div className="login-page__form__field">
          <PasswordField
            label="front.set-password-form.new-password.label"
            name="password"
            minLength={minLengthPassword}
            maxLength={maxLengthPassword}
            validate={validatePassword}
            absoluteTooltip={<PasswordHint />}
            isLargeInput
            required
          />
        </div>
        <div className="login-page__form__field last">
          <PasswordField
            label="front.set-password-form.confirm-password.label"
            name="confirmPassword"
            validate={validateConfirmPassword}
            isLargeInput
            required
          />
        </div>
        <Button
          color="primary"
          onClick={e => handleSubmit(onChangePassword, e)}
          progress={progress}
          className="login-page__form__btn"
          type="submit"
          size="lg"
          label="front.login-form.set-password-button.label"
        />
      </DefaultForm>
    </>
  );
};

export default withForm(ChangePassword);
