import React from 'react';

import { translate } from 'i18n/translator';

export const LINK_REGEXP = /http.*/;

export const hasLink = (label: string): boolean => LINK_REGEXP.test(label);

export const getLabelLink = (label: string, links: string[] = [], linkLabels: string[] = []) => {
  if (linkLabels.length) {
    return linkLabels.reduce(
      (acc, linkLabel, index) =>
        acc.replace(
          translate(linkLabel),
          `<a href="${links[index]}" target="_blank">${translate(linkLabel)}</a>`,
        ),
      label,
    );
  }

  const [url] = LINK_REGEXP.exec(label);

  return label.replace(url, `<a href="${url}" target="_blank">${url}</a>`);
};

export const RenderLabelWithLink = (label: string, link?: string[], linkLabel?: string[]) => {
  const translatedLabel = translate(label);

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: getLabelLink(translatedLabel, link, linkLabel),
      }}
    />
  );
};
