export enum DepositDetailNames {
  Customer = 'customer',
  Deposit = 'deposit',
  CloseDate = 'closeDate',
  CloseReason = 'closeReason',
  AcceptConditions = 'acceptConditions',
  DoChangeMainAcc = 'doChangeMainAcc',
  RadioMainAcc = 'radioMainAcc',
  ExternalMainNationalAccount = 'externalMainNationalAccount',
  ExternalMainAccCorrBank = 'externalMainAccCorrBank',
  ExternalMainAccCorrSwiftCode = 'externalMainAccCorrSwiftCode',
  ExternalMainAccBenefBank = 'externalMainAccBenefBank',
  ExternalMainAccCode = 'externalMainAccCode',
  ExternalMainAccNumber = 'externalMainAccNumber',
  ExternalMainAccName = 'externalMainAccName',
  ExternalMainAccAddress = 'externalMainAccAddress',
  OwnMainAccount = 'ownMainAccount',
  DoChangeFeeAcc = 'doChangeFeeAcc',
  RadioFeeAcc = 'radioFeeAcc',
  ExternalFeeNationalAccount = 'externalFeeNationalAccount',
  ExternalFeeAccCorrBank = 'externalFeeAccCorrBank',
  ExternalFeeAccCorrSwiftCode = 'externalFeeAccCorrSwiftCode',
  ExternalFeeAccBenefBank = 'externalFeeAccBenefBank',
  ExternalFeeAccCode = 'externalFeeAccCode',
  ExternalFeeAccNumber = 'externalFeeAccNumber',
  ExternalFeeAccName = 'externalFeeAccName',
  ExternalFeeAccAddress = 'externalFeeAccAddress',
  OwnFeeAccount = 'ownFeeAccount',
  DoChangeTerm = 'doChangeTerm',
  NewTerm = 'newTerm',
  DoChangeAmount = 'doChangeAmount',
  NewAmout = 'newAmout',
  ExternalSwiftMainAccNumber = 'externalSwiftMainAccNumber',
  ExternalSwiftMainAccCorrBank = 'externalSwiftMainAccCorrBank',
  ExternalSwiftMainAccCorrSwiftCode = 'externalSwiftMainAccCorrSwiftCode',
  ExternalSwiftMainAccBenefBank = 'externalSwiftMainAccBenefBank',
  ExternalSwiftMainAccCode = 'externalSwiftMainAccCode',
  ExternalSwiftMainAccName = 'externalSwiftMainAccName',
  ExternalSwiftMainAccAddress = 'externalSwiftMainAccAddress',

  ToContract = 'toContract',
  AmountTranche = 'amountTranche',
  Currency = 'currency',
  RetentionPeriodFrom = 'retentionPeriodFrom',
  RetentionPeriodTo = 'retentionPeriodTo',
  PeriodInterestPayment = 'periodInterestPayment',
  SelectDetails = 'label4',
  RadioPlacementAccTranche = 'radioPlacementAccTranche',
  PlacementOwnIbanTranche = 'placementOwnIbanTranche',
  PlacementOwnAccountTranche = 'placementOwnAccountTranche',
  DetailsForInterestPayment = 'label5',
  RadioInterestAccTranche = 'radioInterestAccTranche',
  InterestOwnIbanTranche = 'interestOwnIbanTranche',
  InterestOwnAccountTranche = 'interestOwnAccountTranche',
  ExternalInterestSwiftAccCorrBank = 'externalInterestSwiftAccCorrBank',
  ExternalInterestSwiftAccCorrSwiftCode = 'externalInterestSwiftAccCorrSwiftCode',
  ExternalInterestSwiftAccBenefBank = 'externalInterestSwiftAccBenefBank',
  ExternalInterestSwiftAccCode = 'externalInterestSwiftAccCode',
  ExternalInterestSwiftAccNumber = 'externalInterestSwiftAccNumber',
  ExternalAmountSwiftAccName = 'externalAmountSwiftAccName',
  ExternalAmountSwiftAccAddress = 'externalAmountSwiftAccAddress',
  SelectDepositAmount = 'label6',
  RadioAmountAccTranche = 'radioAmountAccTranche',
  DepositAgreements = 'depositAgreements',
  DoCommission = 'doCommission',
  FormOfContract = 'label10',
  FormContractRadio = 'formContractRadio',
  AmountOwnAccountTranche = 'amountOwnAccountTranche',
  AmountOwnIbanTranche = 'amountOwnIbanTranche',
  ExternalAmountSwiftAccCorrBank = 'externalAmountSwiftAccCorrBank',
  ExternalAmountSwiftAccCorrSwiftCode = 'externalAmountSwiftAccCorrSwiftCode',
  ExternalAmountSwiftAccBenefBank = 'externalAmountSwiftAccBenefBank',
  ExternalAmountSwiftAccCode = 'externalAmountSwiftAccCode',
  ExternalAmountSwiftAccNumber = 'externalAmountSwiftAccNumber',
  ExternalInterestSwiftAccName = 'externalInterestSwiftAccName',
  ExternalInterestSwiftAccAddress = 'externalInterestSwiftAccAddress',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  CheckedPaymentDepositAmount = 'label5',

  DepositType = 'depositType',
  DepositCurrency = 'depositCurrency',
  Amount = 'amount',
  CheckPlaceFundsDeposit = 'label3',
  RadioPlacementAccTerm = 'radioPlacementAccTerm',
  DoInternalPlacementAccTerm = 'doInternalPlacementAccTerm',
  PlacementOwnAccountTerm = 'placementOwnAccountTerm',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  CheckAccountPaymentInterest = 'label4',
  RadioInterestAccTerm = 'radioInterestAccTerm',
  InterestOwnAccountTerm = 'interestOwnAccountTerm',
  InterestOwnIbanTerm = 'interestOwnIbanTerm',
  RadioAmountAccTerm = 'radioAmountAccTerm',
  AmountOwnAccountTerm = 'amountOwnAccountTerm',
  AmountOwnIbanTerm = 'amountOwnIbanTerm',
  DoSelectedContribution = 'doSelectedContribution',
  DoConditions = 'doConditions',
  DoUnderstanding = 'doUnderstanding',
  DoAcceptance = 'doAcceptance',
  DoReliableInfo = 'doReliableInfo',
  DoInformationLaw = 'doInformationLaw',
  DoSend = 'doSend',
  ChannelTypeSend = 'channelTypeSend',
  ChannelEmail = 'channelEmail',

  DoInformation = 'doInformation',
  DoAccommodationContribution = 'doAccommodationContribution',
}

export enum ChangingDepositRadioMainAccFieldsNames {
  DoExternalMainAcc = 'doExternalMainAcc',
  DoExternalSwiftMainAcc = 'doExternalSwiftMainAcc',
  DoOwnMainAcc = 'doOwnMainAcc',
}

export enum ChangingDepositRadioFeeAccFieldsNames {
  DoExternalFeeAcc = 'doExternalFeeAcc',
  DoExternalSwiftFeeAcc = 'doExternalSwiftFeeAcc',
  DoOwnFeeAcc = 'doOwnFeeAcc',
}

export enum RadioPlacementAccTrancheFieldsNames {
  DoExternalPlacementAccTranche = 'doExternalPlacementAccTranche',
  DoInternalPlacementAccTranche = 'doInternalPlacementAccTranche',
}

export enum RadioInterestAccTrancheFieldsName {
  DoExternalInterestAccTranche = 'doExternalInterestAccTranche',
  DoExternalSwiftInterestAccTranche = 'doExternalSwiftInterestAccTranche',
  DoInternalInterestAccTranche = 'doInternalInterestAccTranche',
}

export enum RadioAmountAccTrancheFieldsName {
  DoExternalAmountAccTranche = 'doExternalAmountAccTranche',
  DoExternalAmountSwiftAccTranche = 'doExternalAmountSwiftAccTranche',
  DoInternalAmountAccTranche = 'doInternalAmountAccTranche',
}

export enum RadioPlacementAccTermFieldNames {
  DoExternalPlacementAccTerm = 'doExternalPlacementAccTerm',
  DoInternalPlacementAccTerm = 'doInternalPlacementAccTerm',
}

export enum RadioInterestAccTermFieldNames {
  DoExternalInterestAccTerm = 'doExternalInterestAccTerm',
  DoExternalInterestSwiftAccTerm = 'doExternalInterestSwiftAccTerm',
  DoInternalInterestAccTerm = 'doInternalInterestAccTerm',
}

export enum RadioAmountAccTermFieldNames {
  DoExternalAmountAccTerm = 'doExternalAmountAccTerm',
  DoExternalAmountSwiftAccTerm = 'doExternalAmountSwiftAccTerm',
  DoInternalAmountAccTerm = 'doInternalAmountAccTerm',
}
