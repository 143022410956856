import * as React from 'react';

import { api } from 'api';
import {
  DocumentType,
  DocumentTypeOptions,
  InitRegistration,
  RegistrationSteps,
} from 'api/RegistrationService';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { PhoneField } from 'components/forms/inputs/PhoneField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextField } from 'components/forms/inputs/TextField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { translate } from 'i18n/translator';
import { RegistrationContext } from 'pages/Registration/RegistrationContext';

const validateIin = (inn: string) => {
  if (!(inn.length === 10))
    return translate('front.registration.init-registration-page.validate-taxcode.label');
};

const InitRegistrationPageForm: React.FC = () => {
  const { onInitRegister } = useInitRegistration();

  const onRegister = async (formData: InitRegistration) => {
    await onInitRegister(formData);
  };

  const { progress, error, handleSubmit, getFieldValue } = useForm<InitRegistration>();

  const docType = getFieldValue('documentType') as DocumentType;

  return (
    <div className="">
      <div className="login-page__body__box card">
        <div className="card-body">
          <div className="login-page__body__box__title">
            {translate('front.registration.init-registration-page.title.label')}
          </div>
          <DefaultForm>
            <FormError>{error}</FormError>
            <AmountField
              label="front.registration.init-registration-page.field-tax-code.label"
              name="taxCode"
              maskConfig={{ prefix: '' }}
              validate={validateIin}
              maxLength={10}
              required
            />
            <PhoneField
              label="front.registration.init-registration-page.field-phone.label"
              name="phone"
              maskConfig={{ prefix: '' }}
              required
            />
            <SelectField
              label="front.registration.init-registration-page.field-document-type.label"
              name="documentType"
              options={DocumentTypeOptions}
              required
            />
            {(docType === DocumentType.PermanentResidency || docType === DocumentType.Passport) && (
              <TextField
                label="front.registration.init-registration-page.field-document-series.label"
                name="documentSeries"
                defaultValue=""
                maxLength={2}
                upper
              />
            )}
            <AmountField
              label="front.registration.init-registration-page.field-document-number.label"
              name="documentNumber"
              maskConfig={{ prefix: '' }}
              maxLength={
                docType === DocumentType.PermanentResidency || docType === DocumentType.Passport
                  ? 6
                  : 9
              }
              required
            />
            <CheckboxField
              label="front.registration.init-registration-page.field-is-allow-personal-data.label"
              name="isAllowPersonalData"
              required
            />
            <Button
              color="primary"
              onClick={e => handleSubmit(onRegister, e)}
              progress={progress}
              className="login-page__form__btn"
              type="submit"
              size="lg"
            >
              {translate('front.registration.init-registration-page.button-continue.label')}
            </Button>
          </DefaultForm>
        </div>
      </div>
    </div>
  );
};

export const InitRegistrationPage = withForm(InitRegistrationPageForm);

const useInitRegistration = () => {
  const { setOrderId, setUuid, setStep, setPhoneNumber } = React.useContext(RegistrationContext);

  const onInitRegister = async (formData: InitRegistration) => {
    const { orderId, uuid } = await api.registration.startRegistration(formData);

    setOrderId(orderId);
    setUuid(uuid);
    setPhoneNumber(formData.phone);
    setStep({ orderState: RegistrationSteps.SMS });
  };

  return { onInitRegister };
};
