import moment from 'moment';

import { Customer, EndDateAction } from 'api/UserService';

const ONE_DAY = 1;

export const getEndDateCustomers = (customers: Array<Customer>, daysToExpire: number) => {
  const today = moment();

  const [deniedAuthCustomers, deniedSignCustomers] = customers.reduce(
    (acc: Array<Array<Customer>>, customer) => {
      const [deniedSignCustomers, deniedAuthCustomers] = acc;
      const endDateDifferedFromToday = moment(customer.endDate).diff(today, 'days');
      const isExpiringDate =
        daysToExpire - ONE_DAY > endDateDifferedFromToday && endDateDifferedFromToday >= 0;

      if (customer.endDateAction === EndDateAction.BLOCK_SIGN && isExpiringDate) {
        return [[...deniedSignCustomers, customer], deniedAuthCustomers];
      }
      if (customer.endDateAction === EndDateAction.BLOCK_AUTH && isExpiringDate) {
        return [deniedSignCustomers, [...deniedAuthCustomers, customer]];
      }

      return acc;
    },
    [[], []],
  );
  return [deniedAuthCustomers, deniedSignCustomers];
};
