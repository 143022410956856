import * as React from 'react';

import { OrderFields } from 'api/interfaces';
import { Import } from 'components/icons';
import { PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import { getAmountNumberFormatWithComma } from 'components/utils/format';
import config from 'config';
import { translate } from 'i18n/translator';

const { nationalCurrency } = config.bank;

interface Props {
  orders: OrderFields[];
}

interface ReducedByIsoCode {
  count: number;
  isoCode: string;
  totalAmount: number;
}

const getReducedByIsoCode = (orders: OrderFields[]) =>
  orders?.reduce((reducedByIsoCode: ReducedByIsoCode[], order: OrderFields) => {
    const isoCodeIndex = reducedByIsoCode.findIndex(({ isoCode }) => isoCode === order.isoCode);

    if (isoCodeIndex !== -1) {
      const reducedIsoCode = reducedByIsoCode[isoCodeIndex];
      reducedByIsoCode[isoCodeIndex] = {
        totalAmount: reducedIsoCode.totalAmount + (order.amount as number),
        count: reducedIsoCode.count + 1,
        isoCode: order.isoCode,
      };

      return reducedByIsoCode;
    }

    return [
      ...reducedByIsoCode,
      {
        totalAmount: order.amount as number,
        count: 1,
        isoCode: order.isoCode,
      },
    ];
  }, []);

export const PaymentImportResultModal: React.FC<PropsWithConfirmModal<Props>> = ({ orders }) => {
  const reducedByIsoCode = getReducedByIsoCode(orders);

  const totalLabels = reducedByIsoCode
    .sort((_, b) => {
      if (b.isoCode === nationalCurrency) {
        return 1;
      }
      return -1;
    })
    .map(({ count, totalAmount, isoCode }: ReducedByIsoCode) => (
      <li key={isoCode}>
        {translate('front.import-modal-total-info-domestic.label')
          .replace('{count}', `${count}`)
          .replace('{amount}', `${getAmountNumberFormatWithComma(totalAmount)}`)
          .replace('{currency}', `${isoCode}`)}
      </li>
    ));

  return (
    <div>
      <div className="import-modal-header">
        <Import />
        <div className="import-modal-title">{translate('front.import-modal-title.label')}</div>
      </div>
      <ul className="import-modal-info">{totalLabels}</ul>
    </div>
  );
};
