import { api } from 'api';
import { GridResponse } from 'api/Service';
import { Customer, CustomerInfo, DebtUserInterface } from 'api/UserService';
import { ModalView } from 'components/modals/globalModalCollection/GlobalModalCollection';
import { ModalEnum } from 'components/modals/globalModalCollection/modalEnum';
import { Privileges } from 'navigations/privileges';
import { DebtPage } from 'pages/Login/CustomerSelectModal/DebtModal/DebtModal';
import { FactorType } from 'pages/Login/LoginPage';
import { toUniqueArray } from 'pages/utils/utils';
import { UserState } from 'store/reducers/user';

export const getUniquePrivileges = (customers: Customer[], chosenCustomerIds: number[]) =>
  toUniqueArray(
    customers.reduce((acc, { id, privileges }) => {
      if (chosenCustomerIds.includes(id)) {
        return [...acc, ...privileges];
      }
      return acc;
    }, []) as Privileges[],
  );

export const collectUserState = (
  customerInfo: CustomerInfo & { currentAuthFactor?: FactorType },
  chosenCustomerIds: number[],
): Partial<UserState> => ({
  privileges: getUniquePrivileges(customerInfo.customers, chosenCustomerIds),
  userFullName: `${customerInfo.userLastName} ${customerInfo.userFirstName} ${customerInfo.userMiddleName}`,
  userEmail: customerInfo.userEmail,
  currentAuthPerson: customerInfo.userId,
  avatar: customerInfo.userConfig.userConfigMap.avatar,
  customers: customerInfo.customers.map(customer => ({
    ...customer,
    isSelected: chosenCustomerIds.includes(customer.id),
  })),
});

export const checkDebtModal = async () => {
  const debtUser = await api.user.getDebtUser();

  if (debtUser.rows.length) {
    const debtModal = new ModalView<{ debtUser: GridResponse<DebtUserInterface> }>({
      Component: DebtPage,
      width: 900,
      id: ModalEnum.DebtModal,
      data: {
        debtUser,
      },
    });

    await debtModal.open();
  }
};
