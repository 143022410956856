import * as React from 'react';

import classnames from 'classnames';

import './Switch.scss';

interface SwitchProps {
  checked: boolean;
  className: string;
  labelText: string;
  onChange: (e?: any) => void;
  isGrey?: boolean;
}

export const Switch = ({
  checked,
  onChange,
  className,
  labelText,
  isGrey = false,
}: SwitchProps) => {
  return (
    <span className={className}>
      <input
        checked={checked}
        onChange={onChange}
        className={classnames('react-switch-checkbox', {
          'react-switch-checkbox--grey': isGrey,
        })}
        id="react-switch-input"
        type="checkbox"
      />
      <label className="react-switch-label" htmlFor="react-switch-input">
        <span className="react-switch-button" />
      </label>
      {labelText && (
        <label htmlFor="react-switch-input" className="react-switch-sub-label">
          {labelText}
        </label>
      )}
    </span>
  );
};
