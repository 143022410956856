import * as React from 'react';
import { TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { CardLimitsRequest, CardLimitView } from 'api/ProductsService';
import { SortOrder } from 'api/Service';
import { Page } from 'components/layout/Page/Page';
import { confirmationModal } from 'components/modals/ConfirmModal';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { getAmountNumberFormatWithComma } from 'components/utils/format';
import { translate } from 'i18n/translator';
import { isActionAllowed } from 'navigations/access';
import { Privileges } from 'navigations/privileges';

import { LimitFilter } from './LimitFilter';
import { LimitModal } from './LimitModal';

const limitAmount = (amount: number | string) =>
  amount === 9999999999999 ? translate('front.cards-page.no-limits.label') : amount;

const limitCount = (count: number) =>
  count === 999999999 ? translate('front.cards-page.no-limits.label') : count;

const fetchDocs = async (request: CardLimitsRequest) => {
  const limits = await api.products.getCardLimits({ ...request, size: 0 });
  return {
    rows: limits.map((item, index) => ({ ...item, id: index })),
    total: {
      count: limits.length,
    },
  };
};

const columns: TableColumnProps<CardLimitView>[] = [
  {
    label: translate('front.cards-page.cols-type.label'),
    render: row => <DataColumn title={row.code} />,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: translate('front.cards-page.cols-limitation.label'),
    render: row => <DataColumn title={row.limit.label} />,
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: translate('front.cards-page.cols-period.label'),
    render: row => <DataColumn title={row.period.label} />,
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: translate('front.cards-page.cols-max-sum.label'),
    render: row => {
      const formatAmount = getAmountNumberFormatWithComma(row.maxAmount);

      return <DataColumn title={limitAmount(formatAmount)} />;
    },
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: translate('front.cards-page.cols-min.label'),
    render: row => <DataColumn title={limitCount(row.maxCount)} />,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    renderMenu: row => [
      {
        label: translate('front.cards-page.cols-change.label'),
        onClick: async () => {
          const limit = await confirmModal(LimitModal, { limit: row });
          if (limit) {
            await confirmationModal(
              translate('front.internal-payment-page.saved-successfully.label'),
              true,
              false,
            );
          }
        },
        isVisible: isActionAllowed([Privileges.productCardLimitEdit], row.customerId),
        isReloadable: true,
      },
    ],
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

export const LimitsPage = () => (
  <Page title={translate('front.cards-page.limits.label')}>
    <DataTable
      name={TableNamesEnum.CardsLimit}
      columns={columns}
      fetchRows={fetchDocs}
      defaultSort="id"
      defaultOrder={SortOrder.Desc}
      filterComponent={LimitFilter}
      hidePagination
    />
  </Page>
);
