import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';

import { api } from 'api';
import { Locale } from 'api/LocaleService';
import config from 'config';
import { AppStore } from 'store';
import { LocaleActions } from 'store/actions/locale';
import { LocaleState } from 'store/reducers/locale';
import { selectLocaleState } from 'store/selectors';

const logo = config.page.login.desktopLogoPath;
const logoGreen = config.page.login.mobileLogoPath;
const earth = config.page.login.earth;

export const AuthLayout: React.FC = ({ children }) => {
  const { locales, activeLocale } = useSelector<AppStore, LocaleState>(selectLocaleState());
  const dispatch = useDispatch();

  const onLocalChange = async (locale: string) => {
    dispatch(LocaleActions.setActiveLocale(locale));
    window.location.reload();
  };

  const fetchLocales = async (): Promise<void> => {
    try {
      const locales = await api.locale.getLocales();
      const languages = Object.keys(locales).map(local => ({
        value: local,
        label: locales[local as keyof Locale],
      }));

      dispatch(LocaleActions.setLocales(languages));
      dispatch(
        LocaleActions.setActiveLocale(
          locales[activeLocale as keyof Locale] ? activeLocale : config.i18n.defaultLanguageCode,
        ),
      );
    } catch (e) {
      return null;
    }
  };

  React.useEffect(() => {
    fetchLocales();
  }, []);

  return (
    <div className="login-page">
      <header className="login-page__header">
        <img src={logo} alt="app" className="img-fluid login-page__header_logo white" />
        <img src={logoGreen} alt="app" className="img-fluid login-page__header_logo green" />
        <div className="login-page__header__languages">
          {locales.map(language => (
            <div
              className={classNames('login-page__header__languages_item', {
                active: language.value === activeLocale,
              })}
              key={language.value}
              onClick={() => onLocalChange(language.value)}
            >
              {language.label}
            </div>
          ))}
        </div>
      </header>

      {children}

      <div className="login-page__earth-wrap">
        <img src={earth} alt="Earth" className="login-page__earth" />
      </div>
    </div>
  );
};
