import React from 'react';

import { isValidIBAN } from 'ibantools';

import { VatType } from 'api/enums';
import { SubscriptionCb } from 'components/forms/ValidatingForm/FormContext';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { DomesticTransferDetailNames } from 'pages/Payments/InternalPayment/enums';
import { getBicAndBankNameFromAccount } from 'pages/Payments/InternalPayment/hooks/utils';
import { Payload as InternalPaymentPagePayload } from 'pages/Payments/InternalPayment/interface';

export const useDomesticTransferSubscribe = (budgetCodes: string[]) => {
  const { subscribe, updateData, unsubscribe, payload, updateFieldInData } = useForm<
    any,
    InternalPaymentPagePayload
  >();

  const { localBanksOptions = [] } = payload;

  const onPayerAccountSelect: SubscriptionCb<any, InternalPaymentPagePayload> = (
    { value },
    { setPayload },
  ) => {
    if (!value) {
      return setPayload(state => ({ ...state, isoCode: null }));
    }

    setPayload(state => {
      const accountOption = state.payerAccountOptions.find(option => option.value === value);

      return { ...state, isoCode: accountOption.content.currency };
    });
  };

  const onReceiverNameChange: SubscriptionCb<any, InternalPaymentPagePayload> = (
    { value, payload },
    { setPayload },
  ) => {
    const bank = payload.receiverOptions.find(o => o.label === value);

    if (!bank?.content) {
      setPayload({
        selectedCounterparty: null,
        receiverAccountOptions: [],
      });

      return;
    }

    const {
      content: { counterparty, accounts },
    } = bank;

    const account = accounts[0];

    if (budgetCodes.includes(account.bankCode)) {
      updateData({ [DomesticTransferDetailNames.VatType]: VatType.TAXES_PAYMENT });
    }

    updateData({
      receiverTaxCode: counterparty.bin,
      receiverAccount: accounts.length === 1 ? account.iban || '' : '',
      receiverBic: accounts.length === 1 ? account.bankCode || '' : '',
    });

    setPayload({
      receiverAccountOptions: accounts.map(item => ({
        value: item.iban || item.number,
        label: item.iban,
        content: item,
      })),

      selectedCounterparty: bank.content,
    });
  };

  const onReceiverAccountChange: SubscriptionCb<any, InternalPaymentPagePayload> = ({ value }) => {
    const accountNumber = value as string;

    if (isValidIBAN(accountNumber)) {
      const { receiverBic, receiverBankName } = getBicAndBankNameFromAccount(
        accountNumber,
        localBanksOptions,
      );

      if (budgetCodes.includes(receiverBic)) {
        updateData({ [DomesticTransferDetailNames.VatType]: VatType.TAXES_PAYMENT });
      }

      return updateData({
        receiverBic: receiverBic,
        receiverBankName: receiverBankName,
      });
    }

    updateData({
      receiverBic: '',
      receiverBankName: '',
      isBudgetBic: false,
    });

    updateFieldInData(DomesticTransferDetailNames.VatType, 'error', null);
  };

  React.useEffect(() => {
    subscribe(DomesticTransferDetailNames.PayerAccount, onPayerAccountSelect as SubscriptionCb);
    subscribe(DomesticTransferDetailNames.ReceiverName, onReceiverNameChange as SubscriptionCb);
    subscribe(
      DomesticTransferDetailNames.ReceiverAccount,
      onReceiverAccountChange as SubscriptionCb,
    );

    return () => {
      unsubscribe(DomesticTransferDetailNames.PayerAccount);
      unsubscribe(DomesticTransferDetailNames.ReceiverName);
      unsubscribe(DomesticTransferDetailNames.ReceiverAccount);
    };
  }, []);
};
