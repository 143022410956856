import * as React from 'react';
import { Col, Row } from 'react-grid';
import { useLocation } from 'react-router';

import { isValidBIC, isValidIBAN } from 'ibantools';

import { api } from 'api';
import { parseUrlParams } from 'api/backend';
import { OrderState } from 'api/enums';
import { CounterpartyAccount, CounterpartyInfo } from 'api/HandbooksService';
import { BankSwift } from 'api/interfaces';
import { Option, SortOrder } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { HintField } from 'components/forms/inputs/HintField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextAreaField } from 'components/forms/inputs/TextAreaField';
import { TextField } from 'components/forms/inputs/TextField';
import config from 'config';
import { translate } from 'i18n/translator';
import { ForeignPaymentFields } from 'pages/Payments/ForeignPayment/ForeignPaymentPage';
import { isExistOrder, isString } from 'utils/isData';

const smallChar = /[a-z]./g;
const ACCOUNT_MAX_LENGTH = 50;

const INITIAL_RECEIVER_BANK: InitialReceiverBank = {
  receiverBankCorrAccount: null,
  receiverBankName: null,
  receiverBankAddress: null,
  receiverBankCountryId: null,
  receiverCity: null,
  receiverId: null,
};

interface InitialReceiverBank {
  receiverBankAddress: string;
  receiverBankCorrAccount: string;
  receiverBankCountryId: number;
  receiverBankName: string;
  receiverCity: string;
  receiverId: number;
}

const INITIAL_INTERMEDIARY_BANK: InitialIntermediaryBank = {
  intermediaryCorrAccount: null,
  intermediaryBankName: null,
  intermediaryBankAddress: null,
  intermediaryCountryId: null,
  intermediaryCity: null,
  intermediaryId: null,
};

interface InitialIntermediaryBank {
  intermediaryBankAddress: string;
  intermediaryBankName: string;
  intermediaryCity: string;
  intermediaryCorrAccount: string;
  intermediaryCountryId: number;
  intermediaryId: number;
}

const validateReceiverBic = (value: string) => {
  if (!smallChar.test(value) && isValidBIC(value)) {
    return '';
  }
  return translate('front.foreign-transfer.error-bic.label');
};

const validateReceiverBankCorrAccount = (value: string) => {
  if (isValidIBAN(value)) {
    return '';
  }
  return translate('front.foreign-transfer.error-corr-account.label');
};

const validateReceiverAccount = (value: string) => {
  if (isValidIBAN(value)) {
    return '';
  }
  return translate('front.form.account-number.error');
};

const validateIntermediaryCorrAccount = (value: string) => {
  if (isValidIBAN(value)) {
    return '';
  }
  return translate('front.foreign-transfer.error-corr-account.label');
};

const onFetchBicHints = async (
  value: string,
  setContents: React.Dispatch<React.SetStateAction<BankSwift[]>>,
) => {
  if (value.length < 7) return [];

  const banks = await api.payments.getInternationalBanksOption({
    search: value,
    sort: 'international_bic',
    order: SortOrder.Desc,
  });

  setContents(banks.map(bank => bank.content));
  return banks;
};

const RUB = 'RUB';
const JPY = 'JPY';

export const ForeignPaymentDetails = () => {
  const { order, form, payload, setPayload, onChangeCustomer } =
    useOrderDetails<ForeignPaymentFields>();
  const { copyFrom, customerId } = parseUrlParams(useLocation().search);
  const {
    receiverAccount,
    receiverBankCorrAccount,
    intermediaryCorrAccount,
    isVerifyReceiverAccount,
    isVerifyReceiverBankCorrAccount,
    isVerifyIntermediaryCorrAccount,
  } = form.getFormData();

  const hasReceiverAccount = isString(receiverAccount);
  const hasReceiverBankCorrAccount = isString(receiverBankCorrAccount);
  const hasIntermediaryCorrAccount = isString(intermediaryCorrAccount);

  React.useEffect(() => {
    order && form.updateData({ ...order.detailFields });
  }, []);
  const isoCode = form.getFieldValue('isoCode') as string;

  const onChangePayerAccount = (value: string | string[], content: any) => {
    if (content) {
      form.updateData({ isoCode: content.currency });
    }
  };

  const onChangeReceiverAccount = (_: string, option: Partial<CounterpartyAccount>) => {
    if (option) {
      form.updateData({
        receiverBic: option.bankCode,
        receiverBankCorrAccount: option.corrBankAccount,
        receiverBankName: option.bankName,
        receiverBankAddress: option.bankAddress,
        receiverCountryId: option.bankCountryId,
      });
    }
  };

  const onChangeReceiver = (_: string, receiverOption: Option<CounterpartyInfo>) => {
    if (!receiverOption) return;

    const { accounts, counterparty } = receiverOption.content;
    const account = accounts?.length ? accounts[0] : null;
    const accountValue = account ? account.iban || account.number : '';

    form.updateData({
      receiverAccount: accountValue,
      receiverAddress: counterparty.address,
    });

    setPayload((state: ForeignPaymentFields) => ({
      ...state,
      selectedCounterparty: receiverOption.content,
      receiverAccountsOptions: accounts.map(item => ({
        value: item.iban || item.number,
        label: item.iban,
        content: item,
      })),
    }));
    if (account) {
      onChangeReceiverAccount(_, account);
    }
  };

  const onSelectReceiverBic = async (value: string, content: BankSwift) => {
    if (content) {
      const bank = await api.payments.getInternationalBank(content.id);
      form.updateData({
        receiverBankCorrAccount: bank.bankCode || '',
        receiverBankName: bank.bankName,
        receiverBankAddress: bank.address,
        receiverBankCountryId: bank.country.id,
        receiverCity: bank.city,
        receiverId: bank.id,
      });
    }

    if (!value) {
      form.updateData(INITIAL_RECEIVER_BANK);
    }
  };

  const onSelectIntermediaryBankCode = async (value: any, content: { id: number }) => {
    if (content) {
      const bank = await api.payments.getInternationalBank(content.id);
      form.updateData({
        intermediaryCorrAccount: bank.bankCode || '',
        intermediaryBankName: bank.bankName,
        intermediaryBankAddress: bank.address,
        intermediaryCountryId: bank.country.id,
        intermediaryCity: bank.city,
        intermediaryId: bank.id,
      });
    }

    if (!value) {
      form.updateData(INITIAL_INTERMEDIARY_BANK);
    }
  };

  const validateFeeAccount = React.useCallback(
    value => {
      if (!value) {
        return '';
      }

      const accountFee = payload.feeAccountsOptions.find((item: Option) => item.value === value);

      if (!accountFee) {
        return `${translate('front.form.another-account.error')}`;
      }

      return '';
    },
    [payload.feeAccountsOptions],
  );

  const normalizeReceiverName = React.useCallback(
    value => {
      const receiverOption = payload.receiverOptions.find((r: Option) => r.label === value);

      return receiverOption?.value || value;
    },
    [payload.receiverOptions],
  );

  const isoCodeNotRUB = isoCode !== RUB;
  const isoCodeNotJPY = isoCode !== JPY;

  const updateErrorField = (name: string, error: string) =>
    form.updateFieldInData(name, 'error', error);

  const hasValidateReceiverAccount = isoCodeNotJPY && hasReceiverAccount;

  const relevantValidateReceiverAccount =
    isVerifyReceiverAccount && hasValidateReceiverAccount ? validateReceiverAccount : undefined;

  const relevantValidateReceiverCorrAccount =
    isVerifyReceiverBankCorrAccount && hasReceiverBankCorrAccount
      ? validateReceiverBankCorrAccount
      : undefined;

  const relevantValidateIntermediaryCorrAccount =
    isVerifyIntermediaryCorrAccount && hasIntermediaryCorrAccount
      ? validateIntermediaryCorrAccount
      : undefined;

  React.useEffect(() => {
    if (hasReceiverAccount) {
      updateErrorField(
        'receiverAccount',
        relevantValidateReceiverAccount ? relevantValidateReceiverAccount(receiverAccount) : '',
      );
    }
  }, [isVerifyReceiverAccount]);

  React.useEffect(() => {
    if (hasReceiverBankCorrAccount) {
      updateErrorField(
        'receiverBankCorrAccount',
        relevantValidateReceiverCorrAccount
          ? relevantValidateReceiverCorrAccount(receiverBankCorrAccount)
          : '',
      );
    }
  }, [isVerifyReceiverBankCorrAccount]);

  React.useEffect(() => {
    if (hasIntermediaryCorrAccount) {
      updateErrorField(
        'intermediaryCorrAccount',
        relevantValidateIntermediaryCorrAccount
          ? relevantValidateIntermediaryCorrAccount(intermediaryCorrAccount)
          : '',
      );
    }
  }, [isVerifyIntermediaryCorrAccount]);

  return (
    <DefaultForm disabled={form.progress}>
      <h3>{translate('front.foreign-transfer.title-payer.label')}</h3>
      <CustomerSelect
        name="payerName"
        label="front.foreign-transfer.field-organization-name.label"
        onSelectOption={onChangeCustomer}
        disabled={!!order?.state || !!copyFrom || !!customerId}
        required
      />
      <SelectField
        name="payerAccount"
        label="front.foreign-transfer.field-payer-account.label"
        options={payload.payerAccountOptions}
        onSelectOption={onChangePayerAccount}
        required
        clearable
      />
      <br className="m-b-24" />
      <h3>{translate('front.foreign-transfer.title-receiver.label')}</h3>
      <HintField
        name="receiverName"
        label="front.foreign-transfer.field-organization-name.label"
        hints={payload.receiverOptions}
        onChange={onChangeReceiver}
        normalize={normalizeReceiverName}
        maxLength={70}
        required
      />
      <HintField
        name="receiverAccount"
        label="front.foreign-transfer.field-receiver-account.label"
        hints={payload.receiverAccountsOptions}
        onChange={onChangeReceiverAccount}
        validate={relevantValidateReceiverAccount}
        maxLength={ACCOUNT_MAX_LENGTH}
      />
      <CheckboxField
        label="front.internal-payment-page.check-validate-iban.label"
        name="isVerifyReceiverAccount"
      />
      <SelectField
        name="receiverCountryId"
        label="front.foreign-transfer.field-receiver-country.label"
        options={payload.countryOptions}
        required
      />
      <TextAreaField
        name="receiverAddress"
        label="front.foreign-transfer.field-receiver-address.label"
        rows={2}
        maxLength={70}
        required
      />
      {!form.disabled && (
        <CheckboxField
          name="saveReceiver"
          label="front.foreign-transfer.field-save-receiver.label"
        />
      )}
      <br className="m-b-24" />
      <h3>{translate('front.foreign-transfer.title-receiver-bank.label')}</h3>
      <HintField
        name="receiverBic"
        label="BIC"
        required={isoCodeNotRUB}
        onChange={onSelectReceiverBic}
        hints={onFetchBicHints}
        validate={isoCodeNotRUB ? validateReceiverBic : null}
        requiredSelectInDropDown
      />
      <TextField
        name="receiverBankCorrAccount"
        label="front.foreign-transfer.field-receiver-bank-corr-account.label"
        validate={relevantValidateReceiverCorrAccount}
        maxLength={ACCOUNT_MAX_LENGTH}
      />
      <CheckboxField
        label="front.internal-payment-page.check-validate-iban.label"
        name="isVerifyReceiverBankCorrAccount"
      />
      <TextField
        name="receiverBankName"
        label="front.foreign-transfer.field-receiver-bank-name.label"
        disabled={isoCodeNotRUB}
      />
      <TextAreaField
        name="receiverBankAddress"
        label="front.foreign-transfer.field-receiver-bank-address.label"
        required
        rows={2}
        maxLength={140}
      />
      <br className="m-b-24" />
      <h3>{translate('front.foreign-transfer.title-intermediary-bank.label')}</h3>
      <HintField
        name="intermediaryBankCode"
        label="BIC"
        hints={onFetchBicHints}
        onChange={onSelectIntermediaryBankCode}
        validate={isoCodeNotRUB ? validateReceiverBic : null}
        requiredSelectInDropDown
      />
      <TextField
        name="intermediaryCorrAccount"
        label="front.foreign-transfer.field-intermediary-bank-corr-account.label"
        validate={relevantValidateIntermediaryCorrAccount}
        maxLength={ACCOUNT_MAX_LENGTH}
      />
      <CheckboxField
        label="front.internal-payment-page.check-validate-iban.label"
        name="isVerifyIntermediaryCorrAccount"
      />
      <TextField
        name="intermediaryBankName"
        label="front.foreign-transfer.field-intermediary-bank-name.label"
        disabled={isoCodeNotRUB}
        onChange={val => val.replace(config.page.foreignPayment.forbiddenSwiftCharactersRegex, '')}
      />
      <TextAreaField
        name="intermediaryBankAddress"
        label="front.foreign-transfer.field-intermediary-bank-address.label"
        rows={2}
        maxLength={140}
      />
      <br className="m-b-24" />
      <h3>{translate('front.foreign-transfer.title-sum.label')}</h3>
      <Row>
        <Col sm={6}>
          <AmountField
            name="amount"
            label={`${translate('front.foreign-transfer.field-amount.label')} ${isoCode || ''}`}
            minAmount={0.01}
            maxLengthWithoutZero={12}
            isoCode={isoCode}
            hasMoneyFormat
            thousandSeparator={' '}
            required
          />
        </Col>
      </Row>
      <SelectField
        name="commission"
        label="front.foreign-transfer.field-commission.label"
        options={payload.feeTypesOptions}
        required
      />
      <SelectField
        name="feeAccount"
        label="front.foreign-transfer.field-fee-account.label"
        options={payload.feeAccountsOptions}
        validate={validateFeeAccount}
      />
      <SelectField
        name="priority"
        label="front.foreign-transfer.field-priority.label"
        options={payload.priorityTypes}
        required
      />
      <HintField
        name="purpose"
        label="front.internal-payment-page.order-purpose.label"
        rows={3}
        minLength={7}
        maxLength={140}
        hints={payload.purposes}
        hasCounter
        required
      />
      {isExistOrder(order) && (!order.state || order.state === OrderState.Draft) && (
        <CheckboxField
          label="front.internal-payment-page.order-save-payment-purpose.label"
          name="savePaymentPurpose"
        />
      )}
    </DefaultForm>
  );
};
