import * as React from 'react';

import { api } from 'api';
import { Instruction } from 'api/HandbooksService';
import { getPage, GridRequest } from 'api/Service';
import { useDataFetcher } from 'components/DataFetcher/useDataFetcher';
import { useDataFetcherFilter } from 'components/DataFetcher/useDataFetcherFilter';
import { PageFetcher } from 'components/PageFetcher';
import { SearchInput } from 'components/searchInput/SearchInput';
import { translate } from 'i18n/translator';
import { InstructionList } from 'pages/Instructions/components/InstructionList';

import './style.scss';

const InstructionListData: React.FC = () => {
  const { rows } = useDataFetcher();

  return (
    <div className="instructions-page">
      <InstructionList instructionsList={rows} />
    </div>
  );
};

const Search: React.FC = () => {
  const { filter, updateFilter } = useDataFetcherFilter();

  const onChange = (search: string) => {
    updateFilter({ search });
  };

  return (
    <div>
      <SearchInput
        placeholder={translate('front.working-documents-table.filter-search.label')}
        value={filter?.search}
        onChange={onChange}
      />
    </div>
  );
};

const fetchInstruction = async (request: Instruction & GridRequest) => {
  const rows = await api.handbooks.getInstructions(request);
  return getPage(rows, request);
};

export const InstructionsPage: React.FC = () => {
  return (
    <PageFetcher fetch={fetchInstruction} title="front.menu.instructions.label" right={<Search />}>
      <InstructionListData />
    </PageFetcher>
  );
};
