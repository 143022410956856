import React from 'react';

import classname from 'classnames';

import { InputLabel } from 'components/forms/inputs/InputLabel';
import { FinalValue } from 'components/forms/ValidatingForm/FormContext';
import { Tooltip, TTooltip } from 'components/Tooltip/Tooltip';
import { isExist, isString } from 'utils/isData';
import './fieldInput.scss';

interface FieldInputProps {
  isDisabled: boolean;
  isFocus: boolean;
  label: string;
  name: string;
  value: FinalValue;
  absoluteTooltip?: TTooltip;
  error?: string;
  hasOutline?: boolean;
  isLargeInput?: boolean;
  tooltip?: TTooltip;
  warning?: string;
}

export const FieldInput: React.FC<FieldInputProps> = ({
  label,
  name,
  tooltip,
  isFocus,
  isLargeInput,
  children,
  value,
  error,
  absoluteTooltip,
  warning,
  isDisabled,
  hasOutline = true,
}) => {
  const hasValue = isExist(value) && value !== '';

  const isInputFocus = hasValue || isFocus;

  const hasLabel = isString(label);

  return (
    <div className="input-main-wrapper">
      <div
        data-id={name}
        className={classname(
          {
            'input-wrapper_outline': hasOutline,
            'input-wrapper_error': error && hasOutline,
            'input-wrapper_success': !error && hasValue && hasOutline,
          },
          'input-wrapper',
        )}
      >
        <div className="input-flex-container">
          {hasLabel && (
            <InputLabel
              label={label}
              error={error}
              isLargeInput={isLargeInput}
              isInputFocus={isInputFocus}
              hasValue={hasValue}
              isDisabled={isDisabled}
            >
              {children}
            </InputLabel>
          )}
          {!hasLabel && <div className="input-inner-wrapper"> {children} </div>}
          {tooltip && <Tooltip id={`${name}-tooltip`} tooltip={tooltip} />}
        </div>
      </div>
      {error && <div className="form-input-error">{error}</div>}
      {warning && <div className="form-input-warn">{warning}</div>}
      {absoluteTooltip && (
        <div className="tooltip-absolute">
          <Tooltip id={`${name}-tooltip`} tooltip={absoluteTooltip} />
        </div>
      )}
    </div>
  );
};
