import { CardLimitPeriodResponse } from 'api/ProductsService';
import { Option } from 'api/Service';

export const getLimitOptions = (
  limits: CardLimitPeriodResponse[],
): Option<CardLimitPeriodResponse>[] =>
  limits.map(item => ({
    value: item.code,
    label: item.label,
    content: item,
  }));
