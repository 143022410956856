import * as React from 'react';
import { useDataTable } from 'react-data-table';

import { Pagination } from 'components/Pagination';
import { bem } from 'components/utils/bem';

export const DataTableFooter: React.FC = () => {
  const { isHidePagination, totalCount, updateGridRequest, pagination, breakpoint } =
    useTablePagination();

  if (isHidePagination) return null;

  return (
    <div className={bem('data-table', 'footer').toClassName()}>
      <Pagination
        breakpoint={breakpoint}
        totalCount={totalCount}
        pagination={pagination}
        updatePagination={updateGridRequest}
      />
    </div>
  );
};

const useTablePagination = () => {
  const {
    total,
    breakpoint,
    gridRequest: { updateGridRequest, ...pagination },
    isHidePagination,
  } = useDataTable();

  return { pagination, updateGridRequest, totalCount: total.count, isHidePagination, breakpoint };
};
