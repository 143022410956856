import * as React from 'react';

import { api } from 'api';
import { Button } from 'components/buttons/Button';
import { translate } from 'i18n/translator';
import { ActivationContext } from 'pages/Registration/activation/ActivationContext';

export const ActivationResultPage: React.FC = () => {
  const { fetchActivationResult, startActivation } = useActivationResult();

  const [ABSAccountIban, setABSAccountIban] = React.useState<string>();
  const [isSent, setIsSent] = React.useState<boolean>();

  const fetchABSAccountIban = async () => {
    const { ABSAccountIban } = await fetchActivationResult();
    setABSAccountIban(ABSAccountIban);
    setIsSent(isSent);
  };

  React.useEffect(() => {
    fetchABSAccountIban();
  }, []);

  return (
    <>
      <div>
        <h5>{translate('front.activation.result-page.title.label')}</h5>
        <p>
          {translate('front.activation.result-page.first-description.label')} {ABSAccountIban}
        </p>
        {translate('front.activation.result-page.second.description.label')}
      </div>
      <Button
        color="primary"
        onClick={() => startActivation()}
        className="login-page__form__btn"
        size="lg"
      >
        {translate('front.activation.result-page.button-activate.label')}
      </Button>
    </>
  );
};

const useActivationResult = () => {
  const { step, setStep } = React.useContext(ActivationContext);

  const fetchActivationResult = async () => {
    const { ABSAccountIban } = step;
    return { ABSAccountIban };
  };

  const startActivation = async () => {
    setStep(await api.registration.startActivation(step.orderId));
  };

  return { fetchActivationResult, startActivation };
};
