import * as React from 'react';
import { Col, Row, Container } from 'react-grid';

import { api } from 'api';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { ExportDropdown, getExportActions } from 'components/ExportDropdown/ExportDropdown';
import { withForm } from 'components/forms/withForm';
import { PageHeader } from 'components/layout/Page/PageHeader';
import { SearchInput } from 'components/searchInput/SearchInput';
import { CreateButton } from 'components/Table/CreateButton';
import { useFilter } from 'components/Table/DataTable';
import { ToggleFilterButton } from 'components/Table/ToggleFilterButton/ToggleFilterButton';
import { translate } from 'i18n/translator';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { EXPORT_TYPE } from 'pages/Handbooks/CurrencyHistory/CurrencyHistoryFilter';

const PaymentPurposePageFilterForm = () => {
  const { updateFilter, extraFields } = useFilter({
    extraFields: { searchText: '' },
  });

  const exportActions = React.useMemo(() => {
    const action = () => async () =>
      await api.handbooks.getPaymentPurposeExcel({
        ...extraFields,
      });

    return getExportActions({
      action,
      exportTypes: EXPORT_TYPE,
    });
  }, [extraFields]);

  return (
    <div>
      <Container>
        <PageHeader.Right>
          <ExportDropdown actions={exportActions}>
            {translate('front.handbooks-table.excel-export.label')}
          </ExportDropdown>
        </PageHeader.Right>
        <Row>
          <Col lg={5}>
            <CreateButton
              onClick={() => goto(pages.handbooks.paymentPurpose('new'))}
              title="front.handbooks-table.create.label"
            />
          </Col>
          <Col lg={5} md={8}>
            <SearchInput
              placeholder={translate('front.working-documents-table.filter-search.label')}
              value={extraFields.searchText}
              onChange={searchText => updateFilter({ searchText })}
            />
          </Col>
          <Col lg={2} md={4}>
            <ToggleFilterButton>
              <Row>
                <Col md={6}>
                  <CustomerSelect
                    label="front.internal-payment-page.order-payer-name.label"
                    name="customerIds"
                    multi
                  />
                </Col>
              </Row>
            </ToggleFilterButton>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export const PaymentPurposePageFilter = withForm(PaymentPurposePageFilterForm);
