import * as React from 'react';

import { MaskInput } from 'components/forms/maskInput/MaskInput';
import { useField } from 'components/forms/ValidatingForm/components/useField';
import { validateMessages } from 'components/validateMessages';
import { translate } from 'i18n/translator';

import { FieldInput } from './FieldInput';
import { PreloadField } from './PreoadField';

interface TextProps {
  label: string;
  mask: Array<string | Obj>;
  name: string;
  defaultValue?: string;
  disabled?: boolean;
  guide?: boolean;
  length?: number;
  normalize?: (value: string) => string;
  onChange?: (value: string) => void;
  placeholder?: string;
  required?: boolean;
  validate?: (value: string) => string | null;
}

export const normalizeIban = (value: string) => {
  if (/^[A-Za-z.]{0,3}$/g.test(value)) {
    return value.toUpperCase();
  }
  if (!/\.$/.test(value) && value.length < 13) {
    return value;
  }
  return value.replace(/\./g, '');
};

export const MaskField = ({
  name,
  label,
  mask,
  required,
  length,
  disabled,
  defaultValue,
  onChange,
  normalize = value => value,
  placeholder,
  validate: externalValidate,
}: TextProps) => {
  const [isFocus, setFocus] = React.useState<boolean>(false);

  const toggleFocus = (isFocus: boolean) => () => setFocus(isFocus);

  const validate = React.useCallback(
    value => {
      if (required && !value) {
        return translate(validateMessages.fieldRequired);
      }

      if (length && value?.length !== length) {
        return translate(validateMessages.shouldBe(length));
      }

      if (externalValidate) {
        return translate(externalValidate(value));
      }
    },
    [required, externalValidate],
  );

  const onMaskFieldChange = (e: any, onBaseChange: (value: string) => void) => {
    const value = e?.target?.value || '';
    const normalizedValue = normalize(value);

    onBaseChange(normalizedValue);
    onChange && onChange(normalizedValue);
  };

  const { field, form } = useField({ name, defaultValue, validate, required });

  if (!field && !form) {
    return null;
  }

  const isDisabled = disabled || form.disabled || form.progress;

  return (
    <PreloadField isLoading={form.initializing}>
      <FieldInput
        name={name}
        label={label}
        error={field.error}
        warning={field.warning}
        value={field.value}
        isFocus={isFocus}
        isDisabled={isDisabled}
      >
        <MaskInput
          id={name}
          type="text"
          value={field.value || ''}
          onChange={e => onMaskFieldChange(e, field.onChange)}
          mask={mask}
          placeholder={placeholder}
          onBlur={() => {
            field.onBlur();
            toggleFocus(false)();
          }}
          onFocus={toggleFocus(true)}
          disabled={isDisabled}
        />
      </FieldInput>
    </PreloadField>
  );
};
