import * as React from 'react';

import { api } from 'api';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { SelectField } from 'components/forms/inputs/SelectField';
import { Effects, useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import { translate } from 'i18n/translator';
import { isExist } from 'utils/isData';

export interface AddRoleFormProps {
  customerId: number;
  personId: number;
}

interface AddRoleFormFields {
  roleId: string;
}

export const AddRoleForm: React.FC<PropsWithConfirmModal<AddRoleFormProps>> = ({
  resolve,
  personId,
  customerId,
}) => {
  const fetchRoles = async ({ setPayload }: Effects<AddRoleFormFields, any>) => {
    const roles = await api.auth.getPersonRoleOptions({ customerId });
    const personRoleOptions = roles.filter(role => isExist(role.content.customerId));
    setPayload({ personRoleOptions });
  };

  const { progress, error, payload, handleSubmit } = useForm<AddRoleFormFields>(fetchRoles);

  const onSave = async ({ roleId }: AddRoleFormFields) => {
    await api.auth.addRolesToPerson(personId, [+roleId]);
    resolve(true);
  };

  return (
    <>
      <h3>{translate('front.add-role-modal.title.label')}</h3>
      <DefaultForm>
        <FormError>{error}</FormError>
        <SelectField
          label="front.add-role-modal.role-field.label"
          name="roleId"
          options={payload?.personRoleOptions}
          required
        />
        <Button disabled={progress} onClick={e => handleSubmit(onSave, e)}>
          {translate('front.сounterparty-form.add-button.label')}
        </Button>
        <Button color="secondary" onClick={() => resolve(true)}>
          {translate('front.role-page.create-role.cancel-button.label')}
        </Button>
      </DefaultForm>
    </>
  );
};

export const AddRoleModal = withForm(AddRoleForm);
