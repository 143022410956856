import * as React from 'react';
import MaskedInput from 'react-text-mask';

import { FinalValue } from 'components/forms/ValidatingForm/FormContext';

interface Props {
  mask: Array<string | Obj>;

  onBlur(): void;

  onChange(value: string): void;

  onFocus(): void;

  type: string;
  autoFocus?: boolean;
  disabled?: boolean;
  guide?: boolean;
  id?: string;

  name?: string;

  placeholder?: string;

  value?: FinalValue;
}

export const MaskInput: React.FC<Props> = ({ guide = false, ...props }) => (
  <MaskedInput
    {...(props as any)}
    guide={guide}
    render={(
      ref: React.LegacyRef<HTMLInputElement>,
      props: JSX.IntrinsicAttributes &
        React.ClassAttributes<HTMLInputElement> &
        React.InputHTMLAttributes<HTMLInputElement>,
    ) => <input className="form-input" ref={ref} {...props} />}
  />
);
