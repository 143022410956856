import { KeyType } from 'api/CertificateService/enums';
import { Option } from 'api/Service';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { confirmationModal } from 'components/modals/ConfirmModal';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { translate } from 'i18n/translator';
import { ChangePasswordModal } from 'pages/Profile/Certificates/Modals/ChangePasswordModal';

export const useControls = () => {
  const form = useForm();

  const mode = form.getFieldValue('mode');

  const changePassword = async () => {
    let certificate;
    const token = form.getFieldValue('store');
    const edsKey = form.getFieldValue('edsKey');

    if (mode === KeyType.TOKEN && token) {
      certificate = form.payload.storeOptions.find(
        (item: Option) => item.content.Serial === token,
      ).content;
    }

    if (mode === KeyType.FILE && edsKey) {
      const Description = form.payload.edsKeyOptions.find((item: Option) => item.value === edsKey)
        .content.Description;

      certificate = { Description };
    }

    if (certificate && (await confirmModal(ChangePasswordModal, { certificate }, 426))) {
      await confirmationModal(
        translate('front.cert-page-add.confirm-modal-message.label'),
        true,
        false,
      );
      form.setError(null);
      form.updateData({ password: '' });
    }
  };

  return {
    form,
    changePassword,
  };
};
