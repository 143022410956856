import { keyTypes, mapKeyTypesToConstants } from 'api/CertificateService/CertificateService';
import { ConstantEnum } from 'api/enums';
import { Customer, PersonConfig } from 'api/UserService';

export const getAllowedCustomer = (customers: Customer[], customerId: number): Customer => {
  const defaultCustomer = customers.find(c => c.id === customerId);

  if (defaultCustomer.config.ALLOW_TOKEN_KEY || defaultCustomer.config.ALLOW_FILE_KEY) {
    return defaultCustomer;
  }

  return customers.find(c => c.config.ALLOW_FILE_KEY || c.config.ALLOW_TOKEN_KEY);
};

export const parseConstants = ({
  ALLOW_FILE_KEY,
  ALLOW_TOKEN_KEY,
}: PersonConfig): Omit<PersonConfig, ConstantEnum.IpRestriction | ConstantEnum.HasRole> => ({
  ALLOW_FILE_KEY,
  ALLOW_TOKEN_KEY,
});

export const getKeyTypeOptions = (constants: Omit<PersonConfig, 'IP_RESTRICTION' | 'HAS_ROLE'>) => {
  return keyTypes.filter(keyType => constants[mapKeyTypesToConstants[keyType.value]]);
};
