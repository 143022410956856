import { api } from 'api';
import * as CipherService from 'api/CertificateService/CipherModule/CipherService';
import { CertificateType, KeyType } from 'api/CertificateService/enums';
import { Certificate } from 'api/CertificateService/interfaces';
import { ActionEnum } from 'api/enums';
import { SignCertificateOrders, SignedFile, SignedOrder, SignOrder } from 'api/interfaces';
import { Customer } from 'api/UserService';
import { isEmptyObject } from 'components/utils/utils';
import { translate } from 'i18n/translator';
import { FILE_SEPARATOR } from 'pages/Demands/utils';
import { FileFieldInterface } from 'pages/Profile/Certificates/CertificateEngine/types';
import { isConnectedToken } from 'pages/Profile/Certificates/KepCertificate/utils';
import {
  CheckCertificateParams,
  GetShortOrderForSignParams,
  GetSignatureCsmListParams,
  GetSignatureListParams,
  ShortOrderForSing,
  SigningTokenFileKeyParams,
} from 'pages/utils/SignOrders/CertificateSign/interfaces';
import { toUniqueArray } from 'pages/utils/utils';
import { CustomError } from 'utils/customError';
import { isExist } from 'utils/isData';

const ORDERS_REQUEST_SIZE = 250;

export const createTokenSignOrderRequest = (
  certificate: Certificate,
  orderId: string | number,
  orderCms: string,
  fileCms?: SignedFile[],
): SignOrder => ({
  signFactor: 'EDS',
  certificateSn: certificate.certificateSN,
  certificateCaSn: certificate.certificateCaSerial,
  signatures: { [orderId]: orderCms },
  signedFiles: fileCms
    ? {
        [orderId]: fileCms,
      }
    : {},
});

export const signOrdersInChunks = async (signedOrders: SignOrder[]): Promise<SignedOrder[]> => {
  const signedOrdersChunks = [];

  while (signedOrders.length > 0) {
    signedOrdersChunks.push(signedOrders.splice(0, ORDERS_REQUEST_SIZE));
  }

  const successSignOrders: SignedOrder[] = [];

  for (let indexOrders = 0; indexOrders < signedOrdersChunks.length; indexOrders++) {
    const { data } = await api.payments.signOrder(signedOrdersChunks[indexOrders]);
    successSignOrders.push(...data);
  }

  return successSignOrders;
};

export const signingTokenFileKey = async ({
  payload,
  orders,
  formData,
  currentCertificate,
}: SigningTokenFileKeyParams): Promise<SignOrder[]> => {
  const signData = await api.payments.signCertificateOrder(orders.map(o => o.orderId));

  const { fetchFilter, storeOptions = [] } = payload;
  const { mode, store } = formData;
  const isTokenMode = mode === KeyType.TOKEN;
  const allowSignFile = currentCertificate.certificateType === CertificateType.Kep;

  const keyStorePath = storeOptions.find(({ value }) => value === store)?.content.activeModePath;

  const { firstSignList, secondSignList, firstSignFileList, secondSignFileList } =
    getSignatureCsmList(orders, signData, allowSignFile);

  const ticketUuid = await CipherService.createSession();

  const params = {
    ticketUuid,
    isTokenMode,
    keyStorePath,
    fetchFilter,
    formData,
    currentCertificate,
  };

  const firstSigningDocList = await signingList({
    ...params,
    signList: Object.values(firstSignList),
  });

  const nextSigningDocList = await signingList({
    ...params,
    signList: Object.values(secondSignList),
    hasFirstSign: true,
  });

  const firstSigningFileList = await signingList({
    ...params,
    signList: getValueFromObjectByField<
      GetSignatureCsmListParams['firstSignFileList'],
      keyof SignedFile
    >(firstSignFileList, 'signedFile'),
  });

  const nextSigningFileList = await signingList({
    ...params,
    signList: getValueFromObjectByField<
      GetSignatureCsmListParams['secondSignFileList'],
      keyof SignedFile
    >(secondSignFileList, 'signedFile'),
    hasFirstSign: true,
  });

  await CipherService.closeSession(ticketUuid);

  const allSignOrderIds = [...Object.keys(firstSignList), ...Object.keys(secondSignList)];
  const allResultDocListWithSign = [...firstSigningDocList, ...nextSigningDocList];

  const allFileListForSign = { ...firstSignFileList, ...secondSignFileList };
  const allResultFileListWithSign = [...firstSigningFileList, ...nextSigningFileList];

  const allSignFile = allResultFileListWithSign.length
    ? substituteValueInNestedObjectsInName<typeof allFileListForSign, keyof SignedFile>(
        allFileListForSign,
        allResultFileListWithSign,
        'signedFile',
      )
    : {};

  return allSignOrderIds.map((orderId, index) =>
    createTokenSignOrderRequest(
      currentCertificate,
      orderId,
      allResultDocListWithSign[index],
      allSignFile[orderId] && Object.values(allSignFile[orderId]),
    ),
  );
};

export const signingList = async ({
  signList,
  ticketUuid,
  isTokenMode,
  keyStorePath,
  fetchFilter,
  formData,
  currentCertificate,
  hasFirstSign = false,
}: GetSignatureListParams): Promise<string[]> =>
  isExist(signList[0])
    ? await getSignatureCsmSignedList({
        ticketUuid,
        isTokenMode,
        keyStorePath,
        fetchFilter,
        formData,
        currentCertificate,
        signList,
        hasFirstSign,
      })
    : [];

export const checkCertificate = async ({
  ticketUuid,
  password,
  isTokenMode,
  fetchFilter,
  currentCertificate,
}: CheckCertificateParams): Promise<any> => {
  const cipherCertificate = await CipherService.getCertificateInfo(
    ticketUuid,
    password,
    ActionEnum.SignCipher,
  );

  if (isEmptyObject(cipherCertificate.certificateInfo)) {
    await CipherService.closeSession(ticketUuid);

    throw new CustomError(
      translate(
        isTokenMode
          ? 'front.login-form.certificate.token-no-valid-keys-kep.label'
          : 'front.login-form.certificate.token-no-valid-file-kep.label',
      ),
    );
  }

  const cipherCertificateBase64 = await CipherService.getCertificateBase64(
    ticketUuid,
    password,
    ActionEnum.SignCipher,
  );

  const isConnect = isConnectedToken({
    edrpou: fetchFilter?.edrpou,
    currentCertificates: [currentCertificate],
    cipherCertificate,
    cipherCertificateBase64,
  });

  if (!isConnect) {
    await CipherService.closeSession(ticketUuid);

    throw new CustomError(translate('front.certificates.modal-error-belongs.label'));
  }
};

export const getSignatureCsmSignedList = async ({
  ticketUuid,
  isTokenMode,
  keyStorePath,
  fetchFilter,
  formData,
  currentCertificate,
  signList,
  hasFirstSign = false,
}: GetSignatureListParams): Promise<string[]> => {
  const { password } = formData;
  await CipherService.setSessionParams(ticketUuid, hasFirstSign);

  await createSessionAndCheckKey({
    isTokenMode,
    keyStorePath,
    fetchFilter,
    currentCertificate,
    ticketUuid,
    formData,
  });

  return await CipherService.sign(ticketUuid, password, signList);
};

export const createSessionAndCheckKey = async ({
  isTokenMode,
  keyStorePath,
  fetchFilter,
  currentCertificate,
  ticketUuid,
  formData,
}: Omit<GetSignatureListParams, 'signList'>): Promise<any> => {
  const { edsKey, password } = formData;
  isTokenMode && (await CipherService.createSessionContainer({ ticketUuid, keyStorePath }));

  if (!isTokenMode) {
    const edsKeyFieldValue = (edsKey[0] as FileFieldInterface).value;
    const [, content] = edsKeyFieldValue.split(FILE_SEPARATOR);

    await CipherService.createSessionContainer({ ticketUuid, base64Data: content });
  }

  await checkCertificate({
    ticketUuid,
    password,
    isTokenMode,
    fetchFilter,
    currentCertificate,
  });
};

export const getShortOrdersForSign = ({
  props,
  currentCertificate,
  customerIdStr,
  customers,
}: GetShortOrderForSignParams): ShortOrderForSing[] => {
  const customerId = Number(customerIdStr);
  const customerIdsWithOrder = toUniqueArray(props.orders.map(o => o.customerId));

  if (customerIdsWithOrder.length > 1) {
    const isMultiKey = currentCertificate?.isUserCert;

    const customersMap = customers.reduce((acc: { [key: string]: boolean }, customer: Customer) => {
      acc[customer.id] = customer.allowedForUserCert;
      return acc;
    }, {});

    return props.orders
      .filter(order => {
        if (isMultiKey && customerId !== order.customerId) {
          return customersMap[order.customerId];
        }

        return customerId === order.customerId;
      })
      .map(order => ({ orderId: order.id, isSignFiles: order.isSignFiles ?? true }));
  }

  return props.orders.map(order => ({ orderId: order.id, isSignFiles: order.isSignFiles ?? true }));
};

export const getSignatureCsmList = (
  orders: ShortOrderForSing[],
  signData: SignCertificateOrders,
  allowSignFile: boolean,
): GetSignatureCsmListParams =>
  orders.reduce(
    (acc, { orderId, isSignFiles = true }) => {
      const hasCms = isExist(signData[orderId].cms);

      if (hasCms) {
        acc.secondSignList = { ...acc.secondSignList, [orderId]: signData[orderId].cms };
      }

      if (!hasCms) {
        acc.firstSignList = { ...acc.firstSignList, [orderId]: signData[orderId].signatureString };
      }

      if (allowSignFile && isSignFiles) {
        const filesData = signData[orderId].notSignedFiles;

        if (!isEmptyObject(filesData)) {
          for (const key in filesData) {
            if (filesData.hasOwnProperty(key)) {
              const file = filesData[key];

              if (isExist(file.signedFile)) {
                if (file.isFirstSign) {
                  acc.firstSignFileList = {
                    ...acc.firstSignFileList,
                    [orderId]: { ...acc.firstSignFileList[orderId], [key]: file },
                  };
                } else {
                  acc.secondSignFileList = {
                    ...acc.secondSignFileList,
                    [orderId]: { ...acc.secondSignFileList[orderId], [key]: file },
                  };
                }
              }
            }
          }
        }
      }

      return acc;
    },
    {
      firstSignList: [],
      secondSignList: [],
      firstSignFileList: {},
      secondSignFileList: {},
    } as GetSignatureCsmListParams,
  );

export const getValueFromObjectByField = <T, K>(fieldObjects: T, field: K): string[] => {
  const values = [] as string[];

  function extractValues(obj: T): void {
    if (typeof obj === 'object' && obj !== null) {
      if (field as string) {
        values.push((obj as Record<string, any>)[field as unknown as string]);
      }

      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          extractValues(obj[key] as unknown as T);
        }
      }
    }
  }

  extractValues(fieldObjects);

  return values.filter(Boolean);
};

// Функція рекурсивно викликається і змінює значення знайденого поля field на значення з масиву valuesArray по порядку.
export const substituteValueInNestedObjectsInName = <T, K>(
  obj: T,
  valuesArray: string[],
  field: K,
): T => {
  const result = JSON.parse(JSON.stringify(obj)) as T;

  for (const key in result) {
    if (
      result.hasOwnProperty(key) &&
      typeof result[key] === 'object' &&
      result[key] !== null &&
      Object.keys(result[key]).length > 0
    ) {
      result[key] = substituteValueInNestedObjectsInName(result[key], valuesArray, field);
    } else if ((key as unknown) === field && valuesArray.length > 0) {
      result[key] = valuesArray.shift() as unknown as T[Extract<keyof T, string>];
    }
  }

  return result;
};
