import { FILETYPE_PRESETS, IMPORT_TYPES, Option } from 'api/Service';
import { ExportFormatEnum } from 'components/ExportDropdown/enum';
import { translate } from 'i18n/translator';
import { Privileges } from 'navigations/privileges';

export const fileTypeOptions: Option<any, IMPORT_TYPES>[] = [
  {
    value: IMPORT_TYPES.SALARY,
    label: translate('front.demand-import.salary.label'),
  },
  {
    value: IMPORT_TYPES.SALARY_EMPLOYEES,
    label: translate('front.demand-import.employees.label'),
  },
  {
    value: IMPORT_TYPES.DOMESTIC_PAYMENTS,
    label: translate('front.demand-import.domestic-payments.label'),
  },
  {
    value: IMPORT_TYPES.FOREIGN_PAYMENTS,
    label: translate('front.demand-import.foreign-payments.label'),
  },
  {
    value: IMPORT_TYPES.COUNTERPARTIES,
    label: translate('front.demand-import.counterparties.label'),
  },
  {
    value: IMPORT_TYPES.CURRENCY_BUY,
    label: translate('front.demand-import.currency-exchange.label'),
  },
];

export const fileEncodingOptions: Option[] = [{ value: '1', label: 'Windows' }];

export const fileAccepts: Obj<FILETYPE_PRESETS> = {
  csv: FILETYPE_PRESETS.CSV,
  dbf: FILETYPE_PRESETS.DBF,
  xls: FILETYPE_PRESETS.XLS,
  xml: FILETYPE_PRESETS.XML,
};

const defaultFormatOptions: Option<any, ExportFormatEnum>[] = [
  { value: ExportFormatEnum.CSV.toLowerCase() as ExportFormatEnum, label: ExportFormatEnum.CSV },
  { value: ExportFormatEnum.DBF.toLowerCase() as ExportFormatEnum, label: ExportFormatEnum.DBF },
  { value: ExportFormatEnum.XLS.toLowerCase() as ExportFormatEnum, label: ExportFormatEnum.XLS },
];

const domesticFormatOptions: Option<any, ExportFormatEnum>[] = [
  { value: ExportFormatEnum.CSV.toLowerCase() as ExportFormatEnum, label: ExportFormatEnum.CSV },
  { value: ExportFormatEnum.DBF.toLowerCase() as ExportFormatEnum, label: ExportFormatEnum.DBF },
  { value: ExportFormatEnum.XLS.toLowerCase() as ExportFormatEnum, label: ExportFormatEnum.XLS },
  { value: ExportFormatEnum.XML.toLowerCase() as ExportFormatEnum, label: ExportFormatEnum.XML },
];

const counterpartyFormatOptions: Option<any, ExportFormatEnum>[] = [
  { value: ExportFormatEnum.CSV.toLowerCase() as ExportFormatEnum, label: ExportFormatEnum.CSV },
  { value: ExportFormatEnum.XLS.toLowerCase() as ExportFormatEnum, label: ExportFormatEnum.XLS },
];

const currencyExchangeFormatOptions = [
  { value: ExportFormatEnum.DBF.toLowerCase() as ExportFormatEnum, label: ExportFormatEnum.DBF },
];

const formatOptions: Record<string, Option<any, ExportFormatEnum>[]> = {
  [IMPORT_TYPES.DOMESTIC_PAYMENTS]: domesticFormatOptions,
  [IMPORT_TYPES.COUNTERPARTIES]: counterpartyFormatOptions,
  [IMPORT_TYPES.CURRENCY_BUY]: currencyExchangeFormatOptions,
};

export const getFormatOptions = (type: IMPORT_TYPES): Option<any, ExportFormatEnum>[] =>
  formatOptions[type] ?? defaultFormatOptions;

export const allowedImportPrivilegeForSelect = (importType: IMPORT_TYPES): Privileges => {
  const privileges = {
    [IMPORT_TYPES.DOMESTIC_PAYMENTS]: Privileges.paymentDomesticEdit,
    [IMPORT_TYPES.FOREIGN_PAYMENTS]: Privileges.paymentForeignEdit,
    [IMPORT_TYPES.SALARY]: Privileges.salarySheetEdit,
    [IMPORT_TYPES.SALARY_EMPLOYEES]: Privileges.salaryEmployeeEdit,
    [IMPORT_TYPES.COUNTERPARTIES]: Privileges.infoCounterpartiesEdit,
    [IMPORT_TYPES.CURRENCY_BUY]: Privileges.demandCurrencyExchangeEdit,
  };

  return privileges[importType];
};

export const allowedImportPrivilege = (importType: IMPORT_TYPES): Privileges => {
  const privileges: Record<string, Privileges> = {
    [IMPORT_TYPES.CURRENCY_BUY]: Privileges.importCurrencyBuy,
  };

  return privileges[importType];
};
