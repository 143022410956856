import { WIDGET } from 'config';

import { ItemType } from './DragDrop';

export const items: ItemType[] = [
  {
    widget: WIDGET.INTERNAL_PAYMENTS,
    enabled: true,
    title: 'front.working-documents-table.label',
    column: 1,
    order: 0,
  },
  {
    widget: WIDGET.ANALYTICS,
    enabled: true,
    title: 'front.home.cash-flow.label',
    column: 0,
    order: 0,
  },
].map((item, index) => ({
  ...item,
  id: String(index),
}));
