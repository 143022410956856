import React from 'react';
import { Collapse } from 'react-collapse';

import './collapse.scss';

export type RenderHeader = (isOpen: boolean, onToggleShow: () => void) => React.ReactNode;

export interface CollapseProps {
  renderHeader: RenderHeader;
  content?: React.ReactNode;
}

export const CollapseNew: React.FC<CollapseProps> = props => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const onToggleShow = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      {props.renderHeader(isOpen, onToggleShow)}
      <Collapse theme={{ collapse: 'transitioned' }} isOpened={isOpen}>
        {props.content}
      </Collapse>
    </div>
  );
};
