import * as React from 'react';
import { TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { PersonalInformation } from 'api/ProfileService';
import { SortOrder } from 'api/Service';
import { CreateButton } from 'components/Table/CreateButton';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { date } from 'components/utils/format';
import { DisabledBlock } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';

const columns: TableColumnProps<PersonalInformation>[] = [
  {
    label: 'front.profile.information.table.col.login.label',
    render: row => <DataColumn title={row.login} />,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.profile.information.table.col.fullName.label',
    render: row => <DataColumn title={row.fullName} />,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.profile.information.table.col.phone.label',
    render: row => <DataColumn title={row.phone} />,
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.profile.information.table.col.email.label',
    render: row => <DataColumn title={row.email} />,
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.profile.information.table.col.default-enterprise.label',
    render: row => <DataColumn title={row.defaultOrgName} />,
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.profile.information.table.col.password-expiry.label',
    render: row => <DataColumn title={date(row.passwordExpiry)} />,
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    renderMenu: () => [],
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

export const InformationPage = () => (
  <>
    <div style={{ padding: '0 20px' }}>
      <DisabledBlock privileges={[Privileges.settingsInformationEdit]}>
        <CreateButton
          title="front.profile.information.button.change-password.label"
          onClick={() => goto(pages.profile.changePassword)}
        />
        <CreateButton
          title="front.profile.information.button.change-email.label"
          onClick={() => goto(pages.profile.changeEmail)}
        />
      </DisabledBlock>
    </div>
    <DataTable
      name={TableNamesEnum.InformationList}
      columns={columns}
      fetchRows={api.profile.getInformation}
      defaultSort="id"
      defaultOrder={SortOrder.Asc}
    />
  </>
);
