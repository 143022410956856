import * as React from 'react';
import { Col, Row } from 'react-grid';

import { api } from 'api';
import { EmployeeStatus } from 'api/enums';
import { EmployeeFilter } from 'api/SalaryService';
import { IMPORT_TYPES, Option } from 'api/Service';
import { Button } from 'components/buttons/Button';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { ExportFormatEnum } from 'components/ExportDropdown/enum';
import { ExportDropdown, getExportActions } from 'components/ExportDropdown/ExportDropdown';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextField } from 'components/forms/inputs/TextField';
import { withForm } from 'components/forms/withForm';
import { PageHeader } from 'components/layout/Page/PageHeader';
import { SearchInput } from 'components/searchInput/SearchInput';
import { useFilter } from 'components/Table/DataTable';
import { ToggleFilterButton } from 'components/Table/ToggleFilterButton/ToggleFilterButton';
import { FilterTabBar } from 'components/tabs/FilterTabBar';
import { translate } from 'i18n/translator';
import { DisabledBlock } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';

import { LoadEmployeeRegisterButton } from './components/LoadEmployeeRegisterButton/LoadEmployeeRegisterButton';
import './styles.scss';

interface InitialFilterEmployeeConfig {
  searchText: string;
  statuses: EmployeeStatus;
}

const EXPORT_TYPES: Record<string, ExportFormatEnum> = {
  XLS: ExportFormatEnum.EXCEL,
  CSV: ExportFormatEnum.CSV,
  DBF: ExportFormatEnum.DBF,
};

const bankOption: Option[] = [
  {
    value: 'false',
    label: 'front.employees-filter.bank.ugb.label',
  },
  {
    value: 'true',
    label: 'front.employees-filter.bank.other-bank.label',
  },
];

const statusOptions: Array<Option> = [
  {
    value: EmployeeStatus.ALL,
    label: 'front.employees-filter.status.all.label',
  },
  {
    value: EmployeeStatus.ACTIVE,
    label: 'front.employees-filter.status.active.label',
  },
  {
    value: EmployeeStatus.FIRED,
    label: 'front.employees-filter.status.fired.label',
  },
];

const EmployeesFilterForm = () => {
  const { updateFilter, extraFields, setPagination } = useFilter<
    EmployeeFilter,
    InitialFilterEmployeeConfig
  >({
    extraFields: {
      searchText: undefined,
      statuses: EmployeeStatus.ALL,
    },
  });

  const onSearch = (searchText: string) => {
    updateFilter({ searchText });
  };

  const exportActions = React.useMemo(() => {
    const action = (formatType: ExportFormatEnum) => () =>
      api.salary.exportEmployees(formatType, extraFields);

    return getExportActions({
      action,
      exportTypes: EXPORT_TYPES,
    });
  }, [extraFields]);

  return (
    <div>
      <PageHeader.Right>
        <ExportDropdown actions={exportActions} />
      </PageHeader.Right>
      <FilterTabBar
        onChange={status => updateFilter({ statuses: status })}
        value={extraFields.statuses}
        options={statusOptions}
        setPagination={setPagination}
      />
      <Row>
        <Col md={3}>
          <DisabledBlock privileges={[Privileges.salaryEmployeeEdit]}>
            <Button
              color="secondary"
              label="front.demands.salary.import-button.label"
              onClick={() => {
                goto(pages.import, { type: IMPORT_TYPES.SALARY_EMPLOYEES });
              }}
            />
          </DisabledBlock>
          <DisabledBlock privileges={[Privileges.salaryEmployeeEdit]}>
            <LoadEmployeeRegisterButton />
          </DisabledBlock>
        </Col>
        <Col md={6}>
          <SearchInput
            placeholder={translate('front.working-documents-table.filter-search.label')}
            value={extraFields.searchText}
            onChange={onSearch}
          />
        </Col>
        <Col md={3}>
          <ToggleFilterButton>
            <Row>
              <Col md={6}>
                <TextField name="taxCodes" label="front.employees-filter.by-tax-code.label" />
              </Col>
              <Col md={6}>
                <TextField
                  name="productCode"
                  label="front.employees-filter.by-product-code.label"
                />
              </Col>
              <Col md={6}>
                <SelectField
                  name="statuses"
                  label="front.employees-filter.by-status.label"
                  options={statusOptions}
                  defaultValue={EmployeeStatus.ALL}
                />
              </Col>
              <Col md={6}>
                <TextField name="accountNumbers" label="front.employees-filter.by-account.label" />
              </Col>
              <Col md={6}>
                <TextField name="name" label="front.employees-filter.by-name.label" />
              </Col>
              <Col md={6}>
                <CustomerSelect
                  label="front.internal-payment-page.order-payer-name.label"
                  name="customerIds"
                  multi
                />
              </Col>
              <Col md={6}>
                <SelectField
                  label="front.employees-filter.bank.label"
                  name="isAccountInAnotherBank"
                  options={bankOption}
                />
              </Col>
            </Row>
          </ToggleFilterButton>
        </Col>
      </Row>
    </div>
  );
};

export const EmployeesFilter = withForm(EmployeesFilterForm);
