import * as React from 'react';
import { TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { Event, EventStatus, getStatusColor } from 'api/EventService';
import { SortOrder } from 'api/Service';
import { Page } from 'components/layout/Page/Page';
import { Status } from 'components/layout/Status';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { translate } from 'i18n/translator';

const columns: TableColumnProps<Event>[] = [
  {
    label: 'ID',
    render: row => <DataColumn title={row.id} />,
    sort: 'id',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.menu.work-protocol.date.label',
    render: row => <DataColumn title={row.when} />,
    sort: 'eventTime',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.menu.work-protocol.channel.label',
    render: row => <DataColumn title={row.channel && row.channel.label} />,
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.menu.work-protocol.ip.label',
    render: row => <DataColumn title={row.terminal} />,
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.menu.work-protocol.action.label',
    render: row => <DataColumn title={row.action && row.action.label} />,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.menu.work-protocol.status.label',
    render: row => (
      <DataColumn>
        <Status color={getStatusColor(row.status as EventStatus)}>{row.status}</Status>
      </DataColumn>
    ),
    sort: 'eventStatus',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
];

export const ProtocolsPage = () => (
  <Page title={translate('front.menu.work-protocol.label')}>
    <DataTable
      name={TableNamesEnum.ProtocolsPage}
      columns={columns}
      fetchRows={api.events.getCurrent}
      defaultSort="id"
      defaultOrder={SortOrder.Desc}
    />
  </Page>
);
