import React from 'react';

import moment from 'moment';

import { api } from 'api';
import { Country } from 'api/CountryService';
import { OrderType } from 'api/DemandsService/enums';
import { DemandResponse, DynamicField, PartialDemandRequest } from 'api/DemandsService/interfaces';
import { OrderActionType, OrderKind, OrderState } from 'api/enums';
import { Account, OrderFieldResponse } from 'api/interfaces';
import { Order, OrderAction } from 'api/OrderService';
import { Option } from 'api/Service';
import { useOrder } from 'components/Document/useOrder';
import { getOrderDate } from 'components/Document/utils';
import {
  FetchOrderCb,
  OnSaveCreator,
  OnSaveOptions,
  withOrder,
} from 'components/Document/withOrder';
import { FormState } from 'components/forms/ValidatingForm/FormContext';
import { NavTab, NavTabs } from 'components/tabs/NavTabs';
import * as format from 'components/utils/format';
import { translate } from 'i18n/translator';
import { isPaymentActionAllowed } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { NEW } from 'navigations/routes';
import {
  getAccount,
  getFeeAccountValue,
  getFieldId,
  getFieldValue,
  hasSigningRules,
  isExistAccount,
} from 'pages/Payments/ForeignPayment/utils';
import { getAccountValue } from 'pages/Payments/InternalPayment/utils';

import { getTranslateAction } from '../general';
import { PaymentHistoryPage } from '../History/PaymentHistoryPage';
import { ForeignPaymentDetails } from './ForeignPaymentDetails';

export interface ForeignPaymentFields {
  amount: number;
  commission: string;
  orderDate: Date;
  orderNumber: string;
  payerAccount: string;
  payerBankName: string;
  payerBic: string;
  payerBicCode: string;
  payerName: string;
  payerTaxCode: string;
  purpose: string;
  purposes: string[];
  receiverAccount: string;
  receiverBankName: string;
  receiverBic: string;
  receiverName: string;
  valueDate: string;
  intermediaryBankAddress?: string;

  intermediaryBankName?: string;
  intermediaryCity?: string;
  intermediaryCorrAccount?: string;
  intermediaryCountryId?: number;
  intermediaryId?: number;
  isoCode?: string;
  isVerifyIntermediaryCorrAccount?: boolean;
  isVerifyReceiverAccount?: boolean;
  isVerifyReceiverBankCorrAccount?: boolean;
  receiverAddress?: string;
  receiverBankAddress?: string;
  receiverBankCorrAccount?: string;
  receiverBankCountryId?: number;
  receiverCity?: string;
  receiverCountryId?: number;
  receiverId?: number;
}

export interface Payload {
  accountsOptions?: Option<Account>[];
  countryOptions?: Option<Country, string | number>[];
  feeAccountsOptions?: Option[];
  feeTypesOptions?: Option[];
  payerAccountOptions?: Option<Account>[];
  payerOptions?: Option[];
  priorityTypes?: Option[];
  purposes?: Option[];
  receiverAccountsOptions?: Option<Account>[];
  receiverBICOptions?: Option[];
  receiverOptions?: Option[];
}

enum DynamicFields {
  PROFIT_ACCOUNT = 'profitAccount',
  IS_VERIFY_RECEIVER_ACCOUNT = 'isVerifyReceiverAccount',
  IS_VERIFY_RECEIVER_BANK_CORR_ACCOUNT = 'isVerifyReceiverBankCorrAccount',
  IS_VERIFY_INTERMEDIARY_CORR_ACCOUNT = 'isVerifyIntermediaryCorrAccount',
}

export const priorityTypes: Option[] = [
  {
    value: '0',
    label: translate('front.foreign-transfer.select-priority-normal.label'),
  },
  {
    value: '1',
    label: translate('front.foreign-transfer.select-priority-urgent.label'),
  },
];

const actions: OrderAction[] = [
  {
    label: getTranslateAction(OrderActionType.DELETE),
    name: OrderActionType.DELETE,
    type: 'dropdown',
    onClick: async order => {
      await api.payments.deleteOrder(order.id);
      goto(pages.foreignPayments);
    },
    privileges: [Privileges.paymentForeignEdit],
    confirmMessage: 'front.working-documents-table.actions-delete-confirmation.label',
  },
  {
    label: getTranslateAction(OrderActionType.COPY),
    name: OrderActionType.COPY,
    type: 'dropdown',
    onClick: order => {
      goto(pages.foreignPayment.tabs.details('new', { copyFrom: `${order.id}` }));
    },
    privileges: [Privileges.paymentForeignEdit],
  },
  {
    label: getTranslateAction(OrderActionType.PRINT),
    name: OrderActionType.PRINT,
    type: 'dropdown',
    onClick: order => api.payments.getOrderPdf(order.id),
  },
  {
    label: getTranslateAction(OrderActionType.SIGN),
    name: OrderActionType.SIGN,
    type: 'signButton',
    reloadable: true,
    privileges: [Privileges.paymentForeignSign],
  },
];

export const foreignTransferSourceFields = [
  'ACCOUNTS',
  'COUNTERPARTIES',
  'FEE_ACCOUNTS',
  'PURPOSES',
  'KBE',
  'FEE_TYPES',
  'TEMPLATES',
  'CUSTOMER',
  'DOCUMENT_NUMBER',
];

const getDynamicCheckboxField = (
  name: string,
  orderField: OrderFieldResponse,
  booleanValue: boolean,
) =>
  ({
    data: [
      {
        orderFieldId: getFieldId(name, orderField),
        booleanValue,
      },
    ],
  }) as DynamicField;

interface PrepareFieldsForeignPaymentReturn extends Partial<ForeignPaymentFields> {
  custId: number;
  feeAccount: string;
  intermediaryBankCode: string;
  priority: string;
}

export const prepareFieldsForeignPayment = (
  doc: DemandResponse,
  payerAccountOptions: Option<Account>[],
  feeAccountsOptions: Option<Account>[],
  isDraft: boolean,
): PrepareFieldsForeignPaymentReturn => {
  const {
    financial: {
      amount,
      purpose,
      payerAccountId,
      payerIban,
      isoCode,
      receiverIban,
      receiverAddress,
      receiverBankAddress,
      receiverName,
      receiverBic,
      receiverCountryId,
      receiverBankCountryId,
      receiverBankCorrAccount,
      receiverBankName,
      valueDate,
      payerName,
      intermediaryBankAddress,
      intermediaryBankCode,
      intermediaryBankName,
      intermediaryCorrAccount,
    },
    base: { commission, orderDate, priority, customerId },
    dynamicFields,
  } = doc;

  let payerAccount: string;
  if (isDraft) {
    payerAccount = payerAccountOptions
      ? getAccountValue(`${payerAccountId}`, payerAccountOptions)
      : `${payerAccountId}`;
  } else {
    payerAccount = getAccountValue(`${payerAccountId}`, payerAccountOptions) ?? payerIban;
  }

  const feeAccountIban = doc.dynamicFields[0]?.data[0]?.fieldValue;
  const feeAccountId = doc.dynamicFields[0]?.data[0]?.numericValue;

  let feeAccount;
  if (isDraft) {
    feeAccount = feeAccountsOptions
      ? getFeeAccountValue(feeAccountId, feeAccountsOptions)
      : feeAccountIban;
  } else {
    feeAccount = feeAccountIban;
  }

  return {
    amount,
    purpose,
    commission,
    isoCode,
    payerName,
    receiverName,
    receiverCountryId,
    receiverAddress,
    receiverBic,
    receiverBankCorrAccount,
    receiverBankName,
    receiverBankCountryId,
    receiverBankAddress,
    intermediaryBankCode,
    intermediaryCorrAccount,
    intermediaryBankName,
    intermediaryBankAddress,
    receiverAccount: receiverIban,
    feeAccount,
    payerAccount,
    custId: customerId,
    priority: priority ? '1' : '0',
    orderDate: format.parseDate(orderDate),
    valueDate: valueDate,
    isVerifyReceiverAccount: getFieldValue(DynamicFields.IS_VERIFY_RECEIVER_ACCOUNT, dynamicFields),
    isVerifyReceiverBankCorrAccount: getFieldValue(
      DynamicFields.IS_VERIFY_RECEIVER_BANK_CORR_ACCOUNT,
      dynamicFields,
    ),
    isVerifyIntermediaryCorrAccount: getFieldValue(
      DynamicFields.IS_VERIFY_INTERMEDIARY_CORR_ACCOUNT,
      dynamicFields,
    ),
  };
};

const fetchOrder: FetchOrderCb<Partial<ForeignPaymentFields>, Payload> = async (
  {
    customerId: defaultCustomerId,
    routeParams,
    queryParams: { payerAccount, type, copyFrom, customerId },
  },
  { setPayload },
) => {
  if (routeParams.id === NEW) {
    let detailFields;
    let orderType = type;
    let chosenCustomerId = customerId || defaultCustomerId;

    const sourceFields = await api.payments.getInternationalTransferSourceFields(
      foreignTransferSourceFields,
      true,
      chosenCustomerId,
    );

    const {
      receiverOptions,
      receiverBICOptions,
      feeTypesOptions,
      accountsOptions: payerAccountOptions,
      feeAccountsOptions,
      countryOptions,
      purposes,
      formFields: { orderNumber, orderDate, ...formFields },
    } = sourceFields;

    if (copyFrom) {
      const doc = await api.order.getNewOrderModel<DemandResponse>(copyFrom);
      detailFields = prepareFieldsForeignPayment(
        doc,
        payerAccountOptions,
        feeAccountsOptions,
        true,
      );

      detailFields = {
        ...detailFields,
        orderDate: format.parseDate(moment().toDate()),
      };

      orderType = doc.base.orderType;
      chosenCustomerId = detailFields.custId;
    }

    setPayload({
      payerAccountOptions,
      receiverOptions,
      receiverBICOptions,
      feeTypesOptions,
      priorityTypes,
      feeAccountsOptions,
      countryOptions,
      purposes,
    });

    const isExistPayerAccount = payerAccount && isExistAccount(payerAccount, payerAccountOptions);

    const hasSignRule = await hasSigningRules(
      payerAccountOptions,
      receiverOptions,
      orderType as OrderType,
      customerId ?? defaultCustomerId,
    );

    return {
      customerId: chosenCustomerId,
      number: orderNumber,
      id: null,
      checkIsAvailableSign: hasSignRule,
      label: translate('front.foreign-transfer.title-create-payment.label'),
      type: orderType || OrderType.ForeignTransfer,
      state: null,
      stateTranslate: null,
      actions: [],
      date: format.parseDate(moment().toDate()),
      detailFields: detailFields || {
        payerAccount: isExistPayerAccount ? payerAccount : null,
        isoCode: isExistPayerAccount
          ? getAccount(payerAccount, payerAccountOptions)?.content?.currency
          : null,
        ...formFields,
      },
    };
  }

  const doc = await api.order.getNewOrderModel<DemandResponse>(routeParams.id);

  const sourceFields = await api.payments.getInternationalTransferSourceFields(
    foreignTransferSourceFields,
    false,
    doc.base.customerId,
  );

  const {
    accountsOptions: payerAccountOptions,
    feeTypesOptions,
    receiverOptions,
    feeAccountsOptions,
    countryOptions,
  } = sourceFields;

  setPayload({
    payerAccountOptions,
    feeTypesOptions,
    feeAccountsOptions,
    priorityTypes,
    receiverOptions,
    countryOptions,
  });

  const isDraft = doc.base.orderState === OrderState.Draft;
  const { orderDate, custId, ...detailFields } = prepareFieldsForeignPayment(
    doc,
    payerAccountOptions,
    feeAccountsOptions,
    isDraft,
  );

  return {
    detailFields,
    customerId: custId,
    label: translate('front.foreign-transfer.title-payment.label'),
    id: doc.id,
    number: doc.base.orderNumber,
    date: getOrderDate(doc.base),
    state: doc.base.orderState,
    stateTranslate: doc.base.orderState,
    type: doc.base.orderType,
    actions: actions.filter(
      action =>
        doc.actions.some(({ actionName, confirmation }) => {
          if (confirmation) {
            action.payload = { confirmation };
          }
          return actionName === action.name;
        }) && isPaymentActionAllowed(doc?.base?.orderType, action?.name, doc?.base?.customerId),
    ),
  };
};

interface RequestDateForeignPaymentProps {
  formData: Partial<ForeignPaymentFields> & FormState;
  options: OnSaveOptions;
  order: Order<Partial<ForeignPaymentFields>>;
  pld: Payload;
}

export const prepareRequestDataForeignPayment = async ({
  order,
  options,
  formData,
  pld,
}: RequestDateForeignPaymentProps): Promise<PartialDemandRequest> => {
  const { payerAccountOptions, feeAccountsOptions } = pld;

  const orderField =
    order.type === OrderType.ForeignTransfer
      ? await api.payments.createOrderField(OrderType.ForeignTransfer)
      : await api.payments.createOrderField(OrderType.ForeignTransferESCROU);

  const payerAccountId = payerAccountOptions.find(item => item.value === formData.payerAccount)
    ?.content.id;

  const {
    saveReceiver,
    feeAccount,
    intermediaryCity,
    intermediaryCountryId,
    orderNumber,
    intermediaryId,
    receiverCity,
    receiverId,
    orderDate,
    priority,
    commission,
    isVerifyReceiverAccount,
    isVerifyReceiverBankCorrAccount,
    isVerifyIntermediaryCorrAccount,
    ...requestData
  } = formData;

  const accountFee = feeAccountsOptions.find(item => item.value === feeAccount);

  let dynamicFields = [
    getDynamicCheckboxField(
      DynamicFields.IS_VERIFY_RECEIVER_ACCOUNT,
      orderField,
      isVerifyReceiverAccount,
    ),
    getDynamicCheckboxField(
      DynamicFields.IS_VERIFY_RECEIVER_BANK_CORR_ACCOUNT,
      orderField,
      isVerifyReceiverBankCorrAccount,
    ),
    getDynamicCheckboxField(
      DynamicFields.IS_VERIFY_INTERMEDIARY_CORR_ACCOUNT,
      orderField,
      isVerifyIntermediaryCorrAccount,
    ),
  ];
  if (accountFee) {
    dynamicFields = [
      ...dynamicFields,
      {
        data: [
          {
            orderFieldId: getFieldId(DynamicFields.PROFIT_ACCOUNT, orderField),
            numericValue: accountFee.content.id,
            textValue: feeAccount,
            fieldValue: feeAccount,
            isFieldApproved: true,
          },
        ],
      } as DynamicField,
    ];
  }

  return {
    base: {
      commission,
      orderNumber,
      customerId: order.customerId,
      priority: Number(priority),
      orderDate: moment(orderDate).format('YYYY-MM-DD'),
      orderKind: options?.templateName ? OrderKind.TEMPLATE : undefined,
      orderAlias: options?.templateName,
      orderType: order.type as OrderType,
    },
    financial: {
      ...requestData,
      payerAccountId,
      receiverIban: requestData.receiverAccount,
      currencyRate: 1,
    },
    dynamicFields,
  };
};

const createOnSave: OnSaveCreator<Partial<ForeignPaymentFields>, Payload> =
  (order, pld) => async (formData, options) => {
    if (order.id && options?.templateName) {
      return await api.payments.createOrderTemplate(order.id, options?.templateName);
    }

    const orderRequest = await prepareRequestDataForeignPayment({
      order,
      options,
      formData,
      pld,
    });

    if (formData.savePaymentPurpose) {
      await api.payments.createPaymentPurpose({ purpose: formData.purpose });
    }

    if (formData?.saveReceiver) {
      const receivers = pld.receiverOptions
        .map(cp => cp.content)
        .filter(
          ({ counterparty: { internationalName, bin }, accounts }) =>
            internationalName === formData.receiverName &&
            bin === formData.receiverBic &&
            accounts.length,
        )
        .sort((a, b) => b.counterparty.id - a.counterparty.id);

      const bankId =
        formData.receiverId ||
        (await api.payments.getInternationBankByBic(formData.receiverBic))?.id;

      await api.payments.createCounterparty({
        accounts: [
          {
            bankId,
            agentAddress: formData.intermediaryBankAddress,
            agentBankCode: formData.intermediaryBankCode,
            agentBankName: formData.intermediaryBankName,
            agentCity: formData.intermediaryCity,
            agentCorrBankAcc: formData.intermediaryCorrAccount,
            agentCountryId: formData.intermediaryCountryId,
            agentId: formData.intermediaryId,
            bankAddress: formData.receiverBankAddress,
            bankCity: formData.receiverCity,
            bankCode: formData.receiverBic,
            bankCountryId: formData.receiverCountryId,
            bankName: formData.receiverBankName,
            corrBankAccount: formData.receiverBankCorrAccount,
            iban: formData.receiverAccount,
          },
        ],
        contracts: [],
        counterparty: {
          id: receivers?.length ? receivers[0].counterparty.id : null,
          address: formData.receiverAddress,
          bin: formData.receiverBic,
          countryId: formData.receiverCountryId,
          customerId: +order.customerId,
          internationalName: formData.receiverName,
          name: formData.receiverName,
        },
      });
    }

    if (!order.id) {
      return await api.payments.createPayment(orderRequest);
    }

    await api.payments.updatePayment(order.id, orderRequest);
    return order.id;
  };

export const ForeignPaymentPageComponent: React.FC = () => {
  const { order } = useOrder<ForeignPaymentFields, Payload>();

  return (
    <NavTabs>
      <NavTab
        title={translate('front.internal-payment-page.order-detail-tab.label')}
        path={pages.foreignPayment.tabs.details(':id')}
      >
        <ForeignPaymentDetails />
      </NavTab>
      {!!order?.id && (
        <NavTab
          title={translate('front.internal-payment-page.order-history-tab.label')}
          path={pages.foreignPayment.tabs.history(':id')}
        >
          <PaymentHistoryPage />
        </NavTab>
      )}
    </NavTabs>
  );
};

export const ForeignPaymentPage = withOrder<Partial<ForeignPaymentFields>, Payload>(
  ({ order }) => ({
    fetchOrder,
    createOnSave,
    allowSave:
      order &&
      isPaymentActionAllowed(order.type as OrderType, OrderActionType.EDIT, order.customerId),
    allowSign:
      order &&
      order.checkIsAvailableSign &&
      isPaymentActionAllowed(order.type as OrderType, OrderActionType.SIGN, order.customerId),
    allowState: true,
    allowDetails: true,
    allowTemplates: true,
    allowValueDate: true,
    afterSubmit: () => {
      goto(pages.foreignPayments);
    },
    disableButtons: true,
  }),
)(ForeignPaymentPageComponent);
