import * as React from 'react';

import * as format from 'components/utils/format';

interface CurrencyColumnProps {
  amount: number;
  currency: string;
  children?: React.ReactNode;
}

export const CurrencyColumn = (props: CurrencyColumnProps) => {
  const { amount, currency, children } = props;

  return (
    <>
      <span>{format.getAmountCurrency(amount, currency)}</span>
      {children}
    </>
  );
};
