import * as React from 'react';
// eslint-disable-next-line node/no-unpublished-import
import { createUseStyles } from 'react-jss';

import classnames from 'classnames';

import { ContainerContext } from './ContainerContext';
import { RowContext } from './RowContext';
import { ColProps, ContainerContextValue, SizeConfig, ColSizes } from './types';
import { toFlexAligns } from './utils';

const percentage = (value: number) => `${100 * value}%`;

const useStyles = createUseStyles({
  cell: ({ span, scale, gap, vAlign, hAlign, textAlign }: any) => {
    const alignStyles = (vAlign || hAlign) && {
      display: 'flex',
      justifyContent: toFlexAligns(hAlign),
      alignItems: toFlexAligns(vAlign),
    };
    return {
      textAlign,
      maxWidth: percentage(span / scale),
      flex: `0 0 ${percentage(span / scale)}`,
      padding: `0 ${gap}px`,
      boxSizing: 'border-box',
      ...alignStyles,
    };
  },
});

export const createCol = <S extends SizeConfig>() => {
  const Col = (props: React.PropsWithChildren<ColProps<S>>) => {
    const { children, hAlign, vAlign, textAlign, className, ...colSizes } = props;

    const { span, scale } = useCol(colSizes as any); // TODO!!!
    const classes = useStyles({
      span,
      scale,
      hAlign,
      vAlign,
      textAlign,
      gap: 10,
    });

    return <div className={classnames(classes.cell, { [className]: className })}>{children}</div>;
  };
  return Col;
};

const useCol = <S extends SizeConfig>(colSizes: ColSizes<S>) => {
  const { size, sizeConfig } = React.useContext<ContainerContextValue<S>>(ContainerContext);
  const { scale } = React.useContext(RowContext);

  const span = calculateSpan(size, colSizes, sizeConfig) || scale;
  return { span, scale };
};

const calculateSpan = <S extends SizeConfig>(
  currentSize: keyof S,
  colSizes: ColSizes<S>,
  sizeConfig: S,
) => {
  let span: number;
  for (const size of Object.keys(sizeConfig)) {
    span = colSizes[size] || span;
    if (currentSize === size) {
      break;
    }
  }
  return span;
};
