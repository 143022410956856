import { BeforeAfterModifier } from 'react-day-picker/types/Modifiers';

import { isValidIBAN } from 'ibantools';
import moment from 'moment';

import { api } from 'api';
import {
  DemandType,
  LinkedTableEnum,
  OrderType,
  VisualComponentEnum,
} from 'api/DemandsService/enums';
import {
  DemandResponse,
  DynamicField,
  DynamicFieldBase,
  DynamicFieldConstraint,
  DynamicFieldData,
  DynamicFieldEssentials,
  DynamicFieldValue,
  PartialDemandRequest,
  PartialDynamicField,
} from 'api/DemandsService/interfaces';
import { DocumentManagementStateEnum } from 'api/DocumentManagementService/enum';
import { OrderActionType, OrderState } from 'api/enums';
import { Order } from 'api/OrderService';
import { Option } from 'api/Service';
import { FetchOrderCb } from 'components/Document/withOrder';
import { Value as FileValue } from 'components/forms/fileInput/FileInput';
import { FinalValue, FormState } from 'components/forms/ValidatingForm/FormContext';
import * as format from 'components/utils/format';
import { translate } from 'i18n/translator';
import { allowDemandAction, allowPrivilegy } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { NEW } from 'navigations/routes';
import { AcquiringFieldNames } from 'pages/Demands/AcuiringDemand/enums';
import { AuthLetterNames } from 'pages/Demands/AuthLetterDemand/enums';
import { CreditProlongationDetailsName } from 'pages/Demands/CreditDemand/enums';
import { CurrencyDocumentNames } from 'pages/Demands/CurrencyDocument/enums';
import { actions } from 'pages/Demands/DemandPage';
import { DepositDetailNames } from 'pages/Demands/DepositDemand/enums';
import {
  EmployeeManagementNames,
  EmployeeNewNames,
  EmployeeReissueNames,
} from 'pages/Demands/EmployeeManagment/enums';
import { FieldsNameEnums, getSubmitParams } from 'pages/Demands/interfaces';
import { NewSalaryDetailNames } from 'pages/Demands/NewSalaryDemand/enums';
import { SMSNotificationDetailNames } from 'pages/Demands/SmsDemandPage/enums';
import { SalaryNames } from 'pages/Salary/enums';
import { getClearPhone } from 'utils/getClearPhone';
import { getMaskedPhone } from 'utils/getMaskedPhone';
import { isExist } from 'utils/isData';

import { CardFieldNames } from './CardDemand/enums';

export const today: Date = moment().toDate();

export const disabledDaysBeforeToday: BeforeAfterModifier = {
  before: today,
  after: null,
};

export const disableDaysAfterToday: BeforeAfterModifier = {
  before: null,
  after: today,
};

export const cashTransferGroup = [
  DemandType.CashWithdrawalOrder,
  DemandType.CashPreparationOrder,
  DemandType.CashTransferOrder,
];

export const currencyExchangeGroup = [
  DemandType.CurrencyBuy,
  DemandType.CurrencySale,
  DemandType.CurrencyConversion,
];

export const creditDemandGroup = [
  DemandType.CreditProlongationOrder,
  DemandType.NewCreditTranchOrder,
  DemandType.CreditFundsOrder,
];

export const smsDemandGroup = [
  DemandType.SMSNotificationOrder,
  DemandType.ChangeSMSNotificationOrder,
];

export const cardDemandGroup = [
  DemandType.CardBlockingOrder,
  DemandType.CardClosingOrder,
  DemandType.CardReissueOrder,
  DemandType.NewCardOrder,
  DemandType.ServiceDescOrder,
];

export const partialDemandGroup = [
  DemandType.AcquiringNewTerminalOrder,
  DemandType.AuthLetter,
  DemandType.FEAMessage,
];

export const depositDemandGroup = [
  DemandType.DepositClosingOrder,
  DemandType.DepositChangingOrder,
  DemandType.DepositProlongationOrder,
  DemandType.NewPlacementTrancheOrder,
  DemandType.NewContractTermDeposit,
  DemandType.NewDepositGeneralOrder,
];

export const CHECK_ORDER_TYPES = [OrderType.DomesticTransfer];

export const isAuthLetter = (orderType: DemandType) => orderType === DemandType.AuthLetter;
export const isFEAMessage = (orderType: DemandType) => orderType === DemandType.FEAMessage;

export const getUpdatedAcc = (
  acc: PartialDynamicField[],
  data: Partial<DynamicFieldData>[],
  field: Partial<DynamicFieldBase>,
): PartialDynamicField[] => [...acc, { data, field }];

export const FILE_SEPARATOR = ';base64,';
export const TYPE_SEPARATOR = 'data:';

export const toDynamicFields = <T>(demandFields: T, dynamicFields: Partial<DynamicField>[]) =>
  Object.entries(demandFields).reduce((acc: PartialDynamicField[], [field, value]) => {
    const { field: fieldBase, values } =
      dynamicFields.find(({ field: fieldBase }) => fieldBase.code === field) || {};

    if (!fieldBase || !isExist(value)) {
      return acc;
    }

    switch (fieldBase.visualComponent) {
      case VisualComponentEnum.SELECT: {
        const selected = values?.find(({ id, value: val }) =>
          [id?.toString(), val].includes(value?.toString()),
        );

        return getUpdatedAcc(
          acc,
          [
            {
              numericValue: Number.isNaN(+selected?.id) ? null : +selected?.id,
              textValue: selected?.label,
              orderFieldId: fieldBase.id,
              isFieldApproved: true,
              fieldValue: selected?.value,
              managerComment: null,
            },
          ],
          fieldBase,
        );
      }

      case VisualComponentEnum.FILE: {
        return getUpdatedAcc(
          acc,
          (value as any[])?.map(({ value, field, attachment }) => {
            const [type, content] = value.split(FILE_SEPARATOR);
            const [, typeValue] = type.split(TYPE_SEPARATOR);

            return {
              orderFieldId: fieldBase.id,
              textValue: field,
              isFieldApproved: true,
              managerComment: null,
              attachment: {
                name: field,
                type: attachment?.type ?? typeValue,
                content,
              },
            };
          }),
          fieldBase,
        );
        return acc;
      }

      case VisualComponentEnum.AMOUNT:
      case VisualComponentEnum.NUMBER: {
        return getUpdatedAcc(
          acc,
          [
            {
              numericValue: Number(value),
              orderFieldId: fieldBase.id,
              isFieldApproved: true,
              managerComment: null,
            },
          ],
          fieldBase,
        );
      }

      case VisualComponentEnum.CHECKBOX: {
        return getUpdatedAcc(
          acc,
          [
            {
              booleanValue: Boolean(value),
              orderFieldId: fieldBase.id,
              isFieldApproved: true,
              managerComment: null,
            },
          ],

          fieldBase,
        );
      }

      case VisualComponentEnum.PHONE: {
        return getUpdatedAcc(
          acc,
          [
            {
              fieldValue: getClearPhone(value),
              textValue: getClearPhone(value),
              orderFieldId: fieldBase.id,
              isFieldApproved: true,
              managerComment: null,
            },
          ],
          fieldBase,
        );
      }

      default: {
        return getUpdatedAcc(
          acc,
          [
            {
              fieldValue: value,
              textValue: value,
              orderFieldId: fieldBase.id,
              isFieldApproved: true,
              managerComment: null,
            },
          ],
          fieldBase,
        );
      }
    }
  }, []);

export const getDetailFields = (
  dynamicFields: DynamicField[],
  orderState?: OrderState,
): FormState =>
  dynamicFields?.reduce((acc, dynamicField: DynamicField) => {
    let value;
    const { field, data, values } = dynamicField;

    switch (field.visualComponent) {
      case VisualComponentEnum.SELECT: {
        const allValues = values ?? [];
        const checkValueInOptions = getValidatorConstraint(dynamicField, 'checkValueInOptions');
        // TODO check the parameter checkValueInOptions, they always was empty
        if (isObjectNotEmpty(checkValueInOptions)) {
          const numericValue = toSafetyFieldValue(data).numericValue;

          const defaultValue = (allValues as DynamicFieldValue[])?.find(
            fieldValue => `${fieldValue.id}` === `${numericValue}`,
          )?.id;

          value = data[0]?.numericValue ?? data[0]?.fieldValue ?? defaultValue;
        } else {
          const fieldDataValue = toSelectValue(data);

          const isValueExist = (allValues as DynamicFieldValue[]).some(
            value =>
              `${value.value}` === `${fieldDataValue}` || `${value.id}` === `${fieldDataValue}`,
          );

          if (!orderState || orderState === OrderState.Draft) {
            value = isValueExist ? fieldDataValue : null;
          } else {
            value = isValueExist ? fieldDataValue : toSafetyFieldValue(data).textValue;
          }
        }

        break;
      }

      case VisualComponentEnum.NUMBER:
      case VisualComponentEnum.AMOUNT: {
        value = data[0]?.numericValue.toString();
        break;
      }

      case VisualComponentEnum.FILE: {
        value = getFileValue(data, orderState);
        break;
      }

      case VisualComponentEnum.PHONE: {
        value = getMaskedPhone(data[0]?.fieldValue);
        break;
      }

      case VisualComponentEnum.BOOLEAN:
      case VisualComponentEnum.CHECKBOX: {
        value = data[0]?.booleanValue;
        break;
      }

      case VisualComponentEnum.DATE:
      case VisualComponentEnum.DATERANGE: {
        value = format.parseDate(toSafetyFieldValue(data)?.textValue);
        break;
      }

      default: {
        value = data[0]?.textValue;
      }
    }

    return { ...acc, [field.code]: value };
  }, {});

export const isDemandActionVisible = (
  order: DemandResponse,
  action: OrderActionType,
  privilege?: Privileges,
): boolean =>
  order.actions.some(item => item.actionName === action) &&
  (privilege
    ? allowPrivilegy([privilege])
    : allowDemandAction(order.base.orderType as DemandType, action, order.base.customerId));

export const getOptions = (values: DynamicFieldValue[]): Option<DynamicFieldValue>[] =>
  values.map(item => ({
    label: item.label,
    value: item.id,
    content: item,
  }));

export const formattedAccountsLabel = (label: string) =>
  label.replace(/\/\s*(\D+\s\d+?(\s\d*)*?\.\d+)\s*\//g, (match, number) =>
    match.replace(number, number.replace('.', ',')),
  );

export const getAccountOptions = (values: DynamicFieldValue[]): Option<DynamicFieldValue>[] =>
  values.map(item => ({
    label: formattedAccountsLabel(item.label),
    value: item.id,
    content: item,
  }));

export const getPayload = (dynamicFields: DynamicField[], fieldNames: string[]) =>
  dynamicFields.reduce((acc: Record<string, Option<DynamicFieldValue>[]>, field) => {
    if (fieldNames.includes(field.field.code)) {
      const isAccountField = field.field.linkedTable === LinkedTableEnum.Accounts;

      acc[field.field.code] = isAccountField
        ? getAccountOptions(field.values)
        : getOptions(field.values);
    }
    return acc;
  }, {});

export const getSubmitPage = ({ id, type, copyFrom }: getSubmitParams) => {
  switch (true) {
    case type === OrderType.SalarySheet:
      return goto(pages.salary.tabs.details(id, { copyFrom }));

    case cashTransferGroup.includes(type as DemandType):
      return goto(pages.cashTransfer.tabs.details(id, { copyFrom, type }));

    case currencyExchangeGroup.includes(type as DemandType):
      return goto(pages.currencyExchanges.tabs.details(id, { copyFrom, type }));

    case creditDemandGroup.includes(type as DemandType):
      return goto(pages.creditDemand.tabs.details(id, { copyFrom, type }));

    case smsDemandGroup.includes(type as DemandType):
      return goto(pages.smsDemand.tabs.details(id, { copyFrom, type }));

    case type === DemandType.NewSalaryContract:
      return goto(pages.newSalaryDemand.tabs.details(id, { copyFrom, type }));

    case cardDemandGroup.includes(type as DemandType):
      return goto(pages.cardDemand.tabs.details(id, { copyFrom, type }));

    case partialDemandGroup.includes(type as DemandType):
      return goto(pages.partialDemands.tabs.details(type, id, { copyFrom, type }));

    case depositDemandGroup.includes(type as DemandType):
      return goto(pages.depositDemand.tabs.details(id, { copyFrom, type }));

    // default:
    //   return goto(pages.demand.tabs.details(id, { copyFrom, type }));
  }
};

export const getFieldNames = (orderType: DemandType): FieldsNameEnums[] => {
  const DemandTypes: Partial<Record<DemandType, FieldsNameEnums[]>> = {
    [DemandType.CreditProlongationOrder]: [CreditProlongationDetailsName.Credit],
    [DemandType.CreditFundsOrder]: [
      CreditProlongationDetailsName.Credit,
      CreditProlongationDetailsName.Account,
    ],
    [DemandType.NewCreditTranchOrder]: [
      CreditProlongationDetailsName.Credit,
      CreditProlongationDetailsName.OwnFeeAccount,
    ],
    [DemandType.NewSalaryContract]: [NewSalaryDetailNames.Branch],
    [DemandType.CardBlockingOrder]: [CardFieldNames.Card, CardFieldNames.BlockReason],
    [DemandType.CardReissueOrder]: [CardFieldNames.Card, CardFieldNames.ReissueReason],
    [DemandType.ServiceDescOrder]: [CardFieldNames.Card],
    [DemandType.CardClosingOrder]: [CardFieldNames.CardAccount],
    [DemandType.NewCardOrder]: [
      CardFieldNames.CardAccount,
      CardFieldNames.TypePacket,
      CardFieldNames.NewCardType,
      CardFieldNames.Sex,
      CardFieldNames.BirthCountry,
      CardFieldNames.DocumentType,
      CardFieldNames.RegCountry,
      CardFieldNames.LiveCountry,
      CardFieldNames.Branch,
    ],
    [DemandType.SMSNotificationOrder]: [SMSNotificationDetailNames.Card],
    [DemandType.ChangeSMSNotificationOrder]: [SMSNotificationDetailNames.Card],
    [DemandType.FEAMessage]: [CurrencyDocumentNames.Topic],
    [DemandType.AcquiringNewTerminalOrder]: [
      AcquiringFieldNames.AcquiringMerchant,
      AcquiringFieldNames.AdressMerchant,
      AcquiringFieldNames.ConnectionType,
    ],
    [DemandType.AuthLetter]: [AuthLetterNames.Topic],
    [DemandType.DepositClosingOrder]: [DepositDetailNames.Deposit],
    [DemandType.DepositChangingOrder]: [
      DepositDetailNames.RadioMainAcc,
      DepositDetailNames.Deposit,
      DepositDetailNames.OwnMainAccount,
      DepositDetailNames.RadioFeeAcc,
      DepositDetailNames.OwnFeeAccount,
    ],
    [DemandType.DepositProlongationOrder]: [
      DepositDetailNames.Deposit,
      DepositDetailNames.RadioMainAcc,
      DepositDetailNames.OwnMainAccount,
    ],

    [DemandType.NewPlacementTrancheOrder]: [
      DepositDetailNames.ToContract,
      DepositDetailNames.Currency,
      DepositDetailNames.PeriodInterestPayment,
      DepositDetailNames.RadioPlacementAccTranche,
      DepositDetailNames.PlacementOwnAccountTranche,
      DepositDetailNames.RadioInterestAccTranche,
      DepositDetailNames.InterestOwnAccountTranche,
      DepositDetailNames.DepositAgreements,
      DepositDetailNames.FormContractRadio,
      DepositDetailNames.RadioAmountAccTranche,
      DepositDetailNames.AmountOwnAccountTranche,
    ],
    [DemandType.NewContractTermDeposit]: [
      DepositDetailNames.DepositType,
      DepositDetailNames.DepositCurrency,
      DepositDetailNames.PeriodInterestPayment,
      DepositDetailNames.PlacementOwnIbanTranche,
      DepositDetailNames.RadioPlacementAccTerm,
      DepositDetailNames.PlacementOwnAccountTerm,
      DepositDetailNames.RadioInterestAccTerm,
      DepositDetailNames.InterestOwnAccountTerm,
      DepositDetailNames.RadioAmountAccTerm,
      DepositDetailNames.AmountOwnAccountTerm,
      DepositDetailNames.ChannelTypeSend,
    ],
    [DemandType.NewDepositGeneralOrder]: [
      DepositDetailNames.DepositType,
      DepositDetailNames.DepositCurrency,
      DepositDetailNames.ChannelTypeSend,
    ],
    [DemandType.SalaryEmployeeDismissal]: [
      EmployeeManagementNames.SalaryDeal,
      EmployeeManagementNames.EmployeeList,
    ],
    [DemandType.SalaryEmployeeNew]: [
      EmployeeNewNames.SalaryDeal,
      EmployeeNewNames.CardType,
      EmployeeNewNames.Branch,
      EmployeeNewNames.BranchAddress,
      EmployeeNewNames.Gender,
      EmployeeNewNames.BirthCountry,
      EmployeeNewNames.DocumentType,
      EmployeeNewNames.RegCountry,
      EmployeeNewNames.LiveCountry,
    ],
    [DemandType.SalaryEmployeeReissue]: [
      EmployeeReissueNames.SalaryDeal,
      EmployeeReissueNames.ReissueReason,
      EmployeeReissueNames.EmployeeList,
    ],
    [DemandType.SalarySheet]: [
      SalaryNames.SalaryDeal,
      SalaryNames.SalaryType,
      SalaryNames.AccountTotal,
      SalaryNames.AccountFee,
    ],
  };

  return DemandTypes[orderType];
};

export const getOption = (id: FinalValue, options: Option[]) =>
  options?.find(option => option.value === `${id}`);

export const validateIban = (value: string) => {
  if (isValidIBAN(value)) {
    return '';
  }

  return translate('front.form.account-number.error');
};

export const normalize = (value: string) => {
  if (value && value.startsWith(' ')) {
    return value.trim();
  }
  return value;
};

export const getFileValue = (
  fieldData: DynamicFieldData[],
  orderState?: OrderState,
): FileValue[] => {
  const data: FileValue[] = fieldData
    .reduce((acc, file) => {
      const { textValue, fieldValue, attachment, id } = toSafetyFieldValue([file]);

      if (textValue && fieldValue) {
        acc = [
          ...acc,
          {
            field: textValue,
            value: fieldValue,
            isValid: validateFileExtension(textValue),
            attachment,
          },
        ];

        const hasP7sFile = file.booleanValue;
        const isCopy = orderState && orderState !== OrderState.Draft;

        if (hasP7sFile && isCopy) {
          acc = [
            ...acc,
            {
              field: `${textValue}.p7s`,
              value: fieldValue,
              isValid: validateFileExtension(textValue),
              attachment,
              downloadAction: () => api.demands.downloadDemandFile(id, hasP7sFile),
            },
          ];
        }
      }

      return acc;
    }, [])
    .filter(value => value);

  return data.length ? data : null;
};

export const validateFileExtension = (fileName: string, extension?: string[]): boolean => {
  const dotIndex = fileName?.lastIndexOf('.');

  if (!fileName || dotIndex < 1 || fileName.length === dotIndex + 1) {
    return false;
  }

  if (!extension?.length) {
    return true;
  }

  const ext = fileName.substring(dotIndex + 1).toLowerCase();
  return extension.map(it => it.toLowerCase()).includes(ext);
};

export const isObjectNotEmpty = (obj: Obj): boolean => !!Object.keys(obj).length;

export const getValidatorConstraint = (
  dynamicField: DynamicField,
  checkType: string,
): DynamicFieldConstraint => {
  return (
    dynamicField.constraints?.find(
      item => item.checkType.toLowerCase() === checkType.toLowerCase(),
    ) || ({} as DynamicFieldConstraint)
  );
};

export const toSelectValue = (fieldData: DynamicFieldData[]) => {
  const numericValue = toSafetyFieldValue(fieldData).numericValue;
  const fieldValue = toSafetyFieldValue(fieldData).fieldValue;

  // Set numericValue || fieldValue || null
  return numericValue ? String(numericValue) : fieldValue ? fieldValue : null;
};

export const toSafetyFieldValue = (fieldData: DynamicFieldData[]): DynamicFieldData => {
  return fieldData?.length
    ? fieldData[0]
    : {
        id: null,
        orderId: null,
        booleanValue: false,
        datetimeValue: null,
        numericValue: null,
        textValue: null,
        fieldValue: null,
        orderFieldId: null,
        managerComment: null,
        isFieldApproved: false,
      };
};

const orderStates = new Set([
  OrderState.Sent,
  OrderState.PartiallySigned,
  OrderState.Signed,
  OrderState.Ready,
  OrderState.SentToBank,
  OrderState.SendingToBank,
  OrderState.Executed,
  OrderState.Rejected,
  OrderState.Revoked,
  DocumentManagementStateEnum.NeedSigningPartiallySigned,
  DocumentManagementStateEnum.NeedSigningSigned,
  DocumentManagementStateEnum.PartiallySignedNeedSigning,
  DocumentManagementStateEnum.PartiallySignedPartiallySigned,
  DocumentManagementStateEnum.PartiallySignedSigned,
  DocumentManagementStateEnum.SignedSigned,
]);

export const isSignedDocuments = (state: OrderState | DocumentManagementStateEnum) =>
  orderStates.has(state);

export const getActions = (demand: DemandResponse) =>
  actions.filter(action =>
    demand.actions.some(({ actionName, confirmation }) => {
      if (confirmation) {
        action.payload = { confirmation };
      }
      return (
        actionName === action.name &&
        allowDemandAction(demand.base.orderType as DemandType, actionName, demand.base.customerId)
      );
    }),
  );

export const makeFields = (demand: DemandResponse, id: number) => ({
  id,
  customerId: demand.base.customerId,
  label: demand.base.translatedOrderType,
  number: demand.base.orderNumber,
  type: demand.base.orderType,
  state: demand.base.orderState,
  stateTranslate: demand.base.orderStateLabel,
  date: format.parseDate(demand.base.orderDate),
  actions: getActions(demand),
});

export const fetchOrder: FetchOrderCb<DynamicFieldEssentials> = async (
  { customerId, routeParams, queryParams },
  { setPayload },
): Promise<Order> => {
  const { type, copyFrom } = queryParams;
  const { id } = routeParams;
  let demand;
  let number;
  let fields;
  let payload;

  if (id !== NEW) {
    demand = await api.demands.getDemand(id);

    payload = getPayload(demand.dynamicFields, getFieldNames(type as DemandType));

    setPayload({
      ...payload,
      dynamicFields: demand.dynamicFields,
    });

    return {
      ...makeFields(demand, Number(id)),
      detailFields: getDetailFields(demand.dynamicFields, demand.base.orderState),
    };
  }

  if (copyFrom) {
    demand = await api.demands.getDemand(copyFrom);

    payload = getPayload(demand.dynamicFields, getFieldNames(type as DemandType));

    setPayload({
      ...payload,
      dynamicFields: demand.dynamicFields,
    });

    number = await api.payments.getDocumentNumber({
      customerId: demand.base.customerId,
      orderType: demand.base.orderType,
      orderDate: moment().format('YYYY-MM-DD'),
    });

    fields = {
      ...makeFields(demand, Number(queryParams.copyFrom)),
      number,
      id: null,
      state: null,
      stateTranslate: null,
      date: moment().toDate(),
      actions: [],
      detailFields: getDetailFields(demand?.dynamicFields, OrderState.Draft),
    };
  } else {
    number = await api.payments.getDocumentNumber({
      customerId,
      orderType: type,
      orderDate: moment().format('YYYY-MM-DD'),
    });

    demand = await api.demands.getDemandTemplate(queryParams.type, customerId);

    fields = {
      number,
      customerId,
      id: null,
      label: demand.orderTypeModel.name.text,
      type: queryParams.type,
      state: null,
      stateTranslate: null,
      date: moment().toDate(),
      actions: [],
    };

    payload = getPayload(demand.orderFieldModel, getFieldNames(type as DemandType));

    setPayload({
      ...payload,
      dynamicFields: demand.orderFieldModel,
    });
  }

  return fields;
};

export const createOnSave =
  (order: Order, payload: DynamicFieldEssentials) => async (formData: FormState) => {
    const { orderNumber, orderDate, valueDate, ...demandFields } = formData;
    const { id, type } = order;
    const dynamicFields = toDynamicFields(demandFields, payload.dynamicFields);

    const orderData: PartialDemandRequest = {
      dynamicFields,
      base: {
        orderNumber,
        orderDate: format.localDate(orderDate as Date),
        customerId: order.customerId,
        orderType: type,
      },
    };

    if (!id) {
      return await api.demands.saveOrder(orderData);
    }

    await api.demands.updateOrder(id, orderData);
    return id;
  };

export const demandsStates: Option[] = [
  {
    value: '',
    label: 'front.acquiring.demands.statuses.all.label',
  },
  {
    value: `${OrderState.InWork}, ${OrderState.OnExecution}, ${OrderState.RetainedByBank}`,
    label: 'front.acquiring.demands.statuses.working.label',
  },
  {
    value: OrderState.Executed,
    label: 'front.acquiring.demands.statuses.done.label',
  },
];
export const getDynamicField = (code: string, dynamicFields: DynamicField[]) =>
  dynamicFields?.find(item => item.field.code === code);

export const isSignAuthLetterFiles = (dynamicFields: DynamicField[]): boolean =>
  getDynamicField(AuthLetterNames.SignFiles, dynamicFields)?.data[0]?.booleanValue;
