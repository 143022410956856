import * as React from 'react';

import { useField } from 'components/forms/ValidatingForm/components/useField';
import { TTooltip } from 'components/Tooltip/Tooltip';
import { validateMessages } from 'components/validateMessages';
import { translate } from 'i18n/translator';

import { PasswordInput } from '../passwordInput/PasswordInput';
import { FieldInput } from './FieldInput';
import { PreloadField } from './PreoadField';
import { TextFieldProps } from './types';

interface PasswordProps extends TextFieldProps {
  label: string;
  name: string;
  absoluteTooltip?: TTooltip;
  defaultValue?: string;
  disabled?: boolean;
  isLargeInput?: boolean;
  onChange?: (value: string) => void;
  required?: boolean;
  tooltip?: TTooltip;
  validate?: (value: string) => string | null;
  warn?: (value: string) => string | null;
}

export const PasswordField = ({
  name,
  label,
  required,
  minLength,
  maxLength,
  length,
  disabled,
  defaultValue,
  onChange,
  tooltip,
  absoluteTooltip,
  isLargeInput,
  warn: externalWarn = () => null,
  validate: externalValidate,
}: React.PropsWithChildren<PasswordProps>) => {
  const [isFocus, setFocus] = React.useState<boolean>(false);

  const toggleFocus = (isFocus: boolean) => () => setFocus(isFocus);
  const validate = React.useCallback(
    value => {
      if (required) {
        if (!value) {
          return translate(validateMessages.fieldRequired);
        }
      }
      if (maxLength) {
        if (value.length > maxLength) {
          return translate(validateMessages.moreThan(maxLength));
        }
      }

      if (minLength) {
        if (value.length < minLength) {
          return translate(validateMessages.lessThan(minLength));
        }
      }

      if (length) {
        if (value.length !== length) {
          return translate(validateMessages.shouldBe(length));
        }
      }

      if (externalValidate) {
        return translate(externalValidate(value));
      }
    },
    [required, maxLength, minLength, length, externalValidate],
  );

  const warn = React.useCallback(externalWarn, []);

  const onPasswordFieldChange = React.useCallback((e, onBaseChange) => {
    const value = e?.target?.value || '';
    onBaseChange(value);
    if (onChange) onChange(value);
  }, []);

  const { field, form } = useField({ name, defaultValue, validate, warn });

  if (!field && !form) {
    return null;
  }

  const isDisabled = disabled || form.disabled || form.progress;

  return (
    <PreloadField isLoading={form.initializing}>
      <FieldInput
        name={name}
        label={label}
        error={field.error}
        warning={field.warning}
        value={field.value}
        tooltip={tooltip}
        absoluteTooltip={absoluteTooltip}
        isFocus={isFocus}
        isDisabled={isDisabled}
        isLargeInput={isLargeInput}
      >
        <PasswordInput
          id={name}
          type="password"
          name={name}
          value={field.value || ''}
          onChange={e => onPasswordFieldChange(e, field.onChange)}
          onBlur={() => {
            field.onBlur();
            toggleFocus(false)();
          }}
          onFocus={toggleFocus(true)}
          disabled={isDisabled}
          autoComplete={`new-${name}`}
          isLargeInput={isLargeInput}
        />
      </FieldInput>
    </PreloadField>
  );
};
