import * as React from 'react';
import { Col, Row, Container } from 'react-grid';

import { api } from 'api';
import { registrationPages } from 'api/RegistrationService';
import { FILETYPE_PRESETS, Option } from 'api/Service';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { FILE_ACCEPTS } from 'components/forms/inputs/FileField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { Checked } from 'components/icons';
import { translate } from 'i18n/translator';
import { pages } from 'navigations/pages';
import { ActivationContext } from 'pages/Registration/activation/ActivationContext';
import { ScanType } from 'pages/Registration/RegistrationFilesPage';

interface Payload {
  devices: Option[];
}

const fileButton = React.createRef<HTMLInputElement>();

const ActivationFilesPageForm: React.FC = () => {
  const { confirmScan, removeFile, getFile, getScanTypes, addFile, createLink, openLink } =
    useActivationFiles();

  const [scan, setScan] = React.useState<ScanType>({});
  const [scans, setScans] = React.useState<ScanType[]>();

  const { progress, error, handleSubmit } = useForm<any, Payload>(async () => {
    await updateScans();
  });

  const updateScans = async () => {
    const scans = await getScanTypes();
    setScans(scans);
    setScan(scans.find(item => !item.url));
  };

  const uploadFile = async (e: React.MouseEvent<HTMLAnchorElement>, label: string) => {
    e.preventDefault();
    await getFile(label);
  };

  const deleteFile = async (e: React.MouseEvent<HTMLAnchorElement>, label: string) => {
    e.preventDefault();
    await removeFile(label);
    await updateScans();
  };

  const addScan = async () => {
    await addFile(scan);
    await updateScans();
  };

  return (
    <>
      <div className="">
        <div className="">
          <div className="card-body">
            <div className="login-page__body__box__title">
              {translate('front.activation.files-page.title.label')}
            </div>
            <Button
              color="primary"
              onClick={() => createLink()}
              progress={progress}
              type="submit"
              // size="lg"
            >
              {translate('front.activation.files-page.button-send-sms.label')}
            </Button>
            <Button
              color="secondary"
              onClick={updateScans}
              disabled={progress}
              // size="lg"
            >
              {translate('front.activation.files-page.button-update.label')}
            </Button>
            <DefaultForm>
              <FormError>{error}</FormError>
              {scans?.map(item => (
                <div>
                  <span
                    style={{
                      display: 'flex',
                      color: item.type === scan?.type ? 'initial' : item.url ? 'green' : 'grey',
                    }}
                    onClick={() => setScan(item)}
                  >
                    {!!item.url && <Checked />}
                    {registrationPages[item.type]}
                  </span>
                </div>
              ))}
              {(scan?.file || scan?.url) && (
                <img style={{ width: '100%' }} src={scan.url || URL.createObjectURL(scan.file)} />
              )}
              {scan?.url && (
                <div>
                  <a href="" onClick={async e => uploadFile(e, scan.type)}>
                    {translate('front.activation.files-page.button-download.label')}
                  </a>
                  <a href="" onClick={async e => deleteFile(e, scan.type)}>
                    {translate('front.activation.files-page.button-cancel.label')}
                  </a>
                </div>
              )}
              <Container>
                <Row>
                  {scan && (
                    <Col sm={6}>
                      <Button progress={progress} onClick={() => fileButton.current.click()}>
                        {translate('front.activation.files-page.button-select-photo.label')}
                        <input
                          ref={fileButton}
                          type="file"
                          onChange={e => {
                            const file = e.target.files[0];
                            setScan(item => ({
                              ...item,
                              file,
                              url: null,
                              photo: null,
                            }));
                            fileButton.current.value = null;
                          }}
                          className="upload-file__label_file"
                          accept={FILE_ACCEPTS[FILETYPE_PRESETS.IMAGES].join(',')}
                        />
                      </Button>
                    </Col>
                  )}
                  {(scan?.file || scan?.photo) && (
                    <Col sm={6}>
                      <Button
                        color="primary"
                        onClick={e => handleSubmit(addScan, e)}
                        progress={progress}
                      >
                        {translate('front.activation.files-page.button-send.label')}
                      </Button>
                    </Col>
                  )}
                </Row>
              </Container>
              {scans?.every(({ url }) => !!url) && (
                <Button
                  color="primary"
                  onClick={e => handleSubmit(confirmScan, e)}
                  progress={progress}
                  type="submit"
                  size="lg"
                >
                  {translate('front.activation.files-page.button-continue.label')}
                </Button>
              )}
            </DefaultForm>
            <p>{translate('front.activation.files-page.description.label')}</p>
            <a href="" onClick={openLink}>
              {translate('front.activation.files-page.button-photo.label')}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export const ActivationFilesPage = withForm(ActivationFilesPageForm);

const useActivationFiles = () => {
  const { step, setStep } = React.useContext(ActivationContext);

  const addFile = async (scan: ScanType) => {
    return await api.registration.addScan(step.orderId, scan.file || scan.photo, scan.type);
  };

  const getScanTypes = async (): Promise<ScanType[]> => {
    const data = await api.registration.getScanStatus(step.orderId, step.uuid);
    return Object.entries(data.pages).map(([key, value]) => ({
      type: key,
      url: value === 'null' ? null : value,
      file: undefined,
    }));
  };

  const getFile = async (attachmentPurpose: string) => {
    return await api.registration.getFile(step.orderId, step.uuid, attachmentPurpose);
  };

  const removeFile = async (attachmentPurpose: string) => {
    await api.registration.removeScan(step.orderId, attachmentPurpose);
  };

  const createLink = async () => {
    await api.registration.createLink(step.orderId);
  };

  const openLink = async () => {
    window.open(pages.registrationFiles(step.orderId, step.uuid));
  };

  const confirmScan = async () => {
    setStep(await api.registration.confirmScan(step.orderId));
  };

  return {
    removeFile,
    getFile,
    getScanTypes,
    confirmScan,
    addFile,
    openLink,
    createLink,
  };
};
