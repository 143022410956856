import dateFormat from 'dateformat';
import { Decimal } from 'decimal.js-light';
import moment from 'moment';

import { getFormattedValue } from 'components/forms/inputs/amountFields/utils';
import { isExist } from 'utils/isData';

const DD_MM_YYYY = 'DD.MM.YYYY';

const DATE_TIME_LIKE_REGEX = /^\d{4}-\d{2}-\d{2}$/;

export function convertLocalDateToDateObj(data: string | Date): Date | string {
  return DATE_TIME_LIKE_REGEX.test(data as string) ? moment(data).toDate() : data;
}

export function date(date: Date | string, nullValue: string = undefined): string {
  if (!date) {
    return nullValue;
  }

  return moment(convertLocalDateToDateObj(date as string)).format(DD_MM_YYYY);
}

export function parseDate(date: string | Date): Date {
  if (!date) return null;

  return moment(convertLocalDateToDateObj(date), DD_MM_YYYY).toDate();
}

export function dateField(d: Date): string {
  return date(d, '');
}

export function dateRange(from: Date, to: Date): string {
  if (!from && !to) return '';

  return from && !to ? dateField(from) : `${dateField(from)} - ${dateField(to)}`;
}

const convertMsToDays = (time: number): number => Math.trunc(time / (1000 * 60 * 60 * 24));

/** Difference with current date in days **/
export const diffWithCurrentDate = (d: Date | string): number => {
  const days = convertMsToDays(moment(d).toDate().getTime());
  const currentDays = convertMsToDays(moment().toDate().getTime());
  return days - currentDays;
};

export function getValidDate(s: string): Date {
  if (!s) {
    return null;
  }
  const date = moment(s, DD_MM_YYYY);
  const isValid = date.isValid();
  if (!isValid) {
    return null;
  }
  return date.toDate();
}

export function time(d: Date | string, nullValue = '00:00'): string {
  if (!d) return nullValue;

  return moment(d).format('HH:mm');
}

export function fullTime(d: Date | string, nullValue = '-'): string {
  if (!d) return nullValue;

  return moment(d).format('HH:mm:ss');
}

/** @deprecated format.tsx is for UI-related formatting;
 * for backend formatting put corresponding code into backend.tsx. \
 * For example backend.tsx already
 * knows how to convert request params from Date to correct format */
export function localDate(date: Date): string {
  return dateFormat(date, 'yyyy-mm-dd');
}

export function dateTime(date: Date | string): string {
  if (!date) return '-';

  return dateFormat(date instanceof Date ? date : new Date(date), 'dd.mm.yyyy HH:MM');
}

export function dateTimeFull(date: Date | string): string {
  if (!date) return '-';

  return dateFormat(date, 'dd.mm.yyyy HH:MM:ss');
}

export function timeDateFull(date: Date | string): string {
  if (!date) return '-';

  return dateFormat(date, 'HH:MM:ss dd.mm.yyyy');
}

export function today(): string {
  return date(moment().toDate());
}

// TODO delete
export function parseAmount(s: string) {
  if (!s) {
    return null;
  }
  return parseFloat(s.replace(/ /g, ''));
}

export function toDDMMYYY(d: string | Date): string {
  return moment(d).format(DD_MM_YYYY);
}

export function getAmountNumberFormatWithComma(amount: string | number): string {
  return new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2 }).format(+amount);
}

export function getAmountCurrency(amount: number, currency = ''): string {
  if (typeof amount !== 'number') return '-';

  return `${getAmountNumberFormatWithComma(amount)} ${currency}`;
}

export const ZERO_MONEY = '0,00';

export function getAmount(amount: number | string): string {
  if (!amount) return ZERO_MONEY;

  return getAmountNumberFormatWithComma(Number(amount));
}

export const toAmountFormatWithDot = (value: string | number): string =>
  isExist(value) ? getFormattedValue(`${value}`.replace(/[,]/g, '.')) : null;

export const toAmountFormatWithComma = (value: string | number): string =>
  isExist(value) ? getFormattedValue(`${value}`.replace(/[.]/g, ',')) : null;

export const toAmountFormat = (value: string | number): string => {
  if (isNotNumberValue(value)) {
    return '';
  }

  return new Decimal(value).toFixed(2, Decimal.ROUND_HALF_UP);
};

const isNotNumberValue = (value: string | number) => typeof value !== 'number' && !value;
