// tslint:disable-next-line: import-name
import classNames from 'classnames';

type Modificators = { [key: string]: boolean | string };

class Bem {
  private class: string;
  private modificators: Modificators = {};

  private elmSeparator = '__';
  private modSeparator = '_';

  constructor(block: string, element?: string) {
    this.class = block;
    if (element) {
      this.class = block + this.elmSeparator + element;
    }
  }

  modificator(key: string, value: boolean | string) {
    this.modificators[this.class + this.modSeparator + key] = value;
    // if (typeof value === 'boolean') {
    // } else {
    //   this.modificators[this.class + this.modSeparator + key + this.modSeparator];
    // }
    return this;
  }

  toClassName(...otherClasses: string[]): string {
    const [booleanModificators, stringModificators] = Object.entries(this.modificators).reduce(
      (acc: [{ [key: string]: boolean | string }, string[]], [key, value]) => {
        if (typeof value === 'boolean' || !value) {
          acc[0][key] = value;
        } else {
          acc[1].push(key + this.modSeparator + value);
        }
        return acc;
      },
      [{}, []],
    );

    return classNames(this.class, stringModificators, booleanModificators, ...otherClasses);
  }
}

export const bem = (block: string, element?: string) => new Bem(block, element);
