import React from 'react';

import { DynamicFieldValue } from 'api/DemandsService/interfaces';
import { Option } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { PhoneField } from 'components/forms/inputs/PhoneField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { useDisabledCustomer } from 'hooks/useDisabledCustomer';
import { NewSalaryDetailNames } from 'pages/Demands/NewSalaryDemand/enums';

interface Fields {
  [NewSalaryDetailNames.Customer]: string;
  [NewSalaryDetailNames.EmployeeCount]: number;
  [NewSalaryDetailNames.SalaryAmount]: number;
  [NewSalaryDetailNames.Branch]: string;
  [NewSalaryDetailNames.Contacts]: string;
}

interface Payload {
  [NewSalaryDetailNames.Branch]: Option<DynamicFieldValue>[];
}

export const NewSalaryDetail = () => {
  const {
    order,
    payload: { branch },
    onChangeCustomer,
  } = useOrderDetails<Fields, Payload>();

  const isDisabledCustomer = useDisabledCustomer(order);

  return (
    <DefaultForm>
      <CustomerSelect
        name={NewSalaryDetailNames.Customer}
        label="front.new-salary-demand.organization.label"
        onSelectOption={onChangeCustomer}
        selectDefaultCustomer
        disabled={isDisabledCustomer}
        required
      />
      <AmountField
        name={NewSalaryDetailNames.EmployeeCount}
        label="front.new-salary-demand.employeeCount.label"
        required
      />
      <AmountField
        name={NewSalaryDetailNames.SalaryAmount}
        label="front.new-salary-demand.salaryAmount.label"
        required
      />
      <SelectField
        name={NewSalaryDetailNames.Branch}
        label="front.new-salary-demand.branch.label"
        options={branch}
      />
      <PhoneField
        name={NewSalaryDetailNames.Contacts}
        label="front.new-salary-demand.contacts.label"
        required
      />
    </DefaultForm>
  );
};
