import React from 'react';

import { api } from 'api';
import { Errors } from 'components/forms/ValidatingForm/FormContext';
import { translate } from 'i18n/translator';
import { getStoragesB64 } from 'utils/handleError';

import { validatingForm, FormInitialParams } from './ValidatingForm/validatingForm';

interface FieldError {
  field: string;
  fieldCode: string;
  message: string;
}

const defaultGetFieldErrors = (fieldErrors: FieldError[]) => {
  if (Array.isArray(fieldErrors)) {
    return fieldErrors.reduce((acc, item) => ({ ...acc, [item.fieldCode]: item.message }), {});
  }
  return {};
};

export interface ErrorFromBack {
  code: number;
  description: string;
  message: string;
  meta: { messages: string[] };
  name: string;
  stack: any;
}

function handleError(
  e: ErrorFromBack,
  setError: (errorMessage: string | null) => void,
  setErrors: (errors: Errors) => void,
  getFieldErrors = defaultGetFieldErrors,
) {
  let error;

  try {
    error = JSON.parse(e.message);
  } catch (err) {
    error = e;
  }

  let errorMessage = error.message;

  if (e.code === 400) {
    setErrors(getFieldErrors(error.fieldErrors));
  } else if (e.code) {
    errorMessage =
      error.description ||
      error.message ||
      error?.meta?.messages[0]?.message ||
      translate('front.system-error.reload-page.label');
  } else {
    const stringifyMessage = `name: ${e.name}, message: ${e.message}`;

    const storages = {
      stack: e?.stack,
      ...getStoragesB64(),
    };
    const storagesBase64 = btoa(unescape(encodeURIComponent(JSON.stringify(storages))));
    const fullError = { level: 'ERROR', message: stringifyMessage, data: storagesBase64 };

    api.logService.log(fullError);
    errorMessage = translate('front.system-error.reload-page.label');
  }
  console.error(e);
  setError(errorMessage);
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function withForm<U = {}>(
  Component: React.FC<U>,
  formConfig: Partial<FormInitialParams> = {},
): React.FC<U> {
  // @ts-ignore
  return validatingForm({ handleError, ...formConfig })(Component);
}

export const exportedForTesting = {
  handleError,
  defaultGetFieldErrors,
};
