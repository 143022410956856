import * as React from 'react';
import { HasId, TableColumnProps } from 'react-data-table';

import { TableSortType } from 'components/react-data-table/types';
import { DataTableHeaderColumn } from 'components/Table/DataTableHeaderColumn';
import { checkSelected, getColArray } from 'components/Table/helpers';
import { bem } from 'components/utils/bem';

import './data-table-content.scss';

const Slash = () => <div className={bem('data-table-content', 'slash').toClassName()}>/</div>;

interface DataTableHeaderWrapperProps {
  col: TableColumnProps<HasId>;
  order: string;
  setSort: (sort: TableSortType, order: any) => void;
  sort: TableSortType;
}

export const DataTableHeaderWrapper = ({
  col,
  sort,
  order,
  setSort,
}: DataTableHeaderWrapperProps) => {
  const labels = getColArray(col.label) as string[];
  const sorts = getColArray(col.sort);

  const hasDefaultSort = checkSelected(col.sort, sort);

  const isLastLabel = (index: number) => labels.length - 1 === index;

  return (
    <th className={bem('data-table-content', 'th').toClassName()}>
      {labels.map((item, index) => (
        <DataTableHeaderColumn
          key={labels[index]}
          colLabel={labels[index]}
          renderLabelIcon={col.labelIcon}
          colSort={sorts[index]}
          sort={sort}
          order={order}
          setSort={setSort}
          hasDefaultSort={hasDefaultSort}
        >
          {!isLastLabel(index) && <Slash />}
        </DataTableHeaderColumn>
      ))}
    </th>
  );
};
