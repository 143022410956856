import * as React from 'react';
import { TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { ImportPaymentsResponse, OrderFields } from 'api/interfaces';
import { getPage, IMPORT_TYPES } from 'api/Service';
import { Button } from 'components/buttons/Button';
import { CounterDescription } from 'components/CounterDescription/CounterDescription';
import { FormError } from 'components/forms/FormError';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { getAmountNumberFormatWithComma } from 'components/utils/format';
import { translate } from 'i18n/translator';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { PaymentImportResultModal } from 'pages/Import/ImportModal/PaymentImportResultModal';

import { useError } from './useError';
import { useLoading } from './useLoading';

interface Props {
  importResult: ImportPaymentsResponse;
  importType: string;
}

const columns: TableColumnProps<OrderFields>[] = [
  {
    label: 'front.payroll-page.col-receiver.label',
    render: row => <DataColumn title={row.receiverName} subTitle={row.receiverIban} />,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.payroll-page.col-sum.label',
    render: row => <DataColumn title={getAmountNumberFormatWithComma(row.amount)} />,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

const goBack = (importType: string) => {
  switch (importType) {
    case IMPORT_TYPES.DOMESTIC_PAYMENTS:
      goto(pages.payments);
      break;
    case IMPORT_TYPES.FOREIGN_PAYMENTS:
      goto(pages.foreignPayments);
      break;
  }
};

export const SuccessPaymentsList = ({ importResult, importType }: Props) => {
  const [error, setError] = useError();
  const [isLoading, setLoading] = useLoading();

  const isDisableSave = !importResult?.orders?.length || isLoading;

  const handleClick = async () => {
    setLoading(true);
    try {
      await api.payments.acceptImportPayments(
        importResult.orders.map(order => order.id),
        importType,
      );

      goBack(importType);

      await confirmModal(PaymentImportResultModal, {
        orders: importResult.orders,
      });
    } catch (error: any) {
      setError(JSON.parse(error.message)?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <FormError>{error}</FormError>
      <Button color="primary" onClick={handleClick} size="md" disabled={isDisableSave}>
        {translate('front.import-page.save.label')}
      </Button>
      <Button
        color="secondary"
        onClick={() => {
          goBack(importType);
        }}
        size="md"
      >
        {translate('front.import-page.cancel.label')}
      </Button>
      <CounterDescription
        count={importResult.orders?.length}
        description={translate('front.import-page.tab-success.label')}
      />
      <DataTable
        name={TableNamesEnum.SuccessPaymentsImport}
        columns={columns}
        fetchRows={async request => getPage(importResult.orders, request)}
      />
    </>
  );
};
