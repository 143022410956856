import * as React from 'react';
import { TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { PersonalOrganization } from 'api/ProfileService';
import { SortOrder } from 'api/Service';
import * as Icon from 'components/icons';
import { Page } from 'components/layout/Page/Page';
import { Status, StatusColor } from 'components/layout/Status';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { translate } from 'i18n/translator';
import store from 'store';
import { UserActions } from 'store/actions/user';

import { PersonalOrganizationsFilter } from './PersonalOrganizationsFilter';

export const getStatusColor = (isBlocked: boolean): StatusColor => (isBlocked ? 'red' : 'green');

const columns: TableColumnProps<PersonalOrganization>[] = [
  {
    label: 'front.profile.organizations.table.col.name.label',
    render: row => <DataColumn title={row.name} />,
    sort: 'name',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.profile.organizations.table.col.bin.label',
    render: row => <DataColumn title={row.bin} />,
    sort: 'taxCode',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.profile.organizations.table.col.status.label',
    render: row => {
      const title = row.isBlocked
        ? 'front.profile.organizations.table.col.status-blocked.label'
        : 'front.profile.organizations.status-unblocked.label';
      return (
        <DataColumn>
          <Status color={getStatusColor(row.isBlocked)}>{translate(title)}</Status>
        </DataColumn>
      );
    },
    sort: 'companyPerson.status',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.profile.organizations.action.default-enterprise.label',
    render: row => <DataColumn title={row.isDefault ? <Icon.Checked /> : null} />,
    sort: 'isDefaultPerson',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    renderMenu: row => [
      {
        label: 'front.profile.organizations.action.default-enterprise.label',
        onClick: async () => {
          await api.profile.setDefaultEnterprise(row.personId);
          store.dispatch(UserActions.setDefaultCustomer(row.id));
        },
        isReloadable: true,
      },
    ],
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

export const PersonalOrganizationsPage = () => (
  <Page title="">
    <DataTable
      name={TableNamesEnum.PersonalOrganizationsList}
      columns={columns}
      fetchRows={api.profile.getOrganizations}
      defaultSort="id"
      filterComponent={PersonalOrganizationsFilter}
      defaultOrder={SortOrder.Asc}
    />
  </Page>
);
