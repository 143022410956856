import moment from 'moment';

import * as format from 'components/utils/format';

interface DateRangeSelectOption {
  key: string;
  label: string;
  className?: string;
  value?: Array<string>;
}

export enum DatePeriodEnum {
  SELECT_RANGE = 'select_range',
  TODAY = 'today',
  PREVIOUS_DAY = 'previous_day',
  CURRENT_WEEK = 'current_week',
  PREVIOUS_WEEK = 'previous_week',
  CURRENT_MONTH = 'current_month',
  PREVIOUS_MONTH = 'previous_month',
  CURRENT_QUARTER = 'current_quarter',
  PREVIOUS_QUARTER = 'previous_quarter',
}

export const getToday = (): Array<string> => {
  const today = format.date(moment().toDate());
  return [today, today];
};

export const getPreviousDay = (): Array<string> => {
  const today = moment().toDate();
  const dd = today.getDate() - 1;
  const prevDay = format.date(new Date(today.setDate(dd)));
  return [prevDay, prevDay];
};

export const getCurrentWeek = (): Array<string> => {
  const start = moment().toDate();
  const startDay = start.getDay();
  start.setDate(start.getDate() - startDay + (startDay === 0 ? -6 : 1));
  const end = moment().toDate();
  const endDay = end.getDay();
  end.setDate(end.getDate() - endDay + (endDay === 0 ? 0 : 7));

  return [format.date(new Date(start)), format.date(end)];
};

export const getPreviousWeek = (): Array<string> => {
  const today = moment().toDate();
  const sunday = new Date(today.setDate(today.getDate() - today.getDay()));
  const monday = new Date(today.setDate(today.getDate() - 6));

  return [format.date(monday), format.date(sunday)];
};

export const getCurrentMonthAsDates = (): Array<Date> => {
  const start = moment().toDate();
  start.setDate(1);
  const end = new Date(start.getFullYear(), start.getMonth() + 1, 0);
  return [start, end];
};

export const getCurrentMonth = (): Array<string> =>
  getCurrentMonthAsDates().map(d => format.date(d));

export const getPreviousMonth = (): Array<string> => {
  const today = moment().toDate();
  const month = today.getMonth();
  const year = today.getFullYear();
  today.setMonth(month - 1);
  const countDayInMonth = dayInMonth(month, year);

  return [
    format.date(new Date(today.setDate(1))),
    format.date(new Date(today.setDate(countDayInMonth))),
  ];
};

export const dayInMonth = (month: number, year: number): number => {
  return new Date(year, month, 0).getDate();
};

export const getCurrentQuarter = (): Array<string> => {
  const start = moment().toDate();
  start.setDate(1);
  const quarter = getQuarterNumber(start);
  const quarterFirstMonth = (quarter - 1) * 3;
  start.setMonth(quarterFirstMonth);
  const end = new Date(start.getFullYear(), start.getMonth() + 3, 0);

  return [start, end].map(d => format.date(d));
};

export const getQuarterNumber = (date: any): number => {
  const month = date.getMonth() + 1;
  return Math.ceil(month / 3);
};

export const getPreviousQuarter = (): Array<string> => {
  const today = moment().toDate();
  const lastQuarterDate = moment().toDate();

  const year = today.getFullYear();
  const quarter = getQuarterNumber(today);
  const quarterFirstMonth = quarter === 1 ? 9 : quarter === 2 ? 0 : quarter === 3 ? 3 : 6;
  today.setMonth(quarterFirstMonth);

  if (quarterFirstMonth === 9) {
    today.setFullYear(year - 1);
    lastQuarterDate.setFullYear(year - 1);
  }

  const quarterLastMonth = quarterFirstMonth + 2;
  const countDayInMonth = dayInMonth(quarterLastMonth + 1, year);
  lastQuarterDate.setMonth(quarterLastMonth);

  return [
    format.date(new Date(today.setDate(1))),
    format.date(new Date(lastQuarterDate.setDate(countDayInMonth))),
  ];
};

export const getDateArray = (start: Date, end: Date) => {
  const result = [];
  const currentDate = new Date(start);
  while (currentDate <= end) {
    result.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return result;
};

export const getSelectedValueKey = (value: string) => {
  if (!value) {
    return { value: '', key: '' };
  }

  const option = getSelectOptions().find(
    item => item.value && `${item.value[0]} - ${item.value[1]}` === value,
  );
  if (option) {
    return { value: option.label, key: option.key };
  }
  return { value, key: DatePeriodEnum.SELECT_RANGE };
};

// TODO this method should be memoized. Use ServerTime#getSyncTime for tracking
export const getSelectOptions = (): Array<DateRangeSelectOption> => [
  {
    label: 'front.date-range.option-select-range.label',
    key: DatePeriodEnum.SELECT_RANGE,
    className: 'range-item',
  },
  {
    label: 'front.date-range.option-today.label',
    key: DatePeriodEnum.TODAY,
    value: getToday(),
  },
  {
    label: 'front.date-range.option-previous-day.label',
    key: DatePeriodEnum.PREVIOUS_DAY,
    value: getPreviousDay(),
  },
  {
    label: 'front.date-range.option-current-week.label',
    key: DatePeriodEnum.CURRENT_WEEK,
    value: getCurrentWeek(),
  },
  {
    label: 'front.date-range.option-previous-week.label',
    key: DatePeriodEnum.PREVIOUS_WEEK,
    value: getPreviousWeek(),
  },
  {
    label: 'front.date-range.option-current-month.label',
    key: DatePeriodEnum.CURRENT_MONTH,
    value: getCurrentMonth(),
  },
  {
    label: 'front.date-range.option-previous-month.label',
    key: DatePeriodEnum.PREVIOUS_MONTH,
    value: getPreviousMonth(),
  },
  {
    label: 'front.date-range.option-current-quarter.label',
    key: DatePeriodEnum.CURRENT_QUARTER,
    value: getCurrentQuarter(),
  },
  {
    label: 'front.date-range.option-previous-quarter.label',
    key: DatePeriodEnum.PREVIOUS_QUARTER,
    value: getPreviousQuarter(),
  },
];
