import * as React from 'react';
import { useLocation } from 'react-router';

import { api } from 'api';
import { parseUrlParams } from 'api/backend';
import { AccountRequisites, RequisitesParams } from 'api/ProductsService';
import { Button } from 'components/buttons/Button';
import { translate } from 'i18n/translator';

const isUAH = (currency: string) => currency === 'UAH';

const downloadPDF = async (accountId: string | number) => {
  await api.products.getAccRequisitesPDF(accountId as string);
};

const getRows = (requisites: AccountRequisites) => {
  const { account, accountCurrencyInfo, ...info } = requisites;

  return [
    {
      label: translate('front.account-requisites-page.row.account-number.label'),
      value: `${account.number} / ${account.iban}`,
    },
    {
      label: translate('front.account-requisites-page.row.currency-account.label'),
      value: `${accountCurrencyInfo.digitalCode} (${accountCurrencyInfo.isoCode})`,
    },
    {
      label: translate('front.account-requisites-page.row.name-of-the-bank.label'),
      value: info.bankShortName,
    },
    {
      label: translate('front.account-requisites-page.row.the-recipient.label'),
      value: info.customerFullName,
    },
    {
      label: translate('front.account-requisites-page.row.code-EDRPOU.label'),
      value: info.customerTaxCode,
    },
    {
      label: translate('front.account-requisites-page.row.beneficiary-bank.label'),
      value: info.internationalBankName,
      isVisible: !isUAH(account.currency),
    },
    {
      label: translate('front.account-requisites-page.row.bank-address.label'),
      value: info.intBankAddress,
      isVisible: !isUAH(account.currency),
    },
    {
      label: 'SWIFT BIC',
      value: info.bik,
      isVisible: !isUAH(account.currency),
    },
    {
      label: translate('front.account-requisites-page.row.purpose-of-payment.label'),
      value: info.paymentPurposeText,
    },
  ];
};

export const AccountRequisitesPage = () => {
  const [requisites, setRequisites] = React.useState<AccountRequisites>();
  const [accountId, setAccountId] = React.useState<string | number>('');
  const location = useLocation();

  const onMount = async () => {
    const { accountId } = parseUrlParams(location.search);
    const params: RequisitesParams = { accountId, withIban: false };
    const requisites = await api.products.sendRequisites(params);
    setRequisites(requisites);
    setAccountId(accountId);
  };

  React.useEffect(() => {
    onMount();
  }, []);

  if (!requisites) return null;

  const rows = getRows(requisites);

  const renderRowFields = () => {
    if (!Object.keys(requisites).length) {
      return null;
    }

    return rows.map(({ label, value, isVisible = true }, index) => {
      if (!isVisible) {
        return null;
      }

      return (
        <div className="account-requisites__col" key={index}>
          <span className="account-requisites__col__label">{`${label}: `}</span>
          <span>{value}</span>
        </div>
      );
    });
  };

  return (
    <div className="account-requisites">
      <div>{renderRowFields()}</div>
      <Button onClick={() => downloadPDF(accountId)}>
        {translate('front.account-requisites-page.export-button.label')}
      </Button>
    </div>
  );
};
