import React from 'react';

import { CurrencyAmountInterface } from 'components/react-data-table/types';

interface AmountCardProps {
  item: CurrencyAmountInterface<string>;
  isShowAmount?: boolean;
}

export const AmountCard = ({
  isShowAmount,
  item: { currency, amount, count },
}: AmountCardProps) => (
  <div key={currency} className="amount-card-wrapper">
    <span>{isShowAmount ? `${amount} ${currency}` : `${currency}`}</span>
    <span className="amount-card-wrapper__count">{count}</span>
  </div>
);
