import React from 'react';

import { Option } from 'api/Service';
import { FieldInput } from 'components/forms/inputs/FieldInput';
import { useField } from 'components/forms/ValidatingForm/components/useField';
import { validateMessages } from 'components/validateMessages';
import { translate } from 'i18n/translator';

import { CssType, Radio } from '../radio/Radio';

interface RadioProps {
  name: string;
  options: Option[];
  clearable?: boolean;
  cssType?: CssType;
  defaultValue?: string;
  disabled?: boolean;
  externalValidation?: (value: string) => string | null;
  onChange?: (value: string) => void;
  required?: boolean;
}

export const RadioField = (props: RadioProps) => {
  const {
    name,
    options,
    required,
    cssType,
    disabled,
    clearable,
    defaultValue,
    onChange,
    externalValidation,
  } = props;

  const validate = React.useCallback(
    value => {
      if (required) {
        if (!value) {
          return translate(validateMessages.fieldRequired);
        }
      }

      if (externalValidation) {
        return externalValidation(value);
      }

      return null;
    },
    [required, externalValidation],
  );

  const { field, form } = useField({ name, defaultValue, validate });

  if (!field && !form) {
    return null;
  }

  const onRadioFieldChange = (e: any, baseOnChange: (value: string) => void) => {
    const value = e.target.value;
    baseOnChange(value);

    onChange && onChange(value);
  };

  return (
    <FieldInput
      label=""
      isLargeInput={false}
      hasOutline={false}
      isDisabled={disabled}
      name={name}
      error={field.error}
      warning={field.warning}
      value={field.value}
      isFocus
    >
      <Radio
        name={name}
        value={field.value as string}
        onChange={e => onRadioFieldChange(e, field.onChange)}
        options={options}
        cssType={cssType}
        disabled={disabled || form.disabled || form.progress}
        clearable={clearable}
      />
    </FieldInput>
  );
};
