import React from 'react';
import { Col } from 'react-grid';

import { api } from 'api';
import { CertificateType, UserKeystoreType } from 'api/CertificateService/enums';
import { Certificate } from 'api/CertificateService/interfaces';
import { CheckSmsActionEnum } from 'api/enums';
import { DataFromWebBank } from 'api/interfaces';
import { CustomerTypeEnum } from 'api/UserService';
import { SelectField } from 'components/forms/inputs/SelectField';
import { confirmationModal } from 'components/modals/ConfirmModal';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { SmsModal } from 'components/modals/SmsModal';
import { Privileges } from 'navigations/privileges';
import { CertificateFieldNames, KeyKepType } from 'pages/Profile/Certificates/KepCertificate/enums';
import { CreateOnSave, FetchOnMount } from 'pages/Profile/Certificates/KepCertificate/types';
import {
  confirmIssueCertificateKep,
  defaultStoreOption,
  gotoCertificatesPage,
  hasSomeActiveCertificate,
} from 'pages/Profile/Certificates/KepCertificate/utils';
import { withKepCertificate } from 'pages/Profile/Certificates/KepCertificate/withKepCertificate';
import { parseErrorMessage } from 'pages/Profile/Certificates/utils';

const CreateESealPage = () => (
  <Col sm={10}>
    <SelectField
      label="front.cert-page-new.input-keystore.label"
      name={CertificateFieldNames.Store}
      defaultValue={KeyKepType.CLOUD_STORAGE}
      options={defaultStoreOption}
      disabled
    />
  </Col>
);

const fetchOnMount: FetchOnMount = async ({ customer }, { setFields }) => {
  setFields({ customerId: `${customer.id}` });
};

const createOnSave: CreateOnSave =
  (validatePassword, { customer }) =>
  async formData => {
    const { password, puk, customerId } = formData;

    validatePassword();

    const certificates = await api.certificate.getCertificates();
    const currentCustomerCerts =
      certificates.filter(cert => cert.customerId === +customerId) ?? ([] as Certificate[]);

    if (hasSomeActiveCertificate(currentCustomerCerts)) {
      await confirmationModal('front-profile.prohibited-e-seal.label', false, false);
      return gotoCertificatesPage();
    }

    if (customer.type === CustomerTypeEnum.IndividualEmployer) {
      await confirmationModal('front.profile.e-seal-generation-is-prohibited.label', false, false);
      return gotoCertificatesPage();
    }

    let dataProfile: DataFromWebBank;

    const confirmPayload = {
      customerId: +customerId,
      keystoreType: UserKeystoreType.CloudStorage,
      isReissue: false,
      certificateType: CertificateType.Seal,
    };

    try {
      dataProfile = await confirmIssueCertificateKep(confirmPayload);
    } catch (e: any) {
      return await confirmationModal(parseErrorMessage(e), false, false);
    }

    if (!dataProfile) {
      return gotoCertificatesPage();
    }

    const smsCode = await confirmModal<string>(SmsModal, {
      action: CheckSmsActionEnum.ADD_CERTIFICATE,
    });
    if (!smsCode) return gotoCertificatesPage();

    try {
      await api.certificate.createCloudCertificate({
        smsCode,
        customerId,
        dataHash: dataProfile.dataHash,
        pin: password,
        puk,
        keystoreType: UserKeystoreType.CloudStorage,
        certificateType: CertificateType.Seal,
      });
    } catch (e: any) {
      await confirmationModal(parseErrorMessage(e), false, false);
      return;
    }

    gotoCertificatesPage();
  };

const createFetchCerts = () => async () => {};

export const CreateESeal = withKepCertificate({
  isCreate: true,
  fetchOnMount,
  createOnSave,
  createFetchCerts,
  title: 'front.cert-page-new.e-seal.title.label',
  labelButtonOnSave: 'front.cert-page-new.create-e-seal-button.label',
  labelPasswordCode: 'front.cert-page-add.pin-e-seal-password.label',
  labelPukCode: 'front.cert-page-add.puk-e-seal-password.label',
  customerPrivilege: Privileges.sealKeysAddNew,
})(CreateESealPage);
