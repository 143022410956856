import * as React from 'react';

export const useModal = () => {
  const [progress, setProgress] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleSubmit = (onChange: () => void, e: any) => {
    e && e.preventDefault();
    try {
      setProgress(true);
      onChange();
    } catch (e: any) {
      const errorMessage = JSON.parse(e.message);
      setError(errorMessage);
    } finally {
      setProgress(false);
    }
  };

  return {
    progress,
    error,
    handleSubmit,
  };
};
