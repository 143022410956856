import { useContext, useEffect } from 'react';

import { useForm } from 'components/forms/ValidatingForm/useForm';

import { OrderContext, OrderContextValue } from './OrderContext';

export const useOrderDetails = <T, P = any>() => {
  const form = useForm<T>();
  const {
    order,
    setOrder,
    fetchOrder,
    setPayload,
    payload,
    setIsDetails,
    resetTemplate,
    fetchTemplates,
  } = useContext<OrderContextValue<T, P>>(OrderContext);

  useEffect(() => {
    setIsDetails(true);
    return () => {
      setIsDetails(false);
    };
  }, []);

  const onChangeCustomer = async (customerId: number): Promise<void> => {
    form.setInitializing(true);
    form.resetData();
    resetTemplate();
    const order = await fetchOrder(customerId);
    setOrder(order);
    fetchTemplates(order.type, order.customerId);
    form.setInitializing(false);
  };

  return {
    onChangeCustomer,
    form,
    order,
    payload,
    setPayload,
  };
};
