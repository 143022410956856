import React from 'react';

import moment, { Moment } from 'moment';
import RcTimePicker from 'rc-time-picker';

import { Clear } from 'components/icons';

import './styles.scss';

interface Props {
  onBlur(): void;

  onChange: (value: string) => void;

  onFocus(): void;

  value: string;
  disabled?: boolean;
  hourStep?: number;
  minuteStep?: number;

  secondStep?: number;

  showSecond?: boolean;
}

const valueFormatting = (value: string): Moment => {
  if (!value) return value as unknown as Moment;

  return value.length === 5 ? moment(value, 'HH:mm') : moment(value);
};

export const TimePicker = ({
  value,
  onChange,
  disabled,
  hourStep,
  minuteStep,
  secondStep,
  showSecond,
  onFocus,
  onBlur,
}: Props) => {
  let formattedValue;

  try {
    formattedValue = valueFormatting(value);
  } catch (error) {
    console.error(error);
  }

  return (
    <RcTimePicker
      className="rc-time-picker-wrap"
      value={formattedValue}
      onChange={value => onChange(value && value.format('HH:mm'))}
      disabled={disabled}
      showSecond={showSecond}
      hourStep={hourStep}
      minuteStep={minuteStep}
      secondStep={secondStep}
      onOpen={onFocus}
      onClose={onBlur}
      clearIcon={
        <a role="button" className="rc-time-picker-clear">
          <Clear />
        </a>
      }
    />
  );
};
