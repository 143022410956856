import { configureStore } from '@reduxjs/toolkit';

import reducers from './reducers';

const initialState = {};

const store = configureStore({
  reducer: reducers,
  preloadedState: initialState,
  devTools: process.env.NODE_ENV === 'development',
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
});

export type AppStore = ReturnType<typeof store.getState>;

export default store;
