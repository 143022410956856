import { Department } from 'api/DepartmentsService';
import { Position } from 'components/Map/Map';

export interface Point {
  content: string;
  id: number;
  position: Position;
  addInfo?: string;
}

export function toPoints(departments: Department[]): Point[] {
  return departments.map(dep => {
    const coordinates = dep.geoposition.split(',');

    return {
      id: dep.id,
      position: {
        lat: Number(coordinates[0].trim()),
        lng: Number(coordinates[1].trim()),
      },
      content: `${dep.name}\n${dep.address}`,
      addInfo: `${dep.addInfo}`,
    };
  });
}

export async function getNearestPoint(points: Point[]): Promise<Point> {
  const currentPosition = await getCurrentPosition();

  if (!currentPosition) {
    return null;
  }

  let distance;
  let point;
  for (let i = 0; i < points.length; i += 1) {
    const marker = points[i];
    const d = getDistanceFromLatLonInKm(marker.position, currentPosition);
    if (distance) {
      if (d < distance) {
        distance = d;
        point = marker;
      }
    } else {
      distance = d;
      point = marker;
    }
  }

  return point;
}

function getCurrentPosition(): Promise<Position> {
  return new Promise(resolve => {
    navigator.geolocation.getCurrentPosition(
      e => {
        const { latitude: lat, longitude: lng } = e.coords;
        resolve({ lat, lng });
      },
      () => resolve(null),
    );
  });
}

function getDistanceFromLatLonInKm(startPoint: Position, endPoint: Position): number {
  const { lat: lat1, lng: lon1 } = startPoint;
  const { lat: lat2, lng: lon2 } = endPoint;
  const R = 6371;
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
}

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}
