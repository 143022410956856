import React from 'react';
import { useDataTable } from 'react-data-table';
import { Col, Row } from 'react-grid';

import { Button } from 'components/buttons/Button';
import { Counter } from 'components/Counter/Counter';
import { TableAction } from 'components/Table/TableActions/TableAction';
import { translate } from 'i18n/translator';
import { isExist } from 'utils/isData';

import { calculateSelectedByPage, getSelectedRows, handleClick } from './utils';

export const TableActions = () => {
  const {
    rows,
    name,
    total,
    actions,
    selected,
    gridRequest,
    setSelected,
    ActionsComponent,
    reFetchData,
  } = useTableActions();
  const { updateGridRequest, page, size } = gridRequest;

  const hasActionsComponent = isExist(ActionsComponent);
  const hasSelected = selected.length !== 0;

  if (hasActionsComponent && !hasSelected) {
    return <ActionsComponent total={total} selectedItems={[]} />;
  }

  if (!hasSelected || !actions) {
    return null;
  }

  const selectedItems = getSelectedRows(rows, selected);
  const selectedForTable = calculateSelectedByPage(selected, page * size);

  const onClick = handleClick(
    selected,
    selectedForTable,
    selectedItems,
    updateGridRequest,
    gridRequest.page,
    reFetchData,
  );

  const Buttons = actions.reduce((acc, item, index) => {
    const { isVisible = () => true, disabled = () => selected.length <= 0 } = item;

    const isVisibleValue = isVisible(selectedItems);

    if (!isVisibleValue) {
      return acc;
    }

    const key = `${name}-action-${index}`;

    return [
      ...acc,
      <TableAction key={key} isDisabled={disabled()} onClick={onClick} item={item} />,
    ];
  }, []);

  const handleClickReset = () => setSelected([]);

  if (hasActionsComponent) {
    return (
      <ActionsComponent total={total} selectedItems={selectedItems}>
        {Buttons}
        <Button color="secondary" size="sm" onClick={handleClickReset}>
          {translate('front.table.selected-actions-clear.label')}
        </Button>
      </ActionsComponent>
    );
  }

  return (
    <Row>
      <Col lg={2} sm={5}>
        <Row>
          <Col lg={12} sm={8} vAlign="middle">
            <span>{translate('front.table.selected-actions-count.label')}</span>
            <Counter count={selected.length} />
          </Col>
          <Col sm={4}>
            <Button color="secondary" size="sm" onClick={handleClickReset}>
              {translate('front.table.selected-actions-clear.label')}
            </Button>
          </Col>
        </Row>
      </Col>
      <Col lg={10} sm={12}>
        {Buttons}
      </Col>
    </Row>
  );
};

const useTableActions = () => {
  const {
    name,
    rows,
    total,
    actions,
    selection,
    gridRequest,
    actionsComponent: ActionsComponent,
    reFetchData,
  } = useDataTable();
  const { selected, setSelected, toggleSelected } = selection;

  return {
    name,
    rows,
    total,
    actions,
    selected,
    setSelected,
    gridRequest,
    toggleSelected,
    ActionsComponent,
    reFetchData,
  };
};
