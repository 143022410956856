import { Option } from 'api/Service';

import { DocumentManagementStateFilter, DocumentsManagementFilterEnum, StateTab } from './enums';
import { TabsState } from './interfaces';

export const DocumentManagementOptionStatuses: Option<TabsState, StateTab>[] = [
  {
    value: StateTab.Working,
    label: 'front.working-documents-table.filter-status-working.label',
  },
  {
    value: StateTab.Signed,
    label: 'front.working-documents-table.filter-status-signed.label',
  },
  {
    value: StateTab.Rejected,
    label: 'front.working-documents-table.filter-status-rejected.label',
  },
  {
    value: StateTab.All,
    label: 'front.working-documents-table.filter-status-all.label',
  },
];

export const DocumentManagementStateOptions: Option<undefined, DocumentManagementStateFilter>[] = [
  {
    value: DocumentManagementStateFilter.Draft,
    label: 'front.order-state.draft.label',
  },
  {
    value: DocumentManagementStateFilter.OnSigning,
    label: 'front.document-management.state.on-signing.label',
  },
  {
    value: DocumentManagementStateFilter.NeedSigning,
    label: 'front.document-management.state.need-signing.label',
  },
  {
    value: DocumentManagementStateFilter.PartiallySigned,
    label: 'front.document-management.state.partial-signing.label',
  },
  {
    value: DocumentManagementStateFilter.NotRequiredSign,
    label: 'front.document-management.state.not-required-sign.label',
  },
  {
    value: DocumentManagementStateFilter.Signed,
    label: 'front.document-management.state.signed.label',
  },
  {
    value: DocumentManagementStateFilter.Rejected,
    label: 'front.document-management.state.rejected.label',
  },
];

export const documentManagementTabs: Record<StateTab, TabsState> = {
  [StateTab.All]: {
    [DocumentsManagementFilterEnum.OrderStates]: [],
  },
  [StateTab.Working]: {
    [DocumentsManagementFilterEnum.ExcludedStates]: [
      `${DocumentManagementStateFilter.Signed}:${DocumentManagementStateFilter.Signed}`,
      `${DocumentManagementStateFilter.Rejected}:${DocumentManagementStateFilter.Rejected}`,
    ],
  },
  [StateTab.Rejected]: {
    [DocumentsManagementFilterEnum.OrderStates]: [
      `${DocumentManagementStateFilter.Rejected}:${DocumentManagementStateFilter.Rejected}`,
    ],
  },
  [StateTab.Signed]: {
    [DocumentsManagementFilterEnum.OrderStates]: [
      `${DocumentManagementStateFilter.Signed}:${DocumentManagementStateFilter.Signed}`,
    ],
  },
};
