import * as React from 'react';

import { Order, OrderHistory } from 'api/OrderService';
import { GridResponse, Option } from 'api/Service';
import { FetchOrderCbParams, GetCustomerFilteringPrivilege } from 'components/Document/withOrder';

export type RefetchOrderCb<T> = (
  customerId: number,
  opts?: Partial<FetchOrderCbParams>,
) => Promise<Order<T>>;

export interface OrderContextValue<T = any, P = any> {
  fetchOrder: RefetchOrderCb<T>;
  fetchTemplates: (orderType: string, customerId: number) => void;
  order: Order<T>;
  resetTemplate: () => void;
  setHistory: React.Dispatch<React.SetStateAction<GridResponse<OrderHistory>>>;
  setIsDetails: React.Dispatch<React.SetStateAction<boolean>>;
  setOrder: React.Dispatch<React.SetStateAction<Order<T>>>;
  setPayload: React.Dispatch<React.SetStateAction<P>>;
  // templateOptions: Option[];
  setTemplateOptions: React.Dispatch<React.SetStateAction<Option[]>>;
  getCustomerFilteringPrivilege?: GetCustomerFilteringPrivilege;
  history?: GridResponse<OrderHistory>;
  payload?: P;
  template?: string;
}

export const OrderContext = React.createContext<OrderContextValue>({
  order: null,
  // templateOptions: [],
  setTemplateOptions: () => {},
  setPayload: () => {},
  setHistory: () => {},
  fetchOrder: () => null,
  setIsDetails: () => {},
  setOrder: () => {},
  resetTemplate: () => {},
  fetchTemplates: () => {},
  getCustomerFilteringPrivilege: () => undefined,
});
