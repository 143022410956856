import * as React from 'react';
import { Action, Menu, TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { DemandType } from 'api/DemandsService/enums';
import { DemandResponse, DemandsGridFilter } from 'api/DemandsService/interfaces';
import { OrderActionType } from 'api/enums';
import { ExportType } from 'api/ExportService';
import { GridResponse, SortOrder } from 'api/Service';
import { getStatusColor } from 'api/utils';
import { Page } from 'components/layout/Page/Page';
import { Status } from 'components/layout/Status';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { DEFAULT_WIDTH, PrintingDocuments } from 'components/PrintingDocuments/PrintingDocuments';
import { CurrencyColumn } from 'components/Table/CurrencyColumn';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import * as format from 'components/utils/format';
import { translate } from 'i18n/translator';
import { allowDemandAction, allowPrivilegy } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { NEW } from 'navigations/routes';
import { getAmountValue } from 'pages/Demands/CurrencyExchange/utils';
import { isDemandActionVisible } from 'pages/Demands/utils';
import { getTranslateAction } from 'pages/Payments/general';
import { signCertificateOrders, signOrders } from 'pages/utils/SignOrders/signOrders';

import { CurrencyExchangeFilter } from './CurrencyExchangeFilter';

const columns: TableColumnProps<DemandResponse>[] = [
  {
    label: 'front.demands-table.number.label',
    render: row => {
      const orderNumber = row?.base?.orderNumber;
      const orderDate = format.date(row?.base?.orderDate);
      return <DataColumn title={orderNumber} subTitle={orderDate} />;
    },
    sort: 'orderNumberNumeric',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.working-documents-table.customer-name.label',
    render: row => <DataColumn title={row?.base?.customerName} />,
    sort: 'name',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.working-documents-table.order-type.label',
    render: row => <DataColumn title={row?.base?.translatedOrderType} />,
    sort: 'orderType',
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.demands-table.status.label',
    render: row => (
      <DataColumn>
        <Status color={getStatusColor(row?.base.orderState)}>{row.base.orderStateLabel}</Status>
      </DataColumn>
    ),
    sort: 'orderState',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.working-documents-table.amount.label',
    render: row => {
      const { amount, currency } = getAmountValue(row);

      return <CurrencyColumn amount={amount} currency={currency} />;
    },
    sort: 'dynamicBoolean.isBuyAmount',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    renderMenu: (order): Menu<DemandResponse>[] => [
      {
        label: getTranslateAction(OrderActionType.EDIT),
        onClick: () => goto(pages.currencyExchanges.tabs.details(order.id)),
        isVisible: isDemandActionVisible(order, OrderActionType.EDIT),
      },
      {
        label: getTranslateAction(OrderActionType.SIGN),
        onClick: async () => {
          const action = order.actions.find(item => item.actionName === OrderActionType.SIGN);
          if (
            action &&
            (action.confirmation.includes('Signature') || action.confirmation.includes('EDS'))
          ) {
            await signCertificateOrders([{ id: order.id, customerId: order.base.customerId }]);
          } else {
            await signOrders([order.id]);
          }
        },
        isReloadable: true,
        isVisible: isDemandActionVisible(order, OrderActionType.SIGN),
      },
      {
        label: getTranslateAction(OrderActionType.PRINT),
        onClick: async () => await api.demands.getOrderPdf(order.id, true),
        isVisible: isDemandActionVisible(order, OrderActionType.PRINT),
      },
      {
        label: getTranslateAction(OrderActionType.COPY),
        onClick: () => goto(pages.currencyExchanges.tabs.details(NEW, { copyFrom: `${order.id}` })),
        isVisible: isDemandActionVisible(order, OrderActionType.COPY),
      },
      {
        label: getTranslateAction(OrderActionType.DELETE),
        onClick: async () => await api.demands.deleteOrder(order.id),
        isReloadable: true,
        confirmMessage: 'front.working-documents-table.actions-delete-confirmation.label',
        isVisible: isDemandActionVisible(order, OrderActionType.DELETE),
      },
      {
        label: getTranslateAction(OrderActionType.REJECT),
        onClick: async () => await api.demands.executeAction(order.id, OrderActionType.REJECT),
        isReloadable: true,
        isVisible: isDemandActionVisible(order, OrderActionType.REJECT),
      },
      {
        label: getTranslateAction(OrderActionType.REVOKE),
        onClick: async () => await api.demands.withdrawOrders([order.id]),
        isVisible: isDemandActionVisible(order, OrderActionType.REVOKE),
        confirmMessage: 'front.working-documents-table.actions-revoke-confirmation.label',
      },
    ],
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

const actions: Action<DemandResponse>[] = [
  {
    label: 'front.misc.sign-subscribe-sms.label',
    action: async selected => {
      const orders = selected.filter(item =>
        item.actions.some(
          action =>
            action.actionName === OrderActionType.SIGN && action.confirmation.includes('SMS'),
        ),
      );
      await signOrders(orders.map(item => item.id));
    },
    isReloadable: true,
    isVisible: selected =>
      selected.some(({ actions }) =>
        actions.some(
          action =>
            action.actionName === OrderActionType.SIGN && action.confirmation.includes('SMS'),
        ),
      ),
  },
  {
    label: 'front.misc.sign-electronic-sign.label',
    action: async selected => {
      const orders = selected.filter(item =>
        item.actions.some(
          action =>
            action.actionName === OrderActionType.SIGN &&
            (action.confirmation.includes('Signature') || action.confirmation.includes('EDS')),
        ),
      );
      await signCertificateOrders(
        orders.map(item => ({ id: item.id, customerId: item.base.customerId })),
      );
    },
    isReloadable: true,
    isVisible: selected =>
      selected.some(item =>
        item.actions.some(
          action =>
            action.actionName === OrderActionType.SIGN &&
            (action.confirmation.includes('Signature') || action.confirmation.includes('EDS')),
        ),
      ),
  },
  {
    label: 'front.working-documents-table.actions-revoke.label',
    action: async (selected: DemandResponse[]) => {
      const orders = selected.filter(item =>
        isDemandActionVisible(item, OrderActionType.REVOKE, Privileges.demandCurrencyExchangeEdit),
      );
      await api.demands.withdrawOrders(orders.map(item => item.id));
    },
    color: 'secondary',
    isVisible: selected =>
      selected.some(item => isDemandActionVisible(item, OrderActionType.REVOKE)),
  },
  {
    label: getTranslateAction(OrderActionType.PRINT),
    action: async data =>
      await confirmModal(
        PrintingDocuments,
        {
          data: data.filter(item => isDemandActionVisible(item, OrderActionType.PRINT)),
          objectCode: ExportType.CURRENCY_OPERATION_ORDERS,
        },
        DEFAULT_WIDTH,
      ),
    color: 'secondary',
    isVisible: selected =>
      selected.some(item => isDemandActionVisible(item, OrderActionType.PRINT)),
  },
  {
    label: getTranslateAction(OrderActionType.DELETE),
    action: async selected => {
      const orders = selected.filter(
        item =>
          item.actions.some(action => action.actionName === OrderActionType.DELETE) &&
          allowDemandAction(
            item.base.orderType as DemandType,
            OrderActionType.SIGN,
            item.base.customerId,
          ),
      );
      for (let i = 0; i < orders.length; i += 1) {
        await api.demands.deleteOrder(orders[i].id);
      }
    },
    color: 'secondary',
    confirmMessage: 'front.working-documents-table.actions-delete-confirmation.label',
    isVisible: selected =>
      selected.some(
        item =>
          item.actions.some(action => action.actionName === OrderActionType.DELETE) &&
          allowPrivilegy([Privileges.demandCurrencyExchangeEdit]),
      ),
    isReloadable: true,
  },
];

const fetchDemands = (request: DemandsGridFilter): Promise<GridResponse<DemandResponse>> => {
  return api.demands.getDemands({ ...request, isPaymentOrder: true });
};

export const CurrencyExchangesPage = () => (
  <Page title={translate('front.menu.currency-exchange.label')}>
    <DataTable
      name={TableNamesEnum.ForeignDemandsTable}
      columns={columns}
      fetchRows={fetchDemands}
      actions={actions}
      defaultSort="id"
      defaultOrder={SortOrder.Desc}
      filterComponent={CurrencyExchangeFilter}
      onRowClick={row => goto(pages.currencyExchanges.tabs.details(row.id))}
    />
  </Page>
);
