import * as React from 'react';
import { useLocation, useParams } from 'react-router';

import { api } from 'api';
import { parseUrlParams } from 'api/backend';
import { OrderType } from 'api/DemandsService/enums';
import { DemandResponse } from 'api/DemandsService/interfaces';
import { OrderKind, OrderState } from 'api/enums';
import { withOrder } from 'components/Document/withOrder';
import { NavTab, NavTabs } from 'components/tabs/NavTabs';
import * as format from 'components/utils/format';
import { translate } from 'i18n/translator';
import { pages } from 'navigations/pages';
import { NEW } from 'navigations/routes';
import { prepareFieldsInternalPayment } from 'pages/Payments/InternalPayment/helpers';

import { PaymentHistoryPage } from '../History/PaymentHistoryPage';
import { DomesticTransferDetails } from '../InternalPayment/DomesticTransferDetails';
import { InternalTransferDetails } from '../InternalPayment/InternalTransferDetails';
import { getScheduleDetailFields, SchedulePage } from './SchedulePage';

export const RegularPaymentPageComponent: React.FC = () => {
  const { scheduleId } = useParams<{ id: string; scheduleId: string }>();
  const location = useLocation();
  const { type: paymentType } = parseUrlParams(location.search);
  const isNew = scheduleId === NEW;

  return (
    <NavTabs>
      <NavTab
        title={translate('front.regular-payments-page-table.schedule.label')}
        path={pages.regularPayment.tabs.schedule(':id', ':scheduleId')}
      >
        <SchedulePage />
      </NavTab>
      <NavTab
        title={translate('front.internal-payment-page.order-detail-tab.label')}
        path={pages.regularPayment.tabs.paymentRequisites(':id', ':scheduleId')}
      >
        {paymentType === OrderType.DomesticTransfer ||
        paymentType === OrderType.DomesticTransferESCROU ? (
          <DomesticTransferDetails isDisabled />
        ) : (
          <InternalTransferDetails isDisabled />
        )}
      </NavTab>
      {!isNew && (
        <NavTab
          title={translate('front.internal-payment-page.order-history-tab.label')}
          path={pages.regularPayment.tabs.history(':id', ':scheduleId')}
        >
          <PaymentHistoryPage />
        </NavTab>
      )}
    </NavTabs>
  );
};

export const RegularPaymentPage = withOrder({
  fetchOrder: async ({ routeParams }) => {
    const doc = await api.order.getNewOrderModel<DemandResponse>(routeParams.id);
    const paymentDetailFields = prepareFieldsInternalPayment({ doc, isDraft: false });
    const scheduleDetailFields = await getScheduleDetailFields(
      routeParams.id,
      routeParams.scheduleId,
    );

    return {
      detailFields: {
        ...paymentDetailFields,
        ...scheduleDetailFields,
      },
      customerId: doc.base.customerId,
      id: doc.id,
      date: format.parseDate(doc.base.orderDate),
      label: translate('front.regular-payments-page-table.create.label'),
      state: OrderState.Draft,
      orderKind: OrderKind.TEMPLATE,
      stateTranslate: doc.base.orderState,
      number: doc.base.orderNumber,
      type: doc.base.orderType,
      actions: [],
    };
  },
})(RegularPaymentPageComponent);
