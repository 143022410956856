import React from 'react';

import classnames from 'classnames';
import { isValidIBAN } from 'ibantools';
import { string } from 'yup';

import { useField } from 'components/forms/ValidatingForm/components/useField';
import { TTooltip } from 'components/Tooltip/Tooltip';
import { validateMessages } from 'components/validateMessages';
import { translate } from 'i18n/translator';

import { FieldInput } from './FieldInput';
import { PreloadField } from './PreoadField';
import { TextFieldProps } from './types';

interface TextProps extends TextFieldProps {
  label: string;
  name: string;
  account?: boolean;
  allowedCharacters?: string;
  autoComplete?: string;
  defaultValue?: string;
  disabled?: boolean;
  email?: boolean;
  isLargeInput?: boolean;
  normalize?: (value: string, prevVal?: string) => string;
  onBlur?: (value: string) => void;
  onChange?: (value: string) => void;
  onFocus?: (value: string) => void;
  outline?: boolean;
  passportType?: string;
  placeholder?: string;
  required?: boolean;
  tooltip?: TTooltip;
  upper?: boolean;
  // regExp pattern;
  validate?: (value: string) => string | null;
}

export enum PassportType {
  PASSPORT = 'Passport',
  IDCARD = 'Idcard',
}

const emailValidationSchema = string().email();

const PATTERN_PASSPORT = /[А-ГҐДЕЁЄЖЗИІЇЙК-Я]{2}[0-9]{6}$/;
const PATTERN_IDCARD = /^[0-9]{9}$/;

const validateAccount = (value: string) => {
  if (value?.length !== 29) {
    return translate('front.form.account-number-len.error');
  }
  if (!/UA\d{27}/.test(value)) {
    return translate('front.form.account-number.error');
  }
  if (!isValidIBAN(value)) {
    return translate('front.form.account-not-valid.error');
  }

  return null;
};

export const TextField = ({
  name,
  label,
  required,
  minLength,
  maxLength,
  length,
  email,
  account,
  disabled,
  defaultValue,
  placeholder,
  upper,
  tooltip,
  passportType,
  allowedCharacters,
  autoComplete = `new-${name}`,
  normalize = value => value,
  isLargeInput,
  onChange: externalOnChange,
  validate: externalValidate,
  onFocus: externalFocus,
  onBlur: externalBlur,
}: TextProps) => {
  const [isFocus, setFocus] = React.useState<boolean>(false);

  const toggleFocus = (isFocus: boolean) => () => setFocus(isFocus);
  const validate = React.useCallback(
    value => {
      if (required) {
        if (!value) {
          return translate(validateMessages.fieldRequired);
        }
      }
      if (maxLength) {
        if (value?.length > maxLength) {
          return translate(validateMessages.moreThan(maxLength));
        }
      }

      if (minLength) {
        if (value?.length < minLength) {
          return translate(validateMessages.lessThan(minLength));
        }
      }
      if (length) {
        if (value?.length !== length) {
          return translate(validateMessages.shouldBe(length));
        }
      }

      if (email) {
        if (!emailValidationSchema.isValidSync(value)) {
          return translate(validateMessages.invalidEmail);
        }
      }

      if (account) {
        return validateAccount(value);
      }

      if (externalValidate) {
        return translate(externalValidate(value));
      }

      if (passportType) {
        if (passportType === PassportType.PASSPORT && !PATTERN_PASSPORT.test(value)) {
          return translate(validateMessages.invalidPassport);
        }
        if (passportType === PassportType.IDCARD && !PATTERN_IDCARD.test(value)) {
          return translate(validateMessages.invalidIdCard);
        }
        return null;
      }
    },
    [required, maxLength, minLength, length, email, externalValidate, passportType],
  );

  const onTextFieldChange = React.useCallback(
    (onChange, prevVal?) => (e: any) => {
      let value: string = e.target.value || '';
      if (allowedCharacters && !new RegExp(allowedCharacters).test(value)) {
        return;
      }
      if (upper) {
        value = value.toUpperCase();
      }

      const normalizedValue = normalize(value, prevVal);
      onChange(normalizedValue);
      if (externalOnChange) externalOnChange(normalizedValue);
    },
    [externalOnChange, normalize],
  );

  const handleFocus = React.useCallback(
    e => {
      externalFocus?.(e.target.value);
      toggleFocus(true);
    },
    [externalFocus],
  );

  const { field, form } = useField({ name, defaultValue, validate, required });

  const handleBlur = React.useCallback(
    e => {
      e.target.value = e.target.value?.trim();
      onTextFieldChange(e, field?.onChange);
      field?.onBlur();
      externalBlur?.(e.target.value);
      toggleFocus(false)();
    },
    [onTextFieldChange, externalFocus, field?.onBlur],
  );

  if (!field && !form) {
    return null;
  }

  const isDisabled = disabled || form.disabled || form.progress;

  return (
    <PreloadField isLoading={form.initializing}>
      <FieldInput
        name={name}
        label={label}
        error={field.error}
        warning={field.warning}
        value={field.value}
        tooltip={tooltip}
        isFocus={isFocus}
        isLargeInput={isLargeInput}
        isDisabled={isDisabled}
      >
        <input
          id={name}
          type={account ? 'account' : 'text'}
          name={name}
          className={classnames('form-input', { 'form-input-large': isLargeInput })}
          placeholder={placeholder}
          value={(field.value as string) || ''}
          disabled={isDisabled}
          onChange={onTextFieldChange(field.onChange, field.value)}
          onBlur={handleBlur}
          onFocus={handleFocus}
          autoComplete={autoComplete}
        />
      </FieldInput>
    </PreloadField>
  );
};
