import { ConstantList } from 'api/ConstantService';
import { CONSTANT } from 'store/actions/types';
import { ActionInterface } from 'store/reducers/interfaces';

export type ConstantsState = Partial<ConstantList>;

const initialState: ConstantsState = {};

export const constantReducer = (state = initialState, action: ActionInterface): ConstantsState => {
  switch (action.type) {
    case CONSTANT.SET_CONSTANTS: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};
