import { api } from 'api';
import { OrderType } from 'api/DemandsService/enums';
import { DynamicField } from 'api/DemandsService/interfaces';
import { Account, OrderFieldResponse } from 'api/interfaces';
import { Option } from 'api/Service';
import config from 'config';
import { getDynamicField } from 'pages/Demands/utils';

export const isExistAccount = (id: string, accounts: Option[]): boolean =>
  accounts.some(item => item.value === id);
export const getAccount = (id: string, accounts: Option<Account>[]): Option<Account> =>
  accounts.find(item => item.value === id);
export const getFieldId = (value: string, orderField: OrderFieldResponse): number =>
  orderField.orderFieldModel.find(item => item.field.code === value).field.id;
export const getFieldValue = (field: string, dynamicFields: DynamicField[]): boolean =>
  getDynamicField(field, dynamicFields)?.data[0]?.booleanValue ?? false;

export const getFeeAccountValue = (
  accountId: number | string,
  options: Option<Account>[] = [],
): string =>
  accountId ? options.find(acc => Number(acc.content?.id) === Number(accountId))?.value : null;

export const hasSigningRules = async (
  payerAccountOptions: Option[],
  receiverAccountOptions: Option[],
  orderType: OrderType,
  customerId: number,
): Promise<boolean> => {
  const hasEscrouAccount =
    payerAccountOptions.some(item => item.content.type === config.page.accounts.ESCROU) ||
    !!receiverAccountOptions?.some(item => item.content.type === config.page.accounts.ESCROU);

  const checkIsAvailableSign = await api.payments.checkIsAvailableSignForCustomers(
    customerId,
    orderType || OrderType.ForeignTransfer,
  );

  const checkIsAvailableSignForEscrow = hasEscrouAccount
    ? await api.payments.checkIsAvailableSignForCustomers(
        customerId,
        orderType || OrderType.ForeignTransferESCROU,
      )
    : null;

  return checkIsAvailableSign || checkIsAvailableSignForEscrow;
};
