import React from 'react';
import { useLocation } from 'react-router';

import { parseUrlParams } from 'api/backend';
import { DemandType } from 'api/DemandsService/enums';
import { OrderActionType } from 'api/enums';
import { useOrder } from 'components/Document/useOrder';
import { withOrder } from 'components/Document/withOrder';
import { NavTab, NavTabs } from 'components/tabs/NavTabs';
import { translate } from 'i18n/translator';
import { allowDemandAction } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { DemandHistoryPage } from 'pages/Demands/DemandHistoryPage';
import '../styles.scss';
import { EmployeeReissueDetails } from 'pages/Demands/EmployeeManagment/EmploeeReissueeDetails';
import { EmployeeDismissalDetails } from 'pages/Demands/EmployeeManagment/EmployeeDismissalDetails';
import { EmployeeNewDetails } from 'pages/Demands/EmployeeManagment/EmployeeNewDetails';
import { createOnSave, fetchOrder } from 'pages/Demands/utils';
import { isExist } from 'utils/isData';

const goBack = () => goto(pages.employeesManagement);

const EmployeeManagementDemands: Partial<Record<DemandType, React.ReactNode>> = {
  [DemandType.SalaryEmployeeDismissal]: <EmployeeDismissalDetails />,
  [DemandType.SalaryEmployeeNew]: <EmployeeNewDetails />,
  [DemandType.SalaryEmployeeReissue]: <EmployeeReissueDetails />,
};

const renderDetails = (orderType: DemandType): React.ReactNode => {
  if (isExist(orderType)) {
    return EmployeeManagementDemands[orderType];
  }

  return null;
};

const EmployeeManagementDemandComponent: React.FC = () => {
  const { order } = useOrder();

  const { type } = parseUrlParams(useLocation().search);
  const orderType = type ?? order?.type;

  const Details = React.useMemo(() => renderDetails(orderType), [orderType]);

  return (
    <NavTabs>
      <NavTab
        title={translate('front.demand-detail.details.label')}
        path={pages.employeeManagement.tabs.details(':id')}
      >
        {Details}
      </NavTab>
      {!!order?.state && (
        <NavTab
          title={translate('front.demand-detail.history.label')}
          path={pages.employeeManagement.tabs.history(':id')}
        >
          <DemandHistoryPage />
        </NavTab>
      )}
    </NavTabs>
  );
};

export const EmployeeManagementDemandPage = withOrder(({ order }) => ({
  createOnSave,
  fetchOrder: fetchOrder,
  allowState: true,
  allowDetails: true,
  allowSave:
    order && allowDemandAction(order.type as DemandType, OrderActionType.EDIT, +order.customerId),
  allowSign:
    order && allowDemandAction(order.type as DemandType, OrderActionType.SIGN, +order.customerId),
  disableButtons: true,
  afterSubmit: goBack,
}))(EmployeeManagementDemandComponent);
