import React from 'react';

import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextAreaField } from 'components/forms/inputs/TextAreaField';
import { TextField } from 'components/forms/inputs/TextField';
import { useDisabledCustomer } from 'hooks/useDisabledCustomer';
import { CardFieldNames } from 'pages/Demands/CardDemand/enums';
import { CardPayload } from 'pages/Demands/CardDemand/interfaces';

interface CardClosingFields {
  [CardFieldNames.Customer]: string;
  [CardFieldNames.CardAccount]: string;
  [CardFieldNames.CloseReason]: string;
  [CardFieldNames.DoExternalFeeAcc]: string;
  [CardFieldNames.ExternalFeeAccName]: string;
  [CardFieldNames.ExternalFeeAccCode]: string;
  [CardFieldNames.ExternalFeeAccNumber]: string;
}

export const CardClosingDetail = () => {
  const {
    order,
    payload: { cardAccount },
    onChangeCustomer,
    form: { getFieldValue },
  } = useOrderDetails<CardClosingFields, CardPayload>();

  const isDisabledCustomer = useDisabledCustomer(order);

  const isCheckedDoExternalFeeAcc = getFieldValue(CardFieldNames.DoExternalFeeAcc);

  return (
    <DefaultForm>
      <CustomerSelect
        name={CardFieldNames.Customer}
        label="front.card-edit.organization.label"
        onSelectOption={onChangeCustomer}
        selectDefaultCustomer
        disabled={isDisabledCustomer}
        required
      />
      <SelectField
        name={CardFieldNames.CardAccount}
        label="front.card-edit.cardAccount.label"
        options={cardAccount}
        required
      />
      <TextAreaField
        name={CardFieldNames.CloseReason}
        label="front.card-edit.closeReason.label"
        maxLength={160}
        rows={3}
        hasCounter
        required
      />
      <CheckboxField
        name={CardFieldNames.DoExternalFeeAcc}
        label="front.card-edit.doExternalFeeAcc.label"
      />
      {isCheckedDoExternalFeeAcc && (
        <>
          <TextField
            name={CardFieldNames.ExternalFeeAccName}
            label="front.card-edit.externalFeeAccName.label"
            required
          />
          <AmountField
            name={CardFieldNames.ExternalFeeAccCode}
            label="front.card-edit.externalFeeAccCode.label"
            required
          />
          <TextField
            name={CardFieldNames.ExternalFeeAccNumber}
            label="front.card-edit.externalFeeAccNumber.label"
            account
          />
        </>
      )}
    </DefaultForm>
  );
};
