import { StatusColor } from 'components/layout/Status';

import { get } from './backend';
import { GridRequest, GridResponse } from './Service';

type Action = {
  category: string;
  code: string;
  deleted: boolean;
  id: number;
  description?: string;
  descriptionTranslation?: string;
  label?: string;
};

type Channel = {
  deleted: boolean;
  category?: string;
  code?: string;
  description?: string;
  descriptionTranslation?: string;
  label?: string;
};

export type Event = {
  action: Action;
  channel: Channel;
  id: number;
  status: string;
  terminal: string;
  when: string;
  comment?: string;
};

export enum EventStatus {
  Bad = 'bad',
  Ok = 'ok',
}

export interface ProtocolsFilter {
  text: string;
}

export const getStatusColor = (status: EventStatus): StatusColor => {
  switch (status.toLocaleLowerCase()) {
    case EventStatus.Ok:
      return 'green';
    case EventStatus.Bad:
      return 'red';
    default:
      return 'grey';
  }
};

type ProtocolsRequest = GridRequest & ProtocolsFilter;

export class EventService {
  async getCurrent(request: ProtocolsRequest): Promise<GridResponse<Event>> {
    return await get('/events/current', request);
  }
}
