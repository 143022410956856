import React from 'react';

import moment from 'moment';

import { DemandType } from 'api/DemandsService/enums';
import { DynamicFieldBase } from 'api/DemandsService/interfaces';
import { Order } from 'api/OrderService';
import { getMaxDate } from 'components/Document/utils';
import { DateField } from 'components/forms/inputs/DateField';
import { PreloadField } from 'components/forms/inputs/PreoadField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { dateTimeFull } from 'components/utils/format';
import { translate } from 'i18n/translator';
import { depositOrderTypes } from 'pages/Demands/DemandFilter';

import { EmbeddedEditField } from '../forms/embeddedEditField/EmbeddedEditField';
import { useDateOperation } from './useDateOperation';

import './document-details.scss';

interface Props {
  order: Order;
  allowExecutionDate?: boolean;
  allowOperationDate?: boolean;
  allowValueDate?: boolean;
}

const MAX_DAYS_ORDER_DATE = 30;
const MAX_DAYS_VALUE_DATE = 10;
const TODAY = moment().toDate();
const MAX_DATE_ORDER = getMaxDate(TODAY, MAX_DAYS_ORDER_DATE);

export const DocumentDetails = ({
  order,
  allowValueDate,
  allowOperationDate,
  allowExecutionDate,
}: Props) => {
  const { maxDate, disabledDays } = useDateOperation(order?.type as DemandType);
  const isDepositOrder = depositOrderTypes.includes(order?.type as DemandType);
  const { getFieldValue, initializing } = useForm<DynamicFieldBase>();
  const orderDate = getFieldValue('orderDate') as Date;
  const executionDate = dateTimeFull(order?.executionTime);
  const minValueDate = moment(orderDate).toDate();
  const maxValueDate = getMaxDate(orderDate, MAX_DAYS_VALUE_DATE);

  return (
    <div className="document-details">
      <div className="document-details_col">
        <EmbeddedEditField
          label="front.internal-payment-page.order-number.label"
          name="orderNumber"
          maxLength={!isDepositOrder && 10}
          required
        />
      </div>
      <div className="document-details_col">
        <span className="text">
          {translate('front.internal-payment-page.order-date-from.label')}
        </span>
        <DateField
          label=""
          name="orderDate"
          maxDate={MAX_DATE_ORDER}
          disabledDays={{
            before: TODAY,
            after: MAX_DATE_ORDER,
          }}
          hasOutline={false}
          required
        />
      </div>
      {allowValueDate && (
        <div className="document-details_col">
          <span className="text"> {translate('front.internal-payment-page.order-date.label')}</span>
          <DateField
            label=""
            name="valueDate"
            maxDate={maxValueDate}
            minDate={minValueDate}
            disabledDays={{
              before: minValueDate,
              after: maxValueDate,
            }}
            hasOutline={false}
          />
        </div>
      )}
      {allowOperationDate && (
        <div className="document-details_col">
          <span className="text">
            {translate('front.internal-payment-page.order-date-operation.label')}
          </span>
          <DateField
            label=""
            name="dateOperation"
            disabledDays={disabledDays}
            placeholder="_______"
            maxDate={maxDate}
            hasOutline={false}
            required
          />
        </div>
      )}
      {allowExecutionDate && executionDate && (
        <div className="document-details_col">
          <span className="text">
            {translate('front.internal-payment-page.order-date-executed.label')}
          </span>
          <PreloadField height={20} width={140} isLoading={initializing} display="table-cell">
            <span className="text-date">{executionDate}</span>
          </PreloadField>
        </div>
      )}
    </div>
  );
};
