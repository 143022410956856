import { SignFlag } from './SignatoryRow/SignatoryRow';

export const getSignFlag = (signed = false, rejected = false) => {
  if (rejected) {
    return SignFlag.Rejected;
  }
  if (signed) {
    return SignFlag.Signed;
  }
  return SignFlag.NeedSign;
};
