import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import config from 'config';
import { CustomerSelectModal } from 'pages/Login/CustomerSelectModal/CustomerSelectModal';
import {
  CustomerSelectModalProps,
  CustomerSelectMode,
} from 'pages/Login/CustomerSelectModal/types';
import { FactorType } from 'pages/Login/LoginPage';
import { UserActions } from 'store/actions/user';
import { UserState } from 'store/reducers/user';
import { selectChosenCustomers, selectUser } from 'store/selectors';

const { avatarIcon } = config.page.dashboard;

export const useUserInfo = () => {
  const chosenCustomers = useSelector(selectChosenCustomers());
  const { avatar, userFullName, currentAuthFactor } = useSelector(selectUser());
  const dispatch = useDispatch();

  const updateUser = (user: UserState) => dispatch(UserActions.setUser(user));

  const onChooseCustomers = React.useCallback(async () => {
    const user = await confirmModal<UserState, CustomerSelectModalProps>(CustomerSelectModal, {
      mode:
        currentAuthFactor === FactorType.Signature
          ? CustomerSelectMode.readOnly
          : CustomerSelectMode.select,
    });

    if (user) {
      updateUser(user);

      location.reload();
    }
  }, [currentAuthFactor]);

  return {
    avatar: avatar || avatarIcon,
    chosenCustomers,
    userFullName,
    onChooseCustomers,
  };
};
