import * as React from 'react';
import { ExpandRowProps } from 'react-data-table';
import { Col, Row } from 'react-grid';

import { CardView } from 'api/ProductsService';
import * as format from 'components/utils/format';
import { translate } from 'i18n/translator';

import './styles.scss';

const getDetails = (row: CardView) => [
  {
    render: () => {
      const label = translate('front.cards-page.card-number.label');
      const value = row.cardNumber;
      return <DataColumn label={label} value={value} />;
    },
  },
  {
    render: () => {
      const label = translate('front.deposits-page.togglerow-product-code.label');
      const value = row.contractNumber;
      return <DataColumn label={label} value={value} />;
    },
  },
  {
    render: () => {
      const label = translate('front.working-documents-filter-form.currency.label');
      const value = row.currency;
      return <DataColumn label={label} value={value} />;
    },
  },
  {
    render: () => {
      const label = translate('front.cards-page.card-type.label');
      const value = row.cardType;
      return <DataColumn label={label} value={value} />;
    },
  },
  {
    render: () => {
      const label = translate('front.cards-page.valid-until.label');
      const value = row.finishDate;
      return <DataColumn label={label} value={value} />;
    },
  },
  {
    render: () => {
      const label = translate('front.cards-page.card-owner.label');
      const value = row.ownerName;
      return <DataColumn label={label} value={value} />;
    },
  },
  {
    render: () => {
      const label = translate('front.cards-page.card-status.label');
      const value = row.statusTranslation;
      return <DataColumn label={label} value={value} />;
    },
  },
  {
    render: () => {
      const label = translate('front.cards-page.card-available-balance.label');
      const value = format.getAmount(row.balance);
      return <DataColumn label={label} value={value} />;
    },
  },
  {
    render: () => {
      const label = translate('front.cards-page.card-blocking-reason.label');
      const value = row.blockUnblockReason;
      return <DataColumn label={label} value={value} />;
    },
  },
  {
    render: () => {
      const label = translate('front.cards-page.card-account-number.label');
      const value = row.iban ? row.iban : row.accountNumber;
      return <DataColumn label={label} value={value} />;
    },
  },
  {
    render: () => {
      const label = translate('front.cards-page.card-account-status.label');
      const value = row.accountStatusTranslation;
      return <DataColumn label={label} value={value} />;
    },
  },
  {
    render: () => {
      const label = translate('front.cards-page.card-available-money.label');
      const value = format.getAmount(row.balance);
      return <DataColumn label={label} value={value} />;
    },
  },
  {
    render: () => {
      const label = translate('front.cards-page.card-account-owner.label');
      return <DataColumn label={label} value={row.organization} />;
    },
  },
  {
    render: () => {
      const label = translate('front.menu.branches.label');
      const value = row.bankBranch;
      return <DataColumn label={label} value={value} />;
    },
  },
];

export const CardDetails: React.FC<ExpandRowProps> = ({ row }) => {
  return (
    <Row>
      {getDetails(row).map((item, index) => (
        <React.Fragment key={index}>{item.render()}</React.Fragment>
      ))}
    </Row>
  );
};

const DataColumn = ({ label = '', value = '' }) => {
  return (
    <Col sm={12} md={6} className="card-details__col">
      <Row>
        <Col sm={4} className="card-details__col__label">
          {label}
        </Col>
        <Col sm={8} className="card-details__col__value">
          {value}
        </Col>
      </Row>
    </Col>
  );
};
