import * as React from 'react';

import { Button } from 'components/buttons/Button';
import { translate } from 'i18n/translator';
import { Success } from 'pages/Home/Icons';

export const SuccessModal = ({ resolve }: { resolve: () => any }) => (
  <div style={{ textAlign: 'center' }}>
    <Success />
    <h1>{translate('front.account-statements-modal.title.label')}</h1>
    <Button color="secondary" size="lg" onClick={resolve}>
      {translate('front.account-statements-modal.close.label')}
    </Button>
  </div>
);
