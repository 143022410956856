import moment from 'moment';

import { api } from 'api';
import { mapKeyTypesToConstants } from 'api/CertificateService/CertificateService';
import * as CipherService from 'api/CertificateService/CipherModule/CipherService';
import {
  ConnectedTokenInfoParams,
  ImportCertificateData,
  TokenInfo,
} from 'api/CertificateService/CipherModule/interfaces';
import { CertificateStatus, KeyType } from 'api/CertificateService/enums';
import { Certificate } from 'api/CertificateService/interfaces';
import { ActionEnum } from 'api/enums';
import { DataFromWebBank } from 'api/interfaces';
import { GetCurrentDataFromWebBankParams } from 'api/PaymentService/interfaces';
import { Option } from 'api/Service';
import { Customer, PersonConfig } from 'api/UserService';
import { confirmationModal } from 'components/modals/ConfirmModal';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { translate } from 'i18n/translator';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { ExpandedCustomer } from 'pages/Login/CustomerSelectModal/types';
import { KeyKepType } from 'pages/Profile/Certificates/KepCertificate/enums';
import { IdentificationData } from 'pages/Profile/Certificates/KepCertificate/interfaces';
import { CertificatesError } from 'pages/Profile/Certificates/KepCertificate/Modals/CertificatesError';
import { ConfirmationIdentificationPage } from 'pages/Registration/ConfirmationIdentificationPage/ConfirmationIdentificationPage';
import { isString } from 'utils/isData';

interface CertificateVerificationResult {
  isReissuedCert: boolean;
  isVerifiedCert: boolean;
}

export const ERROR_CODES = [
  'error.client.verification.failed',
  'error.person.sign.action.prohibited',
];

export const keyKepTypes: Option<undefined, KeyType>[] = [
  {
    value: KeyType.TOKEN,
    label: translate('front.cert-page-new.options-key-kep-types.token.label'),
  },
  {
    value: KeyType.FILE,
    label: translate('front.cert-page-new.options-key-kep-types.file.label'),
  },
];

export const getCustomer = (customerId: number, customers: ExpandedCustomer[]) =>
  customers.find(c => c.id === customerId);

export const getKeyTypeOptions = (constants: Omit<PersonConfig, 'IP_RESTRICTION' | 'HAS_ROLE'>) =>
  keyKepTypes.filter(keyType => constants[mapKeyTypesToConstants[keyType.value]]);

export const hasSomeActiveCertificate = (certificates: Certificate[]): boolean =>
  certificates.some(
    cert => cert.status === CertificateStatus.Active || cert.status === CertificateStatus.Certified,
  );

const defaultCertificateVerification = async (
  tokenOption: Option,
  password: string,
): Promise<CertificateVerificationResult> => {
  const ticketUuid = await CipherService.createSession();

  await CipherService.createSessionContainer({
    ticketUuid,
    keyStorePath: tokenOption.content.activeModePath,
  });

  const tokenCertificate = await CipherService.getCertificateBase64(
    ticketUuid,
    password,
    ActionEnum.CreateCertificate,
  );

  if (isString(tokenCertificate)) {
    await confirmModal(CertificatesError);
    await CipherService.closeSession(ticketUuid);

    return {
      isVerifiedCert: false,
      isReissuedCert: false,
    };
  }

  await CipherService.closeSession(ticketUuid);

  return {
    isVerifiedCert: true,
    isReissuedCert: false,
  };
};

export const certificateVerification = async (
  currentCustomerCerts: Certificate[],
  tokenOption: Option<TokenInfo>,
  password: string,
): Promise<CertificateVerificationResult> => {
  if (hasSomeActiveCertificate(currentCustomerCerts)) {
    const ticketUuid = await CipherService.createSession();

    await CipherService.createSessionContainer({
      ticketUuid,
      keyStorePath: tokenOption.content.activeModePath,
    });

    const tokenCertificate = await CipherService.getCertificateBase64(
      ticketUuid,
      password,
      ActionEnum.CreateCertificate,
    );
    const isCurrentCertificateToken = currentCustomerCerts.some(
      cert => cert.userKeystoreName === tokenOption.content.serial,
    );

    if (isString(tokenCertificate) || !isCurrentCertificateToken) {
      await confirmationModal('front-profile.prohibited-certificate.label', false, false);
      await CipherService.closeSession(ticketUuid);

      return {
        isVerifiedCert: false,
        isReissuedCert: false,
      };
    }

    await CipherService.closeSession(ticketUuid);

    return {
      isVerifiedCert: true,
      isReissuedCert: true,
    };
  }

  return await defaultCertificateVerification(tokenOption, password);
};

const isEdrpouMatches = (edrpou: string, customerEdrpou: string) => edrpou === customerEdrpou;

export const isConnectedToken = ({
  edrpou,
  currentCertificates,
  cipherCertificate,
  cipherCertificateBase64,
  connectedToSystem = true,
}: ConnectedTokenInfoParams): boolean => {
  const { certificateInfo, personalData } = cipherCertificate;

  if (!personalData) return false;

  const personalInn = personalData.edrpou.value ?? personalData.drfou.value;
  const equalMatches = edrpou ? isEdrpouMatches(personalInn, edrpou) : true;

  const isActualDate = moment().isBetween(
    certificateInfo.notBefore.value,
    certificateInfo.notAfter.value,
  );

  const equalCertificate =
    connectedToSystem ===
    currentCertificates.some(item => item?.certificate === cipherCertificateBase64);

  return equalMatches && equalCertificate && isActualDate;
};

export const allowedForCustomer = (isCreate: boolean, customer: Customer) => {
  const isDenyForCustomer = {
    isCreate: customer.config.ALLOW_TOKEN_KEY,
    default: customer.config.ALLOW_FILE_KEY || customer.config.ALLOW_TOKEN_KEY,
  };

  return isCreate ? isDenyForCustomer.isCreate : isDenyForCustomer.default;
};

export const getAllowedCustomerKep = (
  customers: Customer[],
  customerId: number,
  isCreate: boolean,
): Customer => {
  const defaultCustomer = customers.find(customer => customer.id === customerId);

  if (allowedForCustomer(isCreate, defaultCustomer)) {
    return defaultCustomer;
  }

  return customers.find(customer => allowedForCustomer(isCreate, customer));
};

export const importCertificates = async (
  ticketUuid: string,
  password: string,
  certificates: ImportCertificateData,
): Promise<void> => {
  const { certificate, certificateAgreement } = certificates;

  await CipherService.importCertificate(ticketUuid, password, certificate);
  certificateAgreement &&
    (await CipherService.importCertificate(ticketUuid, password, certificateAgreement));
};

export const confirmIssueCertificateKep = async (
  confirmPayload: GetCurrentDataFromWebBankParams,
): Promise<DataFromWebBank | null> => {
  const dataProfile = await api.payments.getCurrentDataFromWebBank(confirmPayload);

  const isConfirmedData = await confirmModal<IdentificationData>(
    ConfirmationIdentificationPage,
    { dataProfile, certificateType: confirmPayload.certificateType },
    640,
  );

  return isConfirmedData ? dataProfile : null;
};

export const gotoCertificatesPage = () => goto(pages.profile.tabs.certificates);

export const defaultStoreOption = [
  {
    label: 'front.certificate-confirmation.cloud-storage.label',
    value: KeyKepType.CLOUD_STORAGE as string,
  },
];
