import React from 'react';

import { useForm } from 'components/forms/ValidatingForm/useForm';
import { translate } from 'i18n/translator';
import { DocumentManagementDetailsNames } from 'pages/DocumentManagement/enums';
import { DocumentManagementDetailsFields } from 'pages/DocumentManagement/interfaces';

import { SignatoryRow } from './SignatoryRow/SignatoryRow';
import { getSignFlag } from './utils';

export const Managers = () => {
  const { getFormData } = useForm<DocumentManagementDetailsFields>();
  const formData = getFormData();

  if (!formData[DocumentManagementDetailsNames.ManagersSignatory]) {
    return;
  }

  return (
    <div>
      <h3>{translate('front.document-management.signatories_bank.label')}</h3>
      {formData[DocumentManagementDetailsNames.ManagersSignatory].map(manager => (
        <SignatoryRow
          key={manager.name}
          signFlag={getSignFlag(manager.signed, manager.rejected)}
          name={manager.name}
          role={manager.roleName}
          branch={manager.bankBranchName}
        />
      ))}
    </div>
  );
};
