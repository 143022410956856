import * as React from 'react';
import { SortOrder, TableColumnProps, useDataTable } from 'react-data-table';

import classnames from 'classnames';
import { Devices } from 'enums/DevicesEnum';

import { HasId } from 'api/Service';
import { Checkbox } from 'components/forms/checkbox/Checkbox';
import { Popover } from 'components/popover/Popover';
import { TableSortType } from 'components/react-data-table/types';
import { DataTableCheckbox } from 'components/Table/DataTableCheckbox';
import { bem } from 'components/utils/bem';
import config from 'config';

import { DataTableHeaderWrapper } from './DataTableHeaderWrapper';

const tableConfigsLogo = config.components.table.tableConfigGear;

interface TableHeadersProps {
  toggleColVisible: (columnLabel: string | string[]) => void;
  visibleColumns: TableColumnProps<HasId>[];
}

export const TableHeaders = ({ visibleColumns, toggleColVisible }: TableHeadersProps) => {
  const {
    rows,
    columns,
    actions,
    name,
    selection: { isAllSelected, toggleAllSelected },
    gridRequest: { sort, order, updateGridRequest },
    maxVisibleTableRows,
    breakpoint,
  } = useTableHeaders();
  const className = classnames({ ['settings-list']: columns.length > 7 });

  const isDisable = (checked: boolean) => {
    const defaultDisabled = checked && visibleColumns.filter(item => !item.renderMenu).length === 1;

    if (maxVisibleTableRows) {
      return (visibleColumns.length > maxVisibleTableRows && !checked) || defaultDisabled;
    }

    return defaultDisabled;
  };

  const getNewColumn = (col: TableColumnProps<any>, colInx: number) => {
    if (!col.showOnlySelectedDevices) {
      return { label: col.label, colIndex: colInx };
    }

    const isDesktop = breakpoint === Devices.Desktop && col.showDesktop;
    const isTablet = breakpoint === Devices.Tablet && col.showTablet;
    const isMobile = breakpoint === Devices.Mobile && col.showMobile;

    return isMobile || isTablet || isDesktop
      ? { label: col.label, colIndex: colInx }
      : { label: null, colIndex: null };
  };

  const renderTableSettings = () => {
    return (
      <ul className={className}>
        {columns.map((col, colIdx) => {
          if (!col || col.renderMenu) return null;

          const { label, colIndex } = getNewColumn(col, colIdx);

          const checked = visibleColumns.some(({ label }) => label === col.label);
          const disabled = isDisable(checked);

          return label ? (
            <li key={colIndex}>
              <DataTableCheckbox
                label={label}
                disabled={disabled}
                checked={checked}
                onChange={() => toggleColVisible(col.label)}
                name={`${name}-name-col-${colIdx}`}
                colSort={col.sort}
                sort={[sort]}
              />
            </li>
          ) : null;
        })}
      </ul>
    );
  };

  return (
    <thead className={bem('data-table-content', 'thead').toClassName()}>
      <tr>
        {rows.length > 0 && actions && (
          <th className={bem('data-table-content', 'th').toClassName()}>
            <Checkbox
              isAnimated={rows.length < 300 || !isAllSelected}
              checked={isAllSelected}
              onChange={toggleAllSelected}
              name={`${name}`}
              hasEnlargedArea
            />
          </th>
        )}
        {visibleColumns.map(
          (col, colIdx) =>
            !col.renderMenu && (
              <DataTableHeaderWrapper
                key={colIdx}
                col={col}
                sort={sort}
                order={order}
                setSort={(sort: TableSortType, order: SortOrder) =>
                  updateGridRequest({ sort, order, page: 0 })
                }
              />
            ),
        )}
        {visibleColumns.find(col => !!col.renderMenu) ? (
          <th
            className={bem('data-table-content', 'th').modificator('type', 'gear').toClassName()}
            id="table-settings"
          >
            <Popover
              className="table-settings-popover"
              label={<img src={tableConfigsLogo} alt="tableConfigsLogo" />}
              id={`${name}-table-settings`}
              isClickAutoClose={false}
              placement="left-end"
            >
              {renderTableSettings()}
            </Popover>
          </th>
        ) : null}
      </tr>
    </thead>
  );
};

export const useTableHeaders = () => {
  const { rows, columns, actions, name, selection, gridRequest, maxVisibleTableRows, breakpoint } =
    useDataTable();

  return {
    rows,
    columns,
    actions,
    selection,
    name,
    gridRequest,
    maxVisibleTableRows,
    breakpoint,
  };
};
