import React from 'react';
import { Input as BsInput } from 'reactstrap';

import * as Icon from 'components/icons';
import { confirmationModal } from 'components/modals/ConfirmModal';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';

import { AvatarModal } from './AvatarModal';

import './upload-avatar.scss';

interface Props {
  avatar: string;
  onUpload: (value: string) => void;
  accept?: string;
  size?: number;
}

const ACCEPT_TYPE = 'image/jpeg,image/jpg,image/png';

const validationFile = async (type: string) => {
  if (!ACCEPT_TYPE.includes(type) || type === '') {
    return await confirmationModal('front.upload-avatar.error-file-type.label', false, false);
  }

  return true;
};

const toBase64 = (file: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const UploadAvatar: React.FC<Props> = props => {
  const { avatar, accept = ACCEPT_TYPE, size = 80, onUpload } = props;

  const onChange = async (e: any) => {
    e.preventDefault();
    const { files } = e.target;

    const isValidAvatar = await validationFile(files[0].type);

    if (isValidAvatar) {
      const avatar = await toBase64(files[0]);
      const uploadAvatar = await confirmModal<string>(AvatarModal, { avatar }, 465);
      if (uploadAvatar) {
        onUpload(uploadAvatar);
      }
      const el = document.getElementById('upload-avatar-input') as any;
      el.value = '';
    }

    return;
  };

  return (
    <div className="upload-avatar">
      <div className="upload-avatar-wrap" style={{ width: size, height: size }}>
        <img
          className="upload-avatar__avatar-img"
          style={{ width: size, height: size }}
          src={avatar}
          alt="avatar"
        />
        <span className="upload-avatar__edit-icon">
          <Icon.EditPhoto />
          <BsInput
            accept={accept}
            onChange={onChange}
            type="file"
            className="file-input"
            id="upload-avatar-input"
          />
        </span>
      </div>
    </div>
  );
};
