import React from 'react';

import { api } from 'api';
import { DataFromWebBank } from 'api/interfaces';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { confirmModal, PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import { translate } from 'i18n/translator';
import {
  IssueUepKeyModalProps,
  UepKeyData,
} from 'pages/Profile/Certificates/KepCertificate/Modals/interfaces';
import { parseErrorMessage } from 'pages/Profile/Certificates/utils';

const IssueUepKeyDetail: React.ElementType<PropsWithConfirmModal<IssueUepKeyModalProps>> = ({
  confirmPayload,
  message,
  saveAfterConfirmation,
  resolve,
}): React.ReactElement<Promise<UepKeyData | boolean>> => {
  const { error, progress, setProgress, setError } = useForm();

  const handleGenerateUepKey = async () => {
    setProgress(true);
    let dataProfile: DataFromWebBank;

    try {
      dataProfile = await api.payments.getCurrentDataFromWebBank(confirmPayload);
    } catch (e: any) {
      throw setError(parseErrorMessage(e));
    }

    return saveAfterConfirmation
      ? saveAfterConfirmation({
          dataHash: dataProfile.dataHash,
          certificateType: confirmPayload.certificateType,
        })
      : resolve({
          isGenerateUEP: true,
          dataProfile,
        });
  };

  return (
    <DefaultForm disabled={progress}>
      <FormError>{error}</FormError>
      {!error && (
        <div>
          <h3 style={{ textAlign: 'center' }}>{message}</h3>
          <div className="confirm-modal__footer">
            <div>
              <Button onClick={handleGenerateUepKey} size="sm" disabled={!!error}>
                {translate('front.profile.key-generation-uep-key.label')}
              </Button>
            </div>
            <div>
              <Button onClick={() => resolve(false)} color="secondary" size="sm">
                {translate('front.confirm-modal.cancel.label')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </DefaultForm>
  );
};

const IssueUepKeyPage = withForm<PropsWithConfirmModal<IssueUepKeyModalProps>>(IssueUepKeyDetail);

export const confirmIssueCertificateUEP = async ({
  confirmPayload,
  message,
  saveAfterConfirmation,
}: IssueUepKeyModalProps): Promise<UepKeyData | null> => {
  const issueData = await confirmModal<UepKeyData | boolean, IssueUepKeyModalProps>(
    IssueUepKeyPage,
    {
      confirmPayload,
      message,
      saveAfterConfirmation,
    },
  );

  if (issueData) {
    return issueData as UepKeyData;
  }

  return null;
};
