import React from 'react';
import { Col, Row } from 'react-grid';

import { VatType } from 'api/enums';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { HintField } from 'components/forms/inputs/HintField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextAreaField } from 'components/forms/inputs/TextAreaField';
import { TextField } from 'components/forms/inputs/TextField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { toAmountFormat } from 'components/utils/format';
import config from 'config';
import { translate } from 'i18n/translator';
import { getClearValue } from 'pages/Demands/CashTransferPage/utils';
import { validateIban } from 'pages/Demands/utils';
import { DomesticTransferDetailNames } from 'pages/Payments/InternalPayment/enums';
import {
  Payload as InternalPaymentPagePayload,
  PaymentOrderFields,
} from 'pages/Payments/InternalPayment/interface';
import { maskIban, normalizeIban, normalizeVatPercent } from 'pages/Payments/InternalPayment/utils';
import { MAX_VAT_PERCENT } from 'pages/Payments/utils';
import { isExist } from 'utils/isData';

const VatPercentField = (
  isDisabled: DomesticDetailFieldsProps['isDisabled'],
  setStorageVatPercent: DomesticDetailFieldsProps['setStorageVatPercent'],
): React.ReactElement => {
  const { updateData } = useForm<PaymentOrderFields>();

  const onChangeVatPercent = (value: string): void => {
    const correctValue = normalizeVatPercent(value);

    setStorageVatPercent(correctValue);
    updateData({ vatPercent: correctValue });
  };

  return (
    <Row>
      <Col sm={6} />
      <Col sm={4}>
        <AmountField
          name={DomesticTransferDetailNames.VatPercent}
          label="front.domestic-transfer.vat-rate.label"
          onChange={onChangeVatPercent}
          normalize={normalizeVatPercent}
          maxAmount={MAX_VAT_PERCENT}
          disabled={isDisabled}
          required
        />
      </Col>
    </Row>
  );
};

const BudgetPaymentDetailFields = (
  isDisabled: boolean,
  notManualPurpose: boolean,
): React.ReactElement => {
  const { getFormData, setPayload, payload, updateData } = useForm<
    PaymentOrderFields,
    InternalPaymentPagePayload
  >();

  const { isCustomBudgetPurpose, budgetCode, explanatoryInfo } = getFormData();
  const { cachedBudgetInfo } = payload;

  React.useEffect(() => {
    if (isCustomBudgetPurpose && cachedBudgetInfo) {
      setPayload({
        budgetCode,
        explanatoryInfo,
      });
      updateData({
        budgetCode: null,
        explanatoryInfo: null,
      });
    }

    if (!isCustomBudgetPurpose && cachedBudgetInfo) {
      updateData({
        budgetCode: payload?.budgetCode,
        explanatoryInfo: payload?.explanatoryInfo,
      });
    }
  }, [isCustomBudgetPurpose, cachedBudgetInfo]);

  const onChangeIsCustomBudgetCode = () => {
    setPayload({ cachedBudgetInfo: true });
  };

  return (
    <>
      <h3 className="m-t-24">
        {translate('front.internal-payment-page.budget-payment-details.title.label')}
      </h3>
      <Row>
        <Col sm={8}>
          <SelectField
            name={DomesticTransferDetailNames.BudgetCode}
            label="front.internal-payment-page.budget-option.label"
            options={payload?.budgetsOptions}
            required={notManualPurpose}
            disabled={isCustomBudgetPurpose}
            clearable
          />
        </Col>
        <Col sm={4}>
          <TextField
            name={DomesticTransferDetailNames.PayerTaxCode}
            label="front.internal-payment-page.payer-code.label"
            required={notManualPurpose}
            disabled
          />
        </Col>
      </Row>
      <TextAreaField
        label="front.internal-payment-page.explanatory-info.label"
        name={DomesticTransferDetailNames.ExplanatoryInfo}
        rows={3}
        maxLength={119}
        required={notManualPurpose}
        normalize={getClearValue}
        disabled={isCustomBudgetPurpose || isDisabled}
        forbiddenSpecialCharacters
        denyCaretReturn
      />
      <CheckboxField
        label="front.internal-payment-page.order-allow-edit-purpose.label"
        name={DomesticTransferDetailNames.IsCustomBudgetPurpose}
        disabled={isDisabled}
        onChange={onChangeIsCustomBudgetCode}
      />
    </>
  );
};

const TaxesPaymentDetailFields = (isDisabled: boolean): React.ReactElement => {
  const { payload, updateData, getFormData, setErrors } = useForm<
    PaymentOrderFields,
    InternalPaymentPagePayload
  >();
  const { amount, taxAmount } = getFormData();

  React.useEffect(
    () =>
      updateData({
        [DomesticTransferDetailNames.TaxCurrency]: isExist(taxAmount)
          ? config.bank.nationalCurrency
          : null,
      }),
    [taxAmount],
  );

  React.useEffect(
    () =>
      setErrors({ [DomesticTransferDetailNames.TaxAmount]: validateTaxAmount(taxAmount) }, true),
    [amount],
  );

  const handleFocus = (event: any): void => {
    if (!event?.target?.value) {
      updateData({ [DomesticTransferDetailNames.TaxIban]: 'UA' });
    }
  };

  const validateTaxAmount = React.useCallback(
    (value: string | number): string => {
      if (isExist(value) && toAmountFormat(amount) !== toAmountFormat(value)) {
        return translate('front.internal-payment-page.error-tax-amount.label');
      }

      return '';
    },
    [amount],
  );

  return (
    <>
      <SelectField
        label="front.internal-payment-page.code-type-of-payment.label"
        name={DomesticTransferDetailNames.BudgetClassificationCode}
        options={payload?.budgetsOptions}
        disabled={isDisabled}
      />
      <TextAreaField
        label="front.internal-payment-page.additional-record-information.label"
        name={DomesticTransferDetailNames.AdditionalRecordInfo}
        rows={1}
        hasAutoGrowing
        maxLength={140}
        disabled={isDisabled}
      />
      <HintField
        label="front.internal-payment-page.account-number.label"
        name={DomesticTransferDetailNames.TaxIban}
        transform={maskIban}
        validate={validateIban}
        normalize={normalizeIban}
        length={29}
        disabled={isDisabled}
        onFocus={handleFocus}
        forbiddenSpecialCharacters
      />
      <AmountField
        label="front.internal-payment-page.tax-amount.label"
        name={DomesticTransferDetailNames.TaxAmount}
        maxLengthWithoutZero={12}
        validate={validateTaxAmount}
        thousandSeparator={' '}
        hasMoneyFormat
      />
      <TextField
        name={DomesticTransferDetailNames.TaxCurrency}
        label="front.internal-payment-page.tax-currency.label"
        disabled
      />
      <TextField
        name={DomesticTransferDetailNames.TaxNoticeInfo}
        label="front.internal-payment-page.information-tax-notice.label"
        maxLength={35}
        disabled={isDisabled}
      />
      <TextField
        name={DomesticTransferDetailNames.TaxNoticeType}
        label="front.internal-payment-page.type-tax-notice.label"
        maxLength={35}
        disabled={isDisabled}
      />
    </>
  );
};

interface DomesticDetailFieldsProps {
  isDisabled: boolean;
  notManualPurpose: boolean;
  setStorageVatPercent: (value: string) => void;
  vatType: VatType;
}

export const DomesticDetailFields = ({
  isDisabled,
  notManualPurpose,
  vatType,
  setStorageVatPercent,
}: DomesticDetailFieldsProps): React.ReactElement => {
  const renderFields = {
    [VatType.INCLUDE_VAT20]: VatPercentField(isDisabled, setStorageVatPercent),
    [VatType.EMPTY]: BudgetPaymentDetailFields(isDisabled, notManualPurpose),
    [VatType.WITHOUT_VAT]: null as React.ReactElement,
    [VatType.TAXES_PAYMENT]: TaxesPaymentDetailFields(isDisabled),
  };

  return renderFields[vatType];
};
