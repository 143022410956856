import React from 'react';
import { useRouteMatch } from 'react-router';

import moment from 'moment';

import { api } from 'api';
import { Option } from 'api/Service';
import { UserInterface } from 'api/UserService';
import { Button } from 'components/buttons/Button';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { DateField } from 'components/forms/inputs/DateField';
import { PhoneField } from 'components/forms/inputs/PhoneField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextField } from 'components/forms/inputs/TextField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { Page } from 'components/layout/Page/Page';
import { useUserCredentialsValidate } from 'hooks/useUserCredentialsValidate';
import { translate } from 'i18n/translator';
import { goBack, goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { ActionTypes, AuthorizedPersonFields } from 'pages/AuthorizedPersons/enums';
import { validateReceiverTaxCode } from 'pages/Payments/InternalPayment/utils';
import { getClearPhone } from 'utils/getClearPhone';
import { getMaskedPhone } from 'utils/getMaskedPhone';
import { isExist } from 'utils/isData';

import './styles.scss';

interface Payload {
  logins: Option[];
}

export const eighteenYearsBack = moment().add(-18, 'years').toDate();

export const disabledDaysDate = {
  after: eighteenYearsBack,
};

const validateByPostIndexLength = (value: string) => {
  if (value.length !== 5) {
    return translate('front.create-authorized-person.post-index-validation.label');
  }
  return '';
};

const postIndexMaskConfig = { allowNegative: false, decimalSeparator: ' ' };

const AuthorizedPersonDetail = () => {
  const {
    params: { id, action },
  } = useRouteMatch<{ id: string; action?: string }>();
  const isEdit = action === ActionTypes.EDIT;
  const isView = action === ActionTypes.VIEW;
  const isCreate = id === ActionTypes.CREATE;

  const {
    error,
    progress,
    handleSubmit,
    setDisabled,
    getFormData,
    updateData,
    resetData,
    payload: { logins },
  } = useForm<UserInterface, Payload>(async ({ setFields, setPayload }) => {
    if (isCreate) {
      const logins = await api.user.getAllCustomerUserInfo();

      return setPayload({ logins });
    }

    if (!isCreate) {
      const userInfo = await api.user.getCustomerUserInfo(+id);

      setFields({ ...userInfo, phone: getMaskedPhone(userInfo.phone) });
    }

    setDisabled(isView);
  });

  const { validateLogin } = useUserCredentialsValidate();
  const { login } = getFormData();

  const onSubmit = async ({ phone, ...formData }: UserInterface) => {
    const userRequest = { phone: getClearPhone(phone), ...formData };

    if (isCreate) {
      await api.user.createUser(userRequest);
      return goto(pages.authorizedPersons);
    }

    await api.user.updateUser(userRequest);
    return goto(pages.authorizedPersons);
  };

  React.useEffect(() => {
    const isLogin = isExist(login);

    if (isCreate && isLogin) {
      const hasLogin = logins.some(({ value }) => value === login);

      if (hasLogin) {
        const { content } = logins.find(({ value }) => value === login);

        return updateData({ ...content, phone: getMaskedPhone(content.phone) });
      }
    }

    if (!isLogin) {
      resetData();
    }
  }, [login]);

  return (
    <Page title="front.create-authorized-person.page.title">
      <DefaultForm>
        <FormError>{error}</FormError>
        <CustomerSelect
          name={AuthorizedPersonFields.CustomerId}
          label="front.create-authorized-person.customer.label"
          disabled={isEdit}
          required
        />
        {isCreate ? (
          <SelectField
            label="front.create-authorized-person.login.label"
            name={AuthorizedPersonFields.Login}
            options={logins}
            validate={validateLogin}
            isCreatableSelect
            clearable
            required
          />
        ) : (
          <TextField
            label="front.authorized-persons.table.login.label"
            name={AuthorizedPersonFields.Login}
            validate={validateLogin}
            required
          />
        )}
        <TextField
          label="front.create-authorized-person.last-name.label"
          name={AuthorizedPersonFields.LastName}
          maxLength={38}
          required
        />
        <TextField
          label="front.create-authorized-person.first-name.label"
          name={AuthorizedPersonFields.FirstName}
          maxLength={100}
          required
        />
        <TextField
          label="front.create-authorized-person.middle-name.label"
          name={AuthorizedPersonFields.MiddleName}
          maxLength={100}
        />
        <TextField
          label="front.create-authorized-person.tax-code.label"
          name={AuthorizedPersonFields.TaxCode}
          minLength={8}
          maxLength={10}
          validate={validateReceiverTaxCode}
          required
        />
        <DateField
          label="front.create-authorized-person.birth-date.label"
          name={AuthorizedPersonFields.BirthDate}
          disabledDays={disabledDaysDate}
          maxDate={eighteenYearsBack}
          required
        />
        <TextField label="front.create-authorized-person.email.label" name="email" email required />
        <TextField
          label="front.create-authorized-person.document-series.label"
          name={AuthorizedPersonFields.DocumentSerial}
          maxLength={50}
          required
        />
        <AmountField
          label="front.create-authorized-person.document-number.label"
          name={AuthorizedPersonFields.DocumentNumber}
          maxLength={50}
          required
        />
        <AmountField
          label="front.create-authorized-person.post-index.label"
          name={AuthorizedPersonFields.Zip}
          validate={validateByPostIndexLength}
          maskConfig={postIndexMaskConfig}
          required
        />
        <TextField
          label="front.create-authorized-person.address.label"
          name={AuthorizedPersonFields.Address}
          maxLength={100}
          required
        />
        <PhoneField
          label="front.create-authorized-person.phone.label"
          name={AuthorizedPersonFields.Phone}
          required
        />
        <div className="form-button-wrapper">
          {isView ? (
            <Button onClick={goBack} color="secondary" size="md">
              {translate('front.account-statements-modal.close.label')}
            </Button>
          ) : (
            <>
              <Button
                color="primary"
                onClick={event => handleSubmit(onSubmit, event)}
                progress={progress}
                type="submit"
                size="md"
              >
                {translate('front.auth-person.button-apply.label')}
              </Button>
              <Button
                color="secondary"
                onClick={goBack}
                progress={progress}
                type="submit"
                size="md"
              >
                {translate('front.auth-person.button-cancel.label')}
              </Button>
            </>
          )}
        </div>
      </DefaultForm>
    </Page>
  );
};

export const AuthorizedPersonPage = withForm(AuthorizedPersonDetail);
