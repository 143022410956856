import { useEffect, useRef, useState } from 'react';

type CountdownInit = {
  onExpired?: () => void;
  seconds?: number;
  startImmidiatly?: boolean;
};

const formatToTime = (time: number) => {
  if (time === 0) {
    return '00';
  }
  if (time < 10) {
    return `0${time}`;
  }
  return time;
};

export const useCountdown = (init: CountdownInit = {}) => {
  const [remain, setRemain] = useState<number>(init.seconds || 0);
  const [started, setStarted] = useState<boolean>(init.startImmidiatly);
  const timeout = useRef<any>();

  useEffect(() => {
    if (started) {
      timeout.current = setTimeout(() => tick(remain), 1000);
      return () => {
        clearTimeout(timeout.current);
      };
    }

    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }, [started]);

  useEffect(() => {
    if (started && remain === 0) {
      stop();
      init.onExpired && init.onExpired();
    }
  }, [remain]);

  const start = (sec: number = init.seconds || 0) => {
    setRemain(sec);
    setStarted(true);
  };

  const stop = () => {
    setStarted(false);
  };

  const tick = (prev: number) => {
    if (prev > 0) {
      const next = prev - 1;
      timeout.current = setTimeout(() => tick(next), 1000);
      setRemain(next);
    }
  };

  const minutes = Math.floor(remain / 60);
  const seconds = remain % 60;
  const time = `${formatToTime(minutes)}:${formatToTime(seconds)}`;

  return {
    start,
    stop,
    started,
    remain,
    time,
  };
};
