import * as React from 'react';
import { useDataTable } from 'react-data-table';

import { bem } from 'components/utils/bem';
import { isString } from 'utils/isData';

import { TableHeaders } from './TableHeaders';
import { TableRows } from './TableRows';

import './data-table-content.scss';

export const DataTableContent = () => {
  const { tableConfig, columns, hideHeader, breakpoint, updateTableConfig } = useTableContent();

  const visibleColumns = React.useMemo(
    () =>
      columns.filter(
        col =>
          col &&
          (col.renderMenu ||
            (isString(col.label)
              ? tableConfig[breakpoint].includes(col.label)
              : (tableConfig[breakpoint] as string[]).some(
                  item => JSON.stringify(item) === JSON.stringify(col.label),
                ))),
      ),
    [columns, breakpoint, tableConfig],
  );

  return (
    <div className={bem('data-table-content').toClassName()}>
      <table className={bem('data-table-content', 'table').toClassName()}>
        {!hideHeader && (
          <TableHeaders visibleColumns={visibleColumns} toggleColVisible={updateTableConfig} />
        )}
        <TableRows visibleColumns={visibleColumns} />
      </table>
    </div>
  );
};

const useTableContent = () => {
  const { columns, filter, name, breakpoint, hideHeader, tableConfig, updateTableConfig } =
    useDataTable();

  return {
    columns,
    filter,
    breakpoint,
    hideHeader,
    tableName: name,
    tableConfig,
    updateTableConfig,
  };
};
