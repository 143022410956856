class LocalStorageClass {
  getItem = (key: string, defaultValue?: any) => {
    const value = localStorage.getItem(key);
    try {
      return JSON.parse(value) ?? defaultValue;
    } catch (e) {
      return value;
    }
  };

  setItem = (key: string, value: any) => localStorage.setItem(key, JSON.stringify(value));

  clear = () => localStorage.clear();
}

export const LocalStorage = new LocalStorageClass();
