import * as React from 'react';
import { Col, Row, Container } from 'react-grid';
import { RouteComponentProps } from 'react-router';

import { api } from 'api';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { TextField } from 'components/forms/inputs/TextField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { translate } from 'i18n/translator';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { ProductEdit } from 'pages/Products/Accounts/AccountEditPage';

import './styles.scss';

interface Fields {
  alias: string;
}

type Props = RouteComponentProps<{ id: string }>;

const DepositEditForm: React.FC<Props> = props => {
  const { progress, error, handleSubmit } = useForm<Fields>(async ({ setFields }) => {
    const { product } = await api.products.getDeposit(+props.match.params.id);
    setFields({ alias: product.alias });
  });

  const onSave = async (data: Fields) => {
    const { id } = props.match.params;
    const depositData: ProductEdit = {
      id,
      alias: data.alias.trim(),
    };
    await api.products.updateDeposit(depositData);

    goto(pages.deposits);
  };

  return (
    <>
      <h4 className="text-center">{translate('front.deposit-edit-page.title.label')}</h4>
      <DefaultForm>
        <FormError>{error}</FormError>
        <div className="deposit-block__form__sub-title">
          {translate('front.deposit-edit-page.sub-title.label')}
        </div>
        <Container>
          <Row>
            <Col>
              <TextField label="front.deposit-edit-page.input-alias.label" name="alias" required />
            </Col>
            <Col md={6}>
              <Button
                color="primary"
                onClick={e => handleSubmit(onSave, e)}
                progress={progress}
                className="deposit-block__form__btn"
                type="submit"
                size="sm"
                block
              >
                {translate('front.deposit-edit-page.apply-button.label')}
              </Button>
            </Col>
            <Col md={6}>
              <Button
                color="secondary"
                onClick={() => goto(pages.deposits)}
                progress={progress}
                className="deposit-block__form__btn"
                type="submit"
                size="sm"
                block
              >
                {translate('front.deposit-edit-page.cancel-button.label')}
              </Button>
            </Col>
          </Row>
        </Container>
      </DefaultForm>
    </>
  );
};

export const DepositEditPage = withForm(DepositEditForm);
