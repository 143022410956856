import { Option } from 'api/Service';
import { getCurrentMonth } from 'components/forms/datePicker/utils';

export const getOptionsFromRows = <T>(
  rows: T[],
  getValue: (row: T) => any,
  createOptionFromRow: (row: T) => Option,
) => {
  const [options] = rows.reduce(
    (acc, row) => {
      const [options, uniqueValues] = acc;
      const value = getValue(row);

      if (uniqueValues.includes(value)) {
        return acc;
      }

      uniqueValues.push(value);
      options.push(createOptionFromRow(row));

      return acc;
    },
    [[], []],
  );

  return options;
};

export const [start, end] = getCurrentMonth();
