import * as React from 'react';

import { FieldData, FormState } from 'components/forms/ValidatingForm/FormContext';
import { useFormInternal } from 'components/forms/ValidatingForm/useFormInternal';
import { fromPlain } from 'components/forms/ValidatingForm/utils';

type RenderContainer = (items: React.ReactNode, push: () => void) => React.ReactNode;

type RenderItem = (prefix: string, index: number, remove: () => void) => React.ReactNode;

interface Props {
  name: string;
  renderContainer: RenderContainer;
  renderItem: RenderItem;
  defaultValue?: FormState[];
}

interface ArrayFieldContextValue {
  name: string;
  defaultValue?: FormState[];
}

export const ArrayFieldContext = React.createContext<ArrayFieldContextValue>({
  name: '',
});

export const ArrayField = ({ name, renderContainer, renderItem, defaultValue }: Props) => {
  const { fieldsData, registerFieldsArray, setFieldsData } = useFormInternal();

  React.useEffect(() => {
    registerFieldsArray(name, () => null);
  }, []);

  const [entries, setEntries] = React.useState<number[]>([]);

  React.useEffect(() => {
    const last = name.split('.').reverse()[0];
    const plainData = Object.entries(fieldsData).reduce((acc, [fieldName, fieldValue]) => {
      if (fieldName.startsWith(`${name}[`)) {
        const n = fieldName.search(RegExp(`${last}\\[\\d\\]`));
        return { ...acc, [fieldName.substr(n)]: { ...fieldValue } };
      }
      return acc;
    }, {});

    setEntries((fromPlain(plainData)[last] as any[]) || []);
  }, [fieldsData]);

  const actions = {
    push: () => setEntries([...entries, entries.length + 1]),
    delete: (index: number) => {
      const { included, excluded } = Object.entries(fieldsData).reduce(
        (
          acc: { excluded: Record<string, FieldData>; included: Record<string, FieldData> },
          [fieldName, fieldData],
        ) => {
          if (fieldName.startsWith(`${name}[`)) {
            if (!fieldName.startsWith(`${name}[${index}]`)) {
              acc.included[fieldName] = fieldData;
            }
          } else {
            acc.excluded[fieldName] = fieldData;
          }
          return acc;
        },
        { included: {}, excluded: {} },
      );

      const result = Object.entries(included).reduce((acc, [fieldName, fieldData]) => {
        const match = RegExp(`${name}\\[(\\d)\\]`).exec(fieldName);
        const currentIndex = match ? Number(match[1]) : -1;
        if (currentIndex > index) {
          return {
            ...acc,
            [fieldName.replace(`[${currentIndex}]`, `[${currentIndex - 1}]`)]: fieldData,
          };
        }
        return { ...acc, [fieldName]: fieldData };
      }, {});

      setFieldsData({ ...result, ...excluded });
    },
  };

  const items = entries.map((item, index) => renderItem(name, index, () => actions.delete(index)));

  return (
    <ArrayFieldContext.Provider value={{ name, defaultValue }}>
      {renderContainer(items, actions.push)}
    </ArrayFieldContext.Provider>
  );
};
