import * as React from 'react';

import { OrderActionType, OrderState } from 'api/enums';
import { Order, OrderAction } from 'api/OrderService';
import { Button } from 'components/buttons/Button';
import { orderControlsSign } from 'components/Document/utils';
import { OnSaveOptions } from 'components/Document/withOrder';
import { FormState, HandleSubmitFn } from 'components/forms/ValidatingForm/FormContext';
import { translate } from 'i18n/translator';
import { isExistOrder } from 'utils/isData';

import { OrderActionButton } from './OrderActionButton';

interface Props {
  handleSubmit: HandleSubmitFn<any>;
  onSave: (formData: any, options?: OnSaveOptions) => Promise<number>;
  order: Order;
  progress: boolean;
  afterSubmit?: (order: Order, options?: OnSaveOptions) => void;
  allowSave?: boolean;
  allowSign?: boolean;
  disableButtons?: boolean;
  isDisabledSave?: boolean;
  showSaveButton?: boolean;
  showSignOnForm?: boolean;
}

enum ButtonsType {
  Sign = 'sign',
  Save = 'save',
}

export type SignType = 'Signature' | 'SMS' | 'EDS';

export const OrderControls: React.FC<Props> = props => {
  const {
    onSave,
    order,
    handleSubmit,
    progress,
    allowSign,
    allowSave,
    afterSubmit,
    disableButtons,
    showSaveButton = true,
    isDisabledSave,
    showSignOnForm = false,
  } = props;

  const [typePressedButton, setTypePressedButton] = React.useState<ButtonsType>();

  if (!isExistOrder(order)) {
    return null;
  }

  const [signAction, , dropdownActions]: [OrderAction, OrderAction[], OrderAction[]] =
    order.actions?.reduce(
      (acc, action) => {
        if (action.type === 'signButton') {
          if (!order.state || showSignOnForm) {
            acc[0] = action;
          } else {
            acc[2].push(action);
          }
          return acc;
        }

        if (action.type === 'button') {
          acc[1].push(action);
          return acc;
        }

        if (action.type === 'dropdown') {
          acc[2].push(action);
          return acc;
        }
      },
      [null, [], []],
    ) ?? [null, [], []];

  const onSign = async (formData: FormState) => {
    setTypePressedButton(ButtonsType.Sign);

    let id = order.id;
    if (allowSave && (order.state === OrderState.Draft || (!order.state && !order.id))) {
      id = await onSave(formData);
    }

    const signAction = order.actions.find(action => action.name === OrderActionType.SIGN);
    await (signAction?.onClick ? signAction.onClick(order) : orderControlsSign(id));
    afterSubmit && afterSubmit(order);
  };

  const enhancedOnSave = async (formData: FormState) => {
    setTypePressedButton(ButtonsType.Save);

    onSave && (await onSave(formData));
    afterSubmit && afterSubmit(order);
  };

  const createdOnSign = async (e: any) => await handleSubmit(onSign, e);

  const renderButton = (buttonType: ButtonsType, disableButtons: boolean) => {
    const createdOnSave = (e: any) => handleSubmit(enhancedOnSave, e);
    const isSignProgress = typePressedButton === ButtonsType.Sign;
    const isSaveProgress = typePressedButton === ButtonsType.Save;

    if (disableButtons) {
      switch (buttonType) {
        case ButtonsType.Sign:
          return (
            !order.id && (
              <Button
                color="primary"
                disabled={isSaveProgress || !(allowSave && allowSign && (signAction || !order.id))}
                progress={isSignProgress && progress}
                onClick={createdOnSign}
              >
                {translate('front.demand-form.sign-button.label')}
              </Button>
            )
          );
        case ButtonsType.Save:
          return (
            ((order.state === OrderState.Draft && showSaveButton) || !order.id) && (
              <Button
                color="secondary"
                progress={isSaveProgress && progress}
                disabled={isSignProgress || !(allowSave && onSave) || isDisabledSave}
                onClick={createdOnSave}
              >
                {translate('front.demand-form.save-button.label')}
              </Button>
            )
          );
      }
    } else {
      switch (buttonType) {
        case ButtonsType.Sign:
          return (
            allowSign &&
            (signAction || !order.id) && (
              <Button color="primary" progress={isSignProgress && progress} onClick={createdOnSign}>
                {translate('front.demand-form.sign-button.label')}
              </Button>
            )
          );
        case ButtonsType.Save:
          return (
            allowSave &&
            onSave &&
            (order.state === OrderState.Draft || !order.id) && (
              <Button
                color="secondary"
                progress={isSaveProgress && progress}
                onClick={createdOnSave}
              >
                {translate('front.demand-form.save-button.label')}
              </Button>
            )
          );
      }
    }
  };

  return (
    <>
      {renderButton(ButtonsType.Sign, disableButtons)}
      {renderButton(ButtonsType.Save, disableButtons)}

      <OrderActionButton
        onSign={createdOnSign}
        color="secondary"
        actions={dropdownActions}
        order={order}
      />
    </>
  );
};
