import * as React from 'react';
import { TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { CustomerRole } from 'api/AuthService';
import { SortOrder } from 'api/Service';
import { Page } from 'components/layout/Page/Page';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { translate } from 'i18n/translator';
import { isActionAllowed } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { isExist } from 'utils/isData';

import { RolesFilter } from './RolesFilter';

const isVisibleAction = ({ customerId, masterId }: CustomerRole): boolean =>
  isExist(masterId) &&
  isExist(customerId) &&
  isActionAllowed([Privileges.manageUsersManageRoles], customerId);

const columns: TableColumnProps<CustomerRole>[] = [
  {
    label: 'front.roles-page.col-organisation.label',
    render: row => <DataColumn title={row.customerName} />,
    sort: 'customerName',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.roles-page.col-role.label',
    render: row => <DataColumn title={row.nameTranslation} />,
    sort: 'nameMl',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.roles-page.col-type.label',
    render: row => <DataColumn title={row.customerId ? 'Customer' : 'System'} />,
    sort: 'customerId||nulls_first',
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    renderMenu: row => [
      {
        label: 'front.working-documents-table.actions-edit.label',
        onClick: () => goto(pages.role(row.id)),
        isVisible: isVisibleAction(row),
      },
      {
        label: 'front.working-documents-table.actions-delete.label',
        onClick: async () => await api.auth.deleteRole(row.id),
        confirmMessage: 'front.roles-page.delete-action.label',
        isReloadable: true,
        isVisible: isVisibleAction(row),
      },
    ],
  },
];

export const RolesPage = () => (
  <Page title={translate('front.roles-page.title.label')}>
    <DataTable
      name={TableNamesEnum.RolesPage}
      columns={columns}
      fetchRows={api.auth.getAllRoles}
      defaultSort="id"
      defaultOrder={SortOrder.Desc}
      filterComponent={RolesFilter}
      hidePagination
    />
  </Page>
);
