import * as React from 'react';
import { useSelector } from 'react-redux';

import { api } from 'api';
import { Customer, CustomerInfo, CustomerStatus } from 'api/UserService';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import { time } from 'components/utils/format';
import { translate } from 'i18n/translator';
import { checkDebtModal, collectUserState } from 'pages/Login/CustomerSelectModal/utils';
import { OrganizationTimers } from 'pages/Login/CustomerTimers';
import { selectChosenCustomers } from 'store/selectors';

import {
  BooleanString,
  CustomerSelectModalProps,
  CustomerSelectMode,
  ExpandedCustomer,
  ExpandedCustomerInfo,
  Fields,
} from './types';

import 'pages/Login/styles.scss';

const createPrefix = (val: number): string => `customers[id-${val}]`;

const COMMA = ', ';

const parseBooleanIntoStr = (value: boolean) => (value ? BooleanString.true : BooleanString.false);

export const CustomerSelectModal: React.FC<PropsWithConfirmModal<CustomerSelectModalProps>> =
  withForm(({ resolve, mode }) => {
    const [allSelectedCustomers, setAllSelectedCustomers] = React.useState<boolean>(false);

    const {
      payload,
      error,
      handleSubmit,
      getFormData,
      updateData,
      setError,
      setDisabled,
      progress,
    } = useForm<Fields, ExpandedCustomerInfo>(async ({ setPayload, setFields }) => {
      const info = await api.user.getCustomersInfo();

      let allowChosenCustomers = true;
      let chosenCustomers = info.userConfig.userConfigMap.CHOSEN_CUSTOMERS || [];
      let customersNotRole = '';
      const isRememberChosenCustomer =
        info.userConfig.userConfigMap.REMEMBER_CHOSEN_CUSTOMER === BooleanString.true;

      const parsedCustomers = info.customers.map(customer => {
        let error = '';
        let warning = '';
        const startTime = time(customer.validFrom);
        const endTime = time(customer.validTo);
        const isChosenCustomer =
          info.userConfig.userConfigMap.CHOSEN_CUSTOMERS.includes(customer.id) ||
          info.customers.length === 1;

        if (customer.disabled) {
          if (!!customer.validFrom || !!customer.validTo) {
            error = `${translate(
              'front.multi-customer-modal.warning-time-text.label',
            )} ${startTime} - ${endTime}.\n`;
          }

          if (customer.config.IP_RESTRICTION) {
            error += translate('front.multi-customer-modal.warning-ip-text.label');
          }

          if (customer.status === CustomerStatus.BLOCKED) {
            error += `${translate('front.multi-customer-modal.warning-customer-not-active.label')}`;
          }

          if (!customer.config.HAS_ROLE) {
            customersNotRole += `${customer.name}${COMMA}`;
          }

          if (isChosenCustomer) {
            allowChosenCustomers = false;
            chosenCustomers = info.userConfig.userConfigMap.CHOSEN_CUSTOMERS.filter(
              id => customer.id !== id,
            );
          }
        } else if (!customer.disabled && (!!customer.validFrom || !!customer.validTo)) {
          warning = `${translate(
            'front.multi-customer-modal.warning-time-text.label',
          )} ${startTime} - ${endTime}.\n`;
        }

        return {
          error,
          warning,
          ...customer,
        };
      });

      if (customersNotRole) {
        const idx = customersNotRole.lastIndexOf(COMMA);
        const parsedCustomersNotRole = customersNotRole.slice(0, idx);
        const translatedError = translate(
          'front.multi-customer-modal.error-not-role.label',
        ).replace('${customerName}', parsedCustomersNotRole);
        setError(translatedError);
      }

      // DISABLED FORM if User Authorized by signature factor
      if (mode === CustomerSelectMode.readOnly) {
        setDisabled(true);
      }

      if (mode === CustomerSelectMode.auth && allowChosenCustomers && isRememberChosenCustomer) {
        await makeUserStateAndResolve(info.userConfig.userConfigMap.CHOSEN_CUSTOMERS, info);
      } else if (
        mode === CustomerSelectMode.auth &&
        allowChosenCustomers &&
        info.customers.length === 1
      ) {
        await makeUserStateAndResolve(
          info.customers.map(c => c.id),
          info,
        );
      } else {
        setFields({
          isForever: isRememberChosenCustomer,
        });
        setPayload({
          ...info,
          userConfig: {
            ...info.userConfig,
            userConfigMap: { ...info.userConfig.userConfigMap, CHOSEN_CUSTOMERS: chosenCustomers },
          },
          customers: parsedCustomers,
        });
      }
    });

    const makeUserStateAndResolve = async (
      chosenCustomerIds: number[],
      customerInfo: CustomerInfo,
    ) => {
      const userState = collectUserState(customerInfo, chosenCustomerIds);

      mode !== CustomerSelectMode.auth &&
        OrganizationTimers.startTimers(userState.customers, chosenCustomerIds);

      await resolve(userState);

      if (mode === CustomerSelectMode.auth) {
        await checkDebtModal();
      }
    };

    const toCustomerIds = (fields: { [key: string]: boolean }) => {
      return Object.entries(fields)
        .filter(([, value]) => !!value)
        .map(([key]) => +key.substr(3));
    };

    const onSend = async (formData: Fields) => {
      const customerIds = toCustomerIds(formData.customers);
      await api.user.chooseCustomers(customerIds, formData.isForever);
      await api.auth.saveConfig({
        REMEMBER_CHOSEN_CUSTOMER: parseBooleanIntoStr(formData.isForever),
        CHOSEN_CUSTOMERS: customerIds,
      });

      await makeUserStateAndResolve(customerIds, payload);
    };

    const chosenCustomerIdsInSession = useSelector(selectChosenCustomers()).map(p => p.id);

    const isSelected = (
      mode: CustomerSelectMode,
      customer: Customer,
      chosenCustomers: number[],
      chosenCustomersInSession: number[],
    ): boolean => {
      if (mode === CustomerSelectMode.select && !customer.disabled) {
        return chosenCustomersInSession.includes(customer.id);
      }

      if (mode === CustomerSelectMode.auth) {
        return chosenCustomers.includes(customer.id) && !customer.disabled;
      }
    };

    const renderMessage = (customer: ExpandedCustomer) => {
      let message = null;
      if (customer.error) {
        message = <div className="customer-error">{customer.error}</div>;
      } else if (customer.warning) {
        message = <div className="customer-warning">{customer.warning}</div>;
      }

      return message;
    };

    if (!payload) {
      return null;
    }

    const customers = getFormData().customers;

    React.useEffect(() => {
      if (!customers || !payload.customers) return;

      const allowedCustomers = payload.customers.filter(({ disabled }) => !disabled);
      const selectedCustomers = toCustomerIds(customers);
      const isSelectAll = allowedCustomers.length === selectedCustomers.length;

      setAllSelectedCustomers(isSelectAll);
    }, [JSON.stringify(customers)]);

    React.useEffect(() => {
      if (allSelectedCustomers) {
        updateData({ selectAll: true });
      } else {
        updateData({ selectAll: false });
      }
    }, [allSelectedCustomers]);

    const isAllowCustomer = (id: number) => payload.customers.find(c => id === c.id && !c.disabled);

    const onChangeSelectAll = () => {
      const { customers } = getFormData();

      const allowedCustomers = Object.entries(customers)
        .map(([key, value]) => ({ selected: value, id: +key.slice(3) }))
        .filter(c => isAllowCustomer(c.id));
      const isActiveAllCheckbox = allowedCustomers.every(v => v.selected);

      const updatedCustomers: Record<string, boolean> = {};
      if (isActiveAllCheckbox) {
        for (const c in customers) {
          const id = +c.slice(3);
          updatedCustomers[createPrefix(id)] = false;
        }
      } else {
        for (const c in customers) {
          const id = +c.slice(3);
          updatedCustomers[createPrefix(id)] = !!isAllowCustomer(id);
        }
      }
      updateData({ ...updatedCustomers });
    };

    return (
      <DefaultForm>
        <FormError>{error}</FormError>
        <CheckboxField
          label="front.multi-customer-modal.checkbox-select-all.label"
          name="selectAll"
          onChange={onChangeSelectAll}
          isHover
        />
        <hr className="margin-hr" />
        {(payload.customers || []).map(customer => (
          <div key={customer.id}>
            <CheckboxField
              name={createPrefix(customer.id)}
              label={customer.name}
              defaultValue={isSelected(
                mode,
                customer,
                payload.userConfig.userConfigMap.CHOSEN_CUSTOMERS,
                chosenCustomerIdsInSession,
              )}
              disabled={customer.disabled}
              isHover
            />
            {renderMessage(customer)}
          </div>
        ))}
        <hr className="margin-hr" />
        <CheckboxField
          name="isForever"
          label="front.multi-customer-modal.checkbox-remember-choisen.label"
          isHover
        />
        <Button
          color="primary"
          onClick={e => handleSubmit(onSend, e)}
          disabled={!Object.entries(customers || {}).some(([, value]) => !!value) || progress}
        >
          {translate('front.multi-customer-modal.button-continue.label')}
        </Button>
      </DefaultForm>
    );
  });
