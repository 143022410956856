export enum AuthorizedPersonFields {
  CustomerId = 'customerId',
  Login = 'login',
  LastName = 'lastName',
  FirstName = 'firstName',
  MiddleName = 'middleName',
  TaxCode = 'taxCode',
  BirthDate = 'birthDate',
  DocumentSerial = 'documentSerial',
  DocumentNumber = 'documentNumber',
  Zip = 'zip',
  Address = 'address',
  Phone = 'phone',
}

export enum ActionTypes {
  CREATE = 'CREATE',
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}
