import React from 'react';

import { FileType } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { FileField } from 'components/forms/inputs/FileField';
import { PhoneField } from 'components/forms/inputs/PhoneField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextAreaField } from 'components/forms/inputs/TextAreaField';
import { TextField } from 'components/forms/inputs/TextField';
import { useDisabledCustomer } from 'hooks/useDisabledCustomer';
import { useFileExtensions } from 'hooks/useFileExtensions';
import { translate } from 'i18n/translator';
import { getClearValue, MAX_LENGTH_PURPOSE } from 'pages/Demands/CashTransferPage/utils';
import { CurrencyExchangeDetailsName } from 'pages/Demands/CurrencyExchange/enums';
import {
  CurrencyConversionFields,
  CurrencyPayload,
} from 'pages/Demands/CurrencyExchange/interfaces';
import {
  BANK_RATE,
  hasAccount,
  maskDecimalTenConfig,
  MIN_AMOUNT,
  PURPOSE_MIN_LENGTH,
  validateIbanUA,
} from 'pages/Demands/CurrencyExchange/utils';
import { validateIban } from 'pages/Demands/utils';
import { useEffectIfValueChange } from 'utils/hooks';
import { isString } from 'utils/isData';

export const CurrencySaleDetail = () => {
  const {
    order,
    payload: { accountUAH, accountNonUAH, rateType },
    onChangeCustomer,
    form: { getFieldValue, updateData, getFormData },
  } = useOrderDetails<CurrencyConversionFields, CurrencyPayload>();

  const { isoCode, doExternalFeeAcc: isExternalFeeAcc, purpose } = getFormData();
  const fileExtensions = useFileExtensions();
  const isDisabledCustomer = useDisabledCustomer(order);

  useEffectIfValueChange(isoCode, () => updateData({ [CurrencyExchangeDetailsName.Amount]: '' }));

  const onSelectRatyType = React.useCallback(
    (id, { value }) =>
      value === BANK_RATE && updateData({ [CurrencyExchangeDetailsName.CurrencyRate]: null }),
    [rateType],
  );

  const onSelectionAccountNonUAH = React.useCallback(
    id => {
      const options = accountNonUAH.find(({ value }) => value === id);
      updateData({ [CurrencyExchangeDetailsName.IsoCode]: options.content.currency });
    },
    [accountNonUAH],
  );

  const isBankRate =
    rateType &&
    `${getFieldValue(CurrencyExchangeDetailsName.RateType)}` ===
      rateType.find(item => item.content.value === BANK_RATE).value;

  const isAccountOnOptions = hasAccount(
    getFieldValue(CurrencyExchangeDetailsName.AccountUAH),
    accountUAH,
  );

  return (
    <DefaultForm>
      <h5 className="m-t-24">{translate('front.currency-exchange.sell-title-label.label')}</h5>
      <CustomerSelect
        name={CurrencyExchangeDetailsName.Customer}
        selectDefaultCustomer
        label="front.currency-exchange.organization.label"
        onSelectOption={onChangeCustomer}
        disabled={isDisabledCustomer}
        required
      />
      <SelectField
        name={CurrencyExchangeDetailsName.AccountNonUAH}
        label="front.currency-exchange.accountNonUAH.label"
        options={accountNonUAH}
        onSelectOption={onSelectionAccountNonUAH}
        required
      />
      {!isExternalFeeAcc && (
        <SelectField
          name={CurrencyExchangeDetailsName.AccountUAH}
          label="front.currency-exchange.accountUAH.label"
          options={accountUAH}
          validate={!isAccountOnOptions && validateIbanUA}
          clearable
          required
        />
      )}
      <AmountField
        name={CurrencyExchangeDetailsName.Amount}
        label={translate('front.currency-exchange.amount.label')}
        minAmount={MIN_AMOUNT}
        maxLengthWithoutZero={12}
        isoCode={isoCode}
        hasMoneyFormat
        thousandSeparator={' '}
        required
      />
      <TextField
        name={CurrencyExchangeDetailsName.IsoCode}
        label={translate('front.currency-exchange.currency.label')}
        disabled
        required
      />
      <SelectField
        name={CurrencyExchangeDetailsName.RateType}
        label={'front.currency-exchange.rateType.sale.label'}
        options={rateType}
        onSelectOption={onSelectRatyType}
        required
      />
      <AmountField
        label={translate('front.currency-exchange.rate.label')}
        name={CurrencyExchangeDetailsName.CurrencyRate}
        maskConfig={maskDecimalTenConfig}
        disabled={isBankRate}
        required={!isBankRate}
      />
      <TextAreaField
        name={CurrencyExchangeDetailsName.Purpose}
        rows={3}
        label={'front.currency-exchange.purpose.sale.label'}
        minLength={isString(purpose) ? PURPOSE_MIN_LENGTH : null}
        maxLength={MAX_LENGTH_PURPOSE}
        normalize={getClearValue}
        hasCounter
      />
      <FileField
        name={CurrencyExchangeDetailsName.Attach}
        label="front.currency-exchange.document.label"
        outputFormat={FileType.BASE_64}
        accept={fileExtensions}
        multiple
      />
      <CheckboxField
        label="front.currency-exchange.doExternalFeeAcc.label"
        name={CurrencyExchangeDetailsName.DoExternalFeeAcc}
      />
      {isExternalFeeAcc && (
        <>
          <TextField
            label="front.currency-exchange.externalFeeAccName.label"
            name={CurrencyExchangeDetailsName.ExternalFeeAccName}
            required
          />
          <TextField
            label="front.currency-exchange.externalFeeAccCode.label"
            name={CurrencyExchangeDetailsName.ExternalFeeAccCode}
            required
          />
          <TextField
            label="front.currency-exchange.externalFeeAccNumber.label"
            name={CurrencyExchangeDetailsName.ExternalFeeAccNumber}
            validate={validateIban}
            required
          />
        </>
      )}
      <h5 className="m-t-24">{translate('front.currency-exchange.agentText.label')}</h5>
      <TextField
        label="front.currency-exchange.agent.label"
        name={CurrencyExchangeDetailsName.Agent}
      />
      <PhoneField
        label="front.create-authorized-person.phone.label"
        name={CurrencyExchangeDetailsName.Phone}
        required
      />
    </DefaultForm>
  );
};
