import * as React from 'react';

import { Page } from 'components/layout/Page/Page';
import { NavTab, NavTabs } from 'components/tabs/NavTabs';
import { translate } from 'i18n/translator';
import { allowPrivilegy } from 'navigations/access';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';

import { AccountsPage } from './Accounts/AccountsPage';
import { CertificatesPage } from './Certificates/CertificatesPage';
import { ExternalApiPage } from './ExternalApi/ExternalApiPage';
import { InformationPage } from './Information/InformationPage';
import { PersonalOrganizationsPage } from './Information/Organizations/PersonalOrganizationsPage';
import { SettingsPage } from './Settings/SettingsPage';

import './styles.scss';

export const ProfilePage = () => {
  return (
    <Page title={translate('front.profile.title.label')}>
      <NavTabs className="tabs-padding">
        <NavTab title="front.profile.route-information.label" path={pages.profile.tabs.information}>
          <InformationPage />
        </NavTab>
        <NavTab
          title="front.profile.route-organizations.label"
          path={pages.profile.tabs.organizations}
        >
          <PersonalOrganizationsPage />
        </NavTab>
        <NavTab title="front.profile.route-keys.label" path={pages.profile.tabs.certificates}>
          <CertificatesPage />
        </NavTab>
        <NavTab title="front.profile.route-accounts.label" path={pages.profile.tabs.accounts}>
          <AccountsPage />
        </NavTab>
        <NavTab title="front.profile.route-settings.label" path={pages.profile.tabs.settings}>
          <SettingsPage />
        </NavTab>
        {allowPrivilegy([Privileges.externalApiEnabled]) && (
          <NavTab
            title="front.profile.route-external-api.label"
            path={pages.profile.tabs.externalApi}
          >
            <ExternalApiPage />
          </NavTab>
        )}
      </NavTabs>
    </Page>
  );
};
