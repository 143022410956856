import React from 'react';

import { DemandType } from 'api/DemandsService/enums';
import { FileType, Option } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { FileField } from 'components/forms/inputs/FileField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextAreaField } from 'components/forms/inputs/TextAreaField';
import { Col, Row } from 'components/react-grid';
import config from 'config';
import { useDisabledCustomer } from 'hooks/useDisabledCustomer';
import { useFileExtensions } from 'hooks/useFileExtensions';
import { translate } from 'i18n/translator';
import { CashTransferDetailNames } from 'pages/Demands/CashTransferPage/enums';
import {
  CashTransferFields,
  CashTransferPayload,
  CashTransferRepresentativeFields,
} from 'pages/Demands/CashTransferPage/interfaces';
import { useMaxLengthPurpose } from 'pages/Demands/CashTransferPage/useMaxLengthPurpose';
import { isObjectNotEmpty } from 'pages/Demands/utils';
import { useEffectIfValueChange } from 'utils/hooks';
import { isExist } from 'utils/isData';

import {
  checkFieldError,
  checkFieldsFilled,
  DEFAULT_PURPOSE,
  getClearValue,
  getRoundingPenni,
  getTemplate,
  validateBranch,
  validatePurpose,
} from './utils';

export const CashTransferDetails = () => {
  const {
    order,
    payload: { cashSymbolOptions, purposeMapOptions, accounts, branches },
    onChangeCustomer,
    form: { getFieldValue, updateData, setError, getFieldErrors, getFormData },
  } = useOrderDetails<CashTransferFields & CashTransferRepresentativeFields, CashTransferPayload>();

  const fileExtensions = useFileExtensions();
  const isDisabledCustomer = useDisabledCustomer(order);
  const maxLengthPurpose = useMaxLengthPurpose();

  const payerAccountId = getFieldValue(CashTransferDetailNames.PayerAccountId);
  const cashSymbolValue = getFieldValue(CashTransferDetailNames.CashSymbol) as string;
  const amountValue = getFieldValue(CashTransferDetailNames.Amount);
  const secondPurpose = (getFieldValue(CashTransferDetailNames.SecondPurpose) as string) ?? '';
  const purpose = getFieldValue(CashTransferDetailNames.Purpose) as string;

  const payerAccount =
    payerAccountId && accounts.find(account => `${account.value}` === `${payerAccountId}`);
  const currency = payerAccount?.content?.currency;

  useEffectIfValueChange(currency, () => updateData({ amount: '' }));

  const getRoundingPenniOnBlur = React.useCallback(
    () => updateData({ amount: getRoundingPenni(+amountValue, currency) }),
    [amountValue, currency],
  );

  const minAmount = currency !== config.bank.nationalCurrency ? 1 : 0.1;

  const validate = React.useCallback(() => validatePurpose(maxLengthPurpose), [maxLengthPurpose]);

  const branchValidate = React.useCallback(
    branchId => validateBranch(branchId, branches),
    [branches],
  );

  const purposeTemplateRef = React.useRef(new RegExp(''));

  const getSecondPurpose = (value: string) => {
    const isCorrectPurpose = purposeTemplateRef.current.test(value);

    return isCorrectPurpose ? getClearValue(value) : secondPurpose;
  };

  const hasDashes = secondPurpose?.includes('_');

  React.useEffect(() => {
    if (isExist(purpose)) {
      const purposes: Option<string>[] = purposeMapOptions?.get(cashSymbolValue);
      const currentSecondPurpose = purposes.find(option => option.value === purpose)?.content;

      purposeTemplateRef.current = new RegExp(getTemplate(currentSecondPurpose));
    }
  }, [purpose]);

  React.useEffect(() => {
    const formData = getFormData();

    if (isObjectNotEmpty(formData)) {
      const errors = getFieldErrors();
      const isFieldsFilled = checkFieldsFilled(formData);
      const hasFieldError = checkFieldError(errors);

      if (!isFieldsFilled || hasFieldError) {
        setError(translate('front.cash-transfer-page.representative-errors.label'));
      }
    }
  }, []);

  return (
    <DefaultForm>
      <CustomerSelect
        name={CashTransferDetailNames.CustomerId}
        label="front.demands.cash-transfer.customer.field.label"
        onSelectOption={onChangeCustomer}
        disabled={isDisabledCustomer}
        required
      />
      <SelectField
        name={CashTransferDetailNames.Branch}
        label="front.demands.cash-transfer.branch.field.label"
        options={branches}
        validate={branchValidate}
        required
      />
      <SelectField
        name={CashTransferDetailNames.PayerAccountId}
        label={
          order?.type === DemandType.CashWithdrawalOrder
            ? 'front.demands.cash-transfer.withdrawal-account.field.label'
            : 'front.demands.cash-transfer.enrollment-account.field.label'
        }
        options={accounts}
        required
      />
      <h3 className="m-t-24">
        {translate('front.demands.cash-transfer.amount-and-purpose.title.label')}
      </h3>
      <Row>
        <Col sm={6}>
          <AmountField
            name={CashTransferDetailNames.Amount}
            label={`${translate('front.demands.cash-transfer.amount.field.label')} ${
              currency ?? ''
            }`}
            minAmount={minAmount}
            onBlur={getRoundingPenniOnBlur}
            maxLengthWithoutZero={12}
            isoCode={currency}
            disabled={!isExist(payerAccount)}
            hasMoneyFormat
            thousandSeparator={' '}
            required
          />
        </Col>
      </Row>
      <SelectField
        name={CashTransferDetailNames.CashSymbol}
        label="front.demands.cash-transfer.cash-symbol.field.label"
        options={cashSymbolOptions}
        onSelectOption={value => {
          const { value: purpose, content: secondPurpose } =
            purposeMapOptions?.get(value)[DEFAULT_PURPOSE] ?? {};
          updateData({ purpose, secondPurpose });
        }}
        required
      />
      <SelectField
        name={CashTransferDetailNames.Purpose}
        label="front.demands.cash-transfer.purpose.field.label"
        options={purposeMapOptions?.get(cashSymbolValue)}
        onSelectOption={(value, content) => updateData({ secondPurpose: content })}
        required
      />
      <TextAreaField
        name={CashTransferDetailNames.SecondPurpose}
        rows={3}
        label="front.demands.cash-transfer.second-purpose.field.label"
        validate={validate}
        counterNumber={maxLengthPurpose}
        normalize={getSecondPurpose}
        required
      />
      {hasDashes && (
        <p>{translate('front.demands.cash-transfer.additional-details.description.label')}</p>
      )}
      <h3 className="m-t-24">
        {translate('front.demands.cash-transfer.additional-details.title.label')}
      </h3>
      <TextAreaField
        name={CashTransferDetailNames.AdditionalDetails}
        rows={3}
        label="front.demands.cash-transfer.additional-details.title.label"
        validate={validate}
        counterNumber={maxLengthPurpose}
        normalize={getClearValue}
      />
      <FileField
        name={CashTransferDetailNames.Attach}
        label="front.mail-modal.attach-files.label"
        outputFormat={FileType.BASE_64}
        accept={fileExtensions}
        multiple
      />
    </DefaultForm>
  );
};
