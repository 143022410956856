import * as React from 'react';
import { Col, Container, Row } from 'react-grid';
import reactHtmlParser from 'react-html-parser';

import { api } from 'api';
import { Certificate } from 'api/CertificateService/interfaces';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { PasswordField } from 'components/forms/inputs/PasswordField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { translate } from 'i18n/translator';
import { isAvtorCertificate, isAvtorCipherCskCertificate } from 'pages/Profile/Certificates/utils';

interface Fields {
  password: string;
}

interface Props {
  certificate: Certificate;
  resolve: any;
}

const DeleteForm: React.FC<Props> = ({ certificate, resolve }) => {
  const { certificate: Base64, id } = certificate;
  const { error, progress, handleSubmit } = useForm<Fields>();
  const isAvtorCipherCsk = isAvtorCipherCskCertificate(certificate);
  const isAvtorCsk = isAvtorCertificate(certificate);

  const onSave = async (formData: Fields) => {
    const { password } = formData;

    if (isAvtorCsk) {
      await api.certificate.deleteKey(password, Base64);
    }

    await api.certificate.deleteCertificate(id);
    resolve(true);
  };

  return (
    <div>
      <h3>
        {translate(
          isAvtorCipherCsk
            ? 'front.cert-page.delete-modal.title-delete.label'
            : 'front.cert-page.delete-modal.title-execute.label',
        )}
      </h3>
      <DefaultForm disabled={progress}>
        {error && <FormError>{reactHtmlParser(error)}</FormError>}
        <Container>
          {isAvtorCsk && (
            <PasswordField
              label="front.cert-page.delete-modal.input-password.label"
              name="password"
              required
            />
          )}
          <Row>
            <Col sm={6}>
              <Button
                onClick={e => handleSubmit(onSave, e)}
                size="md"
                progress={progress}
                type="submit"
                block
                label={
                  isAvtorCipherCsk
                    ? 'front.cert-page.delete-modal.delete-key-button.label'
                    : 'front.cert-page.delete-modal.execute-key-button.label'
                }
              />
            </Col>
            <Col sm={6}>
              <Button
                onClick={() => resolve(null)}
                color="secondary"
                size="md"
                block
                label="front.cert-page.delete-modal.cancel-button.label"
              />
            </Col>
          </Row>
        </Container>
      </DefaultForm>
    </div>
  );
};

export const DeleteModal = withForm(DeleteForm);
