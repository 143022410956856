import { get, put } from 'api/backend';
import { ClientInterface, OrderResponseV5 } from 'api/DocumentManagementService/interfaces';
import { OrderActionType } from 'api/enums';
import { Instruction } from 'api/HandbooksService';
import { GridResponse, Option } from 'api/Service';
import { Privileges } from 'navigations/privileges';

export class DocumentManagementService {
  getOrdersV5 = async (request: any): Promise<GridResponse<OrderResponseV5>> =>
    await get(`/v5/orders/`, request);

  getCategories = async (): Promise<Instruction[]> =>
    await get(`/v1/instructions/ProtocolCategory`);

  toRejectDocument = async (orderId: number, message: string): Promise<void> =>
    await put(`/v5/orders/${orderId}/state`, message, { action: OrderActionType.REJECT });

  getSignatoryOptions = async (customerIds?: string[]): Promise<Option<ClientInterface>[]> => {
    const { rows }: GridResponse<ClientInterface> = await get('/v1/clients', {
      ['person.customerIds']: customerIds,
      ['person.privilege']: Privileges.protocolSign,
    });

    return rows.map(signatory => ({
      content: signatory,
      label: `${signatory.lastName} ${signatory.firstName}`,
      value: `${signatory.id}`,
    }));
  };

  getOrderV5 = async (id: number | string): Promise<OrderResponseV5> =>
    await get(`/v5/orders/${id}`);
}
