import React from 'react';
import { Col } from 'react-grid';

import { api } from 'api';
import { KeyType, UserKeystoreType } from 'api/CertificateService/enums';
import { FileType } from 'api/Service';
import { Value } from 'components/forms/fileInput/FileInput';
import { FileField } from 'components/forms/inputs/FileField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { confirmModal, PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import { translate } from 'i18n/translator';
import { SearchTokenButton } from 'pages/Profile/Certificates/CertificateEngine/SearchTokenButton';
import { SearchTokenLabel } from 'pages/Profile/Certificates/CertificateEngine/SearchTokenLabel';
import {
  CreateFilteringCustomers,
  FileFieldInterface,
} from 'pages/Profile/Certificates/CertificateEngine/types';
import { CertificateFieldNames } from 'pages/Profile/Certificates/KepCertificate/enums';
import { ExpiredError } from 'pages/Profile/Certificates/KepCertificate/Modals/ExpiredError';
import {
  CreateFetchCerts,
  CreateOnSave,
  FetchOnMount,
} from 'pages/Profile/Certificates/KepCertificate/types';
import { useCertificateDetails } from 'pages/Profile/Certificates/KepCertificate/useCertificateDetails';
import { useSearchToken } from 'pages/Profile/Certificates/KepCertificate/useSearchToken';
import { withKepCertificate } from 'pages/Profile/Certificates/KepCertificate/withKepCertificate';
import {
  createTokenSignOrderRequest,
  getShortOrdersForSign,
  signingTokenFileKey,
  signOrdersInChunks,
} from 'pages/utils/SignOrders/CertificateSign/utils';
import { SignInformation } from 'pages/utils/SignOrders/SignInformation/SignInformation';
import { PropsSign } from 'pages/utils/SignOrders/signOrders';
import { isExist } from 'utils/isData';

type Props = PropsWithConfirmModal<PropsSign>;

const AddCertToken: React.FC = () => {
  const { getFieldValue, updateData, setPayload, payload, setProgress, progress } = useForm();
  const { onSearchToken, isSearchTokens } = useSearchToken();

  const onChangeStore = async (value: string) => {
    const customerId = getFieldValue(CertificateFieldNames.CustomerId);
    setProgress(true);

    const { edsKeyOptions } = await api.certificate.getKepCertificateSignOptions({
      customerIds: [+customerId],
      userKeystoreTypes: [UserKeystoreType.HardWired, UserKeystoreType.CloudStorage],
      allowedMultikey: payload.fetchFilter.allowedMultikey,
      certificateTypes: payload.fetchFilter.certificateTypes,
      storeValue: value,
    });

    const edsKey = edsKeyOptions[0]?.value;

    updateData({ edsKey });
    setPayload({ edsKeyOptions });
    setProgress(false);
  };

  const isDisabledKey = payload?.storeOptions?.length === 0 || progress;

  return (
    <>
      <Col sm={10}>
        <SelectField
          label="front.cert-page-add.input-token.select-token.label"
          onSelectOption={onChangeStore}
          name={CertificateFieldNames.Store}
          options={payload.storeOptions}
          isSearchable={false}
          required
        />
      </Col>
      <SearchTokenButton onSearchToken={onSearchToken} />
      <SearchTokenLabel values={payload.storeOptions} isSearchTokens={isSearchTokens} />
      <ExpiredError />
      <Col sm={12}>
        <SelectField
          label="front.cert-page-add.input-token.select-key-token.label"
          name={CertificateFieldNames.EdsKey}
          options={payload.edsKeyOptions}
          isSearchable={false}
          disabled={isDisabledKey}
          required
        />
      </Col>
    </>
  );
};

const AddCertFile: React.FC = () => {
  const { getFieldValue, setPayload, payload } = useForm();

  const handleChange = async (value: Value[]) => {
    const customerId = getFieldValue(CertificateFieldNames.CustomerId);

    const { edsKeyOptions } = await api.certificate.getKepCertificateSignOptions({
      customerIds: [+customerId],
      userKeystoreTypes: [UserKeystoreType.FileStorage],
      allowedMultikey: payload.fetchFilter.allowedMultikey,
      certificateTypes: payload.fetchFilter.certificateTypes,
      storeValue: value[0].field,
    });

    setPayload({ edsKeyOptions });
  };

  return (
    <>
      <Col sm={12}>
        <div className="title-wrapper">
          <span>{translate('front.cert-page-add.input-token.way-to-key.label')}</span>
        </div>
      </Col>
      <Col sm={12}>
        <FileField
          label="front.cert-page-add.input-token.take-key.label"
          name={CertificateFieldNames.EdsKey}
          outputFormat={FileType.BASE_64}
          onChange={handleChange}
          accept={{}}
          required
        />
      </Col>
    </>
  );
};

const SignCertificateDetails: React.FC = () => {
  const { mode } = useCertificateDetails();

  return mode === KeyType.TOKEN ? <AddCertToken /> : <AddCertFile />;
};

const fetchOnMount: FetchOnMount<PropsSign> = async (
  { customer, props },
  { setFields, updateMode, fetchData },
) => {
  const mode = updateMode(customer.config);

  const filter = {
    customerIds: [customer.id],
    edrpou: customer.taxCode,
    allowedMultikey: props.canUseMultiKey,
    certificateTypes: props.certificateTypes,
  };

  await fetchData(mode, filter);
  setFields({ customerId: `${customer.id}` });
};

const createFetchCerts: CreateFetchCerts =
  ({ updateData, setPayload, setError, createFilter }) =>
  async (mode, filter, isSearchToken) => {
    const createdFilter = createFilter(filter);

    let store;
    let edsKey;
    setError(null);

    if (mode === KeyType.TOKEN) {
      const { edsKeyOptions, storeOptions } = await api.certificate.getKepCertificateSignOptions({
        userKeystoreTypes: [UserKeystoreType.HardWired, UserKeystoreType.CloudStorage],
        isSearchToken,
        ...createdFilter,
      });

      store = storeOptions[0]?.value;
      edsKey = store ? edsKeyOptions[0]?.value : null;

      setPayload({ storeOptions, edsKeyOptions, fetchFilter: createdFilter });
    }

    updateData({ store, edsKey, password: null });
  };

const createOnSave: CreateOnSave<Props> =
  (validatePassword, { payload, customers, props }) =>
  async formData => {
    const { edsKeyOptions } = payload;
    const { edsKey, customerId: customerIdStr, password, mode } = formData;

    const isTokenMode = mode === KeyType.TOKEN;
    const validEdsKey = isTokenMode ? edsKey : (edsKey[0] as FileFieldInterface).field;

    const currentCertificate = edsKeyOptions.find(({ value }) => value === validEdsKey)?.content;

    const isCloudCertificate =
      currentCertificate?.userKeystoreType === UserKeystoreType.CloudStorage;

    validatePassword();

    const orders = getShortOrdersForSign({ props, currentCertificate, customerIdStr, customers });

    let signOrdersRequest;

    if (isCloudCertificate) {
      signOrdersRequest = orders.map(({ orderId }) =>
        createTokenSignOrderRequest(currentCertificate, orderId, password),
      );
    }

    if (!isCloudCertificate) {
      signOrdersRequest = await signingTokenFileKey({
        payload,
        orders,
        formData,
        currentCertificate,
      });
    }

    const signingOrders = await signOrdersInChunks(signOrdersRequest);

    const hasError = isExist(signingOrders.find(item => item.hasError));

    if (props.ordersCount > 1 || hasError) {
      const errors = signingOrders.filter(item => item.hasError);
      const successCount = signingOrders.length - errors.length;

      await confirmModal<string>(
        SignInformation,
        { errors, successCount, ordersCount: props.ordersCount },
        650,
      );
    }

    props.resolve(true);
  };

const createFilteringCustomers: CreateFilteringCustomers = customerIds => customerOption =>
  customerIds.includes(+customerOption.value);

export const KepCertificateSign = withKepCertificate<Props>({
  fetchOnMount,
  createOnSave,
  createFetchCerts,
  createFilteringCustomers,
  allowCancel: true,
  allowedMultikey: true,
  labelButtonOnSave: 'front.certificates.sign-button.label',
  title: 'front.certificates.modal-kep-title.label',
  filteringParams: {
    existingInSystem: true,
  },
})(SignCertificateDetails);
