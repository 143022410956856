export enum EmployeeManagementNames {
  Customer = 'customer',
  SalaryDeal = 'salaryDeal',
  DismissalDate = 'dismissalDate',
  EmployeeList = 'employeeList',
  Attach = 'attach',
}

export enum EmployeeNewNames {
  Customer = 'customer',
  SalaryDeal = 'salaryDeal',
  RecruitDate = 'recruitDate',
  CardType = 'cardType',
  Branch = 'branch',
  BranchAddress = 'branch_adr',
  LastName = 'lastName',
  FirstName = 'firstName',
  MiddleName = 'middleName',
  Inn = 'inn',
  NameEn = 'nameEN',
  Gender = 'gender',
  BirthDay = 'birthDay',
  BirthCountry = 'birthСountry',
  Resident = 'resident',
  SecretWord = 'secretWord',
  Phone = 'phone',
  DocumentType = 'documentType',
  PassportSerial = 'passportSerial',
  PassportIssueDate = 'passportIssueDate',
  PassportIssuer = 'passportIssuer',
  PassportTerm = 'passportTerm',
  RegCountry = 'regCountry',
  RegRegion = 'regRegion',
  RegDistrict = 'regDistrict',
  RegPostCode = 'regPostCode',
  RegCity = 'regCity',
  RegStreet = 'regStreet',
  RegHouse = 'regHouse',
  RegFlat = 'regFlat',
  LiveEqualsReg = 'liveEqualsReg',
  LiveCountry = 'liveCountry',
  LiveRegion = 'liveRegion',
  LiveDistrict = 'liveDistrict',
  LivePostCode = 'livePostCode',
  LiveCity = 'liveCity',
  LiveStreet = 'liveStreet',
  LiveHouse = 'liveHouse',
  LiveFlat = 'liveFlat',
  JobTitle = 'jobTitle',
  JobUnit = 'jobUnit',
  JobPhone = 'jobPhone',
  Attach = 'attach',
}

export enum EmployeeReissueNames {
  Customer = 'customer',
  SalaryDeal = 'salaryDeal',
  ReissueReason = 'reissueReason',
  EmployeeList = 'employeeList',
  Attach = 'attach',
}
