import * as React from 'react';
import { Col, Row } from 'react-grid';

import { api } from 'api';
import { DemandType } from 'api/DemandsService/enums';
import { DictionaryKeyCode, OrderState } from 'api/enums';
import { Option } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { DateRangeField } from 'components/forms/inputs/DateRangeField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { withForm } from 'components/forms/withForm';
import { SearchInput } from 'components/searchInput/SearchInput';
import { CreateButton } from 'components/Table/CreateButton';
import { useFilter } from 'components/Table/DataTable';
import { ToggleFilterButton } from 'components/Table/ToggleFilterButton/ToggleFilterButton';
import { FilterTabBar } from 'components/tabs/FilterTabBar';
import { translate } from 'i18n/translator';
import { deniedPrivilegy } from 'navigations/access';
import { Privileges } from 'navigations/privileges';
import { NEW } from 'navigations/routes';
import { demandsStates, getSubmitPage, isAuthLetter } from 'pages/Demands/utils';

interface Props {
  orderType: string;
}

enum LetterTypes {
  Ved = 'topic:ved',
  Cred = 'topic:cred',
  Depo = 'topic:depo',
  Other = 'topic:other',
}

export interface PartialDemandsFilterFields {
  dynamicTextField: LetterTypes;
}

const statusOptions: Option<undefined, string | string[]>[] = [
  {
    value: OrderState.Draft,
    label: 'front.order-state.draft.label',
  },
  {
    value: OrderState.Sent,
    label: 'front.order-state.sent.label',
  },
  {
    value: OrderState.PartiallySigned,
    label: 'front.order-state.partiallysigned.label',
  },
  {
    value: [OrderState.InWork, OrderState.InProcess],
    label: 'front.order-state.inwork.label',
  },
  {
    value: OrderState.Rejected,
    label: 'front.order-state.rejected.label',
  },
  {
    value: OrderState.Executed,
    label: 'front.order-state.executed.label',
  },
];

const authLetterStates: Option[] = [
  {
    value: '',
    label: 'front.acquiring.demands.statuses.all.label',
  },
  {
    value: 'Signed,Draft,PartiallySigned',
    label: 'front.acquiring.demands.statuses.sign.label',
  },
  {
    value: 'InWork',
    label: 'front.acquiring.demands.statuses.working.label',
  },
  {
    value: 'Rejected',
    label: 'front.acquiring.demands.statuses.rejected.label',
  },
  {
    value: 'Executed',
    label: 'front.acquiring.demands.statuses.done.label',
  },
];

const getPrivilegeByOrderType = (orderType: DemandType): Privileges =>
  (
    ({
      [DemandType.FEAMessage]: Privileges.feamessageEdit,
      [DemandType.AuthLetter]: Privileges.contactSignedLetterEdit,
    }) as Record<DemandType, Privileges>
  )[orderType] ?? Privileges.demandEdit;

const PartialDemandsFilterForm: React.FC<Props> = ({ orderType }) => {
  const onMount = () => fetchData();

  const { updateFilter, extraFields, setPayload, payload, setPagination } = useFilter({
    extraFields: { searchText: '', orderStates: '' },
    fetchInitial: onMount,
  });

  const fetchData = async () => {
    const letterTypeSelect = await api.handbooks.getDictionaryOptions(
      DictionaryKeyCode.AuthLetterTopic,
    );

    const letterTypeSelectOptions = letterTypeSelect.map(item => ({
      value: `topic:${item.value}`,
      label: item.label,
    }));

    setPayload({
      letterTypeSelectOptions,
    });
  };

  const states = React.useMemo(
    () => (isAuthLetter(orderType as DemandType) ? authLetterStates : demandsStates),
    [],
  );

  return (
    <div>
      <FilterTabBar
        onChange={orderStates => updateFilter({ orderStates })}
        value={extraFields.orderStates}
        options={states}
        setPagination={setPagination}
      />
      <Row>
        <Col lg={6}>
          <CreateButton
            onClick={() => getSubmitPage({ id: NEW, type: orderType as DemandType })}
            title="front.notifications.create.label"
            disabled={deniedPrivilegy([getPrivilegeByOrderType(orderType as DemandType)])}
          />
        </Col>
        <Col lg={6}>
          <Row>
            <Col lg={7} md={8}>
              <SearchInput
                placeholder={translate('front.working-documents-table.filter-search.label')}
                value={extraFields.searchText}
                onChange={searchText => updateFilter({ searchText })}
              />
            </Col>
            <Col lg={5} md={4}>
              <ToggleFilterButton>
                <Row>
                  <Col md={6}>
                    <DateRangeField
                      label="front.menu.work-protocol-filter.date-range.label"
                      nameFrom="dateFrom"
                      nameTo="dateTo"
                      clearable
                    />
                  </Col>
                  <Col md={6}>
                    <CustomerSelect
                      label="front.internal-payment-page.order-payer-name.label"
                      name="customerIds"
                      multi
                    />
                  </Col>
                </Row>
                {isAuthLetter(orderType as DemandType) && (
                  <Row>
                    <Col md={6}>
                      <SelectField
                        label="front.demands-table.type-letter.label"
                        name="dynamicFieldValue"
                        options={payload.letterTypeSelectOptions}
                      />
                    </Col>
                    <Col md={6}>
                      <SelectField
                        name="orderStates"
                        label="front.employees-filter.by-status.label"
                        options={statusOptions}
                      />
                    </Col>
                  </Row>
                )}
              </ToggleFilterButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export const PartialDemandsFilter = withForm(PartialDemandsFilterForm);
