import moment from 'moment';

import { del, downloadRequisites, get, post, put } from './backend';
import { Option } from './Service';

export enum RegistrationSteps {
  InitRegistration = 'InitRegistration',
  SMS = 'SMS',
  DoShowClientInfo = 'DoShowClientInfo',
  DoShowClientAddInfo = 'DoShowClientAddInfo',
  DoShowFinProfile = 'DoShowFinProfile',
  DoCreateClient = 'DoCreateClient',
  DoShowRegistrationResult = 'DoShowRegistrationResult',
  Stopped = 'Stopped',
  Rejected = 'Rejected',
  Error = 'Error',
  DoShowPassportScan = 'DoShowPassportScan',
  DoShowCourierRequest = 'DoShowCourierRequest',
  DoShowActivationStatus = 'DoShowActivationStatus',
  Executed = 'Executed',
}

export enum DocumentType {
  Passport = '4',
  IdPassport = '24',
  PermanentResidency = '25',
  IdPermanentResidency = '28',
}

export enum Income {
  LESS_THEN_ONE_MILLION = 'LESS_THEN_ONE_MILLION',
  ONE_TO_FOUR_MILLIONS = 'ONE_TO_FOUR_MILLIONS',
  MORE_THEN_FOUR_MILLIONS = 'MORE_THEN_FOUR_MILLIONS',
}

export enum RealEstate {
  FLAT = 'FLAT',
  HOUSE = 'HOUSE',
  PLOT = 'PLOT',
  VEHICLE = 'VEHICLE',
  NON_RESIDENTIAL_FACILITY = 'NON_RESIDENTIAL_FACILITY',
  NO_PROPERTY = 'NO_PROPERTY',
}

export enum SourcesOfIncome {
  AVERAGE_MONTHLY_INCOME = 'AVERAGE_MONTHLY_INCOME',
  FINANCE_HELP = 'FINANCE_HELP',
  SALE_SECURITIES = 'SALE_SECURITIES',
  CHANGE_OF_CREDITOR = 'CHANGE_OF_CREDITOR',
  CREDIT = 'CREDIT',
  DERIVATIV = 'DERIVATIV',
}

export enum Expenses {
  LESS_THEN_ONE_MILLION = 'LESS_THEN_ONE_MILLION',
  ONE_TO_FOUR_MILLIONS = 'ONE_TO_FOUR_MILLIONS',
  MORE_THEN_FOUR_MILLIONS = 'MORE_THEN_FOUR_MILLIONS',
}

export enum Planning {
  REGULAR_GET_MONEY = 'REGULAR_GET_MONEY',
  REGULAR_GET_FINANCIAL_HELP = 'REGULAR_GET_FINANCIAL_HELP',
}

export interface InitRegistration {
  documentNumber: number;
  documentSeries: string;
  documentType: DocumentType;
  isAllowPersonalData: boolean;
  phone: string;
  taxCode: number;
}

export interface InitRegistrationResponse {
  orderId: number;
  uuid: string;
}

export interface SmsRegistrationResponse {
  smsLength: number;
  ttl: number;
}

export interface RegistrationResponse {
  ABSAccountIban?: string;
  isSent?: boolean;
  message?: string;
  orderId?: number;
  orderState?: RegistrationSteps;
  uuid?: string;
}

export interface ClientInfo {
  addressLegal: string;
  countryName: string;
  firstName: string;
  kvedList: Kved[];
  lastName: string;
  middleName: string;
  orderId: number;
  orderState: string;
  organisationName: string;
  phone: string;
  postalZip: string;
  taxCode: string;
  uuid: string;
}

export interface Kved {
  code: string;
  is_primary: boolean;
  name: string;
}

export interface ClientAddInfo {
  addressFact: string;
  email: string;
  login: string;
}

export interface AdditionalQuestion {
  expiringDate: string;
  issuedBy: string;
  name: string;
  number: string;
  series: string;
}

export interface FinProfile {
  additionalQuestions: AdditionalQuestion[];
  anotherAccounts: boolean;
  expenses: Expenses;
  hasRealEstate: RealEstate;
  kveds: any;
  licenses: boolean;
  planning: Planning[];
  publicPerson: boolean;
  sourcesOfIncome: SourcesOfIncome;
  totalIncome: Income;
}

export interface ScanStatus {
  documentType: string;
  pages: {
    [name: string]: string;
  };
}

export interface CourierRegistration {
  courierAddress: string;
  courierCheckByPhone: boolean;
  courierCoordinate: string;
  courierDate: string;
  courierTime: string;
  message: string;
  orderId: number;
  orderState: RegistrationSteps;
  uuid: string;
}

export const DocumentTypeOptions: Option<undefined, DocumentType>[] = [
  { value: DocumentType.Passport, label: 'Паспорт громадянина України' },
  { value: DocumentType.IdPassport, label: 'Паспорт (id-карта)' },
  {
    value: DocumentType.PermanentResidency,
    label: 'Посвідка на тимчасове проживання',
  },
  {
    value: DocumentType.IdPermanentResidency,
    label: 'Посвідка на тимчасове проживання (Id-карта)',
  },
];

export const realEstateOptions: Option<undefined>[] = [
  { value: RealEstate.FLAT, label: 'Квартира' },
  { value: RealEstate.HOUSE, label: 'Будинок' },
  { value: RealEstate.PLOT, label: 'Земельна ділянка' },
  { value: RealEstate.VEHICLE, label: 'Транспортні засоби' },
  { value: RealEstate.NON_RESIDENTIAL_FACILITY, label: 'Нежитловий фонд' },
  { value: RealEstate.NO_PROPERTY, label: 'Власності немає' },
];

export const incomeOptions: Option<undefined>[] = [
  { value: Income.LESS_THEN_ONE_MILLION, label: 'до 1 млн грн' },
  { value: Income.ONE_TO_FOUR_MILLIONS, label: 'від 1 млн до 4млн грн' },
  { value: Income.MORE_THEN_FOUR_MILLIONS, label: 'більше 4 млн грн' },
];

export const sourcesOfIncomeOptions: Option<undefined>[] = [
  {
    value: SourcesOfIncome.AVERAGE_MONTHLY_INCOME,
    label: 'сукупний середньомісячний дохід',
  },
  {
    value: SourcesOfIncome.FINANCE_HELP,
    label: 'у вигляді фінансової допомоги',
  },
  {
    value: SourcesOfIncome.SALE_SECURITIES,
    label: 'від продажу цінних паперів',
  },
  {
    value: SourcesOfIncome.CHANGE_OF_CREDITOR,
    label: 'від продажу або відступлення права',
  },
  { value: SourcesOfIncome.CREDIT, label: 'у вигляді позики' },
  {
    value: SourcesOfIncome.DERIVATIV,
    label:
      'від укладання строкових контрактів або використання інших похідних фінансових інструментів та деривативів.',
  },
];

export const expensesOptions: Option<undefined>[] = [
  { value: Expenses.LESS_THEN_ONE_MILLION, label: 'до 1 млн грн' },
  { value: Expenses.ONE_TO_FOUR_MILLIONS, label: 'від 1 млн до 4млн грн' },
  { value: Expenses.MORE_THEN_FOUR_MILLIONS, label: 'більше 4 млн грн' },
];

export const planningOptions: Option<undefined>[] = [
  {
    value: Planning.REGULAR_GET_MONEY,
    label: 'Регулярно (більше 3 разів на місяць) отримувати з рахунку коштів у готівковій формі',
  },
  {
    value: Planning.REGULAR_GET_FINANCIAL_HELP,
    label: 'Регулярно надавати/одержувати фінансову допомогу',
  },
];

export const registrationPages: Record<string, string> = {
  TaxCodeSheet: 'Довідка про присвоєння ідентифікаційного коду',
  CitizenPassportFirstSpread: 'Перший розворот паспорту',
  CitizenPassportSecondThirdSpread: 'Другий розворот паспорту',
  CitizenPassportFourthFifthSpread: 'Третій розворот паспорту',
  CitizenPassportSixthSeventhSpread: 'Четвертий розворот паспорту',
  CitizenPassportRegistrationAddressSpread: 'Сторінка з останнім місцем реєстрації',
  IdCardFrontSide: 'Лицьова сторона картки',
  IdCardBackside: 'Зворотня сторона картки',
  ResidencePermitTemporaryIdCardFrontSide: 'Лицьова сторона картки',
  ResidencePermitTemporaryFirstSpread: 'Перший розворот',
  ResidencePermitTemporaryThirdFourthSpread: 'Другий розворот',
  IdCardExtract: 'Витяг або довідка з реєстрацією',
};

export class RegistrationService {
  async startRegistration(formData: InitRegistration): Promise<InitRegistrationResponse> {
    return await post('/v1/registration/start', {
      ...formData,
      documentType: Number(formData.documentType),
      documentSeries: formData.documentSeries || '',
    });
  }

  async smsRequest(orderId: number, uuid: string): Promise<SmsRegistrationResponse> {
    return await post('/v1/registration/sms', { orderId, uuid });
  }

  async confirmSms(orderId: number, uuid: string, smsCode: string): Promise<boolean> {
    return await put('/v1/registration/sms', { orderId, uuid, smsCode });
  }

  async signRegistration(orderId: number, uuid: string): Promise<RegistrationResponse> {
    return await put('/v1/registration/actions', {
      orderId,
      uuid,
      action: 'SIGN',
    });
  }

  async getClientInfo(orderId: number, uuid: string): Promise<ClientInfo> {
    return await get(`/v1/registration/client-info/${orderId}/${uuid}`);
  }

  async confirmClientInfo(orderId: number, uuid: string): Promise<RegistrationResponse> {
    const fields = { isAllowPersonalData: true };
    return post('/v1/registration/client-info', { orderId, uuid, fields });
  }

  async getClientAddInfo(orderId: number, uuid: string): Promise<{ email: string }> {
    return await get(`/v1/registration/login-select/${orderId}/${uuid}`);
  }

  async confirmClientAddInfo(
    orderId: number,
    uuid: string,
    request: ClientAddInfo,
  ): Promise<RegistrationResponse> {
    return post('/v1/registration/login-select', { orderId, uuid, ...request });
  }

  async confirmFinProfile(
    orderId: number,
    uuid: string,
    request: FinProfile,
  ): Promise<RegistrationResponse> {
    return post('/v1/registration/fin-profile', { orderId, uuid, ...request });
  }

  async createClient(orderId: number, uuid: string): Promise<RegistrationResponse> {
    const action = 'CREATE_ABS_CLIENT_FLP';
    return await put('/v1/registration/actions', { orderId, uuid, action });
  }

  async getRegistrationResult(orderId: number, uuid: string): Promise<RegistrationResponse> {
    return get(`/v1/registration/registration-result/${orderId}/${uuid}`);
  }

  async isSmsSent(orderId: number, uuid: string): Promise<RegistrationResponse> {
    return await get(`/v1/registration/registration-result/${orderId}/${uuid}`);
  }

  async resendSms(orderId: number, uuid: string): Promise<RegistrationResponse> {
    return post('/v1/registration/ibs-user-sms', { orderId, uuid });
  }

  async getScanStatus(orderId: string | number, uuid: string): Promise<ScanStatus> {
    return await get(`/v1/registration/attachment/status/${orderId}/${uuid}`);
  }

  async addScan(
    orderId: string | number,
    attachment: Blob,
    attachmentPurpose: string,
  ): Promise<any> {
    const formData: FormData = new FormData();
    formData.append('attachment', attachment);
    formData.append('attachmentPurpose', attachmentPurpose);
    return post(`/v1/registration/attachment/${orderId}`, formData);
  }

  async getRegistrationOrder(): Promise<RegistrationResponse> {
    return get('/v1/auth/registration');
  }

  async startActivation(orderId: number): Promise<RegistrationResponse> {
    return await post(`/v1/registration/registration-result/${orderId}`, {});
  }

  async getFile(orderId: number, uuid: string, attachmentPurpose: string): Promise<void> {
    await downloadRequisites(`/v1/registration/attachment/${orderId}/${uuid}`, {
      attachmentPurpose,
    });
  }

  async removeScan(orderId: string | number, attachmentPurpose: string): Promise<void> {
    const formData: FormData = new FormData();
    formData.append('attachmentPurpose', attachmentPurpose);
    await del(`/v1/registration/attachment/${orderId}`, formData);
  }

  async confirmScan(orderId: number): Promise<RegistrationResponse> {
    return await post(`/v1/registration/attachment/confirm/${orderId}`, {});
  }

  async getCourierInfo(orderId: number): Promise<CourierRegistration> {
    return get(`/v1/registration/courier/${orderId}`);
  }

  async confirmCourierInfo(formData: CourierRegistration): Promise<RegistrationResponse> {
    const { uuid, message, orderState, ...fields } = formData;
    const { courierCoordinate, courierCheckByPhone, courierDate } = fields;
    return post('/v1/registration/courier', {
      ...fields,
      courierCoordinate: courierCoordinate || '',
      courierCheckByPhone: Boolean(courierCheckByPhone),
      courierDate: moment(courierDate).format('YYYY-MM-DD'),
    });
  }

  async createLink(orderId: number): Promise<void> {
    return post(`/v1/registration/make-unique-url/${orderId}`, {});
  }
}
