import { isExist } from 'utils/isData';

export function isEmptyObject(obj = {}) {
  return Object.getOwnPropertyNames(obj).length === 0;
}

export function cls(...args: any[]) {
  const classes: string[] = [];

  args.forEach(arg => {
    if (typeof arg === 'string') {
      classes.push(arg);
    } else if (typeof arg === 'object' && arg !== null) {
      const cc = Object.keys(arg).filter(key => key !== 'null' && arg[key]);

      classes.push(...cc);
    }
  });

  return classes.join(' ');
}

export function capitalize(s: string): string {
  if (!isExist(s) || !s.length) return '';
  return s[0].toUpperCase() + s.slice(1).toLowerCase();
}
