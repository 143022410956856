export enum DomesticTransferDetailNames {
  PayerName = 'payerName',
  PayerAccount = 'payerAccount',
  ReceiverName = 'receiverName',
  ReceiverAccount = 'receiverAccount',
  ReceiverBic = 'receiverBic',
  ReceiverTaxCode = 'receiverTaxCode',
  ReceiverCountryId = 'receiverCountryId',
  ReceiverAccountId = 'receiverAccountId',
  SubAccount = 'subAccount',
  ReceiverAddCode = 'receiverAddCode',
  Amount = 'amount',
  VatType = 'vatType',
  VatPercent = 'vatPercent',
  BudgetCode = 'budgetCode',
  PayerTaxCode = 'payerTaxCode',
  ExplanatoryInfo = 'explanatoryInfo',
  IsCustomBudgetPurpose = 'isCustomBudgetPurpose',
  Purpose = 'purpose',
  IsActualPayer = 'isActualPayer',
  PayerNameF = 'payerNameF',
  PayerTaxCodeF = 'payerTaxCodeF',
  IsActualRecipient = 'isActualRecipient',
  ReceiverNameF = 'receiverNameF',
  ReceiverTaxCodeF = 'receiverTaxCodeF',
  BudgetClassificationCode = 'budgetClassificationCode',
  AdditionalRecordInfo = 'additionalRecordInfo',
  TaxIban = 'taxIban',
  TaxAmount = 'taxAmount',
  TaxCurrency = 'taxCurrency',
  TaxNoticeInfo = 'taxNoticeInfo',
  TaxNoticeType = 'taxNoticeType',
  AdditionalPaymentInfo = 'additionalPaymentInfo',
}

export enum InternationalTransferDetailNames {
  PayerName = 'payerName',
  PayerAccount = 'payerAccount',
  ReceiverAccount = 'receiverAccount',
  Amount = 'amount',
  SubAccount = 'subAccount',
  Purpose = 'purpose',
  SavePaymentPurpose = 'savePaymentPurpose',
}

export enum InternalPaymentFilterFormNames {
  CustomerIds = 'customerIds',
  PayerIds = 'payerIds',
  OrderNumber = 'orderNumber',
  ReceiverTaxCode = 'receiverTaxCode',
  Types = 'types',
  ReceiverName = 'receiverName',
  ReceiverAccount = 'receiverAccount',
  ReceiverBic = 'receiverBic',
  AmountFrom = 'amountFrom',
  AmountTo = 'amountTo',
  DateFrom = 'dateFrom',
  DateTo = 'dateTo',
}

export enum InternalSourceFieldsEnum {
  PURPOSES = 'PURPOSES',
  ALLOWED_CURRENCY_FOR_INTERNAL_TRANSFER = 'ALLOWED_CURRENCY_FOR_INTERNAL_TRANSFER',
  ACCOUNTS = 'ACCOUNTS',
  ACCOUNT_TRANSFER_PAYMENT_PURPOSE = 'ACCOUNT_TRANSFER_PAYMENT_PURPOSE',
  CUSTOMER = 'CUSTOMER',
  REPORTS_FOR_PURPOSE_DEBT = 'REPORTS_FOR_PURPOSE_DEBT',
}

export enum DomesticSourceFieldsEnum {
  NATIONAL_ACCOUNTS = 'NATIONAL_ACCOUNTS',
  COUNTERPARTIES = 'COUNTERPARTIES',
  LOCAL_BANKS = 'LOCAL_BANKS',
  KBK = 'KBK',
  PURPOSES = 'PURPOSES',
  COUNTRIES = 'COUNTRIES',
  CUSTOMER = 'CUSTOMER',
  ASP_PROVIDERS = 'ASP_PROVIDERS',
}
