import * as React from 'react';
import { Col, Row } from 'react-grid';

import moment from 'moment';

import { AccountType } from 'api/enums';
import { AccountView } from 'api/ProductsService';
import * as format from 'components/utils/format';
import { translate } from 'i18n/translator';

const getDetails = (row: AccountView) => [
  {
    render: () => (
      <DataColumn
        label={translate('front.product-accounts-togglerow.iban.label')}
        value={row.iban}
      />
    ),
  },
  {
    render: () => (
      <DataColumn
        label={translate('front.product-accounts-togglerow.currency.label')}
        value={row.currency}
      />
    ),
  },
  {
    render: () => (
      <DataColumn
        label={translate('front.product-accounts-togglerow.account-name.label')}
        value={row.productName}
      />
    ),
  },
  {
    render: () => (
      <DataColumn
        label={translate('front.product-accounts-togglerow.customer-name.label')}
        value={row.customerName}
      />
    ),
  },
  {
    render: () => (
      <DataColumn
        label={translate('front.product-accounts-togglerow.status-translation.label')}
        value={row.statusTranslation}
      />
    ),
  },
  {
    render: () => (
      <DataColumn
        label={translate('front.product-accounts-togglerow.type-translation.label')}
        value={row.typeTranslation}
      />
    ),
  },
  {
    render: () => (
      <DataColumn
        label={translate('front.product-accounts-togglerow.planned-balance.label')}
        value={format.getAmount(row.plannedBalance)}
      />
    ),
  },
  {
    render: () => (
      <DataColumn
        label={translate('front.product-accounts-togglerow.balance.label')}
        value={format.getAmount(row.balance)}
      />
    ),
  },
  ...(row.type === AccountType.CARD
    ? [
        {
          render: () => (
            <DataColumn
              label={translate('front.product-accounts-togglerow.hold-balance.label')}
              value={format.getAmount(row.blockedAmount)}
            />
          ),
        },
      ]
    : []),
  {
    render: () => (
      <DataColumn
        label={translate('front.product-accounts-togglerow.opened-date.label')}
        value={moment(row.openedDate).format('DD.MM.YYYY')}
      />
    ),
  },
  {
    render: () => (
      <DataColumn
        label={translate('front.product-accounts-togglerow.balance-by-documents.label')}
        value={format.getAmount(row.balanceByDocuments)}
      />
    ),
  },
  {
    render: () => (
      <DataColumn
        label={translate('front.product-accounts-togglerow.overdraft-limit.label')}
        value={format.getAmount(row.overdraftLimit)}
      />
    ),
  },
  {
    render: () => (
      <DataColumn
        label={translate('front.product-accounts-togglerow.bank-branch.label')}
        value={row.bankBranchShortName}
      />
    ),
  },
];

export const AccountDetails = ({ row }: { row: AccountView }) => (
  <Row>
    {getDetails(row).map((item, index) => (
      <React.Fragment key={index}>{item.render()}</React.Fragment>
    ))}
  </Row>
);

const DataColumn = ({ label = '', value = '' }) => {
  return (
    <Col lg={6}>
      <div className="expanded-row-cell">
        <Row>
          <Col md={6}>
            <span className="expanded-row-cell__label">{label}</span>
          </Col>
          <Col md={6}>
            <span className="expanded-row-cell__vall">{value}</span>
          </Col>
        </Row>
      </div>
    </Col>
  );
};
