import { ConstantEnum } from 'api/enums';
import store from 'store';

import { get, post } from './backend';

export enum ETypes {
  boolean = 'boolean',
  string = 'string',
  number = 'number',
}

export type StringBoolean = 'false' | 'true';

export type ConstantList = {
  [ConstantEnum.AllowFileKey]: StringBoolean;
  [ConstantEnum.AllowTokenKey]: StringBoolean;
  [ConstantEnum.IsMultiCustomer]: StringBoolean;
  [ConstantEnum.FileSize]: string;
  [ConstantEnum.FileType]: string;
  [ConstantEnum.AllowedDaysForReissue]: string;
  [ConstantEnum.SmsValidPeriod]: string;
  [ConstantEnum.CompanyPersonDaysToExpire]: string;
  [ConstantEnum.AllowedPasswordSymbols]: string;
  [ConstantEnum.MinPasswordLength]: string;
  [ConstantEnum.MaxPasswordLength]: string;
  [ConstantEnum.MobileOperatorsCodes]: string;
  [ConstantEnum.MinLoginLength]: string;
  [ConstantEnum.MaxLoginLength]: string;
  [ConstantEnum.AllowedLoginSymbols]: string;
  [ConstantEnum.AllowedDaysForCashOrderWithdraw]: string;
  [ConstantEnum.AllowedDaysForCashOrderTransfer]: string;
  [ConstantEnum.AllowedDaysForCashOrderDemands]: string;
  [ConstantEnum.CipherTokenCaId]: string;
  [ConstantEnum.BankCodesForBudgetPayments]: string;
};

interface ConstantName {
  value: string;
}

export const constantsList = [
  ConstantEnum.AllowFileKey,
  ConstantEnum.AllowTokenKey,
  ConstantEnum.IsMultiCustomer,
  ConstantEnum.FileSize,
  ConstantEnum.FileType,
  ConstantEnum.AllowedDaysForReissue,
  ConstantEnum.SmsValidPeriod,
  ConstantEnum.AllowedPasswordSymbols,
  ConstantEnum.MinPasswordLength,
  ConstantEnum.MaxPasswordLength,
  ConstantEnum.MobileOperatorsCodes,
  ConstantEnum.MinLoginLength,
  ConstantEnum.MaxLoginLength,
  ConstantEnum.AllowedLoginSymbols,
  ConstantEnum.CompanyPersonDaysToExpire,
  ConstantEnum.AllowedDaysForCashOrderWithdraw,
  ConstantEnum.AllowedDaysForCashOrderTransfer,
  ConstantEnum.AllowedDaysForCashOrderDemands,
  ConstantEnum.CipherTokenCaId,
  ConstantEnum.BankCodesForBudgetPayments,
];

export class ConstantService {
  async getConstantsList(constants = constantsList): Promise<ConstantList> {
    return await post('/constants/list', { codes: constants });
  }

  async getConstantByName(name: ConstantEnum): Promise<ConstantName> {
    return await get(`/constants/name/${name}`);
  }
}

export const checkMultiCustomer = (request: Obj): Obj => {
  if (store.getState().constantState.IS_MULTI_CUSTOMER === 'true') {
    return { ...request, forAll: true };
  }
  return request;
};
