import * as React from 'react';
import { Col, Container, Row } from 'react-grid';
import reactHtmlParser from 'react-html-parser';

import { api } from 'api';
import { Certificate } from 'api/CertificateService/interfaces';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { PasswordField } from 'components/forms/inputs/PasswordField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { validateMessages } from 'components/validateMessages';
import { translate } from 'i18n/translator';
import {
  validateMatchingPassword,
  validatePasswordWithException,
} from 'pages/Profile/Certificates/PasswordValidator';

interface Fields {
  confirmPassword: string;
  newPassword: string;
  password: string;
}

interface Props {
  certificate: Certificate;
  resolve: (value: boolean) => void;
}

const ChangePasswordForm: React.FC<Props> = ({ certificate, resolve }) => {
  const { progress, error, handleSubmit, getFieldValue } = useForm<Fields>();

  const onSave = async (formData: Fields): Promise<void> => {
    const { password, newPassword } = formData;

    validatePasswordWithException(password, '', 'front.form.current-pin-code.error');
    validatePasswordWithException(newPassword, '', 'front.form.new-pin-code.error');
    validateMatchingPassword(password, newPassword, true);

    await api.certificate.changeCloudCertificatePassword(password, newPassword, certificate.id);
    return resolve(true);
  };

  const newPassword = getFieldValue('newPassword');

  const validatePasswords = React.useCallback(
    (value: string) => {
      if (value !== newPassword) {
        return validateMessages.passwordsEqualKep;
      }
      return '';
    },
    [newPassword],
  );

  return (
    <div>
      <div className="title-wrap">
        <h3>{translate('front.cert-page.change-password-modal-kep.title.label')}</h3>
        <Tooltip
          className={'popover-position'}
          tooltip={translate('front.cert-page.change-password-modal-tooltip.label')}
        />
      </div>
      <DefaultForm disabled={progress}>
        {error && <FormError>{reactHtmlParser(error)}</FormError>}
        <Container>
          <PasswordField
            label="front.cert-page.current-password-modal-kep.title.label"
            name="password"
            minLength={6}
            maxLength={8}
            required
          />
          <PasswordField
            label="front.cert-page.new-password-modal-kep.title.label"
            name="newPassword"
            minLength={6}
            maxLength={8}
            required
          />
          <PasswordField
            label="front.cert-page.new-change-password-modal-kep.title.label"
            name="confirmPassword"
            validate={validatePasswords}
            minLength={6}
            maxLength={8}
            required
          />
          <Row>
            <Col sm={6}>
              <Button
                onClick={e => handleSubmit(onSave, e)}
                size="md"
                progress={progress}
                type="submit"
                block
              >
                {translate('front.cert-page.change-password-modal.save-button.label')}
              </Button>
            </Col>
            <Col sm={6}>
              <Button onClick={() => resolve(null)} color="secondary" size="md" block>
                {translate('front.cert-page.change-password-modal.cancel-button.label')}
              </Button>
            </Col>
          </Row>
        </Container>
      </DefaultForm>
    </div>
  );
};

export const ChangePasswordKepModal = withForm(ChangePasswordForm);
