import React from 'react';

import moment from 'moment';

import { api } from 'api';
import { OrderType } from 'api/DemandsService/enums';
import { DemandResponse, PartialDemandRequest } from 'api/DemandsService/interfaces';
import { OrderActionType, OrderKind, OrderState, VatType } from 'api/enums';
import { Order, OrderAction } from 'api/OrderService';
import { useOrder } from 'components/Document/useOrder';
import { getOrderDate } from 'components/Document/utils';
import { FetchOrderCb, OnSaveCreator, withOrder } from 'components/Document/withOrder';
import { NavTab, NavTabs } from 'components/tabs/NavTabs';
import * as format from 'components/utils/format';
import { getAmountNumberFormatWithComma, toAmountFormatWithDot } from 'components/utils/format';
import config from 'config';
import { translate } from 'i18n/translator';
import { isPaymentActionAllowed } from 'navigations/access';
import { goBack, goto } from 'navigations/navigate';
import { pages, PAYMENT_REQUEST_TYPE } from 'navigations/pages';
import { NEW } from 'navigations/routes';
import { getAccountContent } from 'pages/Demands/CurrencyExchange/utils';
import { getTranslateAction } from 'pages/Payments/general';
import { PaymentHistoryPage } from 'pages/Payments/History/PaymentHistoryPage';
import {
  createPurpose,
  getAccountValue,
  getCorrectVatPercent,
} from 'pages/Payments/InternalPayment/utils';
import { ExposedOrderDetails } from 'pages/Payments/PaymentRequest/ExposedOrderDetails';
import { useAccessToExposedType } from 'pages/Payments/PaymentRequest/hooks/useAccessToExposedType';
import {
  PaymentRequestDetailsFields,
  PaymentRequestOrderFields,
  PaymentRequestPayload,
} from 'pages/Payments/PaymentRequest/interfaces';
import { InvoiceOrderDetails } from 'pages/Payments/PaymentRequest/InvoiceOrderDetails';
import { toReceiverAccountOptions } from 'pages/Payments/PaymentRequest/utils';
import { Accounts } from 'pages/Profile/Accounts/AccountsPage';

import { ExposedOrderDetailsNames, PaymentRequestsSourceFieldsEnum } from './enums';

const toDetailsFields = (doc: DemandResponse, isDraft: boolean): PaymentRequestDetailsFields => {
  const {
    base: { orderType, extraData },
    financial: {
      amount,
      purpose,
      receiverTaxCode,
      receiverBic,
      receiverName,
      payerAccountId,
      vatType,
      isoCode,
      receiverIban,
      payerName,
      payerTaxCode,
      payerBic,
      payerIban,
      vatPercent,
      receiverAddCode,
      receiverCountryId,
    },
  } = doc;

  let correctVatPercent = vatPercent;
  let correctPurpose = purpose;

  if (orderType === OrderType.ExposedOrder && vatType === VatType.INCLUDE_VAT20) {
    correctVatPercent = getCorrectVatPercent(vatPercent);

    if (isDraft) {
      correctPurpose = createPurpose({
        vatPercent: getAmountNumberFormatWithComma(+vatPercent),
        prevVatPercent: vatPercent,
        purpose,
        amount,
        vatType,
      });
    }
  }

  return {
    amount,
    purpose: correctPurpose,
    isoCode,
    payerName,
    payerTaxCode,
    payerBic,
    receiverName,
    receiverTaxCode,
    receiverBic,
    receiverIban,
    [ExposedOrderDetailsNames.ExposedType]: extraData ? extraData.exposedType : null,
    payerAccount: orderType === OrderType.ExposedOrder ? payerAccountId : payerIban,
    vatType,
    vatPercent: correctVatPercent,
    receiverAddCode,
    receiverCountryId,
  };
};

const fetchOrder: FetchOrderCb<
  Partial<PaymentRequestDetailsFields>,
  PaymentRequestPayload
> = async (
  { customerId: defaultCustomerId, routeParams, queryParams },
  { setPayload },
): Promise<Order<Partial<PaymentRequestDetailsFields>>> => {
  let doc: DemandResponse;
  if (routeParams.id === NEW) {
    let customerId = defaultCustomerId;

    if (queryParams.copyFrom) {
      doc = await api.order.getNewOrderModel<DemandResponse>(queryParams.copyFrom);
      customerId = doc.base.customerId;
    }

    customerId = doc?.base.customerId || customerId;

    const sourceFields = await api.payments.getExposedTransferSourceFields(
      Object.values(PaymentRequestsSourceFieldsEnum),
      true,
      customerId,
    );

    const { transferDefaultAccount } = await api.user.getDefaultAccounts({
      customerId: +defaultCustomerId,
      code: [Accounts.ACC_TRANSFER_DEFAULT_ACCOUNT, Accounts.PAYMENT_ORDER_DEFAULT_ACCOUNT],
    });

    const {
      formFields: { orderNumber, orderDate, ...formFields },
      receiverOptions,
      payerAccountOptions,
      localBanksOptions,
      countriesOptions,
    } = sourceFields;

    setPayload({
      countriesOptions,
      payerAccountOptions,
      receiverOptions,
      localBanksOptions,
      payerOptions: null,
      receiverAccountOptions: [],
    });

    if (doc) {
      return {
        customerId,
        id: null,
        state: null,
        number: orderNumber,
        label: translate('front.working-documents-order.exposed-order.label'),
        type: doc.base.orderType,
        stateTranslate: doc.base.orderState,
        date: moment(orderDate).toDate(),
        detailFields: {
          ...formFields,
          ...toDetailsFields(doc, true),
        },
        actions: actions.filter(
          action =>
            doc.actions.some(({ actionName, confirmation }) => {
              if (confirmation) {
                action.payload = { confirmation };
              }
              return actionName === action.name;
            }) && isPaymentActionAllowed(doc?.base?.orderType, action?.name, doc?.base?.customerId),
        ),
      };
    }

    return {
      customerId,
      id: null,
      state: null,
      stateTranslate: null,
      number: orderNumber,
      date: moment(orderDate).toDate(),
      label: translate('front.working-documents-order.create-exposed-order.label'),
      type: OrderType.ExposedOrder,
      detailFields: {
        ...formFields,
        payerAccount: getAccountValue(transferDefaultAccount, payerAccountOptions),
        isoCode: getAccountContent(transferDefaultAccount, payerAccountOptions)?.currency,
      },
      actions: [],
    };
  }

  doc = await api.order.getNewOrderModel<DemandResponse>(routeParams.id);

  const isDraft = doc.base.orderState === OrderState.Draft;
  const fields = toDetailsFields(doc, isDraft);

  if (isDraft && doc.base.orderType === OrderType.ExposedOrder) {
    const sourceFields = await api.payments.getExposedTransferSourceFields(
      Object.values(PaymentRequestsSourceFieldsEnum),
      false,
      doc.base.customerId,
    );

    const {
      receiverOptions,
      payerOptions,
      payerAccountOptions,
      localBanksOptions,
      countriesOptions,
    } = sourceFields;

    const selectedReceiver = receiverOptions.find(({ value }) => value === fields.payerName);
    const receiverAccountOptions = toReceiverAccountOptions(selectedReceiver?.content?.accounts);

    setPayload({
      countriesOptions,
      receiverAccountOptions,
      receiverOptions,
      payerOptions,
      payerAccountOptions,
      localBanksOptions,
    });

    return {
      detailFields: fields,
      label: translate('front.working-documents-order.exposed-order.label'),
      type: doc.base.orderType,
      date: getOrderDate(doc.base),
      id: doc.id,
      number: doc.base.orderNumber,
      customerId: doc.base.customerId,
      state: doc.base.orderState,
      stateTranslate: doc.base.orderState,
      actions: actions.filter(
        action =>
          doc.actions.some(({ actionName, confirmation }) => {
            if (confirmation) {
              action.payload = { confirmation };
            }
            return actionName === action.name;
          }) && isPaymentActionAllowed(doc?.base?.orderType, action?.name, doc?.base?.customerId),
      ),
    };
  }

  const { payerAccountOptions, localBanksOptions, countriesOptions } =
    await api.payments.getExposedTransferSourceFields(
      Object.values(PaymentRequestsSourceFieldsEnum),
      false,
      doc.base.customerId,
    );

  setPayload({
    payerAccountOptions,
    receiverAccountOptions: [],
    receiverOptions: [],
    payerOptions: [],
    localBanksOptions,
    countriesOptions,
  });

  return {
    label: translate('front.working-documents-order.exposed-order.label'),
    type: doc.base.orderType,
    date: format.parseDate(doc.base.orderDate),
    detailFields: fields,
    actions: actions.filter(
      action =>
        doc.actions.some(({ actionName, confirmation }) => {
          if (confirmation) {
            action.payload = { confirmation };
          }
          return actionName === action.name;
        }) && isPaymentActionAllowed(doc?.base?.orderType, action?.name, doc?.base?.customerId),
    ),
    customerId: doc.base.customerId,
    id: doc.id,
    number: doc.base.orderNumber,
    state: doc.base.orderState,
    stateTranslate: doc.base.orderState,
  };
};

const actions: OrderAction<Partial<PaymentRequestDetailsFields>>[] = [
  {
    name: OrderActionType.REJECT,
    label: getTranslateAction(OrderActionType.REJECT),
    type: 'dropdown',
    onClick: async order => {
      await api.payments.executeAction(order.id, OrderActionType.REJECT);
    },
    reloadable: true,
    closable: true,
  },
  {
    name: OrderActionType.EXECUTE,
    label: getTranslateAction(OrderActionType.EXECUTE),
    type: 'dropdown',
    onClick: async order => {
      await api.payments.executeAction(order.id, OrderActionType.EXECUTE);
    },
    reloadable: true,
    closable: true,
  },
  {
    name: OrderActionType.REVOKE,
    type: 'dropdown',
    label: getTranslateAction(OrderActionType.REVOKE),
    onClick: async order => {
      await api.payments.withdrawOrders([order.id]);
    },
    reloadable: true,
    confirmMessage: 'front.working-documents-table.actions-revoke-confirmation.label',
  },
  {
    name: OrderActionType.DELETE,
    type: 'dropdown',
    label: getTranslateAction(OrderActionType.DELETE),
    onClick: async order => {
      await api.payments.deleteOrder(order.id);
      goto(pages.paymentRequests(PAYMENT_REQUEST_TYPE.EXPOSED));
    },
    confirmMessage: 'front.working-documents-table.actions-delete-confirmation.label',
  },
  {
    name: OrderActionType.SIGN,
    type: 'signButton',
    label: getTranslateAction(OrderActionType.SIGN),
  },
  {
    name: OrderActionType.COPY,
    type: 'dropdown',
    label: getTranslateAction(OrderActionType.COPY),
    onClick: order => {
      goto(pages.paymentRequest.tabs.details('new', { copyFrom: order.id }));
    },
  },
  {
    name: OrderActionType.PRINT,
    type: 'dropdown',
    label: getTranslateAction(OrderActionType.PRINT),
    onClick: order => api.payments.getOrderPdf(order.id),
  },
];

type FormData = PaymentRequestDetailsFields & PaymentRequestOrderFields;

const createOnSave: OnSaveCreator<FormData, PaymentRequestPayload> =
  (order: Order) => async (formData, options) => {
    const {
      savePaymentPurpose,
      orderNumber,
      orderDate,
      valueDate,
      vatType,
      vatPercent,
      payerAccount: payerAccountId,
      exposedType,
      ...orderData
    } = formData;

    const purpose = createPurpose({
      vatType,
      amount: orderData.amount as number,
      purpose: orderData.purpose,
      vatPercent: vatPercent,
      prevVatPercent: vatPercent,
    });

    const orderRequest: PartialDemandRequest = {
      base: {
        orderNumber,
        orderDate: moment(orderDate).format('YYYY-MM-DD'),
        orderKind: options?.templateName ? OrderKind.TEMPLATE : undefined,
        orderAlias: options?.templateName,
        orderType: order.type as OrderType,
        customerId: order.customerId,
        extraData: {
          [ExposedOrderDetailsNames.ExposedType]: exposedType,
        },
      },
      financial: {
        ...orderData,
        purpose,
        payerBic: config.page.paymentRequest.defaultBic,
        payerAccountId: +payerAccountId,
        vatType: vatType as VatType,
        currencyRate: 1,
        vatPercent: toAmountFormatWithDot(vatPercent),
      },
    };

    if (savePaymentPurpose) {
      await api.payments.createPaymentPurpose({ purpose: orderData.purpose });
    }

    if (!order.id) {
      return await api.payments.createPayment(orderRequest);
    }

    await api.payments.updatePayment(order.id, orderRequest);
    return order.id;
  };

export const PaymentRequestPage = withOrder<
  PaymentRequestDetailsFields,
  PaymentRequestPayload
  // @ts-ignore
>(({ order }) => {
  const { isAllowSaveByConfidant } = useAccessToExposedType({
    order,
    exposedType: order?.detailFields.exposedType,
  });

  return {
    createOnSave,
    fetchOrder,
    allowDetails: true,
    allowSign:
      order &&
      isPaymentActionAllowed(order.type as OrderType, OrderActionType.SIGN, order.customerId),
    allowState: true,
    allowSave:
      order &&
      order.type === OrderType.ExposedOrder &&
      isPaymentActionAllowed(order.type as OrderType, OrderActionType.EDIT, order.customerId) &&
      isAllowSaveByConfidant,
    afterSubmit: () => goBack(),
    disableButtons: true,
  };
})(() => {
  const { order } = useOrder<PaymentRequestDetailsFields>();
  return (
    <NavTabs>
      <NavTab
        title={translate('front.demand-detail.document-detail.label')}
        path={pages.paymentRequest.tabs.details(':id')}
      >
        {order?.type === OrderType.ExposedOrder ? <ExposedOrderDetails /> : <InvoiceOrderDetails />}
      </NavTab>
      {order && order.state && (
        <NavTab
          title={translate('front.demand-detail.document-history.label')}
          path={pages.paymentRequest.tabs.history(':id')}
        >
          <PaymentHistoryPage />
        </NavTab>
      )}
    </NavTabs>
  );
});
