export enum ConfirmationIdentificationFormFields {
  OrganizationName = 'ORGANIZATION_NAME',
  Edrpou = 'EDRPOU',
  Drfou = 'DRFOU',
  Passport = 'PASSPORT',
  Title = 'TITLE',
  CompanyAddress = 'companyAddress',
  RegistrationAddress = 'registrationAddress',
  CommonName = 'COMMON_NAME',
  CorrectDate = 'correctDate',
  Confirm = 'confirm',
  Unzr = 'UNZR',
}

export enum CorrectType {
  YES = 'yes',
  NO = 'no',
}

export enum ProfileIdKeys {
  ClientLegalPersonSeal = '4',
  ClientLegalPerson = '5',
  FopOrSelfEmployedPerson = '7',
  FopOrSelfEmployedPersonWithoutInnClient = '10',
}
