import * as React from 'react';

import { normalizeStringValue } from 'components/forms/ValidatingForm/utils';

import { FieldData, Validate } from '../FormContext';
import { useFormInternal } from '../useFormInternal';
import { ArrayFieldContext } from './ArrayField';

export interface Field extends FieldData {
  onBlur: () => void;
  onChange: (value: any) => void;
  pristine: boolean;
}

interface FieldInterface {
  field: Field;
  form: {
    disabled: boolean;
    initializing: boolean;
    progress: boolean;
  };
}

interface Props {
  name: string;
  defaultValue?: any;
  required?: boolean;
  shouldNormalize?: boolean;
  validate?: Validate;
  warn?: Validate;
}

export const useField = ({
  name,
  defaultValue,
  validate,
  warn,
  shouldNormalize = true,
}: Props): Partial<FieldInterface> => {
  const {
    updateInput,
    fieldsData,
    registerField,
    unregisterField,
    validateField,
    disabled,
    progress,
    updateField,
    initializing,
    updateFieldInData,
    fetchOnBlur,
    updateFetchOnBlur,
  } = useFormInternal();

  const { name: arrayName } = React.useContext(ArrayFieldContext);

  React.useEffect(() => {
    // TODO: updating validate after changing constraints
    registerField(name, defaultValue, { validate, warn });

    return () => unregisterField(name, !!arrayName, location.pathname);
  }, []);

  React.useEffect(() => {
    updateField(name, { validate });
  }, [validate]);

  React.useEffect(() => {
    updateField(name, { warn });
  }, [warn]);

  const fieldData = fieldsData[name];

  if (!fieldData) {
    return {};
  }

  const field = {
    ...fieldData,
    pristine: fieldData.value === fieldData.defaultValue,
    onChange: (value: any) => updateInput(name, value),
    onBlur: () => {
      let normalizedValue = fieldData.value;
      if (shouldNormalize && typeof fieldData.value === 'string') {
        normalizedValue = normalizeStringValue(fieldData.value);
        updateFieldInData(name, 'value', normalizedValue);
      }
      const [error, warning] = validateField(name, normalizedValue);

      error && updateFieldInData(name, 'error', error);
      warning && updateFieldInData(name, 'warning', warning);

      !error && fetchOnBlur.func && updateFetchOnBlur({ needToCall: true });
    },
  };

  const form = {
    disabled,
    progress,
    initializing,
  };

  return { field, form };
};
