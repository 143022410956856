import * as React from 'react';

import './page-header.scss';

interface Interface {
  children?: React.ReactNode | undefined;
  style?: React.StyleHTMLAttributes<any>;
}

export const PageHeader = ({ children, style = {} }: Interface) => (
  <div className="page-header" style={style}>
    {children}
  </div>
);

const Title: React.FC = ({ children }) => <div className="page-header__title">{children}</div>;

const Subtitle: React.FC = ({ children }) => (
  <div className="page-header__subtitle">{children}</div>
);

const Right: React.FC = ({ children }) => <div className="page-header__right">{children}</div>;

PageHeader.Right = Right;
PageHeader.Title = Title;
PageHeader.Subtitle = Subtitle;
