import moment from 'moment';

import { DN } from 'api/AuthService';
import { del, downloadFile, get, post, put, renderQueryString } from 'api/backend';
import * as CipherService from 'api/CertificateService/CipherModule/CipherService';
import { ConnectedTokenOptions } from 'api/CertificateService/CipherModule/interfaces';
import {
  CertificateStatus,
  CertificateType,
  KeyType,
  UserKeystoreType,
} from 'api/CertificateService/enums';
import {
  AddCertificateResponse,
  Certificate,
  CloudKepCertificateRequest,
  FilterKeystore,
  GetCertificateParams,
  GetKepCertificateParams,
  KeyStore,
  ReissueResponse,
  Token,
  TokenKepCertificateRequest,
} from 'api/CertificateService/interfaces';
import {
  CertificateUmka,
  ParsedCertificate,
  SigType,
} from 'api/CertificateService/UmcaModule/interfaces';
import * as UmcaService from 'api/CertificateService/UmcaModule/UmcaService';
import { getEdsKeyOptions, getStoreOptions } from 'api/CertificateService/utils';
import { ConstantEnum } from 'api/enums';
import { BooleanBuilder, getPage, GridRequest, GridResponse, Option } from 'api/Service';
import { StatusColor } from 'components/layout/Status';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { translate } from 'i18n/translator';
import { NoConnectionModal } from 'pages/Profile/Certificates/Modals/NoConnectionModal';

interface CertificatesRequest extends GridRequest {
  certificateTypes?: string[];
  customerIds?: string[];
  keyType?: string;
  status?: string;
  statuses?: string;
}

interface CertificatesOptions {
  edsKeyOptions: Option<Certificate>[];
  storeOptions: Option<Certificate>[];
}

export const getStatusColor = (status: CertificateStatus): StatusColor => {
  switch (status) {
    case CertificateStatus.Active:
      return 'green';
    case CertificateStatus.NotValid:
    case CertificateStatus.Rejected:
      return 'red';
    default:
      return 'grey';
  }
};

export const mapKeyTypesToConstants: Record<
  KeyType,
  Extract<ConstantEnum, ConstantEnum.AllowTokenKey | ConstantEnum.AllowFileKey>
> = {
  [KeyType.FILE]: ConstantEnum.AllowFileKey,
  [KeyType.TOKEN]: ConstantEnum.AllowTokenKey,
};

export const keyTypes: Option<undefined, KeyType>[] = [
  {
    value: KeyType.TOKEN,
    label: translate('front.cert-page-new.options-key-types.token.label'),
  },
  {
    value: KeyType.FILE,
    label: translate('front.cert-page-new.options-key-types.file.label'),
  },
];

export const isAvtorActiveCertificate = (certificate: Certificate): boolean =>
  !certificate.isCertificateCaReissueAllowed &&
  certificate.isCertificateCaRevokeAllowed &&
  certificate.status === CertificateStatus.Active;

const isOtherActiveCertificate = (certificate: Certificate): boolean =>
  !isAvtorActiveCertificate(certificate) && certificate.status === CertificateStatus.Active;

const parseCertificate = (cert: CertificateUmka, store: Token): ParsedCertificate => ({
  hw: store.Hw,
  Description: store.Description,
  label: store.Label,
  Serial: store.Serial,
  certBase64: cert.Base64,
  fio: cert.Subject['CN'],
  edrpou: cert.DirectoryAttributes.edrpou || cert.DirectoryAttributes.drfo,
  organization: cert.Subject['O'] || '',
  notAfter: cert.NotAfter,
  notBefore: cert.NotBefore,
  certificateSerialNumber: cert.CertificateSerialNumber,
  certificateCSKSerialNumber: cert.Issuer.SERIALNUMBER,
  certificateData: cert,
  isMultiKey: false,
});

const parsedCertificates = (certificates: Token[]): ParsedCertificate[] =>
  certificates.reduce((acc, store) => {
    if (!store?.Certs) return acc;

    const certs = store.Certs.filter(cert => cert.KeyUsage === 's').map(c =>
      parseCertificate(c, store),
    );
    acc.push(...certs);

    return acc;
  }, []);

class FilterCertificate {
  connectedToSystem: boolean;
  edrpou: string;
  certs: ParsedCertificate[];
  systemCertificates: Certificate[];
  systemCertificatesBase64: string[];

  constructor(
    certs: ParsedCertificate[],
    systemCertificates: Certificate[],
    connectedToSystem = true,
    edrpou?: string,
  ) {
    this.certs = certs;
    this.connectedToSystem = connectedToSystem;
    this.edrpou = edrpou;
    this.systemCertificates = systemCertificates.filter(cert =>
      connectedToSystem ? isAvtorActiveCertificate(cert) : true,
    );
    this.systemCertificatesBase64 = this.systemCertificates.map(cert => cert.certificate);
  }

  isEdrpouMatches = (cert: ParsedCertificate) => cert.edrpou === this.edrpou;

  isActualDate = (cert: ParsedCertificate) => moment().isBetween(cert.notBefore, cert.notAfter);

  isConnectedToSystem = (cert: ParsedCertificate) =>
    this.connectedToSystem === this.systemCertificatesBase64.includes(cert.certBase64);

  filterCerts = () => {
    this.certs = this.certs.filter(cert => {
      const b = new BooleanBuilder(true);

      b.and(this.isActualDate(cert));
      b.and(this.isConnectedToSystem(cert));
      this.edrpou && b.and(this.isEdrpouMatches(cert));
      return b.apply();
    });
  };

  getMultikey = () => {
    return this.systemCertificates.find(cert => cert.isUserCert);
  };

  markMultikey = () => {
    const multiKey = this.getMultikey();
    if (multiKey) {
      this.certs = this.certs.map(cert =>
        cert.certificateSerialNumber === multiKey.certificateSN
          ? { ...cert, isMultiKey: true }
          : cert,
      );
    }
  };

  getCertificates = () => {
    this.filterCerts();
    this.markMultikey();
    return this.certs;
  };
}

export class CertificateService {
  async getTokens(keyType: KeyType): Promise<Option<Token>[]> {
    const stores = await UmcaService.getKeyStores(keyType);

    return stores.map(item => ({
      label: `${item.Description} ${item.Label ? `(${item.Label})` : ''}`,
      value: keyType === KeyType.TOKEN ? item.Serial : item.Description,
      content: item,
    }));
  }

  getUserCertificates = async (
    request: CertificatesRequest,
  ): Promise<
    GridResponse<
      Certificate & {
        user: DN;
      }
    >
  > => {
    const { customerIds, status, keyType } = request;
    const certificates = await this.getCertificates(customerIds, true, request);

    const filter = (certificate: Certificate) => {
      const b = new BooleanBuilder(true);
      status && b.and(status === certificate.status);
      keyType &&
        b.and(keyType === certificate.userKeystoreType || keyType === certificate.certificateType);
      return b.apply();
    };

    const hasMultiCert = certificates.find(
      c => c.isUserCert && CertificateStatus.Active === c.status,
    );

    const parsedCertificates = certificates.map(c => ({
      ...c,
      user: JSON.parse(c.dn),
      allowUserCert: hasMultiCert ? false : c.allowUserCert,
    }));

    return getPage(parsedCertificates, { ...request, size: certificates.length }, filter);
  };

  async addNewCertificate(data: TokenKepCertificateRequest, customerId: number): Promise<boolean> {
    return await post('/v1/certificates', data, { customerId });
  }

  async revokeCertificate(id: number, smsCode?: string): Promise<any> {
    return await del(`/v1/ugb/certificates/${id}/revoke`, smsCode);
  }

  async createTokenCertificate(
    data: TokenKepCertificateRequest,
    params: {
      customerId: number;
      dataHash: string;
      certificateType?: CertificateType;
    },
  ): Promise<AddCertificateResponse> {
    return await post('/v1/ugb/certificates/generate_new_key', data, params);
  }

  async confirmCertificate(id: number, certificate?: string): Promise<any> {
    return await put(`/v1/ugb/certificates/${id}/attach`, certificate);
  }

  async isUmcaAvailable(): Promise<boolean> {
    return UmcaService.isUmcaAvailable();
  }

  async deleteKey(pin: string, certificate: string) {
    return await UmcaService.deleteKey(pin, certificate);
  }

  async sign(
    data: string,
    certificate: string,
    password: string,
    type: SigType,
    cms?: string,
    alg?: string,
  ) {
    return await UmcaService.sign(data, certificate, password, type, alg, cms);
  }

  async getCertifcatesUmka(filter: FilterKeystore) {
    return parsedCertificates(await UmcaService.getCertificatesExtended(filter));
  }

  async getCertificates(
    customerIds?: Array<string | number>,
    withUserCert?: boolean,
    request?: CertificatesRequest,
  ): Promise<Certificate[]> {
    return get('/v1/certificates', { ...request, size: 0, customerIds, withUserCert });
  }

  async multiSign(
    certificate: string,
    password: string,
    multipleData: string[],
    multipleCms: string[] = undefined,
    type: SigType = 'cms-attached',
  ) {
    return await UmcaService.multiSign(multipleData, certificate, password, multipleCms, type);
  }

  async validateKeyStorePassword(password: string, keyStore: Partial<KeyStore>) {
    return await UmcaService.checkPin(password, keyStore);
  }

  async getKepCertificateSignOptions({
    customerIds,
    allowedMultikey,
    certificateTypes,
    userKeystoreTypes,
    isSearchToken = false,
    storeValue = null,
  }: GetKepCertificateParams): Promise<CertificatesOptions> {
    const certificates = await this.getCertificates(customerIds, allowedMultikey, {
      certificateTypes,
    });

    const filteredCertificates = certificates.filter(cert =>
      userKeystoreTypes.some(
        type => type === cert.userKeystoreType && isOtherActiveCertificate(cert),
      ),
    );

    const tokenCertificates = filteredCertificates.filter(
      cert => cert.userKeystoreType === UserKeystoreType.HardWired,
    );

    const hasOnlyCipherTokenCertificate = tokenCertificates.length === filteredCertificates.length;

    let edsKeyOptions: Option<Certificate>[] = [];
    let storeOptions: Option<Certificate>[] = [];
    let tokenOption: ConnectedTokenOptions[] = [];

    if (isSearchToken || hasOnlyCipherTokenCertificate) {
      const isAvailable = await CipherService.isCipherAvailable();

      if (!isAvailable) {
        await confirmModal(NoConnectionModal);
        return { edsKeyOptions, storeOptions };
      }

      tokenOption = await CipherService.getConnectedTokenOptions();
    }

    storeOptions = getStoreOptions(filteredCertificates, tokenOption);
    const defaultStoreValue = storeValue ?? storeOptions[0]?.value;

    edsKeyOptions = getEdsKeyOptions(filteredCertificates, defaultStoreValue);

    return { edsKeyOptions, storeOptions };
  }

  async getCertificatesOptions({
    mode,
    customerIds,
    edrpou,
    fetchFilter = {},
    existingInSystem = true,
    allowedMultikey = false,
  }: GetCertificateParams): Promise<Option<ParsedCertificate>[]> {
    const certificates = await this.getCertificates(customerIds, allowedMultikey);

    const filter = {
      ReadCertificates: true,
      EnfoldFileKeyStores: false,
      Hw: mode === KeyType.TOKEN,
      ...fetchFilter,
    };

    const umkaCertificates = await this.getCertifcatesUmka(filter);

    const filteredCertificates = new FilterCertificate(
      umkaCertificates,
      certificates,
      existingInSystem,
      edrpou,
    ).getCertificates();

    return filteredCertificates.map(item => ({
      label: `${item.certificateSerialNumber} - ${item.fio} ${
        item.organization ? `- ${item.organization}` : ''
      }`,
      value: mode === KeyType.TOKEN ? item.certBase64 : item.Description,
      content: item,
    }));
  }

  async updateCertificate(password: string, certificate: string) {
    return await UmcaService.updateCertificate(password, certificate);
  }

  async reissueCloudCertificate(
    id: number,
    oldPin: string,
    newPin: string,
    dataHash: string,
    certificateType: CertificateType,
  ): Promise<ReissueResponse> {
    return await post(`/v1/ugb/certificates/reissue`, {
      certificateId: id,
      oldPin,
      newPin,
      dataHash,
      certificateType,
    });
  }

  async reissueCertificateApi(
    id: number,
    certificate: string,
    dnChanged: boolean,
  ): Promise<ReissueResponse> {
    let url = `/v1/ugb/certificates/${id}/reissue`;

    if (dnChanged) {
      url = `${url}${renderQueryString({ dnChanged })}`;
    }

    return await post(url, certificate);
  }

  async getCertificate(certificate: string) {
    return await UmcaService.getCertificate(certificate);
  }

  async changePin(password: string, newPassword: string, certificate: Token) {
    const keyStoreInfo = {
      Hw: certificate.Hw,
      Description: certificate.Description,
      Label: certificate.Label,
      Serial: certificate.Serial,
    };

    return await UmcaService.changePin(password, newPassword, keyStoreInfo);
  }

  async deleteCertificate(id: number): Promise<boolean> {
    return await del(`/v1/certificates/${id}/delete`);
  }

  async getCertificatePdf(id: number): Promise<string> {
    return await downloadFile(`/v1/certificates/get-pdf/${id}`);
  }

  async activateMultiCertificate(id: number, isUserCert: boolean): Promise<void> {
    return await put(`/v1/certificates/${id}/user-cert`, isUserCert);
  }

  async createCloudCertificate(data: CloudKepCertificateRequest): Promise<boolean> {
    return await post('/v1/ugb/certificates/issue', data);
  }

  async changeCloudCertificatePassword(
    oldPin: string,
    newPin: string,
    certificateId: number,
  ): Promise<boolean> {
    return await post('/v1/ugb/certificates/changePin', { oldPin, newPin, certificateId });
  }
}
