import * as React from 'react';
import { useDataTable } from 'react-data-table';

import './data-table-filter.scss';

export const TableFilter = () => {
  const { FilterComponent, breakpoint } = useTableFilter();

  if (!FilterComponent) {
    return null;
  }

  return (
    <div className="data-table-filter">
      <FilterComponent breakpoint={breakpoint} />
    </div>
  );
};

const useTableFilter = () => {
  const { filterComponent: FilterComponent, breakpoint } = useDataTable();

  return { FilterComponent, breakpoint };
};
