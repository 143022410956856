import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { ConstantEnum } from 'api/enums';
import { phoneMaskConfig } from 'components/forms/inputs/PhoneField';
import { validateMessages } from 'components/validateMessages';
import { translate } from 'i18n/translator';
import { selectConstants } from 'store/selectors';

export interface PasswordConstraints {
  maxLengthPassword: number;
  minLengthPassword: number;
  validateLogin: (login: string) => string;
  validatePassword: (password: string) => string;
  validatePhone: (phone: string) => string;
}

const phoneRegExp = '^\\+38\\s\\((codes)\\)\\s\\d{7}$';

export const useUserCredentialsValidate = (): PasswordConstraints => {
  const constants = useSelector(selectConstants());

  const validatePassword = useCallback(
    password => {
      const allowedPasswordSymbols = constants[ConstantEnum.AllowedPasswordSymbols];

      if (allowedPasswordSymbols && !new RegExp(allowedPasswordSymbols).test(password)) {
        return 'front.pass-validation.symbols-error.label';
      }
    },
    [constants],
  );

  const validateLogin = useCallback(
    login => {
      const allowedLoginSymbols = constants[ConstantEnum.AllowedLoginSymbols];
      const minLengthLogin = +constants[ConstantEnum.MinLoginLength];
      const maxLengthLogin = +constants[ConstantEnum.MaxLoginLength];

      if (login.length < minLengthLogin) {
        return translate(validateMessages.lessThan(minLengthLogin));
      }

      if (login.length > maxLengthLogin) {
        return translate(validateMessages.moreThan(maxLengthLogin));
      }

      if (allowedLoginSymbols && !new RegExp(allowedLoginSymbols).test(login)) {
        return translate('front.validation-login.error.label');
      }
    },
    [constants],
  );

  const validatePhone = useCallback(
    phone => {
      const allowedMobileOperators = constants[ConstantEnum.MobileOperatorsCodes].replaceAll(
        ',',
        '|',
      );
      const phoneOperatorsRegExp = phoneRegExp.replace('codes', allowedMobileOperators);
      const clearPhone = phone.replaceAll('_', '');

      if (clearPhone.length < phoneMaskConfig.format.length) {
        return 'front.validation-phone.correct-number.error.label';
      }

      if (!new RegExp(phoneOperatorsRegExp).test(phone)) {
        return 'front.validation-phone.operator-code.error.label';
      }
    },
    [constants],
  );

  return {
    validateLogin,
    validatePassword,
    minLengthPassword: +constants[ConstantEnum.MinPasswordLength],
    maxLengthPassword: +constants[ConstantEnum.MaxPasswordLength],
    validatePhone,
  };
};
