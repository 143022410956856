import React from 'react';
import { useLocation } from 'react-router';

import { parseUrlParams } from 'api/backend';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { HiddenField } from 'components/forms/inputs/HiddenField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextAreaField } from 'components/forms/inputs/TextAreaField';
import config from 'config';
import { useDisabledCustomer } from 'hooks/useDisabledCustomer';
import { translate } from 'i18n/translator';
import { CashPreparationDetailNames } from 'pages/Demands/CashTransferPage/enums';
import {
  CashPreparationFields,
  CashPreparationPayload,
} from 'pages/Demands/CashTransferPage/interfaces';
import {
  getClearValue,
  getRoundingPenni,
  validateBranch,
} from 'pages/Demands/CashTransferPage/utils';
import { useEffectIfValueChange } from 'utils/hooks';
import { isExist, isString } from 'utils/isData';

export const CashPreparationDetails = () => {
  const {
    order,
    payload: { accounts, branches },
    onChangeCustomer,
    form: { updateData, getFieldValue },
  } = useOrderDetails<CashPreparationFields, CashPreparationPayload>();

  const defaultNotes = translate('front.demands.cash-transfer.notes.default-value.label');
  const { customerId } = parseUrlParams(useLocation().search);

  const isDisabledCustomer = useDisabledCustomer(order) || isString(customerId);

  const onSelectAccount = React.useCallback(
    (id, { currency }) => updateData({ currency }),
    [accounts],
  );

  const branchValidate = React.useCallback(
    branchId => validateBranch(branchId, branches),
    [branches],
  );

  const accountId = getFieldValue(CashPreparationDetailNames.PayerAccountId);
  const amountValue = getFieldValue(CashPreparationDetailNames.Amount);

  const selectedAccount =
    accountId && accounts.find(account => `${account.value}` === `${accountId}`);

  const currency = selectedAccount?.content?.currency;
  const minAmount = currency !== config.bank.nationalCurrency ? 1 : 0.1;

  useEffectIfValueChange(currency, () => updateData({ amount: '' }));

  const getRoundingPenniOnBlur = React.useCallback(
    () =>
      updateData({ [CashPreparationDetailNames.Amount]: getRoundingPenni(+amountValue, currency) }),
    [amountValue, currency],
  );

  return (
    <DefaultForm>
      <CustomerSelect
        name={CashPreparationDetailNames.CustomerId}
        label="front.demands.cash-transfer.customer.field.label"
        onSelectOption={onChangeCustomer}
        disabled={isDisabledCustomer}
        required
      />
      <SelectField
        name={CashPreparationDetailNames.Branch}
        label="front.demands.cash-transfer.branch.field.label"
        options={branches}
        validate={branchValidate}
        required
      />
      <SelectField
        name={CashPreparationDetailNames.PayerAccountId}
        label="front.demands.cash-transfer.withdrawal-account.field.label"
        options={accounts}
        onSelectOption={onSelectAccount}
        required
      />
      <HiddenField name={CashPreparationDetailNames.Currency} />
      <AmountField
        name={CashPreparationDetailNames.Amount}
        label={`${translate('front.demands.cash-transfer.amount.field.label')} ${currency ?? ''}`}
        minAmount={minAmount}
        onBlur={getRoundingPenniOnBlur}
        maxLengthWithoutZero={12}
        isoCode={currency}
        disabled={!isExist(selectedAccount)}
        hasMoneyFormat
        thousandSeparator={' '}
        required
      />
      <TextAreaField
        name={CashPreparationDetailNames.Purpose}
        rows={3}
        label="front.demands.cash-transfer.notes.field.label"
        defaultValue={defaultNotes}
        maxLength={250}
        normalize={getClearValue}
      />
    </DefaultForm>
  );
};
