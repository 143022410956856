import React from 'react';

import { WIDGET } from 'config';
import { AnalyticsPage } from 'pages/Analytics/AnalyticsPage';
import { ItemType } from 'pages/Home/DragDrop';
import { InternalPaymentsPage } from 'pages/Payments/InternalPayment/InternalPaymentsPage';

const widget2Component: Obj<React.FC> = {
  [WIDGET.ANALYTICS]: AnalyticsPage,
  [WIDGET.INTERNAL_PAYMENTS]: InternalPaymentsPage,
};

export const getWidgetComponent = (widget: WIDGET) => widget2Component[widget];

export const reorder = (items: any[], startIndex: number, endIndex: number) => {
  const result = [...items];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const move = (
  source: ItemType[],
  destination: ItemType[],
  droppableSource: any,
  droppableDestination: any,
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: Record<string, any> = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

export const getItemStyle = (
  isDragging: boolean,
  draggableStyle: React.StyleHTMLAttributes<any>,
) => ({
  userSelect: 'none',
  margin: '0 0 8px 0',
  ...draggableStyle,
});

export const getDroppableId = (i: number): string => `droppable-col-${i}`;
export const getColIndex = (droppableId: string) =>
  Number(/droppable-col-(\d+)/.exec(droppableId)[1]) || 0;
