import React from 'react';
import { GridRequest, SortOrder } from 'react-data-table';

import { Devices } from 'enums/DevicesEnum';

interface DataFetchContextType {
  breakpoint: string;
  count: number;
  error: string;
  filter: Obj;
  gridRequest: GridRequests;
  isLoading: boolean;
  rows: any[];
  updateFilter(filter: Obj): void;
  isHidePagination?: boolean;
}

interface GridRequests {
  updateGridRequest: (GridRequest: GridRequest) => void;
  order?: SortOrder;
  page?: number;
  size?: number;
  sort?: string | string[];
}

export const DataFetcherContext = React.createContext<DataFetchContextType>({
  rows: [],
  count: 0,
  error: null,
  filter: null,
  breakpoint: Devices.Desktop,
  isLoading: false,
  updateFilter: () => {},
  gridRequest: {
    page: 0,
    sort: 'id',
    size: 0,
    order: SortOrder.Desc,
    updateGridRequest: () => {},
  },
  isHidePagination: false,
});
