import * as React from 'react';

import { api } from 'api';
import { FactorsChain } from 'api/AuthService';
import { isCipherAvailable } from 'api/CertificateService/CipherModule/CipherService';
import { UserKeystoreType } from 'api/CertificateService/enums';
import { Certificate } from 'api/CertificateService/interfaces';
import { AuthLayout } from 'components/layout/AuthLayout';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { CurrentFactor } from 'pages/Login/LoginContextType';
import { LoginLayout } from 'pages/Login/LoginLayout';
import ChangePassword from 'pages/Login/Steps/ChangePassword';
import KepSignatureLogin from 'pages/Login/Steps/SignatureLogin/KepSignatureLogin';
import UmkaSignatureLogin from 'pages/Login/Steps/SignatureLogin/UmkaSignatureLogin';
import { NoConnectionModal } from 'pages/Profile/Certificates/Modals/NoConnectionModal';
import { AgreementModalResult } from 'pages/utils/SignOrders/enums';
import { switchSignApplications } from 'pages/utils/SignOrders/utils';
import { CustomError } from 'utils/customError';

import { LoginContext } from './LoginContext';
import PasswordLogin from './Steps/PasswordLogin';
import SmsLogin from './Steps/SmsLogin';

export enum FactorType {
  Password = 'Password',
  Signature = 'Signature',
  SMS = 'SMS',
  ChangePassword = 'ChangePassword',
}

const firstFactor = { type: FactorType.Password };

export const LoginPage: React.FC = () => {
  const [currentFactor, setCurrentFactor] = React.useState<CurrentFactor>(firstFactor);
  const [factorsChain, setFactorsChain] = React.useState<FactorsChain>(null);
  const [certificates, setCertificates] = React.useState<Certificate[]>(null);
  const [useAvtorCsk, setUseAvtorCsk] = React.useState<boolean>(false);

  const handleFactorsChain = async () => {
    if (factorsChain) {
      const nextFactorType = getNextFactorType();

      const filteredOnlyTokenCertificates = certificates.filter(
        cert => cert.userKeystoreType === UserKeystoreType.HardWired,
      );

      if (nextFactorType === FactorType.Signature) {
        const result = await switchSignApplications({
          certificates: filteredOnlyTokenCertificates,
          warningMessage: 'front.confirm-modal.switch-login.label',
        });

        if (!result) {
          return;
        }

        const useAvtorCsk = result === AgreementModalResult.No;
        const isConnect = useAvtorCsk
          ? await api.certificate.isUmcaAvailable()
          : await isCipherAvailable();

        if (isConnect) {
          setUseAvtorCsk(useAvtorCsk);

          return goToNextFactor();
        }

        await confirmModal(NoConnectionModal, {
          description: useAvtorCsk
            ? 'front.certificates.error-modal-umca-text.label'
            : 'front.certificates.error-modal-cipher-text.label',
          useCipherApp: !useAvtorCsk,
        });

        sessionStorage.clear();
      } else {
        goToNextFactor();
      }
    }
  };

  React.useEffect(() => {
    handleFactorsChain();
  }, [factorsChain]);

  const getCurrentIndexFactor = () => {
    return factorsChain.findIndex(factor => factor.factorType === currentFactor.type);
  };

  const goToNextFactor = () => {
    const indexFactor = getCurrentIndexFactor();
    if (indexFactor < 0) throw new CustomError('Oops, something wents wrong');
    setCurrentFactor({
      type: factorsChain[indexFactor + 1]?.factorType,
    });
  };

  const getNextFactorType = (): FactorType => {
    const indexFactor = getCurrentIndexFactor();
    if (indexFactor < 0) throw new CustomError('Oops, something wents wrong');
    return factorsChain[indexFactor + 1]?.factorType;
  };

  const goToPrevFactor = async () => {
    const indexFactor = getCurrentIndexFactor();
    if (indexFactor < 0) throw new CustomError('Oops, something wents wrong');
    sessionStorage.clear();
    setCurrentFactor({
      type: factorsChain[indexFactor - 1]?.factorType,
    });
  };

  const goToFirstStep = async () => {
    sessionStorage.clear();
    return setCurrentFactor(firstFactor);
  };

  return (
    <AuthLayout>
      <LoginLayout>
        <LoginContext.Provider
          value={{
            goToNextFactor,
            goToPrevFactor,
            goToFirstStep,
            currentFactor,
            certificates,
            setCertificates,
            setCurrentFactor,
            setFactorsChain,
          }}
        >
          {renderFactors(currentFactor.type, useAvtorCsk)}
        </LoginContext.Provider>
      </LoginLayout>
    </AuthLayout>
  );
};

const renderFactors = (type: FactorType, useAvtorCsk: boolean) => {
  switch (type) {
    case FactorType.Password: {
      return <PasswordLogin />;
    }

    case FactorType.ChangePassword: {
      return <ChangePassword />;
    }

    case FactorType.SMS: {
      return <SmsLogin />;
    }

    case FactorType.Signature: {
      return useAvtorCsk ? <UmkaSignatureLogin /> : <KepSignatureLogin />;
    }

    default:
      return null;
  }
};
