import { withRouter } from 'react-router';

// eslint-disable-next-line node/no-extraneous-import
import { createBrowserHistory, Location, LocationDescriptor } from 'history';
import { pathToRegexp } from 'path-to-regexp';

import { NEW } from 'navigations/routes';

import { pages } from './pages';
import { allRoutes } from './routes';

export const history = createBrowserHistory();

let prevLocation = history.location;

history.listen(location => {
  let prevRoute = allRoutes().find(route => pathToRegexp(route.path).test(prevLocation.pathname));
  const nextRoute = allRoutes().find(route => pathToRegexp(route.path).test(location.pathname));

  if (!prevRoute && prevLocation.pathname === '/login') {
    prevRoute = nextRoute;
  }

  if (!prevRoute || !nextRoute) {
    return;
  }

  const isPrevRouteModal = prevRoute.isModal;
  const isNextRouteModal = nextRoute.isModal;

  const isBothModal = Boolean(isPrevRouteModal && isNextRouteModal);

  if (prevLocation.pathname !== location.pathname && !isBothModal) {
    setBackgroundLocation(prevLocation);

    if (
      location.pathname === pages.internalPayments.tabs.details(NEW) &&
      getBackgroundLocation().pathname !== pages.payments
    ) {
      const internalPaymentsPage = {
        hash: '',
        key: pages.payments,
        pathname: pages.payments,
        search: '',
      };
      setBackgroundLocation(internalPaymentsPage);
    }
  }

  prevLocation = location;
});

const BACKGROUND_LOCATION_KEY = 'backgroundLocation';

const defaultLocation = {
  hash: '',
  key: pages.dashboard,
  pathname: pages.dashboard,
  search: '',
};

export const goto = (href: LocationDescriptor<unknown>, state?: any) => history.push(href, state);

export const goBack = () => goto(getBackgroundLocation().pathname);

export const replace = (href: LocationDescriptor<unknown>) => history.replace(href);

export const navigate = (e: any) => {
  e.preventDefault();

  const link: HTMLElement = e.currentTarget;
  const href = link.getAttribute('href');

  history.push(href);
};

export const getBackgroundLocation = () => {
  const location = sessionStorage.getItem(BACKGROUND_LOCATION_KEY);
  try {
    if (location) {
      return JSON.parse(location);
    }
    return defaultLocation;
  } catch {
    return defaultLocation;
  }
};

export const setBackgroundLocation = (location: Partial<Location<unknown>>) => {
  return sessionStorage.setItem(BACKGROUND_LOCATION_KEY, JSON.stringify(location));
};

//TODO delete
export const existPreviousLocation = !!sessionStorage.getItem(BACKGROUND_LOCATION_KEY);

//TODO delete
export function withReactRouter(target: any): any {
  return withRouter(target);
}
