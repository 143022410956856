import * as React from 'react';

import classNames from 'classnames';

import { SortOrder } from 'api/Service';
import { DefaultSortIcon, SortDown, SortUp } from 'components/icons';
import { TableSortType } from 'components/react-data-table/types';
import { bem } from 'components/utils/bem';
import { translate } from 'i18n/translator';
import { isExist } from 'utils/isData';

import './data-table-content.scss';

interface Props {
  colLabel: string;
  colSort: TableSortType;
  hasDefaultSort: boolean;
  order: string;
  renderLabelIcon: () => React.ReactNode;
  setSort(sort: TableSortType, order: string): void;
  sort: TableSortType | Array<TableSortType>;

  children?: React.ReactNode;
}

export const DataTableHeaderColumn = ({
  renderLabelIcon,
  colLabel,
  colSort,
  sort,
  order,
  setSort,
  children,
  hasDefaultSort,
}: Props) => {
  const hasColSort = isExist(colSort);
  const hasSelectedSort = JSON.stringify(colSort) === JSON.stringify(sort);
  const shouldRenderDefaultIcon = !hasSelectedSort && hasColSort;

  const updateSort = (e: any) => {
    e.preventDefault();
    if (!hasColSort) {
      return;
    }
    if (hasColSort) {
      setSort(colSort, hasSelectedSort && order === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc);
    }
  };

  const Icon = React.useMemo(() => {
    if (shouldRenderDefaultIcon && !hasDefaultSort) {
      return <DefaultSortIcon />;
    }

    if (hasDefaultSort || hasColSort) {
      return order === SortOrder.Asc ? <SortUp /> : <SortDown />;
    }
  }, [order, colSort, sort, hasDefaultSort]);

  return (
    <div
      onClick={updateSort}
      className={classNames(
        { 'sortable-column-title': colSort, isActive: hasDefaultSort || hasSelectedSort },
        'column-title',
      )}
    >
      {renderLabelIcon?.() ?? translate(colLabel).toUpperCase()}
      <div className={bem('data-table-content', 'sort-arrow').toClassName()}>{Icon}</div>
      {children}
    </div>
  );
};
