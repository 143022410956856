import { translate } from 'i18n/translator';

const replaceValue = (label: string, name: string, val: string) => label.replace(name, val);

export const fillStringParams = (label: string, ...values: any[]) => {
  return [...values].reduce((acc, val, idx) => {
    acc =
      idx === 0
        ? replaceValue(translate(label), '{value}', val)
        : replaceValue(acc, `{value${idx + 1}}`, val);
    return acc;
  }, '');
};
