import { downloadFile, get, HttpMethod, post, put } from 'api/backend';
import { checkMultiCustomer } from 'api/ConstantService';
import {
  BankBranchInterface,
  CashSymbolInterface,
  CurrencyConversionDirectory,
  DemandResponse,
  DemandsGridFilter,
  DemandTemplate,
  DemandTypes,
  ImportSalaryResponse,
  PartialDemandRequest,
} from 'api/DemandsService/interfaces';
import { OrderActionType } from 'api/enums';
import { Account } from 'api/interfaces';
import { Employee } from 'api/SalaryService';
import { GridResponse, GridResponseWithMeta, Option } from 'api/Service';
import { needHeaders } from 'api/utils';
import { ExportFormatEnum } from 'components/ExportDropdown/enum';
import { createFormDataFromObj } from 'components/utils/createFormDataFromObj';
import { FileFieldInterface } from 'pages/Profile/Certificates/CertificateEngine/types';

import { CashTransferAccounts, DemandGroupTypes } from './enums';

export class DemandsService {
  async getDemand(id: number | string): Promise<DemandResponse> {
    return await get(`/v1/orders/${id}`);
  }

  async getDemandTemplate(type: string, userId: number): Promise<DemandTemplate> {
    return await post(`/v1/orders/fields/${type}`, {}, { customerId: [userId] });
  }

  async getDemands(request: DemandsGridFilter): Promise<GridResponse<DemandResponse>> {
    return await get('/v1/orders', checkMultiCustomer(request));
  }

  async getTypesGroups(groupIds: DemandGroupTypes[]): Promise<Array<DemandTypes>> {
    return await get('/v1/orders/types/groups', { groupIds });
  }

  async getDemandTypes(groupIds: DemandGroupTypes[]): Promise<Option[]> {
    const orderTypesGroups = await this.getTypesGroups(groupIds);

    return orderTypesGroups.reduce((options: Option[], group) => {
      const orderTypeList = group.orderTypeList;

      orderTypeList.map(orderType => {
        const option = { value: orderType.orderType, label: orderType.translatedName };
        options.push(option);
      });

      return options;
    }, []);
  }

  async saveOrder(request: PartialDemandRequest): Promise<number> {
    return await post('/v2/orders', request);
  }

  async saveOrderV3(request: PartialDemandRequest): Promise<number> {
    return await post('/v3/orders', request);
  }

  async updateOrder(id: number, request: PartialDemandRequest): Promise<number> {
    return await put(`/v2/orders/${id}`, request);
  }

  async updateOrderV4(id: number, request: PartialDemandRequest): Promise<number> {
    return await put(`/v4/orders/${id}`, request);
  }

  async deleteOrder(id: number): Promise<any> {
    return await put(`/v1/orders/${id}/DELETE`);
  }

  async withdrawOrders(ids: Array<number>): Promise<Array<number>> {
    return await put('/v1/orders/withdraw', ids);
  }

  async getOrderPdf(id: number, isOrderDemand: boolean): Promise<any> {
    return await downloadFile(`/v1/orders/get-pdf/${id}`, {}, { isOrderDemand });
  }

  async executeAction(orderId: number, action: OrderActionType, data?: Obj): Promise<any> {
    return await put(`/v1/orders/${orderId}/${action}`, data);
  }

  async importSalaryV2(
    files: FileFieldInterface[] = [],
    orderType: string,
    customerId: number,
    productId: number,
    fileFormat: ExportFormatEnum,
  ): Promise<ImportSalaryResponse> {
    const formData = createFormDataFromObj({});
    files.forEach((file: { value: string | Blob }) => formData.append('data', file.value));

    const result = await post('/v2/import', formData, {
      orderType,
      customerId,
      productId,
      objectCode: orderType,
    });

    const hasOrderErrors = result.errors.length > 0 || result.meta?.messages?.length > 0;
    let newErrors = [];

    if (hasOrderErrors) {
      const HEADER_ID = 1;

      const objectCode: Record<string, string> = {
        SalaryEmployeeNew: 'employee',
        SalarySheet: 'employeeSalaryModel',
      };

      const hasHeaders = needHeaders(fileFormat, result.errors[0]?.[objectCode[orderType]]?.id);

      newErrors = result.errors.map(
        (error: { employee: Employee; employeeSalaryModel: Employee }) => {
          const currentId = error.employeeSalaryModel?.id ?? error.employee?.id;

          return {
            ...error,
            id: hasHeaders ? currentId : currentId + HEADER_ID,
          };
        },
      );
    }

    return { ...result, errors: newErrors };
  }

  async getBranches(
    customerId: number | string,
    isShowAllBranches = true,
  ): Promise<BankBranchInterface[]> {
    return get(`/v1/bank-branch/customer/${customerId}`, { isShowAllBranches });
  }

  async getCashTransferSymbols(
    upLevelCode: string,
  ): Promise<GridResponseWithMeta<CashSymbolInterface>> {
    return get(`/codes/${upLevelCode}`);
  }

  async getAccountsCashTransfer(
    customerId: string | number,
    fieldList: CashTransferAccounts[],
  ): Promise<{ [key in CashTransferAccounts]: { account: Obj<Account> }[] }> {
    return get('/payment/account-transfer/source-field', { customerId, fieldList });
  }

  async getCurrencyPair(): Promise<CurrencyConversionDirectory> {
    const { rows } = await get('/codes/CurrencyPair/');
    return rows;
  }

  async downloadDemandFile(fieldDataId: number, isSignedFile = false): Promise<void> {
    return await downloadFile(
      `/v1/orders/files/${fieldDataId}`,
      undefined,
      { isSignedFile, encoding: 'UTF8' },
      HttpMethod.GET,
    );
  }

  importSalary(
    files: any[] = [],
    orderType: string,
    customerId: number,
    productId?: number,
  ): Promise<ImportSalaryResponse> {
    const formData = createFormDataFromObj({});
    for (let i = 0; i < files.length; i += 1) {
      formData.append('uploadFile', files[i].value);
    }
    return post('/v1/orders/import', formData, {
      orderType,
      customerId,
      productId,
    });
  }
}
