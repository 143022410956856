import * as React from 'react';
import { useDataTable } from 'react-data-table';

import { ExportWayOption } from 'components/ExportingDocuments/types';
import { PrintOption } from 'components/PrintingDocuments/types';
import { handleError } from 'utils/handleError';

export const useExport = () => {
  const [loaders, setLoaders] = React.useState<Obj<boolean>>({});
  const { setError } = useDataTable();

  const handleSetLoaders = (label: string, value: boolean) =>
    setLoaders(prevState => ({ ...prevState, [label]: value }));

  const createOnClick =
    (label: string, action: (option?: ExportWayOption | PrintOption) => void) => async () => {
      handleSetLoaders(label, true);

      try {
        await action();
      } catch (e: any) {
        return handleError(e, setError);
      } finally {
        handleSetLoaders(label, false);
      }

      setLoaders(prevState => {
        const newState = { ...prevState };
        delete newState[label];
        return newState;
      });
    };

  return { loaders, createOnClick };
};
