import Decimal, { Numeric } from 'decimal.js-light';

import { DemandType, VisualComponentEnum } from 'api/DemandsService/enums';
import {
  DemandResponse,
  DynamicField,
  DynamicFieldBase,
  DynamicFieldData,
} from 'api/DemandsService/interfaces';
import { OrderState } from 'api/enums';
import { Order } from 'api/OrderService';
import { Option } from 'api/Service';
import { OrderRouteParams } from 'components/Document/withOrder';
import { FormState } from 'components/forms/ValidatingForm/FormContext';
import { toAmountFormatWithDot } from 'components/utils/format';
import config from 'config';
import { translate } from 'i18n/translator';
import { NEW } from 'navigations/routes';
import { CurrencyExchangeDetailsName } from 'pages/Demands/CurrencyExchange/enums';
import {
  CurrencyConversionFields,
  CurrencyPayload,
} from 'pages/Demands/CurrencyExchange/interfaces';
import { FILE_SEPARATOR, getDynamicField, TYPE_SEPARATOR } from 'pages/Demands/utils';
import { getFieldValue } from 'pages/Payments/ForeignPayment/utils';
import { getAccountValue } from 'pages/Payments/InternalPayment/utils';
import { findAccount } from 'pages/Salary/utils';
import { getClearPhone } from 'utils/getClearPhone';
import { isExist } from 'utils/isData';

export const PURPOSE_MIN_LENGTH = 5;
export const BANK_RATE = 'BankRate';

export const MIN_AMOUNT = 0.1;

export const multiply = (a: Numeric, b: Numeric) =>
  new Decimal(a).mul(b).toFixed(2, Decimal.ROUND_HALF_UP).toString();

export const divide = (a: Numeric, b: Numeric) =>
  new Decimal(a).div(b).toFixed(2, Decimal.ROUND_HALF_UP).toString();

const UA_IBAN_REGEX = /^UA\d{8}[A-Z0-9]{19}$/;

export const validateIbanUA = (value: string) => {
  if (UA_IBAN_REGEX.test(value)) {
    return '';
  }

  return translate('front.form.account-number.error');
};

export const getAccountType = (orderType: string): CurrencyExchangeDetailsName[] => {
  switch (orderType) {
    case DemandType.CurrencyBuy: {
      return [
        CurrencyExchangeDetailsName.AccountUAH,
        CurrencyExchangeDetailsName.AccountNonUAH,
        CurrencyExchangeDetailsName.RateType,
      ];
    }
    case DemandType.CurrencySale: {
      return [
        CurrencyExchangeDetailsName.AccountUAH,
        CurrencyExchangeDetailsName.AccountNonUAH,
        CurrencyExchangeDetailsName.RateType,
      ];
    }
    case DemandType.CurrencyConversion: {
      return [
        CurrencyExchangeDetailsName.AccountSale,
        CurrencyExchangeDetailsName.AccountBuy,
        CurrencyExchangeDetailsName.AccountFee,
        CurrencyExchangeDetailsName.RateType,
      ];
    }
  }
};

export const getUpdatedData = (rateType: string, isBuyAmount: boolean) => {
  const data: Record<string, null> = {
    [isBuyAmount ? 'amountSale' : 'amountBuy']: null,
  };
  if (rateType === BANK_RATE) {
    data['currencyRate'] = null;
  }
  return data;
};

export const getDynamicAccountValue = (code: string, dynamicFields: DynamicField[]) => {
  const dynamicField = getDynamicField(code, dynamicFields);

  const hasAccountField = hasAccount(dynamicField?.data[0]?.fieldValue, dynamicField?.values);

  return hasAccountField ? dynamicField?.data[0].numericValue : dynamicField?.data[0]?.textValue;
};

export const getCurrencyConversion = (
  accounts: Option[],
  accountId: string,
  defaultCurrency: Option,
) =>
  accounts?.find(account => `${account.value}` === `${accountId}`)?.content.currency ??
  defaultCurrency;

export const getFilteredAccounts = (accountOptions: Option[], currency: string) =>
  accountOptions.filter(account => account.content.currency !== currency);

export const hasAccount = (accountId: string | number, payload: Option[]) =>
  payload?.some(item => `${item.value}` === `${accountId}` || `${item.label}` === `${accountId}`);

const getUpdatedAcc = (
  acc: Partial<DynamicField>[],
  data: Partial<DynamicFieldData>[],
  field: DynamicFieldBase,
) => [
  ...acc,
  {
    data,
    field,
  },
];

export const getDynamicFields = (demandFields: AccountFields, dynamicFields: DynamicField[]) =>
  Object.entries(demandFields).reduce((acc, [field, value]) => {
    const { field: fieldBase, values } =
      dynamicFields.find(({ field: fieldBase }) => fieldBase.code === field) || {};

    if (!fieldBase || !isExist(value)) {
      return acc;
    }

    switch (fieldBase.visualComponent) {
      case VisualComponentEnum.SELECT: {
        const selected = values?.find(({ id, value: val }) =>
          [id?.toString(), val].includes(value?.toString()),
        );

        return getUpdatedAcc(
          acc,
          [
            {
              numericValue: isNaN(+selected?.id) ? fieldBase.id : +selected.id,
              textValue: selected?.label ?? value,
              orderFieldId: fieldBase.id,
              isFieldApproved: true,
              fieldValue: selected?.value ?? value,
              managerComment: null,
            },
          ],
          fieldBase,
        );
      }

      case VisualComponentEnum.FILE: {
        // @ts-ignore
        if (!value.length) {
          return acc;
        }

        return getUpdatedAcc(
          acc,
          (value as any[])?.map(({ value, field, attachment }) => {
            const [type, content] = value.split(FILE_SEPARATOR);
            const [, typeValue] = type.split(TYPE_SEPARATOR);

            return {
              orderFieldId: fieldBase.id,
              textValue: field,
              isFieldApproved: true,
              managerComment: null,
              attachment: {
                name: field,
                type: attachment?.type ?? typeValue,
                content,
              },
            };
          }),
          fieldBase,
        );
        return acc;
      }

      case VisualComponentEnum.AMOUNT:
      case VisualComponentEnum.NUMBER: {
        return getUpdatedAcc(
          acc,
          [
            {
              numericValue: Number(value),
              orderFieldId: fieldBase.id,
              isFieldApproved: true,
              managerComment: null,
            },
          ],
          fieldBase,
        );
      }

      case VisualComponentEnum.BOOLEAN:
      case VisualComponentEnum.CHECKBOX: {
        return getUpdatedAcc(
          acc,
          [
            {
              booleanValue: Boolean(value),
              orderFieldId: fieldBase.id,
              isFieldApproved: true,
              managerComment: null,
            },
          ],

          fieldBase,
        );
      }

      case VisualComponentEnum.PHONE: {
        return getUpdatedAcc(
          acc,
          [
            {
              fieldValue: getClearPhone(value),
              textValue: getClearPhone(value),
              orderFieldId: fieldBase.id,
              isFieldApproved: true,
              managerComment: null,
            },
          ],
          fieldBase,
        );
      }

      default: {
        return getUpdatedAcc(
          acc,
          [
            {
              fieldValue: value,
              textValue: value,
              orderFieldId: fieldBase.id,
              isFieldApproved: true,
              managerComment: null,
            },
          ],
          fieldBase,
        );
      }
    }
  }, []);

export const getAccountContent = (id: number, values: Option[]) =>
  values.find(({ value }) => +value === +id)?.content;

export const getFinancialDetailsByOrder = (
  order: Order,
  filteredFormData: CurrencyConversionFields | FormState,
  payload: CurrencyPayload,
) => {
  const {
    amount,
    amountBuy,
    amountSale,
    accountUAH,
    currencyRate,
    accountNonUAH,
    accountSale,
    accountBuy,
    purpose,
    receiverAmount,
    isoCode,
    receiverCurrency,
    doExternalFeeAcc,
    externalFeeAccName,
    externalFeeAccCode,
    externalFeeAccNumber,
    isBuyAmount,
  } = filteredFormData;

  const { id, type } = order;

  const accountUah = getAccountContent(accountUAH, payload.accountUAH);
  const correctCurrencyRate = toAmountFormatWithDot(currencyRate as string);

  const isAccountUahCustomIban = !accountUah?.iban;

  let financialDetails;

  if (type === DemandType.CurrencyBuy) {
    financialDetails = {
      id,
      receiverAmount,
      receiverCurrency,
      purpose,
      currencyRate: correctCurrencyRate,
      payerAccountId: isAccountUahCustomIban ? null : accountUAH,
      payerIban: isAccountUahCustomIban ? accountUAH : accountUah.iban,
      receiverAccountId: accountNonUAH,
      receiverName: doExternalFeeAcc && externalFeeAccName,
      receiverTaxCode: doExternalFeeAcc ? externalFeeAccCode : payload.payerOptions.taxCode,
      receiverIban: doExternalFeeAcc
        ? externalFeeAccNumber
        : getAccountContent(+accountNonUAH, payload.accountNonUAH).iban,
      taxCode: payload.payerOptions.taxCode,
      amount: isExist(correctCurrencyRate) ? multiply(+receiverAmount, +correctCurrencyRate) : 0,
      isoCode: isAccountUahCustomIban ? config.bank.nationalCurrency : accountUah.currency,
    };
  }

  if (type === DemandType.CurrencySale) {
    financialDetails = {
      id,
      purpose,
      amount,
      isoCode,
      currencyRate: correctCurrencyRate,
      payerAccountId: accountNonUAH,
      payerIban: getAccountContent(+accountNonUAH, payload.accountNonUAH).iban,
      receiverAccountId: doExternalFeeAcc ? null : accountUAH,
      receiverName: doExternalFeeAcc ? externalFeeAccName : null,
      receiverTaxCode: doExternalFeeAcc ? externalFeeAccCode : payload.payerOptions.taxCode,
      receiverIban: doExternalFeeAcc ? externalFeeAccNumber : accountUah.iban,
      taxCode: payload.payerOptions.taxCode,
      receiverAmount: isExist(correctCurrencyRate) ? multiply(+amount, +correctCurrencyRate) : 0,
      receiverCurrency: doExternalFeeAcc ? null : accountUah.currency,
    };
  }

  if (type === DemandType.CurrencyConversion) {
    financialDetails = {
      id,
      isoCode,
      currencyRate: correctCurrencyRate,
      receiverCurrency,
      purpose,
      payerAccountId: accountSale,
      payerIban: getAccountContent(+accountSale, payload.accountSale).iban,
      receiverAccountId: accountBuy,
      receiverTaxCode: payload.payerOptions.taxCode,
      receiverIban: getAccountContent(+accountBuy, payload.accountBuy).iban,
      amount: !correctCurrencyRate && isBuyAmount ? 0 : amountSale,
      receiverAmount: !correctCurrencyRate && !isBuyAmount ? 0 : amountBuy,
    };
  }

  return financialDetails;
};

interface AccountFields {
  accountBuy?: string | number;
  accountFee?: string | number;
  accountNonUAH?: string | number;
  accountSale?: string | number;
  accountUAH?: string | number;
  amount?: string | number;
  amountBuy?: string | number;
  amountSale?: string | number;
  currencyRate?: number;
  externalFeeAccCode?: string;
  externalFeeAccName?: string;
  externalFeeAccNumber?: string;
  isBuyAmount?: boolean;
  isoCode?: string;
  purpose?: string;
  receiverAmount?: string | number;
  receiverCurrency?: string;
}

export const prepareFieldsCurrencyExchange = (
  demand: DemandResponse,
  detailFields: { accountFee?: any; doExternalFeeAcc?: any; isCustomIban?: any },
  routeParams: OrderRouteParams,
  uahAccountOptions: Option[],
  currencyAccountOptions: Option[],
  isBuyAmount: boolean,
  isCopy = false,
): AccountFields => {
  const {
    base: { orderType, orderState },
    dynamicFields,
    financial,
  } = demand;
  const isDraft = orderState === OrderState.Draft;
  const { accountFee, isCustomIban, doExternalFeeAcc } = detailFields;

  const {
    receiverAccountId,
    receiverIban,
    payerAccountId,
    payerIban,
    purpose,
    amount,
    receiverAmount,
    isoCode,
    receiverCurrency,
    currencyRate,
    receiverName,
    receiverTaxCode,
  } = financial;

  if (routeParams.id !== NEW) {
    if (orderType === DemandType.CurrencyBuy) {
      const payerAccountValue = getAccountValue(payerAccountId, uahAccountOptions);
      const receiverAccountValue = getAccountValue(receiverAccountId, currencyAccountOptions);
      const receiverAccountNoDraft = receiverAccountValue ?? receiverIban;
      const payerAccountNoDraft = payerAccountValue ?? payerIban;

      return {
        receiverAmount,
        receiverCurrency,
        currencyRate,
        purpose,
        accountNonUAH: isDraft ? receiverAccountValue : receiverAccountNoDraft,
        accountUAH: isDraft && !isCustomIban ? payerAccountValue : payerAccountNoDraft,
      };
    }

    if (orderType === DemandType.CurrencySale) {
      const receiverAccountValue = getAccountValue(payerAccountId, currencyAccountOptions);
      const payerAccountValue = getAccountValue(receiverAccountId, uahAccountOptions);
      const receiverAccountNoDraft = receiverAccountValue ?? receiverIban;
      const payerAccountNoDraft = payerAccountValue ?? payerIban;

      return {
        isoCode,
        amount,
        currencyRate,
        purpose,
        externalFeeAccName: doExternalFeeAcc ? receiverName : null,
        externalFeeAccCode: doExternalFeeAcc ? receiverTaxCode : null,
        externalFeeAccNumber: doExternalFeeAcc ? receiverIban : null,
        accountNonUAH: isDraft ? receiverAccountValue : receiverAccountNoDraft,
        accountUAH: isDraft ? payerAccountValue : payerAccountNoDraft,
        accountFee: isDraft
          ? findAccount(dynamicFields, accountFee, 'accountFee')
          : getDynamicAccountValue('accountFee', dynamicFields),
      };
    }

    if (orderType === DemandType.CurrencyConversion) {
      return {
        isoCode,
        isBuyAmount,
        currencyRate,
        receiverCurrency,
        purpose,
        amountBuy: receiverAmount?.toString(),
        amountSale: amount?.toString(),
        accountSale: isDraft
          ? getAccountValue(payerAccountId, currencyAccountOptions)
          : getAccountValue(payerAccountId, currencyAccountOptions) ?? payerIban,
        accountBuy: isDraft
          ? getAccountValue(receiverAccountId, currencyAccountOptions)
          : getAccountValue(receiverAccountId, currencyAccountOptions) ?? receiverIban,
      };
    }
  }

  if (isCopy) {
    if (orderType === DemandType.CurrencyBuy) {
      const currentAccountUAH = payerAccountId ?? payerIban;
      const hasAccountUAH = hasAccount(payerAccountId, uahAccountOptions);
      const hasAccountNonUAH = hasAccount(receiverAccountId, currencyAccountOptions);

      return {
        receiverAmount,
        receiverCurrency,
        purpose,
        currencyRate: isExist(currencyRate) ? currencyRate : null,
        accountNonUAH: hasAccountNonUAH ? receiverAccountId : null,
        accountUAH: hasAccountUAH || isCustomIban ? currentAccountUAH : null,
      };
    }

    if (orderType === DemandType.CurrencySale) {
      const hasAccountUAH = hasAccount(receiverAccountId, uahAccountOptions);
      const hasAccountNonUAH = hasAccount(payerAccountId, currencyAccountOptions);

      return {
        isoCode,
        amount,
        purpose,
        externalFeeAccName: doExternalFeeAcc ? receiverName : null,
        externalFeeAccCode: doExternalFeeAcc ? receiverTaxCode : null,
        externalFeeAccNumber: doExternalFeeAcc ? receiverIban : null,
        currencyRate: isExist(currencyRate) ? currencyRate : null,
        accountNonUAH: hasAccountNonUAH ? payerAccountId : null,
        accountUAH: hasAccountUAH ? receiverAccountId : null,
      };
    }

    if (orderType === DemandType.CurrencyConversion) {
      const hasAccountSale = hasAccount(payerAccountId, currencyAccountOptions);
      const hasAccountBuy = hasAccount(receiverAccountId, currencyAccountOptions);
      const hasAccountFee = hasAccount(accountFee, uahAccountOptions);

      return {
        isoCode,
        receiverCurrency,
        purpose,
        currencyRate: isExist(currencyRate) ? currencyRate : null,
        amountBuy: isBuyAmount ? receiverAmount.toString() : null,
        amountSale: !isBuyAmount ? amount.toString() : null,
        accountSale: hasAccountSale ? payerAccountId : null,
        accountBuy: hasAccountBuy ? receiverAccountId : null,
        accountFee: hasAccountFee ? accountFee : null,
      };
    }
  }
};

export const maskDecimalTenConfig = {
  decimalScale: 4,
  fixedDecimalScale: true,
  allowedDecimalSeparators: [',', '.'],
  decimalSeparator: ',',
};

export const getAmountValue = (row: DemandResponse) => {
  if (row.base.orderType === DemandType.CurrencyBuy) {
    return { amount: row.financial.receiverAmount, currency: row.financial.receiverCurrency };
  }

  if (row.base.orderType === DemandType.CurrencySale) {
    return { amount: row.financial.amount, currency: row.financial.isoCode };
  }

  const isBuyAmount = getFieldValue(CurrencyExchangeDetailsName.IsBuyAmount, row.dynamicFields);

  const amount = isBuyAmount ? row.financial.receiverAmount : row.financial.amount;
  const currency = isBuyAmount ? row.financial.receiverCurrency : row.financial.isoCode;

  return { amount, currency };
};
