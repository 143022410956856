import React from 'react';
import { Col, Row, Container } from 'react-grid';
import reactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';

import moment from 'moment';

import { api } from 'api';
import { KeyType } from 'api/CertificateService/enums';
import { GetCertificateParams } from 'api/CertificateService/interfaces';
import { Option } from 'api/Service';
import { Customer } from 'api/UserService';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { PasswordField } from 'components/forms/inputs/PasswordField';
import { RadioField } from 'components/forms/inputs/RadioField';
import { FormPayload } from 'components/forms/ValidatingForm/FormContext';
import { Effects, useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { translate } from 'i18n/translator';
import { Privileges } from 'navigations/privileges';
import { CertificateControls } from 'pages/Profile/Certificates/CertificateEngine/CertificateControls';
import {
  ConfigType,
  Fields,
  FileFieldInterface,
  FilterCert,
} from 'pages/Profile/Certificates/CertificateEngine/types';
import { useMode } from 'pages/Profile/Certificates/CertificateEngine/useCertificate';
import { getAllowedCustomer } from 'pages/Profile/Certificates/CertificateEngine/utils';
import { NotAllowed } from 'pages/Profile/Certificates/Modals/NotAllowed';
import { validatePasswordWithException } from 'pages/Profile/Certificates/PasswordValidator';
import { selectChosenCustomers, selectDefaultCustomerId, selectCustomers } from 'store/selectors';

import { CertificateContext } from './CertificateContext';

export function withCertificate<PP>(config: ConfigType<PP>) {
  return (CertificateDetail: React.ElementType): React.FC => {
    const CertificateComponent = (props: React.PropsWithChildren<any>) => {
      const [isSearchTokens, setIsSearchTokens] = React.useState<boolean>(false);
      const [expiredError, setExpiredError] = React.useState<string>(null);

      const customerRef = React.useRef(null);
      const setCustomer = (customer: Customer) => (customerRef.current = customer);

      const { updateMode, currentMode } = useMode();

      const {
        title,
        labelButtonOnSave,
        fetchOnMount,
        createOnSave,
        createFetchCerts,
        allowCancel,
        allowChangePassword,
        validatePasswordError,
        allowedMultikey = false,
        filteringParams = {},
        customerPrivilege = '' as Privileges,
        createFilteringCustomers,
      } = typeof config === 'function' ? config(currentMode) : config;

      const defaultCustomerId = useSelector(selectDefaultCustomerId(customerPrivilege));
      const selectedCustomers = useSelector(selectChosenCustomers());
      const customers = useSelector(selectCustomers());

      const initialCustomer = () => {
        let customers = selectedCustomers;
        let customerId = defaultCustomerId;
        if (props.customerIds) {
          customerId = props.customerIds[0];
          customers = customers.filter(c => props.customerIds.includes(c.id));
        }

        const customer = getAllowedCustomer(customers, customerId);
        setCustomer(customer);
        return customer;
      };

      const initialCertificate = async (formActions: Effects<Fields, FormPayload>) => {
        const customer = initialCustomer();

        customer &&
          (await fetchOnMount({ customer, props }, { ...formActions, updateMode, fetchData }));
      };

      const form = useForm<Fields, FormPayload>(initialCertificate);

      const edsKey = form.getFieldValue('edsKey');

      React.useEffect(() => {
        const option = (form.payload?.edsKeyOptions || []).find(
          (item: Option<any, string | FileFieldInterface[]>) => item.value === edsKey,
        );
        const days = option && moment(option.content.notAfter).diff(moment(), 'days');

        if (option && days < 30) {
          const expiredError = `${translate(
            'front.certificates.expired-error.label',
          )} ${days} ${translate('front.certificates.expired-days.label')}`;
          setExpiredError(expiredError);
        } else {
          setExpiredError(null);
        }
      }, [edsKey]);

      const getCustomer = (customerId: number) => {
        return customers.find(c => c.id === customerId);
      };

      const validatePassword = () =>
        validatePasswordWithException(
          form.getFieldValue('password') as string,
          validatePasswordError,
        );

      const createdOnSave =
        createOnSave &&
        createOnSave(
          { customer: customerRef.current, customers, props, payload: form.payload },
          validatePassword,
        );

      const onSave = (e: any) => form.handleSubmit(createdOnSave, e);

      const onChangeCustomer = form.setInitialingCb(async (customerId: string) => {
        const customer = getCustomer(Number(customerId));
        setCustomer(customer);

        const mode = updateMode(customer.config);
        await onSelectMode(mode as KeyType, {
          edrpou: customer.taxCode,
          customerIds: [customerId],
        });
      });

      const createFilter = (filter: FilterCert = {}): Partial<GetCertificateParams> => {
        const { edrpou, customerIds } = filter;

        const newFilter: Partial<GetCertificateParams> = {
          allowedMultikey: allowedMultikey && customerRef.current.allowedForUserCert,
          customerIds: customerIds || [customerRef.current.id],
          existingInSystem: filteringParams.existingInSystem,
        };

        if (filteringParams.edrpou) {
          newFilter['edrpou'] = edrpou || customerRef.current.taxCode;
        }

        return newFilter;
      };

      const fetchCertificatesDefault = async (mode: KeyType, filter?: FilterCert) => {
        const createdFilter = createFilter(filter);
        let storeOptions;
        let edsKeyOptions: Option[] = [];
        let store;
        let edsKey;
        form.setError(null);

        if (mode === KeyType.FILE) {
          edsKeyOptions = await api.certificate.getCertificatesOptions({
            mode,
            ...createdFilter,
          });
          edsKey = edsKeyOptions[0]?.value;
        }

        if (mode === KeyType.TOKEN) {
          storeOptions = await api.certificate.getTokens(mode);
          store = storeOptions[0]?.value;
          const Serial = storeOptions[0]?.content.Serial;
          edsKeyOptions = await api.certificate.getCertificatesOptions({
            mode,
            ...createdFilter,
            fetchFilter: { Serial },
          });
          edsKey = edsKeyOptions[0]?.value;
        }

        form.setPayload({ storeOptions, edsKeyOptions });
        form.updateData({ store, edsKey, password: null });
      };

      const fetchData = createFetchCerts
        ? createFetchCerts({
            updateData: form.updateData,
            setPayload: form.setPayload,
          })
        : fetchCertificatesDefault;

      const onSearchToken = async () => {
        setIsSearchTokens(true);
        form.setError(null);
        await fetchData(currentMode);
        setIsSearchTokens(false);
      };

      const onSelectMode = form.setInitialingCb(async (value: KeyType, filter?: FilterCert) => {
        form.setError(null);
        setExpiredError(null);
        form.updateData({ store: null, edsKey: null });
        await fetchData(value, filter);
      });

      const filteringCustomers =
        createFilteringCustomers && createFilteringCustomers(props.customerIds);

      const isDenyForCustomer =
        customerRef.current &&
        !customerRef.current.config.ALLOW_FILE_KEY &&
        !customerRef.current.config.ALLOW_TOKEN_KEY;

      if (!form.payload?.keyTypeOptions || isDenyForCustomer) {
        return <NotAllowed resolve={props?.resolve} />;
      }

      return (
        <CertificateContext.Provider
          value={{
            customer: customerRef.current,
            isSearchTokens,
            expiredError,
            onSearchToken,
          }}
        >
          <h3>{translate(title)}</h3>
          <DefaultForm disabled={form.progress}>
            {form?.error && <FormError>{reactHtmlParser(form.error)}</FormError>}
            <RadioField
              name="mode"
              options={form.payload.keyTypeOptions}
              cssType="flex"
              onChange={onSelectMode}
              required
            />
            <Container>
              <Row>
                <Col>
                  <CustomerSelect
                    name="customerId"
                    label="front.cert-page-new.input-customer-id.label"
                    onSelectOption={onChangeCustomer}
                    filterCustomers={filteringCustomers}
                    privilege={customerPrivilege}
                    selectDefaultCustomer
                    required
                  />
                </Col>

                <CertificateDetail />

                <Col>
                  <PasswordField
                    label="front.cert-page-add.input-password.label"
                    name="password"
                    minLength={6}
                    maxLength={8}
                    required
                  />
                </Col>
                <CertificateControls
                  onSave={onSave}
                  progress={form.progress}
                  resolve={props.resolve}
                  labelButtonOnSave={labelButtonOnSave}
                  allowCancel={allowCancel}
                  allowChangePassword={allowChangePassword}
                />
              </Row>
            </Container>
          </DefaultForm>
        </CertificateContext.Provider>
      );
    };

    return withForm(CertificateComponent);
  };
}
