import React from 'react';
import { Modifier } from 'react-day-picker';

import moment from 'moment';

import { DatePicker } from 'components/forms/datePicker/DatePicker';
import { useField } from 'components/forms/ValidatingForm/components/useField';
import * as format from 'components/utils/format';
import { validateMessages } from 'components/validateMessages';
import { translate } from 'i18n/translator';

import { FieldInput } from './FieldInput';
import { PreloadField } from './PreoadField';

export interface DateProps {
  label: string;
  name: string;
  clearable?: boolean;
  defaultValue?: Date;
  disabled?: boolean;
  disabledDays?: Modifier | Modifier[];
  hasOutline?: boolean;
  initialDate?: Date;
  isEditable?: boolean;
  maxDate?: Date;
  minDate?: Date;
  pipConfig?: {
    maxYear: number;
    minYear: number;
  };
  placeholder?: string;
  required?: boolean;
  validate?: (value: any) => string;
}

const onDateChange = (e: any, onChange: (value: any) => void) => {
  const value = e?.target ? e.target.value : e || '';
  onChange(format.parseDate(value));
};

export const DateField = ({
  name,
  label,
  disabled,
  required,
  placeholder,
  clearable,
  minDate,
  maxDate,
  initialDate,
  defaultValue,
  validate: externalValidate,
  disabledDays,
  isEditable,
  pipConfig,
  hasOutline,
}: DateProps) => {
  const minDateFormatted = minDate?.getDate();
  const maxDateFormatted = maxDate?.getDate();

  const validate = React.useCallback(
    value => {
      if (required) {
        if (!value) {
          return translate(validateMessages.fieldRequired);
        }
      }
      if (value && minDate && value < moment(minDate).startOf('date').toDate()) {
        return validateMessages.mustBeNoEarlier(moment(minDate).format('DD.MM.YYYY'));
      }
      if (value && maxDate && value > maxDate) {
        return validateMessages.mustBeNoLater(moment(maxDate).format('DD.MM.YYYY'));
      }
      if (externalValidate) {
        return externalValidate(value);
      }
    },
    [required, externalValidate, minDateFormatted, maxDateFormatted],
  );

  const { field, form } = useField({ name, defaultValue, validate });

  if (!field && !form) {
    return null;
  }

  const isDisabled = disabled || form.disabled || form.progress;

  return (
    <PreloadField
      marginLeft={5}
      height={20}
      width={135}
      isLoading={form.initializing}
      display="table-cell"
    >
      <FieldInput
        isFocus={false}
        name={name}
        label={label}
        error={field.error}
        warning={field.warning}
        value={field.value}
        hasOutline={hasOutline}
        isDisabled={isDisabled}
      >
        <DatePicker
          value={format.date(field.value as Date)}
          onChange={e => onDateChange(e, field.onChange)}
          onBlur={field.onBlur}
          disabled={isDisabled}
          disabledDays={disabledDays}
          isEditable={isEditable}
          clearable={clearable}
          placeholder={placeholder}
          minDate={minDate}
          maxDate={maxDate}
          initialDate={initialDate}
          pipConfig={pipConfig}
        />
      </FieldInput>
    </PreloadField>
  );
};
