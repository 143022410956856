import React from 'react';
import { Col, Row } from 'react-grid';

import { NeedSignDocumentIcon, RejectedDocumentIcon, SignedDocumentIcon } from 'components/icons';
import './SignatoryRow.scss';

export enum SignFlag {
  Signed = 'Signed',
  NeedSign = 'NeedSign',
  Rejected = 'Rejected',
}

interface SignatoryRowProps {
  name: string;
  signFlag: SignFlag;
  branch?: string;
  role?: string;
}

const IconOfSignatory: Record<SignFlag, React.ReactNode> = {
  [SignFlag.Signed]: SignedDocumentIcon(),
  [SignFlag.NeedSign]: NeedSignDocumentIcon(),
  [SignFlag.Rejected]: RejectedDocumentIcon(),
};

export const SignatoryRow = ({ name, signFlag, role, branch }: SignatoryRowProps) => (
  <Row className="row-container">
    <Col sm={4} className="col-container">
      {name}
    </Col>
    <Col sm={1}>{IconOfSignatory[signFlag]}</Col>
    <Col sm={3} className="col-container">
      {role}
    </Col>
    <Col sm={4} className="col-container">
      {branch}
    </Col>
  </Row>
);
