import { get } from './backend';

export type Banner = {
  category: string;
  code: string;
  deleted: boolean;
  description: string;
  descriptionTranslation: string;
  id: number;
  label: string;
};

export type ServerTimestamp = {
  offsetSeconds: number;
  serverTime: string;
  timestamp: number;
  utcTime: string;
};

export class CommonService {
  async getBanner(): Promise<Banner[]> {
    return await get('/codes/by/Banner_main');
  }

  async getServerTime(): Promise<ServerTimestamp> {
    return await get('/v1/common/time');
  }

  downloadData(filename: string, data: string | Blob): void {
    const file = data instanceof Blob ? data : new Blob([data]);
    if ((window.navigator as Obj).msSaveOrOpenBlob) {
      // IE10+
      (window.navigator as Obj).msSaveOrOpenBlob(file, filename);
    } else {
      // Others
      const a = document.createElement('a');
      const url = URL.createObjectURL(file);

      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  }
}
