import * as React from 'react';

import { Button } from 'components/buttons/Button';
import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import { translate } from 'i18n/translator';

export type AmountModalProps = { amount: number };

const SalaryAmountModal: React.FC<PropsWithConfirmModal<AmountModalProps>> = props => {
  const { resolve, amount } = props;

  const { handleSubmit } = useForm<AmountModalProps>(async ({ setFields }) =>
    setFields({ amount }),
  );
  const onSave = ({ amount }: AmountModalProps) => resolve(amount);

  return (
    <DefaultForm>
      <AmountField
        name="amount"
        label="front.employees-actions.set-salary.label"
        minAmount={0.01}
        maxLengthWithoutZero={12}
        hasMoneyFormat
        thousandSeparator={' '}
        autoFocus
        required
      />
      <Button type="submit" onClick={e => handleSubmit(onSave, e)} block>
        {translate('front.table.filter-footer-apply.label')}
      </Button>
    </DefaultForm>
  );
};

export const AmountModal = withForm(SalaryAmountModal);
