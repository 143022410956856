export enum SalaryNames {
  Customer = 'customer',
  SalaryDeal = 'salaryDeal',
  SalaryType = 'salaryType',
  SalaryPeriod = 'salaryPeriod',
  SalaryPeriodEnd = 'salaryPeriodEnd',
  SalaryTotal = 'salaryTotal',
  SalaryFee = 'salaryFee',
  MakePayments = 'makePayments',
  AccountTotal = 'accountTotal',
  AccountFee = 'accountFee',
}
