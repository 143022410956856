import * as React from 'react';
import { TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { OrderType } from 'api/DemandsService/enums';
import {
  DepositActions,
  DepositProduct,
  DepositView,
  getStatusColor,
  isActionAvailable,
  ProductStatus,
} from 'api/ProductsService';
import { GridRequest, Option, SortOrder, TotalInterface } from 'api/Service';
import * as Icon from 'components/icons';
import { Page } from 'components/layout/Page/Page';
import { Status } from 'components/layout/Status';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import * as format from 'components/utils/format';
import { translate } from 'i18n/translator';
import { isActionAllowed } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { NEW } from 'navigations/routes';
import { ProductLogo } from 'pages/Products/components/ProductLogo';

import { DepositDetails, ParamType } from './DepositDetails';
import { DepositFilterFields, DepositsFilter } from './DepositsFilter';

import './styles.scss';

const tabLabel = {
  all: 'front.product-accounts-filter.statuses-all.label',
  [ProductStatus.Active]: 'front.product-accounts-filter.statuses-opened.label',
  [ProductStatus.Closed]: 'front.product-accounts-filter.statuses-closed.label',
  // [AccountStatus.Blocked]: translate('front.product-accounts-filter.statuses-blocked.label'),
};

const actionPrivileges: { [key in DepositActions]: Privileges[] } = {
  [DepositActions.STATEMENT]: [Privileges.financeStatementView],
  [DepositActions.HISTORY]: [Privileges.financeTransactionHistoryView],
  [DepositActions.TRANSFER]: [Privileges.paymentInternalEdit],
};

const isActionVisible = (product: DepositProduct, action: DepositActions) => {
  const { customerId, actions } = product;
  return (
    isActionAvailable<DepositActions>(actions, action) &&
    getMainAccountId(product) &&
    isActionAllowed(actionPrivileges[action], customerId)
  );
};

const getStatusesOptions = (total: TotalInterface): Array<Option> =>
  Object.entries(tabLabel).map(([value, label]) => ({
    value,
    label: (
      <span className="deposits-filter__tab">
        {translate(label)}
        <span className="deposits-filter__tab__badge">
          {value === 'all'
            ? Object.values(total.status).reduce((a, b) => a + b, 0)
            : total.status[value.toUpperCase()] || 0}
        </span>
      </span>
    ),
  }));

const columns: TableColumnProps<DepositView>[] = [
  {
    label: 'deposit-logo',
    render: ({ product }) => (
      <DataColumn>
        <ProductLogo type={product.subTypeName} currency={product.currency} />
      </DataColumn>
    ),
    sort: 'subType',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'deposit-edit',
    render: ({ product }) => (
      <DataColumn>
        {isActionAllowed([Privileges.productDepositEdit], product.customerId) && (
          <span
            onClick={e => {
              e.stopPropagation();
              goto(pages.deposit(product.id));
            }}
            className="deposit-name-edit"
          >
            <Icon.Edit />
          </span>
        )}
        <span className="deposit-name">{product.alias || product.subTypeName}</span>
      </DataColumn>
    ),
    sort: 'subTypeName',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'deposit-organization-name',
    render: ({ product }) => <DataColumn title={product.organization} />,
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'deposit-code',
    render: ({ product }) => <DataColumn title={product.code} />,
    sort: 'code',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'deposit-close-date',
    render: ({ product }) => (
      <DataColumn
        titleClassName="deposit-date"
        subTitleClassName="deposit-date"
        title={translate('front.deposits-page.close-date.label')}
        subTitle={format.date(product.closed)}
      />
    ),
    sort: 'сlosed',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'deposit-status',
    render: ({ product }) => (
      <DataColumn>
        <Status color={getStatusColor(product.status as ProductStatus)}>
          {product.statusName}
        </Status>
      </DataColumn>
    ),
    sort: 'status',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'deposit-amount',
    render: ({ product }) => {
      const amount = format.getAmount(Number(product.amount));
      return (
        <DataColumn
          titleClassName="deposit-amount-label"
          subTitleClassName="deposit-amount"
          title={translate('front.deposits-page.available-limit.label')}
          subTitle={`${amount} ${product.currency}`}
        />
      );
    },
    sort: 'amount',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    renderMenu: ({ product }) => {
      const accountId = getMainAccountId(product);
      return [
        {
          label: 'front.menu.statements.label',
          onClick: () => goto(pages.statements({ accountId })),
          isVisible: isActionVisible(product, DepositActions.STATEMENT),
        },
        {
          label: 'front.product-accounts-page.menu-history.label',
          onClick: () => goto(pages.transactionsHistory({ accountId })),
          isVisible: isActionVisible(product, DepositActions.HISTORY),
        },
        {
          label: 'front.menu.replenish-deposit.label',
          onClick: () =>
            goto(
              pages.internalPayments.tabs.details(NEW, {
                receiverAccount: `${accountId}`,
                customerId: +product.customerId,
                type: OrderType.InternalTransfer,
              }),
            ),
          isVisible: isActionVisible(product, DepositActions.TRANSFER),
        },
      ];
    },
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

const getMainAccountId = ({ params }: DepositProduct) =>
  params.find(({ type }) => type === ParamType.MAIN || type === ParamType.MAIN_BASE)
    ?.linkedProductId;

const fetchDeposits = async (request: GridRequest & DepositFilterFields) => {
  const { subType, ...otherRequest } = request;
  const status = request?.status !== 'all' ? request.status.toUpperCase() : undefined;
  const { rows, total } = await api.products.getDeposits({
    ...otherRequest,
    status,
    subTypes: [subType],
    sort: 'displayOrder',
    size: 0,
  });
  return {
    rows,
    total,
    tabOptions: getStatusesOptions(total),
  };
};

export const DepositsPage = () => (
  <div className="deposits-page">
    <Page title={translate('front.deposits-page.title.label')}>
      <DataTable
        name={TableNamesEnum.DepositsTable}
        columns={columns}
        fetchRows={fetchDeposits}
        defaultSort="displayOrder"
        defaultOrder={SortOrder.Desc}
        filterComponent={DepositsFilter}
        expandRowComponent={DepositDetails}
        hidePagination
        hideHeader
      />
    </Page>
  </div>
);
