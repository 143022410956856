import React from 'react';

import { toAmountFormatWithComma, toAmountFormatWithDot } from 'components/utils/format';
import { validateMessages } from 'components/validateMessages';
import { translate } from 'i18n/translator';
import { isExist } from 'utils/isData';

import { AmountProps } from './types';
import { getFormattedValue } from './utils';

export const defaultMaskConfig = {
  decimalScale: 2,
  allowedDecimalSeparators: [',', '.'],
  decimalSeparator: ',',
};

export const useAmountField = ({
  label,
  thousandSeparator,
  required,
  minAmount,
  isoCode,
  maxAmount,
  minLength,
  maxLength,
  maxLengthWithoutZero,
  validate: externalValidate,
  autoFocus = false,
  onFocus,
  hasMoneyFormat,
  maskConfig: customMaskConfig,
}: AmountProps) => {
  const [isFocus, setFocus] = React.useState<boolean>(autoFocus);

  const toggleFocus = (isFocus: boolean) => setFocus(isFocus);
  const handleFocus = () => {
    onFocus?.();
    toggleFocus(true);
  };

  const handleBlur = () => toggleFocus(false);

  const validate = React.useCallback(
    (value, values?) => {
      if (required && !isExist(value)) {
        return translate(validateMessages.fieldRequired);
      }

      if (isExist(value)) {
        const numberValue = toAmountFormatWithDot(value);
        const formattedValue = thousandSeparator ? +getFormattedValue(numberValue) : +numberValue;

        if (minAmount && formattedValue < minAmount) {
          const minAmountFixed = toAmountFormatWithComma(minAmount);
          return validateMessages.min(minAmountFixed, isoCode || '', label);
        }

        if (maxAmount && formattedValue > maxAmount) {
          return validateMessages.max(toAmountFormatWithComma(maxAmount), isoCode || '');
        }
        const stringValue = `${numberValue}`;

        if (minLength && stringValue.length < minLength) {
          return translate(validateMessages.lessThan(minLength));
        }

        if (maxLength && stringValue.length > maxLength) {
          return translate(validateMessages.moreThan(maxLength));
        }

        if (maxLengthWithoutZero && numberValue) {
          const valueWithoutZero = stringValue.replace(/\.(.*)/, '');
          if (valueWithoutZero.length > maxLengthWithoutZero) {
            return validateMessages.maxLengthWithoutZero(maxLengthWithoutZero);
          }
        }

        return externalValidate ? translate(externalValidate(numberValue, values)) : null;
      }
    },
    [required, minAmount, maxAmount, minLength, maxLength, isoCode, externalValidate],
  );

  const maskConfig = React.useMemo(() => {
    if (hasMoneyFormat) {
      return {
        ...defaultMaskConfig,
        fixedDecimalScale: hasMoneyFormat,
        thousandSeparator: thousandSeparator,
      };
    }

    if (thousandSeparator) {
      return { thousandSeparator: thousandSeparator };
    }

    return customMaskConfig ? customMaskConfig : defaultMaskConfig;
  }, [hasMoneyFormat, customMaskConfig, thousandSeparator]);

  return { validate, handleFocus, handleBlur, isFocus, maskConfig };
};
