import * as React from 'react';
import { useSelector } from 'react-redux';

import { Option } from 'api/Service';
import { SelectField, SelectProps } from 'components/forms/inputs/SelectField';
import { Privileges } from 'navigations/privileges';
import { ExpandedCustomer } from 'pages/Login/CustomerSelectModal/types';
import {
  selectChosenPersonOptions,
  selectChosenCustomersId,
  selectDefaultCustomerId,
} from 'store/selectors';

interface CustomerSelectProps extends SelectProps {
  filterCustomers?: (c: Option<ExpandedCustomer>) => boolean;
  privilege?: Privileges;
  selectAll?: boolean;
  selectDefaultCustomer?: boolean;
}

export const CustomerSelect = ({
  label,
  name,
  onSelectOption,
  selectAll,
  filterCustomers,
  defaultValue,
  selectDefaultCustomer,
  privilege,
  disabled,
  multi,
  onBlur,
  ...rest
}: Partial<CustomerSelectProps>) => {
  let chosenPersons = useSelector(selectChosenPersonOptions(privilege));
  const chosenCustomersIds = useSelector(selectChosenCustomersId()).map(id => `${id}`);
  const defaultCustomerId = `${useSelector(selectDefaultCustomerId(privilege))}`;

  if (filterCustomers) {
    chosenPersons = chosenPersons.filter(filterCustomers);
  }
  let newDefaultValue = defaultValue;

  if (selectDefaultCustomer) {
    newDefaultValue = defaultCustomerId;
  } else if (chosenPersons.length === 1) {
    const customerId = chosenPersons[0].value;

    newDefaultValue = multi ? [customerId] : customerId;
  }

  if (selectAll && multi) {
    newDefaultValue = chosenCustomersIds;
  }

  return (
    <SelectField
      label={label}
      name={name}
      options={chosenPersons}
      defaultValue={newDefaultValue}
      disabled={disabled || !chosenPersons || chosenPersons.length < 2}
      onSelectOption={onSelectOption}
      multi={multi}
      onBlur={onBlur}
      {...rest}
    />
  );
};
