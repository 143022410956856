import * as moment from 'moment';

import { ServerTimestamp } from 'api/CommonService';
import store from 'store';
import { ApplicationInitActions } from 'store/actions/applicationInit';
import { CustomError } from 'utils/customError';

interface ServerTimeInterface {
  getTime(): number;

  initTime(syncFn: () => Promise<ServerTimestamp>): void;

  synchronizeSeverTime(): void;
}

class ServerTime implements ServerTimeInterface {
  private isLoading: boolean;
  private timeSyncFn: () => Promise<ServerTimestamp>;
  private serverOffsetSeconds: number = null;
  private clientSyncTime: number = null;
  private serverTime: number = null;

  synchronizeSeverTime = async () => {
    try {
      const response = await this.timeSyncFn();
      this.serverTime = response.timestamp;
      this.serverOffsetSeconds = response.offsetSeconds;
      this.clientSyncTime = Date.now();
    } catch (e) {
      console.warn(`Time synchronization failed: ${e}`);
    }
  };

  initTime = async (timeSyncFn: () => Promise<ServerTimestamp>): Promise<void> => {
    if (!timeSyncFn) {
      throw new CustomError('Time synchronization function not provided');
    }
    this.isLoading = true;
    this.timeSyncFn = timeSyncFn;

    await this.synchronizeSeverTime();

    this.isLoading = false;

    store.dispatch(ApplicationInitActions.setIsServerTimeInit(true));
  };

  getTime = (): number => {
    if (this.isLoading) {
      return Date.now();
    }

    const localTimestamp = Date.now();
    const currentServerTime = this.serverTime + (localTimestamp - this.clientSyncTime);

    const clientOffsetSeconds = new Date().getTimezoneOffset() * 60 * -1;

    const diffOffsetMilliseconds = (clientOffsetSeconds - this.serverOffsetSeconds) * 1000;

    return currentServerTime - diffOffsetMilliseconds;
  };
}

export const ServerTimeInstance = new ServerTime();

// override moment.js time supplier
Object.assign(moment, { now: ServerTimeInstance.getTime });
