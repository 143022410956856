import * as React from 'react';
import { Action, TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { PaymentPurposesGrid } from 'api/HandbooksService';
import { SortOrder } from 'api/Service';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';

import { PaymentPurposePageFilter } from './PaymentPurposePageFilter';

const columns: TableColumnProps<PaymentPurposesGrid>[] = [
  {
    label: 'front.payment-purpose-table.purpose.label',
    render: row => <DataColumn title={row.purpose} />,
    sort: 'purpose',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    renderMenu: item => [
      {
        label: 'front.mails-table-actions.view.label',
        onClick: () => goto(pages.handbooks.paymentPurpose(item.id)),
      },
      {
        label: 'front.mails-table-actions.delete.label',
        onClick: async () => await api.handbooks.deletePaymentPurpose(item.id),
        isReloadable: true,
        confirmMessage: 'front.payment-purpose-table.confirm-delete.label.label',
      },
    ],
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

const actions: Action[] = [
  {
    label: 'front.working-documents-table.actions-delete.label',
    action: async (selected: any[]) => {
      const ids = selected.map(item => item.id);
      await api.handbooks.deletePaymentPurposes(ids);
    },
    isReloadable: true,
    confirmMessage: 'front.payment-purpose-table.confirm-delete.label.label',
  },
];

export const PaymentPurposesPage = () => (
  <DataTable
    name={TableNamesEnum.PaymentPurposeTable}
    columns={columns}
    fetchRows={api.handbooks.getPaymentPurposes}
    actions={actions}
    defaultSort="id"
    defaultOrder={SortOrder.Desc}
    filterComponent={PaymentPurposePageFilter}
  />
);
