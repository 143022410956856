import React from 'react';
import { Col, Container, Row } from 'react-grid';
import reactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';

import moment from 'moment/moment';

import { KeyType } from 'api/CertificateService/enums';
import { Customer } from 'api/UserService';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { PasswordField } from 'components/forms/inputs/PasswordField';
import { RadioField } from 'components/forms/inputs/RadioField';
import { Effects, useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { PageHeader } from 'components/layout/Page/PageHeader';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { translate } from 'i18n/translator';
import { Privileges } from 'navigations/privileges';
import { CertificateControls } from 'pages/Profile/Certificates/CertificateEngine/CertificateControls';
import { Fields } from 'pages/Profile/Certificates/CertificateEngine/types';
import { CertificateFieldNames, KeyKepType } from 'pages/Profile/Certificates/KepCertificate/enums';
import { CertificateContext } from 'pages/Profile/Certificates/KepCertificate/KepCertificateContext';
import {
  ConfigType,
  KepFilterCert,
  Payload,
} from 'pages/Profile/Certificates/KepCertificate/types';
import { useMode } from 'pages/Profile/Certificates/KepCertificate/useCertificate';
import { NotAllowed } from 'pages/Profile/Certificates/Modals/NotAllowed';
import { validatePasswordWithException } from 'pages/Profile/Certificates/PasswordValidator';
import { selectChosenCustomers, selectCustomers, selectDefaultCustomerId } from 'store/selectors';

import { allowedForCustomer, getAllowedCustomerKep, getCustomer } from './utils';

import './styles.scss';

export function withKepCertificate<PP>(config: ConfigType<PP>) {
  return (CertificateDetail: React.ElementType): React.FC<PP> => {
    const CertificateComponent = (props: React.PropsWithChildren<any>) => {
      const [isSearchTokens, setIsSearchTokens] = React.useState<boolean>(false);
      const [expiredError, setExpiredError] = React.useState<string>(null);

      const customerRef = React.useRef<Customer>(null);
      const setCustomer = (customer: Customer) => (customerRef.current = customer);

      const { updateMode, currentMode } = useMode();

      const {
        title,
        createFetchCerts,
        labelButtonOnSave,
        allowCancel,
        createOnSave,
        createFilteringCustomers,
        validatePasswordError = '',
        allowedMultikey = false,
        fetchOnMount,
        filteringParams = {},
        customerPrivilege = '' as Privileges,
        isCreate = false,
        isGettingCertificate = false,
        labelPasswordCode = 'front.cert-page-add.input-password.label',
        labelPukCode = 'front.cert-page-add.input-puk-code.label',
      } = config;

      const defaultCustomerId = useSelector(selectDefaultCustomerId(customerPrivilege));
      const selectedCustomers = useSelector(selectChosenCustomers());
      const customers = useSelector(selectCustomers());

      const getInitialCustomer = () => {
        let customers = selectedCustomers;
        let customerId = defaultCustomerId;
        if (props.customerIds) {
          customerId = props.customerIds[0];
          customers = customers.filter(c => props.customerIds.includes(c.id));
        }

        const customer = getAllowedCustomerKep(customers, customerId, isCreate);
        setCustomer(customer);
        return customer;
      };

      const fetchInitialCertificate = async (formActions: Effects<Fields, Payload>) => {
        const customer = getInitialCustomer();

        customer &&
          (await fetchOnMount({ customer, props }, { ...formActions, updateMode, fetchData }));
      };

      const {
        updateData,
        payload,
        setPayload,
        handleSubmit,
        setInitialingCb,
        setError,
        getFieldValue,
        progress,
        error,
      } = useForm<Fields, Payload>(fetchInitialCertificate);

      const createFilter = (filter: KepFilterCert = {}): Partial<KepFilterCert> => {
        const newFilter: Partial<KepFilterCert> = {
          allowedMultikey:
            filter.allowedMultikey && allowedMultikey && customerRef.current.allowedForUserCert,
          customerIds: customerRef.current.id
            ? [customerRef.current.id]
            : filter?.customerIds || [],
          existingInSystem: filteringParams.existingInSystem,
          certificateTypes: filter?.certificateTypes,
        };

        if (filteringParams.edrpou) {
          newFilter['edrpou'] = filter?.edrpou ?? customerRef.current.taxCode;
        }

        return newFilter;
      };

      const fetchData = createFetchCerts({
        updateData,
        setPayload,
        setError,
        createFilter,
      });

      const onChangeCustomer = setInitialingCb(async (customerId: string) => {
        const customer = getCustomer(Number(customerId), customers);
        setError(null);
        setCustomer(customer);

        const currentMode = updateMode(customer.config);
        await fetchData(currentMode, createFilter(payload.fetchFilter));
      });

      const onSearchToken = async () => {
        setIsSearchTokens(true);
        setError(null);
        await fetchData(currentMode, createFilter(payload.fetchFilter), true);
        setIsSearchTokens(false);
      };

      const edsKey = getFieldValue(CertificateFieldNames.EdsKey);

      React.useEffect(() => {
        const option = (payload?.edsKeyOptions || []).find(item => item.value === edsKey);
        const days = option && moment(option.content.validTo).diff(moment(), 'days');

        if (option && days < 30) {
          const expiredError = `${translate(
            'front.certificates.expired-error.label',
          )} ${days} ${translate('front.certificates.expired-days.label')}`;

          setExpiredError(expiredError);
        } else {
          setExpiredError(null);
        }
      }, [edsKey]);

      const validatePassword = () => {
        const pukCodeValue = getFieldValue(CertificateFieldNames.PukCode) as string;

        validatePasswordWithException(
          getFieldValue(CertificateFieldNames.Password) as string,
          validatePasswordError,
          'front.form.pin-code.error',
        );

        pukCodeValue &&
          validatePasswordWithException(pukCodeValue, '', 'front.form.puk-code.error');
      };

      const createdOnSave =
        createOnSave &&
        createOnSave(validatePassword, {
          customer: customerRef.current,
          customers,
          props,
          payload,
        });

      const onSave = (e: any) => handleSubmit(createdOnSave, e);

      const filteringCustomers =
        createFilteringCustomers && createFilteringCustomers(props.customerIds);

      const onSelectMode = setInitialingCb(async (value: KeyType) => {
        setError(null);
        setExpiredError(null);
        updateData({ store: null, edsKey: null });
        await fetchData(value, createFilter(payload.fetchFilter));
      });

      const storeValue = getFieldValue(CertificateFieldNames.Store);
      const isCloudStorage = storeValue === KeyKepType.CLOUD_STORAGE;

      const isDenyForCustomer =
        (customerRef.current && !allowedForCustomer(isCreate, customerRef.current)) ?? true;

      const isDenyMode = !isCreate && !payload?.keyTypeOptions;

      if (isDenyMode || isDenyForCustomer) {
        return <NotAllowed resolve={props?.resolve} />;
      }

      const hasErrorOnCreateToken = isCreate && !isCloudStorage && error;

      return (
        <CertificateContext.Provider value={{ isSearchTokens, onSearchToken, expiredError }}>
          <PageHeader.Title>
            <div className="tooltipWrap">
              {translate(title)}
              {hasErrorOnCreateToken && (
                <Tooltip tooltip={translate('front.cert-page.create-kep-tooltip.label')} />
              )}
            </div>
          </PageHeader.Title>
          <DefaultForm disabled={progress}>
            {error && <FormError>{reactHtmlParser(error)}</FormError>}
            {!isCreate && (
              <RadioField
                name={CertificateFieldNames.Mode}
                data-testid={CertificateFieldNames.Mode}
                options={payload?.keyTypeOptions}
                cssType="flex"
                onChange={onSelectMode}
                required
              />
            )}
            <Container>
              <Row>
                <Col>
                  <CustomerSelect
                    name={CertificateFieldNames.CustomerId}
                    label="front.cert-page-new.input-customer-id.label"
                    onSelectOption={onChangeCustomer}
                    filterCustomers={filteringCustomers}
                    privilege={customerPrivilege}
                    selectDefaultCustomer
                    isSearchable={false}
                    disabled={isGettingCertificate}
                    required
                  />
                </Col>

                <CertificateDetail />
              </Row>
              <Row>
                <Col>
                  <PasswordField
                    label={labelPasswordCode}
                    name={CertificateFieldNames.Password}
                    minLength={6}
                    maxLength={isCreate ? 8 : 20}
                    required
                  />
                </Col>
                {isCloudStorage && (
                  <Col>
                    <PasswordField
                      label={labelPukCode}
                      name={CertificateFieldNames.PukCode}
                      minLength={6}
                      maxLength={8}
                      required
                    />
                  </Col>
                )}
                <CertificateControls
                  onSave={onSave}
                  progress={progress}
                  resolve={props.resolve}
                  allowCancel={allowCancel}
                  labelButtonOnSave={labelButtonOnSave}
                />
              </Row>
            </Container>
          </DefaultForm>
        </CertificateContext.Provider>
      );
    };

    return withForm(CertificateComponent);
  };
}
