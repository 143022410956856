import * as React from 'react';

import { Page } from 'components/layout/Page/Page';
import { NavTabs, NavTab } from 'components/tabs/NavTabs';
import { translate } from 'i18n/translator';
import { pages } from 'navigations/pages';

import { AnnouncementsList } from './AnnouncementsList';

import './styles.scss';

export const AnnouncementsPage: React.FC = () => {
  return (
    <Page title={translate('front.menu.announcements.label')}>
      <NavTabs className="tabs-padding">
        <NavTab
          title={translate('front.ads.tab-advertisement.label')}
          path={pages.announcements.tabs.current}
        >
          <AnnouncementsList />
        </NavTab>
        <NavTab
          title={translate('front.ads.tab-ads-history.label')}
          path={pages.announcements.tabs.history}
        >
          <AnnouncementsList />
        </NavTab>
      </NavTabs>
    </Page>
  );
};
