import { translate } from 'i18n/translator';
import { CustomError } from 'utils/customError';

const symbolsPattern = /^[a-zA-Z0-9!"#$%&'()*+,\-.\/:;<=>?@\[\]^_`{|}~\\]+$/;
const requiredPattern = /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

const NUMS = '01234567890';
const NUMS_REV = NUMS.split('').reverse().join('');
const MAX_SEQ_LENGTH = 4;

export const validatePasswordWithException = (
  pass: string,
  customError?: string,
  fieldLabel?: string,
) => {
  validateSymbols(pass, customError, fieldLabel);
  validateSequence(pass, customError, fieldLabel);
  validateRequired(pass, customError, fieldLabel);
};

const dispatchError = (err: string, fieldLabel?: string) => {
  throw new CustomError(`${translate(fieldLabel) || ''} ${translate(err)}`);
};

const validateSymbols = (pass: string, customError?: string, fieldLabel?: string) => {
  if (!symbolsPattern.test(pass)) {
    if (customError) {
      dispatchError(customError);
    }

    dispatchError('front.pass-validation.symbols-error.label', fieldLabel);
  }
};

const validateSequence = (pass: string, customError?: string, fieldLabel?: string) => {
  const passLength = pass.length;
  if (!passLength) return;

  for (let i = 0; i < passLength - MAX_SEQ_LENGTH + 1; i += 1) {
    const sl = pass.slice(i, i + MAX_SEQ_LENGTH);
    if (NUMS.indexOf(sl) !== -1 || NUMS_REV.indexOf(sl) !== -1) {
      if (customError) {
        dispatchError(customError);
      }

      dispatchError('front.pass-validation.sequence-pattern.label', fieldLabel);
    }
  }

  // Validating same symbol occurense
  let prev = pass[0];
  let seqCount = 1;
  for (const c of pass.slice(1)) {
    if (c === prev) {
      seqCount += 1;
      if (seqCount >= MAX_SEQ_LENGTH) {
        if (customError) {
          dispatchError(customError);
        }

        dispatchError('front.pass-validation.sequence-pattern.label', fieldLabel);
      }
    } else {
      prev = c;
      seqCount = 1;
    }
  }
};

const validateRequired = (pass: string, customError?: string, fieldLabel?: string) => {
  if (!requiredPattern.test(pass)) {
    if (customError) {
      dispatchError(customError);
    }

    dispatchError('front.pass-validation.required-pattern-error.label', fieldLabel);
  }
};

export const validateMatchingPassword = (prevPass: string, newPass: string, isKep = false) => {
  if (prevPass === newPass) {
    dispatchError(
      translate(
        isKep
          ? 'front.pass-validation.matching-kep-password-error.label'
          : 'front.pass-validation.matching-password-error.label',
      ),
    );
  }
};
