import * as React from 'react';
import { Col, Row } from 'react-grid';

import { isCipherAvailable } from 'api/CertificateService/CipherModule/CipherService';
import { CertificateStatus, CertificateType, UserKeystoreType } from 'api/CertificateService/enums';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { SelectField } from 'components/forms/inputs/SelectField';
import { withForm } from 'components/forms/withForm';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { CreateButton } from 'components/Table/CreateButton';
import { useFilter } from 'components/Table/DataTable';
import { ToggleFilterButton } from 'components/Table/ToggleFilterButton/ToggleFilterButton';
import { translate } from 'i18n/translator';
import { DisabledBlock, hasPrivileges } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { NoConnectionModal } from 'pages/Profile/Certificates/Modals/NoConnectionModal';
import { parseStatusToLocalization } from 'pages/Profile/Certificates/utils';

const statusOptions = [
  {
    label: parseStatusToLocalization(CertificateStatus.Active),
    value: CertificateStatus.Active,
  },
  {
    label: parseStatusToLocalization(CertificateStatus.Certified),
    value: CertificateStatus.Certified,
  },
  { label: parseStatusToLocalization(CertificateStatus.Blocked), value: CertificateStatus.Blocked },
  {
    label: parseStatusToLocalization(CertificateStatus.NotValid),
    value: CertificateStatus.NotValid,
  },
];

const keyTypesOption = [
  {
    label: translate('front.cert-page.col-key-type-cloud.label'),
    value: UserKeystoreType.CloudStorage,
  },
  {
    label: translate('front.cert-page.col-key-type-file.label'),
    value: UserKeystoreType.FileStorage,
  },
  {
    label: translate('front.cert-page.col-key-type-token.label'),
    value: UserKeystoreType.HardWired,
  },
  {
    label: translate('front.certificate-confirmation.e-seal.label'),
    value: CertificateType.Seal,
  },
];

const CertificatesFilterForm: React.FC = () => {
  useFilter();

  return (
    <Row>
      <Col md={7}>
        <CreateButton
          title="front.cert-page.action-create-key.label"
          onClick={() => goto(pages.profile.createCertificate)}
          disabled={hasPrivileges([Privileges.keysAddNew], false)}
        />
        <CreateButton
          title="front.cert-page.action-connect-the-existing-key.label"
          onClick={async () => {
            if (await isCipherAvailable()) {
              goto(pages.profile.addCertificate);
            } else {
              await confirmModal(NoConnectionModal);
            }
          }}
          disabled={hasPrivileges([Privileges.keysAddExisted], false)}
        />
        {hasPrivileges([Privileges.sealKeysAddNew], true) && (
          <CreateButton
            title="front.cert-page.action-create-e-seal.label"
            onClick={() => goto(pages.profile.CreateESeal)}
          />
        )}
      </Col>
      <Col md={5} hAlign={'end'}>
        <DisabledBlock
          privileges={[Privileges.keysAddNew, Privileges.keysReissue, Privileges.keysDelete]}
        >
          <ToggleFilterButton>
            <Row>
              <Col md={6}>
                <CustomerSelect
                  label="front.certificates.filter-customer.label"
                  name="customerIds"
                />
              </Col>
              <Col md={6}>
                <SelectField
                  label="front.certificates.filter-status.label"
                  name="status"
                  options={statusOptions}
                />
              </Col>
              <Col md={6}>
                <SelectField
                  label="front.certificates.filter-key-type.label"
                  name="keyType"
                  options={keyTypesOption}
                />
              </Col>
            </Row>
          </ToggleFilterButton>
        </DisabledBlock>
      </Col>
    </Row>
  );
};

export const CertificatesFilter = withForm(CertificatesFilterForm);
