export enum CurrencyExchangeFilterEnum {
  OrderNumber = 'orderNumber',
  DateFrom = 'dateFrom',
  DateTo = 'dateTo',
  AmountFrom = 'amountFrom',
  AmountTo = 'amountTo',
  Currency = 'currency',
}

export enum CurrencyExchangeDetailsName {
  Customer = 'customer',
  AccountUAH = 'accountUAH',
  AccountNonUAH = 'accountNonUAH',
  IsoCode = 'isoCode',
  ReceiverCurrency = 'receiverCurrency',
  RateType = 'rateType',
  CurrencyRate = 'currencyRate',
  Agent = 'agent',
  Attach = 'attach',
  Phone = 'phone',
  Amount = 'amount',
  ReceiverAmount = 'receiverAmount',
  AmountBuy = 'amountBuy',
  AmountSale = 'amountSale',
  Purpose = 'purpose',
  DoExternalFeeAcc = 'doExternalFeeAcc',
  ExternalFeeAccName = 'externalFeeAccName',
  ExternalFeeAccCode = 'externalFeeAccCode',
  ExternalFeeAccNumber = 'externalFeeAccNumber',
  AccountSale = 'accountSale',
  AccountBuy = 'accountBuy',
  AccountFee = 'accountFee',
  IsBuyAmount = 'isBuyAmount',
}
