import React from 'react';

import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { useDisabledCustomer } from 'hooks/useDisabledCustomer';
import { CardFieldNames } from 'pages/Demands/CardDemand/enums';
import { CardPayload } from 'pages/Demands/CardDemand/interfaces';

interface CardReissueFields {
  [CardFieldNames.Customer]: string;
  [CardFieldNames.Card]: string;
  [CardFieldNames.ReissueReason]: string;
  [CardFieldNames.DocumentNotChanged]: string;
}

export const CardReissueDetail = () => {
  const {
    order,
    payload: { card, reissueReason },
    onChangeCustomer,
  } = useOrderDetails<CardReissueFields, CardPayload>();

  const isDisabledCustomer = useDisabledCustomer(order);

  return (
    <DefaultForm>
      <CustomerSelect
        name={CardFieldNames.Customer}
        label="front.card-edit.organization.label"
        onSelectOption={onChangeCustomer}
        selectDefaultCustomer
        disabled={isDisabledCustomer}
        required
      />
      <SelectField
        name={CardFieldNames.Card}
        label="front.card-edit.card.label"
        options={card}
        required
      />
      <SelectField
        name={CardFieldNames.ReissueReason}
        label="front.card-edit.reissueReason.label"
        options={reissueReason}
        required
      />
      <CheckboxField
        name={CardFieldNames.DocumentNotChanged}
        label="front.card-edit.documentNotChanged.label"
        required
      />
    </DefaultForm>
  );
};
