import { Devices } from 'enums/DevicesEnum';
import moment from 'moment';

import { logout } from 'api/backend';
import { BooleanBuilder } from 'api/Service';
import { Customer } from 'api/UserService';
import { showExpiredCustomersModal } from 'components/TermExpirationModal/TermExpirationModal';
import { getEndDateCustomers } from 'components/TermExpirationModal/utils';
import config from 'config';
import { translate } from 'i18n/translator';
import { LOCAL_STORAGE_KEY, SESSION_STORAGE_KEYS } from 'store/actions/types';
import { LoginType, UserState } from 'store/reducers/user';
import { isExist } from 'utils/isData';
import { LocalStorage } from 'utils/LocalStorage';

export const setStoragesSessionInfo = (info: { appId: string; userId: string }) => {
  sessionStorage.setItem(SESSION_STORAGE_KEYS.SESSION_INFO, JSON.stringify(info));
  LocalStorage.setItem(LOCAL_STORAGE_KEY.SESSION_INFO, info);
};

export const handleClickNotShowAgain = (key: string, userId: number, newCustomers: Customer[]) => {
  const users = LocalStorage.getItem(key);
  const customers = newCustomers.reduce(
    (acc, customer) => ({
      ...acc,
      [customer.id]: customer.endDate,
    }),
    {},
  );

  const hasUsers = isExist(users);
  const hasCurrentUser = hasUsers && isExist(users[userId]);

  const currentUser = hasCurrentUser
    ? { [userId]: { ...users[userId], ...customers } }
    : { [userId]: customers };

  const newUsers = hasUsers ? { ...users, ...currentUser } : currentUser;

  LocalStorage.setItem(key, newUsers);
};

export const getUpdatedCustomers = (
  customers: Customer[],
  rememberedCustomers: Record<string, Date>,
) =>
  customers.filter(customer => {
    const endDate = rememberedCustomers[customer.id];

    return isExist(endDate) ? moment(endDate).unix() !== moment(customer.endDate).unix() : true;
  });

export const openEndDateCustomersModals = async (
  { customers, loginType, currentAuthPerson }: UserState,
  daysToExpire: string,
) => {
  const [endSignCustomers, endAuthCustomers] = getEndDateCustomers(customers, +daysToExpire);

  if (loginType !== LoginType.AUTHORIZATION) {
    return;
  }

  const userSignExpired = LocalStorage.getItem(LOCAL_STORAGE_KEY.IS_EXPIRATION_SIGN_MODAL_HIDDEN)?.[
    currentAuthPerson
  ];

  const userAuthExpired = LocalStorage.getItem(LOCAL_STORAGE_KEY.IS_EXPIRATION_AUTH_MODAL_HIDDEN)?.[
    currentAuthPerson
  ];

  const newEndSignCustomers = isExist(userSignExpired)
    ? getUpdatedCustomers(endSignCustomers, userSignExpired)
    : endSignCustomers;

  const newEndAuthCustomers = isExist(userAuthExpired)
    ? getUpdatedCustomers(endAuthCustomers, userAuthExpired)
    : endAuthCustomers;

  const shouldRenderSignExpirationModal = newEndSignCustomers.length > 0;

  shouldRenderSignExpirationModal &&
    (await showExpiredCustomersModal(
      newEndSignCustomers,
      translate('front.modal-auth-person-term-expiring-signing-forbidden-modal.label'),
      () =>
        handleClickNotShowAgain(
          LOCAL_STORAGE_KEY.IS_EXPIRATION_SIGN_MODAL_HIDDEN,
          currentAuthPerson,
          newEndSignCustomers,
        ),
    ));

  const shouldRenderAuthExpirationModal = newEndAuthCustomers.length > 0;

  shouldRenderAuthExpirationModal &&
    (await showExpiredCustomersModal(
      newEndAuthCustomers,
      translate('front.modal-auth-person-term-access-limit-modal.label'),
      () =>
        handleClickNotShowAgain(
          LOCAL_STORAGE_KEY.IS_EXPIRATION_AUTH_MODAL_HIDDEN,
          currentAuthPerson,
          newEndAuthCustomers,
        ),
    ));
};

export const logoutOldSessionCb = (e: any) => {
  const localStorageInfo = JSON.parse(e.storageArea?.[LOCAL_STORAGE_KEY.SESSION_INFO]);

  if (localStorageInfo === null || localStorageInfo === undefined) {
    return;
  }

  const { appId, userId } = localStorageInfo;

  const sessionInfo = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.SESSION_INFO));

  const b = new BooleanBuilder(true);
  appId && b.and(appId !== sessionInfo.appId);
  userId && b.and(userId !== sessionInfo.userId);
  if (b.apply()) {
    logout(false);
  }
};

export const calculateCurrentDevice = (): Devices => {
  const windowWidth = window.innerWidth;

  if (windowWidth > config.app.screenSize.mobile && windowWidth <= config.app.screenSize.tablet) {
    return Devices.Tablet;
  }

  if (windowWidth >= config.app.screenSize.tablet) {
    return Devices.Desktop;
  }

  if (windowWidth <= config.app.screenSize.mobile) {
    return Devices.Mobile;
  }
};
