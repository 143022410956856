import React from 'react';

import { GridRequest, SortOrder } from 'api/Service';
import { useStorageTable } from 'components/react-data-table/useStorageTable';
import {
  getInitialGridRequest,
  getInitialTableConfig,
  getToggleColumn,
  TableConfig,
} from 'components/react-data-table/utils';
import { handleError } from 'utils/handleError';
import { usePrevious } from 'utils/hooks';

import { DataTableContext, initialTotal } from './DataTableContext';
import { DataTableContextValue, DataTableProps } from './types';

export const DataTable = <R,>({
  name,
  columns,
  defaultOrder = SortOrder.Desc,
  defaultSort = 'id',
  defaultPageSize = 20,
  filterComponent,
  actionsComponent,
  expandRowComponent,
  isExpandedRowComponentDisabled = () => false,
  actions,
  fetchRows,
  hideHeader,
  hidePagination,
  breakpoint,
  location,
  onRowClick,
  isRowDisabled = () => false,
  children,
  shouldUseStorageSize = false,
  maxVisibleTableRows,
  shouldUseStorageSort = false,
  shouldFetchIfChangedRoute = true,
}: React.PropsWithChildren<DataTableProps<R>>): JSX.Element => {
  const [tableConfig, setTableConfig] = React.useState<TableConfig>(() =>
    getInitialTableConfig<R>(name, columns),
  );

  const updateTableConfig = (columnKey: string | string[]) =>
    setTableConfig({ ...tableConfig, ...getToggleColumn(columnKey, tableConfig, breakpoint) });

  const [gridRequest, setGridRequest] = React.useState<GridRequest>(() =>
    getInitialGridRequest(
      name,
      defaultOrder,
      defaultSort,
      defaultPageSize,
      shouldUseStorageSort,
      shouldUseStorageSize,
      hidePagination,
    ),
  );

  useStorageTable(
    name,
    tableConfig,
    breakpoint,
    gridRequest,
    shouldUseStorageSort,
    shouldUseStorageSize,
  );

  const [selected, setSelected] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [tabOptions, setTabOptions] = React.useState([]);
  const [total, setTotal] = React.useState(initialTotal);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string>(null);
  const [filter, setFilter] = React.useState(null);

  const previousSort = usePrevious(gridRequest.sort);
  const previousOrder = usePrevious(gridRequest.order);

  const shouldUseIntegration =
    gridRequest.order === previousOrder && gridRequest.sort === previousSort;

  const locationKey = shouldFetchIfChangedRoute ? location.key : '';

  React.useEffect(() => {
    if (!filterComponent || (filterComponent && filter)) {
      fetchData();
    }
  }, [JSON.stringify(gridRequest), filter, locationKey, fetchRows]);

  React.useEffect(() => {
    updateGridRequest({ page: 0 });
  }, [total.count]);

  React.useEffect(() => {
    setTableConfig({ ...getInitialTableConfig<R>(name, columns) });
  }, [name]);

  const fetchData = async () => {
    setSelected([]);
    setIsLoading(true);
    try {
      const { rows, total, tabOptions } = await fetchRows({
        ...gridRequest,
        ...filter,
        shouldUseIntegration,
      });
      setRows(rows);
      setTotal(total as any);
      setTabOptions(tabOptions || []);
    } catch (e: any) {
      handleError(e, setError);
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const reFetchData = async () => {
    await fetchData();
  };

  const updateFilter = (partialFilter: Obj) => {
    setError(null);
    setFilter((oldFilter: Obj) => ({ ...oldFilter, ...partialFilter }));
  };

  const updateGridRequest = (gridRequest: GridRequest) => {
    setError(null);
    setGridRequest(oldGridRequest => {
      return { ...oldGridRequest, ...gridRequest };
    });
  };

  const nonDisabledRows = React.useMemo(
    () => rows.filter(row => !isRowDisabled(row)),
    [isRowDisabled, rows],
  );
  const isAllSelected = selected?.length === nonDisabledRows?.length;
  const isChecked = React.useCallback((idx: number) => selected.includes(idx), [selected]);
  const toggleAllSelected = React.useCallback(
    () => setSelected(isAllSelected ? [] : nonDisabledRows.map((r, i) => i)),
    [isAllSelected, nonDisabledRows],
  );
  const toggleSelected = React.useCallback((idx: number) => {
    setError(null);
    setSelected(oldSelected => {
      const newSelected = [...oldSelected];
      const selectedIndex = newSelected.indexOf(idx);

      if (selectedIndex >= 0) {
        newSelected.splice(selectedIndex, 1);
      } else {
        newSelected.push(idx);
      }
      return newSelected;
    });
  }, []);

  const selection = React.useMemo(
    () => ({
      isAllSelected,
      toggleSelected,
      toggleAllSelected,
      isChecked,
      selected,
      setSelected,
    }),
    [isAllSelected, isChecked, selected, toggleAllSelected, toggleSelected],
  );

  const contextValue: DataTableContextValue<R> = {
    name,
    rows,
    tabOptions,
    total,
    columns,
    actions,
    selection,
    filterComponent,
    actionsComponent,
    expandRowComponent,
    isExpandedRowComponentDisabled,
    onRowClick,
    setError,
    error,
    hideHeader,
    setIsLoading,
    isLoading,
    reFetchData,
    isRowDisabled,
    breakpoint,
    filter: { filter, updateFilter },
    gridRequest: { ...gridRequest, updateGridRequest },
    isHidePagination: hidePagination,
    maxVisibleTableRows,
    tableConfig,
    updateTableConfig,
  };

  if (!breakpoint) return null;

  return <DataTableContext.Provider value={contextValue}>{children}</DataTableContext.Provider>;
};
