import * as React from 'react';
import { Col, Row } from 'react-grid';

import { CustomerSelect } from 'components/Document/CustomerSelect';
import { withForm } from 'components/forms/withForm';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { CreateButton } from 'components/Table/CreateButton';
import { useFilter } from 'components/Table/DataTable';
import { ToggleFilterButton } from 'components/Table/ToggleFilterButton/ToggleFilterButton';
import { AllowDisplayBlock } from 'navigations/access';
import { Privileges } from 'navigations/privileges';
import { CreateRoleModal } from 'pages/AuthorizedPersons/Roles/CreateRoleModal';

export const RolesFilter = withForm(() => {
  const { reFetchData } = useFilter();

  return (
    <div>
      <Row>
        <Col md={6} sm={8}>
          <AllowDisplayBlock privileges={[Privileges.manageUsersManageRoles]}>
            <CreateButton
              title="front.working-documents.create.label"
              onClick={async () => {
                await confirmModal(CreateRoleModal);
                reFetchData();
              }}
            />
          </AllowDisplayBlock>
        </Col>
        <Col md={6} sm={4}>
          <ToggleFilterButton smallMode>
            <Row>
              <Col md={6}>
                <CustomerSelect
                  label="front.roles-page.filter-organizations.label"
                  name="customerId"
                />
              </Col>
            </Row>
          </ToggleFilterButton>
        </Col>
      </Row>
    </div>
  );
});
