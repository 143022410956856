import * as React from 'react';

import { Import } from 'components/icons';
import { PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import { translate } from 'i18n/translator';

interface Props {
  count: number;
  totalAmount: number;
}

export const ImportResultsModal: React.FC<PropsWithConfirmModal<Props>> = ({
  totalAmount,
  count,
}) => {
  const importedOrdersInfo = translate('front.import-modal-total-info.label')
    .replace('{count}', `${count}`)
    .replace('{amount}', `${totalAmount}`);

  return (
    <div>
      <div className="import-modal-header">
        <Import />
        <div className="import-modal-title">{translate('front.import-modal-title.label')}</div>
      </div>
      <div className="import-modal-info">{importedOrdersInfo}</div>
    </div>
  );
};
