import * as React from 'react';
import { Col, Container, Row } from 'react-grid';
import reactHtmlParser from 'react-html-parser';

import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { PasswordField } from 'components/forms/inputs/PasswordField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { HandleSubmitFn } from 'components/forms/ValidatingForm/FormContext';
import { translate } from 'i18n/translator';
import { LoginContextType } from 'pages/Login/LoginContextType';
import { KepSignatureLoginPayload } from 'pages/Login/Steps/SignatureLogin/interface';
import { SearchTokenButton } from 'pages/Profile/Certificates/CertificateEngine/SearchTokenButton';
import { SearchTokenLabel } from 'pages/Profile/Certificates/CertificateEngine/SearchTokenLabel';

import './SignatureLogin.scss';

interface SignatureComponentProps {
  error: string;
  goToFirstStep: (value: Pick<LoginContextType, 'goToFirstStep'>) => void;
  handleSubmit: HandleSubmitFn<any>;
  isSearchTokens: boolean;
  onSearchToken: () => void;
  payload: KepSignatureLoginPayload;
  progress: boolean;
  isCipher?: boolean;
}

export const SignatureComponent = ({
  progress,
  error,
  payload,
  onSearchToken,
  isSearchTokens,
  goToFirstStep,
  handleSubmit,
  isCipher = false,
}: SignatureComponentProps) => (
  <div className="login-page__signature-step">
    <Container>
      <div className="login-page__signature-step__title">
        <span>{translate('front.login-form.certificate.enter-token-pin.label')}</span>
      </div>
      <DefaultForm className="login-page__signature-step__form" disabled={progress}>
        <FormError className="login-page__form__server-error">{reactHtmlParser(error)}</FormError>
        <div className="signature-form-wrapper">
          <Row justifyContent="space-between">
            <Col sm={10}>
              <div className="signature-form__field">
                <SelectField
                  name="token"
                  label={translate('front.cert-page-add.input-token.select-umca-token.label')}
                  options={payload.tokenOptions}
                  isSearchable={false}
                  required
                />
              </div>
            </Col>
            <Col sm={2}>
              <div className="search-token-authorizing-step">
                <SearchTokenButton onSearchToken={onSearchToken} />
              </div>
            </Col>
            <div className="key-not-found">
              <Col sm={12}>
                <SearchTokenLabel isSearchTokens={isSearchTokens} values={payload.tokenOptions} />
              </Col>
            </div>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="signature-form__field pin-token-field ">
                <PasswordField
                  label="front.login-form.certificate.token-pin.label"
                  name="password"
                  minLength={6}
                  maxLength={isCipher ? 20 : 8}
                  required
                />
              </div>
            </Col>
          </Row>
          <div>
            <Row justifyContent="space-between">
              <Col sm={6}>
                <Button
                  color="primary"
                  onClick={e => handleSubmit(e)}
                  progress={progress}
                  className="login-page__form__btn save-btn"
                  type="submit"
                  size="sm"
                  label="front.confirm-modal.continue.label"
                />
              </Col>
              <Col sm={6}>
                <Button
                  color="secondary"
                  onClick={goToFirstStep}
                  disabled={progress}
                  className="login-page__form__btn cancel-btn"
                  size="sm"
                >
                  <span className="cancel-btn-color">
                    {translate('front.login-form.cancel-button.label')}
                  </span>
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </DefaultForm>
    </Container>
  </div>
);
