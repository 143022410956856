import * as React from 'react';
import { Action, ExpandRowProps, Menu, TableColumnProps, useDataTable } from 'react-data-table';

import { Devices } from 'enums/DevicesEnum';

import { GridRequest, HasId } from 'api/Service';
import { Checkbox } from 'components/forms/checkbox/Checkbox';
import { confirmationModal } from 'components/modals/ConfirmModal';
import { Popover } from 'components/popover/Popover';
import { useTableHeaders } from 'components/Table/TableHeaders';
import { bem } from 'components/utils/bem';
import config from 'config';
import { translate } from 'i18n/translator';
import { TestSelectorsEnum } from 'test/TestSelectors';
import { handleError } from 'utils/handleError';

import './data-table.scss';

const rowConfigsLogo = config.components.table.tableMore;

interface RenderColumnProps
  extends Pick<
    TableRowProps,
    | 'name'
    | 'row'
    | 'index'
    | 'tableIndex'
    | 'filter'
    | 'updateGridRequest'
    | 'setError'
    | 'setIsLoading'
    | 'reFetchData'
    | 'page'
  > {
  col: TableColumnProps<any>;
  colIdx: number;
}

const renderColumn = ({
  name,
  col,
  colIdx,
  row,
  index,
  tableIndex,
  filter,
  updateGridRequest,
  setError,
  setIsLoading,
  reFetchData,
  page,
}: RenderColumnProps) => {
  const device = Devices.Desktop;

  if (col.renderMenu) {
    const menu =
      col
        .renderMenu(row, { index, tableIndex }, filter)
        .filter(item =>
          Object.keys(item).includes('isVisible') ? item && item.isVisible : item,
        ) || [];

    return (
      <td
        align="right"
        key={colIdx}
        className={bem('data-table-content', 'td').modificator('type', 'more').toClassName()}
        onClick={e => e.stopPropagation()}
      >
        {menu.length >= 1 && (
          <Popover
            className="table-row-action-popover"
            label={<img src={rowConfigsLogo} alt="rowConfigsLogo" />}
            id={`${name}-${row.id}-${index}`}
            fade={false}
            dataTestId={TestSelectorsEnum.TableRowActions}
          >
            <ul>
              {menu.map(
                ({ isVisible = true, label, ...rest }) =>
                  isVisible && (
                    <li
                      key={`payment-${row.id}-${label}`}
                      onClick={async e => {
                        await onClickRowAction({
                          action: rest,
                          row,
                          device,
                          filter,
                          e,
                          updateGridRequest,
                          setError,
                          setIsLoading,
                          reFetchData,
                          page,
                        });
                      }}
                    >
                      {translate(label)}
                    </li>
                  ),
              )}
            </ul>
          </Popover>
        )}
      </td>
    );
  }

  const title = col.hint ? col.hint(row) : undefined;

  return (
    <td key={colIdx} title={title} className={bem('data-table-content', 'td').toClassName()}>
      {col.render(row, { index, tableIndex }) || []}
    </td>
  );
};

interface RowActionProps
  extends Pick<
    TableRowProps,
    'row' | 'filter' | 'updateGridRequest' | 'setError' | 'setIsLoading' | 'reFetchData' | 'page'
  > {
  action: Omit<Menu, 'label' | 'isVisible'>;
  device: Devices;
  e: React.MouseEvent<HTMLLIElement, MouseEvent>;
}

const onClickRowAction = async ({
  action,
  row,
  device,
  filter,
  e,
  updateGridRequest,
  setError,
  setIsLoading,
  reFetchData,
  page,
}: RowActionProps) => {
  const {
    onClick,
    isReloadable = false,
    isLoading = false,
    isPreservePage = false,
    confirmMessage,
  } = action;

  setError(null);

  if (!onClick) {
    await confirmationModal('front.table.no-functionality.label', true, false);
    return;
  }
  if (confirmMessage && !(await confirmationModal(confirmMessage))) {
    return;
  }
  try {
    if (isLoading) {
      setIsLoading(true);
    }

    await onClick(row, device, filter, e);

    if (isReloadable) {
      if (page === 0 || isPreservePage) {
        await reFetchData();
      } else {
        updateGridRequest({ page: 0 });
      }
    }
  } catch (e: any) {
    handleError(e, setError);
  } finally {
    setIsLoading(false);
  }
};

interface TableRowsProps {
  visibleColumns: TableColumnProps<HasId>[];
}

const calculateTableIndex = (idx: number, { page, size }: { page: number; size: number }) =>
  page * size + idx;

export const TableRows = ({ visibleColumns }: TableRowsProps) => {
  const {
    name,
    rows,
    actions,
    selection,
    gridRequest,
    reFetchData,
    onRowClick,
    setError,
    setIsLoading,
    expandRowComponent,
    isExpandedRowComponentDisabled,
    isRowDisabled,
    filter,
  } = useTableRows();

  const { isChecked, toggleSelected } = selection;
  const { page, size, updateGridRequest } = gridRequest;

  const {
    selection: { isAllSelected },
  } = useTableHeaders();

  const renderRows = React.useMemo(
    () =>
      rows.map((row, index) => {
        const tableIndex = calculateTableIndex(index, { page, size });
        return (
          <TableRow
            key={`${name}-row-${index}`}
            page={page}
            index={index}
            tableIndex={tableIndex}
            name={name}
            isRowChecked={isChecked(index)}
            onClick={onRowClick ? () => onRowClick(row, { index, tableIndex }) : null}
            toggleSelected={() => toggleSelected(index)}
            row={row}
            actions={actions}
            ExpandRow={isExpandedRowComponentDisabled(row) ? undefined : expandRowComponent}
            visibleColumns={visibleColumns}
            updateGridRequest={updateGridRequest}
            setError={setError}
            setIsLoading={setIsLoading}
            isRowDisabled={isRowDisabled}
            reFetchData={reFetchData}
            isAnimatedCheckbox={rows.length < 300 || !isAllSelected}
            filter={filter}
          />
        );
      }),
    [isChecked, rows, visibleColumns, expandRowComponent, isAllSelected],
  );

  if (!rows.length) {
    return <NoRecordsRow colSpan={visibleColumns.length} />;
  }

  return <tbody className={bem('data-table-content', 'tbody').toClassName()}>{renderRows}</tbody>;
};

const NoRecordsRow = ({ colSpan }: { colSpan: number }) => (
  <tbody>
    <tr className="noRecords">
      <td colSpan={colSpan} className="no-records-td" key="zero-records">
        {translate('front.working-documents-table.empty.label')}
      </td>
    </tr>
  </tbody>
);

interface TableRowProps {
  actions: Action[];
  ExpandRow: React.ComponentType<ExpandRowProps>;
  filter: Obj;
  index: number;
  isAnimatedCheckbox: boolean;
  isRowChecked: boolean;
  isRowDisabled: (row: any & HasId) => boolean;
  name: string;
  onClick: () => void;
  page: number;
  reFetchData: () => Promise<void>;
  row: any & HasId;
  setError: (error: any) => void;
  setIsLoading: (loading: boolean) => void;
  tableIndex: number;
  toggleSelected: () => void;
  updateGridRequest: (gridRequest: GridRequest) => void;
  visibleColumns: TableColumnProps<any & HasId>[];
}

const TableRow = ({
  isRowChecked,
  onClick,
  row,
  page,
  toggleSelected,
  actions,
  ExpandRow,
  isRowDisabled,
  visibleColumns,
  updateGridRequest,
  setError,
  setIsLoading,
  index,
  tableIndex,
  name,
  reFetchData,
  isAnimatedCheckbox,
  filter,
}: TableRowProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const onRowClick = () => {
    !!ExpandRow && setIsExpanded(state => !state);
    !!onClick && onClick();
  };

  const rowDisabled = isRowDisabled(row);

  return (
    <>
      <tr
        className={bem('data-table-content__tr')
          .modificator('isSelected', isRowChecked)
          .modificator('allowHovering', !!onClick || !!ExpandRow)
          .toClassName()}
        onClick={onRowClick}
        data-row-id={`${row.id}`}
      >
        {!!actions?.length && (
          <>
            <td className={bem('data-table-content__td').toClassName()}>
              <Checkbox
                checked={isRowChecked}
                disabled={rowDisabled}
                onChange={toggleSelected}
                name={`name-row-${index}`}
                isAnimated={isAnimatedCheckbox}
                hasEnlargedArea
              />
            </td>
          </>
        )}

        {visibleColumns.map((col, colIdx) =>
          renderColumn({
            name,
            col,
            colIdx,
            row,
            index,
            tableIndex,
            filter,
            updateGridRequest,
            setError,
            setIsLoading,
            reFetchData,
            page,
          }),
        )}
      </tr>
      {!!ExpandRow && isExpanded && (
        <tr>
          <td colSpan={visibleColumns?.length}>
            <div className="expanded-row">
              <ExpandRow row={row} />
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

const useTableRows = () => {
  const {
    name,
    rows,
    actions,
    selection,
    gridRequest,
    onRowClick,
    setError,
    setIsLoading,
    isRowDisabled,
    expandRowComponent,
    isExpandedRowComponentDisabled,
    reFetchData,
    filter: { filter },
  } = useDataTable();

  return {
    name,
    rows,
    actions,
    selection,
    gridRequest,
    onRowClick,
    setError,
    setIsLoading,
    isRowDisabled,
    expandRowComponent,
    isExpandedRowComponentDisabled,
    reFetchData,
    filter,
  };
};
