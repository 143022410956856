import * as React from 'react';

import classNames from 'classnames';

// tslint:disable-next-line

export const NavBarNavigation = ({ onPreviousClick, onNextClick, className }: any) => {
  return (
    <div className={classNames(className, 'date-picker-header')}>
      <div
        className="date-picker-header__left"
        onClick={e => {
          e.preventDefault();
          onPreviousClick();
        }}
      >
        <span className="date-picker-header__left__icon">←</span>
      </div>
      <div
        className="date-picker-header__right"
        onClick={e => {
          e.preventDefault();
          onNextClick();
        }}
      >
        <span className="date-picker-header__right__icon">→</span>
      </div>
    </div>
  );
};
