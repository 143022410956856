import * as React from 'react';

import { RegistrationResponse } from 'api/RegistrationService';

export interface ActivationContextValue {
  setStep: React.Dispatch<React.SetStateAction<RegistrationResponse>>;
  step: RegistrationResponse;
}

export const ActivationContext = React.createContext<ActivationContextValue>({
  step: null,
  setStep: () => {},
});
