import * as React from 'react';
import { CSSProperties } from 'react';

import { logout } from 'api/backend';
import { Popover } from 'components/popover/Popover';
import config from 'config';
import { AllowDisplayBlock, isActionAllowed } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { useNotificationsSelector } from 'pages/Dashboard/Header/useNotificationsSelector';
import { useReIdentificationMessage } from 'pages/Dashboard/Header/useReIdentificationMessage';
import { LogoutButton } from 'pages/Dashboard/LogoutButton/LogoutButton';
import { NotificationsHeader, NotificationsList } from 'pages/Dashboard/Notifications';
import { isString } from 'utils/isData';
import './header-large.scss';

const {
  desktopLogoPath: sitelogo,
  settingsIcon,
  locationIcon,
  instructionsIcon,
  notificationsIcon,
} = config.page.dashboard;

const desktopHeaderIcons = () => [
  {
    icon: settingsIcon,
    key: 'settings',
    link: pages.profile.tabs.settings,
    isVisible: isActionAllowed([Privileges.settingsView]),
  },
  {
    icon: locationIcon,
    key: 'location',
    link: pages.departments.tabs.map(),
    isVisible: isActionAllowed([Privileges.infoBranchView]),
  },
  {
    icon: instructionsIcon,
    key: 'instructions',
    link: pages.instructions,
    isVisible: isActionAllowed([Privileges.instructionDictionaryView]),
  },
];

export const HeaderLarge = () => {
  const {
    notifications,
    readNotification,
    getNotificationsSize,
    readAllNotifications,
    gotoNotification,
  } = useNotificationsSelector();

  const reIdentificationMessage = useReIdentificationMessage();

  const getNotificationsStylePosition: CSSProperties = {
    position: getNotificationsSize() > 0 ? 'absolute' : 'static',
  };

  const hasNotifications = notifications.length > 0;

  return (
    <header className="large-header-wrapper">
      <div className="control-wrapper">
        <img src={sitelogo} className="logo" alt="logo" />
        <ul className="icons-list list-reset">
          <AllowDisplayBlock privileges={[Privileges.contactLetterView]}>
            <li className="icons-list_item">
              <span className="notifications" style={getNotificationsStylePosition} />
              <Popover
                isClickAutoClose={false}
                placement="bottom"
                fade={false}
                className="notifications-popover"
                id="notifications"
                header={<NotificationsHeader onReadAll={readAllNotifications} />}
                label={
                  <a>
                    <img className="notifications-icon" src={notificationsIcon} alt="logo" />
                    {hasNotifications && <div className="unread-notifications-mark" />}
                  </a>
                }
              >
                <NotificationsList
                  onClose={readNotification}
                  onClick={gotoNotification}
                  data={notifications}
                />
              </Popover>
            </li>
          </AllowDisplayBlock>
          {desktopHeaderIcons().map(
            ({ icon, key, link, isVisible }) =>
              isVisible && (
                <li
                  className="icons-list_item"
                  key={key}
                  onClick={() => {
                    link && goto(link);
                  }}
                >
                  <img className="item-icon" src={icon} alt="logo" />
                </li>
              ),
          )}
          <li className="icons-list_item">
            <LogoutButton onLogout={logout} />
          </li>
        </ul>
      </div>
      {isString(reIdentificationMessage) && (
        <div className="header-info-message">{reIdentificationMessage}</div>
      )}
    </header>
  );
};
