import React from 'react';

import { DynamicFieldValue } from 'api/DemandsService/interfaces';
import { FileType, Option } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { DateField } from 'components/forms/inputs/DateField';
import { FileField } from 'components/forms/inputs/FileField';
import { RadioField } from 'components/forms/inputs/RadioField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextField } from 'components/forms/inputs/TextField';
import { useDisabledCustomer } from 'hooks/useDisabledCustomer';
import { useFileExtensions } from 'hooks/useFileExtensions';
import { translate } from 'i18n/translator';
import { AcquiringFieldNames } from 'pages/Demands/AcuiringDemand/enums';
import { OrderBaseFields } from 'pages/Demands/interfaces';
import { disabledDaysBeforeToday } from 'pages/Demands/utils';

interface AcquiringDemandFields extends OrderBaseFields {
  [AcquiringFieldNames.Customer]: string;
  [AcquiringFieldNames.AcquiringMerchant]: string;
  [AcquiringFieldNames.AdressMerchant]: string;
  [AcquiringFieldNames.NewAcquiringMerchant]: boolean;
  [AcquiringFieldNames.NewAdressMerchant]: string;
  [AcquiringFieldNames.ModeTerm]: string;
  [AcquiringFieldNames.NumberTerm]: string;
  [AcquiringFieldNames.ExtTerm]: string;
  [AcquiringFieldNames.RadioAcquiringNew]: boolean;
  [AcquiringFieldNames.ConnectionType]: string;
  [AcquiringFieldNames.ParamTerm]: string;
  [AcquiringFieldNames.DateInst]: string;
  [AcquiringFieldNames.ContactData]: string;
  [AcquiringFieldNames.Comment]: string;
  [AcquiringFieldNames.Attach]: File[];
}

interface AcquiringDemandPayload {
  [AcquiringFieldNames.AcquiringMerchant]: Option<DynamicFieldValue>[];
  [AcquiringFieldNames.AdressMerchant]: Option<DynamicFieldValue>[];
  [AcquiringFieldNames.ConnectionType]: Option<DynamicFieldValue>[];
}

const typeOptions = [
  { value: 'extTerm', label: 'front.acquiring.field.portable-terminal.label' },
  { value: 'extTermWithPin', label: 'front.acquiring.field.external-terminal.label' },
];

const maskConfigNumberTerm = {
  decimalSeparator: ' ',
};

export const AcquiringDemandDetails = () => {
  const {
    order,
    payload: { acquiringMerchant, adressMerchant, connectionType },
    onChangeCustomer,
    form: { updateData, getFieldValue },
  } = useOrderDetails<AcquiringDemandFields, AcquiringDemandPayload>();
  const isDisabledCustomer = useDisabledCustomer(order);

  const handleChangeMerchant = (acquiringMerchant: string) => {
    const address = adressMerchant.find(({ value }) => acquiringMerchant === value);
    updateData({ [AcquiringFieldNames.AdressMerchant]: address.value });
  };

  const isNewAddres = getFieldValue(AcquiringFieldNames.NewAcquiringMerchant) as boolean;

  const fileExtensions = useFileExtensions();

  return (
    <DefaultForm>
      <CustomerSelect
        name={AcquiringFieldNames.Customer}
        label="front.acquiring.field.name-organization.label"
        onSelectOption={onChangeCustomer}
        selectDefaultCustomer
        disabled={isDisabledCustomer}
        required
      />
      <SelectField
        name={AcquiringFieldNames.AcquiringMerchant}
        label="front.acquiring.field.shopping-point.label"
        options={acquiringMerchant}
        onSelectOption={handleChangeMerchant}
        required
      />
      <SelectField
        name={AcquiringFieldNames.AdressMerchant}
        label="front.acquiring.field.shopping-point-address.label"
        options={adressMerchant}
        required
      />
      <CheckboxField
        name={AcquiringFieldNames.NewAcquiringMerchant}
        label="front.acquiring.field.new-address.label"
      />
      {isNewAddres && (
        <TextField
          name={AcquiringFieldNames.NewAdressMerchant}
          label="front.acquiring.field.address-new-shopping-point.label"
          required
        />
      )}
      <TextField
        name={AcquiringFieldNames.ModeTerm}
        label="front.acquiring.field.mode.label"
        required={isNewAddres}
      />
      <AmountField
        name={AcquiringFieldNames.NumberTerm}
        label="front.acquiring.field.terminal-number.label"
        maskConfig={maskConfigNumberTerm}
        maxLength={12}
        required
      />
      <h5>{translate('front.acquiring.field.terminal-type.label')}</h5>
      <RadioField
        name={AcquiringFieldNames.RadioAcquiringNew}
        options={typeOptions}
        cssType="block"
        required
      />
      <SelectField
        name={AcquiringFieldNames.ConnectionType}
        label="front.acquiring.field.communication-type.label"
        options={connectionType}
      />
      <TextField
        label="front.acquiring.field.communication-parameters.label"
        name={AcquiringFieldNames.ParamTerm}
      />
      <DateField
        name={AcquiringFieldNames.DateInst}
        label="front.acquiring.field.installation-date.label"
        disabledDays={disabledDaysBeforeToday}
      />
      <TextField
        name={AcquiringFieldNames.ContactData}
        label="front.acquiring.field.contact-details.label"
        required
      />
      <TextField
        name={AcquiringFieldNames.Comment}
        label="front.acquiring.field.comment.label"
        maxLength={500}
      />
      <FileField
        name={AcquiringFieldNames.Attach}
        label="front.acquiring.field.attachments.label"
        outputFormat={FileType.BASE_64}
        accept={fileExtensions}
        multiple
      />
    </DefaultForm>
  );
};
