import * as React from 'react';

import { useMergeState } from 'api/Service';
import { ExpandOptions, Option } from 'components/expandOptions/ExpandOptions';
import { Checkbox } from 'components/forms/checkbox/Checkbox';
import { Popover } from 'components/popover/Popover';
import config, { WIDGET, WIDGET_LAYOUT } from 'config';
import { translate } from 'i18n/translator';
import { isActionAllowed } from 'navigations/access';
import { Privileges } from 'navigations/privileges';
import { LocalStorage } from 'utils/LocalStorage';

import { items as mockItems } from './data';
import { DragDrop, ItemType } from './DragDrop';
import * as Icons from './Icons';

import './styles.scss';

const MOCK_LAYOUT = {
  items: mockItems,
  layout: WIDGET_LAYOUT.DOUBLE,
};

type Condition = {
  items?: ItemType[];
  layout?: WIDGET_LAYOUT;
};

interface State extends Condition {
  isDraggable: boolean;
  isInitialized: boolean;
}

const initialState: State = {
  isInitialized: false,
  isDraggable: false,
  layout: null,
  items: null,
};

const LS_KEY = config.page.main.widgets.LS_KEY;

export const HomePage = () => {
  const [state, setState] = useMergeState<State>(initialState);

  const onMount = () => {
    const { items, ...rest }: { items: ItemType[] } = LocalStorage.getItem(LS_KEY, MOCK_LAYOUT);

    const filtredItemsByPrivileges: ItemType[] = items.filter(({ widget }) => {
      switch (widget) {
        case WIDGET.INTERNAL_PAYMENTS:
          return isActionAllowed([Privileges.paymentInternalView]);
        case WIDGET.ANALYTICS:
          return (
            isActionAllowed([Privileges.financeAnalyticsView]) &&
            isActionAllowed([Privileges.productAccountView])
          );
        default:
          return false;
      }
    });

    setState(prevState => ({
      ...prevState,
      ...rest,
      items: filtredItemsByPrivileges,
      isInitialized: true,
    }));
  };

  React.useEffect(() => {
    onMount();
  }, []);

  const onLayoutChange = (layout: WIDGET_LAYOUT): void => {
    saveToLS({ layout });
    setState(prevState => ({ ...prevState, layout }));
  };

  const saveToLS = (data: any): void => {
    const { layout, items } = state;
    localStorage.setItem(LS_KEY, JSON.stringify({ layout, items, ...data }));
  };

  const createOptions = () => {
    const { isDraggable, layout, items } = state;
    const activeColor = config.page.main.widgets.activeColor;
    return [
      {
        label: 'front.home.widgets.settings.two-col',
        color: layout === WIDGET_LAYOUT.DOUBLE && activeColor,
        icon: Icons.DoubleColumn,
        action: () => onLayoutChange(WIDGET_LAYOUT.DOUBLE),
      },
      {
        label: 'front.home.widgets.settings.one-col',
        icon: Icons.SingleColumn,
        color: layout === WIDGET_LAYOUT.SINGLE && activeColor,
        action: () => onLayoutChange(WIDGET_LAYOUT.SINGLE),
      },
      {
        label: 'front.home.widgets.settings.draggable-mode',
        icon: Icons.Draggable,
        color: isDraggable && activeColor,
        action: () => setState(prevState => ({ ...prevState, isDraggable: !isDraggable })),
      },
      {
        label: 'front.home.widgets.settings.on-off-widgets',
        icon: Icons.Layers,
        render: button => (
          <Popover
            id="popover"
            placement="bottom-end"
            className="table-settings-popover"
            label={button}
            isClickAutoClose={false}
          >
            <ul>
              {items.map((item, i) => (
                <li key={item.title}>
                  <Checkbox
                    label={translate(item.title)}
                    checked={item.enabled}
                    name="operations"
                    onChange={() => onWidgetToggle(i)}
                  />
                </li>
              ))}
            </ul>
          </Popover>
        ),
      },
    ] as Option[];
  };

  const onWidgetToggle = (index: number) => {
    const { items } = state;
    const cloneItems = [...items];
    cloneItems[index].enabled = !cloneItems[index].enabled;
    saveToLS({ items: cloneItems });
    setState({ items: cloneItems });
  };

  const onDragDropChange = (dropItems: ItemType[]) => {
    const { items } = state;

    const newItems = items.map(item => {
      const dropItem = dropItems.find(dropItem => dropItem.id === item.id);
      return dropItem ? dropItem : item;
    });

    saveToLS({ items: newItems });
    setState({ items: newItems });
  };

  const { isInitialized, layout, items, isDraggable } = state;

  if (!isInitialized) return null;

  return (
    <>
      <div className="home-page__header">
        <div className="home-page__title">{translate('front.home.title')}</div>
        <ExpandOptions
          options={createOptions()}
          title={translate('front.home.widgets.settings.more')}
          icon={Icons.Star}
        />
      </div>
      <DragDrop
        isDraggable={isDraggable}
        layout={layout}
        items={items}
        onChange={onDragDropChange}
      />
    </>
  );
};
