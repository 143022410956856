import { FactorsChain } from 'api/AuthService';
import { Certificate } from 'api/CertificateService/interfaces';
import { FactorType } from 'pages/Login/LoginPage';

export type CurrentFactor = {
  type: FactorType;
  payload?: any;
};

export type LoginContextType = {
  certificates: Certificate[];
  currentFactor: CurrentFactor;
  goToFirstStep: () => void;
  goToNextFactor: () => void;
  goToPrevFactor: () => void;
  setCertificates: (certificates: Certificate[]) => void;
  setCurrentFactor: (factor: CurrentFactor) => void;
  setFactorsChain: (factors: FactorsChain) => void;
};

export const initialContext: LoginContextType = {
  currentFactor: null,
  certificates: null,
  goToNextFactor: () => {},
  goToPrevFactor: () => {},
  goToFirstStep: () => {},
  setCurrentFactor: () => {},
  setCertificates: () => {},
  setFactorsChain: () => {},
};
