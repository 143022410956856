export enum RegularPaymentsFilterFormNames {
  AmountFrom = 'amountFrom',
  AmountTo = 'amountTo',
}

export enum SchedulePageNames {
  Type = 'type',
  Period = 'period',
  StartDate = 'startDate',
  StopDate = 'stopDate',
}
