import * as React from 'react';
import { TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { CurrencyHistoryGrid } from 'api/HandbooksService';
import { SortOrder } from 'api/Service';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';

import { CurrencyHistoryFilter } from './CurrencyHistoryFilter';

const columns: TableColumnProps<CurrencyHistoryGrid>[] = [
  {
    label: 'front.currency-history-table.base-currency.label',
    render: row => <DataColumn title={row.baseCurrencyIsoCode} />,
    sort: 'baseCurrency',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.currency-history-table.currency.label',
    render: row => <DataColumn title={row.currencyIsoCode} />,
    sort: 'currency',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.currency-history-table.validFrom.label',
    render: row => <DataColumn title={row.validFrom} />,
    sort: 'validFrom',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.currency-history-table.validTo.label',
    render: row => <DataColumn title={row.validTo} />,
    sort: 'validTo',
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.currency-history-table.course-purchase.label',
    render: row => <DataColumn title={row.coursePurchase} />,
    sort: 'ratePurchase',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.currency-history-table.course-sale.label',
    render: row => <DataColumn title={row.courseSale} />,
    sort: 'rateSale',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.currency-history-table.course-type.label',
    render: row => <DataColumn title={row.type?.label} />,
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
];

export const CurrencyHistoryPage = () => (
  <DataTable
    name={TableNamesEnum.CurrencyHistoryTable}
    columns={columns}
    fetchRows={api.handbooks.getCurrencyHistory}
    defaultSort="baseCurrencyIsoCode"
    defaultOrder={SortOrder.Desc}
    filterComponent={CurrencyHistoryFilter}
  />
);
