import { downloadFile, HttpMethod } from 'api/backend';
import { OrderRequest } from 'api/interfaces';

export interface ExportRequest {
  format: string;
  objectCode: ExportType;
  layout?: string;
}

export enum ExportType {
  DOMESTIC_PAYMENT_ORDERS = 'DOMESTIC_PAYMENT_ORDERS',
  FOREIGN_TRANSFER_ORDERS = 'FOREIGN_TRANSFER_ORDERS',
  CURRENCY_OPERATION_ORDERS = 'CURRENCY_OPERATION_ORDERS',
  EXPOSED_ORDERS = 'EXPOSED_ORDERS',
  SALARY_SHEET_ORDERS = 'SALARY_SHEET_ORDERS',
  FEA_ORDERS = 'FEA_ORDERS',
  ACCOUNT_TRANSACTIONS = 'ACCOUNT_TRANSACTIONS',
}

export class ExportService {
  async exportOrders(params: OrderRequest & ExportRequest): Promise<void> {
    return downloadFile('/v1/export', undefined, params, HttpMethod.GET);
  }

  async exportAccountTransactions(params: any & ExportRequest): Promise<void> {
    return downloadFile('/v1/export', undefined, params, HttpMethod.GET);
  }

  exportByCode(objectCode: ExportType, request: { format: string; ids: number[] }): Promise<any> {
    return downloadFile('/v1/export', undefined, { objectCode, ...request }, HttpMethod.GET);
  }
}
