import { ConstantEnum } from 'api/enums';
import { LettersGrid, MailService } from 'api/MailService';
import { GridResponse, Option, SortOrder } from 'api/Service';
import { BooleanString } from 'pages/Login/CustomerSelectModal/types';

import { get, post, put } from './backend';

export enum CustomerStatus {
  BLOCKED = 'BLOCKED',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export enum CustomerTypeEnum {
  LegalEntity = 'LegalEntity',
  IndividualEmployer = 'IndividualEmployer',
  PrivatePerson = 'PrivatePerson',
}

export enum EndDateAction {
  BLOCK_SIGN = 'BLOCK_SIGN',
  BLOCK_AUTH = 'BLOCK_AUTH',
}

enum NotificationStatus {
  SIGNED = 'SENT',
  DRAFT = 'DRAFT',
}

export type ChosenCustomerModel = {
  default: boolean;
  disabled: boolean;
  fullName: string;
  id: number;
  intlName: string;
  name: string;
  personId: number;
  personStatus: CustomerStatus;
  restrictedByIp: boolean;
  restrictedByTime: boolean;
  status: CustomerStatus;
  taxCode: string;
  ipRestriction?: string;
  validFrom?: string;
  validTo?: string;
};

export interface CustomerInfo {
  contacts: any;
  contactsList: [];
  customerConfig: any;
  customers: Customer[];
  defaultLanguage: string;
  serverTime: string;
  status: CustomerStatus;
  systemConfig: PersonConfig;
  userConfig: UserConfig;
  userEmail: string;
  userFirstName: string;
  userId: number;
  userImage: null;
  userLastName: string;
  userMiddleName: string;
  userPhone: string;
  userShortName: string;
}

interface UserConfig {
  customerGroups: [];
  privileges: string[];
  userConfigMap: {
    avatar: string;
    CHOSEN_CUSTOMERS: number[];
    EMAIL: string;
    PHONE: string;
    REMEMBER_CHOSEN_CUSTOMER: BooleanString;
    TAX_CODE: string;
    WIDGETS: string;
  };
  uuid: string;
}

export type Customer = {
  allowedForUserCert: boolean;
  config: PersonConfig;
  default: boolean;
  disabled: boolean;
  id: number;
  name: string;
  personExtraData: { isConfidant?: boolean };
  personId: number;
  privileges: string[];
  status: CustomerStatus;
  subjectDN: string;
  taxCode: string;
  type: CustomerTypeEnum;
  validFrom: Date;
  validTo: Date;
  endDate?: Date;
  endDateAction?: EndDateAction;
};

export interface PersonConfig {
  [ConstantEnum.IpRestriction]: string;
  [ConstantEnum.AllowFileKey]: boolean;
  [ConstantEnum.AllowTokenKey]: boolean;
  [ConstantEnum.HasRole]: boolean;
}

export interface NotificationInterface {
  generateTime: Date;
  id: number;
  messageText: string;
  notificationType: 'PASSWORD_EXPIRING' | 'LETTER';
  subjectText: string;
  subjectType: 'USER';
  channelCode?: 'INTERNAL';
}

export interface UserInterface {
  acceptPassword: string;
  address: string;
  birthDate: string;
  customerId: string;
  documentNumber: string;
  documentSerial: string;
  firstName: string;
  lastName: string;
  login: string;
  middleName: string;
  password: string;
  phone: string;
  zip: string;
  companyPersonId?: number;
  id?: number;
  roleIds?: string[];
}

export interface AccountsInterface {
  paymentDefaultAccount: string;
  transferDefaultAccount: string;
  customerId?: number | string;
}

export interface DebtUserInterface {
  accountId: number;
  accountNumber: string;
  amount: number;
  contractNumber: string;
  currency: string;
  customerId: number;
  customerName: string;
  openedContractDate: string;
}

export class UserService {
  async getNotifications(): Promise<NotificationInterface[]> {
    const letters = await new MailService().getLetters({
      type: 'in',
      sort: 'sentTime',
      order: SortOrder.Desc,
    });

    const letterNotifications = letters.rows
      .filter((n: LettersGrid) => n.documentStatus === NotificationStatus.SIGNED)
      .map((letter: LettersGrid) => ({
        generateTime: letter.date,
        id: letter.id,
        messageText: letter.desc,
        notificationType: 'LETTER',
        subjectText: letter.subject,
        subjectType: 'USER',
      }));

    const userNotifications = await get('/v1/notifications/message');

    return [...letterNotifications, ...userNotifications];
  }

  getNotificationsCount(): Promise<any> {
    return get('/v1/notifications/message/count');
  }

  async readNotifications(messages: NotificationInterface[]): Promise<void> {
    const [letters, notifications] = messages.reduce(
      (acc, notification) => {
        if (notification.notificationType === 'LETTER') acc[0].push(notification);
        else acc[1].push(notification);

        return acc;
      },
      [[], []],
    );

    await Promise.all(letters.map(letter => put(`/v1/ugb/letter/read/${letter.id}`)));

    if (notifications.length) {
      await put(
        '/v1/notifications/message/read',
        notifications.map(({ id }) => id),
      );
    }
  }

  async getDefaultAccounts(request: { code: string[] | string; customerId: number }): Promise<any> {
    const { ACC_TRANSFER_DEFAULT_ACCOUNT, PAYMENT_ORDER_DEFAULT_ACCOUNT } = await get(
      '/v1/config/company-person',
      request,
    );

    return {
      transferDefaultAccount: ACC_TRANSFER_DEFAULT_ACCOUNT,
      paymentDefaultAccount: PAYMENT_ORDER_DEFAULT_ACCOUNT,
    };
  }

  setDefaultAccounts(
    customerId: number | string,
    { transferDefaultAccount, paymentDefaultAccount }: AccountsInterface,
  ): Promise<any> {
    const newRequest = {
      ACC_TRANSFER_DEFAULT_ACCOUNT: transferDefaultAccount,
      PAYMENT_ORDER_DEFAULT_ACCOUNT: paymentDefaultAccount,
    };

    return put(`/v1/config/company-person?customerId=${customerId}`, newRequest);
  }

  getCustomersInfo(): Promise<CustomerInfo> {
    return get('/v2/customers/info');
  }

  async getCustomerUserInfo(id: number): Promise<UserInterface> {
    return await get(`/v1/ugb/customer/user/${id}`);
  }

  async getAllCustomerUserInfo(): Promise<Option[]> {
    const { rows }: Obj<UserInterface[]> = await get(`/v1/ugb/customer/user/all-for-admin`);

    return rows.reduce((acc, user) => {
      const { customerId, ...fields } = user;

      return [...acc, { value: fields.login, label: fields.login, content: fields }];
    }, []);
  }

  async setDefaultLanguage(language: string): Promise<void> {
    return await put('/v1/customer-user/default-language', language);
  }

  chooseCustomers(customers: number[], isForever = false): Promise<void> {
    return post('/v1/customer-user/chosen-customers', { customers, isForever });
  }

  async createUser(user: UserInterface) {
    return post('/v1/ugb/customer/user', user);
  }

  async updateUser(user: UserInterface) {
    return put('/v1/ugb/customer/user', user);
  }

  async getDebtUser(): Promise<GridResponse<DebtUserInterface>> {
    const data: DebtUserInterface[] = await get('/v1/ugb/debt');

    return {
      rows: data,
      total: {
        count: data.length,
      },
    };
  }

  async getReIdentificationMessage(): Promise<string> {
    return await get('/v1/ugb/reidentification');
  }
}
