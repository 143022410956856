import { combineReducers } from 'redux';

import { applicationInitReducer } from './applicationInit';
import { constantReducer } from './constant';
import { deviceReducer } from './device';
import { localeReducer } from './locale';
import { modalReducer } from './modal';
import { modalCollectionReducer } from './modalCollection';
import { notificationsReducer } from './notifications';
import { serverErrorReducer } from './serverError';
import { userReducer } from './user';

const reducers = combineReducers({
  userState: userReducer,
  localeState: localeReducer,
  modalState: modalReducer,
  modalCollectionState: modalCollectionReducer,
  constantState: constantReducer,
  device: deviceReducer,
  serverError: serverErrorReducer,
  notifications: notificationsReducer,
  applicationInit: applicationInitReducer,
});

export default reducers;
