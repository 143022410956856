import { get } from './backend';

export interface Locale {
  en: string;
  ru: string;
  uk: string;
}

export class LocaleService {
  async getLocales(): Promise<Locale> {
    return await get('/translate/locales');
  }
}
