import { Action } from 'react-data-table';

import { GridRequest } from 'api/Service';
import { confirmationModal } from 'components/modals/ConfirmModal';

export const getSelectedRows = (rows: unknown[], selected: unknown[]) =>
  rows.filter((item, idx) => selected.includes(idx));

export const calculateSelectedByPage = (selected: unknown[], multiplier: number) =>
  selected.map(s => (s as number) + multiplier);

export const handleClick =
  (
    selected: number[],
    selectedByTable: number[],
    selectedRows: unknown[],
    updateGridRequest: (gridRequest: GridRequest) => void,
    page: number,
    reFetchData: () => void,
  ) =>
  (item: Action) =>
  async () => {
    const { action, isReloadable = false, confirmMessage } = item;

    if (confirmMessage && !(await confirmationModal(confirmMessage))) {
      return;
    }

    await action(selectedRows, { selectedByTable, selectedByPage: selected });

    if (isReloadable) {
      if (page === 0) {
        reFetchData();
      } else {
        updateGridRequest({ page: 0 });
      }
    }
  };
