import * as React from 'react';
import { useSelector } from 'react-redux';

import { ClaimTokenCodeEnum } from 'api/RedirectUri/enums';
import { Button } from 'components/buttons/Button';
import { RedirectButton } from 'components/buttons/components/RedirectButton/RedirectButton';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { LoadEmployeeRegisterModal } from 'pages/Employees/components/LoadEmployeeRegisterModal/LoadEmployeeRegisterModal';
import { selectChosenPersonOptions } from 'store/selectors';

const handleDownloadRegister = async () => await confirmModal(LoadEmployeeRegisterModal);

export const LoadEmployeeRegisterButton = () => {
  const chosenPersons = useSelector(selectChosenPersonOptions());
  const isSingleChosenCustomer = chosenPersons.length === 1;

  return isSingleChosenCustomer ? (
    <RedirectButton
      claimTokenCode={ClaimTokenCodeEnum.SalaryRegistry}
      customerId={+chosenPersons[0].value}
      label="front.demands.salary.download_register.label"
      color="secondary"
    />
  ) : (
    <Button
      color="secondary"
      label="front.demands.salary.download_register.label"
      onClick={handleDownloadRegister}
    />
  );
};
