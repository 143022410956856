import * as React from 'react';

import { Option, SortOrder } from 'api/Service';
import { PreloadField } from 'components/forms/inputs/PreoadField';
import { AutocompleteWithSorting } from 'components/forms/select/AutocompleteWithSorting';
import * as Icon from 'components/icons';
import { translate } from 'i18n/translator';
import { DisabledBlock } from 'navigations/access';

import './template-controls.scss';

interface TemplateSelectProps {
  disabled: boolean;
  onChange: (value: string) => void;
  options: Option[];
  value: string;
}

export const TemplateSelect: React.FC<TemplateSelectProps> = ({
  value,
  options,
  onChange,
  disabled,
}) => (
  <div className="template-control">
    <PreloadField isLoading={disabled} width={'inherit'}>
      <Icon.SmallSearch />
      <div className="template-control_title">
        <AutocompleteWithSorting
          name="company"
          placeholder="front.form.select-search-template.label"
          value={value}
          options={options}
          onChange={onChange}
          multi={false}
          disabled={disabled}
          isSearchable
          isMenuMaxContentWidth={false}
          hiddenTextLongOption
          sortParams={{
            defaultSort: 'orderAlias',
            columns: [
              {
                name: 'orderAlias',
                label: 'front.autocomplete-sort-a-z.label',
                order: SortOrder.Asc,
              },
              {
                name: 'updated',
                label: 'front.autocomplete-sort-data.label',
                order: SortOrder.Asc,
              },
            ],
          }}
        />
      </div>
    </PreloadField>
  </div>
);

interface TemplateCreateProps {
  disabled: boolean;
  onClick: (e: any) => void;
}

export const TemplateCreate: React.FC<TemplateCreateProps> = ({ onClick, disabled }) => (
  <DisabledBlock disabled={disabled}>
    <div className="template-control" onClick={e => onClick(e)}>
      <Icon.Template />
      <span className="template-control_create">
        {translate('front.internal-payment-page.order-create-template-button.label')}
      </span>
    </div>
  </DisabledBlock>
);
