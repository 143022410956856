import * as React from 'react';

import { TimePicker } from 'components/forms/timePicker/TimePicker';
import { useField } from 'components/forms/ValidatingForm/components/useField';
import { validateMessages } from 'components/validateMessages';
import { translate } from 'i18n/translator';

import { FieldInput } from './FieldInput';

interface TimeProps {
  label: string;
  name: string;
  clearable?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  isEditable?: boolean;
  maxTime?: string;
  minTime?: string;
  placeholder?: string;
  required?: boolean;
}

export const TimeField = ({ required, name, label, defaultValue, disabled }: TimeProps) => {
  const [isFocus, setFocus] = React.useState<boolean>(false);

  const toggleFocus = (isFocus: boolean) => () => setFocus(isFocus);

  const validate = React.useCallback(
    value => {
      if (required) {
        if (!value) {
          return translate(validateMessages.fileRequired);
        }
      }
    },
    [required],
  );

  const { field, form } = useField({ name, defaultValue, validate, required });

  if (!field && !form) {
    return null;
  }

  const isDisabled = disabled || form.disabled || form.progress;

  return (
    <FieldInput
      name={name}
      label={label}
      error={field.error}
      warning={field.warning}
      value={field.value}
      isFocus={isFocus}
      isDisabled={isDisabled}
    >
      <TimePicker
        onChange={field.onChange}
        value={field.value as string}
        disabled={isDisabled}
        showSecond={false}
        onFocus={toggleFocus(true)}
        onBlur={toggleFocus(false)}
      />
    </FieldInput>
  );
};
