import * as React from 'react';
import { Action } from 'react-data-table';

import { Button, Size } from 'components/buttons/Button';
import { translate } from 'i18n/translator';

interface Props {
  item: Action;
  onClick: () => Promise<void>;
  size: Size;
}

export const ActionButton = ({ onClick, item, size }: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const { label, className, color = 'primary' } = item;

  const handleClick = async () => {
    setIsLoading(true);
    await onClick();
    setIsLoading(false);
  };

  return (
    <Button
      size={size}
      className={className}
      disabled={isLoading}
      onClick={handleClick}
      color={color}
    >
      {translate(label)}
    </Button>
  );
};
