import { Customer, CustomerInfo } from 'api/UserService';

export interface Fields {
  customers: Obj<boolean>;
  isForever: boolean;
  selectAll: boolean;
}

export interface CustomerSelectModalProps {
  mode: CustomerSelectMode;
}

export interface Timers {
  timerExit: number;
  timerWarning: number;
}

export interface ExpandedCustomer extends Customer {
  error?: string;
  isSelected?: boolean;
  timers?: Timers;
  warning?: string;
}

export interface ExpandedCustomerInfo extends CustomerInfo {
  customers: ExpandedCustomer[];
}

export enum CustomerSelectMode {
  auth = 'auth',
  select = 'select',
  readOnly = 'readOnly',
}

export enum BooleanString {
  true = 'true',
  false = 'false',
}
