import React from 'react';

import { translate } from 'i18n/translator';

export const PasswordHint = () => (
  <>
    <div>{translate('front.change-password.attention.label')}</div>
    <div>{translate('front.change-password.create-password.label')}</div>
    <div>{translate('front.change-password.max-length.label')}</div>
    <ul className="list-regulation">
      <li>{translate('front.change-password.letters.label')}</li>
      <li>{translate('front.change-password.numbers.label')}</li>
      <li>{translate('front.change-password.symbols.label')}</li>
    </ul>
    <div>{translate('front.change-password.other.label')}</div>
  </>
);
