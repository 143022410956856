import * as React from 'react';

import { ImportPaymentsResponse } from 'api/interfaces';
import { IMPORT_TYPES } from 'api/Service';
import { Page } from 'components/layout/Page/Page';
import { Tab, Tabs, TabsRef } from 'components/tabs/Tabs';
import { translate } from 'i18n/translator';
import { ErrorCounterpartiesList } from 'pages/Import/ErrorImportList/ErrorCounterpartiesList';
import { ErrorCurrencyExchangeImportList } from 'pages/Import/ErrorImportList/ErrorCurrencyExchangeImportList';
import { ErrorEmployeesList } from 'pages/Import/ErrorImportList/ErrorEmployeesList';
import { ErrorPaymentsList } from 'pages/Import/ErrorImportList/ErrorPaymentsList';
import { ImportDetails } from 'pages/Import/ImportDetail/ImportDetails';
import { SetResultProps } from 'pages/Import/ImportPage/interfaces';
import { ImportResult } from 'pages/Import/interfaces';
import { SuccessCounterpartyList } from 'pages/Import/SuccessImportList/SuccessCounterpartyList';
import { SuccessCurrencyExchangeList } from 'pages/Import/SuccessImportList/SuccessCurrencyExchangeList';
import { SuccessEmployeesList } from 'pages/Import/SuccessImportList/SuccessEmployeesList';
import { SuccessPaymentsList } from 'pages/Import/SuccessImportList/SuccessPaymentsList';

enum TabNames {
  Details = 'details',
  Successed = 'successed',
  WithErrors = 'withErrors',
}

interface Results {
  importResult: ImportResult;
  importType: IMPORT_TYPES;
}

const isSuccessImported = (importType: IMPORT_TYPES, importResult: ImportResult) => {
  switch (importType) {
    case IMPORT_TYPES.DOMESTIC_PAYMENTS:
    case IMPORT_TYPES.FOREIGN_PAYMENTS:
    case IMPORT_TYPES.CURRENCY_BUY:
      return importResult.orders?.length > 0;
    case IMPORT_TYPES.SALARY:
      return importResult.salaryProjectModel?.employeeSalaryModels?.length > 0;
    case IMPORT_TYPES.SALARY_EMPLOYEES:
      return importResult.employees?.length > 0;
    case IMPORT_TYPES.COUNTERPARTIES:
      return importResult.counterparties?.length > 0;
  }
};

const SuccessList = ({ importType, importResult }: Results) => {
  switch (importType) {
    case IMPORT_TYPES.SALARY_EMPLOYEES:
    case IMPORT_TYPES.SALARY:
      return <SuccessEmployeesList demandType={importType} importResult={importResult} />;
    case IMPORT_TYPES.DOMESTIC_PAYMENTS:
    case IMPORT_TYPES.FOREIGN_PAYMENTS:
      return (
        <SuccessPaymentsList
          importType={importType}
          importResult={importResult as ImportPaymentsResponse}
        />
      );
    case IMPORT_TYPES.CURRENCY_BUY:
      return (
        <SuccessCurrencyExchangeList
          importType={importType}
          importResult={importResult as ImportPaymentsResponse}
        />
      );
    case IMPORT_TYPES.COUNTERPARTIES:
      return <SuccessCounterpartyList counterparties={importResult.counterparties} />;
  }
  return null;
};

const ErrorList = ({ importType, importResult }: Results) => {
  switch (importType) {
    case IMPORT_TYPES.SALARY_EMPLOYEES:
    case IMPORT_TYPES.SALARY:
      return <ErrorEmployeesList importResult={importResult} />;
    case IMPORT_TYPES.DOMESTIC_PAYMENTS:
    case IMPORT_TYPES.FOREIGN_PAYMENTS:
      return <ErrorPaymentsList errors={importResult.errors} />;
    case IMPORT_TYPES.CURRENCY_BUY:
      return <ErrorCurrencyExchangeImportList errors={importResult.errors} />;
    case IMPORT_TYPES.COUNTERPARTIES:
      return <ErrorCounterpartiesList errors={importResult.errors} />;
  }
  return null;
};

export const ImportPage = () => {
  const [importResult, setImportResult] = React.useState<ImportResult>(null);
  const importTypeRef = React.useRef<IMPORT_TYPES>();
  const tabsRef = React.useRef<TabsRef<Exclude<TabNames, TabNames.Details>>>();

  const hasSuccessfullyImported =
    importResult && isSuccessImported(importTypeRef.current, importResult);

  //TODO refactor this after new import implementation
  const hasImportedWithError =
    importResult && (importResult.errors?.length || importResult.hasErrors);

  const isEmptyResult = importResult && !hasSuccessfullyImported && !hasImportedWithError;
  importResult &&
    tabsRef.current.setActive(hasImportedWithError ? TabNames.WithErrors : TabNames.Successed);

  const setResult = ({ result, importType }: SetResultProps) => {
    importTypeRef.current = importType;
    setImportResult(result);
  };

  return (
    <Page title={translate('front.import-page.label')}>
      {/* @ts-ignore */}
      <Tabs ref={tabsRef}>
        <Tab
          label={translate('front.demands.salary-import.details.label')}
          value={TabNames.Details}
        >
          <ImportDetails setResult={setResult} />
        </Tab>
        <Tab
          label={translate('front.import-page.tab-success.label')}
          value={TabNames.Successed}
          isHidden={!importResult || !(hasSuccessfullyImported || isEmptyResult)}
        >
          <SuccessList importType={importTypeRef.current} importResult={importResult} />
        </Tab>
        <Tab
          label={translate('front.import-page.tab-errors.label')}
          value={TabNames.WithErrors}
          isHidden={!importResult || !hasImportedWithError}
        >
          <ErrorList importType={importTypeRef.current} importResult={importResult} />
        </Tab>
      </Tabs>
    </Page>
  );
};
