export enum CardFieldNames {
  Customer = 'customer',
  Card = 'card',
  BlockReason = 'blockReason',
  ReissueReason = 'reissueReason',
  Description = 'description',
  DocumentNotChanged = 'documentNotChanged',
  Problem = 'problem',
  CardAccount = 'cardAccount',
  CloseReason = 'closeReason',
  DoExternalFeeAcc = 'doExternalFeeAcc',
  ExternalFeeAccName = 'externalFeeAccName',
  ExternalFeeAccCode = 'externalFeeAccCode',
  ExternalFeeAccNumber = 'externalFeeAccNumber',
  TypePacket = 'typePacket',
  NewCardType = 'newCardType',
  SecretWord = 'secretWord',
  LastName = 'lastName',
  FirstName = 'firstName',
  MiddleName = 'middleName',
  Inn = 'inn',
  Sex = 'sex',
  BirthDay = 'birthDay',
  BirthCountry = 'birthСountry',
  Resident = 'resident',
  DocumentType = 'documentType',
  PassportSerial = 'passportSerial',
  PassportIssueDate = 'passportIssueDate',
  PassportIssuer = 'passportIssuer',
  PassportTerm = 'passportTerm',
  RegCountry = 'regCountry',
  RegRegion = 'regRegion',
  RegDistrict = 'regDistrict',
  RegPostCode = 'regPostCode',
  RegCity = 'regCity',
  RegStreet = 'regStreet',
  RegHouse = 'regHouse',
  RegFlat = 'regFlat',
  CoincidesResidenceAddress = 'label4',
  LiveCountry = 'liveCountry',
  LiveRegion = 'liveRegion',
  LiveDistrict = 'liveDistrict',
  LivePostCode = 'livePostCode',
  LiveCity = 'liveCity',
  LiveStreet = 'liveStreet',
  LiveHouse = 'liveHouse',
  LiveFlat = 'liveFlat',
  Branch = 'branch',
  BranchAdr = 'branchAdr',
  AddSMS = 'addSMS',
  Phone = 'phone',
  AcceptConditions = 'acceptConditions',
}
