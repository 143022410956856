import * as React from 'react';
import { TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';
import useClippy from 'use-clippy/use-clippy';

import { api } from 'api';
import { ExternalApiSession } from 'api/ProfileService';
import { SortOrder } from 'api/Service';
import { LinkButton } from 'components/buttons/LinkButton';
import { Status } from 'components/layout/Status';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { date } from 'components/utils/format';
import { translate } from 'i18n/translator';

import { ExternalApiDetails } from './ExternalApiDetails';
import { ExternalApiFilter } from './ExternalApiFilter';
import './styles.scss';

interface Props {
  token: string;
  disabled?: boolean;
}

const ExternalApiActions: React.FC<Props> = ({ token, disabled }) => {
  const [, setClipboardData] = useClippy();
  const [isCopied, setCopied] = React.useState(false);

  const onCopyToken = React.useCallback(() => {
    setCopied(true);
    setClipboardData(token);
    // unlock button in 5 sec
    setTimeout(() => setCopied(false), 5000);
  }, [token]);

  const onDownloadToken = React.useCallback(
    () => api.common.downloadData('API_TOKEN.txt', token),
    [token],
  );

  return (
    <div className="session-row__token-control">
      <LinkButton onClick={onDownloadToken} disabled={disabled}>
        {translate('front.profile.route-external-api.download-token')}
      </LinkButton>
      <LinkButton onClick={onCopyToken} disabled={isCopied || disabled}>
        {isCopied
          ? translate('front.profile.route-external-api.copied-token')
          : translate('front.profile.route-external-api.copy-token')}
      </LinkButton>
    </div>
  );
};

const columns: TableColumnProps<ExternalApiSession>[] = [
  {
    label: 'front.profile.route-external-api.status',
    render: row => (
      <DataColumn>
        <Status color={row.active ? 'green' : 'red'}>
          {row.active
            ? translate('front.profile.route-external-api.status.active')
            : translate('front.profile.route-external-api.status.blocked')}
        </Status>
      </DataColumn>
    ),
    sort: 'status',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.profile.route-external-api.token',
    render: row => (
      <DataColumn>
        <ExternalApiActions token={row.token} disabled={!row.active} />
      </DataColumn>
    ),
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.profile.route-external-api.request-interval',
    render: row => <DataColumn title={row.minRequestGap} />,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.profile.route-external-api.valid-to',
    render: row => <DataColumn title={date(row.valid_to)} />,
    sort: 'validTo',
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    renderMenu: () => [],
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

export const ExternalApiPage = () => (
  <DataTable
    name={TableNamesEnum.ExternalApiList}
    columns={columns}
    fetchRows={api.profile.getExternalApiSessions}
    defaultSort="id"
    defaultOrder={SortOrder.Desc}
    filterComponent={ExternalApiFilter}
    expandRowComponent={ExternalApiDetails}
  />
);
