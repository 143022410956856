import * as React from 'react';

export type Errors = { [key: string]: string };
export type SetFormDataFunc<T = any> = (formData: Partial<T>) => void;

export type Validate = (value: any, formData: FormState, fieldsData: FieldsData) => string | null;

export type FinalValue = any | any[];

export interface FieldData {
  defaultValue?: any;
  error?: string | null;
  isFieldArray?: boolean;
  validate?: Validate;
  value?: FinalValue;
  warn?: Validate;
  warning?: string | null;
}

export type FieldsData = {
  [key: string]: FieldData;
};

export type SetPayload<P> = React.Dispatch<React.SetStateAction<Partial<P>>>;
export type UpdateData<T> = (formData: Partial<T>, arrayFormData?: FormState) => void;

export type SubscriptionCbEffects<T, P> = {
  setPayload: SetPayload<P>;
  updateData: UpdateData<T>;
};

export type SubscriptionCb<T = FormState, P = Obj> = (
  data: { formData: T; path: string; payload: P; value: FinalValue },
  effects: SubscriptionCbEffects<T, P>,
) => void;
export type Subscriptions<T = FormState, P = Obj> = {
  [key: string]: SubscriptionCb<T, P>;
};

export type FormState = { [key: string]: FormState | FormState[] | FinalValue };

export type FlatFormState = { [key: string]: FinalValue };

export type FormPayload = { [key: string]: any };

export type HandleSubmitFn<T> = (onSubmit: (formData: T) => void, e?: any) => Promise<boolean>;

export interface FieldValidations {
  validate: Validate;
  warn: Validate;
}

export type FetchOnBlur = {
  func(formData: any): void;
  needToCall: boolean;
  updatedField: boolean;
};

export interface FormContextValue<T, P> {
  clearData: (isPreserveValidate?: boolean) => void;
  disabled: boolean;
  error: string | null;
  fetchOnBlur: FetchOnBlur;
  fieldsData: { [key: string]: FieldData };
  formId: string;
  getFieldErrors: () => FinalValue;
  getFieldValue: (path: string) => FinalValue;
  getFormData: () => T;
  handleError: (error: any) => void;
  handleSubmit: HandleSubmitFn<T>;
  // initialized: boolean;
  initializing: boolean;
  payload: P;
  progress: boolean;
  ready: boolean;
  registerField: (name: string, value: any, fieldValidating: FieldValidations) => void;
  registerFieldsArray: (name: string, validate: Validate) => void;
  removeField: (name: string) => void;
  resetData: () => void;
  setData: (formData: Partial<T>, arrayFieldsData?: Partial<T>) => void;
  setDisabled: (disabled: boolean) => void;
  setError: (error: string) => void;
  setErrors: (errors: Errors, isMerge: boolean) => void;

  setFieldsData: (fieldsData: FieldsData) => void;
  setInitialingCb: (callback: any) => (...args: any) => void;
  setInitializing: React.Dispatch<React.SetStateAction<boolean>>;

  setPayload: SetPayload<P>;
  setProgress: React.Dispatch<React.SetStateAction<boolean>>;
  subscribe: (path: string, cb: SubscriptionCb) => void;

  unregisterField: (name: string, isRemovable?: boolean, prevPathName?: string) => void;
  unsubscribe: (path: string) => void;
  updateData: UpdateData<T>;
  updateFetchOnBlur: (state: Partial<FetchOnBlur>) => void;
  updateField: (path: string, fieldData: FieldData) => void;
  updateFieldInData: (path: string, fieldName: string, value: any) => void;

  updateInput: (name: string, value: any) => void;
  validateField: (path: string, value: FinalValue) => string[];
}

export const FormContext = React.createContext<FormContextValue<any, any>>({
  formId: undefined,
  updateInput: () => {},
  registerField: () => {},
  unregisterField: () => {},
  registerFieldsArray: () => {},
  getFormData: () => ({}),
  getFieldValue: () => undefined,
  getFieldErrors: () => undefined,
  setProgress: () => {},
  validateField: () => [],
  updateField: () => {},
  setFieldsData: () => {},
  handleSubmit: () => Promise.resolve(true),
  handleError: () => {},
  setData: () => {},
  updateData: () => {},
  resetData: () => {},
  removeField: () => {},
  setDisabled: () => {},
  updateFieldInData: () => {},
  setError: () => {},
  setErrors: () => {},
  setInitializing: () => {},
  setInitialingCb: () => () => {},
  subscribe: () => {},
  unsubscribe: () => {},
  setPayload: () => {},
  clearData: () => {},
  fieldsData: {},
  payload: null,
  error: null,
  disabled: false,
  progress: false,
  // initialized: false,
  initializing: false,
  ready: false,

  fetchOnBlur: null,
  updateFetchOnBlur: () => {},
});
