import React from 'react';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { useDebouncedCallback } from 'use-debounce';

import { Option } from 'api/Service';
import {
  ClearIndicator,
  Input,
  Menu,
  MenuList,
  Options,
  selectStyles,
  ValueContainer,
} from 'components/forms/select/AutocompleteComponents';
import { AutocompleteProps } from 'components/forms/select/interfaces';
import {
  getDisplayedOptions,
  getFilteredOptions,
  getSelectedOptions,
  getValue,
  isOptionsEquals,
} from 'components/forms/select/utils';
import { translate } from 'i18n/translator';
import { DEBOUNCED_DELAY } from 'pages/Demands/CashTransferPage/useMaxLengthPurpose';
import { usePrevious } from 'utils/hooks';
import { isExist, isNumber, isString } from 'utils/isData';

import './styles.scss';

const defaultPlaceholder = 'front.form.default-select-placeholder.label';
const getEmptyOptionsMessage = () => translate('front.form.select-no-options.label');

export const Autocomplete = <Sort extends boolean = false>({
  name,
  value,
  onChange,
  placeholder: placeholderText,
  onBlur,
  disabled,
  multi,
  isSearchable = true,
  options,
  clearable = false,
  isMenuMaxContentWidth = true,
  onlySearch,
  maxWidth,
  hiddenTextLongOption,
  autoFocus,
  isCreatableSelect,
  sortParams,
}: AutocompleteProps<Sort>) => {
  const [inputValue, setInputValue] = React.useState<string>('');
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [filteredOptions, setFilteredOptions] = React.useState([]);

  const prevInputValue = usePrevious(inputValue);
  const definedValue = React.useMemo(
    () => getValue(multi, selectedOptions),
    [multi, value, selectedOptions],
  );

  const Select = (isCreatableSelect ? CreatableSelect : ReactSelect) as React.ElementType;

  const isAllSelected = React.useMemo(
    () =>
      multi && isOptionsEquals(selectedOptions, filteredOptions.length ? filteredOptions : options),
    [selectedOptions, filteredOptions, inputValue, options],
  );

  const placeholder = onlySearch
    ? translate('front.form.search-select-placeholder.label')
    : translate(placeholderText ?? defaultPlaceholder);

  const handleInputChange = (inputValue: string) => {
    sortParams?.resetSortedOptions();
    setInputValue(inputValue);
  };

  const handleUpdateSelectedOptions = (selectedOptions: Option<any, string | string[]>[]) =>
    setSelectedOptions(selectedOptions);
  const handleUpdateFilteredOptions = (filteredOptions: Option<any, string | string[]>[]) =>
    setFilteredOptions(filteredOptions);
  const handleCloseMenu = () => {
    sortParams?.resetSortedOptions();
    handleUpdateFilteredOptions([]);
  };
  const handleBlur = () => {
    onBlur?.();
    handleUpdateFilteredOptions([]);
  };

  const handleScroll = (e: any) =>
    e.target.classList && !e.target.classList.contains('autocomplete__menu-list');

  const debounced = useDebouncedCallback(handleScroll, DEBOUNCED_DELAY, { leading: true });

  const handleChange = (option: Option | Option[]) => {
    if (multi) {
      const values = option ? (option as Option[]).map(v => v.value) : [];

      handleUpdateSelectedOptions(option as Option[]);
      onChange(values);
    } else {
      handleUpdateSelectedOptions(option ? [option as Option] : []);
      onChange(option ? (option as Option).value : '');
    }
  };

  const displayedOptions = React.useMemo(
    () => getDisplayedOptions(options, onlySearch, inputValue, filteredOptions),
    [inputValue, filteredOptions.length, selectedOptions.length, options],
  );

  const updateFilteredOptions = React.useCallback(() => {
    const hasInputValue = isString(inputValue);

    if (!hasInputValue && !selectedOptions.length && isExist(filteredOptions)) {
      return handleUpdateFilteredOptions([]);
    }

    if (hasInputValue) {
      handleUpdateFilteredOptions(getFilteredOptions(options, inputValue));
    }
  }, [inputValue]);

  React.useEffect(() => {
    const isAllSelected =
      selectedOptions?.length === options?.length && filteredOptions?.length !== options?.length;

    if (isExist(prevInputValue) && prevInputValue !== inputValue) {
      updateFilteredOptions();
    }

    if (inputValue.length && isAllSelected) {
      handleChange([]);
    }
  }, [inputValue]);

  const prevSelectedOptions = usePrevious(selectedOptions);
  const prevValue = usePrevious(value);

  React.useEffect(() => {
    const hasValue = isExist(value) && (value.length > 0 || isNumber(value));

    if (!hasValue) {
      return handleUpdateSelectedOptions([]);
    }

    const values = multi ? value : [value];

    const isEmptyPrevSelectedOptions =
      !isExist(prevSelectedOptions) || prevSelectedOptions?.length === 0;

    const isUpdatedSelectedOptions = prevSelectedOptions !== selectedOptions;
    const isUpdatedValue = prevValue !== value;

    if ((!multi && isEmptyPrevSelectedOptions) || isUpdatedSelectedOptions || isUpdatedValue) {
      return handleUpdateSelectedOptions(getSelectedOptions(values, options));
    }

    if (multi && selectedOptions.length !== options.length && isUpdatedSelectedOptions) {
      handleUpdateSelectedOptions(isAllSelected ? options : getSelectedOptions(values, options));
    }
  }, [value, options]);

  return (
    <Select
      name={name}
      isClearable={clearable}
      isSearchable={isSearchable}
      isDisabled={disabled}
      styles={selectStyles(isMenuMaxContentWidth, maxWidth, hiddenTextLongOption)}
      onInputChange={handleInputChange}
      menuPortalTarget={document.getElementById('popupTarget')}
      options={displayedOptions}
      isMulti={multi}
      value={definedValue}
      onChange={handleChange}
      onBlur={handleBlur}
      autoFocus={autoFocus}
      placeholder={translate(placeholder)}
      noOptionsMessage={getEmptyOptionsMessage}
      formatCreateLabel={(value: string) => value}
      backspaceRemovesValue={false}
      onMenuClose={handleCloseMenu}
      components={{
        Input,
        ClearIndicator,
        MenuList,
        Option: Options,
        Menu,
        ValueContainer,
      }}
      className={`autocomplete select-${name}`}
      classNamePrefix="autocomplete"
      isValidNewOption={() => true}
      hideSelectedOptions={false}
      closeMenuOnSelect={!multi}
      closeMenuOnScroll={debounced}
      isAllSelected={isAllSelected}
      sortParams={sortParams}
    />
  );
};
