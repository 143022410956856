import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router';

import classnames from 'classnames';
import { Devices } from 'enums/DevicesEnum';
import { pathToRegexp } from 'path-to-regexp';
import { createStructuredSelector } from 'reselect';
import { v4 as uuidv4 } from 'uuid';

import { api } from 'api';
import { LogoutTimer } from 'api/backend';
import { constantsList } from 'api/ConstantService';
import { ConstantEnum } from 'api/enums';
import { useDevice } from 'components/layout/deviceResolver/useDevice';
import { ClockLoader } from 'components/Loader/Loader';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import withModal from 'components/modals/withModal';
import { getBackgroundLocation } from 'navigations/navigate';
import routes, { splitRoutes } from 'navigations/routes';
import { RequiredEmailModal } from 'pages/Dashboard/RequiredEmailModal';
import {
  calculateCurrentDevice,
  logoutOldSessionCb,
  openEndDateCustomersModals,
  setStoragesSessionInfo,
} from 'pages/Dashboard/utils';
import { OrganizationTimers } from 'pages/Login/CustomerTimers';
import { ConstantActions } from 'store/actions/constant';
import { UserActions } from 'store/actions/user';
import { LoginType } from 'store/reducers/user';
import {
  selectChosenCustomers,
  selectConstants,
  selectIsServerTimeInit,
  selectUser,
} from 'store/selectors';

import { NotFoundPage } from '../NotFound';
import { Header } from './Header/Header';
import { SideBar } from './SideBar';

import './styles.scss';

export const Dashboard = () => {
  const location = useLocation();

  const { user, constants, chosenCustomers, isServerTimeInit } = useSelector(
    // @ts-ignore
    createStructuredSelector({
      user: selectUser(),
      constants: selectConstants(),
      chosenCustomers: selectChosenCustomers(),
      isServerTimeInit: selectIsServerTimeInit(),
    }),
  );

  const dispatch = useDispatch();

  const [currentDevice, setCurrentDevice] = React.useState<Devices>(calculateCurrentDevice());

  const setLoginTypeReload = () =>
    dispatch(UserActions.setUser({ ...user, loginType: LoginType.RELOAD }));

  React.useEffect(() => {
    onMount();
    fetchRequiredEmailLetters();
  }, []);

  const fetchRequiredEmailLetters = async () => {
    const letters = await api.mail.getRequiredLetters();

    for (const letter of letters) {
      const shouldRenderNextLetter = await confirmModal(RequiredEmailModal, { letter });

      if (!shouldRenderNextLetter) break;
    }
  };

  const onMount = async () => {
    setStoragesSessionInfo({
      userId: `${user.currentAuthPerson}`,
      appId: uuidv4(),
    });
    // Logout if opened new session
    window.addEventListener('storage', logoutOldSessionCb);

    const classes = ['fix-header', 'fix-sidebar', 'card-no-border'];
    document.body.classList.add(...classes);
    LogoutTimer.startTimers();
    startTimersCustomers();

    if (Object.entries(constants).length < 1) {
      const fetchedConstants = await api.constant.getConstantsList(constantsList);

      dispatch(ConstantActions.setConstants(fetchedConstants));
    }

    await openEndDateCustomersModals(user, constants[ConstantEnum.CompanyPersonDaysToExpire]);
    setLoginTypeReload();

    // Calculate Device SIZE
    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      document.body.classList.remove(...classes);
      LogoutTimer.resetTimerModal();
      OrganizationTimers.resetTimers();
      // removeEventListener('error', logErrorCb);
      removeEventListener('storage', logoutOldSessionCb);
    };
  };

  const onResize = () => {
    setCurrentDevice(calculateCurrentDevice());
  };

  const startTimersCustomers = async () => {
    const { customers } = await api.user.getCustomersInfo();
    OrganizationTimers.startTimers(
      customers,
      chosenCustomers.map(c => c.id),
    );
  };

  const device = useDevice();

  const menu = React.useMemo(() => routes(user.privileges), [user]);
  const { modalRoutes, noModalRoutes } = React.useMemo(
    () => splitRoutes(user.privileges, device),
    [user, device],
  );

  const [noModal, modal] = React.useMemo(() => {
    const noModal = noModalRoutes.map(({ exact, path, component }, key) => (
      <Route key={`nm-route-${key}`} exact={!!exact} path={path} component={component} />
    ));

    const modal = modalRoutes.map(({ exact, path, component, width }, key) => (
      <Route
        key={`m-route-${key}`}
        exact={!!exact}
        path={path}
        component={withModal(component, width)}
      />
    ));

    return [noModal, modal];
  }, [modalRoutes, noModalRoutes]);

  const [backgoundLocation, isModal] = React.useMemo(() => {
    const backgoundLocation = getBackgroundLocation();
    const currentRoute = modalRoutes.find(route =>
      pathToRegexp(route.path).test(location.pathname),
    );
    const isModal = currentRoute && backgoundLocation.pathname !== location.pathname;

    return [backgoundLocation, isModal];
  }, [location]);

  const isApplicationInit =
    user.customers.length && Object.keys(constants).length && isServerTimeInit;

  if (!isApplicationInit) {
    return (
      <div className="dashboard-wrapper">
        <ClockLoader size={60} loading />
      </div>
    );
  }

  return (
    <div id="main-wrapper" className={classnames(device, 'main-wrapper')}>
      <SideBar menu={menu} currentDevice={currentDevice} />
      <div className="page-wrapper">
        <Header currentDevice={currentDevice} />
        <div className="container-fluid">
          <Switch location={isModal ? backgoundLocation : location}>
            {noModal}
            <Route component={NotFoundPage} />
          </Switch>
          {modal}
        </div>
      </div>
    </div>
  );
};
