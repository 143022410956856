import * as React from 'react';
import { Action, TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { Notification } from 'api/NotificationService';
import { SortOrder } from 'api/Service';
import { Checked } from 'components/icons';
import { Page } from 'components/layout/Page/Page';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { translate } from 'i18n/translator';
import { isActionAllowed } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';

import { NotificationsFilter } from './NotificationFilter';

const isAllowEditActions = ({ customerId, type }: Notification): boolean =>
  type.isEditableByClient && isActionAllowed([Privileges.contactNotificationEdit], customerId);

const onRowClick = (row: Notification) =>
  isAllowEditActions(row) && goto(pages.editNotification(String(row.id)));

const columns: TableColumnProps<Notification>[] = [
  {
    label: 'front.notifications.col-product-alert.label',
    render: row => <DataColumn title={row.type.name} />,
    sort: 'name',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.notifications.col-account.label',
    render: row => <DataColumn title={row.accountIban} />,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'sms',
    render: row => <DataColumn title={!!row.sms && <Checked />} />,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'email',
    render: row => <DataColumn title={!!row.email && <Checked />} />,
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.notification.internal.label',
    render: row => <DataColumn title={!!row.internal && <Checked />} />,
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    renderMenu: row => [
      {
        label: 'front.working-documents-table.actions-edit.label',
        onClick: () => goto(pages.editNotification(String(row.id))),
        isReloadable: true,
        isVisible: isAllowEditActions(row),
      },
      {
        label: 'front.working-documents-table.actions-delete.label',
        onClick: async () => await api.notification.deleteNotificationTarget(row.id),
        confirmMessage: 'front.notification-modal.confirm-delete.label',
        isReloadable: true,
        isVisible: isAllowEditActions(row),
      },
    ],
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

const actions: Action<Notification>[] = [
  {
    label: 'front.notifications.delete.label',
    action: async (selected: Notification[]) =>
      await Promise.all(selected.map(n => api.notification.deleteNotificationTarget(n.id))),
    confirmMessage: 'front.notification-modal.confirm-delete.label',
    isReloadable: true,
    isVisible: (selected: Notification[]) => !!selected.filter(isAllowEditActions).length,
  },
];

export const NotificationsPage = () => (
  <Page title={translate('front.configuring-notifications.title.label')}>
    <DataTable
      name={TableNamesEnum.NotificationsPage}
      columns={columns}
      fetchRows={api.notification.getNotificationData}
      defaultSort="id"
      defaultOrder={SortOrder.Desc}
      onRowClick={onRowClick}
      actions={actions}
      filterComponent={NotificationsFilter}
      isRowDisabled={row => !isAllowEditActions(row)}
      hidePagination
    />
  </Page>
);
