import React from 'react';
import { Col, Row, Container } from 'react-grid';

import moment from 'moment';

import { api } from 'api';
import {
  expensesOptions,
  FinProfile,
  incomeOptions,
  planningOptions,
  realEstateOptions,
  RegistrationSteps,
  sourcesOfIncomeOptions,
} from 'api/RegistrationService';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { DateField } from 'components/forms/inputs/DateField';
import { HiddenField } from 'components/forms/inputs/HiddenField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextField } from 'components/forms/inputs/TextField';
import { ArrayField } from 'components/forms/ValidatingForm/components/ArrayField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import * as Icons from 'components/icons';
import { translate } from 'i18n/translator';
import { RegistrationContext } from 'pages/Registration/RegistrationContext';

const FinProfilePageForm: React.FC = () => {
  const { onConfirmFinProfile } = useFinProfile();

  const onRegister = async (formData: FinProfile) => {
    await onConfirmFinProfile(formData);
  };

  const { progress, error, handleSubmit, getFieldValue, updateData } = useForm<FinProfile>();

  const licenses = getFieldValue('licenses') as boolean;

  return (
    <div style={{ position: 'initial' }}>
      <div className="login-page__body__box card">
        <div className="card-body">
          <div className="login-page__body__box__title">
            {translate('front.registration.fin-profile-page.title.label')}
          </div>
          <DefaultForm>
            <FormError>{error}</FormError>
            <CheckboxField
              label="front.registration.fin-profile-page.field-public-person.label"
              name="publicPerson"
            />
            <HiddenField name="kveds" defaultValue={true} />
            <Container>
              <Row>
                <Col md={12}>
                  <SelectField
                    label="front.registration.fin-profile-page.field-has-real-estate.label"
                    name="hasRealEstate"
                    multi
                    options={realEstateOptions}
                    required
                  />
                </Col>
                <Col md={12}>
                  <SelectField
                    label="front.registration.fin-profile-page.field-total-income.label"
                    name="totalIncome"
                    options={incomeOptions}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <SelectField
                    label="front.registration.fin-profile-page.field-sources-of-income.label"
                    name="sourcesOfIncome"
                    options={sourcesOfIncomeOptions}
                    required
                  />
                </Col>
                <Col md={12}>
                  <SelectField
                    label="front.registration.fin-profile-page.field-expenses.label"
                    name="expenses"
                    options={expensesOptions}
                    required
                  />
                </Col>
              </Row>
              <CheckboxField
                label="front.registration.fin-profile-page.field-another-accounts.label"
                name="anotherAccounts"
              />
              <Row>
                <Col md={12}>
                  <SelectField
                    label="front.registration.fin-profile-page.field-planning.label"
                    name="planning"
                    multi
                    options={planningOptions}
                  />
                </Col>
              </Row>
              <CheckboxField
                label="front.registration.fin-profile-page.field-licenses.label"
                name="licenses"
                onChange={value => {
                  if (value) {
                    updateData(
                      {},
                      {
                        additionalQuestions: [
                          {
                            name: '',
                            series: '',
                            number: '',
                            issuedBy: '',
                            expiringDate: null,
                          },
                        ],
                      },
                    );
                  }
                }}
              />
              {licenses && (
                <ArrayField
                  name="additionalQuestions"
                  renderContainer={(items, push) => (
                    <>
                      <Button color="primary" onClick={push} size="sm">
                        {translate('front.registration.fin-profile-page.button-add.label')}
                      </Button>
                      {items}
                    </>
                  )}
                  renderItem={(prefix, index, remove) => (
                    <Row key={`account-${index}`}>
                      <Col md={12}>
                        <TextField
                          label="front.registration.fin-profile-page.field-name.label"
                          name={`${prefix}[${index}].name`}
                          required
                        />
                      </Col>
                      <Col md={12}>
                        <TextField
                          label="front.registration.fin-profile-page.field-series.label"
                          name={`${prefix}[${index}].series`}
                          required
                        />
                      </Col>
                      <Col md={12}>
                        <TextField
                          label="front.registration.fin-profile-page.field-number.label"
                          name={`${prefix}[${index}].number`}
                          required
                        />
                      </Col>
                      <Col md={12}>
                        <TextField
                          label="front.registration.fin-profile-page.field-issued-by.label"
                          name={`${prefix}[${index}].issuedBy`}
                          required
                        />
                      </Col>
                      <Col md={12}>
                        <DateField
                          label="front.registration.fin-profile-page.field-expiring-date.label"
                          name={`${prefix}[${index}].expiringDate`}
                          defaultValue={moment().toDate()}
                        />
                      </Col>
                      {!!index && (
                        <Col md={1}>
                          <Button close onClick={() => remove()}>
                            <Icons.Delete />
                          </Button>
                        </Col>
                      )}
                    </Row>
                  )}
                />
              )}
              <Button
                color="primary"
                onClick={e => handleSubmit(onRegister, e)}
                progress={progress}
                className="login-page__form__btn"
                type="submit"
                size="lg"
              >
                {translate('front.registration.fin-profile-page.button-continue.label')}
              </Button>
            </Container>
          </DefaultForm>
        </div>
      </div>
    </div>
  );
};

export const FinProfilePage = withForm(FinProfilePageForm);

const useFinProfile = () => {
  const { orderId, uuid, setStep } = React.useContext(RegistrationContext);

  const onConfirmFinProfile = async (formData: FinProfile) => {
    const response = await api.registration.confirmFinProfile(orderId, uuid, formData);
    switch (response.orderState) {
      case RegistrationSteps.DoCreateClient: {
        setStep(await api.registration.createClient(orderId, uuid));
        break;
      }
      default: {
        setStep(response);
      }
    }
  };

  return { onConfirmFinProfile };
};
