import React from 'react';

import { compareNumber, compareString, SortOrder } from 'api/Service';
import { Autocomplete } from 'components/forms/select/Autocomplete';
import { AutocompleteProps, SortColumn } from 'components/forms/select/interfaces';
import { isExist, isNumber, isString } from 'utils/isData';

interface SortParams {
  columns: SortColumn[];
  defaultSort: string;
}

interface AutocompleteWithSortingParams extends Omit<AutocompleteProps, 'sortParams'> {
  sortParams: SortParams;
}

export const AutocompleteWithSorting = (
  allProps: AutocompleteWithSortingParams,
): React.ReactElement => {
  const {
    sortParams: { defaultSort, columns },
    options,
    ...props
  } = allProps;

  const [sort, setSort] = React.useState<SortColumn[]>(columns);
  const [sortOptions, setSortOptions] = React.useState<AutocompleteWithSortingParams['options']>();
  const [currentSort, setCurrentSort] = React.useState<string>(defaultSort);

  const resetSortedOptions = () => setSortOptions(options);

  const updateSort = (name: string, order: SortOrder) =>
    setSort(prev => prev.map(column => (column.name === name ? { ...column, order } : column)));

  const handleSort =
    (options: AutocompleteWithSortingParams['options']) => (name: string, order: SortOrder) => {
      setCurrentSort(name);
      updateSort(name, order);

      updateSortedOptions(name, order, options);
    };

  React.useEffect(() => {
    const defaultSortColumn = sort.find(({ name }) => name === defaultSort);
    updateSortedOptions(defaultSortColumn.name, defaultSortColumn.order, options);
  }, [options]);

  const updateSortedOptions = (
    name: string,
    order: SortOrder,
    options: AutocompleteWithSortingParams['options'],
  ): void => {
    if (isExist(options)) {
      const isStringValue = options.some(
        ({ content }) =>
          isExist(content?.[name]) && isString(content?.[name]) && !isNumber(content?.[name]),
      );

      const compare: (a: any, b: any) => number = isStringValue ? compareString : compareNumber;

      const sortedOptions = options.sort((a, b) =>
        order === SortOrder.Desc
          ? compare(b?.content?.[name] ?? '', a?.content?.[name] ?? '')
          : compare(a?.content?.[name] ?? '', b?.content?.[name] ?? ''),
      );

      setSortOptions(sortedOptions);
    }
  };

  return (
    <Autocomplete<true>
      {...props}
      options={sortOptions}
      sortParams={{ handleSort, columns: sort, currentSort, resetSortedOptions }}
    />
  );
};
