import { checkMultiCustomer } from 'api/ConstantService';
import { ProductStatus } from 'api/ProductsService';

import { downloadFile, get } from './backend';
import { GridRequest, GridResponse, Option } from './Service';

export interface Terminal {
  baseName: string;
  baseTerminal: string;
  currency: string;
  externalId: string;
  id: number;
  isDeleted: boolean;
  isMultiMerchant: boolean;
  productStatus: ProductStatus;
  projectId: string;
  taxCode: string;
  terminalStatus: string;
  tradePointId: number;
}

interface TradePoint {
  accountNumber: string;
  address: string;
  customerName: string;
  deleted: boolean;
  externalId: string;
  id: number;
  isDeleted: boolean;
  projectId: string;
  status: string;
  taxCode: string;
  tradePointName: string;
}

interface AcquiringStatementsFilter extends GridRequest {
  amountFrom: number;
  amountTo: number;
  refundDateFrom: Date;
  refundDateTo: Date;
  terminalIds: Array<string | number>;
  customerIds?: string[];
  tradePointId?: string;
  tradePointIds?: string[];
}

export interface AcquiringStatement {
  amount: number;
  amountNational: number;
  authCode: number;
  cardNumber: number;
  comissionAmount: number;
  createTime: string;
  currency: string;
  customerId: number;
  customerName: string;
  customerTaxCode: string;
  cycleDate: string;
  executeTime: string;
  id: number;
  productId: number;
  refundAmount: number;
  refundDate: string;
  rrn: string;
  status: string;
  terminalAddress: string;
  terminalCode: string;
  terminalId: number;
  terminalMasterId: number;
  terminalName: string;
  tradePointAddress: string;
  tradePointCode: string;
  tradePointId: number;
  tradePointMasterId: number;
  tradePointName: string;
}

export class AcquiringService {
  async getTradePoints(
    request: GridRequest & { customerIds: Array<string> },
  ): Promise<GridResponse<TradePoint>> {
    return await get('/v2/acquiring/get-trade-points', request);
  }

  async getTradePointOptions(
    request: GridRequest & { customerIds: Array<string> },
  ): Promise<Option[]> {
    const tradePoints = await this.getTradePoints(request);
    return tradePoints.rows.map(item => ({
      value: `${item.id}`,
      label: item.tradePointName,
    }));
  }

  async getAcquiringStatements(
    request: AcquiringStatementsFilter,
  ): Promise<GridResponse<AcquiringStatement>> {
    request.tradePointIds = Array.isArray(request.tradePointId)
      ? request.tradePointId
      : [request.tradePointId];
    return await get('/v1/products/acquaring/transactions', checkMultiCustomer(request));
  }

  async exportAcquiringStatements(
    filter: AcquiringStatementsFilter & { exportType: string },
  ): Promise<any> {
    return downloadFile('/v1/acquiring/statements/export', {}, checkMultiCustomer(filter));
  }

  async getTerminals(shopId: number, request?: GridRequest): Promise<Terminal[]> {
    return await get('/v1/acquiring/get-terminals', checkMultiCustomer({ shopId, ...request }));
  }
}
