import React from 'react';
import { GoogleMap, withScriptjs, withGoogleMap } from 'react-google-maps';

import config from 'config';

export interface Position {
  lat: number;
  lng: number;
}

interface Props {
  center: Position;
  children?: React.ReactNode;
  zoom?: number;
}

const defaultZoom = config.page.department.defaultZoom;
const apiKey = config.page.department.googleApiUrl;

const MapComponent = withScriptjs(
  withGoogleMap<Props>(({ zoom = defaultZoom, center, children }) => (
    <GoogleMap
      zoom={zoom}
      center={center}
      options={{ streetViewControl: false, mapTypeControl: false }}
    >
      {children}
    </GoogleMap>
  )),
);

export const Map = (props: Props) => (
  <MapComponent
    googleMapURL={apiKey}
    containerElement={<div style={{ height: '500px' }} />}
    loadingElement={<div style={{ height: '100%' }} />}
    mapElement={<div style={{ height: '100%' }} />}
    {...props}
  />
);
