import * as React from 'react';

import { ExportStatementRequest } from 'api/AccountService';
import { ExportFormatEnum } from 'components/ExportDropdown/enum';

export interface ModalExportProps {
  exportData: ExportStatementRequest;
  exportType: ExportFormatEnum;
}

export enum ExportWays {
  OneFile = 'oneFile',
  OneAccountInOneFile = 'oneAccountInOneFile',
  OneAccountOneDateInOneFile = 'oneAccountOneDateInOneFile',
}

export interface ExportWayOption {
  icon: React.FunctionComponentElement<any>;
  label: string;
  type: ExportWays;
}

export type IconOptionType = Partial<Record<ExportWays, React.FunctionComponentElement<any>>>;

export interface DisablingWays {
  [ExportWays.OneFile]?: boolean;
  [ExportWays.OneAccountInOneFile]?: boolean;
  [ExportWays.OneAccountOneDateInOneFile]?: boolean;
}
