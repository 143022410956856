import * as React from 'react';
import { Input as BsInput } from 'reactstrap';

import classNames from 'classnames';

import { PreloadField } from 'components/forms/inputs/PreoadField';
import { TextFieldProps } from 'components/forms/inputs/types';
import { useField } from 'components/forms/ValidatingForm/components/useField';
import { validateMessages } from 'components/validateMessages';
import { translate } from 'i18n/translator';
import './embedded-edit-field.scss';

export const REGEX_TEMPLATE_NAME = /^[ a-zA-Z0-9а-яА-ЯіїєёґІЇЁЄҐ!"#'()№+,\\\/_.-]+$/;

interface Props extends TextFieldProps {
  label: string;
  name: string;
  defaultValue?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  placeholder?: string;
  required?: boolean;
  validate?: (value: any) => string | null;
}

export const EmbeddedEditField = ({
  name,
  label,
  required,
  minLength,
  maxLength,
  length,
  disabled,
  defaultValue,
  placeholder,
  onChange,
}: Props) => {
  const [isShowField, setIsShowField] = React.useState(false);

  const validate = React.useCallback(
    value => {
      if (required) {
        if (!value) {
          return translate(validateMessages.fieldRequired);
        }
      }

      if (!REGEX_TEMPLATE_NAME.test(value)) {
        return translate(validateMessages.invalidDocNumber);
      }

      if (maxLength) {
        if (value.length > maxLength) {
          return translate(validateMessages.moreThan(maxLength));
        }
      }

      if (minLength) {
        if (value.length < minLength) {
          return translate(validateMessages.lessThan(minLength));
        }
      }

      if (length) {
        if (value.length !== length) {
          return translate(validateMessages.shouldBe(length));
        }
      }

      return;
    },
    [required, maxLength, minLength, length],
  );

  const onTextFieldChange = React.useCallback((e, onBaseChange) => {
    const value = e?.target?.value || '';
    onBaseChange(value);
    if (onChange) onChange(value);
  }, []);

  const { field, form } = useField({ name, defaultValue, validate });

  if (!field && !form) {
    return null;
  }

  return (
    <PreloadField height={20} width={140} isLoading={form.initializing}>
      <span className="edit-field">
        {translate(label)}
        <BsInput
          id={name}
          type="text"
          className={classNames({ 'edit-field__enabled': isShowField })}
          placeholder={placeholder}
          value={field.value || ''}
          disabled={disabled || form.disabled || form.progress}
          onChange={(e: any) => onTextFieldChange(e, field.onChange)}
          onFocus={() => setIsShowField(true)}
          onBlur={() => {
            setIsShowField(false);
            field.onBlur();
          }}
          style={{
            width: `${field.value ? ((field.value as string).length + 1) * 8 + 8 : 20}px`,
          }}
        />
        {!disabled && !form.disabled && (
          <svg
            className="show-field__icon"
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              // tslint:disable-next-line
              d="M3.30934 7.36254C2.74403 7.90082 2.74403 8.80256 3.30934 9.34084C3.8349 9.84128 4.66006 9.84334 5.18811 9.34553L9.00252 5.74961C9.57792 5.20716 9.5802 4.29293 9.00751 3.74762C8.47499 3.24056 7.63827 3.24056 7.10575 3.74762L3.30934 7.36254ZM2.2039 9.04524C2.0186 8.8688 1.72745 8.8688 1.54215 9.04524C1.44703 9.13581 1.3932 9.2614 1.3932 9.39273V9.76182C1.3932 10.5034 1.99513 11.1042 2.73671 11.1027L3.12979 11.102C3.25543 11.1017 3.37625 11.0536 3.46766 10.9674C3.67378 10.7731 3.6746 10.4456 3.46945 10.2503L2.2039 9.04524ZM9.42471 1.53953C8.89653 2.04246 8.89653 2.88496 9.42471 3.38788L9.50919 3.46833C10.043 3.9766 10.881 3.97869 11.4174 3.47309L11.4429 3.44904C11.6623 3.24218 11.6621 2.90687 11.4407 2.69608L10.2258 1.53929C10.004 1.32804 9.64656 1.32828 9.42471 1.53953ZM9.02721 1.16104C9.46803 0.741289 10.1814 0.739976 10.6233 1.16079L11.8382 2.31759C12.2789 2.7372 12.2802 3.41289 11.8384 3.82942L4.11885 11.1068C3.75794 11.447 3.28094 11.6369 2.78494 11.6379L2.70551 11.6381C1.67087 11.6401 0.831055 10.8019 0.831055 9.76724C0.831055 9.25517 1.04095 8.76549 1.41179 8.41238L9.02721 1.16104Z"
              fill="#858A93"
              stroke="#858A93"
              strokeWidth="0.5"
            />
          </svg>
        )}
        {field.error && <div className="edit-field__error form-input-error">{field.error}</div>}
      </span>
    </PreloadField>
  );
};
