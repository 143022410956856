import * as React from 'react';

import { OrderState } from 'api/enums';
import { Order } from 'api/OrderService';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { createPurpose } from 'pages/Payments/InternalPayment/utils';
import { PaymentRequestDetailsFields } from 'pages/Payments/PaymentRequest/interfaces';
import { usePrevious } from 'utils/hooks';
import { isExist } from 'utils/isData';

export interface UseUpdatePurposeProps {
  order: Order;
  vatPercent: number | string;
}

export const useUpdatePurpose = ({ order, vatPercent }: UseUpdatePurposeProps) => {
  const { getFormData, updateData } = useForm<PaymentRequestDetailsFields>();
  const prevVatPercent = usePrevious(vatPercent);

  const { amount, vatType, purpose } = getFormData();
  const updatePurpose = () => {
    if (!isExist(vatType) || !isExist(amount)) {
      return;
    }

    const newPurpose = createPurpose({
      vatType,
      purpose,
      amount,
      vatPercent,
      prevVatPercent,
    });

    queueMicrotask(() => {
      updateData({ purpose: newPurpose });
    });
  };

  React.useEffect(() => {
    const isDraft = order && (order.state === OrderState.Draft || !order.state);

    if (isDraft && vatType) {
      updatePurpose();
    }
  }, [amount, vatType, vatPercent, prevVatPercent, order]);
};
