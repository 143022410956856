import React from 'react';
import DayPicker, { BeforeAfterModifier, DayModifiers, DayPickerProps } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import MaskedInput from 'react-text-mask';

import classNames from 'classnames';
import moment from 'moment';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

import { Clear } from 'components/icons';
import * as format from 'components/utils/format';
import config from 'config';
import { translate } from 'i18n/translator';
import { LOCAL_STORAGE_KEY } from 'store/actions/types';
import { LocalStorage } from 'utils/LocalStorage';

import { MonthYearSelect } from './MonthYearSelect';
import { NavBarNavigation } from './NavBarNavigation';
import './styles.scss';

interface Props extends DayPickerProps {
  onChange(value: string): void;
  clearable?: boolean;
  disabled?: boolean;
  initialDate?: Date;
  isEditable?: boolean;
  maxDate?: Date;

  minDate?: Date;

  onBlur?(event?: any): void;

  pipConfig?: {
    maxYear: number;
    minYear: number;
  };

  placeholder?: string;

  value?: string;
}

interface State {
  isOpen: boolean;
  month?: Date;
}

const autoCorrectedDatePipe = (config: { maxYear: number; minYear: number }) =>
  createAutoCorrectedDatePipe('dd.mm.yyyy', {
    minYear: config.minYear,
    maxYear: config.maxYear,
  });

const { MIN_YEAR, MAX_YEAR } = config.components.datePicker;

export class DatePicker extends React.PureComponent<Props, State> {
  static defaultProps = {
    value: '',
    isEditable: false,
    clearable: false,
    disabled: false,
    onBlur: () => {},
    placeholder: '',
    minDate: new Date(MIN_YEAR, 0),
    maxDate: new Date(MAX_YEAR, 11),
    pipConfig: {
      minYear: MIN_YEAR,
      maxYear: MAX_YEAR,
    },
  };

  state: State = {
    isOpen: false,
    month: null,
  };

  onFocus = () => {
    this.setState({ isOpen: true });
  };

  onClickArea = (e: React.MouseEvent<HTMLDivElement>) => {
    e && e.preventDefault();
    this.setState({ isOpen: false }, this.props.onBlur);
  };

  onDayClick = (value: Date, modifires: DayModifiers, e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!modifires.disabled) {
      this.props.onChange(format.date(value));
      this.setState({ isOpen: false }, () => {
        this.props.onBlur();
      });
    }
  };

  onYearMonthChange = (month: Date) => {
    this.setState({ month });
  };

  onChange = (e: any) => {
    const { value } = e.target;
    this.props.onChange(value);
  };

  onClear = (e: any) => {
    e.preventDefault();
    this.props.onChange(null);
  };

  render() {
    const {
      placeholder,
      value,
      disabled,
      disabledDays,
      minDate,
      maxDate,
      initialDate,
      pipConfig,
      isEditable,
      clearable,
    } = this.props;
    const { isOpen, month } = this.state;
    const locale = LocalStorage.getItem(
      LOCAL_STORAGE_KEY.ACTIVE_LOCALE,
      config.i18n.defaultLanguageCode,
    );
    const date = format.getValidDate(value);

    return (
      <>
        <div className="date-field date-input">
          <div
            className="click-outside"
            onClick={this.onClickArea}
            style={{ display: isOpen ? 'block' : 'none' }}
          />
          <MaskedInput
            mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
            className={classNames('form-control', 'input-date', 'date-input__input', {
              'input-date__active': !disabled,
            })}
            value={value}
            onChange={this.onChange}
            disabled={disabled}
            keepCharPositions={true}
            pipe={autoCorrectedDatePipe(pipConfig)}
            placeholder={translate(placeholder)}
            onFocus={this.onFocus}
            readOnly={!isEditable}
          />
          {value && clearable ? (
            <div className="date-input__clear" onClick={this.onClear}>
              <Clear />
            </div>
          ) : null}
          {isOpen ? (
            <span className="date-input__wrap-picker">
              <DayPicker
                className="date-input__picker"
                locale={locale}
                localeUtils={MomentLocaleUtils}
                onDayClick={this.onDayClick}
                selectedDays={date}
                disabledDays={disabledDays}
                month={
                  month ||
                  (disabledDays as BeforeAfterModifier)?.before ||
                  date ||
                  initialDate ||
                  (disabledDays as BeforeAfterModifier)?.after ||
                  moment().toDate()
                }
                fromMonth={minDate}
                toMonth={maxDate}
                navbarElement={<NavBarNavigation />}
                captionElement={props => (
                  <MonthYearSelect
                    {...props}
                    onChange={this.onYearMonthChange}
                    fromMonth={minDate}
                    toMonth={maxDate}
                  />
                )}
              />
            </span>
          ) : null}
        </div>
      </>
    );
  }
}
