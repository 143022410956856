import * as React from 'react';

import { translate } from 'i18n/translator';

export const RegistrationExecutedPage: React.FC = () => {
  return (
    <div className="">
      <div className="login-page__body__box card">
        <div className="card-body">
          <div className="no-connection">
            <div className="no-connection__error">
              <h1 className="no-connection__error_title">
                {translate('front.registration.executed-page.title.label')}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
