import React from 'react';

import { Option } from 'api/Service';
import { MaskField, normalizeIban } from 'components/forms/inputs/MaskField';
import { RadioField } from 'components/forms/inputs/RadioField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextField } from 'components/forms/inputs/TextField';
import config from 'config';
import {
  ChangingDepositRadioMainAccFieldsNames,
  DepositDetailNames,
} from 'pages/Demands/DepositDemand/enums';
import {
  isRadioFieldChecked,
  SWIFT_ALLOWED_CHARACTERS_REGEXP,
  swiftValidation,
} from 'pages/Demands/DepositDemand/utils';
import { validateIban } from 'pages/Demands/utils';

const { ibanMask } = config.page.domesticPayment;

interface DepositComponentProps {
  ownMainAccounts: Option[];
  radioMainAcc: string;
  radioMainAccounts: Option[];
}

export const ChangeMainAccDetail = ({
  radioMainAccounts,
  ownMainAccounts,
  radioMainAcc,
}: DepositComponentProps) => {
  const [isExternalAcc, isExternalSwiftAcc, isOwnAcc] = React.useMemo(
    () =>
      Object.values(ChangingDepositRadioMainAccFieldsNames).map(field =>
        isRadioFieldChecked(radioMainAccounts, radioMainAcc, field),
      ),
    [radioMainAcc],
  );

  return (
    <>
      <RadioField
        name={DepositDetailNames.RadioMainAcc}
        options={radioMainAccounts}
        cssType="block"
        required
      />
      {isExternalAcc && (
        <MaskField
          name={DepositDetailNames.ExternalMainNationalAccount}
          label="front.changing-deposit-demand.externalMainNationalAccount.label"
          normalize={normalizeIban}
          validate={validateIban}
          mask={ibanMask}
          required
        />
      )}
      {isExternalSwiftAcc && (
        <>
          <TextField
            name={DepositDetailNames.ExternalMainAccCorrBank}
            label="front.changing-deposit-demand.externalMainAccCorrBank.label"
            required
          />
          <TextField
            name={DepositDetailNames.ExternalMainAccCorrSwiftCode}
            label="front.changing-deposit-demand.externalMainAccCorrSwiftCode.label"
            maxLength={34}
            validate={swiftValidation}
            allowedCharacters={SWIFT_ALLOWED_CHARACTERS_REGEXP}
            required
          />
          <TextField
            name={DepositDetailNames.ExternalMainAccBenefBank}
            label="front.changing-deposit-demand.externalMainAccBenefBank.label"
            required
          />
          <TextField
            name={DepositDetailNames.ExternalMainAccCode}
            label="front.changing-deposit-demand.externalMainAccCode.label"
            maxLength={34}
            validate={swiftValidation}
            allowedCharacters={SWIFT_ALLOWED_CHARACTERS_REGEXP}
            required
          />
          <TextField
            name={DepositDetailNames.ExternalMainAccNumber}
            label="front.changing-deposit-demand.externalMainAccNumber.label"
            maxLength={34}
          />
          <TextField
            name={DepositDetailNames.ExternalMainAccName}
            label="front.changing-deposit-demand.externalMainAccName.label"
            maxLength={34}
            required
          />
          <TextField
            name={DepositDetailNames.ExternalMainAccAddress}
            label="front.changing-deposit-demand.externalMainAccAddress.label"
            required
          />
        </>
      )}
      {isOwnAcc && (
        <SelectField
          name={DepositDetailNames.OwnMainAccount}
          label="front.changing-deposit-demand.ownMainAccount.label"
          options={ownMainAccounts}
          required
        />
      )}
    </>
  );
};
