export enum DemandGroupTypes {
  CardOrders = 'CardOrders',
  DepositOrders = 'DepositOrders',
  CreditOrders = 'CreditOrders',
  SalaryDemands = 'SalaryDemands',
  CashOperationOrders = 'CashOperationOrders',
}

export enum CashTransferAccounts {
  cashTransferAccounts = 'CASH_TRANSFER_ACCOUNTS',
  cashWithdrawalAccounts = 'CASH_WITHDRAWAL_ACCOUNTS',
  cashPreparationAccounts = 'CASH_PREPARATION_ACCOUNTS',
}

export enum VisualComponentEnum {
  SELECT = 'SELECT',
  LABEL = 'LABEL',
  LABEL1 = 'LABEL1',
  LABEL2 = 'LABEL2',
  LABEL3 = 'LABEL3',
  LABEL4 = 'LABEL4',
  NUMBER = 'NUMBER',
  AMOUNT = 'AMOUNT',
  TEXT = 'TEXT',
  ACCOUNT = 'ACCOUNT',
  CHECKBOX = 'CHECKBOX',
  DATE = 'DATE',
  DATERANGE = 'DATERANGE',
  PHONE = 'PHONE',
  TEXTAREA = 'TEXTAREA',
  FILE = 'FILE',
  RADIO = 'RADIO',
  IBAN = 'IBAN',
  EMAIL = 'EMAIL',
  BOOLEAN = 'BOOLEAN',
}

export enum LinkedTableEnum {
  Accounts = 'accounts',
  Customer = 'customer',
  Bank_branch = 'bank_branch',
  Card = 'card',
  Country = 'country',
  Credit = 'credit',
  Deposit = 'deposit',
  Dictionary = 'dictionary',
  Employee = 'employee',
  Product = 'product',
  Product_calc = 'product_calc',
  RetentionPeriodTo = 'retentionPeriodTo',
  Salary = 'salary',
}

export enum DynamicFieldCheckTypesEnum {
  hide = 'hide',
  show = 'show',
  minAmount = 'minAmount',
  checkExternalAccount = 'checkExternalAccount',
  required = 'required',
  checkRequiredInCheckBox = 'checkRequiredInCheckBox',
  setFilter = 'setFilter',
  dateRange = 'dateRange',
  dateTo = 'dateTo',
  amountRange = 'amountRange',
  regexp = 'regexp',
  requiredIfNotValue = 'requiredIfNotValue',
  dynamicShowFromParams = 'dynamicShowFromParams',
  dynamicHideFromParams = 'dynamicHideFromParams',
}

export enum DemandType {
  CurrencyBuy = 'CurrencyBuy',
  CurrencySale = 'CurrencySale',
  CurrencyConversion = 'CurrencyConversion',
  NewCardOrder = 'NewCardOrder',
  CardReissueOrder = 'CardReissueOrder',
  CardBlockingOrder = 'CardBlockingOrder',
  CardClosingOrder = 'CardClosingOrder',
  SMSNotificationOrder = 'SMSNotificationOrder',
  ChangeSMSNotificationOrder = 'ChangeSMSNotificationOrder',
  ServiceDescOrder = 'ServiceDescOrder',
  NewContractTermDeposit = 'NewContractTermDeposit',
  NewPlacementTrancheOrder = 'NewPlacementTrancheOrder',
  NewDepositGeneralOrder = 'NewDepositGeneralOrder',
  NewDepositStandartOrder = 'NewDepositStandartOrder',
  NewDepositCalculateOrder = 'NewDepositCalculateOrder',
  NewDepositIndividualOrder = 'NewDepositIndividualOrder',
  DepositProlongationOrder = 'DepositProlongationOrder',
  DepositClosingOrder = 'DepositClosingOrder',
  DepositChangingOrder = 'DepositChangingOrder',
  CreditProlongationOrder = 'CreditProlongationOrder',
  NewCreditTranchOrder = 'NewCreditTranchOrder',
  CreditFundsOrder = 'CreditFundsOrder',
  AuthLetter = 'AuthLetter',
  SalarySheet = 'SalarySheet',
  SalaryEmployeeDismissal = 'SalaryEmployeeDismissal',
  SalaryEmployeeNew = 'SalaryEmployeeNew',
  SalaryEmployeeReissue = 'SalaryEmployeeReissue',
  SalaryEmployeeCard = 'SalaryEmployeeCard',
  NewSalaryContract = 'NewSalaryContract',
  FEAMessage = 'FEAMessage',
  AcquiringNewTerminalOrder = 'AcquiringNewTerminalOrder',
  CashWithdrawalOrder = 'CashWithdrawalOrder',
  CashTransferOrder = 'CashTransferOrder',
  CashPreparationOrder = 'CashPreparationOrder',
  DocumentManagement = 'DocumentManagement',
}

export enum OrderType {
  NewCardOrder = 'NewCardOrder',
  InvoiceOrder = 'InvoiceOrder',
  ExposedOrder = 'ExposedOrder',
  InternalTransfer = 'InternalTransfer',
  DomesticTransfer = 'DomesticTransfer',
  InternalTransferESCROU = 'InternalTransferESCROU',
  DomesticTransferESCROU = 'DomesticTransferESCROU',
  ForeignTransfer = 'ForeignTransfer',
  ForeignTransferESCROU = 'ForeignTransferESCROU',
  SalarySheet = 'SalarySheet',
}
