import * as React from 'react';
import { TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { ImportSalaryResponse } from 'api/DemandsService/interfaces';
import { Employee } from 'api/SalaryService';
import { getPage, IMPORT_TYPES } from 'api/Service';
import { Button } from 'components/buttons/Button';
import { CounterDescription } from 'components/CounterDescription/CounterDescription';
import { FormError } from 'components/forms/FormError';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { getSum } from 'components/TotalCurrency/utils';
import { getAmountNumberFormatWithComma } from 'components/utils/format';
import { translate } from 'i18n/translator';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { ImportResultsModal } from 'pages/Import/ImportModal/ImportResultsModal';
import { useError } from 'pages/Import/SuccessImportList/useError';
import { useLoading } from 'pages/Import/SuccessImportList/useLoading';
import { isExist } from 'utils/isData';

interface Props {
  demandType: string;
  importResult: ImportSalaryResponse;
}

const columns: TableColumnProps<Employee>[] = [
  {
    label: 'front.menu.employees.label',
    render: row => (
      <DataColumn
        title={[row.lastName, row.firstName, row.middleName].join(' ')}
        subTitle={row.iban}
      />
    ),
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.сounterparty-form.bin.label',
    render: row => <DataColumn title={row.taxCode} />,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

export const SuccessEmployeesList = ({ importResult, demandType }: Props) => {
  const [error, setError] = useError();
  const [isLoading, setLoading] = useLoading();

  const employees = importResult.employees || importResult.salaryProjectModel?.employeeSalaryModels;

  const isDisableSave = !employees?.length || isLoading;

  const importedOrdersAmount =
    demandType === IMPORT_TYPES.SALARY && isExist(employees)
      ? getAmountNumberFormatWithComma(getSum(employees.map(order => order.amount)))
      : null;

  return (
    <>
      <FormError>{error}</FormError>
      <Button
        color="primary"
        onClick={async () => {
          setLoading(true);
          try {
            switch (demandType) {
              case IMPORT_TYPES.SALARY:
                goto(pages.salary.tabs.details('new'), {
                  importData: importResult,
                });
                await confirmModal(ImportResultsModal, {
                  totalAmount: importedOrdersAmount,
                  count: employees?.length,
                });
                break;
              case IMPORT_TYPES.SALARY_EMPLOYEES:
                await api.salary.acceptEmployees(employees);

                goto(pages.employees);
                break;
            }
          } catch (error: any) {
            setError(JSON.parse(error.message)?.message);
          } finally {
            setLoading(false);
          }
        }}
        disabled={isDisableSave}
        size="md"
      >
        {translate('front.import-page.save.label')}
      </Button>
      <Button
        color="secondary"
        onClick={() => {
          switch (demandType) {
            case IMPORT_TYPES.SALARY:
              goto(pages.salaries);
              break;
            case IMPORT_TYPES.SALARY_EMPLOYEES:
              goto(pages.employees);
          }
        }}
        size="md"
      >
        {translate('front.import-page.cancel.label')}
      </Button>
      <CounterDescription
        count={employees?.length}
        description={translate('front.import-page.tab-success.label')}
      />
      <DataTable
        name={TableNamesEnum.SuccessEmployeesImport}
        columns={columns}
        fetchRows={async request => getPage(employees, request)}
      />
    </>
  );
};
