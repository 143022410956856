import { Privileges } from 'navigations/privileges';
import { ExpandedCustomer } from 'pages/Login/CustomerSelectModal/types';
import { FactorType } from 'pages/Login/LoginPage';
import { SESSION_STORAGE_KEYS, USER } from 'store/actions/types';
import { ActionInterface } from 'store/reducers/interfaces';

export type UserState = {
  avatar: string;
  currentAuthFactor: FactorType;
  currentAuthPerson: number;
  customers: Array<ExpandedCustomer>;
  phoneNumber: string;
  privileges: Privileges[];
  userEmail: string;
  userFullName: string;
  value: string;
  loginType?: LoginType;
};

export enum LoginType {
  AUTHORIZATION = 'AUTHORIZATION',
  RELOAD = 'RELOAD',
}

function getInitUser() {
  const defaultLoginInfoState: UserState = {
    value: null,
    privileges: [],
    customers: [],
    currentAuthPerson: null,
    avatar: null,
    phoneNumber: '',
    userFullName: '',
    userEmail: '',
    currentAuthFactor: null,
    loginType: LoginType.RELOAD,
  };

  const authData = sessionStorage.getItem(SESSION_STORAGE_KEYS.AUTH_DATA);

  let initialState: UserState;

  try {
    initialState = {
      ...(authData ? JSON.parse(authData) : defaultLoginInfoState),
    };
  } catch (e) {
    initialState = defaultLoginInfoState;
  }

  return initialState;
}

export const userReducer = (state = getInitUser(), action: ActionInterface): UserState => {
  switch (action.type) {
    case USER.SET_TOKEN: {
      sessionStorage.setItem(SESSION_STORAGE_KEYS.TOKEN, action.payload);
      return { ...state };
    }
    case USER.SET_USER: {
      const newUser = { ...state, ...action.payload };
      sessionStorage.setItem(SESSION_STORAGE_KEYS.AUTH_DATA, JSON.stringify(newUser));
      return newUser;
    }
    case USER.SET_COLLECT_USER: {
      const newUser = { ...state, ...action.payload };
      sessionStorage.setItem(SESSION_STORAGE_KEYS.TOKEN, action.payload.value);
      return newUser;
    }
    case USER.SET_CUSTOMERS: {
      const updatedUser = { ...state, customers: action.payload };
      sessionStorage.setItem(SESSION_STORAGE_KEYS.AUTH_DATA, JSON.stringify(updatedUser));
      return updatedUser;
    }
    case USER.SET_DEFAULT_CUSTOMER: {
      const updatedCustomers = state.customers.map(p =>
        p.id === action.payload ? { ...p, default: true } : { ...p, default: false },
      );
      const updatedUser = { ...state, customers: updatedCustomers };
      sessionStorage.setItem(SESSION_STORAGE_KEYS.AUTH_DATA, JSON.stringify(updatedUser));
      return updatedUser;
    }
    default:
      return state;
  }
};
