import { useLocation } from 'react-router';

import { parseUrlParams } from 'api/backend';
import { Order } from 'api/OrderService';
import { isString } from 'utils/isData';

export const useDisabledCustomer = (order: Order) => {
  const { copyFrom } = parseUrlParams(useLocation().search);

  return isString(order?.state) || isString(copyFrom);
};
