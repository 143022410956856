import * as React from 'react';

import { Option } from 'api/Service';
import { translate } from 'i18n/translator';
import { isString } from 'utils/isData';

import { FinalValue } from '../ValidatingForm/FormContext';

import './style.scss';

export interface HintInputProps {
  disabled?: boolean;
  hints?:
    | string[]
    | Option[]
    | ((
        value: string,
        setContents?: React.Dispatch<React.SetStateAction<any[]>>,
      ) => Promise<Option[]>);
  name?: string;
  onBlur?(): void;
  onChange?: (value: string, option?: any) => void;
  onFocus?(e: React.FocusEvent): void;
  placeholder?: string;
  requiredSelectInDropDown?: boolean;
  rows?: number;
  showHintsOnFocus?: boolean;
  templateValue?: FinalValue;
  transform?: (v: string) => FinalValue;
  value?: string;
}

export const HintInput: React.FC<HintInputProps> = ({
  name,
  placeholder,
  value,
  requiredSelectInDropDown,
  disabled,
  onFocus,
  onBlur,
  hints = [],
  showHintsOnFocus = false,
  transform = v => v,
  onChange,
  rows,
  templateValue,
}) => {
  const [filteredHints, setFilteredHints] = React.useState([]);
  const [options, setOptions] = React.useState<Option[]>([]);

  const updateHintItems = React.useCallback(
    async (value: string) => {
      const valueWithoutTemplate = value.replace(`${templateValue}`, '').trim();

      if (typeof hints === 'function') {
        setFilteredHints(await hints(valueWithoutTemplate, setOptions));
      } else if (valueWithoutTemplate) {
        setFilteredHints(
          (hints as Array<string | Option>).filter((item: string | Option) => {
            return typeof item === 'string'
              ? item.includes(valueWithoutTemplate)
              : item.value?.includes(valueWithoutTemplate);
          }),
        );
      } else {
        setFilteredHints(showHintsOnFocus ? hints : []);
      }
    },
    [hints, showHintsOnFocus, templateValue],
  );

  const onInputChangeCb = React.useCallback(
    async e => {
      const value: string = e?.target?.value || '';
      onChange(value);
      await updateHintItems(value);
    },
    [hints, onChange],
  );

  const onFocusCb = React.useCallback(
    async e => {
      showHintsOnFocus && !e.target.value && (await updateHintItems(e.target.value));
    },
    [showHintsOnFocus, updateHintItems],
  );

  const onHintItemClick = (value: string, option?: Option) => (e: React.SyntheticEvent) => {
    setFilteredHints([]);
    showHintsOnFocus && value && e.preventDefault();
    templateValue ? onChange(`${value} ${templateValue}`, option) : onChange(value, option);
  };

  const HintsList = React.useMemo(
    () => (
      <div className="date-input__wrap-picker hint-input__floating">
        <div className="date-input__select-range">
          {filteredHints.map((item, index) => {
            return isString(item) ? (
              <div
                key={`${item}${index}`}
                className="date-input__select-range__item"
                onClick={onHintItemClick(item)}
              >
                {translate(item)}
              </div>
            ) : (
              <div
                key={`${item.value}${index}`}
                className="date-input__select-range__item"
                onClick={onHintItemClick(item.value, options[index] ?? item)}
              >
                {translate(item.value)}
              </div>
            );
          })}
        </div>
      </div>
    ),
    [hints, filteredHints],
  );

  const blurHandler = React.useCallback(
    e => {
      showHintsOnFocus && e.preventDefault();
      setFilteredHints([]);
      requiredSelectInDropDown && onChange('');
    },
    [showHintsOnFocus, requiredSelectInDropDown, onChange],
  );

  return (
    <>
      {filteredHints.length ? <div className="click-outside" onClick={blurHandler} /> : null}
      {hints && filteredHints.length ? HintsList : null}
      {rows ? (
        <textarea
          name={name}
          className="form-input form-input-textarea"
          placeholder={placeholder}
          value={transform(value) as string}
          disabled={disabled}
          onChange={onInputChangeCb}
          onFocus={onFocusCb && onFocus}
          onBlur={onBlur}
          autoComplete="off"
          rows={rows}
        />
      ) : (
        <input
          type="text"
          placeholder={placeholder}
          name={name}
          value={transform(value) as string}
          disabled={disabled}
          className="form-input"
          onChange={onInputChangeCb}
          onFocus={e => {
            onFocusCb(e);
            onFocus(e);
          }}
          onBlur={onBlur}
          autoComplete="off"
        />
      )}
    </>
  );
};
