import * as React from 'react';

import { Button } from 'components/buttons/Button';
import { translate } from 'i18n/translator';

import { confirmModal, PropsWithConfirmModal } from './globalModal/GlobalModal';

const NotificationModal = ({ resolve }: PropsWithConfirmModal) => (
  <div className="confirm-modal">
    <h2 className="confirm-modal__header-text">{translate('front.dear-customer.label')}</h2>
    <h3 className="confirm-modal__message">{translate('front.no-rights-to-sign.label')}</h3>
    <div className="confirm-modal__footer">
      <Button onClick={() => resolve(false)} color="primary" size="sm">
        {translate('front.account-statements-modal.close.label')}
      </Button>
    </div>
  </div>
);

export const notificationModal = async () => await confirmModal(NotificationModal);
