import * as React from 'react';
import { Input as BsInput } from 'reactstrap';

import classNames from 'classnames';

import { download } from 'api/backend';
import { FileType, FILETYPE_PRESETS } from 'api/Service';
import { Collapse } from 'components/Collapse';
import * as Icon from 'components/icons';
import { translate } from 'i18n/translator';
import { validateFileExtension } from 'pages/Demands/utils';

import './styles.scss';

export const FILE_TYPES = {
  [FILETYPE_PRESETS.DOCUMENTS]: ['csv', 'dbf', 'xls', 'xlsx', 'doc', 'docx', 'pdf'],
  [FILETYPE_PRESETS.IMAGES]: ['jpeg', 'jpg', 'png'],
  [FILETYPE_PRESETS.PDF]: ['pdf'],
  [FILETYPE_PRESETS.DOC]: ['doc', 'docx'],
  [FILETYPE_PRESETS.CSV]: ['csv'],
  [FILETYPE_PRESETS.XLS]: ['xls', 'xlsx'],
  [FILETYPE_PRESETS.DBF]: ['dbf'],
  [FILETYPE_PRESETS.ARCHIVES]: ['rar', 'zip'],
  [FILETYPE_PRESETS.XML]: ['xml'],
};

export interface FileConfig {
  extensions?: Array<string>;
  multiple?: boolean;
  outputFormat?: FileType;
  showFileList?: boolean;
  size?: number; // in Mb
}

export interface Value {
  field: string;
  isValid: boolean;
  value: File | string;
  downloadAction?: () => void;
}

interface Props {
  label: string;

  onBlur(): void;

  onChange(value: Array<Value>): void;

  type: string;
  autoComplete?: string;

  autoFocus?: boolean;

  disabled?: boolean;

  fileConfig?: FileConfig;
  id?: string;
  isEDSSignedDocuments?: boolean;
  name?: string;
  required?: boolean;
  style?: Obj;
  value?: any[];
}

const defaultFileConfig = {
  multiple: true,
  accept: [...FILE_TYPES[FILETYPE_PRESETS.DOCUMENTS], ...FILE_TYPES[FILETYPE_PRESETS.IMAGES]],
  size: 5,
  outputFormat: FileType.BASE_64,
  showFileList: true,
};

const toBase64 = (file: Blob | File): Promise<any> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

const b64toBlob = (dataURI: string) => {
  const byteString = atob(dataURI.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' });
};

const formatValue = async (file: Blob | File, format: FileType) => {
  let value = file;
  if (format === FileType.FILE) {
    value = file;
  } else if (format === FileType.BASE_64) {
    value = await toBase64(file);
  } else if (format === FileType.BLOB) {
    const base = await toBase64(file);
    value = b64toBlob(base);
  }
  return value;
};

function isEqual(array1: any[], array2: any[]) {
  if (!Array.isArray(array1) || !Array.isArray(array2)) {
    return array1 === array2;
  }
  if (array1.length !== array2.length) {
    return false;
  }
  for (let i = 0; i < array1.length; i = i + 1) {
    const val1 = array1[i];
    const val2 = array2[i];
    if (val1 !== val2 && !(array1.includes(val2) && array2.includes(val1))) {
      return false;
    }
  }
  return true;
}

export const useCashed = (value: string[]): string[] => {
  const ref = React.useRef<string[]>(value);
  const equal = isEqual(value, ref.current);

  React.useEffect(() => {
    if (!equal) {
      ref.current = value;
    }
  });

  return equal ? ref.current : value;
};

export const FileInput = (props: Props) => {
  const { label, name, value, fileConfig, isEDSSignedDocuments, ...rest } = props;
  const config: FileConfig = { ...defaultFileConfig, ...fileConfig };
  const [isOpen, setIsOpen] = React.useState<boolean>(true);

  const extensions: string[] = useCashed(config.extensions);

  const checkValidExtensions = React.useCallback(
    (fileName: string) => validateFileExtension(fileName, extensions),
    [extensions],
  );

  React.useEffect(() => {
    if (value?.length) {
      const newValue = value.map(file => ({
        ...file,
        isValid: checkValidExtensions(file.field),
      }));
      props.onChange(newValue);
    }
  }, [extensions, value?.length]);

  const onToggleShow = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if ((value || []).length) {
      setIsOpen(!isOpen);
    }
  };
  const onChange = async (e: any) => {
    const { outputFormat, size, multiple } = fileConfig;
    // e is synthetic event and this func is async, then need to save target before 'await'
    const { target } = e;
    const { files } = target;
    const result = [];
    if (multiple) {
      value && result.push(...value);
    }
    for (let i = 0; i < (multiple ? files.length : 1); i += 1) {
      const file = files[i];
      const isValidType = checkValidExtensions(file.name);
      const isValidSize = !size || file.size / 1024 ** 2 <= size;
      if (!result.some(item => item.field === file.name)) {
        result.push({
          field: file.name,
          value: await formatValue(file, outputFormat),
          isValid: isValidType && isValidSize,
          size: file.size,
        });
      }
    }
    props.onChange(result);
    // it clear file list of native file input.
    target.value = '';
    setIsOpen(true);
  };

  const onDeleteFile = (file: any, index: number) => {
    const copyValues = [...(value || [])];
    const foundIndex = (copyValues || []).findIndex(
      (item, idx) => item.value === file.value && idx === index,
    );
    if (foundIndex > -1) {
      copyValues.splice(foundIndex, 1);
      props.onChange(copyValues.length ? copyValues : null);
      if (!copyValues.length) {
        setIsOpen(false);
      }
    }
  };

  const onDownloadFile = async (file: any) => {
    const { outputFormat } = config;

    if (file.downloadAction) {
      file.downloadAction();
      return;
    }

    if (outputFormat === FileType.FILE) {
      const base = await toBase64(file.value);
      download(b64toBlob(base), file.field);
      return;
    }
    if (outputFormat === FileType.BLOB) {
      download(file.value, file.field);
      return;
    }
    download(b64toBlob(file.value), file.field);
  };

  const onCollapseClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className="upload-file">
      {config.showFileList ? (
        (value || []).length ? (
          <div
            className={classNames(
              { 'upload-file__collapse_wide': isEDSSignedDocuments },
              'upload-file__collapse',
            )}
            onClick={onCollapseClick}
          >
            <div className={classNames({ 'file-field-wrapper': isEDSSignedDocuments })}>
              <div className="upload-file__collapse_wrap" onClick={onToggleShow}>
                <span className="upload-file__collapse_title">
                  {translate('front.components.file-input.label')}
                </span>
                <span className="upload-file__collapse_icon">
                  {isOpen ? <Icon.GreenArrowUp /> : <Icon.GreenArrowDown />}
                </span>
              </div>
              {isEDSSignedDocuments && value && (
                <div className="attached-files-info">
                  {translate('front.attachments-with-eds-notifications-label')}
                </div>
              )}
            </div>
            <Collapse isOpen={isOpen} className="upload-file__list">
              {(value || []).map((item, index) => (
                <div
                  key={`${item.value}-${index}`}
                  className={classNames('upload-file__list__item', {
                    'not-valid': item.isValid !== undefined && !item.isValid,
                  })}
                >
                  <span className="upload-file__list__item_icon">
                    <Icon.Document />
                  </span>
                  <span
                    className="upload-file__list__item_name"
                    onClick={() => onDownloadFile(item)}
                  >
                    {item.field}
                  </span>
                  {!rest.disabled && (
                    <span
                      className="upload-file__list__item_close"
                      onClick={() => onDeleteFile(item, index)}
                    >
                      <Icon.CloseDocument />
                    </span>
                  )}
                </div>
              ))}
            </Collapse>
          </div>
        ) : (
          <span />
        )
      ) : null}

      <div className="upload-file__label">
        <label htmlFor={name}>
          <BsInput
            {...rest}
            accept={(extensions || []).map(t => `.${t}`).join(',')}
            multiple={config.multiple}
            name={name}
            onChange={onChange}
            type="file"
            className="upload-file__label_file"
          />
          {!rest.disabled && (
            <>
              <span className="upload-file__label_icon">
                <Icon.Attach />
              </span>
              <span className="upload-file__label_name">{label}</span>
            </>
          )}
        </label>
      </div>
    </div>
  );
};
