import * as React from 'react';
import { useSelector } from 'react-redux';

import { Order } from 'api/OrderService';
import { UpdateData } from 'components/forms/ValidatingForm/FormContext';
import { ExposedTypes } from 'pages/Payments/PaymentRequest/enums';
import { PaymentRequestDetailsFields } from 'pages/Payments/PaymentRequest/interfaces';
import { selectCustomer } from 'store/selectors';
import { isFunction } from 'utils/isData';

interface UseAccessProps {
  exposedType: ExposedTypes;
  order: Order<PaymentRequestDetailsFields>;
  updateData?: UpdateData<PaymentRequestDetailsFields>;
}

interface UseAccessReturn {
  isAllowSaveByConfidant: boolean;
  isDisabledExposedRadio: boolean;
  isShowExposedType: boolean;
}

export const useAccessToExposedType = ({
  order,
  exposedType,
  updateData,
}: UseAccessProps): UseAccessReturn => {
  const isConfidant = useSelector(selectCustomer(order?.customerId))?.personExtraData.isConfidant;
  const isShowExposedType = order?.state ? !!exposedType : isConfidant;
  const isDisabledExposedRadio = !isConfidant;
  const isSavedFDOrder = !!order?.state && exposedType === ExposedTypes.FD;
  const isAllowSaveByConfidant = isConfidant || !isSavedFDOrder;

  React.useEffect(() => {
    isFunction(updateData) &&
      updateData({ exposedType: isShowExposedType ? exposedType ?? ExposedTypes.TRF : null });
  }, [isShowExposedType, exposedType]);

  return { isShowExposedType, isDisabledExposedRadio, isAllowSaveByConfidant };
};
