import * as React from 'react';

import './styles.scss';

import config from 'config';
import { ExposedTypes } from 'pages/Payments/PaymentRequest/enums';

const LogoOptions = [
  {
    background: config.page.product.currencyUSDColor,
    label: ExposedTypes.FD,
  },
  {
    background: config.page.product.currencyUAHColor,
    label: ExposedTypes.TRF,
  },
];

interface PaymentRequestLogoProps {
  exposedType: ExposedTypes;
}

export const PaymentRequestLogo = ({
  exposedType = ExposedTypes.TRF,
}: PaymentRequestLogoProps): JSX.Element => {
  const logoType = LogoOptions.find(option => option.label === exposedType);

  return (
    <div style={{ background: logoType.background }} className="product-logo">
      {logoType.label}
    </div>
  );
};
