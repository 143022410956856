import * as React from 'react';

import { useExpiredError } from 'pages/Profile/Certificates/CertificateEngine/useExpiredError';

export const ExpiredError: React.FC = () => {
  const { expiredError } = useExpiredError();

  if (!expiredError) return null;

  return <div className="expired-error">{expiredError}</div>;
};
