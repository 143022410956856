import * as React from 'react';
import { Input as BsInput } from 'reactstrap';

import { useField } from 'components/forms/ValidatingForm/components/useField';

interface HidenProps {
  name: string;
  defaultValue?: any;
}

export const HiddenField = ({ name, defaultValue }: HidenProps) => {
  const { field, form } = useField({ name, defaultValue });

  if (!field && !form) {
    return null;
  }

  return <BsInput id={name} type="hidden" value={field.value || ''} />;
};
