import * as React from 'react';

import { useFieldAutoGrowing } from 'components/forms/inputs/useFieldAutoGrowing';
import { useField } from 'components/forms/ValidatingForm/components/useField';
import { TTooltip } from 'components/Tooltip/Tooltip';
import { bem } from 'components/utils/bem';
import { validateMessages } from 'components/validateMessages';
import { translate } from 'i18n/translator';
import { isExist } from 'utils/isData';

import { FieldInput } from './FieldInput';
import { PreloadField } from './PreoadField';
import { TextFieldProps } from './types';

interface TextAreaProps extends TextFieldProps {
  label: string;
  name: string;
  rows: number;
  counterNumber?: number;
  defaultValue?: string;
  disabled?: boolean;
  hasAutoGrowing?: boolean;
  hasCounter?: boolean;
  normalize?: (value: string) => string;
  placeholder?: string;
  required?: boolean;
  tooltip?: TTooltip;
  validate?: (value: string) => string;
  validateAllowedSymbol?: boolean;
}

// eslint-disable-next-line no-control-regex
const forbiddenSpecialCharactersRegex = /[\u0000-\u0009\u000B-\u001F]/;
const allowedSymbolsRegex =
  /^[ a-zA-Z0-9а-яА-ЯіїєёґІЇЁЄҐ!"#$%&'()№*+,;.\\\/<=>:ЂЃѓ„…†‡€‰Љ‹ЊЌЋЏђ‘•–—™љњќћ›џЎўЈ¤¦§©«»¬®°\x{201C}\x{201D}\x{201E}\x{2018}\x{201A}\x{2019}\x{A0}?¶·µ±@\[\]^_`{|}’\r\n~-]+$/;

export const TextAreaField = (props: TextAreaProps) => {
  const {
    name,
    label,
    rows,
    required,
    minLength,
    maxLength,
    length,
    disabled,
    hasAutoGrowing,
    defaultValue,
    counterNumber,
    placeholder,
    tooltip,
    forbiddenSpecialCharacters,
    denyCaretReturn = false,
    hasCounter = false,
    validateAllowedSymbol,
    validate: externalValidate,
    normalize = value => value,
  } = props;

  const [isFocus, setFocus] = React.useState<boolean>(false);

  const toggleFocus = (isFocus: boolean) => () => setFocus(isFocus);

  const onTextAreaFieldChange = (e: any, onChange: (value: string) => void) => {
    let value = e?.target ? e.target.value : e || '';
    if (forbiddenSpecialCharacters && value) {
      value = value.replace(forbiddenSpecialCharactersRegex, '');
    }
    if (denyCaretReturn) {
      value = value.replace(/\n$/, '').replace(/\n+/g, ' ');
    }

    onChange(normalize(value));
  };

  const validate = React.useCallback(
    value => {
      if (required) {
        if (!value) {
          return translate(validateMessages.fieldRequired);
        }
      }
      if (maxLength) {
        if (value?.length > maxLength) {
          return translate(validateMessages.moreThan(maxLength));
        }
      }

      if (minLength) {
        if (value?.length < minLength) {
          return translate(validateMessages.lessThan(minLength));
        }
      }

      if (length) {
        if (value?.length !== length) {
          return translate(validateMessages.shouldBe(length));
        }
      }

      if (validateAllowedSymbol && !allowedSymbolsRegex.test(value)) {
        return translate(validateMessages.allowedSymbols);
      }

      if (externalValidate) {
        return translate(externalValidate(value));
      }

      return null;
    },
    [required, maxLength, minLength, length, externalValidate],
  );

  const { field, form } = useField({ name, defaultValue, validate });
  const { textareaRef } = useFieldAutoGrowing(field, hasAutoGrowing);

  if (!field && !form) {
    return null;
  }

  let count = maxLength;

  if (hasCounter && isExist(field.value)) {
    count = maxLength - (field.value as string).length;
  }

  const isDisabled = disabled || form.disabled || form.progress;

  return (
    <PreloadField height={88} isLoading={form.initializing}>
      <FieldInput
        name={name}
        label={label}
        error={field.error}
        warning={field.warning}
        value={field.value}
        tooltip={tooltip}
        isFocus={isFocus || !!placeholder}
        isDisabled={isDisabled}
      >
        <textarea
          id={name}
          ref={textareaRef}
          className="form-input form-input-textarea"
          placeholder={placeholder}
          value={(field.value as string) || ''}
          disabled={isDisabled}
          onChange={e => onTextAreaFieldChange(e, field.onChange)}
          onBlur={() => {
            field.onBlur();
            toggleFocus(false)();
          }}
          onFocus={toggleFocus(true)}
          autoComplete={`new-${name}`}
          rows={rows}
        />
      </FieldInput>
      {(hasCounter || isExist(counterNumber)) && !form.disabled && (
        <div
          className={bem('form-counter')
            .modificator('negative', (counterNumber ?? count) < 0)
            .toClassName()}
        >
          {`${translate('front.form.counter-first-field.label')}
                ${counterNumber ?? count}
                ${translate('front.form.counter-second-field.label')}`}
        </div>
      )}
    </PreloadField>
  );
};
