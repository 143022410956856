import React from 'react';

import { DynamicFieldValue } from 'api/DemandsService/interfaces';
import { FileType, Option } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { FileField } from 'components/forms/inputs/FileField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextAreaField } from 'components/forms/inputs/TextAreaField';
import { TextField } from 'components/forms/inputs/TextField';
import { useDisabledCustomer } from 'hooks/useDisabledCustomer';
import { useFileExtensions } from 'hooks/useFileExtensions';
import { AuthLetterNames } from 'pages/Demands/AuthLetterDemand/enums';
import { OrderBaseFields } from 'pages/Demands/interfaces';
import { isSignedDocuments } from 'pages/Demands/utils';

export interface AuthLetterFields extends OrderBaseFields {
  [AuthLetterNames.Customer]: string;
  [AuthLetterNames.Topic]: string;
  [AuthLetterNames.Subject]: string;
  [AuthLetterNames.Body]: string;
  [AuthLetterNames.Attach]: File[];
  [AuthLetterNames.ManagerAnswer]: boolean;
  [AuthLetterNames.SignFiles]: boolean;
}

export interface AuthLetterPayload {
  [AuthLetterNames.Topic]: Option<DynamicFieldValue>[];
}

export const AuthLetterDetails = () => {
  const {
    order,
    payload: { topic },
    onChangeCustomer,
  } = useOrderDetails<AuthLetterFields, AuthLetterPayload>();

  const fileExtensions = useFileExtensions();

  const isDisabledCustomer = useDisabledCustomer(order);

  return (
    <DefaultForm>
      <CustomerSelect
        name={AuthLetterNames.Customer}
        label="front.auth-letter.customer.label"
        onSelectOption={onChangeCustomer}
        selectDefaultCustomer
        disabled={isDisabledCustomer}
        required
      />
      <SelectField
        name={AuthLetterNames.Topic}
        label="front.auth-letter.type-of-letter.label"
        options={topic}
        required
      />
      <TextField name={AuthLetterNames.Subject} label="front.auth-letter.topic.label" required />
      <TextAreaField
        name={AuthLetterNames.Body}
        label="front.auth-letter.message.label"
        rows={3}
        maxLength={750}
        minLength={7}
        hasAutoGrowing
        hasCounter
        required
      />
      <FileField
        name={AuthLetterNames.Attach}
        label="front.auth-letter.attachment.label"
        outputFormat={FileType.BASE_64}
        accept={fileExtensions}
        isEDSSignedDocuments={isSignedDocuments(order?.state)}
        multiple
      />
      <CheckboxField name={AuthLetterNames.ManagerAnswer} label="front.auth-letter.addKEP.label" />
      <CheckboxField
        name={AuthLetterNames.SignFiles}
        label="front.auth-letter.put-signature-on-document.label"
        defaultValue={true}
      />
    </DefaultForm>
  );
};
