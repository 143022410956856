import { api } from 'api/index';

import { del, get, post, put } from './backend';
import { GridRequest, GridResponse, Option } from './Service';

export enum NotificationTypeObject {
  PERSON = 'PERSON',
  USER = 'USER',
}

export enum NotificationTypeCode {
  DEBIT_ACCOUNT = 'DEBIT_ACCOUNT',
  CREDIT_ACCOUNT = 'CREDIT_ACCOUNT',
  CERTIFICATE_EXPIRING = 'CERTIFICATE_EXPIRING',
  SUCCESS_SIGN_IN = 'SUCCESS_SIGN_IN',
  STATEMENT_09 = 'STATEMENT_09',
}

export enum ChannelTypes {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  INTERNAL = 'INTERNAL',
}

export type Lang = 'uk' | 'ru' | 'en';

export interface Notification {
  customerId: number;
  email: boolean;
  id: number;
  internal: boolean;
  notificationTypeId: number;
  sms: boolean;
  type: NotificationType;
  account?: number;
  accountIban?: string;
}

export interface NotificationTypeParam {
  channelCode: ChannelTypes;
  id: number;
  isActive: boolean;
  templateBody: Record<Lang, string>;
  isVisibleByClient?: boolean;
  templateBodyType?: string;
  templateSubject?: Record<Lang, string>;
}

export interface NotificationType {
  code: string;
  displayOrder: number;
  id: number;
  isActive: boolean;
  isAvailableObjectParam: boolean;
  isEditableByClient: boolean;
  isLogForUser: boolean;
  isVisibleByClient: boolean;
  name: string;
  notificationTypeCode: NotificationTypeCode;
  notificationTypeObject: NotificationTypeObject;
  params: NotificationTypeParam[];
  groupName?: string;
}

export interface Channel {
  channelCode: ChannelTypes;
  id: number;
  customerContactId?: string | number;
}

export interface NotificationTarget {
  customerId: number;
  id: number;
  isAllChannels: boolean;
  isAllObjects: boolean;
  notificationType: string;
  notificationTypeId: number;
  objectId: string;
  personId: number;
  targetParams: Channel[];
  notificationGroup?: string;
  objectParam?: string;
  objectType?: string;
}

export interface NotificationRequest {
  withParams: boolean;
}

export interface AddNotification {
  notificationTypeId: number;
  customerId?: number;
  objectId?: string;
  objectParam?: string;
  targetParams?: Array<{ channelCode: ChannelTypes }>;
}

export interface NotificationsParam extends GridRequest {
  customerId: number;
}

export class NotificationService {
  getNotificationTypes(request: NotificationRequest): Promise<GridResponse<NotificationType>> {
    return get('/v1/notifications/types', request);
  }

  async getNotificationTypeOptions(): Promise<Option<NotificationType>[]> {
    const types = await this.getNotificationTypes({ withParams: true });

    return types.rows
      .filter(item => item.isActive && item.isEditableByClient && item.isVisibleByClient)
      .map(item => ({
        value: item.id.toString(),
        label: item.name,
        content: item,
      }));
  }

  getNotificationTargets(params: {
    companyPersonId: number;
  }): Promise<GridResponse<NotificationTarget>> {
    return get('/v1/notifications/targets', params);
  }

  deleteNotificationTarget(id: string | number): Promise<void> {
    return del(`/v1/notifications/targets/${id}`);
  }

  addNotificationTarget({
    customerId,
    ...otherData
  }: AddNotification): Promise<NotificationTarget> {
    return post('/v1/notifications/targets', otherData, { customerId });
  }

  editNotificationTarget(
    id: number,
    notificationTypeId: number,
    objectParam: string,
  ): Promise<any> {
    return put('/v1/notifications/targets', {
      id,
      notificationTypeId,
      objectParam,
    });
  }

  setNotificationParam(id: number, channelCode: ChannelTypes): Promise<any> {
    return post(`/v1/notifications/targets/${id}/params`, { channelCode });
  }

  unsetNotificationParam(paramId: number): Promise<any> {
    return del(`/v1/notifications/targets/params/${paramId}`);
  }

  getNotificationData = async (params: NotificationsParam): Promise<GridResponse<Notification>> => {
    const { customers } = await api.user.getCustomersInfo();
    const { customerId } = params;
    const { page, size, ...otherParams } = params;
    const companyPersonId = customerId
      ? customers.find(c => c.id === +customerId).personId
      : undefined;

    const [{ rows: targets }, { rows: types }, { rows: accounts }] = await Promise.all([
      this.getNotificationTargets({ companyPersonId, ...otherParams }),
      this.getNotificationTypes({ withParams: true }),
      api.payments.getAccountsForPayment({
        type: 'all',
        withDocumentBalance: false,
        ...otherParams,
      }),
    ]);

    const allowStatementAccounts = accounts.filter(row => row.allowStatement);
    const allowDebitAccounts = accounts.filter(row => row.allowDebit);

    const rows = [];

    for (const target of targets) {
      const type = types.find(t => t.id === target.notificationTypeId);
      if (!type) continue;

      const sms = target.targetParams.find(tp => tp.channelCode === ChannelTypes.SMS);
      const email = target.targetParams.find(tp => tp.channelCode === ChannelTypes.EMAIL);
      const internal = target.targetParams.find(tp => tp.channelCode === ChannelTypes.INTERNAL);

      const rowDraft: Notification = {
        type,
        id: target.id,
        customerId: target.customerId,
        notificationTypeId: target.notificationTypeId,
        sms: Boolean(sms),
        email: Boolean(email),
        internal: Boolean(internal),
      };

      if (type.code === NotificationTypeCode.DEBIT_ACCOUNT) {
        const account = allowDebitAccounts.find(a => a.id.toString() === target.objectId);
        const isAccount = account?.iban ?? account?.number;

        if (!isAccount) continue;

        rowDraft.accountIban = account.iban;
        rowDraft.account = account.id;
      }

      if (type.notificationTypeObject === NotificationTypeObject.PERSON) {
        const account = allowStatementAccounts.find(a => a.id.toString() === target.objectId);
        const isAccount = account?.iban ?? account?.number;

        if (!isAccount) continue;

        rowDraft.accountIban = account?.iban ?? account?.number;
        rowDraft.account = account?.id;
      }

      rows.push(rowDraft);
    }

    return {
      rows,
      total: {
        count: rows.length,
      },
    };
  };

  getSingleNotification(id: number): Promise<NotificationTarget> {
    return get(`/v1/notifications/targets/${id}`);
  }
}
