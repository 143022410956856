import React from 'react';

import { DynamicFieldValue } from 'api/DemandsService/interfaces';
import { FileType, Option } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { DateField } from 'components/forms/inputs/DateField';
import { FileField } from 'components/forms/inputs/FileField';
import { MaskField } from 'components/forms/inputs/MaskField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextAreaField } from 'components/forms/inputs/TextAreaField';
import { TextField } from 'components/forms/inputs/TextField';
import config from 'config';
import { useDisabledCustomer } from 'hooks/useDisabledCustomer';
import { useFileExtensions } from 'hooks/useFileExtensions';
import { CreditProlongationDetailsName } from 'pages/Demands/CreditDemand/enums';
import { disabledDaysBeforeToday, today } from 'pages/Demands/utils';

interface NewCreditTranchOrderFields {
  [CreditProlongationDetailsName.Customer]: string;
  [CreditProlongationDetailsName.Credit]: string;
  [CreditProlongationDetailsName.Amount]: number;
  [CreditProlongationDetailsName.FromDate]: Date;
  [CreditProlongationDetailsName.PeriodDays]: number;
  [CreditProlongationDetailsName.OwnFeeAccount]: string;
  [CreditProlongationDetailsName.CreditReason]: string;
  [CreditProlongationDetailsName.LinkedPayment]: boolean;
  [CreditProlongationDetailsName.DocumentNumber]: string;
  [CreditProlongationDetailsName.DocumentDate]: Date;
  [CreditProlongationDetailsName.DocumentReceiver]: string;
  [CreditProlongationDetailsName.DocumentAccount]: number;
  [CreditProlongationDetailsName.DocumentAmount]: number;
  [CreditProlongationDetailsName.Attach]: File[];
}

interface NewCreditTranchOrder {
  [CreditProlongationDetailsName.Credit]: Option<DynamicFieldValue>[];
  [CreditProlongationDetailsName.OwnFeeAccount]: Option<DynamicFieldValue>[];
}

const { ibanMask } = config.page.domesticPayment;

export const NewCreditTrancheOrderDetail = () => {
  const {
    order,
    payload: { credit, ownFeeAccount },
    onChangeCustomer,
    form: { getFieldValue },
  } = useOrderDetails<NewCreditTranchOrderFields, NewCreditTranchOrder>();

  const fileExtensions = useFileExtensions();

  const isCheckedLinkedPayment = getFieldValue(
    CreditProlongationDetailsName.LinkedPayment,
  ) as boolean;

  const isDisabledCustomer = useDisabledCustomer(order);

  return (
    <DefaultForm>
      <CustomerSelect
        name={CreditProlongationDetailsName.Customer}
        label="front.credit-funds.organization.label"
        onSelectOption={onChangeCustomer}
        selectDefaultCustomer
        disabled={isDisabledCustomer}
        required
      />
      <SelectField
        name={CreditProlongationDetailsName.Credit}
        label="front.credit-prolongation.credit.label"
        options={credit}
        required
      />
      <AmountField
        name={CreditProlongationDetailsName.Amount}
        label="front.credit-funds.amount.label"
        maxLengthWithoutZero={12}
        minAmount={0.01}
        hasMoneyFormat
        thousandSeparator={' '}
        required
      />
      <DateField
        name={CreditProlongationDetailsName.FromDate}
        label="front.credit-new-credit-tranche.fromDate.label"
        disabledDays={disabledDaysBeforeToday}
        required
      />
      <AmountField
        name={CreditProlongationDetailsName.PeriodDays}
        label="front.credit-new-credit-tranche.periodDays.label"
        maxLength={5}
        required
      />
      <SelectField
        name={CreditProlongationDetailsName.OwnFeeAccount}
        label="front.credit-new-credit-tranche.ownFeeAccount.label"
        options={ownFeeAccount}
        required
      />
      <TextAreaField
        name={CreditProlongationDetailsName.CreditReason}
        label="front.credit-new-credit-tranche.creditReason.label"
        maxLength={200}
        hasCounter
        rows={3}
        required
      />
      <CheckboxField
        label="front.credit-new-credit-tranche.linkedPayment.label"
        name={CreditProlongationDetailsName.LinkedPayment}
      />
      {isCheckedLinkedPayment && (
        <>
          <TextField
            name={CreditProlongationDetailsName.DocumentNumber}
            label="front.credit-new-credit-tranche.documentNumber.label"
            required={isCheckedLinkedPayment}
          />
          <DateField
            name={CreditProlongationDetailsName.DocumentDate}
            label="front.credit-new-credit-tranche.documentDate.label"
            initialDate={today}
            required={isCheckedLinkedPayment}
          />
          <TextField
            name={CreditProlongationDetailsName.DocumentReceiver}
            label="front.credit-new-credit-tranche.documentReceiver.label"
            required={isCheckedLinkedPayment}
          />
          <MaskField
            name={CreditProlongationDetailsName.DocumentAccount}
            label="front.credit-new-credit-tranche.documentAccount.label"
            mask={ibanMask}
            required={isCheckedLinkedPayment}
          />
          <AmountField
            name={CreditProlongationDetailsName.DocumentAmount}
            label="front.credit-new-credit-tranche.documentAmount.label"
            maxLengthWithoutZero={12}
            minAmount={0.01}
            hasMoneyFormat
            thousandSeparator={' '}
            required={isCheckedLinkedPayment}
          />
          <FileField
            label="front.credit-new-credit-tranche.document.label"
            name={CreditProlongationDetailsName.Attach}
            outputFormat={FileType.BASE_64}
            accept={fileExtensions}
            multiple
          />
        </>
      )}
    </DefaultForm>
  );
};
