import * as React from 'react';

import { translate } from 'i18n/translator';

import { PageHeader } from './PageHeader';

import './page.scss';

export interface Props {
  title: string;
  children?: React.ReactNode;
  headerStyle?: React.CSSProperties;
  right?: React.ReactNode;
  subtitle?: string;
}

export const Page: React.FC<Props> = ({ title, subtitle, right, children, headerStyle }) => {
  return (
    <div className="app-page">
      <PageHeader style={headerStyle}>
        <PageHeader.Title>{translate(title)}</PageHeader.Title>
        {subtitle && <PageHeader.Subtitle>{subtitle}</PageHeader.Subtitle>}
        <PageHeader.Right>{right}</PageHeader.Right>
      </PageHeader>
      {children}
    </div>
  );
};
