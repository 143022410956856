import * as React from 'react';

import { RegistrationSteps, RegistrationResponse } from 'api/RegistrationService';
import { AuthLayout } from 'components/layout/AuthLayout';
import { ClientAddInfoPage } from 'pages/Registration/ClientAddInfoPage';
import { ClientInfoPage } from 'pages/Registration/ClientInfoPage';
import { FinProfilePage } from 'pages/Registration/FinProfilePage';
import { InitRegistrationPage } from 'pages/Registration/InitRegistrationPage';
import { RegistrationErrorPage } from 'pages/Registration/RegistrationErrorPage';
import { RegistrationExecutedPage } from 'pages/Registration/RegistrationExecutedPage';
import { RegistrationRejectPage } from 'pages/Registration/RegistrationRejectPage';
import { RegistrationResultPage } from 'pages/Registration/RegistrationResultPage';
import { SmsRegistrationPage } from 'pages/Registration/SmsRegistrationPage';

import { RegistrationContext } from './RegistrationContext';

const initialStep: RegistrationResponse = {
  orderState: RegistrationSteps.InitRegistration,
};

export const RegistrationPage = () => {
  const [orderId, setOrderId] = React.useState<number>();
  const [uuid, setUuid] = React.useState<string>();
  const [phoneNumber, setPhoneNumber] = React.useState<string>();
  const [step, setStep] = React.useState<RegistrationResponse>(initialStep);

  const renderSteps = () => {
    switch (step.orderState) {
      case RegistrationSteps.InitRegistration: {
        return <InitRegistrationPage />;
      }
      case RegistrationSteps.SMS: {
        return <SmsRegistrationPage />;
      }
      case RegistrationSteps.DoShowClientInfo: {
        return <ClientInfoPage />;
      }
      case RegistrationSteps.DoShowClientAddInfo: {
        return <ClientAddInfoPage />;
      }
      case RegistrationSteps.DoShowFinProfile: {
        return <FinProfilePage />;
      }
      case RegistrationSteps.DoShowRegistrationResult: {
        return <RegistrationResultPage />;
      }
      case RegistrationSteps.Rejected:
      case RegistrationSteps.Stopped: {
        return <RegistrationRejectPage />;
      }
      case RegistrationSteps.Error: {
        return <RegistrationErrorPage />;
      }
      case RegistrationSteps.Executed: {
        return <RegistrationExecutedPage />;
      }
      default: {
        return null;
      }
    }
  };

  const contextValue = {
    orderId,
    setOrderId,
    uuid,
    setUuid,
    step,
    setStep,
    phoneNumber,
    setPhoneNumber,
    errorMessage: step.message,
  };

  return (
    <AuthLayout>
      <RegistrationContext.Provider value={contextValue}>
        {renderSteps()}
      </RegistrationContext.Provider>
    </AuthLayout>
  );
};
