import * as React from 'react';
import { DataTable as VendorDataTable, DataTableProps, useDataTable } from 'react-data-table';
import { useLocation } from 'react-router';

import { FormPayload } from 'components/forms/ValidatingForm/FormContext';
import { FormFetchCb, useForm } from 'components/forms/ValidatingForm/useForm';
import { useFormInternal } from 'components/forms/ValidatingForm/useFormInternal';
import { Container } from 'components/react-grid';
import { withDeviceResolver } from 'components/utils/withResizeObserver';

import { DataTableContent } from './DataTableContent';
import { DataTableFooter } from './DataTableFooter/DataTableFooter';
import { DataTableHeader } from './DataTableHeader/DataTableHeader';
import { DataTableWrapper } from './DataTableWrapper';

import './data-table.scss';

type Props = Omit<Omit<DataTableProps, 'breakpoint'>, 'location'>;

export const DataTable: React.FC<Props> = withDeviceResolver<Props>(props => {
  const location = useLocation();
  return (
    <VendorDataTable {...props} location={location}>
      <DataTableWrapper>
        <Container>
          <DataTableHeader />
          <DataTableContent />
          <DataTableFooter />
        </Container>
      </DataTableWrapper>
    </VendorDataTable>
  );
});

interface UseFilterConfig<T> {
  extraFields?: T;
  fetchInitial?: FormFetchCb<T>;
  fetchOnDemand?: boolean;
}

export const useFilter = <F extends Obj, T = Obj, P = FormPayload>(
  config: UseFilterConfig<T> = {},
) => {
  const { extraFields: initialExtraFields, fetchOnDemand = false, fetchInitial } = config;

  const {
    filter: { filter, updateFilter },
    rows,
    total,
    tabOptions,
    selection,
    reFetchData,
    isLoading,
    gridRequest,
  } = useDataTable();

  const {
    payload,
    setPayload,
    formId,
    setData,
    getFormData,
    ready,
    handleSubmit,
    updateData,
    setProgress,
    progress,
    resetData: resetFilter,
    updateFieldInData,
  } = useFormInternal<F, P>();

  const [extraFields, setExtraFields] = React.useState<T>(initialExtraFields);

  useForm(fetchInitial);

  React.useEffect(() => {
    !formId && updateFilter(extraFields);
  }, [formId]);

  React.useEffect(() => {
    if (ready && !fetchOnDemand) {
      const formData = getFormData();
      updateFilter({ ...formData, ...extraFields });
    }
  }, [ready]);

  const sendFilter = (formData: F) => updateFilter({ ...formData, ...extraFields });

  const applyFilter = (e?: Event) => handleSubmit(sendFilter, e);

  const applyOnBlurFilter = React.useCallback(
    formData => handleSubmit(sendFilter.bind(null, formData)),
    [],
  );

  const setPagination = React.useCallback(page => gridRequest.updateGridRequest({ page }), []);

  return {
    isLoading,
    gridRequest,
    rows,
    tabOptions,
    updateFilter,
    updateData,
    applyFilter,
    applyOnBlurFilter,
    resetFilter,
    getFormData,
    setData,
    progress,
    setProgress,
    selection,
    reFetchData,
    setPagination,
    payload,
    setPayload,
    totalCount: total.count,
    extraFields: { ...extraFields, ...filter } as T & F,
    updateFieldInData,
    updateExtraFields: (fields: Partial<T>) =>
      setExtraFields(oldState => ({ ...oldState, ...fields })),
  };
};
