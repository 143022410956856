import * as React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { Col, Container, Row } from 'react-grid';

import config, { WIDGET, WIDGET_LAYOUT } from 'config';

import { Droppable } from './Droppable';
import { getColIndex, getDroppableId, move, reorder } from './utils';

import './styles.scss';

export type ItemType = {
  enabled: boolean;
  id: string;
  title: string;
  widget: WIDGET;
  column?: number;
  order?: number;
};

interface Props {
  items: ItemType[];
  layout: WIDGET_LAYOUT;
  onChange: (items: ItemType[]) => void;
  isDraggable?: boolean;
}

const items2Lists = (items: ItemType[], colNum: number) => {
  const cols = Array(colNum).fill(0);
  return cols.map((col, i) =>
    items
      .filter(item => item.enabled)
      .filter(item => (colNum > 1 ? item.column === i : true))
      .sort((a, b) => a.order - b.order)
      .map(({ order, column, ...item }) => item),
  );
};

const lists2Items = (lists: ItemType[][]) =>
  lists.reduce((items, list, column) => {
    const colItems = list.reduce((acc, item, order) => [...acc, { ...item, order, column }], []);
    return [...items, ...colItems];
  }, []);

export const DragDrop = ({ items, layout, isDraggable = true, onChange }: Props) => {
  const cols = config.page.main.widgets.layouts[layout];

  const lists = React.useMemo(() => items2Lists(items, cols.length), [cols, items]);

  const getList = React.useCallback(id => lists[getColIndex(id)], [lists]);

  const onDragEnd = React.useCallback(
    ({ source, destination }) => {
      if (!destination) {
        return;
      }

      const listsClone = [...lists];

      if (source.droppableId === destination.droppableId) {
        const listIndex = getColIndex(destination.droppableId);
        const list = reorder(getList(destination.droppableId), source.index, destination.index);
        listsClone[listIndex] = list;
      } else {
        const result = move(
          getList(source.droppableId),
          getList(destination.droppableId),
          source,
          destination,
        );

        Object.keys(result).forEach(
          droppableId => (listsClone[getColIndex(droppableId)] = result[droppableId]),
        );
      }

      onChange(lists2Items(listsClone));
    },
    [items, lists],
  );

  if (!lists) return null;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Container>
        <Row>
          {cols.map((col, i) => (
            <Col {...col} key={i}>
              <div className="droppable-wrapper">
                <Droppable
                  droppableId={getDroppableId(i)}
                  isDraggable={isDraggable}
                  items={lists[i]}
                />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </DragDropContext>
  );
};
