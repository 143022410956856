import { get } from 'api/backend';

import { ClaimTokenRequestInterface } from './interfaces';

interface ClaimTokenResponseInterface {
  uri: string;
}

export class RedirectUri {
  getRedirectUri = async ({
    customerId,
    claimTokenCode,
    questionnaireId,
  }: ClaimTokenRequestInterface): Promise<ClaimTokenResponseInterface> =>
    await get('/v1/ugb/redirect/uri', { customerId, claimTokenCode, questionnaireId });
}
