import * as React from 'react';

import { RegistrationSteps } from 'api/RegistrationService';
import { Button } from 'components/buttons/Button';
import { translate } from 'i18n/translator';
import { RegistrationContext } from 'pages/Registration/RegistrationContext';

const RejectSvg = () => (
  <svg
    className="no-connection__error_svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* tslint:disable-next-line: max-line-length */}
    <path
      d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM9 13H11V15H9V13ZM9 5H11V11H9V5Z"
      fill="#E95F5A"
    />
  </svg>
);

export const RegistrationRejectPage: React.FC = () => {
  const { goBack, errorMessage } = useRegistrationReject();

  return (
    <div className="">
      <div className="login-page__body__box card">
        <div className="card-body">
          <div className="no-connection">
            <div className="no-connection__error">
              <RejectSvg />
              <h1 className="no-connection__error_title">
                {translate('front.registration.reject-page.title.label')}
              </h1>
              <div className="no-connection__error_desc">
                {translate('front.registration.reject-page.first-description.label')}
                <p>{errorMessage}</p>
                {translate('front.registration.reject-page.second-description.label')}
              </div>
            </div>
            <div className="certificate-modal__error_footer">
              <Button onClick={goBack} color="secondary">
                {translate('front.registration.reject-page.button-close.label')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const useRegistrationReject = () => {
  const { setStep, errorMessage } = React.useContext(RegistrationContext);

  const goBack = async () => {
    setStep({ orderState: RegistrationSteps.InitRegistration });
  };

  return { goBack, errorMessage };
};
