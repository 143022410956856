import React from 'react';

import { GridRequest } from 'api/Service';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { LOCAL_STORAGE_KEY } from 'store/actions/types';

import { TableConfig } from './utils';

export const useStorageTable = (
  name: string,
  tableConfig: TableConfig,
  breakpoint: string,
  gridRequest: GridRequest,
  shouldUseStorageSort: boolean,
  shouldUseStorageSize: boolean,
) => {
  const [tableStorageConfig, setTableStorageConfig] = useLocalStorage(
    LOCAL_STORAGE_KEY.TABLE_SETTINGS,
    name,
  );

  const handleUpdateStorage = (config: TableConfig) => setTableStorageConfig(config);

  React.useEffect(() => {
    handleUpdateStorage({
      ...tableStorageConfig,
      gridRequest: {
        sort: shouldUseStorageSort ? gridRequest.sort : null,
        order: shouldUseStorageSort ? gridRequest.order : null,
        size: shouldUseStorageSize ? gridRequest.size : null,
      },
    });
  }, [gridRequest]);

  React.useEffect(
    () => handleUpdateStorage({ ...tableStorageConfig, columns: { ...tableConfig } }),
    [tableConfig],
  );
};
