import { translate } from 'i18n/translator';

const NAME_REGULAR = /^[a-zA-Zа-яА-ЯіїєёґІЇЁЄҐ]*$/;
const INN_REGULAR = /^[0-9]{8,12}$/;
export const ONLY_NUMBER = '^[0-9]*$';

export const normalizeName = (value: string, prevValue: string) => {
  if (NAME_REGULAR.test(value)) {
    return value;
  }

  return prevValue;
};

export const validateInn = (value: string) => {
  if (!INN_REGULAR.test(value)) {
    return translate('front.card-edit.inn-validate.label');
  }
};
