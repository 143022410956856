import * as React from 'react';
import { useRouteMatch } from 'react-router';

import { api } from 'api';
import { PaymentPurpose } from 'api/HandbooksService';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { TextAreaField } from 'components/forms/inputs/TextAreaField';
import { Effects, useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { Page } from 'components/layout/Page/Page';
import { translate } from 'i18n/translator';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { lengthPurpose } from 'pages/Payments/InternalPayment/utils';

const getLabelKey = (id: string) => {
  return Number(id)
    ? 'front.payment-purpose-form.edit-title.label'
    : 'front.payment-purpose-form.create-title.label';
};

interface PaymentPurposeFields {
  purpose: string;
}

const PaymentPurposeForm = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();

  const fetchPaymentPurpose = async ({ setFields }: Effects<PaymentPurposeFields, undefined>) => {
    if (Number(id)) {
      const { purpose } = await api.handbooks.getPaymentPurpose(+id);
      setFields({ purpose });
    }
  };

  const { progress, error, handleSubmit } = useForm<PaymentPurposeFields>(fetchPaymentPurpose);

  const onSave = async (data: PaymentPurpose) => {
    if (Number(id)) {
      const newData = { id: +id, purpose: data.purpose };
      await api.handbooks.updatePaymentPurpose(newData);
    } else {
      await api.handbooks.createPaymentPurpose(data);
    }

    goto(pages.handbooks.tabs.paymentPurposes);
  };

  return (
    <Page title={translate(getLabelKey(id))}>
      <DefaultForm>
        <FormError>{error}</FormError>
        <TextAreaField
          name="purpose"
          label="front.payment-purpose-form.purpose.label"
          rows={3}
          minLength={6}
          maxLength={lengthPurpose}
          required
          denyCaretReturn
          validateAllowedSymbol
          hasCounter
        />
        <div>
          <Button
            color="secondary"
            onClick={() => goto(pages.handbooks.tabs.paymentPurposes)}
            progress={progress}
            type="submit"
            size="sm"
          >
            {translate('front.payment-purpose-form.cancel-button.label')}
          </Button>
          <Button
            color="primary"
            onClick={e => handleSubmit(onSave, e)}
            progress={progress}
            type="submit"
            size="sm"
          >
            {translate('front.payment-purpose-form.save-button.label')}
          </Button>
        </div>
      </DefaultForm>
    </Page>
  );
};

export const PaymentPurposePage = withForm(PaymentPurposeForm);
