import React from 'react';

import classnames from 'classnames';

import { FinalValue } from 'components/forms/ValidatingForm/FormContext';
import * as Icon from 'components/icons';

import './styles.scss';

interface Props {
  onBlur(): void;
  onChange(value: React.ChangeEvent<HTMLInputElement>): void;
  onFocus(): void;
  type: string;
  autoComplete?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  id?: string;
  isLargeInput?: boolean;
  name?: string;
  required?: boolean;
  style?: React.CSSProperties;
  value?: FinalValue;
}

export const PasswordInput: React.FC<Props> = ({
  id,
  value,
  name,
  onChange,
  onBlur,
  onFocus,
  style,
  isLargeInput,
}: Props) => {
  const [isShowPassword, setIsShowPassword] = React.useState<boolean>(false);

  const onShowToggle = () => setIsShowPassword(!isShowPassword);

  return (
    <>
      <span onClick={onShowToggle}>
        {!isShowPassword ? (
          <Icon.OpenEye className="show-password__icon" />
        ) : (
          <Icon.CrossedEye className="show-password__icon" />
        )}
      </span>
      <input
        id={id}
        name={name}
        onChange={e => onChange(e)}
        value={value as any}
        className={classnames('form-input', { 'form-input-large': isLargeInput })}
        type={isShowPassword ? 'text' : 'password'}
        style={{ ...(style || {}), paddingRight: '30px' }}
        onFocus={onFocus}
        onBlur={onBlur}
        required
      />
    </>
  );
};
