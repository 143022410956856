import * as React from 'react';
import { Card as RsCard } from 'reactstrap';

import classnames from 'classnames';

import './Card.scss';

export const Card = ({ children, className = '' }: React.PropsWithChildren<any>) => (
  <RsCard className={classnames('card-wrapper', className)}>{children}</RsCard>
);
