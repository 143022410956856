import React from 'react';

import { DynamicFieldValue } from 'api/DemandsService/interfaces';
import { Option } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { DateField } from 'components/forms/inputs/DateField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextAreaField } from 'components/forms/inputs/TextAreaField';
import { useDisabledCustomer } from 'hooks/useDisabledCustomer';
import { translate } from 'i18n/translator';
import { CreditProlongationDetailsName } from 'pages/Demands/CreditDemand/enums';
import { disabledDaysBeforeToday } from 'pages/Demands/utils';

interface CreditProlongationFields {
  [CreditProlongationDetailsName.Customer]: string;
  [CreditProlongationDetailsName.Credit]: string;
  [CreditProlongationDetailsName.NewTerm]: string;
  [CreditProlongationDetailsName.FromDate]: Date;
  [CreditProlongationDetailsName.Reason]: string;
}

interface CreditProlongationPayload {
  [CreditProlongationDetailsName.Credit]: Option<DynamicFieldValue>[];
}

export const CreditProlongationDetails = () => {
  const {
    order,
    payload: { credit },
    onChangeCustomer,
  } = useOrderDetails<CreditProlongationFields, CreditProlongationPayload>();

  const isDisabledCustomer = useDisabledCustomer(order);

  return (
    <DefaultForm>
      <h5>{translate('front.credit-demand.titleLabel.prolongation.label')}</h5>
      <CustomerSelect
        name={CreditProlongationDetailsName.Customer}
        label="front.credit-prolongation.organization.label"
        onSelectOption={onChangeCustomer}
        selectDefaultCustomer
        disabled={isDisabledCustomer}
        required
      />
      <SelectField
        name={CreditProlongationDetailsName.Credit}
        label="front.credit-prolongation.credit.label"
        options={credit}
        required
      />
      <AmountField
        name={CreditProlongationDetailsName.NewTerm}
        label="front.credit-prolongation.newTerm.label"
        maxLength={3}
        required
      />
      <DateField
        name={CreditProlongationDetailsName.FromDate}
        label="front.credit-prolongation.fromDate.label"
        disabledDays={disabledDaysBeforeToday}
        required
      />
      <TextAreaField
        name={CreditProlongationDetailsName.Reason}
        label="front.credit-prolongation.reason.label"
        rows={3}
        maxLength={200}
        hasCounter
      />
    </DefaultForm>
  );
};
