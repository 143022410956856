import * as React from 'react';

import classNames from 'classnames';

import { Option } from 'api/Service';
import { SelectArrow } from 'components/icons';

import './styles.scss';

interface Props {
  className: string;
  onChange: (value: number) => void;
  options: Option<any, any>[];
  value: string | number;
}

export const Selector: React.FC<Props> = ({ options, onChange, value, className }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const activeElementRef = React.useRef(null);

  const option = options.find(o => o.value === value);

  React.useEffect(() => {
    if (isOpen) {
      activeElementRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [isOpen]);

  return (
    <div className={classNames('wrapper', { [className]: className })}>
      <div className="selected" onClick={() => setIsOpen(prevIsOpen => !prevIsOpen)}>
        {option?.label}
        <span className="icon">
          <SelectArrow />
        </span>
      </div>
      {isOpen && (
        <>
          <div className="clickArea" onClick={() => setIsOpen(false)} />
          <div className="menu">
            {options.map(o => {
              const isActive = o.value === value;

              return (
                <div
                  ref={isActive ? activeElementRef : null}
                  key={o.value}
                  className={classNames('menu-item', { isActive })}
                  onClick={() => {
                    setIsOpen(false);
                    onChange(o.value);
                  }}
                >
                  {o.label}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
