import * as React from 'react';
import { useDataTable } from 'react-data-table';

import { bem } from 'components/utils/bem';

export const DataTableWrapper: React.FC = ({ children }) => {
  const { isLoading } = useDataTable();
  return (
    <div className={bem('data-table').modificator('isLoading', isLoading).toClassName()}>
      {children}
    </div>
  );
};
