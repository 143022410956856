import * as React from 'react';

import { DemandType } from 'api/DemandsService/enums';
import { OrderFields } from 'api/interfaces';
import { Import } from 'components/icons';
import { PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import { getAmountNumberFormatWithComma } from 'components/utils/format';
import { translate } from 'i18n/translator';

interface ReducedByCurrency {
  count: number;
  currency: string;
  totalAmount: number;
}

const reducedByCurrency = (orders: OrderFields[]) =>
  orders.reduce((reducedByCurrency: ReducedByCurrency[], order: OrderFields) => {
    const isCurrencyBuy = order.orderType === DemandType.CurrencyBuy;

    const currentCurrency = isCurrencyBuy ? order.receiverCurrency : order.currency;
    const currentAmount = isCurrencyBuy ? order.receiverAmount : +order.amount;

    const currencyIndex = reducedByCurrency.findIndex(
      ({ currency }) => currency === currentCurrency,
    );

    if (currencyIndex !== -1) {
      const reducedCurrency = reducedByCurrency[currencyIndex];
      reducedByCurrency[currencyIndex] = {
        totalAmount: reducedCurrency.totalAmount + currentAmount,
        count: reducedCurrency.count + 1,
        currency: currentCurrency,
      };

      return reducedByCurrency;
    }

    return [
      ...reducedByCurrency,
      {
        totalAmount: currentAmount,
        count: 1,
        currency: currentCurrency,
      },
    ];
  }, []);

export const CurrencyExchangeImportResultModal: React.FC<
  PropsWithConfirmModal<Obj<OrderFields[]>>
> = ({ orders }) => {
  const reducedByIsoCode = reducedByCurrency(orders);

  const totalLabels = reducedByIsoCode.map(
    ({ count, totalAmount, currency }: ReducedByCurrency) => (
      <li key={currency}>
        {translate('front.import-modal-total-info-domestic.label')
          .replace('{count}', `${count}`)
          .replace('{amount}', `${getAmountNumberFormatWithComma(totalAmount)}`)
          .replace('{currency}', `${currency}`)}
      </li>
    ),
  );

  return (
    <div>
      <div className="import-modal-header">
        <Import />
        <div className="import-modal-title">{translate('front.import-modal-title.label')}</div>
      </div>
      <ul className="import-modal-info">{totalLabels}</ul>
    </div>
  );
};
