import * as React from 'react';

import { api } from 'api';
import { Kved, RegistrationSteps } from 'api/RegistrationService';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { TextField } from 'components/forms/inputs/TextField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { translate } from 'i18n/translator';
import { RegistrationContext } from 'pages/Registration/RegistrationContext';

interface Fields {
  address: string;
  organisationName: string;
  owner: string;
  phone: string;
  taxCode: string;
}

interface Payload {
  kvedList: Kved[];
  primaryKved: Kved;
}

const renderKved = (kved: Kved, i: number | string) => {
  return (
    <tr key={i}>
      <td>{kved.code}</td>
      <td>{kved.name}</td>
    </tr>
  );
};

const ClientInfoPageForm: React.FC = () => {
  const { getClientInfo, onConfirmClientInfo, goBack } = useClientInfo();

  const onRegister = async () => {
    await onConfirmClientInfo();
  };

  const { progress, error, payload, handleSubmit } = useForm<Fields, Payload>(
    async ({ setFields, setPayload }) => {
      const { kvedList, primaryKved, ...fields } = await getClientInfo();
      setFields(fields);
      setPayload({ kvedList, primaryKved });
    },
  );

  return (
    <div className="" style={{ position: 'initial' }}>
      <div className="login-page__body__box card" style={{}}>
        <div className="card-body">
          <div className="login-page__body__box__title">
            {translate('front.registration.client-info-page.title.label')}
          </div>
          <h5>{translate('front.registration.client-info-page.sub-title.label')}</h5>
          <DefaultForm>
            <FormError>{error}</FormError>
            <TextField
              label="front.registration.client-info-page.field-taxCode.label"
              name="taxCode"
              disabled
            />
            <TextField
              label="front.registration.client-info-page.field-organization-name.label"
              name="organisationName"
              disabled
            />
            <TextField
              label="front.registration.client-info-page.field-owner.label"
              name="owner"
              disabled
            />
            <TextField
              label="front.registration.client-info-page.field-phone.label"
              name="phone"
              disabled
            />
            <TextField
              label="front.registration.client-info-page.field-address.label"
              name="address"
              disabled
            />
            {payload.primaryKved && (
              <>
                <h5>{translate('front.registration.client-info-page.main-code-kved.label')}</h5>
                <table>{renderKved(payload.primaryKved, 'primaryKved')}</table>
              </>
            )}
            {payload.kvedList?.length > 0 && (
              <>
                <h5>{translate('front.registration.client-info-page.other-code-kved.label')}</h5>
                <table>{payload.kvedList.map((kved, i) => renderKved(kved, i))}</table>
              </>
            )}
            <Button
              color="primary"
              onClick={e => handleSubmit(onRegister, e)}
              progress={progress}
              className="login-page__form__btn"
              type="submit"
              size="lg"
            >
              {translate('front.registration.client-info-page.button-continue.label')}
            </Button>
            <Button
              color="secondary"
              onClick={goBack}
              disabled={progress}
              className="login-page__form__btn cancel-btn"
              size="lg"
            >
              {translate('front.registration.client-info-page.button-cancel.label')}
            </Button>
          </DefaultForm>
        </div>
      </div>
    </div>
  );
};

export const ClientInfoPage = withForm(ClientInfoPageForm);

const useClientInfo = () => {
  const { orderId, uuid, setStep } = React.useContext(RegistrationContext);

  const getClientInfo = async (): Promise<Fields & Payload> => {
    const clientInfo = await api.registration.getClientInfo(orderId, uuid);

    const [primaryKved, kvedList] = clientInfo.kvedList.reduce(
      (acc, item) => {
        if (item.is_primary && acc[0] === null) {
          acc[0] = item;
        } else {
          acc[1].push(item);
        }
        return acc;
      },
      [null, []],
    );

    return {
      kvedList,
      primaryKved,
      taxCode: clientInfo.taxCode,
      organisationName: clientInfo.organisationName,
      owner: `${clientInfo.lastName} ${clientInfo.firstName} ${clientInfo.middleName}`,
      phone: clientInfo.phone,
      address: `${clientInfo.countryName} ${clientInfo.postalZip} ${clientInfo.addressLegal}`,
    };
  };

  const onConfirmClientInfo = async () => {
    setStep(await api.registration.confirmClientInfo(orderId, uuid));
  };

  const goBack = async () => {
    setStep({ orderState: RegistrationSteps.InitRegistration });
  };

  return { onConfirmClientInfo, getClientInfo, goBack };
};
