import React from 'react';
import reactHtmlParser from 'react-html-parser';

import { RequiredLetter } from 'api/MailService';
import { Button } from 'components/buttons/Button';
import { DefaultForm } from 'components/forms/formParts';
import { PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import * as format from 'components/utils/format';
import { translate } from 'i18n/translator';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { useNotificationsSelector } from 'pages/Dashboard/Header/useNotificationsSelector';
import { transformForMail } from 'pages/Mail/utils';
import './styles.scss';

interface Props extends PropsWithConfirmModal {
  letter: RequiredLetter;
}

export const RequiredEmailModal: React.FC<Props> = ({ letter, resolve }) => {
  const { notifications, readNotification } = useNotificationsSelector();

  const readLetter = async (item: RequiredLetter) => {
    const notification = notifications.find(elem => elem.id === item.id);
    readNotification(notification);

    resolve(true);
    goto(pages.message(item.id, 'view'));
  };

  return (
    <div className="required-mail-modal">
      <DefaultForm>
        <div className="required-mail-modal__item" key={letter.id}>
          <div className="required-mail-modal__item__subject">{letter.subject}</div>
          <div className="required-mail-modal__item__date">{format.dateTimeFull(letter.date)}</div>
          <p className="required-mail-modal__item__desc">
            {reactHtmlParser(letter.desc, {
              transform: transformForMail,
            })}
          </p>
          <div className="required-mail-modal__footer">
            <Button onClick={() => readLetter(letter)} type="submit" size="sm">
              {translate('front.required-mail-modal.read-button.label')}
            </Button>
            <Button onClick={() => resolve(true)} color="secondary" size="sm">
              {translate('front.required-mail-modal.close-button.label')}
            </Button>
          </div>
        </div>
      </DefaultForm>
    </div>
  );
};
