import moment from 'moment';

import config from 'config';
import { LOCAL_STORAGE_KEY, LOCALE } from 'store/actions/types';
import { ActionInterface } from 'store/reducers/interfaces';
import { LocalStorage } from 'utils/LocalStorage';

const locales = LocalStorage.getItem(LOCAL_STORAGE_KEY.LOCALES, []);
const activeLocale = LocalStorage.getItem(
  LOCAL_STORAGE_KEY.ACTIVE_LOCALE,
  config.i18n.defaultLanguageCode,
);

document.cookie = `lang=${activeLocale};path=/`;
moment.locale(activeLocale);

const initialState = {
  activeLocale,
  locales,
};

export type LocaleState = {
  activeLocale: string;
  locales: {
    label: string;
    value: string;
  }[];
};

export const localeReducer = (state = initialState, action: ActionInterface): LocaleState => {
  switch (action.type) {
    case LOCALE.SET_LOCALES: {
      localStorage.setItem(LOCAL_STORAGE_KEY.LOCALES, JSON.stringify(action.payload));
      return { ...state, locales: action.payload };
    }
    case LOCALE.SET_ACTIVE_LOCALE: {
      const locale = action.payload;
      localStorage.setItem(LOCAL_STORAGE_KEY.ACTIVE_LOCALE, locale);
      document.cookie = `lang=${locale};path=/`;
      moment.locale(locale);
      return { ...state, activeLocale: locale };
    }
    default:
      return state;
  }
};
