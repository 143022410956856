import React from 'react';

import { isExist } from 'utils/isData';

export const usePrevious = <T>(value: T): T => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = React.useRef<T>();

  // Store current value in ref
  React.useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

// function call if the value has changed, taking into account the check for "undefined"
export const useEffectIfValueChange = <T>(value: T, callback: () => void) => {
  const prevValue = usePrevious(value);

  React.useEffect(() => {
    isExist(prevValue) && value !== prevValue && callback();
  }, [value]);
};
