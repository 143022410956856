import * as React from 'react';
import { Col, Row, Container } from 'react-grid';
import { useDispatch, useSelector } from 'react-redux';

import { api } from 'api';
import { Option } from 'api/Service';
import { Button } from 'components/buttons/Button';
import { DefaultForm } from 'components/forms/formParts';
import { SelectField } from 'components/forms/inputs/SelectField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { UploadAvatar } from 'components/uploadAvatar/UploadAvatar';
import config from 'config';
import { translate } from 'i18n/translator';
import { AppStore } from 'store';
import { LocaleActions } from 'store/actions/locale';
import { LOCAL_STORAGE_KEY } from 'store/actions/types';
import { UserActions } from 'store/actions/user';
import { LocaleState } from 'store/reducers/locale';
import { UserState } from 'store/reducers/user';
import { selectLocaleState, selectUser } from 'store/selectors';
import { LocalStorage } from 'utils/LocalStorage';

import './styles.scss';

const avatarIcon = config.page.dashboard.avatarIcon;

interface Fields {
  activeLocale: string;
}

interface Payload {
  locales: Option[];
}

const SettingsForm: React.FC = () => {
  const { activeLocale } = useSelector<AppStore, LocaleState>(selectLocaleState());
  const [selectedLocale, setSelectedLocale] = React.useState<string>(activeLocale);
  const user = useSelector(selectUser());

  const dispatch = useDispatch();

  const setUser = (info: UserState) => dispatch(UserActions.setUser(info));

  const { payload } = useForm<Fields, Payload>(async ({ setFields, setPayload }) => {
    const locales = LocalStorage.getItem(LOCAL_STORAGE_KEY.LOCALES, []);
    const activeLocale = LocalStorage.getItem(
      LOCAL_STORAGE_KEY.ACTIVE_LOCALE,
      config.i18n.defaultLanguageCode,
    );
    setFields({ activeLocale });
    setPayload({ locales });
  });

  const onLocaleChange = (locale: ((prevState: string) => string) | string) => {
    setSelectedLocale(locale);
  };

  const onUpload = async (avatar: string) => {
    await api.auth.saveConfig({ avatar });
    setUser({ ...user, avatar });
  };

  const onLocaleSave = async () => {
    dispatch(LocaleActions.setActiveLocale(selectedLocale));
    await api.user.setDefaultLanguage(selectedLocale);
    window.location.reload();
  };

  const { avatar } = user;

  return (
    <div style={{ padding: '0 20px' }}>
      <Container>
        <Row className="info-block__row">
          <Col>
            <div className="info-block__title">
              {translate('front.profile-settings.picture.label')}
            </div>
            <div className="info-block__avatar">
              <UploadAvatar avatar={avatar || avatarIcon} onUpload={onUpload} />
            </div>
          </Col>
          <Col>
            <div className="info-block__title">
              {translate('front.profile-settings.lang.label')}
            </div>
          </Col>
        </Row>

        <DefaultForm className="info-block__form">
          <Row>
            <Col md={4}>
              <SelectField
                name="activeLocale"
                onSelectOption={onLocaleChange}
                options={payload?.locales}
                label="front.profile-settings.select-lang.label"
                isMenuMaxContentWidth={false}
                required
              />
            </Col>
          </Row>

          <Row className="info-block__row">
            <Col className="info-block__footer">
              <div className="info-block__footer__btns">
                <Button
                  color="primary"
                  onClick={onLocaleSave}
                  className="info-block__form__btn"
                  type="submit"
                  size="sm"
                >
                  {translate('front.table.filter-footer-apply.label')}
                </Button>
              </div>
            </Col>
          </Row>
        </DefaultForm>
      </Container>
    </div>
  );
};

export const SettingsPage = withForm(SettingsForm);
