import * as React from 'react';

import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { TextField } from 'components/forms/inputs/TextField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import { translate } from 'i18n/translator';

export interface EmailModalProps {
  fieldName: string;
  onSend: (params: any) => Promise<void>;
  params: any;
}

export const EmailModalForm = ({
  params,
  resolve,
  fieldName,
  onSend,
}: PropsWithConfirmModal<EmailModalProps>) => {
  const sendEmail = async (formData: any) => {
    await onSend({ ...params, ...formData });
    resolve(true);
  };

  const { handleSubmit, progress, error } = useForm();

  return (
    <div>
      <DefaultForm>
        <FormError>{error}</FormError>
        <h3>{translate('front.account-statements-send-email-form.title.label')}</h3>
        <TextField
          label="front.account-statements-send-email-form.email.label"
          name={fieldName}
          email
          required
        />
        <Button
          onClick={e => handleSubmit(sendEmail, e)}
          progress={progress}
          type="submit"
          size="sm"
        >
          {translate('front.account-statements-send-email-form.send.label')}
        </Button>
        <Button onClick={() => resolve(false)} color="secondary" size="sm">
          {translate('front.account-statements-send-email-form.cancel.label')}
        </Button>
      </DefaultForm>
    </div>
  );
};

export const EmailModal = withForm<PropsWithConfirmModal<EmailModalProps>>(EmailModalForm);
