import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import lodashThrottle from 'lodash.throttle';
import ResizeObserver from 'resize-observer-polyfill';

import { AppStore } from 'store';
import { DeviceActions } from 'store/actions/device';

let dashboardResizeObserver: ResizeObserver;

export const DeviceResolver = ({ children }: React.PropsWithChildren<any>) => {
  const dispatch = useDispatch();
  const wrapperRef = React.useRef<HTMLDivElement>();

  const device = useSelector((state: AppStore) => state.device);

  React.useLayoutEffect(() => {
    dashboardResizeObserver = new ResizeObserver(onResize);
    dashboardResizeObserver.observe(wrapperRef.current);

    return () => dashboardResizeObserver.unobserve(wrapperRef.current);
  }, []);

  const onResize = lodashThrottle(([entry]) => {
    if (wrapperRef.current) {
      dispatch(DeviceActions.resolveDevice(entry.contentRect.width));
    }
  }, 300);

  return (
    <div ref={wrapperRef} id="device-resolver" className={device}>
      {children}
    </div>
  );
};
