import * as React from 'react';
import { Col, Row, Container } from 'react-grid';
import { useSelector } from 'react-redux';

import { api } from 'api';
import { AccountType } from 'api/enums';
import { ProductStatus } from 'api/ProductsService';
import { SortOrder, Option } from 'api/Service';
import { AccountsInterface } from 'api/UserService';
import { Button } from 'components/buttons/Button';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { SelectField } from 'components/forms/inputs/SelectField';
import { FormState } from 'components/forms/ValidatingForm/FormContext';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { Page } from 'components/layout/Page/Page';
import config from 'config';
import { translate } from 'i18n/translator';
import { getDefaultAccount } from 'pages/Profile/Accounts/utils';
import { selectDefaultCustomerId } from 'store/selectors';

import './style.scss';

export enum Accounts {
  ACC_TRANSFER_DEFAULT_ACCOUNT = 'ACC_TRANSFER_DEFAULT_ACCOUNT',
  PAYMENT_ORDER_DEFAULT_ACCOUNT = 'PAYMENT_ORDER_DEFAULT_ACCOUNT',
}

interface Payload {
  allAccountOptions: Option[];
  nationalCurrencyAccountOptions: Option[];
}

const fetchAccountOptions = async (customerIds?: any) => {
  const gridRes = await api.products.getAccounts({
    customerIds,
    type: 'all',
    sort: 'displayOrder',
    order: SortOrder.Asc,
    excludedStatuses: [ProductStatus.Closed],
  });

  return gridRes.rows
    .filter(item => item.type !== AccountType.INNER)
    .map(row => ({
      label: `${row.iban} / ${row.currency} ${row.balance} / ${row.alias}`,
      value: row.id.toString(),
      content: row,
    }));
};

const onChangeCustomer = async (
  customer: string,
  setPayload: (
    value: ((prevState: Partial<Payload>) => Partial<Payload>) | Partial<Payload>,
  ) => void,
  updateData: (formData: Partial<AccountsInterface>, arrayFormData?: FormState) => void,
  setProgress: (value: ((prevState: boolean) => boolean) | boolean) => void,
) => {
  setProgress(true);

  updateData({ transferDefaultAccount: null, paymentDefaultAccount: null });

  const customerId = Number(customer);

  const allAccountOptions = await fetchAccountOptions([customerId]);
  const nationalCurrencyAccountOptions = allAccountOptions.filter(
    account => account.content.currency === config.bank.nationalCurrency,
  );
  setPayload({ allAccountOptions, nationalCurrencyAccountOptions });

  const { transferDefaultAccount, paymentDefaultAccount } = await api.user.getDefaultAccounts({
    customerId,
    code: [Accounts.ACC_TRANSFER_DEFAULT_ACCOUNT, Accounts.PAYMENT_ORDER_DEFAULT_ACCOUNT],
  });

  updateData({
    transferDefaultAccount: getDefaultAccount(allAccountOptions, transferDefaultAccount),
    paymentDefaultAccount: getDefaultAccount(nationalCurrencyAccountOptions, paymentDefaultAccount),
    customerId,
  });

  setProgress(false);
};

const onSubmit = async ({
  customerId,
  transferDefaultAccount,
  paymentDefaultAccount,
}: AccountsInterface) => {
  await api.user.setDefaultAccounts(customerId, {
    transferDefaultAccount,
    paymentDefaultAccount,
  });
};

export const AccountsPageForm = () => {
  const defaultCustomerId = `${useSelector(selectDefaultCustomerId())}`;
  const { handleSubmit, payload, setPayload, updateData, setProgress, progress } = useForm<
    AccountsInterface,
    Payload
  >(async ({ setFields, setPayload }) => {
    const allAccountOptions = await fetchAccountOptions([defaultCustomerId]);
    const nationalCurrencyAccountOptions = allAccountOptions.filter(
      account => account.content.currency === config.bank.nationalCurrency,
    );

    setPayload({ allAccountOptions, nationalCurrencyAccountOptions });

    const { transferDefaultAccount, paymentDefaultAccount } = await api.user.getDefaultAccounts({
      customerId: +defaultCustomerId,
      code: [Accounts.ACC_TRANSFER_DEFAULT_ACCOUNT, Accounts.PAYMENT_ORDER_DEFAULT_ACCOUNT],
    });

    setFields({
      transferDefaultAccount: getDefaultAccount(allAccountOptions, transferDefaultAccount),
      paymentDefaultAccount: getDefaultAccount(
        nationalCurrencyAccountOptions,
        paymentDefaultAccount,
      ),
    });
  });

  return (
    <Page title={'front.profile.accounts-page.default-settings-accounts.label'}>
      <Container>
        <Row className="account-page__row">
          <Col md={6}>
            <CustomerSelect
              defaultValue={defaultCustomerId}
              name="customerId"
              label="front.internal-payment-page.order-payer-name.label"
              onSelectOption={value => onChangeCustomer(value, setPayload, updateData, setProgress)}
            />
          </Col>
        </Row>
        <Row className="account-page__row">
          <Col md={6}>
            <SelectField
              label="front.internal-payment-page.order-internal-type.title.label"
              name="transferDefaultAccount"
              options={payload.allAccountOptions}
              clearable
            />
          </Col>
        </Row>
        <Row className="account-page__row">
          <Col md={6}>
            <SelectField
              label="front.internal-payment-page.order-external-type.title.label"
              name="paymentDefaultAccount"
              options={payload.nationalCurrencyAccountOptions}
              clearable
            />
          </Col>
        </Row>
        <Row className="account-page__row">
          <Col md={6}>
            <Button disabled={progress} color="primary" onClick={e => handleSubmit(onSubmit, e)}>
              {translate('front.mail-modal.save-button.label')}
            </Button>
          </Col>
        </Row>
      </Container>
    </Page>
  );
};

export const AccountsPage = withForm(AccountsPageForm);
