import React from 'react';

import classname from 'classnames';

import { translate } from 'i18n/translator';

interface InputLabelProps {
  error: string;
  hasValue: boolean;
  isDisabled: boolean;
  isInputFocus: boolean;
  isLargeInput: boolean;
  label: string;
}

export const InputLabel: React.FC<InputLabelProps> = ({
  isDisabled,
  hasValue,
  isLargeInput,
  isInputFocus,
  error,
  label,
  children,
}) => (
  <label className="input-inner-wrapper">
    {children}
    <span
      className={classname('default-label', {
        'input-disabled': isDisabled,
        'input-value': hasValue,
        'input-large': isLargeInput && !isInputFocus,
        'input-error': error,
        'input-focus': isInputFocus,
      })}
    >
      {translate(label)}
    </span>
  </label>
);
