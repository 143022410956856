import React from 'react';

import { api } from 'api';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { WarningIcon } from 'components/icons';
import { PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import { translate } from 'i18n/translator';
import { Payload } from 'pages/Profile/Certificates/KepCertificate/types';
import { AwaitCertificateFormProps } from 'pages/Profile/Certificates/Modals/interfaces';

import './certificates.scss';

const AwaitCertificateForm = ({
  requestPayload,
  customerId,
  dataHash,
  certificateType,
  resolve,
}: PropsWithConfirmModal<AwaitCertificateFormProps>) => {
  const { progress, error } = useForm<Payload>(async () => {
    const { certificate, certificateAgreement } = await api.certificate.createTokenCertificate(
      requestPayload,
      {
        customerId: Number(customerId),
        dataHash,
        certificateType,
      },
    );

    resolve({ certificate, certificateAgreement });
  });

  return (
    <DefaultForm>
      <FormError>{error}</FormError>
      {progress && (
        <div className="no-connection">
          <div className="no-connection__error">
            <WarningIcon />
            <h2 className="no-connection__error_title">
              {translate('front.profile.key-generation-certification-progress.label')}
            </h2>
          </div>
        </div>
      )}
    </DefaultForm>
  );
};

export const AwaitCertificateModal = withForm(AwaitCertificateForm);
