import React from 'react';

import { Order } from 'api/OrderService';
import { Status } from 'components/layout/Status';
import { DocumentManagementDetailsFields } from 'pages/DocumentManagement/interfaces';
import {
  getDocumentManagementStatusColor,
  getStatus,
  labelBankStatus,
  labelCustomerStatus,
} from 'pages/DocumentManagement/utils';

import './DoubleStatusFrom.scss';

interface DoubleStatusFrom {
  order: Order<DocumentManagementDetailsFields>;
}

interface StateWithLabelProps {
  label: string;
  order: Order<DocumentManagementDetailsFields>;
  isCustomerStatus?: boolean;
}

const StateWithLabel = ({ order, label, isCustomerStatus = false }: StateWithLabelProps) => {
  const { state, translate } = getStatus(order, isCustomerStatus);

  return (
    <div className="wrapper-status">
      <span className="label-status">{label}</span>
      <Status color={getDocumentManagementStatusColor(state)}>{translate}</Status>
    </div>
  );
};

export const DoubleStatusForm = ({ order }: DoubleStatusFrom) => (
  <div className="wrapper-statuses">
    <StateWithLabel order={order} label={labelBankStatus} />
    <StateWithLabel order={order} label={labelCustomerStatus} isCustomerStatus />
  </div>
);
