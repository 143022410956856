import * as React from 'react';
import { Col, Row } from 'react-grid';
import { useLocation } from 'react-router';

import { isValidIBAN } from 'ibantools';

import { parseUrlParams } from 'api/backend';
import { VatType } from 'api/enums';
import { Account } from 'api/interfaces';
import { Option } from 'api/Service';
import { CustomerSelect } from 'components/Document/CustomerSelect';
import { useOrderDetails } from 'components/Document/useOrderDetails';
import { DefaultForm } from 'components/forms/formParts';
import { AmountField } from 'components/forms/inputs/amountFields/AmountField';
import { CheckboxField } from 'components/forms/inputs/CheckboxField';
import { HintField } from 'components/forms/inputs/HintField';
import { MaskField, normalizeIban } from 'components/forms/inputs/MaskField';
import { PreloadField } from 'components/forms/inputs/PreoadField';
import { RadioField } from 'components/forms/inputs/RadioField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { TextAreaField } from 'components/forms/inputs/TextAreaField';
import { TextField } from 'components/forms/inputs/TextField';
import config from 'config';
import { translate } from 'i18n/translator';
import {
  isVisiblePassportField,
  isVisibleResidentCountryFiled,
  nonResidentCountryPlaceholder,
  validatePassport,
} from 'pages/Payments/InternalPayment/DomesticTransferDetails';
import { useChangingPurpose } from 'pages/Payments/InternalPayment/hooks/useChangingPurpose';
import { useMaxLengthPurpose } from 'pages/Payments/InternalPayment/hooks/useMaxLengthPurpose';
import { useStorageVatPercent } from 'pages/Payments/InternalPayment/hooks/useStorageVatPercent';
import { getBicAndBankNameFromAccount } from 'pages/Payments/InternalPayment/hooks/utils';
import {
  codeRegexps,
  DEFAULT_VAT_PERCENT,
  normalizeVatPercent,
} from 'pages/Payments/InternalPayment/utils';
import { ExposedOrderDetailsNames, ExposedTypes } from 'pages/Payments/PaymentRequest/enums';
import { useAccessToExposedType } from 'pages/Payments/PaymentRequest/hooks/useAccessToExposedType';
import { useUpdatePurpose } from 'pages/Payments/PaymentRequest/hooks/useUpdatePurpose';
import {
  PaymentRequestDetailsFields,
  PaymentRequestPayload,
} from 'pages/Payments/PaymentRequest/interfaces';
import { ExposedTypeOptions, vatTypes } from 'pages/Payments/PaymentRequest/utils';
import { MAX_VAT_PERCENT } from 'pages/Payments/utils';
import { isString } from 'utils/isData';

import { toReceiverAccountOptions } from './utils';

import './styles.scss';

const validatePayerTaxCode = (value: string) => {
  if (!codeRegexps.some(item => item.test(value))) {
    return translate('front.internal-payment-page.invalid-code.label');
  }
  return '';
};

const DEFAULT_RECEIVER_IBAN = 'UA';

export const ExposedOrderDetails: React.FC = () => {
  const { form, order, payload, onChangeCustomer } = useOrderDetails<
    PaymentRequestDetailsFields,
    PaymentRequestPayload
  >();

  const [defaultStorageVatPercent, setDefaultStorageVatPercent] = useStorageVatPercent();

  const onChangeVatPercent = (value: string) => {
    const correctValue = normalizeVatPercent(value);

    setDefaultStorageVatPercent(correctValue);
    form.updateData({ vatPercent: correctValue });
  };

  const { copyFrom } = parseUrlParams(useLocation().search);

  const defaultVatPercent = isString(defaultStorageVatPercent)
    ? defaultStorageVatPercent
    : DEFAULT_VAT_PERCENT;

  const {
    vatType,
    vatPercent = defaultVatPercent,
    isoCode = '',
    exposedType,
    receiverTaxCode,
  } = form.getFormData();

  React.useEffect(() => {
    if (order) {
      form.updateData({ ...order.detailFields });
      !defaultStorageVatPercent && setDefaultStorageVatPercent(DEFAULT_VAT_PERCENT);
    }
  }, []);

  const onChangePayerAccount = (value: string, content: Account): void => {
    if (content) {
      form.updateData({ isoCode: content.currency });
    }
  };

  const onChangeReceiver = async (value: string, option: Option) => {
    if (value && option) {
      const receiverAccountOptions = toReceiverAccountOptions(option.content.accounts);
      const { value: receiverIban, content } =
        receiverAccountOptions.length && receiverAccountOptions[0];

      form.updateData({
        receiverIban,
        [ExposedOrderDetailsNames.ReceiverTaxCode]: option.content.counterparty?.bin,
        [ExposedOrderDetailsNames.ReceiverBic]: content?.bankCode,
        [ExposedOrderDetailsNames.ReceiverBankName]: content?.bankName,
      });
      form.setPayload({ receiverAccountOptions });
    }
  };

  const validateReceiverAccount = React.useCallback((value: string): string => {
    if (isValidIBAN(value)) {
      const { receiverBic, receiverBankName } = getBicAndBankNameFromAccount(
        value,
        payload.localBanksOptions,
      );

      form.updateData({ receiverBic, receiverBankName });
      return '';
    }

    form.updateData({ receiverBic: null, receiverBankName: null });
    return translate('front.form.account-number.error');
  }, []);

  useChangingPurpose({ order, vatPercent: vatPercent || defaultVatPercent });
  useUpdatePurpose({ order, vatPercent: vatPercent || defaultVatPercent });
  const maxLengthPurpose = useMaxLengthPurpose();

  const onChangeVatType = (value: string) => {
    if (value !== VatType.INCLUDE_VAT20) {
      form.updateData({
        vatPercent: null,
      });
    }

    if (value === VatType.INCLUDE_VAT20) {
      form.updateData({
        vatPercent: defaultStorageVatPercent ?? DEFAULT_VAT_PERCENT,
      });
    }
  };

  const { isShowExposedType, isDisabledExposedRadio } = useAccessToExposedType({
    order,
    exposedType,
    updateData: form.updateData,
  });

  return (
    <div className="exposed-transfer">
      <DefaultForm className="exposed-transfer__form" disabled={form.progress}>
        {isShowExposedType && (
          <PreloadField isLoading={form.initializing}>
            <Row className="warning-radio">
              <Col sm={6} className="warning-label">
                {translate('front.internal-payment-page.forced-write-off-operation.label')}
              </Col>
              <Col sm={3}>
                <RadioField
                  name={ExposedOrderDetailsNames.ExposedType}
                  options={ExposedTypeOptions}
                  defaultValue={ExposedTypes.TRF}
                  disabled={isDisabledExposedRadio}
                />
              </Col>
            </Row>
          </PreloadField>
        )}

        <h3>{translate('front.internal-payment-page.receiver.title.label')}</h3>
        <CustomerSelect
          label="front.internal-payment-page.organization-name.label"
          name={ExposedOrderDetailsNames.PayerName}
          onSelectOption={onChangeCustomer}
          disabled={!!order?.state || !!copyFrom}
          required
        />
        <SelectField
          label="front.internal-payment-page.receiver-account.label"
          name={ExposedOrderDetailsNames.PayerAccount}
          options={payload.payerAccountOptions}
          onSelectOption={onChangePayerAccount}
          required
          clearable
        />
        <h3>{translate('front.internal-payment-page.payer.title.label')}</h3>
        <HintField
          label="front.internal-payment-page.organization-name.label"
          name={ExposedOrderDetailsNames.ReceiverName}
          hints={payload.receiverOptions}
          onChange={onChangeReceiver}
          maxLength={140}
          required
        />
        <MaskField
          label="front.internal-payment-page.payer-account.label"
          name={ExposedOrderDetailsNames.ReceiverIban}
          validate={validateReceiverAccount}
          mask={config.page.domesticPayment.ibanMask}
          normalize={normalizeIban}
          defaultValue={DEFAULT_RECEIVER_IBAN}
          required
        />
        <Row>
          <Col sm={4}>
            <TextField
              label="front.internal-payment-page.payer-code.label"
              name={ExposedOrderDetailsNames.ReceiverTaxCode}
              maxLength={10}
              validate={validatePayerTaxCode}
              required
            />
          </Col>
          <Col sm={8}>
            <SelectField
              name={ExposedOrderDetailsNames.ReceiverBic}
              label="front.internal-payment-page.order-receiver-bic.label"
              options={payload.localBanksOptions}
              required
              disabled
            />
          </Col>
        </Row>
        <Row>
          {isVisibleResidentCountryFiled(receiverTaxCode) && (
            <Col sm={8}>
              <SelectField
                label="front.internal-payment-page.non-resident-country.label"
                name={ExposedOrderDetailsNames.ReceiverCountryId}
                options={payload.countriesOptions}
                placeholder={nonResidentCountryPlaceholder(form)}
                required
                clearable
                isSearchable
              />
            </Col>
          )}
          <Col sm={8}>
            {isVisiblePassportField(receiverTaxCode) && (
              <TextField
                label="front.internal-payment-page.passport.label"
                name={ExposedOrderDetailsNames.ReceiverAddCode}
                validate={validatePassport}
                tooltip={translate('front.internal-payment-page.passport-tooltip.label')}
                required
              />
            )}
          </Col>
        </Row>
        <h3>{translate('front.internal-payment-page.amount-and-purpose.title.label')}</h3>
        <Row>
          <Col sm={4}>
            <AmountField
              label={`${translate('front.internal-payment-page.order-amount.label')} ${isoCode}`}
              name={ExposedOrderDetailsNames.Amount}
              minAmount={0.01}
              maxLengthWithoutZero={12}
              hasMoneyFormat
              thousandSeparator={' '}
              required
            />
          </Col>
          <Col sm={8}>
            <RadioField
              name={ExposedOrderDetailsNames.VatType}
              options={vatTypes}
              onChange={onChangeVatType}
              clearable
            />
          </Col>
        </Row>
        {vatType === VatType.INCLUDE_VAT20 && (
          <Row>
            <Col sm={6} />
            <Col sm={4}>
              <AmountField
                name={ExposedOrderDetailsNames.VatPercent}
                label="front.domestic-transfer.vat-rate.label"
                onChange={onChangeVatPercent}
                normalize={normalizeVatPercent}
                maxAmount={MAX_VAT_PERCENT}
                required
              />
            </Col>
          </Row>
        )}
        <TextAreaField
          label="front.internal-payment-page.order-purpose.label"
          name={ExposedOrderDetailsNames.Purpose}
          rows={3}
          minLength={6}
          maxLength={maxLengthPurpose}
          hasCounter
          required
          denyCaretReturn
          forbiddenSpecialCharacters
        />
        {!form.disabled && (
          <CheckboxField
            label="front.internal-payment-page.order-save-payment-purpose.label"
            name={ExposedOrderDetailsNames.SavePaymentPurpose}
          />
        )}
      </DefaultForm>
    </div>
  );
};
