export enum ExposedOrderDetailsNames {
  PayerName = 'payerName',
  PayerAccount = 'payerAccount',
  ReceiverName = 'receiverName',
  ReceiverIban = 'receiverIban',
  ReceiverAccount = 'receiverAccount',
  ReceiverTaxCode = 'receiverTaxCode',
  ReceiverBic = 'receiverBic',
  ReceiverCountryId = 'receiverCountryId',
  ReceiverAddCode = 'receiverAddCode',
  ReceiverBankName = 'receiverBankName',
  Amount = 'amount',
  VatType = 'vatType',
  VatPercent = 'vatPercent',
  Purpose = 'purpose',
  SavePaymentPurpose = 'savePaymentPurpose',
  ExposedType = 'exposedType',
}

export enum InvoiceOrderDetailsNames {
  PayerName = 'payerName',
  PayerAccount = 'payerAccount',
  ReceiverName = 'receiverName',
  ReceiverIban = 'receiverIban',
  ReceiverTaxCode = 'receiverTaxCode',
  ReceiverBic = 'receiverBic',
  Amount = 'amount',
  VatType = 'vatType',
  VatPercent = 'vatPercent',
  Purpose = 'purpose',
  SavePaymentPurpose = 'savePaymentPurpose',
}

export enum PaymentRequestsFilterFormNames {
  ExactOrderNumber = 'exactOrderNumber',
  PayerAccount = 'payerAccount',
  AmountFrom = 'amountFrom',
  AmountTo = 'amountTo',
  DateFrom = 'dateFrom',
  DateTo = 'dateTo',
  CustomerIds = 'customerIds',
}

export enum ExposedTypes {
  FD = 'FD',
  TRF = 'TRF',
}

export enum PaymentRequestsSourceFieldsEnum {
  NATIONAL_ACCOUNTS = 'NATIONAL_ACCOUNTS',
  CUSTOMER = 'CUSTOMER',
  LOCAL_BANKS = 'LOCAL_BANKS',
  COUNTRIES = 'COUNTRIES',
  ASP_PROVIDERS = 'ASP_PROVIDERS',
  COUNTERPARTIES = 'COUNTERPARTIES',
}
