import * as React from 'react';

import {
  CertificateContext,
  ICertificateContext,
} from 'pages/Profile/Certificates/CertificateEngine/CertificateContext';

export const useSearchToken = () => {
  const { isSearchTokens, onSearchToken } =
    React.useContext<ICertificateContext>(CertificateContext);

  return {
    isSearchTokens,
    onSearchToken,
  };
};
