import * as React from 'react';

import { Counter } from 'components/Counter/Counter';

import './styles.scss';

interface CounterDescriptionProps {
  count: number;
  description: string;
}

export const CounterDescription: React.FC<CounterDescriptionProps> = ({ description, count }) => (
  <div className="counter-description">
    <span className="description">{description}</span>
    <div className="counter-wrapper">
      <Counter count={count} />
    </div>
  </div>
);
