import React from 'react';

import { Counter } from 'components/Counter/Counter';
import { translate } from 'i18n/translator';

interface TableCounterProps {
  selectedCount: number;
  total: number;
}

export const TableCounter = ({ selectedCount, total }: TableCounterProps) => {
  if (selectedCount !== 0) {
    return (
      <div className="table-counter mb-8">
        {translate('front.table.selected-actions-count.label')}
        <Counter count={selectedCount} />
      </div>
    );
  }

  return (
    <div className="mb-8">{`${translate('front.table.all-orders-count.label')} ${total}`}</div>
  );
};
