import * as React from 'react';
import { TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { UkraineBanksGrid } from 'api/HandbooksService';
import { SortOrder } from 'api/Service';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';

import { UkraineBanksFilter } from './UkraineBanksFilter';

const columns: TableColumnProps<UkraineBanksGrid>[] = [
  {
    label: 'front.ukraine-banks-table.id.label',
    render: row => <DataColumn title={row.nationalBankBik} />,
    sort: 'id',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.ukraine-banks-table.name.label',
    render: row => <DataColumn title={row.bankName} />,
    sort: 'bankName',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

export const UkraineBanksPage = () => (
  <DataTable
    name={TableNamesEnum.UkraineBanksTable}
    columns={columns}
    fetchRows={api.handbooks.getUkraineBanks}
    defaultSort="id"
    defaultOrder={SortOrder.Desc}
    filterComponent={UkraineBanksFilter}
  />
);
